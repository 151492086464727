import React, { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";

import {
  ClassificationAttributeType,
  useClassificationAttributes,
} from "../../../../Annotate/components/Manage/hooks/useClassificationAttributes";
import {
  getInsertClassificationAttributesVariables,
  useInsertClassificationAttributes,
} from "../../../../Annotate/components/Manage/hooks/useInsertClassificationAttributes";
import { ActionButtonsWrapper } from "../../../../common/components/Dialog/Form/ActionButtonsWrapper";
import { FormProps } from "../../../../common/components/Dialog/FormProps";
import { ErrorLabel } from "../../../../common/components/input/ErrorLabel";
import { Input } from "../../../../common/components/input/Input";
import { InputButton } from "../../../../common/components/input/InputButton";
import { Label } from "../../../../common/components/input/Label";
import { main } from "../../../../common/theme/main";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 24px 15px;
  gap: 24px;
  width: 419px;
`;

export interface CreateClassificationFormProps {
  classification: ClassificationAttributeType | undefined;
}

export const CreateClassificationForm: FC<FormProps<CreateClassificationFormProps>> = ({
  onSubmit,
  onCancel,
  onSetTitle,
  props: { classification },
}: FormProps<CreateClassificationFormProps>) => {
  const isNewClassification = !classification;

  useEffect(() => {
    onSetTitle?.(isNewClassification ? "Create Classification" : "Edit Classification");
  }, [isNewClassification]);

  const { readonly } = classification ?? { readonly: false, text: "" };

  const { data: classificationAttributesData } = useClassificationAttributes();

  const [insertClassificationAttributes] = useInsertClassificationAttributes();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ClassificationAttributeType>({
    defaultValues: classification ?? { text: "", readonly: false },
  });

  const handleCancel = () => {
    onCancel();
  };

  const handleSubmitForm = async (classification: ClassificationAttributeType) => {
    if (isNewClassification) {
      const variables = getInsertClassificationAttributesVariables([classification]);
      await insertClassificationAttributes({ variables });
    } else {
      throw new Error("Editing Classifications is unimplemented!");
    }
    onSubmit();
  };

  const validateName = (value: string) => {
    const { classificationAttributes } = classificationAttributesData || {
      classificationAttributes: [],
    };
    return !classificationAttributes.some(({ text }) => text === value);
  };

  return (
    <form onSubmit={handleSubmit(handleSubmitForm)}>
      <Wrapper>
        <div>
          <Label htmlFor={"classification-name"} required error={errors.text}>
            Classification Name
          </Label>
          <Input
            error={errors.text}
            type="text"
            id={"classification-name"}
            autoFocus
            autoComplete={"off"}
            disabled={readonly}
            {...register("text", {
              required: true,
              validate: validateName,
            })}
          />
          {errors.text && <ErrorLabel>Unique classification name must be provided</ErrorLabel>}
        </div>
      </Wrapper>

      <ActionButtonsWrapper>
        <InputButton type="submit" name="submit-button" value={"Confirm"} />
        <InputButton
          type="button"
          name="cancel-button"
          value={"Cancel"}
          background={main.colors.neutral.white}
          color={main.colors.neutral.black}
          onClick={handleCancel}
        />
      </ActionButtonsWrapper>
    </form>
  );
};
