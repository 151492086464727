import { ContourDiametersDataType, getRoiContourDiameters } from "./getRoiContourDiameters";

export function getKeyContourDiameters(roiId: number): ContourDiametersDataType | undefined {
  const diameters = getRoiContourDiameters(roiId);

  if (diameters.length === 0) {
    return undefined;
  }
  return diameters.reduce((prev, current) => (prev.LAD > current.LAD ? prev : current));
}
