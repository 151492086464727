import { CircularProgress } from "@mui/material";
import React, { FC, ReactNode } from "react";
import styled from "styled-components";

import { main } from "../theme/main";
import { Card } from "./cards/Card";

interface LoadingProps {
  color?: string;
  size?: number;
  thickness?: number;
  children?: ReactNode;
}

export const Loading: FC<LoadingProps> = ({
  color = main.colors.actionPrimary.hover,
  size = 16,
  thickness = 8,
}: LoadingProps) => {
  return <CircularProgress size={size} thickness={thickness} style={{ color }} />;
};

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  height: 100%;
`;

const ScreenSizedWrapper = styled.div`
  height: 100vh;
`;

export function FlexLoading({ children, ...loadingProps }: LoadingProps): JSX.Element {
  return (
    <Wrapper>
      <Loading {...loadingProps} />
      {children}
    </Wrapper>
  );
}

export function BigFlexLoading({
  size = 48,
  thickness = 6,
  ...otherProps
}: LoadingProps): JSX.Element {
  return (
    <ScreenSizedWrapper>
      <FlexLoading size={size} thickness={thickness} {...otherProps} />
    </ScreenSizedWrapper>
  );
}

export const LoadingCard: FC = () => {
  return <Card noHead={true} content={<FlexLoading />} />;
};
