export const hexToRgbArray = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (!result) {
    throw new Error(
      "ERROR: hex string passed into hexToRgb converter did not match hex color format!"
    );
  }

  return [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16), 255];
};
