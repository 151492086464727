import { TASK_COMPLETED } from "../../../Annotate/components/TaskWorklist/TaskProgressType";
import { LesionType, TaskAssignmentType } from "../hooks/useProjectLabels";

export function isLesionTaskComplete(
  { taskId }: Pick<LesionType, "taskId">,
  taskAssignments: Pick<TaskAssignmentType, "taskId" | "progress">[]
): boolean {
  return taskAssignments
    .filter(({ taskId: taskAssignmentTaskId }) => taskAssignmentTaskId === taskId)
    .some(({ progress }) => progress === TASK_COMPLETED);
}
