import React from "react";
import styled from "styled-components";

import { formatMM } from "./utils/formatMM";

const Wrapper = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.neutral.neutral3};
`;

interface TumourBurdenTimepointStatProps {
  value: number | undefined;
}

export function TumourBurdenTimepointStat({ value }: TumourBurdenTimepointStatProps): JSX.Element {
  return <Wrapper>{value ? formatMM(value) : "N/A"}</Wrapper>;
}
