import { getMasksFromCornerstone } from "./getMasksFromCornerstone";

export function clearMask(id: number, firstImageId: string) {
  const masks = getMasksFromCornerstone(firstImageId);

  if (!Object.keys(masks).some((maskId) => maskId === id.toString())) {
    throw new Error(`Mask with id ${id} not found`);
  }

  // cornerstone expects the mask to be deleted from the labelmaps3D object instead of just setting it undefined
  delete masks[id];
}
