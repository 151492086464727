import React, { ReactElement } from "react";
import styled from "styled-components";

import { TUMOR_SPELLING } from "../../../common/utils/spellingConstants";
import { CheckboxGroup } from "../../common/components/CheckboxGroup";
import DisplayRadioButton from "../../common/components/DisplayRadioButton";

export const RECIST = "RECIST";
export const IPRO = "IPRO";
export const MORTALITY = "MORTALITY";
// TODO: Really should be mapping these in an array or set, not as _AND_ strings - see NOTA-650
export const RECIST_AND_IPRO = "RECIST_AND_IPRO";
export const RECIST_AND_MORTALITY = "RECIST_AND_MORTALITY";

export type FilterType =
  | typeof RECIST
  | typeof IPRO
  | typeof MORTALITY
  | typeof RECIST_AND_IPRO
  | typeof RECIST_AND_MORTALITY;

const burdenTypes = ["DIAMETRIC", "VOLUMETRIC"] as const;
export type BurdenType = typeof burdenTypes[number];
export type GraphView = BurdenType;

const FILTER_DISPLAY_NAMES: Record<FilterType, string> = {
  RECIST: TUMOR_SPELLING + " Burden",
  MORTALITY: "Mortality Risk",
  IPRO: "Survival",
  RECIST_AND_IPRO: TUMOR_SPELLING + " Burden & Survival",
  RECIST_AND_MORTALITY: TUMOR_SPELLING + " Burden & Mortality",
};

export const VIEW_DISPLAY_NAMES: Record<BurdenType, string> = {
  DIAMETRIC: "Diametric",
  VOLUMETRIC: "Volumetric",
};

interface FilterProps {
  activeFilter: FilterType;
  activeView: GraphView;
  onGraphParamsChanged: (view: GraphView, filter: FilterType) => void;
  allowedDataTypes: FilterType[];
  graphViews: GraphView[];
}

const Wrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 48px;
`;

const parseCheckboxSelections = (
  selectedValues: FilterType[],
  currentFilter: FilterType
): FilterType => {
  if (selectedValues.length === 1) {
    return selectedValues[0];
  }

  if (
    selectedValues.includes(RECIST) &&
    selectedValues.includes(MORTALITY) &&
    selectedValues.includes(IPRO)
  ) {
    return currentFilter === RECIST_AND_IPRO ? RECIST_AND_MORTALITY : RECIST_AND_IPRO;
  }

  if (selectedValues.includes(RECIST) && selectedValues.includes(IPRO)) {
    return RECIST_AND_IPRO;
  }
  if (selectedValues.includes(RECIST) && selectedValues.includes(MORTALITY)) {
    return RECIST_AND_MORTALITY;
  }

  // TODO: we currently can't show IPRO and MORTALITY at the same time, so we we revert the selection to prevent it. When working on NOTA-650, we should maintain this logic
  if (selectedValues.includes(IPRO) && selectedValues.includes(MORTALITY)) {
    return currentFilter;
  }

  return RECIST;
};

const isSelected = (filter: FilterType, activeFilter: FilterType): boolean => {
  switch (activeFilter) {
    case RECIST_AND_IPRO:
      return filter === RECIST || filter === IPRO;
    case RECIST_AND_MORTALITY:
      return filter === RECIST || filter === MORTALITY;
    default:
      return filter === activeFilter;
  }
};

export function Filter({
  onGraphParamsChanged,
  activeFilter,
  activeView,
  allowedDataTypes,
  graphViews,
}: FilterProps): ReactElement {
  const allowedFilters: FilterType[] = [RECIST, IPRO, MORTALITY];
  const displayedFilters = allowedDataTypes.filter((filter) => allowedFilters.includes(filter));

  return (
    <Wrapper>
      <CheckboxGroup
        items={displayedFilters.map((filter) => ({
          label: FILTER_DISPLAY_NAMES[filter],
          value: filter,
          selected: isSelected(filter, activeFilter),
          color: "#000",
        }))}
        requireOne
        size="small"
        direction="horizontal"
        showReset
        defaultValue={[RECIST]}
        style={{ marginRight: "16px" }}
        onChange={(selectedItems) => {
          const selectedValues = selectedItems
            .filter((item) => item.selected)
            .map((item) => item.value);

          const newFilter = parseCheckboxSelections(selectedValues, activeFilter);
          onGraphParamsChanged(activeView, newFilter);
        }}
      />

      {graphViews.map((view) => {
        return (
          <DisplayRadioButton
            active={activeView.includes(view)}
            key={view}
            onClick={() => onGraphParamsChanged(view, activeFilter)}
          >
            {VIEW_DISPLAY_NAMES[view as GraphView]}
          </DisplayRadioButton>
        );
      })}
    </Wrapper>
  );
}
