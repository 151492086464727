import { useCallback } from "react";

import { useActivateViewer } from "../../ViewerPanel/Viewer/hooks/useActivateViewer";
import { useAddViewer } from "../../ViewerPanel/Viewer/hooks/useAddViewer";
import { useGetViewerForSeries } from "../../ViewerPanel/Viewer/hooks/useGetViewerForSeries";
import { SeriesListItemFragmentType } from "./useStudiesList";

export function useJumpToViewerSeries() {
  const addViewer = useAddViewer();
  const activateViewer = useActivateViewer();
  const getViewerForSeries = useGetViewerForSeries();

  return useCallback(
    (series: SeriesListItemFragmentType) => {
      const { id: seriesId } = series;
      // TODO: disabled collapsing for now since doesn't play well with virtualization
      // dispatch(setSelectedRoiCollapsedState({ roiId }));

      const viewer = getViewerForSeries(seriesId);
      if (viewer) {
        const { id } = viewer;
        activateViewer(id);
      } else {
        addViewer(seriesId);
      }
    },
    [activateViewer, addViewer, getViewerForSeries]
  );
}
