import React, { ComponentType, useContext } from "react";

import { useOkForm } from "../../../../../common/components/Dialog/useOkForm";
import { useCurrentUser } from "../../../../../common/contexts/UserContext/useCurrentUser";
import { CONSTRAINT_ANY, CONSTRAINT_ONLY_ONE } from "../../../../enums/TaskDescriptionEnums";
import { TaskContext } from "../../../../TaskContext";
import { ConstraintType } from "../../../../types/TaskDescriptionType";
import { useUpdateTaskInProgress } from "../hooks/useUpdateTaskInProgress";
import {
  getVariables,
  useInsertSeriesClassifications,
} from "./hooks/useInsertSeriesClassifications";
import { SeriesListItemFragmentType } from "./hooks/useStudiesList";

interface InsertSeriesClassificationWrapperProps {
  children: ComponentType<{
    onClick: (classification: string) => void;
  }>;
  series: SeriesListItemFragmentType;
  constraint: ConstraintType;
}

export function InsertSeriesClassificationsWrapper({
  series,
  constraint,
  children: Component,
}: InsertSeriesClassificationWrapperProps): JSX.Element {
  const [showInvalidClassificationsDialog, { dialog: invalidClassificationsDialog }] = useOkForm({
    title: `Cannot Add Classification to Series`,
    message:
      "You cannot add this classification to the series as it already has a classification. To add this classification, remove the existing one first.",
  });

  const [insertSeriesClassifications] = useInsertSeriesClassifications();
  const updateTaskInProgress = useUpdateTaskInProgress();

  const {
    task: { id: currentTaskId },
  } = useContext(TaskContext);
  const { id: currentUserId } = useCurrentUser();

  const { seriesClassifications, id: seriesId } = series;

  const seriesClassificationsValues = seriesClassifications
    .filter(({ taskId }) => taskId === currentTaskId)
    .map(({ classification }) => classification);

  const handleClassificationAdded = async (classification: string) => {
    if (seriesClassificationsValues.includes(classification)) {
      return;
    }

    if (constraint === CONSTRAINT_ONLY_ONE && seriesClassificationsValues.length > 0) {
      showInvalidClassificationsDialog(true);
      return;
    }

    if (constraint !== CONSTRAINT_ONLY_ONE && constraint !== CONSTRAINT_ANY) {
      throw new Error("Invalid constraint!");
    }

    await insertSeriesClassifications({
      variables: getVariables(seriesId, currentUserId, currentTaskId, [classification]),
    });
    await updateTaskInProgress();
  };

  return (
    <>
      {invalidClassificationsDialog}
      <Component onClick={handleClassificationAdded} />
    </>
  );
}
