import { useDialogReturnType } from "../../../common/components/Dialog/useDialog";
import { useFormDialog } from "../../../common/components/Dialog/useFormDialog";
import { UploadMetadataForm, UploadMetadataFormProps } from "./UploadMetadataForm";

export function useUploadMetadataForm(props: UploadMetadataFormProps): useDialogReturnType {
  const { onCancelled } = props;
  return useFormDialog({
    children: UploadMetadataForm,
    label: "Upload",
    showCloseButton: true,
    reactModalProps: {
      onAfterClose: onCancelled,
    },
    props,
  });
}
