import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { MutationTuple } from "@apollo/client/react/types/types";

import { PATIENT_BIOPSY_DETAILS_FRAGMENT } from "../../fragments/PatientBiopsyDetailsFragment";
import { PatientBiopsyDetailsFragmentType } from "../../fragments/PatientBiopsyDetailsFragmentType";

const MUTATION = gql`
  mutation UpdateBiopsyDetailsLesionId($lesionId: Int, $unsure: Boolean, $id: Int!) {
    update_patient_biopsy_details_by_pk(
      pk_columns: { patient_biopsy_details_id: $id }
      _set: { lesion_id: $lesionId, unsure: $unsure }
    ) {
      ...PatientBiopsyDetails
    }
  }
  ${PATIENT_BIOPSY_DETAILS_FRAGMENT}
`;

type Variables = {
  lesionId: number | null;
  unsure: boolean;
  id: number;
};

type Data = {
  update_patient_biopsy_details_by_pk: PatientBiopsyDetailsFragmentType;
};

export function useUpdateBiopsyLesionAssignment(): MutationTuple<Data, Variables> {
  return useMutation<Data, Variables>(MUTATION);
}
