import {
  FilterableTimepointType,
  TimepointType,
} from "../../AnnotationPanel/hooks/Timepoint/TimepointType";
import { FilterableSeriesListItemFragmentType } from "../../AnnotationPanel/hooks/useStudiesList";

const SERIES_FILTERABLE_PROPS = ["seriesInstanceUid", "seriesDescription"] as const;

const countFilteredSeries = (timepoints: FilterableTimepointType[]) =>
  timepoints.flatMap((timepoint) => timepoint.series).filter((series) => series.filtered).length;

type MapTimepointsReturnType = {
  timepoints: FilterableTimepointType[];
  matchesCount: number;
};

export const mapTimepointsFilter = (
  timepoints: TimepointType[],
  filter?: string
): MapTimepointsReturnType => {
  if (!filter || timepoints.length === 0) {
    return { timepoints, matchesCount: -1 };
  }

  const filterLower = filter.toLowerCase();

  const timepointsWithFilter = timepoints.map((timepoint) => {
    const series = timepoint.series.map((series) => {
      const isMatch = SERIES_FILTERABLE_PROPS.some((key) =>
        series?.[key]?.toLowerCase().includes(filterLower)
      );
      return {
        ...series,
        filtered: !isMatch,
      } as FilterableSeriesListItemFragmentType;
    });

    return {
      ...timepoint,
      series,
    };
  });

  const seriesCount = timepoints.flatMap((timepoint) => timepoint.series).length;
  const matchesCount = seriesCount - countFilteredSeries(timepointsWithFilter);
  return {
    timepoints: timepointsWithFilter,
    matchesCount,
  };
};

