import { CohortType } from "../../../../common/types/CohortType";
import { useInsertDeleteCohortPatient } from "../Patients/useInsertDeleteCohortPatient";
import { DeletableCohortLabel } from "./DeletableCohortLabel";

interface DeletableCohortPatientLabelProps {
  cohort: CohortType;
  patientId: number;
}

export function DeletableCohortPatientLabel({
  cohort,
  patientId,
}: DeletableCohortPatientLabelProps): JSX.Element {
  const { id } = cohort;

  const [updateCohortPatient] = useInsertDeleteCohortPatient();

  const handleDeleteClicked = async () => {
    await updateCohortPatient({
      variables: {
        deleted: {
          _or: [
            {
              _and: [{ patient_id: { _eq: patientId }, label_id: { _eq: id } }],
            },
          ],
        },
        added: [],
      },
    });
  };

  return <DeletableCohortLabel cohort={cohort} onDeleteClicked={handleDeleteClicked} />;
}

