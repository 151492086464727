import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useOkForm } from "../../../../../../common/components/Dialog/useOkForm";
import { lesionsClassificationsDefinitionsSelector } from "../../../../../../common/store/annotatePage/classificationsSelector";
import {
  ClassificationsDefinitionsType,
  setLesionEditing,
} from "../../../../../../common/store/annotatePage/classificationsSlice";
import { recistTaskSelector } from "../../../../../../common/store/annotatePage/taskSelector";
import { useUpdateTaskInProgress } from "../../hooks/useUpdateTaskInProgress";
import { LesionListItemFragmentType } from "../fragments/LesionListItemFragment";
import { areClassificationsComplete } from "../utils/areClassificationsComplete";
import { areClassificationsEqual } from "../utils/areClassificationsEqual";
import { areRecistClassificationsValid } from "../utils/areRecistClassificationsValid";
import {
  BASELINE_CLASSIFICATION_PREVENTS_TARGET,
  EXISTS_AT_BASELINE,
  NO_MEASUREMENTS,
  NODE_SAD_TOO_SMALL,
  RecistClassificationErrorType,
  TARGET_LAD_TOO_SMALL,
  TOO_MANY_TARGETS_PER_ORGAN,
  TOO_MANY_TARGETS_TOTAL,
} from "../utils/ClassificationChecks";
import { getInsertAndDeleteClassificationLists } from "../utils/getInsertAndDeleteClassificationLists";
import { useChangeLesionColors } from "../utils/useChangeLesionColors";
import { useGetRoiPreventsLesionClassification } from "./timepointOptions/useGetRoiPreventsLesionClassification";
import { useInsertLesionClassifications } from "./useInsertLesionClassification";
import { useIsBaselineRoi } from "./useIsBaselineRoi";
import { useLesionsList } from "./useLesionsList";
import { lesionsForCurrentTaskFilter } from "./useTaskLesionListOptions";
import { useTumourBurdenChanged } from "./useTumourBurdenChanged";
import { useUpdateLesionLocation } from "./useUpdateLesionLocation";

type ReturnType = {
  callback: () => void;
  dialog: JSX.Element | null;
};

export function useHandleClassificationsSaved(
  lesion: LesionListItemFragmentType,
  onSaved?: () => void
): ReturnType {
  const [dialogMessage, setDialogMessage] = useState<string>("N/A");
  const isRecist = useSelector(recistTaskSelector);

  const isBaselineRoi = useIsBaselineRoi();

  const dispatch = useDispatch();

  const { data } = useLesionsList(lesionsForCurrentTaskFilter);

  const { id: lesionId, location: lesionLocationId } = lesion;

  const [insertLesionClassifications] = useInsertLesionClassifications();
  const { onTumourBurdenChanged } = useTumourBurdenChanged();
  const changeLesionColors = useChangeLesionColors();

  const [updateLesionLocation] = useUpdateLesionLocation();
  const updateTaskInProgress = useUpdateTaskInProgress();

  const [showInvalidClassificationsDialog, { dialog: invalidClassificationsDialog }] = useOkForm({
    title: `Cannot Classify Lesion`,
    message: dialogMessage,
  });

  const lesionClassificationsDefinitions = useSelector(lesionsClassificationsDefinitionsSelector);

  const getRoiPreventsLesionClassification = useGetRoiPreventsLesionClassification();

  const updateLesionClassifications = async () => {
    const { classificationsMapProxy, selectedAnatomicalStructure } =
      lesionClassificationsDefinitions[lesionId];
    const { insertClassifications, deleteClassifications } =
      getInsertAndDeleteClassificationLists(classificationsMapProxy);

    await insertLesionClassifications(lesionId, insertClassifications, deleteClassifications);
    await updateTaskInProgress();

    await onTumourBurdenChanged(lesionId);

    const location = selectedAnatomicalStructure ? selectedAnatomicalStructure.value.id : null;

    if (location !== null) {
      await updateLesionLocation({
        variables: {
          id: lesionId,
          location,
        },
      });
      changeLesionColors(lesionId);
    }
  };

  const handleOnSaved = () => {
    dispatch(setLesionEditing({ lesionId, editing: false }));
    onSaved?.();
  };

  const handleLesionClassificationsSaved = async () => {
    const lesionClassifications = lesionClassificationsDefinitions[lesionId];

    await handleClassificationsSaved(
      lesionClassifications,
      lesionLocationId,
      handleOnSaved,
      updateLesionClassifications
    );
  };

  const handleRecistLesionClassificationsSaved = async () => {
    const lesionClassifications = lesionClassificationsDefinitions[lesionId];

    const doRecistChecks = () => {
      if (!lesionClassifications) {
        throw new Error(`Classification definition does not exist for lesion with id ${lesionId}`);
      }

      const { classificationsMapProxy, selectedAnatomicalStructure } = lesionClassifications;

      if (!data) {
        throw new Error("Lesions data should not be null!");
      }

      const { lesions } = data;

      const errorType = areRecistClassificationsValid(
        lesion,
        lesions,
        classificationsMapProxy,
        selectedAnatomicalStructure?.value?.id,
        isBaselineRoi,
        getRoiPreventsLesionClassification
      );

      if (!errorType) {
        return true;
      }

      setDialogMessage(errorMessages[errorType]);
      showInvalidClassificationsDialog(true);
      return false;
    };

    await handleClassificationsSaved(
      lesionClassifications,
      lesionLocationId,
      handleOnSaved,
      updateLesionClassifications,
      doRecistChecks
    );
  };

  return {
    callback: !isRecist ? handleLesionClassificationsSaved : handleRecistLesionClassificationsSaved,
    dialog: !isRecist ? null : invalidClassificationsDialog,
  };
}

async function handleClassificationsSaved(
  lesionClassification: ClassificationsDefinitionsType | undefined,
  locationId: number | null,
  onSaved: () => void,
  updateClassifications: () => Promise<void>,
  runAdditionalChecks?: () => boolean
): Promise<void> {
  if (!lesionClassification) {
    throw new Error(`Classification definition does not exist`);
  }

  const {
    classificationsMapProxy,
    classificationsMapInitial: initialClassificationsMap,
    useAnatomySelector,
    selectedAnatomicalStructure,
  } = lesionClassification;

  if (
    !areClassificationsComplete(
      classificationsMapProxy,
      useAnatomySelector,
      selectedAnatomicalStructure
    )
  ) {
    return;
  }

  if (runAdditionalChecks && !runAdditionalChecks()) {
    return;
  }

  onSaved();

  if (
    areClassificationsEqual(
      classificationsMapProxy,
      initialClassificationsMap,
      useAnatomySelector,
      locationId,
      selectedAnatomicalStructure?.value?.id
    )
  ) {
    return;
  }

  await updateClassifications();
}

const errorMessages: Record<RecistClassificationErrorType, string> = {
  [EXISTS_AT_BASELINE]: "This lesion does not exist at the baseline",
  [BASELINE_CLASSIFICATION_PREVENTS_TARGET]:
    "This lesion contains a baseline classification that prevents it from being a Target",
  [NO_MEASUREMENTS]: "This lesion has no LAD or SAD",
  [NODE_SAD_TOO_SMALL]:
    "In order to be classified as Node, this lesion's SAD must be at least 15mm",
  [TARGET_LAD_TOO_SMALL]:
    "In order to be classified as Target, this lesion's LAD must be at least 10mm",
  [TOO_MANY_TARGETS_TOTAL]: "There can only be a maximum of 5 target lesions",
  [TOO_MANY_TARGETS_PER_ORGAN]: "There can only be a maximum of 2 target lesions per organ",
};
