import { cloneDeep } from "lodash";

import {
  ANNOTATE_LIMIT_TO_BASELINE,
  CONSTRAINT_ANY,
  CONSTRAINT_ONLY_ONE,
  DEFAULT,
} from "../../../../enums/TaskDescriptionEnums";
import {
  AnnotateRulesType,
  ClassificationModeType,
  ClassificationObjectsType,
  ConstraintType,
  TaskDescriptionType as TaskDescriptionDataType,
} from "../../../../types/TaskDescriptionType";
import { ClassificationAttributeType } from "../../hooks/useClassificationAttributes";
import { SeriesClassificationOptions } from "../SeriesClassificationOptions";

export function createSeriesClassificationTaskDescription(
  data: TaskDescriptionDataType,
  classificationAttributes: ClassificationAttributeType[],
  newTaskName: string,
  { toggleOnlyOne, toggleAny }: SeriesClassificationOptions,
  firstTimepointOnly: boolean
): TaskDescriptionDataType {
  const taskDescriptionCopy = cloneDeep(data);

  const { options } = taskDescriptionCopy;

  let constraint: ConstraintType | undefined = undefined;

  if (toggleOnlyOne && !toggleAny) {
    constraint = CONSTRAINT_ONLY_ONE;
  }

  if (toggleAny && !toggleOnlyOne) {
    constraint = CONSTRAINT_ANY;
  }

  if (!constraint) {
    throw new Error("Constraint is undefined!");
  }

  taskDescriptionCopy.name = newTaskName;

  const timepointOption = {
    rule: ANNOTATE_LIMIT_TO_BASELINE as AnnotateRulesType,
    enabled: firstTimepointOnly,
  };

  const values = classificationAttributes.map(({ text }) => ({
    value: text,
    readOnly: false,
    displayName: text,
  }));

  const classifications = [
    {
      mode: DEFAULT as ClassificationModeType,
      constraint,
      values: values as ClassificationObjectsType[],
    },
  ];

  const series = {
    classifications,
  };

  const study = {
    series,
  };

  taskDescriptionCopy.patient = {
    study,
  };

  if (!options) {
    taskDescriptionCopy.options = [timepointOption];
    return taskDescriptionCopy;
  }

  const filteredOptions = options.filter(({ rule }) => rule !== ANNOTATE_LIMIT_TO_BASELINE);

  filteredOptions.push(timepointOption);

  return taskDescriptionCopy;
}
