import React, { ReactElement } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Text = styled.div`
  margin-left: 12px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  color: ${(props) => props.theme.colors.neutral.black};
`;

interface LabelProps {
  icon: ReactElement;
  title: string;
}

export function Label({ icon, title }: LabelProps): ReactElement {
  return (
    <Wrapper>
      {icon}
      <Text>{title}</Text>
    </Wrapper>
  );
}
