import {
  HISTOLOGY_AC,
  HISTOLOGY_BRONCHIOLOALVEOLAR,
  HISTOLOGY_LCC,
  HISTOLOGY_MIXED,
  HISTOLOGY_NOS,
  HISTOLOGY_OTHER,
  HISTOLOGY_SQCC,
} from "../../../Analysis/common/types/TumourHistologyType";
import { HistologyFilters } from "../../types/ProjectViewFilterTypes";
import { RootState } from "../store";
import { ProjectFilterSelectors } from "./ProjectFilterSelectors";

export const defaultHistologyState = {
  [HISTOLOGY_AC]: true,
  [HISTOLOGY_LCC]: true,
  [HISTOLOGY_SQCC]: true,
  [HISTOLOGY_BRONCHIOLOALVEOLAR]: true,
  [HISTOLOGY_MIXED]: true,
  [HISTOLOGY_NOS]: true,
  [HISTOLOGY_OTHER]: true,
};

export const clearHistologyState = {
  [HISTOLOGY_AC]: false,
  [HISTOLOGY_LCC]: false,
  [HISTOLOGY_SQCC]: false,
  [HISTOLOGY_BRONCHIOLOALVEOLAR]: false,
  [HISTOLOGY_MIXED]: false,
  [HISTOLOGY_NOS]: false,
  [HISTOLOGY_OTHER]: false,
};

export const histologyFilters = new ProjectFilterSelectors<HistologyFilters>(
  defaultHistologyState,
  clearHistologyState,
  (state: RootState) => state.projectViewFilters.histology
);
