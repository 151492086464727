import { GlobalRoleType, UserRoleType } from "./UserRoleType";

export type UserType = {
  id: number;
  auth0Id?: string;
  firstName?: string | null;
  lastName?: string | null;
  title?: string | null;
  organization?: string | null;
  email: string;
  suspended: boolean;
  globalRoles: GlobalRoleType[];
  projectRoles: UserRoleType[] | undefined;
  avatarImageSource?: string;
};

export type UserAvatarType = Pick<
  UserType,
  "id" | "firstName" | "lastName" | "email" | "avatarImageSource"
>;

export function getInitials({ firstName, lastName, email }: UserAvatarType): string {
  let initials = "N/A";

  if (firstName?.trim().length && lastName?.trim().length) {
    initials = `${firstName.trim()[0]}${lastName.trim()[0]}`;
  } else if (email?.trim().indexOf("@") >= 2) {
    initials = email.trim().slice(0, 2);
  }

  initials = initials.toUpperCase();

  return initials;
}

export function isNewUser({ id }: UserType): boolean {
  return id === 0;
}

export function isExistingUser(user: UserType): boolean {
  return !isNewUser(user);
}
