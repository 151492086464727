import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useDeepCompareMemo } from "use-deep-compare";

import { FlexLoading } from "../../../../../common/components/Loading";
import {
  includeOtherLesionsSelector,
  showPredictedRoisSelector,
} from "../../../../../common/store/annotatePage/taskSelector";
import handleApolloError from "../../../../../common/utils/handleApolloError";
import { useLesionListQueryInput } from "./hooks/useLesionListQueryInput";
import { useLesionsList } from "./hooks/useLesionsList";
import {
  otherLesionsOptions,
  predictedLesionsOptions,
  useTaskLesionListOptions,
} from "./hooks/useTaskLesionListOptions";
import { useValidateLesionClassifications } from "./hooks/useValidateLesionClassifications";
import { LesionsListContainer } from "./LesionsListContainer";
import { MaskContextProvider } from "./MaskContextProvider";
import { SeriesClassificationsListContainer } from "./SeriesClassificationsListContainer";

const AnnotationListsWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: hidden;
  margin-top: 32px;
  padding: 2px 1px;
`;

export function AnnotationsPanel(): JSX.Element {
  const lesionListOptions = useTaskLesionListOptions();
  const classificationsChangedDialog = useValidateLesionClassifications();
  const includeOtherLesions = useSelector(includeOtherLesionsSelector);
  const showPredictedRois = useSelector(showPredictedRoisSelector);

  const lesionListQueryInput = useLesionListQueryInput();
  const {
    taskContext: {
      task: { id: taskId },
    },
  } = lesionListQueryInput;

  const { data, loading, error } = useLesionsList();

  const { lesions } = data ?? { lesions: [] };

  const sortedLesions = useDeepCompareMemo(
    () =>
      [...lesions].sort((a, b) => {
        const createdA = new Date(a.created);
        const createdB = new Date(b.created);
        return createdB.getTime() - createdA.getTime();
      }),
    [lesions]
  );

  if (error) handleApolloError(error);
  if (loading) return <FlexLoading />;

  if (!data) {
    throw new Error("Lesions are undefined!");
  }

  return (
    <>
      {classificationsChangedDialog}
      <SeriesClassificationsListContainer />
      <MaskContextProvider>
        <AnnotationListsWrapper>
          {lesionListOptions.map((options, index) => {
            const filteredLesions = sortedLesions.filter((lesion) =>
              options.filter ? options.filter(lesion, taskId) : lesions
            );
            return <LesionsListContainer lesions={filteredLesions} options={options} key={index} />;
          })}
          {includeOtherLesions && (
            <LesionsListContainer
              lesions={sortedLesions.filter((lesion) =>
                otherLesionsOptions.filter ? otherLesionsOptions.filter(lesion, taskId) : lesions
              )}
              options={otherLesionsOptions}
            />
          )}
          {showPredictedRois && (
            <LesionsListContainer
              lesions={sortedLesions.filter((lesion) =>
                predictedLesionsOptions.filter
                  ? predictedLesionsOptions.filter(lesion, taskId)
                  : lesions
              )}
              options={predictedLesionsOptions}
            />
          )}
        </AnnotationListsWrapper>
      </MaskContextProvider>
    </>
  );
}
