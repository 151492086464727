import { createSelector } from "@reduxjs/toolkit";
import { OutputSelector } from "reselect";

import { RootState } from "../store";

type FilterSelectedSelectorType = OutputSelector<
  [(state: RootState) => boolean],
  boolean,
  (res: boolean) => boolean
>;

export class ProjectFilterSelectors<T> {
  private readonly initialState: T;
  private readonly clearState: T;
  private readonly getState: (state: RootState) => T;
  private readonly allSelectedSelector: FilterSelectedSelectorType;
  private readonly noneSelectedSelector: FilterSelectedSelectorType;

  constructor(initialState: T, clearState: T, getState: (state: RootState) => T) {
    this.initialState = initialState;
    this.clearState = clearState;
    this.getState = getState;

    const allSelected = (state: RootState): boolean =>
      JSON.stringify(this.getState(state)) === JSON.stringify(this.initialState);

    this.allSelectedSelector = createSelector([allSelected], (allSelected) => allSelected);

    const noneSelected = (state: RootState): boolean =>
      JSON.stringify(this.getState(state)) === JSON.stringify(this.clearState);

    this.noneSelectedSelector = createSelector([noneSelected], (noneSelected) => noneSelected);
  }

  getAllFiltersSelected(): FilterSelectedSelectorType {
    return this.allSelectedSelector;
  }

  getNoFiltersSelected(): FilterSelectedSelectorType {
    return this.noneSelectedSelector;
  }
}
