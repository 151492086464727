import Tooltip from "@mui/material/Tooltip";
import React, { useState } from "react";
import styled from "styled-components";

import { SvgIcon } from "../../../../../../common/components/icons/SvgIcon";
import { getToolbarButtonColor } from "./getToolbarButtonColor";

const Wrapper = styled.div`
  cursor: pointer;
`;

interface ToolbarNodeProps {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  value: boolean;
  onChange: () => void;
  tooltip?: string;
}

export function ToolbarCheckboxButton({
  icon,
  value,
  onChange,
  tooltip,
}: ToolbarNodeProps): JSX.Element {
  const [hover, setHover] = useState(false);

  function handleMouseOver() {
    setHover(true);
  }

  function handleMouseOut() {
    setHover(false);
  }

  const colour = getToolbarButtonColor(value, hover);

  return (
    <Tooltip title={tooltip}>
      <Wrapper onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} onClick={() => onChange()}>
        <SvgIcon icon={icon} size={20} color={colour} />
      </Wrapper>
    </Tooltip>
  );
}
