import { ApolloCache } from "@apollo/client";

import { RoiRecistEvaluationType } from "../types/RoiRecistEvaluationType";
import { getRoiCacheId } from "./getRoiCacheId";

export function updateRoiRecistEvaluationsInCache<T>(
  evaluations: RoiRecistEvaluationType[],
  cache: ApolloCache<T>
): void {
  for (const evaluation of evaluations) {
    const {
      roiId: roi_id,
      SAD: short_axis_diameter,
      LAD: long_axis_diameter,
      volume,
      SADPoints: short_axis_diameter_points,
      LADPoints: long_axis_diameter_points,
      instanceId: instance_id,
      huMin: hu_min,
      huMax: hu_max,
      huMean: hu_mean,
    } = evaluation;

    cache.modify({
      id: getRoiCacheId(roi_id, cache),
      fields: {
        roi_recist_evaluation() {
          return {
            __typename: "roi_recist_evaluations",
            roi_id,
            short_axis_diameter,
            long_axis_diameter,
            volume,
            short_axis_diameter_points,
            long_axis_diameter_points,
            instance_id,
            hu_min,
            hu_max,
            hu_mean,
          };
        },
      },
    });
  }
}
