import React from "react";
import styled from "styled-components";

import { Card } from "../common/components/cards/Card";
import { DeviationsCard } from "./DeviationsCard";
import ProjectCompletionBurndownContainer from "./ProjectCompletionBurndown/components/ProjectCompletionBurndownContainer";

const HorizontalCardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;
//TODO improve this
const HorizontalCardWrapper = styled.div`
  flex-basis: 50%;
`;

export function BurndownAndDeviationsCard(): JSX.Element {
  return (
    <HorizontalCardsWrapper>
      <HorizontalCardWrapper>
        <Card title={"Projected Completion"} content={<ProjectCompletionBurndownContainer />} />
      </HorizontalCardWrapper>
      <HorizontalCardWrapper>
        <DeviationsCard />
      </HorizontalCardWrapper>
    </HorizontalCardsWrapper>
  );
}
