import { GetTokenSilentlyOptions } from "@auth0/auth0-spa-js/src/global";

import { AUTH_SCOPE } from "./AuthProvider";
import { GetTokenType } from "./GetTokenType";
import { useGetTokenSilently } from "./useGetTokenSilently";

const hasuraAuth0Options: GetTokenSilentlyOptions = {
  audience: window._env_.REACT_APP_AUTH0_AUDIENCE,
  scope: AUTH_SCOPE,
};

export function useGetAuthToken(): GetTokenType {
  const getToken = useGetTokenSilently();
  return () => getToken(hasuraAuth0Options);
}
