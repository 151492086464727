import cornerstone from "cornerstone-core";

export default class CurrentImageIdWatcher {
  constructor() {
    this.cornerstoneElement = null;
    this.currentImage = null;
  }

  setCornerstoneElement(cornerstoneElement) {
    if (this.cornerstoneElement) {
      this.cornerstoneElement.removeEventListener(
        cornerstone.EVENTS.NEW_IMAGE,
        this.onImageRendered
      );
    }

    this.cornerstoneElement = cornerstoneElement;

    if (!cornerstoneElement) {
      return;
    }

    const targetEnabledElement = cornerstone.getEnabledElement(cornerstoneElement);
    this.currentImage = targetEnabledElement.image;

    this.cornerstoneElement.addEventListener(
      cornerstone.EVENTS.NEW_IMAGE,
      this.onImageRendered,
      false
    );
  }

  onImageRendered = (event) => {
    this.currentImage = event.detail.image;
  };

  getCurrentImageId() {
    return this.currentImage.imageId;
  }

  getCurrentImage() {
    return this.currentImage;
  }
}
