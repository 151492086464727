import React from "react";
import styled from "styled-components";

import { OK_ONLY } from "../../Dialog/DialogActionButtonsType";
import { useDialog, useDialogReturnType } from "../../Dialog/useDialog";
import { EditProjectUsers } from "../EditProjectUsers";

const Wrapper = styled.div`
  flex: 1;
  width: 57vw;
  min-width: 800px;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export function useEditProjectUsersDialog(): useDialogReturnType {
  const label = "Edit Team Members";

  const content = (
    <Wrapper>
      <EditProjectUsers />
    </Wrapper>
  );

  return useDialog({
    label,
    content,
    options: {
      showCloseButton: true,
      actionButtonOptions: {
        type: OK_ONLY,
      },
    },
  });
}
