import cornerstoneTools from "@altis-labs/cornerstone-tools";

export type CornerstoneMask = {
  labelmaps2D: unknown[];
};

export function getMasksFromCornerstone(firstImageId: string): Record<number, CornerstoneMask> {
  const {
    state: { series },
  } = cornerstoneTools.getModule("segmentation");

  const masks = series[firstImageId]?.labelmaps3D;
  if (!masks) {
    console.warn("No masks found for this image");
    return [];
  }
  return masks;
}
