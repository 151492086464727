import React from "react";
import { ValueType } from "react-select";
import CreatableSelect from "react-select/creatable";
import { NamedProps } from "react-select/src/Select";

import { UserType } from "../../types/UserType";
import { getSelectStyle } from "../input/getSelectStyle";
import { UserSelectOptionType } from "./Dialogs/UserSelectOptionType";
import { UserLabel } from "./UserLabel";

interface UserSelectProps extends NamedProps<UserSelectOptionType, true> {
  users: UserType[];
  selectedUsers: UserType[];
  setSelectedUsers: (users: UserType[]) => void;
  isCreatable: boolean;
}

export function UserSelect({
  users,
  selectedUsers,
  setSelectedUsers,
  isCreatable,
  ...selectProps
}: UserSelectProps): JSX.Element {
  const userSelectStyle = getSelectStyle<UserSelectOptionType, true>();

  const options = users.map(getUserSelectOptionType);
  const value = selectedUsers.map(getUserSelectOptionType);

  const handleCreateOption = (value: string) => {
    if (!validateEmail(value)) {
      return;
    }
    const newUser: UserType = {
      id: 0,
      email: value,
      globalRoles: [],
      projectRoles: undefined,
      suspended: false,
    };
    setSelectedUsers([...selectedUsers, newUser]);
  };

  const handleSelectedUsersChanged = (value: ValueType<UserSelectOptionType, true>) => {
    const users = value
      // @ts-ignore
      .filter((option) => !option.__isNew__)
      .map((option) => option.value);
    setSelectedUsers(users);
  };

  const formatUserOptionLabel = (option: UserSelectOptionType) => {
    const { value: user } = option;

    //we have to ignore the following lines since this is how react-select new options work
    // @ts-ignore
    if (option.__isNew__) {
      // @ts-ignore
      return isCreatable ? `Invite: ${user as string}` : "No options";
    }

    return <UserLabel user={user} />;
  };

  const formatCreateUserLabel = (input: string) => {
    return <>Invite: {input}</>;
  };

  const noOptionsMessage = isCreatable
    ? "Enter a valid email address to invite a new user"
    : "No options";

  return (
    <CreatableSelect
      styles={userSelectStyle}
      {...selectProps}
      id={"users"}
      autoFocus
      isMulti
      isDisabled={false}
      isLoading={false}
      isClearable={true}
      isSearchable={true}
      options={options}
      value={value}
      noOptionsMessage={() => noOptionsMessage}
      onCreateOption={isCreatable ? handleCreateOption : undefined}
      onChange={handleSelectedUsersChanged}
      menuPortalTarget={document.body}
      isValidNewOption={(userInput) => validateEmail(userInput)}
      formatCreateLabel={isCreatable ? formatCreateUserLabel : undefined}
      formatOptionLabel={formatUserOptionLabel}
    />
  );
}

function validateEmail(email: string): boolean {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function getUserSelectOptionType(user: UserType): UserSelectOptionType {
  const { email } = user;
  return {
    value: user,
    label: email,
  };
}
