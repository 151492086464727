import { useApolloClient } from "@apollo/client";
import { stringify } from "flatted";
import { useContext } from "react";

import { useCurrentUser } from "../../../../../../../../common/contexts/UserContext/useCurrentUser";
import { ROI } from "../../../../../../../../cornerstone/ToolType";
import { ContourContext } from "../../../../../../../ContourContext";
import { CONTOUR_VERSION } from "../../../../../../../enums/contourVersion";
import { ContourToolDataType } from "../../../../../../../types/ContourToolDataType";
import { useGetMeasurementsDisabledForRoi } from "../../../../AnnotationPanel/hooks/timepointOptions/useGetMeasurementsDisabledForRoi";
import { useTumourBurdenChanged } from "../../../../AnnotationPanel/hooks/useTumourBurdenChanged";
import { getKeyContourDiameters } from "../../../../AnnotationPanel/Stats/utils/getKeyContourDiameters";
import { useUpdateTaskInProgress } from "../../../../hooks/useUpdateTaskInProgress";
import { getInstanceIdFromImageIdFromCache } from "../../../selection/getInstanceIdFromImageIdFromCache";
import { useSelectedLesion } from "../../../selection/useSelectedLesion";
import { NEW_FINDING_ROI_ID } from "../../toolController/NewFindingRoiId";
import { ContourCallbackDataType } from "../types/ContourCallbackDataType";
import { cleanContourToolData } from "../utils/cleanContourToolData";
import { getContourExistsInCache } from "../utils/getContourExistsInCache";
import { useContourMeasurements } from "./useContourMeasurements";
import { useDeleteContour } from "./useDeleteContour";
import { useHandleNewRoiDrawn } from "./useHandleNewRoiDrawn";
import { useInsertContour } from "./useInsertContour";
import { useUpdateContour } from "./useUpdateContour";

type ReturnType = {
  onContourChanged: (data: ContourCallbackDataType) => Promise<void>;
};

const actions = ["CREATE", "UPDATE", "DELETE"] as const;
type ActionType = (typeof actions)[number];

export function useContourChanged(seriesId: number | undefined): ReturnType {
  const { cache } = useApolloClient();
  const selectedLesion = useSelectedLesion();
  const [insertContour] = useInsertContour();
  const [updateContour] = useUpdateContour();
  const [deleteContour] = useDeleteContour();
  const applyMeasurements = useContourMeasurements();
  const { id: userId } = useCurrentUser();

  const { fireCallbacks } = useContext(ContourContext);
  const handleNewRoiDrawn = useHandleNewRoiDrawn(ROI);
  const { onTumourBurdenChanged } = useTumourBurdenChanged();
  const getMeasurementsDisabledForRoi = useGetMeasurementsDisabledForRoi();
  const updateTaskInProgress = useUpdateTaskInProgress();

  const onContourChanged = async (data: ContourCallbackDataType) => {
    const { imageId, roiId: callbackRoiId, toolData: callbackToolData } = data;
    let roiId = callbackRoiId;
    fireCallbacks(roiId);

    if (seriesId === undefined || !selectedLesion) {
      return;
    }
    let toolData = cleanContourToolData(callbackToolData);

    const isContourEmpty = toolData.length === 0;

    if (callbackRoiId === NEW_FINDING_ROI_ID && !isContourEmpty) {
      const { roiId: newRoiId, toolData: updatedToolData } = await handleNewRoiDrawn(
        data,
        selectedLesion,
        seriesId
      );

      roiId = newRoiId;
      toolData = updatedToolData as ContourToolDataType[];
    }

    const roi = selectedLesion.rois.find(({ id }) => id === roiId);

    const disableMeasurements = getMeasurementsDisabledForRoi(selectedLesion, roi);

    const contourDiametersData = getKeyContourDiameters(roiId);

    const roiRecistEvaluation = applyMeasurements(
      roiId,
      seriesId,
      contourDiametersData,
      disableMeasurements
    );

    const instanceId = getInstanceIdFromImageIdFromCache(seriesId, imageId, cache);

    if (instanceId === null) {
      throw new Error(`Unable to find instanceId for imageId: ${imageId}`);
    }

    // this is known to fail periodically causing errors
    // although additional error handling/mechanisms were added upstream
    // this is likely still problematic with respect to issues reported around contouring
    const contourExists = getContourExistsInCache(seriesId, roiId, instanceId, cache);

    const variables = {
      instanceId,
      roiId,
      roiRecistEvaluations: [roiRecistEvaluation],
    };

    const mutationStartedTime = performance.now();

    const actionHandlers: Record<ActionType, () => Promise<void>> = {
      CREATE: async () => {
        const flatToolData = JSON.parse(stringify(toolData));
        await insertContour({
          variables: {
            ...variables,
            toolData: flatToolData,
            version: CONTOUR_VERSION,
            createdBy: userId,
          },
        });
        await updateTaskInProgress();
      },
      DELETE: async () => {
        await deleteContour({ variables });
      },
      UPDATE: async () => {
        const flatToolData = JSON.parse(stringify(toolData));
        await updateContour({
          variables: {
            ...variables,
            toolData: flatToolData,
            version: CONTOUR_VERSION,
          },
        });
      },
    };

    const action = getAction(isContourEmpty, contourExists);
    if (!action) {
      return;
    }

    await actionHandlers[action]();

    const mutationCompletedTime = performance.now();
    console.debug(
      `Contour mutation completed in: ${mutationCompletedTime - mutationStartedTime} ms`
    );

    await onTumourBurdenChanged(selectedLesion.id);
  };

  return { onContourChanged };
}

function getAction(isContourEmpty: boolean, contourExists: boolean): ActionType | undefined {
  if (isContourEmpty) {
    return contourExists ? "DELETE" : undefined;
  }

  return contourExists ? "UPDATE" : "CREATE";
}
