import { MEASURE } from "../../../../../../cornerstone/ToolType";
import { InstanceType } from "../../../../../types/InstanceType";
import { getCornerstonePointsData } from "../../ViewerPanel/tools/contour/utils/getCornerstonePointsData";
import {
  appendToolSliceData,
  clearNonReadOnlyToolSliceData,
} from "../../ViewerPanel/tools/legacy/ToolUtils";
import { ToolDataLesionFragmentType } from "../hooks/useLazyTaskToolData";

export function loadMeasurements(
  lesions: ToolDataLesionFragmentType[],
  instances: InstanceType[]
): void {
  clearNonReadOnlyToolSliceData(MEASURE);
  lesions.forEach(({ rois }) => {
    rois.forEach(({ roiRecistEvaluation }) => {
      if (!roiRecistEvaluation) {
        return;
      }
      const { instanceId, LADPoints, SADPoints } = roiRecistEvaluation;
      const { wadoUrl } = instances.find(({ id }) => id === instanceId) || {};
      if (!wadoUrl) {
        console.warn("wadoUrl not found for roi recist evaluations instanceId");
        return;
      }
      if (!LADPoints || !SADPoints) {
        return;
      }
      const LADPointData = getCornerstonePointsData(LADPoints);
      const SADPointData = getCornerstonePointsData(SADPoints);
      appendToolSliceData(MEASURE, wadoUrl, [LADPointData]);
      appendToolSliceData(MEASURE, wadoUrl, [SADPointData]);
    });
  });
}
