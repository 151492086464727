import dayjs from "dayjs";
import React, { useContext, useMemo, useState } from "react";
import styled from "styled-components";

import DisplayRadioButton from "../../../Analysis/common/components/DisplayRadioButton";
import { IconButton } from "../../../Annotate/components/Annotate/page/AnnotationPanel/IconButton";
import {
  TASK_COMPLETED,
  TASK_IN_PROGRESS,
  TASK_PENDING,
} from "../../../Annotate/components/TaskWorklist/TaskProgressType";
import { ReactComponent as ResetIcon } from "../../../assets/svgs/Reset.svg";
import { Card } from "../../../common/components/cards/Card";
import { SvgIcon } from "../../../common/components/icons/SvgIcon";
import { InputButton } from "../../../common/components/input/InputButton";
import { UserLabel } from "../../../common/components/UserManagement/UserLabel";
import { formatUserDisplayName } from "../../../common/components/UserManagement/utils/formatUserDisplayName";
import { main } from "../../../common/theme/main";
import { UserType } from "../../../common/types/UserType";
import { DateFilterType, DEFAULT_FILTER } from "../../../common/utils/dateFormatUtils/dateFilters";
import { DayjsDateRange } from "../../../common/utils/dateFormatUtils/DayjsDateRange";
import { formatRange } from "../../../common/utils/dateFormatUtils/formatRange";
import handleApolloError from "../../../common/utils/handleApolloError";
import { GenericMultiSelect } from "../../../DataManagement/Upload/components/GenericMultiSelect";
import { ProjectContext } from "../../../Project/contexts/ProjectContext";
import { useProjectId } from "../../../Project/hooks/useProjectId";
import { Data, useProjectLabels } from "../hooks/useProjectLabels";
import { buildTasksPerAnnotatorData } from "../utils/buildTasksPerAnnotatorData";
import { getEarliestTaskDate } from "../utils/getEarliestTaskDate";
import { ChartLoadingWrapper } from "./ChartLoadingWrapper";
import { DownloadChartDataButton } from "./DownloadChartDataButton";
import { Filter } from "./Filter";
import { TasksPerAnnotatorChart } from "./TasksPerAnnotatorChart";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-height: 700px;
`;

const FilterBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const SelectWrapper = styled.div`
  width: 350px;
  display: flex;
  flex-direction: row;
  gap: 6px;
`;

const FilterWrapper = styled.div`
  flex: 2;
  display: flex;
  flex-direction: row;
  justify-content: right;
`;

const Separator = styled.div`
  margin-right: 6px;
  margin-left: 6px;
  color: ${(props) => props.theme.colors.neutral.neutral6};
`;

export function TasksPerAnnotatorCard(): JSX.Element {
  const projectId = useProjectId();

  const { data, loading, error, refetch } = useProjectLabels(projectId);

  const { users } = useContext(ProjectContext);

  const [selectedUsers, setSelectedUsers] = useState<UserType[]>(
    users.length > 0 ? [users[0]] : []
  );

  const [groupByMethod, setGroupByMethod] = useState<DataGroupByType>(DEFAULT_GROUP_BY_METHOD);

  const [timeFilter, setTimeFilter] = useState<DateFilterType>(DEFAULT_FILTER);

  const [selectedDateRange, setSelectedDateRange] = useState<DayjsDateRange>({
    from: dayjs(),
    to: dayjs(),
  });

  const earliestTaskDate = useMemo(
    () => getEarliestTaskDate(data?.patients ?? [], true) ?? dayjs(),
    [data]
  );

  const handleRefreshClicked = async () => {
    await refetch();
  };

  const handleClickGroupByMethod = (groupByMethod: DataGroupByType) => {
    setGroupByMethod(groupByMethod);
  };

  if (error) handleApolloError(error);

  if (!loading && !data) {
    throw new Error("Data is null with no Apollo error");
  }

  const tasksPerAnnotatorData =
    !loading && data && selectedUsers.length >= 1
      ? buildTasksPerAnnotatorData(
          data,
          selectedUsers.map(({ id }) => id),
          selectedDateRange,
          groupByMethod
        )
      : [];

  const selectedFilename =
    selectedUsers.length === 1
      ? `tasks-per-annotator-user-${selectedUsers[0].email}-${formatRange(selectedDateRange)}.csv`
      : `tasks-per-annotator-${formatRange(selectedDateRange)}.csv`;

  return (
    <Card
      title={"Tasks Per Annotator"}
      content={
        <Wrapper>
          <FilterBarWrapper>
            <SelectWrapper>
              <GenericMultiSelect
                values={users}
                selected={selectedUsers}
                onSelectedChanged={setSelectedUsers}
                getOption={(user) => ({
                  value: user,
                  label: <UserLabel user={user} />,
                })}
                isLoading={loading}
                isDisabled={loading}
              />
              <div>
                <InputButton
                  type={"button"}
                  onClick={() => {
                    setSelectedUsers(users);
                  }}
                  value={"Select All"}
                  background={main.colors.neutral.white}
                  color={main.colors.neutral.black}
                  width={71}
                  height={42}
                />
              </div>
            </SelectWrapper>

            <FilterWrapper>
              {dataGroupByMethods.map((groupBy) => {
                return (
                  <DisplayRadioButton
                    active={groupBy === groupByMethod}
                    key={groupBy}
                    onClick={() => handleClickGroupByMethod(groupBy)}
                  >
                    {dataGroupByDisplayName[groupBy]}
                  </DisplayRadioButton>
                );
              })}
              <Separator>|</Separator>
              <Filter
                filterMode={timeFilter}
                onSelectFilterMode={setTimeFilter}
                selectedRange={selectedDateRange}
                onSetSelectedRange={setSelectedDateRange}
                earliestDate={earliestTaskDate}
                disabled={loading}
              />
            </FilterWrapper>
          </FilterBarWrapper>
          <ChartLoadingWrapper loading={loading} data={tasksPerAnnotatorData}>
            <TasksPerAnnotatorChart data={tasksPerAnnotatorData} />
          </ChartLoadingWrapper>
        </Wrapper>
      }
      actionButtons={
        <>
          <DownloadChartDataButton
            entries={[
              {
                name: "Selected Annotators",
                filename: selectedFilename,
                data: dataToCSV(data, users, selectedUsers, selectedDateRange, groupByMethod),
              },
              {
                name: "All Annotators",
                filename: `tasks-per-annotator-all-${formatRange(selectedDateRange)}.csv`,
                data: dataToCSV(data, users, [], selectedDateRange, groupByMethod),
              },
            ]}
          />
          <IconButton onClick={handleRefreshClicked}>
            <SvgIcon icon={ResetIcon} size={20} />
          </IconButton>
        </>
      }
    />
  );
}

function dataToCSV(
  data: Data | undefined,
  users: UserType[],
  selectedUsers: UserType[],
  range: DayjsDateRange,
  groupByMethod: DataGroupByType
): string[][] {
  const groupByName = dataGroupByDisplayName[groupByMethod];
  const csvData = [
    [
      "Annotator",
      "Task",
      `Pending Task Count (by ${groupByName})`,
      `In Progress Task Count (by ${groupByName})`,
      `Completed Task Count (by ${groupByName})`,
    ],
  ];

  if (!data) {
    return csvData;
  }

  const exportedUsers = selectedUsers.length > 0 ? selectedUsers : users;

  for (const user of exportedUsers) {
    const parsedData = buildTasksPerAnnotatorData(data, [user.id], range, groupByMethod);

    for (const entry of parsedData) {
      csvData.push([
        formatUserDisplayName(user),
        entry.task,
        entry[TASK_PENDING].toString(),
        entry[TASK_IN_PROGRESS].toString(),
        entry[TASK_COMPLETED].toString(),
      ]);
    }
  }
  return csvData;
}

const dataGroupByMethods = ["PATIENT", "SERIES"] as const;
const DEFAULT_GROUP_BY_METHOD = "PATIENT";
export type DataGroupByType = typeof dataGroupByMethods[number];

const dataGroupByDisplayName: Record<DataGroupByType, string> = {
  PATIENT: "Subject",
  SERIES: "Series",
};
