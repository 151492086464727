import { RoutePathSingleOrArray } from "../Layout";
import { useGoToRoute } from "./useGoToRoute";

export interface LinkToRouteProps {
  linkToRoute?: RoutePathSingleOrArray;
}

export function useProjectPageLinkHandler(
  linkToRoute: RoutePathSingleOrArray | undefined
): (() => void) | undefined {
  const goToProjectPath = useGoToRoute();

  if (!linkToRoute) {
    return undefined;
  }

  return () => {
    if (!linkToRoute) {
      throw new Error("linkToRoute is undefined but used in handleClick");
    }

    goToProjectPath(linkToRoute);
  };
}
