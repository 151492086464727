import { useApolloClient } from "@apollo/client";
import React, { useContext } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { FlexLoading } from "../../../../../common/components/Loading";
import {
  allowedOrgansSelector,
  allowLesionsSelector,
} from "../../../../../common/store/annotatePage/taskSelector";
import { ANNOTATION_SCOPE } from "../../../../../Project/Layout/Layout";
import { TaskContext } from "../../../../TaskContext";
import { LESION, ORGAN } from "../../../../types/LesionTypeType";
import { AnatomicalStructuresFragmentType } from "../../../AnatomicalStructuresFragment";
import { LESION_STRUCTURE_NAME } from "../../../isAnatomicalStructureLesion";
import { useUpdateTaskInProgress } from "../hooks/useUpdateTaskInProgress";
import {
  getNewLabelNameFromCache,
  getNewLesionNameFromCache,
} from "./hooks/getNewLesionNameFromCache";
import { useCreateLesion } from "./hooks/useCreateLesion";
import { useLesionListQueryInput } from "./hooks/useLesionListQueryInput";
import { useMissingOrgans } from "./hooks/useMissingOrgans";
import { NewLabelButton } from "./NewLabelButton";

const Wrapper = styled.div`
  padding: 3px 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 6px;
`;

export function RequiredLesionsList(): JSX.Element {
  const lesionListQueryInput = useLesionListQueryInput();

  const { viewOnly } = useContext(TaskContext);

  const allowedOrgans = useSelector(allowedOrgansSelector);
  const allowLesions = useSelector(allowLesionsSelector);

  const updateTaskInProgress = useUpdateTaskInProgress();

  const { missingOrgans, loading: missingOrgansLoading } = useMissingOrgans([
    ...allowedOrgans,
    LESION_STRUCTURE_NAME,
  ]);

  const { createLesion, loading: createLesionLoading } = useCreateLesion();

  const { cache } = useApolloClient();

  const handleAddLabel = async (anatomicalStructure: AnatomicalStructuresFragmentType) => {
    if (createLesionLoading) {
      throw new Error("User clicked add lesion/organ while a lesion/organ was being inserted.");
    }

    const { id: anatomicalStructureId, name: baseName } = anatomicalStructure;

    const name = getNewLabelNameFromCache(
      lesionListQueryInput,
      anatomicalStructureId,
      baseName,
      cache
    );
    await createLesion({ anatomicalStructureId, name, type: ORGAN });
    await updateTaskInProgress();
  };

  const handleAddLesion = async () => {
    if (createLesionLoading) {
      throw new Error("User clicked add lesion/organ while a lesion/organ was being inserted.");
    }

    const lesionAnatomicalStructure = missingOrgans?.find(
      ({ structure }) => structure === LESION_STRUCTURE_NAME
    );

    if (!lesionAnatomicalStructure) {
      throw new Error(
        `Unable to find lesion anatomical structure (structure: ${LESION_STRUCTURE_NAME}. Ensure its present in the database`
      );
    }

    const { id: anatomicalStructureId } = lesionAnatomicalStructure;

    const name = getNewLesionNameFromCache(lesionListQueryInput, cache);
    await createLesion({ anatomicalStructureId, name, type: LESION });
    await updateTaskInProgress();
  };

  const requiredOrgans =
    missingOrgans?.filter(({ structure }) => structure !== LESION_STRUCTURE_NAME) ?? [];

  useHotkeys(
    "n",
    () => {
      handleAddLesion();
    },
    { scopes: [ANNOTATION_SCOPE] }
  );

  if (missingOrgansLoading) {
    return <FlexLoading />;
  }

  if (viewOnly) {
    return <></>;
  }

  return (
    <Wrapper>
      {allowLesions && (
        <NewLabelButton
          onClick={handleAddLesion}
          text={"Lesion"}
          loading={createLesionLoading}
          tooltip={"Add New Lesion (N)"}
        />
      )}
      {requiredOrgans.map((anatomicalStructure, index) => {
        const { name } = anatomicalStructure;
        return (
          <NewLabelButton
            key={index}
            onClick={() => handleAddLabel(anatomicalStructure)}
            text={name}
            loading={createLesionLoading}
          />
        );
      })}
    </Wrapper>
  );
}
