import React from "react";

import { getRadiusMode } from "../svgUtils/getRadiusMode";
import { getRectPath } from "../svgUtils/getRectPath";
import { RadiusMode } from "../svgUtils/RadiusMode";
import { BaseBarType } from "./BaseBarType";

interface BarShapeProps<T extends BaseBarType> {
  fill: string;
  x: number;
  y: number;
  width: number;
  height: number;
  payload: T;
}

function getBarShapeRadiusMode<T extends BaseBarType>(
  payload: T,
  keys: (keyof T)[],
  key: keyof T
): RadiusMode {
  const counts = keys.map((key) => payload[key] ?? 0);

  const keyIndex = keys.indexOf(key);
  if (keyIndex < 0) {
    throw new Error(`Unable to get bar height ${String(key)}`);
  }

  const roundBottom = counts.slice(0, keyIndex).reduce((partialSum, a) => partialSum + a, 0) === 0;

  const roundTop = counts.slice(keyIndex + 1).reduce((partialSum, a) => partialSum + a, 0) === 0;

  return getRadiusMode(roundTop, roundBottom);
}

export function getBarShape<T extends BaseBarType>(
  { fill, x, y, width, height, payload }: BarShapeProps<T>,
  keys: (keyof T)[],
  key: keyof T
) {
  const radiusMode = getBarShapeRadiusMode(payload, keys, key);

  const path = getRectPath(x, y, width, height, radiusMode);

  if (!path) {
    return <></>;
  }

  return <path d={path} fill={fill} />;
}
