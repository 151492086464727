import React, { forwardRef, useContext, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";

import { ActionButtonsWrapper } from "../../../../../common/components/Dialog/Form/ActionButtonsWrapper";
import {
  InnerButtonWrapper,
  InputButton,
  InputDiv,
} from "../../../../../common/components/input/InputButton";
import { FlexLoading } from "../../../../../common/components/Loading";
import { SiteTableRowType } from "../../../../../common/components/SiteManagement/SiteTableRowType";
import { Table, TableProps } from "../../../../../common/components/Table/Table";
import { TableControlsType } from "../../../../../common/components/Table/TableControlsType";
import { UserSelect } from "../../../../../common/components/UserManagement/UserSelect";
import { main } from "../../../../../common/theme/main";
import { UpdateModeType } from "../../../../../common/types/UpdateModeType";
import { UserType } from "../../../../../common/types/UserType";
import { ProjectContext } from "../../../../../Project/contexts/ProjectContext";
import { PatientTaskTableRowType } from "../types/PatientTaskTableRowType";
import { ConfirmTaskAssignmentsRowType } from "./ConfirmTaskAssignmentsRowType";
import { getColumns } from "./getColumns";

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  box-sizing: border-box;
`;

const SelectLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  font-size: 14px;
`;

const SelectWrapper = styled.div`
  flex: 1;
`;

const TableWrapper = styled.div`
  border-radius: 6px;
  border: ${({ theme }) => theme.colors.neutral.neutral4} 1px solid;
  flex: 1;
  overflow: hidden;
`;

export interface ConfirmTaskAssignmentFormProps {
  updateMode: UpdateModeType;
  patientTableRows: PatientTaskTableRowType[];
  initialSelectedUsers: UserType[];
  loading: boolean;
  onConfirm: (rows: ConfirmTaskAssignmentsRowType[], updateMode: UpdateModeType) => Promise<void>;
  onCancel: () => void;
}

export function ConfirmTaskAssignmentForm({
  updateMode,
  patientTableRows,
  initialSelectedUsers,
  loading,
  onConfirm,
  onCancel,
}: ConfirmTaskAssignmentFormProps): JSX.Element {
  const ref = useRef<TableControlsType<SiteTableRowType>>(null);
  const { users = [] } = useContext(ProjectContext);

  const [selectedUsers, setSelectedUsers] = useState<UserType[]>(initialSelectedUsers);

  useEffect(() => {
    setSelectedUsers(initialSelectedUsers);
  }, [initialSelectedUsers]);

  const columns = useMemo(() => getColumns(updateMode), [updateMode]);

  const rows: ConfirmTaskAssignmentsRowType[] = useMemo(
    () =>
      patientTableRows.map((row) => ({
        ...row,
        users: selectedUsers,
      })),
    [patientTableRows, selectedUsers]
  );

  const handleConfirm = async () => {
    await onConfirm(rows, updateMode);
  };

  return (
    <Wrapper>
      <SelectLabelWrapper>
        Users:
        <SelectWrapper>
          <UserSelect
            users={users}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
            isCreatable={false}
          />
        </SelectWrapper>
      </SelectLabelWrapper>
      <TableWrapper>
        <Table
          ref={ref}
          columns={columns}
          data={rows}
          enableSelect={false}
          showFilterBar={false}
          minTableHeight={200}
        />
      </TableWrapper>
      <ActionButtonsWrapper>
        <InputButton
          type="button"
          name="cancel-button"
          value={"Cancel"}
          background={main.colors.neutral.white}
          color={main.colors.neutral.black}
          onClick={onCancel}
          disabled={loading}
        />
        <InputDiv
          type="submit"
          color={main.colors.neutral.white}
          background={main.colors.neutral.black}
          width={84}
          onClick={handleConfirm}
          disabled={loading}
        >
          <InnerButtonWrapper>
            {loading && <FlexLoading color={main.colors.neutral.white} />}
            Confirm
          </InnerButtonWrapper>
        </InputDiv>
      </ActionButtonsWrapper>
    </Wrapper>
  );
}

const TableWithRef = forwardRef<
  TableControlsType<ConfirmTaskAssignmentsRowType>,
  TableProps<ConfirmTaskAssignmentsRowType>
>((props, ref) => {
  return <Table {...props} forwardedRef={ref} />;
});
TableWithRef.displayName = "TableWithRef";
