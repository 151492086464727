import { useContext } from "react";
import { useSelector } from "react-redux";

import { selectedLesionIdSelector } from "../../../../../../../../common/store/annotatePage/selectionSelector";
import { ViewerConfigType } from "../../../../../../../../common/store/annotatePage/ViewerConfigType";
import { ContourContext } from "../../../../../../../ContourContext";
import { useSelectedViewerRoiId } from "../../../selection/useSelectedViewerRoiId";
import { useViewerViewOnly } from "../../../useViewerViewOnly";
import { useActiveTool } from "../../../Viewer/hooks/useActiveTool";
import { useGetViewerImageId } from "../../../Viewer/hooks/useGetViewerImageId";
import { canPasteContour } from "../utils/canPasteContour";

type ReturnType = {
  canPaste: () => boolean;
};

export function useViewerCanPasteContour(viewerConfig: ViewerConfigType): ReturnType {
  const { contourClipboardData } = useContext(ContourContext);
  const selectedLesionId = useSelector(selectedLesionIdSelector);
  const selectedRoiId = useSelectedViewerRoiId(viewerConfig);
  const { activeTool } = useActiveTool();
  const viewOnly = useViewerViewOnly(viewerConfig);
  const imageId = useGetViewerImageId(viewerConfig.id);

  const canPaste = () => {
    if (
      !contourClipboardData ||
      selectedLesionId === null ||
      selectedRoiId === null ||
      imageId === null ||
      viewOnly
    ) {
      return false;
    }

    return canPasteContour(
      contourClipboardData,
      selectedLesionId,
      selectedRoiId,
      imageId,
      activeTool
    );
  };
  return { canPaste };
}
