import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { taskToolsSelector } from "../../../../../../../../common/store/annotatePage/taskSelector";
import { ELLIPTICAL_ROI } from "../../../../../../../../cornerstone/ToolType";
import { OBJECT_DETECTION } from "../../../../../../../enums/TaskDescriptionEnums";
import { ToolRoiType } from "../../../../../../../types/ToolRoiType";
import { useTrySelectLesionId } from "../../../../AnnotationPanel/hooks/useTrySelectLesionId";
import { useSetActiveTool } from "../../../Viewer/hooks/useSetActiveTool";
import { ToolControllerType } from "../../toolController/ToolControllerType";

export function useEllipseDoubleClicked(toolController: ToolControllerType | null): void {
  const toolControllerRef = useRef<ToolControllerType>();

  const setActiveTool = useSetActiveTool();

  const availableTools = useSelector(taskToolsSelector);

  const trySelectLesionId = useTrySelectLesionId();

  const handleEllipseDoubleClicked = ({ lesionId }: ToolRoiType) => {
    const onLesionChanged = () => {
      if (availableTools.includes(OBJECT_DETECTION)) {
        setActiveTool(ELLIPTICAL_ROI);
      }
    };

    if (!trySelectLesionId(lesionId, onLesionChanged)) {
      return;
    }
  };

  useEffect(() => {
    toolController?.setOnEllipseDoubleClickedCallback(handleEllipseDoubleClicked);
    return () => {
      const toolController = toolControllerRef.current;
      toolController?.setOnEllipseDoubleClickedCallback(null);
    };
  }, [toolController, handleEllipseDoubleClicked]);
}
