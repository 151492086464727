import { useSelector } from "react-redux";

import { lesionsClassificationsDefinitionsSelector } from "../../../../../../../../../common/store/annotatePage/classificationsSelector";
import { ClassificationsDefinitionsType } from "../../../../../../../../../common/store/annotatePage/classificationsSlice";
import { ERROR, SUCCESS } from "../../../../../../../../../common/types/StatusTypes";
import { CHECK_MISSING_CLASSIFICATIONS } from "../../../../../../../../enums/TaskDescriptionEnums";
import { TimepointOptionsType } from "../../../../../../../../types/TaskDescriptionType";
import { LesionListItemFragmentType } from "../../../../fragments/LesionListItemFragment";
import { RoiListItemFragmentType } from "../../../../fragments/RoiListItemFragment";
import { useTimepointsNonViewOnlySeriesIds } from "../../../../hooks/Timepoint/useTimepointsNonViewOnlySeriesIds";
import { useGetExistingAvailableRoiClassifications } from "../../../../hooks/timepointOptions/useGetExistingAvailableRoiClassifications";
import { areClassificationsComplete } from "../../../../utils/areClassificationsComplete";
import { CHECK_RESULT_LESION_LEVEL } from "../../types/SaveCheckResultType";
import { UseLesionCheckReturnType } from "../../types/UseTaskCheckReturnType";

export type MissingClassificationsCheckResultType = {
  lesionId: number;
  roiIds: number[];
} | null;

export function useMissingClassificationsCheck(): UseLesionCheckReturnType<MissingClassificationsCheckResultType> {
  const lesionClassificationsDefinitions = useSelector(lesionsClassificationsDefinitionsSelector);

  const seriesIds = useTimepointsNonViewOnlySeriesIds();

  const getAvailableOptions = useGetExistingAvailableRoiClassifications();

  return ({ lesion }) => {
    const result = getResult(
      lesion,
      lesionClassificationsDefinitions,
      seriesIds,
      getAvailableOptions
    );

    const status = !result ? SUCCESS : ERROR;

    const successMessage = "All label/timepoint classifications are complete";
    const errorMessage = "Missing classification(s)";

    const message = status === SUCCESS ? successMessage : errorMessage;

    return {
      type: CHECK_MISSING_CLASSIFICATIONS,
      message,
      status,
      required: true,
      level: CHECK_RESULT_LESION_LEVEL,
      showOnSwitch: true,
      result,
    };
  };
}

function getResult(
  lesion: LesionListItemFragmentType,
  lesionClassificationsDefinitions: Record<number, ClassificationsDefinitionsType>,
  seriesIds: number[] | undefined,
  getAvailableOptions: (
    lesion: LesionListItemFragmentType,
    roi: RoiListItemFragmentType
  ) => {
    setOptions: TimepointOptionsType[];
    unsetOptions: TimepointOptionsType[];
  }
) {
  if (!seriesIds) {
    return null;
  }

  const { id: lesionId, rois } = lesion;

  const lesionClassificationsDefinition = lesionClassificationsDefinitions[lesionId];

  let lesionComplete = true;

  if (lesionClassificationsDefinition) {
    const { classificationsMapProxy, useAnatomySelector, editing } =
      lesionClassificationsDefinition;
    lesionComplete =
      !editing && areClassificationsComplete(classificationsMapProxy, useAnatomySelector);
  }

  const roiIdsWithMissingClassifications: number[] = [];
  const nonViewOnlyRois = rois.filter(({ series: { id: seriesId } }) =>
    seriesIds.includes(seriesId)
  );
  for (const roi of nonViewOnlyRois) {
    const { id: roiId } = roi;

    const { unsetOptions } = getAvailableOptions(lesion, roi);
    const hasUnsetOptions = unsetOptions.length > 0;
    const roiComplete = !hasUnsetOptions;

    if (!roiComplete) {
      roiIdsWithMissingClassifications.push(roiId);
    }
  }

  if (!lesionComplete || roiIdsWithMissingClassifications.length > 0) {
    return {
      lesionId,
      roiIds: roiIdsWithMissingClassifications,
    };
  }

  return null;
}
