import React, { FC, useContext } from "react";

import { ProjectContext } from "../../../Project/contexts/ProjectContext";
import { useGoToProject } from "../../../Project/Layout/hooks/useGoToProject";
import { useCurrentUser } from "../../contexts/UserContext/useCurrentUser";
import { useAllUsersProjects } from "../../queries/useAllUsersProjects";
import handleApolloError from "../../utils/handleApolloError";
import { GenericSelector } from "./GenericSelector";

export const ProjectSelectorContainer: FC = () => {
  const goToProject = useGoToProject();
  const { disabled, project } = useContext(ProjectContext);
  const currentLegacyTrialId = project?.legacyTrialId;
  if (currentLegacyTrialId === undefined) {
    throw new Error("Legacy trial id should not be undefined here");
  }

  const { id: userId } = useCurrentUser();
  const { projects, loading, error } = useAllUsersProjects(userId);
  if (disabled) {
    return <></>;
  }

  if (error) handleApolloError(error);

  const handleProjectIdSelected = (legacyTrialId: number) => {
    if (legacyTrialId === currentLegacyTrialId) {
      return;
    }

    goToProject(legacyTrialId);
  };

  const projectDetails = projects ? projects : [];

  return (
    <GenericSelector
      items={projectDetails}
      selectedItem={currentLegacyTrialId}
      isDisabled={loading}
      isLoading={loading}
      onItemIdSelected={handleProjectIdSelected}
    />
  );
};
