import { ViewerConfigType } from "../../../../../common/store/annotatePage/ViewerConfigType";
import { useGetRoiViewOnlyClassifications } from "../AnnotationPanel/hooks/timepointOptions/useGetRoiViewOnlyClassifications";
import { useSelectedLesion } from "./selection/useSelectedLesion";
import { getRoi } from "./utils/getRoi";

export function useSelectedRoiViewOnly(viewerConfig: ViewerConfigType): boolean {
  const selectedLesion = useSelectedLesion();
  const getRoiViewOnlyClassifications = useGetRoiViewOnlyClassifications();

  if (!selectedLesion) {
    return false;
  }

  const roi = getRoi(selectedLesion, viewerConfig);
  if (!roi) {
    return false;
  }

  const viewOnlyClassifications = getRoiViewOnlyClassifications(selectedLesion, roi);

  return viewOnlyClassifications.length > 0;
}
