import React from "react";
import { Row } from "react-table";
import styled from "styled-components";

import { ReactComponent as CloseIcon } from "../../../assets/svgs/Close.svg";
import { SvgIcon } from "../icons/SvgIcon";
import { SeriesTableColumnType } from "../SeriesTable/getColumns";
import { SeriesTable } from "../SeriesTable/SeriesTable";
import { StudyTableRowType } from "./StudyTableRowType";

const Wrapper = styled.div`
  padding: 8px;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.neutral.white};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  height: auto;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
`;

const Label = styled.p`
  font-size: 14px;
  font-weight: bold;
`;

const TableWrapper = styled.div`
  flex: 1;
`;

const Button = styled.div`
  cursor: pointer;
`;

interface SubSeriesTableProps {
  tableKey: string;
  row: Row<StudyTableRowType>;
  onClose: (row: StudyTableRowType) => void;
  enableSelect?: boolean;
  hiddenColumns?: SeriesTableColumnType[];
  loading: boolean;
}

export function SubSeriesTable({
  tableKey,
  row: { original: row },
  onClose,
  enableSelect = false,
  hiddenColumns = [],
  loading,
}: SubSeriesTableProps): JSX.Element {
  const { patientDicomId, id: studyId, seriesRows } = row;

  const handleClose = () => {
    onClose(row);
  };

  return (
    <Wrapper>
      <Header>
        <Label>{patientDicomId} - Series</Label>
        <Button onClick={handleClose}>
          <SvgIcon icon={CloseIcon} size={16} />
        </Button>
      </Header>
      <TableWrapper>
        <SeriesTable
          tableKey={tableKey}
          studyId={studyId}
          series={seriesRows}
          hiddenColumns={hiddenColumns}
          enableSelect={enableSelect}
          loading={loading}
          maxHeight={500}
        />
      </TableWrapper>
    </Wrapper>
  );
}
