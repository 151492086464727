import { Column } from "react-table";
import styled from "styled-components";

import { REASON_OPTIONS } from "../../../../Annotate/components/Annotate/page/StudyPanel/CreateSeriesFlagForm";
import { areCohortsEqual } from "../../../../Annotate/components/Manage/PatientTable/filters/areCohortsEqual";
import { getCohortOption } from "../../../../Annotate/components/Manage/PatientTable/filters/getCohortOption";
import { getReasonsFromStudies } from "../../../../Annotate/components/Manage/PatientTable/utils/getReasonsFromStudies";
import { ReactComponent as Visibility } from "../../../../assets/svgs/Visibility.svg";
import { ReactComponent as VisibilityOff } from "../../../../assets/svgs/VisibilityOff.svg";
import { SvgIcon } from "../../../../common/components/icons/SvgIcon";
import { getFlagValueFromSeries } from "../../../../common/components/SeriesFlags/getFlagValueFromSeries";
import {
  areFlagsEqual,
  FLAGGED,
  FlagTypeOptions,
  NOT_FLAGGED,
  PARTIALLY_FLAGGED,
  SeriesFlag,
  seriesFlagFilterProps,
} from "../../../../common/components/SeriesFlags/SeriesFlag";
import { SeriesFlagLabel } from "../../../../common/components/SeriesFlags/SeriesFlagLabel";
import { CellProps } from "../../../../common/components/Table/CellProps";
import {
  CheckboxProps,
  getCheckboxColumn,
} from "../../../../common/components/Table/getCheckboxColumn";
import { TextCell } from "../../../../common/components/Table/TextCell";
import { CohortType } from "../../../../common/types/CohortType";
import { CohortLabel } from "../Cohort/CohortLabel";
import { DeletableCohortPatientLabel } from "../Cohort/DeletableCohortPatientLabel";
import { getFilterColumnProps } from "../Users/GenericColumnFilter";
import { PatientTableRowType } from "./PatientTableRowType";

const Button = styled.div`
  cursor: pointer;
  padding: 0 8px;
`;

function getPatientTableCheckboxColumn(
  onSelectPatient: (id: number, selected: boolean) => void
): Column<PatientTableRowType> {
  function getHeaderProps(data: PatientTableRowType[]): CheckboxProps {
    const checked = data.length > 0 && data.every(({ isSelected }) => isSelected);
    const indeterminate = !checked && data.some(({ isSelected }) => isSelected);

    return {
      checked,
      indeterminate,
      onChange: (event) => {
        const selected = (event.target as HTMLInputElement).checked;
        const patientIds = [...new Set(data.map(({ id }) => id))];
        patientIds.map((patientId) => onSelectPatient(patientId, selected));
      },
    };
  }

  function getRowProps(patientRow: PatientTableRowType): CheckboxProps {
    const checked = patientRow.isSelected;
    return {
      checked,
      onChange: (event) => {
        const selected = (event.target as HTMLInputElement).checked;
        onSelectPatient(patientRow.id, selected);
      },
    };
  }

  return getCheckboxColumn({
    getHeaderProps,
    getRowProps,
  });
}

export function usePatientTableColumns(
  onPatientViewClick: (patient: PatientTableRowType | undefined) => void,
  patientToView: PatientTableRowType | undefined,
  cohorts: CohortType[],
  cohortsLoading: boolean,
  onSelectPatient: (patientId: number, selected: boolean) => void
): Column<PatientTableRowType>[] {
  const flagValues: FlagTypeOptions[] = [FLAGGED, PARTIALLY_FLAGGED, NOT_FLAGGED];

  return [
    getPatientTableCheckboxColumn(onSelectPatient),
    {
      id: "seriesFlags",
      Header: <SeriesFlag flagSize={17} />,
      ...getFilterColumnProps(getFlagValues, areFlagsEqual, {
        values: flagValues,
        ...seriesFlagFilterProps,
      }),
      style: {
        width: "50px",
      },
    },
    {
      id: "patientDicomId",
      Header: "Subject ID",
      disableFilters: false,
      accessor: ({ patientDicomId }) => patientDicomId,
      Cell: ({ value }: { value: string }) => <TextCell value={value} />,
    },
    {
      id: "cohorts",
      Header: "Cohort(s)",
      disableSortBy: true,
      ...getFilterColumnProps(
        ({ cohorts }) => cohorts.map(({ cohort }) => cohort),
        areCohortsEqual,
        {
          values: cohorts,
          getOption: getCohortOption,
          includeUnassigned: true,
          loading: cohortsLoading,
          placeholder: "Filter Cohorts...",
          // eslint-disable-next-line react/display-name,react/prop-types
          RowLabelComponent: ({ value, row: { id } }) => (
            <DeletableCohortPatientLabel cohort={value} patientId={id} />
          ),
          // eslint-disable-next-line react/display-name,react/prop-types
          SelectLabelComponent: ({ value }) => <CohortLabel cohort={value} />,
        }
      ),
    },
    {
      id: "flagReasons",
      Header: "Flag Reasons",
      disableSortBy: true,
      ...getFilterColumnProps(
        ({ studies }) => getReasonsFromStudies(studies),
        (a, b) => a === b,
        {
          values: REASON_OPTIONS,
          getOption: (value) => ({
            value: value,
            label: value ?? "",
          }),
          includeUnassigned: true,
          placeholder: "Filter Flag Reasons...",
          // eslint-disable-next-line react/display-name,react/prop-types
          RowLabelComponent: ({ value }) => <SeriesFlagLabel reason={value} />,
          // eslint-disable-next-line react/display-name,react/prop-types
          SelectLabelComponent: ({ value }) => <SeriesFlagLabel reason={value} />,
        }
      ),
      accessor: ({ studies }) => getReasonsFromStudies(studies),
      Cell: ({ value: reasons }: { value: string[] }) => (
        <>
          {reasons.map((reason) => (
            <SeriesFlagLabel key={reason} reason={reason} />
          ))}
        </>
      ),
    },
    {
      id: "view",
      Header: "View",
      style: {
        width: "42px",
      },
      // eslint-disable-next-line react/prop-types,react/display-name
      Cell: ({ row: { original: patient } }: CellProps<PatientTableRowType>) => {
        const { id: patientId } = patient;
        const isPatientViewed = patientId === patientToView?.id;

        const handleOnClick = () => {
          onPatientViewClick(!isPatientViewed ? patient : undefined);
        };

        return (
          <Button onClick={handleOnClick}>
            <SvgIcon icon={isPatientViewed ? VisibilityOff : Visibility} size={14} />
          </Button>
        );
      },
    },
  ];
}

function getFlagValues({ studies }: PatientTableRowType): FlagTypeOptions[] {
  const allSeries = studies.flatMap(({ series }) => series);
  return getFlagValueFromSeries(allSeries);
}
