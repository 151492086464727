import React from "react";
import styled from "styled-components";

import { ReactComponent as EditIcon } from "../../../assets/svgs/EditOutline.svg";
import { UserType } from "../../types/UserType";
import { SvgIcon } from "../icons/SvgIcon";
import { useEditUserDialog } from "./Dialogs/useEditUserDialog";
import { formatUserFullName } from "./utils/formatUserFullName";
import { formatUserTitleAndOrganization } from "./utils/formatUserTitleAndOrganization";

//Modals that are nested in modals lose tab functionality if the parent modal has no input field
//This component exists to hide an input field where the modal contains none by default, to resolve this issue
const SubModalTabEnabler = styled.input`
  height: 0px;
  visibility: hidden;
  margin-top: -22px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const NameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const NameText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

const EditButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 8px;
  cursor: pointer;
`;

const SubText = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 16px;
`;

interface UserDetailsProps {
  user: UserType;
  showEditButton: boolean;
}

export function UserDetails({ user, showEditButton }: UserDetailsProps): JSX.Element {
  const [setShowEditUserDialog, { dialog: editUserDialog }] = useEditUserDialog(user);

  const { email } = user;

  const fullName = formatUserFullName(user);
  const titleAndOrganization = formatUserTitleAndOrganization(user);

  const displayName = fullName ?? email;

  const handleClickEdit = () => {
    setShowEditUserDialog(true);
  };

  return (
    <>
      {editUserDialog}
      <SubModalTabEnabler />
      <Wrapper>
        <NameWrapper>
          <NameText>{displayName}</NameText>
          {showEditButton && (
            <EditButtonWrapper onClick={handleClickEdit}>
              <SvgIcon icon={EditIcon} size={16} />
            </EditButtonWrapper>
          )}
        </NameWrapper>
        {titleAndOrganization && <SubText>{titleAndOrganization}</SubText>}
        {fullName && <SubText>{email}</SubText>}
      </Wrapper>
    </>
  );
}
