import { RawProjectDetailsType } from "../../types/RawProjectDetailsType";

const parseProjectDetailsData = (data: RawProjectDetailsType): string[] => {
  const { project } = data;
  const { name, details, status } = project[0];
  const parsedData: string[] = [];
  parsedData.push(name);
  parsedData.push(details.study_no);
  parsedData.push(status);
  parsedData.push(details.ip);
  parsedData.push(details.phase);
  parsedData.push(details.arms);
  parsedData.push(details.design);
  parsedData.push(details.primary_endpoints);
  parsedData.push(details.secondary_endpoints);
  parsedData.push(details.target_end_date);

  return parsedData;
};

export default parseProjectDetailsData;
