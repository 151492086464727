import styled from "styled-components";

import { DialogContentInnerWrapper } from "../DialogContentInnerWrapper";

export const Wrapper = styled(DialogContentInnerWrapper)<{
  width?: number | string;
  maxWidth?: number | string;
}>`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  text-align: center;
  width: ${({ width = "372px" }) => (width && typeof width === "number" ? `${width}px` : width)};
  max-width: ${({ maxWidth }) =>
    maxWidth && typeof maxWidth === "number" ? `${maxWidth}px` : maxWidth};
`;
