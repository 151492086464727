import { TaskLink } from "../../../common/components/Link";
import { PatientMutableTaskType } from "../Manage/hooks/TaskType";
import { TaskLabel } from "./TaskLabel";

interface WorklistTaskLinkProps {
  row: PatientMutableTaskType;
  openTaskViewOnly: boolean;
}

export function WorklistTaskLink({ row, openTaskViewOnly }: WorklistTaskLinkProps): JSX.Element {
  if (row.task === undefined) {
    throw new Error("Task should not be undefined here");
  }

  return (
    <TaskLink patientID={row.id} task={row.task} openTaskViewOnly={openTaskViewOnly}>
      <TaskLabel
        task={row.task}
        onClick={
          // Do not propagate click event to parent.
          (e) => e.stopPropagation()
        }
      />
    </TaskLink>
  );
}
