import { gql } from "@apollo/client/core";

export const COHORT_FRAGMENT = gql`
  fragment Cohort on label {
    id
    name
    project_id
    metadata
  }
`;
