import { displayImage, enable, getDefaultViewportForImage, loadImage } from "cornerstone-core";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const Viewport = styled.div`
  width: 144px;
  height: 71px;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  z-index: 1;
`;

//This div exists to allow scrolling through Timepoints, even when hovering a Cornerstone image (TimepointViewPort)
const CanvasScrollCover = styled.div`
  height: 71px;
  width: 100%;
  margin-right: -100%;
  position: relative;
  z-index: 2;
`;

interface ViewportOptionsType {
  scale?: number;
  yOffset?: number;
  xOffset?: number;
  windowCenter?: number;
  windowWidth?: number;
}

interface TimepointViewportProps {
  imageId: string | null;
  viewportOptions?: ViewportOptionsType;
}

export function TimepointViewport({
  imageId,
  viewportOptions,
}: TimepointViewportProps): JSX.Element {
  const viewportElement = useRef<HTMLDivElement>(null);
  const [elementEnabled, setElementEnabled] = useState<boolean>(false);

  useEffect(() => {
    const { scale, yOffset, xOffset, windowCenter, windowWidth } = viewportOptions || {};

    if (!imageId || !viewportElement) {
      return;
    }

    const { current: currentViewportElement } = viewportElement;

    if (!elementEnabled) {
      return;
    }

    loadImage(imageId).then((image: unknown) => {
      const viewport = getDefaultViewportForImage(currentViewportElement, image);
      const {
        translation: { x: defaultX, y: defaultY },
        scale: defaultScale,
        voi: { windowCenter: defaultWindowCenter, windowWidth: defaultWindowWidth },
      } = viewport;
      const newViewport = {
        ...viewport,
        scale: scale ? defaultScale * scale : defaultScale,
        translation: {
          x: xOffset ?? defaultX,
          y: yOffset ?? defaultY,
        },
        voi: {
          windowWidth: windowWidth ?? defaultWindowWidth,
          windowCenter: windowCenter ?? defaultWindowCenter,
        },
      };
      displayImage(currentViewportElement, image, newViewport);
    });
  }, [imageId, viewportElement, viewportOptions, elementEnabled]);

  useEffect(() => {
    const { current: currentViewportElement } = viewportElement;

    if (!currentViewportElement) {
      return;
    }

    enable(currentViewportElement);
    setElementEnabled(true);
  }, [viewportElement]);

  return (
    <>
      <CanvasScrollCover />
      <Viewport ref={viewportElement} />
    </>
  );
}
