import React, { FC } from "react";

import { Card } from "../../../common/components/cards/Card";
import { EnrolmentChartContainer } from "./EnrolmentChartContainer";

interface EnrolmentCardProps {
  title: string;
  onClickHeader?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export const EnrolmentCard: FC<EnrolmentCardProps> = ({
  title,
  onClickHeader,
}: EnrolmentCardProps) => {
  return <Card title={title} content={<EnrolmentChartContainer />} onClickHeader={onClickHeader} />;
};

export function EnrolmentCardContainer(): JSX.Element {
  return <EnrolmentCard title={"Imaging Acquisition"} />;
}
