import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";

interface DatePickerProps {
  date?: Date;
  onDateChanged: (date?: Date) => void;
}

export function DatePicker({ date, onDateChanged }: DatePickerProps) {
  function changeHandler(date: Dayjs | null) {
    onDateChanged(date?.toDate() ?? undefined);
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        // @ts-ignore
        value={dayjs(date)}
        onChange={changeHandler}
        sx={{ width: "100%" }}
        format="MM/DD/YYYY"
        slotProps={{ textField: { variant: "standard", InputProps: { disableUnderline: true } } }}
      />
    </LocalizationProvider>
  );
}
