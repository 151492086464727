import dayjs, { Dayjs } from "dayjs";

import { PatientType } from "../hooks/useProjectLabels";
import { getEarliestDate } from "./getEarliestDate";
import { getIncludedPatientLesions } from "./getIncludedPatientLesions";

export function getEarliestLabelDate(
  patients: PatientType[],
  includeIncompleteTasks: boolean
): Dayjs | undefined {
  const lesions = getIncludedPatientLesions(patients, undefined, includeIncompleteTasks).flatMap(
    ({ lesions }) => lesions
  );

  const dates = lesions.map(({ created }) => dayjs(created));

  return getEarliestDate(dates);
}
