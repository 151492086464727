import { RawSubjectType } from "../../rawTypes/RawSubjectType";
import { AJCC_IVA } from "../../types/AjccStageType";
import { DiagnosisType } from "../../types/DiagnosisType";
import { ETHNICITY_CAUCASIAN, EthnicityType } from "../../types/EthnicityType";
import { FollowUpType } from "../../types/FollowUpType";
import { ProjectArmType } from "../../types/ProjectArmType";
import { SMOKING_NEVER, SmokingStatusType } from "../../types/SmokingStatusType";
import { SubjectType } from "../../types/SubjectType";
import { TreatmentType } from "../../types/TreatmentType";
import { HISTOLOGY_NOS } from "../../types/TumourHistologyType";
import { MUTATION_EGFR } from "../../types/TumourMutationType";
import { parseFollowUpFromQueryResult } from "./parseFollowUpFromQueryResult";
import { parsePatientDemographicFromQueryResults } from "./parsePatientDemographicFromQueryResults";
import { parsePatientHistoryFromQueryResults } from "./parsePatientHistoryFromQueryResults";

// Generating from ID ensures consistent QCs per user
function generateQCFromId(id: number): number {
  // Generate an integer in the range [1,4]; given ids [1,2,3,4,5,6,7,8...], would generate [1,2,3,4,1,2,3,4,...]
  return (id % 4) + 1;
}

export function parseSubjectFromQueryResult(rawSubject: RawSubjectType): SubjectType {
  const {
    patient_id: id,
    patient_dicom_id: subjectId,
    enrolments: rawEnrolments,
    follow_ups: rawFollowUps,
    patient_demographic: rawPatientDemographic,
    patient_history: rawPatientHistory,
    patient_project_arms: rawPatientProjectArm,
    temp_demo_patients,
  } = rawSubject;

  const { bmi, dob, ecogPerformanceStatus, ethnicity, sex } =
    parsePatientDemographicFromQueryResults(rawPatientDemographic);

  const dateOfBirth = dob ? new Date(dob) : null;

  const { smokingStatus, deathDate } = parsePatientHistoryFromQueryResults(rawPatientHistory);

  const randomizationDate =
    rawEnrolments.length > 0 ? new Date(rawEnrolments[0].randomization_date) : null;

  const projectArm: ProjectArmType =
    rawPatientProjectArm.length > 0
      ? {
          name: rawPatientProjectArm[0].project_arm.name,
          number: rawPatientProjectArm[0].project_arm.arm_num,
        }
      : //   TODO: this is a temporary fix for the fact that we don't handle patients w/o project arms very well
        {
          name: "N/A",
          number: 0,
        };

  const followUpOutput = rawFollowUps.map(parseFollowUpFromQueryResult);

  const intermediateDiagnosis = followUpOutput
    .filter((fu) => fu.diagnoses.length > 0)
    .map((fu) => fu.diagnoses[0]);

  const treatments: TreatmentType[] = intermediateDiagnosis
    .filter((d) => d.treatments.length > 0)
    .flatMap((d) => {
      return { startDate: d.treatments[0].startDate };
    });

  const diagnoses: DiagnosisType[] = intermediateDiagnosis.flatMap((d) => {
    return {
      ajccStage: d.ajccStage,
      histology: d.histology,
      mutation: d.mutation,
      tStage: d.tStage,
      nStage: d.nStage,
      mStage: d.mStage,
      diagnosisDate: d.diagnosisDate,
    };
  });

  const diagnosis =
    diagnoses.length > 0
      ? diagnoses[0]
      : {
          ajccStage: null,
          histology: null,
          mutation: null,
          tStage: null,
          nStage: null,
          mStage: null,
          diagnosisDate: null,
        };

  const followUps: FollowUpType[] = followUpOutput.map((fu) => {
    return {
      order: fu.order,
      date: fu.date,
      tumourBurdens: fu.tumourBurdens,
      tumourResponses: fu.tumourResponses,
      survivalPredictions: fu.survivalPredictions,
      mortalityRisks: fu.mortalityRisks,
      studies: fu.studies,
    };
  });

  //TODO get qc from db
  const qcStatus = generateQCFromId(id);

  // TODO: This was here previously, presumable to allow filtering against uploaded patients that didn't otherwise have clinical data. We turn this off for now, but should revisit.
  const overrideNonSynthPatients = false;
  const isSynthPatient = temp_demo_patients.length > 0;
  const override = overrideNonSynthPatients && !isSynthPatient;

  return {
    id,
    subjectId,
    bmi,
    dateOfBirth,
    deathDate,
    diagnosis,
    ecogPerformanceStatus,
    ethnicity,
    followUps,
    randomizationDate,
    sex,
    smokingStatus,
    projectArm,
    treatments,
    qcStatus,
    ...(override ? overriddenAttributes : {}),
  };
}

const overriddenAttributes: {
  diagnosis: DiagnosisType;
  bmi: number;
  smokingStatus: SmokingStatusType;
  ethnicity: EthnicityType;
  ecogPerformanceStatus: number | null;
  sex: string;
} = {
  diagnosis: {
    mutation: MUTATION_EGFR,
    ajccStage: AJCC_IVA,
    diagnosisDate: null,
    mStage: null,
    nStage: null,
    tStage: null,
    histology: HISTOLOGY_NOS,
  },
  bmi: 24.2,
  smokingStatus: SMOKING_NEVER,
  ethnicity: ETHNICITY_CAUCASIAN,
  ecogPerformanceStatus: 1,
  sex: "Female",
};
