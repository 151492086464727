import { LesionListItemFragmentType } from "../../fragments/LesionListItemFragment";
import { useLesionsList } from "../../hooks/useLesionsList";
import { lesionsForCurrentTaskFilter } from "../../hooks/useTaskLesionListOptions";
import { AnyTaskResult, LesionTaskCheckResult, TaskCheckResult } from "./types/SaveCheckResultType";
import { useEnabledLesionChecks } from "./useEnabledLesionChecks";
import { useEnabledTaskChecks } from "./useEnabledTaskChecks";

// TODO: make this generic for task-level and sub-criteria level checks
export type LesionTaskCheckResults = {
  lesion: LesionListItemFragmentType;
  results: LesionTaskCheckResult<unknown>[];
};

export type TaskCheckResults = {
  task: TaskCheckResult<unknown>[];
  // TODO: make this generic for task-level and sub-criteria level checks
  lesions?: LesionTaskCheckResults[];
};

export function flattenTaskResults({
  task,
  lesions = [],
}: TaskCheckResults): AnyTaskResult<unknown>[] {
  const lesionResults = lesions.flatMap(({ results }) => results);
  return [...task, ...lesionResults];
}

export function useTaskChecks(): TaskCheckResults {
  const { data: lesionsData } = useLesionsList(lesionsForCurrentTaskFilter);

  const taskChecks = useEnabledTaskChecks();
  const lesionChecks = useEnabledLesionChecks(); // TODO: collapse into taskChecks

  const taskResults = taskChecks.map((taskCheck) => taskCheck());

  const { lesions } = lesionsData ?? { lesions: [] };

  const lesionsResults = lesions.map((lesion) => {
    const results = lesionChecks.map((lesionCheck) => lesionCheck({ lesion }));
    return {
      lesion,
      results,
    };
  });

  return { task: taskResults, lesions: lesionsResults };
}
