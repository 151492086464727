import { gql } from "@apollo/client/core";

import { useParsedMutation } from "../apollo/utils/useParsedMutation";
import { useParsedMutationReturnType } from "../apollo/utils/useParsedMutationReturnType";
import { SiteType } from "../common/types/SiteType";

const MUTATION = gql`
  mutation DeleteSite($site_id: Int!) {
    delete_label(where: { id: { _eq: $site_id } }) {
      affected_rows
    }
  }
`;

type Variables = {
  site_id: number;
};

type Data = {
  delete_label: { affected_rows: number };
};

export function useDeleteSite(): useParsedMutationReturnType<SiteType, number> {
  return useParsedMutation<SiteType, number, Data, Variables>({
    mutation: MUTATION,
    parseVariables,
    parseData,
  });
}

function parseVariables({ id: site_id }: SiteType): Variables {
  if (!site_id) {
    throw new Error("Site Id is undefined when trying to delete site label.");
  }

  return { site_id };
}

function parseData(data: Data): number {
  return data.delete_label.affected_rows;
}
