import { useMutation } from "@apollo/client";
import { gql, Reference, StoreObject } from "@apollo/client/core";
import { FetchResult } from "@apollo/client/link/core";
import {
  MutationFunctionOptions,
  MutationResult,
  MutationTuple,
} from "@apollo/client/react/types/types";

import { ClassificationValuesType } from "../../../../../types/TaskDescriptionType";
import {
  Data as DeleteRoiRecistEvaluationsData,
  INTERNAL_MUTATION as DELETE_ROI_RECIST_EVALUATIONS_MUTATION,
  useUpdateCache as useUpdateRoiRecistEvaluationsCache,
  Variables as DeleteRoiRecistEvaluationsVariables,
} from "../../ViewerPanel/tools/contour/hooks/useDeleteRoiRecistEvaluations";
import {
  Data as UpsertRoiRecistEvaluationData,
  INTERNAL_MUTATION as UPSERT_ROI_RECIST_EVALUATION_MUTATION,
  Variables as UpsertRoiRecistEvaluationVariables,
} from "../../ViewerPanel/tools/contour/hooks/useUpsertRoiRecistEvaluation";
import { RoiRecistEvaluationVariablesType } from "../../ViewerPanel/tools/contour/utils/getRoiRecistEvaluationVariables";
import { LESION_CLASSIFICATION_FRAGMENT } from "../fragments/LesionClassificationFragment";
import { ROI_RECIST_EVALUATION_FRAGMENT } from "../fragments/RoiRecistEvaluationFragment";
import { ClassificationType } from "../types/ClassificationType";
import { getLesionCacheId } from "./getLesionCacheId";
import { getLesionClassificationCacheId } from "./getLesionClassificationCacheId";
import { updateRoiRecistEvaluationsInCache } from "./updateRoiRecistEvaluationsInCache";
import { useGetRoiRecistEvaluationsToUpdate } from "./useGetRoiRecistEvaluationsToUpdate";

const MUTATION = gql`
  mutation InsertLesionClassifications(
    $objects: [lesion_classification_insert_input!]!
    $lesionId: Int!
    $deleteClassifications: [String!]!
    $roiRecistEvaluations: [roi_recist_evaluations_insert_input!]!
    $roiRecistEvaluationIdsToDelete: [Int!]!
  ) {
    insertLesionClassification: insert_lesion_classification(
      objects: $objects
      on_conflict: {
        constraint: lesion_classification_lesion_id_classification_key
        update_columns: classification
      }
    ) {
      inserted: returning {
        ...InsertLesionClassificationFragment
      }
    }
    deleteLesionClassification: delete_lesion_classification(
      where: {
        _and: [
          { lesion_id: { _eq: $lesionId } }
          { classification: { _in: $deleteClassifications } }
        ]
      }
    ) {
      deleted: returning {
        ...InsertLesionClassificationFragment
      }
    }
    ${UPSERT_ROI_RECIST_EVALUATION_MUTATION}
    ${DELETE_ROI_RECIST_EVALUATIONS_MUTATION}
  }

  fragment InsertLesionClassificationFragment on lesion_classification {
    id
    lesionId: lesion_id
    classification
  }

  ${ROI_RECIST_EVALUATION_FRAGMENT}
`;

export type Variables = {
  objects: {
    classification: ClassificationValuesType;
    lesion_id: number;
  }[];
  lesionId: number;
  deleteClassifications: ClassificationValuesType[];
} & UpsertRoiRecistEvaluationVariables &
  DeleteRoiRecistEvaluationsVariables;

type InsertLesionClassificationFragmentType = {
  id: number;
  lesionId: number;
  classification: ClassificationValuesType;
};

type Data = {
  insertLesionClassification: {
    inserted: InsertLesionClassificationFragmentType[];
  };
  deleteLesionClassification: {
    deleted: InsertLesionClassificationFragmentType[];
  };
} & UpsertRoiRecistEvaluationData &
  DeleteRoiRecistEvaluationsData;

function useInsertLesionClassificationsInternal(): MutationTuple<Data, Variables> {
  const updateRoiRecistEvaluationCache = useUpdateRoiRecistEvaluationsCache();

  return useMutation<Data, Variables>(MUTATION, {
    update: (cache, { data }) => {
      if (!data) {
        return;
      }

      const {
        insertLesionClassification: { inserted },
        deleteLesionClassification: { deleted },
        insert_roi_recist_evaluations: { roiRecistEvaluations },
      } = data;

      inserted.forEach(({ id, lesionId, classification }) => {
        const lesionCacheId = getLesionCacheId(lesionId, cache);
        cache.modify({
          id: lesionCacheId,
          fields: {
            lesion_classifications(existingClassificationRefs = [], { readField }) {
              const newClassificationRef = cache.writeFragment<
                ClassificationType & { __typename: string }
              >({
                fragment: LESION_CLASSIFICATION_FRAGMENT,
                data: {
                  __typename: "lesion_classification",
                  id,
                  classification,
                },
              });

              return [
                ...existingClassificationRefs.filter(
                  (classificationRef: Reference | StoreObject | undefined) => {
                    const existingClassification = readField("classification", classificationRef);
                    const existingId = readField("id", classificationRef);
                    const isUnique = existingClassification !== classification && existingId !== id;
                    const isDeleted = deleted.some(
                      ({
                        id: deletedId,
                        lesionId: deletedLesionId,
                        classification: deletedClassification,
                      }) =>
                        deletedId === existingId &&
                        deletedLesionId === lesionId &&
                        deletedClassification === existingClassification
                    );
                    return isUnique && !isDeleted;
                  }
                ),
                newClassificationRef,
              ];
            },
          },
        });
      });

      deleted.forEach(({ id }) => {
        const lesionClassificationCacheId = getLesionClassificationCacheId(id, cache);
        cache.evict({ id: lesionClassificationCacheId });
      });

      updateRoiRecistEvaluationCache(cache, { data });

      updateRoiRecistEvaluationsInCache(roiRecistEvaluations, cache);

      cache.gc();
    },
  });
}

function getVariables(
  lesionId: number,
  classifications: ClassificationValuesType[],
  deleteClassifications: ClassificationValuesType[],
  roiRecistEvaluations: RoiRecistEvaluationVariablesType[],
  roiRecistEvaluationIdsToDelete: number[]
): Variables {
  return {
    objects: classifications.map((classification) => ({
      classification,
      lesion_id: lesionId,
    })),
    lesionId,
    deleteClassifications,
    roiRecistEvaluations,
    roiRecistEvaluationIdsToDelete,
  };
}

export type InsertLesionClassificationsInternalReturnType = [
  (
    lesionId: number,
    classifications: ClassificationValuesType[],
    deleteClassifications: ClassificationValuesType[],
    options?: Omit<MutationFunctionOptions<Data, Variables>, "variables">
  ) => Promise<FetchResult<Data>>,
  MutationResult<Data>
];

export function useInsertLesionClassifications(): InsertLesionClassificationsInternalReturnType {
  const [insertLesionClassifications, ...other] = useInsertLesionClassificationsInternal();

  const getRoiRecistEvaluationsToUpdate = useGetRoiRecistEvaluationsToUpdate();

  return [
    async (lesionId, classifications, deleteClassifications, options) => {
      const { roiRecistEvaluationIdsToDelete, roiRecistEvaluationsToInsert } =
        await getRoiRecistEvaluationsToUpdate(lesionId, classifications, deleteClassifications);

      const variables = getVariables(
        lesionId,
        classifications,
        deleteClassifications,
        roiRecistEvaluationsToInsert,
        roiRecistEvaluationIdsToDelete
      );

      return insertLesionClassifications({ variables, ...options });
    },
    ...other,
  ];
}
