import { TaskDataType } from "../../../../../../common/store/annotatePage/taskSlice";
import { PatientMutableTaskType } from "../../../../Manage/hooks/TaskType";
import { useProjectPatientTaskRows } from "../../../../Manage/hooks/useProjectPatientTaskRows";
import { isUnassigned } from "../../../../TaskWorklist/TaskWorklistTableRowType";

type NextTaskType = {
  loading?: boolean;
  nextTask?: TaskDataType;
};

type ReturnType = NextTaskType & {
  refetch: () => Promise<NextTaskType>;
};

export function useNextTask(): ReturnType {
  const { patientsTasks, loading, refetch } = useProjectPatientTaskRows(false);

  const refetchCallback = async () => {
    const patientsTasks = await refetch();
    return getNextTask(patientsTasks, false);
  };

  return {
    ...getNextTask(patientsTasks, loading),
    refetch: refetchCallback,
  };
}

function getNextTask(
  patientsTasks: PatientMutableTaskType[] | undefined,
  loading: boolean
): NextTaskType {
  const unassigned = patientsTasks?.filter(isUnassigned) ?? [];
  if (loading) {
    return { loading, nextTask: undefined };
  }

  const nextAvailableUnassignedTask = unassigned.find(({ task }) => !!task);
  if (!nextAvailableUnassignedTask) {
    return { loading, nextTask: undefined };
  }

  const { id: patientId, task } = nextAvailableUnassignedTask;
  if (!task) {
    throw new Error("Task should not be undefined here");
  }

  const { id: taskId, projectId } = task;

  return { loading, nextTask: { projectId, patientId, taskId } };
}
