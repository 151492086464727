import { gql, useQuery } from "@apollo/client";
import { WatchQueryFetchPolicy } from "@apollo/client/core";
import { useMemo } from "react";

import { TaskType } from "./TaskType";
import { TASK_FRAGMENT, TaskFragmentType } from "./useRawPatientTasksQuery";

const QUERY = gql`
  query GetTask($taskId: Int!, $patientId: Int!) {
    task: task_by_pk(id: $taskId) {
      ...Task
    }
    patientCohorts: label(
      where: {
        _and: [
          { type_name: { _eq: "COHORT" } }
          {
            _or: [
              { label_patients: { patient_id: { _eq: $patientId } } }
              { label_studies: { study: { patient_id: { _eq: $patientId } } } }
              { label_series: { series: { study: { patient_id: { _eq: $patientId } } } } }
            ]
          }
        ]
      }
    ) {
      cohortId: id
    }
  }
  ${TASK_FRAGMENT}
`;

type ReturnType = {
  task: TaskType | undefined;
  patientCohortIds: number[];
  loading: boolean;
};

type Data = {
  task: TaskFragmentType | null;
  patientCohorts: { cohortId: number }[];
};

type Variables = {
  taskId: number;
  patientId: number;
};

export function useTask(
  patientId: number,
  taskId: number,
  fetchOptions: {
    fetchPolicy?: WatchQueryFetchPolicy;
    nextFetchPolicy?: WatchQueryFetchPolicy;
  } = {}
): ReturnType {
  const result = useQuery<Data, Variables>(QUERY, {
    variables: { taskId, patientId },
    ...fetchOptions,
  });

  return useMemo(() => {
    const { data, loading } = result;
    const task = data?.task;

    const parsedTask: TaskType | undefined =
      loading || !task ? undefined : parseTask(task, patientId);

    const patientCohorts = data?.patientCohorts;
    const patientCohortIds: number[] =
      loading || !patientCohorts ? [] : patientCohorts.map(({ cohortId }) => cohortId);

    return {
      task: parsedTask,
      patientCohortIds,
      loading,
    };
  }, [patientId, result]);
}

function parseTask(task: TaskFragmentType, patientId: number): TaskType | undefined {
  const { taskAssignments } = task;

  const filteredTaskAssignments = taskAssignments.filter(
    ({ patientId: taskAssignmentPatientId }) => taskAssignmentPatientId === patientId
  );

  return { ...task, taskAssignments: filteredTaskAssignments };
}
