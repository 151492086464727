import React from "react";
import { useSelector } from "react-redux";
import { components, IndicatorProps } from "react-select";

import { ReactComponent as DropDownArrow } from "../../../../../assets/svgs/DropDownArrow.svg";
import { ReactComponent as DropUpArrow } from "../../../../../assets/svgs/DropUpArrow.svg";
import { classificationPropertiesSelector } from "../../../../../common/store/annotatePage/taskSelector";
import { OptionType } from "../../../../../DataManagement/Upload/components/GenericMultiSelect";
import { GenericSingleSelect } from "../../../../../DataManagement/Upload/components/GenericSingleSelect";
import {
  ClassificationModeType,
  ClassificationValuesType,
} from "../../../../types/TaskDescriptionType";
import { getClassificationsSelectStyle } from "./getClassificationsSelectStyle";

interface ClassificationSelectProps {
  onSelect: (option: ClassificationValuesType) => void;
  values: Record<ClassificationValuesType, boolean>;
  mode: ClassificationModeType;
  disabled: boolean;
}

const DropdownIndicator = (props: IndicatorProps<OptionType<ClassificationOption>, false>) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        {props.selectProps.menuIsOpen ? <DropUpArrow /> : <DropDownArrow />}
      </components.DropdownIndicator>
    )
  );
};

export function ClassificationSelect({
  onSelect,
  values,
  mode,
  disabled,
}: ClassificationSelectProps): JSX.Element | null {
  const selectStyle = getClassificationsSelectStyle<OptionType<ClassificationOption>, false>();

  const classificationPropertiesMap = useSelector(classificationPropertiesSelector);

  const modeClassificationPropertiesMap = classificationPropertiesMap[mode];
  if (!modeClassificationPropertiesMap) {
    return null;
  }

  const placeholderClassification = Object.keys(values)[0] as ClassificationValuesType;

  const placeholderClassificationProperties =
    modeClassificationPropertiesMap[placeholderClassification];

  if (!placeholderClassificationProperties) {
    return null;
  }

  const { displayName } = placeholderClassificationProperties;

  const selectedValue = Object.keys(values).find(
    (classification) => values[classification as ClassificationValuesType]
  );

  let selectedOption: ClassificationOption | null = null;
  let selectedOptionReadOnly = false;

  if (selectedValue) {
    const selectedValueProperties =
      modeClassificationPropertiesMap[selectedValue as ClassificationValuesType];

    if (!selectedValueProperties) {
      throw new Error("Properties should exist for selected classification");
    }

    const { displayName, readOnly } = selectedValueProperties;

    selectedOption = {
      classification: selectedValue as ClassificationValuesType,
      displayName,
      readOnly,
    };

    selectedOptionReadOnly = readOnly;
  }

  const options: ClassificationOption[] = Object.keys(values).map((value) => {
    const classification = value as ClassificationValuesType;
    const optionValueProperties = modeClassificationPropertiesMap[classification];

    if (!optionValueProperties) {
      throw new Error("Properties should exist for classification options");
    }

    return { ...optionValueProperties, classification };
  });

  const handleSelectedChanged = (value: ClassificationOption | null) => {
    if (!value) {
      throw new Error("Selected classification is null");
    }
    const { classification } = value;
    onSelect(classification);
  };

  const placeholder = !disabled ? displayName : "N/A";

  return (
    <GenericSingleSelect
      hideSelectedOptions={false}
      components={{ DropdownIndicator }}
      values={options}
      selected={selectedOption}
      onSelectedChanged={handleSelectedChanged}
      getOption={getOption}
      isDisabled={disabled || selectedOptionReadOnly}
      placeholder={placeholder}
      styles={selectStyle}
      menuPortalTarget={document.body}
      menuPosition={"fixed"}
      compareValues={compareValues}
    />
  );
}

type ClassificationOption = {
  classification: ClassificationValuesType;
  displayName: string;
  readOnly: boolean;
};

function getOption(item: ClassificationOption): OptionType<ClassificationOption> {
  const { displayName } = item;
  return {
    label: displayName,
    value: item,
  };
}

// we need a custom compare function since the default one does not work with objects
function compareValues(
  { classification: a }: ClassificationOption,
  { classification: b }: ClassificationOption
): boolean {
  return a === b;
}
