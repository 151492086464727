import { useContext } from "react";

import { UserType } from "../../types/UserType";
import { UserContext } from "./UserContext";

export function useCurrentUser(): UserType {
  const { user } = useContext(UserContext);
  if (!user) {
    throw new Error("Current user is undefined");
  }

  return user;
}
