import React, { useContext } from "react";
import styled from "styled-components";

import { ReactComponent as ChevronLeft } from "../../../../../assets/svgs/ChevronLeft.svg";
import { ReactComponent as TasksIcon } from "../../../../../assets/svgs/Tasks.svg";
import { SvgIcon } from "../../../../../common/components/icons/SvgIcon";
import { main } from "../../../../../common/theme/main";
import { PatientContext } from "../../../../../Dashboard/components/Settings/Patients/Viewer/PatientContext";
import { useGoToData } from "../../../../../Project/Layout/AnnotatePage/useGoToData";
import { useGoToWorklist } from "../../../../../Project/Layout/AnnotatePage/useGoToWorklist";
import { TaskBackButton } from "./TaskBackButton";
import { TaskForwardButton } from "./TaskForwardButton";

const Wrapper = styled.div`
  flex: 0;
  padding: 9px 6px 8px 6px;
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
`;

const LabelWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  overflow-wrap: anywhere;
  flex-wrap: wrap;
`;

const ReturnButton = styled.div`
  cursor: pointer;
`;

interface TaskInfoProps {
  standalone?: boolean;
}

export function TaskInfo({ standalone }: TaskInfoProps): JSX.Element {
  const goToWorklist = useGoToWorklist();
  const goToData = useGoToData();

  const { patientDicomId } = useContext(PatientContext);

  const handleClickReturn = () => {
    if (standalone) {
      goToData();
    } else {
      goToWorklist();
    }
  };

  const icon = standalone ? ChevronLeft : TasksIcon;

  return (
    <Wrapper>
      <ReturnButton onClick={handleClickReturn}>
        <SvgIcon icon={icon} color={main.colors.neutral.black} size={30} />
      </ReturnButton>
      <LabelWrapper>
        {!standalone && <TaskBackButton />}
        {patientDicomId}
        {!standalone && <TaskForwardButton />}
      </LabelWrapper>
    </Wrapper>
  );
}
