import React, { FC } from "react";

import { Card } from "../../../common/components/cards/Card";
import {
  LinkToPatientProps,
  usePatientLinkHandler,
} from "../../../Project/Layout/hooks/usePatientLinkHandler";
import {
  LinkToRouteProps,
  useProjectPageLinkHandler,
} from "../../../Project/Layout/hooks/useProjectPageLinkHandler";
import AnalysisOverviewPatientJourney from "./AnalysisOverviewPatientJourney";

type PatientJourneyCardProps = LinkToRouteProps & LinkToPatientProps;

export const PatientJourneyCard: FC<PatientJourneyCardProps> = ({
  linkToRoute,
  patientRoute,
}: PatientJourneyCardProps) => {
  const handleGoToProjectPageLink = useProjectPageLinkHandler(linkToRoute);
  const handleGoToPatient = usePatientLinkHandler(patientRoute);

  return (
    <Card
      title={"Subject Journey"}
      content={<AnalysisOverviewPatientJourney onClickPatient={handleGoToPatient} />}
      onClickHeader={handleGoToProjectPageLink}
    />
  );
};
