import React from "react";
import Select, { ValueType } from "react-select";

import { getSelectStyle } from "../../../common/components/input/getSelectStyle";
import { CohortType } from "../../../common/types/CohortType";

type OptionType = {
  value: CohortType;
  label: string;
};

function getCohortOption(cohort: CohortType): OptionType {
  const { name } = cohort;
  return {
    value: cohort,
    label: name,
  };
}

interface CohortSelectProps {
  cohorts: CohortType[];
  selected: CohortType[];
  onSelectedChanged: (cohorts: CohortType[]) => void;
}

export function CohortSelect({
  cohorts,
  selected,
  onSelectedChanged,
}: CohortSelectProps): JSX.Element {
  const options = cohorts.map(getCohortOption);
  const value = selected.map(getCohortOption);
  const styles = getSelectStyle<OptionType, true>();

  const handleSelectCohorts = (value: ValueType<OptionType, true>) => {
    const cohorts = value.map(({ value }) => value);
    onSelectedChanged(cohorts);
  };

  return (
    <Select
      isMulti
      menuPortalTarget={document.body} 
      styles={styles}
      value={value}
      options={options}
      onChange={handleSelectCohorts}
      placeholder={"Select cohort(s)"}
    />
  );
}
