import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { MutationTuple } from "@apollo/client/react/types/types";

import { useProjectId } from "../../../Project/hooks/useProjectId";
import { S3ConfigType } from "./S3ConfigType";
import {
  Data as ProjectConfigData,
  QUERY,
  Variables as ProjectConfigVariables,
} from "./useProjectS3Config";

type Data = {
  s3BucketConfig: S3ConfigType;
};

type Variables = {
  s3Config: {
    project_id: number;
    bucket_name: string;
    bucket_prefix: string | null;
    created_by: number;
    cohort_label_id: number;
  };
};

const MUTATION = gql`
  mutation UpsertProjectS3Config($s3Config: s3_bucket_config_insert_input!) {
    s3BucketConfig: insert_s3_bucket_config_one(
      object: $s3Config
      on_conflict: {
        constraint: s3_bucket_config_project_id_key
        update_columns: [bucket_name, bucket_prefix, created_by, cohort_label_id]
      }
    ) {
      id
      projectId: project_id
      bucketName: bucket_name
      bucketPrefix: bucket_prefix
      cohortId: cohort_label_id
      permissionVerified: permission_verified
    }
  }
`;

export function useUpsertProjectS3Config(): MutationTuple<Data, Variables> {
  const projectId = useProjectId();

  return useMutation<Data, Variables>(MUTATION, {
    update(cache, { data }) {
      if (!data) {
        throw new Error("Something went wrong updating the cache after inserting a S3 config");
      }

      const { s3BucketConfig } = data;
      const { s3BucketConfigs: existingS3BucketConfigs } = cache.readQuery<
        ProjectConfigData,
        ProjectConfigVariables
      >({
        query: QUERY,
        variables: { projectId },
      }) ?? {
        s3BucketConfigs: [],
      };

      if (existingS3BucketConfigs.some(({ id }) => id === s3BucketConfig.id)) {
        return;
      }

      cache.writeQuery<ProjectConfigData, ProjectConfigVariables>({
        query: QUERY,
        variables: { projectId },
        data: {
          s3BucketConfigs: [s3BucketConfig, ...existingS3BucketConfigs],
        },
      });
    },
  });
}
