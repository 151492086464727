import React from "react";
import { ColumnInstance } from "react-table";

import { TableDataType } from "./TableDataType";

interface DefaultHeaderCellProps<D extends TableDataType> {
  column: ColumnInstance<D>;
}

export function DefaultHeaderCell<D extends TableDataType>({
  column,
}: DefaultHeaderCellProps<D>): JSX.Element {
  return (
    <div {...column.getSortByToggleProps()}>
      {column.render("Header")}
      {column.isSorted ? (column.isSortedDesc ? "  ⬇" : "  ⬆") : ""}
    </div>
  );
}
