import { ApolloCache } from "@apollo/client";

export function getContourCacheId<T>(contourId: number, cache: ApolloCache<T>): string {
  const id = cache.identify({
    id: contourId,
    __typename: "contour",
  });

  if (!id) {
    throw new Error("Unable to construct contour cache id");
  }

  return id;
}
