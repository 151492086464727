import { PageType } from "../Layout";
import { getRouteDisplayName } from "../RoutePathType";
import { getPageRoute } from "./getPageRoute";

export function getPageDisplayName(page: PageType): string {
  const pageRoute = getPageRoute(page);
  const routes = Array.isArray(pageRoute) ? pageRoute : [pageRoute];
  return routes
    .map(getRouteDisplayName)
    .filter((name) => !!name)
    .join(" | ");
}
