import React, { FC } from "react";

import { Card } from "../../../common/components/cards/Card";
import RadiologyCaseStatusTableContainer from "./RadiologyCaseStatusTableContainer";
import RadiologyPanel from "./RadiologyPanel";

const RadiologyPanelContainer: FC = () => {
  const data = {
    criteria: "RECIST 1.1",
    followUp: "Double central review with adjudication",
  };

  return (
    <RadiologyPanel criteria={data.criteria} followUp={data.followUp}>
      <RadiologyCaseStatusTableContainer />
    </RadiologyPanel>
  );
};

export function RadiologyPanelCard(): JSX.Element {
  return <Card title={"Radiology"} content={<RadiologyPanelContainer />} />;
}
