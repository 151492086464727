import React, { FC } from "react";

import { ActionButtonsWrapper } from "../../../../../../../../common/components/Dialog/Form/ActionButtonsWrapper";
import { Wrapper } from "../../../../../../../../common/components/Dialog/Form/Wrapper";
import { FormProps } from "../../../../../../../../common/components/Dialog/FormProps";
import { useDialogReturnType } from "../../../../../../../../common/components/Dialog/useDialog";
import { useFormDialog } from "../../../../../../../../common/components/Dialog/useFormDialog";
import { InputButton } from "../../../../../../../../common/components/input/InputButton";
import { main } from "../../../../../../../../common/theme/main";
import { useGoToTask } from "../../../../../../../../Project/Layout/AnnotatePage/useGoToTask";
import { useGoToWorklist } from "../../../../../../../../Project/Layout/AnnotatePage/useGoToWorklist";
import { useSelectedTaskAssignment } from "../../../../../../../useSelectedTaskAssignment";
import { TASK_IN_PROGRESS } from "../../../../../../TaskWorklist/TaskProgressType";
import { useNextTask } from "../../../../StudyPanel/hooks/useNextTask";
import { useUpdateTaskAssignmentProgress } from "../../../hooks/useUpdateTaskAssignmentProgress";

export function useSuccessfulSaveTaskDialog(): useDialogReturnType {
  return useFormDialog({
    children: SuccessfulSaveTaskDialog,
    label: "Task is Completed",
    showCloseButton: false,
    reactModalProps: {
      shouldCloseOnOverlayClick: false,
      shouldCloseOnEsc: false,
    },
    props: undefined,
  });
}

const SuccessfulSaveTaskDialog: FC<FormProps<undefined>> = ({ onCancel }: FormProps<undefined>) => {
  const goToWorklist = useGoToWorklist();
  const goToTask = useGoToTask();
  const { nextTask } = useNextTask();
  const [updateTaskAssignmentProgress] = useUpdateTaskAssignmentProgress();
  const taskAssignment = useSelectedTaskAssignment();

  const handleClickWorklist = () => {
    onCancel();

    goToWorklist();
  };

  const handleClickNextTask = () => {
    onCancel();

    if (!nextTask) {
      throw new Error("No next task but click callback run!");
    }

    goToTask(nextTask);
  };

  const handleReOpenTask = async () => {
    await updateTaskAssignmentProgress({
      variables: {
        id: taskAssignment.id,
        progress: TASK_IN_PROGRESS,
      },
    });

    onCancel();
  };

  return (
    <Wrapper width={300}>
      <ActionButtonsWrapper>
        <InputButton autoFocus type="button" value={"Worklist"} onClick={handleClickWorklist} />
        <InputButton width={72} type="button" value={"Next Task"} onClick={handleClickNextTask} />
        <InputButton width={72} type="button" value={"Re-Open"} onClick={handleReOpenTask} />
        <InputButton
          type="button"
          value={"View"}
          background={main.colors.neutral.white}
          color={main.colors.neutral.black}
          onClick={onCancel}
        />
      </ActionButtonsWrapper>
    </Wrapper>
  );
};
