import { createSelector } from "@reduxjs/toolkit";
import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getLifestyleFilters,
  projectViewFiltersActions,
} from "../../../../common/store/projectViewFiltersSlice";
import { capitalize } from "../../../../common/utils/capitalize";
import { NumberInputWrapper } from "../FilterInputWrapper";
import FilterNumberInput from "../FilterNumberInput";
import FilterSubtext from "../FilterSubtext";

const getBmiFilters = createSelector(
  [getLifestyleFilters],
  (lifestyleFilters) => lifestyleFilters.bmi
);

export const BmiFilters: FC = () => {
  const dispatch = useDispatch();
  const bmiFilters = useSelector(getBmiFilters);

  const bmiOptions = Object.entries(bmiFilters).map(([filter, value], index) => {
    const updateBmiFilters = (number: number | null) => {
      dispatch(
        projectViewFiltersActions.setBmi({
          ...bmiFilters,
          [filter]: number,
        })
      );
    };
    return (
      <FilterNumberInput
        key={index}
        label={capitalize(filter)}
        onChange={updateBmiFilters}
        value={value}
      />
    );
  });

  const options = <NumberInputWrapper>{bmiOptions}</NumberInputWrapper>;

  return (
    <>
      <FilterSubtext text="BMI" />
      {options}
    </>
  );
};
