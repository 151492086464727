import { useContext } from "react";

import { ToolType } from "../../../../../../../cornerstone/ToolType";
import { ViewerContext } from "../ViewerContext";

type ToolbarState = {
  activeTool: ToolType;
  lastActiveTool: ToolType | null;
};

export function useActiveTool(): ToolbarState {
  const { activeTool, lastActiveTool } = useContext(ViewerContext);
  return { activeTool, lastActiveTool };
}
