import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { MutationTuple } from "@apollo/client/react/types/types";

import { deleteLesionFromCache } from "./deleteLesionFromCache";
import { getDiagnosisCacheId } from "./getDiagnosisCacheId";
import { useLesionListQueryInput } from "./useLesionListQueryInput";

const MUTATION = gql`
  mutation DeleteLesion($lesionId: Int!, $diagnosisIds: [Int!]!) {
    delete_diagnosis(where: { id: { _in: $diagnosisIds } }) {
      returning {
        id
      }
    }
    delete_lesion_by_pk(lesion_id: $lesionId) {
      id: lesion_id
    }
  }
`;

type Variables = {
  lesionId: number;
  diagnosisIds: number[];
};

type Data = {
  delete_lesion_by_pk: { id: number; caseId: number };
  delete_diagnosis: { returning: { id: number }[] };
};

export type DeleteLesionFunctionType = MutationTuple<Data, Variables>

export function useDeleteLesion(): DeleteLesionFunctionType {
  const lesionListQueryInput = useLesionListQueryInput();

  return useMutation<Data, Variables>(MUTATION, {
    update(cache, { data }) {
      if (!data) {
        throw new Error("Something went wrong updating the cache after deleting a contour");
      }

      const {
        delete_lesion_by_pk: { id: lesionId },
        delete_diagnosis: { returning: diagnoses },
      } = data;

      deleteLesionFromCache(lesionListQueryInput, lesionId, cache);

      const diagnosisIds = diagnoses.map(({ id }) => id);

      for (const diagnosisId of diagnosisIds) {
        const diagnosisCacheId = getDiagnosisCacheId(diagnosisId, cache);
        cache.evict({ id: diagnosisCacheId });
      }

      cache.gc();
    },
  });
}
