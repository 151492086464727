import { useFlags } from "launchdarkly-react-client-sdk";

import { useFindPageByRoute } from "../hooks/useFindPageByRoute";
import { useGoToRoute } from "../hooks/useGoToRoute";
import { useLayout } from "../hooks/useLayout";
import { DATA_MANAGEMENT, DATA_MANAGEMENT_NEW } from "../RoutePathType";
import { getPageRoute } from "../utils/getPageRoute";

type ReturnType = () => void;

export function useGoToData(): ReturnType {
  const layout = useLayout();
  const flags = useFlags();
  const showReorgPages = flags["notaShowReorganisationPages"];

  const goToProjectPath = useGoToRoute();
  const findPageByRoute = useFindPageByRoute();

  return () => {
    const { project } = layout;

    const dataPage = findPageByRoute(
      project,
      showReorgPages ? DATA_MANAGEMENT_NEW : DATA_MANAGEMENT
    );

    if (!dataPage) {
      throw new Error("Unable to find worklist page in useGoToWorklist");
    }

    const route = getPageRoute(dataPage);
    goToProjectPath(route);
  };
}
