import React, { FC } from "react";
import styled from "styled-components";

import { ReactComponent as BackArrowIcon } from "../../../assets/svgs/Back.svg";
import { SvgIcon } from "../../../common/components/icons/SvgIcon";

const BackButtonStyle = styled.button`
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  outline: none;
  border: none;
`;
const BackTextStyle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  margin-left: 12px;
  color: ${(props) => props.theme.colors.neutral.black};
`;
const BackIconWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.colors.primary.default};
  border-radius: 6px;
`;

interface BackButtonProps {
  onClickBack: React.MouseEventHandler;
}

export const BackButton: FC<BackButtonProps> = ({ onClickBack }: BackButtonProps) => {
  return (
    <BackButtonStyle onClick={onClickBack}>
      <BackIconWrapper>
        <SvgIcon icon={BackArrowIcon} />
      </BackIconWrapper>
      <BackTextStyle>Back</BackTextStyle>
    </BackButtonStyle>
  );
};
