import { ApolloClient } from "@apollo/client";
import { gql } from "@apollo/client/core";

import { TaskDescriptionType } from "../../../../../types/TaskDescriptionType";

const QUERY = gql`
  query GetTaskData($taskId: Int!) {
    task: task_by_pk(id: $taskId) {
      id
      taskDescription: task_description {
        data
      }
    }
  }
`;

type Data = {
  task: {
    taskDescription: {
      data: TaskDescriptionType;
    };
  };
};

type Variables = {
  taskId: number;
};

export function getVariables(taskId: number): Variables {
  return { taskId };
}

export async function getTaskDescription(
  client: ApolloClient<unknown>,
  taskId: number
): Promise<TaskDescriptionType> {
  const variables = getVariables(taskId);
  const {
    data: {
      task: {
        taskDescription: { data: taskDescription },
      },
    },
  } = await client.query<Data, Variables>({ query: QUERY, variables });
  return taskDescription;
}
