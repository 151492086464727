import { gql } from "@apollo/client/core";

import { TumourResponseType } from "../../../../../../Analysis/common/types/TumourResponseType";

export type RoiListItemTumourBurdenType = {
  id: number;
  diametric: number;
  volumetric: number | null;
};

export type RoiListItemTumourResponseType = {
  id: number;
  classification: TumourResponseType;
};

export const ROI_LIST_ITEM_TUMOUR_BURDEN_FRAGMENT = gql`
  fragment RoiListItemTumourBurden on tumour_burden {
    id
    diametric
    volumetric
  }
`;

export const ROI_LIST_ITEM_TUMOUR_RESPONSE_FRAGMENT = gql`
  fragment RoiListItemTumourResponse on tumour_response {
    id
    classification
  }
`;

export type RoiListItemDiagnosisFragmentType = {
  id: number;
  type: string;
  code: number;
  primaryLocation: string;
  diagnosisDate: string;
  followUpId: number;
};

export const ROI_LIST_ITEM_DIAGNOSIS_FRAGMENT = gql`
  fragment RoiListItemDiagnosis on diagnosis {
    id
    type
    code
    primaryLocation: primary_location
    diagnosisDate: diagnosis_date
    followUpId: follow_up_id
  }
`;

export type RoiListItemTumourFragmentType = {
  id: number;
  burdens: RoiListItemTumourBurdenType[];
  responses: RoiListItemTumourResponseType[];
  diagnosis: RoiListItemDiagnosisFragmentType;
  rois: { id: number }[];
};

export const ROI_LIST_ITEM_TUMOUR_FRAGMENT = gql`
  fragment RoiTumour on tumour {
    id
    burdens: tumour_burdens {
      ...RoiListItemTumourBurden
    }
    responses: tumour_responses {
      ...RoiListItemTumourResponse
    }
    diagnosis {
      ...RoiListItemDiagnosis
    }
    rois: tumour_roi_maps {
      id: roi_id
    }
  }
  ${ROI_LIST_ITEM_TUMOUR_BURDEN_FRAGMENT}
  ${ROI_LIST_ITEM_DIAGNOSIS_FRAGMENT}
  ${ROI_LIST_ITEM_TUMOUR_RESPONSE_FRAGMENT}
`;
