import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { MutationTuple } from "@apollo/client/react/types/types";

import { getCacheId } from "../../../../Manage/cache/getCacheId";

const MUTATION = gql`
  mutation InsertViewOnlySeries($seriesId: Int!, $projectId: Int!) {
    insert: insert_view_only_series_one(
      object: { series_id: $seriesId, project_id: $projectId, enabled: true }
    ) {
      seriesId: series_id
      projectId: project_id
      enabled
    }
  }
`;

type Variables = {
  seriesId: number;
  projectId: number;
};

type Data = {
  insert: {
    seriesId: number;
    projectId: number;
    enabled: boolean;
  };
};

export function useInsertViewOnlySeries(): MutationTuple<Data, Variables> {
  return useMutation<Data, Variables>(MUTATION, {
    update: (cache, { data }) => {
      if (!data) {
        throw new Error("Something went wrong updating the cache after inserting view_only_series");
      }

      const {
        insert: { projectId, seriesId, enabled },
      } = data;

      cache.modify({
        id: getCacheId(seriesId, "series", cache),
        fields: {
          view_only(view_only = []) {
            const newViewOnly = {
              __typename: "view_only_series",
              project_id: projectId,
              enabled: enabled,
            };
            return [...view_only, newViewOnly];
          },
        },
      });
    },
  });
}
