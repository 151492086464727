import { DayjsDateRange } from "../../../common/utils/dateFormatUtils/DayjsDateRange";
import { PatientType } from "../hooks/useProjectLabels";
import { isLesionInRange } from "./isLesionInRange";
import { isLesionTaskComplete } from "./isLesionTaskComplete";

export function getIncludedPatientLesions(
  patients: PatientType[],
  range: DayjsDateRange | undefined,
  includeIncompleteTasks: boolean
): PatientType[] {
  return patients.flatMap((patient) => {
    const { lesions, taskAssignments } = patient;
    const filteredLesions = lesions.filter(
      (lesion) =>
        (!range || isLesionInRange(lesion, range)) &&
        (includeIncompleteTasks || isLesionTaskComplete(lesion, taskAssignments))
    );

    if (filteredLesions.length === 0) {
      return [];
    }
    return { ...patient, lesions: filteredLesions };
  });
}
