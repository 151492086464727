import { gql } from "@apollo/client/core";

import { PROJECT_FRAGMENT } from "./ProjectFragment";

export const PROJECT_DETAILS_FRAGMENT = gql`
  fragment ProjectDetails on project {
    ...Project
    anonymizationMethods: project_anonymization_methods {
      id: anonymization_method_id
      params
    }
  }
  ${PROJECT_FRAGMENT}
`;
