import { SeriesFlagType } from "../../../../types/SeriesFlagType";

type getReasonsFromStudiesProps = {
  id: number;
  series: {
    id: number;
    seriesFlags: SeriesFlagType[];
  }[];
}[];
export function getReasonsFromStudies(studies: getReasonsFromStudiesProps): string[] {
  return Array.from(
    new Set(
      studies.flatMap(({ series }) =>
        series.flatMap(({ seriesFlags }) =>
          seriesFlags.flatMap(({ reasons }) => reasons.map(({ reason }) => reason))
        )
      )
    )
  );
}
