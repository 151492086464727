import React from "react";
import styled from "styled-components";

import ItemSubtext from "../../../common/components/text/ItemSubtext";
import ItemSubtext2 from "../../../common/components/text/ItemSubtext2";
import ItemText from "../../../common/components/text/ItemTextSize16";
import { ProjectStatusType } from "../../utils/ProjectStatusType";
import ProjectStatus from "../Project/ProjectStatus";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.neutral.neutral7};
  cursor: pointer;
  position: relative;
`;

const PreviewWrapper = styled.div`
  width: 260px;
  height: 161px;
  background: ${(props) => props.theme.colors.background.secondary};
`;

const HeaderWrapper = styled.div`
  padding-bottom: 8px;
`;

const TextWrapper = styled.div`
  padding: 16px;
`;

const TextSection = styled.div`
  padding-top: 16px;
`;

interface RecentItemProps {
  label: string;
  status: ProjectStatusType;
  fields: Record<string, string>;
  previewContent: JSX.Element;
  onClicked: () => void;
}

const StatusWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export function RecentItem({
  label,
  status,
  fields,
  previewContent,
  onClicked,
}: RecentItemProps): JSX.Element {
  return (
    <Wrapper onClick={onClicked}>
      <PreviewWrapper>{previewContent}</PreviewWrapper>
      <StatusWrapper>
        <ProjectStatus status={status} />
      </StatusWrapper>
      <TextWrapper>
        <HeaderWrapper>
          <ItemText text={label} />
        </HeaderWrapper>
        {Object.entries(fields).map(([label, value], index) => {
          return (
            <TextSection key={index}>
              <ItemSubtext2 text={label} />
              <ItemSubtext text={value} />
            </TextSection>
          );
        })}
      </TextWrapper>
    </Wrapper>
  );
}
