import { useContext } from "react";

import { useCurrentUserCan } from "../../../../../../common/contexts/UserContext/useCurrentUserCan";
import { ADMIN, PROJECT_ROLE_QC_ANNOTATOR } from "../../../../../../common/types/UserRoleType";
import { useProjectId } from "../../../../../../Project/hooks/useProjectId";
import { TaskContext } from "../../../../../TaskContext";
import { SeriesListItemFragmentType } from "../../AnnotationPanel/hooks/useStudiesList";
import { isSeriesViewOnly } from "../../AnnotationPanel/utils/isSeriesViewOnly";

export function useCanEditSeriesObservations(series: SeriesListItemFragmentType): boolean {
  const projectId = useProjectId();

  const { viewOnly: taskViewOnly } = useContext(TaskContext);

  const seriesViewOnly = isSeriesViewOnly(series, projectId);

  const isAdmin = useCurrentUserCan([PROJECT_ROLE_QC_ANNOTATOR, ADMIN]);

  return isAdmin || !(taskViewOnly || seriesViewOnly);
}
