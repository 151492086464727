import { TaskDataType } from "../../../common/store/annotatePage/taskSlice";
import { useFindPageByRoute } from "../hooks/useFindPageByRoute";
import { useLayout } from "../hooks/useLayout";
import { RoutePathSingleOrArray } from "../Layout";
import { PATIENT_SLUG, PROJECT_SLUG, TASK_SLUG } from "../RoutePathType";
import { getPageRoute } from "../utils/getPageRoute";
import { annotatePageRoute } from "./route";

type ReturnType = (task: TaskDataType) => RoutePathSingleOrArray;

export function useGetTaskRoute(): ReturnType {
  const layout = useLayout();
  const findPageByRoute = useFindPageByRoute();

  return (task) => {
    const { project } = layout;

    const annotatePage = findPageByRoute(project, annotatePageRoute);

    if (!annotatePage) {
      throw new Error("Unable to find annotate page in useGetTaskRoute");
    }

    const route = getPageRoute(annotatePage);

    return getTaskRoute(route, task);
  };
}

function getTaskRoute(
  route: RoutePathSingleOrArray,
  { projectId, patientId, taskId }: TaskDataType
): RoutePathSingleOrArray {
  if (!Array.isArray(route)) {
    throw new Error("annotateRoute should be an array");
  }

  return route.map((route) => {
    switch (route) {
      case PATIENT_SLUG:
        return patientId;
      case PROJECT_SLUG:
        return projectId;
      case TASK_SLUG:
        return taskId;
      default:
        return route;
    }
  });
}
