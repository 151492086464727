import React from "react";

import ProjectStatus from "../../../Dashboard/components/Project/ProjectStatus";
import { ProjectStatusType } from "../../../Dashboard/utils/ProjectStatusType";
import DetailGrid from "../DetailGrid";

const headers = [
  "Study Name",
  "Study Number",
  "Project Status",
  "Investigational Product",
  "Phase",
  "Project Arms",
  "Design",
  "Primary Endpoint(s)",
  "Secondary Endpoints(s)",
  "Expected Completion",
];

interface ProjectDetailsPanelProps {
  details: string[];
}

function ProjectDetailsPanel({ details }: ProjectDetailsPanelProps): React.ReactElement {
  const detailInfo = headers.map((header, i) => {
    return i === 2
      ? {
          title: header,
          value: <ProjectStatus status={details[i] as ProjectStatusType} />,
        }
      : { title: header, value: details[i] };
  });

  return <DetailGrid details={detailInfo} numColumns={5} />;
}

export default ProjectDetailsPanel;
