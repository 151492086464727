import { useDeepCompareCallback } from "use-deep-compare";

import { ViewerConfigType } from "../../../../../common/store/annotatePage/ViewerConfigType";
import { useProjectId } from "../../../../../Project/hooks/useProjectId";
import { TimepointType } from "../AnnotationPanel/hooks/Timepoint/TimepointType";
import { getNextViewerConfigs } from "../ViewerPanel/utils/getNextViewerConfigs";
import { useClearViewers } from "../ViewerPanel/Viewer/hooks/useClearViewers";
import { useJumpToViewer } from "../ViewerPanel/Viewer/hooks/useJumpToViewer";
import { useSetViewers } from "../ViewerPanel/Viewer/hooks/useSetViewers";

type ReturnType = {
  createViewers: (timepoints: TimepointType[], numberOfViewers: number) => ViewerConfigType[];
  clearViewers: () => void;
};

export function useViewerCreator(): ReturnType {
  const jumpToViewer = useJumpToViewer();
  const clearViewers = useClearViewers();
  const setViewers = useSetViewers();
  const projectId = useProjectId();

  const createViewers = useDeepCompareCallback(
    (timepoints: TimepointType[], numberOfViewers: number) => {
      const configs = getNextViewerConfigs(timepoints, [], numberOfViewers, projectId);

      setViewers(configs);

      for (const config of configs) {
        jumpToViewer(config);
      }
      return configs;
    },
    [projectId, jumpToViewer, setViewers]
  );

  return { createViewers, clearViewers };
}
