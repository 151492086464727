import React from "react";

import { ViewerConfigType } from "../../../../../common/store/annotatePage/ViewerConfigType";
import {
  ToolControllerCallbacks,
  useToolController,
} from "./tools/toolController/useToolController";

interface ToolControllerProps {
  viewerConfig: ViewerConfigType;
  element: HTMLDivElement | null;
  callbacks: ToolControllerCallbacks;
}

// FIXME: There are 2 ToolControllers, 2 useToolControllers, and an AnnotateViewerToolController. See #185044274
// --B

export function ToolController({
  viewerConfig,
  element,
  callbacks,
}: ToolControllerProps): JSX.Element {
  useToolController(viewerConfig, element, callbacks);

  return <></>;
}
