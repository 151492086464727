import Tooltip from "@mui/material/Tooltip";
import React, { useState } from "react";
import styled from "styled-components";

import { SvgIcon } from "../../../../../../common/components/icons/SvgIcon";
import { ToolType } from "../../../../../../cornerstone/ToolType";
import { getToolbarButtonColor } from "./getToolbarButtonColor";

const Wrapper = styled.div`
  cursor: pointer;
`;

interface ToolbarNodeProps {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  value: ToolType;
  currentValue: ToolType;
  onChange: (value: ToolType) => void;
  tooltip?: string;
}

export function ToolbarRadioButton({
  icon,
  value,
  currentValue,
  onChange,
  tooltip,
}: ToolbarNodeProps): JSX.Element {
  const [hover, setHover] = useState(false);

  function handleMouseOver() {
    setHover(true);
  }

  function handleMouseOut() {
    setHover(false);
  }

  const colour = getToolbarButtonColor(value === currentValue, hover);

  return (
    <Tooltip title={tooltip}>
      <Wrapper
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onClick={() => onChange(value)}
      >
        <SvgIcon icon={icon} size={20} color={colour} />
      </Wrapper>
    </Tooltip>
  );
}
