import React from "react";
import { ColumnInstance } from "react-table";
import styled from "styled-components";

import { DefaultHeaderCell } from "./DefaultHeaderCell";
import { TableDataType } from "./TableDataType";

const HeaderCellContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
`;

interface DefaultIconFilterHeaderProps<T extends TableDataType> {
  column: ColumnInstance<T>;
  children: JSX.Element;
}

export function DefaultIconFilterHeader<T extends TableDataType>({
  column,
  children,
}: DefaultIconFilterHeaderProps<T>): JSX.Element {
  return (
    <HeaderCellContent>
      <DefaultHeaderCell column={column} />
      {children}
    </HeaderCellContent>
  );
}
