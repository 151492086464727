import React from "react";
import styled from "styled-components";

const StyledTableHeader = styled.th`
  font-size: 13px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.neutral.neutral5};
  font-weight: 400;
`;
interface TableHeaderTextProps {
  text: string;
}

export default function TableHeaderText({ text }: TableHeaderTextProps): React.ReactElement {
  return <StyledTableHeader>{text}</StyledTableHeader>;
}
