import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { MutationTuple } from "@apollo/client/react/types/types";

import { getCacheId } from "../../../../Manage/cache/getCacheId";

const MUTATION = gql`
  mutation DeleteViewOnlySeries($seriesId: Int!, $projectId: Int!) {
    delete: delete_view_only_series(
      where: { _and: [{ series_id: { _eq: $seriesId } }, { project_id: { _eq: $projectId } }] }
    ) {
      returning {
        seriesId: series_id
        projectId: project_id
        enabled
      }
    }
  }
`;

type Variables = {
  seriesId: number;
  projectId: number;
};

type Data = {
  delete: {
    returning: {
      seriesId: number;
      projectId: number;
      enabled: boolean;
    }[];
  };
};

export function useDeleteViewOnlySeries(): MutationTuple<Data, Variables> {
  return useMutation<Data, Variables>(MUTATION, {
    update: (cache, { data }) => {
      if (!data) {
        throw new Error("Something went wrong updating the cache after deleting view_only_series");
      }

      const {
        delete: { returning },
      } = data;

      if (returning.length !== 1) {
        throw new Error("0 or more than 1 record were updated when deleting view_only_series");
      }
      const { projectId, seriesId } = returning[0];

      cache.modify({
        id: getCacheId(seriesId, "series", cache),
        fields: {
          view_only(
            view_only: { project_id: number; enabled: boolean; __typename: string }[] = []
          ) {
            const index = view_only.findIndex(({ project_id }) => project_id === projectId);

            if (index === -1) {
              return [...view_only];
            }

            return [...view_only.slice(0, index), ...view_only.slice(index + 1)];
          },
        },
      });
    },
  });
}
