import { ApolloCache } from "@apollo/client";

import { CohortType } from "../../../../common/types/CohortType";
import {
  Data as GlobalCohortsData,
  QUERY as GLOBAL_COHORTS_QUERY,
  Variables as GlobalCohortsVariables,
} from "../Project/useGlobalCohorts";
import {
  Data as ProjectCohortsData,
  QUERY as PROJECT_COHORTS_QUERY,
  Variables as ProjectCohortsVariables,
} from "./useProjectCohorts";

export function updateCohortsInCache(updatedCohort: CohortType, cache: ApolloCache<unknown>) {
  const projectId = updatedCohort.project_id;

  if (projectId) {
    const { cohorts: existingCohorts } = cache.readQuery<
      ProjectCohortsData,
      ProjectCohortsVariables
    >({
      query: PROJECT_COHORTS_QUERY,
      variables: { projectId },
    }) ?? { cohorts: [] };

    const projectCohorts = updateCohortsList(updatedCohort, existingCohorts);

    cache.writeQuery<ProjectCohortsData, ProjectCohortsVariables>({
      query: PROJECT_COHORTS_QUERY,
      variables: { projectId },
      data: { cohorts: projectCohorts },
    });
  } else {
    const { cohorts: existingCohorts } = cache.readQuery<GlobalCohortsData, GlobalCohortsVariables>(
      {
        query: GLOBAL_COHORTS_QUERY,
      }
    ) ?? { cohorts: [] };

    const globalCohorts = updateCohortsList(updatedCohort, existingCohorts);

    cache.writeQuery<GlobalCohortsData, GlobalCohortsVariables>({
      query: GLOBAL_COHORTS_QUERY,
      data: { cohorts: globalCohorts },
    });
  }
}

function updateCohortsList(updatedCohort: CohortType, existingCohorts: CohortType[]): CohortType[] {
  const { id: updatedCohortId } = updatedCohort;
  const updatedCohorts = existingCohorts.map((cohort) => {
    const { id } = cohort;
    if (id !== updatedCohortId) {
      return cohort;
    }

    return updatedCohort;
  });

  if (!updatedCohorts.includes(updatedCohort)) {
    updatedCohorts.push(updatedCohort);
  }

  return updatedCohorts;
}
