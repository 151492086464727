import React, { Dispatch } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import styled from "styled-components";

import { ClassificationsMapType } from "../../../../../common/store/annotatePage/ClassificationsMapType";
import {
  ClassificationsDefinitionsType,
  setLesionClassificationsProxyMap,
  setLesionEditing,
  setLesionSelectedAnatomicalStructure,
} from "../../../../../common/store/annotatePage/classificationsSlice";
import { ANNOTATION_SCOPE } from "../../../../../Project/Layout/Layout";
import { AnatomicalStructureOptionType } from "./AnatomicalStructureSelector/AnatomicalStructureOptionType";
import { AnatomyClassificationSelectorContainer } from "./AnatomicalStructureSelector/AnatomyClassificationSelectorContainer";
import { ClassificationsSelector } from "./ClassificationsSelector";
import { LesionListItemFragmentType } from "./fragments/LesionListItemFragment";
import { LesionModeReturnType } from "./hooks/useGetLesionMode";
import { useHandleClassificationsSaved } from "./hooks/useHandleClassificationsSaved";
import { LesionClassificationOperationButton } from "./LesionClassificationOperationButton";
import { areClassificationsComplete } from "./utils/areClassificationsComplete";
import { areClassificationsEqual } from "./utils/areClassificationsEqual";
import { isLesionLesion } from "./utils/isLesionLesion";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  gap: 6px;
  padding: 3px 3px 3px 8px;
`;

interface LesionClassificationsSelectorWrapperProps {
  lesion: LesionListItemFragmentType;
  viewOnly: boolean;
  lesionClassificationsDefinitions: Record<number, ClassificationsDefinitionsType>;
  getLesionMode: LesionModeReturnType;
  dispatch: Dispatch<any>;
  onSaved?: () => void;
}

export function LesionClassificationsSelector({
  lesion,
  viewOnly,
  lesionClassificationsDefinitions,
  getLesionMode,
  dispatch,
  onSaved,
}: LesionClassificationsSelectorWrapperProps): JSX.Element | null {
  const lesionMode = getLesionMode(lesion);

  const { id: lesionId, location: lesionLocationId } = lesion;

  const { callback: handleLesionClassificationsSaved, dialog: invalidClassificationsDialog } =
    useHandleClassificationsSaved(lesion, onSaved);

  const {
    classificationsMapProxy,
    classificationsMapInitial: initialClassificationsMap,
    useAnatomySelector,
    selectedAnatomicalStructure,
  } = lesionClassificationsDefinitions[lesionId] ?? {};

  const canSave =
    lesionClassificationsDefinitions[lesionId] &&
    areClassificationsComplete(
      classificationsMapProxy,
      useAnatomySelector,
      selectedAnatomicalStructure
    ) &&
    !areClassificationsEqual(
      classificationsMapProxy,
      initialClassificationsMap,
      useAnatomySelector,
      lesionLocationId,
      selectedAnatomicalStructure?.value?.id
    );

  useHotkeys(
    "s",
    () => {
      if (canSave) {
        handleLesionClassificationsSaved();
      }
    },
    { scopes: [ANNOTATION_SCOPE] }
  );

  if (!lesionClassificationsDefinitions[lesionId]) {
    return null;
  }

  const handleClassificationChanged = (classificationsMap: ClassificationsMapType) => {
    dispatch(setLesionClassificationsProxyMap({ lesionId, classificationsMap }));

    dispatch(setLesionEditing({ lesionId, editing: !canSave }));
  };

  const handleAnatomicalLocationChanged = (
    anatomicalStructure: AnatomicalStructureOptionType | null
  ) => {
    dispatch(
      setLesionSelectedAnatomicalStructure({
        lesionId,
        anatomicalStructure,
      })
    );

    dispatch(setLesionEditing({ lesionId, editing: !canSave }));
  };

  const showAnatomicalSelector = useAnatomySelector && isLesionLesion(lesion);

  return (
    <Wrapper>
      {invalidClassificationsDialog}
      <ClassificationsSelector
        classificationsMap={classificationsMapProxy}
        onClassificationsChanged={handleClassificationChanged}
        mode={lesionMode}
        disabled={viewOnly}
      />
      {showAnatomicalSelector && (
        <AnatomyClassificationSelectorContainer
          lesion={lesion}
          selected={selectedAnatomicalStructure}
          onSelected={handleAnatomicalLocationChanged}
          disabled={viewOnly}
        />
      )}
      {!viewOnly && (
        <LesionClassificationOperationButton
          onSavedClicked={handleLesionClassificationsSaved}
          canSave={canSave}
        />
      )}
    </Wrapper>
  );
}
