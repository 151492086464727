import { LesionListItemFragmentType } from "../../AnnotationPanel/fragments/LesionListItemFragment";

export function getSelectedLesion(
  lesions: LesionListItemFragmentType[],
  selectedLesionId: number | null
): LesionListItemFragmentType | null {
  if (selectedLesionId === null) {
    return null;
  }

  return lesions.find(({ id }) => selectedLesionId === id) || null;
}
