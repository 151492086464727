import React, { ReactNode } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
  flex-grow: 1;
  align-self: stretch;
`;

const CardWrapper = styled.div<{ isLast: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: ${({ isLast }) => (!isLast ? "0 0 8px 0" : "0 0 0 0")};
`;

const OuterCard = styled.div`
  position: relative;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  overflow: hidden;
`;

const Card = styled.div<{
  background: string;
}>`
  font-weight: 600;
  font-size: 13px;

  background: ${(props) => props.background};
  flex: 1;
`;

export interface TimelineItemProps {
  isLast: boolean;
  background: string;
  progressBar: ReactNode;
  children: ReactNode;
}

export function TimelineItem({
  background,
  progressBar,
  children,
  isLast,
}: TimelineItemProps): JSX.Element {
  return (
    <Wrapper>
      {progressBar}
      <CardWrapper isLast={isLast}>
        <OuterCard>
          <Card background={background}>{children}</Card>
        </OuterCard>
      </CardWrapper>
    </Wrapper>
  );
}
