import "./wdyr";
import "./assets/css/fonts.css";
import "./assets/css/style.css";

import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import initCornerstone from "./cornerstone/initCornerstone";
import { initTelemetry } from "./telemetry";

console.log(
  `Nota\n
  Version: ${window._env_.RELEASE}\n
  Deployed: ${new Date(window._env_.RELEASE_DATE).toString()}\n
  Environment: ${window._env_.REACT_APP_ENVIRONMENT}\n
  Commit: ${window._env_.RELEASE_SHA}`
);

initCornerstone();
initTelemetry();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
