import { ApolloCache } from "@apollo/client";
import React from "react";
import styled from "styled-components";

import { ViewerConfigType } from "../../../../../../common/store/annotatePage/ViewerConfigType";
import { main } from "../../../../../../common/theme/main";
import { StatsType } from "../../../../../types/TaskDescriptionType";
import { LabelIndicator } from "../../common/LabelIndicator";
import { LesionListItemFragmentType } from "../fragments/LesionListItemFragment";
import { RoiListItemFragmentType } from "../fragments/RoiListItemFragment";
import { ExistingRoiClassificationButtonsReturnType } from "../hooks/timepointOptions/useGetExistingRoiClassificationButtons";
import { MissingRoiClassificationButtonsReturnType } from "../hooks/timepointOptions/useGetMissingRoiClassificationButtons";
import { RoiViewOnlyOptionsReturnType } from "../hooks/timepointOptions/useGetRoiViewOnlyOptions";
import { TimepointOptionsFromRoiClassificationsReturnType } from "../hooks/timepointOptions/useGetTimepointOptionsFromRoiClassifications";
import { DeleteRoisReturnType } from "../hooks/useDeleteRois";
import { InsertLesionClassificationsInternalReturnType } from "../hooks/useInsertLesionClassification";
import { InsertRoiClassificationsReturnType } from "../hooks/useInsertRoiClassifications";
import { InsertRoisReturnType } from "../hooks/useInsertRois";
import { OnRoiClickedReturnType } from "../hooks/useOnRoiClicked";
import { OnSeriesClickedReturnType } from "../hooks/useOnSeriesClicked";
import { SeriesListItemFragmentType } from "../hooks/useStudiesList";
import { Mask } from "../MaskContextProvider";
import { DownloadState } from "../Masks/types/downloadState";
import { ViewerTimelineItem } from "../Timeline/ViewerTimelineItem";
import { MissingRoiCard } from "./MissingRoiCard";
import { RoiCardWrapper } from "./RoiCardWrapper";

export const CardWrapper = styled.div<{ roiMissing: boolean; background?: string }>`
  display: flex;
  background-color: ${({ background }) => (background ? background : "unset")};

  &:not(:last-child) {
    border-bottom: ${({ theme, roiMissing }) =>
      `1px ${
        roiMissing ? theme.colors.actionSecondary.default : theme.colors.neutral.neutral7
      } solid`};
  }

  padding: 3px;
`;

export const RoiWrapper = styled.div`
  width: 100%;
`;

const TimepointLabelText = styled.div<{ roiMissing: boolean; background: string }>`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  flex-shrink: 0;
  padding-left: 14px;
  background-color: ${({ background }) => background};
  border-bottom: ${({ theme, roiMissing }) =>
    `1px ${
      roiMissing ? theme.colors.actionSecondary.default : theme.colors.neutral.neutral7
    } solid`};
`;

export const IndicatorWrapper = styled.div`
  padding: 4px 0 4px 4px;
`;

interface SeriesRoiCardWrapperProps {
  rois: RoiListItemFragmentType[];
  isFirst: boolean;
  isLast: boolean;
  seriesList: SeriesListItemFragmentType[];
  lesion: LesionListItemFragmentType;
  label: string;
  isFromCurrentTask: boolean;
  getViewerForSeries: (seriesId: number) => ViewerConfigType | undefined;
  getRoiViewOnlyOptions: RoiViewOnlyOptionsReturnType;
  getIsLesionFromCurrentTask: (lesion: Pick<LesionListItemFragmentType, "taskId">) => boolean;
  canClassify: boolean;
  canDelete: boolean;
  onToggleMaskVisibility: (mask: Mask) => Promise<boolean>;
  getMaskDownloadState: (maskId: number) => DownloadState | undefined;
  jumpToViewerRoi: (roi: RoiListItemFragmentType) => void;
  getTimepointOptionsFromRoiClassifications: TimepointOptionsFromRoiClassificationsReturnType;
  headerStats: StatsType[];
  bodyStats: StatsType[];
  getExistingRoiClassificationButtons: ExistingRoiClassificationButtonsReturnType;
  canAdd: boolean;
  getMissingRoiTimepointOptions: MissingRoiClassificationButtonsReturnType;
  handleRoiSelected: OnRoiClickedReturnType;
  handleSeriesSelected: OnSeriesClickedReturnType;
  deleteRois: DeleteRoisReturnType;
  useInsertRoisObj: InsertRoisReturnType;
  updateTaskInProgress: () => Promise<void>;
  onTumourBurdenChanged: (lesionId: number) => Promise<void>;
  insertLesionClassificationsObj: InsertLesionClassificationsInternalReturnType;
  insertRoiClassificationsObj: InsertRoiClassificationsReturnType;
  updateLesionClassificationsForMode: (
    isBaseline: boolean,
    lesion: LesionListItemFragmentType
  ) => Promise<void>;
  userId: number;
  cache: ApolloCache<object>;
  isRecist: boolean;
}

export function SeriesRoiCardWrapper({
  rois,
  isFirst,
  isLast,
  seriesList,
  lesion,
  label,
  isFromCurrentTask,
  getViewerForSeries,
  getRoiViewOnlyOptions,
  getIsLesionFromCurrentTask,
  canClassify,
  canDelete,
  onToggleMaskVisibility,
  getMaskDownloadState,
  jumpToViewerRoi,
  getTimepointOptionsFromRoiClassifications,
  headerStats,
  bodyStats,
  getExistingRoiClassificationButtons,
  canAdd,
  getMissingRoiTimepointOptions,
  handleRoiSelected,
  handleSeriesSelected,
  deleteRois,
  useInsertRoisObj,
  updateTaskInProgress,
  onTumourBurdenChanged,
  insertLesionClassificationsObj,
  insertRoiClassificationsObj,
  updateLesionClassificationsForMode,
  userId,
  cache,
  isRecist,
}: SeriesRoiCardWrapperProps): JSX.Element {
  if (seriesList.length === 0) {
    throw new Error("No seriesIds found for MissingRoiCard");
  }

  const { origin } = lesion;

  const isMissingPredictedMasks =
    origin === "PREDICTION" && rois.every(({ masks }) => masks.length === 0);

  const titleBackground =
    rois.length === 0
      ? isFromCurrentTask
        ? main.colors.states.success
        : main.colors.states.successDisabled
      : "unset";
  const isMulti = seriesList.length > 1;
  return (
    <ViewerTimelineItem isFirst={isFirst} isLast={isLast} seriesList={seriesList}>
      <>
        {isMulti && (
          <TimepointLabelText roiMissing={rois.length === 0} background={titleBackground}>
            {label}
          </TimepointLabelText>
        )}
        {seriesList.map((series) => {
          const roi = rois.find(({ series: { id: seriesId } }) => seriesId === series.id);
          const viewer = getViewerForSeries(series.id);
          const accentColor = viewer?.color || main.colors.transparent.transparent;
          const background = !roi
            ? isFromCurrentTask
              ? main.colors.states.success
              : main.colors.states.successDisabled
            : "unset";
          return (
            <CardWrapper roiMissing={!roi} key={series.id} background={background}>
              <IndicatorWrapper>
                <LabelIndicator height={"100%"} color={accentColor} />
              </IndicatorWrapper>
              <RoiWrapper>
                {roi && !isMissingPredictedMasks ? (
                  <RoiCardWrapper
                    series={series}
                    roi={roi}
                    onSelected={handleRoiSelected}
                    isMulti={isMulti}
                    lesion={lesion}
                    label={label}
                    isFirst={isFirst}
                    getRoiViewOnlyOptions={getRoiViewOnlyOptions}
                    getIsLesionFromCurrentTask={getIsLesionFromCurrentTask}
                    isFromCurrentTask={isFromCurrentTask}
                    canClassify={canClassify}
                    canDelete={canDelete}
                    onToggleMaskVisibility={onToggleMaskVisibility}
                    getMaskDownloadState={getMaskDownloadState}
                    jumpToViewerRoi={jumpToViewerRoi}
                    getTimepointOptionsFromRoiClassifications={
                      getTimepointOptionsFromRoiClassifications
                    }
                    headerStats={headerStats}
                    bodyStats={bodyStats}
                    getExistingRoiClassificationButtons={getExistingRoiClassificationButtons}
                    deleteRois={deleteRois}
                    updateTaskInProgress={updateTaskInProgress}
                    onTumourBurdenChanged={onTumourBurdenChanged}
                    insertLesionClassificationsObj={insertLesionClassificationsObj}
                    insertRoiClassificationsObj={insertRoiClassificationsObj}
                    updateLesionClassificationsForMode={updateLesionClassificationsForMode}
                  />
                ) : (
                  <MissingRoiCard
                    isFirst={isFirst}
                    isMulti={isMulti}
                    series={series}
                    onSelected={handleSeriesSelected}
                    label={label}
                    lesion={lesion}
                    canAdd={canAdd}
                    canClassify={canClassify}
                    getMissingRoiTimepointOptions={getMissingRoiTimepointOptions}
                    useInsertRoisObj={useInsertRoisObj}
                    updateTaskInProgress={updateTaskInProgress}
                    onTumourBurdenChanged={onTumourBurdenChanged}
                    insertLesionClassificationsObj={insertLesionClassificationsObj}
                    userId={userId}
                    cache={cache}
                    isRecist={isRecist}
                  />
                )}
              </RoiWrapper>
            </CardWrapper>
          );
        })}
      </>
    </ViewerTimelineItem>
  );
}
SeriesRoiCardWrapper.whyDidYouRender = true;
