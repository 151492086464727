import { useEffect } from "react";

import { FlexLoading } from "../../../../../common/components/Loading";
import { useTimepoints } from "../AnnotationPanel/hooks/Timepoint/useTimepoints";
import { TimepointList } from "./TimepointList";
import { mapTimepointsFilter } from "./utils/mapTimepointsFilter";

interface TimepointListContainerProps {
  filter?: string;
  onFilterChanged?: (matchesCount: number) => void;
}

export function TimepointListContainer({ filter, onFilterChanged }: TimepointListContainerProps) {
  const { timepoints, excludedStudyCount } = useTimepoints();
  const { timepoints: timepointsWithFilter, matchesCount } = mapTimepointsFilter(
    timepoints,
    filter
  );

  useEffect(() => {
    onFilterChanged?.(matchesCount);
  }, [matchesCount, onFilterChanged]);

  return !timepoints ? (
    <FlexLoading />
  ) : (
    <TimepointList timepoints={timepointsWithFilter} excludedStudyCount={excludedStudyCount} />
  );
}
