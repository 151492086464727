import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { QueryResult } from "@apollo/client/react/types/types";

import { TaskProgressType } from "../../../Annotate/components/TaskWorklist/TaskProgressType";

type Data = {
  taskAssignments: TaskReassignmentKeys[];
};

export type TaskReassignmentKeys = {
  id: number;
  taskId: number;
  patientId: number;
  progress: TaskProgressType;
  task: { projectId: number };
};

type Variables = {
  filter: GetUsersTaskAssignmentsQueryFilter;
};

type GetUsersTaskAssignmentsQueryFilter = {
  _and: { user_id: { _in: number[] } };
  task?: { project_id: { _eq: number } };
};

const GET_USERS_TASK_ASSIGNMENTS_QUERY = gql`
  query GetUsersTaskAssignments($filter: task_assignment_bool_exp!) {
    taskAssignments: task_assignment(where: $filter) {
      id
      taskId: task_id
      patientId: patient_id
      progress
      task {
        projectId: project_id
      }
    }
  }
`;

export function useGetUsersTaskAssignments(
  filter: GetUsersTaskAssignmentsQueryFilter
): QueryResult<Data, Variables> {
  return useQuery<Data, Variables>(GET_USERS_TASK_ASSIGNMENTS_QUERY, {
    variables: { filter },
  });
}

export function buildGetUsersTaskAssignmentsFilter(userIds: number[], projectId?: number) {
  const filter: GetUsersTaskAssignmentsQueryFilter = {
    _and: { user_id: { _in: userIds } },
  };
  if (projectId) {
    filter.task = { project_id: { _eq: projectId } };
  }

  return filter;
}
