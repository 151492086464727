import React from "react";

import { BigFlexLoading } from "../../components/Loading";
import { useCurrentUserQuery } from "./useCurrentUserQuery";
import { UserContext } from "./UserContext";

interface UserContextProviderProps {
  children: JSX.Element;
}

export function UserContextProvider({ children }: UserContextProviderProps): JSX.Element {
  const { data, loading } = useCurrentUserQuery();

  if (loading) {
    return <BigFlexLoading>Loading user metadata...</BigFlexLoading>;
  }

  if (!data) {
    throw new Error("No data returned in UserContextProvider");
  }

  const { user, allProjectUserRoles } = data;

  return (
    <UserContext.Provider value={{ user, allProjectUserRoles }}>{children}</UserContext.Provider>
  );
}
