import { ApolloClient, gql, useQuery } from "@apollo/client";
import { QueryResult } from "@apollo/client/react/types/types";
import { useContext } from "react";
import { useDeepCompareMemo } from "use-deep-compare";

import { PatientContext } from "../../../../../../Dashboard/components/Settings/Patients/Viewer/PatientContext";
import { BASE_WADO_URL_FRAGMENT } from "../../../../../fragments/BaseWadoUrlFragment";
import { BaseWadoUrlFragmentType } from "../../../../../fragments/BaseWadoUrlFragmentType";
import { SERIES_CLASSIFICATION_FRAGMENT } from "../../../../../fragments/SeriesClassificationFragment";
import { SERIES_FLAG_FRAGMENT } from "../../../../../fragments/SeriesFlagFragment";
import { SeriesNotesFragmentType } from "../../../../../fragments/SeriesNotesFragmentType";
import { TaskContext } from "../../../../../TaskContext";
import { SeriesClassificationType } from "../../../../../types/SeriesClassificationType";
import { SeriesFlagType } from "../../../../../types/SeriesFlagType";
import { DEBUG_STUDIES_LIST_LIMIT } from "./debugUtils";

type InstanceListItemFragmentType = {
  id: number;
  seriesId: number;
  sopInstanceUid: string | null;
  instanceNumber: number | null;
  sliceThickness: number | null;
  imagePositionPatient: number[] | null;
  imageOrientationPatient: number[] | null;
};

const INSTANCE_LIST_ITEM_FRAGMENT = gql`
  fragment InstanceListItem on instance {
    id: instance_id
    seriesId: series_id
    instanceNumber: instance_number
    sopInstanceUid: sop_instance_uid
    sliceThickness: slice_thickness
    imagePositionPatient: image_position_patient
    imageOrientationPatient: image_orientation_patient
  }
`;

export type SeriesListItemFragmentType = {
  id: number;
  studyId: number;
  seriesInstanceUid: string;
  seriesDescription: string | null;
  seriesClassifications: SeriesClassificationType[];
  projectSeriesExclusions: { projectId: number }[];
  seriesDate: string | null;
  viewOnly: {
    enabled: boolean;
    projectId: number;
  }[];
  instances: InstanceListItemFragmentType[];
  seriesFlags: SeriesFlagType[];
  notes: SeriesNotesFragmentType[];
};

export type FilterableSeriesListItemFragmentType = SeriesListItemFragmentType & {
  filtered?: boolean;
};

export const SERIES_NOTE_FRAGMENT = gql`
  fragment SeriesNotes on series_notes {
    id
    seriesId: series_id
    createdBy: created_by
    notes
  }
`;

export const SERIES_LIST_ITEM_FRAGMENT = gql`
  fragment SeriesListItem on series {
    id: series_id
    studyId: study_id
    seriesInstanceUid: series_instance_uid
    seriesDescription: series_description
    seriesDate: series_date
    viewOnly: view_only(where: { project_id: { _in: $projectIds } }) {
      enabled
      projectId: project_id
    }
    seriesClassifications: series_classifications {
      ...SeriesClassification
    }
    projectSeriesExclusions: project_series_exclusions {
      projectId: project_id
    }
    instances {
      ...InstanceListItem
    }
    seriesFlags: series_flags(where: { task: { project_id: { _in: $projectIds } } }) {
      ...SeriesFlag
    }
    notes: series_notes {
      ...SeriesNotes
    }
  }
  ${SERIES_CLASSIFICATION_FRAGMENT}
  ${INSTANCE_LIST_ITEM_FRAGMENT}
  ${SERIES_FLAG_FRAGMENT}
  ${SERIES_NOTE_FRAGMENT}
`;

export type StudyListItemFragmentType = {
  id: number;
  studyInstanceUid: string;
  studyDate: string | null;
  series: SeriesListItemFragmentType[];
  studyFollowUps: {
    followUp: {
      order: number;
    };
  }[];
};

export const STUDY_LIST_ITEM_FRAGMENT = gql`
  fragment StudyListItem on study {
    id: study_id
    studyInstanceUid: study_instance_uid
    studyDate: study_date
    studyFollowUps: study_follow_up {
      followUp: follow_up {
        id
        order: follow_up_order
      }
    }
    series(where: $seriesFilter, order_by: [{ series_date: asc }]) {
      ...SeriesListItem
    }
  }
  ${SERIES_LIST_ITEM_FRAGMENT}
`;

const QUERY = gql`
  query GetStudiesByCaseId($projectIds: [Int!]!, $studyFilter: study_bool_exp!, $seriesFilter: series_bool_exp!) {
    studies: study(
      limit: ${DEBUG_STUDIES_LIST_LIMIT}
      where: $studyFilter
      order_by: [
        { follow_up_map: { follow_up: { follow_up_order: asc } } }
        { study_date: asc }
        { series_aggregate: { min: { series_date: asc } } }
      ]
    ) {
      ...StudyListItem
    }
    ...BaseWadoUrl
  }
  ${STUDY_LIST_ITEM_FRAGMENT}
  ${BASE_WADO_URL_FRAGMENT}
`;

type Data = {
  studies: StudyListItemFragmentType[];
  wadoUrls: BaseWadoUrlFragmentType;
};

type Variables = {
  projectIds: number[];
  studyFilter: StudyFilter;
  seriesFilter: SeriesFilter;
};

type Input = {
  id: number;
  task: {
    projectId: number;
    restrictedCohorts: { id: number }[];
  };
};

type StudyFilter =
  | {
      _and: [
        { patient_id: { _eq: number } },
        { patients_with_children_and_cohorts: { cohort_id: { _in: number[] } } }
      ];
    }
  | { patient_id: { _eq: number } };

type SeriesFilter =
  | {
      _and: [
        { _not: { project_series_exclusions: { project_id: { _in: number[] } } } },
        {
          patients_with_children_and_cohorts: { cohort_id: { _in: number[] } };
        }
      ];
    }
  | { _not: { project_series_exclusions: { project_id: { _in: number[] } } } };

export function useStudiesList(): QueryResult<Data, Variables> {
  const { task } = useContext(TaskContext);
  const { id } = useContext(PatientContext);
  const query = useQuery<Data, Variables>(QUERY, {
    variables: getVariables({ id, task }),
  });
  return useDeepCompareMemo(() => query, [query]);
}

export function getVariables({
  id: patientId,
  task: { projectId, restrictedCohorts },
}: Input): Variables {
  const cohorts = restrictedCohorts.map(({ id }) => id);
  const studyFilter: StudyFilter =
    restrictedCohorts.length > 0
      ? {
          _and: [
            { patient_id: { _eq: patientId } },
            { patients_with_children_and_cohorts: { cohort_id: { _in: cohorts } } },
          ],
        }
      : { patient_id: { _eq: patientId } };
  const seriesFilter: SeriesFilter =
    restrictedCohorts.length > 0
      ? {
          _and: [
            { _not: { project_series_exclusions: { project_id: { _in: [projectId] } } } },
            {
              patients_with_children_and_cohorts: { cohort_id: { _in: cohorts } },
            },
          ],
        }
      : { _not: { project_series_exclusions: { project_id: { _in: [projectId] } } } };

  return {
    projectIds: [projectId],
    studyFilter: studyFilter,
    seriesFilter: seriesFilter,
  };
}

// TODO: This should be a hook here not an async function because the query is being recreated
// on every render --B
// see See #184924509
export async function getStudiesList(
  client: ApolloClient<unknown>,
  taskContext: Input
): Promise<Data> {
  const variables = getVariables(taskContext);
  const { data } = await client.query<Data, Variables>({
    query: QUERY,
    variables,
  });
  return data;
}
