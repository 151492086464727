import React, { FC, ReactElement } from "react";
import styled from "styled-components";

import AnalysisMenu from "../../../Analysis/common/components/AnalysisMenu";

const Wrapper = styled.div<{ expand?: boolean }>`
  height: ${({ expand }) => (expand ? "100%" : "unset")};
  box-sizing: border-box;
`;

const CardHalf = styled.div<{ clickable?: boolean; shadow?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 24px;

  box-sizing: border-box;

  height: 100%;
  min-height: 40px;
  min-width: 200px;

  background: ${(props) => props.theme.colors.actionPrimary.inverted};
  box-shadow: ${(props) => (props.shadow ? "0 1px 3px rgba(0, 0, 0, 0.3)" : "none")};
  border-radius: 12px;

  cursor: ${(props) => (props.clickable ? "pointer" : "default")};
`;

const CardHeaderWrapper = styled.div`
  width: 100%;
  height: 32px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
`;

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

interface CardHeaderTextProps {
  clickable?: boolean;
}

const CardHeaderText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  cursor: ${(props: CardHeaderTextProps) => (props.clickable ? "pointer" : "auto")};
`;

const CardContent = styled.div<{ noHead: boolean | undefined }>`
  flex: 1;
  margin-top: ${(props) => (props.noHead ? "0px" : "24px")};

  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

interface CardProps {
  title?: string;
  content: ReactElement;
  // eslint-disable-next-line no-unused-vars
  onClickHeader?: (event: React.MouseEvent<HTMLDivElement>) => void;
  noHead?: boolean;
  noShadow?: boolean;
  noMenu?: boolean;
  onClickCard?: () => void;
  expand?: boolean;
  actionButtons?: ReactElement;
}

export const Card: FC<CardProps> = ({
  title,
  content,
  onClickHeader,
  noMenu = true,
  noHead,
  noShadow,
  onClickCard,
  expand,
  actionButtons,
}: CardProps) => {
  const handleClickHeader = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!onClickHeader) {
      return;
    }
    onClickHeader(e);
  };

  const handleCardClick = () => {
    if (!onClickCard) {
      return;
    }

    onClickCard();
  };

  return (
    <Wrapper expand={expand}>
      <CardHalf onClick={handleCardClick} clickable={onClickCard !== undefined} shadow={!noShadow}>
        {!noHead && (
          <CardHeaderWrapper>
            <CardHeaderText
              clickable={onClickHeader !== undefined}
              onClick={handleClickHeader}
              data-testid={`card-title-${title}`}
            >
              {title}
            </CardHeaderText>
            <ActionsWrapper>
              {actionButtons}
              {!noMenu && <AnalysisMenu />}
            </ActionsWrapper>
          </CardHeaderWrapper>
        )}
        <CardContent noHead={noHead}>{content}</CardContent>
      </CardHalf>
    </Wrapper>
  );
};
