import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { MutationTuple } from "@apollo/client/react/types/types";

import { SERIES_FLAG_FRAGMENT } from "../../../../../fragments/SeriesFlagFragment";
import { SeriesFlagType } from "../../../../../types/SeriesFlagType";
import { getCacheId } from "../../../../Manage/cache/getCacheId";

type Data = { seriesFlag: SeriesFlagType };

type Variables = {
  seriesFlag: {
    series_id: number;
    task_id: number;
    created_by: number;
    notes: string | null;
    series_flag_reasons: { data: { reason: string }[] };
  };
};

const MUTATION = gql`
  mutation CreateSeriesFlag($seriesFlag: series_flag_insert_input!) {
    seriesFlag: insert_series_flag_one(object: $seriesFlag) {
      ...SeriesFlag
    }
  }
  ${SERIES_FLAG_FRAGMENT}
`;

export function useCreateSeriesFlag(): MutationTuple<Data, Variables> {
  return useMutation<Data, Variables>(MUTATION, {
    update(cache, { data }) {
      if (!data) {
        throw new Error("Something went wrong updating the cache after inserting a series flag");
      }

      const { seriesFlag } = data;

      const seriesCacheId = getCacheId(seriesFlag.seriesId, "series", cache);

      cache.modify({
        id: seriesCacheId,
        fields: {
          series_flags(currentSeriesFlagRefs = []) {
            const newSeriesFlagRef = cache.writeFragment({
              data: seriesFlag,
              fragment: gql`
                fragment SeriesFlagReference on series_flag {
                  id
                  type
                }
              `,
            });
            return [...currentSeriesFlagRefs, newSeriesFlagRef];
          },
        },
      });
    },
  });
}
