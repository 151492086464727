import { gql, useQuery } from "@apollo/client";
import React from "react";

import { FlexLoading } from "../../../common/components/Loading";
import handleApolloError from "../../../common/utils/handleApolloError";
import { useProjectId } from "../../../Project/hooks/useProjectId";
import {
  parseImagingDeviationData,
  RawImagingDeviationAndViolationsType,
  sortImagingDeviationData,
} from "../utils/parseImagingDeviationData";
import ImagingDeviationsChart from "./ImagingDeviationsChart";

const GET_IMAGING_DEVIATIONS_AND_VIOLATION = gql`
  query getImagingDeviationsAndViolations($projectId: Int!) {
    sites: label(
      where: {
        _and: [
          { project_id: { _eq: $projectId } }
          { label_studies: { study: { series: { imaging_violations: {} } } } }
          { label_type: { name: { _eq: "SITE" } } }
        ]
      }
    ) {
      id
      name
      labelStudies: label_studies {
        study {
          series {
            imaging_violations {
              violation_type
            }
          }
        }
      }
    }
  }
`;

function ImagingDeviationsContainer(): React.ReactElement {
  const projectId = useProjectId();
  const { loading, error, data } = useQuery<RawImagingDeviationAndViolationsType>(
    GET_IMAGING_DEVIATIONS_AND_VIOLATION,
    {
      variables: { projectId },
    }
  );

  if (loading) return <FlexLoading />;
  if (error) handleApolloError(error);
  const parsedData = data ? parseImagingDeviationData(data) : [];
  const sortedData = sortImagingDeviationData(parsedData);
  return <ImagingDeviationsChart data={sortedData} />;
}

export default ImagingDeviationsContainer;
