import React from "react";

import DownloadReportButton from "../../../common/components/buttons/DownloadReportButton";
import { TopBarUploadButton } from "../../../common/components/buttons/TopBarUploadButton";
import { CreateProjectButton } from "../../../Dashboard/components/Project/CreateProjectButton";
import { ExportProjectButton } from "../../../DataManagement/AnnotationsExport/ExportProjectButton";
import {
  ActionButtonInstanceType,
  CREATE_PROJECT,
  DOWNLOAD_REPORT,
  EXPORT_PROJECT,
  UPLOAD_DICOM,
  UPLOAD_DOCUMENTS,
} from "../Layout";

const actionButtons: Record<ActionButtonInstanceType, JSX.Element> = {
  [DOWNLOAD_REPORT]: <DownloadReportButton text={"Export Report"} />,
  [CREATE_PROJECT]: <CreateProjectButton />,
  [UPLOAD_DICOM]: <TopBarUploadButton text={"Upload"} type={UPLOAD_DICOM} />,
  [UPLOAD_DOCUMENTS]: <TopBarUploadButton text={"Upload Documents"} type={UPLOAD_DOCUMENTS} />,
  [EXPORT_PROJECT]: <ExportProjectButton />,
};

interface ActionButtonProps {
  action: ActionButtonInstanceType;
}

export function ActionButton({ action }: ActionButtonProps): JSX.Element {
  return actionButtons[action];
}
