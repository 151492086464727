import { createContext } from "react";

import { ClassificationModeDefinitionsType } from "../../../../../common/store/annotatePage/ClassificationsMapType";
import { ClassificationsDefinitionsType } from "../../../../../common/store/annotatePage/classificationsSlice";
import { ViewerConfigType } from "../../../../../common/store/annotatePage/ViewerConfigType";
import { DEFAULT } from "../../../../enums/TaskDescriptionEnums";
import { StatsType } from "../../../../types/TaskDescriptionType";
import { AnatomicalStructuresFragmentType } from "../../../AnatomicalStructuresFragment";
import { LesionListItemFragmentType } from "./fragments/LesionListItemFragment";
import { RoiListItemFragmentType } from "./fragments/RoiListItemFragment";
import { TimepointType } from "./hooks/Timepoint/TimepointType";
import { ExistingRoiClassificationButtonsReturnType } from "./hooks/timepointOptions/useGetExistingRoiClassificationButtons";
import { MissingRoiClassificationButtonsReturnType } from "./hooks/timepointOptions/useGetMissingRoiClassificationButtons";
import { RoiViewOnlyOptionsReturnType } from "./hooks/timepointOptions/useGetRoiViewOnlyOptions";
import { TimepointOptionsFromRoiClassificationsReturnType } from "./hooks/timepointOptions/useGetTimepointOptionsFromRoiClassifications";
import { LesionModeReturnType } from "./hooks/useGetLesionMode";
import { ReadonlyClassificationsReturnType } from "./hooks/useGetReadonlyClassificationsFromLesion";
import { NextLesionIdReturnType } from "./hooks/useNextLesionId";
import { Mask } from "./MaskContextProvider";
import { DownloadState } from "./Masks/types/downloadState";

export type LesionsListContextType = {
  getLesionMode: LesionModeReturnType;
  getReadonlyClassificationsFromLesion: ReadonlyClassificationsReturnType;
  getNextLesionId: NextLesionIdReturnType;
  classificationModeDefinitions: ClassificationModeDefinitionsType;
  selectedLesionId: number | null;
  lesionClassificationsDefinitions: Record<number, ClassificationsDefinitionsType>;
  anatomicalStructures: AnatomicalStructuresFragmentType[];
  anatomicalStructuresLoading: boolean;
  getViewerForSeries: (seriesId: number) => ViewerConfigType | undefined;
  getRoiViewOnlyOptions: RoiViewOnlyOptionsReturnType;
  getIsLesionFromCurrentTask: (lesion: Pick<LesionListItemFragmentType, "taskId">) => boolean;
  onToggleMaskVisibility: (mask: Mask) => Promise<boolean>;
  getMaskDownloadState: (maskId: number) => DownloadState | undefined;
  jumpToViewerRoi: (roi: RoiListItemFragmentType) => void;
  getTimepointOptionsFromRoiClassifications: TimepointOptionsFromRoiClassificationsReturnType;
  headerStats: StatsType[];
  bodyStats: StatsType[];
  getExistingRoiClassificationButtons: ExistingRoiClassificationButtonsReturnType;
  getMissingRoiTimepointOptions: MissingRoiClassificationButtonsReturnType;
  userId: number;
  isRecist: boolean;
  statTypes?: StatsType[];
  timepoints?: TimepointType[];
};

export const LesionsListContext = createContext<LesionsListContextType>({
  getLesionMode: () => DEFAULT,
  getReadonlyClassificationsFromLesion: () => [],
  getNextLesionId: () => null,
  classificationModeDefinitions: {},
  selectedLesionId: null,
  lesionClassificationsDefinitions: {},
  anatomicalStructures: [],
  anatomicalStructuresLoading: false,
  getViewerForSeries: () => undefined,
  getRoiViewOnlyOptions: () => [],
  getIsLesionFromCurrentTask: () => false,
  onToggleMaskVisibility: () => new Promise(() => false),
  getMaskDownloadState: () => undefined,
  jumpToViewerRoi: () => undefined,
  getTimepointOptionsFromRoiClassifications: () => [],
  headerStats: [],
  bodyStats: [],
  getExistingRoiClassificationButtons: () => [],
  getMissingRoiTimepointOptions: () => [],
  userId: -1,
  isRecist: false,
});
