import cloneDeep from "lodash/cloneDeep";
import React from "react";

import { ClassificationsMapType } from "../../../../../common/store/annotatePage/ClassificationsMapType";
import { CONSTRAINT_ANY } from "../../../../enums/TaskDescriptionEnums";
import {
  ClassificationModeType,
  ClassificationsTypeType,
  ClassificationValuesType,
} from "../../../../types/TaskDescriptionType";
import { ClassificationSelect } from "./ClassificationSelect";

interface ClassificationsSelectorProps {
  classificationsMap: ClassificationsMapType;
  onClassificationsChanged: (classificationsMap: ClassificationsMapType) => void;
  mode: ClassificationModeType;
  disabled: boolean;
}

export function ClassificationsSelector({
  classificationsMap,
  onClassificationsChanged,
  mode,
  disabled,
}: ClassificationsSelectorProps): JSX.Element {
  const handleSelect = (
    type: ClassificationsTypeType,
    selectedValue: ClassificationValuesType | null
  ) => {
    if (!selectedValue) {
      return;
    }
    const classificationsMapCopy = cloneDeep(classificationsMap);
    const { constraint, values } = classificationsMapCopy[type];
    if (constraint !== CONSTRAINT_ANY) {
      Object.keys(values).forEach((classification) => {
        values[classification as ClassificationValuesType] = false;
      });
    }
    values[selectedValue] = true;
    onClassificationsChanged(classificationsMapCopy);
  };

  return (
    <>
      {Object.entries(classificationsMap).map(([type, { values }], index) => (
        <ClassificationSelect
          onSelect={(value) => handleSelect(type as ClassificationsTypeType, value)}
          values={values}
          key={index}
          mode={mode}
          disabled={disabled}
        />
      ))}
    </>
  );
}
