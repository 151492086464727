import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { MutationTuple } from "@apollo/client/react/types/types";

import { USER_FRAGMENT, UserFragmentType } from "../../../Settings/UserFragment";

const MUTATION = gql`
  mutation InsertUserIntoProject($projectUsers: [project_user_insert_input!]!) {
    insert_project_user(
      objects: $projectUsers
      on_conflict: { constraint: project_user_pk, update_columns: [] }
    ) {
      returning {
        user {
          ...UserFragment
        }
      }
    }
  }
  ${USER_FRAGMENT}
`;

type Data = {
  returning: { user: UserFragmentType }[];
};

type Variables = {
  projectUsers: { project_id: number; user_id: number }[];
};

export function useAddUsersToProject(): MutationTuple<Data, Variables> {
  return useMutation<Data, Variables>(MUTATION);
}
