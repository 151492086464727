import { createSelector } from "@reduxjs/toolkit";
import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getDemographicsFilters,
  projectViewFiltersActions,
} from "../../../../common/store/projectViewFiltersSlice";
import { capitalize } from "../../../../common/utils/capitalize";
import { NumberInputWrapper } from "../FilterInputWrapper";
import FilterNumberInput from "../FilterNumberInput";
import FilterSubtext from "../FilterSubtext";

const getAgeFilters = createSelector(
  [getDemographicsFilters],
  (demographicsFilters) => demographicsFilters.age
);

export const AgeFilters: FC = () => {
  const dispatch = useDispatch();
  const ageFilters = useSelector(getAgeFilters);

  const ageOptions = Object.entries(ageFilters).map(([filter, value], index) => {
    const updateAgeFilters = (number: number | null) => {
      dispatch(
        projectViewFiltersActions.setAge({
          ...ageFilters,
          [filter]: number,
        })
      );
    };
    return (
      <FilterNumberInput
        key={index}
        label={capitalize(filter)}
        onChange={updateAgeFilters}
        value={value}
      />
    );
  });

  const options = (
    <>
      <NumberInputWrapper>{ageOptions}</NumberInputWrapper>
    </>
  );

  return (
    <>
      <FilterSubtext text="Age" />
      {options}
    </>
  );
};
