import { TaskDescriptionType } from "../../hooks/useTaskDescriptions";
import { TaskDescriptionOptionType } from "../TaskDescriptionOptionType";
import { CreateTaskType } from "./CreateTaskType";
import { taskTemplates } from "./TaskTemplate";

export function getTaskDescriptionOptions(
  taskDescription: TaskDescriptionType,
  type: CreateTaskType
): TaskDescriptionOptionType[] {
  const templates = Object.values(taskTemplates);

  const {
    data: { name },
    isTemplate,
  } = taskDescription;

  return templates
    .filter(
      ({ type: templateType, taskDescriptionType }) =>
        templateType === type && taskDescriptionType === name && isTemplate
    )
    .map((template) => {
      const { label } = template;
      return {
        label,
        value: { taskDescription, template },
      };
    });
}
