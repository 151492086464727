import { GlobalRoleType, UserRoleType } from "../../common/types/UserRoleType";
import { RoutePathType } from "./RoutePathType";

export type RoutePathSingleOrArray = RoutePathType | RoutePathType[];

export type LayoutType = {
  dashboard: DashboardLayoutType;
  project: ProjectLayoutType;
  settings: ProjectLayoutType;
};

export type ProjectLayoutType = {
  menu: MenuItemType[];
  pages: ProjectPageType[];
  standalonePages: PageType[];
};

export type DashboardLayoutType = {
  buttons: ActionButtonType[];
  content: ContentType[];
  showKaplanMeierPreview: boolean;
};

export type MenuItemType = {
  label: string;
  icon: IconType;
  route: RoutePathSingleOrArray;
  subItems?: SubMenuItemType[];
};

export type SubMenuItemType = Pick<MenuItemType, "label" | "route">;

export type PageType = {
  route: RoutePathSingleOrArray;
  parentRoute?: RoutePathSingleOrArray;
  content: ContentType[];
  allowedRoles?: (UserRoleType | GlobalRoleType)[];
  defaultPage?: boolean;
};

export type DrawerPageType = {
  topBar?: TopBarType;
  parentRoute?: never;
  content: ContentType[];
};

export type ProjectPageType = PageType & {
  topBar: TopBarType;
  showPatientFilters?: boolean;
};

export type TopBarType = {
  showBackButton?: boolean;
  icon?: IconType;
  label?: string;
  buttons?: ButtonType[];
};

export type ButtonType = RouteButtonType | ActionButtonType | CustomButtonType;

interface ButtonBaseType {
  icon?: IconType;
  label: string;
  allowedRoles?: (UserRoleType | GlobalRoleType)[];
}

interface RouteButtonType extends ButtonBaseType {
  action?: never;
  route: RoutePathSingleOrArray;
  component?: never;
}

export interface ActionButtonType extends ButtonBaseType {
  action: ActionButtonInstanceType;
  route?: never;
  component?: never;
}

export interface CustomButtonType extends ButtonBaseType {
  route?: never;
  action?: never;
  component: JSX.Element;
}

export const OVERVIEW_ICON = "OVERVIEW_ICON";
export const DATA_MANAGEMENT_ICON = "DATA_MANAGEMENT_ICON";
export const ANALYSIS_ICON = "ANALYSIS_ICON";
export const DOCUMENTS_ICON = "DOCUMENTS_ICON";
export const RADIOLOGY_ICON = "RADIOLOGY_ICON";
export const DOWNLOAD_ICON = "DOWNLOAD_ICON";
export const UPLOAD_ICON = "UPLOAD_ICON";
export const SETTINGS_ICON = "SETTINGS_ICON";
export const USERS_ICON = "USERS_ICON";
export const USER_ICON = "USER_ICON";
export const SCHEMA_ICON = "SCHEMA_ICON";
export const WORKLIST_ICON = "WORKLIST_ICON";
export const HOME_ICON = "HOME_ICON";

export type IconType =
  | typeof OVERVIEW_ICON
  | typeof DATA_MANAGEMENT_ICON
  | typeof ANALYSIS_ICON
  | typeof DOCUMENTS_ICON
  | typeof RADIOLOGY_ICON
  | typeof DOWNLOAD_ICON
  | typeof UPLOAD_ICON
  | typeof SETTINGS_ICON
  | typeof USERS_ICON
  | typeof USER_ICON
  | typeof SCHEMA_ICON
  | typeof WORKLIST_ICON
  | typeof HOME_ICON;

export const DOWNLOAD_REPORT = "DOWNLOAD_REPORT";
export const CREATE_PROJECT = "CREATE_PROJECT";
export const UPLOAD_DICOM = "UPLOAD_DICOM";
export const UPLOAD_DOCUMENTS = "UPLOAD_DOCUMENTS";
export const EXPORT_PROJECT = "EXPORT_PROJECT";

export type ActionButtonInstanceType =
  | typeof DOWNLOAD_REPORT
  | typeof CREATE_PROJECT
  | typeof UPLOAD_DICOM
  | typeof UPLOAD_DOCUMENTS
  | typeof EXPORT_PROJECT;

export const PROJECT_DETAILS_CONTENT = "PROJECT_DETAILS";
export const ENROLMENT_CONTENT = "ENROLMENT";
export const RADIOLOGY_CONTENT = "RADIOLOGY";
export const UPLOAD_CONTENT = "UPLOAD";
export const UPLOAD_CSV_CONTENT = "UPLOAD_CSV";
export const IMAGING_DEVIATIONS_CONTENT = "IMAGING_DEVIATIONS_CONTENT";
export const BURNDOWN_AND_DEVIATIONS_CONTENT = "BURNDOWN_AND_DEVIATIONS";
export const INGESTION_CONTENT = "INGESTION";
export const ALGORITHM_INFO_CONTENT = "ALGORITHM_INFO";
export const KAPLAN_MEIER_CONTENT = "KAPLAN_MEIER";
export const STUDY_TABLE_CONTENT = "STUDY_TABLE";
export const PATIENT_JOURNEY_CONTENT = "PATIENT_JOURNEY";
export const PATIENT_JOURNEY_DRAWER = "PATIENT_JOURNEY_DRAWER";
export const PATIENT_DETAILS_CONTENT = "PATIENT_DETAILS";
export const PATIENT_LONGITUDINAL_CONTENT = "PATIENT_LONGITUDINAL";
export const PATIENT_TASKS_CONTENT = "PATIENT_TASKS";
export const ANALYSIS_ALGORITHM_SUMMARY_CONTENT = "ANALYSIS_ALGORITHM_SUMMARY";
export const MEDIAN_SURVIVAL_CONTENT = "MEDIAN_SURVIVAL";
export const CHANGE_IN_MORTALITY_CONTENT = "CHANGE_IN_MORTALITY";
export const WORKLIST_CONTENT = "WORKLIST_CONTENT";
export const USER_MANAGEMENT_CONTENT = "USER_MANAGEMENT_CONTENT";
export const ANNOTATIONS_PER_COHORT_CONTENT = "ANNOTATIONS_PER_COHORT_CONTENT";
export const ANNOTATIONS_PER_ANNOTATOR_CONTENT = "ANNOTATIONS_PER_ANNOTATOR_CONTENT";
export const TASK_PER_ANNOTATOR_CONTENT = "TASK_PER_ANNOTATOR_CONTENT";
export const LESION_STATISTICS_CONTENT = "LESION_STATISTICS_CONTENT";
export const SITE_MANAGEMENT_CONTENT = "SITE_MANAGEMENT_CONTENT";
export const GLOBAL_COHORT_MANAGEMENT_CONTENT = "GLOBAL_COHORT_MANAGEMENT_CONTENT";
export const PROJECT_COHORT_MANAGEMENT_CONTENT = "PROJECT_COHORT_MANAGEMENT_CONTENT";
export const ANNOTATE_PAGE_CONTENT = "ANNOTATE_PAGE";
export const TASKLESS_PAGE_CONTENT = "TASKLESS_PAGE";
export const ANNOTATE_MANAGE_PAGE_CONTENT = "ANNOTATE_MANAGE_PAGE";
export const PROJECT_DOCUMENT_CONTENT = "PROJECT_DOCUMENT_CONTENT";
export const PROJECT_SELECTION_CONTENT = "PROJECT_SELECTION";
export const EMPTY_CONTENT = "EMPTY_CONTENT";
export const LOADING_CONTENT = "LOADING";
export const GLOBAL_USER_MANAGEMENT = "GLOBAL_USER_MANAGEMENT";
export const GLOBAL_PROJECT_MANAGEMENT = "GLOBAL_PROJECT_MANAGEMENT";
export const GLOBAL_PATIENT_MANAGEMENT = "GLOBAL_PATIENT_MANAGEMENT";
export const PROJECT_PATIENT_MANAGEMENT_CONTENT = "PROJECT_PATIENT_MANAGEMENT_CONTENT";
export const GLOBAL_ANNOTATION_MANAGEMENT = "GLOBAL_ANNOTATION_MANAGEMENT";

export type ContentType = {
  component: ContentComponentType;
  props?: object;
  allowedRoles?: (UserRoleType | GlobalRoleType)[];
};

export type ContentComponentType =
  | typeof PROJECT_DETAILS_CONTENT
  | typeof ENROLMENT_CONTENT
  | typeof RADIOLOGY_CONTENT
  | typeof UPLOAD_CONTENT
  | typeof UPLOAD_CSV_CONTENT
  | typeof IMAGING_DEVIATIONS_CONTENT
  | typeof BURNDOWN_AND_DEVIATIONS_CONTENT
  | typeof INGESTION_CONTENT
  | typeof ALGORITHM_INFO_CONTENT
  | typeof KAPLAN_MEIER_CONTENT
  | typeof STUDY_TABLE_CONTENT
  | typeof PATIENT_JOURNEY_CONTENT
  | typeof PATIENT_JOURNEY_DRAWER
  | typeof PATIENT_DETAILS_CONTENT
  | typeof PATIENT_LONGITUDINAL_CONTENT
  | typeof PATIENT_TASKS_CONTENT
  | typeof ANALYSIS_ALGORITHM_SUMMARY_CONTENT
  | typeof MEDIAN_SURVIVAL_CONTENT
  | typeof CHANGE_IN_MORTALITY_CONTENT
  | typeof WORKLIST_CONTENT
  | typeof USER_MANAGEMENT_CONTENT
  | typeof ANNOTATIONS_PER_COHORT_CONTENT
  | typeof ANNOTATIONS_PER_ANNOTATOR_CONTENT
  | typeof TASK_PER_ANNOTATOR_CONTENT
  | typeof LESION_STATISTICS_CONTENT
  | typeof SITE_MANAGEMENT_CONTENT
  | typeof GLOBAL_COHORT_MANAGEMENT_CONTENT
  | typeof PROJECT_COHORT_MANAGEMENT_CONTENT
  | typeof ANNOTATE_PAGE_CONTENT
  | typeof TASKLESS_PAGE_CONTENT
  | typeof ANNOTATE_MANAGE_PAGE_CONTENT
  | typeof PROJECT_SELECTION_CONTENT
  | typeof PROJECT_DOCUMENT_CONTENT
  | typeof EMPTY_CONTENT
  | typeof LOADING_CONTENT
  | typeof GLOBAL_USER_MANAGEMENT
  | typeof GLOBAL_PROJECT_MANAGEMENT
  | typeof GLOBAL_PATIENT_MANAGEMENT
  | typeof PROJECT_PATIENT_MANAGEMENT_CONTENT
  | typeof GLOBAL_ANNOTATION_MANAGEMENT;

export const ANNOTATION_SCOPE = "ANNOTATION_SCOPE";
