import { AJCC_I, AJCC_II, AJCC_III, AJCC_IV } from "../../../Analysis/common/types/AjccStageType";
import { StageFilters } from "../../types/ProjectViewFilterTypes";
import { RootState } from "../store";
import { ProjectFilterSelectors } from "./ProjectFilterSelectors";

export const defaultStageState: StageFilters = {
  ajccStage: {
    [AJCC_I]: true,
    [AJCC_II]: true,
    [AJCC_III]: true,
    [AJCC_IV]: true,
  },
  tStages: {
    "0": true,
    "1a": true,
    "1b": true,
    "1c": true,
    "2a": true,
    "2b": true,
    "3": true,
    "4": true,
  },
  nStages: {
    "0": true,
    "1": true,
    "2": true,
    "3": true,
    "4": true,
  },
  mStages: {
    "0": true,
    "1a": true,
    "1b": true,
    "1c": true,
  },
};

export const clearStageState: StageFilters = {
  ajccStage: {
    [AJCC_I]: false,
    [AJCC_II]: false,
    [AJCC_III]: false,
    [AJCC_IV]: false,
  },
  tStages: {
    "0": false,
    "1a": false,
    "1b": false,
    "1c": false,
    "2a": false,
    "2b": false,
    "3": false,
    "4": false,
  },
  nStages: {
    "0": false,
    "1": false,
    "2": false,
    "3": false,
    "4": false,
  },
  mStages: {
    "0": false,
    "1a": false,
    "1b": false,
    "1c": false,
  },
};

export const ajccFilters = new ProjectFilterSelectors<StageFilters>(
  defaultStageState,
  clearStageState,
  (state: RootState) => state.projectViewFilters.stage
);
