import dayjs from "dayjs";
import React, { useMemo, useState } from "react";
import styled from "styled-components";

import { IconButton } from "../../../Annotate/components/Annotate/page/AnnotationPanel/IconButton";
import { MALIGNANT } from "../../../Annotate/enums/TaskDescriptionEnums";
import { ReactComponent as ResetIcon } from "../../../assets/svgs/Reset.svg";
import { Card } from "../../../common/components/cards/Card";
import { SvgIcon } from "../../../common/components/icons/SvgIcon";
import { DateFilterType, DEFAULT_FILTER } from "../../../common/utils/dateFormatUtils/dateFilters";
import { DayjsDateRange } from "../../../common/utils/dateFormatUtils/DayjsDateRange";
import { formatRange } from "../../../common/utils/dateFormatUtils/formatRange";
import handleApolloError from "../../../common/utils/handleApolloError";
import { useProjectId } from "../../../Project/hooks/useProjectId";
import { useProjectLabels } from "../hooks/useProjectLabels";
import { buildLesionData, LesionData } from "../utils/buildLesionData";
import { getEarliestLabelDate } from "../utils/getEarliestLabelDate";
import { DownloadChartDataButton } from "./DownloadChartDataButton";
import { Filter } from "./Filter";

const FilterBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const FilterWrapper = styled.div`
  flex: 2;
  display: flex;
  flex-direction: row;
  justify-content: right;
`;

export function LesionDownloadCard(): JSX.Element {
  const projectId = useProjectId();

  const { data, loading, error, refetch } = useProjectLabels(projectId);

  const [filterMode, setFilterMode] = useState<DateFilterType>(DEFAULT_FILTER);

  const [selectedDateRange, setSelectedDateRange] = useState<DayjsDateRange>({
    from: dayjs(),
    to: dayjs(),
  });

  const earliestLesionDate = useMemo(
    () => getEarliestLabelDate(data?.patients ?? [], false) ?? dayjs(),
    [data]
  );

  const handleRefreshClicked = async () => {
    await refetch();
  };

  if (error) handleApolloError(error);

  if (!loading && !data) {
    throw new Error("Data is null with no Apollo error");
  }

  const parsedAllData = !loading && data ? buildLesionData(data, selectedDateRange) : [];

  const includedClassifications = [MALIGNANT];

  const parsedMalignantData =
    !loading && data ? buildLesionData(data, selectedDateRange, includedClassifications) : [];

  return (
    <Card
      title={"Download Lesion Data"}
      content={
        <FilterBarWrapper>
          <FilterWrapper>
            <Filter
              filterMode={filterMode}
              onSelectFilterMode={setFilterMode}
              selectedRange={selectedDateRange}
              onSetSelectedRange={setSelectedDateRange}
              earliestDate={earliestLesionDate}
              disabled={loading}
            />
          </FilterWrapper>
        </FilterBarWrapper>
      }
      actionButtons={
        <>
          <DownloadChartDataButton
            entries={[
              {
                filename: `lesion-data-${formatRange(selectedDateRange)}.csv`,
                name: "All Lesions",
                data: dataToCSV(parsedAllData),
              },
              {
                filename: `malignant-lesion-data-${formatRange(selectedDateRange)}.csv`,
                name: "Malignant Lesions",
                data: dataToCSV(parsedMalignantData),
              },
            ]}
          />
          <IconButton onClick={handleRefreshClicked}>
            <SvgIcon icon={ResetIcon} size={20} />
          </IconButton>
        </>
      }
    />
  );
}

function dataToCSV(data: LesionData[]): string[][] {
  const sortedData = data.sort((a, b) => b.total - a.total);
  const csvData: string[][] = [
    [
      "",
      "Total Detected Lesions",
      "Primary Lesions",
      "Metastatic Lesions",
      "Malignant Lesions",
      "Benign Lesions",
      "Measurable Lesions",
      "Non-Measurable Lesions",
      "Contoured Lesions",
      "Indeterminate Lesions",
    ],
  ];
  for (const entry of sortedData) {
    csvData.push([
      entry.organ,
      entry.total.toString(),
      entry.primary.toString(),
      entry.metastatic.toString(),
      entry.malignant.toString(),
      entry.benign.toString(),
      entry.measurable.toString(),
      entry.nonMeasurable.toString(),
      entry.contoured.toString(),
      entry.indeterminate.toString(),
    ]);
  }
  const transposedData = csvData[0].map((col, c) => csvData.map((row, r) => csvData[r][c]));
  return transposedData;
}
