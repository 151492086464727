import { getTimepointFromSeriesId } from "./getTimepointFromSeriesId";
import { TimepointType } from "./TimepointType";

export function getPreviousTimepoints(
  timepoints: TimepointType[],
  seriesId: number
): TimepointType[] {
  const currentTimepoint = getTimepointFromSeriesId(seriesId, timepoints);
  if (!currentTimepoint) {
    throw new Error(`Unable to find current timepoint with series id ${seriesId}`);
  }

  const { index: currentTimepointIndex } = currentTimepoint;
  if (currentTimepointIndex === undefined) {
    return [];
  }

  return timepoints.filter(({ index }) => index !== undefined && index < currentTimepointIndex);
}
