import cornerstoneTools from "@altis-labs/cornerstone-tools";
import cornerstone from "cornerstone-core";

import { ELLIPTICAL_PROBE } from "../../../../../../../cornerstone/ToolType";
import EllipseToolChangeDetector, { clearEllipseCache } from "./EllipseToolChangeDetector";
import { clearRoiData } from "./ToolUtils";

const toolType = ELLIPTICAL_PROBE;
const toolOptions = {
  mouseButtonMask: 1,
};
const defaultRenderSettings = {
  color: "rgb(222, 52, 235)",
  lineWidth: 2,
  renderHandles: false,
};

export default class EllipticalProbeTool {
  constructor(cornerstoneElement, onEllipseCreated) {
    this.cornerstoneElement = cornerstoneElement;
    this.onEllipseCreatedCallback = onEllipseCreated;

    const tool = cornerstoneTools.getToolForElement(cornerstoneElement, toolType);

    if (!tool) {
      throw new Error(`Cornerstone ${toolType} tool is null`);
    }

    const ellipseToolChangeDetector = new EllipseToolChangeDetector(
      this.onEllipseCreated,
      this.onEllipseChanged
    );

    tool.setRenderSettings(defaultRenderSettings);
    tool.setDataInvalidatedCallback((ellipseData) =>
      ellipseToolChangeDetector.testEllipseData(ellipseData)
    );
    this.tool = tool;
  }

  setActive(active) {
    if (active) {
      cornerstoneTools.setToolActiveForElement(this.cornerstoneElement, toolType, toolOptions);
    } else {
      cornerstoneTools.setToolPassiveForElement(this.cornerstoneElement, toolType, toolOptions);
    }
  }

  setEnabled(enabled) {
    if (enabled) {
      cornerstoneTools.setToolEnabledForElement(this.cornerstoneElement, toolType, toolOptions);
    } else {
      cornerstoneTools.setToolDisabledForElement(this.cornerstoneElement, toolType, toolOptions);
    }
  }

  onEllipseCreated = async (ellipseData) => {
    this.clearTool();

    if (!this.onEllipseCreatedCallback) {
      console.warn(`No onEllipseCreatedCallback specified for ${toolType} tool`);
      return;
    }

    this.onEllipseCreatedCallback(ellipseData);
  };

  onEllipseChanged = async () => {
    return undefined;
  };

  clearTool = () => {
    clearEllipseCache();

    clearRoiData(toolType, toolType);

    cornerstone.updateImage(this.cornerstoneElement);
  };
}
