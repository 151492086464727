import React, { FC, useEffect } from "react";
import styled from "styled-components";

import ToolPanelContainer from "../../Analysis/ToolPanel/components/ToolPanelContainer";
import { useScrollToTop } from "../hooks/useScrollToTop";
import { Content } from "./Content";
import { ProjectPageType } from "./Layout";
import { TopBar } from "./TopBar/TopBar";
import { getPageDisplayName } from "./utils/getPageDisplayName";
import { setWindowTitle } from "./utils/setWindowTitle";

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.background.main};
`;

const TopBarWrapper = styled.div`
  display: flex;
  height: 64px;
  background: ${(props) => props.theme.colors.actionPrimary.inverted};
  box-shadow: inset 0px -1px 0px ${(props) => props.theme.colors.neutral.neutral8};
`;

const ContentWrapper = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;
  align-content: stretch;
`;

const SideBarWrapper = styled.div`
  width: 280px;
  display: flex;
  padding: 24px 12px 0 12px;
`;

const MainContentWrapper = styled.div`
  overflow-y: auto;
  flex: 1;
  overflow-x: auto;
  width: calc(100vw - 480px - 48px - 48px);
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
`;

interface ProjectPageProps {
  page: ProjectPageType;
}

export const ProjectPage: FC<ProjectPageProps> = ({ page }: ProjectPageProps) => {
  const { showPatientFilters, content, topBar, parentRoute } = page;
  const scrollRestoreRef = useScrollToTop<HTMLDivElement>();

  useEffect(() => {
    const windowTitle = getPageDisplayName(page);
    setWindowTitle(windowTitle);
  }, [page]);

  return (
    <Wrapper>
      <TopBarWrapper>
        <TopBar topBar={topBar} parentRoute={parentRoute} />
      </TopBarWrapper>
      <ContentWrapper>
        <MainContentWrapper ref={scrollRestoreRef}>
          <Content content={content} />
        </MainContentWrapper>
        {showPatientFilters && (
          <SideBarWrapper>
            <ToolPanelContainer />
          </SideBarWrapper>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};
