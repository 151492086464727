import Tooltip from "@mui/material/Tooltip";
import React from "react";
import styled from "styled-components";

import { capitalizeFirstLetters } from "../../../../../common/components/UserManagement/utils/capitalizeFirstLetters";
import { AnatomicalStructuresFragmentType } from "../../../AnatomicalStructuresFragment";
import { LesionListItemFragmentType } from "./fragments/LesionListItemFragment";
import { isLesionLesion } from "./utils/isLesionLesion";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 3px 3px 3px 8px;
  height: 28px;
`;

const ClassificationText = styled.p`
  flex: 1 1 0;
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme: { colors } }) => colors.actionPrimary.active};
  padding: 0 8px;
  text-overflow: ellipsis;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
`;

function formatLabel(label: string): string {
  return capitalizeFirstLetters(label.replace(/_/g, " ").toLowerCase());
}

interface TasklessClassificationsSelectorProps {
  lesion: LesionListItemFragmentType;
  anatomicalStructures: AnatomicalStructuresFragmentType[];
}

export function TasklessLesionClassificationsSelector({
  lesion,
  anatomicalStructures,
}: TasklessClassificationsSelectorProps): JSX.Element {
  const { classifications, location } = lesion;

  const isLesion = isLesionLesion(lesion);
  if (!isLesion) {
    return <></>;
  }

  const anatomicalStructure = anatomicalStructures.find(
    ({ id, structure }) => id === location && structure !== "lesion"
  );

  const anatomicalStructureDisplayName = anatomicalStructure
    ? formatLabel(anatomicalStructure.name)
    : null;

  const formattedClassifications = classifications.map(({ classification }) =>
    formatLabel(classification)
  );

  const allLabels = [...formattedClassifications];
  if (anatomicalStructureDisplayName) {
    allLabels.push(anatomicalStructureDisplayName);
  }

  if (allLabels.length === 0) {
    return <></>;
  }

  return (
    <Wrapper>
      {allLabels.map((label) => (
        <Tooltip title={label} key={label}>
          <ClassificationText>{label}</ClassificationText>
        </Tooltip>
      ))}
    </Wrapper>
  );
}
