import { PageType, ProjectLayoutType } from "../Layout";
import { getRouteString } from "../utils/getRouteString";
import { useAllowedLayoutPages } from "./useAllowedLayoutPages";

export function usePageGroups(layout: ProjectLayoutType): Record<string, PageType[]> {
  const pages = useAllowedLayoutPages()(layout);

  return pages.reduce<Record<string, PageType[]>>((group, obj) => {
    if (!obj.parentRoute) {
      return group;
    }
    const parentRouteStr = getRouteString(obj.parentRoute);
    return Object.assign(group, {
      [parentRouteStr]: (group[parentRouteStr] || []).concat(obj),
    });
  }, {});
}
