import React from "react";
import styled from "styled-components";

import { Label } from "../../../../../common/components/Label";
import { main } from "../../../../../common/theme/main";

const ContentsWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
`;

interface LabelProps {
  text: string;
  onClick?: () => void;
  children?: JSX.Element | false;
}

export function ClassificationLabel({ text, onClick, children }: LabelProps): JSX.Element {
  const {
    colors: {
      accent: { accent1 },
      neutral: { white },
    },
  } = main;
  return (
    <Label background={accent1} color={white} onClick={onClick}>
      <ContentsWrapper>
        {text}
        {children}
      </ContentsWrapper>
    </Label>
  );
}
