import React from "react";
import { components } from "react-select";
import { OptionProps } from "react-select/src/components/Option";
import styled from "styled-components";

import FilterCheckbox from "../../../../../Analysis/ToolPanel/components/FilterCheckbox";
import { UserLabel } from "../../../../../common/components/UserManagement/UserLabel";
import { UserType } from "../../../../../common/types/UserType";
import {
  GenericMultiSelect,
  OptionType,
} from "../../../../../DataManagement/Upload/components/GenericMultiSelect";
import { getFilterPopoutSelectStyle } from "./getFilterPopoutSelectStyle";

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
`;

export const Option = (props: OptionProps<OptionType<UserType | undefined>, true>) => {
  //@ts-ignore
  const { value: user } = props as OptionType<UserType | undefined>;

  return (
    <div>
      <components.Option {...props}>
        <OptionWrapper>
          <FilterCheckbox
            label={user ? <UserLabel user={user} /> : "Unassigned"}
            name={props.label}
            checked={props.isSelected}
            onChange={() => undefined}
          />
        </OptionWrapper>
      </components.Option>
    </div>
  );
};

interface UserColumnFilterProps {
  users: (UserType | undefined)[];
  selectedUsers: (UserType | undefined)[];
  onSelectedUsersChanged: (users: (UserType | undefined)[]) => void;
}

export function UserColumnFilter({
  users,
  selectedUsers,
  onSelectedUsersChanged,
}: UserColumnFilterProps): JSX.Element {
  const styles = getFilterPopoutSelectStyle<OptionType<UserType | undefined>, true>();

  return (
    <GenericMultiSelect
      autoFocus={false}
      values={users}
      selected={selectedUsers}
      placeholder={"Filter Users..."}
      getOption={getUserSelectOptionType}
      onSelectedChanged={onSelectedUsersChanged}
      isClearable={false}
      menuIsOpen
      backspaceRemovesValue={false}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      controlShouldRenderValue={false}
      tabSelectsValue={false}
      styles={styles}
      preventBaseStyles={true}
      components={{
        Option,
        DropdownIndicator: null,
        IndicatorSeparator: null,
      }}
    />
  );
}

function getUserSelectOptionType(user: UserType | undefined): {
  value: UserType | undefined;
  label: string;
} {
  if (!user) {
    return {
      value: undefined,
      label: "Unassigned",
    };
  }
  const { email } = user;
  return {
    value: user,
    label: email,
  };
}
