import { gql } from "@apollo/client";
import { ApolloCache } from "@apollo/client/core";

import { getCacheId } from "../../../../Manage/cache/getCacheId";
import {
  GetLesionsListDataType,
  GetLesionsListVariablesType,
  LesionsListQueryInput,
} from "../fragments/LesionListItemFragment";
import { PatientBiopsyDetailsFragmentType } from "../fragments/PatientBiopsyDetailsFragmentType";
import { QUERY as GetPatientBiopsyDetailsForCaseQuery } from "../hooks/usePatientBiopsyDetails";
import { deleteRoiFromCache } from "./deleteRoiFromCache";
import { getLesionCacheId } from "./getLesionCacheId";
import { getLesionsListFromCache } from "./getLesionsListFromCache";
import { GET_LESIONS_LIST, getVariables } from "./useLesionsList";

export function deleteLesionFromCache<T>(
  lesionListQueryInput: LesionsListQueryInput,
  lesionId: number,
  cache: ApolloCache<T>
): void {
  const existingLesions = getLesionsListFromCache(lesionListQueryInput, cache);

  const deletedLesion = existingLesions.find(({ id }) => id === lesionId);
  if (!deletedLesion) {
    throw new Error(`Unable to find lesion with id ${lesionId} in cache`);
  }

  const { rois, taskId } = deletedLesion;

  if (taskId === null) {
    throw new Error(
      `Lesion with id ${lesionId} has no task id. Deleting lesions with no task id is not currently supported`
    );
  }

  for (const { id: roiId } of rois) {
    deleteRoiFromCache(lesionListQueryInput, roiId, cache);
  }

  const newLesions = existingLesions.filter(({ id }) => id !== lesionId);

  const variables = getVariables(lesionListQueryInput);
  cache.writeQuery<GetLesionsListDataType, GetLesionsListVariablesType>({
    query: GET_LESIONS_LIST,
    variables,
    data: { lesions: newLesions },
  });

  const lesionCacheId = getLesionCacheId(lesionId, cache);
  cache.evict({ id: lesionCacheId });

  const taskCacheId = getCacheId(taskId, "task", cache);

  cache.modify({
    id: taskCacheId,
    fields: {
      lesions(existingLesions = []) {
        return existingLesions.filter(({ lesion_id }: never) => lesion_id !== lesionId);
      },
    },
  });

  const {
    taskContext: { id: patientId },
  } = lesionListQueryInput;

  const { biopsyDetails } = cache.readQuery<{
    biopsyDetails: PatientBiopsyDetailsFragmentType[];
  }>({
    query: GetPatientBiopsyDetailsForCaseQuery,
    variables: { patientId },
  }) ?? { biopsyDetails: [] };

  const assignedBiopsies = biopsyDetails.filter(
    ({ lesionId: assignedBiopsyLesionId }) => assignedBiopsyLesionId === lesionId
  );
  if (assignedBiopsies.length === 0) {
    return;
  }

  for (const { id } of assignedBiopsies) {
    cache.writeFragment({
      id: `patient_biopsy_details:${id}`,
      fragment: gql`
        fragment PatientBiopsyDetails on patient_biopsy_details {
          lesion_id
        }
      `,
      data: {
        lesion_id: null,
      },
    });
  }
}
