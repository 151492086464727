import { List, ListItem } from "@mui/material";
import { TaskContext } from "nota-predict-web/src/Annotate/TaskContext";
import { useOkCancelForm } from "nota-predict-web/src/common/components/Dialog/useOkCancelForm";
import { main } from "nota-predict-web/src/common/theme/main";
import { AnyObject } from "nota-predict-web/src/common/types/EmptyObjectType";
import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useDeepCompareMemo } from "use-deep-compare";

import { QcSchema } from "../../../utils/qc-validation/schema/ruleSet";
import { findMissingDefaultValues, makeQcReportKey, qc_debugLog } from "./QcForm.utils";
import { useQcFormState } from "./QualityControlProvider.hooks";
import { QcReportKey } from "./QualityControlProvider.types";

const StyledMessageBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
`;

const StyledList = styled(List)`
  background-color: ${main.colors.background.secondary};
  border-radius: 6px;
  &.MuiList-root {
    margin: 8px 0 24px;
  }
`;

export const useQcMissingDefaultsDialog = ({
  qcFormState,
  schema,
}: {
  qcFormState: ReturnType<typeof useQcFormState>;
  schema?: QcSchema;
}) => {
  const { viewOnly } = useContext(TaskContext);
  const [scope] = qcFormState.activeScopeState;
  const [isSaving] = qcFormState.isSavingState;
  const stored = useMemo(() => qcFormState.storedQcReport, [qcFormState.storedQcReport]);

  const [dismissedDialog, setDismissedDialog] = useState<QcReportKey | null>(null);

  const proposedReportKey = makeQcReportKey(scope, qcFormState.storedQcReport);
  const rules = scope === "PATIENT" ? schema?.subject_rule_set : schema?.series_rule_set;
  const scopeFormatted = scope === "PATIENT" ? "subject" : "series";
  const defaultsCheck = useDeepCompareMemo(
    () => findMissingDefaultValues(rules as AnyObject[], stored?.result),
    [rules, stored]
  );
  const [showMissingDefaultsDialog, { dialog: missingDefaultsDialog }] = useOkCancelForm({
    message: (
      <StyledMessageBox>
        {`The QC report for this ${scopeFormatted} is missing default values for:`}
        <StyledList disablePadding dense>
          {defaultsCheck.missing.map((key) => (
            <Fragment key={key}>
              <ListItem key={key}>{key}</ListItem>
            </Fragment>
          ))}
        </StyledList>
        {"Would you like to add default values for these to the report?"}
      </StyledMessageBox>
    ),
    title: "Update with defaults?",
    width: 500,
    onOkCallback: () => {
      if (proposedReportKey && defaultsCheck.proposed) {
        qcFormState.updateQcReportWithDefaults(
          proposedReportKey,
          qcFormState?.storedQcReport?.id,
          defaultsCheck.proposed
        );
      }
      setDismissedDialog(proposedReportKey);
    },
    cancelLabel: "No",
    okLabel: "Yes, update this report",
    showCloseButton: true,
    onCancelCallback: () => setDismissedDialog(proposedReportKey),
  });

  const shouldShowDialog =
    viewOnly &&
    !isSaving &&
    !!stored?.id &&
    !!defaultsCheck.proposed &&
    dismissedDialog !== proposedReportKey;

  useEffect(() => {
    if (shouldShowDialog) {
      qc_debugLog(`Showing missing defaults dialog for ${proposedReportKey}`, {
        reportId: stored?.id,
        "why?": defaultsCheck,
        stored: stored?.result,
      });
      showMissingDefaultsDialog(shouldShowDialog);
    }
  }, [shouldShowDialog, showMissingDefaultsDialog]);

  return missingDefaultsDialog ?? null;
};
