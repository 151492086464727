/*
eslint-disable react/prop-types, react/display-name, react/jsx-key
 */

import React, {
  CSSProperties,
  forwardRef,
  ReactNode,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  IdType,
  Row as RowType,
  TableOptions,
  useExpanded,
  useFilters,
  useGlobalFilter,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { TableVirtuoso } from "react-virtuoso";
import styled from "styled-components";

import { FlexLoading } from "../Loading";
import { DefaultColumnFilter } from "./DefaultColumnFilter";
import { DefaultHeaderCell } from "./DefaultHeaderCell";
import { TableControlsType } from "./TableControlsType";
import { TableDataType } from "./TableDataType";
import {
  checkBoxColumnStyle,
  expandColumnStyle,
  useAppendExtraColumns,
} from "./useAppendExtraColumns";

//region Table Styles

const ScrollWrapper = styled.div`
  overflow-x: auto;
  display: flex;
  height: 100%;
  position: relative;
  flex-direction: column-reverse;
`;

const SizingWrapper = styled.div<{ minWidth: number }>`
  display: flex;
  height: 100%;
  position: relative;
  flex-direction: column-reverse;
  min-width: ${(props) => props.minWidth + "px"};
`;

const TableWrapper = styled.table`
  width: 100%;
  table-layout: fixed;
  font-size: 12px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.neutral.neutral3};
  border-collapse: collapse;
  border-spacing: 0;
`;

const HeaderTable = styled(TableWrapper)`
  flex: 0;
`;

const TableHead = styled.thead`
  z-index: 1;
  position: sticky;
  top: 0;
`;

const Header = styled.tr`
  text-align: left;
  background: ${({ theme }) => theme.colors.background.secondary};
`;

const HeaderCell = styled.th`
  padding: 0.4rem;
`;

const TableBody = styled.tbody``;

const Row = styled.tr<{ clickable?: boolean }>`
  cursor: ${(props) => (props.clickable ? "pointer" : "unset")};
`;

const Cell = styled.td`
  padding: 0.4rem;
`;

const CellNoPadding = styled(Cell)`
  padding: unset;
`;

const LoadingOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: ${({ theme }) => theme.colors.primary.primary10Alt};
  z-index: 2;
`;

//endregion

export interface TableProps<D extends TableDataType> extends TableOptions<D> {
  forwardedRef?: Ref<TableControlsType<D>>;
  enableSelect?: boolean;
  selectColumnOrder?: number;
  enableExpand?: boolean;
  expandColumnOrder?: number;
  onRowsSelected?: (rows: RowType<D>[], prevRows: RowType<D>[]) => void;
  renderSubComponent?: ({ row }: { row: RowType<D> }) => JSX.Element;
  initialSelectedRowIds?: Record<IdType<D>, boolean>;
  loading?: boolean;
  loadingMessage?: ReactNode;
  onClickRow?: (row: D) => void;
  query?: string;
  globalFilterCallback?: (row: D, query: string) => boolean;
  initialHeight?: number;
  maxHeight?: number;
  onHeightChanged?: (height: number) => void;
  minimumWidth?: number;
  increaseViewportBy?: number;
  onVisibleRowsChanged?: (rows: D[]) => void;
}

export function Table<D extends TableDataType>({
  columns,
  data,
  forwardedRef,
  enableSelect = false,
  selectColumnOrder = 0,
  enableExpand = false,
  expandColumnOrder = 1,
  onRowsSelected,
  renderSubComponent,
  initialSelectedRowIds = {} as Record<IdType<D>, boolean>,
  loading = false,
  loadingMessage,
  getRowId,
  onClickRow,
  query = "",
  globalFilterCallback = () => true,
  initialHeight,
  maxHeight,
  onHeightChanged,
  onVisibleRowsChanged,
  minimumWidth = 400,
  increaseViewportBy = 50,
}: TableProps<D>): JSX.Element {
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
      disableFilters: true,
    }),
    []
  );

  const [tableHeight, setTableHeight] = useState<number | undefined>(initialHeight ?? maxHeight);

  const appendExtraColumns = useAppendExtraColumns<D>();

  const globalFilter = useCallback(
    (rows: RowType<D>[], ids: IdType<D>[], query: string) => {
      return rows.filter(({ isSelected, original }) => {
        return isSelected || globalFilterCallback(original, query);
      });
    },
    [globalFilterCallback]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    visibleColumns,
    prepareRow,
    selectedFlatRows,
    toggleRowSelected,
    toggleAllRowsSelected,
    toggleAllRowsExpanded,
    state: { selectedRowIds },
    setAllFilters,
    toggleRowExpanded,
    setGlobalFilter,
  } = useTable<D>(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        selectedRowIds: initialSelectedRowIds,
      },
      autoResetSelectedRows: false,
      autoResetFilters: false,
      globalFilter,
      autoResetExpanded: false,
      getRowId,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    useRowSelect,
    (hooks) =>
      appendExtraColumns(hooks, enableSelect, enableExpand, selectColumnOrder, expandColumnOrder)
  );

  useEffect(() => {
    setGlobalFilter(query); // Set the Global Filter to the filter prop.
  }, [setGlobalFilter, query]);

  const [previouslySelectedRows, setPreviouslySelectedRows] = useState<RowType<D>[]>([]);

  const handleClickRow = (row: D) => {
    onClickRow?.(row);
  };

  useImperativeHandle(
    forwardedRef,
    () => ({
      clearSelectedRows: () => toggleAllRowsSelected(false),
      selectAllRows: () => toggleAllRowsSelected(true),
      clearAllFilters: () => setAllFilters([]),
      setRowSelected: (rowId, selected) => toggleRowSelected(rowId, selected),
      setRowExpanded: (rowIds, expanded) => toggleRowExpanded(rowIds, expanded),
      toggleAllRowsExpanded: (expanded) => toggleAllRowsExpanded(expanded),
    }),
    [forwardedRef]
  );

  useEffect(() => {
    if (!onRowsSelected) {
      return;
    }
    // Begin with the previously selected rows as they may no longer be in the currently filtered rows
    // Then add any newly selected rows that are not in the previously selected rows
    const currentlySelectedRows = [
      ...previouslySelectedRows.filter(({ id }) => selectedRowIds[id]),
      ...selectedFlatRows.filter(({ id }) => !previouslySelectedRows.some((row) => row.id === id)),
    ];
    onRowsSelected(currentlySelectedRows, previouslySelectedRows);
    setPreviouslySelectedRows(currentlySelectedRows);
  }, [selectedRowIds, onRowsSelected, data]);

  useEffect(() => {
    onVisibleRowsChanged?.(rows.map(({ original }) => original));
  }, [rows, onVisibleRowsChanged]);

  const handleDynamicHeight = (height: number) => {
    if (!maxHeight) {
      return;
    }

    const newHeight = height < maxHeight ? height : maxHeight;
    if (tableHeight === newHeight) {
      return;
    }

    setTableHeight(newHeight);
    onHeightChanged?.(newHeight);
  };

  const orderedColumns: { style?: CSSProperties }[] = [...columns];
  if (enableSelect) {
    orderedColumns.splice(selectColumnOrder, 0, checkBoxColumnStyle);
  }
  if (enableExpand) {
    orderedColumns.splice(expandColumnOrder, 0, expandColumnStyle);
  }

  const columnGroups = (
    <colgroup>
      {orderedColumns.map((column, index) => {
        const { style } = column ?? { style: undefined };
        return <col key={index} style={style} />;
      })}
    </colgroup>
  );

  return (
    <ScrollWrapper>
      <SizingWrapper minWidth={minimumWidth}>
        {loading && (
          <LoadingOverlay>
            <FlexLoading size={24} thickness={6}>
              {loadingMessage}
            </FlexLoading>
          </LoadingOverlay>
        )}
        <TableVirtuoso
          style={{
            ...(tableHeight && { height: tableHeight }),
          }}
          increaseViewportBy={increaseViewportBy}
          totalListHeightChanged={handleDynamicHeight}
          totalCount={rows.length}
          components={{
            Table: ({ style, ...props }) => {
              const { children, ...otherProps } = props;
              return (
                <TableWrapper {...getTableProps()} {...otherProps} style={style}>
                  {columnGroups}
                  {children}
                </TableWrapper>
              );
            },
            TableBody: forwardRef(({ style, ...props }, ref) => (
              <TableBody {...getTableBodyProps()} {...props} ref={ref} style={style} />
            )),
            TableRow: (props) => {
              const index = props["data-index"];
              const row = rows[index];
              const { children, ...other } = props;
              return (
                <Row
                  {...row.getRowProps()}
                  {...other}
                  clickable={!!onClickRow}
                  onClick={() => handleClickRow(row.original)}
                >
                  <CellNoPadding colSpan={visibleColumns.length}>
                    <TableWrapper {...getTableBodyProps()}>
                      {columnGroups}
                      <TableBody>{children}</TableBody>
                    </TableWrapper>
                  </CellNoPadding>
                </Row>
              );
            },
          }}
          itemContent={(index) => {
            const row = rows[index];
            prepareRow(row);
            return (
              <>
                <Row>
                  {row.cells.map((cell) => (
                    <Cell {...cell.getCellProps()}>{cell.render("Cell")}</Cell>
                  ))}
                </Row>
                {row.isExpanded && (
                  <Row>
                    <Cell colSpan={visibleColumns.length}>{renderSubComponent?.({ row })}</Cell>
                  </Row>
                )}
              </>
            );
          }}
        />
        {/*
      We put the header in its own table completely since react-virtuoso re-renders the header when filtering is applied, causing the filters to re-mount.
      We also put the header below so that it renders last, and use flex: column-reverse to put it above the table
      */}
        <HeaderTable>
          {columnGroups}
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <Header {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  if (column.disableFilters) {
                    return (
                      <HeaderCell {...column.getHeaderProps()}>
                        <DefaultHeaderCell column={column} {...column.getHeaderProps()} />
                      </HeaderCell>
                    );
                  }

                  return (
                    <HeaderCell {...column.getHeaderProps()}>{column.render("Filter")}</HeaderCell>
                  );
                })}
              </Header>
            ))}
          </TableHead>
        </HeaderTable>
      </SizingWrapper>
    </ScrollWrapper>
  );
}
