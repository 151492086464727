import styled from "styled-components";

import { LesionTaskCheckType } from "../../../../../../../types/TaskDescriptionType";
import { formatLesionName } from "../../../LesionsListItemHeaderLabel";
import { allLesionCheckComponents } from "../allSaveCheckComponents";
import { LesionCheckResultLevelType } from "../types/SaveCheckResultType";
import { LesionTaskCheckResults } from "../useTaskSaveChecks";
import { getUnsuccessfulLesionCheckResults } from "../utils/getUnsuccessfulLesionCheckResults";
import { LesionCheckRow } from "./CheckRow";

const Text = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
`;

interface LesionCheckResultsProps {
  result: LesionTaskCheckResults;
  onRequestClose: () => void;
  hideActionLevels?: LesionCheckResultLevelType[];
}

export function LesionCheckResults({
  result,
  onRequestClose,
  hideActionLevels = [],
}: LesionCheckResultsProps): JSX.Element {
  const { lesion, results } = result;
  const lesionName = formatLesionName(lesion);

  const unsuccessfulChecks = getUnsuccessfulLesionCheckResults(results);

  if (unsuccessfulChecks.length === 0) {
    return <></>;
  }

  return (
    <>
      <Text>{lesionName}</Text>
      {unsuccessfulChecks.map((result, index) => {
        const { type, level } = result;
        const hideActions = hideActionLevels.includes(level);
        const actions = allLesionCheckComponents[type as LesionTaskCheckType];
        return (
          <LesionCheckRow
            key={index}
            actions={actions}
            hideActions={hideActions}
            onRequestClose={onRequestClose}
            {...result}
          />
        );
      })}
    </>
  );
}
