import { useMemo } from "react";

import { useTimepointsNonViewOnlySeries } from "./useTimepointsNonViewOnlySeries";

export function useTimepointsNonViewOnlySeriesIds(): number[] | undefined {
  const timepoints = useTimepointsNonViewOnlySeries();
  return useMemo(() => {
    if (!timepoints) {
      return undefined;
    }

    return timepoints.flatMap(({ series }) => series.map(({ id }) => id));
  }, [timepoints]);
}
