import ClearIcon from "@mui/icons-material/Clear";
import React, { useContext } from "react";
import { Column } from "react-table";
import styled from "styled-components";

import { ReactComponent as EditIcon } from "../../../assets/svgs/EditOutline.svg";
import { UserTableRowType } from "../../../Dashboard/components/Settings/Users/UserTableRowType";
import { orderRolesByGroup } from "../../types/RoleGroupType";
import { UserType } from "../../types/UserType";
import { ConditionalGreyedWrapper } from "../../utils/ConditionalGreyedWrapper";
import { Avatar } from "../Avatar/Avatar";
import { MuiIcon } from "../icons/MuiIcon";
import { SvgIcon } from "../icons/SvgIcon";
import { CellProps } from "../Table/CellProps";
import { DeleteUserButton } from "./Dialogs/DeleteUserButton";
import { useEditUserDialog } from "./Dialogs/useEditUserDialog";
import { RoleLabel } from "./RoleLabel";
import { UserManagementContext } from "./UserManagementContext";
import { formatUserDisplayName } from "./utils/formatUserDisplayName";

export const USER_IDENTIFIER_COLUMN_ID = "userIdentifier";
export const TITLE_COLUMN_ID = "title";
export const ORGANIZATION_COLUMN_ID = "organization";
export const PROJECT_ROLE_COLUMN_ID = "projectRoles";
export const EDIT_USER_COLUMN_ID = "editUser";
export const REMOVE_USER_COLUMN_ID = "removeUser";

const Text = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.neutral.black};
  min-width: 125px;
  margin: auto 10px;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 6px;
  gap: 3px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 8px;
  cursor: pointer;
`;

const RolesWrapper = styled(FlexWrapper)`
  justify-content: center;
`;

const UserWrapper = styled.div`
  display: flex;
  margin: auto;
`;

export function useProjectUsersTableColumns(): Column<UserType>[] {
  const {
    rules: { allowRemoveUser, allowEditOtherUser },
  } = useContext(UserManagementContext);

  const columns: Column<UserType>[] = [
    {
      id: USER_IDENTIFIER_COLUMN_ID,
      Header: "User",
      accessor: (user) => user,
      showFilter: false,
      Cell: ({ value: user }: { value: UserType }) => (
        <ConditionalGreyedWrapper greyed={user.suspended}>
          <UserWrapper>
            <Avatar user={user} />
            <Text>{formatUserDisplayName(user)}</Text>
          </UserWrapper>
        </ConditionalGreyedWrapper>
      ),
    },
    {
      id: TITLE_COLUMN_ID,
      Header: "Title",
      accessor: (user) => user,
      Cell: ({ value: user }: { value: UserType }) => {
        return (
          <ConditionalGreyedWrapper greyed={user.suspended}>
            {user.title ?? "N/A"}
          </ConditionalGreyedWrapper>
        );
      },
      showFilter: true,
    },
    {
      id: ORGANIZATION_COLUMN_ID,
      Header: "Organization",
      accessor: (user) => user,
      Cell: ({ value: user }: { value: UserType }) => {
        return (
          <ConditionalGreyedWrapper greyed={user.suspended}>
            {user.organization ?? "N/A"}
          </ConditionalGreyedWrapper>
        );
      },
      showFilter: true,
    },
    {
      id: PROJECT_ROLE_COLUMN_ID,
      Header: "Roles",
      accessor: (user) => user,
      // eslint-disable-next-line react/display-name,@typescript-eslint/explicit-module-boundary-types
      Cell: ({ value: user }: { value: UserType }) => {
        return (
          <RolesWrapper>
            {orderRolesByGroup(user.projectRoles ?? []).map((role, index) => (
              <ConditionalGreyedWrapper key={index} greyed={user.suspended}>
                <RoleLabel role={role} />
              </ConditionalGreyedWrapper>
            ))}
          </RolesWrapper>
        );
      },
    },
  ];
  if (allowEditOtherUser) {
    columns.push({
      id: EDIT_USER_COLUMN_ID,
      Header: "",
      style: {
        width: "42px",
      },
      // eslint-disable-next-line react/prop-types,react/display-name
      Cell: ({ row: { original: row } }: CellProps<UserTableRowType>) => {
        const [setShowEditUserDialog, { dialog: editUserDialog }] = useEditUserDialog(row);
        const handleOnClick = () => {
          setShowEditUserDialog(true);
        };

        return (
          <>
            {editUserDialog}
            <ButtonWrapper onClick={handleOnClick}>
              <SvgIcon icon={EditIcon} size={16} />
            </ButtonWrapper>
          </>
        );
      },
    });
  }
  if (allowRemoveUser) {
    columns.push({
      id: REMOVE_USER_COLUMN_ID,
      Header: "",
      style: {
        width: "42px",
      },
      // eslint-disable-next-line react/prop-types,react/display-name
      Cell: ({ row: { original: row } }: CellProps<UserTableRowType>) => {
        return (
          <ButtonWrapper>
            <DeleteUserButton user={row}>
              <MuiIcon icon={ClearIcon} size={16} />
            </DeleteUserButton>
          </ButtonWrapper>
        );
      },
    });
  }

  return columns;
}
