import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { MutationTuple } from "@apollo/client/react/types/types";

import { TumourResponseType } from "../../../../../../Analysis/common/types/TumourResponseType";
import { SeriesTumourResponseType } from "../../StudyPanel/utils/TumourResponse/types/SeriesTumourResponseType";
import { RoiListItemFragmentType } from "../fragments/RoiListItemFragment";
import { CUDMode, DELETE, INSERT, UPDATE } from "../utils/CUDModes";
import { getTumourCacheId } from "./getTumourCacheId";
import { getTumourResponseCacheId } from "./getTumourResponseCacheId";

const MUTATION = gql`
  mutation UpdateTumourResponses(
    $insertObjects: [tumour_response_insert_input!]!
    $tumourResponseIdsToDelete: [Int!]!
  ) {
    update: insert_tumour_response(
      objects: $insertObjects
      on_conflict: {
        constraint: tumour_response_id_tumour_id_key
        update_columns: [classification]
      }
    ) {
      tumourResponses: returning {
        tumourId: tumour_id
        id
        classification
      }
    }
    delete: delete_tumour_response(where: { id: { _in: $tumourResponseIdsToDelete } }) {
      tumourResponses: returning {
        tumourId: tumour_id
        id
      }
    }
  }
`;

type UpdateVariables = {
  tumour_id: number;
  id: number;
  classification: TumourResponseType;
};

type InsertVariables = {
  tumour_id: number;
  classification: TumourResponseType;
  series_tumour_response_maps: {
    data: { series_id: number };
    on_conflict: {
      constraint: string;
      update_columns: string[];
    };
  };
};

type ResponseVariables = UpdateVariables | InsertVariables;

type Variables = {
  insertObjects: ResponseVariables[];
  tumourResponseIdsToDelete: number[];
};

type Data = {
  update: {
    tumourResponses: {
      tumourId: number;
      id: number;
      classification: TumourResponseType;
    }[];
  };
  delete: { tumourResponses: { tumourId: number; id: number }[] };
};

export function useUpdateTumourResponses(): MutationTuple<Data, Variables> {
  return useMutation<Data, Variables>(MUTATION, {
    update(cache, { data }) {
      if (!data) {
        throw new Error("Something went wrong updating the cache after updating tumour responses");
      }

      const {
        update: { tumourResponses: updated },
        delete: { tumourResponses: deleted },
      } = data;

      for (const updatedTumourResponse of updated) {
        const { tumourId, id } = updatedTumourResponse;
        cache.modify({
          id: getTumourCacheId(tumourId, cache),
          fields: {
            tumour_responses(tumourResponseRefs = [], { readField }) {
              const newTumourResponseRef = cache.writeFragment({
                data: updatedTumourResponse,
                fragment: gql`
                  fragment TumourResponse on tumour_response {
                    id
                    tumour_id
                    classification
                  }
                `,
              });

              if (tumourResponseRefs.some((ref: never) => readField("id", ref) === id)) {
                return tumourResponseRefs;
              }

              return [...tumourResponseRefs, newTumourResponseRef];
            },
          },
        });
      }

      const deletedTumourResponseIds = deleted.map(({ id }) => id);
      for (const id of deletedTumourResponseIds) {
        const tumourResponseCacheId = getTumourResponseCacheId(id, cache);
        cache.evict({ id: tumourResponseCacheId });
      }

      cache.gc();
    },
  });
}

type Input = {
  roiId: number;
  seriesId: number;
  response: TumourResponseType | undefined;
  data: {
    tumourId: number;
    tumourResponseIds: number[];
  }[];
};

export function getVariables(
  rois: RoiListItemFragmentType[],
  responses: SeriesTumourResponseType[]
): Variables {
  const insertObjects: ResponseVariables[] = [];
  const tumourResponseIdsToDelete: number[] = [];

  const inputs = rois.map((roi) => getInput(roi, responses)).filter((input) => !!input) as Input[];

  for (const input of inputs) {
    const mode = getMode(input);

    const { response, data, seriesId } = input;

    switch (mode) {
      case DELETE: {
        const tumourResponseIds = data.flatMap(({ tumourResponseIds }) => tumourResponseIds);
        tumourResponseIdsToDelete.push(...tumourResponseIds);
        break;
      }
      case INSERT: {
        if (!response) {
          throw new Error("Response should be undefined here");
        }
        insertObjects.push(
          ...data.map(({ tumourId }) => ({
            tumour_id: tumourId,
            classification: response,
            series_tumour_response_maps: {
              data: { series_id: seriesId },
              on_conflict: {
                constraint: "series_tumour_response_map_pkey",
                update_columns: [],
              },
            },
          }))
        );
        break;
      }
      case UPDATE:
        if (!response) {
          throw new Error("Response should be undefined here");
        }

        insertObjects.push(
          ...data.flatMap(({ tumourId, tumourResponseIds }) =>
            tumourResponseIds.map((id) => ({
              tumour_id: tumourId,
              id,
              classification: response,
            }))
          )
        );
        break;
      default:
        throw new Error(`Mode ${mode} not defined`);
    }
  }

  return {
    insertObjects,
    tumourResponseIdsToDelete,
  };
}

function getInput(
  { id: roiId, tumours, series: { id: seriesId } }: RoiListItemFragmentType,
  seriesResponses: SeriesTumourResponseType[]
): Input | undefined {
  const matchingResponse = seriesResponses.find(
    ({ seriesId: responseSeriesId }) => responseSeriesId === seriesId
  );
  if (!matchingResponse) {
    console.warn(`No response for series with id ${seriesId}`);
    return undefined;
  }

  const { tumourResponse } = matchingResponse;

  if (!tumours || tumours.length === 0) {
    throw new Error(`roi with id ${roiId} does not have tumours in useUpdateTumourResponse`);
  }

  const data = tumours.map(({ tumour: { id: tumourId, responses } }) => {
    const tumourResponseIds = responses.map(({ id }) => id);
    return { tumourId, tumourResponseIds };
  });

  return {
    roiId,
    seriesId,
    response: tumourResponse,
    data,
  };
}

function getMode({ response, data }: Input): CUDMode {
  if (!response) {
    return DELETE;
  }

  const hasResponses = data.some(({ tumourResponseIds }) => tumourResponseIds.length > 0);
  if (!hasResponses) {
    return INSERT;
  }

  return UPDATE;
}
