import { CohortType } from "nota-predict-web/src/common/types/CohortType";

import { useDialogReturnType } from "../../../../common/components/Dialog/useDialog";
import { useFormDialog } from "../../../../common/components/Dialog/useFormDialog";
import { CreateCohortForm } from "./CreateCohortForm";

export interface EditCohortFormProps {
  cohort: CohortType;
}

export function useCreateCohortForm(projectId?: number): useDialogReturnType {
  return useFormDialog({
    children: CreateCohortForm,
    label: projectId ? "Create Project Cohort" : "Create Global Cohort",
    showCloseButton: true,
    props: { cohort: undefined, projectId: projectId },
  });
}

export function useEditCohortForm({
  cohort
}: EditCohortFormProps): useDialogReturnType {
  return useFormDialog({
    children: CreateCohortForm,
    label: "Edit Cohort",
    showCloseButton: true,
    props: { cohort },
  });
}
