import { LesionListItemFragmentType } from "../../../AnnotationPanel/fragments/LesionListItemFragment";
import { toggleCornerstonePointVisibility } from "./toggleCornerstonePointVisibility";

export function toggleLesionsMeasurementsVisibility(
  lesions: LesionListItemFragmentType[],
  visible: boolean
): void {
  lesions.forEach((lesion) => {
    const { rois } = lesion;

    rois.forEach(({ roiRecistEvaluation }) => {
      const { LADPoints, SADPoints } = roiRecistEvaluation || {};

      if (!LADPoints || !SADPoints) {
        return;
      }

      toggleCornerstonePointVisibility(LADPoints, SADPoints, visible);
    });
  });
}
