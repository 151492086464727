import React, { FC, useContext, useEffect, useState } from "react";
import styled from "styled-components";

import FilterCheckbox from "../../../../Analysis/ToolPanel/components/FilterCheckbox";
import { main } from "../../../theme/main";
import { ActionButtonsWrapper } from "../../Dialog/Form/ActionButtonsWrapper";
import { FormProps } from "../../Dialog/FormProps";
import { useDialogReturnType } from "../../Dialog/useDialog";
import { useFormDialog } from "../../Dialog/useFormDialog";
import { InputButton } from "../../input/InputButton";
import {
  AnonymizationConfigurationContext,
  AnonymizationMethodType,
} from "./Contexts/AnonymizationConfigurationContext";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 400px;
`;

const MetadataLabel = styled.div`
  font-size: 12px;
`;

export type ConfigType = {
  patientId: boolean;
  patientName: boolean;
};

export function useAnonymizationEncryptionSelectForm(): useDialogReturnType {
  return useFormDialog({
    children: AnonymizationEncryptionSelectForm,
    label: "Anonymization",
    showCloseButton: true,
    reactModalProps: {
      shouldCloseOnOverlayClick: true,
      shouldCloseOnEsc: true,
    },
    props: undefined,
  });
}

const defaultConfig: ConfigType = { patientName: false, patientId: false };

export const AnonymizationEncryptionSelectForm: FC<FormProps<undefined>> = ({
  onSubmit,
  onCancel,
}: FormProps<undefined>) => {
  const configType: AnonymizationMethodType = "SUBJECT_ID_ENCRYPTION";

  const { config, setConfig } = useContext(AnonymizationConfigurationContext);

  const globalConfig = config[configType] as ConfigType;

  const [localConfig, setLocalConfig] = useState<ConfigType>(defaultConfig);

  useEffect(() => {
    setLocalConfig(globalConfig ?? defaultConfig);
  }, [globalConfig]);

  const patientIdChecked = localConfig.patientId;

  const patientNameChecked = localConfig.patientName;

  const isValid = patientNameChecked || patientIdChecked;

  const handleSubmitForm = () => {
    if (!isValid) {
      throw new Error("handleSubmit form was clicked, but isValid === false");
    }

    if (!localConfig) {
      throw new Error("Anonymization Parameters is null in AnonymizationEncryptionSelectForm");
    }

    setConfig((prevConfig) => {
      return {
        ...prevConfig,
        [configType]: localConfig,
      };
    });

    onSubmit();
  };

  const handleCancel = () => {
    onCancel();
  };

  const handlePatientNameChanged = (checked: boolean) => {
    setLocalConfig((prevConfig) => {
      return {
        ...prevConfig,
        patientName: checked,
      };
    });
  };

  const handlePatientIdChanged = (checked: boolean) => {
    setLocalConfig((prevConfig) => {
      return {
        ...prevConfig,
        patientId: checked,
      };
    });
  };

  return (
    <>
      <Wrapper>
        <MetadataLabel>
          <FilterCheckbox
            name={"patientId"}
            label={"Subject ID"}
            checked={patientIdChecked}
            onChange={handlePatientIdChanged}
          />
        </MetadataLabel>
        <MetadataLabel>
          <FilterCheckbox
            name={"patientName"}
            label={"Subject Name"}
            checked={patientNameChecked}
            onChange={handlePatientNameChanged}
          />
        </MetadataLabel>
      </Wrapper>
      <ActionButtonsWrapper>
        <InputButton
          type="submit"
          name="submit-button"
          value={"Confirm"}
          disabled={!isValid}
          background={!isValid ? main.colors.actionPrimary.disabled : undefined}
          borderColor={!isValid ? main.colors.actionPrimary.hover10 : undefined}
          onClick={handleSubmitForm}
        />
        <InputButton
          type="button"
          name="cancel-button"
          value={"Cancel"}
          background={main.colors.neutral.white}
          color={main.colors.neutral.black}
          onClick={handleCancel}
        />
      </ActionButtonsWrapper>
    </>
  );
};
