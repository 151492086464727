import { TimepointOptionsType } from "../../../../../types/TaskDescriptionType";
import { RoiClassificationButtonProps } from "../RoiCard/RoiClassificationButtons";

export function getRoiClassificationButton(
  option: TimepointOptionsType,
  isSet: boolean
): RoiClassificationButtonProps {
  return {
    ...option,
    isSet,
  };
}
