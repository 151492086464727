import { BurdenType, IPRO, MORTALITY, RECIST } from "../../ChangeFromBaseline/components/Filter";
import { SubjectType } from "../../common/types/SubjectType";
import { followUpIds } from "../../common/utils/followUpIds";
import { getFollowUpAtOrder } from "../../common/utils/getFollowUpAtOrder";
import { getFollowUpDiametricTumourBurden } from "../../common/utils/getFollowUpDiametricTumourBurden";
import { getFollowUpInMonths } from "../../common/utils/getFollowUpInMonths";
import { getFollowUpMortalityRisk } from "../../common/utils/getFollowUpMortalityRisk";
import { getFollowUpSurvivalPrediction } from "../../common/utils/getFollowUpSurvivalPrediction";
import { getFollowUpVolumetricTumourBurden } from "../../common/utils/getFollowUpVolumetricTumourBurden";
import { getMostRecentFollowUp } from "../../common/utils/getMostRecentFollowUp";
import { projectArmIds } from "../../common/utils/projectArmIds";

export type AnalysisGraphData<T> = {
  [RECIST]: Record<BurdenType, T>;
  [IPRO]: T;
  [MORTALITY]: T;
};

export type AnalysisGraphKeys = keyof AnalysisGraphData<never>;

type MedianGraphData = AnalysisGraphData<number | null>;

export type FollowUpMedianGraphData = {
  followUpOrder: number;
  byArm: { [arm: number]: MedianGraphData };
};

export type MedianDomain = AnalysisGraphData<[number, number]>;

export function getMedianSurvivalSeries(subjects: SubjectType[]): FollowUpMedianGraphData[] {
  const byFollowUp: {
    [followUp: number]: {
      [arm: number]: AnalysisGraphData<number[]>;
    };
  } = {};

  for (const followUpId of followUpIds) {
    if (!byFollowUp[followUpId]) {
      byFollowUp[followUpId] = {};
    }

    for (const projectArmId of projectArmIds) {
      if (!byFollowUp[followUpId][projectArmId]) {
        byFollowUp[followUpId][projectArmId] = {
          [RECIST]: {
            DIAMETRIC: [],
            VOLUMETRIC: [],
          },
          [IPRO]: [],
          [MORTALITY]: [],
        };
      }
    }
  }

  for (const subject of subjects) {
    const {
      projectArm: { number: arm },
      followUps,
    } = subject;

    //get last follow up and add it to bucket
    //iterate forward to max number of followups, subtract time and add any residuals > 0
    const mostRecentFollowUp = getMostRecentFollowUp(followUps);
    if (!mostRecentFollowUp) {
      continue;
    }

    const latestSurvival = getFollowUpSurvivalPrediction(mostRecentFollowUp);
    const latestMortality = getFollowUpMortalityRisk(mostRecentFollowUp);

    const { order: latestFollowUpId } = mostRecentFollowUp;

    for (const followUpId of [...followUpIds].reverse()) {
      if (latestFollowUpId < followUpId) {
        // if latest follow up is before this follow up, add residual
        // this is done since, although we don't have the followup data, we can still
        // infer the survival time from the latest follow up
        if (latestSurvival !== null) {
          const differenceMonths = getFollowUpInMonths(followUpId - latestFollowUpId);
          const survivalAtFollowUp = latestSurvival - differenceMonths;
          byFollowUp[followUpId][arm][IPRO].push(survivalAtFollowUp > 0 ? survivalAtFollowUp : 0);
        }
      } else if (latestFollowUpId === followUpId) {
        if (latestSurvival !== null) {
          byFollowUp[followUpId][arm][IPRO].push(latestSurvival);
        }
        if (latestMortality !== null) {
          byFollowUp[followUpId][arm][MORTALITY].push(latestMortality);
        }
      } else {
        const followUp = getFollowUpAtOrder(followUps, followUpId);

        const survivalPrediction = followUp ? getFollowUpSurvivalPrediction(followUp) : null;
        const mortalityRisk = followUp ? getFollowUpMortalityRisk(followUp) : null;

        if (survivalPrediction !== null) {
          byFollowUp[followUpId][arm][IPRO].push(survivalPrediction);
        }
        if (mortalityRisk !== null) {
          byFollowUp[followUpId][arm][MORTALITY].push(mortalityRisk);
        }
      }
    }

    for (const followUp of followUps) {
      const { order } = followUp;

      const diametricTumourBurden = getFollowUpDiametricTumourBurden(followUp);
      if (diametricTumourBurden !== null) {
        byFollowUp[order][arm][RECIST]["DIAMETRIC"].push(diametricTumourBurden);
      }

      const volumetricTumourBurden = getFollowUpVolumetricTumourBurden(followUp);
      if (volumetricTumourBurden !== null) {
        byFollowUp[order][arm][RECIST]["VOLUMETRIC"].push(volumetricTumourBurden);
      }
    }
  }

  //convert into series type suitable for plotting
  const series: FollowUpMedianGraphData[] = [];

  for (const followUpOrderKey in byFollowUp) {
    if (!Object.prototype.hasOwnProperty.call(byFollowUp, followUpOrderKey)) {
      continue;
    }

    const followUpOrder = Number(followUpOrderKey);
    const median: { [arm: number]: MedianGraphData } = {};
    const byArm = byFollowUp[followUpOrder];
    for (const arm in byArm) {
      if (!Object.prototype.hasOwnProperty.call(byArm, arm)) {
        continue;
      }

      const medianPrediction = getMedian(byArm[arm][IPRO]);
      const followUpTime = getFollowUpInMonths(followUpOrder);

      median[arm] = {
        [IPRO]: medianPrediction !== null ? medianPrediction + followUpTime : null,
        [RECIST]: {
          DIAMETRIC: getMedian(byArm[arm][RECIST]["DIAMETRIC"]),
          VOLUMETRIC: getMedian(byArm[arm][RECIST]["VOLUMETRIC"]),
        },
        [MORTALITY]: getMedian(byArm[arm][MORTALITY]),
      };
    }

    series.push({
      followUpOrder,
      byArm: median,
    });
  }

  return series;
}

export function getDomains(medianSurvivalSeries: FollowUpMedianGraphData[]): MedianDomain {
  const maxIPRO = Math.max(
    ...medianSurvivalSeries.flatMap(({ byArm }) =>
      Object.values(byArm).flatMap(({ IPRO }) => (IPRO ? [Math.ceil(IPRO)] : [0]))
    )
  );
  const maxMortality = Math.max(
    ...medianSurvivalSeries.flatMap(({ byArm }) =>
      Object.values(byArm).flatMap(({ MORTALITY }) => (MORTALITY ? [Math.ceil(MORTALITY)] : [0]))
    )
  );
  const maxDiametricBurden = Math.max(
    ...medianSurvivalSeries.flatMap(({ byArm }) =>
      Object.values(byArm).flatMap(({ RECIST }) =>
        RECIST["DIAMETRIC"] ? [Math.ceil(RECIST["DIAMETRIC"])] : [0]
      )
    )
  );
  const maxVolumetricBurden = Math.max(
    ...medianSurvivalSeries.flatMap(({ byArm }) =>
      Object.values(byArm).flatMap(({ RECIST }) =>
        RECIST["VOLUMETRIC"] ? [Math.ceil(RECIST["VOLUMETRIC"])] : [0]
      )
    )
  );

  return {
    [IPRO]: [0, maxIPRO],
    [RECIST]: {
      DIAMETRIC: [0, maxDiametricBurden],
      VOLUMETRIC: [0, maxVolumetricBurden],
    },
    [MORTALITY]: [0, maxMortality],
  };
}

function getMedian(values: number[]): number | null {
  if (values.length === 0) {
    return null;
  }

  values.sort((a, b) => a - b);

  const half = Math.floor(values.length / 2);

  if (values.length % 2) {
    return values[half];
  }

  return (values[half - 1] + values[half]) / 2.0;
}
