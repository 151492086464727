import { useFlags } from "launchdarkly-react-client-sdk";
import React, { forwardRef, RefObject, useCallback, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { Row } from "react-table";

import { useProjectPatientTaskRows } from "../../../Annotate/components/Manage/hooks/useProjectPatientTaskRows";
import { useGoToTaskless } from "../../../Project/Layout/AnnotatePage/useGoToTaskless";
import {
  patientTableHeightActions,
  studiesSubTableHeights,
} from "../../store/patientTableHeightsSlice";
import {
  makeLoadingSelector,
  makeStudyRowsSelector,
  seriesSelectionActions,
} from "../../store/seriesSelectionSlice";
import { useAppSelector } from "../../store/store";
import { SeriesTableColumnType } from "../SeriesTable/getColumns";
import { SELECT_HEADER_ID } from "../Table/getCheckboxColumn";
import { Table, TableProps } from "../Table/Table";
import { TableControlsType } from "../Table/TableControlsType";
import { getColumns, StudyTableColumnType } from "./StudyTableColumns";
import { getStudyTableRowId, StudyTableRowType } from "./StudyTableRowType";
import { SubSeriesTable } from "./SubSeriesTable";

interface StudyTableProps {
  tableKey: string;
  patientId?: number;
  tableRef?: RefObject<TableControlsType<StudyTableRowType>>;
  hiddenColumns?: StudyTableColumnType[];
  hiddenSeriesColumns?: SeriesTableColumnType[];
  enableSelect?: boolean;
  enableExpand?: boolean;
  maxHeight?: number;
}

export function StudyTable({
  tableKey,
  patientId,
  tableRef,
  hiddenColumns = [],
  hiddenSeriesColumns = [],
  enableSelect = false,
  enableExpand = false,
  maxHeight,
}: StudyTableProps): React.ReactElement {
  const flags = useFlags();
  const showReorgPages: boolean = flags["notaShowReorganisationPages"];

  const { search } = useLocation();
  const { replace } = useHistory();
  const dispatch = useDispatch();

  const searchParams = new URLSearchParams(search);

  const goToTaskless = useGoToTaskless();

  const loadingSelector = useMemo(makeLoadingSelector, []);
  const studyRowsSelector = useMemo(makeStudyRowsSelector, []);

  const rows = useAppSelector((state) => studyRowsSelector(state, tableKey));
  const loading = useAppSelector((state) => loadingSelector(state, tableKey));

  const ownRef = useRef<TableControlsType<StudyTableRowType>>(null);
  const ref = tableRef ?? ownRef;

  const heights = useSelector(studiesSubTableHeights);
  const { patientsTasks } = useProjectPatientTaskRows(true);

  const handleSelectAll = (isSelected: boolean) => {
    dispatch(seriesSelectionActions.setAllStudiesSelected({ tableKey, isSelected }));
  };

  const handleSelectStudy = (studyId: number, selected: boolean) => {
    dispatch(seriesSelectionActions.setStudySelected({ tableKey, studyId, selected }));
  };

  const handleOpenPatient = (patientId: number) => {
    searchParams.set("subject_id", patientId.toString());
    replace({ search: searchParams.toString() });
  };

  const handleOpenTaskless = (patientId: number) => {
    goToTaskless(patientId);
  };

  const handleHeightChanged = (height: number) => {
    if (patientId === undefined) {
      return;
    }

    dispatch(patientTableHeightActions.setSubStudiesTableHeight({ patientId, height }));
  };

  const initialHeight = patientId !== undefined ? heights[patientId] : undefined;

  const columns = useMemo(() => {
    return getColumns({
      hiddenColumns: enableSelect ? hiddenColumns : [...hiddenColumns, SELECT_HEADER_ID],
      onSelectAll: handleSelectAll,
      onSelectStudy: handleSelectStudy,
      onOpenPatient: handleOpenPatient,
      onOpenTaskless: handleOpenTaskless,
      patientsTasks,
      showReorgPages,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientsTasks]);

  const getRowId = useCallback(({ id }) => getStudyTableRowId(id), []);

  const handleCloseSeries = (row: StudyTableRowType) => {
    const { id } = row;
    const { current: tableControls } = ref;
    tableControls?.setRowExpanded([getStudyTableRowId(id)], false);
  };

  return (
    <>
      <TableWithRef
        ref={ref}
        columns={columns}
        data={rows}
        enableExpand={enableExpand}
        expandColumnOrder={enableSelect ? 1 : 0}
        minimumWidth={1000}
        /*
        we require a manually defined checkbox here due to the special way selection is handled for studies/series (i.e. using nested tables + redux). if we use the table's provided selection mechanism we end up with infinite loops since the redux implementation does not mutate state on selection causing the table to re-render and the selection callback to fire. attempting to fix this by early-outing only breaks the nested selection relationship with the parent
      */
        enableSelect={false}
        getRowId={getRowId}
        loading={loading}
        maxHeight={maxHeight}
        initialHeight={initialHeight}
        onHeightChanged={handleHeightChanged}
        renderSubComponent={(props: { row: Row<StudyTableRowType> }) => (
          <SubSeriesTable
            {...props}
            tableKey={tableKey}
            onClose={handleCloseSeries}
            enableSelect={enableSelect}
            hiddenColumns={hiddenSeriesColumns}
            loading={loading}
          />
        )}
      />
    </>
  );
}

const TableWithRef = forwardRef<
  TableControlsType<StudyTableRowType>,
  TableProps<StudyTableRowType>
>((props, ref) => {
  return <Table {...props} forwardedRef={ref} />;
});
TableWithRef.displayName = "TableWithRef";
