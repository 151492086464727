import React, { useEffect, useState } from "react";

import { Card } from "../common/components/cards/Card";
import { useOkForm } from "../common/components/Dialog/useOkForm";
import { BigFlexLoading } from "../common/components/Loading";
import {
  EditProjectSites,
  EditSitesOptions,
} from "../common/components/SiteManagement/EditProjectSites";
import { useCurrentUserCan } from "../common/contexts/UserContext/useCurrentUserCan";
import { useCurrentProject } from "../common/queries/useCurrentProject";
import { SiteType } from "../common/types/SiteType";
import { PROJECT_ROLE_COORDINATOR } from "../common/types/UserRoleType";
import handleApolloError from "../common/utils/handleApolloError";
import { useDeleteSite } from "./useDeleteSite";
import { useInsertSites } from "./useInsertSites";
import { useUpdateSite } from "./useUpdateSite";

export function SiteManagementCard(): JSX.Element {
  const isManager = useCurrentUserCan([PROJECT_ROLE_COORDINATOR]);

  const options: EditSitesOptions = {
    allowCreateSite: isManager,
    allowRemoveSite: isManager,
    allowEditSite: isManager,
  };

  const [sites, setSites] = useState<SiteType[]>([]);

  const { data: project, error, loading, refetch: refetchProject } = useCurrentProject();

  const [insertSites, { error: insertSitesError, loading: insertSitesLoading }] = useInsertSites();

  const [updateSite, { loading: updateSiteLoading, error: updateSiteError }] = useUpdateSite();

  const [deleteSite, { error: deleteSiteError, loading: deleteSiteLoading }] = useDeleteSite();

  const [showCreateErrorDialog, { dialog: createErrorDialog }] = useOkForm({
    title: "Error Creating New Site",
    message:
      "There was an error creating a new Site, please contact your administrator for more details.",
  });

  const [showUpdateErrorDialog, { dialog: updateErrorDialog }] = useOkForm({
    title: "Error Updating Site",
    message:
      "There was an error updating a Site, please contact your administrator for more details.",
  });

  useEffect(() => {
    if (!project) {
      setSites([]);
      return;
    }

    const { sites } = project;

    setSites(sites);
  }, [project]);

  useEffect(() => {
    if (insertSitesError) {
      showCreateErrorDialog(true);
    }
  }, [insertSitesError]);

  useEffect(() => {
    if (updateSiteError) {
      showUpdateErrorDialog(true);
    }
  }, [updateSiteError]);

  if (loading) return <BigFlexLoading />;
  if (error) handleApolloError(error);
  if (insertSitesLoading) return <BigFlexLoading />;
  if (updateSiteLoading) return <BigFlexLoading />;
  if (deleteSiteLoading) return <BigFlexLoading />;
  if (deleteSiteError) handleApolloError(deleteSiteError);

  const handleSitesCreated = async (sites: SiteType[]) => {
    if (!project) {
      throw new Error("Project is undefined in handleSiteCreated");
    }

    const { id: projectId } = project;

    await insertSites({ projectId, sites });
    await refetchProject();
  };

  const handleSiteRemoved = async (site: SiteType) => {
    await deleteSite(site);
    await refetchProject();
  };

  const handleSiteEdited = async (site: SiteType) => {
    await updateSite(site);
    await refetchProject();
  };

  return (
    <>
      {createErrorDialog}
      {updateErrorDialog}
      <Card
        noHead={true}
        expand={true}
        content={
          <EditProjectSites
            sites={sites}
            actionCallbacks={{
              onRemoved: handleSiteRemoved,
              onCreated: handleSitesCreated,
              onEdited: handleSiteEdited,
            }}
            options={options}
          />
        }
      />
    </>
  );
}
