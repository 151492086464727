import { gql } from "@apollo/client";
import { ApolloCache } from "@apollo/client/core";

import { getRoiCacheId } from "../../AnnotationPanel/hooks/getRoiCacheId";

export function getRoiEllipseImageIdFromCache<T>(
  roiId: number,
  cache: ApolloCache<T>
): string | null {
  const result = cache.readFragment<{ ellipse: { image_id: string | null } }>({
    id: getRoiCacheId(roiId, cache),
    fragment: gql`
      fragment RoiEllipse on roi {
        ellipse {
          image_id
        }
      }
    `,
  });
  const { ellipse } = result ?? { ellipse: null };
  if (!ellipse) {
    return null;
  }

  const { image_id } = ellipse;
  return image_id;
}
