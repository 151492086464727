import { useHistory } from "react-router-dom";

import { SETTINGS } from "../RoutePathType";

type ReturnType = () => void;

export function useGoToGlobalSettings(): ReturnType {
  const { push } = useHistory();

  return () => {
    push(`/${SETTINGS}`);
  };
}
