import { TimepointsFilters } from "../../types/ProjectViewFilterTypes";
import { RootState } from "../store";
import { ProjectFilterSelectors } from "./ProjectFilterSelectors";

export const defaultTimepointsState = {
  0: true,
  1: true,
  2: true,
  3: true,
  4: true,
  5: true,
  6: true,
  7: true,
};

export const clearTimepointsState = {
  0: false,
  1: false,
  2: false,
  3: false,
  4: false,
  5: false,
  6: false,
  7: false,
};

export const timePointFilters = new ProjectFilterSelectors<TimepointsFilters>(
  defaultTimepointsState,
  clearTimepointsState,
  (state: RootState) => state.projectViewFilters.timepoints
);
