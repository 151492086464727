import React, { Component, ErrorInfo, ReactNode } from "react";

import { ErrorPage } from "./ErrorPage";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render(): ReactNode | JSX.Element {
    if (this.state.hasError) {
      return <ErrorPage />;
    }

    return this.props.children;
  }
}
