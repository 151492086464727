import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React, { ReactElement, useMemo } from "react";
import styled from "styled-components";
import { z } from "zod";

import { FlexLoading } from "../../../common/components/Loading";
import { useProjectId } from "../../../Project/hooks/useProjectId";
import { ModelSummary, useAlgorithms } from "../hooks/useAlgorithms";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  width: 100%;
`;

const ModelSuiteInfoPanel = (): ReactElement => {
  const projectId = useProjectId();
  const { models, loading, error } = useAlgorithms(projectId);

  const latestModel = models[0];

  const details = useMemo(() => (latestModel ? parseModelDetails(latestModel) : []), [latestModel]);

  if (error || details.length === 0) {
    return (
      <Wrapper>
        <InfoTextRow title={"Model:"} detail={"Details unavailable"} />
      </Wrapper>
    );
  }

  if (loading) {
    return (
      <Wrapper>
        <FlexLoading />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <InfoWrapper>
        {details.map(({ title, detail }, key) => (
          <InfoTextRow title={title} detail={detail} key={key} />
        ))}
      </InfoWrapper>
    </Wrapper>
  );
};

export default ModelSuiteInfoPanel;

const InfoItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  gap: 0 35px;
  height: 25px;

  width: 100%;
`;

const BoldInfoText = styled.span`
  font-weight: bold;
`;

const InfoText = styled.div`
  /* H4/Regular */
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
`;

interface InfoTextRowProps {
  title: string;
  detail: string;
}

function InfoTextRow({ title, detail }: InfoTextRowProps): JSX.Element {
  return (
    <InfoItemWrapper>
      <InfoOutlinedIcon fontSize={"small"} />
      <InfoText>
        <BoldInfoText>{title}</BoldInfoText> {detail}
      </InfoText>
    </InfoItemWrapper>
  );
}

type Detail = {
  title: string;
  detail: string;
};

function parseModelDetails({ displayName, details }: ModelSummary): Detail[] {
  const schema = z.array(
    z.object({
      title: z.string(),
      detail: z.string(),
    })
  );
  const result = schema.safeParse(details);
  if (!result.success) {
    console.warn(`Error parsing model details for model ${displayName}`, result.error);
    return [];
  } else {
    return result.data;
  }
}
