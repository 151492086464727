import React, { useContext, useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useLocation } from "react-router";
import styled from "styled-components";

import { BigFlexLoading } from "../../common/components/Loading";
import { PatientContext } from "../../Dashboard/components/Settings/Patients/Viewer/PatientContext";
import { PatientContextProvider } from "../../Dashboard/components/Settings/Patients/Viewer/PatientContextProvider";
import { ViewerPanel } from "../../Dashboard/components/Settings/Patients/Viewer/ViewerPanel";
import { getTimepointFromSeriesId } from "../components/Annotate/page/AnnotationPanel/hooks/Timepoint/getTimepointFromSeriesId";
import { TimepointType } from "../components/Annotate/page/AnnotationPanel/hooks/Timepoint/TimepointType";
import { PatientTaskList } from "../components/Annotate/page/AnnotationPanel/PatientTaskList";
import { TaskInfo } from "../components/Annotate/page/StudyPanel/TaskInfo";
import { TasklessTimepointPanel } from "../components/Annotate/page/StudyPanel/TasklessTimepointPanel";
import { ViewerContextProvider } from "../components/Annotate/page/ViewerPanel/Viewer/ViewerContextProvider";
import { useTasklessPageParams } from "./useTasklessPageParams";

const Wrapper = styled.div`
  height: 100vh;
  padding: 3px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
`;

const TimepointColumnWrapper = styled.div`
  flex: 5;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 3px;
  gap: 6px;
`;

const ViewerColumnWrapper = styled.div`
  flex: 27;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 3px;
`;

const TaskColumnWrapper = styled.div`
  flex: 8;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 3px;
  gap: 6px;
  margin: 36px 0 12px 0;
`;

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  font-size: 13px;
`;

interface LocationState {
  seriesId?: number;
}

export function TasklessRouteContainer(): JSX.Element {
  const { patientId } = useTasklessPageParams();
  return (
    <DndProvider backend={HTML5Backend}>
      <PatientContextProvider patientId={patientId}>
        <ViewerContextProvider options={{ viewOnly: true }}>
          <Page />
        </ViewerContextProvider>
      </PatientContextProvider>
    </DndProvider>
  );
}

function Page(): JSX.Element {
  const [selectedTimepoint, setSelectedTimepoint] = useState<TimepointType | undefined>(undefined);

  const [selectedSeriesId, setSelectedSeriesId] = useState<number | undefined>(undefined);

  const { timepoints, loading } = useContext(PatientContext);

  const location = useLocation<LocationState>();
  const { seriesId: navSeriesId } = location.state || { seriesId: undefined };
  const handleTimepointSelected = (
    seriesId: number | undefined,
    timepoint: TimepointType | undefined
  ) => {
    setSelectedSeriesId(seriesId);
    setSelectedTimepoint(timepoint);
  };

  useEffect(() => {
    if (loading) {
      return;
    }

    if (!timepoints) {
      throw new Error("Timepoints are undefined in TasklessRouteContainer");
    }

    if (timepoints.length === 0) {
      return;
    }

    const updatedTimepoint: TimepointType = navSeriesId
      ? timepoints.find(({ series }) => series.some(({ id }) => id === navSeriesId)) ??
        timepoints[0]
      : timepoints[0];

    const { series: seriesList } = updatedTimepoint;

    const { id: seriesId } = seriesList[0];

    handleTimepointSelected(seriesId, updatedTimepoint);
  }, [timepoints, loading]);

  const handleViewerLoaded = (seriesId: number | undefined) => {
    const timepoint =
      seriesId !== undefined && timepoints
        ? getTimepointFromSeriesId(seriesId, timepoints)
        : undefined;

    handleTimepointSelected(seriesId, timepoint);
  };

  if (loading) {
    return (
      <Wrapper>
        <BigFlexLoading />
      </Wrapper>
    );
  }

  if (!timepoints || timepoints.length === 0) {
    return <ErrorWrapper>The selected subject does not contain any series.</ErrorWrapper>;
  }

  return (
    <Wrapper>
      <TimepointColumnWrapper>
        <TaskInfo standalone={true} />
        <TasklessTimepointPanel
          timepoints={timepoints}
          selected={selectedTimepoint}
          onSelected={handleTimepointSelected}
        />
      </TimepointColumnWrapper>

      <ViewerColumnWrapper>
        <ViewerPanel seriesId={selectedSeriesId} onLoaded={handleViewerLoaded} />
      </ViewerColumnWrapper>

      <TaskColumnWrapper>
        <PatientTaskList />
      </TaskColumnWrapper>
    </Wrapper>
  );
}
