import Tooltip from "@mui/material/Tooltip";
import React from "react";

import { ReactComponent as EditIcon } from "../../../../../assets/svgs/EditOutline.svg";
import { SvgIcon } from "../../../../../common/components/icons/SvgIcon";
import { main } from "../../../../../common/theme/main";
import { SeriesListItemFragmentType } from "../AnnotationPanel/hooks/useStudiesList";
import { IconButton } from "../AnnotationPanel/IconButton";
import { useSeriesNotesFormDialog } from "./SeriesNotesForm";

interface SeriesNotesButtonProps {
  series: SeriesListItemFragmentType;
  size?: number;
  noNotesColor?: string;
  hasNotesColor?: string;
}

export function SeriesNotesButton({
  series,
  size = 18,
  noNotesColor = main.colors.neutral.black,
  hasNotesColor = main.colors.states.success,
}: SeriesNotesButtonProps): JSX.Element {
  const [showSeriesNotesDialog, { dialog: seriesNotesDialog }] = useSeriesNotesFormDialog(series);

  const handleClick = () => {
    showSeriesNotesDialog(true);
  };

  const color = series.notes.length > 0 ? hasNotesColor : noNotesColor;

  return <>
    {seriesNotesDialog}
    <Tooltip title={"Series Notes"}>
      <IconButton onClick={handleClick}>
        <SvgIcon icon={EditIcon} size={size} color={color} />
      </IconButton>
    </Tooltip>
  </>;
}
