import React, { ReactElement } from "react";
import { CSVLink } from "react-csv";

import { ReactComponent as DownloadIcon } from "../../../assets/svgs/Download.svg";
import useCsvLink from "../../utils/useCsvLink";
import { SvgIcon } from "../icons/SvgIcon";
import { Loading } from "../Loading";
import CTAButton from "./CTAButton";

interface DownloadReportButtonProps {
  text: string;
}

const DownloadReportButton = ({ text }: DownloadReportButtonProps): ReactElement => {
  const { csvLink, loading } = useCsvLink({});
  if (loading) {
    return <Loading />;
  }

  if (!csvLink) {
    throw new Error("Csv link contents is null but loading is false!");
  }

  const { handleDownload, csvLinkRef, csvData } = csvLink;

  return (
    <>
      <CTAButton
        icon={<SvgIcon icon={DownloadIcon} size={22} />}
        text={text}
        onClick={handleDownload}
      />
      <CSVLink data={csvData} ref={csvLinkRef} filename={"analysis-data.csv"} />
    </>
  );
};

export default DownloadReportButton;
