import React, { FC } from "react";

import { Card } from "../../../common/components/cards/Card";
import { GraphCardProps } from "../../common/utils/GraphCardProps";
import { ChartDataTypeProps } from "../../MedianSurvival/components/MedianTumorBurdenSurvivalPredictionCard";
import PatientJourneyTumourDetailsContainer from "./PatientJourneyTumourDetailsContainer";

type PatientLongitudinalGraphCardProps = GraphCardProps & ChartDataTypeProps;

export const PatientLongitudinalGraphCard: FC<PatientLongitudinalGraphCardProps> = ({
  onClickHeader,
  allowedDataTypes,
}: PatientLongitudinalGraphCardProps) => {
  return (
    <Card
      title={"Subject Journey"}
      content={<PatientJourneyTumourDetailsContainer allowedDataTypes={allowedDataTypes} />}
      onClickHeader={onClickHeader}
    />
  );
};
