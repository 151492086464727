import axios, { CancelTokenSource } from "axios";
import React, { FC, useRef, useState } from "react";
import styled from "styled-components";

import { useGetAuthToken } from "../../../auth0/useGetAuthToken";
import { SUCCESS } from "../../../common/types/StatusTypes";
import { UPLOAD_DICOM } from "../../../Project/Layout/Layout";
import { DragAndDropUploaderPanel } from "./DragAndDropUploaderPanel";
import { TitleWrapper } from "./TitleWrapper";
import UploadButtons from "./UploadButtons";
import { uploadFiles } from "./uploadFiles";
import { UploadInProgressLabel } from "./UploadInProgressLabel";
import { UploadMetadataType } from "./UploadMetadataType";
import { useS3BucketConfigForm } from "./useS3BucketConfigForm";
import { useUploadMetadataForm } from "./useUploadMetadataForm";
import { useUploadProjectFileForm } from "./useUploadProjectFileForm";

const MessageWrapper = styled.div`
  align-items: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const SubtitleWrapper = styled(TitleWrapper)`
  font-weight: 400;
`;

interface DragDropFileUploadProps {
  type?: typeof UPLOAD_DICOM;
}

export const DragDropFileUpload: FC<DragDropFileUploadProps> = ({ type }) => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const inputFolderRef = useRef<HTMLInputElement>(null);

  const [dragged, setDragged] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const [cancellationSource, setCancellationSource] =
    useState<CancelTokenSource | undefined>(undefined);

  const [files, setFiles] = useState<FileList | null>(null);

  const getToken = useGetAuthToken();

  const executeUpload = async (metadata: UploadMetadataType) => {
    if (!files || files.length === 0) {
      throw new Error("File list is null/empty in handleConfirmUpload");
    }
    setIsUploading(true);
    setShowUploadMetadataForm(false);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    setCancellationSource(source);

    await uploadFiles(
      files,
      metadata,
      handleUploadProgressChanged,
      handleUploadCancelled,
      handleUploadError,
      source,
      getToken
    );

    clearUploadState();
  };

  const handleUploadProgressChanged = (progressEvent: ProgressEvent) => {
    const { loaded, total } = progressEvent;
    const progress = loaded / total;
    console.debug(`Upload progress: ${progress}`);
    setUploadProgress(progress);
  };

  const handleUploadDialogClosed = () => {
    if (!isUploading) {
      clearUploadState();
    }
  };

  const showUploadDialog = (files: FileList | null) => {
    if (!files || files.length === 0) {
      console.warn("No files selected for upload");
      clearUploadState();
      return;
    }

    setFiles(files);
    setShowUploadMetadataForm(true);
  };

  const handleFilesSelected = (event: React.FormEvent<HTMLInputElement>) => {
    const { files } = event.target as HTMLInputElement;
    showUploadDialog(files);
  };

  const handleClickCloudUpload = () => {
    setShowS3BucketConfigForm(true);
  };

  const handleClickProjectFileUpload = () => {
    showUploadProjectFileDialog(true);
  };

  const handleCancelUploadClicked = () => {
    if (!cancellationSource) {
      return;
    }

    cancellationSource.cancel("Operation canceled by the user.");
  };

  const handleUploadCancelled = (message: string) => {
    console.warn("Request canceled", message);
    clearUploadState();
  };

  const handleUploadError = (e: Error) => {
    console.error(`An error occurred: ${e.message}`, e);
    clearUploadState();
  };

  const clearUploadState = () => {
    setIsUploading(false);
    setCancellationSource(undefined);
    setUploadProgress(0);
    setFiles(null);

    if (inputFileRef.current) {
      inputFileRef.current.value = "";
    }
    if (inputFolderRef.current) {
      inputFolderRef.current.value = "";
    }
  };

  const [setShowUploadMetadataForm, { dialog: uploadMetadataForm }] = useUploadMetadataForm({
    onConfirm: executeUpload,
    onCancelled: handleUploadDialogClosed,
    files,
  });

  const [setShowS3BucketConfigForm, { dialog: s3BucketConfigForm }] = useS3BucketConfigForm();

  const [showUploadProjectFileDialog, { dialog: uploadProjectFileDialog }] =
    useUploadProjectFileForm();

  return (
    <>
      {uploadMetadataForm}
      {uploadProjectFileDialog}
      {s3BucketConfigForm}
      <DragAndDropUploaderPanel
        dragged={dragged}
        setDragged={setDragged}
        onFilesDropped={showUploadDialog}
      >
        <MessageWrapper>
          {dragged ? (
            <TitleWrapper>Upload</TitleWrapper>
          ) : isUploading ? (
            <UploadInProgressLabel
              progress={uploadProgress}
              state={SUCCESS}
              onCancel={handleCancelUploadClicked}
            />
          ) : (
            <>
              <TitleWrapper>
                {type === UPLOAD_DICOM
                  ? "Drag files here to upload DICOM images"
                  : "Drag files here"}
              </TitleWrapper>
              <SubtitleWrapper>or select an option below</SubtitleWrapper>
            </>
          )}
        </MessageWrapper>
        <UploadButtons
          disabled={isUploading}
          onFilesSelected={handleFilesSelected}
          onCloudUploadClicked={handleClickCloudUpload}
          onFileUploadClicked={handleClickProjectFileUpload}
          inputFileRef={inputFileRef}
          inputFolderRef={inputFolderRef}
          type={type}
        />
      </DragAndDropUploaderPanel>
    </>
  );
};
