import { ProjectArmType } from "../../common/types/ProjectArmType";
import { SubjectType } from "../../common/types/SubjectType";
import { getBaselineFollowUp } from "../../common/utils/getBaselineFollowUp";
import { getFollowUpDiametricTumourBurden } from "../../common/utils/getFollowUpDiametricTumourBurden";
import { getFollowUpInMonths } from "../../common/utils/getFollowUpInMonths";
import { getFollowUpMortalityRisk } from "../../common/utils/getFollowUpMortalityRisk";
import { getFollowUpSurvivalPrediction } from "../../common/utils/getFollowUpSurvivalPrediction";
import { getFollowUpVolumetricTumourBurden } from "../../common/utils/getFollowUpVolumetricTumourBurden";
import { getMostRecentFollowUp } from "../../common/utils/getMostRecentFollowUp";
import { AnalysisGraphData } from "../../MedianSurvival/utils/getMedianSurvival";
import { IPRO, MORTALITY, RECIST } from "../components/Filter";

export type SubjectMortalityVsBaselineType = {
  id: number;
  subjectId: string;
  projectArm: ProjectArmType;
  data: AnalysisGraphData<number>;
};

export const parseMortalityBaselineChartData = (
  subjects: SubjectType[]
): SubjectMortalityVsBaselineType[] => {
  const subjectsData: SubjectMortalityVsBaselineType[] = [];

  for (const subject of subjects) {
    const { id, subjectId, projectArm, followUps } = subject;

    if (followUps.length < 2) {
      continue;
    }

    const baselineFollowUp = getBaselineFollowUp(followUps);
    const mostRecentFollowUp = getMostRecentFollowUp(followUps);

    if (baselineFollowUp === mostRecentFollowUp) {
      throw new Error("Error in change calculation. Baseline = Most Recent Follow Up");
    }

    if (!baselineFollowUp || !mostRecentFollowUp) {
      continue;
    }

    const baselineSurvival = getFollowUpSurvivalPrediction(baselineFollowUp);
    const baselineMortality = getFollowUpMortalityRisk(baselineFollowUp);
    const baselineDiametricBurden = getFollowUpDiametricTumourBurden(baselineFollowUp);
    const baselineVolumetricBurden = getFollowUpVolumetricTumourBurden(baselineFollowUp);

    const recentSurvival = getFollowUpSurvivalPrediction(mostRecentFollowUp);
    const recentMortality = getFollowUpMortalityRisk(mostRecentFollowUp);
    const recentDiametricBurden = getFollowUpDiametricTumourBurden(mostRecentFollowUp);
    const recentVolumetricBurden = getFollowUpVolumetricTumourBurden(mostRecentFollowUp);

    if (
      baselineSurvival === null ||
      baselineMortality === null ||
      baselineDiametricBurden === null ||
      baselineVolumetricBurden === null ||
      recentSurvival === null ||
      recentMortality === null ||
      recentDiametricBurden === null ||
      recentVolumetricBurden === null
    ) {
      continue;
    }

    const { order: mostRecentFollowUpOrder } = mostRecentFollowUp;
    const mostRecentFollowUpTime = getFollowUpInMonths(mostRecentFollowUpOrder);

    const survivalChange = getChangePercent(
      recentSurvival + mostRecentFollowUpTime,
      baselineSurvival
    );

    const diametricBurdenChange = getChangePercent(recentDiametricBurden, baselineDiametricBurden);
    const volumetricBurdenChange = getChangePercent(
      recentVolumetricBurden,
      baselineVolumetricBurden
    );

    const mortalityChange = getChangePercent(recentMortality, baselineMortality);

    subjectsData.push({
      id,
      subjectId,
      projectArm,
      data: {
        [RECIST]: {
          DIAMETRIC: diametricBurdenChange,
          VOLUMETRIC: volumetricBurdenChange,
        },
        [IPRO]: survivalChange,
        [MORTALITY]: mortalityChange,
      },
    });
  }

  return subjectsData;
};

function getChangePercent(recent: number, baseline: number) {
  return 100 * ((recent - baseline) / Math.abs(baseline));
}
