import React, { ReactElement } from "react";
import styled from "styled-components";

import { useCurrentUser } from "../../common/contexts/UserContext/useCurrentUser";

const Wrapper = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 44px;
  color: ${(props) => props.theme.colors.actionPrimary.inverted};
`;

const HelloText = (): ReactElement => {
  const { firstName } = useCurrentUser();

  const displayName = firstName ? `, ${firstName}` : "";
  const text = `Hello${displayName}`;
  return <Wrapper>{text}</Wrapper>;
};

export default HelloText;
