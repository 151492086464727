import React, { forwardRef, useCallback, useMemo, useRef } from "react";

import {
  patientTableHeightActions,
  seriesSubTableHeights,
} from "../../store/patientTableHeightsSlice";
import { seriesSelectionActions } from "../../store/seriesSelectionSlice";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { SELECT_HEADER_ID } from "../Table/getCheckboxColumn";
import { Table, TableProps } from "../Table/Table";
import { TableControlsType } from "../Table/TableControlsType";
import { getColumns, SeriesTableColumnType } from "./getColumns";
import { getSeriesTableRowId, SeriesTableRowType } from "./SeriesTableRowType";

interface SeriesTableProps {
  tableKey: string;
  studyId: number;
  series: SeriesTableRowType[];
  hiddenColumns?: SeriesTableColumnType[];
  enableSelect?: boolean;
  loading: boolean;
  maxHeight?: number;
}

export function SeriesTable({
  tableKey,
  studyId,
  series,
  hiddenColumns = [],
  enableSelect = false,
  loading,
  maxHeight,
}: SeriesTableProps): JSX.Element {
  const dispatch = useAppDispatch();

  const tableHeights = useAppSelector(seriesSubTableHeights);

  const tableRef = useRef<TableControlsType<SeriesTableRowType>>(null);

  const handleSelectSeries = (seriesId: number, selected: boolean) => {
    dispatch(seriesSelectionActions.setSeriesSelected({ tableKey, seriesId, selected }));
  };

  const handleHeightChanged = (height: number) => {
    dispatch(patientTableHeightActions.setSubSeriesTableHeight({ studyId, height }));
  };

  const columns = useMemo(() => {
    return getColumns(
      enableSelect ? hiddenColumns : [...hiddenColumns, SELECT_HEADER_ID],
      handleSelectSeries
    );
  }, []);

  const rows = useMemo(() => series, [series]);

  const getRowId = useCallback(({ id }) => getSeriesTableRowId(id), []);

  const initialHeight = tableHeights[studyId];

  return (
    <TableWithRef
      ref={tableRef}
      columns={columns}
      data={rows}
      /*
        we require a manually defined checkbox here due to the special way selection is handled for studies/series (i.e. using nested tables + redux). if we use the table's provided selection mechanism we end up with infinite loops since the redux implementation does not mutate state on selection causing the table to re-render and the selection callback to fire. attempting to fix this by early-outing only breaks the nested selection relationship with the parent
      */
      enableSelect={false}
      loading={loading}
      getRowId={getRowId}
      maxHeight={maxHeight}
      initialHeight={initialHeight}
      onHeightChanged={handleHeightChanged}
    />
  );
}

const TableWithRef = forwardRef<
  TableControlsType<SeriesTableRowType>,
  TableProps<SeriesTableRowType>
>((props, ref) => {
  return <Table {...props} forwardedRef={ref} />;
});
TableWithRef.displayName = "TableWithRef";
