import React from "react";

import { DetailProps } from "../../../common/components/Detail";
import DetailGrid from "../../../common/components/DetailGrid";
import { RTOGDetailDataType } from "../types/RTOGDetailDataType";
import { headers } from "../utils/parseRTOGDetailPanelContainerData";

function RTOGDetailPanel(allDetails: { details: RTOGDetailDataType }): React.ReactElement {
  const details = allDetails.details;
  const allDetailInfo: DetailProps[] = [];
  headers.forEach((header) => {
    const { title, key } = header;
    allDetailInfo.push({ title, value: details[key as keyof typeof details] });
  });

  return <DetailGrid details={allDetailInfo} numColumns={8} />;
}

export default RTOGDetailPanel;
