import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../../store";

const cornerstoneInfo = (state: RootState) => state.annotatePage.cornerstoneInfo;

export const imagesLoadingProgressSelector = createSelector(
  [cornerstoneInfo],
  (slice) => slice.imagesLoadingProgress
);

export const imagesLoadingFailedSeriesIdsSelector = createSelector(
  [cornerstoneInfo],
  (slice) => slice.failedSeriesIds
);
