import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { MutationTuple } from "@apollo/client/react/types/types";

import { USER_FRAGMENT, UserFragmentType } from "../../../Settings/UserFragment";

type Data = {
  user: UserFragmentType;
};

type Variables = {
  auth0UserId: string;
  suspend: boolean;
};

export const MUTATION = gql`
  mutation updateUserSuspension($auth0UserId: String!, $suspend: Boolean!) {
    update_user_suspension(auth0UserId: $auth0UserId, suspend: $suspend) {
      user {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

export function useChangeUserSuspension(): MutationTuple<Data, Variables> {
  return useMutation<Data, Variables>(MUTATION);
}
