import { QueryResult } from "@apollo/client/react/types/types";

import { AnatomicalStructuresFragmentType } from "../../../../AnatomicalStructuresFragment";
import { useAnatomicalStructures } from "../../../../useAnatomicalStructures";

type Variables = {
  structures: string[];
};

type Data = {
  anatomicalStructures: AnatomicalStructuresFragmentType[];
};

export function useAnatomicalStructuresByStructure(
  structures: string[]
): Pick<QueryResult<Data, Variables>, "data" | "loading" | "error"> {
  const { data: anatomicalStructures, loading, error } = useAnatomicalStructures();

  if (loading || error) {
    return { data: undefined, loading, error };
  }

  const matches = anatomicalStructures
    ? anatomicalStructures.filter(({ structure }) => structures.includes(structure))
    : undefined;

  const data = matches ? { anatomicalStructures: matches } : undefined;

  return { data, loading, error };
}
