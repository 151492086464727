// derived from https://stackoverflow.com/a/42234988

import React, { ElementType, RefObject, useEffect, useRef } from "react";
import styled from "styled-components";

/**
 * Hook that alerts clicks outside of the passed ref
 */
export function useOutsideAlerter(callback: () => void, ref: RefObject<any>): void {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

/**
 * Component that alerts if you click outside of it
 */

interface OutsideAlerterProps {
  children: JSX.Element;
  callback: () => void;
  Component?: ElementType;
}

const DefaultWrapper = styled.div``;

export function OutsideAlerter({
  children,
  callback,
  Component = DefaultWrapper,
}: OutsideAlerterProps): JSX.Element {
  const wrapperRef = useRef(null);
  useOutsideAlerter(callback, wrapperRef);

  return <Component ref={wrapperRef}>{children}</Component>;
}
