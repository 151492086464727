import { TaskAssignmentType } from "../../../../Manage/hooks/TaskType";

export function getTaskAssignment(
  taskAssignments: TaskAssignmentType[],
  userId: number
): TaskAssignmentType | undefined {
  const userTaskAssignment = taskAssignments.find(
    ({ user: { id: taskUserId } }) => taskUserId === userId
  );

  return userTaskAssignment ?? taskAssignments[0];
}
