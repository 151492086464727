import { UserType } from "../../../types/UserType";
import { useDialogReturnType } from "../../Dialog/useDialog";
import { useFormDialog } from "../../Dialog/useFormDialog";
import { EditUserForm } from "./EditUserForm";

export function useEditUserDialog(user: UserType): useDialogReturnType {
  return useFormDialog({
    children: EditUserForm,
    label: "Edit Team Member",
    showCloseButton: true,
    props: {
      user,
    },
  });
}
