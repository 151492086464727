import React, { FC, ReactElement, useEffect, useState } from "react";

import { BaseDialogProps } from "./BaseDialogProps";
import { ContentsWrapper, Wrapper } from "./DialogContents";
import { DialogHeader, DialogHeaderProps } from "./DialogHeader";
import { DialogWrapper, ReactModalOverrides } from "./DialogWrapper";
import { FormProps } from "./FormProps";

interface Props<T> extends FormDialogProps<T>, BaseDialogProps {}

export interface FormDialogProps<T> extends Omit<DialogHeaderProps, "onClose"> {
  children: FC<FormProps<T>>;
  props: T;
  reactModalProps?: ReactModalOverrides;
}

export function FormDialog<T>({
  children: Form,
  props,
  open,
  setOpen,
  label,
  showCloseButton,
  reactModalProps,
}: Props<T>): ReactElement {
  const [title, setTitle] = useState<string>(label);

  const handleSubmit = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSetTitle = (title: string) => {
    setTitle?.(title);
  };

  useEffect(() => {
    setTitle(label);
  }, [label]);

  return (
    <DialogWrapper setOpen={setOpen} open={open} reactModalProps={reactModalProps}>
      <Wrapper>
        <DialogHeader label={title} showCloseButton={showCloseButton} onClose={handleCancel} />

        <ContentsWrapper>
          <Form
            props={props}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            onSetTitle={handleSetTitle}
          />
        </ContentsWrapper>
      </Wrapper>
    </DialogWrapper>
  );
}
