import {
  COMPLETE_RESPONSE,
  PARTIAL_RESPONSE,
  PROGRESSIVE_DISEASE,
  STABLE_DISEASE,
} from "../../../Analysis/common/types/TumourResponseType";
import { Recist1_1Filters } from "../../types/ProjectViewFilterTypes";
import { RootState } from "../store";
import { ProjectFilterSelectors } from "./ProjectFilterSelectors";

export const defaultResponseState = {
  [PROGRESSIVE_DISEASE]: true,
  [STABLE_DISEASE]: true,
  [PARTIAL_RESPONSE]: true,
  [COMPLETE_RESPONSE]: true,
};

export const clearResponseState = {
  [PROGRESSIVE_DISEASE]: false,
  [STABLE_DISEASE]: false,
  [PARTIAL_RESPONSE]: false,
  [COMPLETE_RESPONSE]: false,
};

export const tumourBurdenFilters = new ProjectFilterSelectors<Recist1_1Filters>(
  defaultResponseState,
  clearResponseState,
  (state: RootState) => state.projectViewFilters.recist1_1
);
