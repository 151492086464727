import React from "react";
import Select, { ValueType } from "react-select";
import { NamedProps } from "react-select/src/Select";

import { getSelectStyle } from "../../../common/components/input/getSelectStyle";

export type OptionType<T> = {
  value: T;
  label: string | JSX.Element;
};

interface GenericMultiSelectProps<T>
  extends Omit<NamedProps<OptionType<T>, true>, "isMulti" | "value" | "options" | "onChange"> {
  values: T[];
  selected: T[];
  onSelectedChanged: (values: T[]) => void;
  getOption: (value: T) => OptionType<T>;
  inputRef?: React.Ref<unknown>;
  preventBaseStyles?: boolean;
}

export function GenericMultiSelect<T>({
  values,
  selected,
  onSelectedChanged,
  getOption,
  inputRef,
  preventBaseStyles = false,
  styles: stylesOverride,
  ...other
}: GenericMultiSelectProps<T>): JSX.Element {
  const options = values.map(getOption);
  const value = selected.map(getOption);

  const styles = preventBaseStyles
    ? stylesOverride
    : getSelectStyle<OptionType<T>, true>(stylesOverride);

  const handleSelectDatasets = (values: ValueType<OptionType<T>, true>) => {
    const selected = values.map(({ value }) => value);
    onSelectedChanged(selected);
  };

  return (
    <Select
      styles={styles}
      {...other}
      isMulti
      value={value}
      options={options}
      onChange={handleSelectDatasets}
      inputRef={inputRef}
    />
  );
}
