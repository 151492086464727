import React, { useContext } from "react";

import { FlexLoading } from "../../../../../../common/components/Loading";
import { PatientContext } from "../../../../../../Dashboard/components/Settings/Patients/Viewer/PatientContext";
import { usePatientBiopsyDetails } from "../hooks/usePatientBiopsyDetails";
import { ASSIGN, CHANGE, UNASSIGN } from "./BiopsyAssignmentActionType";
import { BiopsyIcon } from "./BiopsyIcon";
import { useUpdateBiopsyLesionAssignment } from "./hooks/useUpdateBiopsyLesionAssignment";
import { useBiopsyAssignmentForm } from "./useBiopsyAssignmentForm";

interface BiopsyAssignmentButtonProps {
  lesionId: number;
  readOnly?: boolean;
}

export function BiopsyAssignmentButton({
  lesionId,
  readOnly = false,
}: BiopsyAssignmentButtonProps): JSX.Element {
  const { id: patientId } = useContext(PatientContext);

  const { data: biopsies, loading } = usePatientBiopsyDetails(patientId);

  const [updateBiopsyLesionAssignment] = useUpdateBiopsyLesionAssignment();

  const biopsy = biopsies?.find(({ lesionId: biopsyLesionId }) => biopsyLesionId === lesionId);
  const hasUnsureBiopsy = biopsy?.unsure;

  const handleAssignBiopsy = async (unsure: boolean) => {
    const biopsy = biopsies?.[0];
    if (!biopsy) {
      throw new Error("No biopsy present to assign to");
    }

    await updateBiopsyLesionAssignment({
      variables: {
        lesionId,
        unsure,
        id: biopsy.id,
      },
    });
  };

  const handleUnassignBiopsy = async () => {
    const biopsy = biopsies?.[0];
    if (!biopsy) {
      throw new Error("No biopsy present to assign to");
    }
    await updateBiopsyLesionAssignment({
      variables: {
        lesionId: null,
        unsure: true,
        id: biopsy.id,
      },
    });
  };

  const [showAssignBiopsyDialog, { dialog: assignBiopsyDialog }] = useBiopsyAssignmentForm({
    action: ASSIGN,
    onConfirm: handleAssignBiopsy,
  });
  const [showUnassignBiopsyDialog, { dialog: unassignBiopsyDialog }] = useBiopsyAssignmentForm({
    action: UNASSIGN,
    onConfirm: handleUnassignBiopsy,
  });
  const [showChangeBiopsyDialog, { dialog: changeBiopsyDialog }] = useBiopsyAssignmentForm({
    action: CHANGE,
    onConfirm: handleAssignBiopsy,
  });

  const handleDetectClicked = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();

    if (!biopsies) {
      throw new Error("Biopsy information is not available");
    }

    const biopsyAssignedToOtherLesion = biopsies.some(
      ({ lesionId: biopsyLesionId }) => biopsyLesionId !== null && biopsyLesionId !== lesionId
    );

    if (hasUnsureBiopsy !== undefined) {
      showUnassignBiopsyDialog(true);
      return;
    }

    if (biopsyAssignedToOtherLesion) {
      showChangeBiopsyDialog(true);
      return;
    }

    showAssignBiopsyDialog(true);
  };

  const noClick = () => void 0;

  if (biopsies && biopsies.length === 0) {
    return <></>;
  }

  if (loading) {
    return <FlexLoading />;
  }

  const isLinked = hasUnsureBiopsy !== undefined;
  const isUnsure = !!hasUnsureBiopsy;

  return (
    <>
      {assignBiopsyDialog}
      {unassignBiopsyDialog}
      {changeBiopsyDialog}
      <BiopsyIcon
        isLinked={isLinked}
        isUnsure={isUnsure}
        onClick={!readOnly ? handleDetectClicked : noClick}
        readOnly={readOnly}
      />
    </>
  );
}
