import React, { useContext } from "react";
import { Column } from "react-table";

import ToggleSwitch from "../../../../common/components/ToggleSwitch";
import {
  AnonymizationConfigurationContext,
  AnonymizationMethodType,
} from "./Contexts/AnonymizationConfigurationContext";
import { AnonymizationTableRowType } from "./hooks/useAllAnonymizationMethods";
import { useAnonymizationMethodConfigDialog } from "./useAnonymizationMethodConfigDialog";

export function useAnonymizationTableColumns(): Column<AnonymizationTableRowType>[] {
  const { config, setConfig } = useContext(AnonymizationConfigurationContext);

  const columns: Column<AnonymizationTableRowType>[] = [
    {
      id: "name",
      Header: "Name",
      disableFilters: false,
      accessor: ({ name }) => name,
      // eslint-disable-next-line react/display-name
      Cell: ({ value: name }: { value: string }) => {
        return <>{name}</>;
      },
    },
    {
      id: "description",
      Header: "Description",
      disableFilters: false,
      accessor: ({ description }) => description,
      // eslint-disable-next-line react/display-name
      Cell: ({ value: description }: { value: string }) => {
        return <>{description}</>;
      },
    },
    {
      id: "toggle",
      Header: "Apply",
      disableFilters: true,
      accessor: (row) => row,
      // eslint-disable-next-line react/display-name
      Cell: ({ value: { key } }: { value: AnonymizationTableRowType }) => {
        const configType = key as AnonymizationMethodType;
        const [showDialog, { dialog }] = useAnonymizationMethodConfigDialog(configType);
        const isEnabled = !!config[configType];

        const handleChange = () => {
          if (!isEnabled) {
            showDialog(true);
            return;
          }
          setConfig((prevConfig) => ({
            ...prevConfig,
            [configType]: undefined,
          }));
        };
        return (
          <>
            {dialog}
            <ToggleSwitch size="small" checked={isEnabled} onChange={handleChange} />
          </>
        );
      },

      style: {
        width: "60px",
      },
    },
  ];

  return columns;
}
