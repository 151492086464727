import styled from "styled-components";

export const Input = styled.input<{ error?: unknown }>`
  box-sizing: border-box;
  width: 100%;
  padding: 12px;
  font-size: 12px;
  margin: 8px 0 0 0;
  border-radius: 8px;
  border: 1px solid
    ${(props) =>
      props.error ? props.theme.colors.states.error : props.theme.colors.neutral.neutral5};
  outline: none;
  &:focus-within {
    border: 1px solid
      ${(props) =>
        props.error ? props.theme.colors.states.error : props.theme.colors.neutral.black};
  }
`;
