import React from "react";

import { InputButton } from "../../../../../../../../../common/components/input/InputButton";
import { useTrySelectLesionId } from "../../../../hooks/useTrySelectLesionId";
import { useSetLesionToScrollTo } from "../../LesionToScrollTo/useSetLesionToScrollTo";
import { LesionTaskCheckResultComponentProps } from "../../types/SaveCheckResultComponentProps";
import { useJumpToSeries } from "../../utils/useJumpToSeries";
import { MissingRoisCheckResultType } from "./useMissingRoisCheck";

export function MissingRoisCheckActions({
  result,
  onRequestClose,
}: LesionTaskCheckResultComponentProps<MissingRoisCheckResultType>): JSX.Element {
  const trySelectLesionId = useTrySelectLesionId();
  const setLesionToScrollTo = useSetLesionToScrollTo();

  const jumpToSeries = useJumpToSeries();

  const handleGoToObject = () => {
    if (!result) {
      throw new Error("Missing rois result is empty when go to object was clicked");
    }

    const { lesionId, seriesIds } = result;

    if (seriesIds.length > 0) {
      jumpToSeries(seriesIds[0]);
    }

    trySelectLesionId(lesionId);
    setLesionToScrollTo(lesionId);

    onRequestClose();
  };

  if (!result) {
    return <></>;
  }

  return (
    <InputButton type={"button"} onClick={handleGoToObject} value={"Go to label"} width={100} />
  );
}
