import { ApolloCache } from "@apollo/client/core";

import {
  LesionListItemFragmentType,
  LesionsListQueryInput,
} from "../fragments/LesionListItemFragment";
import { getLesionsListFromCache } from "./getLesionsListFromCache";

export function getLesionFromCache<T>(
  lesionListQueryInput: LesionsListQueryInput,
  lesionId: number,
  cache: ApolloCache<T>
): LesionListItemFragmentType | undefined {
  const lesions = getLesionsListFromCache(lesionListQueryInput, cache);
  return lesions.find(({ id }) => id === lesionId);
}
