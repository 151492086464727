import React, { ReactNode, useCallback, useMemo, useState } from "react";
import { useDeepCompareMemo } from "use-deep-compare";

import { ViewerConfigType } from "../../../../../../common/store/annotatePage/ViewerConfigType";
import { ROI, ToolType } from "../../../../../../cornerstone/ToolType";
import { ViewerContext, ViewerContextType } from "./ViewerContext";

type ViewerContextProviderOptions = Pick<ViewerContextType, "viewOnly">;

const DEFAULT_OPTIONS: ViewerContextProviderOptions = {
  viewOnly: false,
};

interface ViewerContextProviderProps {
  options?: ViewerContextProviderOptions;
  children: ReactNode;
}

export function ViewerContextProvider({
  options = DEFAULT_OPTIONS,
  children,
}: ViewerContextProviderProps): JSX.Element {
  const { viewOnly: defaultViewOnly } = useDeepCompareMemo(() => options, [options]);

  const [viewerConfigs, setViewerConfigs] = useState<ViewerConfigType[]>([]);
  const memoizedViewerConfigs = useDeepCompareMemo(() => viewerConfigs, [viewerConfigs]);
  const [activeTool, setActiveTool] = useState<ToolType>(ROI);
  const [lastActiveTool, setLastActiveTool] = useState<ToolType | null>(null);
  const [viewerImageIds, setViewerImageIds] = useState<Record<number, string | null>>({});
  const memoViewerImageIds = useDeepCompareMemo(() => viewerImageIds, [viewerImageIds]);
  const [viewOnly, setViewOnly] = useState<boolean>(defaultViewOnly);

  const setViewerImageId = useCallback((viewerConfigId: number, imageId: string | null) => {
    setViewerImageIds((viewerImageIds) => {
      return { ...viewerImageIds, [viewerConfigId]: imageId };
    });
  }, []);

  const clearViewerImageId = useCallback((viewerConfigId: number) => {
    setViewerImageIds((viewerImageIds) => {
      const newViewerImages = { ...viewerImageIds };
      delete newViewerImages[viewerConfigId];
      return newViewerImages;
    });
  }, []);

  const context = useMemo(
    () => ({
      viewerConfigs: memoizedViewerConfigs,
      setViewerConfigs,
      activeTool,
      setActiveTool,
      lastActiveTool,
      setLastActiveTool,
      viewerImageIds: memoViewerImageIds,
      setViewerImageId,
      clearViewerImageId,
      viewOnly,
      setViewOnly,
    }),
    [
      memoizedViewerConfigs,
      setViewerConfigs,
      activeTool,
      setActiveTool,
      lastActiveTool,
      setLastActiveTool,
      memoViewerImageIds,
      setViewerImageId,
      clearViewerImageId,
      viewOnly,
      setViewOnly,
    ]
  );

  return <ViewerContext.Provider value={context}>{children}</ViewerContext.Provider>;
}
