import * as Sentry from "@sentry/react";

export const initSentry = () => {
  if (window._env_.REACT_APP_ENABLE_TELEMETRY !== "true") {
    return;
  }

  Sentry.init({
    dsn: window._env_.REACT_APP_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
    debug: false,
    environment: window._env_.REACT_APP_ENVIRONMENT,
    release: window._env_.RELEASE,
  });
};
