import { ApolloCache, ApolloClient, useApolloClient } from "@apollo/client";

import { useProjectId } from "../../../../../../Project/hooks/useProjectId";
import { getSeriesCacheId } from "./getSeriesCacheId";
import { SERIES_LIST_ITEM_FRAGMENT, SeriesListItemFragmentType } from "./useStudiesList";

export function getSeriesFromCache<T>(
  seriesId: number,
  projectId: number,
  cache: ApolloCache<T>
): SeriesListItemFragmentType | null {
  return cache.readFragment<SeriesListItemFragmentType>({
    fragment: SERIES_LIST_ITEM_FRAGMENT,
    id: getSeriesCacheId(seriesId, cache),
    fragmentName: "SeriesListItem",
    variables: {
      projectIds: [projectId],
    },
  });
}

interface GetSeriesFromCacheProps<T> {
  seriesId: number;
  projectId?: number;
  cache?: ApolloCache<T>;
}

export const useSeriesFromCache = <T>(props: GetSeriesFromCacheProps<T>) => {
  const contextProjectId = useProjectId();
  const { cache } = useApolloClient() as ApolloClient<T>;
  return getSeriesFromCache<T>(
    props.seriesId,
    props.projectId || contextProjectId,
    props?.cache || cache
  );
};
