import { RoutePathSingleOrArray } from "../Layout";
import { getRouteString } from "./getRouteString";

export function getProjectRoute(
  root: RoutePathSingleOrArray,
  route?: RoutePathSingleOrArray
): string {
  const paths = [root, route].filter((path) => !!path);
  return `/${getRouteString(...paths)}`;
}
