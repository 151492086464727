import { SubjectType } from "../../../Analysis/common/types/SubjectType";
import { followUpIds } from "../../../Analysis/common/utils/followUpIds";
import { formatFollowUp } from "../../../Analysis/common/utils/formatFollowUp";
import { EnrolmentDataProps } from "../components/EnrolmentChart";

export function parseEnrollmentData(subjects: SubjectType[]): EnrolmentDataProps[] {
  const total = subjects.length;
  return followUpIds.map((followUpId) => {
    const followUpCount = subjects.filter(({ followUps }) => {
      return followUps.some(({ order, studies }) => order === followUpId && studies.length > 0);
    }).length;

    return {
      name: formatFollowUp(followUpId),
      success: followUpCount,
      total,
      percentage: (100 * followUpCount) / total,
    };
  });
}
