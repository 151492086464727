import { ApolloCache, gql } from "@apollo/client";

import { getSeriesCacheId } from "../../AnnotationPanel/hooks/getSeriesCacheId";

export function getInstanceIdFromImageIdFromCache<T>(
  seriesId: number,
  imageId: string,
  cache: ApolloCache<T>
): number | null {
  const { instances } = cache.readQuery<{
    instances: { id: number; wadoUrl: string }[];
  }>({
    query: gql`
      query GetContourFromCache {
        instances {
          id
          wadoUrl
        }
      }
    `,
    id: getSeriesCacheId(seriesId, cache),
  }) ?? { instances: [] };

  const { id } = instances.find(({ wadoUrl }) => wadoUrl === imageId) ?? {
    id: null,
  };
  return id;
}
