import { Payload } from "recharts/types/component/DefaultTooltipContent";

import { GraphView, RECIST } from "../../ChangeFromBaseline/components/Filter";
import { getDataTypeFromName } from "./getDataTypeFromName";
import { MedianDomain } from "./getMedianSurvival";

export const tooltipItemSorter = (
  { value, name }: Payload<number, string>,
  domains: MedianDomain,
  graphView: GraphView
) => {
  if (!value || !name) {
    return 0;
  }

  const dataType = getDataTypeFromName(name, graphView);

  if (!dataType) {
    throw new Error(`Could not find data type for ${name}`);
  }

  const [min, max] = dataType === RECIST ? domains[dataType][graphView] : domains[dataType];
  return (value - min) / (max - min);
};
