import {
  MORTALITY,
  RECIST,
  RECIST_AND_MORTALITY,
} from "../../../Analysis/ChangeFromBaseline/components/Filter";
import {
  ADMIN,
  PROJECT_ROLE_COORDINATOR,
  PROJECT_ROLE_VIEWER,
} from "../../../common/types/UserRoleType";
import { annotatePageRoute, tasklessPageRoute } from "../AnnotatePage/route";
import {
  ALGORITHM_INFO_CONTENT,
  ANALYSIS_ALGORITHM_SUMMARY_CONTENT,
  ANALYSIS_ICON,
  ANNOTATE_MANAGE_PAGE_CONTENT,
  ANNOTATE_PAGE_CONTENT,
  ANNOTATIONS_PER_ANNOTATOR_CONTENT,
  ANNOTATIONS_PER_COHORT_CONTENT,
  CHANGE_IN_MORTALITY_CONTENT,
  CREATE_PROJECT,
  DATA_MANAGEMENT_ICON,
  DOCUMENTS_ICON,
  DOWNLOAD_REPORT,
  ENROLMENT_CONTENT,
  EXPORT_PROJECT,
  HOME_ICON,
  IMAGING_DEVIATIONS_CONTENT,
  LayoutType,
  LESION_STATISTICS_CONTENT,
  MEDIAN_SURVIVAL_CONTENT,
  OVERVIEW_ICON,
  PATIENT_DETAILS_CONTENT,
  PATIENT_JOURNEY_DRAWER,
  PATIENT_LONGITUDINAL_CONTENT,
  PATIENT_TASKS_CONTENT,
  PROJECT_COHORT_MANAGEMENT_CONTENT,
  PROJECT_DETAILS_CONTENT,
  PROJECT_DOCUMENT_CONTENT,
  PROJECT_PATIENT_MANAGEMENT_CONTENT,
  PROJECT_SELECTION_CONTENT,
  RADIOLOGY_ICON,
  SCHEMA_ICON,
  SETTINGS_ICON,
  SITE_MANAGEMENT_CONTENT,
  STUDY_TABLE_CONTENT,
  TASK_PER_ANNOTATOR_CONTENT,
  TASKLESS_PAGE_CONTENT,
  UPLOAD_CONTENT,
  UPLOAD_DICOM,
  UPLOAD_DOCUMENTS,
  UPLOAD_ICON,
  USER_ICON,
  USER_MANAGEMENT_CONTENT,
  USERS_ICON,
  WORKLIST_CONTENT,
  WORKLIST_ICON,
} from "../Layout";
import {
  ANALYSIS,
  ANALYSIS_ALGORITHM,
  ANALYSIS_MORTALITY_DETAILS,
  ANALYSIS_NEW,
  ANALYSIS_SUBJECT_JOURNEY,
  ANALYSIS_SURVIVAL_DETAILS,
  ANNOTATE,
  ANNOTATE_MANAGE,
  ANNOTATE_WORKLIST,
  COHORTS,
  DATA_MANAGEMENT,
  DATA_MANAGEMENT_NEW,
  DATA_MANAGEMENT_UPLOAD,
  DOCUMENTS,
  OVERVIEW,
  SUBJECT,
  PATIENT_SLUG,
  SUBJECTS,
  PROJECT,
  PROJECT_SLUG,
  SETTINGS,
  SITES,
  TEAM_MEMBERS,
} from "../RoutePathType";
import { settingsLayout } from "./settingsLayout";

export const projectLayout: LayoutType = {
  dashboard: {
    content: [{ component: PROJECT_SELECTION_CONTENT }],
    buttons: [
      {
        label: "Create Project",
        action: CREATE_PROJECT,
        allowedRoles: [],
      },
    ],
    showKaplanMeierPreview: false,
  },
  settings: settingsLayout,
  project: {
    menu: [
      {
        label: "Overview",
        route: OVERVIEW,
        icon: OVERVIEW_ICON,
      },
      {
        label: "Data",
        route: DATA_MANAGEMENT,
        icon: DATA_MANAGEMENT_ICON,
        subItems: [
          {
            label: "Overview",
            route: OVERVIEW,
          },
          {
            label: "Upload",
            route: DATA_MANAGEMENT_UPLOAD,
          },
        ],
      },
      {
        label: "Data",
        route: DATA_MANAGEMENT_NEW,
        icon: DATA_MANAGEMENT_ICON,
      },
      {
        label: "Analysis",
        route: ANALYSIS,
        icon: ANALYSIS_ICON,
        subItems: [
          {
            label: "Overview",
            route: OVERVIEW,
          },
          {
            label: "Algorithm",
            route: ANALYSIS_ALGORITHM,
          },
          {
            label: "Median",
            route: ANALYSIS_SURVIVAL_DETAILS,
          },
          {
            label: "Change from Baseline",
            route: ANALYSIS_MORTALITY_DETAILS,
          },
        ],
      },
      {
        label: "Analysis",
        route: ANALYSIS_NEW,
        icon: ANALYSIS_ICON,
      },
      {
        label: "Annotate",
        route: ANNOTATE,
        icon: RADIOLOGY_ICON,
        subItems: [
          {
            label: "Overview",
            route: OVERVIEW,
          },
          {
            label: "Worklist",
            route: ANNOTATE_WORKLIST,
          },
          {
            label: "Task Manager",
            route: ANNOTATE_MANAGE,
          },
        ],
      },
      {
        label: "Documents",
        route: DOCUMENTS,
        icon: DOCUMENTS_ICON,
      },
      {
        label: "Settings",
        route: SETTINGS,
        icon: SETTINGS_ICON,
        subItems: [
          {
            label: "Sites",
            route: SITES,
          },
          {
            label: "Team Members",
            route: TEAM_MEMBERS,
          },
          {
            label: "Cohorts",
            route: COHORTS,
          },
          {
            label: "Subjects",
            route: SUBJECTS,
          },
        ],
      },
    ],
    pages: [
      {
        route: OVERVIEW,
        defaultPage: true,
        content: [{ component: PROJECT_DETAILS_CONTENT }, { component: ENROLMENT_CONTENT }],
        topBar: {
          icon: OVERVIEW_ICON,
          label: "Overview",
          buttons: [
            {
              label: "Export Report",
              action: DOWNLOAD_REPORT,
              allowedRoles: [PROJECT_ROLE_COORDINATOR],
            },
            {
              label: "Export Annotations",
              action: EXPORT_PROJECT,
              allowedRoles: [],
            },
          ],
        },
      },
      {
        route: OVERVIEW,
        parentRoute: DATA_MANAGEMENT,
        defaultPage: true,
        content: [
          { component: PROJECT_DETAILS_CONTENT },
          { component: PROJECT_DOCUMENT_CONTENT },
          { component: ENROLMENT_CONTENT },
          { component: IMAGING_DEVIATIONS_CONTENT },
          {
            component: STUDY_TABLE_CONTENT,
            props: {
              hiddenColumns: [],
              patientRoute: [DATA_MANAGEMENT, SUBJECT, PATIENT_SLUG, ANALYSIS_SUBJECT_JOURNEY],
            },
          },
        ],
        topBar: {
          icon: DATA_MANAGEMENT_ICON,
          label: "Data - Overview",
          buttons: [
            {
              label: "Upload",
              icon: UPLOAD_ICON,
              route: [DATA_MANAGEMENT, DATA_MANAGEMENT_UPLOAD],
              allowedRoles: [],
            },
            {
              label: "Export Report",
              action: DOWNLOAD_REPORT,
              allowedRoles: [PROJECT_ROLE_COORDINATOR],
            },
            {
              label: "Export Annotations",
              action: EXPORT_PROJECT,
              allowedRoles: [],
            },
          ],
        },
      },
      {
        route: DATA_MANAGEMENT_UPLOAD,
        parentRoute: DATA_MANAGEMENT,
        content: [
          { component: UPLOAD_CONTENT, allowedRoles: [PROJECT_ROLE_COORDINATOR] },
          { component: PROJECT_DOCUMENT_CONTENT },
          {
            component: STUDY_TABLE_CONTENT,
            props: {
              hiddenColumns: [],
              patientRoute: [DATA_MANAGEMENT, SUBJECT, PATIENT_SLUG, ANALYSIS_SUBJECT_JOURNEY],
            },
          },
        ],
        topBar: {
          icon: DATA_MANAGEMENT_ICON,
          label: "Data - Upload",
        },
      },
      {
        route: DATA_MANAGEMENT_NEW,
        defaultPage: true,
        content: [
          {
            component: STUDY_TABLE_CONTENT,
            props: {
              hiddenColumns: [],
              patientRoute: [SUBJECT, PATIENT_SLUG, ANALYSIS_SUBJECT_JOURNEY],
            },
          },
          {
            component: PATIENT_JOURNEY_DRAWER,
            props: {
              patientRoute: [SUBJECT, PATIENT_SLUG, ANALYSIS_SUBJECT_JOURNEY],
            },
          },
        ],
        topBar: {
          icon: DATA_MANAGEMENT_ICON,
          label: "Data",
          buttons: [
            {
              label: "Upload",
              action: UPLOAD_DICOM,
              allowedRoles: [],
            },
            {
              label: "Export Report",
              action: DOWNLOAD_REPORT,
              allowedRoles: [PROJECT_ROLE_COORDINATOR],
            },
            {
              label: "Export Annotations",
              action: EXPORT_PROJECT,
              allowedRoles: [],
            },
          ],
        },
      },
      {
        route: [SUBJECT, PATIENT_SLUG, ANALYSIS_SUBJECT_JOURNEY],
        parentRoute: DATA_MANAGEMENT,
        allowedRoles: [PROJECT_ROLE_VIEWER],
        content: [
          { component: PATIENT_DETAILS_CONTENT },
          {
            component: PATIENT_LONGITUDINAL_CONTENT,
            props: {
              allowedDataTypes: [RECIST, MORTALITY, RECIST_AND_MORTALITY],
            },
          },
          {
            component: PATIENT_TASKS_CONTENT,
            allowedRoles: [PROJECT_ROLE_VIEWER],
          },
        ],
        topBar: {
          showBackButton: true,
        },
      },
      {
        route: [PATIENT_SLUG, ANALYSIS_SUBJECT_JOURNEY],
        parentRoute: SUBJECT,
        allowedRoles: [PROJECT_ROLE_VIEWER],
        content: [
          { component: PATIENT_DETAILS_CONTENT },
          {
            component: PATIENT_LONGITUDINAL_CONTENT,
            props: {
              allowedDataTypes: [RECIST, MORTALITY, RECIST_AND_MORTALITY],
            },
          },
          {
            component: PATIENT_TASKS_CONTENT,
            allowedRoles: [PROJECT_ROLE_VIEWER],
          },
        ],
        topBar: {
          icon: USER_ICON,
          label: "Subject",
        },
      },
      {
        route: OVERVIEW,
        parentRoute: ANALYSIS,
        defaultPage: true,
        allowedRoles: [PROJECT_ROLE_VIEWER],
        showPatientFilters: true,
        content: [
          { component: PROJECT_DETAILS_CONTENT },
          {
            component: ANALYSIS_ALGORITHM_SUMMARY_CONTENT,
            props: { linkToRoute: [ANALYSIS, ANALYSIS_ALGORITHM] },
          },
          {
            component: MEDIAN_SURVIVAL_CONTENT,
            props: {
              allowedDataTypes: [RECIST, MORTALITY, RECIST_AND_MORTALITY],
              linkToRoute: [ANALYSIS, ANALYSIS_SURVIVAL_DETAILS],
              patientRoute: [DATA_MANAGEMENT, SUBJECT, PATIENT_SLUG, ANALYSIS_SUBJECT_JOURNEY],
            },
          },
          {
            component: CHANGE_IN_MORTALITY_CONTENT,
            props: {
              allowedDataTypes: [RECIST, MORTALITY, RECIST_AND_MORTALITY],
              linkToRoute: [ANALYSIS, ANALYSIS_MORTALITY_DETAILS],
              patientRoute: [DATA_MANAGEMENT, SUBJECT, PATIENT_SLUG, ANALYSIS_SUBJECT_JOURNEY],
            },
          },
          {
            component: STUDY_TABLE_CONTENT,
            props: {
              patientRoute: [DATA_MANAGEMENT, SUBJECT, PATIENT_SLUG, ANALYSIS_SUBJECT_JOURNEY],
            },
          },
        ],
        topBar: {
          icon: ANALYSIS_ICON,
          label: "Analysis",
          buttons: [
            {
              label: "Export Report",
              action: DOWNLOAD_REPORT,
              allowedRoles: [PROJECT_ROLE_COORDINATOR],
            },
            {
              label: "Export Annotations",
              action: EXPORT_PROJECT,
              allowedRoles: [],
            },
          ],
        },
      },
      {
        route: ANALYSIS_ALGORITHM,
        parentRoute: ANALYSIS,
        allowedRoles: [PROJECT_ROLE_VIEWER],
        content: [
          { component: ALGORITHM_INFO_CONTENT },
          {
            component: STUDY_TABLE_CONTENT,
            props: {
              patientRoute: [DATA_MANAGEMENT, SUBJECT, PATIENT_SLUG, ANALYSIS_SUBJECT_JOURNEY],
            },
          },
        ],
        topBar: {
          showBackButton: true,
        },
      },
      {
        route: ANALYSIS_SURVIVAL_DETAILS,
        parentRoute: ANALYSIS,
        allowedRoles: [PROJECT_ROLE_VIEWER],
        showPatientFilters: true,
        content: [
          {
            component: MEDIAN_SURVIVAL_CONTENT,
            props: {
              allowedDataTypes: [RECIST, MORTALITY, RECIST_AND_MORTALITY],
              patientRoute: [DATA_MANAGEMENT, SUBJECT, PATIENT_SLUG, ANALYSIS_SUBJECT_JOURNEY],
            },
          },
          {
            component: STUDY_TABLE_CONTENT,
            props: {
              patientRoute: [DATA_MANAGEMENT, SUBJECT, PATIENT_SLUG, ANALYSIS_SUBJECT_JOURNEY],
            },
          },
        ],
        topBar: {
          showBackButton: true,
        },
      },
      {
        route: ANALYSIS_MORTALITY_DETAILS,
        parentRoute: ANALYSIS,
        allowedRoles: [PROJECT_ROLE_VIEWER],
        showPatientFilters: true,
        content: [
          {
            component: CHANGE_IN_MORTALITY_CONTENT,
            props: {
              allowedDataTypes: [RECIST, MORTALITY, RECIST_AND_MORTALITY],
            },
          },
          {
            component: STUDY_TABLE_CONTENT,
            props: {
              patientRoute: [DATA_MANAGEMENT, SUBJECT, PATIENT_SLUG, ANALYSIS_SUBJECT_JOURNEY],
            },
          },
        ],
        topBar: {
          showBackButton: true,
        },
      },
      {
        route: ANALYSIS_NEW,
        defaultPage: false,
        allowedRoles: [PROJECT_ROLE_VIEWER],
        showPatientFilters: true,
        content: [
          { component: PROJECT_DETAILS_CONTENT },
          {
            component: ANALYSIS_ALGORITHM_SUMMARY_CONTENT,
          },
          {
            component: MEDIAN_SURVIVAL_CONTENT,
            props: {
              allowedDataTypes: [RECIST, MORTALITY, RECIST_AND_MORTALITY],
              patientRoute: [SUBJECT, PATIENT_SLUG, ANALYSIS_SUBJECT_JOURNEY],
            },
          },
          {
            component: CHANGE_IN_MORTALITY_CONTENT,
            props: {
              allowedDataTypes: [RECIST, MORTALITY, RECIST_AND_MORTALITY],
              patientRoute: [SUBJECT, PATIENT_SLUG, ANALYSIS_SUBJECT_JOURNEY],
            },
          },
          { component: ENROLMENT_CONTENT },
          {
            component: PATIENT_JOURNEY_DRAWER,
            props: {
              patientRoute: [SUBJECT, PATIENT_SLUG, ANALYSIS_SUBJECT_JOURNEY],
            },
          },
        ],
        topBar: {
          icon: ANALYSIS_ICON,
          label: "Analysis",
          buttons: [
            {
              label: "Export Report",
              action: DOWNLOAD_REPORT,
              allowedRoles: [PROJECT_ROLE_COORDINATOR],
            },
            {
              label: "Export Annotations",
              action: EXPORT_PROJECT,
              allowedRoles: [],
            },
          ],
        },
      },
      {
        route: [OVERVIEW],
        allowedRoles: [PROJECT_ROLE_COORDINATOR, ADMIN],
        defaultPage: true,
        parentRoute: ANNOTATE,
        content: [
          { component: ANNOTATIONS_PER_COHORT_CONTENT },
          { component: TASK_PER_ANNOTATOR_CONTENT },
          { component: ANNOTATIONS_PER_ANNOTATOR_CONTENT },
          { component: LESION_STATISTICS_CONTENT },
        ],
        topBar: {
          icon: OVERVIEW_ICON,
          label: "Overview",
        },
      },
      {
        route: ANNOTATE_WORKLIST,
        allowedRoles: [PROJECT_ROLE_VIEWER],
        parentRoute: ANNOTATE,
        content: [{ component: WORKLIST_CONTENT }],
        topBar: {
          icon: WORKLIST_ICON,
          label: "Annotate - Worklist",
        },
      },
      {
        route: ANNOTATE_MANAGE,
        allowedRoles: [PROJECT_ROLE_COORDINATOR],
        parentRoute: ANNOTATE,
        defaultPage: false,
        content: [{ component: ANNOTATE_MANAGE_PAGE_CONTENT }],
        topBar: {
          icon: SCHEMA_ICON,
          label: "Annotate - Manage",
          buttons: [
            {
              label: "Upload DICOMs",
              icon: UPLOAD_ICON,
              route: [DATA_MANAGEMENT, DATA_MANAGEMENT_UPLOAD],
              allowedRoles: [],
            },
          ],
        },
      },
      {
        route: DOCUMENTS,
        defaultPage: false,
        content: [{ component: PROJECT_DOCUMENT_CONTENT }],
        topBar: {
          icon: DOCUMENTS_ICON,
          label: "Documents",
          buttons: [
            {
              label: "Upload Documents",
              action: UPLOAD_DOCUMENTS,
              allowedRoles: [PROJECT_ROLE_COORDINATOR],
            },
          ],
        },
      },
      {
        route: SITES,
        parentRoute: SETTINGS,
        defaultPage: true,
        content: [{ component: SITE_MANAGEMENT_CONTENT }],
        topBar: {
          icon: HOME_ICON,
          label: "Sites",
        },
      },
      {
        route: TEAM_MEMBERS,
        parentRoute: SETTINGS,
        content: [{ component: USER_MANAGEMENT_CONTENT }],
        topBar: {
          icon: USERS_ICON,
          label: "Team Members",
        },
      },
      {
        route: COHORTS,
        parentRoute: SETTINGS,
        content: [{ component: PROJECT_COHORT_MANAGEMENT_CONTENT }],
        topBar: {
          icon: SETTINGS_ICON,
          label: "Project Cohorts",
        },
      },
      {
        route: SUBJECTS,
        parentRoute: SETTINGS,
        content: [{ component: PROJECT_PATIENT_MANAGEMENT_CONTENT }],
        topBar: {
          icon: SETTINGS_ICON,
          label: "Project Subjects",
        },
      },
    ],
    standalonePages: [
      {
        route: [ANNOTATE, PROJECT, PROJECT_SLUG, ...annotatePageRoute],
        allowedRoles: [PROJECT_ROLE_VIEWER],
        content: [{ component: ANNOTATE_PAGE_CONTENT }],
      },
      {
        route: [ANNOTATE, PROJECT, PROJECT_SLUG, ...tasklessPageRoute],
        allowedRoles: [PROJECT_ROLE_VIEWER],
        content: [{ component: TASKLESS_PAGE_CONTENT }],
      },
    ],
  },
};
