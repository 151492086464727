import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { MutationTuple } from "@apollo/client/react/types/types";

type Variables = { taskAssignmentId: number };

type Data = {
  update_task_assignment: {
    returning: { id: number; last_assigned: string }[];
  };
};

const MUTATION = gql`
  mutation UpdateLastAccessedTaskAssignment($taskAssignmentId: Int!) {
    update_task_assignment(
      where: { id: { _eq: $taskAssignmentId } }
      _set: { last_accessed: "now()" }
    ) {
      returning {
        id
        last_accessed
      }
    }
  }
`;

export function useUpdateTaskAssignmentLastAccessed(): MutationTuple<Data, Variables> {
  return useMutation<Data, Variables>(MUTATION);
}
