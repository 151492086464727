import React from "react";
import styled from "styled-components";

import { NotaLogo } from "../common/components/NotaLogo";

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 50vw;
  text-align: center;
`;

export function ErrorPage(): JSX.Element {
  return (
    <Wrapper>
      <NotaLogo />
      <TextWrapper>
        <div>Something went wrong!</div>
        <div>Please contact your administrator for more details.</div>
      </TextWrapper>
    </Wrapper>
  );
}
