import { gql, useMutation } from "@apollo/client";
import { MutationUpdaterFn } from "@apollo/client/core";
import { MutationTuple } from "@apollo/client/react/types/types";

import { SeriesNotesFragmentType } from "../../../../../fragments/SeriesNotesFragmentType";
import { SERIES_NOTE_FRAGMENT } from "./useStudiesList";

const INSERT_SERIES_NOTES_MUTATION = gql`
  mutation insertSeriesNotes($seriesId: Int!, $projectId: Int!, $notes: String) {
    insert_series_notes_one(
      object: { series_id: $seriesId, project_id: $projectId, notes: $notes }
    ) {
      ...SeriesNotes
    }
  }
  ${SERIES_NOTE_FRAGMENT}
`;

type Data = {
  insert_series_notes_one: SeriesNotesFragmentType;
};

type Variables = {
  seriesId: number;
  projectId: number;
  notes: string | null;
};

export function useInsertSeriesNotes(): MutationTuple<Data, Variables> {
  return useMutation<Data, Variables>(INSERT_SERIES_NOTES_MUTATION, {
    update: updateCache,
  });
}

const updateCache: MutationUpdaterFn<Data> = (cache, { data }) => {
  if (!data) {
    throw new Error("Something went wrong updating the cache after inserting series notes");
  }

  const {
    insert_series_notes_one: { id, seriesId },
  } = data;

  cache.modify({
    id: cache.identify({ __typename: "series", id: seriesId }),
    fields: {
      series_notes(currentSeriesNotesRefs = []) {
        const newSeriesNotesRef = cache.writeFragment({
          data: { id, __typename: "series_notes" },
          fragment: gql`
            fragment SeriesNotesReference on series_notes {
              id
            }
          `,
        });
        return [...currentSeriesNotesRefs, newSeriesNotesRef];
      },
    },
  });
};
