import { GlobalRoleType, UserRoleType } from "../../../types/UserRoleType";
import { globalRoleLabels, projectRoleLabels } from "./roleLabels";

export function getGlobalAndProjectRoles(roles: (UserRoleType | GlobalRoleType)[]): {
  globalRoles: GlobalRoleType[];
  projectRoles: UserRoleType[];
} {
  const allGlobalRoles = Object.keys(globalRoleLabels);
  const allProjectRoles = Object.keys(projectRoleLabels);

  const globalRoles = roles.filter((role) => allGlobalRoles.includes(role)) as GlobalRoleType[];
  const projectRoles = roles.filter((role) => allProjectRoles.includes(role)) as UserRoleType[];

  return {
    globalRoles,
    projectRoles,
  };
}
