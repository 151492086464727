import { gql } from "@apollo/client";

import { useParsedMutation } from "../../../apollo/utils/useParsedMutation";
import { useParsedMutationReturnType } from "../../../apollo/utils/useParsedMutationReturnType";
import { ProjectType } from "../../types/ProjectType";
import { UserType } from "../../types/UserType";

const MUTATION = gql`
  mutation DeleteProjectUser($projectId: Int!, $userId: Int!) {
    delete_project_user(
      where: { _and: [{ project_id: { _eq: $projectId } }, { user_id: { _eq: $userId } }] }
    ) {
      affected_rows
    }
    delete_project_user_role(
      where: { _and: [{ project_id: { _eq: $projectId } }, { user_id: { _eq: $userId } }] }
    ) {
      affected_rows
    }
  }
`;

type Variables = {
  projectId: number;
  userId: number;
};

type Data = {
  delete_project_user: { affected_rows: number };
};

type Input = {
  user: UserType;
  project: ProjectType;
};

export function useDeleteProjectUser(): useParsedMutationReturnType<Input, number> {
  return useParsedMutation<Input, number, Data, Variables>({
    mutation: MUTATION,
    parseVariables,
    parseData,
  });
}

function parseVariables({ project: { id: projectId }, user: { id: userId } }: Input): Variables {
  return {
    userId,
    projectId,
  };
}

function parseData(data: Data): number {
  return data.delete_project_user.affected_rows;
}
