import React, { useState } from "react";

import FilterButton from "../../../../Analysis/ToolPanel/components/FilterButton";
import { UserType } from "../../../types/UserType";
import { OK_CANCEL } from "../../Dialog/DialogActionButtonsType";
import { RED_BUTTON_OVERRIDES } from "../../Dialog/RedButtonOverrides";
import { useDialog } from "../../Dialog/useDialog";
import { TaskReassignmentType } from "../types/TaskReassignmentType";
import { useChangeUserSuspension } from "../useChangeUserSuspension";
import { useReassignUsersTasks } from "../useReassignUsersTasks";
import { buildReassignmentData } from "../utils/buildReassignmentData";
import { useSuspendUserDialog } from "./useSuspendUserDialog";

interface SuspendUserButtonProps {
  user: UserType;
}

export function SuspendUserButton({ user }: SuspendUserButtonProps): JSX.Element {
  const { id: userId } = user;

  const [changeUserSuspension] = useChangeUserSuspension();
  const [reassignUsersTasks] = useReassignUsersTasks();

  const [taskReassignments, setTaskReassignments] = useState<TaskReassignmentType[]>([]);

  const handleFinalizeSuspendUser = () => {
    showConfirmSuspendUserDialog(true);
  };

  const handleConfirmSuspendUser = async () => {
    const taskReassignmentData = buildReassignmentData(taskReassignments);

    await reassignUsersTasks({
      variables: taskReassignmentData,
    });

    await changeUserSuspension({
      variables: { auth0UserId: user.auth0Id || "", suspend: true },
    });
  };

  const [showConfirmSuspendUserDialog, { dialog: confirmSuspendUserDialog }] = useDialog({
    label: `Suspend User`,
    content: <>Are you sure you want to suspend this user, reassigning their tasks?</>,
    options: {
      showCloseButton: false,
      actionButtonOptions: {
        type: OK_CANCEL,
        okText: "Confirm",
        onOkOverride: handleConfirmSuspendUser,
        okButtonOverrides: RED_BUTTON_OVERRIDES,
      },
    },
  });

  const [setManageUserSuspensionDialogOpen, { dialog: manageUserSuspensionDialog }] =
    useSuspendUserDialog(user, taskReassignments, setTaskReassignments, handleFinalizeSuspendUser);

  const handleSuspendClicked = () => {
    if (taskReassignments.length > 0) {
      setManageUserSuspensionDialogOpen(true);
      return;
    }

    handleFinalizeSuspendUser();
  };

  return (
    <>
      {manageUserSuspensionDialog}
      {confirmSuspendUserDialog}
      <FilterButton onClick={handleSuspendClicked} text={"Remove"} active={true} width={71} />
    </>
  );
}
