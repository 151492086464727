import { CornerstoneImageType } from "../../../../../../../cornerstone/CornerstoneImageType";
import { ContourToolDataType } from "../../../../../../types/ContourToolDataType";
import { getImagePixelSpacing } from "../../../ViewerPanel/tools/toolController/getImagePixelSpacing";
import { findContourDiameters } from "./findContourDiameters";

export function appendContourDiameters(
  toolData: ContourToolDataType[],
  image: CornerstoneImageType
): ContourToolDataType[] {
  const { rowPixelSpacing, columnPixelSpacing } = getImagePixelSpacing(image);
  return toolData.map((data) => {
    const {
      handles: { points },
    } = data;
    const diameters = findContourDiameters(points, rowPixelSpacing, columnPixelSpacing);
    return { ...data, diameters };
  });
}
