import { gql, useQuery } from "@apollo/client";
import React from "react";

import { Card } from "../../../common/components/cards/Card";
import { FlexLoading } from "../../../common/components/Loading";
import handleApolloError from "../../../common/utils/handleApolloError";
import RadiologyPanel from "../../../Overview/Radiology/components/RadiologyPanel";
import { useProjectId } from "../../../Project/hooks/useProjectId";
import parseDataIngestionData from "../utils/parseDataIngestionData";
import DataIngestionProgress from "./DataIngestionProgress";

const GET_UPLOAD_REPORTS = gql`
  query GetUploadReports($projectId: Int!) {
    uploadJobReport: upload_job_report(where: { upload_job: { project_id: { _eq: $projectId } } }) {
      contents
    }
  }
`;

function DataIngestionProgressContainer(): React.ReactElement {
  const projectId = useProjectId();
  const { data, loading, error } = useQuery(GET_UPLOAD_REPORTS, {
    variables: { projectId },
  });

  if (loading) return <FlexLoading />;
  if (error) handleApolloError(error);

  const criteria = "RECIST 1.1";
  const followUp = "Double central review with adjudication";

  const processedData = parseDataIngestionData(data);
  return (
    <RadiologyPanel criteria={criteria} followUp={followUp}>
      <DataIngestionProgress dataInfo={processedData} />
    </RadiologyPanel>
  );
}

export function DataIngestionProgressCard(): JSX.Element {
  return <Card title={"Data Ingestion Progress"} content={<DataIngestionProgressContainer />} />;
}
