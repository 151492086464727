import {
  SMOKING_CURRENT,
  SMOKING_FORMER,
  SMOKING_NEVER,
} from "../../../Analysis/common/types/SmokingStatusType";
import { LifestyleFilters } from "../../types/LifestyleFilterTypes";
import { RootState } from "../store";
import { ProjectFilterSelectors } from "./ProjectFilterSelectors";

export const defaultLifestyleState = {
  bmi: {
    min: null,
    max: null,
  },
  smokingStatus: {
    [SMOKING_CURRENT]: true,
    [SMOKING_FORMER]: true,
    [SMOKING_NEVER]: true,
  },
};

export const clearLifestyleState = {
  bmi: {
    min: null,
    max: null,
  },
  smokingStatus: {
    [SMOKING_CURRENT]: false,
    [SMOKING_FORMER]: false,
    [SMOKING_NEVER]: false,
  },
};

export const lifestyleFilters = new ProjectFilterSelectors<LifestyleFilters>(
  defaultLifestyleState,
  clearLifestyleState,
  (state: RootState) => state.projectViewFilters.lifestyle
);
