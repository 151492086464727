import { gql, useQuery } from "@apollo/client";
import { List, ListItem } from "@mui/material";
import { CHECK_MISSING_REQUIRED_QC_FIELDS } from "nota-predict-web/src/Annotate/enums/TaskDescriptionEnums";
import { TaskContext } from "nota-predict-web/src/Annotate/TaskContext";
import { SUCCESS, WARNING } from "nota-predict-web/src/common/types/StatusTypes";
import { PatientContext } from "nota-predict-web/src/Dashboard/components/Settings/Patients/Viewer/PatientContext";
import { useContext, useMemo } from "react";
import styled from "styled-components";

import { UseTaskCheckReturnType } from "../toolbar/SaveChecks/types/UseTaskCheckReturnType";
import { getQcReportsStatus } from "./QcForm.utils";
import { useQualityControlContext } from "./QualityControlProvider";
import { QcFormScope } from "./QualityControlProvider.types";

const Bold = styled.span`
  font-weight: bold;
`;

// TODO: implement most of this, and no any!
export const useQualityControlTaskChecks = (): UseTaskCheckReturnType<null> => {
  const { isQc } = useContext(TaskContext);
  const { status, count } = useTaskQcReportStatus();

  if (!isQc) {
    throw new Error("useQcForm hook can only be used with QC tasks.");
  }

  const successMessage = "Quality control for this task is complete.";

  // const expectedCount = count.series.expected + count.studies.expected + count.subjects.expected;
  // const validCount = count.series.valid + count.studies.valid + count.subjects.valid;
  const knownCount = count.series.known + count.studies.known + count.subjects.known;

  const noReportsFoundMessage = "No quality control reports were found for this task.";

  const incompleteSeries = count.series.expected - count.series.valid;
  // const incompleteStudies = count.studies.expected - count.studies.valid;
  const incompleteSubjects = count.subjects.expected - count.subjects.valid;

  const missingReportsMessage = (
    <>
      Some required reports are incomplete:{" "}
      <List>
        {!!incompleteSeries && (
          <ListItem>
            <Bold>
              {incompleteSeries} of {count.series.expected} series report(s)
            </Bold>
          </ListItem>
        )}
        {/* TODO: we don't have any study rules in v0.1, so leaving this out for now
        because there is no logic to support counting expected reports based on QC schema */}
        {/*{incompleteStudies && (*/}
        {/*  <ListItem>*/}
        {/*    <Bold>*/}
        {/*      {incompleteStudies} of {count.studies.expected} study report(s)*/}
        {/*    </Bold>*/}
        {/*  </ListItem>*/}
        {/*)}*/}
        {!!incompleteSubjects && (
          <ListItem>
            <Bold>The subject report has missing or invalid fields</Bold>
          </ListItem>
        )}
      </List>
    </>
  );

  // TODO: Currently no logic will allow error message to be shown, but leaving this here for now
  // Need to decide whether or not to prevent completion of forms if invalid; currently all cases
  // are warning only —B
  const errorMessage = (
    <>
      Some required fields are missing. Please complete the following forms:{" "}
      <List>
        {count.series.invalid > 0 && (
          <ListItem>
            <Bold>
              {count.series.invalid} of {count.series.known} series form(s)
            </Bold>
          </ListItem>
        )}
        {/* TODO: we don't have any study in v0.1, so leaving this out for now
        because there is no logic to support counting expected reports based on QC schema */}
        {/* {count.studies.invalid > 0 && (
          <ListItem>
            <Bold>
              {count.studies.invalid} of {count.studies.known} study form(s)
            </Bold>
          </ListItem>
        )}*/}
        {count.subjects.invalid > 0 && (
          <ListItem>
            <Bold>The subject form has missing or invalid fields</Bold>
          </ListItem>
        )}
      </List>
    </>
  );

  return () => ({
    type: CHECK_MISSING_REQUIRED_QC_FIELDS,
    message:
      status === SUCCESS
        ? successMessage
        : status === WARNING
        ? knownCount
          ? missingReportsMessage
          : noReportsFoundMessage
        : errorMessage,
    status,
    required: false,
    result: null,
  });
};

interface UseTaskQcReportsStatusOptions {
  pollInterval?: number;
  failAction?: "none" | "warn" | "throw";
}

export const IGNORE_SERIES_WITH_DESCRIPTIONS = ["attention map"];

export const useTaskQcReportStatus = ({
  pollInterval = TASK_QC_REPORT_STATUS_DEFAULT_POLL_INTERVAL,
  failAction = "none",
}: UseTaskQcReportsStatusOptions = {}) => {
  const { task } = useContext(TaskContext);
  const { timepoints, id: patientId } = useContext(PatientContext);
  const flatSeries = timepoints
    .flatMap((t) => t.series)
    .filter(
      (s) =>
        !IGNORE_SERIES_WITH_DESCRIPTIONS.includes(String(s?.seriesDescription).toLowerCase()) &&
        !s.projectSeriesExclusions.some(({ projectId }) => {
          return projectId === task.projectId;
        })
    );
  const totalSeries = flatSeries.length;

  const { schema, error: validationError } = useQualityControlContext();

  const err = "No current task found for useTaskQcReportStatus()";

  const { data: statusData, error: statusError } = useGetQcReportsStatusQuery({
    pollInterval,
    qcSchemaId: task.qcSchemaId,
    seriesIds: flatSeries.map((s) => s.id),
    patientIds: [patientId],
  });

  if (!task || validationError) {
    switch (failAction) {
      case "warn":
        console.warn(err);
        break;
      case "throw":
        throw new Error(err);
      default:
        break;
    }
  }

  const totalSubjectSchemas = schema?.subject_rule_set.length ?? 0;

  const count = useMemo(() => {
    const series = statusData?.qcReports?.filter((report) => report.scope === "SERIES") || [];
    const studies = statusData?.qcReports?.filter((report) => report.scope === "STUDY") || [];
    const subjects = statusData?.qcReports?.filter((report) => report.scope === "PATIENT") || [];

    return {
      series: {
        expected: totalSeries,
        known: series.length,
        valid: series.filter((report) => report.isValid).length,
        invalid: series.filter((report) => !report.isValid).length,
      },
      studies: {
        expected: 0,
        known: studies.length,
        valid: studies.filter((report) => report.isValid).length,
        invalid: studies.filter((report) => !report.isValid).length,
      },
      subjects: {
        expected: totalSubjectSchemas,
        known: subjects.length,
        valid: subjects.filter((report) => report.isValid).length,
        invalid: subjects.filter((report) => !report.isValid).length,
      },
    };
  }, [totalSeries, totalSubjectSchemas, statusData?.qcReports]);

  // TODO: we are not yet counting study reports
  const overallStatus = getQcReportsStatus(
    statusData?.qcReports ?? [],
    totalSeries + totalSubjectSchemas
  );

  return {
    qcReports: statusData?.qcReports,
    error: statusError,
    status: overallStatus,
    count,
  };
};

export type GetTaskQcReportsStatus = {
  qcReports: {
    id: number;
    qcSchemaId: number;
    seriesId: number | null;
    studyId: number | null;
    patientId: number | null;
    scope: QcFormScope; // TODO: rename to scope in db
    isValid: boolean;
  }[];
};

const TASK_QC_REPORT_STATUS_DEFAULT_POLL_INTERVAL = 2500;

const useGetQcReportsStatusQuery = ({
  qcSchemaId,
  seriesIds,
  studyIds = [],
  patientIds,
  pollInterval = TASK_QC_REPORT_STATUS_DEFAULT_POLL_INTERVAL,
}: {
  qcSchemaId: number | null;
  seriesIds: number[];
  studyIds?: number[];
  patientIds: number[];
  pollInterval?: number;
}) => {
  const { isQc } = useContext(TaskContext);
  const shouldSkip = !isQc || !qcSchemaId;

  return useQuery<GetTaskQcReportsStatus>(GET_QC_REPORTS_STATUS, {
    variables: {
      qcSchemaId,
      seriesIds,
      studyIds,
      patientIds,
    },
    skip: shouldSkip,
    pollInterval: shouldSkip ? undefined : pollInterval,
  });
};

export const GET_QC_REPORTS_STATUS = gql`
  query GetQcReportsStatus(
    $qcSchemaId: Int!
    $seriesIds: [Int!]!
    $studyIds: [Int!] = []
    $patientIds: [Int!]!
  ) {
    qcReports: qc_report(
      where: {
        _and: [
          { qc_schema_id: { _eq: $qcSchemaId } }
          {
            _or: [
              { series_id: { _in: $seriesIds } }
              { study_id: { _in: $studyIds } }
              { patient_id: { _in: $patientIds } }
            ]
          }
        ]
      }
    ) {
      id
      qcSchemaId: qc_schema_id
      scope
      seriesId: series_id
      studyId: study_id
      patientId: patient_id
      isValid: result_valid
    }
  }
`;
