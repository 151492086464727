import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { Row } from "react-table";
import styled from "styled-components";

import { ReactComponent as CancelIcon } from "../../../assets/svgs/CancelOutline.svg";
import { ReactComponent as MoveIcon } from "../../../assets/svgs/MoveDownOutline.svg";
import { ReactComponent as AddIcon } from "../../../assets/svgs/PlusOutline.svg";
import { ReactComponent as RemoveIcon } from "../../../assets/svgs/RemoveOutline.svg";
import { Card } from "../../../common/components/cards/Card";
import { SeriesSelectionLabel } from "../../../common/components/StudyTable/SeriesSelectionLabel";
import { TableControlsType } from "../../../common/components/Table/TableControlsType";
import { ADD, REMOVE, UpdateModeType } from "../../../common/types/UpdateModeType";
import { ActionButton } from "./ActionButton";
import { AnnotationsExportButton, QCReportExportButton } from "./ExportButton";
import { useProjectPatientTaskRows } from "./hooks/useProjectPatientTaskRows";
import { useUpdateTaskAssignmentsStatusDialog } from "./ManageTasksDialog/useUpdateTaskAssignmentsStatusDialog";
import { useConfirmTaskAssignmentsForm } from "./PatientTable/ConfirmTaskAssignments/useConfirmTaskAssignmentsForm";
import { MemoTableWithRef } from "./PatientTable/PatientTableWithRef";
import { TasksSelect } from "./PatientTable/TaskSelect/TasksSelect";
import { PatientTaskTableRowType } from "./PatientTable/types/PatientTaskTableRowType";
import { usePatientTableColumns } from "./PatientTable/usePatientTableColumns";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ActionPanelWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const TaskSelectWrapper = styled.div`
  flex: 0.75;
`;

const ButtonsWrapper = styled.div`
  flex: 0;
  display: flex;
  padding: 4px;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

export function ManageTaskAssignments(): JSX.Element {
  const flags = useFlags();
  const showAnnotationExport = flags["showNotaExportAnnotations"];

  const [selectedPatientRowIds, setSelectedPatientRowIds] = useState<[number, number][]>([]);

  const tableRef = useRef<TableControlsType<PatientTaskTableRowType>>(null);

  const [showConfirmTaskAssignmentsDialog, { dialog: confirmTaskAssignmentsDialog }] =
    useConfirmTaskAssignmentsForm();

  const { patientsTasks, loading: patientTasksLoading } = useProjectPatientTaskRows(true, true);

  //use memo here since defaulting to an empty array causes infinite loop
  const rows = useMemo(() => {
    return patientsTasks ?? [];
  }, [patientsTasks]);

  const getSelectedRows = () =>
    rows.filter(({ id, task }) =>
      selectedPatientRowIds.find((idPair) => idPair[0] === id && idPair[1] === task?.id)
    );

  const columns = usePatientTableColumns();

  const handleRowsSelected = useCallback((selectedRows: Row<PatientTaskTableRowType>[]) => {
    // The -1 replacement is to placate typescript, as a task will always exist in this context
    // In the case one doesn't we wouldn't want anything to run for this record, so using -1 is a safety measure
    setSelectedPatientRowIds(
      selectedRows.map(({ original }) => [original.id, original.task?.id ?? -1])
    );
  }, []);

  const handleClearClicked = () => {
    const { current: tableControls } = tableRef;
    tableControls?.clearAllFilters();
    tableControls?.clearSelectedRows();
  };

  const handleAssignClicked = async () => {
    await handleAssign(ADD);
  };

  const handleUnassignClicked = async () => {
    await handleAssign(REMOVE);
  };

  const handleAssign = async (updateMode: UpdateModeType) => {
    showConfirmTaskAssignmentsDialog({
      updateMode,
      patientTableRows: getSelectedRows(),
      initialSelectedUsers: [],
    });
  };

  const [showChangeStatusDialog, { dialog: changeStatusDialog }] =
    useUpdateTaskAssignmentsStatusDialog(getSelectedRows());

  const handleChangeStatus = () => {
    showChangeStatusDialog(true);
  };

  const canAssign = selectedPatientRowIds.length > 0;

  return (
    <Card
      noHead
      expand={true}
      content={
        <Wrapper>
          {changeStatusDialog}
          {confirmTaskAssignmentsDialog}
          <ActionPanelWrapper>
            <ButtonsWrapper>
              <ActionButton
                label={"Clear"}
                icon={CancelIcon}
                onClick={handleClearClicked}
                tooltip={"Clear all selections/filters"}
              />
              <ActionButton
                label={"Assign"}
                icon={AddIcon}
                onClick={handleAssignClicked}
                tooltip={"Assign selected users from tasks"}
                disabled={!canAssign}
              />
              <ActionButton
                label={"Unassign"}
                icon={RemoveIcon}
                onClick={handleUnassignClicked}
                tooltip={"Unassign selected users from tasks"}
                disabled={!canAssign}
              />
              {showAnnotationExport && (
                <AnnotationsExportButton
                  selectedPatientRows={getSelectedRows()}
                  variant="panel"
                  label="Export Annotations"
                  tooltip={"Export annotations for selected subjects"}
                />
              )}
              <QCReportExportButton
                selectedPatientRows={getSelectedRows()}
                variant="panel"
                label="Export QC Report"
                tooltip={"Export QC report for selected subjects"}
              />
              <ActionButton
                label={"Change Status"}
                icon={MoveIcon}
                onClick={handleChangeStatus}
                tooltip={"Change Status of Selected Task Assignments"}
                disabled={!canAssign}
              />
            </ButtonsWrapper>
            <SelectionInfo selectedRows={getSelectedRows()} />
            <TaskSelectWrapper>
              <TasksSelect
                selectedTasks={[]}
                setSelectedTasks={() => undefined}
                enableTasksManagement={true}
                placeholder={"Create and Manage Tasks"}
              />
            </TaskSelectWrapper>
          </ActionPanelWrapper>
          <MemoTableWithRef
            ref={tableRef}
            columns={columns}
            data={rows}
            enableSelect={true}
            onRowsSelected={handleRowsSelected}
            loading={patientTasksLoading}
            loadingMessage={"Loading tasks..."}
            minimumWidth={600}
          />
        </Wrapper>
      }
    />
  );
}

interface SelectionInfoProps {
  selectedRows: PatientTaskTableRowType[];
}

function SelectionInfo({ selectedRows }: SelectionInfoProps): JSX.Element {
  const { totalStudies, totalSeries } = useMemo(() => {
    const studies = selectedRows.flatMap(({ studies }) => studies);
    const series = studies.flatMap(({ series }) => series);
    return { totalStudies: studies.length, totalSeries: series.length };
  }, [selectedRows]);

  return <SeriesSelectionLabel totalSeries={totalSeries} totalStudies={totalStudies} />;
}
