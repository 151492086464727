import { CircularProgress, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React, { FC, useContext, useEffect, useState } from "react";
import { FiInfo as InfoIcon } from "react-icons/fi";
import styled from "styled-components";

import CohortUploadCsvExample from "../../../assets/images/CohortUploadCsvExample.png";
import CTAButton from "../../../common/components/buttons/CTAButton";
import { Card } from "../../../common/components/cards/Card";
import { useOkForm } from "../../../common/components/Dialog/useOkForm";
import { main } from "../../../common/theme/main";
import { CohortType } from "../../../common/types/CohortType";
import { CohortLabel } from "../../../Dashboard/components/Settings/Cohort/CohortLabel";
import { ProjectContext } from "../../../Project/contexts/ProjectContext";
import { useDeleteCSVValues, useInsertCSVValues } from "../useChangeCohortAssociations";
import { getCSVIdsVariables, useCSVIds } from "../useCSVIds";
import { DragDropCsvUpload } from "./DragDropCsvUpload";

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  white-space: pre;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
`;

const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  justify-content: center;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  & .MuiFormControlLabel-label {
    font-size: 13px;
  }
`;

const LoadingIcon = styled(CircularProgress)`
  &.MuiCircularProgress-colorPrimary {
    color: ${main.colors.primary.primary};
  }
`;

const StyledRadio = styled(Radio)`
  & .MuiSvgIcon-root {
    font-size: 14px;
  }

  &.Mui-checked {
    color: ${main.colors.neutral.black};
  }

  color: ${main.colors.neutral.black};
`;

export const InfoTextWrapper = styled.div`
  font-size: 15px;
  margin: 8px 0;
`;

const ErrorTextWrapper = styled(InfoTextWrapper)`
  color: ${main.colors.states.error};
`;

const BoldInfoTextWrapper = styled.span`
  font-weight: bold;
`;

export const CodeInfoTextWrapper = styled.span`
  font-family: "Oxygen Mono", monospace;
  font-weight: 400;
  background-color: #f3f3f3;
  border-radius: 5px;
  padding: 0.15em 0.4em;
`;

const StyledImage = styled.img`
  height: 150px;
  width: fit-content;
  border: 1px solid #c3c7c5;
`;

interface UploadCsvProps {
  cohort?: CohortType;
}

export type ColumnType = "subjects" | "studies" | "series";

export const UploadCsv: FC<UploadCsvProps> = ({ cohort }: UploadCsvProps) => {
  const [action, setAction] = useState<string>("add");
  const [isValidCsv, setIsValidCsv] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [processing, setProcessing] = useState<boolean>(false);
  const [data, setData] = useState<Record<ColumnType, string[]>>({
    subjects: [],
    studies: [],
    series: [],
  });

  const { projectId } = useContext(ProjectContext);

  const [getCSVIds, { data: idData, error: idError }] = useCSVIds();
  const [insertCSVValues, { data: insertData, error: insertError }] = useInsertCSVValues();
  const [deleteCSVValues, { data: deleteData, error: deleteError }] = useDeleteCSVValues();

  const [showCSVErrorDialog, { dialog: CSVErrorDialog }] = useOkForm({
    title: "An issue occurred while uploading the CSV.",
    message: "An issue occurred either while processing, or while adding/removing values.",
  });

  const resetState = () => {
    setIsValidCsv(false);
    setErrorMessage(null);
    setData({
      subjects: [],
      studies: [],
      series: [],
    });
    setProcessing(false);
  };

  useEffect(() => {
    if (cohort && idData) {
      if (action === "add") {
        insertCSVValues({
          variables: {
            labelSeriesPairs:
              idData.series.map(({ seriesId }) => ({
                label_id: cohort.id,
                series_id: seriesId,
              })) ?? [],
            labelStudyPairs:
              idData.study.map(({ studyId }) => ({
                label_id: cohort.id,
                study_id: studyId,
              })) ?? [],
            labelPatientPairs:
              idData.patient.map(({ patientId }) => ({
                label_id: cohort.id,
                patient_id: patientId,
              })) ?? [],
          },
        });
      } else if (action === "remove") {
        deleteCSVValues({
          variables: {
            labelId: cohort.id,
            seriesIds: idData.series.map(({ seriesId }) => seriesId) ?? [],
            studyIds: idData.study.map(({ studyId }) => studyId) ?? [],
            patientIds: idData.patient.map(({ patientId }) => patientId) ?? [],
          },
        });
      } else {
        showCSVErrorDialog(true);
        resetState();
      }
    }
    //Deps array is intentionally limited, we only want to fire changes off when ids are retrieved
  }, [idData]);

  useEffect(() => {
    resetState();
  }, [insertData, deleteData]);

  useEffect(() => {
    if (idError || insertError || deleteError) {
      showCSVErrorDialog(true);
      resetState();
    }
  }, [showCSVErrorDialog, idError, insertError, deleteError]);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAction((event.target as HTMLInputElement).value);
  };

  const handleUploadCsv = async () => {
    if (cohort) {
      await getCSVIds({
        variables: getCSVIdsVariables(projectId, data.series, data.studies, data.subjects),
      });
      setProcessing(true);
    }
  };

  return (
    <>
      {CSVErrorDialog}
      <TitleRow>Bulk edit - {cohort && <CohortLabel cohort={cohort} />}</TitleRow>
      <div>
        <Card
          noHead
          content={
            <DragDropCsvUpload
              isValidCsv={isValidCsv}
              setIsValidCsv={setIsValidCsv}
              setData={setData}
              setErrorMessage={setErrorMessage}
            />
          }
        />
        {errorMessage && <ErrorTextWrapper>{errorMessage}</ErrorTextWrapper>}
      </div>
      <InputWrapper>
        <StyledRadioGroup row value={action} onChange={handleRadioChange} name={"csv-action"}>
          <StyledFormControlLabel
            value={"add"}
            control={<StyledRadio disableRipple />}
            label={"Add to cohort"}
            disabled={!isValidCsv || processing}
          />
          <StyledFormControlLabel
            value={"remove"}
            control={<StyledRadio disableRipple />}
            label={"Remove from cohort"}
            disabled={!isValidCsv || processing}
          />
        </StyledRadioGroup>
        {!processing && (
          <CTAButton
            blackBackground
            text={
              isValidCsv
                ? action === "add"
                  ? "Add to cohort"
                  : "Remove from cohort"
                : "Upload a .csv file to continue"
            }
            disabled={!isValidCsv || processing}
            onClick={handleUploadCsv}
          />
        )}
        {processing && (
          <CTAButton
            icon={<LoadingIcon size={22} />}
            text={action === "add" ? "Adding to cohort..." : "Removing from cohort..."}
            disabled={true}
          />
        )}
      </InputWrapper>
      <Card
        noHead
        noShadow
        content={
          <>
            <InfoIcon color={main.colors.states.info} size={20} />
            <InfoTextWrapper>
              You can bulk add or remove subjects (patients), studies, or series to/from a cohort by
              uploading a .csv file. Your .csv file must have{" "}
              <BoldInfoTextWrapper>headers</BoldInfoTextWrapper> for each column - the following
              column names will be recognized:
            </InfoTextWrapper>
            <InfoTextWrapper>
              <CodeInfoTextWrapper>patient</CodeInfoTextWrapper> or{" "}
              <CodeInfoTextWrapper>subject</CodeInfoTextWrapper> or{" "}
              <CodeInfoTextWrapper>patient_dicom_id</CodeInfoTextWrapper>
            </InfoTextWrapper>
            <InfoTextWrapper>
              <CodeInfoTextWrapper>study</CodeInfoTextWrapper> or{" "}
              <CodeInfoTextWrapper>study_id</CodeInfoTextWrapper> or{" "}
              <CodeInfoTextWrapper>study_uid</CodeInfoTextWrapper>
            </InfoTextWrapper>
            <InfoTextWrapper>
              <CodeInfoTextWrapper>series</CodeInfoTextWrapper> or{" "}
              <CodeInfoTextWrapper>series_id</CodeInfoTextWrapper> or{" "}
              <CodeInfoTextWrapper>series_uid</CodeInfoTextWrapper>
            </InfoTextWrapper>
            <InfoTextWrapper>Example:</InfoTextWrapper>
            <StyledImage src={CohortUploadCsvExample} alt="CohortUploadCsvExampleImage" />
          </>
        }
      />
    </>
  );
};
