import { ApolloCache } from "@apollo/client";

export function getSeriesCacheId<T>(seriesId: number, cache: ApolloCache<T>): string {
  const id = cache.identify({
    id: seriesId,
    __typename: "series",
  });

  if (!id) {
    throw new Error("Unable to construct series cache id");
  }

  return id;
}
