import { DayjsDateRange } from "../../../common/utils/dateFormatUtils/DayjsDateRange";
import { DataGroupByType } from "../components/TasksPerAnnotatorCard";
import { PatientType, TaskAssignmentType } from "../hooks/useProjectLabels";
import { isTaskAssignmentInRange } from "./isTaskAssignmentInRange";

export function getUserTaskAssignments(
  patients: PatientType[],
  userIds: number[],
  range: DayjsDateRange,
  groupByMethod: DataGroupByType
): { taskAssignments: TaskAssignmentType[]; count: number }[] {
  return patients.flatMap(({ taskAssignments, studies }) => ({
    taskAssignments: taskAssignments.filter((taskAssignment) => {
      const { userId: taskAssignmentUserId } = taskAssignment;
      return (
        isTaskAssignmentInRange(taskAssignment, range) && userIds.includes(taskAssignmentUserId)
      );
    }),
    //TODO: check how this interfaces with excluded patients, studies (via limitToBaseline) and series, additionally
    // if all the chart data includes excluded elements. This work is tracked to the following ticket:
    // https://linear.app/altislabs/issue/NOTA-779/annotate-overview-graphs-should-respect-patient-study-and-series
    count:
      groupByMethod === "PATIENT"
        ? 1
        : studies.reduce((acc, val) => acc + val.series_aggregate.aggregate.count, 0),
  }));
}
