import { ApolloCache } from "@apollo/client";

export function getRoiClassificationCacheId<T>(
  roiClassificationId: number,
  cache: ApolloCache<T>
): string {
  const id = cache.identify({
    id: roiClassificationId,
    __typename: "roi_classification",
  });

  if (!id) {
    throw new Error("Unable to construct roiClassification cache id");
  }

  return id;
}
