import {
  LESION_CLASSIFICATION,
  ORGAN_SEGMENTATION,
  RECIST_1_1,
  SERIES_CLASSIFICATION,
} from "../../../../enums/TaskDescriptionEnums";

export const SEGMENTATION = "SEGMENTATION";
export const DETECTION = "DETECTION";

export const QUALITY_CONTROL = "QUALITY_CONTROL";

export type TaskTemplateType =
  | typeof RECIST_1_1
  | typeof LESION_CLASSIFICATION
  | typeof ORGAN_SEGMENTATION
  | typeof SEGMENTATION
  | typeof DETECTION
  | typeof QUALITY_CONTROL
  | typeof SERIES_CLASSIFICATION;
