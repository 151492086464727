import { useContext } from "react";

import { GlobalRoleType, UserRoleType } from "../../types/UserRoleType";
import { useCurrentUser } from "./useCurrentUser";
import { UserContext } from "./UserContext";

export function useCurrentUserRoles(
  projectId: number | undefined
): (UserRoleType | GlobalRoleType)[] {
  const { globalRoles } = useCurrentUser();

  const { allProjectUserRoles } = useContext(UserContext);
  if (!allProjectUserRoles) {
    throw new Error("Current user allProjectUserRoles is undefined");
  }

  if (projectId === undefined) {
    return globalRoles;
  }

  const projectUserRoles = allProjectUserRoles
    .filter(({ projectId: roleProjectId }) => roleProjectId === projectId)
    .map(({ role }) => role);

  return [...globalRoles, ...projectUserRoles];
}
