import React from "react";
import styled from "styled-components";

import { AnatomicalStructuresFragmentType } from "../../../../Annotate/components/AnatomicalStructuresFragment";
import { DEFAULT_ANNOTATION_COLOR } from "../../../../Annotate/components/Annotate/page/AnnotationPanel/utils/useLesionColorDeferred";

const Circle = styled.div<{ color: string; size: number }>`
  background: ${({ color }) => color};
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 50%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

interface LabelLabelProps {
  label: AnatomicalStructuresFragmentType;
}

export function LabelLabel({ label }: LabelLabelProps): JSX.Element {
  const { name, color } = label;

  return (
    <Wrapper>
      <Circle color={color ?? DEFAULT_ANNOTATION_COLOR} size={12} />
      {name}
    </Wrapper>
  );
}
