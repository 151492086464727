import React from "react";

import { TUMOUR_LAD } from "../../../../../enums/TaskDescriptionEnums";
import { statsDisplayName } from "../../../../../types/TaskDescriptionType";
import { SimpleBodyTimepointStat } from "./SimpleBodyTimepointStat";
import { RoiStatComponentDefinition, RoiStatProps } from "./useGetRoiStatComponents";
import { formatMM } from "./utils/formatMM";

function RoiLADStat(props: RoiStatProps): JSX.Element {
  const { roi } = props;
  const LAD = roi.roiRecistEvaluation?.LAD;

  if (!LAD || !hasValue(props)) {
    throw new Error("Should not render");
  }

  const label = statsDisplayName[TUMOUR_LAD];
  const formattedValue = formatMM(LAD);

  return <SimpleBodyTimepointStat title={label} value={formattedValue} />;
}

function hasValue({ roi: { roiRecistEvaluation } }: RoiStatProps): boolean {
  return !!roiRecistEvaluation?.LAD;
}

export const roiLADStatDefinition: RoiStatComponentDefinition = {
  type: TUMOUR_LAD,
  hasValue,
  component: RoiLADStat,
};
