import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { MutationTuple } from "@apollo/client/react/types/types";

import { SERIES_FLAG_FRAGMENT } from "../../../../../fragments/SeriesFlagFragment";
import { SeriesFlagType } from "../../../../../types/SeriesFlagType";
import { getCacheId } from "../../../../Manage/cache/getCacheId";

type Data = {
  seriesFlag: SeriesFlagType | null;
};

type Variables = {
  id: number;
};

const MUTATION = gql`
  mutation DeleteSeriesFlag($id: Int!) {
    seriesFlag: delete_series_flag_by_pk(id: $id) {
      ...SeriesFlag
    }
  }
  ${SERIES_FLAG_FRAGMENT}
`;

export function useDeleteSeriesFlag(): MutationTuple<Data, Variables> {
  return useMutation<Data, Variables>(MUTATION, {
    update(cache, { data }) {
      if (!data) {
        throw new Error("Something went wrong updating the cache after deleting a series flag");
      }

      if (data.seriesFlag === null) {
        return;
      }

      const {
        seriesFlag: { id, seriesId, reasons },
      } = data;

      const seriesCacheId = getCacheId(seriesId, "series", cache);

      cache.modify({
        id: seriesCacheId,
        fields: {
          series_flags(existingRefs = [], { readField }) {
            return existingRefs.filter((ref: never) => id !== readField("id", ref));
          },
        },
      });

      for (const { id } of reasons) {
        const seriesFlagReasonId = getCacheId(id, "series_flag_reason", cache);
        cache.evict({ id: seriesFlagReasonId });
      }

      const seriesFlagCacheId = getCacheId(id, "series_flag", cache);
      cache.evict({ id: seriesFlagCacheId });
    },
  });
}
