import { EllipseFragmentType } from "../../../../../../../fragments/EllipseFragmentType";
import { setEllipseId } from "../../legacy/ToolUtils";

export function updateEllipseToolDataId(
  ellipseId: number,
  ellipseData: EllipseFragmentType["data"]
): void {
  const { slice } = ellipseData;
  if (slice) {
    const { toolData } = slice;
    for (const item of toolData) {
      setEllipseId(item, ellipseId);
    }
  }
}
