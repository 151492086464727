import { MutationTuple, useMutation } from "@apollo/client";
import { gql } from "@apollo/client/core";

import {
  LESION_CLASSIFICATION,
  OBJECT_LABELLING,
  ORGAN_SEGMENTATION,
  SERIES_CLASSIFICATION,
} from "../../../enums/TaskDescriptionEnums";
import { TaskDescriptionType as TaskDescriptionDataType } from "../../../types/TaskDescriptionType";
import { AnatomicalStructuresFragmentType } from "../../AnatomicalStructuresFragment";
import { LabellingOptions } from "../CreateTaskWizard/LabellingOptions";
import { SeriesClassificationOptions } from "../CreateTaskWizard/SeriesClassificationOptions";
import { createLesionClassificationDescription } from "../CreateTaskWizard/utils/createLesionClassificationDescription";
import { createObjectLabellingTaskDescription } from "../CreateTaskWizard/utils/createObjectLabellingTaskDescription";
import { createOrganSegmentationTaskDescription } from "../CreateTaskWizard/utils/createOrganSegmentationTaskDescription";
import { createSeriesClassificationTaskDescription } from "../CreateTaskWizard/utils/createSeriesClassificationTaskDescription";
import { ClassificationAttributeType } from "./useClassificationAttributes";
import { TaskDescriptionType } from "./useTaskDescriptions";

const MUTATION = gql`
  mutation InsertTaskDescription($data: jsonb!) {
    insert_task_description_one(object: { data: $data }) {
      id
      data
    }
  }
`;

export type VariablesType = {
  data: TaskDescriptionDataType;
};

export type DataType = {
  insert_task_description_one: {
    id: number;
    data: TaskDescriptionDataType;
  };
};

export function useInsertTaskDescription(): MutationTuple<DataType, VariablesType> {
  return useMutation<DataType, VariablesType>(MUTATION);
}

export function getTaskDescriptionToInsert(
  taskDescription: TaskDescriptionType | undefined,
  anatomicalStructures: AnatomicalStructuresFragmentType[],
  newTaskName: string,
  labellingOptions: LabellingOptions,
  seriesClassifications: ClassificationAttributeType[],
  seriesClassificationOptions: SeriesClassificationOptions,
  firstTimepointOnly: boolean
): TaskDescriptionDataType | null {
  if (!taskDescription) {
    return null;
  }
  const { data } = taskDescription;

  const { name: taskDescriptionName } = data;

  if (taskDescriptionName === SERIES_CLASSIFICATION) {
    return createSeriesClassificationTaskDescription(
      data,
      seriesClassifications,
      newTaskName,
      seriesClassificationOptions,
      firstTimepointOnly
    );
  }

  if (taskDescriptionName === ORGAN_SEGMENTATION) {
    return createOrganSegmentationTaskDescription(
      data,
      anatomicalStructures,
      newTaskName,
      firstTimepointOnly
    );
  }

  if (taskDescriptionName === LESION_CLASSIFICATION) {
    return createLesionClassificationDescription(data, newTaskName, firstTimepointOnly);
  }

  if (taskDescriptionName === OBJECT_LABELLING) {
    return createObjectLabellingTaskDescription(
      data,
      anatomicalStructures,
      newTaskName,
      labellingOptions,
      firstTimepointOnly
    );
  }

  return null;
}
