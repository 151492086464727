import { isSeriesViewOnly } from "../../utils/isSeriesViewOnly";
import { SeriesListItemFragmentType } from "../useStudiesList";
import { TimepointType } from "./TimepointType";

export function getFirstSeriesNotViewOnly(
  { series: seriesList }: TimepointType,
  currentProjectId: number
): SeriesListItemFragmentType | undefined {
  return seriesList.find((series) => !isSeriesViewOnly(series, currentProjectId));
}
