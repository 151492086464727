import { MutationTuple, useMutation } from "@apollo/client";
import { gql } from "@apollo/client/core";

const MUTATION = gql`
  mutation UpdateTaskEnabled($id: Int!, $enabled: Boolean!) {
    task: update_task_by_pk(_set: { enabled: $enabled }, pk_columns: { id: $id }) {
      id
      enabled
    }
  }
`;

type Variables = {
  id: number;
  enabled: boolean;
};

type Data = {
  task: {
    id: number;
    enabled: boolean;
  };
};

export function useUpdateTaskEnabled(): MutationTuple<Data, Variables> {
  return useMutation<Data, Variables>(MUTATION);
}
