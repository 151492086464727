import { ClassificationsMapType } from "../../../../../../common/store/annotatePage/ClassificationsMapType";
import { CONSTRAINT_ONLY_ONE } from "../../../../../enums/TaskDescriptionEnums";
import { ClassificationValuesType } from "../../../../../types/TaskDescriptionType";
import { AnatomicalStructureOptionType } from "../AnatomicalStructureSelector/AnatomicalStructureOptionType";

export function areClassificationsComplete(
  classificationsMap: ClassificationsMapType,
  useAnatomySelector: boolean,
  anatomicalStructure?: AnatomicalStructureOptionType | null
): boolean {
  const maps = Object.values(classificationsMap);

  const classificationsComplete = maps.every(({ values, constraint }) => {
    if (constraint !== CONSTRAINT_ONLY_ONE) {
      throw new Error(`Constraint ${constraint} is not supported in areClassificationsComplete`);
    }

    const classifications = Object.keys(values);
    return classifications.some(
      (classification) => values[classification as ClassificationValuesType]
    );
  });

  return classificationsComplete && (anatomicalStructure !== null || !useAnatomySelector);
}
