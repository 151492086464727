import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { ReactComponent as BackIcon } from "../../../../../assets/svgs/Back.svg";
import { SvgIcon } from "../../../../../common/components/icons/SvgIcon";
import { previousTaskSelector } from "../../../../../common/store/annotatePage/taskSelector";
import { popPreviousTask } from "../../../../../common/store/annotatePage/taskSlice";
import { main } from "../../../../../common/theme/main";
import { useGoToTask } from "../../../../../Project/Layout/AnnotatePage/useGoToTask";

const Wrapper = styled.div<{ enabled: boolean }>`
  cursor: ${(props) => (props.enabled ? "pointer" : "default")};
`;

export function TaskBackButton(): JSX.Element {
  const {
    colors: {
      actionPrimary: { default: black, disabled },
    },
  } = main;
  const dispatch = useDispatch();
  const previousTask = useSelector(previousTaskSelector);
  const goToTask = useGoToTask();

  const handleClickBack = () => {
    if (!previousTask) {
      throw new Error("No previous task but click callback run!");
    }
    goToTask(previousTask);
    dispatch(popPreviousTask());
  };

  return (
    <Wrapper onClick={previousTask ? handleClickBack : undefined} enabled={!!previousTask}>
      <SvgIcon icon={BackIcon} size={16} color={previousTask ? black : disabled} />
    </Wrapper>
  );
}
