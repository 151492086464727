import React from "react";

import { getStatusColorType } from "../types/StatusColorTypes";
import { TaskStatusType } from "../types/StatusTypes";
import ProgressBar from "./ProgressBar";

interface StateProgressBarProps {
  state: TaskStatusType;
  max: number;
  value: number;
  width?: number;
  height?: number;
}

function StateProgressBar({
  max,
  value,
  state,
  width,
  height,
}: StateProgressBarProps): React.ReactElement<React.ProgressHTMLAttributes<HTMLProgressElement>> {
  const color = getStatusColorType(state);
  return <ProgressBar max={max} value={value} color={color} width={width} height={height} />;
}

export default StateProgressBar;
