import { ApolloCache } from "@apollo/client/core";

import { ContourListItemFragmentType } from "../fragments/RoiListItemFragment";
import { getContourCacheId } from "./getContourCacheId";
import { getRoiCacheId } from "./getRoiCacheId";

export function deleteContoursFromCache<T>(
  roiId: number,
  contours: ContourListItemFragmentType[],
  cache: ApolloCache<T>
): void {
  const contourIds = contours.map(({ id }) => id);
  cache.modify({
    id: getRoiCacheId(roiId, cache),
    fields: {
      contours(contourRefs, { readField }) {
        return contourRefs.filter(
          (contourRef: never) => !contourIds.includes(readField("id", contourRef) as number)
        );
      },
    },
  });

  for (const id of contourIds) {
    const contourCacheId = getContourCacheId(id, cache);
    cache.evict({ id: contourCacheId });
  }
}
