import { TaskContext } from "nota-predict-web/src/Annotate/TaskContext";
import { useOkForm } from "nota-predict-web/src/common/components/Dialog/useOkForm";
import { ErrorLabel } from "nota-predict-web/src/common/components/input/ErrorLabel";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import { useGetActiveSeries } from "../hooks/useGetActiveSeries";
import { getIsIgnoredSeries } from "./QcForm.utils";
import { QcHelpButton } from "./QcHelpButton";
import { QcTabsContainer } from "./QcTabsContainer";
import { useQualityControlContext } from "./QualityControlProvider";
import { QcValidationError } from "./useValidatedQualityControlSchema";

const FullHeightContainer = styled.div`
  height: 100%;
`;

export function QualityControlPanel(): JSX.Element {
  const { task: currentTask } = useContext(TaskContext);

  const { schema, error } = useQualityControlContext();

  const [didDismissDialog, setDidDismissDialog] = useState(false);
  const [showErrorDialog, { dialog }] = useOkForm({
    message:
      error instanceof QcValidationError
        ? error.message
        : `The QC form could not be displayed, please contact support.`,
    title: "Invalid QC schema",
    width: 600,
    onOkCallback: () => setDidDismissDialog(true),
  });

  useEffect(() => {
    if (error instanceof QcValidationError) {
      showErrorDialog(true);
    }
  }, [error, showErrorDialog]);

  const {
    activeScopeState: [activeScope],
  } = useQualityControlContext();

  const activeSeries = useGetActiveSeries();

  if (getIsIgnoredSeries(activeScope, activeSeries)) {
    return (
      <FullHeightContainer>
        <ErrorLabel showIcon={false} variant="neutral">
          {`The selected series type is not available for Quality Control task work. 
          If this is unexpected, please contact your administrator for more details.`}
        </ErrorLabel>
      </FullHeightContainer>
    );
  }

  if (error)
    return (
      <FullHeightContainer>
        <ErrorLabel showIcon={false}>{error.message}</ErrorLabel>
        {!didDismissDialog && dialog}
      </FullHeightContainer>
    );

  return (
    <>
      <QcHelpButton task={currentTask} />
      {!!schema && <QcTabsContainer />}
      {!didDismissDialog && dialog}
    </>
  );
}
