import React from "react";
import styled from "styled-components";

import { Label } from "../../../common/components/Label";
import { main } from "../../../common/theme/main";
import { TaskType } from "../Manage/hooks/TaskType";

const Wrapper = styled.div`
  max-width: 160px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

interface TaskLabelProps {
  task: TaskType;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

export function TaskLabel({ task, onClick = undefined }: TaskLabelProps): JSX.Element {
  const { name } = task;
  const {
    neutral: { white },
    accent: { accent1 },
  } = main.colors;

  return (
    <Label background={accent1} color={white} onClick={onClick}>
      <Wrapper title={name}>{name}</Wrapper>
    </Label>
  );
}
