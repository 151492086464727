import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AnatomicalStructuresFragmentType } from "../../../Annotate/components/AnatomicalStructuresFragment";
import { AnatomicalStructureOptionType } from "../../../Annotate/components/Annotate/page/AnnotationPanel/AnatomicalStructureSelector/AnatomicalStructureOptionType";
import { LesionListItemFragmentType } from "../../../Annotate/components/Annotate/page/AnnotationPanel/fragments/LesionListItemFragment";
import { RoiListItemFragmentType } from "../../../Annotate/components/Annotate/page/AnnotationPanel/fragments/RoiListItemFragment";
import { ClassificationsMapType } from "./ClassificationsMapType";
import { getClassificationsDefinitions } from "./utils/getClassificationsDefinitions";

export type ClassificationsDefinitionsType = {
  classificationsMapProxy: ClassificationsMapType;
  selectedAnatomicalStructure: AnatomicalStructureOptionType | null;
  initialAnatomicalStructure: AnatomicalStructureOptionType | null;
  classificationsMapInitial: ClassificationsMapType;
  useAnatomySelector: boolean;
  editing: boolean;
};

type State = {
  lesionsClassificationsDefinitions: Record<number, ClassificationsDefinitionsType>;
};

const initialState: State = {
  lesionsClassificationsDefinitions: {},
};

export const classificationsSlice = createSlice({
  name: "classifications",
  initialState,
  reducers: {
    setLesionClassificationsDefinitions(
      state,
      action: PayloadAction<{
        lesion: LesionListItemFragmentType;
        classificationsMapTemplate: ClassificationsMapType;
        useAnatomySelector: boolean;
        anatomicalStructures: AnatomicalStructuresFragmentType[] | undefined;
      }>
    ) {
      const { lesion, classificationsMapTemplate, useAnatomySelector, anatomicalStructures } =
        action.payload;
      const { lesionsClassificationsDefinitions } = state;
      const { id, classifications, location } = lesion;
      state.lesionsClassificationsDefinitions[id] = getClassificationsDefinitions(
        classifications,
        classificationsMapTemplate,
        useAnatomySelector,
        anatomicalStructures,
        lesionsClassificationsDefinitions[id],
        location
      );
    },
    clearLesionClassificationsDefinitions(
      state,
      action: PayloadAction<{
        lesionId: number;
      }>
    ) {
      const { lesionId } = action.payload;
      delete state.lesionsClassificationsDefinitions[lesionId];
    },
    setLesionClassificationsProxyMap(
      state,
      action: PayloadAction<{
        classificationsMap: ClassificationsMapType;
        lesionId: number;
      }>
    ) {
      const { classificationsMap, lesionId } = action.payload;
      if (!state.lesionsClassificationsDefinitions[lesionId]) {
        throw new Error(`No classification definition for lesion with id ${lesionId}.`);
      }
      state.lesionsClassificationsDefinitions[lesionId].classificationsMapProxy =
        classificationsMap;
    },
    setLesionSelectedAnatomicalStructure(
      state,
      action: PayloadAction<{
        anatomicalStructure: AnatomicalStructureOptionType | null;
        lesionId: number;
      }>
    ) {
      const { anatomicalStructure, lesionId } = action.payload;
      if (!state.lesionsClassificationsDefinitions[lesionId]) {
        throw new Error(`No classification definition for lesion with id ${lesionId}.`);
      }
      state.lesionsClassificationsDefinitions[lesionId].selectedAnatomicalStructure =
        anatomicalStructure;
    },
    setLesionEditing(
      state,
      action: PayloadAction<{
        editing: boolean;
        lesionId: number;
      }>
    ) {
      const { editing, lesionId } = action.payload;
      if (!state.lesionsClassificationsDefinitions[lesionId]) {
        throw new Error(`No classification definition for lesion with id ${lesionId}.`);
      }
      state.lesionsClassificationsDefinitions[lesionId].editing = editing;
    },
    clearClassificationDefinitions(state) {
      state.lesionsClassificationsDefinitions = {};
    },
  },
});

export const {
  setLesionClassificationsDefinitions,
  setLesionClassificationsProxyMap,
  setLesionSelectedAnatomicalStructure,
  setLesionEditing,
  clearClassificationDefinitions,
  clearLesionClassificationsDefinitions,
} = classificationsSlice.actions;
