import React from "react";
import Select, { ValueType } from "react-select";
import { NamedProps } from "react-select/src/Select";

import { getSelectStyle } from "../../../common/components/input/getSelectStyle";
import { OptionType } from "./GenericMultiSelect";

interface GenericSingleSelectProps<T>
  extends Omit<NamedProps<OptionType<T>>, "isMulti" | "value" | "options" | "onChange"> {
  values: T[];
  selected: T | null;
  onSelectedChanged: (value: T | null) => void;
  getOption: (value: T) => OptionType<T>;
  compareValues?: (valueA: T, valueB: T) => boolean;
}

export function GenericSingleSelect<T>({
  values,
  selected,
  onSelectedChanged,
  getOption,
  compareValues = defaultCompare,
  styles: stylesOverride,
  ...other
}: GenericSingleSelectProps<T>): JSX.Element {
  const options = values.map(getOption);

  const value = selected ? options.find(({ value }) => compareValues(value, selected)) : null;

  const styles = getSelectStyle<OptionType<T>, false>(stylesOverride);

  const handleOnChange = (value: ValueType<OptionType<T>, false>) => {
    const selected = value ? value.value : null;
    onSelectedChanged(selected);
  };

  return (
    <Select
      hideSelectedOptions={true}
      {...other}
      styles={styles}
      value={value}
      options={options}
      onChange={handleOnChange}
    />
  );
}

function defaultCompare<T>(valueA: T, valueB: T): boolean {
  return valueA === valueB;
}
