import React from "react";
import styled from "styled-components";

import { TumourResponseType } from "../../../../../Analysis/common/types/TumourResponseType";
import { tumourResponseColors } from "../../../../../Analysis/common/utils/tumourResponseColors";
import { main } from "../../../../../common/theme/main";

const Wrapper = styled.div`
  background: ${(props) => props.theme.colors.background.secondary};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  font-weight: 600;
`;

const ResponseWrapper = styled.div<{ background?: string; color?: string }>`
  background: ${({ background }) => background};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  height: 100%;
  color: ${({ color }) => color};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  padding: 2px 3px;
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: ${(props) => props.theme.colors.neutral.neutral3};
  font-size: 10px;
  padding: 2px 6px;
`;

interface TumourResponseLabelProps {
  isBaseline: boolean;
  response: TumourResponseType | undefined;
  extraInfo: string | undefined;
}

export function TumourResponseLabel({
  isBaseline,
  response,
  extraInfo,
}: TumourResponseLabelProps): JSX.Element {
  if (!isBaseline && !response) {
    return <></>;
  }

  const background = response ? tumourResponseColors[response] : undefined;
  const color = isBaseline ? main.colors.actionPrimary.default : "white";
  const label = isBaseline ? "BL" : response;

  return (
    <Wrapper>
      {(response || isBaseline) && (
        <ResponseWrapper background={background} color={color}>
          {label}
        </ResponseWrapper>
      )}
      {extraInfo && <TextWrapper>{extraInfo}</TextWrapper>}
    </Wrapper>
  );
}
