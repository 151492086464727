import { FetchResult, useApolloClient } from "@apollo/client";
import { useCallback } from "react";
import { useSelector } from "react-redux";

import { useCurrentUser } from "../../../../../../../../common/contexts/UserContext/useCurrentUser";
import {
  drawMissingRoisOptionSelector,
  recistTaskSelector,
} from "../../../../../../../../common/store/annotatePage/taskSelector";
import { ToolType } from "../../../../../../../../cornerstone/ToolType";
import {
  ClassificationValuesType,
  TimepointOptionsType,
} from "../../../../../../../types/TaskDescriptionType";
import { LesionListItemFragmentType } from "../../../../AnnotationPanel/fragments/LesionListItemFragment";
import { RoiListItemFragmentType } from "../../../../AnnotationPanel/fragments/RoiListItemFragment";
import { getPreviousTimepoints } from "../../../../AnnotationPanel/hooks/Timepoint/getPreviousTimepoints";
import { useTimepointsNonViewOnlySeries } from "../../../../AnnotationPanel/hooks/Timepoint/useTimepointsNonViewOnlySeries";
import { useGetTimepointOptionsForLesion } from "../../../../AnnotationPanel/hooks/timepointOptions/useGetTimepointOptionsForLesion";
import { getVariables as getDeleteRoiVariables } from "../../../../AnnotationPanel/hooks/useDeleteRois";
import { Data, useInsertDeleteRois } from "../../../../AnnotationPanel/hooks/useInsertDeleteRois";
import { useInsertLesionClassifications } from "../../../../AnnotationPanel/hooks/useInsertLesionClassification";
import { getVariables as getInsertRoiVariables } from "../../../../AnnotationPanel/hooks/useInsertRois";
import { useLesionListQueryInput } from "../../../../AnnotationPanel/hooks/useLesionListQueryInput";
import { useUpdateTaskInProgress } from "../../../../hooks/useUpdateTaskInProgress";
import { useGetIsLesionFromCurrentTask } from "../../../useGetIsLesionFromCurrentTask";
import { NEW_FINDING_ROI_ID } from "../../toolController/NewFindingRoiId";
import { updateToolDataRoiId } from "../../toolController/updateToolDataRoiId";
import { ToolCallbackDataType } from "../../types/ToolCallbackDataType";
import { isRoiEmpty } from "../utils/isRoiEmpty";

type ReturnType = (
  data: ToolCallbackDataType<unknown>,
  lesion: LesionListItemFragmentType,
  seriesId: number
) => Promise<{
  roiId: number;
  toolData: ToolCallbackDataType<unknown>["toolData"];
}>;

export function useHandleNewRoiDrawn(toolType: ToolType): ReturnType {
  const { cache } = useApolloClient();
  const [insertDeleteRois] = useInsertDeleteRois();
  const { id: userId } = useCurrentUser();
  const isRecist = useSelector(recistTaskSelector);
  const timepoints = useTimepointsNonViewOnlySeries();
  const lesionListQueryInput = useLesionListQueryInput();
  const drawMissingRoisOption = useSelector(drawMissingRoisOptionSelector);
  const getTimepointOptionsForLesion = useGetTimepointOptionsForLesion();
  const [insertLesionClassifications] = useInsertLesionClassifications();
  const getIsLesionFromCurrentTask = useGetIsLesionFromCurrentTask();
  const updateTaskInProgress = useUpdateTaskInProgress();

  // TODO: A bunch of these need to be memoized.

  const handleNewRoiDrawn = async (
    { imageId }: ToolCallbackDataType<unknown>,
    lesion: LesionListItemFragmentType,
    seriesId: number
  ) => {
    const { id: lesionId, rois: existingRois } = lesion;

    const isLesionFromCurrentTask = getIsLesionFromCurrentTask(lesion);

    if (!timepoints) {
      throw new Error("Unable to retrieve timepoints when handling new drawn roi");
    }

    if (!drawMissingRoisOption) {
      throw new Error("drawMissingRoisOption is not defined when handling new drawn roi");
    }

    const { classifications } = isLesionFromCurrentTask
      ? drawMissingRoisOption
      : { classifications: [] };

    const previousTimepoints = getPreviousTimepoints(timepoints, seriesId);
    const previousSeriesIds = previousTimepoints.flatMap(({ series }) =>
      series.map(({ id }) => id)
    );

    const roisToDelete = getRoisToDelete(existingRois, previousSeriesIds);

    const seriesIdsToCreate = getSeriesIdsToInsert(existingRois, previousSeriesIds, roisToDelete);

    const options = getTimepointOptionsForLesion(lesion);
    const isBaselineCreated = previousTimepoints
      .filter(({ series }) => series.some(({ id }) => seriesIdsToCreate.includes(id)))
      .some(({ index }) => index === 0);

    const { insertClassifications, deleteClassifications } = getTriggeredLesionClassifications(
      options,
      isBaselineCreated,
      classifications
    );
    if (insertClassifications.length > 0 || deleteClassifications.length > 0) {
      await insertLesionClassifications(lesionId, insertClassifications, deleteClassifications);
    }

    const deleteVariables = getDeleteRoiVariables(roisToDelete);

    const insertVariables = getInsertRoiVariables(
      [
        ...seriesIdsToCreate.map((seriesId) => ({
          seriesId,
          lesionId,
          createdBy: userId,
          classificationValues: classifications,
        })),
        {
          seriesId,
          lesionId,
          createdBy: userId,
        },
      ],
      isRecist,
      cache
    );

    const result = await insertDeleteRois({
      variables: { ...deleteVariables, ...insertVariables },
    });
    await updateTaskInProgress();

    const newRoiId = getNewRoiId(result, seriesId);

    const toolData = updateToolDataRoiId(imageId, toolType, NEW_FINDING_ROI_ID, newRoiId);

    return { roiId: newRoiId, toolData };
  };

  return useCallback(handleNewRoiDrawn, [
    // This deps list is not up to date. Don't change without verifying which properties may or may not cause re-renders.
    // --B
    cache,
    insertDeleteRois,
    userId,
    isRecist,
    timepoints,
    lesionListQueryInput,
    drawMissingRoisOption,
  ]);
}

function getRoisToDelete(
  existingRois: RoiListItemFragmentType[],
  previousSeriesIds: number[]
): RoiListItemFragmentType[] {
  return existingRois
    .filter(({ series: { id } }) => previousSeriesIds.includes(id))
    .filter(isRoiEmpty);
}

function getSeriesIdsToInsert(
  existingRois: RoiListItemFragmentType[],
  previousSeriesIds: number[],
  roisToDelete: RoiListItemFragmentType[]
): number[] {
  const seriesIdsWithoutRoi = previousSeriesIds.filter(
    (seriesId) => !existingRois.some(({ series: { id } }) => seriesId === id)
  );

  return [...seriesIdsWithoutRoi, ...roisToDelete.map(({ series: { id } }) => id)];
}

function getNewRoiId(result: FetchResult<Data>, seriesId: number): number {
  const { data } = result;
  if (!data) {
    throw new Error("Failed to insert roi in useHandleNewRoiDrawn");
  }

  const {
    insert_roi: { returning: newRois },
  } = data;
  if (newRois.length === 0) {
    throw new Error("Inserted no new rois in useHandleNewRoiDrawn");
  }

  const newRoi = newRois.find(({ series: { id } }) => id === seriesId);
  if (!newRoi) {
    throw new Error("Failed to create new roi in useHandleNewRoiDrawn");
  }

  const { id: newRoiId } = newRoi;

  return newRoiId;
}

function getTriggeredLesionClassifications(
  options: TimepointOptionsType[],
  isBaselineCreated: boolean,
  roiClassifications: ClassificationValuesType[]
): {
  insertClassifications: ClassificationValuesType[];
  deleteClassifications: ClassificationValuesType[];
} {
  if (!isBaselineCreated) {
    return { insertClassifications: [], deleteClassifications: [] };
  }

  const createdOptions = options.filter(({ rule }) => roiClassifications.includes(rule));

  const insertClassifications = [
    ...new Set(
      createdOptions.flatMap(({ triggersLesionClassification }) => triggersLesionClassification)
    ),
  ];

  const deleteClassifications = [
    ...new Set(
      createdOptions.flatMap(({ preventsLesionClassification }) => preventsLesionClassification)
    ),
  ];

  return { insertClassifications, deleteClassifications };
}
