import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { QueryResult } from "@apollo/client/react/types/types";
import { useMemo } from "react";

import {
  getLesionQueryCondition,
  GetLesionsListDataType,
  GetLesionsListVariablesType,
  getRoiQueryCondition,
  LESION_LIST_ITEM_FRAGMENT,
  LesionsListQueryInput,
} from "../fragments/LesionListItemFragment";
import { DEBUG_LESION_LIMIT } from "./debugUtils";
import { useLesionListQueryInput } from "./useLesionListQueryInput";
import { LesionListItemPredicateType } from "./useTaskLesionListOptions";

const QUERY = gql`
  query GetLesionsList(
    $skipRecist: Boolean!
    $lesionCondition: lesion_bool_exp!
    $roiCondition: roi_bool_exp!
  ) {
    lesions: lesion(limit: ${DEBUG_LESION_LIMIT}, where: $lesionCondition) {
      ...LesionListItem
    }
  }
  ${LESION_LIST_ITEM_FRAGMENT}
`;

export function useLesionsList(
  filter?: LesionListItemPredicateType
): Pick<
  QueryResult<GetLesionsListDataType, GetLesionsListVariablesType>,
  "data" | "loading" | "error"
> {
  const lesionListQueryInput = useLesionListQueryInput();

  const variables = getVariables(lesionListQueryInput);

  const { data, loading, error } = useQuery<GetLesionsListDataType, GetLesionsListVariablesType>(
    QUERY,
    { variables }
  );

  const {
    taskContext: {
      task: { id: taskId },
    },
  } = lesionListQueryInput;

  return useMemo(() => {
    const lesions = data?.lesions.filter((lesion) => {
      const isTaskEnabled = lesion.task?.enabled ?? true;
      return isTaskEnabled && (!filter || filter(lesion, taskId));
    });

    return {
      data: lesions ? { lesions } : undefined,
      loading,
      error,
    };
  }, [data, filter, taskId]);
}

export function getVariables({
  taskContext,
  includeOtherLesions,
  includePredictedLesions,
  skipRecist,
}: LesionsListQueryInput): GetLesionsListVariablesType {
  const {
    task: { projectId, restrictedCohorts },
  } = taskContext;

  const lesionCondition = getLesionQueryCondition(
    taskContext,
    includeOtherLesions,
    includePredictedLesions
  );

  const roiCondition = getRoiQueryCondition(
    projectId,
    restrictedCohorts.map(({ id }) => id)
  );

  return {
    lesionCondition,
    roiCondition,
    skipRecist,
    includeOtherLesions,
    includePredictedLesions,
  };
}

export { QUERY as GET_LESIONS_LIST };
