import {
  ClassificationModeType,
  ClassificationObjectsType,
  ClassificationsType,
  ClassificationValuesType,
} from "../../../../Annotate/types/TaskDescriptionType";

export type ClassificationModeToPropertiesMapType = Partial<
  Record<ClassificationModeType, ClassificationPropertiesMapType>
>;

export type ClassificationPropertiesMapType = Partial<
  Record<ClassificationValuesType, { displayName: string; readOnly: boolean }>
>;

export function getClassificationModeToPropertiesMap(
  classifications: ClassificationsType[]
): ClassificationModeToPropertiesMapType {
  const classificationModesToPropertiesMap: ClassificationModeToPropertiesMapType = {};
  classifications.forEach(({ values: classificationGroupings, mode }) => {
    if (!mode) {
      throw new Error("The classifications should have a mode!");
    }
    const classificationModeProperties: ClassificationPropertiesMapType = {};
    (classificationGroupings as ClassificationsType[]).forEach(({ values }) => {
      (values as ClassificationObjectsType[]).forEach(({ value, displayName, readOnly }) => {
        if (classificationModeProperties[value]) {
          throw new Error(
            `There are duplicate classifications for mode ${mode} with value ${value}`
          );
        }

        if (!displayName) {
          return;
        }

        classificationModeProperties[value] = {
          displayName,
          readOnly,
        };
      });
    });
    classificationModesToPropertiesMap[mode] = classificationModeProperties;
  });

  return classificationModesToPropertiesMap;
}
