import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { MutationTuple } from "@apollo/client/react/types/types";

import { CohortType } from "../../../../common/types/CohortType";
import { COHORT_FRAGMENT } from "../../../../Project/contexts/Project/CohortFragment";
import { updateCohortsInCache } from "./updateCohortsInCache";

const MUTATION = gql`
  mutation UpdateCohort($id: Int!, $name: String!, $metadata: jsonb!) {
    cohort: update_label_by_pk(_set: { name: $name, metadata: $metadata }, pk_columns: { id: $id },) {
      ...Cohort
    }
  }
  ${COHORT_FRAGMENT}
`;

type Data = { cohort: CohortType };

type Variables = { id: number; name: string; metadata: { color: string } };

export function useUpdateCohort(): MutationTuple<Data, Variables> {
  return useMutation<Data, Variables>(MUTATION, {
    update(cache, { data }) {
      if (!data) {
        throw new Error("Something went wrong updating the cache after updating a cohort");
      }

      const { cohort } = data;

      updateCohortsInCache(cohort, cache);
    },
    refetchQueries: ["GetAllCohorts"],
  });
}
