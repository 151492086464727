import { gql } from "@apollo/client";

import { PROJECT_DETAILS_FRAGMENT } from "../../Project/contexts/Project/ProjectDetailsFragment";

export const GET_ALL_PROJECT_DETAILS = gql`
  query GetProject {
    project {
      ...ProjectDetails
    }
  }
  ${PROJECT_DETAILS_FRAGMENT}
`;
