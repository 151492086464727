import React, { FC } from "react";

import { FormProps } from "../../../../common/components/Dialog/FormProps";
import { useDialogReturnType } from "../../../../common/components/Dialog/useDialog";
import { useFormDialog } from "../../../../common/components/Dialog/useFormDialog";
import { TaskType } from "../hooks/TaskType";
import { EditTaskWizard } from "./EditTaskWizard";

export function useEditTaskForm(props: EditTaskFormProps): useDialogReturnType {
  return useFormDialog({
    children: EditTaskForm,
    label: "Edit Task",
    showCloseButton: true,
    props,
  });
}

interface EditTaskFormProps {
  task: TaskType | undefined;
}

export const EditTaskForm: FC<FormProps<EditTaskFormProps>> = ({
  onSubmit,
  onCancel,
  props: { task },
}: FormProps<EditTaskFormProps>) => {
  if (!task) {
    return <>No task selected.</>;
  }

  const handleSubmit = () => {
    onSubmit();
  };

  const handleCancel = () => {
    onCancel();
  };

  return <EditTaskWizard task={task} onSubmit={handleSubmit} onCancel={handleCancel} />;
};
