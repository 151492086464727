import { combineReducers } from "@reduxjs/toolkit";

import { classificationsSlice } from "./classificationsSlice";
import { cornerstoneInfoSlice } from "./cornerstone/cornerstoneInfoSlice";
import { selectionSlice } from "./selectionSlice";
import { taskSlice } from "./taskSlice";

export const reducer = combineReducers({
  selection: selectionSlice.reducer,
  task: taskSlice.reducer,
  cornerstoneInfo: cornerstoneInfoSlice.reducer,
  classifications: classificationsSlice.reducer,
});
