import React from "react";

import { PatientFragmentType } from "../../../../../common/components/StudyTable/usePatientStudies";
import { PatientContextProvider } from "./PatientContextProvider";
import { PatientViewer } from "./PatientViewer";

interface SeriesViewerWrapperProps {
  patient: PatientFragmentType;
  setPatient: (patient: PatientFragmentType | undefined) => void;
}

export function PatientViewerWrapper({
  patient,
  setPatient,
}: SeriesViewerWrapperProps): JSX.Element {
  const { id } = patient;

  return (
    <PatientContextProvider patientId={id}>
      <PatientViewer onClose={() => setPatient(undefined)} />
    </PatientContextProvider>
  );
}
