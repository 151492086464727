import { capitalizeFirstLetter } from "nota-predict-web/src/Analysis/common/utils/captatlizeFirstLetter";
import { FC, useEffect, useState } from "react";
import styled from "styled-components";

import { ActionButtonsWrapper } from "../../../../../../../../common/components/Dialog/Form/ActionButtonsWrapper";
import { Wrapper } from "../../../../../../../../common/components/Dialog/Form/Wrapper";
import { FormProps } from "../../../../../../../../common/components/Dialog/FormProps";
import { useDialogReturnType } from "../../../../../../../../common/components/Dialog/useDialog";
import { useFormDialog } from "../../../../../../../../common/components/Dialog/useFormDialog";
import { InputButton } from "../../../../../../../../common/components/input/InputButton";
import { main } from "../../../../../../../../common/theme/main";
import {
  ERROR,
  SUCCESS,
  TaskStatusType,
  WARNING,
} from "../../../../../../../../common/types/StatusTypes";
import { TaskCheckType } from "../../../../../../../types/TaskDescriptionType";
import { allTaskCheckComponents } from "../allSaveCheckComponents";
import { getOverallTaskStatus } from "../getResultsStatus";
import { flattenTaskResults, TaskCheckResults } from "../useTaskSaveChecks";
import { getLesionChecksMessage } from "../utils/getLesionChecksMessage";
import { taskHasNoErrors } from "../utils/taskHasNoErrors";
import { TaskCheckRow } from "./CheckRow";
import { LesionCheckResults } from "./LesionCheckResults";

const ERRORS_WIDTH = 500;
const NO_ERRORS_WIDTH = 300;

const InfoText = styled.div`
  text-align: left;
`;

const ErrorWrapper = styled.div`
  max-height: 70vh;
  padding-right: 16px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: 12px;
`;

export function useCantSaveTaskDialog(props: CantSaveTaskDialogProps): useDialogReturnType {
  return useFormDialog({
    children: CantSaveTaskDialog,
    label: PASSED_DIALOG_TITLE,
    showCloseButton: false,
    reactModalProps: {
      shouldCloseOnOverlayClick: false,
      shouldCloseOnEsc: false,
    },
    props,
  });
}

interface CantSaveTaskDialogProps {
  results: TaskCheckResults;
  onConfirmSave: () => void;
  entity?: TaskDialogEntity;
  multiple?: boolean;
}

const CantSaveTaskDialog: FC<FormProps<CantSaveTaskDialogProps>> = ({
  onCancel,
  onSetTitle,
  props: { results, onConfirmSave, entity = "label", multiple = false },
}: FormProps<CantSaveTaskDialogProps>) => {
  const [width, setWidth] = useState<number>(NO_ERRORS_WIDTH);

  const handleConfirmSave = async () => {
    onConfirmSave();
  };

  const { lesions = [], task } = results ?? {};

  const taskResults = flattenTaskResults(results);

  useEffect(() => {
    if (!onSetTitle) {
      throw new Error("No setTitle callback defined in SaveDialog");
    }

    const status = getOverallTaskStatus(taskResults);
    const title = getDialogTitle(status, multiple || lesions.length > 1, entity);

    onSetTitle(title);

    const allChecksPassed = taskResults.every(({ status }) => status === SUCCESS);
    const width = allChecksPassed ? NO_ERRORS_WIDTH : ERRORS_WIDTH;
    setWidth(width);
  }, [taskResults]);

  const handleRequestClose = () => {
    onCancel();
  };

  const showIgnoreButton = taskHasNoErrors(results);

  const message = getLesionChecksMessage(lesions, false);

  return (
    <Wrapper width={width}>
      {task
        .filter(({ status }) => status !== SUCCESS)
        .map((result, index) => {
          const { type } = result;
          const actions = allTaskCheckComponents[type as TaskCheckType];
          return (
            <TaskCheckRow
              key={index}
              actions={actions}
              onRequestClose={handleRequestClose}
              {...result}
            />
          );
        })}
      {lesions.length > 0 && <InfoText>{message}</InfoText>}

      <ErrorWrapper>
        {lesions.map((lesionResult, index) => (
          <LesionCheckResults
            key={index}
            result={lesionResult}
            onRequestClose={handleRequestClose}
          />
        ))}
      </ErrorWrapper>

      <ActionButtonsWrapper>
        {showIgnoreButton && (
          <InputButton
            autoFocus
            type="submit"
            value={"Ignore"}
            background={main.colors.neutral.white}
            color={main.colors.neutral.black}
            onClick={handleConfirmSave}
          />
        )}
      </ActionButtonsWrapper>
    </Wrapper>
  );
};

const PASSED_DIALOG_TITLE = "Confirm Save";
const DialogEntities = ["task", "lesion", "label", "report"] as const;
export type TaskDialogEntity = typeof DialogEntities[number];

function getDialogTitle(
  status: TaskStatusType,
  multiple: boolean,
  entity: TaskDialogEntity = "label"
): string {
  const upperCaseEntity = capitalizeFirstLetter(entity);
  switch (status) {
    case SUCCESS:
      return PASSED_DIALOG_TITLE;
    case WARNING:
    case ERROR:
      return `Incomplete ${upperCaseEntity}${multiple ? "s" : ""}`;
    default:
      throw new Error(`Unsupported status ${status} in getDialogTitle for SaveDialog`);
  }
}

