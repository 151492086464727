export const dateFilters = ["ALL_TIME", "MONTH", "WEEK", "CUSTOM"] as const;
export const DEFAULT_FILTER = "ALL_TIME";
export type DateFilterType = typeof dateFilters[number];

export const dateFilterDisplayName: Record<DateFilterType, string> = {
  ALL_TIME: "All Time",
  MONTH: "Month",
  WEEK: "Last 7 Days",
  CUSTOM: "Custom",
};
