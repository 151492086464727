import { UserType } from "../../../../../common/types/UserType";
import { TaskProgressType } from "../../../TaskWorklist/TaskProgressType";
import { TaskType } from "../../hooks/TaskType";
import { getMinimumTaskAssignmentProgress } from "../TaskLabel";

export function includeTaskByUserAndProgress(
  task: TaskType | undefined,
  filteredProgresses: (TaskProgressType | undefined)[],
  filteredUsers: (UserType | undefined)[]
): boolean {
  if (!task) {
    return true;
  }

  const { taskAssignments } = task;

  const progress = getMinimumTaskAssignmentProgress(taskAssignments);
  const excludeTaskByProgress =
    filteredProgresses.length > 0 && !filteredProgresses.includes(progress);

  if (excludeTaskByProgress) {
    return false;
  }

  const assignedUserIds = taskAssignments.map(({ user: { id } }) => id);
  const filteredUserIds = filteredUsers.flatMap((user) => {
    if (!user) {
      return [];
    }

    const { id } = user;
    return [id];
  });

  const excludeTaskByUser =
    filteredUsers.length > 0 &&
    !(assignedUserIds.length === 0 && filteredUsers.includes(undefined)) &&
    !assignedUserIds.some((id) => filteredUserIds.includes(id));
  return !excludeTaskByUser;
}

export function includeTaskByName(task: TaskType | undefined, filteredTasks: TaskType[]): boolean {
  if (!task) {
    return true;
  }

  const { id } = task;

  const excludeTaskByTask =
    filteredTasks.length > 0 && !filteredTasks.map(({ id }) => id).includes(id);

  return !excludeTaskByTask;
}
