import { ViewerConfigType } from "../../../../../../common/store/annotatePage/ViewerConfigType";
import { TimepointType } from "../../AnnotationPanel/hooks/Timepoint/TimepointType";
import { getNextSeries } from "./getNextSeries";
import { getNextViewerConfigId } from "./getNextViewerConfigId";
import { getViewerConfigFromSeriesId } from "./getViewerConfigFromSeriesId";

export function getNextViewerConfig(
  timepoints: TimepointType[],
  viewerConfigs: ViewerConfigType[],
  projectId: number
): ViewerConfigType | null {
  const nextSeriesId = getNextSeries(timepoints, viewerConfigs, projectId);
  if (!nextSeriesId) {
    return null;
  }

  const nextId = getNextViewerConfigId(viewerConfigs);
  return getViewerConfigFromSeriesId(nextSeriesId, nextId);
}
