import React, { ComponentType } from "react";

import { useConfirmDeletePatientsDialog } from "./useConfirmDeletePatientsDialog";
import { useDeletePatients } from "./useDeletePatients";

interface DeletePatientWrapperProps {
  children: ComponentType<{
    onClick: () => void;
  }>;
  patientIds: number[];
  onDeleted: () => void;
}

export function DeletePatientWrapper({
  children: Component,
  patientIds,
  onDeleted,
}: DeletePatientWrapperProps): JSX.Element {
  const [deletePatients] = useDeletePatients();

  const handleDeletePatients = async () => {
    if (patientIds.length === 0) {
      return;
    }
    await deletePatients({ variables: { patientIds } });
    onDeleted();
  };

  const [showDialog, { dialog }] = useConfirmDeletePatientsDialog(handleDeletePatients);

  const handleClick = () => {
    showDialog(true);
  };

  return (
    <>
      {dialog}
      <Component onClick={handleClick} />
    </>
  );
}
