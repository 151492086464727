import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { QueryResult } from "@apollo/client/react/types/types";

import type { CohortType } from "../../../../common/types/CohortType";
import { COHORT_FRAGMENT } from "../../../../Project/contexts/Project/CohortFragment";

export type Data = {
  cohorts: CohortType[];
};

export type Variables = undefined;

export const QUERY = gql`
  query GetGlobalCohorts {
    cohorts: label(where: { type_name: { _eq: "COHORT" }, project_id: { _is_null: true } }) {
      ...Cohort
    }
  }
  ${COHORT_FRAGMENT}
`;

export function useGlobalCohorts(): QueryResult<Data, Variables> {
  return useQuery<Data, Variables>(QUERY);
}
