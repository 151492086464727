import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { RetryLink } from "@apollo/client/link/retry";

import { ellipseTypePolicy } from "./typePolicies/ellipseTypePolicy";
import { instanceWadoUrlTypePolicy } from "./typePolicies/instanceWadoUrlTypePolicy";
import { roiTypePolicy } from "./typePolicies/roiTypePolicy";

export function createHasuraClient(token: string): ApolloClient<NormalizedCacheObject> {
  const uri = window._env_.REACT_APP_GRAPHQL_URL_NOTA_ANNOTATE;

  const annotateHttpLink = createHttpLink({
    uri,
  });

  const authLink = setContext(async (_, { headers }) => {
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    };
  });

  const retryLink = new RetryLink();

  return new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        query_root: { queryType: true },
        ...instanceWadoUrlTypePolicy,
        ...roiTypePolicy,
        ...ellipseTypePolicy,
      },
    }),
    link: from([retryLink, authLink, annotateHttpLink]),
  });
}
