import SearchTextField from "nota-predict-web/src/common/components/input/SearchTextField";
import { main } from "nota-predict-web/src/common/theme/main";
import React from "react";
import styled from "styled-components";
import { useDebounce } from "use-debounce";

const SearchWrapper = styled.div`
  padding: 8px 12px;
`;

const Search = styled(SearchTextField)`
  padding: 4px 12px 4px 40px;
`;

const MatchCount = styled.div`
  padding: 3px 0;
  font-size: 11px;
  margin: 2px 6px 0;
  color: ${main.colors.neutral.neutral2};
`;

export const SeriesStudyFilter = ({
  filter,
  setFilter,
  matchesCount = -1,
}: {
  filter: string;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
  matchesCount: number;
}) => {
  const [debouncedSetFilter] = useDebounce(setFilter, 500, {
    leading: true,
  });

  const handleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    debouncedSetFilter(event.target.value);
  };

  const countText = matchesCount === 0 ? "No matching series" : `${matchesCount} matching series`;

  return (
    <SearchWrapper>
      <Search value={filter} onChange={handleChange} placeholder={"Filter series"} />
      {matchesCount >= 0 && <MatchCount>{countText}</MatchCount>}
    </SearchWrapper>
  );
};
