import { MUTATION_EGFR } from "../../../Analysis/common/types/TumourMutationType";
import { TumourMutationsFilters } from "../../types/ProjectViewFilterTypes";
import { RootState } from "../store";
import { ProjectFilterSelectors } from "./ProjectFilterSelectors";

export const defaultMutationsState = {
  [MUTATION_EGFR]: true,
};

export const clearMutationsState = {
  [MUTATION_EGFR]: false,
};

export const mutationFilters = new ProjectFilterSelectors<TumourMutationsFilters>(
  defaultMutationsState,
  clearMutationsState,
  (state: RootState) => state.projectViewFilters.tumourMutations
);
