import LabelmapSeries from "./LabelmapSeries";

export default class LabelmapProvider {
  constructor(labelmapWrapper) {
    this.labelmapWrapper = labelmapWrapper;
    this.maskMap = new Map();
    this.usedSeries = [];
  }

  addSeries(seriesId) {
    let newSeries = new LabelmapSeries(seriesId, this.labelmapWrapper);
    this.usedSeries.push(newSeries);
    return newSeries;
  }

  setLabelmapColors(seriesId, labelmapId, color) {
    let seriesUsed = this.usedSeries.find((series) => series.seriesId === seriesId);
    seriesUsed.setLabelmapColors(labelmapId, color);
  }

  getLabelmapId(seriesId) {
    let seriesUsed = this.usedSeries.find((series) => series.seriesId === seriesId);
    if (!seriesUsed) {
      seriesUsed = this.addSeries(seriesId);
    }
    let labelmapIndex = seriesUsed.findFreeLabelmap();
    let segmentId = seriesUsed.selectNextLabelmapSegment(labelmapIndex);
    let labelmapId = seriesUsed.addCornerstoneDataToMap(labelmapIndex, segmentId);
    return labelmapId;
  }

  setActiveLabelmap(seriesId, element, labelmapId) {
    let seriesUsed = this.usedSeries.find((series) => series.seriesId === seriesId);
    seriesUsed.setActiveLabelmap(element, labelmapId);
  }

  setLabelmap(seriesId, element, labelmapId, seriesInstances, maskInstances, visible) {
    let seriesUsed = this.usedSeries.find((series) => series.seriesId === seriesId);
    seriesUsed.setLabelmap(element, labelmapId, seriesInstances, maskInstances, visible);
  }

  setLabelmapSlice(seriesId, toolData, labelmapId, element, visible) {
    let seriesUsed = this.usedSeries.find((series) => series.seriesId === seriesId);
    seriesUsed.setLabelmapSlice(element, labelmapId, toolData, visible);
  }

  getSegmentIdFromLabelmapId(seriesId, labelmapId) {
    let seriesUsed = this.usedSeries.find((series) => series.seriesId === seriesId);
    return seriesUsed.getSegmentIdFromLabelmapId(labelmapId);
  }

  clearSeriesData() {
    this.usedSeries = [];
  }

  deleteAllLabelmaps(element) {
    this.labelmapWrapper.clearLabelmaps(element);
  }

  deleteCornerstoneData(seriesId, elements, labelmapId) {
    let seriesUsed = this.usedSeries.find((series) => series.seriesId === seriesId);
    seriesUsed.deleteCornerstoneData(elements, labelmapId);
  }
}
