import Tooltip from "@mui/material/Tooltip";
import styled from "styled-components";

const Text = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface TextCellProps {
  value?: string;
  naValue?: string;
}

export function TextCell({ value, naValue = "N/A" }: TextCellProps) {
  const displayedValue = value ?? naValue;
  return (
    <Tooltip title={displayedValue}>
      <Text>{displayedValue}</Text>
    </Tooltip>
  );
}
