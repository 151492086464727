export const SMOKING_CURRENT = "Current";
export const SMOKING_FORMER = "Former";
export const SMOKING_NEVER = "Never";

export type SmokingStatusType =
  | typeof SMOKING_CURRENT
  | typeof SMOKING_FORMER
  | typeof SMOKING_NEVER;

export function getSmokingStatus(smokingStatusStr: string): SmokingStatusType | null {
  const dbNames: Record<string, SmokingStatusType> = {
    Current: SMOKING_CURRENT,
    Former: SMOKING_FORMER,
    Never: SMOKING_NEVER,
  };

  return dbNames[smokingStatusStr] ?? null;
}
