import { useDispatch } from "react-redux";

import { setSelectedLesionId } from "../../../../../../common/store/annotatePage/selectionSlice";
import { useChangeLesionColors } from "../utils/useChangeLesionColors";

type ReturnType = (lesionId: number, onLesionChangedCallback?: () => void) => boolean;

export function useTrySelectLesionId(): ReturnType {
  const dispatch = useDispatch();
  const changeLesionColors = useChangeLesionColors();

  return (lesionId, onLesionChangedCallback) => {
    changeLesionColors(lesionId);
    dispatch(setSelectedLesionId(lesionId));
    onLesionChangedCallback?.();
    return true;
  };
}
