import React, { useState } from "react";
import styled from "styled-components";

import { PatientBiopsyDetailsType } from "../AnnotationPanel/types/PatientBiopsyDetailsType";
import { ExpandCollapseButton } from "../common/ExpandCollapseButton";
import { ListPatientContextLabel } from "./ListPatientContextLabel";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  overflow: hidden;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px 6px 6px 10px;
  cursor: pointer;
  background: ${(props) => props.theme.colors.background.main};
`;

const Text = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
`;

interface ClinicalInformationListProps {
  biopsies: PatientBiopsyDetailsType[];
}

export function PatientContextList({ biopsies }: ClinicalInformationListProps): JSX.Element {
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const handleHeaderClicked = () => {
    setCollapsed(!collapsed);
  };

  const title = "Subject Context";
  const itemTitle = "Biopsy";

  return (
    <Wrapper>
      <HeaderWrapper onClick={handleHeaderClicked}>
        <Text>{title}</Text>
        <ExpandCollapseButton collapsed={collapsed} />
      </HeaderWrapper>
      {!collapsed &&
        biopsies.map((biopsy, index) => (
          <ListPatientContextLabel title={itemTitle} biopsy={biopsy} key={index} />
        ))}
    </Wrapper>
  );
}
