import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useOkForm } from "../../common/components/Dialog/useOkForm";
import { BigFlexLoading } from "../../common/components/Loading";
import { useGetProjectFromTrial } from "../../common/routes/useGetProjectFromTrial";
import { DASHBOARD } from "../Layout/RoutePathType";
import { addRecentProjectId } from "../utils/localStorage";
import { useTrialIdParam } from "../utils/useTrialIdParam";
import { useGetProjectContext } from "./Project/useGetProjectContext";
import { ProjectContext } from "./ProjectContext";

interface ProjectContextProviderProps {
  children: JSX.Element;
}

export function ProjectContextProvider({ children }: ProjectContextProviderProps): JSX.Element {
  const trialId = useTrialIdParam();

  const {
    data: projectFromTrialData,
    loading: projectFromTrialLoading,
    error: projectFromTrialError,
  } = useGetProjectFromTrial(trialId);

  if (projectFromTrialError) {
    throw new Error(`Error while trying to find project for trial ID: ${trialId}`);
  }
  if (projectFromTrialLoading) {
    return <BigFlexLoading>Loading project...</BigFlexLoading>;
  }
  if (!projectFromTrialData) {
    throw new Error("Data is null with no Apollo error");
  }

  if (projectFromTrialData.project.length !== 1) {
    throw new Error(`Can't find project for trial ID: ${trialId}`);
  }
  const projectId = projectFromTrialData.project[0].id;

  addRecentProjectId(projectId);

  return (
    <ProjectContextProviderInternal projectId={projectId}>
      {children}
    </ProjectContextProviderInternal>
  );
}

function ProjectContextProviderInternal({
  children,
  projectId,
}: ProjectContextProviderProps & { projectId: number }): JSX.Element {
  const { context, loading } = useGetProjectContext(projectId);
  const { push } = useHistory();
  const [showDisabledProjectForm, { dialog: disabledProjectForm }] = useOkForm({
    title: `Project Disabled`,
    message:
      "This project is disabled. Consult with your administrator to restore this project if required. Click OK to go back to the dashboard.",
    onOkCallback: () => push(DASHBOARD),
  });
  useEffect(() => showDisabledProjectForm(context?.disabled ?? false), [context]);

  if (loading) {
    return <BigFlexLoading>Loading project...</BigFlexLoading>;
  }

  if (!context) {
    throw new Error("Something went wrong loading project context");
  }

  return (
    <>
      {disabledProjectForm}
      <ProjectContext.Provider value={context}>{children}</ProjectContext.Provider>
    </>
  );
}
