import { useTaskDescriptions } from "../../hooks/useTaskDescriptions";
import { TaskDescriptionOptionType } from "../TaskDescriptionOptionType";
import { TASK_TEMPLATE } from "./CreateTaskType";
import { getTaskDescriptionOptions } from "./getTaskDescriptionOptions";

export function useTaskTemplateOptions(): {
  options: TaskDescriptionOptionType[];
  loading: boolean;
} {
  const { taskDescriptions = [], loading } = useTaskDescriptions();

  const options = taskDescriptions.flatMap((taskDescription) =>
    getTaskDescriptionOptions(taskDescription, TASK_TEMPLATE)
  );

  return {
    options,
    loading,
  };
}
