import { gql, useQuery } from "@apollo/client";
import { ApolloError } from "@apollo/client/errors";
import { useMemo } from "react";

const GET_ALGORITHMS = gql`
  query GetAlgorithms($projectId: Int!) {
    models: model(where: { project_id: { _eq: $projectId } }) {
      displayName: display_name
      createdAt: created_at
      details
      mortalityRisks: mortality_risks_aggregate(
        where: {
          series: {
            study: {
              patient: {
                patient_project_arms: { project_arm: { project_id: { _eq: $projectId } } }
              }
            }
          }
        }
      ) {
        aggregate {
          max {
            lastModified: last_modified
          }
        }
      }
      survivalPredictions: survival_predictions_aggregate(
        where: {
          series: {
            study: {
              patient: {
                patient_project_arms: { project_arm: { project_id: { _eq: $projectId } } }
              }
            }
          }
        }
      ) {
        aggregate {
          max {
            lastModified: last_modified
          }
        }
      }
    }
  }
`;

type Data = {
  models: {
    displayName: string;
    createdAt: string | null;
    details: unknown;
    mortalityRisks: {
      aggregate: {
        max: {
          lastModified: string | null;
        };
      };
    };
    survivalPredictions: {
      aggregate: {
        max: {
          lastModified: string | null;
        };
      };
    };
  }[];
};

type Variables = {
  projectId: number;
};

export type ModelSummary = {
  displayName: string;
  createdAt: string | null;
  lastRun: string | null;
  details: unknown;
};

export const useAlgorithms = (
  projectId: number
): {
  error?: ApolloError;
  loading: boolean;
  models: ModelSummary[];
} => {
  const { data, loading, error } = useQuery<Data, Variables>(GET_ALGORITHMS, {
    variables: {
      projectId,
    },
  });

  return useMemo(() => {
    if (loading || error) {
      return { models: [], loading, error };
    }

    const models = data ? parseData(data) : [];
    return { models, loading, error };
  }, [data, loading, error]);
};

function parseData({ models }: Data): ModelSummary[] {
  const sorted = [...models].sort((a, b) => {
    const lastModifiedA = getLastModifiedPrediction(a);
    const lastModifiedB = getLastModifiedPrediction(b);
    if (lastModifiedA === null) {
      return 1;
    } else if (lastModifiedB === null) {
      return -1;
    } else {
      return lastModifiedB.localeCompare(lastModifiedA);
    }
  });

  return sorted.map((model) => {
    const { displayName, createdAt, details } = model;
    const lastRun = getLastModifiedPrediction(model);
    return {
      displayName,
      createdAt,
      lastRun,
      details,
    };
  });
}

function getLastModifiedPrediction({
  survivalPredictions: {
    aggregate: {
      max: { lastModified: lastModifiedSurvival },
    },
  },
  mortalityRisks: {
    aggregate: {
      max: { lastModified: lastModifiedMortality },
    },
  },
}: Data["models"][number]): string | null {
  const mortality = lastModifiedMortality || "0";
  const survival = lastModifiedSurvival || "0";
  return mortality > survival ? mortality : survival;
}
