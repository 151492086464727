export type DownloadState = {
  downloaded: boolean;
  downloading: boolean;
  error: boolean;
  visible: boolean;
};

export const defaultDownloadState: DownloadState = {
  downloaded: false,
  downloading: false,
  error: false,
  visible: false,
};
