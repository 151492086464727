import { matchSorter } from "match-sorter";
import React from "react";

import { FlexLoading } from "../../../common/components/Loading";
import Text from "../../../common/components/text/Text";
import { useCurrentUser } from "../../../common/contexts/UserContext/useCurrentUser";
import { useAllUsersProjects } from "../../../common/queries/useAllUsersProjects";
import { ProjectType } from "../../../common/types/ProjectType";
import handleApolloError from "../../../common/utils/handleApolloError";
import { getRecentProjectIds } from "../../../Project/utils/localStorage";
import { RecentProjects } from "./RecentProjects";

interface RecentProjectsContainerProps {
  onClickProject: (project: ProjectType) => void;
  filter: string;
  showKaplanMeierPreview: boolean;
}

export function RecentProjectsContainer({
  onClickProject,
  filter,
  showKaplanMeierPreview,
}: RecentProjectsContainerProps): JSX.Element {
  const recentProjectIds = getRecentProjectIds();

  const { id: userId } = useCurrentUser();
  const { projects, loading, error } = useAllUsersProjects(userId);

  if (loading) {
    return <FlexLoading />;
  }
  if (error) {
    handleApolloError(error);
  }
  if (!projects) {
    throw new Error("Projects null");
  }

  const filteredProjects = matchSorter(projects, filter, { keys: ["name"] });

  const recentProjects = recentProjectIds.flatMap(
    (recentId) => filteredProjects.find(({ id }) => id === recentId) ?? []
  );

  if (recentProjects.length === 0) {
    return <Text text={"No Recent Projects"} />;
  }

  return (
    <RecentProjects
      recentProjects={recentProjects}
      onClickProject={onClickProject}
      showKaplanMeierPreview={showKaplanMeierPreview}
    />
  );
}
