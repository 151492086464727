import { MutationTuple, useMutation } from "@apollo/client";
import { gql } from "@apollo/client/core";

import { TaskProgressType } from "../../TaskWorklist/TaskProgressType";

type Variables = {
  taskAssignmentIds: number[];
  status: TaskProgressType;
};

type Data = {
  update_task_assignment: {
    returning: { id: number };
  };
};

const MUTATION = gql`
  mutation UpdateTaskAssignmentStatus(
    $taskAssignmentIds: [Int!]!
    $status: enum_task_progress_enum!
  ) {
    update_task_assignment(
      where: { id: { _in: $taskAssignmentIds } }
      _set: { progress: $status }
    ) {
      returning {
        id
        progress
      }
    }
  }
`;

export function useUpdateTaskAssignmentsStatus(): MutationTuple<Data, Variables> {
  return useMutation<Data, Variables>(MUTATION);
}
