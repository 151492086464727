import { useApolloClient } from "@apollo/client";

import { useGetRoiKeyImageId } from "../../AnnotationPanel/hooks/useGetRoiKeyImageId";
import { SeriesListItemFragmentType } from "../../AnnotationPanel/hooks/useStudiesList";
import { getImageIdFromInstanceIdFromCache } from "../../ViewerPanel/selection/getImageIdFromInstanceIdFromCache";
import { useSelectedLesionRois } from "../../ViewerPanel/selection/useSelectedLesionRois";
import { getSeriesInstancesFromCache } from "../../ViewerPanel/utils/getSeriesInstancesFromCache";

export function useKeyImageIdForSeries(series: SeriesListItemFragmentType): string | null {
  const rois = useSelectedLesionRois();
  const getRoiKeyImageId = useGetRoiKeyImageId();

  const { cache } = useApolloClient();
  const { id: seriesId } = series;

  const instances = getSeriesInstancesFromCache(seriesId, cache);

  const { length: numOfInstances } = instances;
  if (numOfInstances === 0) {
    return null;
  }

  const { id: middleInstanceId } = instances[Math.floor(numOfInstances / 2)];

  const roi = rois.find(({ series: { id: roiSeriesId } }) => seriesId === roiSeriesId);

  if (!roi) {
    return getImageIdFromInstanceIdFromCache(cache, middleInstanceId);
  }

  const keyImageId = getRoiKeyImageId(roi);

  if (!keyImageId) {
    return getImageIdFromInstanceIdFromCache(cache, middleInstanceId);
  }

  return keyImageId;
}
