import { useLocation, useParams } from "react-router";

interface RouteProps {
  patientId: string;
}

export function useSubjectIdParam(): number {
  const { search } = useLocation();
  const { patientId } = useParams<RouteProps>();
  const subjectIdNumber = parseInt(patientId) || null;
  if (subjectIdNumber) {
    return subjectIdNumber;
  }

  const searchParams = new URLSearchParams(search);
  const queryPatientId = searchParams.get("subject_id");
  if (queryPatientId) {
    return parseInt(queryPatientId);
  }

  throw new Error("No subject found in route or query params.");
}
