import { PageType, ProjectLayoutType,RoutePathSingleOrArray } from "../Layout";
import { getPageRoute } from "../utils/getPageRoute";
import { useFindPage } from "./useFindPage";

export function useFindPageByRoute(): (
  layout: ProjectLayoutType,
  searchRoute: RoutePathSingleOrArray
) => PageType | undefined {
  const findPage = useFindPage();

  return (layout, searchRoute) =>
    findPage(layout, (page) => {
      const fullRoute = getPageRoute(page);
      const isMatch = (route: RoutePathSingleOrArray | undefined) =>
        matchesEnd(
          Array.isArray(route) ? route : [route],
          Array.isArray(searchRoute) ? searchRoute : [searchRoute]
        );

      return isMatch(fullRoute);
    });
}

function matchesEnd<T>(entries: T[], search: T[]): boolean {
  if (entries.length === 0 || search.length === 0 || search.length > entries.length) {
    return false;
  }

  for (let i = 0; i < search.length; i++) {
    const searchItem = search[search.length - 1 - i];
    const entryItem = entries[entries.length - 1 - i];

    if (searchItem !== entryItem) {
      return false;
    }
  }

  return true;
}
