import React from "react";

import { UserType } from "../../../types/UserType";
import { OK_CANCEL } from "../../Dialog/DialogActionButtonsType";
import { DialogContentInnerWrapper } from "../../Dialog/DialogContentInnerWrapper";
import { DialogText } from "../../Dialog/DialogText";
import { useDialog, useDialogReturnType } from "../../Dialog/useDialog";
import { UserDetails } from "../UserDetails";

export function useResetPasswordDialog(
  user: UserType,
  onConfirmPasswordReset: (user: UserType) => void
): useDialogReturnType {
  const handleConfirmResetPassword = () => {
    onConfirmPasswordReset(user);
  };

  const label = "Reset Password";

  const content = (
    <DialogContentInnerWrapper>
      <UserDetails user={user} showEditButton={false} />
      <DialogText>
        Once confirmed, you will receive an email with details of how to reset your password. Follow
        these instructions to complete the password reset.
      </DialogText>
    </DialogContentInnerWrapper>
  );

  return useDialog({
    label,
    content,
    options: {
      showCloseButton: false,
      actionButtonOptions: {
        type: OK_CANCEL,
        okText: "Reset",
        onOkOverride: handleConfirmResetPassword,
      },
    },
  });
}
