import { Auth0Provider } from "@auth0/auth0-react";
import { AppState } from "@auth0/auth0-react/dist/auth0-provider";
import React from "react";
import { useHistory } from "react-router";

export const BASE_URL = `${window.location.origin}${process.env.PUBLIC_URL}`;

export const AUTH_SCOPE = "nota:hasura:user pacs:download:files";

interface AuthProviderProps {
  children: JSX.Element;
}

export function AuthProvider({ children }: AuthProviderProps): JSX.Element {
  const { push } = useHistory();

  const domain = window._env_.REACT_APP_AUTH0_DOMAIN;
  const clientId = window._env_.REACT_APP_AUTH0_CLIENT_ID;
  const redirectUri = BASE_URL;

  const handleOnRedirect = ({ pathname }: AppState) => {
    push(pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={redirectUri}
      onRedirectCallback={handleOnRedirect}
      audience={window._env_.REACT_APP_AUTH0_AUDIENCE}
      scope={AUTH_SCOPE}
    >
      {children}
    </Auth0Provider>
  );
}
