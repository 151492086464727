import React, { useContext } from "react";
import { useRouteMatch } from "react-router";

import SideNavSubButton from "../../common/components/buttons/SideNavSubButton";
import { BasePathContext } from "../../common/routes/BasePathContextProvider";
import { useGoToRoute } from "./hooks/useGoToRoute";
import { RoutePathSingleOrArray, SubMenuItemType } from "./Layout";
import { RoutePathType } from "./RoutePathType";
import { getRouteString } from "./utils/getRouteString";

interface SubMenuItemProps {
  item: SubMenuItemType;
  parentRoute: RoutePathSingleOrArray;
}

export function SubMenuItem({ item, parentRoute }: SubMenuItemProps): JSX.Element {
  const goToProjectPath = useGoToRoute();

  const { label, route } = item;
  const fullRoute = [parentRoute, route] as RoutePathType[];

  const { basePath } = useContext(BasePathContext);
  const fullRouteStr = getRouteString(fullRoute);
  const match = useRouteMatch(`${basePath}/${fullRouteStr}`);
  const active = !!match;

  const handleClick = () => {
    goToProjectPath(fullRoute);
  };

  return <SideNavSubButton text={label} active={active} onClick={handleClick} />;
}
