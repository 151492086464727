import { gql, useApolloClient } from "@apollo/client";
import { useCallback } from "react";

import { RoiId } from "../../ViewerPanel/selection/RoiId";
import { useGetMaskKeyImageId } from "../Masks/hooks/useGetMaskKeyImageId";
import { getRoiCacheId } from "./getRoiCacheId";

export function useGetRoiKeyImageId() {
  const { cache } = useApolloClient();
  const getMaskKeyImageId = useGetMaskKeyImageId();

  return useCallback(
    ({ id, series: { id: seriesId }, masks }: RoiId) => {
      const data = cache.readFragment<{ key_image_id: string | null }>({
        id: getRoiCacheId(id, cache),
        fragment: gql`
          fragment RoiKeyImageId on roi {
            key_image_id
          }
        `,
      });
      let { key_image_id: keyImageId } = data ?? { key_image_id: null };

      // if we cant get from cache, try the masks
      if (!keyImageId) {
        for (const mask of masks) {
          keyImageId = getMaskKeyImageId(mask.id, seriesId) ?? null;
          if (keyImageId) {
            break;
          }
        }
      }

      return keyImageId;
    },
    [cache, getMaskKeyImageId]
  );
}
