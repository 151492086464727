import axios from "axios";

type UploadFileResponse = {
  id: number;
  project_id: number;
  description: string | null;
  filename: string;
  type: string | null;
  filepath: string;
  size: number;
  upload_user_id: number;
  created_at: string;
  last_modified: string;
};
export async function uploadFile(
  files: FileList,
  url: string,
  token: string,
  description: string | null,
  type: string | null,
  projectId: number
): Promise<UploadFileResponse | undefined> {
  if (files.length === 0) {
    throw new Error("Files is empty in handleSubmit form");
  }

  const formData = new FormData();
  formData.append("project_id", projectId.toString());

  if (description) {
    formData.append("description", description.toString());
  }

  formData.append("filename", files[0].name);

  if (type) {
    formData.append("file_type", type.toString());
  }

  formData.append("file", files[0]);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };

  let uploadFileResponse: UploadFileResponse | undefined = undefined;

  try {
    const response = await axios.post(url, formData, config);
    if (response) {
      const { data } = response;
      uploadFileResponse = data as UploadFileResponse;
    }
  } catch (e) {
    console.error("An error occurred when triggering the file upload:", e);
  }

  return uploadFileResponse;
}
