import { ApolloCache, useQuery } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { POLLING_INTERVAL } from "nota-predict-web/src/common/utils/configConstants";
import { useContext } from "react";

import { CohortType } from "../../../../common/types/CohortType";
import { UserAvatarType } from "../../../../common/types/UserType";
import handleApolloError from "../../../../common/utils/handleApolloError";
import { COHORT_FRAGMENT } from "../../../../Project/contexts/Project/CohortFragment";
import { ProjectContext } from "../../../../Project/contexts/ProjectContext";
import { SERIES_FLAG_FRAGMENT } from "../../../fragments/SeriesFlagFragment";
import { SeriesFlagType } from "../../../types/SeriesFlagType";
import { TaskDescriptionType as TaskDescriptionDataType } from "../../../types/TaskDescriptionType";
import { useIsAnnotatePageAndSkippedCallback } from "../../Annotate/page/AnnotationPanel/hooks/debugUtils";
import { TaskProgressType } from "../../TaskWorklist/TaskProgressType";

export const TASK_ASSIGNMENT_FRAGMENT = gql`
  fragment TaskAssignment on task_assignment {
    id
    patientId: patient_id
    progress
    user {
      id: user_id
      firstName: first_name
      lastName: last_name
      email
      avatarImageSource: picture_url
    }
    showBadge: show_badge
    lastAccessed: last_accessed
  }
`;

export type TaskAssignmentFragmentType = {
  id: number;
  patientId: number;
  progress: TaskProgressType;
  user: UserAvatarType;
  showBadge: boolean;
  lastAccessed: string;
};

export const TASK_FRAGMENT = gql`
  fragment Task on task {
    id
    name
    projectId: project_id
    enabled
    qcSchemaId: qc_schema_id
    taskAssignments: task_assignments {
      ...TaskAssignment
    }
    taskDescription: task_description {
      data
      id
    }
    lesions {
      id: lesion_id
    }
    seriesClassifications: series_classifications {
      id
    }
    excludedPatients: task_patient_exclusions {
      id: patient_id
    }
    restrictedUsers: task_users_maps {
      id: user_id
    }
    restrictedCohorts: restricted_task_cohorts {
      id: cohort_id
    }
  }
  ${TASK_ASSIGNMENT_FRAGMENT}
`;

export type TaskFragmentType = {
  id: number;
  name: string;
  projectId: number;
  enabled: boolean;
  qcSchemaId: number | null;
  taskAssignments: TaskAssignmentFragmentType[];
  taskDescription: {
    id: number;
    data: TaskDescriptionDataType;
  };
  lesions: { id: number }[];
  qcResults: Record<string, number | string | boolean | null>; // TODO: confirm this is what we want
  seriesClassifications: { id: number }[];
  excludedPatients: { id: number }[];
  restrictedUsers: { id: number }[];
  restrictedCohorts: { id: number }[];
};

const QUERY = gql`
  query GetProjectTasks($projectIds: [Int!]!) {
    tasks: task(where: { project_id: { _in: $projectIds } }) {
      ...Task
    }
    cohorts: label(where: { label_projects: { project_id: { _in: $projectIds } } }) {
      ...Cohort
    }
    patients: patient(
      where: {
        _and: [
          {
            patients_with_children_and_cohorts: {
              _and: [
                { cohort: { type_name: { _eq: "COHORT" } } }
                { global_cohort_project_id: { _in: $projectIds } }
              ]
            }
          }
          { _not: { project_patient_exclusions: { project_id: { _in: $projectIds } } } }
        ]
      }
    ) {
      id: patient_id
      patientDicomId: patient_dicom_id
      patientCohorts: label_patients(where: { label: { type_name: { _eq: "COHORT" } } }) {
        id: label_id
      }
      lastModified: last_modified
      studies {
        id: study_id
        studyCohorts: label_studies(where: { label: { type_name: { _eq: "COHORT" } } }) {
          id: label_id
        }
        series(
          where: { _not: { project_series_exclusions: { project_id: { _in: $projectIds } } } }
          order_by: [{ series_date: asc }]
        ) {
          id: series_id
          seriesCohorts: label_series(where: { label: { type_name: { _eq: "COHORT" } } }) {
            id: label_id
          }
          seriesFlags: series_flags(where: { task: { project_id: { _in: $projectIds } } }) {
            ...SeriesFlag
          }
        }
      }
    }
  }
  ${TASK_FRAGMENT}
  ${COHORT_FRAGMENT}
  ${SERIES_FLAG_FRAGMENT}
`;

type Variables = {
  projectIds: number[];
};

export type Data = {
  tasks: TaskFragmentType[];
  cohorts: CohortType[];
  patients: {
    id: number;
    patientDicomId: string;
    patientCohorts: { id: number }[];
    lastModified: string;
    studies: {
      id: number;
      studyCohorts: { id: number }[];
      series: {
        id: number;
        seriesCohorts: { id: number }[];
        seriesFlags: SeriesFlagType[];
      }[];
    }[];
  }[];
};

type ReturnType = {
  data: Data | undefined;
  refetch: () => Promise<Data>;
  startPolling: (pollInterval: number) => void;
  stopPolling: () => void;
  loading: boolean;
};

export function useRawPatientTasksQuery(): ReturnType {
  const { projectId } = useContext(ProjectContext);

  const variables = { projectIds: projectId ? [projectId] : [] };

  const { data, loading, error, refetch, startPolling, stopPolling } = useQuery<Data, Variables>(
    QUERY,
    {
      variables,
      // TODO: the following currently breaks annotations
      // skip: isAnnotatePageAndSkipped(window.location.pathname),
    }
  );

  useIsAnnotatePageAndSkippedCallback((isSkippedOnAnnotatePage) => {
    if (isSkippedOnAnnotatePage) {
      stopPolling();
    } else {
      startPolling(POLLING_INTERVAL);
    }
  });

  if (error) handleApolloError(error);

  const refetchCallback = async () => {
    const { data } = await refetch(variables);
    return data;
  };

  return { data, loading, refetch: refetchCallback, startPolling, stopPolling };
}

export function addTaskToCache(
  taskFragment: TaskFragmentType,
  projectId: number,
  cache: ApolloCache<unknown>
) {
  const variables = { projectIds: [projectId] };
  const data = cache.readQuery<Data, Variables>({
    query: QUERY,
    variables,
  });

  if (!data) {
    throw new Error("Unable to ready tasks query in addTaskToCache");
  }

  const { tasks, ...other } = data;

  cache.writeQuery<Data, Variables>({
    query: QUERY,
    variables,
    data: { ...other, tasks: [...tasks, taskFragment] },
  });
}
