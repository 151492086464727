import { useContext } from "react";

import { ViewerConfigType } from "../../../../../../../common/store/annotatePage/ViewerConfigType";
import { ViewerContext } from "../ViewerContext";

export function useGetViewerForSeries(): (seriesId: number) => ViewerConfigType | undefined {
  const { viewerConfigs } = useContext(ViewerContext);

  return (seriesId) =>
    viewerConfigs.find(({ seriesId: viewerSeriesId }) => viewerSeriesId === seriesId);
}
