import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import { useSelector } from "react-redux";

import { selectedLesionIdSelector } from "../../../../../../../common/store/annotatePage/selectionSelector";
import {
  DRAW_QUEUE,
  ViewerConfigType,
} from "../../../../../../../common/store/annotatePage/ViewerConfigType";
import { useCopyContour } from "../../tools/contour/hooks/useCopyContour";
import { useDeleteSelectedContour } from "../../tools/contour/hooks/useDeleteSelectedContour";
import { ContourContextMenuData } from "../../tools/contour/types/ContourContextMenuDataType";
import { ViewerContextMenu } from "../../ViewerContextMenu";
import { useSetViewerToolQueue } from "../hooks/useSetViewerToolQueue";
import { verifyContourClickedContextMenuData } from "./utils";

interface CopyDeleteContourContextMenuProps {
  viewerConfig: ViewerConfigType;
  event?: ContourContextMenuData;
  onClose: () => Promise<void>;
}

export function CopyDeleteContourContextMenu({
  viewerConfig,
  event,
  onClose,
}: CopyDeleteContourContextMenuProps): JSX.Element {
  const { id: viewerConfigId, seriesId } = viewerConfig;

  const lesionId = useSelector(selectedLesionIdSelector);
  const { deleteContour } = useDeleteSelectedContour(seriesId);
  const { copyContour } = useCopyContour();

  const setViewerToolQueue = useSetViewerToolQueue();

  const handleClose = async () => {
    await onClose();
  };

  const handleCopy = async () => {
    const data = verifyContourClickedContextMenuData(event);

    copyContour(data, lesionId);
    await handleClose();
  };

  const handleDelete = async () => {
    const data = verifyContourClickedContextMenuData(event);

    await deleteContour(data);

    setViewerToolQueue({ viewerConfigId, queue: DRAW_QUEUE, value: true });

    await handleClose();
  };

  return (
    <ViewerContextMenu
      event={event}
      onClose={handleClose}
      menuItems={[
        {
          label: "Copy",
          icon: (
            <Tooltip title={"Copy Contour (ctrl + C to copy all)"}>
              <FileCopyIcon />
            </Tooltip>
          ),
          callback: handleCopy,
        },
        {
          label: "Delete",
          icon: <DeleteIcon />,
          callback: handleDelete,
        },
      ]}
    />
  );
}
