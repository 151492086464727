import { RoiRecistEvaluationType } from "../../../../AnnotationPanel/types/RoiRecistEvaluationType";
import { RoiRecistEvaluationVariablesType } from "../utils/getRoiRecistEvaluationVariables";

export const INTERNAL_MUTATION = `
  insert_roi_recist_evaluations(
    objects: $roiRecistEvaluations
    on_conflict: {
      constraint: roi_evaluations_pkey
      update_columns: [
        long_axis_diameter
        short_axis_diameter
        volume
        long_axis_diameter_points
        short_axis_diameter_points
        instance_id
        hu_min
        hu_max
        hu_mean
      ]
    }
  ) {
    roiRecistEvaluations: returning {
      ...InsertRoiRecistEvaluationFragment
    }
  }
`;

export type Variables = {
  roiRecistEvaluations: RoiRecistEvaluationVariablesType[];
};

export type Data = {
  insert_roi_recist_evaluations: {
    roiRecistEvaluations: RoiRecistEvaluationType[];
  };
};
