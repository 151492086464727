import {
  CHECK_MINIMUM_CONTOUR,
  CHECK_MISSING_CLASSIFICATIONS,
  CHECK_MISSING_CONTOURS,
  CHECK_MISSING_ORGANS,
  CHECK_MISSING_REQUIRED_QC_FIELDS,
  CHECK_MISSING_ROIS,
} from "../../../../../../enums/TaskDescriptionEnums";
import { LesionTaskCheckType, TaskCheckType } from "../../../../../../types/TaskDescriptionType";
import { MinimumContourCheckAction, MinimumContourCheckResult } from "./Checks/MinimumContour";
import { MissingClassificationsCheckActions } from "./Checks/MissingClassificationsCheck/MissingClassificationsCheckActions";
import { MissingClassificationsCheckResultType } from "./Checks/MissingClassificationsCheck/useMissingClassificationsCheck";
import { MissingContoursCheckActions } from "./Checks/MissingContours/MissingContoursCheckActions";
import { MissingContoursCheckResultType } from "./Checks/MissingContours/useMissingContoursCheck";
import { MissingOrgansCheckActions } from "./Checks/MissingOrgans/MissingOrgansCheckActions";
import { MissingOrgansCheckResultType } from "./Checks/MissingOrgans/useMissingOrgansCheck";
import { MissingRoisCheckActions } from "./Checks/MissingRois/MissingRoisCheckActions";
import { MissingRoisCheckResultType } from "./Checks/MissingRois/useMissingRoisCheck";
import { QualityControlCheckActions } from "./Checks/QualityControlCheck/QualityControlCheckActions";
import {
  LesionTaskCheckResultComponentProps,
  TaskCheckResultComponentProps,
} from "./types/SaveCheckResultComponentProps";

type MissingContourCheckProps = LesionTaskCheckResultComponentProps<MissingContoursCheckResultType>;

type MissingClassificationsCheckProps =
  LesionTaskCheckResultComponentProps<MissingClassificationsCheckResultType>;

type MinimumContourCheckProps = LesionTaskCheckResultComponentProps<MinimumContourCheckResult>;
type MissingOrgansCheckProps = TaskCheckResultComponentProps<MissingOrgansCheckResultType>;

type MissingRoisCheckProps = LesionTaskCheckResultComponentProps<MissingRoisCheckResultType>;

export const allLesionCheckComponents: Record<
  LesionTaskCheckType,
  (props: LesionTaskCheckResultComponentProps<unknown>) => JSX.Element
> = {
  [CHECK_MISSING_CONTOURS]: (props) => (
    <MissingContoursCheckActions {...(props as MissingContourCheckProps)} />
  ),
  [CHECK_MISSING_CLASSIFICATIONS]: (props) => (
    <MissingClassificationsCheckActions {...(props as MissingClassificationsCheckProps)} />
  ),
  [CHECK_MINIMUM_CONTOUR]: (props) => (
    <MinimumContourCheckAction {...(props as MinimumContourCheckProps)} />
  ),
  [CHECK_MISSING_ROIS]: (props) => (
    <MissingRoisCheckActions {...(props as MissingRoisCheckProps)} />
  ),
};

export const allTaskCheckComponents: Record<
  TaskCheckType,
  (props: TaskCheckResultComponentProps<unknown>) => JSX.Element
> = {
  [CHECK_MISSING_ORGANS]: (props) => (
    <MissingOrgansCheckActions {...(props as MissingOrgansCheckProps)} />
  ),
  [CHECK_MISSING_REQUIRED_QC_FIELDS]: (props) => (
    <QualityControlCheckActions {...(props as TaskCheckResultComponentProps<null>)} />
  ),
};
