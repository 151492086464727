import React from "react";

import { TUMOUR_HU } from "../../../../../enums/TaskDescriptionEnums";
import { statsDisplayName } from "../../../../../types/TaskDescriptionType";
import { RoiListItemFragmentType } from "../fragments/RoiListItemFragment";
import { HUBodyTimepointStat } from "./HUBodyTimepointStat";
import { RoiStatComponentDefinition, RoiStatProps } from "./useGetRoiStatComponents";
import { HUStat } from "./utils/getRoiHUStats";

function RoiHUStat(props: RoiStatProps): JSX.Element {
  const { roi } = props;

  const huStats = tryGetHuStat(roi);

  if (!hasValue(props) || !huStats) {
    throw new Error("Should not render");
  }

  const label = statsDisplayName[TUMOUR_HU];

  return <HUBodyTimepointStat title={label} stats={huStats} />;
}

function hasValue({ roi }: RoiStatProps): boolean {
  return !!tryGetHuStat(roi);
}

export const roiHUStatDefinition: RoiStatComponentDefinition = {
  type: TUMOUR_HU,
  hasValue,
  component: RoiHUStat,
};

function tryGetHuStat({ roiRecistEvaluation }: RoiListItemFragmentType): HUStat | undefined {
  if (!roiRecistEvaluation) {
    return undefined;
  }

  const { huMin, huMax, huMean } = roiRecistEvaluation;
  if (huMin === null || huMax === null || huMean === null) {
    return undefined;
  }

  return {
    min: huMin,
    max: huMax,
    average: huMean,
  };
}
