import { useContext } from "react";
import { useDeepCompareCallback } from "use-deep-compare";

import { ViewerConfigType } from "../../../../../../../common/store/annotatePage/ViewerConfigType";
import { ViewerContext } from "../ViewerContext";
import { useActivateViewer } from "./useActivateViewer";

export function useAddViewerInternal(): (
  viewerConfig: ViewerConfigType,
  activate: boolean
) => void {
  const { setViewerConfigs } = useContext(ViewerContext);
  const activateViewer = useActivateViewer();

  return useDeepCompareCallback(
    (viewerConfig, activate) => {
      setViewerConfigs((viewerConfigs) => [...viewerConfigs, viewerConfig]);

      if (!activate) {
        return;
      }

      const { id } = viewerConfig;
      activateViewer(id);
    },
    [activateViewer, setViewerConfigs]
  );
}
