import { useAuth0 } from "@auth0/auth0-react";
import React, { useContext } from "react";
import styled from "styled-components";

import FilterButton from "../../../Analysis/ToolPanel/components/FilterButton";
import { BASE_URL } from "../../../auth0/AuthProvider";
import { UserType } from "../../types/UserType";
import { CurrentUserAvatar } from "../Avatar/CurrentUserAvatar";
import { useResetPasswordDialog } from "./Dialogs/useResetPasswordDialog";
import { UserDetails } from "./UserDetails";
import { UserManagementContext } from "./UserManagementContext";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  gap: 18px;
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 12px;
`;

interface UserProfileProps {
  user: UserType;
}

export function UserProfile({ user }: UserProfileProps): JSX.Element {
  const { logout } = useAuth0();
  const { onPasswordReset } = useContext(UserManagementContext);

  if (!onPasswordReset) {
    throw new Error("onUserPasswordReset not defined in UserProfile");
  }

  const handleConfirmPasswordReset = (user: UserType) => {
    onPasswordReset(user);
  };

  const [setResetPasswordDialogOpen, { dialog: resetPasswordDialog }] = useResetPasswordDialog(
    user,
    handleConfirmPasswordReset
  );

  const handleClickResetPassword = () => {
    setResetPasswordDialogOpen(true);
  };

  const handleLogout = () => {
    const returnTo = BASE_URL;
    logout({ returnTo });
  };

  return (
    <>
      {resetPasswordDialog}
      <Wrapper>
        <CurrentUserAvatar size={64} showProfileOnClick={false} />
        <UserDetails user={user} showEditButton={true} />
        <RowWrapper>
          <FilterButton
            onClick={handleClickResetPassword}
            width={128}
            active={true}
            text={"Reset Password"}
          />
          <FilterButton onClick={handleLogout} width={128} active={true} text={"Logout"} />
        </RowWrapper>
      </Wrapper>
    </>
  );
}
