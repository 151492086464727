import { useCallback } from "react";
import { useSelector } from "react-redux";

import { taskModesSelector } from "../../../../../../common/store/annotatePage/taskSelector";
import { DEFAULT } from "../../../../../enums/TaskDescriptionEnums";
import {
  ClassificationModeType,
  ClassificationValuesType,
} from "../../../../../types/TaskDescriptionType";
import { hasClassification } from "../../StudyPanel/utils/TumourResponse/utils/hasClassification";
import { LesionListItemFragmentType } from "../fragments/LesionListItemFragment";

export type LesionModeReturnType = (lesion: LesionListItemFragmentType) => ClassificationModeType;

export function useGetLesionMode(): LesionModeReturnType {
  const taskModes = useSelector(taskModesSelector);

  const getLesionMode = (lesion: LesionListItemFragmentType) => {
    const optionMode = taskModes.find((taskMode) =>
      hasClassification(lesion, taskMode as ClassificationValuesType)
    );

    return optionMode ?? DEFAULT;
  };

  return useCallback(getLesionMode, [taskModes]);
}
