import { useLesionsList } from "../../../../AnnotationPanel/hooks/useLesionsList";
import { removeMeasurementsFromElement } from "../utils/removeMeasurementsFromElement";
import { getCurrentDiameterPoints } from "./useContourMeasurements";

type ReturnType = (roiId: number) => void;

export function useRemoveContourMeasurements(): ReturnType {
  const { data } = useLesionsList();

  return (roiId) => {
    if (!data) {
      throw new Error("Finished loading lesions but no data");
    }

    const { lesions } = data;

    const currentDiameterPoints = getCurrentDiameterPoints(lesions, roiId);
    if (currentDiameterPoints === null) {
      return;
    }

    const { LADPoints, SADPoints } = currentDiameterPoints;

    removeMeasurementsFromElement(LADPoints);
    removeMeasurementsFromElement(SADPoints);
  };
}
