import { useApolloClient } from "@apollo/client";

import { ClassificationValuesType } from "../../../../../types/TaskDescriptionType";
import { RoiRecistEvaluationVariablesType } from "../../ViewerPanel/tools/contour/utils/getRoiRecistEvaluationVariables";
import { LesionListItemFragmentType } from "../fragments/LesionListItemFragment";
import { getLesionFromCache } from "./getLesionFromCache";
import { useGetMeasurementsDisabledForRoi } from "./timepointOptions/useGetMeasurementsDisabledForRoi";
import { useLesionListQueryInput } from "./useLesionListQueryInput";
import { useRecalculateRoiMeasurements } from "./useRecalculateRoiMeasurements";

type ReturnType = (
  lesionId: number,
  classifications: ClassificationValuesType[],
  deleteClassifications: ClassificationValuesType[]
) => Promise<{
  roiRecistEvaluationIdsToDelete: number[];
  roiRecistEvaluationsToInsert: RoiRecistEvaluationVariablesType[];
}>;

export function useGetRoiRecistEvaluationsToUpdate(): ReturnType {
  const getMeasurementsDisabledForRoi = useGetMeasurementsDisabledForRoi();
  const { cache } = useApolloClient();
  const lesionListQueryInput = useLesionListQueryInput();
  const recalculateRoiMeasurements = useRecalculateRoiMeasurements();

  return async (lesionId, classifications, deleteClassifications) => {
    const lesion = getLesionFromCache(lesionListQueryInput, lesionId, cache);
    if (!lesion) {
      throw new Error(
        `Unable to get lesion with id ${lesionId} from cache when inserting lesion classifications`
      );
    }
    const { rois, classifications: existingClassifications } = lesion;

    const proposedClassifications = [...classifications];
    for (const existingClassification of existingClassifications) {
      const { classification } = existingClassification;
      if (deleteClassifications.includes(classification)) {
        continue;
      }

      if (proposedClassifications.includes(classification)) {
        continue;
      }

      proposedClassifications.push(classification);
    }

    const proposedLesion: LesionListItemFragmentType = {
      ...lesion,
      classifications: proposedClassifications.map((classification, id) => ({
        id,
        classification,
      })),
    };

    const roisToClear = rois.filter((roi) => getMeasurementsDisabledForRoi(proposedLesion, roi));

    const roisToMeasure = rois.filter((roi) => !getMeasurementsDisabledForRoi(proposedLesion, roi));

    const roiRecistEvaluationsToInsert: RoiRecistEvaluationVariablesType[] = [];
    for (const roiToMeasure of roisToMeasure) {
      const evaluation = await recalculateRoiMeasurements(roiToMeasure);
      roiRecistEvaluationsToInsert.push(evaluation);
    }

    const roiRecistEvaluationIdsToDelete = roisToClear.map(({ id }) => id);
    return { roiRecistEvaluationIdsToDelete, roiRecistEvaluationsToInsert };
  };
}
