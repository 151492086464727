import { ApolloCache } from "@apollo/client";
import React from "react";
import styled from "styled-components";

import { ReactComponent as PlusOutlineFilled } from "../../../../../../assets/svgs/PlusOutlineFilled.svg";
import { SvgIcon } from "../../../../../../common/components/icons/SvgIcon";
import { FlexLoading } from "../../../../../../common/components/Loading";
import { main } from "../../../../../../common/theme/main";
import { TimepointOptionsType } from "../../../../../types/TaskDescriptionType";
import { LesionListItemFragmentType } from "../fragments/LesionListItemFragment";
import { MissingRoiClassificationButtonsReturnType } from "../hooks/timepointOptions/useGetMissingRoiClassificationButtons";
import { InsertLesionClassificationsInternalReturnType } from "../hooks/useInsertLesionClassification";
import { getVariables, InsertRoisReturnType } from "../hooks/useInsertRois";
import { SeriesListItemFragmentType } from "../hooks/useStudiesList";
import { HeaderLabelText, HeaderWrapper } from "./CommonStyles";
import { RoiClassificationButtons } from "./RoiClassificationButtons";
import { CardWrapper } from "./SeriesRoiCardWrapper";

const Button = styled.button`
  border: none;
  cursor: pointer;
  background: transparent;
  padding: 3px 2px;
`;

const Icon = styled(SvgIcon)`
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
`;

interface MissingRoiProps {
  isFirst: boolean;
  isMulti: boolean;
  series: SeriesListItemFragmentType;
  lesion: LesionListItemFragmentType;
  label: string;
  onSelected: (
    e?: React.MouseEvent<Element, MouseEvent>,
    series?: SeriesListItemFragmentType
  ) => void;
  canAdd: boolean;
  canClassify: boolean;
  getMissingRoiTimepointOptions: MissingRoiClassificationButtonsReturnType;
  useInsertRoisObj: InsertRoisReturnType;
  updateTaskInProgress: () => Promise<void>;
  onTumourBurdenChanged: (lesionId: number) => Promise<void>;
  insertLesionClassificationsObj: InsertLesionClassificationsInternalReturnType;
  userId: number;
  cache: ApolloCache<object>;
  isRecist: boolean;
}

export function MissingRoiCard({
  onSelected,
  series,
  isFirst,
  isMulti,
  lesion,
  label,
  canAdd,
  canClassify,
  getMissingRoiTimepointOptions,
  useInsertRoisObj,
  updateTaskInProgress,
  onTumourBurdenChanged,
  insertLesionClassificationsObj,
  userId,
  cache,
  isRecist,
}: MissingRoiProps): JSX.Element {
  const { id: lesionId } = lesion;
  const buttons = getMissingRoiTimepointOptions(lesion).filter(
    ({ showAtBaseline, showAtFollowUp }) => (isFirst ? showAtBaseline : showAtFollowUp)
  );

  const [insertRois, { loading }] = useInsertRoisObj;
  const [insertLesionClassifications] = insertLesionClassificationsObj;

  const handleClickAdd = async () => {
    if (loading) {
      throw new Error("User clicked insert roi while an roi was being inserted.");
    }
    await insertRois({
      variables: getVariables(
        [
          {
            seriesId: series.id,
            lesionId,
            createdBy: userId,
          },
        ],
        isRecist,
        cache
      ),
    });

    onSelected(undefined, series);
  };
  const handleClickNotPresent = async ({
    rule: classification,
    triggersLesionClassification,
  }: TimepointOptionsType) => {
    if (loading) {
      throw new Error("User clicked insert roi while an roi was being inserted.");
    }

    if (!canClassify) {
      throw new Error("Not present roi clicked but viewOnly");
    }

    await insertRois({
      variables: getVariables(
        [
          {
            seriesId: series.id,
            lesionId,
            createdBy: userId,
            classificationValues: [classification],
          },
        ],
        isRecist,
        cache
      ),
    });
    await updateTaskInProgress();

    if (isFirst && triggersLesionClassification.length > 0) {
      await insertLesionClassifications(lesionId, triggersLesionClassification, []);
    }

    await onTumourBurdenChanged(lesionId);
  };

  return (
    <>
      {canClassify ? (
        <CardWrapper roiMissing={true}>
          <Button onClick={canAdd ? handleClickAdd : undefined} disabled={loading}>
            {!loading && (
              <Icon icon={PlusOutlineFilled} size={18} color={main.colors.primary.primary} />
            )}
            {loading && <FlexLoading size={18} color={main.colors.primary.primary} />}
          </Button>
          <HeaderWrapper onClick={(event) => onSelected(event, series)}>
            <HeaderLabelText>
              {isMulti && series.seriesDescription ? series.seriesDescription : label}
            </HeaderLabelText>
          </HeaderWrapper>
          <RoiClassificationButtons
            buttons={buttons}
            onClickClassification={handleClickNotPresent}
            viewOnly={!canClassify}
            loading={loading}
            justifyContent={"flex-end"}
          />
        </CardWrapper>
      ) : (
        <HeaderWrapper>
          <HeaderLabelText>
            {isMulti && series.seriesDescription ? series.seriesDescription : label}
          </HeaderLabelText>
        </HeaderWrapper>
      )}
    </>
  );
}
MissingRoiCard.whyDidYouRender = true;
