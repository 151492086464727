import { LesionListItemFragmentType } from "../fragments/LesionListItemFragment";
import { useLesionsList } from "./useLesionsList";
import { lesionsForCurrentTaskFilter } from "./useTaskLesionListOptions";

type ReturnType = (currentLesionId: number) => LesionListItemFragmentType | null;

export function useNextLesion(): ReturnType {
  const { data: lesionsData } = useLesionsList(lesionsForCurrentTaskFilter);

  return (currentLesionId) => {
    if (!lesionsData) {
      throw new Error("Unable to get lesions in useNextLesionId");
    }

    const { lesions } = lesionsData;

    return lesions.find(({ id: nextLesionId }) => nextLesionId !== currentLesionId) ?? null;
  };
}
