import { useDispatch, useSelector } from "react-redux";

import { hiddenLesionIdsSelector } from "../../../../../../common/store/annotatePage/selectionSelector";
import { updateHiddenLesionIds } from "../../../../../../common/store/annotatePage/selectionSlice";
import { useLesionsList } from "../../AnnotationPanel/hooks/useLesionsList";
import { LesionListItemPredicateType } from "../../AnnotationPanel/hooks/useTaskLesionListOptions";
import { toggleLesionsMeasurementsVisibility } from "../tools/measure/toggleLesionsMeasurementsVisibility";

type ReturnType = {
  visibility: boolean;
  setVisibility: (visible: boolean) => void;
};

export function useLesionsVisibility(filter?: LesionListItemPredicateType): ReturnType {
  const dispatch = useDispatch();
  const hiddenLesionIds = useSelector(hiddenLesionIdsSelector);

  const { data } = useLesionsList(filter);

  const visibleLesionIds = data ? data.lesions.map(({ id }) => id) : [];

  const setVisibility = (visible: boolean) => {
    if (!data) {
      return;
    }
    const { lesions } = data;
    toggleLesionsMeasurementsVisibility(lesions, visible);
    dispatch(updateHiddenLesionIds({ ids: visibleLesionIds, visible }));
  };

  const visibility =
    visibleLesionIds.length !== 0 && !visibleLesionIds.some((id) => hiddenLesionIds.includes(id));

  return { visibility, setVisibility };
}
