import { useHistory } from "react-router-dom";

import { getProjectBaseRoute } from "../../../common/routes/getBasePath";
import { getProjectRoute } from "../utils/getProjectRoute";

type ReturnType = (projectId: number) => void;

export function useGoToProject(): ReturnType {
  const { push } = useHistory();

  return (projectId) => {
    const baseRoute = getProjectBaseRoute(projectId);
    const path = getProjectRoute(baseRoute);
    push(path);
  };
}
