import React from "react";

import { useCurrentUserAllowedMenu } from "./hooks/useCurrentUserAllowedMenu";
import { ProjectLayoutType } from "./Layout";
import { MenuItem } from "./MenuItem";

interface SideNavMenuProps {
  layout: ProjectLayoutType;
}

export function SideNavMenu({ layout }: SideNavMenuProps): JSX.Element {
  const allowedMenu = useCurrentUserAllowedMenu(layout);

  return (
    <>
      {allowedMenu.map((item, index) => (
        <MenuItem key={index} item={item} />
      ))}
    </>
  );
}
