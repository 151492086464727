import React from "react";
import styled from "styled-components";

import ItemSubtext from "../../../common/components/text/ItemSubtext";
import ItemText from "../../../common/components/text/ItemTextSize16";
import { ProjectType } from "../../../common/types/ProjectType";
import { getMonthDateTime } from "../../../common/utils/dateFormatUtils/getMonthDateTime";
import ProjectStatus from "./ProjectStatus";

const Wrapper = styled.div`
  margin-bottom: 14px;
  font-style: normal;

  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.neutral.neutral7};
  overflow: hidden;
  cursor: pointer;
`;

const Column = styled.div`
  display: flex;
  align-items: center;
  padding-top: 14px;
  padding-bottom: 14px;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.actionPrimary.default};
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
`;

const FlexColumn = styled(Column)<{ basis: number }>`
  flex-basis: ${({ basis }) => basis}%;
`;

const NameWrapper = styled(Column)`
  flex-basis: 20%;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.actionPrimary.default};
`;

interface ProjectRowProps {
  project: ProjectType;
  onClickProject: (project: ProjectType) => void;
}

export function ProjectRow({ project, onClickProject }: ProjectRowProps): JSX.Element {
  const { name, status, estimatedCompletion, investigationName, indicationName } = project;

  const handleClick = () => {
    onClickProject(project);
  };

  return (
    <Wrapper onClick={handleClick}>
      <HeaderWrapper>
        <NameWrapper>
          <ItemText text={name} />
        </NameWrapper>
        <FlexColumn basis={20}>
          <ItemSubtext text={indicationName} />
        </FlexColumn>
        <FlexColumn basis={20}>
          <ItemSubtext text={investigationName} />
        </FlexColumn>
        <FlexColumn basis={15}>
          <ProjectStatus status={status} />
        </FlexColumn>
        <FlexColumn basis={20}>
          <ItemSubtext text={getMonthDateTime(estimatedCompletion)} />
        </FlexColumn>
      </HeaderWrapper>
    </Wrapper>
  );
}
