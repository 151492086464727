import React, { FC, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";

import { useGetAuthToken } from "../../../auth0/useGetAuthToken";
import { ActionButtonsWrapper } from "../../../common/components/Dialog/Form/ActionButtonsWrapper";
import { FormProps } from "../../../common/components/Dialog/FormProps";
import { useOkForm } from "../../../common/components/Dialog/useOkForm";
import { Input } from "../../../common/components/input/Input";
import { InputButton } from "../../../common/components/input/InputButton";
import { Label } from "../../../common/components/input/Label";
import { main } from "../../../common/theme/main";
import { useProjectId } from "../../../Project/hooks/useProjectId";
import { useAllProjectDocuments } from "../../Documents/hooks/useAllProjectDocuments";
import { formatFileSize } from "../../Documents/utils/formatFileSize";
import { uploadFile } from "../../Documents/utils/uploadFile";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 400px;
`;

const MetadataLabel = styled.div`
  font-size: 12px;
`;

const Bold = styled.span`
  font-weight: bold;
`;

export type FileConfigType = {
  id: number;
  projectId: number;
  description: string | null;
};
export const UploadProjectFileForm: FC<FormProps<undefined>> = ({
  onSubmit,
  onCancel,
}: FormProps<undefined>) => {
  const projectId = useProjectId();

  const { refetch } = useAllProjectDocuments(projectId);

  const [files, setFiles] = useState<FileList | null>(null);
  const [type, setType] = useState<string | null>(null);
  const [fileSize, setFileSize] = useState<number | null>(null);
  const [description, setDescription] = useState<string | null>(null);

  const inputFileRef = useRef<HTMLInputElement>(null);

  const getExtension = (file: File) => {
    setType(file.name.split(".").pop() || null);
  };

  const getFileSize = (file: File) => {
    setFileSize(file.size);
  };

  const handleClickUploadFile = () => {
    inputFileRef.current?.click();
  };

  const handleFilesSelected = (event: React.FormEvent<HTMLInputElement>) => {
    const { files } = event.target as HTMLInputElement;
    showUploadDialog(files);
    if (files) {
      getExtension(files[0]);
      getFileSize(files[0]);
    }
  };

  const showUploadDialog = (files: FileList | null) => {
    if (!files || files.length === 0) {
      console.warn("No files selected for upload");
      clearUploadState();
      return;
    }

    setFiles(files);
  };

  const clearUploadState = () => {
    setFiles(null);

    if (inputFileRef.current) {
      inputFileRef.current.value = "";
    }
  };

  const [showErrorDialog, { dialog: errorDialog }] = useOkForm({
    title: "Error Uploading New File",
    message:
      "There was an error uploading the file, please contact your administrator for more details.",
  });

  const { handleSubmit } = useForm<FileConfigType>({});

  const isFileSelected = files && files.length > 0;

  const fileUploadURL = window._env_.REACT_APP_NOTA_API_URL + "/userProjectFile/";

  const getToken = useGetAuthToken();

  const handleSubmitForm = async () => {
    if (!isFileSelected) {
      throw new Error("Files is null in handleSubmit form");
    }
    const token = await getToken();

    const uploadFileResponse = await uploadFile(
      files,
      fileUploadURL,
      token,
      description,
      type,
      projectId
    );

    if (!uploadFileResponse) {
      showErrorDialog(true);
      return;
    }

    await refetch();
    onSubmit();
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <>
      {errorDialog}
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Wrapper>
          <div>
            <Label>Description</Label>
            <Input
              type="text"
              id={"description"}
              autoComplete={"off"}
              onChange={(event) => setDescription(event.target.value)}
            />
          </div>
          <MetadataLabel>
            File Size: <Bold>{fileSize ? formatFileSize(fileSize) : ""}</Bold>
          </MetadataLabel>
          <MetadataLabel>
            File Type: <Bold>{type}</Bold>
          </MetadataLabel>
        </Wrapper>
        <input
          type="file"
          id="file"
          multiple
          ref={inputFileRef}
          style={{ display: "none" }}
          onChange={handleFilesSelected}
        />
        <ActionButtonsWrapper>
          <InputButton
            type="button"
            name="cancel-button"
            value={"Browse"}
            background={main.colors.neutral.black}
            color={main.colors.neutral.white}
            onClick={handleClickUploadFile}
          />
          <InputButton
            type="submit"
            name="submit-button"
            value={"Upload"}
            disabled={!isFileSelected}
            background={!isFileSelected ? main.colors.actionPrimary.disabled : undefined}
            borderColor={!isFileSelected ? main.colors.actionPrimary.hover10 : undefined}
          />
          <InputButton
            type="button"
            name="cancel-button"
            value={"Cancel"}
            background={main.colors.neutral.white}
            color={main.colors.neutral.black}
            onClick={handleCancel}
          />
        </ActionButtonsWrapper>
      </form>
    </>
  );
};
