import { createContext, useContext } from "react";

import { TimepointType } from "../../../../../Annotate/components/Annotate/page/AnnotationPanel/hooks/Timepoint/TimepointType";

export type PatientContextType = {
  id: number;
  patientDicomId: string;
  isSynthetic: boolean;
  timepoints: TimepointType[];
  loading: boolean;
};

const DEFAULT_CONTEXT: PatientContextType = {
  id: 0,
  patientDicomId: "",
  isSynthetic: false,
  timepoints: [],
  loading: true,
};

export const PatientContext = createContext<PatientContextType>(DEFAULT_CONTEXT);

export const usePatientContext = () => {
  return useContext(PatientContext);
};
