import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { MutationTuple } from "@apollo/client/react/types/types";

import { ROI_RECIST_EVALUATION_FRAGMENT } from "../../../../AnnotationPanel/fragments/RoiRecistEvaluationFragment";
import { deleteContoursFromCache } from "../../../../AnnotationPanel/hooks/deleteContoursFromCache";
import { updateRoiRecistEvaluationsInCache } from "../../../../AnnotationPanel/hooks/updateRoiRecistEvaluationsInCache";
import {
  Data as UpsertRoiRecistEvaluationData,
  INTERNAL_MUTATION as UPSERT_ROI_RECIST_EVALUATION_MUTATION,
  Variables as UpsertRoiRecistEvaluationVariables,
} from "./useUpsertRoiRecistEvaluation";

const MUTATION = gql`
  mutation DeleteContour(
    $roiId: Int!
    $instanceId: Int!
    $roiRecistEvaluations: [roi_recist_evaluations_insert_input!]!
  ) {
    delete_contour(
      where: {
        _and: [
          { roi_id: { _eq: $roiId } }
          { instance_id: { _eq: $instanceId } }
        ]
      }
    ) {
      returning {
        id: contour_id
        instanceId: instance_id
        roiId: roi_id
      }
    }
    ${UPSERT_ROI_RECIST_EVALUATION_MUTATION}
  }
  ${ROI_RECIST_EVALUATION_FRAGMENT}
`;

type Variables = {
  roiId: number;
  instanceId: number;
} & UpsertRoiRecistEvaluationVariables;

type Data = {
  delete_contour: {
    returning: { id: number; instanceId: number; roiId: number }[];
  };
} & UpsertRoiRecistEvaluationData;

export function useDeleteContour(): MutationTuple<Data, Variables> {
  return useMutation<Data, Variables>(MUTATION, {
    update(cache, { data }) {
      if (!data) {
        throw new Error("Something went wrong updating the cache after deleting a contour");
      }
      const {
        delete_contour: { returning },
        insert_roi_recist_evaluations: { roiRecistEvaluations },
      } = data;
      updateRoiRecistEvaluationsInCache(roiRecistEvaluations, cache);
      const roiIds = returning.map(({ roiId }) => roiId);
      const contours = returning.map(({ id, instanceId }) => ({
        id,
        instanceId,
      }));

      const uniqueRoiIds = [...new Set(roiIds)];
      if (uniqueRoiIds.length !== 1) {
        throw new Error(
          "There are multiple roi ids when trying to update the cache after deleting contours"
        );
      }
      const roiId = uniqueRoiIds[0];

      deleteContoursFromCache(roiId, contours, cache);

      cache.gc();
    },
  });
}
