import { ApolloCache } from "@apollo/client/core";

import { LESION } from "../../../../../types/LesionTypeType";
import {
  LesionListItemFragmentType,
  LesionsListQueryInput,
} from "../fragments/LesionListItemFragment";
import { isLesionLesion } from "../utils/isLesionLesion";
import { isLesionOrgan } from "../utils/isLesionOrgan";
import { getLesionsListFromCache } from "./getLesionsListFromCache";

const LESION_BASE_NAME = "LESION";

export function getNewLesionNameFromCache<T>(
  lesionListQueryInput: LesionsListQueryInput,
  cache: ApolloCache<T>
): string {
  const lesions = getLesionsListFromCache(lesionListQueryInput, cache);

  return getNewLesionName(lesions, LESION_BASE_NAME, isLesionLesion, true);
}

export function getNewLabelNameFromCache<T>(
  lesionListQueryInput: LesionsListQueryInput,
  anatomicalStructureId: number,
  labelName: string,
  cache: ApolloCache<T>
): string {
  const lesions = getLesionsListFromCache(lesionListQueryInput, cache);

  const existingNamesFilter = (lesion: LesionListItemFragmentType) =>
    isLesionOrgan(lesion) && lesion.location === anatomicalStructureId;

  return getNewLesionName(lesions, labelName, existingNamesFilter, false);
}

function getNewLesionName(
  lesions: LesionListItemFragmentType[],
  baseName: string,
  existingNamesFilter: (lesion: LesionListItemFragmentType) => boolean,
  numberFirstLabel: boolean
): string {
  const existingNames = lesions.filter(existingNamesFilter).map(({ name }) => name);

  const existingLesionNumbers = existingNames.map((name) =>
    getLesionNumber(name, existingNames.length, baseName)
  );

  if (!numberFirstLabel && existingLesionNumbers.length === 0) {
    return baseName;
  }

  let maxExistingLesionNumber =
    existingLesionNumbers.length > 0 ? Math.max(...existingLesionNumbers) : 1;

  let lesionName = formatLesionName(maxExistingLesionNumber, baseName);

  while (existingNames.includes(lesionName)) {
    maxExistingLesionNumber += 1;
    lesionName = formatLesionName(maxExistingLesionNumber, baseName);
  }

  return lesionName;
}

function formatLesionName(number: number, baseName: string): string {
  return `${baseName} ${number}`;
}

function getLesionNumber(name: string, defaultNumber: number, baseName: string): number {
  if (!name.includes(baseName)) {
    return defaultNumber;
  }

  const nameWithoutBasename = name.replace(baseName, "");
  if (!nameWithoutBasename) {
    return defaultNumber;
  }

  const number = parseInt(nameWithoutBasename);
  if (isNaN(number)) {
    return defaultNumber;
  }

  return number;
}
