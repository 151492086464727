import React, { ReactElement } from "react";
import {
  Bar,
  BarChart,
  Cell,
  Legend as ReChartsLegend,
  ResponsiveContainer,
  Tooltip as ReChartsTooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import { CategoricalChartState } from "recharts/types/chart/generateCategoricalChart";
import { Payload } from "recharts/types/component/DefaultLegendContent";
import styled from "styled-components";

import { ImageCaptureContextMenuContainer } from "../../../common/components/ImageCaptureContextMenuContainer";
import { Legend } from "../../common/components/Legend";
import {
  COMPLETE_RESPONSE,
  DISPLAY_NAMES,
  PARTIAL_RESPONSE,
  PROGRESSIVE_DISEASE,
  STABLE_DISEASE,
  TumourResponseType,
} from "../../common/types/TumourResponseType";
import { followUpIds } from "../../common/utils/followUpIds";
import { formatDaysInMonths } from "../../common/utils/formatDaysInMonths";
import { getXAxisProps } from "../../common/utils/getXAxisProps";
import { getYAxisProps } from "../../common/utils/getYAxisProps";
import { tumourResponseColors } from "../../common/utils/tumourResponseColors";
import { SubjectResponseProgressionType } from "../types/SubjectResponseProgressionType";
import { PatientJourneyChartTooltip } from "./PatientJourneyChartTooltip";

interface PatientJourneyChartProps {
  subjectResponseProgressions: SubjectResponseProgressionType[];
  onClickPatient?: (patientId: number) => void;
}

const Wrapper = styled.div`
  flex: 1;
`;

const displayedResponseTypes: TumourResponseType[] = [
  PROGRESSIVE_DISEASE,
  STABLE_DISEASE,
  PARTIAL_RESPONSE,
  COMPLETE_RESPONSE,
];

const legendPayload: Payload[] = displayedResponseTypes.map((key) => {
  const response: TumourResponseType = key as TumourResponseType;
  const color: string = tumourResponseColors[response] ?? "black";
  return {
    type: "square",
    color,
    value: DISPLAY_NAMES[response],
  };
});

function PatientJourneyChart({
  subjectResponseProgressions,
  onClickPatient,
}: PatientJourneyChartProps): React.ReactElement {
  const renderToolTip = (props: TooltipProps<number, string>) => (
    <PatientJourneyChartTooltip {...props} />
  );

  const handleClickSubject = (data: CategoricalChartState) => {
    if (!data || !onClickPatient) {
      return;
    }

    const { activePayload } = data;
    if (!activePayload || activePayload.length < 1) {
      return;
    }

    const { payload: subjectResponseProgression } = activePayload[0];

    const { id } = subjectResponseProgression;
    onClickPatient(id);
  };

  const getValue = (data: SubjectResponseProgressionType, followUpOrder: number): number => {
    const followUp = data.followUpResponses[followUpOrder];
    return followUp?.elapsedDays;
  };

  // eslint-disable-next-line react/display-name
  const getCell =
    (followUpOrder: number) =>
    (subjectResponseProgression: SubjectResponseProgressionType): ReactElement | null => {
      const { followUpResponses } = subjectResponseProgression;
      const followUp = followUpResponses[followUpOrder];
      if (!followUp) {
        return <Cell fill={"transparent"} key={followUpOrder} />;
      }
      const { response } = followUp;
      const color = tumourResponseColors[response];
      return <Cell fill={color} key={followUpOrder} />;
    };

  const xAxisProps = getXAxisProps();
  const yAxisProps = getYAxisProps(false);

  return (
    <Wrapper>
      <ImageCaptureContextMenuContainer>
        {({ reference }) => (
          <ResponsiveContainer ref={reference} height={700}>
            <BarChart
              margin={{ top: 0, right: 0, left: 30, bottom: -40 }}
              layout={"vertical"}
              data={subjectResponseProgressions}
              height={700}
              width={1000}
              onClick={handleClickSubject}
              barGap={0}
              barCategoryGap={0}
            >
              <ReChartsTooltip content={renderToolTip} isAnimationActive={false} />
              <ReChartsLegend
                align="left"
                verticalAlign="top"
                height={48}
                content={<Legend />}
                payload={legendPayload}
              />
              <XAxis
                {...xAxisProps}
                allowDuplicatedCategory={false}
                type={"number"}
                tickFormatter={formatDaysInMonths}
              />
              <YAxis
                dataKey={"subjectId"}
                type={"category"}
                reversed={true}
                tick={false}
                {...yAxisProps}
              />
              {followUpIds.map((followUp) => {
                return (
                  <Bar
                    isAnimationActive={false}
                    dataKey={(data) => getValue(data, followUp)}
                    stackId={"x"}
                    key={followUp.toString()}
                    legendType={"square"}
                  >
                    {subjectResponseProgressions
                      .map(getCell(followUp))
                      .filter((cell) => cell !== null)}
                  </Bar>
                );
              })}
            </BarChart>
          </ResponsiveContainer>
        )}
      </ImageCaptureContextMenuContainer>
    </Wrapper>
  );
}

export default PatientJourneyChart;
