import { matchSorter } from "match-sorter";
import React from "react";
import { Column, Row } from "react-table";
import styled from "styled-components";

import { AnatomicalStructuresFragmentType } from "../../../../Annotate/components/AnatomicalStructuresFragment";
import { ReactComponent as LockIcon } from "../../../../assets/svgs/Lock.svg";
import { ReactComponent as UsersIcon } from "../../../../assets/svgs/Users.svg";
import { SvgIcon } from "../../../../common/components/icons/SvgIcon";
import { OptionType } from "../../../../DataManagement/Upload/components/GenericMultiSelect";
import { getFilterColumnProps } from "../Users/GenericColumnFilter";
import { AnnotationTableRowType } from "./AnnotationTableRowType";
import { ClassificationLabel } from "./ClassificationLabel";
import { LabelColumnRenderer } from "./LabelColumnRenderer";
import { LabelEditColumn } from "./LabelEditColumn";
import { LabelLabel } from "./LabelLabel";
import {
  ANNOTATION,
  CLASSIFICATION,
  LabelTypeOptions,
  READ_ONLY,
  USER_DEFINED,
} from "./LabelTypeOptions";

const FilterIconWrapper = styled.div`
  display: flex;
  gap: 6px;
`;

interface Props {
  onAnnotationEditClicked: (label: AnatomicalStructuresFragmentType) => void;
}

export function useAnnotationTableColumns({
  onAnnotationEditClicked,
}: Props): Column<AnnotationTableRowType>[] {
  const typeOptions: LabelTypeOptions[] = [ANNOTATION, CLASSIFICATION];

  const userDefinedOptions: LabelTypeOptions[] = [READ_ONLY, USER_DEFINED];

  return [
    {
      id: "name",
      Header: "Annotation",
      disableFilters: false,
      filter: (rows: Row<AnnotationTableRowType>[], columnIds: string[], filteredValue: string) =>
        rows.filter(({ original: { classification, anatomicalStructure } }) => {
          const { text } = classification ?? {};
          const { name } = anatomicalStructure ?? {};
          return [text, name]
            .filter((field) => field)
            .some((field) => matchSorter([field], filteredValue).length > 0);
        }),
      accessor: (row) => row,
      // eslint-disable-next-line react/display-name
      Cell: ({ value }: { value: AnnotationTableRowType }) => (
        <LabelColumnRenderer
          row={value}
          AnatomicalStructureComponent={LabelLabel}
          ClassificationComponent={ClassificationLabel}
        />
      ),
    },
    {
      id: "type",
      Header: "Type",
      ...getFilterColumnProps(getLabelTypeValues, areLabelsEqual, {
        values: typeOptions,
        getOption: getLabelOption,
        includeUnassigned: false,
        placeholder: "Filter Type...",
        // eslint-disable-next-line react/display-name,react/prop-types
        RowLabelComponent: ({ value }) => <>{value}</>,
        // eslint-disable-next-line react/display-name,react/prop-types
        SelectLabelComponent: ({ value }) => <>{value}</>,
      }),
    },
    {
      id: "user-defined",
      Header: "User Defined",
      ...getFilterColumnProps(getLabelUserDefinedValues, areLabelsEqual, {
        values: userDefinedOptions,
        getOption: getLabelOption,
        includeUnassigned: false,
        placeholder: "Filter Type...",
        // eslint-disable-next-line react/display-name,react/prop-types
        RowLabelComponent: ({ value }) => (
          <>
            {value !== USER_DEFINED && value !== READ_ONLY ? (
              value
            ) : (
              <SvgIcon size={16} icon={value === USER_DEFINED ? UsersIcon : LockIcon} />
            )}
          </>
        ),
        // eslint-disable-next-line react/display-name,react/prop-types
        SelectLabelComponent: ({ value }) => (
          <>
            {value !== USER_DEFINED && value !== READ_ONLY ? (
              value
            ) : (
              <FilterIconWrapper>
                <SvgIcon size={16} icon={value === USER_DEFINED ? UsersIcon : LockIcon} />
                {value}
              </FilterIconWrapper>
            )}
          </>
        ),
      }),
    },
    {
      id: "edit",
      Header: "Edit",
      style: {
        width: "42px",
      },
      accessor: (label) => label,
      // eslint-disable-next-line react/display-name
      Cell: ({ value }: { value: AnnotationTableRowType }) => (
        <LabelColumnRenderer
          row={value}
          AnatomicalStructureComponent={({ label }) => (
            <LabelEditColumn label={label} onLabelEditClicked={onAnnotationEditClicked} />
          )}
          ClassificationComponent={() => <>N/A</>}
        />
      ),
    },
  ];
}

function getLabelOption(
  label: LabelTypeOptions | undefined
): OptionType<LabelTypeOptions | undefined> {
  if (!label) {
    return {
      value: undefined,
      label: "Unassigned",
    };
  }

  return { value: label, label };
}

function getLabelTypeValues({
  classification,
  anatomicalStructure,
}: AnnotationTableRowType): LabelTypeOptions[] {
  if (classification) {
    return [CLASSIFICATION];
  }
  if (anatomicalStructure) {
    return [ANNOTATION];
  }
  throw new Error("Label without classification/anatomy accessed");
}

function getLabelUserDefinedValues({
  classification,
  anatomicalStructure,
}: AnnotationTableRowType): LabelTypeOptions[] {
  if (classification) {
    const { readonly } = classification;
    return [readonly ? READ_ONLY : USER_DEFINED];
  }
  if (anatomicalStructure) {
    const { readonly } = anatomicalStructure;
    return [readonly ? READ_ONLY : USER_DEFINED];
  }
  throw new Error("Label without classification/anatomy accessed");
}

function areLabelsEqual(a: LabelTypeOptions, b: LabelTypeOptions): boolean {
  return a === b;
}
