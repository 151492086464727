import { AnatomicalStructureOptionType } from "nota-predict-web/src/Annotate/components/AnatomicalStructureOptionType";

import { AnatomicalStructuresFragmentType } from "../../../../Annotate/components/AnatomicalStructuresFragment";
import { getAnatomicalStructureOptions } from "../../../../Annotate/components/Annotate/page/AnnotationPanel/AnatomicalStructureSelector/getAnatomicalStructureOptions";
import { ClassificationType } from "../../../../Annotate/components/Annotate/page/AnnotationPanel/types/ClassificationType";
import { areClassificationsComplete } from "../../../../Annotate/components/Annotate/page/AnnotationPanel/utils/areClassificationsComplete";
import {
  areClassificationMapsEqual,
  areClassificationsEqual,
  isAnatomyEqual,
} from "../../../../Annotate/components/Annotate/page/AnnotationPanel/utils/areClassificationsEqual";
import { getClassificationsMap } from "../../../../Annotate/components/Annotate/page/AnnotationPanel/utils/getClassificationsMap";
import { isAnatomicalStructureLesion } from "../../../../Annotate/components/isAnatomicalStructureLesion";
import { ClassificationValuesType } from "../../../../Annotate/types/TaskDescriptionType";
import { ClassificationsMapType } from "../ClassificationsMapType";
import { ClassificationsDefinitionsType } from "../classificationsSlice";

export function getClassificationsDefinitions(
  classifications: ClassificationType[],
  classificationsMapTemplate: ClassificationsMapType,
  useAnatomySelector: boolean,
  anatomicalStructures: AnatomicalStructuresFragmentType[] | undefined,
  existingState: ClassificationsDefinitionsType | undefined,
  location?: number | null
): ClassificationsDefinitionsType {
  const classificationValues: ClassificationValuesType[] = classifications.map(
    ({ classification }) => classification
  );
  const classificationsMap = getClassificationsMap(
    classificationsMapTemplate,
    classificationValues
  );
  let current: AnatomicalStructureOptionType | null = null;

  let options: ReturnType<typeof getAnatomicalStructureOptions> | undefined = undefined;

  if (anatomicalStructures) {
    options = getAnatomicalStructureOptions(anatomicalStructures);
  }

  if (options) {
    current =
      options.find(({ value: anatomy }) => {
        const { id } = anatomy;
        return id === location && !isAnatomicalStructureLesion(anatomy);
      }) ?? null;
  }

  const editing = !areClassificationsComplete(classificationsMap, useAnatomySelector, current);

  if (existingState !== undefined) {
    const { classificationsMapInitial, initialAnatomicalStructure, useAnatomySelector } =
      existingState;
    // if new classifications and new anatomy from db are equal to previous initial values, no need to update state
    // because nothing has changed and we don't want to update selected anatomy or classifications proxy.
    if (
      areClassificationsEqual(
        classificationsMapInitial,
        classificationsMap,
        useAnatomySelector,
        current?.value?.id,
        initialAnatomicalStructure?.value?.id
      )
    ) {
      return existingState;
    }
    // if classifications from db are not equal to previous initial values but anatomy from db is, update
    // classifications only as anatomy has not changed and we don't want to update current selected.
    if (
      !areClassificationMapsEqual(classificationsMapInitial, classificationsMap) &&
      isAnatomyEqual(useAnatomySelector, current?.value?.id, initialAnatomicalStructure?.value?.id)
    ) {
      return {
        ...existingState,
        editing,
        classificationsMapProxy: classificationsMap,
        classificationsMapInitial: classificationsMap,
      };
    }
    // if anatomy from db is not equal to previous initial values but classifications from db are, update anatomy only
    // as classifications have not changed and we don't want to update current proxy.
    if (
      areClassificationMapsEqual(classificationsMapInitial, classificationsMap) &&
      !isAnatomyEqual(useAnatomySelector, current?.value?.id, initialAnatomicalStructure?.value?.id)
    ) {
      return {
        ...existingState,
        editing,
        selectedAnatomicalStructure: current,
        initialAnatomicalStructure: current,
      };
    }
  }

  return {
    classificationsMapProxy: classificationsMap,
    selectedAnatomicalStructure: current,
    classificationsMapInitial: classificationsMap,
    useAnatomySelector,
    editing,
    initialAnatomicalStructure: current,
  };
}
