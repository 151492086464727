import FileCopyIcon from "@mui/icons-material/FileCopy";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import React, { FC, useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";

import { ExpandCollapseButton } from "../../../Annotate/components/Annotate/page/common/ExpandCollapseButton";
import { ReactComponent as CheckIcon } from "../../../assets/svgs/CheckFilled.svg";
import { ReactComponent as ErrorIcon } from "../../../assets/svgs/Error.svg";
import { useGetAuthToken } from "../../../auth0/useGetAuthToken";
import { DialogText } from "../../../common/components/Dialog/DialogText";
import { ActionButtonsWrapper } from "../../../common/components/Dialog/Form/ActionButtonsWrapper";
import { FormProps } from "../../../common/components/Dialog/FormProps";
import { useOkCancelForm } from "../../../common/components/Dialog/useOkCancelForm";
import { useOkForm } from "../../../common/components/Dialog/useOkForm";
import { SvgIcon } from "../../../common/components/icons/SvgIcon";
import { ErrorLabel } from "../../../common/components/input/ErrorLabel";
import { Input } from "../../../common/components/input/Input";
import {
  InnerButtonWrapper,
  InputButton,
  InputDiv,
} from "../../../common/components/input/InputButton";
import { Label, SelectLabel } from "../../../common/components/input/Label";
import { FlexLoading } from "../../../common/components/Loading";
import { useCurrentUser } from "../../../common/contexts/UserContext/useCurrentUser";
import { main } from "../../../common/theme/main";
import { CohortType } from "../../../common/types/CohortType";
import handleApolloError from "../../../common/utils/handleApolloError";
import { ProjectContext } from "../../../Project/contexts/ProjectContext";
import { useProjectId } from "../../../Project/hooks/useProjectId";
import { GenericSingleSelect } from "./GenericSingleSelect";
import { S3ConfigType } from "./S3ConfigType";
import { useProjectS3Config } from "./useProjectS3Config";
import { useUpsertProjectS3Config } from "./useUpsertProjectS3Config";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 400px;
`;

const RowWrapper = styled.div<{
  canClick?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: ${({ canClick }) => (canClick ? "pointer" : "default")};
`;

const Text = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
`;

const Bold = styled.span`
  font-weight: bold;
`;

export const S3BucketConfigForm: FC<FormProps<undefined>> = ({
  onSubmit,
  onCancel,
}: FormProps<undefined>) => {
  const projectId = useProjectId();
  const { data: projectS3Config, loading: configLoading, error } = useProjectS3Config(projectId);
  if (error) handleApolloError(error);

  const {
    register,
    handleSubmit,
    formState: { isDirty, errors },
    control,
    getValues,
    reset,
  } = useForm<S3ConfigType>({
    defaultValues: projectS3Config,
  });

  const [selectedCohortId, setSelectedCohortId] = useState<number | undefined>(
    projectS3Config ? projectS3Config.cohortId : undefined
  );
  const [loading, setLoading] = useState<boolean>(configLoading);
  const [testCompleted, setTestCompleted] = useState<boolean>(false);
  const [testPassed, setTestPassed] = useState<boolean>(false);

  const [permissionsCollapsed, setPermissionsCollapsed] = useState<boolean>(true);

  useEffect(() => {
    setSelectedCohortId(projectS3Config?.cohortId);
    reset(projectS3Config);
  }, [projectS3Config, reset]);

  useEffect(() => {
    setLoading(configLoading);
  }, [configLoading]);

  const s3PolicyARN = window._env_.REACT_APP_S3_BUCKET_UPLOAD_ROLE_ARN;
  const { id: userId } = useCurrentUser();
  const { cohorts } = useContext(ProjectContext);
  const [upsertS3Config] = useUpsertProjectS3Config();

  const apiUrl = window._env_.REACT_APP_CELERY_API_URL;
  const testS3ConfigUrl = apiUrl + "/upload/verify-s3/";
  const S3UploadUrl = apiUrl + "/s3-upload/";

  const getToken = useGetAuthToken();

  const handleUpsertConfig = async (config: S3ConfigType): Promise<S3ConfigType> => {
    const { bucketName, bucketPrefix, cohortId } = config;
    const variables = {
      s3Config: {
        project_id: projectId,
        created_by: userId,
        bucket_name: bucketName,
        bucket_prefix: bucketPrefix,
        cohort_label_id: cohortId,
      },
    };
    const { data } = await upsertS3Config({ variables });

    if (!data) {
      throw new Error("Inserted S3 Config is undefined");
    }
    return data.s3BucketConfig;
  };

  const handleSubmitForm = async () => {
    const token = await getToken();

    if (!projectS3Config) {
      throw new Error("No project S3 config");
    }
    const { id: s3_bucket_config_id } = projectS3Config;

    try {
      await axios.post(S3UploadUrl, undefined, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          s3_bucket_config_id,
        },
      });
    } catch (e) {
      console.error("An error occurred when triggering the S3 bucket upload:", e);
    }

    onSubmit();
  };

  const handleConfirmCancel = () => {
    onCancel();
  };

  const [showTestFailedDialog, { dialog: testFailedDialog }] = useOkForm({
    title: `Bucket Permission Verification Failed`,
    message:
      "Verification of bucket failed. Ensure that the policy is correctly applied to the target bucket",
  });

  const [setShowCancelDialog, { dialog: cancelDialog }] = useOkCancelForm({
    title: "Cancel S3 Bucket Configuration",
    message: "Unsaved data will be lost.",
    okLabel: "Confirm",
    onOkCallback: handleConfirmCancel,
  });

  const handleTest = async (config: S3ConfigType) => {
    setLoading(true);
    setTestCompleted(false);

    const token = await getToken();

    const { id: s3_bucket_config_id } = await handleUpsertConfig(config);

    try {
      const { data: verified }: { data: boolean } = await axios.get(testS3ConfigUrl, {
        params: {
          s3_bucket_config_id,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setTestPassed(verified);
      if (!verified) {
        showTestFailedDialog(true);
      }
    } catch (e) {
      console.error("An error occurred when triggering the S3 config verification:", e);
    }

    setLoading(false);
    setTestCompleted(true);
  };

  const resetTestStatus = () => {
    setTestCompleted(false);
    setTestPassed(false);
  };

  const handleCancel = () => {
    if (projectS3Config && isDirty) {
      setShowCancelDialog(true);
    } else {
      handleConfirmCancel();
    }
  };

  const handleCopyPolicyClicked = () => {
    const bucketName = getValues("bucketName");
    const bucketPrefix = getValues("bucketPrefix");

    const policy = {
      Version: "2012-10-17",
      Statement: [
        {
          Effect: "Allow",
          Principal: {
            AWS: s3PolicyARN,
          },
          Action: "s3:ListBucket",
          Resource: `arn:aws:s3:::${bucketName}`,
        },
        {
          Effect: "Allow",
          Principal: {
            AWS: s3PolicyARN,
          },
          Action: "s3:GetObject",
          Resource: `arn:aws:s3:::${bucketName}/${bucketPrefix ? `${bucketPrefix}/` : ""}*`,
        },
      ],
    };

    navigator.clipboard.writeText(JSON.stringify(policy));
  };

  const handleCohortChanged = (cohort: CohortType | null | undefined) => {
    if (cohort) {
      setSelectedCohortId(cohort.id);
      return cohort.id;
    }
    return undefined;
  };

  const handlePermissionsClicked = () => {
    setPermissionsCollapsed(!permissionsCollapsed);
  };

  const selectedCohort = cohorts.find(({ id }) => selectedCohortId === id);

  return (
    <>
      {testFailedDialog}
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        {cancelDialog}
        <Wrapper>
          <div>
            <Label htmlFor={"bucketName"} required>
              Bucket Name
            </Label>
            <Input
              type="text"
              id={"bucketName"}
              autoFocus
              autoComplete={"off"}
              disabled={loading}
              {...register("bucketName", {
                required: "A bucket name must be provided!",
                validate: validateBucketName,
                onChange: resetTestStatus,
              })}
              error={errors.bucketName}
            />
            {errors.bucketName && errors.bucketName.message && (
              <ErrorLabel>{errors.bucketName.message}</ErrorLabel>
            )}
          </div>
          <div>
            <Label htmlFor={"bucketPrefix"}>Folder Prefix</Label>
            <Input
              type="text"
              id={"bucketPrefix"}
              autoComplete={"off"}
              disabled={loading}
              {...register("bucketPrefix", {
                validate: validateBucketPrefix,
                onChange: resetTestStatus,
              })}
              error={errors.bucketPrefix}
            />
            {errors.bucketPrefix && errors.bucketPrefix.message && (
              <ErrorLabel>{errors.bucketPrefix.message}</ErrorLabel>
            )}
          </div>
          <Controller
            control={control}
            name="cohortId"
            rules={{ required: true }}
            render={({ field: { onChange } }) => (
              <div>
                <SelectLabel htmlFor={"cohort"} required error={errors.cohortId}>
                  Cohort
                </SelectLabel>
                <GenericSingleSelect
                  id={"cohort"}
                  values={cohorts}
                  selected={selectedCohort}
                  onSelectedChanged={(e) => onChange(handleCohortChanged(e))}
                  getOption={(cohort) => ({
                    value: cohort,
                    label: cohort?.name ?? "undefined",
                  })}
                  menuPortalTarget={document.body}
                  isDisabled={loading}
                />
                {errors.cohortId && <ErrorLabel>A cohort must be selected</ErrorLabel>}
              </div>
            )}
          />
          <RowWrapper onClick={handlePermissionsClicked} canClick={true}>
            <Text>Permissions</Text>
            <ExpandCollapseButton collapsed={permissionsCollapsed} />
          </RowWrapper>
          {!permissionsCollapsed && (
            <RowWrapper>
              <DialogText>
                The bucket must have a policy which allows at a minimum s3:Listbucket and
                s3:GetObject permissions for the following user <Bold>{s3PolicyARN}</Bold>
              </DialogText>
              <Tooltip title={"Copy policy to clipboard"}>
                <FileCopyIcon
                  style={{ fontSize: 16 }}
                  cursor="pointer"
                  onClick={handleCopyPolicyClicked}
                />
              </Tooltip>
            </RowWrapper>
          )}
        </Wrapper>
        <ActionButtonsWrapper>
          <InputDiv
            type="button"
            color={main.colors.neutral.black}
            background={main.colors.neutral.white}
            width={84}
            onClick={handleSubmit(handleTest)}
            disabled={loading}
          >
            <InnerButtonWrapper>
              {loading && <FlexLoading />}
              {testCompleted && (
                <SvgIcon
                  icon={testPassed ? CheckIcon : ErrorIcon}
                  size={20}
                  color={testPassed ? main.colors.states.success : main.colors.states.error}
                />
              )}
              Test
            </InnerButtonWrapper>
          </InputDiv>
          <InputButton
            type="submit"
            name="submit-button"
            value={"Upload"}
            background={!testPassed ? main.colors.actionPrimary.disabled : undefined}
            borderColor={!testPassed ? main.colors.actionPrimary.hover10 : undefined}
            disabled={!testPassed}
          />
          <InputButton
            type="button"
            name="cancel-button"
            value={"Cancel"}
            background={main.colors.neutral.white}
            color={main.colors.neutral.black}
            onClick={handleCancel}
          />
        </ActionButtonsWrapper>
      </form>
    </>
  );
};

function validateBucketName(name: string): string | boolean {
  const regex = /^[a-z\d][a-z\d.-]+[a-z\d]$/;
  if (name.length < 3 || name.length > 63) {
    return "Bucket names must be between 3 (min) and 63 (max) characters long.";
  }
  if (name.startsWith("xn--")) {
    return "Bucket names must not start with the prefix xn--.";
  }
  if (name.endsWith("-s3alias")) {
    return "Bucket names must not end with the suffix -s3alias.";
  }
  if (name.includes("..")) {
    return "Bucket names must not contain two adjacent periods.";
  }
  if (!regex.test(name)) {
    return "Bucket name is invalid.";
  }
  return true;
}

function validateBucketPrefix(prefix: string | null): string | boolean {
  const regex = /^[a-zA-Z\d!_.*'()-/]*$/;
  if (prefix && !regex.test(prefix)) {
    return "Bucket prefix is invalid.";
  }
  return true;
}
