import React, { ReactElement } from "react";
import { default as ReactModal } from "react-modal";

import { main } from "../../theme/main";

export type ReactModalOverrides = Omit<ReactModal.Props, "isOpen" | "requestClose">;

interface DialogWrapperProps {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  children: JSX.Element | JSX.Element[];
  reactModalProps?: ReactModalOverrides;
}

export function DialogWrapper({
  open,
  setOpen,
  children,
  reactModalProps,
}: DialogWrapperProps): ReactElement {
  const style = {
    overlay: {
      backgroundColor: main.colors.primary.primary70,
      zIndex: 1000,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: main.colors.actionPrimary.inverted,
      borderRadius: "12px",
      padding: "12px",
    },
  };

  const handleOnClose = () => {
    setOpen(false);
  };

  return (
    <ReactModal isOpen={open} onRequestClose={handleOnClose} style={style} {...reactModalProps}>
      {children}
    </ReactModal>
  );
}
