import Tooltip from "@mui/material/Tooltip";
import React, { ReactNode } from "react";
import styled from "styled-components";

import { SvgIcon } from "../../../common/components/icons/SvgIcon";
import { Loading } from "../../../common/components/Loading";
import { main } from "../../../common/theme/main";

const Wrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  padding: 6px;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-size: 12px;
  white-space: nowrap;

  border: 1px solid
    ${({ theme, disabled }) => (disabled ? "transparent" : theme.colors.neutral.neutral5)};
  cursor: ${({ disabled }) => (disabled ? "unset" : "pointer")};
  background: ${({ theme, disabled }) => (disabled ? theme.colors.neutral.neutral8 : "unset")};
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.neutral.neutral4 : theme.colors.neutral.black};
`;

interface ActionButtonProps {
  tooltip: NonNullable<ReactNode>;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  label: ReactNode;
  loading?: boolean;
  disabled?: boolean;
}

export function ActionButton({
  tooltip,
  onClick,
  icon: Icon,
  label,
  loading = false,
  disabled = false,
}: ActionButtonProps): JSX.Element {
  const {
    colors: {
      neutral: { black, neutral4, neutral7 },
    },
  } = main;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (disabled) {
      throw new Error("Should not handle on click when disabled");
    }

    onClick?.(event);
  };

  return (
    <Tooltip title={tooltip}>
      <Wrapper disabled={disabled} onClick={!disabled ? handleClick : undefined}>
        {loading ? (
          <Loading color={neutral7} />
        ) : (
          <SvgIcon icon={Icon} size={16} color={disabled ? neutral4 : black} />
        )}
        {label}
      </Wrapper>
    </Tooltip>
  );
}
