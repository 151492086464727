import React, { forwardRef, useRef, useState } from "react";
import styled from "styled-components";

import { AnatomicalStructuresFragmentType } from "../../../../Annotate/components/AnatomicalStructuresFragment";
import { ActionButton } from "../../../../Annotate/components/Manage/ActionButton";
import {
  ClassificationAttributeType,
  useClassificationAttributes,
} from "../../../../Annotate/components/Manage/hooks/useClassificationAttributes";
import { useAnatomicalStructures } from "../../../../Annotate/components/useAnatomicalStructures";
import { ReactComponent as CancelIcon } from "../../../../assets/svgs/CancelOutline.svg";
import { ReactComponent as AddIcon } from "../../../../assets/svgs/PlusOutline.svg";
import { Card } from "../../../../common/components/cards/Card";
import { BigFlexLoading } from "../../../../common/components/Loading";
import { Table, TableProps } from "../../../../common/components/Table/Table";
import { TableControlsType } from "../../../../common/components/Table/TableControlsType";
import { AnnotationTableRowType } from "./AnnotationTableRowType";
import { getAnnotationTableRows } from "./getAnnotationTableRows";
import { useAnnotationTableColumns } from "./useAnnotationTableColumns";
import { useCreateAnnotationForm } from "./useCreateAnnotationForm";
import { useCreateClassificationForm } from "./useCreateClassificationForm";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ButtonsWrapper = styled.div`
  flex: 0;
  display: flex;
  padding: 4px;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

export function GlobalAnnotationManagement(): JSX.Element {
  const tableRef = useRef<TableControlsType<AnnotationTableRowType>>(null);

  const [selectedAnnotation, setSelectedAnnotation] = useState<
    AnatomicalStructuresFragmentType | undefined
  >(undefined);
  const [selectedClassification, setSelectedClassification] = useState<
    ClassificationAttributeType | undefined
  >(undefined);
  const {
    data: anatomicalStructures = [] as AnatomicalStructuresFragmentType[],
    loading: anatomicalStructuresLoading,
  } = useAnatomicalStructures();
  const { data: classificationsData, loading: classificationsLoading } =
    useClassificationAttributes();

  const [setShowCreateAnnotationForm, { dialog: createAnnotationDialog }] = useCreateAnnotationForm(
    {
      annotation: selectedAnnotation,
    }
  );

  const [setShowCreateClassificationForm, { dialog: createClassificationDialog }] =
    useCreateClassificationForm({ classification: selectedClassification });

  const handleEditAnnotationClicked = (annotation: AnatomicalStructuresFragmentType) => {
    setSelectedAnnotation(annotation);
    setShowCreateAnnotationForm(true);
  };

  const handleCreateAnnotation = () => {
    setSelectedAnnotation(undefined);
    setShowCreateAnnotationForm(true);
  };

  const handleCreateClassification = () => {
    setSelectedClassification(undefined);
    setShowCreateClassificationForm(true);
  };

  const handleClearClicked = () => {
    const { current: tableControls } = tableRef;
    tableControls?.clearAllFilters();
  };

  const columns = useAnnotationTableColumns({
    onAnnotationEditClicked: handleEditAnnotationClicked,
  });

  if (anatomicalStructuresLoading || classificationsLoading) {
    return <BigFlexLoading />;
  }

  if (!classificationsData) {
    throw new Error("Classifications loaded but data undefined!");
  }

  const { classificationAttributes } = classificationsData;

  const data = getAnnotationTableRows(classificationAttributes, anatomicalStructures);

  return (
    <Card
      noHead
      expand={true}
      content={
        <Wrapper>
          {createAnnotationDialog}
          {createClassificationDialog}
          <ButtonsWrapper>
            <ActionButton
              label={"Clear"}
              icon={CancelIcon}
              onClick={handleClearClicked}
              tooltip={"Clear all selections/filters"}
            />
            <ActionButton
              label={"Create Annotation"}
              icon={AddIcon}
              onClick={handleCreateAnnotation}
              tooltip={"Create a new annotation"}
            />
            <ActionButton
              label={"Create Classification"}
              icon={AddIcon}
              onClick={handleCreateClassification}
              tooltip={"Create a new classification"}
            />
          </ButtonsWrapper>
          <TableWithRef ref={tableRef} columns={columns} data={data} enableSelect={false} />
        </Wrapper>
      }
    />
  );
}

const TableWithRef = forwardRef<
  TableControlsType<AnnotationTableRowType>,
  TableProps<AnnotationTableRowType>
>((props, ref) => {
  return <Table {...props} forwardedRef={ref} />;
});
TableWithRef.displayName = "TableWithRef";
