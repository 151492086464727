import dayjs from "dayjs";
import React from "react";
import {
  Legend as ReChartsLegend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip as ReChartTooltip,
  XAxis,
  YAxis,
} from "recharts";
import styled from "styled-components";

import { Legend } from "../../../Analysis/common/components/Legend";
import { Tooltip } from "../../../Analysis/common/components/Tooltip";
import { formatPercentage } from "../../../Analysis/common/utils/formatPercentage";
import { getXAxisProps } from "../../../Analysis/common/utils/getXAxisProps";
import { getYAxisLabel } from "../../../Analysis/common/utils/getYAxisLabel";
import { getYAxisProps } from "../../../Analysis/common/utils/getYAxisProps";
import {
  DASHED_STROKE_DASH_ARRAY,
  STROKE_WIDTH,
} from "../../../Analysis/common/utils/strokeSettings";
import { ImageCaptureContextMenuContainer } from "../../../common/components/ImageCaptureContextMenuContainer";
import {
  colorConfig,
  formatBurndownLabel,
  getProjectBurndownTicks,
  ParsedProjectCompletionBurndownDataProps,
  projectBurndownTickFormater,
} from "../utils/parseProjectsCompletionBurndown";

const Wrapper = styled.div`
  flex: 1;
`;

interface ProjectCompletionBurndownPropsChart {
  data: ParsedProjectCompletionBurndownDataProps[];
}

function ProjectCompletionBurndownChart({
  data,
}: ProjectCompletionBurndownPropsChart): React.ReactElement {
  const xAxisProps = getXAxisProps();
  const yAxisLabel = getYAxisLabel("Percent");
  const yAxisProps = getYAxisProps(true);

  return (
    <Wrapper>
      <ImageCaptureContextMenuContainer>
        {({ reference }) => (
          <ResponsiveContainer ref={reference} height={500}>
            <LineChart data={data} margin={{ top: 0, right: 0, left: 20, bottom: -40 }}>
              <XAxis
                dataKey={"date"}
                tickFormatter={(tick) => dayjs(tick).format("YYYY")}
                interval={11}
                {...xAxisProps}
                allowDuplicatedCategory={false}
              />
              <YAxis
                {...yAxisProps}
                type={"number"}
                ticks={getProjectBurndownTicks(data)}
                tickFormatter={(tick) => projectBurndownTickFormater(tick, data)}
              >
                {yAxisLabel}
              </YAxis>
              <ReChartTooltip
                isAnimationActive={false}
                content={
                  <Tooltip formatLabel={formatBurndownLabel} formatValue={formatPercentage} />
                }
              />
              <ReChartsLegend align="left" verticalAlign="top" height={48} content={<Legend />} />
              <Line
                type={"linear"}
                dataKey={"target"}
                stroke={colorConfig.TARGET}
                dot={false}
                strokeDasharray={DASHED_STROKE_DASH_ARRAY}
                legendType={"plainline"}
                isAnimationActive={false}
                name={"Target"}
                strokeWidth={STROKE_WIDTH}
              />
              <Line
                type={"step"}
                dataKey={"estimated"}
                dot={false}
                stroke={colorConfig.ESTIMATE}
                legendType={"plainline"}
                isAnimationActive={false}
                name={"Estimated"}
                strokeWidth={STROKE_WIDTH}
              />
              <Line
                type={"step"}
                dataKey={"current"}
                stroke={colorConfig.CURRENT}
                dot={false}
                legendType={"plainline"}
                isAnimationActive={false}
                name={"Current"}
                strokeWidth={STROKE_WIDTH}
              />
            </LineChart>
          </ResponsiveContainer>
        )}
      </ImageCaptureContextMenuContainer>
    </Wrapper>
  );
}
export default ProjectCompletionBurndownChart;
