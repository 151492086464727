import { ApolloCache, gql } from "@apollo/client";

import { getSeriesCacheId } from "../../AnnotationPanel/hooks/getSeriesCacheId";

export type CacheInstanceType = {
  id: number;
  instanceNumber: number | null;
  sliceThickness: number | null;
  wadoUrl: string;
  imagePositionPatient: number[] | null;
  imageOrientationPatient: number[] | null;
};

export function getSeriesInstancesFromCache<T>(
  seriesId: number,
  cache: ApolloCache<T>
): CacheInstanceType[] {
  const instancesData = cache.readFragment<{
    instances: CacheInstanceType[];
  }>({
    id: getSeriesCacheId(seriesId, cache),
    fragment: gql`
      fragment SeriesInstances on series {
        instances {
          id: instance_id
          instanceNumber: instance_number
          sliceThickness: slice_thickness
          wadoUrl
          imagePositionPatient: image_position_patient
          imageOrientationPatient: image_orientation_patient
        }
      }
    `,
  });

  if (!instancesData) {
    throw new Error(`Unable to get instances for series id ${seriesId} from cache.`);
  }

  const { instances } = instancesData;
  return [...instances];
}
