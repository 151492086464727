import { useSelector } from "react-redux";

import { FOLLOW_UP_TEXT } from "../../../../Analysis/common/utils/FollowUpText";
import { formatFollowUp } from "../../../../Analysis/common/utils/formatFollowUp";
import { showFollowUpLabelsSelector } from "../../../../common/store/annotatePage/taskSelector";
import { DATE_UNKNOWN, formatDate } from "./formatDate";
import { getDate } from "../../../../common/utils/dateFormatUtils/getDate";

const UNKNOWN_FOLLOWUP = `Unknown ${FOLLOW_UP_TEXT}`;

type ReturnType = (date: string | undefined | null, followUpOrder: number | undefined) => string;

export function useGetFormatFollowUp(): ReturnType {
  const showFollowUpLabel = useSelector(showFollowUpLabelsSelector);

  return (date, followUpOrder) => {
    if (showFollowUpLabel) {
      if (followUpOrder == null) {
        return UNKNOWN_FOLLOWUP;
      }

      return formatFollowUp(followUpOrder);
    }

    if (!date || !getDate(date)) {
      return DATE_UNKNOWN;
    }

    return formatDate(date);
  };
}
