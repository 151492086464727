import { CacheInstanceType } from "../components/Annotate/page/ViewerPanel/utils/getSeriesInstancesFromCache";

export function sortSopInstances<T extends CacheInstanceType>(instances: T[]): T[] {
  const sortedInstances = [...instances];
  if (sortedInstances.length < 2) {
    return sortedInstances;
  }

  if (
    sortedInstances.every(
      ({ imageOrientationPatient, imagePositionPatient }) =>
        imageOrientationPatient &&
        imagePositionPatient &&
        imageOrientationPatient.length === 6 &&
        imagePositionPatient.length === 3
    )
  ) {
    const orientation = sortedInstances[0].imageOrientationPatient as number[];

    const crossProduct = [
      orientation[1] * orientation[5] - orientation[2] * orientation[4],
      orientation[2] * orientation[3] - orientation[0] * orientation[5],
      orientation[0] * orientation[4] - orientation[1] * orientation[3],
    ];
    const instancesWithDistances: {
      instance: T;
      distance: number;
    }[] = [];
    for (const instance of sortedInstances) {
      if (!instance.imagePositionPatient) {
        throw new Error("Instances should be sorted by instance ID if it is missing fields");
      }
      instancesWithDistances.push({
        instance: instance,
        distance:
          instance.imagePositionPatient[0] * crossProduct[0] +
          instance.imagePositionPatient[1] * crossProduct[1] +
          instance.imagePositionPatient[2] * crossProduct[2],
      });
    }

    instancesWithDistances.sort(({ distance: a }, { distance: b }) => {
      return b - a;
    });

    return instancesWithDistances.map(({ instance }) => instance);
  }

  sortedInstances.sort(({ instanceNumber: a }, { instanceNumber: b }) => {
    if (a === b) {
      return 0;
    }
    if (a === null) {
      return 1;
    }

    if (b === null) {
      return -1;
    }

    return a - b;
  });

  return sortedInstances;
}
