import { useSetViewerImageId } from "../../../../ViewerPanel/Viewer/hooks/useSetViewerImageId";
import { useTrySelectLesionId } from "../../../hooks/useTrySelectLesionId";
import { useSetLesionToScrollTo } from "../LesionToScrollTo/useSetLesionToScrollTo";
import { JumpToIdsType } from "./JumpToIdsType";
import { useJumpToSeries } from "./useJumpToSeries";

type ReturnType = (ids: JumpToIdsType) => void;

export function useGoToJumpToIds(): ReturnType {
  const setViewerImageId = useSetViewerImageId();

  const trySelectLesionId = useTrySelectLesionId();

  const jumpToSeries = useJumpToSeries();

  const setLesionToScrollTo = useSetLesionToScrollTo();

  return ({ imageId, ...lesion }: JumpToIdsType) => {
    if (!lesion) {
      return;
    }

    const { id: lesionId, rois } = lesion;
    //todo we can only support one roi right now until we can also store a imageId for each roi
    if (rois.length !== 1) {
      throw new Error(
        "None or more than one rois was passed to a function which currently only supports 1 roi"
      );
    }

    if (!imageId) {
      return;
    }

    const {
      series: { id: seriesId },
    } = rois[0];

    const viewerConfigId = jumpToSeries(seriesId);

    setLesionToScrollTo(lesion.id);

    if (viewerConfigId === undefined) {
      return;
    }

    setViewerImageId(viewerConfigId, imageId);

    if (!trySelectLesionId(lesionId)) {
      return;
    }
  };
}
