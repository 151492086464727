import { ClassificationValuesType } from "../../../../../../../types/TaskDescriptionType";
import { InputClassificationType } from "../types/InputClassificationType";

export function hasClassification(
  {
    classifications,
  }: {
    classifications: InputClassificationType[];
  },
  classification: ClassificationValuesType
): boolean {
  return classifications.some(
    ({ classification: classificationValue }) => classificationValue === classification
  );
}
