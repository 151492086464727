import { removeMeasurementsFromElement } from "../../ViewerPanel/tools/contour/utils/removeMeasurementsFromElement";
import { RoiListItemFragmentType } from "../fragments/RoiListItemFragment";

export function deleteRoiCornerstoneMeasurements({
  roiRecistEvaluation,
}: RoiListItemFragmentType): void {
  const { SADPoints, LADPoints } = roiRecistEvaluation || {};

  if (!SADPoints || !LADPoints) {
    return;
  }

  removeMeasurementsFromElement(SADPoints);
  removeMeasurementsFromElement(LADPoints);
}
