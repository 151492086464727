import { TOO_SMALL_TO_MEASURE } from "../../../../../../enums/TaskDescriptionEnums";
import { isRoiMeasurable } from "./isRoiMeasurable";
import { InputRoiType } from "./types/InputLesionType";
import { hasClassification } from "./utils/hasClassification";

type RoiBurden = {
  diametric: number | undefined;
  volumetric: number | undefined;
};

export function getRoiBurden(roi: InputRoiType, isNode: boolean): RoiBurden {
  if (!isRoiMeasurable(roi)) {
    return {
      diametric: undefined,
      volumetric: undefined,
    };
  }

  const { roiRecistEvaluation } = roi;
  const isTooSmallToMeasure = hasClassification(roi, TOO_SMALL_TO_MEASURE);

  const { SAD, LAD, volume } = roiRecistEvaluation ?? {
    LAD: undefined,
    SAD: undefined,
    volume: undefined,
  };

  const diametric = getDiametricBurden(LAD, SAD, isNode, isTooSmallToMeasure);

  return {
    diametric,
    volumetric: volume,
  };
}

function getDiametricBurden(
  LAD: number | undefined,
  SAD: number | undefined,
  isNode: boolean,
  isTooSmallToMeasure: boolean
): number | undefined {
  if (!SAD || !LAD) {
    if (isTooSmallToMeasure) {
      return 5;
    }

    return undefined;
  }

  return isNode ? SAD : LAD;
}
