import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { hiddenLesionIdsSelector } from "../../../../../../common/store/annotatePage/selectionSelector";
import { updateHiddenLesionIds } from "../../../../../../common/store/annotatePage/selectionSlice";
import { toggleLesionsMeasurementsVisibility } from "../../ViewerPanel/tools/measure/toggleLesionsMeasurementsVisibility";
import { LesionListItemFragmentType } from "../fragments/LesionListItemFragment";

export function useLesionListVisibilityRefresher(lesions: LesionListItemFragmentType[]): void {
  const dispatch = useDispatch();
  const [previousLesions, setPreviousLesions] = useState<LesionListItemFragmentType[]>(lesions);
  const hiddenLesionIds = useSelector(hiddenLesionIdsSelector);
  useEffect(() => {
    const newLesionIds = lesions.map(({ id }) => id);

    const previousLesionIds = previousLesions.map(({ id }) => id);

    const visible = previousLesions.length
      ? !previousLesionIds.every((id) => hiddenLesionIds.includes(id))
      : true;

    if (!areListsEqual(previousLesionIds, newLesionIds)) {
      const newLesionsNotInPreviousLesions = lesions.filter(
        ({ id: lesionId }) => !previousLesionIds.includes(lesionId)
      );
      toggleLesionsMeasurementsVisibility(newLesionsNotInPreviousLesions, visible);
      const newLesionIdsNotInPreviousLesions = newLesionsNotInPreviousLesions.map(({ id }) => id);
      dispatch(
        updateHiddenLesionIds({
          ids: newLesionIdsNotInPreviousLesions,
          visible,
        })
      );

      setPreviousLesions(lesions);
    }
  }, [hiddenLesionIds, lesions]);
}

function areListsEqual(arr1: number[], arr2: number[]): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }
  return arr1.every((value) => arr2.includes(value));
}
