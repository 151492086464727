import cloneDeep from "lodash/cloneDeep";
import { useContext } from "react";

import { ContourContext } from "../../../../../../../ContourContext";
import { ContourCallbackDataType } from "../types/ContourCallbackDataType";

type ReturnType = {
  copyContour: (data: ContourCallbackDataType, lesionId: number | null) => void;
};
export function useCopyContour(): ReturnType {
  const { setContourClipboardData } = useContext(ContourContext);

  const copyContour = (data: ContourCallbackDataType, lesionId: number | null) => {
    const { toolData, roiId, imageId } = data;

    if (lesionId == null) {
      throw new Error("SelectedLesionId is null when trying to copy contour");
    }

    const toolDataCopy = cloneDeep(toolData);

    setContourClipboardData({
      lesionId,
      roiId,
      imageId,
      toolData: toolDataCopy,
    });
  };

  return { copyContour };
}
