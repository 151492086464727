import React from "react";
import styled from "styled-components";

import { BiopsyIcon } from "../AnnotationPanel/BiopsyLinking/BiopsyIcon";
import { PatientBiopsyDetailsType } from "../AnnotationPanel/types/PatientBiopsyDetailsType";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 6px 12px;
`;

const TextWrapper = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
`;

const BodyTextWrapper = styled(TextWrapper)`
  padding-top: 6px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
`;

interface ListClinicalInformationLabelProps {
  title: string;
  biopsy: PatientBiopsyDetailsType;
}

export function ListPatientContextLabel({
  title,
  biopsy,
}: ListClinicalInformationLabelProps): JSX.Element {
  const { biopsyDetails, lesionId, unsure } = biopsy;

  const handleBiopsyClicked = () => {
    //
  };

  return (
    <Wrapper>
      <TitleWrapper>
        <BiopsyIcon isLinked={lesionId !== null} isUnsure={unsure} onClick={handleBiopsyClicked} />
        <TextWrapper>{title}</TextWrapper>
      </TitleWrapper>
      {biopsyDetails.map(({ key, title, detail }, index) => {
        if (key) {
          return null;
        }

        const wrapperText = `${title ? `${title}: ` : ""}${detail}`;
        return <BodyTextWrapper key={index}>{wrapperText}</BodyTextWrapper>;
      })}
    </Wrapper>
  );
}
