import { useDialogReturnType } from "../../../../common/components/Dialog/useDialog";
import { useFormDialog } from "../../../../common/components/Dialog/useFormDialog";
import {
  CreateClassificationForm,
  CreateClassificationFormProps,
} from "./CreateClassificationForm";

export function useCreateClassificationForm(
  props: CreateClassificationFormProps
): useDialogReturnType {
  return useFormDialog({
    children: CreateClassificationForm,
    label: "Create Classification",
    showCloseButton: true,
    props,
  });
}
