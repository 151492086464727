import { gql } from "@apollo/client/core";

import { useParsedMutation } from "../apollo/utils/useParsedMutation";
import { useParsedMutationReturnType } from "../apollo/utils/useParsedMutationReturnType";
import { SiteType } from "../common/types/SiteType";

const MUTATION = gql`
  mutation UpdateSiteName($name: String!, $site_id: Int!) {
    update_label(where: { id: { _eq: $site_id } }, _set: { name: $name }) {
      affected_rows
    }
  }
`;

type Variables = {
  site_id: number;
  name: string;
};

type Data = {
  update_label: { affected_rows: number };
};

export function useUpdateSite(): useParsedMutationReturnType<SiteType, number> {
  return useParsedMutation<SiteType, number, Data, Variables>({
    mutation: MUTATION,
    parseVariables,
    parseData,
  });
}

function parseVariables({ id: site_id, name }: SiteType): Variables {
  if (!site_id) {
    throw new Error("Site Id is undefined when trying to update site label.");
  }

  return { site_id, name };
}

function parseData(data: Data): number {
  return data.update_label.affected_rows;
}
