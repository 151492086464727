import React, { forwardRef, useRef } from "react";
import styled from "styled-components";

import { ActionButton } from "../../../../Annotate/components/Manage/ActionButton";
import { ReactComponent as CancelIcon } from "../../../../assets/svgs/CancelOutline.svg";
import { ReactComponent as AddIcon } from "../../../../assets/svgs/PlusOutline.svg";
import { Card } from "../../../../common/components/cards/Card";
import { FlexLoading } from "../../../../common/components/Loading";
import { useCreateProjectDialog } from "../../../../common/components/ProjectManagement/Dialogs/useCreateProjectDialog";
import { Table, TableProps } from "../../../../common/components/Table/Table";
import { TableControlsType } from "../../../../common/components/Table/TableControlsType";
import { ProjectTableRowType } from "./ProjectTableRowType";
import { useAllProjects } from "./useAllProjects";
import { useProjectTableColumns } from "./useProjectTableColumns";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ButtonsWrapper = styled.div`
  flex: 0;
  display: flex;
  padding: 4px;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

export function GlobalProjectsManagement(): JSX.Element {
  const { data, loading } = useAllProjects();
  const tableRef = useRef<TableControlsType<ProjectTableRowType>>(null);

  const [setShowCreateProjectDialog, { dialog: createProjectDialog }] = useCreateProjectDialog();

  const columns = useProjectTableColumns();

  if (loading) {
    return <FlexLoading />;
  }

  const handleClickCreateProject = () => {
    setShowCreateProjectDialog(true);
  };

  const handleClearClicked = () => {
    const { current: tableControls } = tableRef;
    tableControls?.clearAllFilters();
  };

  const projects = data?.project ?? [];

  return (
    <Card
      noHead
      expand={true}
      content={
        <Wrapper>
          {createProjectDialog}
          <ButtonsWrapper>
            <ActionButton
              label={"Clear"}
              icon={CancelIcon}
              onClick={handleClearClicked}
              tooltip={"Clear all selections/filters"}
            />
            <ActionButton
              label={"Create Project"}
              icon={AddIcon}
              onClick={handleClickCreateProject}
              tooltip={"Create a new project"}
            />
          </ButtonsWrapper>
          <TableWithRef ref={tableRef} columns={columns} data={projects} enableSelect={false} />
        </Wrapper>
      }
    />
  );
}

const TableWithRef = forwardRef<
  TableControlsType<ProjectTableRowType>,
  TableProps<ProjectTableRowType>
>((props, ref) => {
  return <Table {...props} forwardedRef={ref} />;
});
TableWithRef.displayName = "TableWithRef";
