import cornerstoneTools from "@altis-labs/cornerstone-tools";

import { MEASURE } from "../../../../../../../../cornerstone/ToolType";
import { DiameterPointsType } from "../../../../AnnotationPanel/fragments/RoiRecistEvaluationFragment";
import { areDiameterPointsEqual } from "../hooks/useContourMeasurements";
import { CornerstonePointsDataType, getCornerstonePointsData } from "./getCornerstonePointsData";

export function removeMeasurementsFromElement(
  diameterPoints: DiameterPointsType | undefined
): void {
  if (!diameterPoints) {
    return;
  }
  const cornerstonePointsData = getCornerstonePointsData(diameterPoints);
  clearMeasureToolSliceData(cornerstonePointsData);
}

function clearMeasureToolSliceData(cornerstoneData: CornerstonePointsDataType) {
  const toolType = MEASURE;
  const toolStates = cornerstoneTools.globalImageIdSpecificToolStateManager.toolState;
  for (const imageId of Object.keys(toolStates)) {
    const imageTools = toolStates[imageId];
    const imageTool = imageTools[toolType];
    if (!imageTool) {
      continue;
    }
    const { handles: newHandles } = cornerstoneData;
    const { data } = imageTool;
    imageTool.data = data.filter(
      ({ handles: currentHandles }: CornerstonePointsDataType) =>
        !areDiameterPointsEqual(currentHandles, newHandles)
    );
  }
}
