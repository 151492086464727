import dayjs from "dayjs";

import { getDate } from "../../../../../../../common/utils/dateFormatUtils/getDate";
import { isSeriesViewOnly } from "../../utils/isSeriesViewOnly";
import { StudyListIdsFragmentType } from "../useAllStudiesList";
import { StudyListItemFragmentType } from "../useStudiesList";
import { TimepointType } from "./TimepointType";

export function getTimepoints(
  studies: StudyListItemFragmentType[],
  allStudies: StudyListIdsFragmentType[],
  projectId: number
): TimepointType[] {
  const nonIndexedTimepoints: TimepointType[] = [];

  for (const { id: studyId, studyDate, series: seriesList } of studies) {
    // TODO: ordering currently disabled as it plays poorly with changing view only status of series in the series info modal
    // const orderedSeries = [...seriesList].sort((seriesA, seriesB) => {
    //   const seriesAViewOnly = isSeriesViewOnly(seriesA, projectId);
    //   const seriesBViewOnly = isSeriesViewOnly(seriesB, projectId);
    //   if (seriesAViewOnly && !seriesBViewOnly) {
    //     return 1;
    //   }
    //   if (seriesBViewOnly && !seriesAViewOnly) {
    //     return -1;
    //   }
    //
    //   return 0;
    // });
    //
    // for (const series of orderedSeries) {
    for (const series of seriesList) {
      const { seriesDate } = series;

      const date =
        getDate(dayjs(seriesDate).format()) ?? getDate(dayjs(studyDate).format()) ?? undefined;

      let existingTimepoint = nonIndexedTimepoints.find(
        ({ date: timepointDate }) => timepointDate?.getTime() === date?.getTime()
      );
      if (!existingTimepoint) {
        existingTimepoint = {
          index: -1,
          date,
          series: [],
          excludedSeriesCount:
            (allStudies.find(({ id }) => id === studyId)?.series.length ?? seriesList.length) -
            seriesList.length,
        };
        nonIndexedTimepoints.push(existingTimepoint);
      }

      existingTimepoint.series.push(series);
    }
  }

  const sorted = nonIndexedTimepoints.sort(({ date: dateA }, { date: dateB }) => {
    if (!dateA) {
      return -1;
    }
    if (!dateB) {
      return -1;
    }
    return dateA.getTime() - dateB.getTime();
  });

  return sorted.map((timepoint, index) => {
    const { date } = timepoint;
    return {
      ...timepoint,
      index: date ? index : undefined,
    };
  });
}
