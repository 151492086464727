import { NODE, TARGET } from "../../../../../enums/TaskDescriptionEnums";
import { hasClassification } from "../../StudyPanel/utils/TumourResponse/utils/hasClassification";
import { LesionListItemFragmentType } from "../fragments/LesionListItemFragment";
import { RoiListItemFragmentType } from "../fragments/RoiListItemFragment";
import { GetRoiPreventsLesionClassificationType } from "../hooks/timepointOptions/useGetRoiPreventsLesionClassification";

export const BASELINE_CLASSIFICATION_PREVENTS_TARGET = "BASELINE_CLASSIFICATION_PREVENTS_TARGET";
export const NO_MEASUREMENTS = "NO_MEASUREMENTS";
export const TARGET_LAD_TOO_SMALL = "TARGET_LAD_TOO_SMALL";
export const NODE_SAD_TOO_SMALL = "NODE_SAD_TOO_SMALL";
export const TOO_MANY_TARGETS_TOTAL = "TOO_MANY_TARGETS_TOTAL";
export const TOO_MANY_TARGETS_PER_ORGAN = "TOO_MANY_TARGETS_PER_ORGAN";
export const EXISTS_AT_BASELINE = "EXISTS_AT_BASELINE";

export type RecistClassificationErrorType =
  | typeof BASELINE_CLASSIFICATION_PREVENTS_TARGET
  | typeof NO_MEASUREMENTS
  | typeof TARGET_LAD_TOO_SMALL
  | typeof NODE_SAD_TOO_SMALL
  | typeof TOO_MANY_TARGETS_TOTAL
  | typeof TOO_MANY_TARGETS_PER_ORGAN
  | typeof EXISTS_AT_BASELINE;

type CheckType = (
  roi: RoiListItemFragmentType | undefined,
  lesion: LesionListItemFragmentType,
  otherLesions: LesionListItemFragmentType[],
  getRoiPreventsLesionClassification: GetRoiPreventsLesionClassificationType,
  proposedAnatomicalStructureId?: number
) => boolean;

export type CheckObject = {
  type: RecistClassificationErrorType;
  check: CheckType;
};

export const roiExistsAtBaselineCheck: CheckObject = {
  type: EXISTS_AT_BASELINE,
  check(roi) {
    return !!roi;
  },
};

export const baselineClassificationPreventsTarget: CheckObject = {
  type: BASELINE_CLASSIFICATION_PREVENTS_TARGET,
  check(roi, lesion, _otherLesions, getRoiPreventsLesionClassification) {
    if (!roi) {
      throw new Error("No roi in notPresentAtBaselineCheck");
    }

    return !getRoiPreventsLesionClassification(lesion, roi, TARGET);
  },
};

export const noMeasurementsCheck: CheckObject = {
  type: NO_MEASUREMENTS,
  check(roi) {
    if (!roi) {
      throw new Error("No roi in noMeasurementsCheck");
    }

    const { roiRecistEvaluation } = roi;

    const { LAD, SAD } = roiRecistEvaluation || {};
    return !!LAD && !!SAD;
  },
};

export const targetLADTooSmallCheck: CheckObject = {
  type: TARGET_LAD_TOO_SMALL,
  check(roi, lesion) {
    if (!roi) {
      throw new Error("No roi in targetLADTooSmallCheck");
    }

    const { roiRecistEvaluation } = roi;
    if (!roiRecistEvaluation) {
      throw new Error("No roi recist evaluation in targetLADTooSmallCheck");
    }

    const { LAD } = roiRecistEvaluation;
    if (LAD === undefined) {
      throw new Error("No LAD value in targetLADTooSmallCheck");
    }

    const isTarget = hasClassification(lesion, TARGET);
    const isNode = hasClassification(lesion, NODE);
    if (!isTarget || isNode) {
      return true;
    }

    return LAD >= 10;
  },
};

export const nodeSADTooSmallCheck: CheckObject = {
  type: NODE_SAD_TOO_SMALL,
  check(roi, lesion) {
    if (!roi) {
      throw new Error("No roi in nodeSADTooSmallCheck");
    }

    const { roiRecistEvaluation } = roi;
    if (!roiRecistEvaluation) {
      throw new Error("No roi recist evaluation in nodeSADTooSmallCheck");
    }

    const { SAD } = roiRecistEvaluation;
    if (SAD === undefined) {
      throw new Error("No LAD value in nodeSADTooSmallCheck");
    }

    const isTarget = hasClassification(lesion, TARGET);
    const isNode = hasClassification(lesion, NODE);
    if (!isTarget || !isNode) {
      return true;
    }

    return SAD >= 15;
  },
};

export const numberOfTargetLesionsCheck: CheckObject = {
  type: TOO_MANY_TARGETS_TOTAL,
  check(_roi, lesion, otherLesions, _, proposedAnatomicalStructureId) {
    if (!hasClassification(lesion, TARGET)) {
      return true;
    }

    const { numberOfTargetLesions } = getLesionCounts(proposedAnatomicalStructureId, otherLesions);
    return numberOfTargetLesions < 5;
  },
};

export const numberOfTargetLesionsForOrganCheck: CheckObject = {
  type: TOO_MANY_TARGETS_PER_ORGAN,
  check(_roi, lesion, otherLesions, _, proposedAnatomicalStructureId) {
    if (!hasClassification(lesion, TARGET)) {
      return true;
    }

    const { numberOfTargetLesionsForOrgan } = getLesionCounts(
      proposedAnatomicalStructureId,
      otherLesions
    );
    return numberOfTargetLesionsForOrgan < 2;
  },
};

function getLesionCounts(
  proposedAnatomicalStructureId: number | undefined,
  otherLesions: LesionListItemFragmentType[]
): { numberOfTargetLesions: number; numberOfTargetLesionsForOrgan: number } {
  return otherLesions.reduce<{
    numberOfTargetLesions: number;
    numberOfTargetLesionsForOrgan: number;
  }>(
    (
      {
        numberOfTargetLesions: currentNumberOfTargetLesions,
        numberOfTargetLesionsForOrgan: currentNumberOfTargetLesionsForOrgan,
      },
      lesion
    ) => {
      const { classifications, location } = lesion;

      const isTarget = classifications.find(({ classification }) => classification === TARGET);

      const isForSameLocation = location === proposedAnatomicalStructureId;

      let newNumberOfTargetLesions = currentNumberOfTargetLesions;
      if (isTarget) {
        newNumberOfTargetLesions++;
      }

      let newNumberOfTargetLesionsForOrgan = currentNumberOfTargetLesionsForOrgan;
      if (isTarget && isForSameLocation) {
        newNumberOfTargetLesionsForOrgan++;
      }

      return {
        numberOfTargetLesions: newNumberOfTargetLesions,
        numberOfTargetLesionsForOrgan: newNumberOfTargetLesionsForOrgan,
      };
    },
    {
      numberOfTargetLesions: 0,
      numberOfTargetLesionsForOrgan: 0,
    }
  );
}
