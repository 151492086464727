import { ApolloCache } from "@apollo/client/core";

import { LesionsListQueryInput } from "../fragments/LesionListItemFragment";
import { deleteRoiDataFromCache } from "./deleteRoiDataFromCache";
import { getRoiFromCache } from "./getRoiFromCache";

export function deleteRoiFromCache<T>(
  lesionListQueryInput: LesionsListQueryInput,
  roiId: number,
  cache: ApolloCache<T>
): void {
  const roi = getRoiFromCache(lesionListQueryInput, roiId, cache);

  if (!roi) {
    throw new Error(`Unable to find roi with id ${roiId} in the cache to delete!`);
  }
  deleteRoiDataFromCache(roi, cache);
  const roiCacheId = cache.identify({ id: roiId, __typename: "roi" });
  cache.evict({ id: roiCacheId });
}
