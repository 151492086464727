import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { MutationTuple } from "@apollo/client/react/types/types";

import { CreateTaskArgumentsType } from "../CreateTaskWizard/CreateTaskArgumentsType";
import { TaskType } from "./TaskType";
import { TASK_FRAGMENT, TaskFragmentType } from "./useRawPatientTasksQuery";

const MUTATION = gql`
  mutation UpdateTask(
    $taskId: Int!
    $name: String!
    $deleteUserIds: [Int!]!
    $addUsers: [task_users_map_insert_input!]!
    $deleteCohortIds: [Int!]!
    $addCohorts: [restricted_task_cohort_insert_input!]!
  ) {
    deletedRestrictedUsers: delete_task_users_map(
      where: { _and: [{ task_id: { _eq: $taskId } }, { user_id: { _in: $deleteUserIds } }] }
    ) {
      returning {
        task_id
        user_id
      }
    }
    insertedRestrictedUsers: insert_task_users_map(objects: $addUsers) {
      returning {
        task_id
        user_id
      }
    }
    deletedRestrictedCohorts: delete_restricted_task_cohort(
      where: { _and: [{ task_id: { _eq: $taskId } }, { cohort_id: { _in: $deleteCohortIds } }] }
    ) {
      returning {
        task_id
        cohort_id
      }
    }
    insertedRestrictedCohorts: insert_restricted_task_cohort(objects: $addCohorts) {
      returning {
        task_id
        cohort_id
      }
    }
    task: update_task_by_pk(_set: { name: $name }, pk_columns: { id: $taskId }) {
      ...Task
    }
  }
  ${TASK_FRAGMENT}
`;

export type Variables = {
  taskId: number;
  name: string;
  deleteUserIds: number[];
  addUsers: { task_id: number; user_id: number }[];
  deleteCohortIds: number[];
  addCohorts: { task_id: number; cohort_id: number }[];
};

export type Data = {
  task: TaskFragmentType;
  deletedRestrictedUsers: {
    returning: {
      task_id: number;
      user_id: number;
    }[];
  };
  insertedRestrictedUsers: {
    returning: {
      task_id: number;
      user_id: number;
    }[];
  };
  deletedRestrictedCohorts: {
    returning: {
      task_id: number;
      cohort_id: number;
    }[];
  };
  insertedRestrictedCohorts: {
    returning: {
      task_id: number;
      cohort_id: number;
    }[];
  };
};

export function useUpdateTask(): MutationTuple<Data, Variables> {
  return useMutation<Data, Variables>(MUTATION, {
    update(cache, { data }) {
      if (!data) {
        throw new Error("Something went wrong updating the cache after updating a task");
      }

      cache.gc();
    },
  });
}

export function getVariables(originalTask: TaskType, newTask: CreateTaskArgumentsType): Variables {
  const {
    id: originalTaskId,
    restrictedUsers: originalRestrictedUsers,
    restrictedCohorts: originalRestrictedCohorts,
  } = originalTask;
  const {
    taskName: name,
    restrictedUsers: newRestrictedUsers,
    restrictedCohorts: newRestrictedCohorts,
  } = newTask;

  const originalUserIds = originalRestrictedUsers.map(({ id }) => id);
  const newUserIds = newRestrictedUsers.map(({ id }) => id);

  const deleteUserIds = originalUserIds.filter(
    (originalUserId) => !newUserIds.includes(originalUserId)
  );
  const addUserIds = newUserIds.filter((newUserId) => !originalUserIds.includes(newUserId));

  const originalCohortIds = originalRestrictedCohorts.map(({ id }) => id);
  const newCohortIds = newRestrictedCohorts.map(({ id }) => id);

  const deleteCohortIds = originalCohortIds.filter(
    (originalCohortId) => !newCohortIds.includes(originalCohortId)
  );
  const addCohortIds = newCohortIds.filter(
    (newCohortId) => !originalCohortIds.includes(newCohortId)
  );

  return {
    taskId: originalTaskId,
    name,
    deleteUserIds,
    addUsers: addUserIds.map((user_id) => ({
      task_id: originalTaskId,
      user_id,
    })),
    deleteCohortIds,
    addCohorts: addCohortIds.map((cohort_id) => ({
      task_id: originalTaskId,
      cohort_id,
    })),
  };
}
