import { WindowLevelPresetType } from "./WindowLevelPresetType";

export const DRAW_QUEUE = "DRAW_QUEUE";
export const MEASURE_QUEUE = "MEASURE_QUEUE";
export const CANCEL_QUEUE = "CANCEL_QUEUE";
export const WINDOW_LEVEL_PRESET_QUEUE = "WINDOW_LEVEL_PRESET_QUEUE";

export type ToolQueueType =
  | typeof DRAW_QUEUE
  | typeof MEASURE_QUEUE
  | typeof CANCEL_QUEUE
  | typeof WINDOW_LEVEL_PRESET_QUEUE;

export type ViewerConfigType = {
  id: number;
  seriesId: number;
  color: string;
  isActive: boolean;
  toolQueues: {
    [DRAW_QUEUE]: boolean;
    [MEASURE_QUEUE]: boolean;
    [CANCEL_QUEUE]: boolean;
    [WINDOW_LEVEL_PRESET_QUEUE]: WindowLevelPresetType | null;
  };
};
