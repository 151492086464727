import { ApolloCache, useApolloClient } from "@apollo/client";
import React from "react";
import styled from "styled-components";

import { ERROR, SUCCESS } from "../../../../../../../../../common/types/StatusTypes";
import { CHECK_MISSING_CONTOURS } from "../../../../../../../../enums/TaskDescriptionEnums";
import { useGetFormatTimepointLabel } from "../../../../../../utils/useGetFormatTimepointLabel";
import { LesionListItemFragmentType } from "../../../../fragments/LesionListItemFragment";
import { useTaskNonViewOnlySeriesIds } from "../../../../hooks/useTaskNonViewOnlySeriesIds";
import { CHECK_RESULT_SLICE_LEVEL } from "../../types/SaveCheckResultType";
import { UseLesionCheckReturnType } from "../../types/UseTaskCheckReturnType";
import { JumpToIdsType } from "../../utils/JumpToIdsType";
import { findMissingRoiContourInstances } from "./findMissingRoiContourInstances";

const Bold = styled.span`
  font-weight: bold;
`;

export type MissingContoursCheckResultType = JumpToIdsType | null;

export function useMissingContoursCheck(): UseLesionCheckReturnType<MissingContoursCheckResultType> {
  const { cache } = useApolloClient();
  const getFormatTimepointLabel = useGetFormatTimepointLabel();
  const seriesIds = useTaskNonViewOnlySeriesIds();

  return ({ lesion }) => {
    const result = getJumpIds(lesion, cache, seriesIds);

    const success = result === null;
    const successMessage = "No missing contours";

    const firstRoiSeriesId = result?.rois[0]?.series?.id;
    const formattedDate = getFormatTimepointLabel(firstRoiSeriesId);
    const errorMessage = (
      <>
        Missing contour(s) for <Bold>{formattedDate}</Bold>
      </>
    );

    return {
      type: CHECK_MISSING_CONTOURS,
      message: success ? successMessage : errorMessage,
      status: success ? SUCCESS : ERROR,
      required: true,
      level: CHECK_RESULT_SLICE_LEVEL,
      showOnSwitch: true,
      result,
    };
  };
}

function getJumpIds<T>(
  lesion: LesionListItemFragmentType,
  cache: ApolloCache<T>,
  seriesIds?: number[]
): JumpToIdsType | null {
  let jumpToIds: JumpToIdsType | null = null;

  const { id: lesionId, rois } = lesion;

  for (const roi of rois) {
    if (!seriesIds?.find((id) => id === roi.series.id)) {
      continue;
    }
    const missingInstances = findMissingRoiContourInstances(roi, cache);
    if (missingInstances.length > 0) {
      const { wadoUrl: imageId } = missingInstances[0];
      jumpToIds = {
        id: lesionId,
        rois: [roi],
        imageId,
      };
      break;
    }
  }

  return jumpToIds;
}
