import { RawMortalityRiskType } from "../../rawTypes/RawMortalityRiskType";

export function parseMortalityRisksFromQueryResult(rawMortalityRisk: RawMortalityRiskType): number {
  const {
    prediction: { predictions: predictionsArr, averaging_func: averagingFuncStr },
  } = rawMortalityRisk;

  if (averagingFuncStr !== "SIMPLE") {
    throw new Error(
      `Averaging function type ${averagingFuncStr} is not implemented in parseMortalityRisksFromQueryResult`
    );
  }

  let sum = 0;
  predictionsArr.forEach((el) => (sum += el.value));

  return sum / predictionsArr.length;
}
