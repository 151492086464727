import { useApolloClient } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import styled from "styled-components";

import { useOkForm } from "../../../../common/components/Dialog/useOkForm";
import { ErrorLabel } from "../../../../common/components/input/ErrorLabel";
import { Input } from "../../../../common/components/input/Input";
import {
  InnerButtonWrapper,
  InputButton,
  InputDiv,
} from "../../../../common/components/input/InputButton";
import { Label } from "../../../../common/components/input/Label";
import { FlexLoading } from "../../../../common/components/Loading";
import ToggleSwitch from "../../../../common/components/ToggleSwitch";
import { UserSelect } from "../../../../common/components/UserManagement/UserSelect";
import { useCurrentUserCan } from "../../../../common/contexts/UserContext/useCurrentUserCan";
import { main } from "../../../../common/theme/main";
import { CohortType } from "../../../../common/types/CohortType";
import { ADMIN } from "../../../../common/types/UserRoleType";
import { UserType } from "../../../../common/types/UserType";
import { CohortSelect } from "../../../../DataManagement/Upload/components/CohortSelect";
import { ProjectContext } from "../../../../Project/contexts/ProjectContext";
import {
  ANNOTATE_ALLOW_ORGANS,
  ANNOTATE_LIMIT_TO_BASELINE,
  TYPE_DEFAULT,
} from "../../../enums/TaskDescriptionEnums";
import { TaskOptionsType } from "../../../types/TaskDescriptionType";
import { getAnatomicalStructureOption } from "../../AnatomicalStructureOptionType";
import { AnatomicalStructuresFragmentType } from "../../AnatomicalStructuresFragment";
import { AnatomicalStructuresList } from "../../AnatomicalStructuresList";
import { getTaskDescription } from "../../Annotate/page/AnnotationPanel/hooks/useLazyTaskDescription";
import { useAnatomicalStructures } from "../../useAnatomicalStructures";
import { TaskType } from "../hooks/TaskType";
import { getVariables as getUpdateTaskVariables, useUpdateTask } from "../hooks/useUpdateTask";
import { CreateTaskArgumentsType } from "./CreateTaskArgumentsType";
import {
  FieldWrapper,
  FormFieldsType,
  RowWrapper,
  validateTaskName,
  Wrapper,
} from "./CreateTaskWizard";

const ButtonGroupWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 10px;
`;

interface EditTaskWizardProps {
  task: TaskType;
  onSubmit: () => void;
  onCancel: () => void;
}

export function EditTaskWizard({ task, onSubmit, onCancel }: EditTaskWizardProps): JSX.Element {
  const client = useApolloClient();

  const [selectedUserIds, setSelectedUserIds] = useState<number[]>(
    task.restrictedUsers.map(({ id }) => id)
  );

  const [selectedCohortIds, setSelectedCohortIds] = useState<number[]>(
    task.restrictedCohorts.map(({ id }) => id)
  );

  const [labels, setLabels] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const { cohorts, users: projectUsers } = useContext(ProjectContext);

  const canDeleteTasks = useCurrentUserCan([ADMIN]);

  useEffect(() => {
    const fetchLabels = async () => {
      const taskDescription = await getTaskDescription(client, task.id);
      const taskOrganOption = taskDescription.options?.find(
        (option) => option.rule === ANNOTATE_ALLOW_ORGANS
      ) as TaskOptionsType<{ organs: string[] }>;
      if (taskOrganOption) {
        const organs = taskOrganOption.args?.organs ?? [];
        setLabels(organs);
      }
    };

    fetchLabels();
  }, []);

  const { data: anatomicalStructures = [] as AnatomicalStructuresFragmentType[] } =
    useAnatomicalStructures();

  const [updateTask] = useUpdateTask();

  const selectedUsers = projectUsers.filter(({ id }) => selectedUserIds.includes(id));

  const selectedCohorts = cohorts.filter(({ id }) => selectedCohortIds.includes(id));

  const taskAnatomicalStructures = anatomicalStructures.filter((anatomicalStructure) =>
    labels.includes(anatomicalStructure.structure)
  );

  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    // FIXME: FormFieldsType specified here is not convertible to CreateTaskArgumentsType
  } = useForm<FormFieldsType>({
    defaultValues: {
      taskName: task.name,
      restrictedUsers: task.restrictedUsers,
      restrictedCohorts: task.restrictedCohorts,
    },
  });

  const handleCancel = () => {
    onCancel();
  };

  const handleRestrictedUsersChanged = (users: UserType[]) => {
    const userIds = users.map(({ id }) => id);
    setSelectedUserIds(userIds);
    return users;
  };

  const handleRestrictedCohortsChanged = (cohorts: CohortType[]) => {
    const cohortIds = cohorts.map(({ id }) => id);
    setSelectedCohortIds(cohortIds);
    return cohorts;
  };

  // FIXME: Should be CreateTaskArgumentsType, but had to change to FormFieldsType to get it to work
  // as part of react-hook-form upgrade. —B
  const handleComplete: SubmitHandler<FormFieldsType> = async (updatedTask) => {
    setLoading(true);

    const variables = getUpdateTaskVariables(task, updatedTask as CreateTaskArgumentsType);
    await updateTask({ variables });

    setLoading(false);
    onSubmit();
  };

  const handleDelete = () => {
    showDeleteTaskDialog(true);
  };

  const [showDeleteTaskDialog, { dialog: deleteTaskDialog }] = useOkForm({
    title: "Contact Support",
    message:
      "Tasks may contain large sums of work, and as such require the support team to remove. Please contact support if you would like to delete this task.",
  });

  return (
    <>
      {deleteTaskDialog}
      <form onSubmit={handleSubmit(handleComplete)}>
        <Wrapper>
          {taskAnatomicalStructures.length > 0 && (
            <AnatomicalStructuresList
              anatomicalStructures={taskAnatomicalStructures.map((a) =>
                getAnatomicalStructureOption(a)
              )}
            />
          )}
          <FieldWrapper>
            <RowWrapper>
              <Label htmlFor={"taskName"} required>
                Task Name
              </Label>
              <Input
                type="text"
                id={"taskName"}
                autoFocus
                autoComplete={"off"}
                disabled={loading}
                {...register("taskName", {
                  required: "A task name must be provided!",
                  validate: validateTaskName,
                })}
                error={errors.taskName}
              />
            </RowWrapper>
            <RowWrapper>
              {errors.taskName && errors.taskName.message && (
                <ErrorLabel>{errors.taskName.message}</ErrorLabel>
              )}
            </RowWrapper>
          </FieldWrapper>
          <FieldWrapper>
            <Controller
              control={control}
              name="restrictedUsers"
              render={({ field: { onChange } }) => (
                <>
                  Restrict To Users
                  <UserSelect
                    users={projectUsers}
                    selectedUsers={selectedUsers}
                    setSelectedUsers={(e) => onChange(handleRestrictedUsersChanged(e))}
                    isCreatable={false}
                    placeholder={"Select team members..."}
                  />
                  {selectedUsers.length === 0 &&
                    "If no users are restricted to the task, it will be available to all users within the project."}
                </>
              )}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Controller
              control={control}
              name="restrictedCohorts"
              render={({ field: { onChange } }) => (
                <>
                  Restrict To Cohorts
                  <CohortSelect
                    cohorts={cohorts}
                    selected={selectedCohorts}
                    onSelectedChanged={(e) => onChange(handleRestrictedCohortsChanged(e))}
                  />
                  {selectedCohorts.length === 0 &&
                    "If the task is not restricted to any cohorts, it will be available to all cohorts within the project."}
                </>
              )}
            />
          </FieldWrapper>
          {task.taskDescription.data.type === TYPE_DEFAULT && (
            <FieldWrapper>
              <RowWrapper>
                Apply task to subject's earliest study only.
                <ToggleSwitch
                  size="small"
                  checked={
                    task.taskDescription.data.options?.find(
                      ({ rule }) => rule === ANNOTATE_LIMIT_TO_BASELINE
                    )?.enabled ?? false
                  }
                  disabled
                />
              </RowWrapper>
            </FieldWrapper>
          )}
          <RowWrapper>
            <div>
              {canDeleteTasks && (
                <InputButton
                  type="button"
                  name="delete-button"
                  value={"Delete Task"}
                  width={100}
                  background={main.colors.neutral.white}
                  color={main.colors.neutral.black}
                  onClick={handleDelete}
                  disabled={loading}
                />
              )}
            </div>
            <ButtonGroupWrapper>
              <InputDiv
                type="submit"
                color={main.colors.neutral.white}
                background={main.colors.neutral.black}
                width={84}
                disabled={loading}
              >
                <InnerButtonWrapper>
                  {loading && <FlexLoading color={main.colors.neutral.white} />}
                  Save
                </InnerButtonWrapper>
              </InputDiv>
              <InputButton
                type="button"
                name="cancel-button"
                value={"Cancel"}
                background={main.colors.neutral.white}
                color={main.colors.neutral.black}
                onClick={handleCancel}
                disabled={loading}
              />
            </ButtonGroupWrapper>
          </RowWrapper>
        </Wrapper>
      </form>
    </>
  );
}
