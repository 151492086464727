import { RadiusMode } from "./RadiusMode";

export function getRectPath(
  x: number,
  y: number,
  width: number,
  height: number,
  radiusMode: RadiusMode
): string | undefined {
  if (!width || !height) {
    return undefined;
  }

  switch (radiusMode) {
    case "TOP":
      return getTopRounded(x, y, width, height);
    case "BOTTOM":
      return getBottomRounded(x, y, width, height);
    case "TOP_BOTTOM":
      return getTopBottomRounded(x, y, width, height);
    case "NONE":
      return getNoneRounded(x, y, width, height);
  }
}

function getTopRounded(x: number, y: number, width: number, height: number): string | undefined {
  const radius = getRadius(width, height);

  const widthAfterRadius = width - 2 * radius;
  const adjustedWidth = widthAfterRadius > 0 ? widthAfterRadius : 0;

  const heightAfterRadius = height - radius;
  const adjustedHeight = heightAfterRadius > 0 ? heightAfterRadius : 0;

  return `M${
    x + radius
  },${y} h${adjustedWidth} a${radius},${radius} 0 0 1 ${radius},${radius} v${adjustedHeight} h-${width} v-${adjustedHeight} a${radius},${radius} 0 0 1 ${radius},-${radius} z`;
}

function getBottomRounded(x: number, y: number, width: number, height: number): string | undefined {
  const radius = getRadius(width, height);

  const widthAfterRadius = width - 2 * radius;
  const adjustedWidth = widthAfterRadius > 0 ? widthAfterRadius : 0;

  const heightAfterRadius = height - radius;
  const adjustedHeight = heightAfterRadius > 0 ? heightAfterRadius : 0;

  return `M${x},${y} h${width} v${adjustedHeight} a${radius},${radius} 0 0 1 -${radius},${radius} h-${adjustedWidth} a${radius},${radius} 0 0 1 -${radius},-${radius} v-${adjustedHeight} z`;
}

function getTopBottomRounded(
  x: number,
  y: number,
  width: number,
  height: number
): string | undefined {
  const radius = getRadius(width, height);

  const widthAfterRadius = width - 2 * radius;
  const adjustedWidth = widthAfterRadius > 0 ? widthAfterRadius : 0;

  const heightAfterRadius = height - 2 * radius;
  const adjustedHeight = heightAfterRadius > 0 ? heightAfterRadius : 0;

  return `M${
    x + radius
  },${y} h${adjustedWidth} a${radius},${radius} 0 0 1 ${radius},${radius} v${adjustedHeight} a${radius},${radius} 0 0 1 -${radius},${radius} h-${adjustedWidth} a${radius},${radius} 0 0 1 -${radius},-${radius} v-${adjustedHeight} a${radius},${radius} 0 0 1 ${radius},-${radius} z`;
}

function getNoneRounded(x: number, y: number, width: number, height: number) {
  return `M${x},${y} h${width} v${height} h-${width} v-${height} z`;
}

function getRadius(width: number, height: number) {
  const xRadius = 0.25 * width;
  const yRadius = 0.5 * height;

  return xRadius > yRadius ? yRadius : xRadius;
}
