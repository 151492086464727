import { RoutePathSingleOrArray } from "../../Project/Layout/Layout";
import { RoutePathType, TRIAL_SLUG } from "../../Project/Layout/RoutePathType";
import { getRouteString } from "../../Project/Layout/utils/getRouteString";

export function getBasePath(): string {
  return `/${getRouteString([TRIAL_SLUG])}`;
}

export function getProjectBaseRoute(trialId: number): RoutePathSingleOrArray {
  return replaceRoute([TRIAL_SLUG], TRIAL_SLUG, trialId);
}

function replaceRoute(
  originalRoute: RoutePathType[],
  routeToChange: RoutePathType,
  newRoute: RoutePathType
): RoutePathType[] {
  const slugIndex = originalRoute.indexOf(routeToChange);
  if (slugIndex === -1) {
    throw new Error("Route to change not found in original route");
  }

  return Object.assign([], originalRoute, { [slugIndex]: newRoute });
}
