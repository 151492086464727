import React from "react";
import styled from "styled-components";

import { main } from "../theme/main";

const OuterWrapper = styled.div<{ clickable?: boolean }>`
  display: inline-block;
  cursor: ${({ clickable }) => (clickable ? "pointer" : "unset")};
`;

const MiddleWrapper = styled.div<{
  compact?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  border-radius: 4px;
  inline-size: min-content;
  white-space: nowrap;

  font-style: normal;
  font-weight: 600;
  font-size: ${({ compact }) => (compact ? "10px" : "12px")};
  line-height: ${({ compact }) => (compact ? "10px" : "12px")};
`;

const Wrapper = styled.div<{
  background: string;
  color: string;
  compact?: boolean;
  hasExtraItems?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: ${({ compact }) => (compact ? "2px 4px" : "2px 8px")};
  background: ${(props) => props.background};
  border-radius: ${(props) => (props.hasExtraItems ? "4px 0 0 4px" : "4px")};
  color: ${(props) => props.color};
`;

const AdditionalItemsWrapper = styled.div<{
  background: string;
  color: string;
  compact?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: ${({ compact }) => (compact ? "2px 4px" : "2px 6px")};
  background: ${(props) => props.background};
  border-radius: 0 4px 4px 0;
  font-size: ${({ compact }) => (compact ? "8px" : "10px")};
  color: ${(props) => props.color};
`;

interface LabelProps {
  children: React.ReactNode;
  background: string;
  color: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  compact?: boolean;
  extraItemCount?: number;
}

export function Label({
  children,
  background,
  color,
  onClick,
  compact,
  extraItemCount,
}: LabelProps): JSX.Element {
  const handleOnClick = (event: React.MouseEvent<HTMLElement>) => {
    onClick?.(event);
  };

  const {
    neutral: { neutral8: gray, neutral4: black },
  } = main.colors;

  const hasExtraItems = extraItemCount !== undefined && extraItemCount > 0;
  const extraItemText = `+${extraItemCount}`;

  return (
    <OuterWrapper clickable={!!onClick} onClick={handleOnClick}>
      <MiddleWrapper compact={compact}>
        <Wrapper
          background={background}
          color={color}
          compact={compact}
          hasExtraItems={hasExtraItems}
        >
          {children}
        </Wrapper>
        {hasExtraItems && (
          <AdditionalItemsWrapper background={gray} color={black} compact={false}>
            {extraItemText}
          </AdditionalItemsWrapper>
        )}
      </MiddleWrapper>
    </OuterWrapper>
  );
}
