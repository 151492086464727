import { taskTimepointEnabledOptionsSelector } from "../../../../../../../common/store/annotatePage/taskSelector";
import { useAppSelector } from "../../../../../../../common/store/store";
import { TimepointOptionsType } from "../../../../../../types/TaskDescriptionType";
import { LesionListItemFragmentType } from "../../fragments/LesionListItemFragment";
import { useGetLesionMode } from "../useGetLesionMode";

type ReturnType = (lesion: LesionListItemFragmentType) => TimepointOptionsType[];

export function useGetTimepointOptionsForLesion(): ReturnType {
  const options = useAppSelector(taskTimepointEnabledOptionsSelector);

  const getLesionMode = useGetLesionMode();

  return (lesion) => {
    if (!options) {
      return [];
    }

    const lesionMode = getLesionMode(lesion);
    return options.filter(({ modes }) => modes.includes(lesionMode));
  };
}
