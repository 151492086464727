import React, { FC } from "react";
import styled from "styled-components";

import { TumourResponseLabel } from "../../../Annotate/components/Annotate/page/StudyPanel/TumourResponseLabel";
import ctImage from "../../../assets/images/ct-thumbnail.png";
import { useGoToTaskless } from "../../../Project/Layout/AnnotatePage/useGoToTaskless";
import { FollowUpType } from "../../common/types/FollowUpType";
import { NOT_EVALUABLE, TumourResponseType } from "../../common/types/TumourResponseType";
import { formatFollowUp } from "../../common/utils/formatFollowUp";
import { SubjectImage } from "./SubjectImage";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.actionPrimary.inverted};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  overflow: hidden;
  margin: 0 4px;
  cursor: pointer;
`;

const ResponseWrapper = styled.div`
  position: absolute;
  top: 40px;
  left: 4px;
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 6px 0;
  flex: 1;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.neutral.black};
`;

interface SubjectImagePanelProps {
  followUp: FollowUpType;
  patientId: number;
  maxImageWidth: number;
  maxImageHeight: number;
}

export const SubjectImagePanel: FC<SubjectImagePanelProps> = ({
  followUp,
  patientId,
  maxImageWidth,
  maxImageHeight,
}: SubjectImagePanelProps) => {
  const goToTaskless = useGoToTaskless();

  const image = getSubjectImageFromFollowUp(followUp);
  const { imageUrl, followUpOrder, response } = image;

  const label = formatFollowUp(followUpOrder);

  const isBaseline = followUpOrder === 0;

  const handleOpenTaskless = (patientId: number) => {
    if (followUp.studies.length > 0 && followUp.studies[0].series.length > 0) {
      goToTaskless(patientId, followUp.studies[0].series[0].id);
    } else {
      goToTaskless(patientId);
    }
  };

  return (
    <Wrapper onClick={() => handleOpenTaskless(patientId)}>
      <ResponseWrapper>
        <TumourResponseLabel
          isBaseline={isBaseline}
          response={isBaseline ? undefined : response ?? NOT_EVALUABLE}
          extraInfo={undefined}
        />
      </ResponseWrapper>
      <SubjectImage
        source={imageUrl}
        maxWidth={maxImageWidth}
        maxHeight={maxImageHeight}
        alt={"ct-image"}
      />
      <LabelWrapper>{label}</LabelWrapper>
    </Wrapper>
  );
};

type SubjectImageType = {
  imageUrl: string;
  followUpOrder: number;
  response: TumourResponseType | undefined;
};

function getSubjectImageFromFollowUp(followUp: FollowUpType): SubjectImageType {
  const { order: followUpOrder, tumourResponses } = followUp;
  const response = tumourResponses[0];
  return {
    //TODO replace with DICOMweb query
    imageUrl: ctImage,
    followUpOrder,
    response,
  };
}
