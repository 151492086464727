import { StylesConfig } from "react-select";
import { GroupTypeBase, OptionTypeBase } from "react-select/src/types";

import { getSelectStyle } from "../../../../../common/components/input/getSelectStyle";
import { main } from "../../../../../common/theme/main";

export function getFilterPopoutSelectStyle<
  OptionType extends OptionTypeBase,
  IsMulti extends boolean,
  GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>
>(): StylesConfig<OptionType, IsMulti, GroupType> {
  const {
    colors: {
      actionPrimary: { active: black },
      neutral: { neutral5 },
    },
  } = main;

  return {
    ...getSelectStyle<OptionType, IsMulti, GroupType>(),
    menu: () => ({}),
    container: (styles) => styles,
    control: (styles) => ({
      ...styles,
      borderColor: neutral5,
      "&:hover": {
        borderColor: black,
      },
      boxShadow: undefined,
    }),
    option: (styles, { isDisabled }) => ({
      ...styles,
      backgroundColor: undefined,
      color: black,
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: undefined,
      },
    }),
  };
}
