import React from "react";
import { Column, Row as RowType, TableToggleCommonProps } from "react-table";

import { IndeterminateCheckbox } from "./IndeterminateCheckbox";
import { TableDataType } from "./TableDataType";
import { ExtraColumnWrapper } from "./useAppendExtraColumns";

export type CheckboxProps = Partial<TableToggleCommonProps>;

type CheckboxColumnProps<T extends TableDataType> = {
  getHeaderProps: (data: T[]) => CheckboxProps;
  getRowProps: (row: T) => CheckboxProps;
};

export const SELECT_HEADER_ID = "select";

export function getCheckboxColumn<T extends TableDataType>({
  getHeaderProps,
  getRowProps,
}: CheckboxColumnProps<T>): Column<T> {
  return {
    id: SELECT_HEADER_ID,
    // eslint-disable-next-line react/display-name
    Header: ({ data }: { data: T[] }) => {
      return (
        <ExtraColumnWrapper>
          <IndeterminateCheckbox {...getHeaderProps(data)} />
        </ExtraColumnWrapper>
      );
    },
    // eslint-disable-next-line react/display-name
    Cell: ({ row }: { row: RowType<T> }) => {
      const { original } = row;

      return (
        <ExtraColumnWrapper>
          <IndeterminateCheckbox {...getRowProps(original)} />
        </ExtraColumnWrapper>
      );
    },
    style: {
      width: "24px",
    },
  };
}
