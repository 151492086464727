import { useState } from "react";
import styled from "styled-components";

import { IconButton } from "../../../../Annotate/components/Annotate/page/AnnotationPanel/IconButton";
import { ReactComponent as CheckIcon } from "../../../../assets/svgs/CheckFilled.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/svgs/Close.svg";
import { SvgIcon } from "../../../../common/components/icons/SvgIcon";
import { main } from "../../../../common/theme/main";
import { CohortType } from "../../../../common/types/CohortType";
import { ADD, UpdateModeType } from "../../../../common/types/UpdateModeType";
import {
  GenericMultiSelect,
  OptionType,
} from "../../../../DataManagement/Upload/components/GenericMultiSelect";
import { CohortLabel } from "../Cohort/CohortLabel";
import {
  useInsertDeleteCohortPatient,
  Variables as CohortVariables,
} from "./useInsertDeleteCohortPatient";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
`;

interface CohortAssignmentSelectorProps {
  updateMode: UpdateModeType;
  patientIds: number[];
  onClose: () => void;
  cohorts: CohortType[];
  cohortsLoading: boolean;
}

export function CohortAssignmentSelector({
  updateMode,
  patientIds,
  onClose,
  cohorts,
  cohortsLoading,
}: CohortAssignmentSelectorProps): JSX.Element {
  const [selectedCohorts, setSelectedCohorts] = useState<CohortType[]>([]);

  const [updateCohortPatient] = useInsertDeleteCohortPatient();

  const canConfirm = selectedCohorts.length > 0;

  const formatOptionLabel = ({ value: cohort }: OptionType<CohortType>) => {
    return <CohortLabel cohort={cohort} />;
  };

  const handleCancel = () => {
    onClose();
  };

  const handleConfirm = async () => {
    if (selectedCohorts.length === 0 || patientIds.length === 0) {
      return;
    }

    const variables =
      updateMode === ADD
        ? getAssignCohortsVariables(selectedCohorts, patientIds)
        : getUnassignCohortsVariables(selectedCohorts, patientIds);

    await updateCohortPatient({
      variables,
    });

    onClose();
  };

  return (
    <Wrapper>
      <GenericMultiSelect
        values={cohorts}
        selected={selectedCohorts}
        placeholder={"Select cohorts..."}
        getOption={(cohort) => ({
          value: cohort,
          label: cohort.name,
        })}
        formatOptionLabel={formatOptionLabel}
        onSelectedChanged={setSelectedCohorts}
        menuPortalTarget={document.body}
        isClearable={true}
        isSearchable={true}
        isLoading={cohortsLoading}
      />
      {canConfirm && (
        <IconButton onClick={handleConfirm}>
          <SvgIcon icon={CheckIcon} size={16} color={main.colors.states.success} />
        </IconButton>
      )}
      <IconButton onClick={handleCancel}>
        <SvgIcon icon={CloseIcon} size={16} />
      </IconButton>
    </Wrapper>
  );
}

function getAssignCohortsVariables(cohorts: CohortType[], patientIds: number[]): CohortVariables {
  const cohortIds = cohorts.map(({ id }) => id);

  const added = patientIds.flatMap((patient_id) =>
    cohortIds.map((label_id) => ({ patient_id, label_id }))
  );

  return { deleted: { _or: [] }, added };
}

function getUnassignCohortsVariables(cohorts: CohortType[], patientIds: number[]): CohortVariables {
  const cohortIds = cohorts.map(({ id }) => id);

  const zippedIds = patientIds.flatMap((patient_id) =>
    cohortIds.map((label_id) => ({
      _and: [
        {
          patient_id: { _eq: patient_id },
          label_id: { _eq: label_id },
        },
      ],
    }))
  );

  return { deleted: { _or: zippedIds }, added: [] };
}
