import { gql, useMutation } from "@apollo/client";
import { MutationTuple } from "@apollo/client/react/types/types";

import { SeriesNotesFragmentType } from "../../../../../fragments/SeriesNotesFragmentType";
import { SERIES_NOTE_FRAGMENT } from "./useStudiesList";

const UPDATE_SERIES_NOTES_MUTATION = gql`
  mutation updateSeriesNotes($id: Int!, $notes: String) {
    update_series_notes_by_pk(pk_columns: { id: $id }, _set: { notes: $notes }) {
      ...SeriesNotes
    }
  }
  ${SERIES_NOTE_FRAGMENT}
`;

type Data = {
  update_series_notes_by_pk: SeriesNotesFragmentType;
};

type Variables = {
  id: number;
  notes: string | null;
};

export function useUpdateSeriesNotes(): MutationTuple<Data, Variables> {
  return useMutation<Data, Variables>(UPDATE_SERIES_NOTES_MUTATION);
}
