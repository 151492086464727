import { useGoToRoute } from "../hooks/useGoToRoute";
import { useGetTasklessRoute } from "./useGetTasklessRoute";

type ReturnType = (patientId: number, seriesId?: number) => void;

export function useGoToTaskless(): ReturnType {
  const goToProjectPath = useGoToRoute();
  const getTasklessRoute = useGetTasklessRoute();

  return (patientId: number, seriesId?: number) => {
    const route = getTasklessRoute(patientId);
    if (route) {
      goToProjectPath(route, seriesId);
    }
  };
}
