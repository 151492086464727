import { Popover } from "@mui/material";
import React from "react";
import { Column } from "react-table";
import styled from "styled-components";

import { main } from "../../../../../../common/theme/main";
import { PatientMutableTaskType, TaskType } from "../../../../Manage/hooks/TaskType";
import { useProjectPatientTaskRows } from "../../../../Manage/hooks/useProjectPatientTaskRows";
import { TaskLabel } from "../../../../TaskWorklist/TaskLabel";
import { TaskWorklistTable } from "../../../../TaskWorklist/TaskWorklistTable";
import {
  STATUS_COLUMN_ID,
  TASK_COLUMN_ID,
  TaskWorklistTableColumnIdType,
} from "../../../../TaskWorklist/TaskWorklistTableColumns";

const PopOut = styled.div`
  display: flex;
  gap: 6px;
  flex-direction: column;
  background-color: ${main.colors.background.secondary};
  padding: 12px;
  height: 275px;
  width: 400px;
`;

interface TaskSelectorProps {
  task: TaskType;
  patientId: number;
}

export function TaskSelector({ task, patientId }: TaskSelectorProps): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const { id: taskId } = task;

  const { patientsTasks, loading } = useProjectPatientTaskRows(false);
  const patientTasks =
    patientsTasks?.filter(({ id, task }) => {
      return id === patientId && task?.id !== taskId;
    }) ?? [];

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "filter-popover" : undefined;

  const columnsFilter = ({ id }: Column<PatientMutableTaskType>) =>
    [STATUS_COLUMN_ID, TASK_COLUMN_ID].includes(id as TaskWorklistTableColumnIdType);

  return (
    <>
      <TaskLabel task={task} onClick={handleClick} />
      {!loading && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 40,
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <PopOut>
            <TaskWorklistTable
              rows={patientTasks}
              columnsFilter={columnsFilter}
              onTaskSelected={handleClose}
              globalProgress={true}
            />
          </PopOut>
        </Popover>
      )}
    </>
  );
}
