import React from "react";
import styled from "styled-components";

import FilterButton from "../../../Analysis/ToolPanel/components/FilterButton";
import { DialogActionButtonsOptions } from "./DialogActionButtonsOptions";
import { CANCEL_ONLY, OK_CANCEL, OK_ONLY } from "./DialogActionButtonsType";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 12px;
  width: 100%;
`;

const DEFAULT_OK_TEXT = "Ok";
const DEFAULT_CANCEL_TEXT = "Cancel";

interface DialogActionButtonsProps {
  options: DialogActionButtonsOptions;
  onOk: () => void;
  onCancel: () => void;
}

export function DialogActionButtons({
  options,
  onOk,
  onCancel,
}: DialogActionButtonsProps): JSX.Element {
  const {
    type,
    okText,
    cancelText,
    onOkOverride,
    onCancelOverride,
    okButtonOverrides: { background: okButtonBackground, borderColor: okButtonBorderColor } = {},
    cancelButtonOverrides: {
      background: cancelButtonBackground,
      borderColor: cancelButtonBorderColor,
    } = {},
  } = options;

  const showOkButton = [OK_ONLY, OK_CANCEL].includes(type);
  const showCancelButton = [CANCEL_ONLY, OK_CANCEL].includes(type);

  const handleClickOk = () => {
    onOkOverride?.();
    onOk();
  };

  const handleClickCancel = () => {
    onCancelOverride?.();
    onCancel();
  };

  return (
    <Wrapper>
      {showOkButton && (
        <FilterButton
          onClick={handleClickOk}
          width={64}
          active={true}
          text={okText ?? DEFAULT_OK_TEXT}
          backgroundColorOverride={okButtonBackground}
          borderColorOverride={okButtonBorderColor}
        />
      )}
      {showCancelButton && (
        <FilterButton
          onClick={handleClickCancel}
          width={64}
          active={false}
          text={cancelText ?? DEFAULT_CANCEL_TEXT}
          backgroundColorOverride={cancelButtonBackground}
          borderColorOverride={cancelButtonBorderColor}
        />
      )}
    </Wrapper>
  );
}
