import React from "react";

import { StatsType, TimepointOptionsType } from "../../../../../types/TaskDescriptionType";
import { LesionListItemFragmentType } from "../fragments/LesionListItemFragment";
import { RoiListItemFragmentType } from "../fragments/RoiListItemFragment";
import { ExistingRoiClassificationButtonsReturnType } from "../hooks/timepointOptions/useGetExistingRoiClassificationButtons";
import { TimepointOptionsFromRoiClassificationsReturnType } from "../hooks/timepointOptions/useGetTimepointOptionsFromRoiClassifications";
import { InsertLesionClassificationsInternalReturnType } from "../hooks/useInsertLesionClassification";
import { InsertRoiClassificationsReturnType } from "../hooks/useInsertRoiClassifications";
import { Mask } from "../MaskContextProvider";
import { DownloadState } from "../Masks/types/downloadState";
import { useGetRoiStatComponents } from "../Stats/useGetRoiStatComponents";
import { RoiCardBody } from "./RoiCardBody";
import { RoiCardHeader } from "./RoiCardHeader";

interface RoiCardProps {
  isFirst: boolean;
  roi: RoiListItemFragmentType;
  lesion: LesionListItemFragmentType;
  label: string;
  onDeleteClick?: () => void;
  isFromCurrentTask: boolean;
  canDelete: boolean;
  canClassify: boolean;
  onToggleMaskVisibility: (mask: Mask) => Promise<boolean>;
  getMaskDownloadState: (maskId: number) => DownloadState | undefined;
  jumpToViewerRoi: (roi: RoiListItemFragmentType) => void;
  getTimepointOptionsFromRoiClassifications: TimepointOptionsFromRoiClassificationsReturnType;
  headerStats: StatsType[];
  bodyStats: StatsType[];
  getExistingRoiClassificationButtons: ExistingRoiClassificationButtonsReturnType;
  updateTaskInProgress: () => Promise<void>;
  onTumourBurdenChanged: (lesionId: number) => Promise<void>;
  insertLesionClassificationsObj: InsertLesionClassificationsInternalReturnType;
  insertRoiClassificationsObj: InsertRoiClassificationsReturnType;
}

export function RoiCard({
  roi,
  lesion,
  label,
  onDeleteClick,
  isFirst,
  isFromCurrentTask,
  canDelete,
  canClassify,
  onToggleMaskVisibility,
  getMaskDownloadState,
  jumpToViewerRoi,
  getTimepointOptionsFromRoiClassifications,
  headerStats,
  bodyStats,
  getExistingRoiClassificationButtons,
  updateTaskInProgress,
  onTumourBurdenChanged,
  insertLesionClassificationsObj,
  insertRoiClassificationsObj,
}: RoiCardProps): JSX.Element | null {
  const options = getTimepointOptionsFromRoiClassifications(lesion, roi);

  const headerStatComponents = useGetRoiStatComponents(roi, lesion, headerStats);

  const bodyStatComponents = useGetRoiStatComponents(roi, lesion, bodyStats);

  const { id: lesionId } = lesion;
  const [insertLesionClassifications] = insertLesionClassificationsObj;
  const [insertRoiClassifications] = insertRoiClassificationsObj;

  const handleClassifyClicked = async (option: TimepointOptionsType) => {
    const { triggersLesionClassification } = option;

    if (isFirst && triggersLesionClassification.length > 0) {
      await insertLesionClassifications(lesionId, triggersLesionClassification, []);
    } else {
      await insertRoiClassifications(roi, option);
      await updateTaskInProgress();
    }

    await onTumourBurdenChanged(lesionId);
  };

  return (
    <>
      <RoiCardHeader
        roi={roi}
        label={label}
        options={options}
        statComponents={headerStatComponents}
        onDeleteClick={onDeleteClick}
        lesion={lesion}
        isFromCurrentTask={isFromCurrentTask}
        canDelete={canDelete}
        onToggleMaskVisibility={onToggleMaskVisibility}
        getMaskDownloadState={getMaskDownloadState}
        jumpToViewerRoi={jumpToViewerRoi}
      />
      <RoiCardBody
        roi={roi}
        lesion={lesion}
        isFirst={isFirst}
        canClassify={canClassify}
        onClassifyClicked={handleClassifyClicked}
        statComponents={bodyStatComponents.map((Component, key) => (
          <Component roi={roi} lesion={lesion} key={key} />
        ))}
        getExistingRoiClassificationButtons={getExistingRoiClassificationButtons}
      />
    </>
  );
}
RoiCard.whyDidYouRender = true;
