import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { QueryResult } from "@apollo/client/react/types/types";

import { useProjectId } from "../../../../Project/hooks/useProjectId";
import { QcSchema } from "../../Annotate/utils/qc-validation/schema/ruleSet";

export const GET_QUALITY_CONTROL_RULE_SETS = gql`
  query GetQualityControlSchema($projectId: Int!) {
    qcSchemas: qc_schema(where: { project_id: { _eq: $projectId } }) {
      id
      schema
      lastModified: last_modified
    }
  }
`;

export type QualityControlRuleSetType = {
  id: number;
  schema: QcSchema;
  lastModified: string;
};

type QualityControlRuleSetResultType = {
  qcSchemas: QualityControlRuleSetType[];
};

type Variables = {
  projectId: number;
};

export function useQualityControlRuleSets(): QueryResult<QualityControlRuleSetResultType> {
  const projectId = useProjectId();
  return useQuery<QualityControlRuleSetResultType, Variables>(GET_QUALITY_CONTROL_RULE_SETS, {
    variables: { projectId: projectId },
  });
}
