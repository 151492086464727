import { useSelector } from "react-redux";

import { taskTimepointEnabledOptionsSelector } from "../../../../../../common/store/annotatePage/taskSelector";
import { ClassificationValuesType } from "../../../../../types/TaskDescriptionType";
import { LesionListItemFragmentType } from "../fragments/LesionListItemFragment";
import { useGetTimepointOptionsFromRoiClassifications } from "./timepointOptions/useGetTimepointOptionsFromRoiClassifications";
import { useDeleteRoiClassifications } from "./useDeleteRoiClassifications";
import { useGetLesionMode } from "./useGetLesionMode";
import { useInsertLesionClassifications } from "./useInsertLesionClassification";

export function useUpdateLesionClassificationsOnDeleteRoi() {
  const options = useSelector(taskTimepointEnabledOptionsSelector);
  const getTimepointOptionsFromRoiClassifications = useGetTimepointOptionsFromRoiClassifications();

  const [insertLesionClassifications] = useInsertLesionClassifications();
  const [deleteRoiClassifications] = useDeleteRoiClassifications();
  const getLesionMode = useGetLesionMode();

  return async (isBaseline: boolean, lesion: LesionListItemFragmentType) => {
    if (!options || !isBaseline) {
      return;
    }

    const { id: lesionId, classifications, rois } = lesion;

    const lesionClassificationsToDelete = new Set<ClassificationValuesType>();

    for (const { triggersLesionClassification } of options) {
      if (
        triggersLesionClassification.every((triggeredClassification) =>
          classifications
            .map(({ classification }) => classification)
            .includes(triggeredClassification)
        )
      ) {
        triggersLesionClassification.forEach((classification) =>
          lesionClassificationsToDelete.add(classification)
        );
      }
    }

    const proposedLesion: LesionListItemFragmentType = {
      ...lesion,
      classifications: lesion.classifications.filter(
        ({ classification }) => ![...lesionClassificationsToDelete].includes(classification)
      ),
    };

    const proposedMode = getLesionMode(proposedLesion);

    const roiClassificationsToDelete = new Set<ClassificationValuesType>();

    const roiIds = rois.map(({ id }) => id);
    for (const roi of rois) {
      const roiOptions = getTimepointOptionsFromRoiClassifications(lesion, roi);
      const roiOptionsNotInProposedMode = roiOptions.filter(
        ({ modes }) => !modes.some((mode) => mode === proposedMode)
      );
      roiOptionsNotInProposedMode.forEach(({ rule }) => roiClassificationsToDelete.add(rule));
    }

    await insertLesionClassifications(lesionId, [], [...lesionClassificationsToDelete]);

    await deleteRoiClassifications({
      variables: { roiIds, classifications: [...roiClassificationsToDelete] },
    });
  };
}
