import React from "react";

import { useSubjectIdParam } from "../../../Project/utils/useSubjectIdParam";
import { useSingleSubjectQuery } from "../../common/hooks/useSingleSubjectQuery";
import { parseRTOGDetailPanelContainerData } from "../utils/parseRTOGDetailPanelContainerData";
import { MissingPatientPanel } from "./MissingPatientPanel";
import RTOGDetailPanel from "./RTOGDetailPanel";

function RTOGDetailPanelContainer(): React.ReactElement {
  const subjectId = useSubjectIdParam();

  const subject = useSingleSubjectQuery(subjectId);

  if (!subject) {
    return <MissingPatientPanel />;
  }

  const processedData = parseRTOGDetailPanelContainerData(subject);
  return <RTOGDetailPanel details={processedData} />;
}

export default RTOGDetailPanelContainer;
