import { AnatomicalStructuresFragmentType } from "../../../../Annotate/components/AnatomicalStructuresFragment";
import { ClassificationAttributeType } from "../../../../Annotate/components/Manage/hooks/useClassificationAttributes";
import { AnnotationTableRowType } from "./AnnotationTableRowType";

export function getAnnotationTableRows(
  classifications: ClassificationAttributeType[],
  anatomicalStructures: AnatomicalStructuresFragmentType[]
): AnnotationTableRowType[] {
  const classificationTableRows: AnnotationTableRowType[] = classifications.map(
    (classification) => ({
      classification,
      anatomicalStructure: undefined,
    })
  );

  const anatomicalStructureTableRows: AnnotationTableRowType[] = anatomicalStructures.map(
    (anatomicalStructure) => ({
      classification: undefined,
      anatomicalStructure,
    })
  );

  return classificationTableRows.concat(anatomicalStructureTableRows);
}
