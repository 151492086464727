import { useCallback } from "react";

import { useAnatomicalStructures } from "../../../../useAnatomicalStructures";

export const DEFAULT_ANNOTATION_COLOR = "#0398fc";

export function useLesionColorDeferred<T>(): (anatomicalStructureId: number) => string {
  const { data: anatomicalStructures } = useAnatomicalStructures();

  return useCallback(
    (anatomicalStructureId) => {
      if (!anatomicalStructures) {
        return DEFAULT_ANNOTATION_COLOR;
      }

      const anatomicalStructure = anatomicalStructures.find(
        ({ id }) => id === anatomicalStructureId
      );
      if (!anatomicalStructure) {
        throw new Error(`No anatomical structure found with id ${anatomicalStructureId}`);
      }

      const { color } = anatomicalStructure;
      return color;
    },
    //   for some reason the loading state is not triggering an update of this callback, so dont add it as a dependency
    [anatomicalStructures]
  );
}

export const lesionColors: string[] = [
  "#ffd900",
  "#8800ff",
  "#ff00f7",
  "#ff6f00",
  "#baeb34",
  "#00ffff",
  "#6fff00",
  "#ffee00",
  "#940000",
  "#2b0057",
  "#570047",
  "#0f9400",
  "#008d94",
  "#001e94",
  "#945100",
  "#0080ff",
  "#ff69b4",
  "#FD5B78",
  "#AF6E4D",
  "#8D4E85",
  "#44D7A8",
  "#DA614E",
  "#DB91EF",
  "#A50B5E",
];
