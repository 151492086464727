import { OptionType } from "../../DataManagement/Upload/components/GenericMultiSelect";

export type ProjectArmType = {
  id: number;
  name: string;
  number: number;
};

export function getProjectArmSelectOption(projectArm: ProjectArmType): OptionType<ProjectArmType> {
  const { name } = projectArm;
  return {
    value: projectArm,
    label: name,
  };
}
