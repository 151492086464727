import { ApolloCache, useApolloClient } from "@apollo/client";
import { LesionListItemFragmentType } from "nota-predict-web/src/Annotate/components/Annotate/page/AnnotationPanel/fragments/LesionListItemFragment";
import { RoiListItemFragmentType } from "nota-predict-web/src/Annotate/components/Annotate/page/AnnotationPanel/fragments/RoiListItemFragment";
import {
  CHECK_RESULT_SLICE_LEVEL,
  LesionTaskCheckResult,
} from "nota-predict-web/src/Annotate/components/Annotate/page/AnnotationPanel/toolbar/SaveChecks/types/SaveCheckResultType";
import { UseLesionCheckReturnType } from "nota-predict-web/src/Annotate/components/Annotate/page/AnnotationPanel/toolbar/SaveChecks/types/UseTaskCheckReturnType";
import { JumpToIdsType } from "nota-predict-web/src/Annotate/components/Annotate/page/AnnotationPanel/toolbar/SaveChecks/utils/JumpToIdsType";
import { getRoiContoursFromCache } from "nota-predict-web/src/Annotate/components/Annotate/page/ViewerPanel/utils/getRoiContoursFromCache";
import {
  CacheInstanceType,
  getSeriesInstancesFromCache,
} from "nota-predict-web/src/Annotate/components/Annotate/page/ViewerPanel/utils/getSeriesInstancesFromCache";
import {
  ReturnType,
  useGetFormatTimepointLabel,
} from "nota-predict-web/src/Annotate/components/Annotate/utils/useGetFormatTimepointLabel";
import {
  CHECK_MINIMUM_CONTOUR,
  NON_MEASURABLE,
  NOT_PRESENT,
} from "nota-predict-web/src/Annotate/enums/TaskDescriptionEnums";
import { ERROR, SUCCESS } from "nota-predict-web/src/common/types/StatusTypes";
import styled from "styled-components";

import { useTaskNonViewOnlySeriesIds } from "../../../../hooks/useTaskNonViewOnlySeriesIds";
import { MinimumContourCheckResult } from "./interface";

const Bold = styled.span`
  font-weight: bold;
`;

type ResultSuccess = {
  ok: true;
};

type ResultError = {
  ok: false;
  message: JSX.Element | string;
  jump: JumpToIdsType;
};

type Result = ResultSuccess | ResultError;

const isResultError = (result: Result): result is ResultError => {
  return !result.ok;
};

export const useMinimumContourCheck = (): UseLesionCheckReturnType<MinimumContourCheckResult> => {
  const { cache } = useApolloClient();
  const format = useGetFormatTimepointLabel();
  const seriesIds = useTaskNonViewOnlySeriesIds();

  // These properties are static and will not be changed.
  const base = {
    type: CHECK_MINIMUM_CONTOUR,
    required: true,
    level: CHECK_RESULT_SLICE_LEVEL,
    showOnSwitch: true,
  } as Pick<LesionTaskCheckResult<Result>, "type" | "required" | "level" | "showOnSwitch">;

  return ({ lesion }) => {
    const { id: lesionID, rois } = lesion;

    if (isNonMeasurable(lesion)) {
      return {
        ...base,
        message: "Label is categorized as non-measurable",
        result: null,
        status: SUCCESS,
      };
    }

    const checks = [hasContourOnEllipseSlice, hasContour];

    for (const roi of rois) {
      if (isNotPresent(roi) || !seriesIds?.find((id) => id === roi.series.id)) {
        continue;
      }

      for (const check of checks) {
        const result = check(lesionID, roi, cache, format);

        // Return the first error.
        if (isResultError(result)) {
          return {
            ...base,
            message: result.message,
            result: result.jump,
            status: ERROR,
          };
        }
      }
    }

    // All checks passed.
    return {
      ...base,
      message: "Minimum contour check passed",
      result: null,
      status: SUCCESS,
    };
  };
};

const isNonMeasurable = (lesion: LesionListItemFragmentType): boolean => {
  return lesion.classifications.some(
    (classification) => classification.classification === NON_MEASURABLE
  );
};

const isNotPresent = (roi: RoiListItemFragmentType): boolean => {
  return roi.classifications.some(
    (classification) => classification.classification === NOT_PRESENT
  );
};

// A sorting strategy.
const byInstanceNumber = (
  { instanceNumber: lhs }: CacheInstanceType,
  { instanceNumber: rhs }: CacheInstanceType
) => {
  return (lhs ?? Infinity) - (rhs ?? Infinity);
};

const getFirstSliceURL = (seriesID: number, cache: ApolloCache<unknown>): string => {
  const slices = getSeriesInstancesFromCache(seriesID, cache);
  slices.sort(byInstanceNumber);

  const firstSlice = slices.at(0);

  if (firstSlice === undefined) {
    throw new Error(`Series ${seriesID} has no instances/slices in Apollo cache`);
  }

  return firstSlice.wadoUrl;
};

type Check = (
  lesionID: number,
  roi: RoiListItemFragmentType,
  cache: ApolloCache<unknown>,
  format: ReturnType
) => Result;

const hasContour: Check = (lesionID, roi, cache, format) => {
  const { contours, id: roiID, series, ...other } = roi;

  if (contours.length > 0) {
    return { ok: true };
  }

  const wadoURL = getFirstSliceURL(series.id, cache);

  return {
    ok: false,
    message: (
      <>
        At least 1 contour is required for <Bold>{format(roi.series.id)}</Bold>
      </>
    ),
    jump: {
      id: lesionID,
      rois: [{ id: roiID, series, ...other }],
      imageId: wadoURL,
    },
  };
};

const hasContourOnEllipseSlice: Check = (lesionID, roi, cache, format) => {
  const { ellipse, id: roiID, series, ...other } = roi;

  // If there is no ellipse (i.e. no detection), then the check can be skipped.
  if (ellipse === null) {
    return { ok: true };
  }

  // As far as I know, the ellipse, if it exists, will always have a slice. So this is being checked so that
  // TypeScript doesn't complain about 'ellipse.data.slice' being potentially 'null'.
  if (ellipse.data.slice === null) {
    throw new Error("ellipse.data.slice is null");
  }

  // Need to find the slice that matches the ellipse's WADO-URL.
  const ellipseSliceWadoUrl = ellipse.data.slice.imageId;
  const slices = getSeriesInstancesFromCache(series.id, cache);
  const slice = slices.find((slice) => slice.wadoUrl === ellipseSliceWadoUrl);

  // It's possible that the slices haven't loaded into the cache yet, so we return early.
  if (slice === undefined) {
    console.warn(`Unable to find slice with WADO-URL ${ellipseSliceWadoUrl} from cache`);
    // we return ok because otherwise the ui won't allow you to navigate properly
    return { ok: true };
  }

  // Now we know which slice the ellipse is on, we can check if there is a contour on that slice.
  const contourSliceIDs = getRoiContoursFromCache(roiID, cache);

  if (contourSliceIDs.includes(slice.id)) {
    return { ok: true };
  }

  return {
    ok: false,
    message: (
      <>
        No contour on detection slice for <Bold>{format(series.id)}</Bold>
      </>
    ),
    jump: {
      id: lesionID,
      rois: [{ id: roiID, series, ...other }],
      imageId: ellipseSliceWadoUrl,
    },
  };
};
