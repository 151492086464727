import ShareIcon from "@mui/icons-material/Share";
import React, { MouseEvent, ReactElement, useState } from "react";
import { CSVLink } from "react-csv";
import styled from "styled-components";

import { ReactComponent as DownloadIcon } from "../../../assets/svgs/Download.svg";
import Menu, {
  IconWrapper,
  StyledLink,
  StyledMenuItem,
} from "../../../common/components/buttons/Menu";
import { CommentIcon } from "../../../common/components/icons/CommentIcon";
import { MuiIcon } from "../../../common/components/icons/MuiIcon";
import { SvgIcon } from "../../../common/components/icons/SvgIcon";
import { FlexLoading } from "../../../common/components/Loading";
import { useCurrentUserCan } from "../../../common/contexts/UserContext/useCurrentUserCan";
import { main } from "../../../common/theme/main";
import { PROJECT_ROLE_COORDINATOR } from "../../../common/types/UserRoleType";
import useCsvLink from "../../../common/utils/useCsvLink";

const StyledCSVLink = styled(CSVLink)`
  text-decoration: none;
  display: none;
  color: ${(props) => props.theme.colors.actionPrimary.active};
`;

const AnalysisMenu = (): ReactElement => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const canDownloadCsv = useCurrentUserCan([PROJECT_ROLE_COORDINATOR]);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { csvLink, loading } = useCsvLink({
    onClose: handleClose,
  });
  if (loading) return <FlexLoading />;

  if (!csvLink) {
    throw new Error("Csv link contents is null but loading is false!");
  }

  const { handleDownload, csvLinkRef, csvData } = csvLink;

  const showNotImplementedButtons = false;

  if (!canDownloadCsv && !showNotImplementedButtons) {
    return <></>;
  }

  return (
    <Menu
      handleClick={handleClick}
      id="analysis-menu"
      anchorEl={anchorEl}
      handleClose={handleClose}
    >
      {showNotImplementedButtons && (
        <StyledMenuItem>
          <IconWrapper>
            <CommentIcon />
          </IconWrapper>
          <StyledLink>Add Comment</StyledLink>
        </StyledMenuItem>
      )}
      {canDownloadCsv && (
        <StyledMenuItem onClick={handleDownload}>
          <SvgIcon icon={DownloadIcon} size={22} color={main.colors.primary.primary50} />
          <StyledLink>Export Report</StyledLink>
          <StyledCSVLink
            asyncOnClick={true}
            onClick={(event, done) => {
              done();
            }}
            data={csvData}
            ref={csvLinkRef}
            filename={"analysis-data.csv"}
          />
        </StyledMenuItem>
      )}
      {showNotImplementedButtons && (
        <StyledMenuItem>
          <IconWrapper>
            <MuiIcon icon={ShareIcon} color={main.colors.primary.primary50} />
          </IconWrapper>
          <StyledLink>Share</StyledLink>
        </StyledMenuItem>
      )}
    </Menu>
  );
};

export default AnalysisMenu;
