import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";

import { FormProps } from "../../../../../common/components/Dialog/FormProps";
import { useFormDialog } from "../../../../../common/components/Dialog/useFormDialog";
import { ADD, UpdateModeType } from "../../../../../common/types/UpdateModeType";
import { Optional } from "../../../../../common/utils/utilityTypes/Optional";
import {
  getAddTaskAssignmentsVariables,
  getRemoveTaskAssignmentsVariables,
  useInsertDeleteTaskAssignments,
} from "../../hooks/useInsertDeleteTaskAssignments";
import {
  ConfirmTaskAssignmentForm,
  ConfirmTaskAssignmentFormProps,
} from "./ConfirmTaskAssignmentForm";
import { ConfirmTaskAssignmentsRowType } from "./ConfirmTaskAssignmentsRowType";

const Wrapper = styled.div`
  width: 800px;
  height: 500px;
`;

type Props = Omit<ConfirmTaskAssignmentFormProps, "onConfirm" | "onCancel" | "loading">;

export type ReturnType = [
  (props?: Props) => void,
  {
    dialog: JSX.Element;
  }
];

export function useConfirmTaskAssignmentsForm(): ReturnType {
  const [props, setProps] = useState<Props | undefined>(undefined);

  const [setShow, { dialog }] = useFormDialog({
    children: ConfirmTaskAssignmentsFormWrapper,
    label: "Confirm Assignments",
    showCloseButton: true,
    props,
  });

  const handleShow = (props?: Props) => {
    if (!props) {
      setProps(undefined);
      setShow(false);
      return;
    }

    setProps(props);
    setShow(true);
  };

  return [handleShow, { dialog }];
}

type ConfirmTaskAssignmentsFormWrapperProps = Optional<FormProps<Props>, "props">;

export const ConfirmTaskAssignmentsFormWrapper: FC<ConfirmTaskAssignmentsFormWrapperProps> = ({
  onSubmit,
  onCancel,
  onSetTitle,
  props,
}: ConfirmTaskAssignmentsFormWrapperProps) => {
  useEffect(() => {
    if (!props) {
      return;
    }

    const { updateMode } = props;
    onSetTitle?.(updateMode === ADD ? "Assign Users" : "Unassign Users");
  }, [props]);

  const [loading, setLoading] = useState(false);
  const [insertTaskAssignments] = useInsertDeleteTaskAssignments();

  if (!props) {
    return <></>;
  }

  const handleModify = async (
    updateMode: UpdateModeType,
    rows: ConfirmTaskAssignmentsRowType[]
  ) => {
    if (rows.length === 0) {
      return;
    }

    const add = updateMode === ADD;

    const variables = add
      ? getAddTaskAssignmentsVariables(rows)
      : getRemoveTaskAssignmentsVariables(rows);

    const { taskAssignmentIdsToDelete, objects } = variables;
    if (taskAssignmentIdsToDelete.length === 0 && objects.length === 0) {
      return;
    }

    setLoading(true);

    await insertTaskAssignments({ variables });

    setLoading(false);
  };

  const handleConfirm = async (
    rows: ConfirmTaskAssignmentsRowType[],
    updateMode: UpdateModeType
  ) => {
    setLoading(true);

    await handleModify(updateMode, rows);

    setLoading(false);
    onSubmit();
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <Wrapper>
      <ConfirmTaskAssignmentForm
        {...props}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        loading={loading}
      />
    </Wrapper>
  );
};
