import { ApolloError } from "@apollo/client";

import { LESION } from "../../../../../types/LesionTypeType";
import { useTimepointsNonViewOnlySeriesIds } from "../../AnnotationPanel/hooks/Timepoint/useTimepointsNonViewOnlySeriesIds";
import { useContourListener } from "../../AnnotationPanel/hooks/useContourListener";
import { useLesionsList } from "../../AnnotationPanel/hooks/useLesionsList";
import { lesionListItemTypeFilters } from "../../AnnotationPanel/hooks/useTaskLesionListOptions";
import { calculateSeriesTumourResponses } from "../utils/TumourResponse/calculateSeriesTumourResponses";
import { SeriesTumourResponseType } from "../utils/TumourResponse/types/SeriesTumourResponseType";

export function useCalculateTumourResponse(): SeriesTumourResponseType[] {
  const {
    data: lesionsListData,
    loading: lesionsListLoading,
    error: lesionsListError,
  } = useLesionsList(lesionListItemTypeFilters[LESION]);

  useContourListener(
    lesionsListData?.lesions.flatMap(({ rois }) => rois.map(({ id }) => id)) ?? []
  );
  const seriesIds = useTimepointsNonViewOnlySeriesIds();
  if (lesionsListError) throw new ApolloError(lesionsListError);
  if (!lesionsListData && lesionsListLoading) {
    return [];
  }
  if (!lesionsListData && !lesionsListLoading) {
    throw new Error("No lesion data but not loading!");
  }
  if (lesionsListData && lesionsListLoading) {
    throw new Error("Lesion data but still loading!");
  }

  const { lesions } = lesionsListData || {};

  if (!lesions || lesions.length === 0 || !seriesIds || seriesIds.length === 0) {
    return [];
  }

  return calculateSeriesTumourResponses(lesions, seriesIds);
}
