import React, { ReactNode } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const LabelComponentWrapper = styled.div`
  margin: 0 6px 0 0;
  line-height: 50%;
`;

const Text = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
`;

interface ListLabelProps {
  text: string;
  labelComponent?: ReactNode;
}

export function ListLabel({ text, labelComponent }: ListLabelProps): JSX.Element {
  return (
    <Wrapper>
      {labelComponent && <LabelComponentWrapper>{labelComponent}</LabelComponentWrapper>}
      <Text>{text}</Text>
    </Wrapper>
  );
}
