import React, { FC, ReactElement } from "react";
import styled from "styled-components";

import ItemSubtext2 from "./text/ItemSubtext2";
import ItemText from "./text/ItemText";

export interface DetailProps {
  title: string;
  value?: string | ReactElement;
}

const DetailContainer = styled.div`
  margin-bottom: 24px;
`;

const DetailTitleContainer = styled.div`
  margin-bottom: 10px;
`;

const Detail: FC<DetailProps> = ({ title, value }: DetailProps) => {
  return (
    <DetailContainer>
      <DetailTitleContainer>
        <ItemSubtext2 text={title} />
      </DetailTitleContainer>
      {typeof value === "string" ? <ItemText text={value} /> : value}
    </DetailContainer>
  );
};

export default Detail;
