import { useCurrentUserCan } from "../../../contexts/UserContext/useCurrentUserCan";
import { orderRolesByGroup } from "../../../types/RoleGroupType";
import {
  ADMIN,
  GlobalRoleType,
  PROJECT_ROLE_QC_ANNOTATOR,
  UserRoleType,
} from "../../../types/UserRoleType";
import { RoleSelectOptionType } from "../types/RoleSelectOptionType";
import { globalRoleLabels, projectRoleLabels, roleLabels } from "./roleLabels";

export function useAllRoleOptions(): {
  projectRoleOptions: RoleSelectOptionType[];
  globalRoleOptions: RoleSelectOptionType[];
} {
  const projectRoles = useFilterNonAdminRoles(
    orderRolesByGroup(Object.keys(projectRoleLabels) as UserRoleType[])
  );

  const globalRoles = useFilterNonAdminRoles(
    orderRolesByGroup(Object.keys(globalRoleLabels) as GlobalRoleType[])
  );

  const projectRoleOptions = projectRoles.map((role) => ({
    value: role,
    label: roleLabels[role],
  }));

  const globalRoleOptions = globalRoles.map((role) => ({
    value: role,
    label: globalRoleLabels[role],
  }));

  return { projectRoleOptions, globalRoleOptions };
}

//we currently don't want to expose the following roles unless admin
const adminOnlyRoles: (UserRoleType | GlobalRoleType)[] = [PROJECT_ROLE_QC_ANNOTATOR, ADMIN];

export function useFilterNonAdminRoles<T extends UserRoleType | GlobalRoleType>(roles: T[]): T[] {
  const isCurrentUserAdmin = useCurrentUserCan([ADMIN]);
  if (isCurrentUserAdmin) {
    return roles;
  }

  return roles.filter((role) => !adminOnlyRoles.includes(role));
}
