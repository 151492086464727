import { FieldFunctionOptions, TypePolicies } from "@apollo/client/cache/inmemory/policies";

import { BASE_WADO_URL_FRAGMENT } from "../../Annotate/fragments/BaseWadoUrlFragment";
import { BaseWadoUrlFragmentType } from "../../Annotate/fragments/BaseWadoUrlFragmentType";
import { EllipseFragmentType } from "../../Annotate/fragments/EllipseFragmentType";
import { normalizeImageId } from "../../Annotate/parsers/normalizeImageId";
import { parseBaseWadoUrl } from "../../Annotate/parsers/parseBaseWadoUrl";

export const ellipseTypePolicy: TypePolicies = {
  ellipse: {
    fields: {
      image_id: {
        read(image_id: string, { readField, cache }: FieldFunctionOptions): string | null {
          const { slice } = readField<EllipseFragmentType["data"]>("data") ?? {
            slice: null,
          };
          if (!slice) {
            return null;
          }

          const { imageId: originalImageId } = slice;

          const baseWadoUrls = cache.readFragment<{
            wadoUrls: BaseWadoUrlFragmentType;
          }>({
            fragment: BASE_WADO_URL_FRAGMENT,
          });
          if (!baseWadoUrls) {
            return null;
          }
          const baseWadoUrl = parseBaseWadoUrl(baseWadoUrls.wadoUrls);

          return normalizeImageId(originalImageId, baseWadoUrl);
        },
      },
    },
  },
};
