import { useSelector } from "react-redux";

import { classificationPropertiesSelector } from "../../../../../../common/store/annotatePage/taskSelector";
import { ClassificationValuesType } from "../../../../../types/TaskDescriptionType";
import { hasClassification } from "../../StudyPanel/utils/TumourResponse/utils/hasClassification";
import { LesionListItemFragmentType } from "../fragments/LesionListItemFragment";
import { useGetLesionMode } from "./useGetLesionMode";

export type ClassificationDisplayNameType = {
  classification: ClassificationValuesType;
  displayName: string;
};

export type ReadonlyClassificationsReturnType = (
  lesion: LesionListItemFragmentType
) => ClassificationDisplayNameType[];

export function useGetReadonlyClassificationsFromLesion(): ReadonlyClassificationsReturnType {
  const classificationPropertiesMap = useSelector(classificationPropertiesSelector);
  const getLesionMode = useGetLesionMode();

  return (lesion) => {
    const lesionMode = getLesionMode(lesion);

    const modeClassificationPropertiesMap = classificationPropertiesMap[lesionMode];

    const results: ClassificationDisplayNameType[] = [];

    if (modeClassificationPropertiesMap) {
      for (const [key, value] of Object.entries(modeClassificationPropertiesMap)) {
        if (!value) {
          continue;
        }

        const { displayName, readOnly } = value;
        if (!readOnly) {
          continue;
        }

        const classification = key as ClassificationValuesType;

        if (!hasClassification(lesion, classification)) {
          console.error(
            `Lesion does not actually have classification ${classification} despite it being present in classificationPropertiesMap\nLesion mode: ${lesionMode}`
          );
          continue;
        }

        results.push({
          classification,
          displayName,
        });
      }
    }

    return results;
  };
}
