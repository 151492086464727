import { GLOBAL_ROLE_SOFTWARE_MANAGER } from "../../../common/types/UserRoleType";
import {
  GLOBAL_ANNOTATION_MANAGEMENT,
  GLOBAL_COHORT_MANAGEMENT_CONTENT,
  GLOBAL_PATIENT_MANAGEMENT,
  GLOBAL_PROJECT_MANAGEMENT,
  GLOBAL_USER_MANAGEMENT,
  ProjectLayoutType,
  SETTINGS_ICON,
} from "../Layout";
import { ANNOTATIONS, COHORTS, GLOBAL_USER_SETTINGS, SUBJECTS, TRIALS } from "../RoutePathType";

export const settingsLayout: ProjectLayoutType = {
  menu: [
    {
      label: "Team Members",
      route: GLOBAL_USER_SETTINGS,
      icon: SETTINGS_ICON,
      subItems: [],
    },
    {
      label: "Projects",
      route: TRIALS,
      icon: SETTINGS_ICON,
      subItems: [],
    },
    {
      label: "Subjects",
      route: SUBJECTS,
      icon: SETTINGS_ICON,
      subItems: [],
    },
    {
      label: "Cohorts",
      route: COHORTS,
      icon: SETTINGS_ICON,
      subItems: [],
    },
    {
      label: "Annotation Dictionary",
      route: ANNOTATIONS,
      icon: SETTINGS_ICON,
      subItems: [],
    },
  ],
  pages: [
    {
      allowedRoles: [GLOBAL_ROLE_SOFTWARE_MANAGER],
      defaultPage: true,
      route: GLOBAL_USER_SETTINGS,
      topBar: {
        showBackButton: false,
        label: "Team Members",
        icon: SETTINGS_ICON,
      },
      content: [{ component: GLOBAL_USER_MANAGEMENT }],
      showPatientFilters: false,
    },
    {
      allowedRoles: [GLOBAL_ROLE_SOFTWARE_MANAGER],
      route: TRIALS,
      topBar: { showBackButton: false, label: "Projects", icon: SETTINGS_ICON },
      content: [{ component: GLOBAL_PROJECT_MANAGEMENT }],
      showPatientFilters: false,
    },
    {
      allowedRoles: [GLOBAL_ROLE_SOFTWARE_MANAGER],
      route: SUBJECTS,
      topBar: { showBackButton: false, label: "Subjects", icon: SETTINGS_ICON },
      content: [{ component: GLOBAL_PATIENT_MANAGEMENT }],
      showPatientFilters: false,
    },
    {
      allowedRoles: [GLOBAL_ROLE_SOFTWARE_MANAGER],
      route: COHORTS,
      topBar: { showBackButton: false, label: "Cohorts", icon: SETTINGS_ICON },
      content: [{ component: GLOBAL_COHORT_MANAGEMENT_CONTENT }],
      showPatientFilters: false,
    },
    {
      allowedRoles: [GLOBAL_ROLE_SOFTWARE_MANAGER],
      route: ANNOTATIONS,
      topBar: {
        showBackButton: false,
        label: "Annotation Dictionary",
        icon: SETTINGS_ICON,
      },
      content: [{ component: GLOBAL_ANNOTATION_MANAGEMENT }],
      showPatientFilters: false,
    },
  ],
  standalonePages: [],
};
