import { Redirect, Route, RouteComponentProps, Router, Switch } from "react-router";

import { Dashboard } from "../../Dashboard/Dashboard";
import { ProjectContextProvider } from "../../Project/contexts/ProjectContextProvider";
import { useLayout } from "../../Project/Layout/hooks/useLayout";
import { ProjectRoutes } from "../../Project/Layout/ProjectRoutes";
import { DASHBOARD, SETTINGS } from "../../Project/Layout/RoutePathType";
import { useMixpanel } from "../../telemetry";
import { FlexLoading } from "../components/Loading";
import { history } from "../store/history";
import { BasePathContextProvider } from "./BasePathContextProvider";
import { getBasePath } from "./getBasePath";
import { useGetTrialFromProject } from "./useGetTrialFromProject";

export function Routes(): JSX.Element {
  const layout = useLayout();
  const mixpanel = useMixpanel();

  mixpanel.trackPageview();
  const { dashboard, project, settings } = layout;

  const settingsBasePath = `/${SETTINGS}`;
  const projectBasePath = getBasePath();

  return (
    <Router history={history}>
      <Switch>
        <Route exact path={DASHBOARD}>
          <Dashboard layout={dashboard} />
        </Route>
        <Route path={settingsBasePath}>
          <BasePathContextProvider basePath={settingsBasePath}>
            <ProjectRoutes layout={settings} />
          </BasePathContextProvider>
        </Route>
        <Route path={projectBasePath}>
          <ProjectContextProvider>
            <BasePathContextProvider basePath={projectBasePath}>
              <>
                <ProjectRoutes layout={project} />
              </>
            </BasePathContextProvider>
          </ProjectContextProvider>
        </Route>
        {/* When merging projects and trials, we reworkd the urls, the Redirect Helpers in these Routes allow old
        links to correctly navigate to the new URLs where those pages would be found*/}
        <Route
          path={"/trial/:trialId(\\d+)"}
          render={(props) => props}
          component={TrialRedirectHelper}
        ></Route>
        <Route path={"/project/:projectId(\\d+)"} component={ProjectRedirectHelper}></Route>
        <Redirect to={DASHBOARD} />
      </Switch>
    </Router>
  );
}

const TrialRedirectHelper = (props: RouteComponentProps): JSX.Element => {
  const { history } = props;
  const path = history.location.pathname.replace("/trial", "");
  history.push(`${path}`);
  return <></>;
};

const ProjectRedirectHelper = (props: RouteComponentProps): JSX.Element => {
  const { history } = props;
  const oldPath = history.location.pathname;
  const splitPath = oldPath.split("/");
  const projectIndex = splitPath.findIndex((value) => value === "project");
  const projectId = splitPath[projectIndex + 1];
  const { data, loading, error } = useGetTrialFromProject(parseInt(projectId));

  if (error) {
    throw new Error(`Error while trying to find trial for project ID: ${projectId}`);
  }
  if (loading) {
    return <FlexLoading />;
  }

  if (!data) {
    throw new Error("Data is null with no Apollo error");
  }

  if (data.trial.length !== 1) {
    throw new Error(
      `Can't find trial for project ID: ${projectId} when trying to redirect old project URL to the related trial URL`
    );
  }

  splitPath.splice(projectIndex, 1);
  splitPath[projectIndex] = data.trial[0].id.toString();

  const path = splitPath.join("/");
  history.push(`${path}`);
  return <></>;
};
