import { AnyObject } from "nota-predict-web/src/common/types/EmptyObjectType";
import { z } from "zod";

// TODO in NOTA-683
const INPUT_TYPE = ["select-one", "select-many", "text", "number"] as const;

const inputType = z.enum(INPUT_TYPE);

export type QcInputType = z.infer<typeof inputType>;

export const isValidInputType = (data: unknown): data is QcInputType =>
  data ? INPUT_TYPE.includes(data as unknown as typeof INPUT_TYPE[number]) : false;

export const getInputType = (data: unknown): QcInputType | undefined => {
  switch (typeof data) {
    case "string":
      return isValidInputType(data) ? data : undefined;
    case "object":
      return isValidInputType((data as AnyObject)?.input_type)
        ? ((data as AnyObject)?.input_type as QcInputType)
        : undefined;
    default:
      return undefined;
  }
};

export const refineInputType = (data: unknown, ctx: z.RefinementCtx) => {
  if (!isValidInputType(data)) {
    ctx.addIssue({
      code: z.ZodIssueCode.invalid_type,
      message: `Input type must be provided, got ${typeof data} (${data})`,
      path: ["input_type"],
      expected: "string",
      received: typeof data,
      fatal: true,
    });
    return z.NEVER;
  }
};

export { INPUT_TYPE, inputType };
