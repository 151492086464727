import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import React from "react";
import styled from "styled-components";

import { MuiIcon } from "../../../../../common/components/icons/MuiIcon";
import { main } from "../../../../../common/theme/main";
import { IconButton } from "./IconButton";

interface LesionClassificationOperationButtonProps {
  onSavedClicked: () => void;
  canSave: boolean;
}

const IconWrapper = styled.div`
  padding: 0 2px 0 0;
`;

export function LesionClassificationOperationButton({
  onSavedClicked,
  canSave,
}: LesionClassificationOperationButtonProps): JSX.Element | null {
  if (!canSave) {
    return <></>;
  }

  const {
    colors: {
      states: { success },
      neutral: { neutral6: disabled },
    },
  } = main;

  const handleSaveClicked = () => {
    if (!canSave) {
      throw new Error("Save clicked but canSave was false!");
    }

    onSavedClicked();
  };

  return (
    <IconWrapper>
      <IconButton onClick={canSave ? handleSaveClicked : undefined}>
        <MuiIcon icon={CheckCircleIcon} size={19} color={canSave ? success : disabled} />
      </IconButton>
    </IconWrapper>
  );
}
