import { useLazyQuery } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { QueryTuple } from "@apollo/client/react/types/types";

import { JobStatusType } from "../../common/types/JobStatusType";

type Variables = { jobId: number };
type Data = { celeryJobs: { status: JobStatusType }[] };

const QUERY = gql`
  query GetJobStatus($jobId: Int!) {
    celeryJobs: celery_job(where: { id: { _eq: $jobId } }) {
      status
    }
  }
`;

export function useJobStatus(): QueryTuple<Data, Variables> {
  return useLazyQuery<Data, Variables>(QUERY, { pollInterval: 500 });
}
