import styled from "styled-components";

import { ReactComponent as CloseIcon } from "../../../../assets/svgs/Close.svg";
import { SvgIcon } from "../../../../common/components/icons/SvgIcon";
import { main } from "../../../../common/theme/main";
import { CohortType } from "../../../../common/types/CohortType";
import { CohortLabel } from "./CohortLabel";

export const IconButton = styled.div`
  cursor: pointer;
  padding: 2px;
`;

interface DeletableCohortLabelProps {
  cohort: CohortType;
  onDeleteClicked: () => Promise<void>;
}

export function DeletableCohortLabel({
  cohort,
  onDeleteClicked,
}: DeletableCohortLabelProps): JSX.Element {
  const {
    colors: {
      neutral: { white },
    },
  } = main;

  return (
    <CohortLabel cohort={cohort}>
      <IconButton onClick={onDeleteClicked}>
        <SvgIcon icon={CloseIcon} size={12} color={white} />
      </IconButton>
    </CohortLabel>
  );
}
