import { ViewerConfigType } from "../../../../../../common/store/annotatePage/ViewerConfigType";
import { TimepointType } from "../../AnnotationPanel/hooks/Timepoint/TimepointType";
import { getNextViewerConfig } from "./getNextViewerConfig";

export function getNextViewerConfigs(
  timepoints: TimepointType[],
  viewerConfigs: ViewerConfigType[],
  numberOfConfigs: number,
  projectId: number
): ViewerConfigType[] {
  return [...Array(numberOfConfigs).keys()].reduce<ViewerConfigType[]>(
    (configs) => {
      const config = getNextViewerConfig(timepoints, configs, projectId);
      if (config) {
        configs.push(config);
      }
      return configs;
    },
    [...viewerConfigs]
  );
}
