import { GlobalRoleType, UserRoleType } from "../../../common/types/UserRoleType";
import { useCurrentUserAllowedItem } from "./useCurrentUserAllowedItem";

export function useCurrentUserAllowedItems<
  T extends { allowedRoles?: (UserRoleType | GlobalRoleType)[] }
>(): (items: T[]) => T[] {
  const allow = useCurrentUserAllowedItem();

  return (items) => items.flatMap<T>((subItem) => (allow(subItem) ? [subItem] : []));
}
