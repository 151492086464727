import React from "react";
import styled from "styled-components";

import { main } from "../../theme/main";
import { UserType } from "../../types/UserType";
import { Avatar } from "../Avatar/Avatar";
import { Label } from "../Label";
import { formatUserDisplayName } from "./utils/formatUserDisplayName";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

interface UserLabelProps {
  user: UserType;
}
export function UserLabel({ user }: UserLabelProps): JSX.Element {
  const {
    neutral: { black, white, neutral7 },
    states: { success },
  } = main.colors;

  const { id } = user;
  const displayName = formatUserDisplayName(user);

  const isNewUser = id === 0;

  const background = !isNewUser ? neutral7 : success;
  const color = !isNewUser ? black : white;

  return (
    <Label background={background} color={color}>
      <Wrapper>
        {!isNewUser && <Avatar user={user} size={20} />}
        {displayName}
      </Wrapper>
    </Label>
  );
}
