import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { MutationTuple } from "@apollo/client/react/types/types";

import { GET_ALL_PROJECT_DETAILS } from "../../queries/GetAllProjectsDetails";
import { SiteType } from "../../types/SiteType";
import { UserType } from "../../types/UserType";
import { getFormattedMMMDDYYYY } from "../../utils/dateFormatUtils/getFormattedDate";
import {
  AnonymizationConfigurationType,
  AnonymizationMethodType,
} from "../UserManagement/Dialogs/Contexts/AnonymizationConfigurationContext";
import { AnonymizationTableRowType } from "../UserManagement/Dialogs/hooks/useAllAnonymizationMethods";
import { NewProjectType } from "./NewProjectType";

const MUTATION = gql`
  mutation InsertProject($project: project_insert_input!) {
    insert_project_one(object: $project) {
      id
    }
  }
`;

type AnonymizationMutationDataType = {
  id: number;
  params: string;
};

type Variables = {
  project: {
    name: string;
    status: string;
    estimated_completion?: string;
    start_date: string;
    details: {
      study_no?: number;
      ip?: string; //investigationName
      disease?: string; //indicationName
      phase?: string;
      arms?: number;
      design?: string;
      primary_endpoints?: string;
      secondary_endpoints?: string;
      target_end_date: string;
    };
    project_arms: {
      data: { arm_num: number; name: string }[];
    };
    project_users: {
      data: {
        user_id: number;
      }[];
    };
    project_user_roles: {
      data: { role: string; user_id: number }[];
    };
    project_anonymization_methods: {
      data: { anonymization_method_id: number; params: string }[];
    };
    labels: {
      data: SiteType[];
    };
    label_projects: { data: { label_id: number }[] };
    legacy_trial_id: number;
  };
};

type Data = {
  id: number;
};

export function useInsertProject(): MutationTuple<Data, Variables> {
  return useMutation<Data, Variables>(MUTATION, {
    refetchQueries: [{ query: GET_ALL_PROJECT_DETAILS }],
  });
}

export function getInsertProjectVariables(
  project: NewProjectType,
  sites: SiteType[],
  users: UserType[],
  anonymizationParams: AnonymizationMutationDataType[],
  maxLegacyTrialId: number
): Variables {
  const {
    name,
    status,
    estimatedCompletion,
    studyNumber: study_no,
    startDate,
    investigationProduct: ip,
    indication: disease,
    phase,
    arms,
    design,
    primaryEndpoints: primary_endpoints,
    secondaryEndpoints: secondary_endpoints,
    targetEndDate,
    cohorts,
  } = project;

  const parsedSites = sites.map(
    ({ name, metadata, type_name }): SiteType => ({
      name,
      metadata,
      type_name,
    })
  );

  const userIds = users.map(({ id: user_id }) => ({
    user_id,
  }));

  const cohortIds =
    cohorts?.map(({ cohort: { id } }) => {
      return {
        label_id: id,
      };
    }) ?? [];

  const projectUserRoles = users.flatMap(({ id: user_id, projectRoles = [] }) =>
    projectRoles.map((role) => ({ user_id, role }))
  );

  return {
    project: {
      name,
      status,
      estimated_completion: estimatedCompletion.toUTCString(),
      start_date: startDate.toUTCString(),
      details: {
        study_no,
        ip,
        disease,
        phase,
        arms,
        design,
        primary_endpoints,
        secondary_endpoints,
        target_end_date: getFormattedMMMDDYYYY(targetEndDate),
      },
      project_arms: {
        data: [
          { arm_num: 0, name: "A" },
          { arm_num: 1, name: "B" },
        ],
      },
      project_users: { data: userIds },
      project_user_roles: { data: projectUserRoles },
      labels: { data: parsedSites },
      project_anonymization_methods: {
        data: anonymizationParams.map(({ id, params }) => ({
          anonymization_method_id: id,
          params,
        })),
      },
      label_projects: { data: cohortIds },
      legacy_trial_id: maxLegacyTrialId + 1,
    },
  };
}

export function getAnonymizationConfigParams(
  config: AnonymizationConfigurationType,
  anonymizationMethods: AnonymizationTableRowType[]
): AnonymizationMutationDataType[] {
  // map the configured methods to the anonymization method ids and filter out the ones that are not configured
  return anonymizationMethods.flatMap(({ id, key }) => {
    const configType = key as AnonymizationMethodType;

    const params = config[configType];
    if (params === undefined) {
      return [];
    }

    return [
      {
        id,
        params,
      },
    ];
  });
}
