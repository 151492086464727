import React from "react";
import styled from "styled-components";

import ItemSubtext2 from "../../../common/components/text/ItemSubtext2";
import { ProjectType } from "../../../common/types/ProjectType";
import { ItemsCard } from "../common/ItemsCard";
import { ProjectRow } from "./ProjectRow";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding-left: 16px;
  padding-right: 16px;
`;

const Column = styled.div`
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  display: flex;
  color: ${(props) => props.theme.colors.neutral.neutral5};
  padding-bottom: 16px;
`;

const FlexColumn = styled(Column)<{ basis: number }>`
  flex-basis: ${({ basis }) => basis}%;
`;

interface AllProjectsProps {
  projects: ProjectType[];
  onClickProject: (project: ProjectType) => void;
}

export function AllProjects({ projects, onClickProject }: AllProjectsProps): JSX.Element {
  const columns: Record<string, number> = {
    "Project Name": 20,
    "Indication Name": 20,
    "Investigation Product Name": 20,
    Status: 15,
    "Estimated Completion": 20,
  };

  return (
    <ItemsCard label={"All Projects"}>
      <Wrapper>
        <HeaderWrapper>
          {Object.entries(columns).map(([label, flex], index) => (
            <FlexColumn basis={flex} key={index}>
              <ItemSubtext2 text={label} />
            </FlexColumn>
          ))}
        </HeaderWrapper>
        {projects.map((project, index) => (
          <ProjectRow key={index} project={project} onClickProject={onClickProject} />
        ))}
      </Wrapper>
    </ItemsCard>
  );
}
