import { parse, ParseResult } from "papaparse";
import React, { FC, useRef, useState } from "react";
import styled from "styled-components";

import { DragAndDropUploaderPanel } from "./DragAndDropUploaderPanel";
import { TitleWrapper } from "./TitleWrapper";
import { ButtonRowWrapper, UploadIcon } from "./UploadButtons";
import MediumFilledButton from "../../../common/components/buttons/MediumFilledButton";
import { CodeInfoTextWrapper, ColumnType, InfoTextWrapper } from "./UploadCsv";

const MessageWrapper = styled.div`
  width: 100%;
  align-items: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SubtitleWrapper = styled(TitleWrapper)`
  font-weight: 400;
`;

const DetectedColumnsTableWrapper = styled.div`
  width: 100%;
  font-size: 15px;
`;

const DetectedColumnsTextWrapper = styled.div`
  margin: 4px 0 4px 16px;
`;

interface DragDropCsvUploadProps {
  isValidCsv: boolean;
  setIsValidCsv: (isValid: boolean) => void;
  setData: (data: Record<ColumnType, string[]>) => void;
  setErrorMessage: (errorMessage: string | null) => void;
}

export const DragDropCsvUpload: FC<DragDropCsvUploadProps> = ({
  isValidCsv,
  setIsValidCsv,
  setData,
  setErrorMessage,
}) => {
  const inputFileRef = useRef<HTMLInputElement>(null);

  const [dragged, setDragged] = useState(false);
  const [detectedColumns, setDetectedColumns] = useState<{ type: string; name: string }[]>([]);
  const [filename, setFilename] = useState<string | null>(null);

  // accepts the first columns containing "patient"/"subject", "study", and "series" in the header
  // invalid if no such columns are detected or if there are no headers
  // ignores extraneous columns
  const validateCsv = (csvFile: File) => {
    parse<Record<string, string>>(csvFile, {
      header: true,
      skipEmptyLines: "greedy",
      complete: (results: ParseResult<Record<string, string>>) => {
        const { fields: headers } = results.meta;

        if (!headers) {
          setErrorMessage("No headers detected in CSV");
          clearUploadState();
          return;
        }

        const patientColIndex = headers.findIndex((col: string) =>
          ["patient", "subject"].some((colName) => col.toLowerCase().includes(colName))
        );
        const studyColIndex = headers.findIndex((col: string) =>
          ["study"].some((colName) => col.toLowerCase().includes(colName))
        );
        const seriesColIndex = headers.findIndex((col: string) =>
          ["series"].some((colName) => col.toLowerCase().includes(colName))
        );

        if (patientColIndex === -1 && studyColIndex === -1 && seriesColIndex === -1) {
          setErrorMessage("No valid headers detected in CSV");
          clearUploadState();
          return;
        }

        setDetectedColumns([
          ...(patientColIndex !== -1 ? [{ type: "Subject", name: headers[patientColIndex] }] : []),
          ...(studyColIndex !== -1 ? [{ type: "Study", name: headers[studyColIndex] }] : []),
          ...(seriesColIndex !== -1 ? [{ type: "Series", name: headers[seriesColIndex] }] : []),
        ]);
        setErrorMessage(null);
        setIsValidCsv(true);

        const data: Record<ColumnType, string[]> = {
          subjects: [],
          studies: [],
          series: [],
        };
        results.data.forEach((row: Record<string, string>) => {
          const patientId = row[headers[patientColIndex]]?.trim();
          const studyId = row[headers[studyColIndex]]?.trim();
          const seriesId = row[headers[seriesColIndex]]?.trim();
          patientId && data.subjects.push(patientId);
          studyId && data.studies.push(studyId);
          seriesId && data.series.push(seriesId);
        });

        setData(data);
      },
    });
  };

  const validateFiles = (files: FileList) => {
    if (!files || files.length === 0) {
      setErrorMessage("No files selected for upload");
      clearUploadState();
      return;
    } else if (files.length !== 1) {
      setErrorMessage("More than 1 file selected for upload");
      clearUploadState();
      return;
    } else if (files[0].type !== "text/csv") {
      setErrorMessage("File selected for upload is not a csv");
      clearUploadState();
      return;
    }

    setFilename(files[0].name);
    validateCsv(files[0]);
  };

  const handleFileSelected = (event: React.FormEvent<HTMLInputElement>) => {
    const { files } = event.target as HTMLInputElement;
    if (!files) {
      setErrorMessage("No files selected for upload");
      clearUploadState();
      return;
    }
    validateFiles(files);
  };

  const handleFileUploadClicked = () => {
    inputFileRef.current?.click();
  };

  const clearUploadState = () => {
    setFilename(null);
    setIsValidCsv(false);

    if (inputFileRef.current) {
      inputFileRef.current.value = "";
    }
  };

  return (
    <DragAndDropUploaderPanel
      dragged={dragged}
      setDragged={setDragged}
      onFilesDropped={validateFiles}
    >
      <MessageWrapper>
        {dragged ? (
          <TitleWrapper>Upload</TitleWrapper>
        ) : isValidCsv ? (
          <>
            <TitleWrapper>{filename}</TitleWrapper>
            <InfoTextWrapper>Detected columns:</InfoTextWrapper>
            <DetectedColumnsTableWrapper>
              <table>
                <tbody>
                  {detectedColumns.map(({ type, name }, index) => (
                    <tr key={index}>
                      <td>
                        <DetectedColumnsTextWrapper>{type}</DetectedColumnsTextWrapper>
                      </td>
                      <td>
                        <DetectedColumnsTextWrapper>
                          <CodeInfoTextWrapper>{name}</CodeInfoTextWrapper>
                        </DetectedColumnsTextWrapper>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </DetectedColumnsTableWrapper>
          </>
        ) : (
          <>
            <TitleWrapper>Drag files here to upload</TitleWrapper>
            <SubtitleWrapper>or click</SubtitleWrapper>
            <ButtonRowWrapper>
              <input
                type="file"
                id="csvFile"
                ref={inputFileRef}
                accept=".csv"
                style={{ display: "none" }}
                onChange={handleFileSelected}
              />
              <MediumFilledButton
                text={"Upload .csv"}
                icon={UploadIcon()}
                onClick={handleFileUploadClicked}
              />
            </ButtonRowWrapper>
          </>
        )}
      </MessageWrapper>
    </DragAndDropUploaderPanel>
  );
};
