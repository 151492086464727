/*
eslint-disable react/prop-types, react/display-name
 */

import React, { CSSProperties } from "react";
import { ColumnInstance, Hooks, Row as RowType } from "react-table";
import styled from "styled-components";

import { ReactComponent as DropDown } from "../../../assets/svgs/DropDownArrowSharp.svg";
import { ReactComponent as RightArrow } from "../../../assets/svgs/RightArrowSharp.svg";
import { main } from "../../theme/main";
import { SvgIcon } from "../icons/SvgIcon";
import { IndeterminateCheckbox } from "./IndeterminateCheckbox";
import { TableDataType } from "./TableDataType";

export const ExtraColumnWrapper = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
  font-weight: normal;
`;

export const IconWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const checkBoxColumnStyle: { style: CSSProperties } = {
  style: {
    width: "24px",
  },
};

export const expandColumnStyle: { style: CSSProperties } = {
  style: { width: "24px" },
};

export function useAppendExtraColumns<D extends TableDataType>(): (
  hooks: Hooks<D>,
  enableSelect: boolean,
  enabledExpand: boolean,
  selectColumnOrder: number,
  expandColumnOrder: number
) => void {
  return (hooks, enableSelect, enabledExpand, selectColumnOrder, expandColumnOrder) => {
    const expandIcon = <SvgIcon size={16} icon={DropDown} color={main.colors.neutral.neutral3} />;

    const collapseIcon = (
      <SvgIcon size={16} icon={RightArrow} color={main.colors.neutral.neutral3} />
    );

    if (!enableSelect && !enabledExpand) {
      return;
    }

    // we don't actually need all the column properties so ignore the error
    //@ts-ignore
    const checkBoxColumn: ColumnInstance<D> = {
      id: "_selection",
      Header: ({ getToggleAllRowsSelectedProps }) => (
        <ExtraColumnWrapper>
          <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
        </ExtraColumnWrapper>
      ),
      Cell: ({ row }: { row: RowType<D> }) => (
        <ExtraColumnWrapper>
          <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
        </ExtraColumnWrapper>
      ),
    };

    // we don't actually need all the column properties so ignore the error
    //@ts-ignore
    const expandColumn: ColumnInstance<D> = {
      id: "_expander",
      Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
        <ExtraColumnWrapper
          // @ts-ignore
          {...getToggleAllRowsExpandedProps()}
        >
          <IconWrapper>{isAllRowsExpanded ? expandIcon : collapseIcon}</IconWrapper>
        </ExtraColumnWrapper>
      ),
      Cell: ({ row }) => (
        <ExtraColumnWrapper {...row.getToggleRowExpandedProps()}>
          <IconWrapper>{row.isExpanded ? expandIcon : collapseIcon}</IconWrapper>
        </ExtraColumnWrapper>
      ),
    };

    hooks.visibleColumns.push((columns) => {
      const orderedColumns = [...columns];
      if (enableSelect) {
        orderedColumns.splice(selectColumnOrder, 0, checkBoxColumn);
      }
      if (enabledExpand) {
        orderedColumns.splice(expandColumnOrder, 0, expandColumn);
      }
      return orderedColumns;
    });
  };
}
