import React from "react";

import FilterButton from "../../../../Analysis/ToolPanel/components/FilterButton";
import { UserType } from "../../../types/UserType";
import { useOkCancelForm } from "../../Dialog/useOkCancelForm";
import { useChangeUserSuspension } from "../useChangeUserSuspension";

interface ReAddUserButtonProps {
  user: UserType;
}

export function ReAddUserButton({ user }: ReAddUserButtonProps): JSX.Element {
  const [changeUserSuspension] = useChangeUserSuspension();

  const handleConfirmReAddUser = async () => {
    await changeUserSuspension({
      variables: { auth0UserId: user.auth0Id || "", suspend: false },
    });
  };

  const [showConfirmReAddUserDialog, { dialog: confirmReAddUserDialog }] = useOkCancelForm({
    title: `Re-add User`,
    message: `Are you sure you want to re-add this user?`,
    okLabel: "Confirm",
    onOkCallback: handleConfirmReAddUser,
  });

  return (
    <>
      {confirmReAddUserDialog}
      <FilterButton
        onClick={() => showConfirmReAddUserDialog(true)}
        text={"Re-add"}
        active={true}
        width={71}
      />
    </>
  );
}
