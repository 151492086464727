import React, { FC, useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import Select, { ValueType } from "react-select";
import styled from "styled-components";

import FilterButton from "../../../../Analysis/ToolPanel/components/FilterButton";
import { ReactComponent as InfoIcon } from "../../../../assets/svgs/Info.svg";
import { useCurrentUser } from "../../../contexts/UserContext/useCurrentUser";
import { main } from "../../../theme/main";
import { orderRolesByGroup } from "../../../types/RoleGroupType";
import { ADMIN, GLOBAL_ROLE_SOFTWARE_MANAGER, GlobalRoleType, PROJECT_ROLE_COORDINATOR, UserRoleType } from "../../../types/UserRoleType";
import { UserType } from "../../../types/UserType";
import { ActionButtonsWrapper } from "../../Dialog/Form/ActionButtonsWrapper";
import { ButtonRowWrapper } from "../../Dialog/Form/ButtonRowWrapper";
import { Wrapper } from "../../Dialog/Form/Wrapper";
import { FormProps } from "../../Dialog/FormProps";
import { SvgIcon } from "../../icons/SvgIcon";
import { getSelectStyle } from "../../input/getSelectStyle";
import { Input } from "../../input/Input";
import { InputButton } from "../../input/InputButton";
import { Label, SelectLabel } from "../../input/Label";
import { RoleLabel } from "../RoleLabel";
import { RoleSelectOptionType } from "../types/RoleSelectOptionType";
import { UserManagementContext } from "../UserManagementContext";
import { globalRoleLabels, roleLabels } from "../utils/roleLabels";
import { useAllRoleOptions, useFilterNonAdminRoles } from "../utils/useAllRoleOptions";
import { DeleteUserButton } from "./DeleteUserButton";
import { ReAddUserButton } from "./ReAddUserButton";
import { SuspendUserButton } from "./SuspendUserButton";
import { useResetPasswordDialog } from "./useResetPasswordDialog";
import { useCurrentUserCan } from "nota-predict-web/src/common/contexts/UserContext/useCurrentUserCan";

const SuspendedWrapper = styled.div`
  color: ${(props) => props.theme.colors.states.error};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

interface EditUserFormProps {
  user: UserType;
}

export const EditUserForm: FC<FormProps<EditUserFormProps>> = ({
  props: { user },
  onSubmit,
  onCancel,
}: FormProps<EditUserFormProps>) => {
  const {
    onRemoved,
    onEdited,
    onPasswordReset,
    rules: {
      allowEditRole,
      allowEditMetadata,
      allowRemoveUser,
      allowResetPassword,
      allowEditUserSuspension,
      showProjectRoles,
      showGlobalRoles,
    },
  } = useContext(UserManagementContext);

  const {
    email,
    firstName = "",
    lastName = "",
    title = "",
    organization = "",
    projectRoles = [],
    globalRoles = [],
    suspended = false,
  } = user;

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<UserType>({
    defaultValues: {
      email,
      firstName,
      lastName,
      title,
      organization,
      projectRoles,
      globalRoles,
      suspended,
    },
  });

  const { id: userId } = user;
  const { id: currentUserId } = useCurrentUser();

  const isCurrentUser = userId === currentUserId;
  const isUserAdmin = useCurrentUserCan([ADMIN]);
  const isUserCoordinator = useCurrentUserCan([PROJECT_ROLE_COORDINATOR]);
  const isUserManager = useCurrentUserCan([GLOBAL_ROLE_SOFTWARE_MANAGER]);

  const { projectRoleOptions, globalRoleOptions } = useAllRoleOptions();

  if (allowResetPassword && !onPasswordReset) {
    throw new Error("onPasswordReset is not defined but allowResetPassword is true");
  }
  if (allowRemoveUser && !onRemoved) {
    throw new Error("onRemoved is not defined but allowRemoveUser is true");
  }

  const handleConfirmPasswordReset = (user: UserType) => {
    if (!allowResetPassword) {
      throw new Error("handleConfirmPasswordReset called but allowResetPassword is true");
    }
    onPasswordReset?.(user);
  };

  const [setResetPasswordDialogOpen, { dialog: resetPasswordDialog }] = useResetPasswordDialog(
    user,
    handleConfirmPasswordReset
  );

  const handleClickResetPassword = () => {
    setResetPasswordDialogOpen(true);
  };

  const handleRoleChanged = (
    value: ValueType<RoleSelectOptionType, true>
  ): (UserRoleType | GlobalRoleType)[] | undefined => {
    if (!value) {
      return;
    }

    return value.map(({ value }) => value);
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleSubmitForm = (editedUser: UserType) => {
    const edited = {
      ...user,
      ...editedUser,
    };
    onEdited?.(edited);
    onSubmit();
  };

  const projectRoleValues = useFilterNonAdminRoles(orderRolesByGroup(projectRoles)).map((role) => ({
    value: role,
    label: roleLabels[role],
  }));

  const globalRoleValues = useFilterNonAdminRoles(orderRolesByGroup(globalRoles)).map((role) => ({
    value: role,
    label: globalRoleLabels[role],
  }));

  const formatRoleOptionLabel = ({ value }: RoleSelectOptionType) => {
    return <RoleLabel role={value} />;
  };

  const selectStyle = getSelectStyle<RoleSelectOptionType, true>();

  const disableMetadataFields = !allowEditMetadata || !(isCurrentUser || isUserAdmin);

  return (
    <>
      {resetPasswordDialog}
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Wrapper>
          <div>
            <Label htmlFor={"email"}>Email</Label>
            <Input disabled type="text" id={"email"} {...register("email")} />
          </div>
          <div>
            <Label htmlFor={"firstName"}>First Name</Label>
            <Input
              type="text"
              id={"firstName"}
              autoFocus
              autoComplete={"new-password"}
              disabled={disableMetadataFields}
              {...register("firstName")}
            />
          </div>
          <div>
            <Label htmlFor={"lastName"}>Last Name</Label>
            <Input
              error={errors.lastName}
              type="text"
              id={"lastName"}
              autoComplete={"new-password"}
              disabled={disableMetadataFields}
              {...register("lastName")}
            />
          </div>
          <div>
            <Label htmlFor={"title"}>Title</Label>
            <Input
              type="text"
              id={"title"}
              disabled={disableMetadataFields}
              {...register("title")}
            />
          </div>
          <div>
            <Label htmlFor={"organization"}>Organization</Label>
            <Input
              type="text"
              id={"organization"}
              autoComplete={"new-password"}
              disabled={disableMetadataFields}
              {...register("organization")}
            />
          </div>
          {showProjectRoles && (
            <Controller
              control={control}
              name="projectRoles"
              render={({ field: { onChange, ref } }) => (
                <div>
                  <SelectLabel htmlFor={"projectRoles"}>Roles</SelectLabel>
                  <Select
                    id={"projectRoles"}
                    styles={selectStyle}
                    isClearable={false}
                    isSearchable={true}
                    isDisabled={!allowEditRole || !isUserCoordinator || (isCurrentUser && !isUserAdmin)}
                    options={projectRoleOptions}
                    defaultValue={projectRoleValues}
                    formatOptionLabel={formatRoleOptionLabel}
                    menuPortalTarget={document.body}
                    onChange={(e) => onChange(handleRoleChanged(e))}
                    inputRef={ref}
                    isMulti
                  />
                </div>
              )}
            />
          )}
          {showGlobalRoles && (
            <Controller
              control={control}
              name="globalRoles"
              render={({ field: { onChange, ref } }) => (
                <div>
                  <SelectLabel htmlFor={"globalRoles"}>Roles</SelectLabel>
                  <Select
                    id={"globalRoles"}
                    styles={selectStyle}
                    isClearable={false}
                    isSearchable={true}
                    isDisabled={!allowEditRole || !isUserAdmin}
                    options={globalRoleOptions}
                    defaultValue={globalRoleValues}
                    formatOptionLabel={formatRoleOptionLabel}
                    menuPortalTarget={document.body}
                    onChange={(e) => onChange(handleRoleChanged(e))}
                    inputRef={ref}
                    isMulti
                  />
                </div>
              )}
            />
          )}
          {user.suspended && (
            <SuspendedWrapper>
              <SvgIcon icon={InfoIcon} color={main.colors.states.error} size={18} />
              User has been suspended.
            </SuspendedWrapper>
          )}
          {user.suspended && isUserAdmin && allowEditUserSuspension && (
            <ButtonRowWrapper>
              Re-add User
              <ReAddUserButton user={user} />
            </ButtonRowWrapper>
          )}
          {!user.suspended && !isCurrentUser && isUserAdmin && allowEditUserSuspension && (
            <ButtonRowWrapper>
              Remove User
              <SuspendUserButton user={user} />
            </ButtonRowWrapper>
          )}
          {allowResetPassword && (isUserManager || isUserCoordinator) && (
            <ButtonRowWrapper>
              Reset Password
              <FilterButton
                onClick={handleClickResetPassword}
                text={"Reset"}
                active={true}
                width={71}
              />
            </ButtonRowWrapper>
          )}
          {allowRemoveUser && isUserCoordinator && (!isCurrentUser || isUserAdmin) && (
            <ButtonRowWrapper>
              Remove User
              <DeleteUserButton user={user}>
                <FilterButton text={"Remove"} active={true} width={71} />
              </DeleteUserButton>
            </ButtonRowWrapper>
          )}
        </Wrapper>
        <ActionButtonsWrapper>
          <InputButton type="submit" name="submit-button" value={"Confirm"} />
          <InputButton
            type="button"
            name="cancel-button"
            value={"Cancel"}
            background={main.colors.neutral.white}
            color={main.colors.neutral.black}
            onClick={handleCancel}
          />
        </ActionButtonsWrapper>
      </form>
    </>
  );
};
