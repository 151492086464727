import { useEffect, useRef } from "react";
import { Row } from "react-table";
import styled from "styled-components";

import { ReactComponent as CloseIcon } from "../../../assets/svgs/Close.svg";
import { seriesSelectionActions } from "../../store/seriesSelectionSlice";
import { useAppDispatch } from "../../store/store";
import { SvgIcon } from "../icons/SvgIcon";
import { SeriesTableColumnType } from "../SeriesTable/getColumns";
import { TableControlsType } from "../Table/TableControlsType";
import { StudyTable } from "./StudyTable";
import { StudyTableColumnType } from "./StudyTableColumns";
import { getStudyTableRow, StudyTableRowType } from "./StudyTableRowType";
import { PatientFragmentType } from "./usePatientStudies";

const Wrapper = styled.div`
  padding: 8px;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.neutral.white};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  height: auto;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  flex: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
`;

const Label = styled.p`
  font-size: 14px;
  font-weight: bold;
`;

const TableWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Button = styled.div`
  cursor: pointer;
`;

interface SubStudyTableProps {
  row: Row<PatientFragmentType>;
  onClose: (row: PatientFragmentType) => void;
  tableKeyPrefix: string;
  hiddenColumns?: StudyTableColumnType[];
  hiddenSeriesColumns?: SeriesTableColumnType[];
}

export function SubStudyTable({
  tableKeyPrefix,
  row: { original: row },
  onClose,
  hiddenColumns,
  hiddenSeriesColumns,
}: SubStudyTableProps): JSX.Element {
  const dispatch = useAppDispatch();

  const { id: patientId, patientDicomId, studies } = row;
  const studyRows = studies.map((study) => getStudyTableRow(row, study));

  const tableKey = `${tableKeyPrefix}_${patientId}`;

  useEffect(() => {
    dispatch(
      seriesSelectionActions.setStudyRows({
        tableKey,
        studyRows,
        loading: false,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studyRows]);

  useEffect(() => {
    return () => {
      dispatch(seriesSelectionActions.clearStudyRows({ tableKey }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    onClose(row);
  };

  const tableRef = useRef<TableControlsType<StudyTableRowType>>(null);

  return (
    <Wrapper>
      <Header>
        <Label>{patientDicomId} - Studies</Label>
        <Button onClick={handleClose}>
          <SvgIcon icon={CloseIcon} size={16} />
        </Button>
      </Header>
      <TableWrapper>
        <StudyTable
          tableKey={tableKey}
          patientId={patientId}
          hiddenColumns={hiddenColumns}
          hiddenSeriesColumns={hiddenSeriesColumns}
          tableRef={tableRef}
          enableExpand={true}
          maxHeight={500}
        />
      </TableWrapper>
    </Wrapper>
  );
}
