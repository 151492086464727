import { gql } from "@apollo/client";
import { FieldFunctionOptions, TypePolicies } from "@apollo/client/cache/inmemory/policies";

import { RoiRecistEvaluationFragmentType } from "../../Annotate/components/Annotate/page/AnnotationPanel/fragments/RoiRecistEvaluationFragment";
import { getImageIdFromInstanceIdFromCache } from "../../Annotate/components/Annotate/page/ViewerPanel/selection/getImageIdFromInstanceIdFromCache";
import { getRoiEllipseImageIdFromCache } from "../../Annotate/components/Annotate/page/ViewerPanel/utils/getRoiEllipseImageIdFromCache";

export const roiTypePolicy: TypePolicies = {
  roi: {
    fields: {
      key_image_id: {
        read(key_image_id: string, { readField, cache }: FieldFunctionOptions): string | null {
          const roiId = readField<number>("roi_id");
          if (!roiId) {
            throw new Error("No roi id field!");
          }

          const contourRefs = readField<{ __ref: string }[]>("contours");

          const ellipseImageId = getRoiEllipseImageIdFromCache(roiId, cache);

          const roiRecistEvaluation =
            readField<RoiRecistEvaluationFragmentType>("roi_recist_evaluation");

          // @ts-ignore
          const { instance_id: evaluationInstanceId } = roiRecistEvaluation ?? {
            instance_id: null,
          };

          if (
            !ellipseImageId &&
            !evaluationInstanceId &&
            (!contourRefs || contourRefs.length === 0)
          ) {
            return null;
          }

          let imageId: string | null = null;

          if (ellipseImageId) {
            imageId = ellipseImageId;
          } else if (evaluationInstanceId) {
            imageId = getImageIdFromInstanceIdFromCache(cache, evaluationInstanceId);
          } else if (contourRefs && contourRefs.length > 0) {
            const { __ref } = contourRefs[0];
            const contourData = cache.readQuery<{
              instanceId: number;
            }>({
              query: gql`
                query GetContourInstanceId {
                  instanceId: instance_id
                }
              `,
              id: __ref,
            });

            if (!contourData) {
              throw new Error(`Unable to get contour data for ${__ref}`);
            }

            const { instanceId } = contourData;

            imageId = getImageIdFromInstanceIdFromCache(cache, instanceId);
          }
          return imageId;
        },
      },
      roi_recist_evaluation: {
        merge: true,
      },
    },
  },
};
