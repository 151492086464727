import { TaskDataType } from "../../../common/store/annotatePage/taskSlice";
import { useGoToRoute } from "../hooks/useGoToRoute";
import { useGetTaskRoute } from "./useGetTaskRoute";

type ReturnType = (task: TaskDataType) => void;

export function useGoToTask(openTaskViewOnly?: boolean): ReturnType {
  const goToProjectPath = useGoToRoute();
  const getTaskRoute = useGetTaskRoute();

  return (task) => {
    const route = getTaskRoute(task);
    goToProjectPath(route, undefined, openTaskViewOnly);
  };
}
