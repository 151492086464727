import { RawTumourBurden } from "../../rawTypes/RawTumourBurden";
import { TumourBurdenType } from "../../types/TumourBurdenType";

export function parseTumourBurdenFromQueryResult(
  rawTumourBurden: RawTumourBurden
): TumourBurdenType {
  const {
    tumour_burden: { diametric, volumetric, predicted },
  } = rawTumourBurden;
  return { diametric, volumetric, predicted };
}
