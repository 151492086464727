import { useDialogReturnType } from "../../../../../common/components/Dialog/useDialog";
import { useFormDialog } from "../../../../../common/components/Dialog/useFormDialog";
import { TaskLoadingFailedDialog } from "./TaskLoadingFailedDialog";

export function useTaskLoadingFailedDialog(): useDialogReturnType {
  return useFormDialog({
    children: TaskLoadingFailedDialog,
    label: "Task Failed to Load",
    showCloseButton: false,
    props: {},
  });
}
