import React, { FC, ReactNode } from "react";

import { InputButton } from "../input/InputButton";
import { ActionButtonsWrapper } from "./Form/ActionButtonsWrapper";
import { Wrapper } from "./Form/Wrapper";
import { FormProps } from "./FormProps";

export interface OkFormProps {
  title: string;
  message: ReactNode;
  okLabel?: string;
  onOkCallback?: () => void;
  width?: number | string;
  maxWidth?: number | string;
  shouldCloseOnOverlayClick?: boolean;
  shouldCloseOnEsc?: boolean;
}

export const OkForm: FC<FormProps<OkFormProps>> = ({
  props: { message, okLabel = "Ok", onOkCallback, width, maxWidth },
  onSubmit,
}: FormProps<OkFormProps>) => {
  const handleSubmitForm = () => {
    onOkCallback?.();
    onSubmit();
  };
  return (
    <form onSubmit={handleSubmitForm}>
      <Wrapper width={width} maxWidth={maxWidth}>
        {message}
        <ActionButtonsWrapper>
          <InputButton autoFocus type="submit" name="submit-button" value={okLabel} />
        </ActionButtonsWrapper>
      </Wrapper>
    </form>
  );
};
