import React, { FC } from "react";
import styled from "styled-components";

import { ReactComponent as ErrorIcon } from "../../../../assets/svgs/Error.svg";
import { ActionButtonsWrapper } from "../../../../common/components/Dialog/Form/ActionButtonsWrapper";
import { Wrapper } from "../../../../common/components/Dialog/Form/Wrapper";
import { FormProps } from "../../../../common/components/Dialog/FormProps";
import { RED_BUTTON_OVERRIDES } from "../../../../common/components/Dialog/RedButtonOverrides";
import { SvgIcon } from "../../../../common/components/icons/SvgIcon";
import { InputButton } from "../../../../common/components/input/InputButton";
import { main } from "../../../../common/theme/main";

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Text = styled.div`
  padding-right: 36px;
`;

interface ConfirmDeletePatientDialogProps {
  onConfirm: () => void;
}

export const ConfirmDeletePatientsDialog: FC<FormProps<ConfirmDeletePatientDialogProps>> = ({
  onSubmit,
  onCancel,
  props: { onConfirm },
}: FormProps<ConfirmDeletePatientDialogProps>): JSX.Element => {
  const handleSubmitForm = () => {
    onConfirm();
    onSubmit();
  };

  return (
    <form onSubmit={handleSubmitForm}>
      <Wrapper width={450}>
        <ContentWrapper>
          <IconWrapper>
            <SvgIcon icon={ErrorIcon} size={28} color={main.colors.states.error} />
          </IconWrapper>
          <Text>
            This operation will delete all selected patients along with all associated subject data.
            Are you sure you want to proceed?
          </Text>
        </ContentWrapper>
        <ActionButtonsWrapper>
          <InputButton
            type="button"
            name="submit-button"
            value={"Delete"}
            {...RED_BUTTON_OVERRIDES}
            color={main.colors.neutral.white}
            onClick={handleSubmitForm}
          />
          <InputButton
            autoFocus
            type="button"
            name="cancel-button"
            value={"Cancel"}
            background={main.colors.neutral.white}
            color={main.colors.neutral.black}
            onClick={onCancel}
          />
        </ActionButtonsWrapper>
      </Wrapper>
    </form>
  );
};
