import cornerstoneTools from "@altis-labs/cornerstone-tools";
import { Mutex } from "async-mutex";

import { ROI } from "../../../../../../../cornerstone/ToolType";
import roiToolStyle from "./RoiToolStyle";

export default class RoiTool {
  constructor(cornerstoneElement) {
    this.onDataUpdate = null;
    this.onContourRightClickedCallback = null;
    this.onContourDoubleClickedCallback = null;
    this.onExceptionThrown = null;
    this.mutex = new Mutex();

    const tool = cornerstoneTools.getToolForElement(cornerstoneElement, ROI);

    if (!tool) {
      throw new Error("Cornerstone roi tool is null");
    }

    cornerstoneElement.addEventListener(
      cornerstoneTools.EVENTS.MEASUREMENT_COMPLETED,
      this.onMeasureCompleted,
      false
    );

    // Cancel drawing when a scroll occurs
    cornerstoneElement.addEventListener(cornerstoneTools.EVENTS.STACK_SCROLL, this.cancel, false);

    tool.setStyle(roiToolStyle);
    tool.setOnContourRightClicked(this.onContourRightClicked);
    tool.setOnContourDoubleClicked(this.onContourDoubleClicked);

    this.tool = tool;
    this.cornerstoneElement = cornerstoneElement;
  }

  setCurrentRoi(roi) {
    this.tool.setCurrentRoi(roi);
  }

  setPassiveRoi(roi) {
    this.tool.setPassiveRoi(roi);
  }

  setActive() {
    cornerstoneTools.setToolActiveForElement(this.cornerstoneElement, ROI, {
      mouseButtonMask: 1,
    });
  }

  setPassive = () => {
    cornerstoneTools.setToolPassiveForElement(this.cornerstoneElement, ROI, {
      mouseButtonMask: 1,
    });
  };

  setEnabled() {
    cornerstoneTools.setToolEnabledForElement(this.cornerstoneElement, ROI, {
      mouseButtonMask: 1,
    });
  }

  cancel = () => {
    this.tool.cancelDrawing(this.cornerstoneElement);
  };

  setDataUpdateCallback(onDataUpdateCallback) {
    this.onDataUpdate = onDataUpdateCallback;
  }

  setOnContourRightClickedCallback(onContourRightClickedCallback) {
    this.onContourRightClickedCallback = onContourRightClickedCallback;
  }

  setOnContourDoubleClickedCallback(onContourDoubleClickedCallback) {
    this.onContourDoubleClickedCallback = onContourDoubleClickedCallback;
  }

  setOnExceptionThrown(onExceptionThrown) {
    this.onExceptionThrown = onExceptionThrown;
  }

  onMeasureCompleted = (event) => {
    if (!this.onDataUpdate) {
      return;
    }

    this.mutex.acquire().then(async (release) => {
      try {
        await this.onDataUpdate(event);
      } catch (e) {
        console.error("Error in RoiTool onMeasureCompleted", e);
        this.onExceptionThrown(e);
      } finally {
        release();
      }
    });
  };

  updateCachedStats = (image, data) => {
    this.tool.updateCachedStats(image, this.cornerstoneElement, data);
  };

  onContourRightClicked = (toolData) => {
    if (!this.onContourRightClickedCallback) {
      return;
    }

    this.onContourRightClickedCallback(toolData);
  };

  onContourDoubleClicked = (toolData) => {
    if (!this.onContourDoubleClickedCallback) {
      return;
    }

    this.onContourDoubleClickedCallback(toolData);
  };

  setToolRoiStates = (roiStates) => {
    this.tool.setToolRoiStates(roiStates);
  };

  isDrawing() {
    return this.tool._drawing;
  }
}
