import { useCurrentUserCanDeferred } from "../../../common/contexts/UserContext/useCurrentUserCanDeferred";
import { GlobalRoleType, UserRoleType } from "../../../common/types/UserRoleType";

export function useCurrentUserAllowedItem<
  T extends { allowedRoles?: (UserRoleType | GlobalRoleType)[] }
>(): (item: T) => boolean {
  const allow = useCurrentUserCanDeferred();

  return (item) => {
    const { allowedRoles } = item;

    if (!allowedRoles) {
      return true;
    }

    return allow(allowedRoles);
  };
}
