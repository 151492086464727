import { useApolloClient } from "@apollo/client";

import { useProjectId } from "../../../../Project/hooks/useProjectId";
import { getSeriesFromCache } from "../page/AnnotationPanel/hooks/getSeriesFromCache";
import { useTaskNonViewOnlySeriesIds } from "../page/AnnotationPanel/hooks/useTaskNonViewOnlySeriesIds";
import { DATE_UNKNOWN } from "./formatDate";
import { useGetFormatFollowUp } from "./useGetFormatFollowUp";

export type ReturnType = (seriesId: number | undefined) => string;

export function useGetFormatTimepointLabel(): ReturnType {
  const getFormatFollowUp = useGetFormatFollowUp();
  const seriesIds = useTaskNonViewOnlySeriesIds();
  const projectId = useProjectId();

  const { cache } = useApolloClient();

  return (seriesId) => {
    if (!seriesId) {
      return DATE_UNKNOWN;
    }
    if (!seriesIds) {
      throw new Error("Series ids not set in useGetFormatTimepointLabel");
    }

    const series = getSeriesFromCache(seriesId, projectId, cache);
    if (!series) {
      throw new Error(`Unable to get series with id ${seriesId} from cache`);
    }

    const { id, seriesDate } = series;
    const followUpOrder = seriesIds.indexOf(id);
    return getFormatFollowUp(seriesDate, followUpOrder);
  };
}
