import Tooltip from "@mui/material/Tooltip";
import React from "react";
import styled from "styled-components";

import { ReactComponent as PlusOutlineFilled } from "../../../../../assets/svgs/PlusOutlineFilled.svg";
import { SvgIcon } from "../../../../../common/components/icons/SvgIcon";
import { FlexLoading } from "../../../../../common/components/Loading";
import { main } from "../../../../../common/theme/main";
import { IconButton } from "./IconButton";

const Wrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  margin-bottom: 2px;
`;

const NameWrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 32px;
  background-color: ${({ theme }) => theme.colors.background.secondary};
  border-radius: 12px;
  color: ${({ theme, disabled }) =>
    !disabled ? theme.colors.neutral.black : theme.colors.neutral.neutral5};
  font-weight: 600;
  padding-left: 12px;
  cursor: ${({ disabled }) => (!disabled ? "pointer" : "unset")};
`;

interface NewLabelButtonProps {
  text: string;
  loading: boolean;
  onClick: () => void;
  disabled?: boolean;
  tooltip?: string;
}

export function NewLabelButton({
  text,
  loading,
  onClick,
  disabled = false,
  tooltip,
}: NewLabelButtonProps): JSX.Element {
  return (
    <Tooltip title={tooltip}>
      <Wrapper onClick={!loading && !disabled ? onClick : undefined}>
        <IconButton readOnly={disabled}>
          {!loading && (
            <SvgIcon
              icon={PlusOutlineFilled}
              color={!disabled ? main.colors.neutral.black : main.colors.neutral.neutral6}
              size={20}
            />
          )}
          {loading && <FlexLoading size={18} />}
        </IconButton>
        <NameWrapper disabled={loading || disabled}>{text}</NameWrapper>
      </Wrapper>
    </Tooltip>
  );
}
