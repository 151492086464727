import { useMemo } from "react";

import { useProjectId } from "../../../../../../Project/hooks/useProjectId";
import { SeriesListItemFragmentType } from "./useStudiesList";
import { useTaskSeries } from "./useTaskSeries";

export function useTaskNonViewOnlySeries(): SeriesListItemFragmentType[] | undefined {
  const series = useTaskSeries();
  const currentProjectId = useProjectId();
  return useMemo(
    () =>
      series?.filter(({ viewOnly }) => {
        const seriesProjectViewOnlyStatus = viewOnly.find(
          ({ projectId }) => projectId === currentProjectId
        );
        return !seriesProjectViewOnlyStatus || !seriesProjectViewOnlyStatus.enabled;
      }),
    [series]
  );
}
