import cloneDeep from "lodash/cloneDeep";
import { useContext } from "react";

import {
  DRAW_QUEUE,
  ViewerConfigType,
} from "../../../../../../../../common/store/annotatePage/ViewerConfigType";
import { ROI } from "../../../../../../../../cornerstone/ToolType";
import { ContourContext } from "../../../../../../../ContourContext";
import { ContourToolDataType } from "../../../../../../../types/ContourToolDataType";
import { useSelectedViewerRoiId } from "../../../selection/useSelectedViewerRoiId";
import { useGetViewerImageId } from "../../../Viewer/hooks/useGetViewerImageId";
import { useSetViewerToolQueue } from "../../../Viewer/hooks/useSetViewerToolQueue";
import { appendToolSliceData, getToolSliceData } from "../../legacy/ToolUtils";
import { useContourChanged } from "./useContourChanged";
import { useViewerCanPasteContour } from "./useViewerCanPasteContour";

type ReturnType = {
  pasteContour: () => void;
};

export function usePasteContour(viewerConfig: ViewerConfigType): ReturnType {
  const { id: viewerConfigId, seriesId } = viewerConfig;
  const { contourClipboardData } = useContext(ContourContext);
  const { onContourChanged } = useContourChanged(seriesId);
  const selectedRoiId = useSelectedViewerRoiId(viewerConfig);

  const imageId = useGetViewerImageId(viewerConfigId);

  const setViewerToolQueue = useSetViewerToolQueue();

  const { canPaste } = useViewerCanPasteContour(viewerConfig);

  const pasteContour = async () => {
    if (!contourClipboardData) {
      throw new Error("There is nothing to paste but the user was able to click Paste");
    }
    if (selectedRoiId === null) {
      throw new Error("There is no selected roi id to paste to");
    }

    if (imageId === null) {
      throw new Error("imageId is null in when handling paste contour");
    }

    if (!canPaste()) {
      return;
    }

    const { toolData } = contourClipboardData;

    const toolDataCopy = toolData.map((contour) => {
      const copiedContour = cloneDeep<ContourToolDataType>(contour);
      copiedContour.roi.id = selectedRoiId;
      return copiedContour;
    });

    const existingToolData = getToolSliceData(selectedRoiId, imageId, ROI);

    const combinedToolData = [...existingToolData, ...toolDataCopy];

    //add new data to cornerstone
    appendToolSliceData(ROI, imageId, toolDataCopy);

    setViewerToolQueue({
      viewerConfigId,
      queue: DRAW_QUEUE,
      value: true,
    });

    await onContourChanged({
      toolData: combinedToolData,
      roiId: selectedRoiId,
      imageId,
    });
  };

  return { pasteContour };
}
