import { ToolClickEventType } from "../tools/types/ToolClickEventType";

export type MouseState = { x: number; y: number };

export function getMouseState(event: ToolClickEventType): MouseState | undefined {
  const {
    event: { clientX, clientY },
  } = event;

  return {
    x: clientX + 2,
    y: clientY - 4,
  };
}
