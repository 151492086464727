import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: 4px;
  padding: 0 8px;
`;

const Label = styled.div`
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.neutral.neutral4};
`;

const MetricsWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex: 1;
`;

const Metric = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.neutral.neutral4};
`;

const Divider = styled.div`
  height: 16px;
  border: 0.5px solid ${(props) => props.theme.colors.neutral.neutral6};
  margin: 0 1px;
  transform: scaleX(0.5);
`;

interface AxisTickLabelProps {
  name: string;
  success: number;
  total: number;
  percent: number;
}

export function AxisTickLabel({ name, success, total, percent }: AxisTickLabelProps) {
  const roundedPercentage = Math.round(percent * 100) / 100;
  return (
    <Wrapper>
      <Label>{name}</Label>
      <MetricsWrapper>
        <Metric>{`${success}/${total}`}</Metric>
        <Divider />
        <Metric>{`${roundedPercentage}%`}</Metric>
      </MetricsWrapper>
    </Wrapper>
  );
}
