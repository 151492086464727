export const NEW = "NEW";
export const ACTIVE = "ACTIVE";
export const PLANNING = "PLANNING";
export const SUSPENDED = "SUSPENDED";
export const ENROLLING = "ENROLLING";
export const COMPLETED = "COMPLETED";
export const TERMINATED = "TERMINATED";
export const DONE = "DONE";
export const DRAFT = "DRAFT";
export const UNKNOWN = "DEFAULT";

export type ProjectStatusType =
  | typeof NEW
  | typeof ACTIVE
  | typeof PLANNING
  | typeof SUSPENDED
  | typeof ENROLLING
  | typeof COMPLETED
  | typeof TERMINATED
  | typeof DONE
  | typeof DRAFT
  | typeof UNKNOWN;
