import { ToolDataLesionFragmentType } from "../components/Annotate/page/AnnotationPanel/hooks/useLazyTaskToolData";
import { DEFAULT_ANNOTATION_COLOR } from "../components/Annotate/page/AnnotationPanel/utils/useLesionColorDeferred";
import { ParsedRoiType, parseRoi } from "./parseRoi";

export type ParsedLesionType = {
  id: number;
  taskId: number | null;
  rois: ParsedRoiType[];
};

export function parseLesion(
  lesionFragment: ToolDataLesionFragmentType,
  baseWadoUrl: string
): ParsedLesionType {
  const { id, taskId, rois: roiFragments, anatomicalStructure } = lesionFragment;

  const { color } = anatomicalStructure ?? {
    color: null,
  };

  const roiColor = color ?? DEFAULT_ANNOTATION_COLOR;

  const rois = roiFragments.map((roi) => parseRoi(roi, roiColor, baseWadoUrl, id));

  return {
    id,
    taskId,
    rois,
  };
}
