import React, { ReactElement } from "react";

import { BaseDialogProps } from "./BaseDialogProps";
import { ContentDialogProps } from "./ContentDialogProps";
import { DialogContents } from "./DialogContents";
import { DialogWrapper, ReactModalOverrides } from "./DialogWrapper";

interface Props extends ContentDialogProps, BaseDialogProps {
  reactModalProps?: ReactModalOverrides;
}

export function Dialog({
  open,
  setOpen,
  label,
  content,
  options,
  reactModalProps,
}: Props): ReactElement {
  const handleOnClose = () => {
    setOpen(false);
  };

  return (
    <DialogWrapper open={open} setOpen={setOpen} reactModalProps={reactModalProps}>
      <DialogContents label={label} content={content} options={options} onClose={handleOnClose} />
    </DialogWrapper>
  );
}
