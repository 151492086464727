import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { MutationTuple } from "@apollo/client/react/types/types";

import { deleteEllipseFromCache } from "../../../../AnnotationPanel/hooks/deleteEllipseFromCache";
import { deleteEllipseRoiIdInCache } from "../../legacy/EllipseToolChangeDetector";

const MUTATION = gql`
  mutation DeleteEllipse($ellipseId: Int!) {
    delete: delete_ellipse(where: { ellipse_id: { _eq: $ellipseId } }) {
      ellipses: returning {
        id: ellipse_id
        roiId: roi_id
      }
    }
  }
`;

type Variables = {
  ellipseId: number;
};

type Data = {
  delete: { ellipses: { id: number; roiId: number }[] };
};

export function useDeleteEllipse(): MutationTuple<Data, Variables> {
  return useMutation<Data, Variables>(MUTATION, {
    update(cache, { data }) {
      if (!data) {
        throw new Error("Something went wrong updating the cache after deleting an ellipse");
      }
      const {
        delete: { ellipses },
      } = data;

      for (const { id, roiId } of ellipses) {
        deleteEllipseRoiIdInCache(roiId);
        deleteEllipseFromCache(roiId, id, cache);
      }

      cache.gc();
    },
  });
}
