import { useParsedQuery } from "../../apollo/utils/useParsedQuery";
import { useParsedQueryReturnType } from "../../apollo/utils/useParsedQueryReturnType";
import { parseProjectDetails } from "../../Dashboard/utils/parseProjectDetails";
import { useProjectId } from "../../Project/hooks/useProjectId";
import { ProjectType } from "../types/ProjectType";
import { RawProjectDetailsType } from "../types/RawProjectDetailsType";
import { GET_ALL_PROJECT_DETAILS } from "./GetAllProjectsDetails";

type Data = RawProjectDetailsType;
type Variables = undefined;
type Output = ProjectType | undefined;

export function useCurrentProject(): useParsedQueryReturnType<number, Output, Variables> {
  const projectId = useProjectId();

  return useParsedQuery<number, Output, Data, Variables>({
    query: GET_ALL_PROJECT_DETAILS,
    input: projectId,
    parseVariables: () => undefined,
    parseData,
  });
}

function parseData(data: Data, projectId: number): Output {
  return parseProjectDetails(data).find((project) => project.id === projectId);
}
