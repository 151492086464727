import { useContext } from "react";

import { ViewerConfigType } from "../../../../../../common/store/annotatePage/ViewerConfigType";
import { TaskContext } from "../../../../../TaskContext";
import { useViewerSeriesViewOnly } from "../../ViewerPanel/useViewerSeriesViewOnly";
import { ViewerContext } from "../../ViewerPanel/Viewer/ViewerContext";

export function useAreSeriesClassificationsViewOnly(viewerConfig: ViewerConfigType): boolean {
  const { viewOnly: isTaskViewOnly } = useContext(TaskContext);
  const { viewOnly: viewerViewOnly } = useContext(ViewerContext);

  const isSeriesViewOnly = useViewerSeriesViewOnly(viewerConfig);

  return isSeriesViewOnly || viewerViewOnly || isTaskViewOnly;
}
