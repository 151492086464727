import { useBeforeUnload } from "nota-predict-web/src/common/utils/useBeforeUnload";
import React, { PropsWithChildren, useCallback, useContext, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import styled from "styled-components";

import { TaskContext } from "../../../../../TaskContext";
import { rulesSetForScope } from "./QcForm.utils";
import { QcFormElement } from "./QcFormElement";
import { QcFormHeader } from "./QcFormHeader";
import { useQualityControlContext } from "./QualityControlProvider";
import { QcFormScope } from "./QualityControlProvider.types";

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 2px 1px;
`;

export const SUBJECT_TAB = "SUBJECT_TAB";
export const SERIES_TAB = "SERIES_TAB";
export const DEFAULT_TAB = SERIES_TAB;
export type qcSchemaTabTypes = typeof SUBJECT_TAB | typeof SERIES_TAB;

export const QC_SCOPE_FOR_TAB: {
  [key in qcSchemaTabTypes]: string;
} = {
  [SUBJECT_TAB]: "PATIENT", // TODO: rename to SUBJECT when db is ready
  [SERIES_TAB]: "SERIES",
};

interface QcFormElementsProps {
  scope: QcFormScope;
  visible: boolean;
}

const StyledFlexForm = styled("form")({
  display: "flex",
  flexDirection: "column",
  overflowY: "scroll",
});

interface QcFormProps
  extends PropsWithChildren<{
    onSubmit?: React.FormHTMLAttributes<HTMLFormElement>["onSubmit"];
  }> {
  visible: boolean;
  scope: QcFormScope;
  header: React.ReactNode;
}

const VisibilityControlledForm = styled(({ header, children, onSubmit, ...props }: QcFormProps) => {
  return (
    <Wrapper {...props}>
      {header}
      <StyledFlexForm onSubmit={onSubmit}>{children}</StyledFlexForm>
    </Wrapper>
  );
})(({ visible, scope }) => ({
  display: visible ? "flex" : "none",
  flexDirection: "column",
  height: "100%",
  position: "relative",
  overflowY: "auto",
  "& > div:nth-of-type(2)": {
    marginTop: scope === "PATIENT" ? "46px" : scope === "SERIES" ? "76px" : "16px",
  },
}));

export function QcForm({ scope, visible }: QcFormElementsProps): JSX.Element {
  const {
    submitActiveFormState: [, setSubmitFormAction],
    storedQcReport,
    activeScopeState,
    isUpToDate,
    formManager,
    schema,
  } = useQualityControlContext();
  const { viewOnly } = useContext(TaskContext);
  const [activeScope] = activeScopeState ?? [];

  const isActivePanel = visible;
  const rules = rulesSetForScope(scope, schema);

  useBeforeUnload({
    preventUnload: !viewOnly && !isUpToDate,
  });

  const submitForm = useCallback(
    () =>
      isActivePanel &&
      formManager.handleSubmit(() => {
        // PRAGMA: handleSubmit must take a callback function, but we are not using data here.
        return;
      }),
    [formManager, isActivePanel]
  );

  useEffect(() => {
    if (!isActivePanel) {
      return;
    }
    setSubmitFormAction(submitForm);
  }, [submitForm, setSubmitFormAction, isActivePanel]);

  return (
    <FormProvider {...formManager}>
      <VisibilityControlledForm
        onSubmit={submitForm}
        visible={visible}
        scope={activeScope}
        header={
          <QcFormHeader isValid={formManager.formState.isValid} isLoading={!storedQcReport?.id} />
        }
      >
        {rules.map((rule) => (
          <QcFormElement
            key={rule.name}
            rule={rule}
            scope={scope}
            isActive={isActivePanel}
            disabled={viewOnly || !storedQcReport?.id}
          />
        ))}
      </VisibilityControlledForm>
    </FormProvider>
  );
}
