import { createSelector } from "@reduxjs/toolkit";

import { getAllTimepointStatTypes } from "../../../Annotate/components/Annotate/page/AnnotationPanel/utils/orderAndGroupStats";
import {
  ANNOTATE_ALLOW_LESIONS,
  ANNOTATE_ALLOW_ORGANS,
  ANNOTATE_CALCULATE_CONTOUR_MEASUREMENTS,
  ANNOTATE_CAN_DRAW_MISSING_ROIS,
  ANNOTATE_INCLUDE_OTHER_LESIONS,
  ANNOTATE_SERIES_SHOW_FOLLOW_UP_LABELS,
  ANNOTATE_SERIES_SHOW_TUMOUR_RESPONSE,
  ANNOTATE_SHOW_PREDICTED_ROIS,
  TYPE_RECIST_1_1,
} from "../../../Annotate/enums/TaskDescriptionEnums";
import {
  CanDrawMissingRoisArgsType,
  ClassificationModeType,
  ClassificationsType,
} from "../../../Annotate/types/TaskDescriptionType";
import { RootState } from "../store";
import { findEnabledOption } from "./utils/findEnabledOption";
import { getClassificationModeToPropertiesMap } from "./utils/getClassificationModeToPropertiesMap";
import { parseClassifications } from "./utils/parseClassifications";
import { parseSeriesClassifications } from "./utils/parseSeriesClassifications";

const task = (state: RootState) => state.annotatePage.task;

const taskSelector = createSelector([task], (task) => task);

export const previousTaskSelector = createSelector([taskSelector], ({ previousTasks }) => {
  const length = previousTasks.length;
  if (length < 2) {
    return undefined;
  }
  return previousTasks[length - 2];
});

export const taskDescriptionSelector = createSelector(
  [taskSelector],
  ({ description }) => description
);

export const taskLesionsSelector = createSelector([taskSelector], ({ description }) => {
  if (!description) {
    throw new Error("Task description null when trying to access lesion");
  }
  return description.lesion;
});

export const taskLesionGroupingSelector = createSelector([taskLesionsSelector], (lesion) => {
  return lesion?.grouping;
});

export const taskLesionTimepointSelector = createSelector([taskLesionsSelector], (lesion) => {
  return lesion?.timepoint;
});

export const taskToolsSelector = createSelector([taskLesionTimepointSelector], (timepoint) => {
  const objects = timepoint?.objects.map((timepointObject) => timepointObject.type);

  return objects ?? [];
});

export const classificationPropertiesSelector = createSelector([taskLesionsSelector], (lesion) => {
  const classifications: ClassificationsType[] = [];
  const { classifications: lesionClassifications } = lesion || {};
  if (lesionClassifications) {
    classifications.push(...lesionClassifications);
  }

  return getClassificationModeToPropertiesMap(classifications);
});

export const taskTimepointOptionsSelector = createSelector(
  [taskLesionTimepointSelector],
  (timepoint) => {
    return timepoint?.options;
  }
);

export const taskTimepointEnabledOptionsSelector = createSelector(
  [taskTimepointOptionsSelector],
  (options) => {
    if (!options) {
      return undefined;
    }

    return options.filter(({ enabled }) => enabled);
  }
);

export const taskModesSelector = createSelector(
  [taskLesionsSelector, taskTimepointEnabledOptionsSelector],
  (lesion, options) => {
    if (!lesion) {
      return [];
    }

    const modes: ClassificationModeType[] = [];

    const { classifications = [] } = lesion;

    classifications.forEach(({ mode }) => modes.push(mode as ClassificationModeType));

    if (!options) {
      return [...new Set(modes)];
    }

    options.forEach(({ modes: allowedModes }) => modes.push(...allowedModes));

    return [...new Set(modes)];
  }
);

export const lesionClassificationModeDefinitionsSelector = createSelector(
  [taskLesionsSelector],
  (lesion) => {
    return parseClassifications(lesion?.classifications);
  }
);

export const patientSelector = createSelector([taskSelector], ({ description }) => {
  if (!description) {
    throw new Error("Task description null when trying to access patient");
  }

  return description.patient;
});

export const studySelector = createSelector([patientSelector], (patient) => {
  return patient?.study;
});

export const seriesSelector = createSelector([studySelector], (study) => {
  return study?.series;
});

export const seriesOptionsSelector = createSelector([seriesSelector], (series) => {
  return series?.options ?? [];
});

export const seriesClassificationsSelector = createSelector([seriesSelector], (series) => {
  return parseSeriesClassifications(series?.classifications);
});

export const showFollowUpLabelsSelector = createSelector([seriesOptionsSelector], (options) => {
  return options.some(({ rule }) => rule === ANNOTATE_SERIES_SHOW_FOLLOW_UP_LABELS);
});

export const showTumourResponseLabelsSelector = createSelector(
  [seriesOptionsSelector],
  (options) => {
    return options.some(({ rule }) => rule === ANNOTATE_SERIES_SHOW_TUMOUR_RESPONSE);
  }
);

const checksSelector = createSelector([taskDescriptionSelector], (description) => {
  if (!description) {
    throw new Error("Description is null when selecting checks");
  }

  return description.checks;
});

export const taskCheckSelector = createSelector([checksSelector], (checks) => checks?.task);

export const lesionCheckSelector = createSelector([checksSelector], (checks) => checks?.lesion);

export const taskTypeSelector = createSelector([taskDescriptionSelector], (description) => {
  if (!description) {
    throw new Error("Description is null when selecting task type");
  }

  const { type } = description;
  return type;
});

export const recistTaskSelector = createSelector([taskTypeSelector], (type) => {
  return type === TYPE_RECIST_1_1;
});

export const allowLesionsSelector = createSelector([taskDescriptionSelector], (description) => {
  return findEnabledOption(description, ANNOTATE_ALLOW_LESIONS) !== undefined;
});

export const includeOtherLesionsSelector = createSelector(
  [taskDescriptionSelector],
  (description) => {
    return findEnabledOption(description, ANNOTATE_INCLUDE_OTHER_LESIONS) !== undefined;
  }
);

export const includePredictedLesionsSelector = createSelector(
  [taskDescriptionSelector],
  (description) => {
    return findEnabledOption(description, ANNOTATE_SHOW_PREDICTED_ROIS) !== undefined;
  }
);

export const showPredictedRoisSelector = createSelector(
  [taskDescriptionSelector],
  (description) => {
    return findEnabledOption(description, ANNOTATE_SHOW_PREDICTED_ROIS) !== undefined;
  }
);

export const includeCalculateContourMeasurementsSelector = createSelector(
  [taskDescriptionSelector],
  (description) => {
    return findEnabledOption(description, ANNOTATE_CALCULATE_CONTOUR_MEASUREMENTS) !== undefined;
  }
);

export const drawMissingRoisOptionSelector = createSelector(
  [taskDescriptionSelector],
  (description) => {
    const option = findEnabledOption(description, ANNOTATE_CAN_DRAW_MISSING_ROIS);
    if (!option) {
      return undefined;
    }

    const { args } = option;
    return args as CanDrawMissingRoisArgsType;
  }
);

export const allowedOrgansSelector = createSelector([taskDescriptionSelector], (description) => {
  const allowedOrganOption = findEnabledOption(description, ANNOTATE_ALLOW_ORGANS);
  if (!allowedOrganOption) {
    return [];
  }

  const { args } = allowedOrganOption;
  if (!args) {
    return [];
  }

  type ArgsType = { organs: string[] };

  const castArgs = args as ArgsType;

  if (!("organs" in castArgs)) {
    throw new Error(
      `Rule: ${ANNOTATE_ALLOW_ORGANS} should contain args that contain an 'organs' key!`
    );
  }

  const { organs } = castArgs;

  // noinspection SuspiciousTypeOfGuard
  const allStrings = organs.every((arg) => typeof arg === "string");
  if (!allStrings) {
    throw new Error(`Rule: ${ANNOTATE_ALLOW_ORGANS} should contain organs that are all strings!`);
  }
  return organs;
});

export const roiStatsSelector = createSelector([taskLesionTimepointSelector], (timepoint) => {
  // TODO: remove timepoint stats from task description since we no longer use them
  // const stats = timepoint?.stats ?? [];
  // return orderAndGroupStats(stats);
  return getAllTimepointStatTypes();
});

export const lesionStatsSelector = createSelector([taskLesionsSelector], (lesion) => {
  return lesion?.stats;
});
