import { gql } from "@apollo/client";

export const SERIES_FLAG_FRAGMENT = gql`
  fragment SeriesFlag on series_flag {
    id
    seriesId: series_id
    taskId: task_id
    reasons: series_flag_reasons {
      id
      reason
    }
    notes
  }
`;
