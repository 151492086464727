import cornerstoneTools from "@altis-labs/cornerstone-tools";

import { hexToRgbArray } from "../../../ViewerPanel/tools/legacy/labelmapUtils/hexToRgbArray";
import { Mask } from "../../MaskContextProvider";

export function loadMask(
  { id, anatomicalStructureId }: Mask,
  color: string,
  buffer: ArrayBuffer,
  firstImageId: string,
  numberOfFrames: number
): void {
  const { setters, getters } = cornerstoneTools.getModule("segmentation");

  const labelmapIndex = id;
  const metadata: never[] = [];
  const segmentsOnLabelmapArray = undefined;
  const colorLUTIndex = 0;
  const colorOffset = anatomicalStructureId - 1;

  // for some reason, editing the colorLUT directly doesn't work properly, despite it displaying in the console correctly. So we make a copy of it, edit it, and then set it.
  const colorLUTCopy = [...getters.colorLUT(colorLUTIndex)];

  colorLUTCopy[anatomicalStructureId] = hexToRgbArray(color);

  // cornerstone unshifts a 0 color into the first index of the colorLUT, so we shift it out
  colorLUTCopy.shift();

  setters.colorLUT(colorLUTIndex, colorLUTCopy);

  setters.labelmap3DByFirstImageId(
    firstImageId,
    buffer,
    labelmapIndex,
    metadata,
    numberOfFrames,
    segmentsOnLabelmapArray,
    colorLUTIndex,
    colorOffset
  );
}
