import { useEffect, useRef } from "react";
import { useLocation } from "react-router";

// Returns a ref to be passed into an element which needs scrolling to top on location change
//TODO: It is not clear if this is required, determine this and remove if necessary
export function useScrollToTop<T extends HTMLElement>(): React.RefObject<T> {
  const scrollableElementRef = useRef<T>(null);
  const location = useLocation();
  useEffect(() => {
    scrollableElementRef.current?.scrollTo(0, 0);
  }, [location]);
  return scrollableElementRef;
}
