import { RoiListItemFragmentType } from "../../AnnotationPanel/fragments/RoiListItemFragment";
import { useSelectedLesion } from "./useSelectedLesion";

export function useSelectedLesionRois(): RoiListItemFragmentType[] {
  const selectedLesion = useSelectedLesion();
  if (!selectedLesion) {
    return [];
  }

  return selectedLesion.rois;
}
