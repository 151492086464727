import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { MutationTuple } from "@apollo/client/react/types/types";

import { Optional } from "../../../../../../../../common/utils/utilityTypes/Optional";
import { ELLIPSE_FRAGMENT } from "../../../../../../../fragments/EllipseFragment";
import { EllipseFragmentType } from "../../../../../../../fragments/EllipseFragmentType";
import { EllipseType } from "../../../../../../../types/EllipseType";
import { getRoiCacheId } from "../../../../AnnotationPanel/hooks/getRoiCacheId";
import { updateEllipseToolDataId } from "../utils/updateEllipseToolDataId";

const MUTATION = gql`
  mutation InsertEllipse($roiId: Int!, $data: jsonb!, $createdBy: Int!) {
    insert_ellipse_one(object: { roi_id: $roiId, data: $data, created_by: $createdBy }) {
      ...Ellipse
    }
  }
  ${ELLIPSE_FRAGMENT}
`;

type Variables = {
  data: Optional<EllipseType, "id">;
  roiId: number;
  createdBy: number;
};

type Data = {
  insert_ellipse_one: EllipseFragmentType;
};

export function useInsertEllipse(): MutationTuple<Data, Variables> {
  return useMutation<Data, Variables>(MUTATION, {
    update(cache, { data }) {
      if (!data) {
        throw new Error("Something went wrong updating the cache after inserting a ellipse");
      }
      const { insert_ellipse_one } = data;
      const { roiId } = insert_ellipse_one;

      const { id: ellipseId, data: ellipseData } = insert_ellipse_one;
      updateEllipseToolDataId(ellipseId, ellipseData);

      cache.modify({
        id: getRoiCacheId(roiId, cache),
        fields: {
          ellipse() {
            return cache.writeFragment({
              data: insert_ellipse_one,
              fragment: gql`
                fragment Roi on roi {
                  id
                  type
                }
              `,
            });
          },
        },
      });
    },
  });
}
