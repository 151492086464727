import React, { useEffect } from "react";

import { useCurrentUser } from "../../../contexts/UserContext/useCurrentUser";
import { PROJECT_ROLE_COORDINATOR } from "../../../types/UserRoleType";
import { UserType } from "../../../types/UserType";
import { OK_CANCEL } from "../../Dialog/DialogActionButtonsType";
import { useDialog, useDialogReturnType } from "../../Dialog/useDialog";
import { ProjectReassignment } from "../ProjectReassignment";
import { ManagerOptionType } from "../types/ManagerOptionType";
import { TaskReassignmentType } from "../types/TaskReassignmentType";
import {
  buildGetUsersTaskAssignmentsFilter,
  useGetUsersTaskAssignments,
} from "../useGetUsersTaskAssignments";
import { ContentsWrapper, ProjectLabelWrapper, ProjectsWrapper } from "./useSuspendUserDialog";

export type UserIdentifierType = Pick<UserType, "id" | "email" | "firstName" | "lastName">;

export function useManageUserDeletionDialog(
  usersToDelete: UserIdentifierType[],
  taskReassignments: TaskReassignmentType[],
  setTaskReassignments: (reassignments: TaskReassignmentType[]) => void,
  onConfirmSuspendUser: () => void,
  users: UserType[],
  projectName?: string,
  projectId?: number
): useDialogReturnType {
  const { id: currentUserId } = useCurrentUser();

  const managers = users.filter((user) => user.projectRoles?.includes(PROJECT_ROLE_COORDINATOR));

  const filter = buildGetUsersTaskAssignmentsFilter(
    usersToDelete.map(({ id }) => id),
    projectId
  );

  const { data, loading, error } = useGetUsersTaskAssignments(filter);

  useEffect(() => {
    if (!projectId) {
      setTaskReassignments([]);
      return;
    }

    if (!data) {
      setTaskReassignments([]);
      return;
    }
    setTaskReassignments([
      {
        projectId: projectId,
        managerId: managers.length > 0 ? managers[0].id : currentUserId,
        taskAssignments: data.taskAssignments,
      },
    ]);
    // managers as a dependancy causes an infinite loop, but users (which is what managers is built from) does not
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserId, setTaskReassignments, data, projectId, users]);

  if (error) {
    throw new Error(
      "Failed to retrieve Task Assignments of User(s) being removed from the project."
    );
  }

  function handleManagerChange(projectId: number, manager: ManagerOptionType | null) {
    if (!manager) {
      return;
    }
    const newTaskReassignments: TaskReassignmentType[] = Object.assign([], taskReassignments);
    const index = newTaskReassignments.findIndex(
      (reassignment) => reassignment.projectId === projectId
    );
    if (index < 0) {
      return;
    }
    newTaskReassignments[index].managerId = manager.value;
    setTaskReassignments(newTaskReassignments);
  }

  const handleConfirmSuspendUser = () => {
    onConfirmSuspendUser();
  };

  const label = usersToDelete.length === 1 ? "Remove Team Member" : "Remove Team Members";

  const userIds = usersToDelete.map(({ id }) => id);

  const content =
    projectId === undefined || projectName === undefined || loading ? (
      <></>
    ) : (
      <TaskReassignment
        userIds={userIds}
        projectName={projectName}
        projectId={projectId}
        managers={managers}
        taskReassignments={taskReassignments}
        onChangeManager={handleManagerChange}
      />
    );

  return useDialog({
    label,
    content,
    options: {
      showCloseButton: false,
      actionButtonOptions: {
        type: OK_CANCEL,
        okText: "Remove",
        onOkOverride: handleConfirmSuspendUser,
      },
    },
  });
}

interface TaskReassignmentProps {
  userIds: number[];
  managers: UserType[];
  projectName: string;
  projectId: number;
  taskReassignments: TaskReassignmentType[];
  onChangeManager: (projectId: number, manager: ManagerOptionType | null) => void;
}

function TaskReassignment({
  userIds,
  managers,
  projectName,
  projectId,
  taskReassignments,
  onChangeManager,
}: TaskReassignmentProps): JSX.Element {
  const { id: currentUserId, email: currentUserEmail } = useCurrentUser();

  const userOverride: ManagerOptionType = {
    label: currentUserEmail,
    value: currentUserId,
  };

  const nonRemovedManagers = managers.filter(({ id }) => !userIds.includes(id));

  const managerOptions =
    nonRemovedManagers.length > 0
      ? nonRemovedManagers.map(({ id, email }) => ({
          label: email,
          value: id,
        }))
      : [userOverride];

  const selectedValue =
    managerOptions.find(
      (option) => taskReassignments[0] && taskReassignments[0].managerId === option.value
    ) || managerOptions[0];

  const message =
    userIds.length === 1
      ? `Removing a user will require that their tasks be assigned to a project manager. Select a manager to re-assign
       their tasks to`
      : `Removing users will require that their tasks be assigned to a project manager. Select a manager to re-assign
       their tasks to`;

  return (
    <ContentsWrapper>
      <ProjectLabelWrapper>{message}</ProjectLabelWrapper>
      <ProjectsWrapper>
        <ProjectReassignment
          name={projectName}
          managerOptions={managerOptions}
          selectedValue={selectedValue}
          onChangeManager={onChangeManager}
          projectId={projectId}
        />
      </ProjectsWrapper>
    </ContentsWrapper>
  );
}
