import { TUMOR_SPELLING } from "../../../common/utils/spellingConstants";
import {
  GraphView,
  IPRO,
  MORTALITY,
  RECIST,
  VIEW_DISPLAY_NAMES,
} from "../../ChangeFromBaseline/components/Filter";
import { AnalysisGraphKeys } from "../../MedianSurvival/utils/getMedianSurvival";

export function formatDataType(dataType: AnalysisGraphKeys, graphView: GraphView): string {
  let formatted;
  switch (dataType) {
    case IPRO:
      formatted = "Predicted Survival";
      break;
    case RECIST:
      formatted = `${VIEW_DISPLAY_NAMES[graphView]} ${TUMOR_SPELLING} Burden`;
      break;
    case MORTALITY:
      formatted = "Mortality Risk";
      break;
    default:
      throw new Error("DataType not supported in formatDataType");
  }
  return formatted;
}

const VIEW_UNITS: Record<GraphView, string> = {
  DIAMETRIC: "mm",
  VOLUMETRIC: "cc",
};

export function getDataTypeUnit(dataType: AnalysisGraphKeys, graphView: GraphView) {
  let unit;
  switch (dataType) {
    case IPRO:
      unit = "Months";
      break;
    case RECIST:
      unit = VIEW_UNITS[graphView];
      break;
    case MORTALITY:
      unit = "%";
      break;
    default:
      throw new Error("DataType not supported in getDataTypeUnit");
  }
  return unit;
}

export function formatDataTypeAxisLabel(
  dataType: AnalysisGraphKeys,
  graphView: GraphView,
  unitOverride?: string
): string {
  const unit = unitOverride ?? getDataTypeUnit(dataType, graphView);
  return `${formatDataType(dataType, graphView)} (${unit})`;
}
