import { ApolloCache, useMutation } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { FetchResult } from "@apollo/client/link/core";
import { MutationTuple } from "@apollo/client/react/types/types";

import { ClassificationValuesType } from "../../../../../types/TaskDescriptionType";
import { ROI_LIST_ITEM_FRAGMENT, RoiListItemFragmentType } from "../fragments/RoiListItemFragment";
import { getLesionCacheId } from "./getLesionCacheId";
import { getSeriesFollowUpIdFromCache } from "./getSeriesFollowUpIdFromCache";
import {
  getTumourAndDiagnosisVariables,
  TumourAndDiagnosisVariables,
} from "./getTumourAndDiagnosisVariables";

export const INTERNAL_MUTATION = `
    insert_roi(objects: $rois) {
      returning {
        ...RoiListItem
        lesionId: lesion_id
      }
    }`;

const MUTATION = gql`
  mutation InsertRois($rois: [roi_insert_input!]!, $skipRecist: Boolean!) {
    ${INTERNAL_MUTATION}
  }
  ${ROI_LIST_ITEM_FRAGMENT}
`;

export type Variables = {
  rois: {
    lesion_id: number;
    series_id: number;
    created_by: number;
    tumour_roi_maps?: {
      data: TumourAndDiagnosisVariables;
    };
    roi_classifications: {
      data: {
        classification: ClassificationValuesType;
      }[];
      on_conflict: {
        constraint: string;
        update_columns: string[];
      };
    };
  }[];
  skipRecist: boolean;
};

type Input = {
  seriesId: number;
  lesionId: number;
  createdBy: number;
  classificationValues?: ClassificationValuesType[];
  burden?: number;
};

export type Data = {
  insert_roi: {
    returning: ({ lesionId: number } & RoiListItemFragmentType)[];
  };
};

export type InsertRoisReturnType = MutationTuple<Data, Variables>;

export function useInsertRois(): InsertRoisReturnType {
  return useMutation<Data, Variables>(MUTATION, {
    update: updateCache,
  });
}

export function updateCache(cache: ApolloCache<Data>, { data }: FetchResult<Data>): void {
  if (!data) {
    throw new Error("Something went wrong updating the cache after inserting rois");
  }

  const {
    insert_roi: { returning: rois },
  } = data;

  for (const roi of rois) {
    const { lesionId } = roi;
    cache.modify({
      id: getLesionCacheId(lesionId, cache),
      fields: {
        rois(rois = []) {
          const newRoiRef = cache.writeFragment({
            data: roi,
            fragment: gql`
              fragment LesionReference on lesion {
                id
                type
              }
            `,
          });
          return [...rois, newRoiRef];
        },
      },
    });
  }
}

export function getVariables<T>(
  inputs: Input[],
  isRecist: boolean,
  cache: ApolloCache<T>
): Variables {
  const rois = inputs.map(
    ({ seriesId, lesionId, createdBy, classificationValues = [], burden }) => {
      const classifications = classificationValues.map((classification) => ({
        classification,
      }));

      const followUpId = isRecist ? getSeriesFollowUpIdFromCache(seriesId, cache) : undefined;
      return {
        lesion_id: lesionId,
        series_id: seriesId,
        created_by: createdBy,
        roi_classifications: {
          data: classifications,
          on_conflict: {
            constraint: "roi_classification_roi_id_classification_key",
            update_columns: [],
          },
        },
        ...(isRecist && {
          tumour_roi_maps: {
            data: getTumourAndDiagnosisVariables(burden, followUpId),
          },
        }),
      };
    }
  );

  return { rois, skipRecist: !isRecist };
}
