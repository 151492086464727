import { TaskReassignmentType } from "../types/TaskReassignmentType";
import { Variables } from "../useReassignUsersTasks";

export function buildReassignmentData(reassignments: TaskReassignmentType[]): Variables {
  return {
    objects: reassignments.flatMap(({ managerId, taskAssignments }) => {
      return taskAssignments.map(({ patientId, taskId, progress }) => ({
        patient_id: patientId,
        task_id: taskId,
        user_id: managerId,
        show_badge: true,
        progress: progress,
      }));
    }),
  };
}
