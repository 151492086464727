import { gql } from "@apollo/client/core";

import { useParsedQuery } from "../../../../../../apollo/utils/useParsedQuery";
import { useParsedQueryReturnType } from "../../../../../../apollo/utils/useParsedQueryReturnType";
import { PATIENT_BIOPSY_DETAILS_FRAGMENT } from "../fragments/PatientBiopsyDetailsFragment";
import { PatientBiopsyDetailsFragmentType } from "../fragments/PatientBiopsyDetailsFragmentType";
import { parseBiopsyDetails } from "../parsers/parseBiopsyDetails";
import { PatientBiopsyDetailsType } from "../types/PatientBiopsyDetailsType";

export const QUERY = gql`
  query GetPatientBiopsyDetailsForCase($patientId: Int!) {
    biopsyDetails: patient_biopsy_details(where: { patient_id: { _eq: $patientId } }) {
      ...PatientBiopsyDetails
    }
  }
  ${PATIENT_BIOPSY_DETAILS_FRAGMENT}
`;

type Variables = {
  patientId: number;
};

type Data = {
  biopsyDetails: PatientBiopsyDetailsFragmentType[];
};

type Output = PatientBiopsyDetailsType[];

export function usePatientBiopsyDetails(
  patientId: number
): useParsedQueryReturnType<number, Output, Variables> {
  return useParsedQuery<number, Output, Data, Variables>({
    query: QUERY,
    input: patientId,
    parseVariables: (patientId) => ({ patientId }),
    parseData: ({ biopsyDetails }: Data) => biopsyDetails.map(parseBiopsyDetails),
  });
}
