import { ClassificationValuesType } from "../../../../../../types/TaskDescriptionType";
import { LesionListItemFragmentType } from "../../fragments/LesionListItemFragment";
import { RoiListItemFragmentType } from "../../fragments/RoiListItemFragment";
import { useGetTimepointOptionsFromRoiClassifications } from "./useGetTimepointOptionsFromRoiClassifications";

export type GetRoiPreventsLesionClassificationType = (
  lesion: LesionListItemFragmentType,
  { classifications }: RoiListItemFragmentType,
  classification: ClassificationValuesType
) => boolean;

/*
Determines, for a given `roi` and `lesion` (mode), if a specified `classification` should be prevented from existing
 */
export function useGetRoiPreventsLesionClassification(): GetRoiPreventsLesionClassificationType {
  const getTimepointOptionsFromRoiClassifications = useGetTimepointOptionsFromRoiClassifications();

  return (lesion, roi, classification) => {
    const options = getTimepointOptionsFromRoiClassifications(lesion, roi);

    const preventedClassifications = [
      ...new Set(
        options.flatMap(({ preventsLesionClassification }) => preventsLesionClassification)
      ),
    ];

    return preventedClassifications.includes(classification);
  };
}
