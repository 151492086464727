import { LesionListItemFragmentType } from "../fragments/LesionListItemFragment";
import { useJumpToLesionKeyImage } from "./useJumpToLesionKeyImage";
import { useTrySelectLesionId } from "./useTrySelectLesionId";

type ReturnType = (lesion: LesionListItemFragmentType) => void;

export function useOnLesionClicked(): ReturnType {
  const trySelectLesionId = useTrySelectLesionId();

  const jumpToLesionKeyImage = useJumpToLesionKeyImage();

  return (lesion: LesionListItemFragmentType) => {
    const { id } = lesion;

    const onLesionChanged = () => {
      jumpToLesionKeyImage(lesion);

      // const activeViewer = memoViewerConfigs.find(({ isActive }) => isActive);
      // const activeViewer = viewerConfigs.find(({ isActive }) => isActive);
      // const { seriesId: activeSeriesId } = activeViewer ?? {
      //   seriesId: undefined,
      // };

      // const { id: activeRoiId } = rois.find(
      //   ({ series: { id: seriesId } }) => seriesId === activeSeriesId
      // ) ?? { id: undefined };

      // TODO: disabled collapsing for now since doesn't play well with virtualization
      // dispatch(setSelectedRoiCollapsedState({ roiId: activeRoiId }));
    };

    if (!trySelectLesionId(id, onLesionChanged)) {
      return;
    }
  };
}
