import { useDeepCompareCallback } from "use-deep-compare";

import { ViewerConfigType } from "../../../../../../../common/store/annotatePage/ViewerConfigType";
import { useJumpToViewerRoiKeyImage } from "../../../AnnotationPanel/hooks/useJumpToViewerRoiKeyImage";
import { useSelectedLesionRois } from "../../selection/useSelectedLesionRois";

export function useJumpToViewer() {
  const selectedLesionRois = useSelectedLesionRois();

  const jumpToViewerRoiKeyImage = useJumpToViewerRoiKeyImage();

  return useDeepCompareCallback(
    ({ id, seriesId }: ViewerConfigType) => {
      const roi = selectedLesionRois.find(
        ({ series: { id: roiSeriesId } }) => roiSeriesId === seriesId
      );

      if (!roi) {
        return;
      }

      jumpToViewerRoiKeyImage(roi, id);
    },
    [jumpToViewerRoiKeyImage, selectedLesionRois]
  );
}
