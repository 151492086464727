import cornerstoneTools from "@altis-labs/cornerstone-tools";
import cornerstone from "cornerstone-core";
import EventEmitter from "events";

import { getImageFromCornerstoneCache } from "../../../../../../../cornerstone/getImageFromCornerstoneCache";
import {
  BRUSH,
  ELLIPTICAL_PROBE,
  ELLIPTICAL_ROI,
  FREEHAND_SCISSORS,
  MEASURE,
  PAN,
  ROI,
  ROI_SCULPTOR,
  WINDOWLEVEL,
  ZOOM,
} from "../../../../../../../cornerstone/ToolType";
import { appendContourDiameters } from "../../../AnnotationPanel/Stats/utils/appendContourDiameters";
import BrushTool from "./BrushTool";
import BrushToolWrapper from "./BrushToolWrapper";
import CurrentImageIdWatcher from "./CurrentImageIdWatcher";
import EllipseTool from "./EllipseTool";
import EllipseToolWrapper from "./EllipseToolWrapper";
import EllipticalProbeTool from "./EllipticalProbeTool";
import FreehandScissorsTool from "./FreehandScissorsTool";
import FreehandScissorsToolWrapper from "./FreehandScissorsToolWrapper";
import HockeyPuckTool from "./HockeyPuckTool";
import { getImageSize } from "./imageUtils/getImageSize";
import getIsolatedSegmentToolData from "./labelmapUtils/getIsolatedSegmentToolData";
import labelmapProvider from "./labelmapUtils/initLabelmapProvider";
import LengthTool from "./LengthTool";
import RoiTool from "./RoiTool";
import RoiToolWrapper from "./RoiToolWrapper";
import ToolControllerEvents from "./ToolControllerEvents";
import { getRoiToolStateDataForRoi, setToolSliceData } from "./ToolUtils";

class ToolController extends EventEmitter {
  constructor(cornerstoneElement, calculateMeasurements, onExceptionThrown) {
    super();

    if (!cornerstoneElement) {
      throw new Error("CornerstoneElement cannot be null!");
    }

    this.cornerstoneElement = cornerstoneElement;

    this.selectedRoi = null;
    this.selectedToolRoi = null;
    this.previousActiveTool = null;
    this.calculateMeasurements = calculateMeasurements;

    this.currentImageIdWatcher = new CurrentImageIdWatcher();
    this.currentImageIdWatcher.setCornerstoneElement(cornerstoneElement);

    let ellipseTool = new EllipseTool(cornerstoneElement);
    ellipseTool.setPassive();

    this.hockeyPuckTool = new HockeyPuckTool(cornerstoneElement);
    this.hockeyPuckTool.setOnRoiPushedCallback(this.onContourChanged);

    this.onEllipseChangedCallback = null;
    this.onEllipseCreatedCallback = null;

    this.ellipseToolWrapper = new EllipseToolWrapper(ellipseTool);
    this.ellipseToolWrapper.setOnEllipseCreatedCallback(this.onEllipseCreated);
    this.ellipseToolWrapper.setOnEllipseChangedCallback(this.onEllipseChanged);
    this.ellipseToolWrapper.setOnEllipseDoubleClickedCallback(this.onEllipseDoubleClicked);
    this.ellipseToolWrapper.setOnEllipseRightClickedCallback(this.onEllipseRightClicked);
    this.ellipseToolWrapper.setOnExceptionThrown(onExceptionThrown);
    this.onEllipseDoubleClickedCallback = null;
    this.onEllipseRightClickedCallback = null;

    this.brushTool = new BrushTool(cornerstoneElement);
    this.brushToolWrapper = new BrushToolWrapper(this.currentImageIdWatcher, this.brushTool);
    this.brushToolWrapper.setToolDataChangedCallback(this.onLabelmapChanged);
    this.brushToolWrapper.setOnUserMaskCopyHotKeyPressedCallback(this.onUserMaskCopyHotKeyPressed);
    this.brushToolWrapper.setOnUserMaskPasteHotKeyPressedCallback(
      this.onUserMaskPasteHotKeyPressed
    );
    this.brushToolWrapper.setOnBrushSizeChangedCallback(this.onBrushSizeChanged);

    this.freehandScissorsTool = new FreehandScissorsTool(cornerstoneElement);
    this.freehandScissorsToolWrapper = new FreehandScissorsToolWrapper(
      this.currentImageIdWatcher,
      this.freehandScissorsTool
    );
    this.freehandScissorsToolWrapper.setToolDataChangedCallback(this.onLabelmapChanged);
    this.freehandScissorsToolWrapper.setOnUserMaskCopyHotKeyPressedCallback(
      this.onUserMaskCopyHotKeyPressed
    );
    this.freehandScissorsToolWrapper.setOnUserMaskPasteHotKeyPressedCallback(
      this.onUserMaskPasteHotKeyPressed
    );

    this.onContourChangedCallback = null;

    let roiTool = new RoiTool(cornerstoneElement);
    roiTool.setPassive();

    this.roiToolWrapper = new RoiToolWrapper(this.currentImageIdWatcher, roiTool);
    this.roiToolWrapper.setToolDataChangedCallback(this.onContourChanged);
    this.roiToolWrapper.setOnContourRightClickedCallback(this.onContourRightClicked);
    this.roiToolWrapper.setOnContourDoubleClickedCallback(this.onContourDoubleClicked);
    this.roiToolWrapper.setOnExceptionThrown(onExceptionThrown);

    this.lengthTool = new LengthTool(cornerstoneElement);
    this.lengthTool.setOnLengthChangedCallback(this.onLengthDataChanged);

    this.ellipticalProbeTool = new EllipticalProbeTool(
      cornerstoneElement,
      this.onEllipseProbeCreated
    );

    //temp
    this.roiToolWrapper.setCornerstoneElement(cornerstoneElement);
  }

  setOnEllipseCreatedCallback(callback) {
    this.onEllipseCreatedCallback = callback;
  }

  setOnEllipseChangedCallback(callback) {
    this.onEllipseChangedCallback = callback;
  }

  setOnContourChangedCallback(callback) {
    this.onContourChangedCallback = callback;
  }

  subscribeToLabelmapChanged(callback) {
    this.on(ToolControllerEvents.LABELMAP_UPDATED, callback);
  }

  unsubscribeToLabelmapChanged(callback) {
    this.off(ToolControllerEvents.LABELMAP_UPDATED, callback);
  }

  subscribeToContourRightClicked(callback) {
    this.on(ToolControllerEvents.CONTOUR_RIGHT_CLICKED, callback);
  }

  subscribeToContourDoubleClicked(callback) {
    this.on(ToolControllerEvents.CONTOUR_DOUBLE_CLICKED, callback);
  }

  subscribeToUserMaskCopyHotKeyPressed(callback) {
    this.on(ToolControllerEvents.USER_MASK_COPY_HOT_KEY_PRESSED, callback);
  }

  subscribeToUserMaskPasteHotKeyPressed(callback) {
    this.on(ToolControllerEvents.USER_MASK_PASTE_HOT_KEY_PRESSED, callback);
  }

  subscribeToBrushSizeChanged(callback) {
    this.on(ToolControllerEvents.BRUSH_SIZE_CHANGED, callback);
  }

  unsubscribeFromContourRightClicked(callback) {
    this.off(ToolControllerEvents.CONTOUR_RIGHT_CLICKED, callback);
  }

  unsubscribeFromContourDoubleClicked(callback) {
    this.off(ToolControllerEvents.CONTOUR_DOUBLE_CLICKED, callback);
  }

  subscribeToMeasureChanged(callback) {
    this.on(ToolControllerEvents.MEASURE_UPDATED, callback);
  }

  unsubscribeToMeasureChanged(callback) {
    this.off(ToolControllerEvents.MEASURE_UPDATED, callback);
  }

  subscribeToEllipseProbeCreated(callback) {
    this.on(ToolControllerEvents.ELLIPSE_PROBE_CREATED, callback);
  }

  unsubscribeToEllipseProbeCreated(callback) {
    this.off(ToolControllerEvents.ELLIPSE_PROBE_CREATED, callback);
  }

  unsubscribeToUserMaskCopyHotKeyPressed(callback) {
    this.off(ToolControllerEvents.USER_MASK_COPY_HOT_KEY_PRESSED, callback);
  }

  unsubscribeToUserMaskPasteHotKeyPressed(callback) {
    this.off(ToolControllerEvents.USER_MASK_PASTE_HOT_KEY_PRESSED, callback);
  }

  unsubscribeToBrushSizeChanged(callback) {
    this.off(ToolControllerEvents.BRUSH_SIZE_CHANGED, callback);
  }

  onEllipseCreated = async (data) => {
    if (!this.onEllipseCreatedCallback) {
      return;
    }

    let imageId = data.imageId;
    let toolData = data.toolData;

    let eventData = {
      roiId: this.selectedRoi.id,
      imageId,
      toolData,
    };

    await this.onEllipseCreatedCallback(eventData);
  };

  onEllipseChanged = async (toolSliceData) => {
    if (!this.onEllipseChangedCallback) {
      return;
    }

    let imageId = toolSliceData.imageId;
    let toolData = toolSliceData.toolData;

    let eventData = {
      roiId: this.selectedRoi.id,
      imageId,
      toolData,
    };

    await this.onEllipseChangedCallback(eventData);
  };

  onEllipseProbeCreated = async (ellipseData) => {
    const roiId = this.selectedRoi.id;
    this.emit(ToolControllerEvents.ELLIPSE_PROBE_CREATED, ellipseData, roiId);
  };

  setOnEllipseDoubleClickedCallback(onEllipseDoubleClickedCallback) {
    this.onEllipseDoubleClickedCallback = onEllipseDoubleClickedCallback;
  }

  setOnEllipseRightClickedCallback(callback) {
    this.onEllipseRightClickedCallback = callback;
  }

  onEllipseDoubleClicked = ({ roi }) => {
    if (this.onEllipseDoubleClickedCallback) {
      this.onEllipseDoubleClickedCallback(roi);
    }
  };

  onEllipseRightClicked = (data) => {
    this.onEllipseRightClickedCallback?.(data);
  };

  onContourChanged = async (toolData, imageId) => {
    if (!this.onContourChangedCallback) {
      return;
    }

    const image = this.currentImageIdWatcher.getCurrentImage();
    if (!image || !imageId || image.imageId !== imageId) {
      throw new Error("Current image/imageId is null/inconsistent in onContourChanged");
    }

    if (!this.selectedToolRoi) {
      throw new Error("no roi selected onContourChanged");
    }

    let finalToolData = toolData;
    if (this.calculateMeasurements) {
      //append diameter measurements and store back in cornerstone
      finalToolData = appendContourDiameters(toolData, image);
      if (
        !("LAD" in finalToolData) ||
        !("LADPoints" in finalToolData) ||
        !("SAD" in finalToolData) ||
        !("SADPoints" in finalToolData)
      ) {
        console.debug("LAD/SAD calculations failed for ROI: ", this.selectedToolRoi);
      }
      setToolSliceData(ROI, this.selectedToolRoi.id, imageId, finalToolData);
    }

    //cornerstone may not update all stats until it renders
    //this forces cornerstone to update all stats prior to us using them
    for (const toolData of finalToolData) {
      const { invalidated } = toolData;
      if (invalidated) {
        const image = await getImageFromCornerstoneCache(imageId);
        this.roiToolWrapper.updateCachedStats(image, toolData);
      }
    }

    let eventData = {
      roiId: this.selectedRoi.id,
      imageId,
      toolData: finalToolData,
    };

    await this.onContourChangedCallback(eventData);
  };

  onContourRightClicked = (data, imageId) => {
    const { toolData, event } = data;

    this.emit(ToolControllerEvents.CONTOUR_RIGHT_CLICKED, {
      roiId: this.selectedRoi.id,
      toolData: [toolData],
      imageId,
      event,
      clickedContour: true,
    });
  };

  onContourDoubleClicked = (data, imageId) => {
    const { toolData, event } = data;

    this.emit(ToolControllerEvents.CONTOUR_DOUBLE_CLICKED, {
      roiId: this.selectedRoi.id,
      toolData,
      imageId,
      event,
    });
  };

  onUserMaskCopyHotKeyPressed = async (toolData, imageId) => {
    let shape = await getImageSize(imageId);
    let { seriesId, labelmapId } = this.selectedRoi;
    let modifiedtoolData = getIsolatedSegmentToolData(seriesId, labelmapId, toolData);

    let eventData = {
      roiId: this.selectedRoi.id,
      imageId,
      toolData: modifiedtoolData,
      shape,
    };
    this.emit(ToolControllerEvents.USER_MASK_COPY_HOT_KEY_PRESSED, eventData);
  };

  onUserMaskPasteHotKeyPressed = (imageId) => {
    let eventData = {
      roi: this.selectedRoi,
      imageId,
      element: this.cornerstoneElement,
    };

    this.emit(ToolControllerEvents.USER_MASK_PASTE_HOT_KEY_PRESSED, eventData);
  };

  onBrushSizeChanged = (newBrushSize) => {
    this.emit(ToolControllerEvents.BRUSH_SIZE_CHANGED, newBrushSize);
  };

  onLabelmapChanged = async (toolData, imageId) => {
    let shape = await getImageSize(imageId);
    let { seriesId, labelmapId } = this.selectedRoi;
    let modifiedtoolData = getIsolatedSegmentToolData(seriesId, labelmapId, toolData);

    let eventData = {
      roiId: this.selectedRoi.id,
      imageId,
      toolData: modifiedtoolData,
      shape,
    };

    this.emit(ToolControllerEvents.LABELMAP_UPDATED, eventData);
  };

  onLengthDataChanged = async (event) => {
    this.emit(ToolControllerEvents.MEASURE_UPDATED, event);
  };

  selectRoi(roi, activeTool, visibleRoiIds, isViewOnly) {
    const { id: selectedRoiId } = this.selectedRoi || {};
    const { id: roiId } = roi || {};
    const roiChanged = selectedRoiId !== roiId;

    const activeToolChanged = activeTool !== this.previousActiveTool;

    if (roiChanged && this.isRoiToolDrawing()) {
      this.cancelActiveTool();
    }

    this.selectedRoi = roi;

    let isRoiVisible = roi ? visibleRoiIds.includes(roi.id) : false;

    let activeToolActions = {
      [ROI]: () => {
        this.hockeyPuckTool.setDisabled();

        let ellipseToolRoi = roi.ellipseToolRoi;
        let contourToolRoi = roi.contoursToolRoi;

        if (roiChanged || this.selectedToolRoi?.type !== ROI || activeToolChanged) {
          this.selectToolRoi(contourToolRoi, ellipseToolRoi);
        }
      },
      [ELLIPTICAL_ROI]: () => {
        this.hockeyPuckTool.setDisabled();

        let ellipseToolRoi = roi.ellipseToolRoi;
        let contourToolRoi = roi.contoursToolRoi;

        this.selectToolRoi(ellipseToolRoi, contourToolRoi);
      },
      [ROI_SCULPTOR]: () => {
        this.turnOffAllTools(ROI_SCULPTOR);

        let imageId = this.currentImageIdWatcher.getCurrentImageId();
        if (!imageId) {
          return;
        }

        const { contoursToolRoi } = roi;
        if (!contoursToolRoi) {
          throw new Error("No roi found for contour");
        }

        this.selectedToolRoi = contoursToolRoi;

        this.hockeyPuckTool.tryStart(contoursToolRoi, imageId, !roiChanged);
      },
      [BRUSH]: () => {
        this.turnOffAllTools();
        labelmapProvider.setActiveLabelmap(
          this.selectedRoi.seriesId,
          this.cornerstoneElement,
          this.selectedRoi.labelmapId
        );
        this.brushToolWrapper.setActive();
      },
      [FREEHAND_SCISSORS]: () => {
        this.turnOffAllTools();
        labelmapProvider.setActiveLabelmap(
          this.selectedRoi.seriesId,
          this.cornerstoneElement,
          this.selectedRoi.labelmapId
        );
        this.freehandScissorsToolWrapper.setActive();
      },
    };

    let activeToolAction = activeToolActions[activeTool];

    this.previousActiveTool = activeTool;

    if (!roi || !activeToolAction || !isRoiVisible || isViewOnly) {
      this.selectedToolRoi = null;
      this.turnOffAllTools();
      return;
    }

    activeToolAction();
  }

  turnOnEllipseTool(activeToolRoi) {
    this.ellipseToolWrapper.turnOn();
    this.ellipseToolWrapper.setCurrentRoi(activeToolRoi);
    this.ellipseToolWrapper.setPassiveRoi(null);
  }

  turnOffEllipseTool() {
    this.ellipseToolWrapper.setCurrentRoi(null);
    this.ellipseToolWrapper.turnOff();
  }

  turnOnRoiTool(activeToolRoi) {
    this.roiToolWrapper.turnOn();
    this.roiToolWrapper.setCurrentRoi(activeToolRoi);
  }

  turnOffRoiTool() {
    this.roiToolWrapper.setCurrentRoi(null);
    this.roiToolWrapper.turnOff();
  }

  selectToolRoi(activeToolRoi, passiveToolRoi) {
    this.selectedToolRoi = activeToolRoi;

    if (activeToolRoi) {
      if (activeToolRoi.type === ELLIPTICAL_ROI) {
        this.turnOffRoiTool();
        this.turnOnEllipseTool(activeToolRoi);
      } else if (activeToolRoi.type === ROI) {
        this.turnOffEllipseTool();
        this.turnOnRoiTool(activeToolRoi);
      }

      this.forceRedraw();
    }

    if (passiveToolRoi) {
      if (passiveToolRoi.type === ELLIPTICAL_ROI) {
        this.ellipseToolWrapper.setPassiveRoi(passiveToolRoi);
      }
    }
  }

  forceRedraw = () => {
    cornerstone.updateImage(this.cornerstoneElement);
  };

  cancelActiveTool() {
    let toolRoi = this.selectedToolRoi;
    if (!toolRoi) {
      return;
    }

    let toolType = toolRoi.type;
    switch (toolType) {
      case ROI:
        this.roiToolWrapper.cancelToolDrawing();
        break;
      default:
    }
  }

  getImageIdForCurrentToolRoi() {
    let toolRoi = this.selectedToolRoi;

    let data = getRoiToolStateDataForRoi(toolRoi);
    if (data.length <= 0) {
      return null;
    }

    return data[0].imageId;
  }

  setWindowLevel(window, level) {
    const viewport = cornerstone.getViewport(this.cornerstoneElement);
    viewport.voi.windowWidth = window;
    viewport.voi.windowCenter = level;
    this.forceRedraw();
  }

  setActiveTool = (tool) => {
    cornerstoneTools.setToolActiveForElement(this.cornerstoneElement, tool, {
      mouseButtonMask: 1,
    });
  };

  setPassiveTool = (tool) => {
    cornerstoneTools.setToolPassiveForElement(this.cornerstoneElement, tool, {
      mouseButtonMask: 1,
    });
  };

  disableTool = (tool) => {
    cornerstoneTools.setToolDisabledForElement(this.cornerstoneElement, tool, {
      mouseButtonMask: 1,
    });
  };

  setToolRoiInfo = (toolRoiInfo) => {
    let { toolRoiSavedStatuses, selectedContourToolRoiId, visibleToolRoiIds } = toolRoiInfo;

    this.ellipseToolWrapper.setToolRoiStates({
      toolRoiSavedStatuses: {
        savedToolRoiIds: toolRoiSavedStatuses,
      },
      visibleToolRoiIds,
    });
    this.roiToolWrapper.setToolRoiStates({
      selectedContourToolRoiId,
      visibleToolRoiIds,
    });

    this.forceRedraw();
  };

  setBrushRadiusValues(brushRadiusValues) {
    this.brushToolWrapper.setBrushRadiusValues(brushRadiusValues);
  }

  turnOffAllTools(skip) {
    this.turnOffEllipseTool();
    this.turnOffRoiTool();
    this.brushToolWrapper.setDisabled();
    if (skip !== ROI_SCULPTOR) {
      this.hockeyPuckTool.setDisabled();
    }
    this.freehandScissorsToolWrapper.setDisabled();
    this.forceRedraw();
  }

  setViewerViewport(viewerData) {
    const originalViewport = cornerstone.getViewport(this.cornerstoneElement);
    const { scale, translation, voi } = viewerData;
    const newViewport = {
      ...originalViewport,
      scale,
      translation: {
        ...originalViewport.translation,
        ...translation,
      },
      voi: {
        ...originalViewport.voi,
        ...voi,
      },
    };
    cornerstone.setViewport(this.cornerstoneElement, newViewport);
  }

  isRoiToolDrawing() {
    return this.roiToolWrapper.isDrawing();
  }

  handleActiveToolChanged(tool, lastActiveTool) {
    const alwaysOnTools = [ZOOM, PAN, MEASURE];
    switch (tool) {
      case ROI:
      case ELLIPTICAL_ROI:
        if (lastActiveTool) {
          if (!alwaysOnTools.includes(lastActiveTool)) {
            this.disableTool(lastActiveTool);
          }
        }
        break;
      case WINDOWLEVEL:
      case ZOOM:
      case PAN:
      case ROI_SCULPTOR:
      case ELLIPTICAL_PROBE:
      case BRUSH:
      case FREEHAND_SCISSORS:
        this.setActiveTool(tool);
        break;
      case MEASURE:
        //do nothing since handled in 'handleMeasureTool'
        break;
      default:
        throw new Error(`tool: ${tool} not supported`);
    }
  }
}

export default ToolController;
