import { useParams } from "react-router";

export function useTrialIdParam() {
  const { trialId } = useParams<{
    trialId: string;
  }>();
  const trialIdNumber = Number(trialId) || null;

  if (!trialIdNumber) {
    throw new Error("No trial found in route.");
  }

  return trialIdNumber;
}
