import { AnnotationsPerCohortCard } from "nota-predict-web/src/Overview/Label/components/AnnotationsPerCohortCard";
import { FC, useEffect } from "react";
import { useHotkeysContext } from "react-hotkeys-hook";

import { AlgorithmInfoCard } from "../../Analysis/Algorithm/components/AlgorithmInfoCard";
import { AlgorithmSummaryCard } from "../../Analysis/Algorithm/components/AlgorithmSummaryCard";
import { MortalityChangeFromBaselineCard } from "../../Analysis/ChangeFromBaseline/components/MortalityChangeFromBaselineCard";
import { StudyTableCard } from "../../Analysis/common/components/StudyTableCard";
import { SubjectDetailsCard } from "../../Analysis/common/components/SubjectDetailsCard";
import { KaplanMeierCurveCard } from "../../Analysis/KaplanMeier/components/KaplanMeierCurveCard";
import { PatientLongitudinalGraphCard } from "../../Analysis/Longitudinal/components/PatientLongitudinalGraphCard";
import { PatientTasksCard } from "../../Analysis/Longitudinal/components/PatientTasksCard";
import { MedianTumorBurdenSurvivalPredictionCard } from "../../Analysis/MedianSurvival/components/MedianTumorBurdenSurvivalPredictionCard";
import { PatientJourneyCard } from "../../Analysis/PatientJourney/components/PatientJourneyCard";
import { PatientJourneyDrawer } from "../../Analysis/PatientJourney/components/PatientJourneyDrawer";
import { ManageTaskAssignments } from "../../Annotate/components/Manage/ManageTaskAssignments";
import { TaskWorklist } from "../../Annotate/components/TaskWorklist/TaskWorklist";
import { TasklessRouteContainer } from "../../Annotate/routes/TasklessRouteContainer";
import { TaskRouteContainer } from "../../Annotate/routes/TaskRouteContainer";
import { BigFlexLoading } from "../../common/components/Loading";
import { ProjectDetailsCard } from "../../common/components/ProjectDetails/ProjectDetailsCard";
import { GlobalCohortsManagement } from "../../Dashboard/components/Settings/Cohort/GlobalCohortsManagement";
import { GlobalAnnotationManagement } from "../../Dashboard/components/Settings/Labels/GlobalAnnotationManagement";
import { GlobalPatientsManagement } from "../../Dashboard/components/Settings/Patients/GlobalPatientsManagement";
import { GlobalProjectsManagement } from "../../Dashboard/components/Settings/Project/GlobalProjectsManagement";
import { GlobalUserManagement } from "../../Dashboard/components/Settings/Users/GlobalUserManagement";
import { ProjectSelection } from "../../Dashboard/ProjectSelection";
import { BurndownAndDeviationsCard } from "../../DataManagement/BurndownAndDeviationsCard";
import { DataIngestionProgressCard } from "../../DataManagement/DataIngestion/components/DataIngestionProgressContainer";
import { DeviationsCard } from "../../DataManagement/DeviationsCard";
import { ProjectDocumentsCard } from "../../DataManagement/Documents/ProjectDocumentsCard";
import { EnrolmentCardContainer } from "../../DataManagement/EnrolmentChart/components/EnrolmentCard";
import { UploadCard } from "../../DataManagement/Upload/components/UploadCard";
import { UploadCsv } from "../../DataManagement/Upload/components/UploadCsv";
import { AnnotationsPerAnnotatorCard } from "../../Overview/Label/components/AnnotationsPerAnnotatorCard";
import { LesionDownloadCard } from "../../Overview/Label/components/LesionDownloadCard";
import { TasksPerAnnotatorCard } from "../../Overview/Label/components/TasksPerAnnotatorCard";
import { RadiologyPanelCard } from "../../Overview/Radiology/components/RadiologyPanelContainer";
import { ProjectCohortsManagement } from "../../Settings/ProjectCohortsManagement";
import { ProjectPatientsManagement } from "../../Settings/ProjectPatientsManagement";
import { SiteManagementCard } from "../../Settings/SiteManagementCard";
import { UserManagementCard } from "../../Settings/UserManagementCard";
import { EmptyPage } from "./EmptyPage";
import { useCurrentUserAllowedItems } from "./hooks/useCurrentUserAllowedItems";
import {
  ALGORITHM_INFO_CONTENT,
  ANALYSIS_ALGORITHM_SUMMARY_CONTENT,
  ANNOTATE_MANAGE_PAGE_CONTENT,
  ANNOTATE_PAGE_CONTENT,
  ANNOTATION_SCOPE,
  ANNOTATIONS_PER_ANNOTATOR_CONTENT,
  ANNOTATIONS_PER_COHORT_CONTENT,
  BURNDOWN_AND_DEVIATIONS_CONTENT,
  CHANGE_IN_MORTALITY_CONTENT,
  ContentComponentType,
  ContentType,
  EMPTY_CONTENT,
  ENROLMENT_CONTENT,
  GLOBAL_ANNOTATION_MANAGEMENT,
  GLOBAL_COHORT_MANAGEMENT_CONTENT,
  GLOBAL_PATIENT_MANAGEMENT,
  GLOBAL_PROJECT_MANAGEMENT,
  GLOBAL_USER_MANAGEMENT,
  IMAGING_DEVIATIONS_CONTENT,
  INGESTION_CONTENT,
  KAPLAN_MEIER_CONTENT,
  LESION_STATISTICS_CONTENT,
  LOADING_CONTENT,
  MEDIAN_SURVIVAL_CONTENT,
  PATIENT_DETAILS_CONTENT,
  PATIENT_JOURNEY_CONTENT,
  PATIENT_JOURNEY_DRAWER,
  PATIENT_LONGITUDINAL_CONTENT,
  PATIENT_TASKS_CONTENT,
  PROJECT_COHORT_MANAGEMENT_CONTENT,
  PROJECT_DETAILS_CONTENT,
  PROJECT_DOCUMENT_CONTENT,
  PROJECT_PATIENT_MANAGEMENT_CONTENT,
  PROJECT_SELECTION_CONTENT,
  RADIOLOGY_CONTENT,
  SITE_MANAGEMENT_CONTENT,
  STUDY_TABLE_CONTENT,
  TASK_PER_ANNOTATOR_CONTENT,
  TASKLESS_PAGE_CONTENT,
  UPLOAD_CONTENT,
  UPLOAD_CSV_CONTENT,
  USER_MANAGEMENT_CONTENT,
  WORKLIST_CONTENT,
} from "./Layout";

const contents: Record<ContentComponentType, FC<unknown>> = {
  [PROJECT_DETAILS_CONTENT]: ProjectDetailsCard,
  [RADIOLOGY_CONTENT]: RadiologyPanelCard,
  [UPLOAD_CONTENT]: UploadCard,
  [UPLOAD_CSV_CONTENT]: UploadCsv,
  [ENROLMENT_CONTENT]: EnrolmentCardContainer,
  [IMAGING_DEVIATIONS_CONTENT]: DeviationsCard,
  [BURNDOWN_AND_DEVIATIONS_CONTENT]: BurndownAndDeviationsCard,
  [INGESTION_CONTENT]: DataIngestionProgressCard,
  [ALGORITHM_INFO_CONTENT]: AlgorithmInfoCard,
  [KAPLAN_MEIER_CONTENT]: KaplanMeierCurveCard,
  [STUDY_TABLE_CONTENT]: StudyTableCard,
  [PATIENT_JOURNEY_CONTENT]: PatientJourneyCard,
  [PATIENT_JOURNEY_DRAWER]: PatientJourneyDrawer,
  [PATIENT_DETAILS_CONTENT]: SubjectDetailsCard,
  [PATIENT_LONGITUDINAL_CONTENT]: PatientLongitudinalGraphCard,
  [PATIENT_TASKS_CONTENT]: PatientTasksCard,
  [ANALYSIS_ALGORITHM_SUMMARY_CONTENT]: AlgorithmSummaryCard,
  [MEDIAN_SURVIVAL_CONTENT]: MedianTumorBurdenSurvivalPredictionCard,
  [CHANGE_IN_MORTALITY_CONTENT]: MortalityChangeFromBaselineCard,
  [GLOBAL_COHORT_MANAGEMENT_CONTENT]: GlobalCohortsManagement,
  [PROJECT_COHORT_MANAGEMENT_CONTENT]: ProjectCohortsManagement,
  [WORKLIST_CONTENT]: TaskWorklist,
  [USER_MANAGEMENT_CONTENT]: UserManagementCard,
  [ANNOTATIONS_PER_COHORT_CONTENT]: AnnotationsPerCohortCard,
  [ANNOTATIONS_PER_ANNOTATOR_CONTENT]: AnnotationsPerAnnotatorCard,
  [TASK_PER_ANNOTATOR_CONTENT]: TasksPerAnnotatorCard,
  [LESION_STATISTICS_CONTENT]: LesionDownloadCard,
  [SITE_MANAGEMENT_CONTENT]: SiteManagementCard,
  [ANNOTATE_PAGE_CONTENT]: TaskRouteContainer,
  [TASKLESS_PAGE_CONTENT]: TasklessRouteContainer,
  [ANNOTATE_MANAGE_PAGE_CONTENT]: ManageTaskAssignments,
  [PROJECT_SELECTION_CONTENT]: ProjectSelection,
  [PROJECT_DOCUMENT_CONTENT]: ProjectDocumentsCard,
  [EMPTY_CONTENT]: EmptyPage,
  [LOADING_CONTENT]: BigFlexLoading,
  [GLOBAL_USER_MANAGEMENT]: GlobalUserManagement,
  [GLOBAL_PROJECT_MANAGEMENT]: GlobalProjectsManagement,
  [GLOBAL_PATIENT_MANAGEMENT]: GlobalPatientsManagement,
  [PROJECT_PATIENT_MANAGEMENT_CONTENT]: ProjectPatientsManagement,
  [GLOBAL_ANNOTATION_MANAGEMENT]: GlobalAnnotationManagement,
} as const;

interface ContentProps {
  content: ContentType[];
}

export function Content({ content }: ContentProps): JSX.Element {
  const filteredContent = useCurrentUserAllowedItems<ContentType>()(content);
  const { enabledScopes, enableScope, disableScope } = useHotkeysContext();

  useEffect(() => {
    //TODO: If/when this is expanded it will become a list of if -> enableScope - else if -> disableScope
    // So if/when this occurs we should look to creating a Record<ContentComponentType, HotkeyScopeType[]> to
    // programmatically set the scopes correctly, instead of a long list of if/else if checks
    if (
      filteredContent.find(({ component }) => component === ANNOTATE_PAGE_CONTENT) &&
      enabledScopes.find((scope) => scope !== ANNOTATION_SCOPE)
    ) {
      enableScope(ANNOTATION_SCOPE);
    } else if (
      !filteredContent.find(({ component }) => component === ANNOTATE_PAGE_CONTENT) &&
      enabledScopes.find((scope) => scope === ANNOTATION_SCOPE)
    ) {
      disableScope(ANNOTATION_SCOPE);
    }
  }, [filteredContent, enabledScopes]);

  return (
    <>
      {filteredContent.map(({ component, props }, index) => {
        const Component = contents[component];
        return <Component key={index} {...props} />;
      })}
    </>
  );
}
