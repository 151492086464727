import { FollowUpType } from "../types/FollowUpType";

export function getFollowUpDiametricTumourBurden(followUp: FollowUpType): number | null {
  const { tumourBurdens } = followUp;
  if (tumourBurdens.length < 1) {
    return null;
  }

  const validDiametricBurdens = tumourBurdens.flatMap(({ diametric }) =>
    diametric !== null ? [diametric] : []
  );
  if (validDiametricBurdens.length < 1) {
    return null;
  }

  return validDiametricBurdens.reduce((total, burden) => total + burden, 0);
}
