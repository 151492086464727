import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import styled from "styled-components";

import { MuiIcon } from "../../../../../common/components/icons/MuiIcon";
import { main } from "../../../../../common/theme/main";

export const Wrapper = styled.div<{ leftRightPadding: number }>`
  display: flex;
  align-items: center;
  padding: 0 ${({ leftRightPadding }) => leftRightPadding}px;
`;

interface ExpandCollapseButtonProps {
  collapsed: boolean;
  size?: number;
  onClick?: (e: React.MouseEvent<Element, MouseEvent>) => void;
  leftRightPadding?: number;
  disabled?: boolean;
}

export function ExpandCollapseButton({
  collapsed,
  size = 16,
  onClick,
  leftRightPadding = 2,
  disabled = false,
}: ExpandCollapseButtonProps): JSX.Element {
  const handleOnClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    if (disabled) {
      return;
    }

    onClick?.(e);
  };

  return (
    <Wrapper onClick={handleOnClick} leftRightPadding={leftRightPadding}>
      <MuiIcon
        icon={collapsed ? ExpandMoreIcon : ExpandLessIcon}
        size={size}
        color={!disabled ? main.colors.actionPrimary.active : main.colors.actionPrimary.disabled}
      />
    </Wrapper>
  );
}
