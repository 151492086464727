import React, { useState } from "react";
import { useDeepCompareMemo } from "use-deep-compare";

import { FormDialog, FormDialogProps } from "./FormDialog";
import { useDialogReturnType } from "./useDialog";

export function useFormDialog<T>({
  children: Form,
  props,
  label,
  showCloseButton,
  reactModalProps,
}: FormDialogProps<T>): useDialogReturnType {
  const [open, setOpen] = useState<boolean>(false);

  const dialog = useDeepCompareMemo(() => {
    return (
      <FormDialog
        open={open}
        setOpen={setOpen}
        props={props}
        label={label}
        showCloseButton={showCloseButton}
        reactModalProps={reactModalProps}
      >
        {({ props, ...other }) => <Form {...other} props={props} />}
      </FormDialog>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, Form, props]);

  return [setOpen, { dialog }];
}
