import {
  PatientMutableTaskType,
  TaskAssignmentType,
  TaskType,
} from "../../../../Annotate/components/Manage/hooks/TaskType";
import {
  CompleteTaskProgressType,
  TASK_COMPLETED,
  TASK_IN_PROGRESS,
  TASK_PENDING,
  TaskProgressType,
} from "../../../../Annotate/components/TaskWorklist/TaskProgressType";
import { getPatientTaskShowBadge } from "../../StudyTable/utils/getPatientTaskShowBadge";

interface TaskAssigneeProgressType {
  displayProgress: TaskProgressType;
  isUnassigned?: boolean;
}

type ProgressTaskAssignments = {
  taskAssignments: PatientMutableTaskType[];
  showBadge: boolean;
} & TaskAssigneeProgressType;

export function getPatientProgressTaskAssignments(
  patientId: number,
  patientsTasks: PatientMutableTaskType[]
): ProgressTaskAssignments {
  const patientTasks = patientsTasks.filter(({ id }) => {
    return id === patientId;
  });

  const tasks = patientTasks
    .map((patientTasks) => patientTasks.task)
    .filter((task): task is TaskType => !!task);

  const { displayProgress, isUnassigned } = getPatientTaskProgress(tasks);
  const showBadge = getPatientTaskShowBadge(tasks);

  return { displayProgress, isUnassigned, taskAssignments: patientTasks, showBadge };
}

export function getPatientTaskProgress(tasks: TaskType[]): CompleteTaskProgressType {
  const allTaskAssignments = tasks.flatMap(({ taskAssignments }) =>
    taskAssignments.map((assignment) => assignment)
  );

  const tasksWithAssignees = tasks.filter(({ taskAssignments }) => {
    return taskAssignments.length >= 1;
  });

  const allTasksAssigned = tasksWithAssignees.length === tasks.length;

  return getCalculatedTaskProgress(allTaskAssignments, allTasksAssigned);
}

export function getCalculatedTaskProgress(
  allTaskAssignments: TaskAssignmentType[],
  allTasksAssigned: boolean
): CompleteTaskProgressType {
  const isUnassigned = allTaskAssignments.length === 0;

  if (isUnassigned) {
    return {
      displayProgress: TASK_PENDING,
      isUnassigned: true,
      taskAssignments: [],
    };
  }

  const newTaskAssignments = allTaskAssignments.filter(
    (assignment) => assignment.progress === TASK_PENDING
  );
  const completedTaskAssignments = allTaskAssignments.filter(
    (assignment) => assignment.progress === TASK_COMPLETED
  );

  const isNew = newTaskAssignments.length === allTaskAssignments.length;
  const isCompleted = completedTaskAssignments.length === allTaskAssignments.length;

  const displayProgress = isNew
    ? TASK_PENDING
    : isCompleted && allTasksAssigned
    ? TASK_COMPLETED
    : TASK_IN_PROGRESS;

  return {
    displayProgress,
    isUnassigned: false,
    taskAssignments: allTaskAssignments,
  };
}
