import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import { getDeltaDateTimeInDays } from "../../../common/utils/dateFormatUtils/getDeltaDateTimeInDays";
import { SubjectType } from "../../common/types/SubjectType";
import { NOT_EVALUABLE } from "../../common/types/TumourResponseType";
import { getBaselineFollowUp } from "../../common/utils/getBaselineFollowUp";
import { getMostRecentFollowUp } from "../../common/utils/getMostRecentFollowUp";
import { FollowUpResponseType } from "../types/FollowUpResponseType";
import { SubjectResponseProgressionType } from "../types/SubjectResponseProgressionType";

dayjs.extend(relativeTime);

export function getPatientJourneySubjects(
  subjects: SubjectType[]
): SubjectResponseProgressionType[] {
  const subjectResponseProgressions: SubjectResponseProgressionType[] = [];

  for (const subject of subjects) {
    const { id, subjectId, followUps, randomizationDate } = subject;

    const baselineFollowUp = getBaselineFollowUp(followUps);
    const mostRecentFollowUp = getMostRecentFollowUp(followUps);
    if (!mostRecentFollowUp || !baselineFollowUp) {
      continue;
    }

    const {
      survivalPredictions,
      tumourResponses,
      order: lastFollowUp,
      date: mostRecentFollowUpDate,
    } = mostRecentFollowUp;

    const { date: baselineFollowUpDate } = baselineFollowUp;
    if (!baselineFollowUpDate || !mostRecentFollowUpDate) {
      continue;
    }

    const daysSinceRandomization = randomizationDate
      ? getDeltaDateTimeInDays(new Date(), randomizationDate)
      : null;

    const daysSinceBaseline = getDeltaDateTimeInDays(mostRecentFollowUpDate, baselineFollowUpDate);

    const lastResponseClassification = tumourResponses.length > 0 ? tumourResponses[0] : null;

    const lastSurvivalPrediction = survivalPredictions.length > 0 ? survivalPredictions[0] : null;

    const orderedFollowUps = [...followUps].sort((a, b) => a.order - b.order);
    const followUpResponses: FollowUpResponseType = {};
    for (let i = 0; i < orderedFollowUps.length; i++) {
      const followUp = orderedFollowUps[i];
      const previousFollowUp = orderedFollowUps[i - 1];
      const previousFollowUpDate = previousFollowUp?.date;

      const { order, date, tumourResponses: followUpTumourResponses } = followUp;

      if (!date) {
        continue;
      }

      const elapsedDays =
        previousFollowUpDate != null ? getDeltaDateTimeInDays(previousFollowUpDate, date) : 0;

      const firstResponseClassification =
        followUpTumourResponses.length > 0 ? followUpTumourResponses[0] : NOT_EVALUABLE;

      followUpResponses[order] = {
        response: firstResponseClassification,
        elapsedDays,
      };
    }

    subjectResponseProgressions.push({
      id,
      subjectId,
      daysSinceRandomization,
      daysSinceBaseline,
      lastFollowUp,
      lastResponseClassification,
      lastSurvivalPrediction,
      followUpResponses,
    });
  }

  subjectResponseProgressions.sort((a, b) => a.daysSinceBaseline - b.daysSinceBaseline);

  return subjectResponseProgressions;
}
