import { default as AvatarMui } from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import { ReactNode } from "react";
import styled from "styled-components";

import { UserAvatarType } from "../../types/UserType";
import { stringAvatar } from "./utils/stringAvatar";

const Wrapper = styled.div<{ clickable: boolean }>`
  cursor: ${(props) => (props.clickable ? "pointer" : "unset")};
  display: flex;
  align-items: center;
`;

interface AvatarProps {
  user: UserAvatarType;
  onDoubleClick?: () => void;
  onClick?: () => void;
  size?: number;
  badgeContent?: ReactNode;
  textColor?: string;
  badgeColor?: string;
  fontSizeMultiplier?: number;
  textBrightness?: number;
}

export function Avatar({
  user,
  onDoubleClick,
  onClick,
  size = 32,
  badgeContent,
  textColor,
  badgeColor,
  textBrightness,
  fontSizeMultiplier,
}: AvatarProps): JSX.Element {
  const { avatarImageSource } = user;
  const handleOnClick = () => {
    onClick?.();
  };

  const handleOnDoubleClick = () => {
    onDoubleClick?.();
  };

  const stringAvatarProps = {
    user,
    size,
    background: badgeColor,
    textColor,
    fontSizeMultiplier,
    textBrightness,
  };

  return (
    <Wrapper clickable={onClick !== undefined}>
      <Badge
        overlap="circular"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        badgeContent={badgeContent}
        onClick={handleOnClick}
      >
        <AvatarMui
          src={avatarImageSource}
          onDoubleClick={handleOnDoubleClick}
          onClick={handleOnClick}
          {...stringAvatar(stringAvatarProps)}
        />
      </Badge>
    </Wrapper>
  );
}
