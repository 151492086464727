import { createContext } from "react";

import { ContourClipboardEntryType } from "./ContourContextProvider";

export type CallbackType = () => void;

type ContourContextType = {
  pushCallback: (callback: CallbackType, roiIds: number[]) => string;
  contourClipboardData: ContourClipboardEntryType | null;
  setContourClipboardData: (clipboardData: ContourClipboardEntryType) => void;
  fireCallbacks: (firedRoiId: number) => void;
  removeCallback: (callbackLocation: string) => void;
};

export const ContourContext = createContext<ContourContextType>({
  pushCallback: () => "",
  contourClipboardData: null,
  setContourClipboardData: () => undefined,
  fireCallbacks: () => undefined,
  removeCallback: () => undefined,
});
