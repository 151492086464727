import { ClassificationsMapValueType } from "../../../../../../common/store/annotatePage/ClassificationsMapType";
import { TARGET } from "../../../../../enums/TaskDescriptionEnums";
import { ClassificationValuesType } from "../../../../../types/TaskDescriptionType";
import { LesionListItemFragmentType } from "../fragments/LesionListItemFragment";
import { RoiListItemFragmentType } from "../fragments/RoiListItemFragment";
import { GetRoiPreventsLesionClassificationType } from "../hooks/timepointOptions/useGetRoiPreventsLesionClassification";
import { ClassificationType } from "../types/ClassificationType";
import {
  baselineClassificationPreventsTarget,
  CheckObject,
  nodeSADTooSmallCheck,
  noMeasurementsCheck,
  numberOfTargetLesionsCheck,
  numberOfTargetLesionsForOrganCheck,
  RecistClassificationErrorType,
  roiExistsAtBaselineCheck,
  targetLADTooSmallCheck,
} from "./ClassificationChecks";

export function areRecistClassificationsValid(
  lesionToUpdate: LesionListItemFragmentType,
  lesions: LesionListItemFragmentType[],
  classificationsMap: Record<number, ClassificationsMapValueType>,
  anatomicalStructureId: number | undefined,
  isBaselineRoi: (roi: RoiListItemFragmentType) => boolean,
  getRoiPreventsLesionClassification: GetRoiPreventsLesionClassificationType
): RecistClassificationErrorType | undefined {
  const proposedLesionClassifications: ClassificationType[] = [];
  let classifyingTarget = false;
  for (const key in classificationsMap) {
    const { values } = classificationsMap[key];
    for (const [classification, value] of Object.entries(values)) {
      if (value) {
        proposedLesionClassifications.push({
          id: Number(key),
          classification: classification as ClassificationValuesType,
        });
      }
    }
    if (values[TARGET]) {
      classifyingTarget = true;
    }
  }

  if (!classifyingTarget) {
    return undefined;
  }

  const { id: lesionIdToUpdate } = lesionToUpdate;

  const filteredLesions = lesions.filter(({ id }) => lesionIdToUpdate !== id);

  const { rois } = lesionToUpdate;

  const baselineRoi = rois.find(isBaselineRoi);

  const proposedLesion: LesionListItemFragmentType = {
    ...lesionToUpdate,
    classifications: proposedLesionClassifications,
  };

  const error = checks.find(
    ({ check }) =>
      !check(
        baselineRoi,
        proposedLesion,
        filteredLesions,
        getRoiPreventsLesionClassification,
        anatomicalStructureId
      )
  );

  return error?.type;
}

const checks: CheckObject[] = [
  roiExistsAtBaselineCheck,
  baselineClassificationPreventsTarget,
  noMeasurementsCheck,
  targetLADTooSmallCheck,
  nodeSADTooSmallCheck,
  numberOfTargetLesionsCheck,
  numberOfTargetLesionsForOrganCheck,
];
