import React, { useContext } from "react";
import styled from "styled-components";

import { TaskContext } from "../../../../TaskContext";
import { ClassificationObjectsType } from "../../../../types/TaskDescriptionType";
import { StudyListItemFragmentType } from "../AnnotationPanel/hooks/useStudiesList";
import { DeleteClassificationLabel } from "./DeleteClassificationLabel";
import { DeleteSeriesClassificationsWrapper } from "./DeleteSeriesClassificationsWrapper";
import { useActivateViewer } from "./Viewer/hooks/useActivateViewer";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  background: ${(props) => props.theme.colors.neutral.black};
  border-radius: 8px;
  padding: 6px 12px;
  flex-wrap: wrap;
  min-height: 20px;
`;

const Text = styled.div`
  font-weight: bold;
  font-size: 13px;
  color: white;
`;

interface SeriesClassificationsPanelProps {
  viewerConfigId: number;
  studies: StudyListItemFragmentType[];
  availableClassifications: ClassificationObjectsType[];
  seriesId: number;
  viewOnly: boolean;
}

export function SeriesClassificationsPanel({
  viewerConfigId,
  studies,
  availableClassifications,
  seriesId,
  viewOnly,
}: SeriesClassificationsPanelProps): JSX.Element {
  const {
    task: { id: currentTaskId },
  } = useContext(TaskContext);

  const activateViewer = useActivateViewer();

  const series = studies.flatMap(({ series }) => series).find(({ id }) => id === seriesId);

  if (!series) {
    throw new Error("Could not find viewer series in studies list!");
  }

  const { seriesClassifications } = series;

  const availableClassificationsValues = availableClassifications.map(
    ({ value }) => value
  ) as string[];

  const filteredSeriesClassifications = seriesClassifications.filter(
    ({ classification, taskId }) =>
      availableClassificationsValues.includes(classification) && taskId === currentTaskId
  );

  const handleOnClick = () => {
    activateViewer(viewerConfigId);
  };

  return (
    <Wrapper onClick={handleOnClick}>
      <Text>
        {filteredSeriesClassifications.length !== 0 ? "Classifications: " : "Classifications: N/A"}
      </Text>
      {filteredSeriesClassifications.map(({ classification }, key) => (
        <DeleteSeriesClassificationsWrapper key={key} series={series} taskId={currentTaskId}>
          {({ onClick }) => (
            <DeleteClassificationLabel
              classification={classification}
              availableClassifications={availableClassifications}
              onDeleteClick={onClick}
              readOnly={viewOnly}
              key={key}
            />
          )}
        </DeleteSeriesClassificationsWrapper>
      ))}
    </Wrapper>
  );
}
