import { Reducer, useCallback, useReducer } from "react";

import { FilterType, GraphView } from "../../ChangeFromBaseline/components/Filter";

export const useGraphParams = <V = GraphView, F = FilterType>() => {
  const [graphParams, dispatch] = useReducer<
    Reducer<{ view: V; filter: F }, { type: "view" | "filter"; payload: string }>
  >(
    (state, action) => {
      switch (action.type) {
        case "filter":
          if (state.filter === action.payload) return state;
          return { ...state, filter: action.payload as F };
        case "view":
          if (state.view === action.payload) return state;
          return { ...state, view: action.payload as V };
        default:
          throw new Error(`useGraphParams: Invalid action type '${action.type}'`);
      }
    },
    {
      view: "DIAMETRIC" as V,
      filter: "RECIST" as F,
    }
  );

  const setGraphParams = useCallback((view: GraphView, filter: FilterType) => {
    dispatch({ type: "view", payload: view });
    dispatch({ type: "filter", payload: filter });
  }, []);

  return { graphParams, setGraphParams };
};
