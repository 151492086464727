import dayjs from "dayjs";
import { useContext } from "react";

import { TimepointType } from "../../../../../../Annotate/components/Annotate/page/AnnotationPanel/hooks/Timepoint/TimepointType";
import { SeriesListItemFragmentType } from "../../../../../../Annotate/components/Annotate/page/AnnotationPanel/hooks/useStudiesList";
import { PatientContext } from "../PatientContext";

type ViewerLabelOverridesType = {
  patientId: string | null;
  patientName: string | null;
  seriesDate: string | null;
  studyDescription: string | null;
  seriesDescription: string | null;
};

export function useViewerLabelOverrides(seriesId: number): ViewerLabelOverridesType | undefined {
  const { patientDicomId, isSynthetic, timepoints } = useContext(PatientContext);

  if (!isSynthetic) {
    return undefined;
  }

  let matchingTimepoint: TimepointType | undefined = undefined;
  let series: SeriesListItemFragmentType | undefined = undefined;

  for (const timepoint of timepoints) {
    const { series: seriesList } = timepoint;

    const timepointSeries = seriesList.find(({ id }) => id === seriesId);
    if (timepointSeries) {
      matchingTimepoint = timepoint;
      series = timepointSeries;
      break;
    }
  }

  if (!matchingTimepoint || !series) {
    throw new Error(`No timepoint/series with id ${seriesId} found in subject timepoints`);
  }

  const { date: seriesDate } = matchingTimepoint;
  const { seriesDescription } = series;

  return {
    patientId: patientDicomId,
    patientName: "",
    seriesDate: seriesDate ? dayjs(seriesDate).format("MMM D, YYYY") : null,
    studyDescription: seriesDescription, //we want this in the top right which is why the names are mismatched
    seriesDescription: "",
  };
}
