import {
  ALT_STROKE_WIDTH,
  AREA_OPACITY,
  ARM_0_COLOR,
  ARM_1_COLOR,
  DASHED_STROKE_DASH_ARRAY,
  SOLID_STROKE_DASH_ARRAY,
  STROKE_WIDTH,
} from "../../common/utils/strokeSettings";
import { DeathType, OBSERVED, PREDICTED } from "../utils/generateKaplanMeierData";

export type KaplanMeierAttributesType = Record<
  number,
  Record<
    DeathType,
    {
      color: string;
      dashArray: string;
      confidenceColor: string;
      confidenceOpacity: number;
      strokeWidth: number;
    }
  >
>;

export const kaplanMeierAttributes: KaplanMeierAttributesType = {
  0: {
    [OBSERVED]: {
      color: ARM_0_COLOR,
      dashArray: SOLID_STROKE_DASH_ARRAY,
      confidenceColor: ARM_0_COLOR,
      confidenceOpacity: AREA_OPACITY,
      strokeWidth: STROKE_WIDTH,
    },
    [PREDICTED]: {
      color: ARM_0_COLOR,
      dashArray: DASHED_STROKE_DASH_ARRAY,
      confidenceColor: ARM_0_COLOR,
      confidenceOpacity: AREA_OPACITY,
      strokeWidth: ALT_STROKE_WIDTH,
    },
  },
  1: {
    [OBSERVED]: {
      color: ARM_1_COLOR,
      dashArray: SOLID_STROKE_DASH_ARRAY,
      confidenceColor: ARM_1_COLOR,
      confidenceOpacity: AREA_OPACITY,
      strokeWidth: STROKE_WIDTH,
    },
    [PREDICTED]: {
      color: ARM_1_COLOR,
      dashArray: DASHED_STROKE_DASH_ARRAY,
      confidenceColor: ARM_1_COLOR,
      confidenceOpacity: AREA_OPACITY,
      strokeWidth: ALT_STROKE_WIDTH,
    },
  },
};
