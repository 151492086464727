import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useOkForm } from "../../../../../../common/components/Dialog/useOkForm";
import { recistTaskSelector } from "../../../../../../common/store/annotatePage/taskSelector";
import { NODE, NON_TARGET, TARGET } from "../../../../../enums/TaskDescriptionEnums";
import { ClassificationValuesType } from "../../../../../types/TaskDescriptionType";
import {
  BASELINE_CLASSIFICATION_PREVENTS_TARGET,
  baselineClassificationPreventsTarget,
  CheckObject,
  EXISTS_AT_BASELINE,
  NO_MEASUREMENTS,
  NODE_SAD_TOO_SMALL,
  nodeSADTooSmallCheck,
  noMeasurementsCheck,
  RecistClassificationErrorType,
  roiExistsAtBaselineCheck,
  TARGET_LAD_TOO_SMALL,
  targetLADTooSmallCheck,
  TOO_MANY_TARGETS_PER_ORGAN,
  TOO_MANY_TARGETS_TOTAL,
} from "../utils/ClassificationChecks";
import { useGetRoiPreventsLesionClassification } from "./timepointOptions/useGetRoiPreventsLesionClassification";
import { useInsertLesionClassifications } from "./useInsertLesionClassification";
import { useIsBaselineRoi } from "./useIsBaselineRoi";
import { useLesionsList } from "./useLesionsList";
import { lesionsForCurrentTaskFilter } from "./useTaskLesionListOptions";
import { useTumourBurdenChanged } from "./useTumourBurdenChanged";

export function useValidateLesionClassifications(): JSX.Element {
  const { data, loading } = useLesionsList(lesionsForCurrentTaskFilter);
  const [dialogMessage, setDialogMessage] = useState<string>("N/A");
  const [insertLesionClassifications] = useInsertLesionClassifications();
  const { onTumourBurdenChanged } = useTumourBurdenChanged();
  const isBaselineRoi = useIsBaselineRoi();

  const [showClassificationsChangedDialog, { dialog: classificationsChangedDialog }] = useOkForm({
    title: `Lesion Classification Changed`,
    message: dialogMessage,
  });
  const isRecist = useSelector(recistTaskSelector);

  const getRoiPreventsLesionClassification = useGetRoiPreventsLesionClassification();

  useEffect(() => {
    if (!isRecist) {
      return;
    }

    if (loading) {
      return;
    }

    if (!data) {
      throw new Error("Finished loading lesions but no data!");
    }

    const { lesions } = data;

    lesions.forEach((lesion) => {
      const { classifications, rois, id: lesionId } = lesion;

      const lesionClassifications = classifications.map(({ classification }) => classification);

      if (!lesionClassifications.includes(TARGET)) {
        return;
      }

      const baselineRoi = rois.find(isBaselineRoi);

      const error = checks.find(
        ({ check }) => !check(baselineRoi, lesion, [], getRoiPreventsLesionClassification)
      );

      if (!error) {
        return;
      }

      const { type } = error;

      insertLesionClassifications(...operations[type](lesionId, lesionClassifications)).then(() => {
        setDialogMessage(errorMessages[type]);
        showClassificationsChangedDialog(true);
      });

      onTumourBurdenChanged(lesionId).then(() => undefined);
      return;
    });
  }, [data, isBaselineRoi, loading]);

  return classificationsChangedDialog;
}

type InputType = (
  lesionId: number,
  classifications: ClassificationValuesType[]
) => [
  lesionId: number,
  classifications: ClassificationValuesType[],
  deleteClassifications: ClassificationValuesType[]
];

const getClearClassificationVariables: InputType = (
  lesionId: number,
  classifications: ClassificationValuesType[]
) => {
  return [lesionId, [], classifications];
};

const getClearClassificationsAddNonTargetVariables: InputType = (
  lesionId: number,
  classifications: ClassificationValuesType[]
) => {
  return [
    lesionId,
    [NON_TARGET],
    classifications.filter((classification) => classification !== NON_TARGET),
  ];
};

const getConvertToNonTargetVariables: InputType = (lesionId: number) => {
  return [lesionId, [NON_TARGET], [TARGET]];
};

const getClearNodeClassificationVariables: InputType = (lesionId: number) => {
  return [lesionId, [], [NODE]];
};

const operations: Record<RecistClassificationErrorType, InputType> = {
  [EXISTS_AT_BASELINE]: getClearClassificationVariables,
  [BASELINE_CLASSIFICATION_PREVENTS_TARGET]: getClearClassificationsAddNonTargetVariables,
  [NO_MEASUREMENTS]: getClearClassificationVariables,
  [TARGET_LAD_TOO_SMALL]: getConvertToNonTargetVariables,
  [NODE_SAD_TOO_SMALL]: getClearNodeClassificationVariables,
  [TOO_MANY_TARGETS_TOTAL]: () => {
    throw new Error("Unsupported operation");
  },
  [TOO_MANY_TARGETS_PER_ORGAN]: () => {
    throw new Error("Unsupported operation");
  },
};

const errorMessages: Record<RecistClassificationErrorType, string> = {
  [EXISTS_AT_BASELINE]: "This lesion does not exist at the baseline. Resetting Classifications.",
  [BASELINE_CLASSIFICATION_PREVENTS_TARGET]:
    "This lesion contains a baseline classification that prevents it from being a Target. Changing classification to Non-Target and resetting the rest.",
  [NO_MEASUREMENTS]: "This lesion has no LAD or SAD. Resetting Classifications.",
  [TARGET_LAD_TOO_SMALL]:
    "Target lesions cannot have LADs less than 10 mm. Changing classification to Non-Target.",
  [NODE_SAD_TOO_SMALL]: "Node lesions cannot have SADs less than 15 mm. Removing Classification.",
  [TOO_MANY_TARGETS_TOTAL]: "N/A",
  [TOO_MANY_TARGETS_PER_ORGAN]: "N/A",
};

const checks: CheckObject[] = [
  roiExistsAtBaselineCheck,
  baselineClassificationPreventsTarget,
  noMeasurementsCheck,
  targetLADTooSmallCheck,
  nodeSADTooSmallCheck,
];
