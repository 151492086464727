import { createSelector } from "@reduxjs/toolkit";
import { SexFilters as SexFiltersType } from "nota-predict-web/src/common/types/DemographicsFilterTypes";
import { capitalize } from "nota-predict-web/src/common/utils/capitalize";
import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getDemographicsFilters,
  projectViewFiltersActions,
} from "../../../../common/store/projectViewFiltersSlice";
import FilterButton from "../FilterButton";
import { ButtonWrapper } from "../FilterInputWrapper";
import FilterSubtext from "../FilterSubtext";

const getSexFilters = createSelector(
  [getDemographicsFilters],
  (demographicsFilters) => demographicsFilters.sex
);

export const SexFilters: FC = () => {
  const dispatch = useDispatch();
  const sexFilters = useSelector(getSexFilters);

  const sexOptions = Object.keys(sexFilters).map((filter, index) => {
    const sex = filter as keyof SexFiltersType;

    const updateSexFilters = () => {
      dispatch(projectViewFiltersActions.toggleSex(sex));
    };

    const isChecked = sexFilters[sex];

    return (
      <FilterButton
        key={index}
        text={capitalize(filter)}
        onClick={updateSexFilters}
        active={isChecked}
        width={90}
      />
    );
  });

  const options = (
    <>
      <ButtonWrapper>{sexOptions}</ButtonWrapper>
    </>
  );

  return (
    <>
      <FilterSubtext text="Sex" />
      {options}
    </>
  );
};
