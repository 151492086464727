import {
  COMPLETE_RESPONSE,
  NOT_EVALUABLE,
  PARTIAL_RESPONSE,
  PROGRESSIVE_DISEASE,
  STABLE_DISEASE,
  TumourResponseType,
} from "../../../../../../../Analysis/common/types/TumourResponseType";
import { IntermediateType } from "./getIntermediateData";

export function getTumourResponse(
  baselineSumOfDiameters: number,
  smallestSumOfDiameters: number,
  numberOfLesionsAtBaseline: number,
  {
    sumOfDiameters,
    numberOfTargetLesions,
    nodeDiameters,
    numberOfLesions,
    containsMissingMeasurements,
  }: IntermediateType
): TumourResponseType | undefined {
  const containsNewLesions = numberOfLesions > numberOfLesionsAtBaseline;

  if (containsMissingMeasurements) {
    return NOT_EVALUABLE;
  }

  if (
    containsNewLesions ||
    (sumOfDiameters >= smallestSumOfDiameters * 1.2 && sumOfDiameters >= smallestSumOfDiameters + 5)
  ) {
    return PROGRESSIVE_DISEASE;
  }

  if (numberOfTargetLesions === 0 && !nodeDiameters.some((diameter) => diameter >= 10)) {
    return COMPLETE_RESPONSE;
  }

  if (sumOfDiameters <= baselineSumOfDiameters * 0.7) {
    return PARTIAL_RESPONSE;
  }

  return STABLE_DISEASE;
}
