import React from "react";
import styled from "styled-components";

import { ReactComponent as ErrorIcon } from "../../../assets/svgs/Error.svg";
import { main } from "../../theme/main";
import { SvgIcon } from "../icons/SvgIcon";

type ErrorLabelVariant = "error" | "warning" | "info" | "neutral";

interface ErrorLabelWrapperProps {
  variant: ErrorLabelVariant;
}

const ErrorLabelWrapper = styled("div")<ErrorLabelWrapperProps>(({ variant = "error" }) => {
  const background =
    variant === "neutral"
      ? main.colors.background.secondary
      : main.colors.states[(variant + "Bg") as keyof typeof main.colors.states];
  const color = variant === "neutral" ? main.colors.neutral.neutral5 : main.colors.states[variant];

  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "6px 8px 6px 16px",
    background,
    borderRadius: "8px",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "20px",
    color,
    gap: "4px",
  };
});

interface ErrorLabelProps {
  children: string;
  variant?: ErrorLabelVariant;
  showIcon?: boolean;
}

export function ErrorLabel({
  children,
  showIcon = true,
  variant = "error",
}: ErrorLabelProps): JSX.Element {
  const color = variant === "neutral" ? main.colors.neutral.neutral5 : main.colors.states[variant];

  return (
    <ErrorLabelWrapper variant={variant}>
      {showIcon ?? <SvgIcon icon={ErrorIcon} size={16} color={color} />}
      {children}
    </ErrorLabelWrapper>
  );
}
