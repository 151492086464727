import React, { FC } from "react";
import { components } from "react-select";
import { OptionProps } from "react-select/src/components/Option";
import {
  ColumnInstance,
  ColumnWithLooseAccessor,
  FilterProps,
  Row,
  UseFiltersColumnProps,
} from "react-table";
import styled from "styled-components";

import FilterCheckbox from "../../../../Analysis/ToolPanel/components/FilterCheckbox";
import { FilterPopout } from "../../../../Annotate/components/Manage/PatientTable/filters/FilterPopout";
import { getFilterPopoutSelectStyle } from "../../../../Annotate/components/Manage/PatientTable/filters/getFilterPopoutSelectStyle";
import { InputButton } from "../../../../common/components/input/InputButton";
import { DefaultIconFilterHeader } from "../../../../common/components/Table/DefaultIconFilterHeader";
import { TableDataType } from "../../../../common/components/Table/TableDataType";
import { main } from "../../../../common/theme/main";
import { ConditionalGreyedWrapper } from "../../../../common/utils/ConditionalGreyedWrapper";
import {
  GenericMultiSelect,
  OptionType,
} from "../../../../DataManagement/Upload/components/GenericMultiSelect";

type FilteredItemType<T> = T | undefined;

const ListWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
`;

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

interface CustomOptionProps<T> extends OptionProps<OptionType<FilteredItemType<T>>, true> {
  LabelComponent: FC<{ value: T }>;
}

function Option<T>(props: CustomOptionProps<T>): JSX.Element {
  const { LabelComponent, ...other } = props;

  //@ts-ignore
  const { value } = other as OptionType<FilteredItemType>;

  return (
    <div>
      <components.Option {...props}>
        <OptionWrapper>
          <FilterCheckbox
            label={value !== undefined ? <LabelComponent value={value} /> : "Unassigned"}
            name={props.label}
            checked={props.isSelected}
            onChange={() => undefined}
          />
        </OptionWrapper>
      </components.Option>
    </div>
  );
}

interface GenericColumnFilterProps<TValue, TRow extends TableDataType> {
  values: TValue[];
  column: ColumnInstance<TRow>;
  getOption: (item: FilteredItemType<TValue> | undefined) => OptionType<FilteredItemType<TValue>>;
  includeUnassigned?: boolean;
  placeholder?: string;
  loading?: boolean;
  rightAlign?: boolean;
  RowLabelComponent: FC<{ value: TValue; row: TRow }>;
  SelectLabelComponent: FC<{ value: TValue }>;
}

function GenericColumnFilter<TValue, TRow extends TableDataType>({
  values,
  column,
  getOption,
  includeUnassigned = false,
  placeholder,
  loading,
  SelectLabelComponent,
  rightAlign = false,
}: GenericColumnFilterProps<TValue, TRow>): JSX.Element {
  const { filterValue, setFilter } = column;

  const selectedItems = (filterValue ?? []) as FilteredItemType<TValue>[];

  const handleFilterChanged = (values: FilteredItemType<TValue>[]) => {
    setFilter(values);
  };

  const handleClearFilter = () => {
    setFilter([]);
  };

  const allValues: FilteredItemType<TValue>[] = [
    ...values,
    ...(includeUnassigned ? [undefined] : []),
  ];

  const handleSelectAll = () => {
    setFilter(values);
  };

  const selected = (filterValue ?? []) as FilteredItemType<TValue>[];

  const isFiltered = selectedItems.length > 0;

  const styles = getFilterPopoutSelectStyle<OptionType<FilteredItemType<TValue>>, true>();

  return (
    <DefaultIconFilterHeader column={column}>
      <FilterPopout
        isFiltered={isFiltered}
        onClearFilter={handleClearFilter}
        rightAlign={rightAlign}
        extraActionButtons={
          <InputButton
            type="button"
            value={"All"}
            background={main.colors.neutral.white}
            color={main.colors.neutral.black}
            onClick={handleSelectAll}
          />
        }
      >
        <GenericMultiSelect
          autoFocus
          values={allValues}
          selected={selected}
          placeholder={placeholder}
          getOption={getOption}
          onSelectedChanged={handleFilterChanged}
          isLoading={loading}
          isClearable={false}
          menuIsOpen
          backspaceRemovesValue={false}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          controlShouldRenderValue={false}
          tabSelectsValue={false}
          preventBaseStyles={true}
          styles={styles}
          components={{
            // eslint-disable-next-line react/display-name
            Option: (props) => <Option {...props} LabelComponent={SelectLabelComponent} />,
            DropdownIndicator: null,
            IndicatorSeparator: null,
          }}
        />
      </FilterPopout>
    </DefaultIconFilterHeader>
  );
}

function includeItem<T>(
  items: T[],
  filteredItems: FilteredItemType<T>[],
  areValuesEqual: (a: T, b: T) => boolean
): boolean {
  const filtered = filteredItems.flatMap((item) => (item !== undefined ? [item] : []));

  return (
    (items.length === 0 && filteredItems.includes(undefined)) ||
    items.some((item) => filtered.some((filteredItem) => areValuesEqual(item, filteredItem)))
  );
}

function getDisplayedItems<T>(
  items: T[],
  filteredItems: FilteredItemType<T>[],
  areValuesEqual: (a: T, b: T) => boolean
): T[] {
  const filtered = filteredItems.flatMap((item) => (item !== undefined ? [item] : []));

  return filteredItems.length > 0
    ? items.filter((item) => filtered.some((filteredItem) => areValuesEqual(item, filteredItem)))
    : items;
}

export function getFilterColumnProps<TValue, TRow extends TableDataType>(
  getValues: (row: TRow) => TValue[],
  areValuesEqual: (a: TValue, b: TValue) => boolean,
  filterProps: Omit<GenericColumnFilterProps<TValue, TRow>, "column">,
  //disabledKey: A conditional parameter that tracks the key to use (if there is one) for styling a cell as disabled
  disabledKey?: string
): Pick<ColumnWithLooseAccessor<TRow>, "disableFilters" | "filter" | "Filter" | "Cell"> {
  return {
    disableFilters: false,
    filter: (rows: Row<TRow>[], columnIds: string[], filteredItems: FilteredItemType<TValue>[]) => {
      if (filteredItems.length === 0) {
        return rows;
      }

      return rows.filter(({ original }) => {
        const values = getValues(original);
        return includeItem(values, filteredItems, areValuesEqual);
      });
    },
    // eslint-disable-next-line react/display-name
    Filter: ({ column }: FilterProps<TRow>) => {
      return <GenericColumnFilter {...filterProps} column={column} />;
    },
    // eslint-disable-next-line react/display-name
    Cell: ({
      column: { filterValue },
      row: { original },
    }: {
      column: UseFiltersColumnProps<TRow>;
      row: Row<TRow>;
    }) => {
      const filteredItems = (filterValue ?? []) as TValue[];

      const values = getValues(original);

      const displayedItems = getDisplayedItems(values, filteredItems, areValuesEqual);

      const numberOfHiddenItems = values.length - displayedItems.length;

      const hiddenItemsString = getHiddenCountString(numberOfHiddenItems);

      if (displayedItems.length === 0) {
        return numberOfHiddenItems > 0 ? hiddenItemsString : "N/A";
      }

      const { RowLabelComponent } = filterProps;

      return (
        <ConditionalGreyedWrapper
          greyed={
            // @ts-ignore
            !!disabledKey && original[disabledKey] === true
          }
        >
          <ListWrapper>
            {displayedItems.map((value, index) => (
              <RowLabelComponent key={index} value={value} row={original} />
            ))}
            {numberOfHiddenItems > 0 && hiddenItemsString}
          </ListWrapper>
        </ConditionalGreyedWrapper>
      );
    },
  };
}

function getHiddenCountString(count: number): string {
  return `+${count}`;
}
