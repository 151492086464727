import { gql } from "@apollo/client/core";

import { useParsedMutation } from "../apollo/utils/useParsedMutation";
import { useParsedMutationReturnType } from "../apollo/utils/useParsedMutationReturnType";
import { SITE } from "../common/types/LabelType";
import { SiteType } from "../common/types/SiteType";

const MUTATION = gql`
  mutation InsertSites($sites: [label_insert_input!]!) {
    insert_label(objects: $sites) {
      affected_rows
    }
  }
`;

type Input = {
  projectId: number;
  sites: SiteType[];
};

type Variables = {
  sites: (SiteType & {
    project_id: number;
  })[];
};

type Data = {
  insert_label: { affected_rows: number };
};

export function useInsertSites(): useParsedMutationReturnType<Input, number> {
  return useParsedMutation<Input, number, Data, Variables>({
    mutation: MUTATION,
    parseVariables,
    parseData,
  });
}

function parseVariables({ projectId: project_id, sites }: Input): Variables {
  return {
    sites: sites.map(({ name }) => {
      return {
        name,
        type_name: SITE,
        metadata: {},
        project_id,
      };
    }),
  };
}

function parseData(data: Data): number {
  return data.insert_label.affected_rows;
}
