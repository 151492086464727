import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import styled from "styled-components";

import { ReactComponent as EditIcon } from "../../../../assets/svgs/EditOutline.svg";
import { ReactComponent as LockIcon } from "../../../../assets/svgs/Lock.svg";
import { ReactComponent as AddIcon } from "../../../../assets/svgs/PlusOutline.svg";
import { MuiIcon } from "../../../../common/components/icons/MuiIcon";
import { SvgIcon } from "../../../../common/components/icons/SvgIcon";
import ToggleSwitch from "../../../../common/components/ToggleSwitch";
import { useCurrentUserCan } from "../../../../common/contexts/UserContext/useCurrentUserCan";
import { main } from "../../../../common/theme/main";
import { ADMIN } from "../../../../common/types/UserRoleType";
import { TaskType } from "../hooks/TaskType";
import { isTaskOption } from "./isTaskOption";
import { TaskOptionType } from "./TaskOptionType";
import { TaskSelectOptionType } from "./TaskSelectOptionType";

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ActionButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Button = styled.button`
  border: none;
  cursor: pointer;
  background: transparent;
  padding: 3px 2px;
`;

const DeleteButtonWrapper = styled.div`
  cursor: pointer;
`;

interface TaskOptionLabelProps {
  option: TaskSelectOptionType;
  isValue: boolean;
  onDeleteTask: () => void;
  onEditTask: (task: TaskType) => void;
  onChangeEnabled: (task: TaskType) => void;
}

export function TaskOptionLabel({
  option,
  isValue,
  onDeleteTask,
  onEditTask,
  onChangeEnabled,
}: TaskOptionLabelProps): JSX.Element {
  const { label } = option;

  const isTask = isTaskOption(option);

  const { value: task } = option as TaskOptionType;

  const canDeleteTasks = useCurrentUserCan([ADMIN]);
  const canDelete = canDeleteTasks && isTask;

  const { enabled } = task;

  const handleClickDelete = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation();

    if (!isTask) {
      throw new Error("Option is not a task");
    }

    if (!canDelete) {
      return;
    }

    onDeleteTask();
  };

  const handleClickEdit = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation();
    onEditTask(task);
  };

  const handleClickEnabled = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();

    onChangeEnabled(task);
  };

  if (!isTask) {
    return (
      <LabelWrapper>
        {!isTask && <SvgIcon icon={AddIcon} size={16} color={main.colors.states.success} />}
        {label}
      </LabelWrapper>
    );
  }

  return (
    <RowWrapper>
      <LabelWrapper>{label}</LabelWrapper>
      {!isValue && (
        <ActionButtonsWrapper>
          <ToggleSwitch size="small" checked={enabled} onChange={handleClickEnabled} />
          <Button onClick={handleClickEdit}>
            <SvgIcon icon={EditIcon} size={18} />
          </Button>
          <DeleteButtonWrapper onClick={handleClickDelete}>
            {isTask && (canDelete ? <MuiIcon icon={DeleteIcon} size={18} /> : null)}
          </DeleteButtonWrapper>
        </ActionButtonsWrapper>
      )}
    </RowWrapper>
  );
}
