import React, { FC, FormEvent } from "react";

import { ActionButtonsWrapper } from "../../../../../common/components/Dialog/Form/ActionButtonsWrapper";
import { Wrapper } from "../../../../../common/components/Dialog/Form/Wrapper";
import { FormProps } from "../../../../../common/components/Dialog/FormProps";
import { InputButton } from "../../../../../common/components/input/InputButton";
import { useGoToWorklist } from "../../../../../Project/Layout/AnnotatePage/useGoToWorklist";

export const TaskDoesNotExistDialog: FC<FormProps> = () => {
  const goToWorklist = useGoToWorklist();

  const handleGoToWorklist = (e: FormEvent) => {
    e.preventDefault();
    goToWorklist();
  };

  return (
    <form onSubmit={handleGoToWorklist}>
      <Wrapper>
        The task you are trying to access is not available. Please select a valid task.
      </Wrapper>
      <ActionButtonsWrapper>
        <InputButton type="submit" name="submit-button" value={"Worklist"} />
      </ActionButtonsWrapper>
    </form>
  );
};
