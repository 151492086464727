import React from "react";
import styled from "styled-components";

import { ReactComponent as FilterIcon } from "../../../assets/svgs/Filter.svg";
import { ReactComponent as FilterAltIcon } from "../../../assets/svgs/FilterAlt.svg";
import { main } from "../../theme/main";
import { SvgIcon } from "../icons/SvgIcon";

const ButtonWrapper = styled.div`
  cursor: pointer;
`;

interface FilterButtonProps {
  isFiltered: boolean;
  onClick: (e: React.MouseEvent<HTMLInputElement>) => void;
}

export function FilterButton({ isFiltered, onClick }: FilterButtonProps): JSX.Element {
  const {
    colors: {
      neutral: { neutral5 },
      states: { success },
    },
  } = main;

  return (
    <ButtonWrapper onClick={onClick} data-testid="filter-button">
      <SvgIcon
        icon={isFiltered ? FilterAltIcon : FilterIcon}
        size={16}
        color={isFiltered ? success : neutral5}
      />
    </ButtonWrapper>
  );
}
