import React from "react";
import {
  Bar,
  BarChart,
  Cell,
  Legend as ReChartsLegend,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import styled from "styled-components";

import { Legend } from "../../../Analysis/common/components/Legend";
import { getXAxisProps } from "../../../Analysis/common/utils/getXAxisProps";
import { getYAxisLabel } from "../../../Analysis/common/utils/getYAxisLabel";
import { getYAxisProps } from "../../../Analysis/common/utils/getYAxisProps";
import { ImageCaptureContextMenuContainer } from "../../../common/components/ImageCaptureContextMenuContainer";
import Text from "../../../common/components/text/Text";
import {
  getColorFromKey,
  ImagingDeviationDataProps,
  legendPayloadGenerator,
} from "../utils/parseImagingDeviationData";

const Wrapper = styled.div`
  flex: 1;
`;

interface ImagingDeviationChartProps {
  data: ImagingDeviationDataProps[];
}

const keys = ["a", "b", "c", "d"];

function ImagingDeviationsChart({ data }: ImagingDeviationChartProps): React.ReactElement {
  if (data.length === 0) {
    return <Text text={"There are no deviations."} />;
  }

  const xAxisProps = getXAxisProps();
  const yAxisProps = getYAxisProps(true);
  const yAxisLabel = getYAxisLabel("Deviations (% of all Imaging Studies)");

  const dense = data.length > 5;

  const longestLabelLength = Math.max(...data.map(({ siteName }) => siteName.length));

  return (
    <Wrapper>
      <ImageCaptureContextMenuContainer>
        {({ reference }) => (
          <ResponsiveContainer ref={reference} height={500}>
            <BarChart
              maxBarSize={100}
              margin={{ top: 0, right: 0, left: 30, bottom: -10 }}
              data={data}
            >
              <XAxis
                {...xAxisProps}
                allowDuplicatedCategory={false}
                dataKey={"siteName"}
                interval={0}
                axisLine={false}
                textAnchor={dense ? "end" : "middle"}
                angle={dense ? -90 : 0}
                height={dense ? 20 + 6 * longestLabelLength : undefined}
              />
              <YAxis
                {...yAxisProps}
                dataKey={"totalCount"}
                domain={[0, (dataMax: number) => Math.ceil(dataMax)]}
              >
                {yAxisLabel}
              </YAxis>
              <ReChartsLegend
                align="left"
                verticalAlign="top"
                height={48}
                content={<Legend />}
                payload={legendPayloadGenerator()}
              />
              {Object.keys(data[0]).map((key, index) => {
                if ([...keys, "totalCount"].includes(key)) {
                  return (
                    <Bar
                      isAnimationActive={false}
                      dataKey={(val) => val[key].count}
                      stackId={"x"}
                      barSize={50}
                      key={index}
                    >
                      {data.map(() => {
                        return <Cell key={key} fill={getColorFromKey(key)} />;
                      })}
                    </Bar>
                  );
                }
                // eslint-disable-next-line react/jsx-key
                return <></>;
              })}
            </BarChart>
          </ResponsiveContainer>
        )}
      </ImageCaptureContextMenuContainer>
    </Wrapper>
  );
}

export default ImagingDeviationsChart;
