import { useUploadProjectFileForm } from "nota-predict-web/src/DataManagement/Upload/components/useUploadProjectFileForm";
import { ReactElement, useState } from "react";
import { FaUpload } from "react-icons/fa6";

import {
  DrawerPageType,
  UPLOAD_CONTENT,
  UPLOAD_DICOM,
  UPLOAD_DOCUMENTS,
} from "../../../Project/Layout/Layout";
import { PROJECT_ROLE_COORDINATOR } from "../../types/UserRoleType";
import { Drawer } from "../Drawer";
import CTAButton from "./CTAButton";

interface TopBarUploadButtonProps {
  text: string;
  type: typeof UPLOAD_DICOM | typeof UPLOAD_DOCUMENTS;
}

export const TopBarUploadButton = ({ text, type }: TopBarUploadButtonProps): ReactElement => {
  const [open, setOpen] = useState<boolean>(false);

  const [showUploadProjectFileDialog, { dialog: uploadProjectFileDialog }] =
    useUploadProjectFileForm();

  const handleClickProjectFileUpload = () => {
    showUploadProjectFileDialog(true);
  };

  const handleOpenDrawer = () => {
    setOpen(true);
  };

  const handleCloseDrawer = () => {
    setOpen(false);
  };

  const uploadDrawerPage: DrawerPageType = {
    content: [
      {
        component: UPLOAD_CONTENT,
        props: {
          type: type,
        },
        allowedRoles: [PROJECT_ROLE_COORDINATOR],
      },
    ],
  };

  return (
    <>
      {uploadProjectFileDialog}
      <CTAButton
        icon={<FaUpload size={22} />}
        text={text}
        onClick={() =>
          type === UPLOAD_DOCUMENTS ? handleClickProjectFileUpload() : handleOpenDrawer()
        }
      />
      <Drawer
        width={800}
        zIndex={1000}
        open={open}
        onClose={handleCloseDrawer}
        page={uploadDrawerPage}
      />
    </>
  );
};
