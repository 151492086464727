import { SubjectType } from "../../common/types/SubjectType";
import { getFollowUpAtOrder } from "../../common/utils/getFollowUpAtOrder";
import { SurvivalBinType } from "./SurvivalBinType";

const BIN_SIZE = 10;
const NUMBER_OF_BINS = 10;

export function getSubjectMortalityHistogram(
  subjects: SubjectType[],
  followUpOrder: number
): SurvivalBinType[] {
  const getMortalityRisks = (subject: SubjectType): number | null => {
    const { followUps } = subject;
    const followUp = getFollowUpAtOrder(followUps, followUpOrder);
    if (!followUp) {
      throw new Error("Subject follow up does not exist!");
    }

    const { mortalityRisks } = followUp;
    return mortalityRisks.length > 0 ? mortalityRisks[0] : null;
  };

  const histogram: SurvivalBinType[] = [];
  for (let i = 0; i < NUMBER_OF_BINS; i++) {
    const binMin = BIN_SIZE * i;
    const binMax = BIN_SIZE * (i + 1);

    histogram.push({
      id: i,
      range: {
        min: binMin,
        max: binMax,
      },
      subjectsByArm: {},
    });
  }

  for (const subject of subjects) {
    const {
      projectArm: { number },
    } = subject;

    const mortalityRisk = getMortalityRisks(subject);
    if (mortalityRisk === null) {
      continue;
    }

    let match = histogram.find((survivalBin) => {
      const {
        range: { min, max },
      } = survivalBin;
      return mortalityRisk >= min && mortalityRisk < max;
    });

    if (!match) {
      match = histogram[histogram.length - 1];
    }

    if (!match.subjectsByArm[number]) {
      match.subjectsByArm[number] = [];
    }

    match.subjectsByArm[number].push(subject);
  }
  return histogram;
}
