import { useMemo } from "react";

import { SeriesListItemFragmentType, useStudiesList } from "./useStudiesList";

export function useTaskSeries(): SeriesListItemFragmentType[] | undefined {
  const { data } = useStudiesList();

  return useMemo(() => {
    if (!data) {
      return undefined;
    }

    const { studies } = data;
    return studies.flatMap(({ series }) => series);
  }, [data]);
}
