import { UserType } from "../../../types/UserType";

export function formatUserFullName({
  firstName,
  lastName,
}: Pick<UserType, "firstName" | "lastName">): string | null {
  const names = [firstName, lastName].filter((name) => name);

  return names.length > 0 ? names.join(" ") : null;
}
