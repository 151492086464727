import { Column } from "react-table";
import styled from "styled-components";

import { areCohortsEqual } from "../../../../Annotate/components/Manage/PatientTable/filters/areCohortsEqual";
import { getCohortOption } from "../../../../Annotate/components/Manage/PatientTable/filters/getCohortOption";
import { ReactComponent as EditIcon } from "../../../../assets/svgs/EditOutline.svg";
import { Avatar } from "../../../../common/components/Avatar/Avatar";
import { SvgIcon } from "../../../../common/components/icons/SvgIcon";
import { projectStatuses } from "../../../../common/components/ProjectManagement/CreateProjectForm";
import { useEditProjectDialog } from "../../../../common/components/ProjectManagement/Dialogs/useEditProjectDialog";
import { capitalizeFirstLetters } from "../../../../common/components/UserManagement/utils/capitalizeFirstLetters";
import { formatUserDisplayName } from "../../../../common/components/UserManagement/utils/formatUserDisplayName";
import { ProjectDetailsType } from "../../../../common/types/RawProjectDetailsType";
import { UserType } from "../../../../common/types/UserType";
import { OptionType } from "../../../../DataManagement/Upload/components/GenericMultiSelect";
import { parseUserFragment } from "../../../../Settings/UserFragment";
import { useUsers } from "../../../../Settings/useUsers";
import { CohortLabel } from "../Cohort/CohortLabel";
import { getFilterColumnProps } from "../Users/GenericColumnFilter";
import { ProjectTableRowType } from "./ProjectTableRowType";
import { useGlobalCohorts } from "./useGlobalCohorts";

type FilteredUserType = UserType | undefined;

const Button = styled.div`
  cursor: pointer;
  padding: 0 8px;
`;

const UserFilter = styled.div`
  gap: 6px;
  display: inline-flex;
`;

export function useProjectTableColumns(): Column<ProjectTableRowType>[] {
  const { data: allUsers = [], loading: allUsersLoading } = useUsers();

  const { data: cohortsData, loading: cohortsLoading } = useGlobalCohorts();
  const cohorts = cohortsData?.cohorts ?? [];

  const disabled = [true, false];

  return [
    {
      id: "name",
      Header: "Name",
      disableFilters: false,
      accessor: ({ name }) => name,
    },
    {
      id: "id",
      Header: "ID",
      disableFilters: false,
      accessor: ({ id }) => id,
      style: {
        width: "50px",
      },
    },
    {
      id: "status",
      Header: "Status",
      disableSortBy: true,
      ...getFilterColumnProps(
        ({ status }) => [status],
        areStatusEqual,

        {
          values: projectStatuses,
          getOption: getStatusOption,
          placeholder: "Filter Status...",
          // eslint-disable-next-line react/display-name,react/prop-types
          RowLabelComponent: ({ value }) => <>{capitalizeFirstLetters(value)}</>,
          // eslint-disable-next-line react/display-name,react/prop-types
          SelectLabelComponent: ({ value }) => <>{capitalizeFirstLetters(value)}</>,
        }
      ),
    },

    {
      id: "disabled",
      Header: "Enabled/Disabled",
      disableSortBy: true,
      ...getFilterColumnProps(
        ({ disabled }) => {
          return [disabled];
        },
        areEnabledStatusEqual,

        {
          values: disabled,
          getOption: getEnabledStatusOption,
          placeholder: "Filter Status...",
          // eslint-disable-next-line react/display-name,react/prop-types
          RowLabelComponent: ({ value }) => (
            <>{value ? <label>Disabled</label> : <label>Enabled</label>}</>
          ),
          // eslint-disable-next-line react/display-name,react/prop-types
          SelectLabelComponent: ({ value }) => (
            <>{value ? <label>Disabled</label> : <label>Enabled</label>}</>
          ),
        }
      ),
    },
    {
      id: "cohorts",
      Header: "Cohorts",
      disableSortBy: true,
      ...getFilterColumnProps(
        ({ cohorts }) => {
          return cohorts.map(({ cohort }) => cohort);
        },
        areCohortsEqual,
        {
          values: cohorts,
          getOption: getCohortOption,
          includeUnassigned: true,
          loading: cohortsLoading,
          placeholder: "Filter Cohorts...",
          // eslint-disable-next-line react/display-name,react/prop-types
          RowLabelComponent: ({ value }) => <CohortLabel cohort={value} />,
          // eslint-disable-next-line react/display-name,react/prop-types
          SelectLabelComponent: ({ value }) => <CohortLabel cohort={value} />,
        }
      ),
    },
    {
      id: "users",
      Header: "Users",
      ...getFilterColumnProps(
        ({ users }) => {
          return users.map(({ user }) => parseUserFragment(user));
        },
        areUsersEqual,
        {
          values: allUsers,
          getOption: getUserOption,
          includeUnassigned: true,
          loading: allUsersLoading,
          placeholder: "Filter Users...",
          // eslint-disable-next-line react/display-name,react/prop-types
          RowLabelComponent: ({ value }) => <Avatar user={value} size={22} />,
          // eslint-disable-next-line react/display-name,react/prop-types
          SelectLabelComponent: ({ value }) => (
            <UserFilter>
              <Avatar user={value} size={22} />
              {formatUserDisplayName(value)}
            </UserFilter>
          ),
        }
      ),
    },
    {
      id: "edit",
      Header: "Edit",
      style: {
        width: "42px",
      },
      accessor: (project) => project,
      // eslint-disable-next-line react/display-name
      Cell: ({ value: project }: { value: ProjectDetailsType }) => {
        const [setShowEditProjectDialog, { dialog: editProjectDialog }] =
          useEditProjectDialog(project);

        const handleEditClicked = () => {
          setShowEditProjectDialog(true);
        };

        return (
          <>
            {editProjectDialog}
            <Button onClick={handleEditClicked}>
              <SvgIcon icon={EditIcon} size={16} />
            </Button>
          </>
        );
      },
    },
  ];
}

function getUserOption(user: FilteredUserType): OptionType<FilteredUserType> {
  if (!user) {
    return {
      value: undefined,
      label: "Unassigned",
    };
  }

  return { value: user, label: user.email };
}

function getEnabledStatusOption(disabled: boolean | undefined): OptionType<boolean | undefined> {
  return {
    value: disabled,
    label: disabled ? "Disabled" : "Active",
  };
}

function getStatusOption(status: string | undefined): OptionType<string | undefined> {
  return {
    value: status,
    label: status ? capitalizeFirstLetters(status) : "",
  };
}

function areUsersEqual({ id: idA }: UserType, { id: idB }: UserType): boolean {
  return idA === idB;
}

function areEnabledStatusEqual(disabled1: boolean, disabled2: boolean): boolean {
  return disabled1 === disabled2;
}

function areStatusEqual(status1: string, status2: string): boolean {
  return status1 === status2;
}
