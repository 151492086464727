import dayjs from "dayjs";

import { DayjsDateRange } from "../../../common/utils/dateFormatUtils/DayjsDateRange";
import { isInRange } from "../../../common/utils/dateFormatUtils/isInRange";
import { TaskAssignmentType } from "../hooks/useProjectLabels";

export function isTaskAssignmentInRange(
  { lastModified }: TaskAssignmentType,
  range: DayjsDateRange
): boolean {
  return isInRange(dayjs(lastModified), range);
}
