import { useLDClient, withLDProvider } from "launchdarkly-react-client-sdk";
import { ComponentType, useContext, useEffect } from "react";

import { UserContext } from "../common/contexts/UserContext/UserContext";

interface LDProviderProps {
  children: JSX.Element;
}

interface LDClientContextProps {
  key: string | undefined;
}

const LDComponent = ({ children }: LDProviderProps): JSX.Element => {
  const { user } = useContext(UserContext);
  const ldClient = useLDClient();

  useEffect(() => {
    const newContext: LDClientContextProps = {
      key: user?.email,
    };

    ldClient?.identify(newContext);
  }, [ldClient, user?.email]);

  return children;
};

const getLDProvider = () => {
  if (
    window._env_.REACT_APP_LAUNCHDARKLY_CLIENT_ID === undefined ||
    window._env_.REACT_APP_LAUNCHDARKLY_CLIENT_ID.length === 0
  ) {
    throw new Error("REACT_APP_LAUNCHDARKLY_CLIENT_ID not specified");
  }
  return withLDProvider({
    clientSideID: `${window._env_.REACT_APP_LAUNCHDARKLY_CLIENT_ID}`,
  })(LDComponent as ComponentType<unknown>);
};

export const LDProvider = getLDProvider();
