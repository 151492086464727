import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

/*
the timezone being set explicitly since there is a bug with dayjs.diff() returning different results depending on
the local timezone, causing tests to sometimes fail in different environments such as GitHub Actions
reference: https://github.com/iamkun/dayjs/issues/1224
 */
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("America/Toronto");

export function getDeltaDateTimeInDays(dateA: Date, dateB: Date): number {
  return Math.abs(dayjs.tz(dateA).diff(dayjs.tz(dateB), "day"));
}
