import { useApolloClient } from "@apollo/client";

import { MEASURE } from "../../../../../../../../cornerstone/ToolType";
import { LesionListItemFragmentType } from "../../../../AnnotationPanel/fragments/LesionListItemFragment";
import { DiameterPointsType } from "../../../../AnnotationPanel/fragments/RoiRecistEvaluationFragment";
import { useLesionsList } from "../../../../AnnotationPanel/hooks/useLesionsList";
import { ContourDiametersDataType } from "../../../../AnnotationPanel/Stats/utils/getRoiContourDiameters";
import { getImageIdFromInstanceIdFromCache } from "../../../selection/getImageIdFromInstanceIdFromCache";
import { appendToolSliceData } from "../../legacy/ToolUtils";
import { getCornerstonePointsData } from "../utils/getCornerstonePointsData";
import {
  getRoiRecistEvaluationVariables,
  RoiRecistEvaluationVariablesType,
} from "../utils/getRoiRecistEvaluationVariables";
import { removeMeasurementsFromElement } from "../utils/removeMeasurementsFromElement";

export type ReturnType = (
  roiId: number,
  seriesId: number,
  contourDiametersData: ContourDiametersDataType | undefined,
  disableMeasurements: boolean
) => RoiRecistEvaluationVariablesType;

export function useContourMeasurements(): ReturnType {
  const { data } = useLesionsList();
  const { cache } = useApolloClient();

  return (
    roiId: number,
    seriesId: number,
    contourDiametersData: ContourDiametersDataType | undefined,
    disableMeasurements: boolean
  ) => {
    const roiRecistEvaluation = getRoiRecistEvaluationVariables(
      roiId,
      seriesId,
      contourDiametersData,
      disableMeasurements,
      cache
    );

    const {
      imageId,
      SADPoints: newSADPoints,
      LADPoints: newLADPoints,
    } = contourDiametersData || {};

    if (!data) {
      throw new Error("Finished loading lesions but no data");
    }

    const { lesions } = data;

    const currentDiameterPoints = getCurrentDiameterPoints(lesions, roiId);

    if (currentDiameterPoints === null) {
      if (disableMeasurements) {
        return roiRecistEvaluation;
      }

      if (!imageId || !newLADPoints || !newSADPoints) {
        return roiRecistEvaluation;
      }

      const LADPointData = getCornerstonePointsData(newLADPoints);
      const SADPointData = getCornerstonePointsData(newSADPoints);

      appendToolSliceData(MEASURE, imageId, [LADPointData]);
      appendToolSliceData(MEASURE, imageId, [SADPointData]);

      return roiRecistEvaluation;
    }

    const {
      LADPoints: currentLADPoints,
      SADPoints: currentSADPoints,
      instanceId: currentInstanceId,
    } = currentDiameterPoints;

    const currentImageId = getImageIdFromInstanceIdFromCache(cache, currentInstanceId);

    if (currentImageId === null) {
      throw new Error(`Could not find image id for instance with id ${currentInstanceId}.`);
    }

    updateExistingCornerstoneDiameterPoints(
      currentLADPoints,
      newLADPoints,
      currentImageId,
      imageId,
      disableMeasurements
    );
    updateExistingCornerstoneDiameterPoints(
      currentSADPoints,
      newSADPoints,
      currentImageId,
      imageId,
      disableMeasurements
    );
    return roiRecistEvaluation;
  };
}

function updateExistingCornerstoneDiameterPoints(
  currentDiameterPoints: DiameterPointsType,
  newDiameterPoints: DiameterPointsType | undefined,
  currentImageId: string,
  newImageId: string | undefined,
  disableMeasurements: boolean
) {
  if (
    !areDiameterPointsEqual(currentDiameterPoints, newDiameterPoints) ||
    currentImageId !== newImageId
  ) {
    removeMeasurementsFromElement(currentDiameterPoints);

    if (newDiameterPoints && !disableMeasurements) {
      const pointData = getCornerstonePointsData(newDiameterPoints);
      appendToolSliceData(MEASURE, newImageId, [pointData]);
    }
  }
}

export function getCurrentDiameterPoints(
  lesions: LesionListItemFragmentType[],
  roiId: number
): {
  LADPoints: DiameterPointsType;
  SADPoints: DiameterPointsType;
  instanceId: number;
} | null {
  const rois = lesions.flatMap(({ rois }) => rois);
  const roi = rois.find(({ id }) => id === roiId);

  if (!roi) {
    return null;
  }

  const { roiRecistEvaluation } = roi;

  const { LADPoints, SADPoints, instanceId } = roiRecistEvaluation || {};

  if (!LADPoints || !SADPoints || !instanceId) {
    return null;
  }

  return { LADPoints, SADPoints, instanceId };
}

export function areDiameterPointsEqual(
  PointsA: DiameterPointsType | undefined,
  PointsB: DiameterPointsType | undefined
): boolean {
  if (!PointsA && !PointsB) {
    return true;
  }

  const { start: startB, end: endB } = PointsB || {};

  const { start: startA, end: endA } = PointsA || {};

  return (
    startA?.x === startB?.x && startA?.y === startB?.y && endA?.x === endB?.x && endA?.y === endB?.y
  );
}
