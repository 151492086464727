import React, { FC } from "react";

import { ActionButtonsWrapper } from "../../../../../../common/components/Dialog/Form/ActionButtonsWrapper";
import { Wrapper } from "../../../../../../common/components/Dialog/Form/Wrapper";
import { FormProps } from "../../../../../../common/components/Dialog/FormProps";
import { InputButton } from "../../../../../../common/components/input/InputButton";
import { main } from "../../../../../../common/theme/main";
import { BiopsyAssignmentFormData } from "./BiopsyAssignmentFormData";

export type BiopsyAssignmentCallbacksType = {
  onConfirm: (unsure: boolean) => Promise<void>;
};

export interface BiopsyAssignmentFormProps
  extends BiopsyAssignmentFormData,
    BiopsyAssignmentCallbacksType {}

export const BiopsyAssignmentForm: FC<FormProps<BiopsyAssignmentFormProps>> = ({
  props: { message, showMaybe, onConfirm },
  onSubmit,
  onCancel,
}: FormProps<BiopsyAssignmentFormProps>) => {
  const {
    colors: {
      neutral: { black, white },
      states: { warning },
    },
  } = main;

  const handleYes = async () => {
    await onConfirm(false);
    onSubmit();
  };

  const handleProbably = async () => {
    await onConfirm(true);
    onSubmit();
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <Wrapper>
      {message}
      <ActionButtonsWrapper>
        <InputButton autoFocus type="button" value={"Yes"} onClick={handleYes} />
        {showMaybe && (
          <InputButton
            autoFocus
            type="button"
            value={"Probably"}
            background={warning}
            borderColor={warning}
            width={75}
            color={white}
            onClick={handleProbably}
          />
        )}
        <InputButton
          type="button"
          value={"No"}
          background={white}
          color={black}
          onClick={handleCancel}
        />
      </ActionButtonsWrapper>
    </Wrapper>
  );
};
