import React from "react";
import { useDeepCompareMemo } from "use-deep-compare";

import { FlexLoading } from "../../../../../common/components/Loading";
import { useGetLesionsListContext } from "./hooks/useGetLesionsListContext";
import { LesionsListContext } from "./LesionsListContext";

interface LesionsListContextProviderProps {
  children: JSX.Element;
}

export function LesionsListContextProvider({
  children,
}: LesionsListContextProviderProps): JSX.Element {
  const { context, loading } = useGetLesionsListContext();
  const memoContext = useDeepCompareMemo(() => context, [context]);
  if (loading) {
    return <FlexLoading></FlexLoading>;
  }

  if (!context) {
    throw new Error("Something went wrong loading lesion list context");
  }

  return <LesionsListContext.Provider value={memoContext}>{children}</LesionsListContext.Provider>;
}
