import { useCallback } from "react";

import { useGetSeriesImageIds } from "../../../ViewerPanel/Viewer/hooks/useGetSeriesImageIds";
import { getMaskFromCornerstone } from "../utils/getMaskFromCornerstone";

export function useGetMaskKeyImageId() {
  const getSeriesImageIds = useGetSeriesImageIds();

  return useCallback(
    (id: number, seriesId: number) => {
      const imageIds = getSeriesImageIds(seriesId);
      if (imageIds.length === 0) {
        throw new Error(`No image ids found for series ${seriesId}`);
      }
      const firstImageId = imageIds[0];

      const mask = getMaskFromCornerstone(id, firstImageId);
      if (!mask) {
        return undefined;
      }

      const { labelmaps2D } = mask;

      const indices = [...labelmaps2D.keys()].filter((index) => labelmaps2D[index]);

      if (indices.length === 0) {
        return undefined;
      }

      if (indices.length > imageIds.length) {
        throw new Error(`More labelmaps2D than imageIds`);
      }

      const middleIndex = indices[Math.floor(indices.length / 2)];

      return imageIds[middleIndex];
    },
    [getSeriesImageIds]
  );
}
