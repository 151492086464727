import {
  CompleteTaskProgressType,
  TASK_PENDING,
} from "../../../../Annotate/components/TaskWorklist/TaskProgressType";

const TASK_PENDING_UNASSIGNED = "PENDING_UNASSIGNED"; // For sorting only

// Helper function to determine the progress value
const getTaskProgress = (taskStatus: CompleteTaskProgressType): string => {
  if (taskStatus?.isUnassigned) {
    return TASK_PENDING_UNASSIGNED;
  } else if (taskStatus?.displayProgress) {
    return taskStatus.displayProgress;
  }
  return TASK_PENDING;
};

export const sortTaskByStatus = (
  taskStatusA: CompleteTaskProgressType,
  taskStatusB: CompleteTaskProgressType
) => {
  const progressA = getTaskProgress(taskStatusA);
  const progressB = getTaskProgress(taskStatusB);

  return progressA.localeCompare(progressB);
};
