import React, { ReactElement } from "react";
import { useHistory } from "react-router-dom";

import { DASHBOARD } from "../../Project/Layout/RoutePathType";
import { ClickableDiv } from "./divs/ClickableDiv";
import { NotaLogoSimple } from "./NotaLogoSimple";

const AppBranding = (): ReactElement => {
  const { push } = useHistory();

  const onClickHome = () => {
    push(DASHBOARD);
  };

  return (
    <ClickableDiv onClick={onClickHome}>
      <NotaLogoSimple />
    </ClickableDiv>
  );
};

export default AppBranding;
