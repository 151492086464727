import { useContext } from "react";

import { ProjectContext } from "../contexts/ProjectContext";

export function useProjectId(): number {
  const { projectId } = useContext(ProjectContext);

  if (projectId === undefined) {
    throw new Error("projectId is null/undefined");
  }
  return projectId;
}
