import {
  DiameterPointsType,
  Point,
} from "../../../../AnnotationPanel/fragments/RoiRecistEvaluationFragment";

export type CornerstonePointsDataType = {
  active: boolean;
  visible: boolean;
  invalidated: boolean;
  readOnly: boolean;
  handles: {
    start: CornerstonePoint;
    end: CornerstonePoint;
    textBox: {
      active: boolean;
      hasMoved: boolean;
      hasBoundingBox: boolean;
      drawnIndependently: boolean;
      movesIndependently: boolean;
      allowedOutsideImage: boolean;
    };
  };
};

type CornerstonePoint = {
  x: number;
  y: number;
  active: boolean;
  highlight: boolean;
};

export function getCornerstonePointsData(points: DiameterPointsType): CornerstonePointsDataType {
  const { start, end } = points;
  const cornerstoneStartPoint = getCornerstonePoint(start);
  const cornerstoneEndPoint = getCornerstonePoint(end);
  return {
    active: false,
    visible: true,
    invalidated: true,
    readOnly: true,
    handles: {
      start: cornerstoneStartPoint,
      end: cornerstoneEndPoint,
      textBox: {
        active: false,
        hasMoved: false,
        hasBoundingBox: true,
        drawnIndependently: true,
        movesIndependently: false,
        allowedOutsideImage: true,
      },
    },
  };
}

function getCornerstonePoint({ x, y }: Point) {
  return {
    x,
    y,
    active: true,
    highlight: true,
  };
}
