import { useDialogReturnType } from "../../Dialog/useDialog";
import { useAnonymizationEncryptionSelectForm } from "./AnonymizationEncryptionSelectForm";
import { AnonymizationMethodType } from "./Contexts/AnonymizationConfigurationContext";

export function useAnonymizationMethodConfigDialog(
  key: AnonymizationMethodType
): useDialogReturnType {
  const dialogHooks: Record<AnonymizationMethodType, () => useDialogReturnType> = {
    SUBJECT_ID_ENCRYPTION: useAnonymizationEncryptionSelectForm,
  };
  return dialogHooks[key]();
}
