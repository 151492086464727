import cornerstone from "cornerstone-core";

import { ROI } from "../../../../../../../cornerstone/ToolType";
import { doesImageContainRoiToolData, getToolSliceData } from "./ToolUtils";

export default class RoiToolWrapper {
  constructor(currentImageIdWatcher, roiTool) {
    this.currentImageIdWatcher = currentImageIdWatcher;
    this.currentRoi = null;
    this.enabled = false;
    this.toolType = ROI;
    this.onToolDataChangedCallback = null;
    this.onContourRightClickedCallback = null;
    this.onContourDoubleClickedCallback = null;

    this.tool = roiTool;
    this.tool.setDataUpdateCallback(this.onMeasureCompleted);
    this.tool.setOnContourRightClickedCallback(this.onContourRightClicked);
    this.tool.setOnContourDoubleClickedCallback(this.onContourDoubleClicked);
  }

  turnOn() {
    this.enabled = true;
  }

  turnOff() {
    this.enabled = false;
  }

  setCurrentRoi(roi) {
    this.currentRoi = roi;

    this.tool.setCurrentRoi(roi);

    if (!roi) {
      this.tool.setPassive();
    } else {
      this.updateState();
    }
  }

  setPassiveRoi(roi) {
    this.tool.setPassiveRoi(roi);
  }

  setToolDataChangedCallback(onToolDataChangedCallback) {
    this.onToolDataChangedCallback = onToolDataChangedCallback;
  }

  setOnContourRightClickedCallback(onContourRightClickedCallback) {
    this.onContourRightClickedCallback = onContourRightClickedCallback;
  }

  setOnContourDoubleClickedCallback(onContourDoubleClickedCallback) {
    this.onContourDoubleClickedCallback = onContourDoubleClickedCallback;
  }

  setOnExceptionThrown(onExceptionThrown) {
    this.tool.setOnExceptionThrown(onExceptionThrown);
  }

  onMeasureCompleted = async () => {
    if (!this.enabled) {
      return;
    }

    this.updateState();

    if (this.onToolDataChangedCallback) {
      let currentImageId = this.currentImageIdWatcher.getCurrentImageId();

      const toolData = getToolSliceData(this.currentRoi.id, currentImageId, this.toolType);

      await this.onToolDataChangedCallback(toolData, currentImageId);
    }
  };

  onContourRightClicked = (toolData) => {
    if (!this.onContourRightClickedCallback) {
      return;
    }

    let currentImageId = this.currentImageIdWatcher.getCurrentImageId();

    this.onContourRightClickedCallback(toolData, currentImageId);
  };

  onContourDoubleClicked = (toolData) => {
    if (!this.onContourDoubleClickedCallback) {
      return;
    }

    let currentImageId = this.currentImageIdWatcher.getCurrentImageId();

    this.onContourDoubleClickedCallback(toolData, currentImageId);
  };

  updateState = () => {
    this.tool.setActive();
  };

  onImageRendered = () => {
    if (!this.enabled) {
      return;
    }

    this.updateState();
  };

  setCornerstoneElement(cornerstoneElement) {
    if (this.cornerstoneElement) {
      this.cornerstoneElement.removeEventListener(
        cornerstone.EVENTS.NEW_IMAGE,
        this.onImageRendered
      );
    }

    this.cornerstoneElement = cornerstoneElement;

    this.cornerstoneElement.addEventListener(
      cornerstone.EVENTS.NEW_IMAGE,
      this.onImageRendered,
      false
    );
  }

  cancelToolDrawing() {
    this.tool.cancel();
  }

  currentSliceContainsCurrentRoiContour = () => {
    if (!this.currentRoi) {
      return false;
    }

    let currentImageId = this.currentImageIdWatcher.getCurrentImageId();
    return doesImageContainRoiToolData(currentImageId, this.toolType, this.currentRoi);
  };

  setToolRoiStates = (roiStates) => {
    this.tool.setToolRoiStates(roiStates);
  };

  isDrawing() {
    return this.tool.isDrawing();
  }

  updateCachedStats(image, toolData) {
    this.tool.updateCachedStats(image, toolData);
  }
}
