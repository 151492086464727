import React from "react";

import { DEFAULT_ICON_COLOR, DEFAULT_ICON_SIZE } from "./IconDefaults";
import { IconProps } from "./IconProps";
import { getPixelsFromSize } from "./utils/getPixelsFromSize";

interface MuiIconProps extends IconProps {
  icon: React.ComponentType<{ style: React.CSSProperties }>;
}

export function MuiIcon({
  size = DEFAULT_ICON_SIZE,
  color = DEFAULT_ICON_COLOR,
  icon: Icon,
}: MuiIconProps): JSX.Element {
  const fontSize = getPixelsFromSize(size);
  return <Icon style={{ fontSize, color: color }} />;
}
