import React, { ComponentType, useContext, useEffect } from "react";
import styled from "styled-components";
import { useDeepCompareMemo } from "use-deep-compare";

import { AnnotateViewerToolController } from "./AnnotateViewerToolController";
import { ViewerOptions, ViewerProps } from "./Viewer";
import { ViewerContext } from "./Viewer/ViewerContext";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 3px;
`;

const ViewerWrapper = styled.div<{
  numViewers: number;
}>`
  display: flex;
  flex: ${({ numViewers }) => `1 0 calc(${numViewers > 4 ? 33 : 50}% - 6px)`};
`;

interface ViewersGridProps {
  Viewer: ComponentType<ViewerProps>;
  options?: ViewerOptions;
}

export function ViewersGrid({ Viewer, options }: ViewersGridProps): JSX.Element {
  const { viewerConfigs } = useContext(ViewerContext);
  const memoViewerConfigs = useDeepCompareMemo(() => viewerConfigs, [viewerConfigs]);

  const memoOptions = useDeepCompareMemo(() => options, [options]);

  const toolControllers = useDeepCompareMemo(
    () =>
      memoViewerConfigs.map((viewerConfig, index) => (
        <ViewerWrapper
          key={`annotate-viewer-tool-controller-${index}`}
          numViewers={memoViewerConfigs.length}
        >
          <Viewer
            viewerConfig={viewerConfig}
            toolController={AnnotateViewerToolController}
            options={memoOptions}
          />
        </ViewerWrapper>
      )),
    // Explicitly leaving Viewer component out of the deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [memoViewerConfigs, memoOptions]
  );

  return <Wrapper>{toolControllers}</Wrapper>;
}
