import React, { FC } from "react";
import styled from "styled-components";

import Detail, { DetailProps } from "./Detail";

interface DetailGridProps {
  details: DetailProps[];
  numColumns: number;
}
const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

interface ChildProps {
  flexBasis: number;
}

const Child = styled("div")<ChildProps>`
  flex-basis: calc(${(props) => props.flexBasis || 0}% - 6px);
  min-width: 50px;
  padding-right: 6px;
`;

const DetailGrid: FC<DetailGridProps> = ({ details, numColumns }) => {
  return (
    <Grid>
      {details.map(({ title, value }: DetailProps, i) => (
        <Child flexBasis={100 / numColumns} key={i}>
          <Detail title={title} value={value ?? "N/A"} />
        </Child>
      ))}
    </Grid>
  );
};

export default DetailGrid;
