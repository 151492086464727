import React from "react";
import styled from "styled-components";

import { useCurrentUser } from "../../../contexts/UserContext/useCurrentUser";
import { OK_CANCEL } from "../../Dialog/DialogActionButtonsType";
import { DialogContentInnerWrapper } from "../../Dialog/DialogContentInnerWrapper";
import { DialogText } from "../../Dialog/DialogText";
import { RED_BUTTON_OVERRIDES } from "../../Dialog/RedButtonOverrides";
import { useDialog, useDialogReturnType } from "../../Dialog/useDialog";
import { NameText, NameWrapper, Wrapper } from "../UserDetails";
import { formatUserFullName } from "../utils/formatUserFullName";
import { UserIdentifierType } from "./useManageUserDeletionDialog";

const WarningBoldText = styled.span`
  font-weight: bold;
  font-size: 13px;
`;

const WarningText = styled(DialogText)`
  font-size: 13px;
`;

export function useRemoveUserFromProjectDialog(
  users: UserIdentifierType[],
  onConfirmRemoveUser: (users: UserIdentifierType[]) => void
): useDialogReturnType {
  const { id: currentUserId } = useCurrentUser();

  const userIds = users.flatMap(({ id }) => id);
  const isCurrentUser = userIds.includes(currentUserId);

  const label = userIds.length === 1 ? "Remove Team Member" : "Remove Team Members";

  const message =
    userIds.length === 1
      ? `This will remove the user from the project. All data created by this user will remain, however
  they will no longer be able to access this project.`
      : `This will remove the users from the project. All data created by these users will remain, however
  they will no longer be able to access this project.`;

  const content = (
    <DialogContentInnerWrapper>
      {users.map((user) => {
        const displayName = formatUserFullName(user) ?? user.email;
        return (
          <Wrapper key={user.id}>
            <NameWrapper>
              <NameText>{displayName}</NameText>
            </NameWrapper>
          </Wrapper>
        );
      })}
      {isCurrentUser && (
        <WarningText>
          <WarningBoldText>Warning: </WarningBoldText>You are removing yourself.
        </WarningText>
      )}
      <DialogText>{message}</DialogText>
    </DialogContentInnerWrapper>
  );

  const handleConfirmRemoveUser = () => {
    onConfirmRemoveUser(users);
  };

  return useDialog({
    label,
    content,
    options: {
      showCloseButton: false,
      actionButtonOptions: {
        type: OK_CANCEL,
        okText: "Remove",
        onOkOverride: handleConfirmRemoveUser,
        okButtonOverrides: RED_BUTTON_OVERRIDES,
      },
    },
  });
}
