import React from "react";
import styled from "styled-components";

import { ERROR, SUCCESS } from "../../../../../../../../../common/types/StatusTypes";
import { CHECK_MISSING_ROIS } from "../../../../../../../../enums/TaskDescriptionEnums";
import { formatSeriesDisplayName } from "../../../../../StudyPanel/utils/formatSeriesDisplayName";
import { useTimepointsNonViewOnlySeries } from "../../../../hooks/Timepoint/useTimepointsNonViewOnlySeries";
import { CHECK_RESULT_LESION_LEVEL } from "../../types/SaveCheckResultType";
import { UseLesionCheckReturnType } from "../../types/UseTaskCheckReturnType";

const Bold = styled.span`
  font-weight: bold;
`;

export type MissingRoisCheckResultType = {
  lesionId: number;
  seriesIds: number[];
} | null;

export function useMissingRoisCheck(): UseLesionCheckReturnType<MissingRoisCheckResultType> {
  const timepoints = useTimepointsNonViewOnlySeries() ?? [];

  return ({ lesion: { id: lesionId, rois } }) => {
    const series = timepoints.flatMap(({ series }) => series);
    const missingRoiSeries = series.filter(
      ({ id }) => !rois.find(({ series }) => id === series.id)
    );
    const missingSeriesLabels = missingRoiSeries.map((missingSeries, index) => {
      return formatSeriesDisplayName(missingSeries);
    });

    const hasAllRois = missingRoiSeries.length === 0;

    const result = !hasAllRois
      ? {
          lesionId,
          seriesIds: missingRoiSeries.flatMap(({ id }) => id),
        }
      : null;

    const status = hasAllRois ? SUCCESS : ERROR;

    const successMessage = "All series have been labeled.";
    const errorMessage = (
      <>
        Missing {missingSeriesLabels.length === 1 ? "a " : ""}label
        {missingSeriesLabels.length > 1 ? "s" : ""} for series{" "}
        <Bold>{missingSeriesLabels.join(", ")}</Bold>
      </>
    );

    const message = status === SUCCESS ? successMessage : errorMessage;

    return {
      type: CHECK_MISSING_ROIS,
      message,
      status,
      required: true,
      level: CHECK_RESULT_LESION_LEVEL,
      showOnSwitch: false,
      result,
    };
  };
}
