import { useLazyQuery } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { QueryTuple } from "@apollo/client/react/types/types";

type ProjectSeriesFilter = {
  _and: [
    { series_instance_uid: { _in: string[] } },
    {
      _or: [
        {
          label_series: {
            label: {
              label_projects: { project_id: { _eq: number } };
              type_name: { _eq: "COHORT" };
            };
          };
        },
        {
          study: {
            label_studies: {
              label: {
                label_projects: { project_id: { _eq: number } };
                type_name: { _eq: "COHORT" };
              };
            };
          };
        },
        {
          study: {
            patient: {
              label_patients: {
                label: {
                  label_projects: { project_id: { _eq: number } };
                  type_name: { _eq: "COHORT" };
                };
              };
            };
          };
        }
      ];
    }
  ];
};

type ProjectStudyFilter = {
  _and: [
    { study_instance_uid: { _in: string[] } },
    {
      _or: [
        {
          label_studies: {
            label: {
              label_projects: { project_id: { _eq: number } };
              type_name: { _eq: "COHORT" };
            };
          };
        },
        {
          patient: {
            label_patients: {
              label: {
                label_projects: { project_id: { _eq: number } };
                type_name: { _eq: "COHORT" };
              };
            };
          };
        }
      ];
    }
  ];
};

type ProjectPatientFilter = {
  _and: [
    { patient_dicom_id: { _in: string[] } },
    {
      label_patients: {
        label: {
          label_projects: { project_id: { _eq: number } };
          type_name: { _eq: "COHORT" };
        };
      };
    }
  ];
};

type GlobalSeriesFilter = {
  series_instance_uid: { _in: string[] };
};

type GlobalStudyFilter = {
  study_instance_uid: { _in: string[] };
};

type GlobalPatientFilter = {
  patient_dicom_id: { _in: string[] };
};

type Variables = {
  seriesFilter: ProjectSeriesFilter | GlobalSeriesFilter;
  studyFilter: ProjectStudyFilter | GlobalStudyFilter;
  patientFilter: ProjectPatientFilter | GlobalPatientFilter;
};
type Data = {
  series: { seriesId: number }[];
  study: { studyId: number }[];
  patient: { patientId: number }[];
};

const QUERY = gql`
  query GetIds(
    $seriesFilter: series_bool_exp!
    $studyFilter: study_bool_exp!
    $patientFilter: patient_bool_exp!
  ) {
    series(where: $seriesFilter) {
      seriesId: series_id
    }
    study(where: $studyFilter) {
      studyId: study_id
    }
    patient(where: $patientFilter) {
      patientId: patient_id
    }
  }
`;

export function useCSVIds(): QueryTuple<Data, Variables> {
  return useLazyQuery<Data, Variables>(QUERY, { fetchPolicy: "no-cache" });
}

export function getCSVIdsVariables(
  projectId: number | undefined,
  series: string[],
  studies: string[],
  patients: string[]
): Variables {
  const seriesFilter: ProjectSeriesFilter | GlobalSeriesFilter = projectId
    ? {
        _and: [
          { series_instance_uid: { _in: series } },
          {
            _or: [
              {
                label_series: {
                  label: {
                    label_projects: { project_id: { _eq: projectId } },
                    type_name: { _eq: "COHORT" },
                  },
                },
              },
              {
                study: {
                  label_studies: {
                    label: {
                      label_projects: { project_id: { _eq: projectId } },
                      type_name: { _eq: "COHORT" },
                    },
                  },
                },
              },
              {
                study: {
                  patient: {
                    label_patients: {
                      label: {
                        label_projects: { project_id: { _eq: projectId } },
                        type_name: { _eq: "COHORT" },
                      },
                    },
                  },
                },
              },
            ],
          },
        ],
      }
    : { series_instance_uid: { _in: series } };
  const studyFilter: ProjectStudyFilter | GlobalStudyFilter = projectId
    ? {
        _and: [
          { study_instance_uid: { _in: studies } },
          {
            _or: [
              {
                label_studies: {
                  label: {
                    label_projects: { project_id: { _eq: projectId } },
                    type_name: { _eq: "COHORT" },
                  },
                },
              },
              {
                patient: {
                  label_patients: {
                    label: {
                      label_projects: { project_id: { _eq: projectId } },
                      type_name: { _eq: "COHORT" },
                    },
                  },
                },
              },
            ],
          },
        ],
      }
    : { study_instance_uid: { _in: studies } };
  const patientFilter: ProjectPatientFilter | GlobalPatientFilter = projectId
    ? {
        _and: [
          { patient_dicom_id: { _in: patients } },
          {
            label_patients: {
              label: {
                label_projects: { project_id: { _eq: projectId } },
                type_name: { _eq: "COHORT" },
              },
            },
          },
        ],
      }
    : { patient_dicom_id: { _in: patients } };

  return { seriesFilter, studyFilter, patientFilter };
}
