import { isLesionLesion } from "../../../AnnotationPanel/utils/isLesionLesion";
import { getIntermediateData, IntermediateType } from "./getIntermediateData";
import { getTumourResponse } from "./getTumourResponse";
import { InputLesionType } from "./types/InputLesionType";
import { SeriesTumourResponseType } from "./types/SeriesTumourResponseType";

// derived from https://ctep.cancer.gov/protocoldevelopment/docs/recist_guideline.pdf section 4.3
export function calculateSeriesTumourResponses(
  lesions: InputLesionType[],
  seriesIds: number[]
): SeriesTumourResponseType[] {
  if (!lesions.every(isLesionLesion)) {
    throw new Error("Series tumour response passed non LESION(s)");
  }

  const intermediateData = getIntermediateData(lesions, seriesIds);
  return calculate(intermediateData);
}

function calculate(intermediateData: IntermediateType[]): SeriesTumourResponseType[] {
  if (intermediateData.length === 0) {
    return [];
  }

  const {
    sumOfDiameters: baselineSumOfDiameters,
    seriesId: baselineSeriesId,
    numberOfLesions: numberOfLesionsAtBaseline,
  } = intermediateData[0];

  const seriesResponses: SeriesTumourResponseType[] = [
    {
      seriesId: baselineSeriesId,
      isBaseline: true,
      totalBurden: baselineSumOfDiameters,
      tumourResponse: undefined,
    },
  ];

  for (let i = 1; i < intermediateData.length; i++) {
    const timepoint = intermediateData[i];

    const previousTimepoints = intermediateData.slice(0, i);
    const smallestSumOfDiameters = Math.min(
      ...previousTimepoints.map(({ sumOfDiameters }) => sumOfDiameters)
    );

    const tumourResponse = getTumourResponse(
      baselineSumOfDiameters,
      smallestSumOfDiameters,
      numberOfLesionsAtBaseline,
      timepoint
    );

    seriesResponses.push({
      seriesId: timepoint.seriesId,
      totalBurden: timepoint.sumOfDiameters,
      isBaseline: false,
      tumourResponse,
    });
  }

  return seriesResponses;
}
