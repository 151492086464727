import React, { useEffect, useState } from "react";
import { Column } from "react-table";

import SearchTextField from "../../../common/components/input/SearchTextField";
import { BigFlexLoading } from "../../../common/components/Loading";
import { useCurrentUser } from "../../../common/contexts/UserContext/useCurrentUser";
import { PatientMutableTaskType } from "../Manage/hooks/TaskType";
import { useProjectPatientTaskRows } from "../Manage/hooks/useProjectPatientTaskRows";
import { WorklistLayout } from "../Worklist/WorklistLayout";
import { TaskWorklistTable } from "./TaskWorklistTable";
import {
  PATIENT_DICOM_ID_COLUMN_ID,
  STATUS_COLUMN_ID,
  TASK_COLUMN_ID,
  TaskWorklistTableColumnIdType,
} from "./TaskWorklistTableColumns";
import {
  doesTaskAssignmentUserMatch,
  isAssigned,
  isCompleted,
  isInProgress,
  isUnassigned,
} from "./TaskWorklistTableRowType";

export function TaskWorklist(): JSX.Element {
  const { patientsTasks, loading, startPolling, stopPolling } = useProjectPatientTaskRows(
    false,
    false
  );

  const { id: userId } = useCurrentUser();

  const [globalFilterQuery, setGlobalFilterQuery] = useState<string>("");

  useEffect(() => {
    startPolling(1000);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  if (loading) {
    return <BigFlexLoading>Loading worklist...</BigFlexLoading>;
  }
  const assigned = patientsTasks?.filter((patientTask) => isAssigned(patientTask, userId)) ?? [];
  const unassigned = (patientsTasks?.filter((patientTask) => isUnassigned(patientTask)) ?? []).sort(
    (a, b) => {
      const timeA = new Date(a.lastModified).getTime();
      const timeB = new Date(b.lastModified).getTime();
      return timeA > timeB ? -1 : timeA < timeB ? 1 : 0;
    }
  );

  const inProgressTasks = assigned
    .filter((task) => isInProgress(task, userId))
    .sort((a, b) => {
      const timeA = new Date(
        a.task?.taskAssignments.filter(({ user }) => doesTaskAssignmentUserMatch(user, userId))[0]
          ?.lastAccessed ?? new Date()
      ).getTime();
      const timeB = new Date(
        b.task?.taskAssignments.filter(({ user }) => doesTaskAssignmentUserMatch(user, userId))[0]
          ?.lastAccessed ?? new Date()
      ).getTime();
      return timeA > timeB ? -1 : timeA < timeB ? 1 : 0;
    });
  const completedTasks = assigned.filter((task) => isCompleted(task, userId));

  const columnsFilter = ({ id }: Column<PatientMutableTaskType>) =>
    [PATIENT_DICOM_ID_COLUMN_ID, STATUS_COLUMN_ID, TASK_COLUMN_ID].includes(
      id as TaskWorklistTableColumnIdType
    );

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setGlobalFilterQuery(value);
  };

  return (
    <WorklistLayout
      pendingComponent={
        <TaskWorklistTable
          rows={unassigned}
          columnsFilter={columnsFilter}
          query={globalFilterQuery}
          globalProgress={false}
        />
      }
      inProgressComponent={
        <TaskWorklistTable
          rows={inProgressTasks}
          columnsFilter={columnsFilter}
          query={globalFilterQuery}
          globalProgress={false}
        />
      }
      completedComponent={
        <TaskWorklistTable
          rows={completedTasks}
          columnsFilter={columnsFilter}
          query={globalFilterQuery}
          globalProgress={false}
        />
      }
      filterComponent={<SearchTextField onChange={handleFilterChange} value={globalFilterQuery} />}
    />
  );
}
