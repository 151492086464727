import { IPRO, MORTALITY, RECIST } from "../../ChangeFromBaseline/components/Filter";
import { FollowUpType } from "../../common/types/FollowUpType";
import { getFollowUpDiametricTumourBurden } from "../../common/utils/getFollowUpDiametricTumourBurden";
import { getFollowUpMortalityRisk } from "../../common/utils/getFollowUpMortalityRisk";
import { getFollowUpVolumetricTumourBurden } from "../../common/utils/getFollowUpVolumetricTumourBurden";
import { MedianDomain } from "../../MedianSurvival/utils/getMedianSurvival";
import { getPredictedSurvival } from "./getPredictedSurvival";

export function getDomains(followUps: FollowUpType[]): MedianDomain {
  const maxIPRO = Math.max(...followUps.map((followUp) => getPredictedSurvival(followUp) ?? 0));
  const maxDiametricBurden = Math.max(
    ...followUps.map((followUp) => getFollowUpDiametricTumourBurden(followUp) ?? 0)
  );
  const maxVolumetricBurden = Math.max(
    ...followUps.map((followUp) => getFollowUpVolumetricTumourBurden(followUp) ?? 0)
  );
  const maxMortality = Math.max(
    ...followUps.map((followUp) => getFollowUpMortalityRisk(followUp) ?? 0)
  );

  return {
    [IPRO]: [0, Math.ceil(maxIPRO)],
    [RECIST]: {
      DIAMETRIC: [0, Math.ceil(maxDiametricBurden)],
      VOLUMETRIC: [0, Math.ceil(maxVolumetricBurden)],
    },
    [MORTALITY]: [0, Math.ceil(maxMortality)],
  };
}
