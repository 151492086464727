import { useCallback } from "react";
import ReactGA from "react-ga4";

import { silentTry } from "./index";

export const initGA = () => {
  if (!window._env_.REACT_APP_GA4_ID?.startsWith("G-")) {
    return;
  }

  ReactGA.initialize([
    {
      trackingId: window._env_.REACT_APP_GA4_ID,
    },
  ]);
};

export const useReactGA = () => {
  const event = useCallback((eventName: string) => {
    silentTry(() => ReactGA.event(eventName));
  }, []);

  return { event };
};
