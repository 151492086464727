import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type State = {
  selectedLesionId: number | null;
  hiddenLesionIds: number[];
};

const initialState: State = {
  selectedLesionId: null,
  hiddenLesionIds: [],
};

export const selectionSlice = createSlice({
  name: "selection",
  initialState,
  reducers: {
    setSelectedLesionId: (state, action: PayloadAction<number | null>) => {
      state.selectedLesionId = action.payload;
    },
    setHiddenLesionIds: (state, action: PayloadAction<number[]>) => {
      state.hiddenLesionIds = action.payload;
    },
    updateHiddenLesionIds: (state, action: PayloadAction<{ ids: number[]; visible: boolean }>) => {
      const { ids, visible } = action.payload;
      if (visible) {
        state.hiddenLesionIds = state.hiddenLesionIds.filter((id) => !ids.includes(id));
      } else {
        state.hiddenLesionIds = [...new Set([...state.hiddenLesionIds, ...ids])];
      }
    },
  },
});

export const { setSelectedLesionId, setHiddenLesionIds, updateHiddenLesionIds } =
  selectionSlice.actions;
