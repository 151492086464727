import React from "react";
import styled from "styled-components";

import { ReactComponent as AddIcon } from "../../../../assets/svgs/PlusOutlineFilled.svg";
import { SvgIcon } from "../../icons/SvgIcon";
import { CountIndicator } from "./CountIndicator";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

const LabelCountWrapper = styled.div`
  padding: 6px 8px 6px 4px;
`;

const ButtonWrapper = styled.div`
  cursor: pointer;
  padding: 0 0 0 6px;
`;

interface ListDisplayProps {
  label: string;
  count: number;
  onClickLabel: () => void;
  onClickAdd: () => void;
}

export function ListDisplay({
  label,
  count,
  onClickLabel,
  onClickAdd,
}: ListDisplayProps): JSX.Element {
  const handleOnClickLabel = () => {
    onClickLabel();
  };

  const handleOnClickAdd = () => {
    onClickAdd();
  };

  return (
    <Wrapper>
      <LabelWrapper onClick={handleOnClickLabel}>
        {label}
        <LabelCountWrapper>
          <CountIndicator count={count} />
        </LabelCountWrapper>
      </LabelWrapper>
      <ButtonWrapper onClick={handleOnClickAdd}>
        <SvgIcon icon={AddIcon} size={18} />
      </ButtonWrapper>
    </Wrapper>
  );
}
