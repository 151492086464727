import { createSelector } from "@reduxjs/toolkit";
import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getProjectViewFilters,
  projectViewFiltersActions,
} from "../../../common/store/projectViewFiltersSlice";
import { useGetProjectArmName } from "../../common/hooks/useGetProjectArmName";
import FilterCategory from "./FilterCategory";
import FilterCheckbox from "./FilterCheckbox";

const getProjectArmFilters = createSelector(
  [getProjectViewFilters],
  (projectViewFilters) => projectViewFilters.projectArm
);

export const ProjectArmFilters: FC = () => {
  const dispatch = useDispatch();
  const projectArmFilters = useSelector(getProjectArmFilters);
  const getProjectArmName = useGetProjectArmName();

  const options = Object.entries(projectArmFilters).map(([filter, value], index) => {
    const updateProjectArmFilters = (checked: boolean) => {
      dispatch(
        projectViewFiltersActions.setProjectArm({
          ...projectArmFilters,
          [filter]: checked,
        })
      );
    };
    return (
      <FilterCheckbox
        checked={value}
        key={index}
        label={getProjectArmName(index)}
        onChange={updateProjectArmFilters}
        name={filter}
      />
    );
  });

  return <FilterCategory name={"Project Arm"} options={options} />;
};
