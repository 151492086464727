import React from "react";

import { InputButton } from "../../../../../../../../../common/components/input/InputButton";
import { LesionTaskCheckResultComponentProps } from "../../types/SaveCheckResultComponentProps";
import { useGoToJumpToIds } from "../../utils/useGoToJumpToIds";
import { MissingContoursCheckResultType } from "./useMissingContoursCheck";

export function MissingContoursCheckActions({
  result,
  onRequestClose,
}: LesionTaskCheckResultComponentProps<MissingContoursCheckResultType>): JSX.Element {
  const jumpTo = useGoToJumpToIds();

  const handleOnClickJumpToSlice = () => {
    if (!result) {
      throw new Error("jumpToIds on missing slice check clicked");
    }

    jumpTo(result);

    onRequestClose();
  };

  if (!result) {
    return <></>;
  }

  return (
    <InputButton
      type={"button"}
      onClick={handleOnClickJumpToSlice}
      value={"Go to slice"}
      width={100}
    />
  );
}
