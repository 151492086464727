import { QualityControlRuleSetType } from "../../hooks/useQualityControlRuleSets";

export type QualityControlRuleSetOptionType = {
  label: string;
  value: number;
};

export function getQualityControlRuleSetOption(
  qualityControlRuleSet: QualityControlRuleSetType
): QualityControlRuleSetOptionType {
  const {
    id,
    schema: {
      info: { display_name },
    },
  } = qualityControlRuleSet;
  return {
    label: display_name,
    value: id,
  };
}
