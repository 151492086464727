import { MutationTuple, useMutation } from "@apollo/client";
import { gql } from "@apollo/client/core";

import {
  ClassificationAttributeType,
  DataType as QueryDataType,
  GET_CLASSIFICATION_ATTRIBUTES_QUERY,
} from "./useClassificationAttributes";

const MUTATION = gql`
  mutation InsertClassificationAttributes($objects: [classification_attribute_insert_input!]!) {
    insert_classification_attribute(objects: $objects) {
      returning {
        text
        readonly
      }
    }
  }
`;

type VariablesType = {
  objects: {
    text: string;
    readonly: boolean;
  }[];
};

type DataType = {
  insert_classification_attribute: {
    returning: ClassificationAttributeType[];
  };
};

export function useInsertClassificationAttributes(): MutationTuple<DataType, VariablesType> {
  return useMutation<DataType, VariablesType>(MUTATION, {
    update(cache, { data }) {
      if (!data) {
        throw new Error(
          "Something went wrong updating the cache after inserting classification attributes"
        );
      }

      const {
        insert_classification_attribute: { returning: insertClassificationAttributes },
      } = data;

      const { classificationAttributes: existingClassificationAttributes } =
        cache.readQuery<QueryDataType>({
          query: GET_CLASSIFICATION_ATTRIBUTES_QUERY,
        }) ?? {
          classificationAttributes: [],
        };

      cache.writeQuery<QueryDataType>({
        query: GET_CLASSIFICATION_ATTRIBUTES_QUERY,
        data: {
          classificationAttributes: [
            ...insertClassificationAttributes,
            ...existingClassificationAttributes,
          ],
        },
      });
    },
  });
}

export function getInsertClassificationAttributesVariables(
  classificationAttributes: ClassificationAttributeType[]
): VariablesType {
  return {
    objects: classificationAttributes.map(({ text }) => ({
      text,
      readonly: false,
    })),
  };
}
