import { RawTumourResponse } from "../../rawTypes/RawTumourResponse";
import { TumourResponseType } from "../../types/TumourResponseType";

export function parseTumourResponsesFromQueryResult(
  rawTumourResponse: RawTumourResponse
): TumourResponseType {
  const {
    tumour_response: { classification },
  } = rawTumourResponse;
  return (classification as TumourResponseType) ?? null;
}
