import { ReactNode } from "react";
import { TooltipProps } from "recharts";
import {
  Formatter,
  NameType,
  Payload,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: ${(props) => props.theme.colors.actionPrimary.default};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  padding: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.neutral.white};
`;

const Header = styled.div`
  font-style: normal;
  font-size: 16px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.neutral.white80};
`;

const TotalWrapper = styled.div`
  float: right;
  margin-left: 24px;
`;

const Divider = styled.div`
  height: 0;
  margin: 8px 0;
  border: 0.5px solid ${(props) => props.theme.colors.neutral.white40};
`;

const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 4px;
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
`;

const ItemLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Item = styled.div`
  font-style: normal;
  font-size: 11px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.neutral.white80};
`;

const Circle = styled.div<{ color: string }>`
  height: 12px;
  width: 12px;
  margin-right: 4px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  display: inline-block;
`;

type GenericTooltipProps<TValue extends ValueType, TName extends NameType> = Pick<
  TooltipProps<TValue, TName>,
  "active" | "payload" | "label" | "formatter" | "labelFormatter" | "itemSorter"
> & {
  summaryFormatter?: (payload: Array<Payload<TValue, TName>>) => ReactNode;
  getColor?: (payload: Payload<TValue, TName>) => string | undefined;
};

export function GenericTooltip<TValue extends ValueType, TName extends NameType>({
  active,
  payload,
  label,
  formatter,
  labelFormatter,
  itemSorter,
  summaryFormatter,
  getColor,
}: GenericTooltipProps<TValue, TName>): JSX.Element | null {
  if (!active || !payload || !payload.length) {
    return null;
  }

  const formattedLabel = labelFormatter?.(label, payload) ?? label;
  const summaryLabel = summaryFormatter?.(payload) ?? undefined;

  const sortedPayload = itemSorter
    ? [...payload].sort((a, b) => (itemSorter(a) > itemSorter(b) ? -1 : 1))
    : payload;

  return (
    <Wrapper>
      <Header>
        {formattedLabel}
        {summaryLabel && <TotalWrapper>{summaryLabel}</TotalWrapper>}
      </Header>
      <Divider />
      <ItemsWrapper>
        {sortedPayload.map((item, index) => {
          const { name, value, color } = formatItem(item, index, payload, formatter, getColor);
          return (
            <ItemWrapper key={index}>
              <ItemLabelWrapper>
                {color && <Circle color={color} />}
                <Item>{name}</Item>
              </ItemLabelWrapper>
              {value}
            </ItemWrapper>
          );
        })}
      </ItemsWrapper>
    </Wrapper>
  );
}

// the types here are a bit wonky due to how the recharts formatter expects its inputs
function formatItem<TValue extends ValueType, TName extends NameType>(
  item: Payload<TValue, TName>,
  index: number,
  payload: Array<Payload<TValue, TName>>,
  formatter: Formatter<TValue, TName> | undefined,
  getColor?: (payload: Payload<TValue, TName>) => string | undefined
): {
  name: TName | undefined;
  value: TValue | undefined;
  color: string | undefined;
} {
  const { name, value, color: payloadColor } = item;

  const color = getColor?.(item) ?? payloadColor;

  if (!formatter) {
    return { name, value, color };
  }

  let formattedValue: TValue | undefined = value;
  let formattedName: TName | undefined = name;

  if (value !== undefined && name !== undefined) {
    const formatted = formatter(value, name, item, index, payload);
    if (Array.isArray(formatted)) {
      formattedValue = formatted[0] as TValue;
      formattedName = formatted[1] as TName;
    } else {
      formattedValue = formatted;
    }
  }

  return { name: formattedName, value: formattedValue, color };
}

export function formatTotal(payload: Array<Payload<number, string>>) {
  const total = payload
    .map(({ value }) => value || 0)
    .reduce((partialSum, value) => partialSum + value, 0);
  return total.toString();
}
