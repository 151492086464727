import React, { forwardRef, useRef } from "react";
import { Column } from "react-table";

import { Table, TableProps } from "../../../common/components/Table/Table";
import { TableControlsType } from "../../../common/components/Table/TableControlsType";
import { TableDataType } from "../../../common/components/Table/TableDataType";
import { useGoToTask } from "../../../Project/Layout/AnnotatePage/useGoToTask";
import { PatientMutableTaskType } from "../Manage/hooks/TaskType";
import { useTaskWorklistTableColumns } from "./TaskWorklistTableColumns";
import { filterTaskWorklistRow } from "./TaskWorklistTableRowType";

type WorklistTableRowType = TableDataType & PatientMutableTaskType;

interface WorklistTableProps {
  rows: PatientMutableTaskType[];
  columnsFilter?: (column: Column<PatientMutableTaskType>) => boolean;
  onTaskSelected?: (task: PatientMutableTaskType) => void;
  query?: string;
  globalProgress: boolean;
  openTaskViewOnly?: boolean;
}

export function TaskWorklistTable({
  rows,
  columnsFilter = () => true,
  onTaskSelected,
  query,
  globalProgress,
  openTaskViewOnly = false,
}: WorklistTableProps): React.ReactElement {
  const goToTask = useGoToTask(openTaskViewOnly);

  const ref = useRef<TableControlsType<WorklistTableRowType>>(null);

  const allColumns = useTaskWorklistTableColumns(globalProgress, openTaskViewOnly);

  const memoizedColumns = React.useMemo<Column<PatientMutableTaskType>[]>(
    () => allColumns.filter(columnsFilter),
    [columnsFilter]
  );
  const memoizedRows = React.useMemo<PatientMutableTaskType[]>(
    () => rows.filter(({ task }) => !!task),
    [rows]
  );

  const handleTaskSelected = async (patientTask: PatientMutableTaskType) => {
    const { id: patientId, task } = patientTask;
    if (!task) {
      throw new Error("No task in selected row");
    }

    const { id: taskId, projectId } = task;

    goToTask({ taskId, projectId, patientId });
    onTaskSelected?.(patientTask);
  };

  return (
    <TableWithRef
      ref={ref}
      columns={memoizedColumns}
      data={memoizedRows}
      onClickRow={handleTaskSelected}
      query={query}
      globalFilterCallback={filterTaskWorklistRow}
      // artificially increase the viewport size to fix a bug that causes bottom rows to not render
      increaseViewportBy={100}
    />
  );
}

const TableWithRef = forwardRef<
  TableControlsType<WorklistTableRowType>,
  TableProps<WorklistTableRowType>
>((props, ref) => {
  return <Table {...props} forwardedRef={ref} />;
});
TableWithRef.displayName = "TableWithRef";
