import { StatsType } from "../../../../../types/TaskDescriptionType";
import { LesionListItemFragmentType } from "../fragments/LesionListItemFragment";
import { RoiListItemFragmentType } from "../fragments/RoiListItemFragment";
import { useContourListener } from "../hooks/useContourListener";
import { roiHUStatDefinition } from "./RoiHUStat";
import { roiLADStatDefinition } from "./RoiLADStat";
import { roiSADStatDefinition } from "./RoiSADStat";
import { roiTumourBurdenStatDefinition } from "./RoiTumourBurdenStat";
import { roiVolumeStatDefinition } from "./RoiVolumeStat";

export interface RoiStatProps {
  roi: RoiListItemFragmentType;
  lesion: LesionListItemFragmentType;
}

export type RoiStatComponentDefinition = {
  type: StatsType;
  hasValue: (props: RoiStatProps) => boolean;
  component: (props: RoiStatProps) => JSX.Element;
};

const componentDefinitions: RoiStatComponentDefinition[] = [
  roiLADStatDefinition,
  roiSADStatDefinition,
  roiVolumeStatDefinition,
  roiHUStatDefinition,
  roiTumourBurdenStatDefinition,
];

export type RoiStatComponent = (props: RoiStatProps) => JSX.Element;

export function useGetRoiStatComponents(
  roi: RoiListItemFragmentType,
  lesion: LesionListItemFragmentType,
  stats: StatsType[]
): RoiStatComponent[] {
  useContourListener([roi.id]);

  const components = stats.map((stat) => {
    const component = componentDefinitions.find(({ type }) => type === stat);
    if (!component) {
      throw new Error(`No stat component defined for stat: ${stat}`);
    }

    return component;
  });

  return components
    .filter(({ hasValue }) => hasValue({ roi, lesion }))
    .map(({ component }) => component);
}
