import { FLAGGED, FlagTypeOptions, NOT_FLAGGED, PARTIALLY_FLAGGED } from "./SeriesFlag";

type SeriesList = {
  seriesFlags: {
    id: number;
  }[];
}[];

export function getFlagValueFromSeries(series: SeriesList): FlagTypeOptions[] {
  const seriesWithFlags = series.filter(({ seriesFlags }) => seriesFlags.length > 0);
  const hasFlags = seriesWithFlags.length > 0;

  if (hasFlags) {
    if (seriesWithFlags.length === series.length) {
      return [FLAGGED];
    } else {
      return [PARTIALLY_FLAGGED];
    }
  }
  return [NOT_FLAGGED];
}
