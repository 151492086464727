import { createContext } from "react";

import { SubjectType } from "../../Analysis/common/types/SubjectType";
import { CohortType } from "../../common/types/CohortType";
import { ProjectType } from "../../common/types/ProjectType";
import { UserType } from "../../common/types/UserType";
import { RoutePathSingleOrArray } from "../Layout/Layout";
import { getRouteString } from "../Layout/utils/getRouteString";

export type ProjectContextType = {
  patients: SubjectType[];
  cohorts: CohortType[];
  project: ProjectType | undefined;
  users: UserType[];
  disabled: boolean;
  getRoute: (route: RoutePathSingleOrArray) => string;
  addUsers: (users: UserType[]) => Promise<void>;
  removeUser: (user: UserType) => Promise<void>;

  projectId: number | undefined;
  projectName: string | undefined;

  startPollingPatients: (pollInterval: number) => void;
  stopPollingPatients: () => void;
  //todo this shouldnt be neccessary but is due to missing cache updates
  refetchUsers: () => Promise<void>;
};

export const ProjectContext = createContext<ProjectContextType>({
  patients: [],
  cohorts: [],
  project: undefined,
  users: [],
  disabled: false,
  getRoute: (route) => getRouteString(route),
  addUsers: async () => await undefined,
  removeUser: async () => await undefined,
  projectId: undefined,
  projectName: undefined,
  startPollingPatients: () => undefined,
  stopPollingPatients: () => undefined,
  refetchUsers: async () => await undefined,
});
