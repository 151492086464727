export const labelTypes = [
  "DETECTED_LESION",
  "CONTOURED_LESION",
  "CONTOURED_MEASURABLE_LESION",
  "ORGAN",
] as const;

export type LabelType = typeof labelTypes[number];

export const labelTypeDisplayNames: Record<LabelType, string> = {
  DETECTED_LESION: "Detected Lesion",
  CONTOURED_LESION: "Contoured Lesion",
  CONTOURED_MEASURABLE_LESION: "Contoured Lesion",
  ORGAN: "Organ",
};
