import { AnyObject } from "nota-predict-web/src/common/types/EmptyObjectType";

import { isFragmentQcRule } from "./baseRule";

export const hasDependentQcRules = <T extends AnyObject = AnyObject, C extends AnyObject = T>(
  data: unknown,
): data is Omit<T, "dependent_rules"> & { dependent_rules: Omit<C, "dependent_rules">[] } => {
  if (data === undefined || data === null || !isFragmentQcRule(data)) {
    return false;
  }
  const dependentRules = (data as AnyObject)?.dependent_rules as C[] | undefined;
  return !!dependentRules && Array.isArray(dependentRules) && dependentRules.length > 0;
};
