import { useApolloClient } from "@apollo/client";

import { getImageFromCornerstoneCache } from "../../../../../../cornerstone/getImageFromCornerstoneCache";
import { ROI } from "../../../../../../cornerstone/ToolType";
import { getImageIdFromInstanceIdFromCache } from "../../ViewerPanel/selection/getImageIdFromInstanceIdFromCache";
import { useContourMeasurements } from "../../ViewerPanel/tools/contour/hooks/useContourMeasurements";
import { RoiRecistEvaluationVariablesType } from "../../ViewerPanel/tools/contour/utils/getRoiRecistEvaluationVariables";
import { getToolSliceData, setToolSliceData } from "../../ViewerPanel/tools/legacy/ToolUtils";
import { RoiListItemFragmentType } from "../fragments/RoiListItemFragment";
import { appendContourDiameters } from "../Stats/utils/appendContourDiameters";
import { getKeyContourDiameters } from "../Stats/utils/getKeyContourDiameters";

type ReturnType = (roi: RoiListItemFragmentType) => Promise<RoiRecistEvaluationVariablesType>;

export function useRecalculateRoiMeasurements(): ReturnType {
  const { cache } = useApolloClient();
  const applyMeasurements = useContourMeasurements();

  return async (roi) => {
    const {
      id: roiId,
      series: { id: seriesId },
      contours,
    } = roi;

    for (const { id, instanceId } of contours) {
      const imageId = getImageIdFromInstanceIdFromCache(cache, instanceId);
      if (!imageId) {
        throw new Error(
          `Unable to get imageId for contour with id ${id} when recalculating roi measurements`
        );
      }

      const image = await getImageFromCornerstoneCache(imageId);
      const toolData = getToolSliceData(roiId, imageId, ROI);

      const finalToolData = appendContourDiameters(toolData, image);

      setToolSliceData(ROI, roiId, imageId, finalToolData);
    }

    const contourDiametersData = getKeyContourDiameters(roiId);
    return applyMeasurements(roiId, seriesId, contourDiametersData, false);
  };
}
