import React, { ReactNode, useMemo, useState } from "react";

import { CallbackType, ContourContext } from "./ContourContext";
import { ContourToolDataType } from "./types/ContourToolDataType";

type BaseContourClipboardEntryType = {
  lesionId: number;
  roiId: number;
  imageId: string;
};

export type ContourClipboardEntryType = BaseContourClipboardEntryType & {
  toolData: ContourToolDataType[];
};

interface ContourContextProviderProps {
  children: ReactNode;
}

const generateCallbackId = function (): string {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
};

export function ContourContextProvider({ children }: ContourContextProviderProps): JSX.Element {
  const [callbacks, setCallbacks] = useState<
    Record<string, { callback: CallbackType; roiIds: number[] }>
  >({});

  const [clipboardData, setClipboardData] = useState<ContourClipboardEntryType | null>(null);

  const context = useMemo(
    () => ({
      contourClipboardData: clipboardData,
      setContourClipboardData: (data: ContourClipboardEntryType) => {
        setClipboardData(data);
      },
      pushCallback: (callback: CallbackType, roiIds: number[]) => {
        const callbackId = generateCallbackId();
        setCallbacks((previous) => ({ ...previous, [callbackId]: { callback, roiIds } }));
        return callbackId;
      },
      fireCallbacks: (firedRoiId: number) => {
        Object.values(callbacks).forEach(({ callback, roiIds }) => {
          if (roiIds.includes(firedRoiId)) {
            callback();
          }
        });
      },
      removeCallback: (callbackId: string) => {
        setCallbacks((previous) => {
          const previousCopy = { ...previous };
          delete previousCopy[callbackId];
          return previousCopy;
        });
      },
    }),
    [clipboardData, callbacks]
  );

  return <ContourContext.Provider value={context}>{children}</ContourContext.Provider>;
}
