import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { AnnotatePageContents } from "../AnnotatePageContents";
import { TaskLoader } from "./TaskLoader";
import { ViewerContextProvider } from "./ViewerPanel/Viewer/ViewerContextProvider";

// Performance check: each component in here only seems to render once.

export function AnnotatePage(): JSX.Element {
  return (
    <TaskLoader>
      <ViewerContextProvider>
        <DndProvider backend={HTML5Backend}>
          <AnnotatePageContents />
        </DndProvider>
      </ViewerContextProvider>
    </TaskLoader>
  );
}
