import React from "react";

import { useCurrentUser } from "../../contexts/UserContext/useCurrentUser";
import { useUserProfileDialog } from "../UserManagement/Dialogs/useUserProfileDialog";
import { Avatar } from "./Avatar";

interface CurrentUserAvatarProps {
  size?: number;
  showProfileOnClick: boolean;
}

export function CurrentUserAvatar({
  size,
  showProfileOnClick,
}: CurrentUserAvatarProps): JSX.Element {
  const user = useCurrentUser();

  const [setShowProfileDialog, { dialog: userProfileDialog }] = useUserProfileDialog(user);

  const handleClick = () => {
    if (!showProfileOnClick) {
      throw new Error("handleClick called but showProfileOnClick is false");
    }

    setShowProfileDialog(true);
  };

  return (
    <>
      {userProfileDialog}
      <Avatar user={user} onClick={showProfileOnClick ? handleClick : undefined} size={size} />
    </>
  );
}
