import { ClassificationValuesType } from "../../../../../../types/TaskDescriptionType";
import { LesionListItemFragmentType } from "../../fragments/LesionListItemFragment";
import { RoiListItemFragmentType } from "../../fragments/RoiListItemFragment";
import { useGetRoiViewOnlyOptions } from "./useGetRoiViewOnlyOptions";

type ReturnType = (
  lesion: LesionListItemFragmentType,
  { classifications }: RoiListItemFragmentType
) => ClassificationValuesType[];

export function useGetRoiViewOnlyClassifications(): ReturnType {
  const getRoiViewOnlyOptions = useGetRoiViewOnlyOptions();

  return (lesion, roi) => {
    const options = getRoiViewOnlyOptions(lesion, roi);

    return options.map(({ rule }) => rule);
  };
}
