import { useFlags } from "launchdarkly-react-client-sdk";
import { FC, useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select, { ValueType } from "react-select";
import styled from "styled-components";

import { ReactComponent as AddIcon } from "../../../assets/svgs/PlusOutlineFilled.svg";
import { useCreateCohortForm } from "../../../Dashboard/components/Settings/Cohort/useCreateCohortForm";
import { useGlobalCohorts } from "../../../Dashboard/components/Settings/Project/useGlobalCohorts";
import { GenericMultiSelect } from "../../../DataManagement/Upload/components/GenericMultiSelect";
import { UserFragmentType } from "../../../Settings/UserFragment";
import { main } from "../../theme/main";
import { CohortType } from "../../types/CohortType";
import { ProjectType } from "../../types/ProjectType";
import { ProjectDetailsType } from "../../types/RawProjectDetailsType";
import { SiteType } from "../../types/SiteType";
import { UserType } from "../../types/UserType";
import { DatePicker } from "../DatePicker";
import { DialogContentInnerWrapper } from "../Dialog/DialogContentInnerWrapper";
import { ActionButtonsWrapper } from "../Dialog/Form/ActionButtonsWrapper";
import { FormProps } from "../Dialog/FormProps";
import { SvgIcon } from "../icons/SvgIcon";
import { ErrorLabel } from "../input/ErrorLabel";
import { getSelectStyle } from "../input/getSelectStyle";
import { Input } from "../input/Input";
import { InputBox } from "../input/InputBox";
import { InputButton } from "../input/InputButton";
import { Label, SelectLabel } from "../input/Label";
import { useCreateUserDialog } from "../UserManagement/Dialogs/useCreateUserDialog";
import { useEditProjectUsersDialog } from "../UserManagement/Dialogs/useEditProjectUsersDialog";
import { useManageUserDeletionDialog } from "../UserManagement/Dialogs/useManageUserDeletionDialog";
import { useRemoveUserFromProjectDialog } from "../UserManagement/Dialogs/useRemoveUserFromProjectDialog";
import { TaskReassignmentType } from "../UserManagement/types/TaskReassignmentType";
import { useReassignUsersTasks } from "../UserManagement/useReassignUsersTasks";
import { UserManagementContext } from "../UserManagement/UserManagementContext";
import { buildReassignmentData } from "../UserManagement/utils/buildReassignmentData";
import { capitalizeFirstLetters } from "../UserManagement/utils/capitalizeFirstLetters";
import { AnonymizationDisplay } from "./Dialogs/AnonymizationDisplay";
import { ListDisplay } from "./Dialogs/ListDisplay";
import { EMPTY_PROJECT } from "./EmptyProject";
import { NewProjectType } from "./NewProjectType";

const DialogWrapper = styled.div`
  max-height: 500px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 819px;
  padding: 24px 0;
`;

const ColumnWrapper = styled(DialogContentInnerWrapper)`
  flex: 1;
  padding: 0 15px;
`;

const CohortWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  gap: 12px;
`;

const CohortSelectWrapper = styled.div`
  flex: 1;
`;

const ButtonWrapper = styled.div`
  cursor: pointer;
`;

const DisableButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

const LabelText = styled.div`
  width: 110px;
  padding-right: 24px;
`;

interface StatusOptions {
  label: string;
  value: string;
}

export const projectStatuses = ["ACTIVE", "DRAFT", "DONE"];

interface CreateProjectProps {
  sites: SiteType[];
  onAddSite: () => void;
  onShowSites: () => void;
  users: UserType[];
  onSaveProject: (project: NewProjectType) => void;
  project?: ProjectDetailsType;
}

export const CreateProjectForm: FC<FormProps<CreateProjectProps>> = ({
  props: { sites, onAddSite, onShowSites, users, onSaveProject, project },
  onSubmit,
  onCancel,
}: FormProps<CreateProjectProps>) => {
  const { estimatedCompletion: defaultEstimatedCompletion } = EMPTY_PROJECT;
  const {
    rules: { allowRemoveUser },
  } = useContext(UserManagementContext);

  const [reassignUsersTasks] = useReassignUsersTasks();

  const [disabled, setDisabled] = useState<boolean>(project?.disabled ?? false);

  const [estimatedCompletionDate, setEstimatedCompletionDate] = useState<Date>(
    defaultEstimatedCompletion
  );

  const [selectedCohortIds, setSelectedCohortIds] = useState<number[]>(
    project?.cohorts.map(({ cohort: { id } }) => id) ?? []
  );

  const [deletedUsers, setDeletedUsers] = useState<UserFragmentType[]>([]);
  const [taskReassignments, setTaskReassignments] = useState<TaskReassignmentType[]>([]);
  const [saveProject, setSaveProject] = useState<NewProjectType>(
    project ? transformProjectData(EMPTY_PROJECT, project, users) : EMPTY_PROJECT
  );

  const handleConfirmUserSuspension = () => {
    setRemoveUserFromProjectDialogOpen(true);
  };

  const handleConfirmRemoveUser = async () => {
    if (!allowRemoveUser) {
      throw new Error("handleConfirmRemoveUser called but allowRemoveUser is not true");
    }
    const taskReassignmentData = buildReassignmentData(taskReassignments);

    await reassignUsersTasks({
      variables: taskReassignmentData,
    });
    onSaveProject(saveProject);
    onSubmit();
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<NewProjectType>({
    defaultValues: project ? transformProjectData(EMPTY_PROJECT, project, users) : EMPTY_PROJECT,
  });

  const [setShowEditUsersDialog, { dialog: editUsersDialog }] = useEditProjectUsersDialog();

  const [setShowCreateUserDialog, { dialog: createUserDialog }] = useCreateUserDialog(users);

  const [setShowCreateCohortDialog, { dialog: createCohortDialog }] = useCreateCohortForm();

  const [setRemoveUserFromProjectDialogOpen, { dialog: removeUserFromProjectDialog }] =
    useRemoveUserFromProjectDialog(deletedUsers, handleConfirmRemoveUser);

  const [setShowManageUsersDeletionDialog, { dialog: manageUsersDeletionDialog }] =
    useManageUserDeletionDialog(
      deletedUsers,
      taskReassignments,
      setTaskReassignments,
      handleConfirmUserSuspension,
      users,
      project?.name,
      project?.id
    );

  const { data: cohorts, loading: loadingCohorts } = useGlobalCohorts();

  const globalCohorts: ProjectCohortType = cohorts
    ? cohorts.cohorts.map((cohort) => ({ cohort }))
    : [];

  const selectedCohorts = globalCohorts.filter(({ cohort: { id } }) =>
    selectedCohortIds.includes(id)
  );

  const handleCohortsChanged = (cohorts: ProjectCohortType): ProjectCohortType => {
    const cohortIds = cohorts.map(({ cohort: { id } }) => id);
    setSelectedCohortIds(cohortIds);
    return cohorts;
  };

  const flags = useFlags();
  const showAnonymization = flags["notaShowCreateTrialEncryptionSettings"];

  const handleStatusChanged = (value: ValueType<StatusOptions, false>): string | undefined => {
    if (!value) {
      return;
    }

    const { value: status } = value;
    return status;
  };

  const handleEstimatedCompletionChanged = (estimatedCompletion?: Date) => {
    const date = estimatedCompletion ?? new Date("2023-01-01");
    setEstimatedCompletionDate(date);
  };

  const handleShowUsers = () => {
    setShowEditUsersDialog(true);
  };

  const handleAddUser = () => {
    setShowCreateUserDialog(true);
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleSubmitForm = (newProject: NewProjectType) => {
    const originalUsers = project?.users ?? [];

    const deleteUsers: UserFragmentType[] | undefined = originalUsers
      ?.flatMap(({ user }) => user)
      .filter(({ id: oldId }) => !users?.find(({ id: newId }) => oldId === newId));
    if (deleteUsers && deleteUsers.length > 0) {
      setDeletedUsers(deleteUsers);
      setSaveProject({
        ...newProject,
        estimatedCompletion: estimatedCompletionDate,
        disabled: disabled,
      });
      setShowManageUsersDeletionDialog(true);
    } else {
      const project = {
        ...newProject,
        estimatedCompletion: estimatedCompletionDate,
        disabled: disabled,
      };
      onSaveProject(project);
      onSubmit();
    }
  };

  const handleClickAddCohort = () => {
    setShowCreateCohortDialog(true);
  };

  const statusOptions = projectStatuses.map((status) => ({
    label: capitalizeFirstLetters(status),
    value: status,
  }));

  const selectStyle = getSelectStyle<StatusOptions, false>();

  return (
    <DialogWrapper>
      {editUsersDialog}
      {createUserDialog}
      {createCohortDialog}
      {manageUsersDeletionDialog}
      {removeUserFromProjectDialog}
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Wrapper>
          <ColumnWrapper>
            <div>
              <Label htmlFor={"project-name"} required error={errors.name}>
                Project Name
              </Label>
              <Input
                error={errors.name}
                type="text"
                id={"project-name"}
                autoFocus
                autoComplete={"off"}
                {...register("name", { required: true })}
              />
              {errors.name && <ErrorLabel>Project name must be provided</ErrorLabel>}
            </div>
            <div>
              <Label htmlFor={"indication"} required error={errors.indication}>
                Indication
              </Label>
              <Input
                error={errors.name}
                type="text"
                id={"indication"}
                autoComplete={"off"}
                {...register("indication", { required: true })}
              />
              {errors.indication && <ErrorLabel>Indication must be provided</ErrorLabel>}
            </div>
            <div>
              <Label htmlFor={"investigation-product"} required error={errors.investigationProduct}>
                Investigation Product
              </Label>
              <Input
                error={errors.investigationProduct}
                type="text"
                id={"investigationProduct"}
                autoComplete={"off"}
                {...register("investigationProduct", { required: true })}
              />
              {errors.investigationProduct && (
                <ErrorLabel>Investigation Product must be provided</ErrorLabel>
              )}
            </div>
            <Controller
              control={control}
              name="status"
              render={({ field: { onChange, ref } }) => (
                <Select
                  styles={selectStyle}
                  defaultValue={
                    statusOptions.find(({ value }) => value === project?.status) ?? statusOptions[0]
                  }
                  isClearable={false}
                  isSearchable={true}
                  options={statusOptions}
                  onChange={(e) => onChange(handleStatusChanged(e))}
                  menuPortalTarget={document.body}
                  inputRef={ref}
                />
              )}
            />
            <InputBox name={"Estimated Completion"} required={true}>
              <DatePicker
                date={estimatedCompletionDate}
                onDateChanged={handleEstimatedCompletionChanged}
              />
            </InputBox>
            <div>
              <Label htmlFor={"study-number"} required error={errors.studyNumber}>
                Study Number
              </Label>
              <Input
                error={errors.studyNumber}
                type="number"
                id={"study-number"}
                autoComplete={"off"}
                {...register("studyNumber", { required: true })}
              />
              {errors.studyNumber && <ErrorLabel>Study number must be provided</ErrorLabel>}
            </div>
            {/*TODO: change to cohorts here */}
            <Controller
              control={control}
              name="cohorts"
              rules={{
                validate: (cohorts) => !!cohorts && cohorts.length > 0,
              }}
              render={({ field: { onChange, ref } }) => (
                <>
                  <CohortWrapper>
                    <CohortSelectWrapper>
                      <SelectLabel htmlFor={"cohorts"}>Cohorts</SelectLabel>
                      <GenericMultiSelect
                        id={"cohorts"}
                        values={globalCohorts}
                        selected={selectedCohorts}
                        placeholder={"Select global cohorts..."}
                        getOption={(cohort: { cohort: CohortType }) => ({
                          value: cohort,
                          label: cohort.cohort.name,
                        })}
                        onSelectedChanged={(e) => onChange(handleCohortsChanged(e))}
                        menuPortalTarget={document.body}
                        inputRef={ref}
                        isClearable={true}
                        isSearchable={true}
                        isLoading={loadingCohorts}
                      />
                    </CohortSelectWrapper>
                    <ButtonWrapper onClick={handleClickAddCohort}>
                      <SvgIcon icon={AddIcon} size={18} />
                    </ButtonWrapper>
                  </CohortWrapper>
                  {errors.cohorts && <ErrorLabel>At least one cohort must be selected</ErrorLabel>}
                </>
              )}
            />
          </ColumnWrapper>
          <ColumnWrapper>
            <div>
              <Label htmlFor={"phase"} required error={errors.phase}>
                Phase
              </Label>
              <Input
                error={errors.phase}
                type="text"
                id={"phase"}
                autoComplete={"off"}
                {...register("phase", { required: true })}
              />
              {errors.phase && <ErrorLabel>Phase must be provided</ErrorLabel>}
            </div>
            <div>
              <Label htmlFor={"design"} required error={errors.design}>
                Design
              </Label>
              <Input
                error={errors.design}
                type="text"
                id={"design"}
                autoComplete={"off"}
                {...register("design", { required: true })}
              />
              {errors.design && <ErrorLabel>Design must be provided</ErrorLabel>}
            </div>
            <div>
              <Label htmlFor={"primary-endpoints"} required error={errors.primaryEndpoints}>
                Primary Endpoint(s)
              </Label>
              <Input
                error={errors.primaryEndpoints}
                type="text"
                id={"primary-endpoints"}
                autoComplete={"off"}
                {...register("primaryEndpoints", { required: true })}
              />
              {errors.primaryEndpoints && (
                <ErrorLabel>Primary Endpoint(s) must be provided</ErrorLabel>
              )}
            </div>
            <div>
              <Label htmlFor={"secondary-endpoints"} required error={errors.secondaryEndpoints}>
                Secondary Endpoint(s)
              </Label>
              <Input
                error={errors.secondaryEndpoints}
                type="text"
                id={"secondary-endpoints"}
                autoComplete={"off"}
                {...register("secondaryEndpoints", { required: true })}
              />
              {errors.secondaryEndpoints && (
                <ErrorLabel>Secondary Endpoint(s) must be provided</ErrorLabel>
              )}
            </div>
            {/* Sites are hidden when editing a project, and the logic is removed for handling them as there
            is issues with deleting sites with studies, and they are looking to be removed as a part of
            https://linear.app/altislabs/issue/NOTA-755/deprecate-sites-in-favor-of-eventual-new-labelling-system */}
            {!project && (
              <ListDisplay
                label={"Sites"}
                count={sites.length}
                onClickAdd={onAddSite}
                onClickLabel={onShowSites}
              />
            )}
            <ListDisplay
              label={"Team Members"}
              count={users.length}
              onClickAdd={handleAddUser}
              onClickLabel={handleShowUsers}
            />
            {!!project && (
              <DisableButtonWrapper>
                <LabelText>{disabled ? "Project Disabled" : "Project Enabled"}</LabelText>
                <InputButton
                  type="button"
                  value={disabled ? "Enable" : "Disable"}
                  onClick={() => setDisabled(!disabled)}
                />
              </DisableButtonWrapper>
            )}
            {showAnonymization && <AnonymizationDisplay label={"Anonymization"} />}
          </ColumnWrapper>
        </Wrapper>
        <ActionButtonsWrapper style={{ paddingBottom: 12 }}>
          <InputButton type="submit" name="submit-button" value={"Confirm"} />
          <InputButton
            type="button"
            name="cancel-button"
            value={"Cancel"}
            background={main.colors.neutral.white}
            color={main.colors.neutral.black}
            onClick={handleCancel}
          />
        </ActionButtonsWrapper>
      </form>
    </DialogWrapper>
  );
};

const transformProjectData = (
  newProject: NewProjectType,
  existingProject: ProjectDetailsType,
  users: UserType[]
): NewProjectType => {
  const mergedProject = { ...newProject };
  mergedProject.name = existingProject.name;
  mergedProject.indication = existingProject.details.disease;
  mergedProject.investigationProduct = existingProject.details.ip;
  mergedProject.status = existingProject.status;
  mergedProject.estimatedCompletion = new Date(existingProject.estimatedCompletion);
  mergedProject.startDate = existingProject.startDate;
  mergedProject.studyNumber = parseInt(existingProject.details.study_no);
  mergedProject.phase = existingProject.details.phase;
  mergedProject.arms = existingProject.arms.length;
  mergedProject.design = existingProject.details.design;
  mergedProject.primaryEndpoints = existingProject.details.primary_endpoints;
  mergedProject.secondaryEndpoints = existingProject.details.secondary_endpoints;
  mergedProject.targetEndDate = new Date(existingProject.details.target_end_date);
  mergedProject.users = users;
  mergedProject.cohorts = existingProject.cohorts;
  mergedProject.disabled = existingProject.disabled;
  return mergedProject;
};

type ProjectCohortType = ProjectType["cohorts"];
