import { forwardRef, useRef, useState } from "react";
import styled from "styled-components";

import { ActionButton } from "../Annotate/components/Manage/ActionButton";
import { ReactComponent as CancelIcon } from "../assets/svgs/CancelOutline.svg";
import { ReactComponent as AddIcon } from "../assets/svgs/PlusOutline.svg";
import { Card } from "../common/components/cards/Card";
import { Drawer } from "../common/components/Drawer";
import { FlexLoading } from "../common/components/Loading";
import { Table, TableProps } from "../common/components/Table/Table";
import { TableControlsType } from "../common/components/Table/TableControlsType";
import { CohortTableRowType } from "../Dashboard/components/Settings/Cohort/CohortTableRowType";
import { useCohortTableColumns } from "../Dashboard/components/Settings/Cohort/useCohortTableColumns";
import { useCreateCohortForm } from "../Dashboard/components/Settings/Cohort/useCreateCohortForm";
import { useProjectCohorts } from "../Dashboard/components/Settings/Cohort/useProjectCohorts";
import { useProjectId } from "../Project/hooks/useProjectId";
import { DrawerPageType, UPLOAD_CSV_CONTENT } from "../Project/Layout/Layout";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ButtonsWrapper = styled.div`
  flex: 0;
  display: flex;
  padding: 4px;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

export function ProjectCohortsManagement(): JSX.Element {
  const tableRef = useRef<TableControlsType<CohortTableRowType>>(null);

  const projectId = useProjectId();

  const { data, loading } = useProjectCohorts(projectId);

  const [bulkActionCohortId, setBulkActionCohortId] = useState<number | undefined>(undefined);

  const cohorts = data?.cohorts ?? [];

  const [showCreateCohortForm, { dialog: createCohortForm }] = useCreateCohortForm(projectId);

  const handleOpenBulkAction = (cohortId: number) => {
    setBulkActionCohortId(cohortId);
  };

  const columns = useCohortTableColumns(handleOpenBulkAction);

  if (loading) {
    return <FlexLoading />;
  }

  const handleClickCreateCohort = () => {
    showCreateCohortForm(true);
  };

  const handleClearClicked = () => {
    const { current: tableControls } = tableRef;
    tableControls?.clearAllFilters();
  };

  const uploadCsvDrawerPage: DrawerPageType = {
    content: [
      {
        component: UPLOAD_CSV_CONTENT,
        props: {
          cohort: cohorts.find((cohort) => cohort.id === bulkActionCohortId),
        },
      },
    ],
  };

  return (
    <>
      <Drawer
        zIndex={1000}
        open={!!bulkActionCohortId}
        width={470}
        onClose={() => setBulkActionCohortId(undefined)}
        page={uploadCsvDrawerPage}
      />
      <Card
        noHead
        expand={true}
        content={
          <Wrapper>
            {createCohortForm}
            <ButtonsWrapper>
              <ActionButton
                label={"Clear"}
                icon={CancelIcon}
                onClick={handleClearClicked}
                tooltip={"Clear all selections/filters"}
              />
              <ActionButton
                label={"Create Cohort"}
                icon={AddIcon}
                onClick={handleClickCreateCohort}
                tooltip={"Create a new cohort"}
              />
            </ButtonsWrapper>
            <TableWithRef ref={tableRef} columns={columns} data={cohorts} enableSelect={false} />
          </Wrapper>
        }
      />
    </>
  );
}

const TableWithRef = forwardRef<
  TableControlsType<CohortTableRowType>,
  TableProps<CohortTableRowType>
>((props, ref) => {
  return <Table {...props} forwardedRef={ref} />;
});
TableWithRef.displayName = "TableWithRef";
