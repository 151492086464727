import React, { forwardRef, useContext, useRef } from "react";
import { Column } from "react-table";
import styled from "styled-components";

import { ActionButton } from "../../../Annotate/components/Manage/ActionButton";
import { ReactComponent as AddIcon } from "../../../assets/svgs/PlusOutlineFilled.svg";
import { UserTableRowType } from "../../../Dashboard/components/Settings/Users/UserTableRowType";
import { UserType } from "../../types/UserType";
import { Table, TableProps } from "../Table/Table";
import { TableControlsType } from "../Table/TableControlsType";
import { useCreateUserDialog } from "./Dialogs/useCreateUserDialog";
import { useProjectUsersTableColumns } from "./useProjectUsersTableColumns";
import { UserManagementContext } from "./UserManagementContext";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ActionPanelWrapper = styled.div`
  flex: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TableWrapper = styled.div`
  flex: 1;
`;

const ButtonsWrapper = styled.div`
  flex: 0;
  display: flex;
  padding: 4px;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

export function EditProjectUsers(): JSX.Element {
  const ref = useRef<TableControlsType<UserTableRowType>>(null);
  const {
    users,
    rules: { allowAddUser },
    onCreated,
  } = useContext(UserManagementContext);

  if (!onCreated) {
    throw new Error("onCreated not defined in EditProjectUsers");
  }

  const [setCreateUserDialogOpen, { dialog: createUserDialog }] = useCreateUserDialog(users);

  const allColumns = useProjectUsersTableColumns();

  const memoizedColumns = React.useMemo<Column<UserType>[]>(() => allColumns, [allColumns]);
  const memoizedRows = React.useMemo<UserType[]>(() => users, [users]);

  const handleClickCreateUser = () => {
    if (!allowAddUser) {
      throw new Error("handleClickCreateUser called by allowCreateUser is false");
    }

    setCreateUserDialogOpen(true);
  };

  return (
    <>
      {createUserDialog}
      <Wrapper>
        {allowAddUser && (
          <ActionPanelWrapper>
            <ButtonsWrapper>
              <ActionButton
                icon={AddIcon}
                label={"Add Team Member"}
                onClick={handleClickCreateUser}
                tooltip={"Add a team member to this project"}
              />
            </ButtonsWrapper>
          </ActionPanelWrapper>
        )}
        <TableWrapper>
          <TableWithRef
            ref={ref}
            columns={memoizedColumns}
            data={memoizedRows}
            minimumWidth={1000}
          />
        </TableWrapper>
      </Wrapper>
    </>
  );
}

const TableWithRef = forwardRef<TableControlsType<UserTableRowType>, TableProps<UserTableRowType>>(
  (props, ref) => {
    return <Table {...props} forwardedRef={ref} />;
  }
);
TableWithRef.displayName = "TableWithRef";
