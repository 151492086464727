import { ToolDataRoiFragmentType } from "../components/Annotate/page/AnnotationPanel/hooks/useLazyTaskToolData";
import { RoiRecistEvaluationFragmentType } from "../fragments/RoiRecistEvaluationFragmentType";
import { ContourType } from "../types/ContourType";
import { EllipseType } from "../types/EllipseType";
import { parseContour } from "./parseContour";
import { parseEllipse } from "./parseEllipse";

export type ParsedRoiType = {
  ellipse?: EllipseType;
  contours: ContourType[];
  roiRecistEvaluation: RoiRecistEvaluationFragmentType | null;
};

export function parseRoi(
  roiFragment: ToolDataRoiFragmentType,
  color: string,
  baseWadoUrl: string,
  lesionId: number
): ParsedRoiType {
  const {
    id,
    contours: contourFragments,
    ellipse: ellipseFragment,
    roiRecistEvaluation,
  } = roiFragment;

  const contours = contourFragments.map((contourFragment) => {
    const { instanceId } = contourFragment;
    const parsedContourToolData = parseContour(contourFragment);
    const toolDataWithUniqueToolRoiId = parsedContourToolData.map((data: any) => ({
      ...data,
      roi: {
        ...data.roi,
        id,
        color,
        fillColor: color,
        lesionId,
      },
    }));
    return {
      instanceId,
      toolData: toolDataWithUniqueToolRoiId,
    };
  });

  const ellipse = parseEllipse(ellipseFragment, id, baseWadoUrl, lesionId);

  return {
    contours,
    ellipse,
    roiRecistEvaluation,
  };
}
