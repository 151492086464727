import { createSelector } from "@reduxjs/toolkit";
import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getProjectViewFilters,
  projectViewFiltersActions,
} from "../../../common/store/projectViewFiltersSlice";
import { capitalize } from "../../../common/utils/capitalize";
import { TumourMutationType } from "../../common/types/TumourMutationType";
import FilterButton from "./FilterButton";
import FilterCategory from "./FilterCategory";
import { ButtonWrapper } from "./FilterInputWrapper";

const getTumourMutationsFilters = createSelector(
  [getProjectViewFilters],
  (projectViewFilters) => projectViewFilters.tumourMutations
);

export const TumourMutationsFilters: FC = () => {
  const dispatch = useDispatch();
  const tumourMutationsFilters = useSelector(getTumourMutationsFilters);

  const mutationsOptions = Object.keys(tumourMutationsFilters).map((key, index) => {
    const mutation = key as TumourMutationType;
    const updateTumourMutationsFilters = () => {
      dispatch(projectViewFiltersActions.toggleTumourMutation(mutation));
    };

    const isChecked = tumourMutationsFilters[mutation] ?? false;

    return (
      <FilterButton
        key={index}
        text={capitalize(mutation)}
        onClick={updateTumourMutationsFilters}
        active={isChecked}
        width={55}
      />
    );
  });

  const options = (
    <>
      <ButtonWrapper>{mutationsOptions}</ButtonWrapper>
    </>
  );

  return <FilterCategory name={"Mutations"} options={options} />;
};
