import React from "react";
import styled from "styled-components";

import { useOkCancelForm } from "../../../../../../common/components/Dialog/useOkCancelForm";
import { StatsType } from "../../../../../types/TaskDescriptionType";
import { LesionListItemFragmentType } from "../fragments/LesionListItemFragment";
import { RoiListItemFragmentType } from "../fragments/RoiListItemFragment";
import { ExistingRoiClassificationButtonsReturnType } from "../hooks/timepointOptions/useGetExistingRoiClassificationButtons";
import { RoiViewOnlyOptionsReturnType } from "../hooks/timepointOptions/useGetRoiViewOnlyOptions";
import { TimepointOptionsFromRoiClassificationsReturnType } from "../hooks/timepointOptions/useGetTimepointOptionsFromRoiClassifications";
import { DeleteRoisReturnType, getVariables } from "../hooks/useDeleteRois";
import { InsertLesionClassificationsInternalReturnType } from "../hooks/useInsertLesionClassification";
import { InsertRoiClassificationsReturnType } from "../hooks/useInsertRoiClassifications";
import { SeriesListItemFragmentType } from "../hooks/useStudiesList";
import { Mask } from "../MaskContextProvider";
import { DownloadState } from "../Masks/types/downloadState";
import { RoiCard } from "./RoiCard";
import { RoiCardHeader } from "./RoiCardHeader";

const EmptyActionButton = styled.div`
  width: 16px;
  height: 16px;
`;

interface RoiCardWrapperProps {
  roi: RoiListItemFragmentType;
  onSelected: (e: React.MouseEvent<Element, MouseEvent>, roi?: RoiListItemFragmentType) => void;
  isMulti: boolean;
  series: SeriesListItemFragmentType;
  lesion: LesionListItemFragmentType;
  label: string;
  isFirst: boolean;
  getRoiViewOnlyOptions: RoiViewOnlyOptionsReturnType;
  getIsLesionFromCurrentTask: (lesion: Pick<LesionListItemFragmentType, "taskId">) => boolean;
  isFromCurrentTask: boolean;
  canClassify: boolean;
  canDelete: boolean;
  onToggleMaskVisibility: (mask: Mask) => Promise<boolean>;
  getMaskDownloadState: (maskId: number) => DownloadState | undefined;
  jumpToViewerRoi: (roi: RoiListItemFragmentType) => void;
  getTimepointOptionsFromRoiClassifications: TimepointOptionsFromRoiClassificationsReturnType;
  headerStats: StatsType[];
  bodyStats: StatsType[];
  getExistingRoiClassificationButtons: ExistingRoiClassificationButtonsReturnType;
  deleteRois: DeleteRoisReturnType;
  updateTaskInProgress: () => Promise<void>;
  onTumourBurdenChanged: (lesionId: number) => Promise<void>;
  insertLesionClassificationsObj: InsertLesionClassificationsInternalReturnType;
  insertRoiClassificationsObj: InsertRoiClassificationsReturnType;
  updateLesionClassificationsForMode: (
    isBaseline: boolean,
    lesion: LesionListItemFragmentType
  ) => Promise<void>;
}

export function RoiCardWrapper({
  roi,
  onSelected,
  isMulti,
  series,
  lesion,
  label,
  isFirst,
  getRoiViewOnlyOptions,
  getIsLesionFromCurrentTask,
  isFromCurrentTask,
  canClassify,
  canDelete,
  onToggleMaskVisibility,
  getMaskDownloadState,
  jumpToViewerRoi,
  getTimepointOptionsFromRoiClassifications,
  headerStats,
  bodyStats,
  getExistingRoiClassificationButtons,
  deleteRois,
  updateTaskInProgress,
  onTumourBurdenChanged,
  insertLesionClassificationsObj,
  insertRoiClassificationsObj,
  updateLesionClassificationsForMode,
}: RoiCardWrapperProps): JSX.Element {
  const [deleteRoisDestructured] = deleteRois;

  const handleConfirmDeleteRoi = async () => {
    const variables = getVariables([roi]);
    await deleteRoisDestructured({
      variables,
    });

    const isLesionFromCurrentTask = getIsLesionFromCurrentTask(lesion);
    if (!isLesionFromCurrentTask) {
      return;
    }

    await updateLesionClassificationsForMode(isFirst, lesion);
    await onTumourBurdenChanged(lesion.id);
  };

  const [showConfirmDeleteDialog, { dialog: confirmDeleteDialog }] = useOkCancelForm({
    title: `Delete Timepoint`,
    message: getConfirmDeleteMessageDialog(isFirst),
    okLabel: "Confirm",
    onOkCallback: handleConfirmDeleteRoi,
  });

  const handleDeleteClicked = () => {
    showConfirmDeleteDialog(true);
  };

  const handleSelected = (e: React.MouseEvent<Element, MouseEvent>) => {
    onSelected(e, roi);
  };

  const options = getRoiViewOnlyOptions(lesion, roi);
  const firstOption = options[0];

  const title = isMulti && series && series.seriesDescription ? series.seriesDescription : label;

  return (
    <>
      {confirmDeleteDialog}
      <div onClick={handleSelected}>
        {firstOption ? (
          <RoiCardHeader
            roi={roi}
            label={title}
            options={[firstOption]}
            statComponents={[]}
            onDeleteClick={canDelete ? handleDeleteClicked : undefined}
            extraActionButtons={[<EmptyActionButton key={0} />]}
            lesion={lesion}
            canDelete={canDelete}
            isFromCurrentTask={isFromCurrentTask}
            onToggleMaskVisibility={onToggleMaskVisibility}
            getMaskDownloadState={getMaskDownloadState}
            jumpToViewerRoi={jumpToViewerRoi}
          />
        ) : (
          <RoiCard
            lesion={lesion}
            roi={roi}
            label={title}
            onDeleteClick={canDelete ? handleDeleteClicked : undefined}
            isFirst={isFirst}
            canDelete={canDelete}
            isFromCurrentTask={isFromCurrentTask}
            canClassify={canClassify}
            onToggleMaskVisibility={onToggleMaskVisibility}
            getMaskDownloadState={getMaskDownloadState}
            jumpToViewerRoi={jumpToViewerRoi}
            getTimepointOptionsFromRoiClassifications={getTimepointOptionsFromRoiClassifications}
            headerStats={headerStats}
            bodyStats={bodyStats}
            getExistingRoiClassificationButtons={getExistingRoiClassificationButtons}
            updateTaskInProgress={updateTaskInProgress}
            onTumourBurdenChanged={onTumourBurdenChanged}
            insertLesionClassificationsObj={insertLesionClassificationsObj}
            insertRoiClassificationsObj={insertRoiClassificationsObj}
          />
        )}
      </div>
    </>
  );
}

function getConfirmDeleteMessageDialog(isBaseline: boolean): string {
  return `Deleting a timepoint will also remove all related annotations${
    !isBaseline ? "" : " and may unassign classification(s)"
  }. Are you sure you want to delete this timepoint?`;
}
RoiCardWrapper.whyDidYouRender = true;
