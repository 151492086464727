import { AnnotationsExportButton } from "nota-predict-web/src/Annotate/components/Manage/ExportButton";
import { ReactElement } from "react";

import { useProjectPatientTaskRows } from "../../Annotate/components/Manage/hooks/useProjectPatientTaskRows";
import { getPatientTaskPairs } from "./PatientTaskPair";

export const ExportProjectButton = (): ReactElement => {
  const { patientsTasks } = useProjectPatientTaskRows(true, true);
  const patientTaskPairs = getPatientTaskPairs(patientsTasks ?? []);

  return (
    <AnnotationsExportButton
      patientTaskPairs={patientTaskPairs}
      label={"Export Annotations"}
      tooltip={"Export all annotations in this project"}
      variant={"toolbar"}
    />
  );
};
