import { main } from "../../../common/theme/main";
import { TaskAssignmentType } from "../Manage/hooks/TaskType";

export const TASK_PENDING = "PENDING";
export const TASK_IN_PROGRESS = "INPROGRESS";
export const TASK_COMPLETED = "COMPLETED";

export const TaskProgressTypeArray = [TASK_PENDING, TASK_IN_PROGRESS, TASK_COMPLETED] as const;

export type TaskProgressType = (typeof TaskProgressTypeArray)[number];

export type CompleteTaskProgressType = {
  taskAssignments?: TaskAssignmentType[];
  displayProgress: TaskProgressType;
  isUnassigned?: boolean;
};

const {
  states: { success, warning, info },
} = main.colors;

export const taskProgressColors: Record<TaskProgressType, string> = {
  [TASK_COMPLETED]: success,
  [TASK_IN_PROGRESS]: warning,
  [TASK_PENDING]: info,
};

export const taskProgressDisplayNames: Record<TaskProgressType, string> = {
  [TASK_COMPLETED]: "Completed",
  [TASK_IN_PROGRESS]: "In Progress",
  [TASK_PENDING]: "New",
};

export const allProgresses = Object.keys(taskProgressColors) as TaskProgressType[];
