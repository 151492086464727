import { gql } from "@apollo/client/core";

import { useParsedMutation } from "../../../apollo/utils/useParsedMutation";
import { useParsedMutationReturnType } from "../../../apollo/utils/useParsedMutationReturnType";
import { parseUserFragment, USER_FRAGMENT, UserFragmentType } from "../../../Settings/UserFragment";
import { UserType } from "../../types/UserType";

const MUTATION = gql`
  mutation UpdateUser(
    $userId: Int!
    $firstName: String
    $lastName: String
    $organization: String
    $title: String
  ) {
    update_users(
      where: { user_id: { _eq: $userId } }
      _set: {
        first_name: $firstName
        last_name: $lastName
        organization: $organization
        title: $title
      }
    ) {
      affected_rows
      returning {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

type Variables = {
  userId: number;
  firstName?: string | null;
  lastName?: string | null;
  organization?: string | null;
  title?: string | null;
};

type Data = {
  update_users: { affected_rows: number; returning: UserFragmentType[] };
};

type Input = {
  user: UserType;
};

export function useUpdateUserMetadata(): useParsedMutationReturnType<Input, UserType[]> {
  return useParsedMutation<Input, UserType[], Data, Variables>({
    mutation: MUTATION,
    parseVariables,
    parseData,
  });
}

function parseVariables({ user }: Input): Variables {
  const { id: userId, firstName, lastName, title, organization } = user;

  return {
    userId,
    firstName,
    lastName,
    organization,
    title,
  };
}

function parseData(data: Data): UserType[] {
  return data.update_users.returning.map(parseUserFragment);
}
