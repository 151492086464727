import { AnatomicalStructuresFragmentType } from "../../../../AnatomicalStructuresFragment";
import { AnatomicalStructureOptionType } from "./AnatomicalStructureOptionType";

export function getAnatomicalStructureOptions(
  anatomicalStructures: AnatomicalStructuresFragmentType[]
): AnatomicalStructureOptionType[] {
  return anatomicalStructures.map((anatomicalStructure) => {
    const { name } = anatomicalStructure;
    return {
      label: name,
      value: anatomicalStructure,
    };
  });
}
