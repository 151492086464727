import Badge from "@mui/material/Badge";
import { Avatar } from "nota-predict-web/src/common/components/Avatar/Avatar";
import { UserAvatarType } from "nota-predict-web/src/common/types/UserType";
import React from "react";
import styled from "styled-components";

import { Label } from "../Label";
import { main } from "../../theme/main";
import { TaskAssignmentType } from "../../../Annotate/components/Manage/hooks/TaskType";
import {
  CompleteTaskProgressType,
  TASK_PENDING,
  taskProgressColors,
  taskProgressDisplayNames,
  TaskProgressType,
} from "../../../Annotate/components/TaskWorklist/TaskProgressType";

const TextWrapper = styled.div`
  margin-left: 4px;
`;

const TaskLabelWithProgressWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px;
`;

interface TaskProgressLabelProps {
  progress?: TaskProgressType;
  showBadge: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  user?: UserAvatarType | undefined;
  extraItemCount?: number;
}

export function TaskProgressLabel({
  progress,
  showBadge,
  onClick,
  user,
  extraItemCount,
}: TaskProgressLabelProps): JSX.Element {
  const {
    neutral: { white },
  } = main.colors;

  const background = taskProgressColors[progress ?? TASK_PENDING];
  const text = taskProgressDisplayNames[progress ?? TASK_PENDING];

  if (!user) {
    return (
      <Label background={background} color={white} onClick={onClick}>
        {text}
      </Label>
    );
  }

  return (
    <Badge color="error" variant="dot" invisible={!showBadge}>
      <Label
        background={background}
        color={white}
        onClick={onClick}
        extraItemCount={extraItemCount}
      >
        <Avatar
          user={user}
          size={16}
          textColor={background}
          badgeColor={white}
          textBrightness={70}
          fontSizeMultiplier={0.55}
        ></Avatar>
        <TextWrapper>{text}</TextWrapper>
      </Label>
    </Badge>
  );
}

export function TaskProgressWithUser({
  progress,
  showBadge = false,
}: {
  progress: CompleteTaskProgressType;
  showBadge?: boolean;
}): JSX.Element {
  const { taskAssignments, displayProgress } = progress;

  if (!taskAssignments || taskAssignments.length === 0) {
    return <TaskProgressLabel progress={displayProgress} showBadge={showBadge} />;
  }

  return (
    <TaskLabelWithProgressWrapper>
      {taskAssignments?.map(({ user, progress, showBadge }: TaskAssignmentType) => {
        return (
          <TaskProgressLabel user={user} progress={progress} key={user.id} showBadge={showBadge} />
        );
      })}
    </TaskLabelWithProgressWrapper>
  );
}
