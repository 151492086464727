import { matchSorter } from "match-sorter";
import React from "react";

import { FlexLoading } from "../../../common/components/Loading";
import { useCurrentUser } from "../../../common/contexts/UserContext/useCurrentUser";
import { useAllUsersProjects } from "../../../common/queries/useAllUsersProjects";
import { ProjectType } from "../../../common/types/ProjectType";
import handleApolloError from "../../../common/utils/handleApolloError";
import { AllProjects } from "./AllProjects";

interface AllProjectsContainerProps {
  onClickProject: (project: ProjectType) => void;
  filter: string;
}

export function AllProjectsContainer({
  onClickProject,
  filter,
}: AllProjectsContainerProps): JSX.Element {
  const { id: userId } = useCurrentUser();
  const { projects, loading, error } = useAllUsersProjects(userId);

  if (loading) {
    return <FlexLoading />;
  }
  if (error) {
    handleApolloError(error);
  }
  if (!projects) {
    throw new Error("Projects null");
  }

  const filteredProjects = matchSorter(projects, filter, { keys: ["name"] });

  return <AllProjects projects={filteredProjects} onClickProject={onClickProject} />;
}
