import { main } from "nota-predict-web/src/common/theme/main";
import React from "react";
import { BsCheck } from "react-icons/bs";
import styled from "styled-components";

import { getMonthDateTime } from "../../../../../../common/utils/dateFormatUtils/getMonthDateTime";
import { usePatientData } from "../../../../../../Dashboard/components/Settings/Patients/Viewer/hooks/usePatientData";
import { useQualityControlContext } from "./QualityControlProvider";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  width: 100%;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.neutral.neutral9};
  gap: 4px;
  padding: 12px 0 14px;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid ${(props) => props.theme.colors.neutral.neutral8};
  }
`;

const Text = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  color: ${(props) => props.theme.colors.neutral.neutral4};
`;

const Bold = styled.span`
  font-weight: bold;
`;

const StyledSuccessMessage = styled("div")((props) => ({
  color: props?.color ?? props.theme.colors.states.success,
  fontSize: "12px",
  lineHeight: "20px",
  width: "100%",
  zIndex: 300,
  backgroundColor: main.colors.neutral.neutral9,
  display: "block",
}));

const TinyCheckIcon = styled(BsCheck)((props) => ({
  ...props.style,
  fontSize: "14px",
  verticalAlign: "text-top",
  height: "14px",
  path: {
    strokeWidth: "0.5px",
    stroke: "currentColor",
  },
}));

const StatusIndicator = ({ isValid }: { isValid: boolean }): JSX.Element => (
  <StyledSuccessMessage color={isValid ? main.colors.states.success : main.colors.neutral.neutral7}>
    Form is valid <TinyCheckIcon />
  </StyledSuccessMessage>
);

export function QcFormHeader({
  isValid = false,
  isLoading = true,
}: {
  isValid: boolean;
  isLoading: boolean;
}): React.ReactElement {
  const { activeSeries, activePatientId, activeScopeState } = useQualityControlContext();
  const { data } = usePatientData(activePatientId ?? 0);

  const patient = data?.patient ?? undefined;
  const [scopeState] = activeScopeState;

  return (
    <Wrapper className="qc-form-header">
      <StatusIndicator isValid={isValid && !isLoading} />
      {!!activeSeries && scopeState === "SERIES" && (
        <>
          <Text>
            Series Description:{" "}
            <Bold title={activeSeries.seriesDescription}>{activeSeries.seriesDescription}</Bold>
          </Text>
          <Text>
            Series Date:{" "}
            <Bold title={getMonthDateTime(new Date(activeSeries.seriesDate))}>
              {getMonthDateTime(new Date(activeSeries.seriesDate))}
            </Bold>
          </Text>
        </>
      )}
      {!!patient && scopeState === "PATIENT" && (
        <Text>
          Subject: <Bold title={patient.patientDicomId}>{patient.patientDicomId}</Bold>
        </Text>
      )}
    </Wrapper>
  );
}
