import { createContext } from "react";

import { ProjectUserRoleType } from "../../types/UserRoleType";
import { UserType } from "../../types/UserType";

export type UserContextType = {
  user: UserType | undefined;
  allProjectUserRoles: ProjectUserRoleType[] | undefined;
};

export const UserContext = createContext<UserContextType>({
  user: undefined,
  allProjectUserRoles: undefined,
});
