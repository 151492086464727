import { LesionListItemFragmentType } from "../../AnnotationPanel/fragments/LesionListItemFragment";
import { NEW_FINDING_ROI_ID } from "../tools/toolController/NewFindingRoiId";

export function getVisibleRoiIds(
  lesions: LesionListItemFragmentType[],
  hiddenLesionIds: number[]
): number[] {
  const visibleLesions = lesions.filter(({ id }) => !hiddenLesionIds.includes(id));

  const visibleRois = visibleLesions.flatMap(({ rois }) => rois);
  const visibleRoiIds = visibleRois.map(({ id }) => id);

  //append magic roi id so new ones are always visible
  return [NEW_FINDING_ROI_ID, ...visibleRoiIds];
}
