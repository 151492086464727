import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { MutationTuple } from "@apollo/client/react/types/types";

import { ELLIPSE_FRAGMENT } from "../../../../../../../fragments/EllipseFragment";
import { EllipseFragmentType } from "../../../../../../../fragments/EllipseFragmentType";
import { EllipseType } from "../../../../../../../types/EllipseType";

const MUTATION = gql`
  mutation UpdateEllipse($ellipseId: Int!, $data: jsonb!) {
    update: update_ellipse(where: { ellipse_id: { _eq: $ellipseId } }, _set: { data: $data }) {
      ellipses: returning {
        ...Ellipse
      }
    }
  }
  ${ELLIPSE_FRAGMENT}
`;

type Variables = {
  ellipseId: number;
  data: EllipseType;
};

type Data = {
  update: { ellipses: EllipseFragmentType[] };
};

export function useUpdateEllipse(): MutationTuple<Data, Variables> {
  return useMutation<Data, Variables>(MUTATION);
}
