import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { MutationTuple } from "@apollo/client/react/types/types";

import { SERIES_CLASSIFICATION_FRAGMENT } from "../../../../../fragments/SeriesClassificationFragment";
import { SeriesClassificationType } from "../../../../../types/SeriesClassificationType";
import { getCacheId } from "../../../../Manage/cache/getCacheId";

const MUTATION = gql`
  mutation InsertSeriesClassification($objects: [series_classification_insert_input!]!) {
    insertSeriesClassification: insert_series_classification(objects: $objects) {
      returning {
        ...SeriesClassification
      }
    }
  }
  ${SERIES_CLASSIFICATION_FRAGMENT}
`;

type Data = {
  insertSeriesClassification: {
    returning: SeriesClassificationType[];
  };
};

type Variables = {
  objects: {
    classification: string;
    series_id: number;
    user_id: number;
    task_id: number;
  }[];
};

export function useInsertSeriesClassifications(): MutationTuple<Data, Variables> {
  return useMutation<Data, Variables>(MUTATION, {
    update: (cache, { data }) => {
      if (!data) {
        throw new Error(
          "There was a problem updating the cache while inserting series classifications!"
        );
      }

      const {
        insertSeriesClassification: { returning: inserted },
      } = data;

      for (const { seriesId, taskId, userId, classification, id } of inserted) {
        const seriesCacheId = getCacheId(seriesId, "series", cache);
        cache.modify({
          id: seriesCacheId,
          fields: {
            series_classifications(existingClassificationRefs = []) {
              const newClassificationRef = cache.writeFragment({
                fragment: SERIES_CLASSIFICATION_FRAGMENT,
                data: {
                  __typename: "series_classification",
                  id,
                  classification,
                  series_id: seriesId,
                  user_id: userId,
                  task_id: taskId,
                },
              });

              return [...existingClassificationRefs, newClassificationRef];
            },
          },
        });

        const taskCacheId = getCacheId(taskId, "task", cache);
        cache.modify({
          id: taskCacheId,
          fields: {
            series_classifications(existingClassificationRefs = []) {
              const newClassificationRef = cache.writeFragment({
                fragment: SERIES_CLASSIFICATION_FRAGMENT,
                data: {
                  __typename: "series_classification",
                  id,
                  classification,
                  series_id: seriesId,
                  user_id: userId,
                  task_id: taskId,
                },
              });

              return [...existingClassificationRefs, newClassificationRef];
            },
          },
        });
      }
    },
  });
}

export function getVariables(
  seriesId: number,
  userId: number,
  taskId: number,
  classifications: string[]
): Variables {
  return {
    objects: classifications.map((classification) => ({
      classification,
      series_id: seriesId,
      user_id: userId,
      task_id: taskId,
    })),
  };
}
