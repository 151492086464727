import { ClassificationAttributeType } from "../../hooks/useClassificationAttributes";

export type ClassificationAttributeOptionType = {
  label: string;
  value: ClassificationAttributeType;
};

export function getClassificationAttributeOption(
  classificationAttribute: ClassificationAttributeType
): ClassificationAttributeOptionType {
  const { text } = classificationAttribute;
  return {
    label: text,
    value: classificationAttribute,
  };
}
