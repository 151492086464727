import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { MutationTuple } from "@apollo/client/react/types/types";

import { TaskProgressType } from "../../../../TaskWorklist/TaskProgressType";

const MUTATION = gql`
  mutation UpdateTaskAssignmentProgress($id: Int!, $progress: enum_task_progress_enum = COMPLETED) {
    update_task_assignment(_set: { progress: $progress }, where: { id: { _eq: $id } }) {
      returning {
        id
        progress
      }
    }
  }
`;

type Variables = {
  id: number;
  progress: TaskProgressType;
};

type Data = {
  update_task_assignment: { returning: { id: number; progress: string }[] };
};

export function useUpdateTaskAssignmentProgress(): MutationTuple<Data, Variables> {
  return useMutation<Data, Variables>(MUTATION);
}
