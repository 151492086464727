import React from "react";

import { LesionListItemFragmentType } from "./fragments/LesionListItemFragment";
import { LesionsList, LesionsListOptions } from "./LesionsList";
import { LesionsListContextProvider } from "./LesionsListContextProvider";

interface LesionsListContainerProps {
  options: LesionsListOptions;
  lesions: LesionListItemFragmentType[];
}

export function LesionsListContainer({ options, lesions }: LesionsListContainerProps): JSX.Element {
  const { hideIfEmpty } = options;

  if (hideIfEmpty && lesions.length === 0) {
    return <></>;
  }

  return (
    <LesionsListContextProvider>
      <LesionsList lesions={lesions} options={options} />
    </LesionsListContextProvider>
  );
}
