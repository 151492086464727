import { ParsedMutationWithClientArgs } from "./ParsedMutationWithClientArgs";

export async function parsedMutation<TInput, TOutput, TData, TVariables>({
  client,
  mutation,
  input,
  options,
  parseVariables,
  parseData,
}: ParsedMutationWithClientArgs<TInput, TOutput, TData, TVariables>): Promise<TOutput> {
  const variables = parseVariables(input);

  const { data } = await client.mutate<TData, TVariables>({
    ...options,
    mutation,
    variables,
  });
  if (!data) {
    throw new Error("data null with no error in parsedQuery");
  }

  return parseData(data, input);
}
