import { getDate } from "../../../../common/utils/dateFormatUtils/getDate";
import { RawStudyType } from "../../rawTypes/RawStudyType";
import { StudyType } from "../../types/StudyType";
import { parseSeriesFromQueryResult } from "./parseSeriesFromQueryResult";
import { parseStudyJob } from "./parseStudyJob";

export function parseStudyFromQueryResult(rawStudy: RawStudyType): StudyType {
  const {
    series: rawSeries,
    study_id: studyId,
    study_dicom_id: studyDicomId,
    study_date: rawStudyDate,
    upload_jobs: rawUploadJobs,
    study_instance_uid: studyInstanceUid,
  } = rawStudy;

  const series = rawSeries.map(parseSeriesFromQueryResult);
  const tumourBurdens = series.flatMap((s) => {
    return s.tumourBurdens;
  });
  const tumourResponses = series.flatMap((s) => {
    return s.tumourResponses;
  });
  const survivalPredictions = series.flatMap((s) => {
    return s.survivalPredictions;
  });
  const mortalityRisks = series.flatMap((s) => {
    return s.mortalityRisks;
  });

  const studyDate = getDate(rawStudyDate);

  const uploadJobs = rawUploadJobs.map(parseStudyJob);

  return {
    tumourBurdens,
    tumourResponses,
    survivalPredictions,
    mortalityRisks,
    series,
    studyId,
    studyDicomId,
    studyInstanceUid,
    studyDate,
    uploadJobs,
  };
}
