import { useSelectedTaskAssignment } from "../../../../useSelectedTaskAssignment";
import { TASK_IN_PROGRESS } from "../../../TaskWorklist/TaskProgressType";
import { useUpdateTaskAssignmentProgress } from "../AnnotationPanel/hooks/useUpdateTaskAssignmentProgress";

export function useUpdateTaskInProgress() {
  const [updateTaskAssignmentProgress] = useUpdateTaskAssignmentProgress();
  const taskAssignment = useSelectedTaskAssignment();
  const { id: taskAssignmentId, progress: taskAssignmentProgress } = taskAssignment;

  return async () => {
    if (taskAssignmentProgress !== TASK_IN_PROGRESS) {
      await updateTaskAssignmentProgress({
        variables: {
          id: taskAssignmentId,
          progress: TASK_IN_PROGRESS,
        },
      });
    }
  };
}
