import { ApolloCache, gql } from "@apollo/client";

import { getRoiCacheId } from "../../../../AnnotationPanel/hooks/getRoiCacheId";

export function getContourExistsInCache<T>(
  seriesId: number,
  roiId: number,
  instanceId: number,
  cache: ApolloCache<T>
): boolean {
  const result = cache.readQuery<{
    contours: { id: number; instanceId: number }[];
  }>({
    id: getRoiCacheId(roiId, cache),
    query: gql`
      query GetContourFromCache {
        contours {
          id: contour_id
          instanceId: instance_id
        }
      }
    `,
  });

  if (!result) {
    throw new Error(`No contours found in cache for roi id: ${roiId}`);
  }

  const { contours } = result ?? { contours: [] };

  return contours.some(({ instanceId: contourInstanceId }) => contourInstanceId === instanceId);
}
