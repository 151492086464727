import { ApolloCache } from "@apollo/client/core";

import { deleteEllipseRoiIdInCache } from "../../ViewerPanel/tools/legacy/EllipseToolChangeDetector";
import { RoiListItemFragmentType } from "../fragments/RoiListItemFragment";
import { deleteRoiCornerstoneMeasurements } from "../utils/deleteRoiCornerstoneMeasurements";
import { deleteContoursFromCache } from "./deleteContoursFromCache";
import { deleteEllipseFromCache } from "./deleteEllipseFromCache";
import { deleteRoiClassificationsFromCache } from "./deleteRoiClassificationsFromCache";

export function deleteRoiDataFromCache<T>(
  roi: RoiListItemFragmentType,
  cache: ApolloCache<T>
): void {
  const { id, contours, ellipse, classifications } = roi;
  deleteEllipseRoiIdInCache(id);
  deleteContoursFromCache(id, contours, cache);

  if (ellipse) {
    const { id: ellipseId } = ellipse;
    deleteEllipseFromCache(id, ellipseId, cache);
  }
  deleteRoiCornerstoneMeasurements(roi);
  deleteRoiClassificationsFromCache(id, classifications, cache);
}
