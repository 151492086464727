import { gql } from "@apollo/client/core";

import { USER_FRAGMENT } from "../../../Settings/UserFragment";
import { COHORT_FRAGMENT } from "./CohortFragment";

export const PROJECT_FRAGMENT = gql`
  fragment Project on project {
    id
    name
    disabled
    estimatedCompletion: estimated_completion
    startDate: start_date
    status
    details
    arms: project_arms {
      id
      name
      number: arm_num
    }
    sites: labels(where: { label_type: { name: { _eq: "SITE" } } }) {
      id
      name
      metadata
      type_name
    }
    cohorts: label_projects {
      cohort: label {
        ...Cohort
      }
    }
    users: project_users {
      user {
        ...UserFragment
      }
    }
    projectUserRoles: project_user_roles {
      userId: user_id
      role
    }
    legacyTrialId: legacy_trial_id
  }
  ${COHORT_FRAGMENT}
  ${USER_FRAGMENT}
`;
