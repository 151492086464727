import React, { FC, useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";

import { ActionButtonsWrapper } from "../../../../../common/components/Dialog/Form/ActionButtonsWrapper";
import { FormProps } from "../../../../../common/components/Dialog/FormProps";
import { RED_BUTTON_OVERRIDES } from "../../../../../common/components/Dialog/RedButtonOverrides";
import { useOkCancelForm } from "../../../../../common/components/Dialog/useOkCancelForm";
import { ErrorLabel } from "../../../../../common/components/input/ErrorLabel";
import { Input } from "../../../../../common/components/input/Input";
import { InputButton } from "../../../../../common/components/input/InputButton";
import { Label, SelectLabel } from "../../../../../common/components/input/Label";
import { useCurrentUser } from "../../../../../common/contexts/UserContext/useCurrentUser";
import { main } from "../../../../../common/theme/main";
import { GenericMultiSelect } from "../../../../../DataManagement/Upload/components/GenericMultiSelect";
import { TaskContext } from "../../../../TaskContext";
import { SeriesFlagType } from "../../../../types/SeriesFlagType";
import { useCreateSeriesFlag } from "../AnnotationPanel/hooks/useCreateSeriesFlag";
import { useDeleteSeriesFlag } from "../AnnotationPanel/hooks/useDeleteSeriesFlag";
import { SeriesListItemFragmentType } from "../AnnotationPanel/hooks/useStudiesList";
import { useUpdateSeriesFlag } from "../AnnotationPanel/hooks/useUpdateSeriesFlag";
import { useCanEditSeriesObservations } from "./hooks/useCanEditSeriesObservations";
import { useTaskSeriesFlags } from "./hooks/useTaskSeriesFlags";
import { SeriesDialogHeader } from "./SeriesDialogHeader";
import { formatSeriesDisplayName } from "./utils/formatSeriesDisplayName";

export const REASON_OPTIONS = [
  "Exclude",
  "Invalid Orientation",
  "Thin Slices",
  "Artifacts",
  "Missing Organ(s)",
  "Inconsistent Slice Spacing",
  "Corner Case",
  "Verification Required",
  "Female",
  "Male",
  "Other",
];

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 15px 20px 15px;
  gap: 24px;
  width: 419px;
`;

interface CreateSeriesFlagFormProps {
  series: SeriesListItemFragmentType;
}

export const CreateSeriesFlagForm: FC<FormProps<CreateSeriesFlagFormProps>> = ({
  onSubmit,
  onCancel,
  props: { series },
}: FormProps<CreateSeriesFlagFormProps>) => {
  const { id: seriesId } = series;

  const seriesFlags = useTaskSeriesFlags(series);

  const [createSeriesFlag] = useCreateSeriesFlag();
  const [updateSeriesFlag] = useUpdateSeriesFlag();
  const [deleteSeriesFlag] = useDeleteSeriesFlag();

  const [selectedReasons, setSelectedReasons] = useState<string[]>(
    seriesFlags[0]?.reasons.flatMap(({ reason }) => reason) ?? []
  );

  const isNewFlag = seriesFlags.length === 0;

  const canEdit = useCanEditSeriesObservations(series);

  const handleConfirmDeleteFlag = async () => {
    const { id } = seriesFlags[0];
    await deleteSeriesFlag({
      variables: { id },
    });
    onSubmit();
  };

  const [showConfirmDeleteDialog, { dialog: confirmDeleteDialog }] = useOkCancelForm({
    title: `Delete Series Flag`,
    message: `Are you sure you want to delete the flag for series: ${formatSeriesDisplayName(
      series
    )}?`,
    okLabel: "Confirm",
    onOkCallback: handleConfirmDeleteFlag,
    destructive: true,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<SeriesFlagType>({ defaultValues: seriesFlags[0] });

  const { id: userId } = useCurrentUser();
  const { task } = useContext(TaskContext);

  const handleCancel = () => {
    onCancel();
  };

  const handleDelete = () => {
    showConfirmDeleteDialog(true);
  };

  const handleSubmitForm = async (seriesFlag: SeriesFlagType) => {
    if (isNewFlag) {
      const variables = {
        seriesFlag: {
          created_by: userId,
          task_id: task.id,
          series_id: seriesId,
          notes: seriesFlag.notes === "" ? null : seriesFlag.notes,
          series_flag_reasons: {
            data: selectedReasons.map((value) => ({
              reason: value,
            })),
          },
        },
      };
      await createSeriesFlag({ variables });
    } else {
      const { id, notes } = seriesFlag;
      const reasonsToAdd = selectedReasons
        .filter((value) => {
          return !seriesFlags[0].reasons.map(({ reason }) => reason).includes(value);
        })
        .map((value) => ({ reason: value, series_flag_id: id }));
      const reasonsToRemove = seriesFlags[0].reasons
        .filter(({ reason }) => {
          return !selectedReasons.includes(reason);
        })
        .map(({ id }) => id);
      await updateSeriesFlag({
        variables: {
          id,
          notes: notes === "" ? null : notes,
          created_by: userId,
          insertReasons: reasonsToAdd,
          deleteReasonIds: reasonsToRemove,
        },
      });
    }

    onSubmit();
  };

  const handleReasonChanged = (reasons: string[]) => {
    setSelectedReasons(reasons);
    return reasons;
  };

  return (
    <form onSubmit={handleSubmit(handleSubmitForm)}>
      {confirmDeleteDialog}
      <Wrapper>
        <SeriesDialogHeader series={series} />
        <Controller
          control={control}
          name="reasons"
          rules={{ required: true }}
          render={({ field: { onChange } }) => (
            <div>
              <SelectLabel htmlFor={"reason"} required error={errors.reasons}>
                Reason
              </SelectLabel>
              <GenericMultiSelect
                autoFocus={true}
                isDisabled={!canEdit}
                id={"reason"}
                values={REASON_OPTIONS}
                selected={selectedReasons}
                onSelectedChanged={(e) => onChange(handleReasonChanged(e))}
                getOption={(reason) => ({
                  value: reason,
                  label: reason,
                })}
                menuPortalTarget={document.body}
              />
              {errors.reasons && <ErrorLabel>A reason must be selected</ErrorLabel>}
            </div>
          )}
        />
        <div>
          <Label
            htmlFor={"flag-notes"}
            required={selectedReasons.includes("Other")}
            error={errors.notes}
          >
            Notes
          </Label>
          <Input
            readOnly={!canEdit}
            disabled={!canEdit}
            error={errors.notes}
            type="text"
            id={"flag-notes"}
            autoComplete={"off"}
            {...register("notes", {
              required: selectedReasons.includes("Other"),
            })}
          />
          {errors.notes && (
            <ErrorLabel>Notes must be provided when "Other" is specified</ErrorLabel>
          )}
        </div>
      </Wrapper>
      <ActionButtonsWrapper>
        {canEdit && <InputButton type="submit" name="submit-button" value={"Confirm"} />}
        <InputButton
          type="button"
          name="cancel-button"
          value={"Cancel"}
          background={main.colors.neutral.white}
          color={main.colors.neutral.black}
          onClick={handleCancel}
        />
        {!isNewFlag && canEdit && (
          <InputButton
            type="button"
            name="delete-button"
            value={"Delete"}
            {...RED_BUTTON_OVERRIDES}
            color={main.colors.neutral.white}
            onClick={handleDelete}
          />
        )}
      </ActionButtonsWrapper>
    </form>
  );
};
