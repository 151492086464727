import React from "react";
import styled from "styled-components";

import { ContentDialogProps } from "./ContentDialogProps";
import { DialogActionButtons } from "./DialogActionButtons";
import { DialogHeader } from "./DialogHeader";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
`;

export const ContentsWrapper = styled.div`
  flex-grow: 1;
  padding: 12px;
`;

export const OuterHeaderWrapper = styled.div`
  flex-shrink: 0;
`;

const FooterWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  padding: 12px;
`;

interface DialogContentsProps extends ContentDialogProps {
  onClose: () => void;
}

export function DialogContents({
  label,
  content,
  options,
  onClose,
}: DialogContentsProps): JSX.Element {
  const { showCloseButton, closeOnOk = true, closeOnCancel = true, actionButtonOptions } = options;

  const handleOk = () => {
    if (!closeOnOk) {
      return;
    }

    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  const handleCancel = () => {
    if (!closeOnCancel) {
      return;
    }

    onClose();
  };

  return (
    <Wrapper>
      <DialogHeader label={label} showCloseButton={showCloseButton} onClose={handleClose} />

      <ContentsWrapper>{content}</ContentsWrapper>

      <OuterHeaderWrapper>
        {actionButtonOptions && (
          <FooterWrapper>
            <DialogActionButtons
              options={actionButtonOptions}
              onOk={handleOk}
              onCancel={handleCancel}
            />
          </FooterWrapper>
        )}
      </OuterHeaderWrapper>
    </Wrapper>
  );
}
