import React, { useContext } from "react";
import styled from "styled-components";

import { PatientContext } from "../../../../../Dashboard/components/Settings/Patients/Viewer/PatientContext";
import { TaskContext } from "../../../../TaskContext";
import { TaskLabel } from "../../../TaskWorklist/TaskLabel";
import { SeriesListItemFragmentType } from "../AnnotationPanel/hooks/useStudiesList";
import { formatSeriesDisplayName } from "./utils/formatSeriesDisplayName";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  overflow: hidden;
`;

const Text = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Bold = styled.span`
  font-weight: bold;
`;

interface SeriesDialogHeaderProps {
  series: SeriesListItemFragmentType;
}

export function SeriesDialogHeader({ series }: SeriesDialogHeaderProps): JSX.Element {
  const { task } = useContext(TaskContext);
  const { patientDicomId } = useContext(PatientContext);

  const seriesDisplayName = formatSeriesDisplayName(series);

  return (
    <Wrapper>
      <Text>
        Subject ID: <Bold>{patientDicomId}</Bold>
      </Text>
      <Text>
        Series Description: <Bold>{seriesDisplayName}</Bold>
      </Text>
      <TaskLabel task={task} />
    </Wrapper>
  );
}
