import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useLocation } from "react-router";

export function useHandleLogin(): boolean {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!isAuthenticated) {
      if (isLoading) {
        return;
      }

      loginWithRedirect({ appState: { pathname } }).then(() => undefined);
    }
  }, [isAuthenticated, isLoading]);

  return isAuthenticated && !isLoading;
}
