import {
  TUMOUR_HU,
  TUMOUR_LAD,
  TUMOUR_SAD,
  TUMOUR_TIMEPOINT_BURDEN,
  TUMOUR_VOLUME,
} from "../../../../../enums/TaskDescriptionEnums";
import { StatsType } from "../../../../../types/TaskDescriptionType";

export function getAllTimepointStatTypes() {
  return {
    headerStats: [TUMOUR_TIMEPOINT_BURDEN] as StatsType[],
    bodyStats: [TUMOUR_LAD, TUMOUR_SAD, TUMOUR_VOLUME, TUMOUR_HU] as StatsType[],
  };
}

export function orderAndGroupStats(stats: StatsType[] | undefined) {
  if (!stats) {
    return { headerStats: [], bodyStats: [] };
  }

  const { headerStats: allHeaderStats, bodyStats: allBodyStats } = getAllTimepointStatTypes();

  const headerStats = allHeaderStats.filter((stat) => stats.includes(stat));

  const bodyStats = allBodyStats.filter((stat) => stats.includes(stat));

  return { headerStats, bodyStats };
}
