import {
  LESION_CLASSIFICATION,
  OBJECT_LABELLING,
  ORGAN_SEGMENTATION,
  QUALITY_CONTROL_TASK,
  RECIST_1_1,
  SERIES_CLASSIFICATION,
} from "../../../../enums/TaskDescriptionEnums";
import { WizardSettingsType } from "../../CreateTaskWizard/CreateTaskWizard";
import { CreateTaskType, NEW_TASK, TASK_TEMPLATE } from "./CreateTaskType";
import { TaskDescriptionTypeType } from "./TaskDescriptionTypeType";
import { DETECTION, QUALITY_CONTROL, SEGMENTATION, TaskTemplateType } from "./TaskTemplateType";

export type TaskTemplate = {
  label: string;
  type: CreateTaskType;
  taskDescriptionType: TaskDescriptionTypeType;
  wizardSettings: WizardSettingsType;
};

export const taskTemplates: Record<TaskTemplateType, TaskTemplate> = {
  [RECIST_1_1]: {
    label: "Recist 1.1",
    type: TASK_TEMPLATE,
    taskDescriptionType: RECIST_1_1,
    wizardSettings: {
      showAnatomySelection: false,
      showInteractionOptions: false,
      enableSegmentation: false,
      enableDetection: false,
      allowUserDefinedLabels: false,
      showClassificationSelection: false,
      allowUserDefinedClassifications: false,
      showSeriesClassificationOptions: false,
      showLimitToBaselineOption: false,
      showQualityControlRuleSetOption: false,
    },
  },
  [LESION_CLASSIFICATION]: {
    label: "Lesion Annotation",
    type: TASK_TEMPLATE,
    taskDescriptionType: LESION_CLASSIFICATION,
    wizardSettings: {
      showAnatomySelection: false,
      showInteractionOptions: false,
      enableSegmentation: false,
      enableDetection: false,
      allowUserDefinedLabels: false,
      showClassificationSelection: false,
      allowUserDefinedClassifications: false,
      showSeriesClassificationOptions: false,
      showLimitToBaselineOption: true,
      showQualityControlRuleSetOption: false,
    },
  },
  [ORGAN_SEGMENTATION]: {
    label: "Organ Segmentation",
    type: TASK_TEMPLATE,
    taskDescriptionType: ORGAN_SEGMENTATION,
    wizardSettings: {
      showAnatomySelection: true,
      showInteractionOptions: false,
      enableSegmentation: false,
      enableDetection: false,
      allowUserDefinedLabels: false,
      showClassificationSelection: false,
      allowUserDefinedClassifications: false,
      showSeriesClassificationOptions: false,
      showLimitToBaselineOption: true,
      showQualityControlRuleSetOption: false,
    },
  },
  [SERIES_CLASSIFICATION]: {
    label: "Series Classification",
    type: TASK_TEMPLATE,
    taskDescriptionType: SERIES_CLASSIFICATION,
    wizardSettings: {
      showAnatomySelection: false,
      showInteractionOptions: false,
      enableSegmentation: false,
      enableDetection: false,
      allowUserDefinedLabels: false,
      showClassificationSelection: true,
      allowUserDefinedClassifications: true,
      showSeriesClassificationOptions: true,
      showLimitToBaselineOption: true,
      showQualityControlRuleSetOption: false,
    },
  },
  [SEGMENTATION]: {
    label: "Segmentation",
    type: NEW_TASK,
    taskDescriptionType: OBJECT_LABELLING,
    wizardSettings: {
      showAnatomySelection: true,
      showInteractionOptions: false,
      enableSegmentation: true,
      enableDetection: false,
      allowUserDefinedLabels: true,
      showClassificationSelection: false,
      allowUserDefinedClassifications: false,
      showSeriesClassificationOptions: false,
      showLimitToBaselineOption: true,
      showQualityControlRuleSetOption: false,
    },
  },
  [DETECTION]: {
    label: "Detection",
    type: NEW_TASK,
    taskDescriptionType: OBJECT_LABELLING,
    wizardSettings: {
      showAnatomySelection: true,
      showInteractionOptions: false,
      enableSegmentation: false,
      enableDetection: true,
      allowUserDefinedLabels: true,
      showClassificationSelection: false,
      allowUserDefinedClassifications: false,
      showSeriesClassificationOptions: false,
      showLimitToBaselineOption: true,
      showQualityControlRuleSetOption: false,
    },
  },
  [QUALITY_CONTROL]: {
    label: "Quality Control",
    type: NEW_TASK,
    taskDescriptionType: QUALITY_CONTROL_TASK,
    wizardSettings: {
      showAnatomySelection: false,
      showInteractionOptions: false,
      enableSegmentation: false,
      enableDetection: false,
      allowUserDefinedLabels: false,
      showClassificationSelection: false,
      allowUserDefinedClassifications: false,
      showSeriesClassificationOptions: false,
      showLimitToBaselineOption: false,
      showQualityControlRuleSetOption: true,
    },
  },
};
