import { PatientRouteProvider } from "nota-predict-web/src/common/components/Link";
import { FC, useEffect } from "react";

import { Card } from "../../../common/components/cards/Card";
import { StudyTableColumnType } from "../../../common/components/StudyTable/StudyTableColumns";
import { StudyTableManager } from "../../../common/components/StudyTable/StudyTableManager";
import { useStudyRowsFromSubjects } from "../../../common/components/StudyTable/useStudyRowsFromSubjects";
import { seriesSelectionActions } from "../../../common/store/seriesSelectionSlice";
import { useAppDispatch } from "../../../common/store/store";
import { LinkToPatientProps } from "../../../Project/Layout/hooks/usePatientLinkHandler";
import { useFilteredSubjectsQuery } from "../hooks/useFilteredSubjectsQuery";

interface StudyTableCardProps extends LinkToPatientProps {
  disablePatientFiltering?: boolean;
  hiddenColumns?: StudyTableColumnType[];
  excludeTempDemoPatients?: boolean;
}

export const StudyTableCard: FC<StudyTableCardProps> = ({
  disablePatientFiltering = false,
  hiddenColumns = [],
  patientRoute,
}: StudyTableCardProps) => {
  const tableKey = "StudyTableCard";

  const dispatch = useAppDispatch();

  const subjects = useFilteredSubjectsQuery(!disablePatientFiltering);

  const { studyRows, loading } = useStudyRowsFromSubjects(subjects);

  useEffect(() => {
    dispatch(seriesSelectionActions.setStudyRows({ tableKey, studyRows, loading }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studyRows, loading]);

  useEffect(() => {
    return () => {
      dispatch(seriesSelectionActions.clearStudyRows({ tableKey }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PatientRouteProvider patientRoute={patientRoute}>
      <Card
        title={"Subjects"}
        content={
          <StudyTableManager
            tableKey={tableKey}
            hiddenColumns={hiddenColumns}
            enableSelect={true}
            enableExpand={true}
          />
        }
      />
    </PatientRouteProvider>
  );
};
