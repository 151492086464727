import React from "react";
import styled from "styled-components";

import { InnerButtonWrapper } from "../../../../common/components/input/InputButton";
import { main } from "../../../../common/theme/main";
import { UpdateModeIcon } from "../../../../common/types/UpdateModeIcon";
import { updateModeColors, UpdateModeType } from "../../../../common/types/UpdateModeType";

const OuterWrapper = styled.div`
  display: inline-block;
`;

const Wrapper = styled.div<{ background: string }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 8px;
  background: ${(props) => props.background};
  border-radius: 4px;
`;

const Text = styled.div<{ color: string }>`
  font-style: normal;
  font-size: 12px;
  line-height: 12px;
  color: ${(props) => props.color};
`;

interface SelectLabelProps {
  name: string;
  state?: UpdateModeType;
  formatName?: (name: string) => JSX.Element;
}

export function SelectLabel({
  name,
  state,
  formatName = (name: string) => <>{name}</>,
}: SelectLabelProps): JSX.Element {
  const color = state ? updateModeColors[state] : main.colors.neutral.neutral4;
  return (
    <OuterWrapper>
      <Wrapper background={color}>
        <InnerButtonWrapper>
          {state && <UpdateModeIcon mode={state} color={main.colors.neutral.white} size={14} />}
          <Text color={"white"}>{formatName(name)}</Text>
        </InnerButtonWrapper>
      </Wrapper>
    </OuterWrapper>
  );
}
