import { RawPatientDemographic } from "../../rawTypes/RawPatientDemographic";
import { getEthnicityType } from "../../types/EthnicityType";
import { PatientDemographicType } from "../../types/PatientDemographicType";

export function parsePatientDemographicFromQueryResults(
  rawPatientDemographic: RawPatientDemographic
): PatientDemographicType {
  if (!rawPatientDemographic) {
    return {
      bmi: null,
      sex: null,
      dob: null,
      ethnicity: null,
      ecogPerformanceStatus: null,
    };
  }

  const {
    bmi,
    dob,
    ecog_performance_status: ecogPerformanceStatus,
    ethnicity: rawEthnicity,
    sex,
  } = rawPatientDemographic;

  const ethnicity = rawEthnicity !== null ? getEthnicityType(rawEthnicity) : null;

  return { bmi, dob, ecogPerformanceStatus, ethnicity, sex };
}
