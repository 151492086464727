import React, { FC, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";

import { AnatomicalStructuresFragmentType } from "../../../../Annotate/components/AnatomicalStructuresFragment";
import { getNewAnatomicalStructure } from "../../../../Annotate/components/Annotate/page/AnnotationPanel/AnatomicalStructureSelector/getNewAnatomicalStructure";
import { validateAnatomicalStructure } from "../../../../Annotate/components/Annotate/page/AnnotationPanel/AnatomicalStructureSelector/validateAnatomicalStructure";
import { DEFAULT_ANNOTATION_COLOR } from "../../../../Annotate/components/Annotate/page/AnnotationPanel/utils/useLesionColorDeferred";
import { useAnatomicalStructures } from "../../../../Annotate/components/useAnatomicalStructures";
import { ActionButtonsWrapper } from "../../../../common/components/Dialog/Form/ActionButtonsWrapper";
import { FormProps } from "../../../../common/components/Dialog/FormProps";
import { ErrorLabel } from "../../../../common/components/input/ErrorLabel";
import { Input } from "../../../../common/components/input/Input";
import { InputButton } from "../../../../common/components/input/InputButton";
import { Label } from "../../../../common/components/input/Label";
import {
  getInsertAnatomicalStructuresVariables,
  useInsertAnatomicalStructures,
} from "../../../../common/components/StudyTable/useInsertAnatomicalStructures";
import { useUpdateAnatomicalStructure } from "../../../../common/components/StudyTable/useUpdateAnatomicalStructure";
import { main } from "../../../../common/theme/main";
import { ColorPicker } from "./ColorPicker";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 24px 15px;
  gap: 24px;
  width: 419px;
`;

export const ColorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  gap: 12px;
  font-size: 12px;
  padding-left: 12px;
`;

export interface CreateAnnotationFormProps {
  annotation: AnatomicalStructuresFragmentType | undefined;
}

export const CreateAnnotationForm: FC<FormProps<CreateAnnotationFormProps>> = ({
  onSubmit,
  onCancel,
  onSetTitle,
  props: { annotation },
}: FormProps<CreateAnnotationFormProps>) => {
  const isNewLabel = !annotation;

  useEffect(() => {
    onSetTitle?.(isNewLabel ? "Create Annotation" : "Edit Annotation");
  }, [isNewLabel]);

  const { readonly } = annotation ?? { readonly: false };

  const { data: anatomicalStructuresData = [] } = useAnatomicalStructures();

  const [insertAnatomicalStructures] = useInsertAnatomicalStructures();

  const [updateAnatomicalStructure] = useUpdateAnatomicalStructure();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<AnatomicalStructuresFragmentType>({
    defaultValues: annotation ?? getNewAnatomicalStructure("", ""),
  });

  const handleCancel = () => {
    onCancel();
  };

  const handleSubmitForm = async (label: AnatomicalStructuresFragmentType) => {
    if (isNewLabel) {
      const structure = label.name.toLowerCase().replace(/[-\s]+/g, "_");

      const variables = getInsertAnatomicalStructuresVariables([{ ...label, structure }]);
      await insertAnatomicalStructures({ variables });
    } else {
      const { id, name, color } = label;
      await updateAnatomicalStructure({
        variables: { id, name, color: color },
      });
    }
    onSubmit();
  };

  const validateName = (value: string) => {
    const anatomicalStructures = anatomicalStructuresData ?? [];
    const otherAnatomicalStructures = anatomicalStructures.filter(
      ({ id }) => id !== annotation?.id
    );
    return validateAnatomicalStructure(value, otherAnatomicalStructures);
  };

  return (
    <form onSubmit={handleSubmit(handleSubmitForm)}>
      <Wrapper>
        <div>
          <Label htmlFor={"label-name"} required error={errors.name}>
            Annotation Name
          </Label>
          <Input
            error={errors.name}
            type="text"
            id={"label-name"}
            autoFocus
            autoComplete={"off"}
            disabled={readonly}
            {...register("name", {
              required: true,
              validate: validateName,
            })}
          />
          {errors.name && (
            <ErrorLabel>
              Unique annotation name must be provided (Spaces, hyphens and underscores are treated
              as equivalent)
            </ErrorLabel>
          )}
        </div>
        <ColorWrapper>
          Color:
          <Controller
            name="color"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <ColorPicker color={value ?? DEFAULT_ANNOTATION_COLOR} onColorChanged={onChange} />
            )}
          />
        </ColorWrapper>
      </Wrapper>

      <ActionButtonsWrapper>
        <InputButton type="submit" name="submit-button" value={"Confirm"} />
        <InputButton
          type="button"
          name="cancel-button"
          value={"Cancel"}
          background={main.colors.neutral.white}
          color={main.colors.neutral.black}
          onClick={handleCancel}
        />
      </ActionButtonsWrapper>
    </form>
  );
};
