import { ApolloClient } from "@apollo/client";
import Ajv2020 from "ajv/dist/2020";

import { findEnabledOption } from "../../../../../../common/store/annotatePage/utils/findEnabledOption";
import { ELLIPTICAL_ROI, MEASURE, ROI } from "../../../../../../cornerstone/ToolType";
import {
  ANNOTATE_INCLUDE_OTHER_LESIONS,
  ANNOTATE_SHOW_PREDICTED_ROIS,
} from "../../../../../enums/TaskDescriptionEnums";
import { parseBaseWadoUrl } from "../../../../../parsers/parseBaseWadoUrl";
import { ParsedLesionType, parseLesion } from "../../../../../parsers/parseLesion";
import { parseStudy } from "../../../../../parsers/parseStudy";
import { InstanceType } from "../../../../../types/InstanceType";
import { TaskDescriptionType } from "../../../../../types/TaskDescriptionType";
import { loadTools } from "../../ViewerPanel/tools/legacy/LesionToolLoader";
import { clearToolSliceData } from "../../ViewerPanel/tools/legacy/ToolUtils";
import { getInstances } from "../../ViewerPanel/utils/getInstances";
import { default as schema } from "../schema/task.schema.json";
import { getTaskDescription } from "./useLazyTaskDescription";
import { getToolData } from "./useLazyTaskToolData";
import { getStudiesList } from "./useStudiesList";

export function unloadTask(): void {
  console.debug("Unloading task data");
  clearToolSliceData(ROI);
  clearToolSliceData(ELLIPTICAL_ROI);
  clearToolSliceData(MEASURE);
}

type Result = {
  taskDescription: TaskDescriptionType;
  lesions: ParsedLesionType[];
  instances: InstanceType[];
};

type TaskInputType = {
  id: number;
  task: { id: number; projectId: number; restrictedCohorts: { id: number }[] };
};

export function validateTaskDescription(taskDescriptionCheck: TaskDescriptionType) {
  const ajv = new Ajv2020();
  const validate = ajv.compile(schema);

  const isTaskDescriptionValid = validate(taskDescriptionCheck);
  if (!isTaskDescriptionValid) {
    throw new Error("TaskDescription validation failed");
  }
}

export async function loadTask(
  client: ApolloClient<unknown>,
  taskContext: TaskInputType,
  onProgress: (progress: string) => void
): Promise<Result> {
  const progressReporter = new ProgressReporter("loadTask", onProgress, 500);

  const taskDescription = await getTaskDescription(client, taskContext.task.id);

  progressReporter.report("Validating task...");
  validateTaskDescription(taskDescription);

  const includeOtherLesions =
    findEnabledOption(taskDescription, ANNOTATE_INCLUDE_OTHER_LESIONS) !== undefined;

  progressReporter.report("Loading studies...");
  // TODO: Maybe this should be a hook here not an async function because the query is being recreated
  // on every render? --B
  // See #184924509
  const { studies, wadoUrls } = await getStudiesList(client, taskContext);

  progressReporter.report("Loading annotations...");
  const lesions = await getToolData(
    client,
    taskContext,
    includeOtherLesions,
    false // we don't need to pull any tool specific data for predicted lesions, so don't include them here
  );

  const baseWadoUrl = parseBaseWadoUrl(wadoUrls);
  const parsedStudies = studies.map((study) => parseStudy(study, baseWadoUrl));

  const instances = getInstances(parsedStudies);

  const parsedLesions = lesions.map((lesion) => {
    return parseLesion(lesion, baseWadoUrl);
  });

  progressReporter.report("Loading viewers...");
  loadTools(parsedLesions, instances);

  progressReporter.finish();

  return {
    lesions: parsedLesions,
    instances,
    taskDescription,
  };
}

export class ProgressReporter {
  private lastTime: number;
  private lastProgress: string;
  private readonly label: string;
  private readonly onProgress?: (progress: string) => void;
  private readonly minTimeToLog: number;

  constructor(label: string, onProgress?: (progress: string) => void, minTimeToLog = 0) {
    this.lastTime = performance.now();
    this.lastProgress = "Initialized";
    this.label = label;
    this.onProgress = onProgress;
    this.minTimeToLog = minTimeToLog;
  }

  private logProgress(progress: string) {
    const endTime = performance.now();
    const roundedElapsed = Math.round((endTime - this.lastTime) * 100) / 100;

    let color = "";
    if (roundedElapsed < 100) {
      color = "color: #666";
    } else if (roundedElapsed < 1000) {
      color = "color: #aaa";
    } else if (roundedElapsed < 10000) {
      color = "color: #facc15";
    } else {
      color = "color: #e94375";
    }

    if (roundedElapsed > this.minTimeToLog) {
      console.debug(`%c[${this.label}]: ${this.lastProgress} took ${roundedElapsed} ms`, color);
    }

    this.lastProgress = progress;
    this.lastTime = endTime;
  }

  report(progress: string) {
    this.onProgress?.(progress);
    this.logProgress(progress);
  }

  finish() {
    this.logProgress("Done");
  }
}
