import React from "react";
import styled from "styled-components";

import { DialogContentInnerWrapper } from "../../Dialog/DialogContentInnerWrapper";
import { useDialog, useDialogReturnType } from "../../Dialog/useDialog";
import { AnonymizationMethodsCard } from "./AnonymizationMethodsCard";

const Wrapper = styled(DialogContentInnerWrapper)`
  width: 600px;
  overflow: visible;
`;

export function useAnonymizationDialog(): useDialogReturnType {
  const label = "Anonymization";

  const content = (
    <Wrapper>
      <AnonymizationMethodsCard />
    </Wrapper>
  );

  return useDialog({
    label,
    content,
    options: {
      showCloseButton: true,
    },
  });
}
