import { ToolConfigurationType } from "../../../../../../../cornerstone/ToolConfigurationType";
import { ACTIVE } from "../../../../../../../cornerstone/ToolModeType";
import {
  BRUSH,
  ELLIPTICAL_PROBE,
  ELLIPTICAL_ROI,
  FREEHAND_SCISSORS,
  MEASURE,
  PAN,
  PAN_MULTI_TOUCH,
  ROI,
  ROI_SCULPTOR,
  SCROLL_MOUSE_WHEEL,
  SCROLL_MULTI_TOUCH,
  WINDOWLEVEL,
  ZOOM,
  ZOOM_TOUCH_PINCH,
} from "../../../../../../../cornerstone/ToolType";

export const defaultTools: ToolConfigurationType[] = [
  {
    name: WINDOWLEVEL,
    mode: ACTIVE,
    modeOptions: { mouseButtonMask: 1 },
  },
  {
    name: ZOOM,
    mode: ACTIVE,
    modeOptions: { mouseButtonMask: 2 },
  },
  {
    name: PAN,
    mode: ACTIVE,
    modeOptions: { mouseButtonMask: 4 },
  },
  {
    name: MEASURE,
    mode: ACTIVE,
    modeOptions: { mouseButtonMask: 1 },
  },
  {
    name: ELLIPTICAL_ROI,
    mode: ACTIVE,
    modeOptions: { mouseButtonMask: 1 },
  },
  {
    name: ELLIPTICAL_PROBE,
    mode: ACTIVE,
    modeOptions: { mouseButtonMask: 1 },
  },
  {
    name: ROI,
    mode: ACTIVE,
    modeOptions: { mouseButtonMask: 1 },
    props: {
      configuration: { alwaysShowHandles: true },
    },
  },
  {
    name: ROI_SCULPTOR,
    mode: ACTIVE,
    modeOptions: { mouseButtonMask: 1 },
    props: {
      configuration: {
        minSpacing: 0.025,
        dragColor: "rgb(20, 20, 255)",
        hoverColor: "rgb(0, 0, 255)",
      },
    },
  },
  // Scroll
  { name: SCROLL_MOUSE_WHEEL, mode: ACTIVE },
  // // Touch
  { name: PAN_MULTI_TOUCH, mode: ACTIVE },
  { name: ZOOM_TOUCH_PINCH, mode: ACTIVE },
  { name: SCROLL_MULTI_TOUCH, mode: ACTIVE },
  { name: BRUSH, mode: ACTIVE, modeOptions: { mouseButtonMask: 1 } },
  {
    name: FREEHAND_SCISSORS,
    mode: ACTIVE,
    modeOptions: { mouseButtonMask: 1 },
  },
];
