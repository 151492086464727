import React from "react";
import styled from "styled-components";

import { ReactComponent as CheckIcon } from "../../../../../../../../assets/svgs/CheckFilled.svg";
import { ReactComponent as ErrorIcon } from "../../../../../../../../assets/svgs/Error.svg";
import { SvgIcon } from "../../../../../../../../common/components/icons/SvgIcon";
import { getStatusColorType } from "../../../../../../../../common/types/StatusColorTypes";
import { SUCCESS } from "../../../../../../../../common/types/StatusTypes";
import {
  LesionTaskCheckResultComponentProps,
  TaskCheckResultComponentProps,
} from "../types/SaveCheckResultComponentProps";
import { LesionTaskCheckResult, TaskCheckResult } from "../types/SaveCheckResultType";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
`;

const Message = styled.div`
  flex: 1;
  text-align: left;
`;

const ActionsWrapper = styled.div`
  flex: 0;
  display: flex;
  flex-direction: row;
  gap: 6px;
`;

interface TaskCheckRowProps<TResult> extends TaskCheckResult<TResult> {
  onRequestClose: () => void;
  actions: (args: TaskCheckResultComponentProps<unknown>) => JSX.Element;
}

export function TaskCheckRow<TResult>(result: TaskCheckRowProps<TResult>): JSX.Element {
  const { actions: Actions, status, message } = result;
  const statusColor = getStatusColorType(status);
  const icon = status === SUCCESS ? CheckIcon : ErrorIcon;
  return (
    <Wrapper>
      <SvgIcon icon={icon} size={24} color={statusColor} />
      <Message>{message}</Message>
      {Actions && (
        <ActionsWrapper>
          <Actions {...result} />
        </ActionsWrapper>
      )}
    </Wrapper>
  );
}

interface LesionCheckRowProps<TResult> extends LesionTaskCheckResult<TResult> {
  onRequestClose: () => void;
  actions: (args: LesionTaskCheckResultComponentProps<unknown>) => JSX.Element;
  hideActions: boolean;
}

export function LesionCheckRow<TResult>(props: LesionCheckRowProps<TResult>): JSX.Element {
  const { actions: Actions, status, message, hideActions } = props;
  const statusColor = getStatusColorType(status);
  const icon = status === SUCCESS ? CheckIcon : ErrorIcon;
  return (
    <Wrapper>
      <SvgIcon icon={icon} size={24} color={statusColor} />
      <Message>{message}</Message>
      {!hideActions && Actions && (
        <ActionsWrapper>
          <Actions {...props} />
        </ActionsWrapper>
      )}
    </Wrapper>
  );
}
