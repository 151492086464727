import React, { useContext, useEffect, useState } from "react";
import Select, { ValueType } from "react-select";
import styled from "styled-components";

import { useUsers } from "../../../../Settings/useUsers";
import { useCurrentUserCan } from "../../../contexts/UserContext/useCurrentUserCan";
import {
  GLOBAL_ROLE_SOFTWARE_MANAGER,
  GlobalRoleType,
  UserRoleType,
} from "../../../types/UserRoleType";
import { UserType } from "../../../types/UserType";
import { OK_CANCEL } from "../../Dialog/DialogActionButtonsType";
import { DialogContentInnerWrapper } from "../../Dialog/DialogContentInnerWrapper";
import { DialogText } from "../../Dialog/DialogText";
import { useDialog, useDialogReturnType } from "../../Dialog/useDialog";
import { getSelectStyle } from "../../input/getSelectStyle";
import { SelectLabel } from "../../input/Label";
import { RoleLabel } from "../RoleLabel";
import { RoleSelectOptionType } from "../types/RoleSelectOptionType";
import { UserManagementContext } from "../UserManagementContext";
import { UserSelect } from "../UserSelect";
import { getGlobalAndProjectRoles } from "../utils/getGlobalAndProjectRoles";
import { getNewUsersByEmail } from "../utils/getNewUsersByEmail";
import { useAllRoleOptions } from "../utils/useAllRoleOptions";

const Wrapper = styled(DialogContentInnerWrapper)`
  width: 372px;
  overflow: visible;
`;

export function useCreateUserDialog(newUsers: UserType[]): useDialogReturnType {
  const {
    rules: { showProjectRoles, showGlobalRoles, allowCreateUser },
    onCreated,
  } = useContext(UserManagementContext);

  const [selectedUsers, setSelectedUsers] = useState<UserType[]>([]);
  const [availableUsers, setAvailableUser] = useState<UserType[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<(UserRoleType | GlobalRoleType)[]>([]);

  const canCreateUsers = useCurrentUserCan([GLOBAL_ROLE_SOFTWARE_MANAGER]) && allowCreateUser;
  const { data: users, loading } = useUsers();

  const { projectRoleOptions, globalRoleOptions } = useAllRoleOptions();

  useEffect(() => {
    if (!users || loading) {
      setAvailableUser([]);
      return;
    }

    const newUsersByEmail = getNewUsersByEmail(users, newUsers);
    setAvailableUser(newUsersByEmail);
  }, [newUsers, loading]);

  const handleRoleChanged = (value: ValueType<RoleSelectOptionType, true>) => {
    if (!value) {
      return;
    }

    const roles = value.map(({ value }) => value);

    setSelectedRoles(roles);
  };

  const formatRoleOptionLabel = ({ value }: RoleSelectOptionType) => <RoleLabel role={value} />;

  const label = "Add Team Member";

  const selectStyle = getSelectStyle<RoleSelectOptionType, true>();

  const userSelectPlaceholder = canCreateUsers
    ? "Select or invite team members..."
    : "Select team members...";

  const content = (
    <Wrapper>
      <div>
        <SelectLabel htmlFor={"users"}>Users</SelectLabel>
        <UserSelect
          users={availableUsers}
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
          isCreatable={canCreateUsers}
          placeholder={userSelectPlaceholder}
        />
      </div>
      {showProjectRoles && (
        <div>
          <SelectLabel htmlFor={"projectRoles"}>Roles</SelectLabel>
          <Select
            id={"projectRoles"}
            styles={selectStyle}
            isClearable={false}
            isSearchable={true}
            options={projectRoleOptions}
            formatOptionLabel={formatRoleOptionLabel}
            onChange={handleRoleChanged}
            menuPortalTarget={document.body}
            isMulti
          />
        </div>
      )}
      {showGlobalRoles && (
        <div>
          <SelectLabel htmlFor={"globalRoles"}>Roles</SelectLabel>
          <Select
            id={"globalRoles"}
            styles={selectStyle}
            isClearable={false}
            isSearchable={true}
            options={globalRoleOptions}
            formatOptionLabel={formatRoleOptionLabel}
            onChange={handleRoleChanged}
            menuPortalTarget={document.body}
            isMulti
          />
        </div>
      )}
      {allowCreateUser && (
        <DialogText>
          Once confirmed, the user will receive an email with details of how to reset your password.
        </DialogText>
      )}
    </Wrapper>
  );

  const handleOnDialogOpened = () => {
    setSelectedUsers([]);
    setSelectedRoles([]);
  };

  const handleConfirmCreateUser = () => {
    const { globalRoles, projectRoles } = getGlobalAndProjectRoles(selectedRoles);

    const selectedUsersWithRoles = selectedUsers.map((user) => ({
      ...user,
      globalRoles,
      projectRoles,
    }));

    if (!onCreated) {
      throw new Error("User onCreated not supplied in context where users can be created");
    }
    onCreated(selectedUsersWithRoles);
  };

  return useDialog({
    label,
    content,
    options: {
      showCloseButton: false,
      onOpened: handleOnDialogOpened,
      actionButtonOptions: {
        type: OK_CANCEL,
        okText: "Add",
        onOkOverride: handleConfirmCreateUser,
      },
    },
  });
}
