import { gql } from "@apollo/client";
import { FieldFunctionOptions, TypePolicies } from "@apollo/client/cache/inmemory/policies";

import { BASE_WADO_URL_FRAGMENT } from "../../Annotate/fragments/BaseWadoUrlFragment";
import { BaseWadoUrlFragmentType } from "../../Annotate/fragments/BaseWadoUrlFragmentType";
import { getWadoUrl } from "../../Annotate/parsers/getWadoUrl";
import { parseBaseWadoUrl } from "../../Annotate/parsers/parseBaseWadoUrl";

export const instanceWadoUrlTypePolicy: TypePolicies = {
  instance: {
    fields: {
      wadoUrl: {
        read(wadoUrl: string, { readField, cache }: FieldFunctionOptions): undefined | string {
          const sopInstanceUid = readField<string>("sop_instance_uid");
          if (!sopInstanceUid) {
            return undefined;
          }

          const seriesId = readField<number>("series_id");
          const series = cache.readQuery<{
            seriesInstanceUid: string;
            studyId: number;
          }>({
            query: gql`
              query GetSeries {
                seriesInstanceUid: series_instance_uid
                studyId: study_id
              }
            `,
            id: `series:${seriesId}`,
          });
          if (!series) {
            return undefined;
          }
          const { seriesInstanceUid, studyId } = series;
          const study = cache.readQuery<{
            studyInstanceUid: string;
          }>({
            query: gql`
              query GetStudy {
                studyInstanceUid: study_instance_uid
              }
            `,
            id: `study:${studyId}`,
          });
          if (!study) {
            return undefined;
          }
          const { studyInstanceUid } = study;

          const baseWadoUrls = cache.readFragment<{
            wadoUrls: BaseWadoUrlFragmentType;
          }>({
            fragment: BASE_WADO_URL_FRAGMENT,
          });
          if (!baseWadoUrls) {
            return undefined;
          }
          const baseWadoUrl = parseBaseWadoUrl(baseWadoUrls.wadoUrls);

          return getWadoUrl(baseWadoUrl, studyInstanceUid, seriesInstanceUid, sopInstanceUid);
        },
      },
    },
  },
};
