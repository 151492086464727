import { gql, useMutation } from "@apollo/client";
import { MutationUpdaterFn, Reference } from "@apollo/client/core";
import { MutationTuple } from "@apollo/client/react/types/types";

import { SeriesNotesFragmentType } from "../../../../../fragments/SeriesNotesFragmentType";
import { SERIES_NOTE_FRAGMENT } from "./useStudiesList";

const DELETE_SERIES_NOTES_MUTATION = gql`
  mutation deleteSeriesNotes($id: Int!) {
    delete_series_notes_by_pk(id: $id) {
      ...SeriesNotes
    }
  }
  ${SERIES_NOTE_FRAGMENT}
`;

type Data = {
  delete_series_notes_by_pk: SeriesNotesFragmentType;
};

type Variables = {
  id: number;
};

export function useDeleteSeriesNotes(): MutationTuple<Data, Variables> {
  return useMutation<Data, Variables>(DELETE_SERIES_NOTES_MUTATION, {
    update: updateCache,
  });
}

const updateCache: MutationUpdaterFn<Data> = (cache, { data }) => {
  if (!data) {
    throw new Error("Something went wrong updating the cache after inserting series notes");
  }

  const {
    delete_series_notes_by_pk: { id, seriesId },
  } = data;

  const seriesNotesCacheId = cache.identify({ __typename: "series_notes", id });

  cache.modify({
    id: cache.identify({ __typename: "series", id: seriesId }),
    fields: {
      series_notes(currentSeriesNotesRefs = [], { readField }) {
        return currentSeriesNotesRefs.filter(
          (seriesNoteRef: Reference) => readField("id", seriesNoteRef) !== id
        );
      },
    },
  });

  cache.evict({ id: seriesNotesCacheId });
};
