import { Popover } from "@mui/material";
import React from "react";
import styled from "styled-components";

import { ReactComponent as Sort } from "../../../../../../assets/svgs/Sort.svg";
import { SvgIcon } from "../../../../../../common/components/icons/SvgIcon";
import { main } from "../../../../../../common/theme/main";
import { IconButton } from "../IconButton";

const PopOut = styled.div`
  background-color: ${main.colors.background.secondary};
  display: flex;
  width: 400px;
  height: 100px;
`;

export function SortPopover(): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "sort-popover" : undefined;

  return <>
    <IconButton onClick={handleClick}>
      <SvgIcon icon={Sort} size={20} color={main.colors.neutral.black} />
    </IconButton>

    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 40,
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <PopOut></PopOut>
    </Popover>
  </>;
}
