import React from "react";
import { useSelector } from "react-redux";

import { FlexLoading } from "../../../../../common/components/Loading";
import { seriesClassificationsSelector } from "../../../../../common/store/annotatePage/taskSelector";
import { ViewerConfigType } from "../../../../../common/store/annotatePage/ViewerConfigType";
import handleApolloError from "../../../../../common/utils/handleApolloError";
import { DEFAULT } from "../../../../enums/TaskDescriptionEnums";
import { useAreSeriesClassificationsViewOnly } from "../AnnotationPanel/hooks/useAreSeriesClassificationsViewOnly";
import { useStudiesList } from "../AnnotationPanel/hooks/useStudiesList";
import { SeriesClassificationsPanel } from "./SeriesClassificationsPanel";

interface SeriesClassificationsPanelWrapperProps {
  viewerConfig: ViewerConfigType;
}

export function SeriesClassificationsPanelWrapper({
  viewerConfig,
}: SeriesClassificationsPanelWrapperProps): JSX.Element {
  const availableClassificationModes = useSelector(seriesClassificationsSelector);

  const { id, seriesId } = viewerConfig;

  const viewOnly = useAreSeriesClassificationsViewOnly(viewerConfig);

  const { data, loading, error } = useStudiesList();

  const { values: classifications } = availableClassificationModes[DEFAULT] || {};

  if (!classifications) {
    return <></>;
  }

  if (error) handleApolloError(error);
  if (loading) return <FlexLoading />;

  if (!data) {
    throw new Error("Lesions are undefined!");
  }

  const { studies } = data;

  return (
    <SeriesClassificationsPanel
      viewerConfigId={id}
      studies={studies}
      availableClassifications={classifications}
      seriesId={seriesId}
      viewOnly={viewOnly}
    />
  );
}
