import React, { useContext } from "react";
import styled from "styled-components";

import { ReactComponent as CancelIcon } from "../../../../assets/svgs/Cancel.svg";
import { ReactComponent as CheckIcon } from "../../../../assets/svgs/CheckFilled.svg";
import { main } from "../../../theme/main";
import { SvgIcon } from "../../icons/SvgIcon";
import { InputButton } from "../../input/InputButton";
import { AnonymizationConfigurationContext } from "../../UserManagement/Dialogs/Contexts/AnonymizationConfigurationContext";
import { useAnonymizationDialog } from "../../UserManagement/Dialogs/useAnonymizationDialog";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

const LabelWrapper = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

interface AnonymizationDisplayProps {
  label: string;
}

const {
  colors: {
    states: { success, error },
  },
} = main;
export function AnonymizationDisplay({ label }: AnonymizationDisplayProps): JSX.Element {
  const { config } = useContext(AnonymizationConfigurationContext);

  const isEnabled = Object.entries(config).some(([_, value]) => !!value);

  const [setShowAnonymizationDialog, { dialog: anonymizationDialog }] = useAnonymizationDialog();

  const handleConfigure = () => {
    setShowAnonymizationDialog(true);
  };

  return (
    <>
      {anonymizationDialog}
      <Wrapper>
        <LabelWrapper>{label}</LabelWrapper>
        {isEnabled ? (
          <SvgIcon icon={CheckIcon} size={18} color={success} />
        ) : (
          <SvgIcon icon={CancelIcon} size={18} color={error} />
        )}
        <InputButton
          type="button"
          name="configure-button"
          value={"Configure"}
          background={main.colors.neutral.black}
          color={main.colors.neutral.white}
          onClick={handleConfigure}
          width={96}
        />
      </Wrapper>
    </>
  );
}
