import { specialCaseWords } from "nota-predict-web/src/common/components/UserManagement/utils/string.utils";
import { AnyObject } from "nota-predict-web/src/common/types/EmptyObjectType";
import { SUCCESS } from "nota-predict-web/src/common/types/JobStatusType";
import { ERROR, TaskStatusType, WARNING } from "nota-predict-web/src/common/types/StatusTypes";
import { isDefined, isNullOrUndefined } from "nota-predict-web/src/common/utils/exists";
import { objHasKey } from "nota-predict-web/src/common/utils/objectHasKey";
import isEqual from "react-fast-compare";

import { capitalizeFirstLetter } from "../../../../../../Analysis/common/utils/captatlizeFirstLetter";
import { isFragmentQcRule } from "../../../utils/qc-validation/schema/baseRule";
import {
  AnyQcRule,
  isOptionsQcRule,
  OptionsQcRule,
} from "../../../utils/qc-validation/schema/option";
import { QcSchema } from "../../../utils/qc-validation/schema/ruleSet";
import { SeriesQueryType } from "../hooks/useGetActiveSeries";
import { QC_COMPUTED_VALUES_MAP, QcReportMappableComputedField } from "./QcForm.stringMaps";
import { GetTaskQcReportsStatus, IGNORE_SERIES_WITH_DESCRIPTIONS } from "./QcTask.hooks";
import {
  isQcReportField,
  OptionType,
  QcFormResult,
  QcFormResults,
  QcFormScope,
  QcFormValue,
  QcReportKey,
  QcReportRow,
} from "./QualityControlProvider.types";

export const scopeIdKey = (scope: QcFormScope) => {
  return `${scope.toLowerCase()}Id` as "seriesId" | "studyId" | "patientId";
};

/**
 * Generates an unique key for storing a QC report based on the scope and the foreign key ID,
 * e.g. "SERIES:1234" or "PATIENT:5678" where the number is the foreign key ID (not the report ID).
 * @param scope
 * @param reportOrFkId
 * @returns A string key for the report, or null if the reportOrFkId is not a number or string.
 */
export const makeQcReportKey = (
  scope: QcFormScope | null,
  reportOrFkId?: number | string | QcReportRow | null
): QcReportKey | null => {
  if (!reportOrFkId || !scope) {
    return null;
  }
  if (typeof reportOrFkId === "number" || typeof reportOrFkId === "string") {
    return `${scope}:${reportOrFkId}`;
  }
  const foreignKeyId = reportOrFkId[scopeIdKey(scope)];
  if (!foreignKeyId) {
    return null;
  }
  return `${scope}:${foreignKeyId}`;
};

/**
 * Parses a QC report key into its scope and foreign key ID components.
 * e.g. "SERIES:1234" => ["SERIES", 1234]
 * @param reportKey
 * @returns A tuple containing the scope and foreign key ID
 */
export const parseQcReportKey = (
  reportKey?: string | null
): [QcFormScope, number] | [null, null] => {
  if (!reportKey) {
    return [null, null];
  }
  return (reportKey?.split(":") ?? []).map((v, i) =>
    i === 0 ? (v as QcFormScope) : parseInt(v, 10)
  ) as [QcFormScope, number];
};

export const isOptionType = (option: unknown): option is OptionType => {
  return typeof option === "object" && option !== null && "label" in option && "value" in option;
};

export const isOptionTypeArray = (option: unknown): option is OptionType[] => {
  return Array.isArray(option) && option.every(isOptionType);
};

export const isStringArray = (value: unknown, resultIfEmpty = false): value is string[] => {
  if (!Array.isArray(value)) return false;
  const isEmpty = value.length === 0;
  return isEmpty ? resultIfEmpty : value.every((item) => typeof item === "string");
};

export const isNumberArray = (value: unknown, resultIfEmpty = false): value is number[] => {
  if (!Array.isArray(value)) return false;
  const isEmpty = value.length === 0;
  return isEmpty ? resultIfEmpty : value.every((item) => typeof item === "number" && !isNaN(item));
};

export const ensureArray = <T>(value: T | T[]): T[] => {
  return Array.isArray(value) ? value : [value];
};

export const stripEmptyValues = <T>(array: T[]): NonNullable<T>[] => {
  return array.filter(
    (v) =>
      v !== null &&
      v !== undefined &&
      v !== "" &&
      (isOptionType(v) ? v.value !== null && v.value !== undefined && v.value !== "" : true)
  ) as NonNullable<T>[];
};

export const ensureArrayValueUniqueness = <
  TReturn extends (string | number | OptionType)[] = (string | number | OptionType)[]
>(
  array: (string | number | OptionType | null)[] | null
): TReturn => {
  if (!array || array.length === 0) {
    return [] as unknown as TReturn;
  }

  return stripEmptyValues(array).filter(
    (item, index, self) =>
      self.findIndex((i) => {
        return (
          i !== null &&
          (isOptionType(i) ? i.value : String(i)).toLowerCase() ===
            (isOptionType(item) ? item.value : String(item)).toLowerCase()
        );
      }) === index
  ) as TReturn;
};

export const rulesSetForScope = <TReturnType = OptionsQcRule[]>(
  scope: QcFormScope,
  schema?: QcSchema
): TReturnType => {
  switch (scope) {
    case "PATIENT":
      return (schema?.subject_rule_set ?? []) as TReturnType;
    case "STUDY":
      throw new Error("STUDY scope is not supported");
    case "SERIES":
      return (schema?.series_rule_set ?? []) as TReturnType;
    default:
      throw new Error(`Unknown scope: ${scope}`);
  }
};

export const extractDefaultValue = (rule: AnyObject): QcFormValue => {
  if (!isFragmentQcRule(rule) || isQcFormValueEmpty(rule.default_value)) {
    return undefined;
  }

  switch (rule.input_type) {
    case "select-one":
    case "text":
      return isFragmentQcRule(rule) ? rule.default_value : undefined;
    case "select-many":
      return isFragmentQcRule(rule) && rule.default_value
        ? (ensureArray(rule.default_value) as string[])
        : [];
    case "number":
      return isFragmentQcRule(rule) && isFinite(parseInt(`${rule.default_value}`))
        ? Number(rule.default_value)
        : undefined;
    default:
      return undefined;
  }
};

export const extractDefaultValues = (rules: AnyObject[]): QcFormResults =>
  (rules as QcFormResults[]).reduce((acc, rule) => {
    if (!isFragmentQcRule(rule)) {
      return acc;
    }
    const { name } = rule;
    const value = extractDefaultValue(rule);
    const parent = isDefined(value) ? { [name]: { value } } : {};
    const dependents = extractDefaultValues((rule.dependent_rules as AnyObject[]) ?? []);
    return {
      ...acc,
      ...parent,
      ...dependents,
    };
  }, {} as QcFormResults);

/**
 * Checks a QcFormResults object against a set of rules to find any missing default values.
 * @param rules The rules to check for missing default values.
 * @param currentResults The current report results to check against.
 * @returns An object with `missing`, an array of missing keys, and `proposed`, a QcFormResults object with a proposed
 * update to the current results. If there are no missing default values, `missing` will be an empty object and
 * `proposed` will be undefined. A missing default value is defined as a value that is not present in the current results,
 * or if the 'value' property is missing from the current result. If the 'value' property is present but is null or undefined,
 * it will be not be considered missing.
 **/
export const findMissingDefaultValues = (
  rules: AnyObject[] = [],
  currentResults: QcFormResults = {}
) => {
  const empty = {
    missing: [],
    proposed: undefined,
  };

  if (!rules || rules.length === 0) return empty;

  const defaultValues = extractDefaultValues(rules);
  const [equal, delta] = compareQcFormValues<"current", "defaults">(currentResults, defaultValues, {
    ignoreExtraKeys: "a",
    aKeyName: "current",
    bKeyName: "defaults",
  });

  if (equal) return empty;

  const missing = Object.keys(delta?.delta ?? {}).filter((key) => {
    return !objHasKey((delta?.delta?.[key] as Record<string, QcFormResult>)?.current, "value");
  });

  if (missing.length === 0) return empty;

  const defaultsToApply = missing.reduce((acc, key) => {
    return {
      ...acc,
      [key]: defaultValues?.[key],
    };
  }, {} as QcFormResults);
  const proposed = mergeQcFormValues(currentResults, defaultsToApply);

  return {
    missing,
    proposed,
  };
};

export const extractComputedValue = (
  rule: AnyObject,
  storedResult?: QcFormResults
): QcFormValue => {
  if (!isFragmentQcRule(rule) || !storedResult) {
    return null;
  }
  return friendlyComputedValue(rule.name, storedResult?.[rule.name]?.computed_value);
};

/**
 * Round a number to a given precision and return as a string
 * @param value number or string to round
 * @param precision [3] number of decimal places to round to (e.g. 0.123)
 * @returns rounded number represented as a string
 */
const roundQcNumber = (value: unknown, precision = 3): string | string[] => {
  if (Array.isArray(value)) {
    return value.map((v) => roundQcNumber(v, precision)) as string[];
  }
  const multiplier = 10 ** precision;
  if (typeof value === "number") {
    return (Math.round(value * multiplier) / multiplier).toString();
  }
  if (typeof value === "string") {
    const num = Number(value);
    if (Number.isNaN(num)) {
      console.warn(`roundQcNumbers: cannot round, value '${value}' is not a number`);
      return value;
    }
    return roundQcNumber(num, precision).toString();
  }
  console.warn(
    `roundQcNumbers: cannot round, got '${value}' (${typeof value}), expected number or string (or array of).`
  );
  return value as string;
};

export const friendlyComputedValue = (
  ruleName: string | QcReportMappableComputedField,
  computedValue: QcFormValue
): QcFormValue => {
  if (isStringArray(computedValue, true) || isNumberArray(computedValue)) {
    return ensureArrayValueUniqueness<string[]>(
      computedValue.map((v) => friendlyComputedValue(ruleName, v)) as string[]
    );
  }

  let value: QcFormValue = null;
  if (typeof computedValue === "number" && !isNaN(computedValue)) {
    value = roundQcNumber(computedValue);
  } else if (typeof computedValue === "string" && computedValue.length > 0) {
    value = computedValue.trim();
  }

  if (value === null) {
    return computedValue;
  }

  const name = ruleName as QcReportMappableComputedField;
  const key = value as keyof (typeof QC_COMPUTED_VALUES_MAP)[typeof name];
  return QC_COMPUTED_VALUES_MAP?.[name]?.[key] ?? value;
};

export const qcOtherValueKey = (key: string) => {
  return `${key}__other` as `${string}__other`;
};

export const getQcOtherValue = (key: string, formValues: QcFormResults): string | null => {
  const otherValue = formValues?.[qcOtherValueKey(key)]?.value;
  return typeof otherValue === "string" && otherValue.length > 0 ? otherValue : null;
};

export const isQcOtherValueKey = (key: string) => {
  return key.endsWith("__other");
};

/**
 * Checks if a value is null, undefined, or an empty string ("")
 * @param value
 * @returns Returns `true` if a value is null, undefined, or an empty string ("") otherwise `false`.
 */
export const isQcFormValueEmpty = (value: unknown) => {
  return value === null || value === undefined || value === "";
};

export const toOptionType = (value: unknown): OptionType | null =>
  isOptionType(value)
    ? value
    : isQcFormValueEmpty(value) || typeof value !== "string"
    ? null
    : {
        label: specialCaseWords(capitalizeFirstLetter(value)),
        value,
      };

export const getSelectOptions = (options: string | string[], allowOther = false): OptionType[] => {
  const _options = coerceToOptionType(options, true) as OptionType[];
  return allowOther
    ? ensureArrayValueUniqueness([..._options, { label: "Other", value: "other" }])
    : _options;
};

export const setOptionChecked = (
  option: string,
  checked: boolean,
  currentValue: unknown = []
): string[] => {
  const arrayValue = Array.isArray(currentValue)
    ? currentValue
    : typeof currentValue === "string"
    ? [currentValue]
    : [];
  // Convert values to lowercase for case-insensitive comparison
  const _option = option.toLowerCase();
  const _arrayValue = arrayValue.map((value) => value.toLowerCase());
  if (checked) {
    return stripEmptyValues(Array.from(new Set([..._arrayValue, _option])).sort());
  } else {
    return stripEmptyValues(_arrayValue.filter((value) => value !== _option));
  }
};

export const isCheckboxChecked = (option: string, currentValue: string | string[]) => {
  const arrayValue = Array.isArray(currentValue) ? currentValue : [currentValue];
  // Convert values to lowercase for case-insensitive comparison
  const _arrayValue = arrayValue.map((value) => value.toLowerCase());
  const _option = option.toLowerCase();
  return _arrayValue.includes(_option);
};

export const isRadioChecked = (option: string, currentValue: string) => {
  return typeof currentValue === "string" && currentValue.toLowerCase() === option.toLowerCase();
};

export const getNumberArrayError = ({
  value,
  computedValue,
}: {
  value: unknown;
  computedValue: unknown;
}) => {
  const valueIsBad = Array.isArray(value) && value.length > 1;
  const computedValueIsBad = Array.isArray(computedValue) && computedValue.length > 1;
  const shouldError = computedValueIsBad && (isNullOrUndefined(value) || valueIsBad);

  const err = shouldError
    ? {
        type: "manual",
        message: "The computed value contains more than one number",
      }
    : undefined;
  return err;
};

export const coerceToPlainValue = (option: unknown, isMulti = false): string | string[] | null => {
  if (isOptionType(option)) {
    return isMulti ? [option.value] : option.value;
  } else if (isOptionTypeArray(option)) {
    return ensureArrayValueUniqueness(option.map((option) => option.value)) as string[];
  }
  return isQcFormValueEmpty(option) || typeof option !== "string"
    ? null
    : isMulti
    ? ensureArray(option)
    : option;
};

// Checks for string, array of strings, number or array of numbers to see if they are the same safe value
// Safe value means that the comparison is case-insensitive for strings and arrays of strings
export const isSafeSameValue = (value1: unknown, value2: unknown): boolean => {
  // First compare as numbers or array of numbers
  const safeValue1 = coerceToSafeNumber(value1, true);
  const safeValue2 = coerceToSafeNumber(value2, true);
  if (safeValue1 && safeValue2) return isEqual(safeValue1, safeValue2);

  // Not a number, compare as strings
  if (typeof value1 === "string" && typeof value2 === "string") {
    return value1.toLowerCase() === value2.toLowerCase();
  }
  if (isStringArray(value1) && isStringArray(value2)) {
    return isEqual(
      value1.map((v) => v.toLowerCase()),
      value2.map((v) => v.toLowerCase())
    );
  }

  // Compare as objects/arrays (order matters)
  return isEqual(value1, value2);
};

export const coerceToSafeNumber = (value: unknown, stringify = false): string | number | null => {
  if (typeof value === "number") {
    if (!Number.isFinite(value)) {
      return null;
    }
    return stringify ? String(value) : value;
  }
  if (typeof value === "string" && value.length > 0) {
    return coerceToSafeNumber(Number(value), stringify);
  }
  if (Array.isArray(value)) {
    const unique = ensureArrayValueUniqueness<(string | number)[]>(value);
    if (unique.length === 0) {
      return null;
    }
    if (unique.length === 1) {
      return coerceToSafeNumber(unique[0], stringify);
    }
    // Deal with nested array and remove null values from the resulting array
    const safeValues = unique
      .map((v) => coerceToSafeNumber(v, stringify))
      .filter((v) => v !== null);
    // Return stringified array if stringify is true, otherwise return null
    return stringify ? safeValues.map((v) => String(coerceToSafeNumber(v))).join(", ") : null;
  }
  return null;
};

export const coerceToOptionType = (
  value: unknown,
  isMulti = false
): OptionType | OptionType[] | null => {
  if (isQcFormValueEmpty(value)) {
    return null;
  }
  const maybeOptionType = toOptionType(value);
  const optTypeArr = isOptionTypeArray(value)
    ? ensureArrayValueUniqueness(value)
    : isStringArray(value)
    ? value.map((value) => toOptionType(value))
    : ensureArray(maybeOptionType);
  if (isMulti) {
    return ensureArrayValueUniqueness<OptionType[]>(optTypeArr) as OptionType[];
  }
  return maybeOptionType;
};

export const formValuesToDict = (formValues: QcFormResults): Record<string, string | string[]> => {
  return Object.entries(formValues ?? {}).reduce((acc, [key, value]) => {
    if (!isQcReportField(value)) {
      return acc;
    }
    return {
      ...acc,
      [key]: value.value,
    };
  }, {});
};

// FIXME: two very similar functions 'getAnnotationTaskStatus' and 'getOverallTaskStatus' already exist
export const getQcReportsStatus = (
  qcReports: GetTaskQcReportsStatus["qcReports"],
  expectedCount: number
): TaskStatusType => {
  if (qcReports.length === 0 || qcReports.length < expectedCount) {
    return WARNING;
  }
  const allStatuses = qcReports.map(({ isValid }) => isValid);
  if (allStatuses.every((status) => status === true)) {
    return SUCCESS;
  }
  return ERROR;
};

export const INPUT_CONTROL_TYPES = [
  "radio",
  "checkbox",
  // "bool-checkbox", TODO: not implemented
  "select-one",
  "select-many",
  "text",
  "number",
  "unknown",
] as const;
export type InputControlType = (typeof INPUT_CONTROL_TYPES)[number];

export const determineInputControlType = (
  rule: AnyQcRule,
  options: OptionType[]
): {
  inputControlType: InputControlType;
  isMulti: boolean;
} => {
  if (!isOptionsQcRule(rule)) {
    return {
      inputControlType: ((rule as AnyObject)?.input_type as InputControlType) ?? "unknown",
      isMulti: false,
    };
  }
  if (options.length > 3) {
    return {
      inputControlType: rule.input_type,
      isMulti: rule.input_type === "select-many",
    };
  } else if (rule.input_type === "select-many") {
    return {
      inputControlType: "checkbox",
      isMulti: true,
    };
  }
  return {
    inputControlType: "radio",
    isMulti: false,
  };
};

export const determineIfShouldShowOther = (
  rule: AnyQcRule,
  value: string | string[] | OptionType | OptionType[]
): boolean => {
  if (!isOptionsQcRule(rule) || !rule.allow_other) {
    return false;
  }
  if (typeof value === "string") {
    return value?.toLowerCase() === "other";
  }
  if (isStringArray(value)) {
    return value.some((v) => v?.toLowerCase() === "other");
  }
  if (isOptionType(value)) {
    return value?.value?.toLowerCase() === "other";
  }
  if (isOptionTypeArray(value)) {
    return value.some((v) => v?.value?.toLowerCase() === "other");
  }
  return false;
};

type CompareQcFormValuesReturns<AKey extends string = "a", BKey extends string = "b"> = [
  boolean,
  (
    | ({
        [key in AKey]: QcFormResults;
      } & {
        [key in BKey]: QcFormResults;
      } & {
        delta: Record<
          string,
          | ({
              [key in AKey]: QcFormResult;
            } & {
              [key in BKey]: QcFormResult;
            })
          | "not in a"
          | "not in b"
        >;
      })
    | undefined
  )
];

type CompareQcFormValuesOptions = {
  ignoreExtraKeys?: "a" | "b" | true;
  aKeyName?: string;
  bKeyName?: string;
};
/**
 * Checks if two sets of QC form values are equal. This only compares "value", "other_value", and "computed_value" properties,
 * all other properties are ignored.
 * @param a The first set of form values.
 * @param b The second set of form values.
 * @param options [optional] The options to use when comparing the form values:
 * - `ignoreExtraKeys`: default=false, "a", "b", or true – Whether to ignore extra properties in a, b, or both.
 *    This applies to child properties as well, e.g. if `ignoreExtraKeys` is "a", `{ a: { value: 1, other_value: 2 } }` and `{ a: { value: 1 } }`
 *    will be considered equal.
 * - `aKeyName`: default="a" – The key name to use for the first object in the returned delta object.
 * - `bKeyName`: default="b" – The key name to use for the second object in the returned delta object.
 * @returns a tuple, `[boolean, deltaObject]`
 * - `true` if each of the compared properties in both a and b are equal, otherwise `false`.
 * - `{ a, b, delta }`: Both original objects – a, b – and a `delta` object that shows the differences between the two,
 *   or undefined when the two objects are either equal or one of them is undefined.
 */
export const compareQcFormValues = <AKey extends string = "a", BKey extends string = "b">(
  a?: QcFormResults,
  b?: QcFormResults,
  options?: CompareQcFormValuesOptions
): CompareQcFormValuesReturns<AKey, BKey> => {
  if (!a || !b) {
    return [false, undefined];
  }

  const { ignoreExtraKeys, aKeyName = "a", bKeyName = "b" } = options ?? {};

  const aKeys = Object.keys(a);
  const bKeys = Object.keys(b);

  const allKeys = [...new Set([...aKeys, ...bKeys])];

  let keysToCompare: string[] = allKeys;

  switch (ignoreExtraKeys) {
    case "a":
      keysToCompare = bKeys;
      break;
    case "b":
      keysToCompare = aKeys;
      break;
    case true:
      keysToCompare = allKeys.filter((key) => aKeys.includes(key) && bKeys.includes(key));
      break;
    default:
      break;
  }

  const extraKeysInA = keysToCompare.filter((key) => !bKeys.includes(key));
  const extraKeysInB = keysToCompare.filter((key) => !aKeys.includes(key));

  // build an object to show the intersection of values that are not equal
  const deltaValues = keysToCompare.reduce((acc, key) => {
    const aValues = Object.keys(a[key] ?? {});
    const bValues = Object.keys(b[key] ?? {});

    let valueIsEqual = isEqual(a[key]?.value ?? "", b[key]?.value ?? "");
    let otherValueIsEqual = isEqual(a[key]?.other_value ?? "", b[key]?.other_value ?? "");
    let computedValueIsEqual = isEqual(a[key]?.computed_value ?? "", b[key]?.computed_value ?? "");

    switch (ignoreExtraKeys) {
      case "a":
        valueIsEqual = !bValues.includes("value") || valueIsEqual;
        otherValueIsEqual = !bValues.includes("other_value") || otherValueIsEqual;
        computedValueIsEqual = !bValues.includes("computed_value") || computedValueIsEqual;
        break;
      case "b":
        valueIsEqual = !aValues.includes("value") || valueIsEqual;
        otherValueIsEqual = !aValues.includes("other_value") || otherValueIsEqual;
        computedValueIsEqual = !aValues.includes("computed_value") || computedValueIsEqual;
        break;
      case true:
        valueIsEqual = !aValues.includes("value") || !bValues.includes("value") || valueIsEqual;
        otherValueIsEqual =
          !aValues.includes("other_value") || !bValues.includes("other_value") || otherValueIsEqual;
        computedValueIsEqual =
          !aValues.includes("computed_value") ||
          !bValues.includes("computed_value") ||
          computedValueIsEqual;
        break;
      default:
        break;
    }

    const eq = valueIsEqual && otherValueIsEqual && computedValueIsEqual;

    if (!eq) {
      const aDeltaValue =
        !valueIsEqual && objHasKey(a[key], "value") ? { value: a[key]?.value } : {};
      const bDeltaValue =
        !valueIsEqual && objHasKey(b[key], "value") ? { value: b[key]?.value } : {};

      const aDeltaOtherValue =
        !otherValueIsEqual && objHasKey(a[key], "other_value")
          ? { other_value: a[key]?.other_value }
          : {};

      const bDeltaOtherValue =
        !otherValueIsEqual && objHasKey(b[key], "other_value")
          ? { other_value: b[key]?.other_value }
          : {};

      const aDeltaComputedValue =
        !computedValueIsEqual && objHasKey(a[key], "computed_value")
          ? { computed_value: a[key]?.computed_value }
          : {};

      const bDeltaComputedValue =
        !computedValueIsEqual && objHasKey(b[key], "computed_value")
          ? { computed_value: b[key]?.computed_value }
          : {};

      const aDelta = !objHasKey(a, key)
        ? undefined
        : {
            ...aDeltaValue,
            ...aDeltaOtherValue,
            ...aDeltaComputedValue,
          };

      const bDelta = !objHasKey(b, key)
        ? undefined
        : {
            ...bDeltaValue,
            ...bDeltaOtherValue,
            ...bDeltaComputedValue,
          };

      return {
        ...acc,
        [key]: {
          [`${aKeyName}`]: aDelta,
          [`${bKeyName}`]: bDelta,
        },
      };
    }
    return acc;
  }, {});

  const delta = {
    ...extraKeysInA.reduce(
      (acc, key) => ({
        ...acc,
        [key]: `not in ${bKeyName}`,
      }),
      {}
    ),
    ...extraKeysInB.reduce(
      (acc, key) => ({
        ...acc,
        [key]: `not in ${aKeyName}`,
      }),
      {}
    ),
    ...deltaValues,
  };

  if (Object.keys(delta).length > 0) {
    return [
      false,
      {
        [aKeyName]: a,
        [bKeyName]: b,
        delta,
      } as unknown as CompareQcFormValuesReturns<AKey, BKey>[1],
    ];
  }
  return [true, undefined];
};

/**
 * Checks if two sets of QC form values are equal. This only compares "value", "other_value", and "computed_value" properties,
 * all other properties are ignored. Passthrough to `compareQcFormValues`'s first return value.
 * @param a The first set of form values.
 * @param b The second set of form values.
 * @param options [optional] The options to use when comparing the form values:
 * - `ignoreExtraKeys`: default=false, "a", "b", or true – Whether to ignore extra properties in a, b, or both.
 *    This applies to child properties as well, e.g. if `ignoreExtraKeys` is "a", `{ a: { value: 1, other_value: 2 } }` and `{ a: { value: 1 } }`
 *    will be considered equal.
 * @returns `true` if each of the compared properties in both a and b are equal, otherwise `false`.
 */
export const areQcFormValuesEqual = (
  a?: QcFormResults,
  b?: QcFormResults,
  options?: CompareQcFormValuesOptions
): boolean => compareQcFormValues(a, b, options)[0];

export const qc_warnLog = (...args: unknown[]) => {
  qc_logger("warn", ...args);
};

export const qc_debugLog = (...args: unknown[]) => {
  qc_logger("debug", ...args);
};

const qc_logger = <T extends keyof Console, F extends Console[T]>(
  func: F extends () => void ? T : never,
  ...args: unknown[]
) => {
  if (
    typeof window !== "undefined" &&
    ["development", "test", "staging"].includes(window._env_.REACT_APP_ENVIRONMENT ?? "")
  ) {
    const consoleFunc = console[func] as (...args: unknown[]) => void;
    if (typeof consoleFunc === "function") {
      // if the first argument is a string and the second is an object, pad the string with padEnd()
      if (args.length >= 2 && typeof args[0] === "string" && typeof args[1] === "object") {
        args[0] = args[0].padEnd(72);
      }

      consoleFunc(...["[qcForm]:", ...args]);
    }
  }
};

/**
 * Deeply merges two sets of QC form values, with the second set taking precedence over the first.
 * Only merges "value", "other_value", and "computed_value" properties, all other properties are ignored.
 * @param existing The existing set of form values.
 * @param update The updated set of form values (takes precedence).
 * @returns The merged set of form values, or an empty object if both base and updates are undefined.
 */
export const mergeQcFormValues = (
  existing?: QcFormResults,
  update?: QcFormResults
): QcFormResults => {
  if (!existing || !update) {
    return existing ?? update ?? {};
  }

  // TODO: This function is extremely blown up so that we can log in detail what is happening.
  // when confident that it works, we can simplify it greatly. —B

  const existingKeys = Object.keys(existing);
  const updateKeys = Object.keys(update);
  const allProps = [...new Set([...existingKeys, ...updateKeys])];

  return allProps.reduce((acc, key) => {
    const existingField = existing?.[key] ?? {};
    const updatedField = update?.[key] ?? {};

    const existingValues = Object.keys(existingField);
    const updateValues = Object.keys(updatedField);

    const hasOtherValue =
      Object.keys(existingField).includes("other_value") ||
      Object.keys(updatedField).includes("other_value");
    const hasComputedValue =
      Object.keys(existingField).includes("computed_value") ||
      Object.keys(updatedField).includes("computed_value");

    const valueIsEqual = isEqual(updatedField?.value, existingField?.value);
    const shouldUpdateValue = updateValues.includes("value") && !valueIsEqual;
    const shouldAddValue = !existingValues.includes("value") && updateValues.includes("value");
    const shouldKeepValue = !updateValues.includes("value") && existingValues.includes("value");

    let value: QcFormValue = existingField?.value;
    if (shouldAddValue) {
      value = updatedField?.value;
      // qc_debugLog(`→ {merge} Adding value for '${key}'`, {
      //   name: key,
      //   value,
      // });
    } else if (shouldKeepValue) {
      value = existingField?.value;
      // qc_debugLog(`→ {merge} Keeping existing value for '${key}'`, {
      //   name: key,
      //   value,
      // });
    } else if (shouldUpdateValue) {
      value = updatedField?.value;
      // qc_debugLog(`→ {merge} Updating '${key}' with new value`, {
      //   name: key,
      //   value,
      //   prevValue: existingField?.value,
      // });
    }

    const otherValueIsEqual = isEqual(updatedField?.other_value, existingField?.other_value);
    const shouldUpdateOtherValue = updateValues.includes("other_value") && !otherValueIsEqual;
    const shouldAddOtherValue =
      !existingValues.includes("other_value") && updateValues.includes("other_value");
    const shouldKeepOtherValue =
      !updateValues.includes("other_value") && existingValues.includes("other_value");

    let otherValue: QcFormValue = existingField?.other_value;

    if (shouldAddOtherValue) {
      otherValue = updatedField?.other_value;
      // qc_debugLog(`→ {merge} Adding other_value for '${key}'`, {
      //   name: key,
      //   otherValue,
      // });
    } else if (shouldKeepOtherValue) {
      otherValue = existingField?.other_value;
      // qc_debugLog(`→ {merge} Keeping existing other_value for '${key}'`, {
      //   name: key,
      //   otherValue,
      // });
    } else if (shouldUpdateOtherValue) {
      otherValue = updatedField?.other_value;
      // const msg = isQcFormValueEmpty(existingField?.other_value)
      //   ? `→ {merge} Clearing '${key}' other_value`
      //   : `→ {merge} Updating '${key}' with new other_value`;
      // qc_debugLog(msg, {
      //   name: key,
      //   otherValue,
      //   prevOtherValue: existingField?.other_value,
      // });
    }

    let computedValue: QcFormValue = existingField?.computed_value;

    const computedValueIsEqual = isEqual(
      updatedField?.computed_value,
      existingField?.computed_value
    );
    const shouldUpdateComputedValue =
      updateValues.includes("computed_value") && !computedValueIsEqual;
    const shouldAddComputedValue =
      !existingValues.includes("computed_value") && updateValues.includes("computed_value");
    const shouldKeepComputedValue =
      !updateValues.includes("computed_value") && existingValues.includes("computed_value");

    if (shouldAddComputedValue) {
      computedValue = updatedField?.computed_value;
      // qc_debugLog(`→ {merge} Adding computed_value for '${key}'`, {
      //   name: key,
      //   computedValue,
      // });
    } else if (shouldKeepComputedValue) {
      computedValue = existingField?.computed_value;
      // qc_debugLog(`→ {merge} Keeping existing computed_value for '${key}'`, {
      //   name: key,
      //   computedValue,
      // });
    } else if (shouldUpdateComputedValue) {
      computedValue = updatedField?.computed_value;
      // const msg = isQcFormValueEmpty(existingField?.computed_value)
      //   ? `→ {merge} Clearing '${key}' computed_value`
      //   : `→ {merge} Updating '${key}' with new computed_value`;
      // qc_debugLog(msg, {
      //   name: key,
      //   computedValue,
      //   prevComputedValue: existingField?.computed_value,
      // });
    }
    const merged: QcFormResult = {
      value,
      ...(hasOtherValue ? { other_value: otherValue } : {}),
      ...(hasComputedValue ? { computed_value: computedValue } : {}),
    };
    return {
      ...acc,
      [key]: merged,
    } as QcFormResults;
  }, {});
};

/**
 * Applies prefilled (default or computed) values to a set of QC form values and returns a flattened object
 * intended to be used with react-hook-form.
 * @param results The base set of form values.
 * @param defaultValues The default set of form values, to be used when a and b are undefined.
 * @returns The merged set of form values.
 */
export const flatQcFormResults = (
  results: QcFormResults,
  defaultValues: QcFormResults = {}
): Record<string, QcFormValue> => {
  const allProps = [...new Set([...Object.keys(results), ...Object.keys(defaultValues)])];

  return allProps.reduce((acc, key) => {
    const result = results?.[key] ?? {};
    const defaultValue = defaultValues?.[key]?.value;

    let value: QcFormValue = result?.value;
    if (isDefined(value)) {
      qc_debugLog(`→ {flatten} Setting '${key}'`, {
        name: key,
        value,
        defaultValue,
        computedValue: result?.computed_value,
      });
    } else {
      if (!isQcFormValueEmpty(result?.computed_value)) {
        value = result?.computed_value;
        qc_debugLog(`→ {flatten} Setting '${key}' to computed value`, {
          name: key,
          value,
          prevValue: result?.value,
          defaultValue,
          computedValue: result?.computed_value,
        });
      } else if (!isQcFormValueEmpty(defaultValue)) {
        value = defaultValue;
        qc_debugLog(`→ {flatten} Setting '${key}' to default value`, {
          name: key,
          value,
          prevValue: result?.value,
          defaultValue,
          computedValue: result?.computed_value,
        });
      } else {
        value = undefined;
        qc_debugLog(`→ {flatten} Clearing '${key}' value`, {
          name: key,
          value,
          prevValue: result?.value,
          defaultValue,
          computedValue: result?.computed_value,
        });
      }
    }

    const hasOtherValueKey = Object.keys(result).includes("other_value");
    const otherValue: QcFormValue = result?.other_value;
    if (isDefined(otherValue)) {
      qc_debugLog(`→ {flatten} Setting '${qcOtherValueKey(key)}'`, {
        name: qcOtherValueKey(key),
        otherValue,
        defaultValue,
        computedValue: result?.computed_value,
      });
    } else if (hasOtherValueKey) {
      qc_debugLog(`→ {flatten} Clearing '${qcOtherValueKey(key)}' value`, {
        name: qcOtherValueKey(key),
        otherValue,
      });
    }

    return {
      ...acc,
      [key]: value,
      ...(hasOtherValueKey ? { [qcOtherValueKey(key)]: otherValue } : {}),
    } as Record<string, QcFormValue>;
  }, {});
};

export const getIsIgnoredSeries = (
  activeScope: QcFormScope,
  activeSeries: SeriesQueryType | undefined
): boolean => {
  return (
    activeScope === "SERIES" &&
    IGNORE_SERIES_WITH_DESCRIPTIONS.includes(String(activeSeries?.seriesDescription).toLowerCase())
  );
};
