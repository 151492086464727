import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { ProjectContext } from "../../Project/contexts/ProjectContext";
import { ProjectLayoutType } from "../../Project/Layout/Layout";
import { DASHBOARD } from "../../Project/Layout/RoutePathType";
import { SideNavMenu } from "../../Project/Layout/SideNavMenu";
import AppBranding from "./AppBranding";
import { CurrentUserAvatar } from "./Avatar/CurrentUserAvatar";
import { ClickableDiv } from "./divs/ClickableDiv";
import { ProjectSelectorContainer } from "./ProjectSelector/ProjectSelectorContainer";

const StyledTop = styled.div``;

const StyledBottom = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px;
`;

const StyledFlexBox = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  overflow-x: hidden;
`;
const StyledLogo = styled.div`
  height: 48px;
  width: 48px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: ${(props) => props.theme.colors.neutral.white10};
`;

export const SubMenu = styled.div<{ active: boolean }>`
  transition: 0.3s all;
  display: ${(props) => (props.active ? "flex" : "none")};
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 240px;
  padding: 12px;
  margin-bottom: 12px;
`;

interface SideNavProps {
  layout: ProjectLayoutType;
}

export function SideNav({ layout }: SideNavProps): JSX.Element {
  const { push } = useHistory();
  const { projectId } = useContext(ProjectContext);

  const onClickHome = () => {
    push(DASHBOARD);
  };

  return (
    <StyledFlexBox>
      <StyledTop>
        <Row>
          <ClickableDiv onClick={onClickHome}>
            <StyledLogo>
              <AppBranding />
            </StyledLogo>
          </ClickableDiv>
          {!!projectId && <ProjectSelectorContainer />}
        </Row>
        <SideNavMenu layout={layout} />
      </StyledTop>
      <StyledBottom>
        <CurrentUserAvatar showProfileOnClick={true} />
      </StyledBottom>
    </StyledFlexBox>
  );
}
