import React from "react";
import styled from "styled-components";

import { useDialog, useDialogReturnType } from "../../../common/components/Dialog/useDialog";
import { FilterType, IPRO, MORTALITY } from "../../ChangeFromBaseline/components/Filter";
import { formatFollowUp } from "../../common/utils/formatFollowUp";
import SurvivalDistributionChartContainer, {
  SurvivalDistributionChartContainerProps,
} from "../../MortalityDistribution/components/SurvivalDistributionChartContainer";

const Wrapper = styled.div`
  width: 80vw;
  height: 80vh;
  overflow: scroll;
`;

export const BOTH = "BOTH";
export type SurvivalDistributionChartDialogType = typeof MORTALITY | typeof IPRO | typeof BOTH;

export function useSurvivalDistributionChartDialog(
  props: Partial<SurvivalDistributionChartContainerProps>,
  allowedDataTypes: FilterType[]
): useDialogReturnType {
  const { followUp } = props;

  let label: string;

  let dialogType: SurvivalDistributionChartDialogType;

  if (allowedDataTypes.includes(MORTALITY) && allowedDataTypes.includes(IPRO)) {
    dialogType = BOTH;
  } else if (allowedDataTypes.includes(MORTALITY)) {
    dialogType = MORTALITY;
  } else if (allowedDataTypes.includes(IPRO)) {
    dialogType = IPRO;
  } else {
    throw new Error(
      "TumorBurdenSurvivalPredictionChart: allowedDataTypes must contain at least one of MORTALITY or IPRO"
    );
  }

  switch (dialogType) {
    case IPRO:
      label = `Survival Distribution at ${formatFollowUp(followUp || 0)}`;
      break;
    case MORTALITY:
      label = `Mortality Risk at ${formatFollowUp(followUp || 0)}`;
      break;
    case BOTH:
      label = `Analysis at ${formatFollowUp(followUp || 0)}`;
      break;
    default:
      throw new Error(
        `dialogType ${dialogType} is not implemented in useSurvivalDistributionChartDialog`
      );
  }

  const content =
    followUp !== undefined ? (
      <Wrapper>
        <SurvivalDistributionChartContainer
          {...props}
          containerType={dialogType}
          followUp={followUp}
        />
      </Wrapper>
    ) : undefined;

  return useDialog({
    label,
    content,
    options: {
      showCloseButton: true,
    },
  });
}
