import React from "react";
import styled from "styled-components";

const DEFAULT_SEPARATOR = ":";

export const LabelValueWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3px;
`;

export const HeaderText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
`;
export const ValueText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.neutral.neutral3};
`;

interface SimpleBodyTimepointStatProps {
  title: string;
  value: string;
  separator?: string;
}

export function SimpleBodyTimepointStat({
  title,
  value,
  separator = DEFAULT_SEPARATOR,
}: SimpleBodyTimepointStatProps): JSX.Element {
  const label = `${title}${separator}`;
  return (
    <LabelValueWrapper>
      <HeaderText>{label}</HeaderText>
      <ValueText>{value}</ValueText>
    </LabelValueWrapper>
  );
}
