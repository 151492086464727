export const ANALYSIS_SURVIVAL_DETAILS = "survivalDetails";
export const ANALYSIS_KAPLAN_DETAILS = "kaplanMeier";
export const ANALYSIS_ALGORITHM = "algorithmDetails";
export const ANALYSIS_MORTALITY_DETAILS = "mortalityDistribution";
export const ANALYSIS_SUBJECT_JOURNEY = "journey";
export const DATA_MANAGEMENT_UPLOAD = "upload";
export const TEAM_MEMBERS = "team";
export const SITES = "sites";
export const COHORTS = "cohorts";
export const OVERVIEW = "overview";
export const DATA_MANAGEMENT = "data";
export const DATA_MANAGEMENT_NEW = "data-new";
export const ANNOTATE = "annotate";
export const ANALYSIS = "analysis";
export const ANALYSIS_NEW = "analysis-new";
export const DOCUMENTS = "documents";
export const SETTINGS = "settings";
export const PROJECT = "project";
export const TRIALS = "trials";
export const PROJECT_SLUG = ":projectId(\\d+)";
export const TASK = "task";
export const TASK_SLUG = ":taskId(\\d+)";
export const SUBJECT = "subject";
export const SUBJECTS = "subjects";
export const PATIENT = "patient";
export const PATIENT_SLUG = ":patientId(\\d+|-)";
export const ANNOTATE_WORKLIST = "worklist";
export const ANNOTATE_MANAGE = "manage";
export const DASHBOARD = "/";
export const GLOBAL_USER_SETTINGS = "users";
export const TRIAL_SLUG = ":trialId(\\d+)";
export const ANNOTATIONS = "annotations";
export const IMAGES = "images";

export type RoutePathType =
  | typeof ANALYSIS_SURVIVAL_DETAILS
  | typeof ANALYSIS_KAPLAN_DETAILS
  | typeof ANALYSIS_ALGORITHM
  | typeof ANALYSIS_MORTALITY_DETAILS
  | typeof ANALYSIS_SUBJECT_JOURNEY
  | typeof DATA_MANAGEMENT_UPLOAD
  | typeof TEAM_MEMBERS
  | typeof SITES
  | typeof COHORTS
  | typeof OVERVIEW
  | typeof DATA_MANAGEMENT
  | typeof DATA_MANAGEMENT_NEW
  | typeof ANNOTATE
  | typeof ANALYSIS
  | typeof ANALYSIS_NEW
  | typeof DOCUMENTS
  | typeof SETTINGS
  | typeof PROJECT
  | typeof TRIALS
  | typeof PROJECT_SLUG
  | typeof TASK
  | typeof TASK_SLUG
  | typeof SUBJECT
  | typeof SUBJECTS
  | typeof PATIENT
  | typeof PATIENT_SLUG
  | typeof ANNOTATE_WORKLIST
  | typeof ANNOTATE_MANAGE
  | typeof DASHBOARD
  | typeof TRIAL_SLUG
  | typeof GLOBAL_USER_SETTINGS
  | typeof ANNOTATIONS
  | typeof IMAGES
  | number;

const routePathDisplayNames: Partial<Record<RoutePathType, string>> = {
  [ANALYSIS_SURVIVAL_DETAILS]: "Median Survival",
  [ANALYSIS_KAPLAN_DETAILS]: "Kaplan Meier",
  [ANALYSIS_ALGORITHM]: "Algorithms",
  [ANALYSIS_MORTALITY_DETAILS]: "Change in Mortality",
  [ANALYSIS_SUBJECT_JOURNEY]: "Subject Journey",
  [DATA_MANAGEMENT_UPLOAD]: "Upload",
  [TEAM_MEMBERS]: "Team",
  [SITES]: "Sites",
  [COHORTS]: "Cohorts",
  [OVERVIEW]: "Overview",
  [DATA_MANAGEMENT]: "Data",
  [DATA_MANAGEMENT_NEW]: "Data",
  [ANNOTATE]: "Annotate",
  [ANALYSIS]: "Analysis",
  [ANALYSIS_NEW]: "Analysis",
  [DOCUMENTS]: "Documents",
  [SETTINGS]: "Settings",
  [ANNOTATE_WORKLIST]: "Worklist",
  [ANNOTATE_MANAGE]: "Manage",
  [DASHBOARD]: "Dashboard",
  [TRIAL_SLUG]: "Trial",
  [GLOBAL_USER_SETTINGS]: "Manage Users",
  [TRIALS]: "Trials",
  [SUBJECTS]: "Subjects",
  [ANNOTATIONS]: "Annotations",
};

export function getRouteDisplayName(route: RoutePathType): string {
  if (typeof route === "number") {
    return route.toString();
  }

  return routePathDisplayNames[route] ?? "";
}
