import React, { useContext } from "react";
import { useRouteMatch } from "react-router";

import SideNavButton from "../../common/components/buttons/SideNavButton";
import { SubMenu } from "../../common/components/SideNav";
import { BasePathContext } from "../../common/routes/BasePathContextProvider";
import { useGoToRoute } from "./hooks/useGoToRoute";
import { Icons } from "./Icons";
import { MenuItemType } from "./Layout";
import { SubMenuItem } from "./SubMenuItem";
import { getRouteString } from "./utils/getRouteString";

interface MenuItemProps {
  item: MenuItemType;
}

export function MenuItem({ item }: MenuItemProps): JSX.Element {
  const goToProjectPath = useGoToRoute();

  const { label, icon: iconEnum, subItems, route } = item;
  const icon = Icons[iconEnum];

  const { basePath } = useContext(BasePathContext);
  const routeStr = getRouteString(route);
  const match = useRouteMatch(`${basePath}/${routeStr}`);
  const active = !!match;

  const handleClick = () => {
    goToProjectPath(route);
  };

  return (
    <>
      <SideNavButton text={label} icon={icon} active={active} onClick={handleClick} />
      <SubMenu active={active}>
        {subItems?.map((subItem, index) => (
          <SubMenuItem key={index} item={subItem} parentRoute={route} />
        ))}
      </SubMenu>
    </>
  );
}
