import {
  ClassificationModeType,
  ClassificationObjectsType,
  ClassificationsType,
  ConstraintType,
} from "../../../../Annotate/types/TaskDescriptionType";

export type SeriesClassificationsType = {
  values: ClassificationObjectsType[];
  constraint: ConstraintType;
};

export type ModeToClassificationObjectsMap = Partial<
  Record<ClassificationModeType, SeriesClassificationsType>
>;

export function parseSeriesClassifications(
  classifications: ClassificationsType[] | undefined
): ModeToClassificationObjectsMap {
  const modeClassificationsMap: ModeToClassificationObjectsMap = {};

  if (!classifications || classifications.length === 0) {
    return modeClassificationsMap;
  }

  classifications.forEach(({ mode, values, constraint }) => {
    if (!mode) {
      throw new Error("Mode should not be undefined here!");
    }

    modeClassificationsMap[mode] = {
      values: values as ClassificationObjectsType[],
      constraint,
    };
  });
  return modeClassificationsMap;
}
