import {
  AnnotateRulesType,
  TaskDescriptionType,
  TaskOptionsType,
} from "../../../../Annotate/types/TaskDescriptionType";

export function findEnabledOption(
  description: TaskDescriptionType | null,
  ruleToCheck: AnnotateRulesType
): TaskOptionsType | undefined {
  if (!description) {
    throw new Error("Description is null when selecting task options");
  }
  const { options } = description;

  if (!options) {
    return undefined;
  }

  const enabledOptions = options.filter(({ enabled }) => enabled);

  return enabledOptions.find(({ rule }) => rule === ruleToCheck);
}
