import React from "react";
import styled from "styled-components";

import { main } from "../../../../../../common/theme/main";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Circle = styled.div<{ isFirst: boolean; color: string }>`
  height: 8px;
  width: 8px;
  margin: 0 7px;
  padding: 3px 0;
  box-sizing: border-box;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
`;

const ProgressBar = styled.div<{ visible: boolean; color: string }>`
  width: 2px;
  visibility: ${({ visible }) => (visible ? "unset" : "hidden")};
  background-color: ${({ color }) => color};
`;

const TopProgressBar = styled(ProgressBar)`
  height: 10px;
  border-radius: 0 0 3px 3px;
  margin: 0 0 3px 0;
`;

const BottomProgressBar = styled(ProgressBar)`
  flex: 1;
  border-radius: 3px 3px 0 0;
  margin: 3px 0 0 0;
`;

export interface TimelineProgressProps {
  isFirst: boolean;
  isLast: boolean;
  loading?: boolean;
}

export function TimelineProgress({ isFirst, isLast }: TimelineProgressProps): JSX.Element {
  const color = main.colors.states.success;
  return (
    <Wrapper>
      <TopProgressBar visible={!isFirst} color={color} />
      <Circle isFirst={isFirst} color={color} />
      <BottomProgressBar visible={!isLast} color={color} />
    </Wrapper>
  );
}
