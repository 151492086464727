import React, { useContext } from "react";
import styled from "styled-components";

import { TimepointType } from "../../../../../Annotate/components/Annotate/page/AnnotationPanel/hooks/Timepoint/TimepointType";
import { timepointIncludeDay } from "../../../../../Annotate/components/Annotate/page/StudyPanel/utils/formatTimepointLabel";
import { FlexLoading } from "../../../../../common/components/Loading";
import { PatientContext } from "./PatientContext";
import { TimepointListItem } from "./TimepointListItem";

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 0 0 12px;
  overflow-y: scroll;
`;

const TitleWrapper = styled.div`
  font-size: 18px;
  font-weight: bolder;
  margin: 36px 0 12px 0;
`;

interface TimepointPanelProps {
  timepoints: TimepointType[];
  selected: TimepointType | undefined;
  onSelected: (seriesId: number, timepoint: TimepointType) => void;
}

export function TimepointPanel({
  timepoints,
  selected,
  onSelected,
}: TimepointPanelProps): JSX.Element {
  const { loading } = useContext(PatientContext);
  return (
    <>
      <TitleWrapper>Timepoints</TitleWrapper>
      <ListWrapper>
        {!loading ? (
          timepoints.map((timepoint, key) => {
            const includeDay = timepointIncludeDay(timepoints, key);
            return (
              <TimepointListItem
                timepoint={timepoint}
                key={key}
                onSelect={onSelected}
                selected={timepoint?.index === selected?.index}
                includeDay={includeDay}
              />
            );
          })
        ) : (
          <FlexLoading />
        )}
      </ListWrapper>
    </>
  );
}
