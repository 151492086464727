import React from "react";
import { useSelector } from "react-redux";

import { FlexLoading } from "../../../../../common/components/Loading";
import { seriesClassificationsSelector } from "../../../../../common/store/annotatePage/taskSelector";
import handleApolloError from "../../../../../common/utils/handleApolloError";
import { DEFAULT } from "../../../../enums/TaskDescriptionEnums";
import { useActiveViewerConfig } from "../ViewerPanel/Viewer/hooks/useActiveViewerConfig";
import { useStudiesList } from "./hooks/useStudiesList";
import { SeriesClassificationsList } from "./SeriesClassificationsList";

export function SeriesClassificationsListContainer(): JSX.Element {
  const availableClassificationModes = useSelector(seriesClassificationsSelector);

  const { data, loading, error } = useStudiesList();

  const activeViewer = useActiveViewerConfig();

  const classifications = availableClassificationModes[DEFAULT];

  if (!classifications || !activeViewer) {
    return <></>;
  }

  if (error) handleApolloError(error);
  if (loading) return <FlexLoading />;

  if (!data) {
    throw new Error("Series classifications are undefined!");
  }

  const { studies } = data;

  return (
    <SeriesClassificationsList
      studies={studies}
      availableClassifications={classifications}
      activeViewer={activeViewer}
    />
  );
}
