export function getTicksAtInterval(min: number, max: number, interval: number): number[] {
  const roundToNearest = (value: number, interval: number) => {
    if (value > 0) return Math.ceil(value / interval) * interval;
    else if (value < 0) return Math.ceil(value / interval) * interval;
    else return value;
  };

  const lower = roundToNearest(min, interval);
  const upper = roundToNearest(max, interval);

  const numberOfTicks = 1 + (upper - lower) / interval;
  if (numberOfTicks <= 0 || isNaN(interval)) {
    return [];
  }
  return [...Array(numberOfTicks).keys()].map((tick) => tick * interval + lower);
}

export function getTicksByNumber(
  min: number,
  max: number,
  numberOfTicks: number,
  minInterval: number
): number[] {
  const range = max - min;
  if (range === 0) {
    return [];
  }

  const tickSize = range / numberOfTicks;

  const quotient = tickSize / minInterval;
  const interval = Math.round(quotient) * minInterval;

  if (interval === 0) {
    return [];
  }

  return getTicksAtInterval(min, max, interval);
}
