import { captureException } from "@sentry/react";

import { initGA } from "./google-analytics";
import { initMixpanel } from "./mixpanel";
import { initSentry } from "./sentry";

export { useMixpanel } from "./mixpanel";

export const initTelemetry = () => {
  initGA();
  initMixpanel();
  initSentry();
};

export const silentTry = (fn: () => void) => {
  try {
    fn?.();
  } catch (e) {
    /*
    We want to capture the exception but not throw it for the user.
    This is because we don't want to block the user from using the app
    if for some reason mixpanel is blocked on their network, or if the
    mixpanel is not configured correctly. —B
    */
    console.warn(e);
    captureException(e);
  }
};
