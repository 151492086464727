import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import React, { useState } from "react";
import styled from "styled-components";

import { ReactComponent as Brightness } from "../../../../../../assets/svgs/Brightness.svg";
import { ReactComponent as KeyboardArrowDown } from "../../../../../../assets/svgs/KeyboardArrowDown.svg";
import { SvgIcon } from "../../../../../../common/components/icons/SvgIcon";
import { WindowLevelPresetType } from "../../../../../../common/store/annotatePage/WindowLevelPresetType";
import { ToolType } from "../../../../../../cornerstone/ToolType";
import { getToolbarButtonColor } from "./getToolbarButtonColor";

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: -2px;
  margin-right: -5px;
  cursor: pointer;
`;

interface ToolbarNodeProps {
  value: ToolType;
  currentValue: ToolType;
  onChange: (value: ToolType) => void;
  presets: WindowLevelPresetType[];
  onPresetSelected: (preset: WindowLevelPresetType) => void;
  tooltip?: string;
}

export function WindowLevelButton({
  value,
  currentValue,
  onChange,
  presets,
  onPresetSelected,
  tooltip,
}: ToolbarNodeProps): JSX.Element {
  const [hover, setHover] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  function handleMouseOver() {
    setHover(true);
  }

  function handleMouseOut() {
    setHover(false);
  }

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePresetSelected = (preset: WindowLevelPresetType) => {
    onPresetSelected(preset);
    closeMenu();
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const colour = getToolbarButtonColor(value === currentValue, hover);

  return (
    <>
      <Tooltip title={tooltip}>
        <IconWrapper
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          onClick={() => onChange(value)}
        >
          <SvgIcon icon={Brightness} size={20} color={colour} />
          <div onClick={handleOpen}>
            <SvgIcon icon={KeyboardArrowDown} size={20} color={colour} />
          </div>
        </IconWrapper>
      </Tooltip>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        {presets.map((preset) => {
          const { name } = preset;
          return (
            <MenuItem key={name} onClick={() => handlePresetSelected(preset)}>
              {name}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}
