import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { TaskDescriptionType } from "../../../Annotate/types/TaskDescriptionType";

export type TaskDataType = {
  projectId: number;
  patientId: number;
  taskId: number;
};

type State = {
  previousTasks: TaskDataType[];
  description: TaskDescriptionType | null;
};

const initialState: State = {
  previousTasks: [],
  description: null,
};

export const taskSlice = createSlice({
  name: "task",
  initialState,
  reducers: {
    pushPreviousTask: (state, { payload }: PayloadAction<TaskDataType>) => {
      const length = state.previousTasks.length;
      if (length === 0) {
        state.previousTasks.push(payload);
        return;
      }

      const { projectId: newProjectId, patientId: newPatientId, taskId: newTaskId } = payload;

      const { projectId, patientId, taskId } = state.previousTasks[length - 1];
      if (projectId === newProjectId && patientId === newPatientId && taskId === newTaskId) {
        return;
      }
      state.previousTasks.push(payload);
    },
    popPreviousTask: (state) => {
      state.previousTasks.pop();
    },
    setTaskDescription: (state, action: PayloadAction<TaskDescriptionType>) => {
      state.description = action.payload;
    },
  },
});

export const { pushPreviousTask, popPreviousTask, setTaskDescription } = taskSlice.actions;
