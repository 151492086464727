import React from "react";

import { ProjectType } from "../../../common/types/ProjectType";
import { getMonthDateTime } from "../../../common/utils/dateFormatUtils/getMonthDateTime";
import { RecentItem } from "../common/RecentItem";
import ProjectPreview from "./ProjectPreview";

interface RecentProjectProps {
  project: ProjectType;
  onClickProject: (project: ProjectType) => void;
  showKaplanMeierPreview: boolean;
}

export function RecentProject({
  project,
  onClickProject,
  showKaplanMeierPreview,
}: RecentProjectProps): JSX.Element {
  const { id, name, status, estimatedCompletion, indicationName, investigationName } = project;

  const handleClick = () => {
    onClickProject(project);
  };

  const fields = {
    "Indication Name": indicationName,
    "Investigational Product Name": investigationName,
    "Estimated Completion": getMonthDateTime(estimatedCompletion),
  };

  return (
    <RecentItem
      label={name}
      status={status}
      fields={fields}
      previewContent={showKaplanMeierPreview ? <ProjectPreview projectId={id} /> : <></>}
      onClicked={handleClick}
    />
  );
}
