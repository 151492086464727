import { useContext, useMemo } from "react";
import { useDeepCompareMemo } from "use-deep-compare";

import { useProjectId } from "../../../../../../../Project/hooks/useProjectId";
import { ANNOTATE_LIMIT_TO_BASELINE } from "../../../../../../enums/TaskDescriptionEnums";
import { TaskContext } from "../../../../../../TaskContext";
import { useAllStudiesList } from "../useAllStudiesList";
import { useStudiesList } from "../useStudiesList";
import { getTimepoints } from "./getTimepoints";
import { TimepointType } from "./TimepointType";

export type TimepointListType = {
  timepoints: TimepointType[];
  excludedStudyCount: number;
};

export function useTimepoints(): TimepointListType {
  const projectId = useProjectId();
  const { data } = useStudiesList();
  const { data: fullData } = useAllStudiesList();
  const { task } = useContext(TaskContext);
  const isLimitedToBaseline = useMemo(
    () =>
      task.taskDescription.data.options?.find(({ rule }) => rule === ANNOTATE_LIMIT_TO_BASELINE)
        ?.enabled ?? false,
    [task]
  );

  const { studies } = data ?? { studies: [] };
  const { studies: allStudies } = fullData ?? { studies: [] };
  const timepoints = useDeepCompareMemo(
    () => getTimepoints(studies, allStudies, projectId),
    [studies, allStudies, projectId]
  );

  // TODO: This will render a billion times on a page load, let's see if we can get this to render less
  // --B
  // console.count("⏰ useTimepoints");

  return isLimitedToBaseline
    ? timepoints.length
      ? {
          timepoints: [timepoints[0]],
          excludedStudyCount: allStudies.length - 1,
        }
      : { timepoints: [], excludedStudyCount: allStudies.length }
    : {
        timepoints: timepoints,
        excludedStudyCount: allStudies.length - studies.length,
      };
}
