import { useCallback } from "react";

import { useCurrentProject } from "../../../common/queries/useCurrentProject";

export function useGetProjectArmName(): (armNumber: number) => string {
  const { data: project } = useCurrentProject();
  if (!project) {
    throw new Error("Project not found");
  }

  const { arms } = project;

  return useCallback(
    (armNumber: number) => {
      const arm = arms.find((arm) => arm.number === armNumber);

      return arm?.name ?? "N/A";
    },
    [arms]
  );
}
