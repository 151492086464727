import React from "react";
import Select, { StylesConfig } from "react-select";

import { OptionType } from "../../../DataManagement/Upload/components/GenericMultiSelect";
import { main } from "../../theme/main";
import { ProjectType } from "../../types/ProjectType";

interface GenericSelectorProps {
  items: ProjectType[];
  selectedItem: number | null;
  onItemIdSelected: (itemId: number) => void;
  isDisabled: boolean;
  isLoading: boolean;
}

type optionType = { value: number; label: string };
type isMulti = false;

const selectStyle: StylesConfig<optionType, isMulti> = {
  control: (provided) => ({
    ...provided,
    "&:hover": {
      border: `1px solid ${main.colors.neutral.white}`,
    },
    background: `${main.colors.neutral.black}`,
    border: `1px solid ${main.colors.neutral.white10}`,
    boxShadow: "none",
    cursor: "pointer",
    minHeight: "48px",
    width: "144px",
  }),

  dropdownIndicator: (provided) => ({
    ...provided,
    "&:hover": {
      color: `${main.colors.neutral.white80}`,
    },
    color: `${main.colors.neutral.white80}`,
  }),

  menuList: (provided) => ({
    ...provided,
    color: `${main.colors.neutral.white}`,
    background: `${main.colors.neutral.black}`,
    borderRadius: "4px",
    paddingTop: 0,
    paddingBottom: 0,
  }),

  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      background: `${main.colors.actionPrimary.hover}`,
    },
    background: state.isSelected ? `${main.colors.actionPrimary.active}` : "inherit",
    color: `${main.colors.neutral.white}`,
    cursor: "pointer",
    fontWeight: 600,
  }),

  singleValue: (provided) => ({
    ...provided,
    color: `${main.colors.neutral.white}`,
  }),
};

export function GenericSelector({
  items,
  selectedItem,
  isDisabled,
  isLoading,
  onItemIdSelected,
}: GenericSelectorProps): JSX.Element {
  const options = items.map(({ legacyTrialId, name }) => {
    if (legacyTrialId === undefined) {
      throw new Error("Legacy trial id should not be undefined here");
    }
    return {
      value: legacyTrialId,
      label: name,
    };
  });

  const selectedOption = options.find((option) => option.value === selectedItem);

  if (selectedItem != null && !selectedOption && !isLoading) {
    throw new Error("Item does not exist in GenericSelector");
  }

  const handleChange = (selected: OptionType<number> | OptionType<number>[] | null) => {
    if (Array.isArray(selected) || selected == null) {
      throw new Error("Multi/no selection options should not be allowed");
    }

    const { value } = selected;
    onItemIdSelected(value);
  };

  return (
    <Select
      isDisabled={isDisabled || selectedItem == null}
      isLoading={isLoading}
      onChange={handleChange}
      options={options}
      placeholder={null}
      styles={selectStyle}
      value={selectedOption}
    />
  );
}
