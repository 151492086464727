import { FilterTypes } from "../../../../common/store/projectViewFiltersSlice";
import { DemographicsFilters } from "../../../../common/types/DemographicsFilterTypes";
import { LifestyleFilters } from "../../../../common/types/LifestyleFilterTypes";
import {
  AjccStageFilters,
  HistologyFilters,
  ProjectArmFilters,
  TumourMutationsFilters,
} from "../../../../common/types/ProjectViewFilterTypes";
import { ageFromDOB } from "../../../../common/utils/dateFormatUtils/ageFromDOB";
import { AjccStageType, getSimilarAjccStages } from "../../types/AjccStageType";
import { EthnicityType } from "../../types/EthnicityType";
import { SmokingStatusType } from "../../types/SmokingStatusType";
import { SubjectType } from "../../types/SubjectType";
import { TumourHistologyType } from "../../types/TumourHistologyType";
import { TumourMutationType } from "../../types/TumourMutationType";

function lifestyleIsEnabled(
  bmi: number | null,
  smokingStatus: SmokingStatusType | null,
  lifestyleFilters: LifestyleFilters
) {
  // Subject is in the enabled age range
  const bmiIsEnabled =
    bmi === null || valueInFilterRange(bmi, lifestyleFilters.bmi.min, lifestyleFilters.bmi.max);

  // Subject is in an enabled sex
  const smokingStatusIsEnabled =
    smokingStatus === null || lifestyleFilters.smokingStatus[smokingStatus];

  return bmiIsEnabled && smokingStatusIsEnabled;
}

function valueInFilterRange(value: number, min: number | null, max: number | null) {
  const minSatisfied = min === null ? true : min <= value;
  const maxSatisfied = max === null ? true : value <= max;
  return minSatisfied && maxSatisfied;
}

function demographicIsEnabled(
  dob: Date | null,
  sex: string | null,
  ethnicity: EthnicityType | null,
  demographicsFilters: DemographicsFilters
) {
  let ageIsEnabled;

  if (dob !== null) {
    const age = ageFromDOB(dob);
    // Subject is in the enabled age range
    ageIsEnabled = valueInFilterRange(
      age,
      demographicsFilters.age.min,
      demographicsFilters.age.max
    );
  } else {
    ageIsEnabled = true;
  }

  // Subject is in an enabled sex
  const sexIsEnabled =
    sex === null || demographicsFilters.sex[sex.toLowerCase() as keyof DemographicsFilters["sex"]];

  // Subject is in an enabled ethnicity
  const ethnicityIsEnabled = ethnicity === null || demographicsFilters.ethnicity[ethnicity];

  return ageIsEnabled && sexIsEnabled && ethnicityIsEnabled;
}

function mutationIsEnabled(
  mutation: TumourMutationType | null,
  tumourMutationFilters: TumourMutationsFilters
): boolean {
  if (mutation === null) {
    return true;
  }

  //TODO we shouldn't use partial types, since things can slip through the cracks
  // however we currently are to accommodate the buttons mapping
  // since we are using a partial type, its possible the filter is undefined, so lets include it
  return tumourMutationFilters[mutation] ?? true;
}

function histologyIsEnabled(
  histology: TumourHistologyType | null,
  histologyFilters: HistologyFilters
) {
  if (histology === null) {
    return true;
  }

  //TODO we shouldn't use partial types, since things can slip through the cracks
  // however we currently are to accommodate the buttons mapping
  // since we are using a partial type, its possible the filter is undefined, so lets include it
  return histologyFilters[histology] ?? true;
}

function ajccStageIsEnabled(ajccStage: AjccStageType | null, ajccStageFilters: AjccStageFilters) {
  if (ajccStage === null) {
    return true;
  }

  const ajccStageGroup = getSimilarAjccStages(ajccStage);

  // if the stage is not part of a known group, we can't filter it, so return true
  if (ajccStageGroup.length === 0) {
    return true;
  }

  return ajccStageGroup.some((stage) => ajccStageFilters[stage]);
}

// Given some filters, create a "filter function" which returns true if the passed subject passes the filters, and false otherwise
export const applySubjectLevelFilters =
  (filters: FilterTypes) =>
  ({
    dateOfBirth,
    sex,
    bmi,
    smokingStatus,
    ethnicity,
    projectArm,
    diagnosis,
    ecogPerformanceStatus,
  }: SubjectType): boolean => {
    const { ajccStage, histology, mutation } = diagnosis;
    const { number: projectArmNumber } = projectArm;

    const isInEnabledProjectArm = filters.projectArm[projectArmNumber as keyof ProjectArmFilters];

    const isInEnabledAjccStage = ajccStageIsEnabled(ajccStage, filters.stage.ajccStage);
    const isInEnabledHistology = histologyIsEnabled(histology, filters.histology);

    const isInEnabledMutation = mutationIsEnabled(mutation, filters.tumourMutations);

    const isInEnabledDemographic = demographicIsEnabled(
      dateOfBirth,
      sex,
      ethnicity,
      filters.demographics
    );

    const isInEnabledLifestyle = lifestyleIsEnabled(bmi, smokingStatus, filters.lifestyle);

    const isInEnabledEcogGroup =
      ecogPerformanceStatus === null || filters.ecog[ecogPerformanceStatus];

    return (
      isInEnabledProjectArm &&
      isInEnabledAjccStage &&
      isInEnabledHistology &&
      isInEnabledMutation &&
      isInEnabledDemographic &&
      isInEnabledLifestyle &&
      isInEnabledEcogGroup
    );
  };
