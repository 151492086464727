import styled from "styled-components";

export const AccentTop = styled.div<{
  accentColor?: string;
  selected: boolean;
  unselectedSize?: number;
  selectedSize?: number;
}>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: ${({ selected, unselectedSize = 2, selectedSize = 4 }) =>
    selected ? `${selectedSize}px` : `${unselectedSize}px`};
  background: ${({ accentColor }) => accentColor};
  z-index: 3;
`;
