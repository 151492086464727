import { ContourCallbackDataType } from "../../tools/contour/types/ContourCallbackDataType";
import { ContourContextMenuData } from "../../tools/contour/types/ContourContextMenuDataType";

export function verifyContourContextMenuData(
  data: ContourContextMenuData | undefined
): ContourContextMenuData {
  if (!data) {
    throw new Error("ContextMenu data is null and shouldn't be");
  }
  return data;
}

export function verifyContourClickedContextMenuData(
  data: ContourContextMenuData | undefined
): ContourCallbackDataType {
  const { clickedContour, roiId, imageId, toolData } = verifyContourContextMenuData(data);

  if (!clickedContour) {
    throw new Error("ContextMenu clickedContour field is false and shouldn't be");
  }

  if (roiId === undefined || imageId === undefined || toolData === undefined) {
    throw new Error("ContextMenu data fields are null and shouldn't be");
  }

  return {
    roiId,
    imageId,
    toolData,
  };
}
