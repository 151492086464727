export function capitalizeFirstLetter(s: string): string {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function capitalizeFirstAlpha(s: string): string {
  // finds the first alpha character and capitalizes it
  const firstAlphaIndex = s.search(/[a-zA-Z]/);
  if (firstAlphaIndex === -1) {
    return s;
  }
  return (
    s.slice(0, firstAlphaIndex) +
    s.charAt(firstAlphaIndex).toUpperCase() +
    s.slice(firstAlphaIndex + 1)
  );
}
