import { useApolloClient } from "@apollo/client";
import { useCallback } from "react";

import { sortSopInstances } from "../../../../../../utilities/sortSopInstances";
import { getSeriesInstancesFromCache } from "../../utils/getSeriesInstancesFromCache";

export function useGetSeriesImageIds(): (seriesId: number | undefined) => string[] {
  const { cache } = useApolloClient();

  return useCallback(
    (seriesId) => {
      if (seriesId === undefined) {
        return [];
      }

      const instances = sortSopInstances(getSeriesInstancesFromCache(seriesId, cache));

      return instances.map(({ wadoUrl }) => wadoUrl);
    },
    [cache]
  );
}
