import { TaskType } from "./TaskType";
import { useRawPatientTasksQuery } from "./useRawPatientTasksQuery";

type ReturnType = {
  projectTasks: TaskType[] | undefined;
  loading: boolean;
};

export function useProjectTasks(): ReturnType {
  const { data, loading } = useRawPatientTasksQuery();

  return {
    projectTasks: data?.tasks,
    loading,
  };
}
