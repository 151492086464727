import { CohortType } from "../../../../../common/types/CohortType";
import { OptionType } from "../../../../../DataManagement/Upload/components/GenericMultiSelect";

export type FilteredCohortType = CohortType | undefined;

export function getCohortOption(cohort: FilteredCohortType): OptionType<FilteredCohortType> {
  if (!cohort) {
    return {
      value: undefined,
      label: "Unassigned",
    };
  }

  const { name } = cohort;
  return { value: cohort, label: name };
}
