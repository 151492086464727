import { UserType } from "../../../../../common/types/UserType";
import { TaskProgressType } from "../../../TaskWorklist/TaskProgressType";

export type CombinedUserProgressFilterType = {
  progresses: (TaskProgressType | undefined)[];
  users: (UserType | undefined)[];
};

export function getNonNullCombinedUserProgressFilter(
  filterValue: unknown
): CombinedUserProgressFilterType {
  return (filterValue ?? {
    progresses: [],
    users: [],
  }) as CombinedUserProgressFilterType;
}
