import React from "react";
import styled from "styled-components";

import { ReactComponent as Close } from "../../../../../../assets/svgs/Close.svg";
import { SvgIcon } from "../../../../../../common/components/icons/SvgIcon";
import { TimepointOptionsType } from "../../../../../types/TaskDescriptionType";
import { LesionListItemFragmentType } from "../fragments/LesionListItemFragment";
import { RoiListItemFragmentType } from "../fragments/RoiListItemFragment";
import { Mask } from "../MaskContextProvider";
import { DownloadButton } from "../Masks/DownloadButton";
import { DownloadState } from "../Masks/types/downloadState";
import { RoiStatComponent } from "../Stats/useGetRoiStatComponents";
import { ClassificationLabel } from "./ClassificationLabel";
import { HeaderLabelText, HeaderWrapper } from "./CommonStyles";

const ActionButtonsWrapper = styled.div`
  flex: 0;
  display: flex;
  flex-direction: row;
  gap: 3px;
`;

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`;

const MeasurementWrapper = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.neutral.neutral6};
  text-align: right;
`;

const IconButton = styled.button`
  padding: 0;
  border: none;
  cursor: pointer;
  background: transparent;
`;

interface RoiCardHeaderProps {
  roi: RoiListItemFragmentType;
  label: string;
  options: TimepointOptionsType[];
  statComponents: RoiStatComponent[];
  onDeleteClick?: () => void;
  extraActionButtons?: JSX.Element[];
  lesion: LesionListItemFragmentType;
  isFromCurrentTask: boolean;
  canDelete: boolean;
  onToggleMaskVisibility: (mask: Mask) => Promise<boolean>;
  getMaskDownloadState: (maskId: number) => DownloadState | undefined;
  jumpToViewerRoi: (roi: RoiListItemFragmentType) => void;
}

export function RoiCardHeader({
  roi,
  label,
  options,
  statComponents,
  onDeleteClick,
  lesion,
  extraActionButtons,
  isFromCurrentTask,
  canDelete,
  onToggleMaskVisibility,
  getMaskDownloadState,
  jumpToViewerRoi,
}: RoiCardHeaderProps): JSX.Element | null {
  const primaryMask = roi.masks[0];

  const { location: anatomicalStructureId } = lesion;

  const {
    series: { id: seriesId },
  } = roi;

  const handleViewMask = async (e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation();

    if (!primaryMask) {
      throw new Error("No mask found for roi");
    }

    const visible = await onToggleMaskVisibility({
      ...primaryMask,
      seriesId,
      anatomicalStructureId,
    });

    if (!visible) {
      return;
    }

    jumpToViewerRoi(roi);
  };

  return (
    <HeaderWrapper>
      <HeaderLabelText>{label}</HeaderLabelText>
      <ContentWrapper>
        {isFromCurrentTask && (
          <MeasurementWrapper>
            {options.map((option, index) => (
              <ClassificationLabel key={index} option={option} />
            ))}
            {statComponents.map((Component, key) => (
              <Component roi={roi} lesion={lesion} key={key} />
            ))}
          </MeasurementWrapper>
        )}
        {primaryMask && (
          <DownloadButton
            state={getMaskDownloadState(primaryMask.id)}
            onClick={handleViewMask}
            plural={false}
          />
        )}
        <ActionButtonsWrapper>
          {canDelete && !!onDeleteClick && (
            <IconButton onClick={onDeleteClick}>
              <SvgIcon icon={Close} size={16} />
            </IconButton>
          )}
          {extraActionButtons ?? []}
        </ActionButtonsWrapper>
      </ContentWrapper>
    </HeaderWrapper>
  );
}
RoiCardHeader.whyDidYouRender = true;
