import React from "react";

import { useTumorClassificationFilteredSubjectsQuery } from "../../common/hooks/useTumorClassificationFilteredSubjectsQuery";
import { getPatientJourneySubjects } from "../utils/parsePatientJourneyData";
import PatientJourneyChart from "./PatientJourneyChart";

interface AnalysisOverviewPatientJourneyProps {
  onClickPatient?: (patientId: number) => void;
}
function AnalysisOverviewPatientJourney({
  onClickPatient,
}: AnalysisOverviewPatientJourneyProps): React.ReactElement {
  const subjects = useTumorClassificationFilteredSubjectsQuery();

  const subjectResponseProgressions = getPatientJourneySubjects(subjects);

  return (
    <PatientJourneyChart
      subjectResponseProgressions={subjectResponseProgressions}
      onClickPatient={onClickPatient}
    />
  );
}

export default AnalysisOverviewPatientJourney;
