import { useDialogReturnType } from "../../../common/components/Dialog/useDialog";
import { useFormDialog } from "../../../common/components/Dialog/useFormDialog";
import { S3BucketConfigForm } from "./S3BucketConfigForm";

export function useS3BucketConfigForm(): useDialogReturnType {
  return useFormDialog({
    children: S3BucketConfigForm,
    label: "Configure S3 Bucket Upload",
    showCloseButton: false,
    reactModalProps: {
      shouldCloseOnOverlayClick: false,
      shouldCloseOnEsc: false,
    },
    props: undefined,
  });
}
