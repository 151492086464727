import React from "react";
import styled from "styled-components";

import { ReactComponent as PlusOutlineFilled } from "../../../../../assets/svgs/PlusOutlineFilled.svg";
import { SvgIcon } from "../../../../../common/components/icons/SvgIcon";
import { SeriesClassificationsType } from "../../../../../common/store/annotatePage/utils/parseSeriesClassifications";
import { ViewerConfigType } from "../../../../../common/store/annotatePage/ViewerConfigType";
import { main } from "../../../../../common/theme/main";
import { ClassificationLabel } from "../ViewerPanel/ClassificationLabel";
import { useAreSeriesClassificationsViewOnly } from "./hooks/useAreSeriesClassificationsViewOnly";
import { StudyListItemFragmentType } from "./hooks/useStudiesList";
import { IconButton } from "./IconButton";
import { InsertSeriesClassificationsWrapper } from "./InsertSeriesClassificationsWrapper";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  cursor: pointer;
`;

interface SeriesClassificationsListProps {
  studies: StudyListItemFragmentType[];
  availableClassifications: SeriesClassificationsType;
  activeViewer: ViewerConfigType;
}

export function SeriesClassificationsList({
  studies,
  availableClassifications,
  activeViewer,
}: SeriesClassificationsListProps): JSX.Element {
  const viewOnly = useAreSeriesClassificationsViewOnly(activeViewer);

  const { values: availableClassificationsValues, constraint } = availableClassifications;

  if (viewOnly) {
    return <></>;
  }

  const { seriesId: activeViewerSeriesId } = activeViewer;

  const activeSeries = studies
    .flatMap(({ series }) => series)
    .find(({ id }) => id === activeViewerSeriesId);

  if (!activeSeries) {
    throw new Error("Could not find active series in studies list!");
  }

  const filteredAvailableClassificationsValues = availableClassificationsValues.filter(
    ({ readOnly }) => !readOnly
  );

  return (
    <Wrapper>
      {filteredAvailableClassificationsValues.map(({ value, displayName }, key) => {
        const label = displayName ?? value;

        return (
          <InsertSeriesClassificationsWrapper
            key={key}
            series={activeSeries}
            constraint={constraint}
          >
            {({ onClick }) => (
              <ItemWrapper onClick={() => onClick(value)}>
                <IconButton>
                  <SvgIcon icon={PlusOutlineFilled} color={main.colors.neutral.black} size={20} />
                </IconButton>
                <ClassificationLabel text={label} key={key} onClick={() => undefined} />
              </ItemWrapper>
            )}
          </InsertSeriesClassificationsWrapper>
        );
      })}
    </Wrapper>
  );
}
