import React from "react";

import { ViewerConfigType } from "../../../../../common/store/annotatePage/ViewerConfigType";
import { ContourContextMenuData } from "./tools/contour/types/ContourContextMenuDataType";
import { CopyDeleteContourContextMenu } from "./Viewer/contextMenus/CopyDeleteContourContextMenu";
import { PasteContourContextMenu } from "./Viewer/contextMenus/PasteContourContextMenu";

interface ContourContextMenuProps {
  viewerConfig: ViewerConfigType;
  event?: ContourContextMenuData;
  onClose: () => Promise<void>;
}

export function ContourContextMenu({
  viewerConfig,
  event,
  onClose,
}: ContourContextMenuProps): JSX.Element {
  const { clickedContour } = event ?? { clickedContour: undefined };

  if (clickedContour) {
    return (
      <CopyDeleteContourContextMenu viewerConfig={viewerConfig} onClose={onClose} event={event} />
    );
  }

  return <PasteContourContextMenu viewerConfig={viewerConfig} onClose={onClose} event={event} />;
}
