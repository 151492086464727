import { PatientMutableTaskType } from "../../Annotate/components/Manage/hooks/TaskType";

export type PatientTaskPair = {
  patientId: number;
  taskId: number;
};

export function getPatientTaskPairs(patientsTasks: PatientMutableTaskType[]): PatientTaskPair[] {
  return patientsTasks
    .filter(({ task }) => task)
    .flatMap(({ id, task }) => {
      if (!task) {
        return [];
      }

      return [{ patientId: id, taskId: task.id }];
    });
}
