import React from "react";
import styled from "styled-components";

const StyledTextInput = styled.input`
  border: 0.5px solid ${(props) => props.theme.colors.neutral.neutral4};
  outline: unset;
  padding: 12px 12px 12px 40px;
  box-sizing: border-box;
  border-radius: 8px;
  background-image: url("data:image/svg+xml, %3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M15.5%2014h-.79l-.28-.27C15.41%2012.59%2016%2011.11%2016%209.5%2016%205.91%2013.09%203%209.5%203S3%205.91%203%209.5%205.91%2016%209.5%2016c1.61%200%203.09-.59%204.23-1.57l.27.28v.79l5%204.99L20.49%2019l-4.99-5zm-6%200C7.01%2014%205%2011.99%205%209.5S7.01%205%209.5%205%2014%207.01%2014%209.5%2011.99%2014%209.5%2014z%22%20fill%3D%22%236D7580%22%2F%3E%3C%2Fsvg%3E");
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 13px;
  width: 100%;

  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
    cursor: pointer;

    height: 14px;
    width: 14px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23000000'%3E%3E%3Cpath d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z'/%3E%3C/svg%3E");
    background-size: 14px;
    background-repeat: no-repeat;
  }
`;

interface SearchTextFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  placeholder?: string;
}

function SearchTextField({
  placeholder = "Search",
  ...props
}: SearchTextFieldProps): React.ReactElement<React.InputHTMLAttributes<HTMLInputElement>> {
  return <StyledTextInput type="search" placeholder={placeholder} {...props} />;
}

export default SearchTextField;
