import React, { useMemo } from "react";
import styled from "styled-components";

import { useProjectId } from "../../../Project/hooks/useProjectId";
import { makeStudyRowsSelector } from "../../store/seriesSelectionSlice";
import { useAppSelector } from "../../store/store";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 50px;
`;

const StatWrapper = styled.div`
  font-size: 13px;
`;

const NumberSpan = styled.span`
  font-weight: bold;
`;

interface StudyTableStatsProps {
  tableKey: string;
}

export function StudyTableStats({ tableKey }: StudyTableStatsProps): JSX.Element {
  const studyRowsSelector = useMemo(makeStudyRowsSelector, []);

  const rows = useAppSelector((state) => studyRowsSelector(state, tableKey));

  const currentProjectId = useProjectId();

  const totalNumberOfPatients = [...new Set(rows.map(({ patientId }) => patientId))].length;

  const totalNumberOfStudies = rows.length;

  const totalNumberOfExcludedStudies = rows.filter(({ seriesRows }) =>
    seriesRows.every(({ projectExclusions }) =>
      projectExclusions.some(({ projectId }) => currentProjectId === projectId)
    )
  ).length;

  const totalNumberOfSeries = rows.flatMap(({ seriesRows }) => seriesRows).length;

  const totalNumberOfExcludedSeries = rows
    .flatMap(({ seriesRows }) => seriesRows)
    .filter(({ projectExclusions }) =>
      projectExclusions.some(({ projectId }) => currentProjectId === projectId)
    ).length;

  return (
    <Wrapper>
      <StatWrapper>
        Total Number of Subjects: <NumberSpan>{totalNumberOfPatients}</NumberSpan>
      </StatWrapper>
      <StatWrapper>
        Total Number of Studies: <NumberSpan>{totalNumberOfStudies}</NumberSpan> (
        <NumberSpan>{totalNumberOfExcludedStudies}</NumberSpan> Excluded)
      </StatWrapper>
      <StatWrapper>
        Total Number of Series: <NumberSpan>{totalNumberOfSeries}</NumberSpan> (
        <NumberSpan>{totalNumberOfExcludedSeries}</NumberSpan> Excluded)
      </StatWrapper>
    </Wrapper>
  );
}
