import { useApolloClient } from "@apollo/client";
import { useContext } from "react";
import { useSelector } from "react-redux";

import { useCurrentUser } from "../../../../../../common/contexts/UserContext/useCurrentUser";
import { recistTaskSelector } from "../../../../../../common/store/annotatePage/taskSelector";
import { PatientContext } from "../../../../../../Dashboard/components/Settings/Patients/Viewer/PatientContext";
import { TaskContext } from "../../../../../TaskContext";
import { LesionTypeType } from "../../../../../types/LesionTypeType";
import { getRoiQueryCondition } from "../fragments/LesionListItemFragment";
import { TimepointType } from "./Timepoint/TimepointType";
import { useTimepointsNonViewOnlySeries } from "./Timepoint/useTimepointsNonViewOnlySeries";
import { getVariables, useInsertLesion } from "./useInsertLesion";
import { useTrySelectLesionId } from "./useTrySelectLesionId";

export interface CreateLesionProps {
  anatomicalStructureId: number;
  name: string;
  type: LesionTypeType;
}

type ReturnType = {
  createLesion: ({ anatomicalStructureId, name, type }: CreateLesionProps) => Promise<void>;
  loading: boolean;
};

export function useCreateLesion(): ReturnType {
  const trySelectLesionId = useTrySelectLesionId();

  const {
    task: { id: taskId, projectId, restrictedCohorts },
  } = useContext(TaskContext);

  const { id: patientId } = useContext(PatientContext);

  const { id: userId } = useCurrentUser();

  const timepoints = useTimepointsNonViewOnlySeries();

  const [insertLesion, { loading }] = useInsertLesion();

  const isRecist = useSelector(recistTaskSelector);

  const { cache } = useApolloClient();

  const createLesion = async ({ anatomicalStructureId, name, type }: CreateLesionProps) => {
    if (!timepoints) {
      throw new Error("No timepoints when trying to create lesion");
    }

    const seriesIds = getSeriesIdsToCreateRoisFor(timepoints);

    const roiCondition = getRoiQueryCondition(
      projectId,
      restrictedCohorts.map(({ id }) => id)
    );

    const variables = getVariables({
      taskId,
      roiCondition,
      patientId,
      type,
      location: anatomicalStructureId,
      name,
      createdBy: userId,
      seriesIds,
      isRecist,
      cache,
    });

    const { data } = await insertLesion({
      variables,
    });
    if (!data) {
      return;
    }

    const {
      insert_lesion_one: { id: lesionId },
    } = data;

    trySelectLesionId(lesionId);
  };

  return { createLesion, loading };
}

function getSeriesIdsToCreateRoisFor(timepoints: TimepointType[]): number[] {
  if (timepoints.length === 0) {
    return [];
  }

  const { series: firstTimepointSeries } = timepoints[0];
  if (firstTimepointSeries.length === 0 || firstTimepointSeries.length > 1) {
    return [];
  }

  const firstTimepointSeriesId = firstTimepointSeries[0].id;
  return [firstTimepointSeriesId];
}
