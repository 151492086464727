import React, { FC, useContext } from "react";

import { ProjectContext } from "../../../Project/contexts/ProjectContext";
import { parseEnrollmentData } from "../utils/parseEnrolmentChartData";
import EnrolmentChart from "./EnrolmentChart";

export const EnrolmentChartContainer: FC = () => {
  const { patients } = useContext(ProjectContext);

  const processedData = parseEnrollmentData(patients);
  return <EnrolmentChart enrolmentData={processedData} />;
};
