import { TimepointOptionsType } from "../../../../../../types/TaskDescriptionType";
import { hasClassification } from "../../../StudyPanel/utils/TumourResponse/utils/hasClassification";
import { LesionListItemFragmentType } from "../../fragments/LesionListItemFragment";
import { RoiListItemFragmentType } from "../../fragments/RoiListItemFragment";
import { useIsBaselineRoi } from "../useIsBaselineRoi";
import { useGetTimepointOptionsForLesion } from "./useGetTimepointOptionsForLesion";

type ReturnType = (
  lesion: LesionListItemFragmentType,
  roi: RoiListItemFragmentType
) => {
  setOptions: TimepointOptionsType[];
  unsetOptions: TimepointOptionsType[];
};

export function useGetExistingAvailableRoiClassifications(): ReturnType {
  const isBaselineRoi = useIsBaselineRoi();
  const getTimepointOptionsForLesion = useGetTimepointOptionsForLesion();

  return (lesion, roi) => {
    const { roiRecistEvaluation } = roi;

    const isBaseline = isBaselineRoi(roi);

    const options = getTimepointOptionsForLesion(lesion);

    const hasClassifiedOption = options.some(({ rule }) => hasClassification(roi, rule));

    const setOptions: TimepointOptionsType[] = [];
    const unsetOptions: TimepointOptionsType[] = [];

    if (hasClassifiedOption) {
      return { setOptions, unsetOptions };
    }

    for (const option of options) {
      const {
        showAtBaseline,
        showAtFollowUp,
        hideIfMeasurementsExist,
        showWhenExists,
        showWhenClassified,
        rule,
      } = option;

      const showAtTimepoint = isBaseline ? showAtBaseline : showAtFollowUp;
      if (!showAtTimepoint) {
        continue;
      }

      const { LAD, SAD } = roiRecistEvaluation || {};
      const hasMeasurements = !!LAD && !!SAD;

      const isClassified = hasClassification(roi, rule);

      if (!isClassified && !showWhenExists) {
        continue;
      }

      if (!isClassified && hideIfMeasurementsExist && hasMeasurements) {
        continue;
      }

      if (isClassified) {
        if (showWhenClassified) {
          setOptions.push(option);
        }
      } else {
        unsetOptions.push(option);
      }
    }
    return { setOptions, unsetOptions };
  };
}
