import React, { FC } from "react";

import { useTimepointFilteredSubjectsQuery } from "../../common/hooks/useTimepointFilteredSubjectsQuery";
import { generateKaplanMeierData } from "../utils/generateKaplanMeierData";
import KaplanChart from "./KaplanChart";
import { kaplanMeierAttributes } from "./kaplanMeierAttributes";

export const KaplanChartContainer: FC = () => {
  const subjects = useTimepointFilteredSubjectsQuery();

  const kaplanMeierData = generateKaplanMeierData(subjects);
  return <KaplanChart data={kaplanMeierData} attributes={kaplanMeierAttributes} />;
};
