import Collapse from "@mui/material/Collapse";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import styled from "styled-components";

import { ExpandLessIcon } from "../../../common/components/icons/ExpandLessIcon";
import { ExpandMoreIcon } from "../../../common/components/icons/ExpandMoreIcon";
import Heading3 from "../../../common/components/text/Heading3";
import { ProjectFilterSelectors } from "../../../common/store/selectors/ProjectFilterSelectors";
import { FilterHeaderButtons } from "./FilterHeaderButtons";

const StyledListItem = styled(ListItemButton)`
  box-shadow: inset 0px -1px 0px ${(props) => props.theme.colors.neutral.neutral7};
`;

const StyledCollapse = styled(Collapse)`
  box-shadow: inset 0px -1px 0px ${(props) => props.theme.colors.neutral.neutral7};
`;

const StyledCollapser = styled.div`
  padding: 16px;
`;

interface FilterPanelProps<T> {
  title: string;
  selectAllAction: FilterActionType;
  clearAction: FilterActionType;
  children: JSX.Element | JSX.Element[];
  filterSelectors: ProjectFilterSelectors<T>;
}

type FilterActionType = () => AnyAction;

export function FilterPanel<T>({
  title,
  children,
  filterSelectors,
  selectAllAction,
  clearAction,
}: FilterPanelProps<T>): JSX.Element {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState<boolean>(true);

  const allSelected = useSelector(filterSelectors.getAllFiltersSelected());
  const noSelected = useSelector(filterSelectors.getNoFiltersSelected());

  const handleExpandClicked = () => {
    setExpanded(!expanded);
  };

  const handleSelectAllClicked = () => {
    dispatch(selectAllAction());
  };

  const handleClearClicked = () => {
    dispatch(clearAction());
  };

  return (
    <>
      <StyledListItem onClick={handleExpandClicked}>
        <Heading3 title={title} />
        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}

        <ListItemSecondaryAction
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
        >
          <FilterHeaderButtons
            onSelectAll={handleSelectAllClicked}
            onClear={handleClearClicked}
            allSelected={allSelected}
            noneSelected={noSelected}
          />
        </ListItemSecondaryAction>
      </StyledListItem>
      <StyledCollapse in={expanded} timeout="auto" unmountOnExit>
        <StyledCollapser>{children}</StyledCollapser>
      </StyledCollapse>
    </>
  );
}
