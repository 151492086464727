import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { QueryResult } from "@apollo/client/react/types/types";

import { DEBUG_STUDIES_LIST_LIMIT } from "../../../../../../Annotate/components/Annotate/page/AnnotationPanel/hooks/debugUtils";
import { SERIES_NOTE_FRAGMENT } from "../../../../../../Annotate/components/Annotate/page/AnnotationPanel/hooks/useStudiesList";
import { BASE_WADO_URL_FRAGMENT } from "../../../../../../Annotate/fragments/BaseWadoUrlFragment";
import { SERIES_CLASSIFICATION_FRAGMENT } from "../../../../../../Annotate/fragments/SeriesClassificationFragment";
import { StudyFragmentType } from "../../../../../../Annotate/fragments/StudyFragmentType";

const INSTANCE_FRAGMENT = gql`
  fragment Instance on instance {
    id: instance_id
    seriesId: series_id
    sopInstanceUid: sop_instance_uid
    instanceNumber: instance_number
    sliceThickness: slice_thickness
    imagePositionPatient: image_position_patient
    imageOrientationPatient: image_orientation_patient
  }
`;

const SERIES_FRAGMENT = gql`
  fragment PatientSeries on series {
    id: series_id
    studyId: study_id
    seriesInstanceUid: series_instance_uid
    seriesDescription: series_description
    seriesNumber: series_number
    seriesDate: series_date
    projectSeriesExclusions: project_series_exclusions {
      projectId: project_id
    }
    seriesClassifications: series_classifications {
      ...SeriesClassification
    }
    displayName: display_name
    instances {
      ...Instance
    }
    notes: series_notes {
      ...SeriesNotes
    }
  }
  ${INSTANCE_FRAGMENT}
  ${SERIES_CLASSIFICATION_FRAGMENT}
  ${SERIES_NOTE_FRAGMENT}
`;

const STUDY_NOTE_FRAGMENT = gql`
  fragment StudyNote on study_notes {
    id: study_notes_id
    studyId: study_id
    lastModifiedByUserId: last_modified_by_user_id
    lastModifiedAt: last_modified
    notes
  }
`;

const STUDY_FRAGMENT = gql`
  fragment PatientStudy on study {
    accessionNumber: accession_number
    created
    lastModified: last_modified
    patientDob: patient_dob
    patientId: patient_id
    patientName: patient_name
    studyDate: study_date
    studyDescription: study_description
    studyDicomId: study_dicom_id
    id: study_id
    studyInstanceUid: study_instance_uid
    studyTime: study_time
    notes: study_note {
      ...StudyNote
    }
    series {
      ...PatientSeries
    }
  }
  ${SERIES_FRAGMENT}
  ${STUDY_NOTE_FRAGMENT}
`;

const QUERY = gql`
  query GetPatientData($id: Int!) {
    patient: patient_by_pk(patient_id: $id) {
      id: patient_id
      patientDicomId: patient_dicom_id
      tempDemoPatients: temp_demo_patients {
        id: patient_id
      }
      studies(limit: ${DEBUG_STUDIES_LIST_LIMIT}) {
        ...PatientStudy
      }
    }
    ...BaseWadoUrl
  }
  ${STUDY_FRAGMENT}
  ${BASE_WADO_URL_FRAGMENT}
`;

export type Data = {
  patient: {
    id: number;
    patientDicomId: string;
    tempDemoPatients: { id: number }[];
    studies: StudyFragmentType[];
  };
};

export type Variables = {
  id: number;
};

export function usePatientData(patientId: number): QueryResult<Data, Variables> {
  return useQuery<Data, Variables>(QUERY, {
    variables: { id: patientId },
  });
}
