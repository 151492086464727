import { ApolloCache } from "@apollo/client/core";

import { LesionsListQueryInput } from "../fragments/LesionListItemFragment";
import { RoiListItemFragmentType } from "../fragments/RoiListItemFragment";
import { getLesionsListFromCache } from "./getLesionsListFromCache";

export function getRoiFromCache<T>(
  lesionListQueryInput: LesionsListQueryInput,
  roiId: number,
  cache: ApolloCache<T>
): RoiListItemFragmentType | undefined {
  const lesions = getLesionsListFromCache(lesionListQueryInput, cache);
  const rois = lesions.flatMap(({ rois }) => rois);
  return rois.find(({ id }) => id === roiId);
}
