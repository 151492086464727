import cornerstoneTools from "@altis-labs/cornerstone-tools";
import { ApolloCache } from "@apollo/client";

import { ROI } from "../../../../../../../cornerstone/ToolType";
import { getSeriesInstancesFromCache } from "../../../ViewerPanel/utils/getSeriesInstancesFromCache";

export function getRoiVolumeMm3<T>(
  roiId: number,
  seriesId: number,
  cache: ApolloCache<T>
): number | undefined {
  const instanceAreas = getRoiContourAreas(roiId);
  const instances = getSeriesInstancesFromCache(seriesId, cache);

  let totalArea = 0;

  for (const [imageId, area] of Object.entries(instanceAreas)) {
    const matchingInstance = instances.find(({ wadoUrl }) => wadoUrl === imageId);
    if (!matchingInstance) {
      throw new Error("No matching instance found");
    }

    const { sliceThickness } = matchingInstance;
    if (sliceThickness === null) {
      continue;
    }

    totalArea += area * sliceThickness;
  }

  // convert to cc
  return totalArea / 1000;
}

function getRoiContourAreas(roiId: number): Record<string, number> {
  const toolStates = cornerstoneTools.globalImageIdSpecificToolStateManager.toolState;

  const areas: Record<string, number> = {};

  for (const imageId of Object.keys(toolStates)) {
    if (!toolStates[imageId][ROI]) {
      continue;
    }

    const { data } = toolStates[imageId][ROI];
    for (const toolData of data) {
      const {
        invalidated,
        roi: { id },
        area,
      } = toolData;
      if (roiId !== id || invalidated) {
        continue;
      }

      if (area === undefined) {
        throw new Error("Area is not present in cornerstone contour data");
      }

      // add area to areas or add to existing area
      areas[imageId] = areas[imageId] ? areas[imageId] + area : area;
    }
  }

  return areas;
}
