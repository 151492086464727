import { ApolloCache } from "@apollo/client";

export function getRoiCacheId<T>(roiId: number, cache: ApolloCache<T>): string {
  const id = cache.identify({
    id: roiId,
    __typename: "roi",
  });

  if (!id) {
    throw new Error("Unable to construct roi cache id");
  }

  return id;
}
