import React from "react";
import styled from "styled-components";

import { ProjectType } from "../../../common/types/ProjectType";
import { ItemsCard } from "../common/ItemsCard";
import { RecentProject } from "./RecentProject";

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 10px;
  padding-bottom: 10px;
  overflow-x: scroll;
`;

interface RecentProjectsProps {
  recentProjects: ProjectType[];
  onClickProject: (project: ProjectType) => void;
  showKaplanMeierPreview: boolean;
}

export function RecentProjects({
  recentProjects,
  onClickProject,
  showKaplanMeierPreview,
}: RecentProjectsProps): JSX.Element {
  return (
    <ItemsCard label={"Recent Projects"}>
      <ContentWrapper>
        {recentProjects.slice(0, 3).map((project, index) => (
          <RecentProject
            key={index}
            project={project}
            onClickProject={onClickProject}
            showKaplanMeierPreview={showKaplanMeierPreview}
          />
        ))}
      </ContentWrapper>
    </ItemsCard>
  );
}
