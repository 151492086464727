import { ApolloCache } from "@apollo/client/core";

import { sortSopInstances } from "../../../../../../../../utilities/sortSopInstances";
import { getRoiContoursFromCache } from "../../../../../ViewerPanel/utils/getRoiContoursFromCache";
import {
  CacheInstanceType,
  getSeriesInstancesFromCache,
} from "../../../../../ViewerPanel/utils/getSeriesInstancesFromCache";
import { RoiListItemFragmentType } from "../../../../fragments/RoiListItemFragment";
import { findIndexGaps } from "./findIndexGaps";

export function findMissingRoiContourInstances<T>(
  { id: roiId, series: { id: seriesId } }: RoiListItemFragmentType,
  cache: ApolloCache<T>
): CacheInstanceType[] {
  const instances = sortSopInstances(getSeriesInstancesFromCache(seriesId, cache));

  const roiContourInstanceIds = getRoiContoursFromCache(roiId, cache);

  const contourIndices = roiContourInstanceIds.map((id) => {
    const index = instances.findIndex(({ id: instanceId }) => id === instanceId);
    if (index === -1) {
      throw new Error(`Contour with instance id ${id} not found in series with id ${seriesId}`);
    }
    return index;
  });

  const missingIndices = findIndexGaps(contourIndices);

  return missingIndices.map((index) => instances[index]);
}
