import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { MutationTuple } from "@apollo/client/react/types/types";

import { FlattedContourToolDataType } from "../../../../../../../types/FlattedContourToolDataType";
import { ROI_RECIST_EVALUATION_FRAGMENT } from "../../../../AnnotationPanel/fragments/RoiRecistEvaluationFragment";
import { getRoiCacheId } from "../../../../AnnotationPanel/hooks/getRoiCacheId";
import { updateRoiRecistEvaluationsInCache } from "../../../../AnnotationPanel/hooks/updateRoiRecistEvaluationsInCache";
import {
  Data as UpsertRoiRecistEvaluationData,
  INTERNAL_MUTATION as UPSERT_ROI_RECIST_EVALUATION_MUTATION,
  Variables as UpsertRoiRecistEvaluationVariables,
} from "./useUpsertRoiRecistEvaluation";

type ContourMutationFragmentType = {
  id: number;
  instanceId: number;
  roiId: number;
};

/*
instanceId is important here since we use it in key_image_id via the cache
 */
const CONTOUR_MUTATION_FRAGMENT = gql`
  fragment ContourInsertMutation on contour {
    id: contour_id
    instanceId: instance_id
    roiId: roi_id
  }
`;

const MUTATION = gql`
  mutation InsertContour(
    $toolData: jsonb!
    $version: String!
    $roiId: Int!
    $instanceId: Int!
    $createdBy: Int!
    $roiRecistEvaluations: [roi_recist_evaluations_insert_input!]!
  ) {
    insert_contour_one(
      object: {
        roi_id: $roiId
        instance_id: $instanceId
        version: $version
        created_by: $createdBy
        tool_data: $toolData
      }
    ) {
      ...ContourInsertMutation
    }
    ${UPSERT_ROI_RECIST_EVALUATION_MUTATION}
  }
  ${CONTOUR_MUTATION_FRAGMENT}
  ${ROI_RECIST_EVALUATION_FRAGMENT}
`;

type Variables = {
  toolData: FlattedContourToolDataType[];
  version: string;
  roiId: number;
  instanceId: number;
  createdBy: number;
} & UpsertRoiRecistEvaluationVariables;

type Data = {
  insert_contour_one: ContourMutationFragmentType;
} & UpsertRoiRecistEvaluationData;

export function useInsertContour(): MutationTuple<Data, Variables> {
  return useMutation<Data, Variables>(MUTATION, {
    update(cache, { data }) {
      if (!data) {
        throw new Error("Something went wrong updating the cache after inserting a contour");
      }
      const {
        insert_contour_one,
        insert_roi_recist_evaluations: { roiRecistEvaluations },
      } = data;
      const { roiId } = insert_contour_one;

      cache.modify({
        id: getRoiCacheId(roiId, cache),
        fields: {
          contours(contourRefs = []) {
            const newContourRef = cache.writeFragment({
              data: insert_contour_one,
              fragment: gql`
                fragment Roi on roi {
                  id
                  type
                }
              `,
            });
            return [...contourRefs, newContourRef];
          },
        },
      });
      updateRoiRecistEvaluationsInCache(roiRecistEvaluations, cache);
    },
  });
}
