import { StylesConfig } from "react-select";
import { OptionTypeBase } from "react-select/src/types";

import { main } from "../../../../../common/theme/main";

const {
  colors: {
    actionPrimary: { active: black },
    neutral: { neutral4, neutral6, white },
    primary: { primary20: hover, primary30: active, primary40: selected },
  },
} = main;

export function getClassificationsSelectStyle<
  OptionType extends OptionTypeBase,
  IsMulti extends boolean
>(): StylesConfig<OptionType, IsMulti> {
  return {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    control: (styles, { isDisabled, hasValue }) => ({
      ...styles,
      borderWidth: "0",
      boxShadow: "none",
      fontSize: "12px",
      fontWeight: 600,
      backgroundColor: isDisabled || hasValue ? "unset" : white,
      height: "100%",
      maxHeight: "100%",
      minHeight: "28px",
      border: `1px solid ${isDisabled || hasValue ? "unset" : neutral6}`,
      cursor: isDisabled ? "pointer" : "unset",

      "&:hover": {
        borderColor: neutral4,
      },
    }),
    placeholder: (styles) => ({
      ...styles,
      color: black,
    }),
    dropdownIndicator: (styles, { isDisabled, hasValue }) => ({
      ...styles,
      padding: "0 10px 0 4px",
      height: "100%",
      display: isDisabled || hasValue ? "none" : "flex",
      alignItems: "center",
      justifyContent: "stretch",
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      display: "none",
    }),
    container: (styles) => ({
      ...styles,
      borderRadius: "8px",
      "&:hover": {
        borderColor: black,
      },
      flexGrow: 1,
      height: "28px",
      boxSizing: "unset",
      padding: "unset",
      border: "unset",
      minWidth: "unset",
    }),
    valueContainer: (styles) => ({
      ...styles,
      width: "1px",
    }),
    menu: (styles) => ({
      ...styles,
      fontSize: "12px",
      color: black,
      margin: 0,
      fontWeight: 600,
    }),
    input: (styles) => ({
      ...styles,
      fontSize: "12px",
      color: black,
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "12px",
      color: black,
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? selected
        : isFocused
        ? hover
        : undefined,
      color: black,
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled ? (isSelected ? active : active) : undefined,
      },
    }),
  };
}
