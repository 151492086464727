import { RadiusMode } from "./RadiusMode";

export function getRadiusMode(roundTop: boolean, roundBottom: boolean): RadiusMode {
  if (roundTop && roundBottom) {
    return "TOP_BOTTOM";
  }

  if (roundTop) {
    return "TOP";
  }

  if (roundBottom) {
    return "BOTTOM";
  }

  return "NONE";
}
