import { AnnotationTableRowType } from "./AnnotationTableRowType";

export const ANNOTATION = "Annotation";
export const CLASSIFICATION = "Classification";
export const USER_DEFINED = "User Defined";
export const READ_ONLY = "Read Only";

export type LabelTypeOptions =
  | typeof ANNOTATION
  | typeof CLASSIFICATION
  | typeof USER_DEFINED
  | typeof READ_ONLY;
