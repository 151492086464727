import React from "react";
import {
  Bar,
  BarChart,
  Cell,
  Legend as ReChartsLegend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Payload } from "recharts/types/component/DefaultTooltipContent";
import styled from "styled-components";

import { ImageCaptureContextMenuContainer } from "../../../common/components/ImageCaptureContextMenuContainer";
import { GenericTooltip } from "../../../common/utils/barChartUtils/GenericTooltip";
import { FilterType, IPRO, MORTALITY } from "../../ChangeFromBaseline/components/Filter";
import { Legend } from "../../common/components/Legend";
import { useFilteredProjectArmIds } from "../../common/hooks/useFilteredProjectArmIds";
import { useGetProjectArmName } from "../../common/hooks/useGetProjectArmName";
import { altArmColors, armColors } from "../../common/utils/armColors";
import { getXAxisProps } from "../../common/utils/getXAxisProps";
import { getYAxisLabel } from "../../common/utils/getYAxisLabel";
import { getYAxisProps } from "../../common/utils/getYAxisProps";
import { BAR_RADIUS } from "../../common/utils/strokeSettings";
import { SUBJECTS_LABEL } from "../../common/utils/SubjectsLabel";
import {
  formatBinRangeMortality,
  formatBinRangeSurvival,
  SurvivalBinType,
} from "../utils/SurvivalBinType";

const Wrapper = styled.div`
  flex: 1;
`;

interface SurvivalDistributionChartProps {
  histogram: SurvivalBinType[];
  selectedBinIndex: number | null;
  onSurvivalBinSelected: (
    survivalBin: SurvivalBinType,
    binIndex: number | null,
    isSelected: boolean
  ) => void;
  chartType: FilterType;
}

export function SurvivalDistributionChart({
  histogram,
  selectedBinIndex,
  onSurvivalBinSelected,
  chartType,
}: SurvivalDistributionChartProps): React.ReactElement {
  if (chartType !== IPRO && chartType !== MORTALITY) {
    throw new Error(`Chart Type ${chartType} is not implemented in SurvivalDistributionChart`);
  }

  const projectArmIds = useFilteredProjectArmIds();
  const getProjectArmName = useGetProjectArmName();

  const bars = projectArmIds.map((arm) => {
    const getData = (bin: SurvivalBinType) => {
      const { subjectsByArm } = bin;
      const armSubjects = subjectsByArm[arm];
      return armSubjects ? armSubjects.length : 0;
    };

    const handleBarClicked = (survivalBin: SurvivalBinType, index: number) => {
      const isSelected = selectedBinIndex !== index;

      onSurvivalBinSelected(survivalBin, isSelected ? index : null, isSelected);
    };

    const color = armColors[arm];
    const projectArmName = getProjectArmName(arm);

    return (
      <Bar
        onClick={handleBarClicked}
        key={arm}
        name={projectArmName}
        isAnimationActive={false}
        dataKey={getData}
        fill={color}
        legendType={"circle"}
        type={"circle"}
        radius={BAR_RADIUS}
      >
        {histogram.map((entry, index) => {
          const color =
            selectedBinIndex !== null
              ? selectedBinIndex === index
                ? armColors[arm]
                : altArmColors[arm]
              : armColors[arm];

          return <Cell cursor="pointer" fill={color} key={`cell-${index}`} />;
        })}
      </Bar>
    );
  });

  const formatXValue = (id: number): string => {
    const bin = histogram.find((bin) => bin.id === id);
    if (!bin) {
      return "N/A";
    }

    if (chartType === IPRO) {
      return formatBinRangeSurvival(bin);
    }
    return formatBinRangeMortality(bin);
  };

  const xAxisLabelAngle = (): number => {
    if (chartType === IPRO) {
      return -20;
    }

    return 0;
  };

  const xAxisProps = getXAxisProps();
  const yAxisLabel = getYAxisLabel(SUBJECTS_LABEL);
  const yAxisProps = getYAxisProps(true);

  return (
    <Wrapper>
      <ImageCaptureContextMenuContainer>
        {({ reference }) => (
          <ResponsiveContainer ref={reference} height={350}>
            <BarChart
              margin={{ top: 0, right: 0, left: 15, bottom: 0 }}
              data={histogram}
              barGap={1}
              barCategoryGap={6}
            >
              <XAxis
                {...xAxisProps}
                dataKey={"id"}
                interval={0}
                tickFormatter={formatXValue}
                tickLine={false}
                angle={xAxisLabelAngle()}
                tickSize={20}
              />
              <YAxis {...yAxisProps}>{yAxisLabel}</YAxis>
              <Tooltip
                content={<GenericTooltip />}
                isAnimationActive={false}
                formatter={formatTooltipValue}
                labelFormatter={(label, payload) => formatTooltipLabel(label, payload, chartType)}
              />
              <ReChartsLegend align="left" verticalAlign="top" height={48} content={<Legend />} />
              {bars}
            </BarChart>
          </ResponsiveContainer>
        )}
      </ImageCaptureContextMenuContainer>
    </Wrapper>
  );
}

const formatTooltipLabel = (
  label: string,
  payload: Array<Payload<number, string>>,
  graphType: FilterType
) => {
  if (payload.length === 0) {
    return undefined;
  }

  const { payload: originalPayload } = payload[0];
  if (!originalPayload) {
    return undefined;
  }

  const bin = originalPayload as SurvivalBinType;

  switch (graphType) {
    case IPRO:
      return `Survival: ${formatBinRangeSurvival(bin)}`;
    case MORTALITY:
      return `Mortality Risk: ${formatBinRangeMortality(bin)}`;
    default:
      throw new Error(`Tooltip type ${graphType} is not implemented in SurvivalDistributionChart`);
  }
};

const formatTooltipValue = (value: number, name: string) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // we need to ignore this because recharts' type doesn't permit us to change the type of value from number to string
  return [`n=${value}`, name] as [number, string];
};
