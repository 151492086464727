import React, { useState } from "react";
import styled from "styled-components";

import { ReactComponent as Close } from "../../../../../../assets/svgs/Close.svg";
import { SvgIcon } from "../../../../../../common/components/icons/SvgIcon";
import { FlexLoading } from "../../../../../../common/components/Loading";
import { main } from "../../../../../../common/theme/main";
import { TimepointOptionsType } from "../../../../../types/TaskDescriptionType";

const Wrapper = styled.div<{ viewOnly: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 0 6px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.neutral.white};
  border: 1px solid
    ${({ theme, viewOnly }) =>
      !viewOnly ? theme.colors.neutral.neutral6 : theme.colors.neutral.white};

  :hover {
    border-color: ${({ theme, viewOnly }) =>
      !viewOnly ? theme.colors.neutral.neutral4 : theme.colors.neutral.white};
  }
`;

const Text = styled.div<{ color: string }>`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: ${({ color }) => color};
`;

const IconButton = styled.button`
  padding: 0;
  border: none;
  cursor: pointer;
  background: transparent;
`;

interface ClassificationButtonProps {
  option: TimepointOptionsType;
  isSet: boolean;
  onClick?: (option: TimepointOptionsType) => void;
  onDeleteClick?: (option: TimepointOptionsType) => void;
  loading?: boolean;
  viewOnly: boolean;
}

export function ClassificationButton({
  option,
  isSet,
  onClick,
  onDeleteClick,
  loading,
  viewOnly,
}: ClassificationButtonProps): JSX.Element {
  const [hovered, setHovered] = useState<boolean>(false);

  const { displayName } = option;

  const handleClicked = () => {
    if (loading) {
      throw new Error(
        "Shouldn't be possible to handleClicked on ClassificationButton when loading"
      );
    }

    onClick?.(option);
  };

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleDeleteClicked = () => {
    if (!onDeleteClick || loading) {
      throw new Error("Shouldn't be possible to handleDeleteClicked on ClassificationButton");
    }

    onDeleteClick(option);
  };

  const {
    colors: {
      neutral: { neutral2, neutral3, neutral4, black },
    },
  } = main;

  const color = !viewOnly
    ? !isSet
      ? hovered
        ? black
        : neutral2
      : hovered
      ? neutral3
      : neutral4
    : neutral3;

  return (
    <Wrapper
      viewOnly={viewOnly}
      onClick={!loading ? handleClicked : undefined}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {loading && <FlexLoading />}
      <Text color={color}>{displayName}</Text>
      {onDeleteClick && (
        <IconButton onClick={!loading ? handleDeleteClicked : undefined}>
          <SvgIcon icon={Close} size={14} color={color} />
        </IconButton>
      )}
    </Wrapper>
  );
}
