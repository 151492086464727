import Tooltip from "@mui/material/Tooltip";
import React, { MouseEventHandler, ReactElement } from "react";
import styled from "styled-components";

interface CTAButtonProps {
  text: string;
  icon?: ReactElement;
  onClick?: MouseEventHandler;
  blackBackground?: boolean;
  disabled?: boolean;
  tooltip?: string;
}

const StyledButton = styled.button<{
  blackBackground?: boolean;
  disabled?: boolean;
}>`
  border: ${(props) =>
    props.disabled ? "none" : `1px solid ${props.theme.colors.actionPrimary.default}`};
  box-sizing: border-box;
  border-radius: 6px;
  background: ${(props) =>
    props.disabled
      ? props.theme.colors.actionPrimary.disabled
      : props.blackBackground
      ? props.theme.colors.actionPrimary.default
      : "transparent"};
  padding: 6px 8px;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TextWrapper = styled.div<{
  hasIcon: boolean | undefined;
  blackBackground: boolean | undefined;
  disabled?: boolean;
}>`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) =>
    props.blackBackground || props.disabled
      ? props.theme.colors.actionPrimary.inverted
      : props.theme.colors.actionPrimary.default};
  margin-left: ${(props) => (props.hasIcon ? "6px" : "0px")};
`;

const CTAButton = ({
  text,
  icon,
  onClick,
  blackBackground,
  disabled,
  tooltip = "",
}: CTAButtonProps): ReactElement => {
  return (
    <Tooltip title={tooltip}>
      <StyledButton disabled={disabled} onClick={onClick} blackBackground={blackBackground}>
        <Wrapper>
          {icon}
          <TextWrapper
            hasIcon={Boolean(icon)}
            blackBackground={blackBackground}
            disabled={disabled}
          >
            {text}
          </TextWrapper>
        </Wrapper>
      </StyledButton>
    </Tooltip>
  );
};

export default CTAButton;
