import React, { ComponentType } from "react";

import { SeriesListItemFragmentType } from "../AnnotationPanel/hooks/useStudiesList";
import { useDeleteSeriesClassifications } from "./useDeleteSeriesClassifications";

interface DeleteSeriesClassificationsWrapperProps {
  children: ComponentType<{
    onClick: (classification: string) => void;
  }>;
  taskId: number;
  series: SeriesListItemFragmentType;
}

export function DeleteSeriesClassificationsWrapper({
  series,
  taskId,
  children: Component,
}: DeleteSeriesClassificationsWrapperProps): JSX.Element {
  const [deleteSeriesClassifications] = useDeleteSeriesClassifications();

  const { id: seriesId } = series;

  const handleClassificationsDeleted = async (classification: string) => {
    await deleteSeriesClassifications({
      variables: {
        seriesIds: [seriesId],
        classifications: [classification],
        taskIds: [taskId],
      },
    });
  };

  return (
    <>
      <Component onClick={handleClassificationsDeleted} />
    </>
  );
}
