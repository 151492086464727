import { gql } from "@apollo/client";

export type GetStoredQcReportsForPatientVariables = {
  qcSchemaId: number;
  patientId: number;
};
export const GET_STORED_QC_REPORTS_FOR_PATIENT = gql`
  query GetStoredQcReportsForPatient($qcSchemaId: Int!, $patientId: Int!) {
    qcReports: qc_report(
      where: {
        qc_schema_id: { _eq: $qcSchemaId }
        _or: [
          { series: { study: { patient_id: { _eq: $patientId } } } }
          { study: { patient_id: { _eq: $patientId } }, patient_id: { _eq: $patientId } }
        ]
      }
    ) {
      id
      qcSchemaId: qc_schema_id
      scope
      seriesId: series_id
      studyId: study_id
      patientId: patient_id
      result
      isValid: result_valid
    }
  }
`;

export const CREATE_QC_REPORT_UNIQUE_CONSTRAINTS = {
  PATIENT: "qc_report_qc_schema_id_patient_id_scope_key" as const,
  STUDY: "qc_report_qc_schema_id_study_id_scope_key" as const,
  SERIES: "qc_report_qc_schema_id_scope_series_id_key" as const,
};

export type CreateQcReportUniqueConstraint =
  (typeof CREATE_QC_REPORT_UNIQUE_CONSTRAINTS)[keyof typeof CREATE_QC_REPORT_UNIQUE_CONSTRAINTS];

export const CREATE_QC_REPORT = gql`
  mutation CreateQcReport(
    $qcSchemaId: Int!
    $scope: String!
    $defaultValues: jsonb!
    $uniqueConstraint: qc_report_constraint!
    $seriesId: Int = null
    $studyId: Int = null
    $patientId: Int = null
  ) {
    qcReport: insert_qc_report_one(
      object: {
        qc_schema_id: $qcSchemaId
        scope: $scope
        result: $defaultValues
        activity_log: []
        series_id: $seriesId
        study_id: $studyId
        patient_id: $patientId
        result_valid: false
      }
      # on_conflict, it should do nothing, but return the row that already exists
      # constraint is that qc_schema_id and at the non-null id are unique
      on_conflict: { constraint: $uniqueConstraint, update_columns: [] }
    ) {
      id
      qcSchemaId: qc_schema_id
      seriesId: series_id
      studyId: study_id
      patientId: patient_id
      result
      isValid: result_valid
    }
  }
`;

export const UPDATE_QC_REPORT = gql`
  mutation UpdateQcReport($id: Int!, $changes: jsonb!) {
    qcReport: update_qc_report_by_pk(pk_columns: { id: $id }, _append: { result: $changes }) {
      id
      qcSchemaId: qc_schema_id
      seriesId: series_id
      studyId: study_id
      patientId: patient_id
      result
      isValid: result_valid
    }
  }
`;

export const UPDATE_QC_REPORT_STATUS = gql`
  mutation UpdateQcReportStatus($id: Int!, $isFormValid: Boolean!) {
    qcReport: update_qc_report_by_pk(
      pk_columns: { id: $id }
      _set: { result_valid: $isFormValid }
    ) {
      id
      qcSchemaId: qc_schema_id
      seriesId: series_id
      studyId: study_id
      patientId: patient_id
      isFormValid: result_valid
    }
  }
`;
