import cornerstoneTools from "@altis-labs/cornerstone-tools";

type ImageViewerSynchronizerType = {
  createSynchronizer: (event: string, handler: unknown) => void;
  setEnabled: (isEnabled: boolean) => void;
  getEnabled: () => boolean;
  add: (element: HTMLDivElement) => void;
  remove: (element: HTMLDivElement) => void;
};

const ImageViewerSynchronizer = (function () {
  let instance: ImageViewerSynchronizerType | null = null;

  function init() {
    let synchronizer: typeof cornerstoneTools.Synchronizer = null;
    const elements: HTMLDivElement[] = [];

    function destroySynchronizer() {
      synchronizer.destroy();
    }

    return {
      // Public methods and variables
      createSynchronizer: (event: string, handler: unknown) => {
        if (synchronizer) {
          destroySynchronizer();
        }

        synchronizer = new cornerstoneTools.Synchronizer(event, handler);

        //re-add all elements if any exist
        elements.forEach((element) => synchronizer.add(element));
      },
      setEnabled: (isEnabled: boolean) => {
        if (!synchronizer) {
          throw new Error("No syncrhonizer created!");
        }

        synchronizer.enabled = isEnabled;
      },
      getEnabled: () => {
        if (!synchronizer) {
          throw new Error("No syncrhonizer created!");
        }

        return synchronizer.enabled;
      },
      add: (element: HTMLDivElement) => {
        if (!synchronizer) {
          throw new Error("No syncrhonizer created!");
        }

        elements.push(element);
        synchronizer.add(element);
      },
      remove: (element: HTMLDivElement) => {
        if (!synchronizer) {
          throw new Error("No syncrhonizer created!");
        }

        const index = elements.indexOf(element);
        if (index < 0) {
          throw new Error("Element does not exist in ImageViewerSynchronizer");
        }

        elements.splice(index, 1);
        synchronizer.remove(element);
      },
    };
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = init();
      }
      return instance;
    },
  };
})();

export { ImageViewerSynchronizer };
