import { createContext } from "react";

import { ViewerConfigType } from "../../../../../../common/store/annotatePage/ViewerConfigType";
import { ROI, ToolType } from "../../../../../../cornerstone/ToolType";

export type ViewerContextType = {
  viewerConfigs: ViewerConfigType[];
  setViewerConfigs: (
    value: ((prevValue: ViewerConfigType[]) => ViewerConfigType[]) | ViewerConfigType[]
  ) => void;
  activeTool: ToolType;
  setActiveTool: (tool: ToolType) => void;
  lastActiveTool: ToolType | null;
  setLastActiveTool: (tool: ToolType) => void;
  viewerImageIds: Record<number, string | null>;
  setViewerImageId: (viewerConfigId: number, imageId: string | null) => void;
  clearViewerImageId: (viewerConfigId: number) => void;
  viewOnly: boolean;
  setViewOnly: (viewOnly: boolean) => void;
};

const DEFAULT_CONTEXT: ViewerContextType = {
  viewerConfigs: [],
  setViewerConfigs: () => undefined,
  activeTool: ROI,
  setActiveTool: () => undefined,
  lastActiveTool: null,
  setLastActiveTool: () => undefined,
  viewerImageIds: {},
  setViewerImageId: () => undefined,
  clearViewerImageId: () => undefined,
  viewOnly: false,
  setViewOnly: () => undefined,
};

export const ViewerContext = createContext<ViewerContextType>(DEFAULT_CONTEXT);
