import React, { FC } from "react";

import { useProjectPatientTaskRows } from "../../../Annotate/components/Manage/hooks/useProjectPatientTaskRows";
import { FlexLoading } from "../../../common/components/Loading";
import { useSubjectIdParam } from "../../../Project/utils/useSubjectIdParam";
import { useSingleSubjectQuery } from "../../common/hooks/useSingleSubjectQuery";
import { ChartDataTypeProps } from "../../MedianSurvival/components/MedianTumorBurdenSurvivalPredictionCard";
import { MissingPatientPanel } from "./MissingPatientPanel";
import { PatientJourneyTumourDetailsChart } from "./PatientJourneyTumourDetailsChart";

const PatientJourneyTumourDetailsContainer: FC<ChartDataTypeProps> = ({
  allowedDataTypes,
}: ChartDataTypeProps) => {
  if (!allowedDataTypes) {
    throw new Error("PatientJourneyTumourDetailsContainer: allowedDataTypes cannot be null!");
  }

  const subjectId = useSubjectIdParam();

  const subject = useSingleSubjectQuery(subjectId);

  const { patientsTasks } = useProjectPatientTaskRows(false);
  const patientTasks = patientsTasks?.filter(({ id }) => id === subjectId) ?? [];

  if (!patientTasks) {
    return <FlexLoading />;
  }

  if (!subject) {
    return <MissingPatientPanel />;
  }

  return <PatientJourneyTumourDetailsChart allowedDataTypes={allowedDataTypes} subject={subject} />;
};

export default PatientJourneyTumourDetailsContainer;
