import React, { ReactElement } from "react";
import styled from "styled-components";

import { projectStatusColors } from "../../utils/projectStatusColors";
import { ProjectStatusType } from "../../utils/ProjectStatusType";

const StatusBox = styled.div<{ fill: string; border: string }>`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 20px;
  width: 80px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.border};
  background-color: ${(props) => props.fill};
`;

const StatusText = styled.p<{ color: string }>`
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-transform: capitalize;
  color: ${(props) => props.color};
`;

interface ProjectStatusProps {
  status: ProjectStatusType;
  labelOverride?: string;
}

const ProjectStatus = ({ status, labelOverride }: ProjectStatusProps): ReactElement => {
  const { fill, text, border } = projectStatusColors[status];

  const label = labelOverride ?? status.toLowerCase();

  return (
    <StatusBox fill={fill} border={border}>
      <StatusText color={text}>{label}</StatusText>
    </StatusBox>
  );
};

export default ProjectStatus;
