import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { MutationTuple } from "@apollo/client/react/types/types";

import { USER_FRAGMENT, UserFragmentType } from "../../../Settings/UserFragment";
import { UserType } from "../../types/UserType";

const MUTATION = gql`
  mutation UpdateUser(
    $userId: Int!
    $deletedGlobalUserRoles: [enum_global_role_enum!]!
    $addedGlobalUserRoles: [global_user_role_insert_input!]!
  ) {
    delete_global_user_role(
      where: { _and: [{ user_id: { _eq: $userId } }, { role: { _in: $deletedGlobalUserRoles } }] }
    ) {
      returning {
        user {
          ...UserFragment
        }
      }
    }
    insert_global_user_role(
      objects: $addedGlobalUserRoles
      on_conflict: { constraint: global_user_role_pkey, update_columns: [] }
    ) {
      returning {
        user {
          ...UserFragment
        }
      }
    }
  }
  ${USER_FRAGMENT}
`;

type Variables = {
  userId: number;
  deletedGlobalUserRoles: string[];
  addedGlobalUserRoles: {
    user_id: number;
    role: string;
  }[];
};

type Data = {
  delete_global_user_role: { returning: { user: UserFragmentType }[] };
  insert_global_user_role: { returning: { user: UserFragmentType }[] };
};

export function useUpdateGlobalUserRoles(): MutationTuple<Data, Variables> {
  return useMutation<Data, Variables>(MUTATION);
}

export function parseVariables(originalUser: UserType, newUser: UserType): Variables {
  const { id: userId, globalRoles: originalGlobalRoles } = originalUser;
  const { globalRoles: newGlobalRoles } = newUser;

  const addedGlobalUserRoles = newGlobalRoles.filter(
    (newGlobalRole) => !originalGlobalRoles.includes(newGlobalRole)
  );
  const deletedGlobalUserRoles = originalGlobalRoles.filter(
    (originalGlobalRole) => !newGlobalRoles.includes(originalGlobalRole)
  );

  return {
    userId,
    addedGlobalUserRoles: addedGlobalUserRoles.map((role) => ({
      user_id: userId,
      role,
    })),
    deletedGlobalUserRoles,
  };
}
