import dayjs from "dayjs";
import React, { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";

import DisplayRadioButton from "../../../Analysis/common/components/DisplayRadioButton";
import { ReactComponent as CloseIcon } from "../../../assets/svgs/Close.svg";
import { SvgIcon } from "../../../common/components/icons/SvgIcon";
import { OutsideAlerter } from "../../../common/components/OutsideAlerter";
import { compareDates } from "../../../common/utils/dateFormatUtils/compareDates";
import {
  dateFilterDisplayName,
  dateFilters,
  DateFilterType,
} from "../../../common/utils/dateFormatUtils/dateFilters";
import { DayjsDateRange } from "../../../common/utils/dateFormatUtils/DayjsDateRange";
import { enumerateMonths } from "../../../common/utils/dateFormatUtils/enumerateMonths";
import { getRange } from "../../../common/utils/dateFormatUtils/getRange";
import { GenericSingleSelect } from "../../../DataManagement/Upload/components/GenericSingleSelect";
import { DateRangePicker } from "./DateRangePicker";

const displayMonthFormat = "MMM YYYY";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  min-width: 275px;
`;

const MonthSelectWrapper = styled.div`
  background: ${(props) => props.theme.colors.neutral.white};
  position: absolute;
  top: 32px;
  border-radius: 8px;
  z-index: 2;
`;

const CustomSelectWrapper = styled.div`
  background: ${(props) => props.theme.colors.neutral.white};
  padding: 10px;
  position: absolute;
  top: 32px;
  right: 0;
  border: 1px black solid;
  border-radius: 8px;
  z-index: 2;
`;

const ToolbarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const Button = styled.div`
  cursor: pointer;
`;

interface FilterProps {
  filterMode: DateFilterType;
  onSelectFilterMode: (filter: DateFilterType) => void;
  selectedRange: DayjsDateRange;
  onSetSelectedRange: (range: DayjsDateRange) => void;
  earliestDate: dayjs.Dayjs;
  disabled?: boolean;
}

export function Filter({
  filterMode,
  onSelectFilterMode,
  selectedRange,
  onSetSelectedRange,
  earliestDate,
  disabled,
}: FilterProps): ReactElement {
  const [selectedMonth, setSelectedMonth] = useState<dayjs.Dayjs | null>(null);

  const [monthSelectorOpen, setMonthSelectorOpen] = useState<boolean>(false);
  const [customSelectorOpen, setCustomSelectorOpen] = useState<boolean>(false);
  const [monthOptions, setMonthOptions] = useState<dayjs.Dayjs[]>([]);

  const handleShowPopouts = (filterMode: DateFilterType) => {
    setMonthSelectorOpen(filterMode === "MONTH");
    setCustomSelectorOpen(filterMode === "CUSTOM");
  };

  // handle the initial filter mode
  useEffect(() => {
    handleShowPopouts(filterMode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const months = enumerateMonths(earliestDate, dayjs());
    setMonthOptions(months);

    setSelectedMonth((month) => (months.length === 0 ? null : month ?? months[months.length - 1]));
  }, [earliestDate]);

  useEffect(() => {
    const range = getRange(filterMode, selectedMonth ?? dayjs(), earliestDate);
    if (!range) {
      return;
    }

    onSetSelectedRange(range);
  }, [filterMode, onSetSelectedRange, selectedMonth, earliestDate]);

  const handleClickFilter = (filterMode: DateFilterType) => {
    if (disabled) {
      return;
    }

    handleShowPopouts(filterMode);
    onSelectFilterMode(filterMode);
  };

  const handleCloseCustomFilter = () => {
    setCustomSelectorOpen(false);
  };

  return (
    <Wrapper>
      {dateFilters.map((filter) => {
        return (
          <DisplayRadioButton
            disabled={disabled}
            active={!disabled && filter === filterMode}
            key={filter}
            onClick={() => handleClickFilter(filter)}
          >
            {dateFilterDisplayName[filter]}
          </DisplayRadioButton>
        );
      })}
      {monthSelectorOpen && !disabled && (
        <MonthSelectWrapper>
          <GenericSingleSelect
            values={monthOptions}
            selected={selectedMonth}
            onSelectedChanged={setSelectedMonth}
            getOption={(month) => ({
              value: month,
              label: month.format(displayMonthFormat),
            })}
            compareValues={compareDates}
            styles={{
              menu: (styles) => ({
                ...styles,
                position: "relative",
                top: 0,
              }),
            }}
          />
        </MonthSelectWrapper>
      )}
      {customSelectorOpen && !disabled && (
        <OutsideAlerter callback={handleCloseCustomFilter}>
          <CustomSelectWrapper>
            <ToolbarWrapper>
              <Button onClick={handleCloseCustomFilter}>
                <SvgIcon icon={CloseIcon} size={16} />
              </Button>
            </ToolbarWrapper>
            <DateRangePicker
              defaultMonth={dayjs()}
              selected={selectedRange}
              onSelected={onSetSelectedRange}
              fromMonth={earliestDate}
              toMonth={dayjs()}
            />
          </CustomSelectWrapper>
        </OutsideAlerter>
      )}
    </Wrapper>
  );
}
