import cloneDeep from "lodash.clonedeep";

import { EllipseFragmentType } from "../fragments/EllipseFragmentType";
import { EllipseToolDataType } from "../types/EllipseToolDataType";
import { EllipseType } from "../types/EllipseType";
import { normalizeImageId } from "./normalizeImageId";

type SliceType = {
  name: string;
  imageId: string;
  toolData: EllipseToolDataType[];
};

export function parseEllipse(
  ellipseFragment: EllipseFragmentType | null,
  toolRoiId: number,
  baseWadoUrl: string,
  lesionId: number
): EllipseType | undefined {
  if (!ellipseFragment) {
    return undefined;
  }

  const {
    id: ellipseId,
    data: { roi, slice },
  } = cloneDeep(ellipseFragment);

  let sliceWithUniqueToolRoiId: SliceType | null = null;

  if (slice) {
    //  swap the internal imageId url to use the one from the db so that the ellipse shows up on the images correctly
    const { imageId: originalImageId } = slice;

    const imageId = normalizeImageId(originalImageId, baseWadoUrl);

    sliceWithUniqueToolRoiId = {
      ...slice,
      imageId,
      toolData: slice.toolData.map((data) => {
        const { roi } = data;
        return {
          ...data,
          roi: { ...roi, id: toolRoiId, lesionId },
        };
      }),
    };
  }

  return {
    id: ellipseId,
    roi: { ...roi, id: toolRoiId, lesionId },
    slice: sliceWithUniqueToolRoiId,
  };
}
