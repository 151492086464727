import cloneDeep from "lodash/cloneDeep";

import { ClassificationsMapType } from "../../../../../../common/store/annotatePage/ClassificationsMapType";
import { ClassificationValuesType } from "../../../../../types/TaskDescriptionType";

export function getClassificationsMap(
  classificationsMap: ClassificationsMapType,
  classifications: ClassificationValuesType[]
): ClassificationsMapType {
  const classificationsMapCopy = cloneDeep(classificationsMap);
  Object.values(classificationsMapCopy).forEach(({ values }) => {
    let classificationFound = false;
    Object.keys(values).forEach((classification) => {
      if (classifications.includes(classification as ClassificationValuesType)) {
        if (classificationFound) {
          throw new Error("More than one classification should not be selected");
        }
        values[classification as ClassificationValuesType] = true;
        classificationFound = true;
        return;
      }
      values[classification as ClassificationValuesType] = false;
    });
  });
  return classificationsMapCopy;
}
