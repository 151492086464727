import { isLesionLesion } from "../../../Annotate/components/Annotate/page/AnnotationPanel/utils/isLesionLesion";
import { isLesionOrgan } from "../../../Annotate/components/Annotate/page/AnnotationPanel/utils/isLesionOrgan";
import { MEASURABLE } from "../../../Annotate/enums/TaskDescriptionEnums";
import { ORGAN } from "../../../Annotate/types/LesionTypeType";
import { LesionType, RoiType } from "../hooks/useProjectLabels";
import { LabelType } from "../types/LabelType";

export function isRoiIncluded(
  { ellipses, contours }: Pick<RoiType, "ellipses" | "contours">,
  { type, classifications }: Pick<LesionType, "type" | "classifications">,
  labelType: LabelType
): boolean {
  const containsContour = contours.aggregate.count > 0;
  const containsEllipse = ellipses.aggregate.count > 0;

  const isLesion = isLesionLesion({ type });
  const isOrgan = isLesionOrgan({ type });

  const isMeasurable = classifications.some(({ classification }) => classification === MEASURABLE);

  switch (labelType) {
    case "DETECTED_LESION":
      return isLesion && containsEllipse;
    case "CONTOURED_LESION":
      return isLesion && containsContour;
    case "CONTOURED_MEASURABLE_LESION":
      return isLesion && containsContour && isMeasurable;
    case "ORGAN":
      return isOrgan && containsContour;
    default:
      throw new Error(`Label type ${labelType} not implemented`);
  }
}
