import React from "react";
import styled from "styled-components";

import { UserAvatarType } from "../../types/UserType";
import { Avatar } from "../Avatar/Avatar";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 1px;
  gap: 5px;
`;

interface UsersListProps {
  users: UserAvatarType[];
}

export function UsersList({ users }: UsersListProps): JSX.Element {
  const sorted = [...users].sort(({ id: idA }, { id: idB }) => idA - idB);
  return (
    <Wrapper>
      {sorted.map((user, index) => (
        <Avatar key={index} user={user} size={22} />
      ))}
    </Wrapper>
  );
}
