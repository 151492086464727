import React from "react";
import { Column } from "react-table";
import styled from "styled-components";

import { ReactComponent as CheckIcon } from "../../../assets/svgs/CheckFilled.svg";
import { ReactComponent as RemoveCircleIcon } from "../../../assets/svgs/RemoveCircle.svg";
import { getFilterColumnProps } from "../../../Dashboard/components/Settings/Users/GenericColumnFilter";
import { main } from "../../theme/main";
import { SvgIcon } from "../icons/SvgIcon";
import { getFlagValueFromSeries } from "../SeriesFlags/getFlagValueFromSeries";
import {
  areFlagsEqual,
  FLAGGED,
  FlagTypeOptions,
  NOT_FLAGGED,
  SeriesFlag,
  seriesFlagFilterProps,
} from "../SeriesFlags/SeriesFlag";
import { CellProps } from "../Table/CellProps";
import { CheckboxProps, getCheckboxColumn, SELECT_HEADER_ID } from "../Table/getCheckboxColumn";
import { TextCell } from "../Table/TextCell";
import { SeriesTableRowType } from "./SeriesTableRowType";

const naValue = "N/A";

export type SeriesTableColumnType =
  | typeof SELECT_HEADER_ID
  | typeof FLAG_HEADER_ID
  | typeof SERIES_INSTANCE_UID_HEADER_ID
  | typeof DESCRIPTION_HEADER_ID
  | typeof MODALITY_HEADER_ID
  | typeof NUMBER_OF_IMAGES_HEADER_ID
  | typeof SLICE_THICKNESS_HEADER_ID
  | typeof ANATOMICAL_PLANE_HEADER_ID
  | typeof INCLUDED_HEADER_ID;

export const FLAG_HEADER_ID = "seriesFlag";
export const SERIES_INSTANCE_UID_HEADER_ID = "seriesInstanceUid";
export const DESCRIPTION_HEADER_ID = "description";
export const MODALITY_HEADER_ID = "modality";
export const NUMBER_OF_IMAGES_HEADER_ID = "numberOfImages";
export const SLICE_THICKNESS_HEADER_ID = "sliceThickness";
export const ANATOMICAL_PLANE_HEADER_ID = "anatomicalPlane";
export const INCLUDED_HEADER_ID = "included";

const Button = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

function getSeriesTableCheckboxColumn(
  onSelectSeries: (id: number, selected: boolean) => void
): Column<SeriesTableRowType> {
  function getHeaderProps(data: SeriesTableRowType[]): CheckboxProps {
    const checked = data.length > 0 && data.every(({ isSelected }) => isSelected);
    const indeterminate = !checked && data.some(({ isSelected }) => isSelected);

    return {
      checked,
      indeterminate,
      onChange: (event) => {
        const selected = (event.target as HTMLInputElement).checked;
        const seriesIds = [...new Set(data.map(({ id }) => id))];
        seriesIds.map((seriesId) => onSelectSeries(seriesId, selected));
      },
    };
  }

  function getRowProps({ isSelected: checked, id }: SeriesTableRowType): CheckboxProps {
    return {
      checked,
      onChange: (event) => {
        const selected = (event.target as HTMLInputElement).checked;
        onSelectSeries(id, selected);
      },
    };
  }

  return getCheckboxColumn({
    getHeaderProps,
    getRowProps,
  });
}

export function getColumns(
  hiddenColumns: SeriesTableColumnType[],
  onSelectSeries: (seriesId: number, selected: boolean) => void
): Column<SeriesTableRowType>[] {
  const flagValues: FlagTypeOptions[] = [FLAGGED, NOT_FLAGGED];

  const columnDefinitions: Record<SeriesTableColumnType, Column<SeriesTableRowType>> = {
    [SELECT_HEADER_ID]: getSeriesTableCheckboxColumn(onSelectSeries),
    [FLAG_HEADER_ID]: {
      id: "flag",
      Header: <SeriesFlag flagSize={17} />,
      ...getFilterColumnProps((row) => getFlagValueFromSeries([row]), areFlagsEqual, {
        values: flagValues,
        ...seriesFlagFilterProps,
      }),
      style: {
        width: "50px",
      },
    },
    [SERIES_INSTANCE_UID_HEADER_ID]: {
      id: "seriesInstanceUid",
      Header: "Series Instance UID",
      accessor: ({ seriesInstanceUid }) => seriesInstanceUid.trim(),
      disableFilters: false,
      Cell: ({ value }: { value: string }) => <TextCell value={value} />,
    },
    [DESCRIPTION_HEADER_ID]: {
      id: "description",
      Header: "Description",
      disableFilters: false,
      accessor: ({ description }) => description ?? naValue,
      Cell: ({ value }: { value: string }) => <TextCell value={value} />,
    },
    [MODALITY_HEADER_ID]: {
      id: "modality",
      Header: "Modality",
      disableFilters: false,
      accessor: ({ instanceModalities }) => {
        const modalities = instanceModalities.flatMap(({ modality }) =>
          modality ? [modality] : []
        );
        return modalities.length > 0 ? modalities.join(", ") : naValue;
      },
    },
    [NUMBER_OF_IMAGES_HEADER_ID]: {
      id: "numberOfSlices",
      Header: "# of Images",
      accessor: ({
        instances: {
          aggregate: { count },
        },
      }) => count,
    },
    [SLICE_THICKNESS_HEADER_ID]: {
      id: "sliceThickness",
      Header: "Slice Thickness",
      accessor: ({ computedSliceThicknesses, instanceSliceThickness }) => {
        const sliceThicknesses = [
          ...new Set(
            instanceSliceThickness.flatMap(({ sliceThickness }) =>
              sliceThickness ? [sliceThickness] : []
            )
          ),
        ];

        if (sliceThicknesses.length > 0) {
          return formatSliceThicknesses(sliceThicknesses);
        }

        if (!computedSliceThicknesses || computedSliceThicknesses.length === 0) {
          return naValue;
        }

        return formatSliceThicknesses(computedSliceThicknesses);
      },
    },
    [ANATOMICAL_PLANE_HEADER_ID]: {
      id: "anatomicalPlane",
      Header: "Anatomical Plane",
      accessor: ({ computedAnatomicalPlanes }) => {
        if (!computedAnatomicalPlanes || computedAnatomicalPlanes.length === 0) {
          return naValue;
        }

        return computedAnatomicalPlanes.join(", ");
      },
    },
    [INCLUDED_HEADER_ID]: {
      id: "excluded",
      Header: "Included",
      disableFilters: true,
      style: {
        width: "96px",
      },
      // eslint-disable-next-line react/prop-types,react/display-name
      Cell: ({
        row: {
          original: { projectExclusions },
        },
      }: CellProps<SeriesTableRowType>) => {
        const included = projectExclusions.length === 0;
        const {
          colors: {
            states: { success, error },
          },
        } = main;
        const handleOnClick = () => {
          //
        };

        return (
          <Button onClick={handleOnClick}>
            {included ? "Included" : "Excluded"}
            <SvgIcon
              icon={included ? CheckIcon : RemoveCircleIcon}
              size={included ? 14 : 13}
              color={included ? success : error}
            />
          </Button>
        );
      },
    },
  };

  const allColumnIds: SeriesTableColumnType[] = [
    SELECT_HEADER_ID,
    FLAG_HEADER_ID,
    SERIES_INSTANCE_UID_HEADER_ID,
    DESCRIPTION_HEADER_ID,
    MODALITY_HEADER_ID,
    NUMBER_OF_IMAGES_HEADER_ID,
    SLICE_THICKNESS_HEADER_ID,
    ANATOMICAL_PLANE_HEADER_ID,
    INCLUDED_HEADER_ID,
  ];

  const columnIds = allColumnIds.filter((id) => !hiddenColumns.includes(id));
  return columnIds.map((id) => columnDefinitions[id]);
}

function formatSliceThicknesses(sliceThicknesses: number[]): string {
  return sliceThicknesses.map((sliceThickness) => `${sliceThickness} mm`).join(", ");
}
