import { SeriesListItemFragmentType } from "../hooks/useStudiesList";

export function isSeriesViewOnly(
  { viewOnly }: SeriesListItemFragmentType,
  currentProjectId: number
): boolean {
  const seriesProjectViewOnlyStatus = viewOnly.find(
    ({ projectId }) => projectId === currentProjectId
  );
  return seriesProjectViewOnlyStatus?.enabled ?? false;
}
