import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: inline;
  font-size: 12px;
`;

const Bold = styled.span`
  font-weight: bold;
`;

interface SeriesSelectionLabelProps {
  totalSeries: number;
  totalStudies: number;
}

export function SeriesSelectionLabel({
  totalSeries,
  totalStudies,
}: SeriesSelectionLabelProps): JSX.Element {
  return (
    <Wrapper>
      Selected <Bold>{totalSeries}</Bold> series from <Bold>{totalStudies}</Bold> studies
    </Wrapper>
  );
}
