import React from "react";

import { useTimepointFilteredSubjectsQuery } from "../../common/hooks/useTimepointFilteredSubjectsQuery";
import { parseMortalityBaselineChartData } from "../utils/parseMortalityBaselineChartData";
import { FilterType } from "./Filter";
import MortalityChangefromBaselineChart from "./MortalityChangefromBaselineChart";

interface MortalityChangefromBaselineContainerProps {
  onClickPatient?: (patientId: number) => void;
  allowedDataTypes: FilterType[];
}

function MortalityChangefromBaselineContainer({
  onClickPatient,
  allowedDataTypes,
}: MortalityChangefromBaselineContainerProps): React.ReactElement {
  const subjects = useTimepointFilteredSubjectsQuery();

  const subjectsData = parseMortalityBaselineChartData(subjects);

  return (
    <MortalityChangefromBaselineChart
      allowedDataTypes={allowedDataTypes}
      subjectsData={subjectsData}
      onClickPatient={onClickPatient}
    />
  );
}

export default MortalityChangefromBaselineContainer;
