import Tooltip from "@mui/material/Tooltip";
import React, { useState } from "react";
import styled from "styled-components";

import { ReactComponent as Cancel } from "../../../../../../assets/svgs/Cancel.svg";
import { ReactComponent as Measure } from "../../../../../../assets/svgs/Measure.svg";
import { SvgIcon } from "../../../../../../common/components/icons/SvgIcon";
import { main } from "../../../../../../common/theme/main";
import { ToolType } from "../../../../../../cornerstone/ToolType";
import { getToolbarButtonColor } from "./getToolbarButtonColor";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

const CancelWrapper = styled.div`
  height: 100%;
  margin-left: -3px;
  align-items: center;
`;

interface ToolbarNodeProps {
  value: ToolType;
  currentValue: ToolType;
  onChange: (value: ToolType) => void;
  onCancel: () => void;
  tooltip?: string;
}

export function MeasureCancelButtons({
  value,
  currentValue,
  onChange,
  onCancel,
  tooltip,
}: ToolbarNodeProps): JSX.Element {
  const [hover, setHover] = useState(false);

  function handleMouseOver() {
    setHover(true);
  }

  function handleMouseOut() {
    setHover(false);
  }

  const colour = getToolbarButtonColor(value === currentValue, hover);

  return (
    <Tooltip title={tooltip}>
      <Wrapper
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onClick={() => onChange(value)}
      >
        <SvgIcon icon={Measure} size={20} color={colour} />
        <CancelWrapper onClick={onCancel}>
          <SvgIcon icon={Cancel} size={15} color={main.colors.actionPrimary.default} />
        </CancelWrapper>
      </Wrapper>
    </Tooltip>
  );
}
