import { useSelector } from "react-redux";

import { selectedLesionIdSelector } from "../../../../../../common/store/annotatePage/selectionSelector";
import { LesionListItemFragmentType } from "../../AnnotationPanel/fragments/LesionListItemFragment";
import { useLesionsList } from "../../AnnotationPanel/hooks/useLesionsList";
import { getSelectedLesion } from "./getSelectedLesion";

export function useSelectedLesion(): LesionListItemFragmentType | null {
  const selectedLesionId = useSelector(selectedLesionIdSelector);
  const { data } = useLesionsList();

  const { lesions } = data ?? {};

  return !lesions ? null : getSelectedLesion(lesions, selectedLesionId);
}
