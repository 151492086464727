import React, { FC } from "react";

import { FormProps } from "../../../../common/components/Dialog/FormProps";
import { useDialogReturnType } from "../../../../common/components/Dialog/useDialog";
import { useFormDialog } from "../../../../common/components/Dialog/useFormDialog";
import { TaskType } from "../hooks/TaskType";
import { TaskDescriptionTemplateType } from "../PatientTable/TaskDescriptionOptionType";
import { CreateTaskWizard } from "./CreateTaskWizard";

export function useCreateTaskForm(props: CreateTaskFormProps): useDialogReturnType {
  return useFormDialog({
    children: CreateTaskForm,
    label: "Create Task",
    showCloseButton: true,
    props,
  });
}

interface CreateTaskFormProps {
  taskDescriptionTemplate: TaskDescriptionTemplateType | undefined;
  onTasksCreated: (tasks: TaskType[]) => void;
}

export const CreateTaskForm: FC<FormProps<CreateTaskFormProps>> = ({
  onSubmit,
  onCancel,
  props: { taskDescriptionTemplate, onTasksCreated },
}: FormProps<CreateTaskFormProps>) => {
  if (!taskDescriptionTemplate) {
    return <>No task description template selected.</>;
  }

  const handleTasksCreated = (tasks: TaskType[]) => {
    onTasksCreated(tasks);
    onSubmit();
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <CreateTaskWizard
      onTasksCreated={handleTasksCreated}
      onCancel={handleCancel}
      taskDescriptionTemplate={taskDescriptionTemplate}
    />
  );
};
