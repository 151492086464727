import React, { useEffect } from "react";
import styled from "styled-components";

import { CurrentUserAvatar } from "../common/components/Avatar/CurrentUserAvatar";
import { ClickableDiv } from "../common/components/divs/ClickableDiv";
import { SettingsIcon } from "../common/components/icons/SettingsIcon";
import SearchTextField from "../common/components/input/SearchTextField";
import { NotaLogo } from "../common/components/NotaLogo";
import { CurrentUserCan } from "../common/contexts/UserContext/CurrentUserCan";
import { GLOBAL_ROLE_SOFTWARE_MANAGER } from "../common/types/UserRoleType";
import { Content } from "../Project/Layout/Content";
import { useGoToGlobalSettings } from "../Project/Layout/hooks/useGoToGlobalSettings";
import { DashboardLayoutType, LOADING_CONTENT } from "../Project/Layout/Layout";
import { DASHBOARD, getRouteDisplayName } from "../Project/Layout/RoutePathType";
import { setWindowTitle } from "../Project/Layout/utils/setWindowTitle";
import HelloText from "./components/HelloText";

const Wrapper = styled.div`
  height: 100vh;
  overflow-x: hidden;
  background: ${(props) => props.theme.colors.background.main};
  position: relative;
`;

const PanelWrapper = styled.div`
  position: relative;
  padding: 64px 320px 24px 320px;
  @media (min-width: 300px) {
    padding: 64px 50px 24px 50px;
  }
  @media (min-width: 1000px) {
    padding: 64px 200px 24px 200px;
  }
  @media (min-width: 1300px) {
    padding: 64px 320px 24px 320px;
  }
  overflow-y: auto;
`;

export const ActionPanelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  height: 48px;
  margin-bottom: 24px;
  gap: 12px;
`;

export const SearchWrapper = styled.div`
  flex: 4;
  display: flex;
`;

export const ActionButtonWrapper = styled.div`
  flex: 1;
  display: flex;
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 40px;
  gap: 40px;

  box-shadow: 0 1px 3px ${(props) => props.theme.colors.primary.primary30};
  border-radius: 12px;
  background: ${(props) => props.theme.colors.actionPrimary.inverted};
`;

const HeaderWrapper = styled.div`
  margin: 40px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.colors.neutral.neutral9};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`;

const EllipseTop = styled.div`
  position: absolute;
  width: 100%;
  height: 500px;
  background: ${(prop) => prop.theme.colors.neutral.black};
`;

const Ellipse = styled.div`
  position: absolute;
  left: -15%;
  width: 130%;
  top: 350px;
  height: 300px;
  border-radius: 50%;
  background: ${(prop) => prop.theme.colors.neutral.black};
`;

export const StyledSearchField = styled(SearchTextField)`
  size: 40px;
  background-size: 24px;
`;

interface DashboardProps {
  layout: DashboardLayoutType | undefined;
}

export function Dashboard({ layout }: DashboardProps): JSX.Element {
  const { content } = layout ?? {
    content: [{ component: LOADING_CONTENT }],
  };

  useEffect(() => {
    const windowTitle = getRouteDisplayName(DASHBOARD);
    setWindowTitle(windowTitle);
  }, []);

  const gotToGlobalSettings = useGoToGlobalSettings();

  const handleClickSettings = () => {
    gotToGlobalSettings();
  };

  return (
    <Wrapper>
      <EllipseTop />
      <Ellipse />
      <PanelWrapper>
        <NotaLogo />
        <HeaderWrapper>
          <HelloText />
          <ButtonsWrapper>
            <CurrentUserCan
              allowedRoles={[GLOBAL_ROLE_SOFTWARE_MANAGER]}
              yes={
                <ClickableDiv onClick={handleClickSettings} data-testid="settings-gear">
                  <SettingsIcon />
                </ClickableDiv>
              }
            />
            <CurrentUserAvatar showProfileOnClick={true} />
          </ButtonsWrapper>
        </HeaderWrapper>
        <Content content={content} />
      </PanelWrapper>
    </Wrapper>
  );
}
