import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  imagesLoadingProgress: 0,
  imagesLoading: false,
  failedSeriesIds: [] as number[],
};

export const cornerstoneInfoSlice = createSlice({
  name: "cornerstoneInfoSlice",
  initialState,
  reducers: {
    setImagesLoadingProgress: (state, action: PayloadAction<number>) => {
      state.imagesLoadingProgress = action.payload;
    },
    setImagesLoading: (state, action: PayloadAction<boolean>) => {
      state.imagesLoading = action.payload;
    },
    setFailedSeriesIds: (state, action: PayloadAction<number[]>) => {
      state.failedSeriesIds = action.payload;
    },
  },
});

export const { setImagesLoadingProgress, setImagesLoading, setFailedSeriesIds } =
  cornerstoneInfoSlice.actions;
