import React from "react";
import styled from "styled-components";

import { TimepointType } from "../../../../../Annotate/components/Annotate/page/AnnotationPanel/hooks/Timepoint/TimepointType";
import { SeriesListItemFragmentType } from "../../../../../Annotate/components/Annotate/page/AnnotationPanel/hooks/useStudiesList";
import { formatTimepointLabel } from "../../../../../Annotate/components/Annotate/page/StudyPanel/utils/formatTimepointLabel";
import { useActiveViewerConfig } from "../../../../../Annotate/components/Annotate/page/ViewerPanel/Viewer/hooks/useActiveViewerConfig";
import { useChangeViewerSeries } from "../../../../../Annotate/components/Annotate/page/ViewerPanel/Viewer/hooks/useChangeViewerSeries";
import { SeriesListItem } from "./SeriesListItem";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TimepointWrapper = styled.div<{ selected: boolean }>`
  cursor: pointer;
  font-weight: ${({ selected }) => (selected ? "bold" : "normal")};
  margin-bottom: 2px;
`;

interface TimepointListItemProps {
  timepoint: TimepointType;
  selected: boolean;
  onSelect: (seriesId: number, timepoint: TimepointType) => void;
  includeDay: boolean;
}

export function TimepointListItem({
  timepoint,
  selected,
  onSelect,
  includeDay,
}: TimepointListItemProps): JSX.Element {
  const { series: seriesList } = timepoint;

  const changeViewerSeries = useChangeViewerSeries();

  const activeViewerConfig = useActiveViewerConfig();

  const { id: activeViewerId, seriesId: activeViewerSeriesId } = activeViewerConfig || {};

  const viewerSeries =
    seriesList.find(({ id }) => activeViewerSeriesId === id) ??
    (seriesList.length > 0 ? seriesList[0] : undefined);

  const handleSeriesClicked = ({ id }: SeriesListItemFragmentType) => {
    if (activeViewerId === undefined) {
      return;
    }
    changeViewerSeries(activeViewerId, id);

    onSelect(id, timepoint);
  };

  const handleTimepointClicked = () => {
    const series = seriesList[0];
    if (!series) {
      throw new Error("Should not be able to select timepoint with no series");
    }

    handleSeriesClicked(series);
  };

  if (!viewerSeries) {
    return <></>;
  }

  const studyLabel = formatTimepointLabel(timepoint, false, includeDay);

  return (
    <Wrapper>
      <TimepointWrapper onClick={() => handleTimepointClicked()} selected={selected}>
        {studyLabel}
      </TimepointWrapper>
      {seriesList.map((series, index) => (
        <SeriesListItem series={series} onClick={handleSeriesClicked} key={index} />
      ))}
    </Wrapper>
  );
}
