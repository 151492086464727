import React, { useEffect, useState } from "react";

import { isAnatomicalStructureLesion } from "../../../../isAnatomicalStructureLesion";
import { useAnatomicalStructures } from "../../../../useAnatomicalStructures";
import { LesionListItemFragmentType } from "../fragments/LesionListItemFragment";
import { AnatomicalStructureOptionType } from "./AnatomicalStructureOptionType";
import { AnatomyClassificationSelector } from "./AnatomyClassificationSelector";
import { getAnatomicalStructureOptions } from "./getAnatomicalStructureOptions";

interface AnatomyClassificationSelectorContainerProps {
  lesion: LesionListItemFragmentType;
  selected: AnatomicalStructureOptionType | null;
  onSelected: (value: AnatomicalStructureOptionType | null) => void;
  disabled: boolean;
}

export function AnatomyClassificationSelectorContainer({
  selected,
  onSelected,
  disabled,
}: AnatomyClassificationSelectorContainerProps): JSX.Element {
  const [options, setOptions] = useState<AnatomicalStructureOptionType[] | undefined>(undefined);

  const { data: anatomicalStructures } = useAnatomicalStructures();

  useEffect(() => {
    if (!anatomicalStructures) {
      setOptions(undefined);
      return;
    }

    const options = anatomicalStructures
      ? getAnatomicalStructureOptions(
          anatomicalStructures.filter((anatomy) => !isAnatomicalStructureLesion(anatomy))
        )
      : undefined;

    setOptions(options);
  }, [anatomicalStructures]);

  const selectedNotLesion = selected
    ? !isAnatomicalStructureLesion(selected.value)
      ? selected
      : null
    : null;

  return (
    <AnatomyClassificationSelector
      options={options}
      selected={selectedNotLesion}
      onSelected={onSelected}
      disabled={disabled}
    />
  );
}
