import { z } from "zod";

const OptionsSchema = z.object({
  includeManualMasks: z.boolean(),
  includePredictedMasks: z.boolean(),
  includeSeriesVolumes: z.boolean(),
  includePatientJson: z.boolean(),
  includeSummaryCsv: z.boolean(),
  includePredictedSummaries: z.boolean(),
});

export const safeParse = OptionsSchema.safeParse;

export type Options = z.infer<typeof OptionsSchema>;
