import { GraphView, IPRO, MORTALITY, RECIST } from "../../ChangeFromBaseline/components/Filter";
import { formatDataType } from "../../common/utils/formatDataType";
import { AnalysisGraphKeys } from "./getMedianSurvival";

export const getDataTypeFromName = (name: string, graphView: GraphView) => {
  return ([RECIST, IPRO, MORTALITY] as AnalysisGraphKeys[]).find((type) => {
    const formattedName = formatDataType(type, graphView);
    return name.startsWith(formattedName);
  });
};
