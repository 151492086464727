import React, { ReactNode } from "react";
import styled from "styled-components";

import { SideNav } from "../common/components/SideNav";
import { ProjectLayoutType } from "./Layout/Layout";

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  width: 100%;
  overflow: hidden;
`;

const SideNavWrapper = styled.div`
  height: 100%;
  width: 240px;
  background: ${(props) => props.theme.colors.neutral.black};
`;

const ContentWrapper = styled.div`
  width: calc(100% - 240px);
`;

interface ProjectProps {
  children: ReactNode;
  layout: ProjectLayoutType;
}

export function Project({ children, layout }: ProjectProps): JSX.Element {
  return (
    <Wrapper>
      <SideNavWrapper>
        <SideNav layout={layout} />
      </SideNavWrapper>
      <ContentWrapper>{children}</ContentWrapper>
    </Wrapper>
  );
}
