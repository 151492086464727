import React from "react";
import styled from "styled-components";

import { ClassificationAttributeType } from "../../../../Annotate/components/Manage/hooks/useClassificationAttributes";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

interface ClassificationLabelProps {
  classification: ClassificationAttributeType;
}

export function ClassificationLabel({ classification }: ClassificationLabelProps): JSX.Element {
  const { text } = classification;

  return <Wrapper>{text}</Wrapper>;
}
