import { StudyListItemFragmentType } from "../components/Annotate/page/AnnotationPanel/hooks/useStudiesList";
import { CacheInstanceType } from "../components/Annotate/page/ViewerPanel/utils/getSeriesInstancesFromCache";
import { sortSopInstances } from "../utilities/sortSopInstances";
import { getWadoUrl } from "./getWadoUrl";

export type ParsedStudyType = {
  id: number;
  studyInstanceUid: string;
  series: ParsedSeriesType[];
};

export type ParsedSeriesType = {
  id: number;
  studyId: number;
  seriesInstanceUid: string | null;
  instances: ParsedInstanceType[];
};

export type ParsedInstanceType = CacheInstanceType & {
  seriesId: number;
  sopInstanceUid: string | null;
};

export function parseStudy(
  studyFragment: StudyListItemFragmentType,
  baseWadoUrl: string
): ParsedStudyType {
  const { id, studyInstanceUid, series: originalSeriesList } = studyFragment;
  const seriesList = originalSeriesList.map((series) => {
    const { instances: originalInstances, seriesInstanceUid } = series;
    const instances: ParsedInstanceType[] = originalInstances.map((instance) => {
      const { sopInstanceUid } = instance;

      //we allow the wado url to be changed in different environments to preserve the image viewing functionality when restore db backups. since the front end can't easily determine this path, we inject it here instead. We also handle sorting here which may not be necessary
      const wadoUrl = getWadoUrl(baseWadoUrl, studyInstanceUid, seriesInstanceUid, sopInstanceUid);

      return { ...instance, wadoUrl };
    });

    const sortedInstances = sortSopInstances(instances);
    return { ...series, instances: sortedInstances };
  });
  return { id, studyInstanceUid, series: seriesList };
}
