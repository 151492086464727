import React from "react";

import { TUMOUR_VOLUME } from "../../../../../enums/TaskDescriptionEnums";
import { statsDisplayName } from "../../../../../types/TaskDescriptionType";
import { SimpleBodyTimepointStat } from "./SimpleBodyTimepointStat";
import { RoiStatComponentDefinition, RoiStatProps } from "./useGetRoiStatComponents";
import { formatCM3 } from "./utils/formatCM3";

function RoiVolumeStat(props: RoiStatProps): JSX.Element {
  const { roi } = props;
  const volume = roi.roiRecistEvaluation?.volume;

  if (!volume || !hasValue(props)) {
    throw new Error("Should not render");
  }

  const label = statsDisplayName[TUMOUR_VOLUME];
  const formattedValue = formatCM3(volume);

  return <SimpleBodyTimepointStat title={label} value={formattedValue} />;
}

function hasValue({ roi: { roiRecistEvaluation } }: RoiStatProps): boolean {
  return !!roiRecistEvaluation?.volume;
}

export const roiVolumeStatDefinition: RoiStatComponentDefinition = {
  type: TUMOUR_VOLUME,
  hasValue,
  component: RoiVolumeStat,
};
