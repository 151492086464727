import { RoiId } from "../../ViewerPanel/selection/RoiId";
import { useGetViewerForSeries } from "../../ViewerPanel/Viewer/hooks/useGetViewerForSeries";
import { useJumpToViewerRoiKeyImage } from "./useJumpToViewerRoiKeyImage";

export function useJumpToRoiKeyImage() {
  const getViewerForSeries = useGetViewerForSeries();

  const jumpToViewerRoiKeyImage = useJumpToViewerRoiKeyImage();

  return (roi: RoiId) => {
    const {
      series: { id: seriesId },
    } = roi;
    const { id: viewerConfigId } = getViewerForSeries(seriesId) || {};
    if (viewerConfigId === undefined) {
      return;
    }

    jumpToViewerRoiKeyImage(roi, viewerConfigId);
  };
}
