import React, { useContext, useEffect } from "react";

import { Card } from "../common/components/cards/Card";
import { BigFlexLoading } from "../common/components/Loading";
import { EditProjectUsers } from "../common/components/UserManagement/EditProjectUsers";
import {
  manageProjectUserContext,
  UserManagementContext,
  UserManagementContextProvider,
} from "../common/components/UserManagement/UserManagementContext";
import { useSendResetPasswordEmail } from "../common/components/UserManagement/useSendResetPasswordEmail";
import { useUpdateProjectUserRoles } from "../common/components/UserManagement/useUpdateProjectUserRoles";
import { useUpdateUserMetadata } from "../common/components/UserManagement/useUpdateUserMetadata";
import { useCurrentUserCan } from "../common/contexts/UserContext/useCurrentUserCan";
import { PROJECT_ROLE_COORDINATOR } from "../common/types/UserRoleType";
import { isExistingUser, isNewUser, UserType } from "../common/types/UserType";
import handleApolloError from "../common/utils/handleApolloError";
import { ProjectContext } from "../Project/contexts/ProjectContext";
import { useProjectId } from "../Project/hooks/useProjectId";

export function UserManagementCard(): JSX.Element {
  const { setUsers } = useContext(UserManagementContext);

  const { users, addUsers, removeUser, refetchUsers } = useContext(ProjectContext);

  const projectId = useProjectId();

  const isManager = useCurrentUserCan([PROJECT_ROLE_COORDINATOR]);

  const userManagementContext = {
    ...manageProjectUserContext,
    allowAddUser: isManager,
    allowRemoveUser: isManager,
    allowResetPassword: isManager,
    allowEditRole: isManager,
    allowEditOtherUser: isManager,
  };

  const [
    updateUserMetadata,
    { loading: updateUserMetadataLoading, error: updateUserMetadataError },
  ] = useUpdateUserMetadata();

  const [
    updateProjectUserRoles,
    { loading: updateProjectUserRolesLoading, error: updateProjectUserRolesError },
  ] = useUpdateProjectUserRoles();

  const [resetUserPassword, { error: resetUserPasswordError, loading: resetUserPasswordLoading }] =
    useSendResetPasswordEmail();

  useEffect(() => {
    if (!users) {
      setUsers([]);
      return;
    }
    setUsers(users);
  }, [users]);

  if (updateUserMetadataLoading) return <BigFlexLoading />;
  if (updateUserMetadataError) handleApolloError(updateUserMetadataError);
  if (updateProjectUserRolesLoading) return <BigFlexLoading />;
  if (updateProjectUserRolesError) handleApolloError(updateProjectUserRolesError);
  if (resetUserPasswordLoading) return <BigFlexLoading />;
  if (resetUserPasswordError) handleApolloError(resetUserPasswordError);

  const handleUsersCreated = async (users: UserType[]) => {
    const usersToBeCreated = users.filter(isNewUser);
    if (usersToBeCreated.length > 0) {
      throw new Error("Should not be able to create new users from UserManagementCard");
    }

    const existingUsers = users.filter(isExistingUser);

    for (const user of existingUsers) {
      await updateProjectUserRoles({ user, projectId });
    }

    await addUsers(existingUsers);

    await refetchUsers();
  };

  const handleUserRemoved = async (user: UserType) => {
    await removeUser(user);
    await refetchUsers();
  };

  const handleUserEdited = async (user: UserType) => {
    await updateUserMetadata({ user }, {});
    await updateProjectUserRoles({ user, projectId }, {});
    await refetchUsers();
  };

  const handleUserPasswordReset = async (user: UserType) => {
    await resetUserPassword(user);
  };

  return (
    <UserManagementContextProvider
      rules={userManagementContext}
      users={users}
      setUsers={setUsers}
      onEdited={handleUserEdited}
      onPasswordReset={handleUserPasswordReset}
      onRemoved={handleUserRemoved}
      onCreated={handleUsersCreated}
    >
      <Card noHead={true} expand={true} content={<EditProjectUsers />} />
    </UserManagementContextProvider>
  );
}
