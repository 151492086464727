import { EcogFilters } from "../../types/ProjectViewFilterTypes";
import { RootState } from "../store";
import { ProjectFilterSelectors } from "./ProjectFilterSelectors";

export const defaultEcogState = {
  "0": true,
  "1": true,
};

export const clearEcogState = {
  "0": false,
  "1": false,
};
export const ecogFilters = new ProjectFilterSelectors<EcogFilters>(
  defaultEcogState,
  clearEcogState,
  (state: RootState) => state.projectViewFilters.ecog
);
