import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { QueryResult } from "@apollo/client/react/types/types";
import { useContext } from "react";

import { TaskProgressType } from "../../../Annotate/components/TaskWorklist/TaskProgressType";
import { PROJECT_SERIES_EXCLUSION_FRAGMENT } from "../../../Annotate/fragments/ProjectSeriesExclusionFragment";
import { SERIES_FLAG_FRAGMENT } from "../../../Annotate/fragments/SeriesFlagFragment";
import { SeriesFlagType } from "../../../Annotate/types/SeriesFlagType";
import { COHORT_FRAGMENT } from "../../../Project/contexts/Project/CohortFragment";
import { ProjectContext } from "../../../Project/contexts/ProjectContext";
import { CohortType } from "../../types/CohortType";
import { PendingInstanceType } from "../../types/PendingInstanceType";

const QUERY = gql`
  query GetPatients(
    $where: patient_bool_exp!
    $seriesExclusionProjectFilter: project_series_exclusion_bool_exp!
    $seriesFilter: series_bool_exp = {}
    $cohortsFilter: label_patient_bool_exp = {}
  ) {
    patients: patient(
      where: $where
      order_by: { studies_aggregate: { max: { last_modified: desc } } }
    ) {
      id: patient_id
      patientDicomId: patient_dicom_id
      taskAssignments: task_assignments {
        progress
        showBadge: show_badge
      }
      cohorts: label_patients(where: $cohortsFilter) {
        patient_id
        cohort_id: label_id
        cohort: label {
          ...Cohort
        }
      }
      tempDemoPatients: temp_demo_patients {
        id: patient_id
      }
      studies(where: { series: $seriesFilter }, order_by: { last_modified: desc }) {
        id: study_id
        studyInstanceUid: study_instance_uid
        studyDicomId: study_dicom_id
        studyDate: study_date
        series(where: $seriesFilter) {
          id: series_id
          seriesInstanceUid: series_instance_uid
          seriesDate: series_date
          description: series_description
          computedSliceThicknesses: computed_slice_thickness
          computedAnatomicalPlanes: computed_anatomical_plane
          instances: instances_aggregate {
            aggregate {
              count
            }
          }
          projectExclusions: project_series_exclusions(where: $seriesExclusionProjectFilter) {
            ...ProjectSeriesExclusion
          }
          seriesFlags: series_flags {
            ...SeriesFlag
            task {
              id
              project_id
              qcSchemaId: qc_schema_id
            }
          }
          pendingInstances: pending_instances {
            id
            status
          }
          instanceModalities: instances(distinct_on: [modality]) {
            modality
          }
          instanceSliceThickness: instances(distinct_on: [slice_thickness]) {
            sliceThickness: slice_thickness
          }
        }
      }
    }
  }
  ${COHORT_FRAGMENT}
  ${PROJECT_SERIES_EXCLUSION_FRAGMENT}
  ${SERIES_FLAG_FRAGMENT}
`;

export type PatientFragmentType = {
  id: number;
  patientDicomId: string;
  taskAssignments: { progress: TaskProgressType; showBadge: boolean }[];
  cohorts: { cohort: CohortType }[];
  studies: StudyFragmentType[];
  tempDemoPatients: { id: number }[];
};

export type StudyFragmentType = {
  id: number;
  studyInstanceUid: string;
  studyDicomId: string | null;
  studyDate: string | null;
  series: SeriesFragmentType[];
};

export type SeriesFragmentType = {
  id: number;
  seriesInstanceUid: string;
  seriesDate: string | null;
  description: string | null;
  computedSliceThicknesses: number[] | null;
  computedAnatomicalPlanes: string[] | null;
  instances: { aggregate: { count: number } };
  instanceModalities: {
    modality: string;
  }[];
  instanceSliceThickness: {
    sliceThickness: number | null;
  }[];
  projectExclusions: {
    projectId: number;
    seriesId: number;
  }[];
  seriesFlags: (SeriesFlagType & {
    task: {
      id: number;
      project_id: number;
      qcSchemaId: number | null;
    };
  })[];
  pendingInstances: PendingInstanceType[];
};

type Data = {
  patients: PatientFragmentType[];
};

type Variables = {
  where: {
    _and: [
      // eslint-disable-next-line @typescript-eslint/ban-types
      { patient_id: { _in: number[] } } | {},
      // eslint-disable-next-line @typescript-eslint/ban-types
      { _not: { temp_demo_patients: {} } } | {},
      (
        | {
            label_patients: {
              label: {
                label_projects: { project_id: { _eq: number } };
                type_name: { _eq: "COHORT" };
              };
            };
          }
        // eslint-disable-next-line @typescript-eslint/ban-types
        | {}
      )
    ];
  };
  seriesExclusionProjectFilter:
    | {
        project_id: { _eq: number };
      }
    // eslint-disable-next-line @typescript-eslint/ban-types
    | {};
  seriesFilter:
    | { _not: { project_series_exclusions: { project_id: { _eq: number } } } }
    | undefined;
  cohortsFilter: {
    label: {
      type_name: { _eq: "COHORT" };
      project_id:
        | {
            _eq: number;
          }
        | {
            _is_null: boolean;
          };
    };
  };
};

export function usePatientStudies(
  patientIds: number[] | undefined,
  excludeTempDemoPatients: boolean,
  restrictToProject?: boolean
): QueryResult<Data, Variables> {
  const { projectId } = useContext(ProjectContext);
  const patientIdFilter = patientIds ? { patient_id: { _in: patientIds } } : {};
  const tempDemoPatientsFilter = excludeTempDemoPatients
    ? { _not: { temp_demo_patients: {} } }
    : {};
  const projectPatientsFilter =
    restrictToProject && projectId !== undefined
      ? {
          label_patients: {
            label: {
              label_projects: { project_id: { _eq: projectId } },
              type_name: { _eq: "COHORT" },
            },
          },
        }
      : {};
  const seriesExclusionProjectFilter =
    projectId !== undefined ? { project_id: { _eq: projectId } } : {};
  const queryResults = useQuery<Data, Variables>(QUERY, {
    variables: {
      where: { _and: [patientIdFilter, tempDemoPatientsFilter, projectPatientsFilter] },
      seriesExclusionProjectFilter,
      seriesFilter:
        projectId !== undefined
          ? {
              _not: {
                project_series_exclusions: {
                  project_id: { _eq: projectId },
                },
              },
            }
          : undefined,
      cohortsFilter:
        restrictToProject && projectId !== undefined
          ? {
              label: {
                type_name: { _eq: "COHORT" },
                project_id: { _eq: projectId },
              },
            }
          : {
              label: {
                type_name: { _eq: "COHORT" },
                project_id: { _is_null: true },
              },
            },
    },
  });

  if (projectId) {
    return {
      ...queryResults,
      data: filterFlags(projectId, queryResults.data),
    };
  }
  return queryResults;
}

function filterFlags(projectId: number, data?: Data): Data | undefined {
  if (!data) return;

  const flagFilteredPatientsData: PatientFragmentType[] = data.patients.map((patient) => {
    const flagFilteredStudies: StudyFragmentType[] = patient.studies.map((study) => {
      const flagFilteredSeries: SeriesFragmentType[] = study.series.map((seriesItem) => {
        return {
          ...seriesItem,
          seriesFlags: seriesItem.seriesFlags.filter((seriesFlag) => {
            if (!seriesFlag.task) {
              return false;
            }
            return seriesFlag.task.project_id === projectId;
          }),
        };
      });
      return {
        ...study,
        series: flagFilteredSeries,
      };
    });
    return {
      ...patient,
      studies: flagFilteredStudies,
    };
  });
  return {
    patients: flagFilteredPatientsData,
  };
}
