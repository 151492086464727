import { TimepointOptionsType } from "../../../../../../types/TaskDescriptionType";
import { LesionListItemFragmentType } from "../../fragments/LesionListItemFragment";
import { RoiListItemFragmentType } from "../../fragments/RoiListItemFragment";
import { useGetTimepointOptionsForLesion } from "./useGetTimepointOptionsForLesion";

export type TimepointOptionsFromRoiClassificationsReturnType = (
  lesion: LesionListItemFragmentType,
  { classifications }: RoiListItemFragmentType,
  filter?: (option: TimepointOptionsType) => boolean
) => TimepointOptionsType[];

export function useGetTimepointOptionsFromRoiClassifications(): TimepointOptionsFromRoiClassificationsReturnType {
  const getTimepointOptionsForLesion = useGetTimepointOptionsForLesion();

  return (lesion, { classifications }, filter) => {
    const options = getTimepointOptionsForLesion(lesion);

    return options
      .filter(({ rule }) => classifications.some(({ classification }) => rule === classification))
      .filter(({ showWhenClassified }) => showWhenClassified)
      .filter((option) => !filter || filter(option));
  };
}
