import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { recistTaskSelector } from "../../../../../../common/store/annotatePage/taskSelector";
import { LESION } from "../../../../../types/LesionTypeType";
import { useTimepointsNonViewOnlySeriesIds } from "../../AnnotationPanel/hooks/Timepoint/useTimepointsNonViewOnlySeriesIds";
import { useContourListener } from "../../AnnotationPanel/hooks/useContourListener";
import { useLesionsList } from "../../AnnotationPanel/hooks/useLesionsList";
import { lesionListItemTypeFilters } from "../../AnnotationPanel/hooks/useTaskLesionListOptions";
import {
  getVariables,
  useUpdateTumourResponses,
} from "../../AnnotationPanel/hooks/useUpdateTumourResponses";
import { SeriesTumourResponseType } from "../utils/TumourResponse/types/SeriesTumourResponseType";
import { useCalculateTumourResponse } from "./useCalculateTumourResponse";

export function useGetAndSaveTumourResponses(): SeriesTumourResponseType[] {
  const [responses, setResponses] = useState<SeriesTumourResponseType[]>([]);

  const { data: lesionsData } = useLesionsList(lesionListItemTypeFilters[LESION]);
  useContourListener(lesionsData?.lesions.flatMap(({ rois }) => rois.map(({ id }) => id)) ?? []);

  const seriesIds = useTimepointsNonViewOnlySeriesIds();

  const seriesTumourResponses = useCalculateTumourResponse();

  const isRecist = useSelector(recistTaskSelector);

  const [updateTumourResponses] = useUpdateTumourResponses();

  useEffect(() => {
    if (
      !isRecist ||
      !lesionsData ||
      !seriesIds ||
      JSON.stringify(responses) === JSON.stringify(seriesTumourResponses)
    ) {
      return;
    }

    const { lesions } = lesionsData;
    const rois = lesions
      .flatMap(({ rois }) => rois.map((roi) => roi))
      .filter(({ series: { id: seriesId } }) => seriesIds.includes(seriesId));

    const variables = getVariables(rois, seriesTumourResponses);
    updateTumourResponses({ variables }).then(() => undefined);
    setResponses(seriesTumourResponses);
  }, [seriesTumourResponses, lesionsData, seriesIds]);

  return seriesTumourResponses;
}
