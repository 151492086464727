import { Menu, MenuItem } from "@mui/material";
import React, { ComponentType } from "react";
import styled from "styled-components";

import { getMouseState } from "../../Annotate/components/Annotate/page/ViewerPanel/utils/getMouseState";
import { useGetComponentAsImage } from "../utils/useGetComponentAsImage";

const ContextMenuWrapper = styled.div`
  width: 100%;
  height: 100%;
`;
interface ImageCaptureContextMenuContainerProps {
  children: ComponentType<{
    reference: React.MutableRefObject<{ current: HTMLElement } | undefined>;
  }>;
}

export function ImageCaptureContextMenuContainer({
  children: Component,
}: ImageCaptureContextMenuContainerProps): JSX.Element {
  const [getImage, { ref }] = useGetComponentAsImage();
  const [anchorPos, setAnchorPos] =
    React.useState<{ top: number; left: number } | undefined>(undefined);

  const open = Boolean(anchorPos);
  const isNotFirefox = navigator.userAgent.indexOf("Firefox") < 0;

  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    const eventPos = {
      event: { clientX: event.clientX, clientY: event.clientY },
    };

    const mouseState = event ? getMouseState(eventPos) : undefined;
    setAnchorPos(mouseState ? { top: mouseState.y, left: mouseState.x } : undefined);
  };

  const handleClose = () => {
    setAnchorPos(undefined);
  };

  const handleDownload = async () => {
    const image = await getImage();

    if (image) {
      const link = document.createElement("a");
      link.setAttribute("href", image.toDataURL("image/png", 1.0));
      link.setAttribute("download", "Chart.png");
      document.body.appendChild(link);

      link.click();
      document.body.removeChild(link);
      handleClose();
    }
  };

  const handleCopy = async () => {
    const image = await getImage();

    if (image && isNotFirefox) {
      image.toBlob((blob) => {
        if (blob) {
          navigator.clipboard.write([
            new ClipboardItem({
              "image/png": blob,
            }),
          ]);
        }
      });
      handleClose();
    }
  };

  return (
    <ContextMenuWrapper onContextMenu={handleClick}>
      <Menu
        onContextMenu={(e) => e.preventDefault()}
        keepMounted
        open={open}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={anchorPos}
      >
        <MenuItem
          onClick={handleCopy}
          disabled={!isNotFirefox}
          title={
            !isNotFirefox
              ? "Copying as an image is not currently supported in Firefox, please switch to Google Chrome should you desire this feature."
              : undefined
          }
          style={{ pointerEvents: "auto" }}
        >
          Copy as image
        </MenuItem>
        <MenuItem onClick={handleDownload}>Save as png</MenuItem>
      </Menu>
      <Component reference={ref} />
    </ContextMenuWrapper>
  );
}
