import React, { useState } from "react";
import styled from "styled-components";

import { PatientContextListContainer } from "./PatientContextListContainer";
import { SeriesStudyFilter } from "./SeriesStudyFilter";
import { TaskInfo } from "./TaskInfo";
import { TimepointListContainer } from "./TimepointListContainer";

const Wrapper = styled.div`
  flex: 5;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 3px;
  gap: 6px;
`;

const ListWrapper = styled.div`
  overflow-y: auto;
`;

const PatientContextWrapper = styled.div`
  margin-top: auto;
`;

export function TimepointPanel(): JSX.Element {
  const [matchesCount, setMatchesCount] = useState<number>(-1);
  const [filter, setFilter] = useState<string>("");

  return (
    <Wrapper>
      <TaskInfo />
      <SeriesStudyFilter filter={filter} setFilter={setFilter} matchesCount={matchesCount} />
      <ListWrapper>
        <TimepointListContainer
          filter={filter}
          onFilterChanged={(matches: number) => setMatchesCount(matches)}
        />
      </ListWrapper>
      <PatientContextWrapper>
        <PatientContextListContainer />
      </PatientContextWrapper>
    </Wrapper>
  );
}
