import { ApolloCache } from "@apollo/client";
import { FetchResult } from "@apollo/client/link/core";

import { getRoiCacheId } from "../../../../AnnotationPanel/hooks/getRoiCacheId";
import { useRemoveContourMeasurements } from "./useRemoveContourMeasurements";

export const INTERNAL_MUTATION = `
    delete: delete_roi_recist_evaluations(where: { roi_id: { _in: $roiRecistEvaluationIdsToDelete } }) {
      roiIds: returning {
        id: roi_id
      }
    }
`;

export type Variables = {
  roiRecistEvaluationIdsToDelete: number[];
};

export type Data = {
  delete: { roiIds: { id: number }[] };
};

export function useUpdateCache(): (cache: ApolloCache<Data>, { data }: FetchResult<Data>) => void {
  const removeContourMeasurements = useRemoveContourMeasurements();

  return (cache, { data }) => {
    if (!data) {
      throw new Error(
        "Something went wrong updating the cache after deleting roi recist evaluations"
      );
    }
    const {
      delete: { roiIds },
    } = data;
    for (const { id } of roiIds) {
      removeContourMeasurements(id);

      cache.modify({
        id: getRoiCacheId(id, cache),
        fields: {
          roi_recist_evaluation() {
            return null;
          },
        },
      });
    }
  };
}
