import dayjs from "dayjs";

import { DateFilterType } from "./dateFilters";
import { DayjsDateRange } from "./DayjsDateRange";

export function getRange(
  filter: DateFilterType,
  selectedMonth: dayjs.Dayjs,
  earliestDate: dayjs.Dayjs
): DayjsDateRange | undefined {
  switch (filter) {
    case "ALL_TIME":
      return {
        from: earliestDate,
        to: dayjs().endOf("day"),
      };
    case "MONTH":
      return {
        from: selectedMonth.startOf("month"),
        to: selectedMonth.endOf("month"),
      };
    case "WEEK":
      return {
        from: dayjs().subtract(7, "day"),
        to: dayjs(),
      };
    case "CUSTOM":
      return undefined;
    default:
      throw new Error(`Filter ${filter} not implemented`);
  }
}
