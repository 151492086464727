import { useNextLesion } from "./useNextLesion";

export type NextLesionIdReturnType = (currentLesionId: number) => number | null;

export function useNextLesionId(): NextLesionIdReturnType {
  const getNextLesion = useNextLesion();

  return (currentLesionId) => {
    const nextLesion = getNextLesion(currentLesionId);
    return nextLesion?.id ?? null;
  };
}
