import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { ProjectContext } from "../../../../Project/contexts/ProjectContext";
import { DASHBOARD } from "../../../../Project/Layout/RoutePathType";
import { useCurrentUser } from "../../../contexts/UserContext/useCurrentUser";
import { UserType } from "../../../types/UserType";
import { TaskReassignmentType } from "../types/TaskReassignmentType";
import { useReassignUsersTasks } from "../useReassignUsersTasks";
import { UserManagementContext } from "../UserManagementContext";
import { buildReassignmentData } from "../utils/buildReassignmentData";
import { useManageUserDeletionDialog, UserIdentifierType } from "./useManageUserDeletionDialog";
import { useRemoveUserFromProjectDialog } from "./useRemoveUserFromProjectDialog";

interface DeleteUserButtonProps {
  user: UserType;
  children: JSX.Element;
}

export function DeleteUserButton({ user, children }: DeleteUserButtonProps): JSX.Element {
  const { id: currentUserId } = useCurrentUser();
  const isCurrentUser = currentUserId === user.id;

  const { push } = useHistory();
  const { projectName, projectId, users } = useContext(ProjectContext);
  const {
    onRemoved,
    rules: { allowRemoveUser, isWithinProject },
  } = useContext(UserManagementContext);

  const [reassignUsersTasks] = useReassignUsersTasks();

  const [taskReassignments, setTaskReassignments] = useState<TaskReassignmentType[]>([]);

  const handleFinalizeDeleteUser = async () => {
    if (projectId) {
      setRemoveUserFromProjectDialogOpen(true);
    } else {
      await handleConfirmRemoveUser([user]);
    }
  };

  const handleConfirmRemoveUser = async (users: UserIdentifierType[]) => {
    if (!allowRemoveUser) {
      throw new Error("handleConfirmRemoveUser called but allowRemoveUser is not true");
    }
    if (projectId) {
      const taskReassignmentData = buildReassignmentData(taskReassignments);

      await reassignUsersTasks({
        variables: taskReassignmentData,
      });
    }
    for (const user of users) {
      onRemoved?.(user as UserType);
    }
    if (isCurrentUser && isWithinProject) {
      push(DASHBOARD);
    }
  };

  const [setRemoveUserFromProjectDialogOpen, { dialog: removeUserFromProjectDialog }] =
    useRemoveUserFromProjectDialog([user], handleConfirmRemoveUser);

  const [setManageUserDeletionDialogOpen, { dialog: manageUserDeletionDialog }] =
    useManageUserDeletionDialog(
      [user],
      taskReassignments,
      setTaskReassignments,
      handleFinalizeDeleteUser,
      users,
      projectName,
      projectId
    );

  const handleClickRemoveUser = async () => {
    if (taskReassignments.length > 0 && projectId) {
      setManageUserDeletionDialogOpen(true);
      return;
    }

    await handleFinalizeDeleteUser();
  };

  return (
    <>
      {manageUserDeletionDialog}
      {removeUserFromProjectDialog}
      <div onClick={handleClickRemoveUser}>{children}</div>
    </>
  );
}
