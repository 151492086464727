import { FC } from "react";

import { Card } from "../../../common/components/cards/Card";
import { UPLOAD_DICOM } from "../../../Project/Layout/Layout";
import { DragDropFileUpload } from "./DragDropFileUpload";

interface UploadCardProps {
  type?: typeof UPLOAD_DICOM;
}

export const UploadCard: FC<UploadCardProps> = ({ type }: UploadCardProps) => {
  return <Card noHead={true} content={<DragDropFileUpload type={type} />} />;
};
