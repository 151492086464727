import { Menu as MenuMui, MenuItem } from "@mui/material";
import React, { ReactElement } from "react";
import styled from "styled-components";

import IconButton from "../../../common/components/buttons/IconButton";
import { EllipsisIcon } from "../icons/EllipsisIcon";

export const StyledLink = styled.div`
  font-family: "Inter", verdana, arial, sans-serif;
  color: ${(props) => props.theme.colors.actionPrimary.active};
  padding-left: 10px;
`;

export const IconWrapper = styled.div`
  top: 3.5px;
  color: ${(props) => props.theme.colors.primary.primary50};
  position: relative;
`;

export const StyledMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
`;

interface MenuProps {
  children: React.ReactNode;
  id: string;
  anchorEl: null | HTMLElement;
  handleClick: React.MouseEventHandler;
  handleClose: React.MouseEventHandler;
  icon?: JSX.Element;
}

const Menu = ({
  children,
  anchorEl,
  handleClick,
  handleClose,
  id,
  icon,
}: MenuProps): ReactElement => {
  return (
    <div>
      <IconButton onClick={handleClick} icon={icon || <EllipsisIcon />} />
      <MenuMui
        id={id}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {children}
      </MenuMui>
    </div>
  );
};

export default Menu;
