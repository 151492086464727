export function sortData<T extends Record<string, unknown>>(
  data: T[],
  getCount: (data: T) => number,
  order: "ASCENDING" | "DESCENDING" = "DESCENDING"
): T[] {
  return [...data].sort((a, b) => {
    const countA = getCount(a);
    const countB = getCount(b);

    return order === "ASCENDING" ? countA - countB : countB - countA;
  });
}
