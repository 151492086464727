import { useEffect, useRef, useState } from "react";

import ToolController from "../../../../../../Annotate/components/Annotate/page/ViewerPanel/tools/legacy/ToolController";
import { ToolControllerType } from "../../../../../../Annotate/components/Annotate/page/ViewerPanel/tools/toolController/ToolControllerType";
import { useActiveTool } from "../../../../../../Annotate/components/Annotate/page/ViewerPanel/Viewer/hooks/useActiveTool";
import { useSetViewerToolQueue } from "../../../../../../Annotate/components/Annotate/page/ViewerPanel/Viewer/hooks/useSetViewerToolQueue";
import {
  CANCEL_QUEUE,
  DRAW_QUEUE,
  MEASURE_QUEUE,
  ViewerConfigType,
  WINDOW_LEVEL_PRESET_QUEUE,
} from "../../../../../../common/store/annotatePage/ViewerConfigType";
import { ImageViewerSynchronizer } from "../../../../../../cornerstone/ImageViewerSynchronizer";
import { MEASURE } from "../../../../../../cornerstone/ToolType";

// FIXME: There are 2 ToolControllers, 2 useToolControllers, and an AnnotateViewerToolController. See #185044274
// --B

export function useToolController(
  viewerConfig: ViewerConfigType,
  element: HTMLDivElement | null
): void {
  const [toolController, setToolController] = useState<ToolControllerType | null>(null);

  const elementRef = useRef<HTMLDivElement>();

  const { activeTool, lastActiveTool } = useActiveTool();

  const setViewerToolQueue = useSetViewerToolQueue();

  const { toolQueues } = viewerConfig;

  useEffect(() => {
    elementRef.current = element ?? undefined;
    if (!element) {
      return;
    }

    const imageViewerSynchronizer = ImageViewerSynchronizer.getInstance();
    imageViewerSynchronizer.add(element);

    let toolController: ToolController;
    try {
      toolController = new ToolController(element, false);
    } catch (e) {
      console.error("ToolController failed to load", e);
      return;
    }
    setToolController(toolController);
  }, [element]);

  useEffect(() => {
    return () => {
      const element = elementRef.current;
      if (!element) {
        return;
      }

      const imageViewerSynchronizer = ImageViewerSynchronizer.getInstance();
      imageViewerSynchronizer.remove(element);
    };
  }, []);

  useEffect(() => {
    if (activeTool === lastActiveTool) {
      return;
    }
    toolController?.handleActiveToolChanged(activeTool, lastActiveTool);
  }, [activeTool, lastActiveTool]);

  useEffect(() => {
    if (!toolController || !toolQueues) {
      return;
    }

    const { id: viewerConfigId } = viewerConfig;
    const cancel = toolQueues?.[CANCEL_QUEUE];
    if (cancel) {
      toolController.cancelActiveTool();
      setViewerToolQueue({
        viewerConfigId,
        queue: CANCEL_QUEUE,
        value: false,
      });
    }

    const draw = toolQueues?.[DRAW_QUEUE];
    if (draw) {
      toolController.forceRedraw();
      setViewerToolQueue({ viewerConfigId, queue: DRAW_QUEUE, value: false });
    }

    const windowLevelPreset = toolQueues?.[WINDOW_LEVEL_PRESET_QUEUE];
    if (windowLevelPreset) {
      const { window, level } = windowLevelPreset;
      toolController.setWindowLevel(window, level);
      setViewerToolQueue({
        viewerConfigId,
        queue: WINDOW_LEVEL_PRESET_QUEUE,
        value: null,
      });
    }

    const measure = toolQueues?.[MEASURE_QUEUE];
    if (!measure) {
      toolController.setPassiveTool(MEASURE);
    } else {
      if (activeTool === MEASURE) {
        toolController.setActiveTool(activeTool);
      } else {
        toolController.setPassiveTool(MEASURE);
        setViewerToolQueue({
          viewerConfigId,
          queue: MEASURE_QUEUE,
          value: false,
        });
      }
    }
  }, [toolController, toolQueues, activeTool]);
}
