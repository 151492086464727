import { INSTANCE_ERROR, INSTANCE_PENDING } from "../../../types/PendingInstanceStatusTypes";
import { PendingInstanceType } from "../../../types/PendingInstanceType";
import { StatusIndicationType } from "../../../types/StatusIndicationType";
import {
  ERROR,
  INFO,
  SUCCESS as SUCCESS_STATUS,
  TaskStatusType,
  WARNING,
} from "../../../types/StatusTypes";
import { StudyFragmentType } from "../usePatientStudies";

export function getUploadStatus({ series }: StudyFragmentType): StatusIndicationType {
  const allPendingInstances = series.flatMap(({ pendingInstances }) => pendingInstances);

  const state = getStateFromPendingInstances(allPendingInstances);
  return getStatusIndicationType(state);
}

export function getStateFromPendingInstances(
  pendingInstances: PendingInstanceType[]
): TaskStatusType {
  const hasErrorInstance = pendingInstances.some(({ status }) => status === INSTANCE_ERROR);
  if (hasErrorInstance) {
    return ERROR;
  }

  const hasPendingInstance = pendingInstances.some(({ status }) => status === INSTANCE_PENDING);
  if (hasPendingInstance) {
    return WARNING;
  }

  return SUCCESS_STATUS;
}

function getStatusIndicationType(status: TaskStatusType): StatusIndicationType {
  return {
    status: getText(status),
    state: status,
    isBusy: isBusy(status),
  };
}

export function isBusy(status: TaskStatusType): boolean {
  return status !== SUCCESS_STATUS && status !== ERROR;
}

export function getText(status: TaskStatusType): string {
  const values: Record<TaskStatusType, string> = {
    [SUCCESS_STATUS]: "Completed",
    [INFO]: "In Progress",
    [WARNING]: "Pending",
    [ERROR]: "Review",
  };
  return values[status];
}
