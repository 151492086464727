import { FC } from "react";

import { FilterType } from "../../ChangeFromBaseline/components/Filter";
import { useFilteredSubjectsQuery } from "../../common/hooks/useFilteredSubjectsQuery";
import { getMedianSurvivalSeries } from "../utils/getMedianSurvival";
import TumorBurdenSurvivalPredictionChart from "./TumorBurdenSurvivalPredictionChart";

interface TumorBurdenSurvivalPredictionContainerProps {
  allowedDataTypes: FilterType[];
}

export const TumorBurdenSurvivalPredictionContainer: FC<TumorBurdenSurvivalPredictionContainerProps> =
  ({ allowedDataTypes }: TumorBurdenSurvivalPredictionContainerProps) => {
    const subjects = useFilteredSubjectsQuery();

    const medianSurvivalSeries = getMedianSurvivalSeries(subjects);

    return (
      <TumorBurdenSurvivalPredictionChart
        allowedDataTypes={allowedDataTypes}
        medianSurvivalSeries={medianSurvivalSeries}
      />
    );
  };
