import React, { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";

import { ActionButtonsWrapper } from "../../../../../common/components/Dialog/Form/ActionButtonsWrapper";
import { FormProps } from "../../../../../common/components/Dialog/FormProps";
import { RED_BUTTON_OVERRIDES } from "../../../../../common/components/Dialog/RedButtonOverrides";
import { useDialogReturnType } from "../../../../../common/components/Dialog/useDialog";
import { useFormDialog } from "../../../../../common/components/Dialog/useFormDialog";
import { useOkCancelForm } from "../../../../../common/components/Dialog/useOkCancelForm";
import { ErrorLabel } from "../../../../../common/components/input/ErrorLabel";
import { InputButton } from "../../../../../common/components/input/InputButton";
import { Label } from "../../../../../common/components/input/Label";
import { TextArea } from "../../../../../common/components/input/TextArea";
import { useCurrentUser } from "../../../../../common/contexts/UserContext/useCurrentUser";
import { main } from "../../../../../common/theme/main";
import { useProjectId } from "../../../../../Project/hooks/useProjectId";
import { SeriesNotesFragmentType } from "../../../../fragments/SeriesNotesFragmentType";
import { useDeleteSeriesNotes } from "../AnnotationPanel/hooks/useDeleteSeriesNotes";
import { useInsertSeriesNotes } from "../AnnotationPanel/hooks/useInsertSeriesNotes";
import { SeriesListItemFragmentType } from "../AnnotationPanel/hooks/useStudiesList";
import { useUpdateSeriesNotes } from "../AnnotationPanel/hooks/useUpdateSeriesNotes";
import { useCanEditSeriesObservations } from "./hooks/useCanEditSeriesObservations";
import { SeriesDialogHeader } from "./SeriesDialogHeader";
import { formatSeriesDisplayName } from "./utils/formatSeriesDisplayName";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 15px 20px 15px;
  gap: 24px;
  width: 419px;
`;

interface SeriesNotesFormProps {
  series: SeriesListItemFragmentType;
}

const SeriesNotesForm: FC<FormProps<SeriesNotesFormProps>> = ({
  onSubmit,
  onCancel,
  props: { series },
}: FormProps<SeriesNotesFormProps>) => {
  const { id: userId } = useCurrentUser();
  const projectId = useProjectId();
  const { id: seriesId, notes } = series;

  const seriesNotes = notes[0];

  const isNewFlag = seriesNotes === undefined;

  const canEdit = useCanEditSeriesObservations(series);

  const [insertSeriesNotes] = useInsertSeriesNotes();
  const [updateSeriesNotes] = useUpdateSeriesNotes();
  const [deleteSeriesNotes] = useDeleteSeriesNotes();

  const handleConfirmDeleteNotes = async () => {
    if (!seriesNotes) {
      throw new Error("seriesNotes is undefined in handleConfirmDeleteNotes");
    }

    const { id } = seriesNotes;
    await deleteSeriesNotes({ variables: { id } });
    onSubmit();
  };

  const [showConfirmDeleteDialog, { dialog: confirmDeleteDialog }] = useOkCancelForm({
    title: `Delete Series Notes`,
    message: `Are you sure you want to delete this notes for series: ${formatSeriesDisplayName(
      series
    )}?`,
    okLabel: "Confirm",
    onOkCallback: handleConfirmDeleteNotes,
    destructive: true,
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<SeriesNotesFragmentType>({
    defaultValues: seriesNotes ?? {
      seriesId,
      projectId,
      createdBy: userId,
      notes: null,
    },
  });

  const handleCancel = () => {
    onCancel();
  };

  const handleDelete = () => {
    showConfirmDeleteDialog(true);
  };

  const handleSubmitForm = async (notes: SeriesNotesFragmentType) => {
    if (isNewFlag) {
      await insertSeriesNotes({
        variables: { seriesId, projectId, notes: notes.notes },
      });
    } else {
      await updateSeriesNotes({
        variables: { id: notes.id, notes: notes.notes },
      });
    }

    onSubmit();
  };

  return (
    <form onSubmit={handleSubmit(handleSubmitForm)}>
      {confirmDeleteDialog}
      <Wrapper>
        <SeriesDialogHeader series={series} />
        <div>
          <Label htmlFor={"notes-textarea"} required={true} error={errors.notes}>
            Notes
          </Label>
          <Controller
            control={control}
            name="notes"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <TextArea
                disabled={!canEdit}
                readOnly={!canEdit}
                text={value}
                setText={onChange}
                id={"notes-textarea"}
                error={errors.notes}
                rows={5}
              />
            )}
          />
          {errors.notes && <ErrorLabel>Notes must not be empty</ErrorLabel>}
        </div>
      </Wrapper>
      <ActionButtonsWrapper>
        {canEdit && <InputButton type="submit" name="submit-button" value={"Confirm"} />}
        <InputButton
          type="button"
          name="cancel-button"
          value={"Cancel"}
          background={main.colors.neutral.white}
          color={main.colors.neutral.black}
          onClick={handleCancel}
        />
        {!isNewFlag && canEdit && (
          <InputButton
            type="button"
            name="delete-button"
            value={"Delete"}
            {...RED_BUTTON_OVERRIDES}
            color={main.colors.neutral.white}
            onClick={handleDelete}
          />
        )}
      </ActionButtonsWrapper>
    </form>
  );
};

export function useSeriesNotesFormDialog(series: SeriesListItemFragmentType): useDialogReturnType {
  return useFormDialog({
    children: SeriesNotesForm,
    label: "Edit Series Notes",
    showCloseButton: true,
    props: { series },
  });
}
