import React, { FC, FormEvent } from "react";

import { ActionButtonsWrapper } from "../../../../../common/components/Dialog/Form/ActionButtonsWrapper";
import { Wrapper } from "../../../../../common/components/Dialog/Form/Wrapper";
import { FormProps } from "../../../../../common/components/Dialog/FormProps";
import { InputButton } from "../../../../../common/components/input/InputButton";
import { main } from "../../../../../common/theme/main";
import { useGoToWorklist } from "../../../../../Project/Layout/AnnotatePage/useGoToWorklist";

export const TaskLoadingFailedDialog: FC<FormProps> = () => {
  const goToWorklist = useGoToWorklist();

  const handleGoToWorklist = (e: FormEvent) => {
    e.preventDefault();
    goToWorklist();
  };

  return (
    <form onSubmit={handleGoToWorklist}>
      <Wrapper>This task failed to load. Contact Altis Labs for more details</Wrapper>
      <ActionButtonsWrapper>
        <InputButton
          type="submit"
          name="submit-button"
          value={"Worklist"}
          color={main.colors.neutral.black}
          background={main.colors.neutral.white}
        />
      </ActionButtonsWrapper>
    </form>
  );
};
