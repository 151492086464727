import dayjs from "dayjs";

import { DayjsDateRange } from "../../../common/utils/dateFormatUtils/DayjsDateRange";
import { isInRange } from "../../../common/utils/dateFormatUtils/isInRange";
import { LesionType } from "../hooks/useProjectLabels";

export function isLesionInRange(
  { created }: Pick<LesionType, "created">,
  range: DayjsDateRange
): boolean {
  return isInRange(dayjs(created), range);
}
