import React from "react";
import styled from "styled-components";

import { TimepointOptionsType } from "../../../../../types/TaskDescriptionType";
import { LesionListItemFragmentType } from "../fragments/LesionListItemFragment";
import { RoiListItemFragmentType } from "../fragments/RoiListItemFragment";
import { ExistingRoiClassificationButtonsReturnType } from "../hooks/timepointOptions/useGetExistingRoiClassificationButtons";
import { RoiClassificationButtons } from "./RoiClassificationButtons";

const Wrapper = styled.div<{ verticalPadding: number }>`
  display: flex;
  flex-direction: column;
  gap: 3px;
  ${({ verticalPadding }) => `padding: ${verticalPadding}px 6px ${verticalPadding}px 8px;`}
`;

const BodyStatsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px;
`;

interface RoiCardBodyProps {
  roi: RoiListItemFragmentType;
  lesion: LesionListItemFragmentType;
  isFirst: boolean;
  canClassify: boolean;
  onClassifyClicked: (option: TimepointOptionsType) => Promise<void>;
  statComponents: JSX.Element[];
  getExistingRoiClassificationButtons: ExistingRoiClassificationButtonsReturnType;
}

export function RoiCardBody({
  roi,
  lesion,
  isFirst,
  canClassify,
  onClassifyClicked,
  statComponents,
  getExistingRoiClassificationButtons,
}: RoiCardBodyProps): JSX.Element | null {
  const buttons = getExistingRoiClassificationButtons(lesion, roi).filter(
    ({ showAtBaseline, showAtFollowUp }) => (isFirst ? showAtBaseline : showAtFollowUp)
  );

  const hasStatComponents = statComponents.length > 0;
  const hasClassificationButtons = buttons.length > 0;

  const isEmpty = !(hasStatComponents || hasClassificationButtons);

  return (
    <Wrapper verticalPadding={isEmpty ? 0 : 3}>
      {hasStatComponents && <BodyStatsWrapper>{statComponents}</BodyStatsWrapper>}
      {hasClassificationButtons && (
        <RoiClassificationButtons
          viewOnly={!canClassify}
          buttons={buttons}
          onClickClassification={onClassifyClicked}
        />
      )}
    </Wrapper>
  );
}
RoiCardBody.whyDidYouRender = true;
