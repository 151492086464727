import Tooltip from "@mui/material/Tooltip";
import { PiUpload } from "react-icons/pi";
import { Column, Row } from "react-table";
import styled from "styled-components";

import { ReactComponent as EditIcon } from "../../../../assets/svgs/EditOutline.svg";
import { SvgIcon } from "../../../../common/components/icons/SvgIcon";
import { CellProps } from "../../../../common/components/Table/CellProps";
import { CohortLabel } from "./CohortLabel";
import { CohortTableRowType } from "./CohortTableRowType";
import { useEditCohortForm } from "./useCreateCohortForm";

const Button = styled.button`
  cursor: pointer;
  padding: 0 8px;
  display: flex;
  border: none;
  background: transparent;
`;

export function useCohortTableColumns(
  handleOpenBulkAction: (cohortId: number) => void
): Column<CohortTableRowType>[] {
  return [
    {
      id: "name",
      Header: "Name",
      disableFilters: false,
      accessor: (cohort) => cohort,
      filter: (rows: Row<CohortTableRowType>[], columnIds: string[], filterValue: string) => {
        return rows.filter(
          ({
            values: {
              name: { name },
            },
          }) => (name as string).toLowerCase().includes(filterValue.toLowerCase())
        );
      },
      // eslint-disable-next-line react/display-name
      Cell: ({ value: cohort }: { value: CohortTableRowType }) => {
        return <CohortLabel cohort={cohort} />;
      },
    },
    {
      id: "edit",
      Header: "Edit",
      style: {
        width: "42px",
      },
      // eslint-disable-next-line react/display-name
      Cell: ({ row: { original: cohort } }: CellProps<CohortTableRowType>) => {
        const [setShowEditCohortForm, { dialog: editCohortDialog }] = useEditCohortForm({ cohort });

        const handleEditClick = () => {
          setShowEditCohortForm(true);
        };
        return (
          <>
            {editCohortDialog}
            <Button onClick={handleEditClick}>
              <SvgIcon icon={EditIcon} size={14} />
            </Button>
          </>
        );
      },
    },
    {
      id: "bulkAction",
      Header: "Bulk Add / Remove",
      style: {
        width: "130px",
      },
      disableFilters: true,
      Cell: ({
        row: {
          original: { id: cohortId },
        },
      }: CellProps<CohortTableRowType>) => {
        return (
          <Tooltip title={"Bulk add / remove from .csv"}>
            <Button onClick={() => handleOpenBulkAction(cohortId)}>
              <PiUpload size={18} />
            </Button>
          </Tooltip>
        );
      },
    },
  ];
}
