import { ANNOTATE_ALLOW_ORGANS } from "../../../enums/TaskDescriptionEnums";
import { TaskDescriptionType, TaskOptionsType } from "../../../types/TaskDescriptionType";
import { validateTaskDescription } from "../../Annotate/page/AnnotationPanel/hooks/loadTask";
import { useProjectTasks } from "./useProjectTasks";

function extractLabels(taskDescription: TaskDescriptionType): string[] {
  let labels = [] as string[];
  const taskOrganOption = taskDescription.options?.find(
    (option) => option.rule === ANNOTATE_ALLOW_ORGANS
  ) as TaskOptionsType<{ organs: string[] }>;

  if (taskOrganOption) {
    labels = taskOrganOption.args?.organs.flatMap((value) => value) ?? [];
  }

  return labels;
}

export function useExistingTaskAnnotations(): string[] {
  const { projectTasks = [] } = useProjectTasks();

  const descriptions = projectTasks.map((task) => task.taskDescription);

  descriptions.forEach(function (taskDesc) {
    validateTaskDescription(taskDesc.data);
  });

  return descriptions.flatMap((taskDesc) => extractLabels(taskDesc.data));
}
