import { SubjectType } from "../../common/types/SubjectType";
import { RangeType } from "../../KaplanMeier/utils/generateKaplanMeierData";

export type SurvivalBinType = {
  id: number;
  range: RangeType;
  subjectsByArm: Record<number, SubjectType[]>;
};

export function formatBinRangeSurvival(bin: SurvivalBinType): string {
  const {
    range: { min, max },
  } = bin;
  return `${Math.round(min)} to ${Math.round(max)} months`;
}

export function formatBinRangeMortality(bin: SurvivalBinType): string {
  const {
    range: { min, max },
  } = bin;

  return `${Math.round(min)}% - ${Math.round(max)}%`;
}
