import cornerstoneTools from "@altis-labs/cornerstone-tools";

import { Mask } from "../../MaskContextProvider";

export function setMaskVisibility({ id }: Mask, visible: boolean, firstImageId: string): void {
  const {
    state: { series },
  } = cornerstoneTools.getModule("segmentation");

  const entry = series[firstImageId];
  if (!entry) {
    return;
  }

  const { labelmaps3D } = entry;

  const maskEntry = labelmaps3D[id];
  if (!maskEntry) {
    return;
  }

  maskEntry.segmentsHidden[1] = !visible;
}
