import { useSelector } from "react-redux";

import { selectedLesionIdSelector } from "../../../../../../common/store/annotatePage/selectionSelector";
import { useAnatomicalStructures } from "../../../../useAnatomicalStructures";
import {
  SELECTED_CONTOUR_COLOR,
  setToolStateColorForLesion,
} from "../../ViewerPanel/tools/legacy/ToolUtils";
import { useLesionsList } from "../hooks/useLesionsList";
import { lesionsForCurrentTaskFilter } from "../hooks/useTaskLesionListOptions";

type LesionColor = {
  lesionId: number;
  color: string;
};

export const useChangeLesionColors = (): ((lesionId: number | null) => void) => {
  const { data: lesionsData } = useLesionsList(lesionsForCurrentTaskFilter);
  const selectedLesionId = useSelector(selectedLesionIdSelector);
  const { data: anatomicalStructures } = useAnatomicalStructures();

  return (lesionId: number | null) => {
    const previousSelectedLesion = lesionsData?.lesions.find(({ id }) => id === selectedLesionId);
    const prevLesionsColor = anatomicalStructures?.find(
      ({ id }) => id === previousSelectedLesion?.location
    )?.color;

    const lesionColors: LesionColor[] = [];

    if (lesionId) {
      lesionColors.push({
        lesionId,
        color: SELECTED_CONTOUR_COLOR,
      });
    }

    if (previousSelectedLesion && prevLesionsColor && previousSelectedLesion.id !== lesionId) {
      lesionColors.push({
        lesionId: previousSelectedLesion.id,
        color: prevLesionsColor,
      });
    }

    setToolStateColorForLesion(lesionColors);
  };
};
