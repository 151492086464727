import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { QueryResult } from "@apollo/client/react/types/types";

import { AnonymizationMethodType } from "../Contexts/AnonymizationConfigurationContext";

export type AnonymizationTableRowType = {
  description: string;
  name: string;
  key: string;
  id: number;
};

export type Variables = { keys: string[] };
export type Data = { anonymizationMethods: AnonymizationTableRowType[] };

export const QUERY = gql`
  query GetAnonymizationMethods($keys: [String!]!) {
    anonymizationMethods: anonymization_method(where: { key: { _in: $keys } }) {
      name
      description
      key
      id
    }
  }
`;

export function useAllAnonymizationMethods(
  keys: AnonymizationMethodType[]
): QueryResult<Data, Variables> {
  return useQuery<Data, Variables>(QUERY, { variables: { keys } });
}
