import { formatUserDisplayName } from "../../../common/components/UserManagement/utils/formatUserDisplayName";
import { UserType } from "../../../common/types/UserType";
import { DayjsDateRange } from "../../../common/utils/dateFormatUtils/DayjsDateRange";
import { Data } from "../hooks/useProjectLabels";
import { LabelType } from "../types/LabelType";
import { StudyFilterType } from "../types/StudyFilterOptions";
import { getIncludedPatientLesions } from "./getIncludedPatientLesions";
import { getIncludedRoiCount } from "./getIncludedRoiCount";
import { getLabelColor } from "./getLabelColor";
import { getLabelDisplayName } from "./getLabelDisplayName";
import { getUserLesions } from "./getUserLesions";

export type LabelsPerAnnotatorData = {
  annotator: string;
  labels: {
    name: string;
    studies: number;
    color: string;
  }[];
};

export function buildAnnotationsPerAnnotatorData(
  { patients }: Data,
  users: UserType[],
  range: DayjsDateRange,
  studyFilter: StudyFilterType
): LabelsPerAnnotatorData[] {
  const includeIncompleteTasks = studyFilter === "ALL_STUDIES";

  const lesions = getIncludedPatientLesions(patients, range, includeIncompleteTasks).flatMap(
    ({ lesions }) => lesions
  );

  const allLabels: LabelsPerAnnotatorData[] = [];

  const labelTypes: LabelType[] = ["DETECTED_LESION", "CONTOURED_MEASURABLE_LESION", "ORGAN"];

  for (const user of users) {
    const annotator = formatUserDisplayName(user);

    const { id } = user;
    const userLesions = getUserLesions(lesions, id);

    const labels: LabelsPerAnnotatorData["labels"] = [];
    for (const labelType of labelTypes) {
      for (const lesion of userLesions) {
        const name = getLabelDisplayName(lesion, labelType);
        // todo color is based on the anatomical structure, but should be fixed based on the labelType
        const color = getLabelColor(lesion);
        const count = getIncludedRoiCount(lesion, labelType);
        if (count === 0) {
          continue;
        }

        let existingLabel = labels.find(({ name: exisingName }) => exisingName === name);
        if (!existingLabel) {
          existingLabel = {
            name,
            color,
            studies: 0,
          };
          labels.push(existingLabel);
        }

        existingLabel.studies += count;
      }
    }

    allLabels.push({
      annotator,
      labels,
    });
  }

  return allLabels;
}
