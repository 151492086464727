import React, { useContext } from "react";

import { FlexLoading } from "../../../../../common/components/Loading";
import handleApolloError from "../../../../../common/utils/handleApolloError";
import { PatientContext } from "../../../../../Dashboard/components/Settings/Patients/Viewer/PatientContext";
import { usePatientBiopsyDetails } from "../AnnotationPanel/hooks/usePatientBiopsyDetails";
import { PatientContextList } from "./PatientContextList";

export function PatientContextListContainer(): JSX.Element {
  const { id: patientId } = useContext(PatientContext);

  const { data: biopsies, loading, error } = usePatientBiopsyDetails(patientId);

  if (error) handleApolloError(error);
  if (loading) return <FlexLoading />;

  if (!biopsies) {
    throw new Error("Biopsies are undefined!");
  }

  const showList = biopsies.length !== 0;

  if (!showList) return <></>;

  return <PatientContextList biopsies={biopsies} />;
}
