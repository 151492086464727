import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export function EmptyPage(): JSX.Element {
  return <Wrapper>No content</Wrapper>;
}
