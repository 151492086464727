import { useQuery } from "@apollo/client";
import React from "react";

import {
  GET_SUBJECTS_QUERY,
  RawSubjectsQueryType,
} from "../../../Analysis/common/hooks/useSubjectQuery";
import { FlexLoading } from "../../../common/components/Loading";
import { GET_PROJECT_DETAILS_BY_IDS } from "../../../common/queries/GetProjectDetailsByIds";
import { RawProjectDetailsType } from "../../../common/types/RawProjectDetailsType";
import handleApolloError from "../../../common/utils/handleApolloError";
import { useProjectId } from "../../../Project/hooks/useProjectId";
import { parseProjectsCompletionBurndownData } from "../utils/parseProjectsCompletionBurndown";
import ProjectCompletionBurndownChart from "./ProjectCompletionBurndownChart";

function ProjectCompletionBurndownContainer(): React.ReactElement {
  const projectId = useProjectId();
  const {
    data: subjectsData,
    loading: subjectsLoading,
    error: subjectsError,
  } = useQuery<RawSubjectsQueryType>(GET_SUBJECTS_QUERY, {
    variables: { project_id: projectId },
  });

  const {
    data: projectData,
    loading: projectLoading,
    error: projectError,
  } = useQuery<RawProjectDetailsType>(GET_PROJECT_DETAILS_BY_IDS, {
    variables: { projectIds: [projectId] },
  });

  if (subjectsLoading || !subjectsData || projectLoading || !projectData) {
    return <FlexLoading />;
  }
  if (subjectsError) {
    handleApolloError(subjectsError);
  }
  if (projectError) {
    handleApolloError(projectError);
  }

  const parsedData = parseProjectsCompletionBurndownData(subjectsData, projectData);
  return <ProjectCompletionBurndownChart data={parsedData} />;
}

export default ProjectCompletionBurndownContainer;
