import { useEffect, useRef } from "react";

import { ToolControllerType } from "../../toolController/ToolControllerType";
import { ContourContextMenuData } from "../types/ContourContextMenuDataType";

export function useContourRightClicked(
  toolController: ToolControllerType | null,
  onContourRightClicked: (data: ContourContextMenuData) => void
): void {
  const toolControllerRef = useRef<ToolControllerType>();

  useEffect(() => {
    toolControllerRef.current = toolController;
    toolController?.subscribeToContourRightClicked(onContourRightClicked);

    return () => {
      const toolController = toolControllerRef.current;
      toolController?.unsubscribeFromContourRightClicked(onContourRightClicked);
    };
  }, [toolController]);
}
