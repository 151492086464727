import { ProjectType } from "../../common/types/ProjectType";
import { RawProjectDetailsType } from "../../common/types/RawProjectDetailsType";
import { ProjectStatusType } from "./ProjectStatusType";

export function parseProjectDetails(data: RawProjectDetailsType): ProjectType[] {
  return data.project.map((project) => {
    const { status, estimatedCompletion } = project;
    return {
      ...project,
      estimatedCompletion: new Date(estimatedCompletion),
      status: status as ProjectStatusType,
      indicationName: project.details.disease,
      investigationName: project.details.ip,
    };
  });
}
