import { useFlags } from "launchdarkly-react-client-sdk";

import { EXPORT_PROJECT, LayoutType, ProjectPageType } from "../Layout";
import { projectLayout } from "../Layouts/projectLayout";
import {
  ANALYSIS,
  ANALYSIS_NEW,
  DATA_MANAGEMENT,
  DATA_MANAGEMENT_NEW,
  DOCUMENTS,
  OVERVIEW,
} from "../RoutePathType";

export function useLayout(): LayoutType {
  //This previously used to support multiple layouts, this continues to exist to potentially
  // support that in the future
  // (See: https://linear.app/altislabs/issue/NOTA-631/squash-project-vs-trial-contexts-and-related-layouts)

  const flags = useFlags();
  const showReorgPages = flags["notaShowReorganisationPages"];
  const showAnnotationExport = flags["showNotaExportAnnotations"];
  const filteredLayout = filterLayout(projectLayout, showReorgPages, showAnnotationExport);
  return filteredLayout;
}

function filterLayout(
  layout: LayoutType,
  showReorg: boolean,
  showAnnotationExport: boolean
): LayoutType {
  const { dashboard, settings, project } = layout;
  const { menu, pages, standalonePages } = project;
  const filteredMenu = menu.filter(({ route }) =>
    showReorg
      ? !(route === DATA_MANAGEMENT || route === ANALYSIS || route === OVERVIEW)
      : !(route === DATA_MANAGEMENT_NEW || route === ANALYSIS_NEW || route === DOCUMENTS)
  );
  const filteredPages = pages.filter(({ route, parentRoute }) =>
    showReorg
      ? !(
          parentRoute === DATA_MANAGEMENT ||
          parentRoute === ANALYSIS ||
          (route === OVERVIEW && !parentRoute)
        )
      : !(route === DATA_MANAGEMENT_NEW || route === ANALYSIS_NEW || route === DOCUMENTS)
  );

  const finalPageList = filteredPages.map((page): ProjectPageType => {
    return {
      ...page,
      topBar: {
        ...page.topBar,
        buttons: showAnnotationExport
          ? page.topBar.buttons
          : page.topBar.buttons?.filter(({ action }) => action !== EXPORT_PROJECT),
      },
    };
  });

  return {
    dashboard,
    settings,
    project: { menu: filteredMenu, pages: finalPageList, standalonePages },
  };
}
