import { useAuth0 } from "@auth0/auth0-react";

export function useCurrentUserAuth0Id(): string {
  const { user } = useAuth0();

  if (!user?.sub) {
    throw new Error("User sub is undefined in useCurrentUserQuery");
  }

  const { sub } = user;
  return sub;
}
