import { useApolloClient } from "@apollo/client";
import { POLLING_INTERVAL } from "nota-predict-web/src/common/utils/configConstants";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { clearClassificationDefinitions } from "../../../common/store/annotatePage/classificationsSlice";
import { ProjectContext } from "../../../Project/contexts/ProjectContext";
import { useTimepoints } from "./page/AnnotationPanel/hooks/Timepoint/useTimepoints";
import { TaskActionPanel } from "./page/AnnotationPanel/TaskActionPanel";
import { useViewerCreator } from "./page/hooks/useViewerCreator";
import { TimepointPanel } from "./page/StudyPanel/TimepointPanel";
import { ViewerPanel } from "./page/ViewerPanel/ViewerPanel";

const Wrapper = styled.div`
  height: 100vh;
  padding: 3px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
`;

export function AnnotatePageContents(): JSX.Element {
  const [hasMounted, setHasMounted] = useState<boolean>(false);

  const { startPollingPatients, stopPollingPatients } = useContext(ProjectContext);

  const dispatch = useDispatch();
  const { cache } = useApolloClient();

  const { timepoints } = useTimepoints();

  const { createViewers, clearViewers } = useViewerCreator();

  useEffect(() => {
    stopPollingPatients();
    return () => startPollingPatients(POLLING_INTERVAL);
  }, [startPollingPatients, stopPollingPatients]);

  useEffect(() => {
    if (!timepoints || timepoints.length === 0 || hasMounted) {
      return;
    }

    createViewers(timepoints, 1);
    setHasMounted(true);
  }, [timepoints]);

  useEffect(() => {
    dispatch(clearClassificationDefinitions());

    // clear lesion cache on exit of the page
    return () => {
      clearViewers();
      cache.evict({ fieldName: "lesion" });
      cache.gc();
    };
  }, []);

  return (
    <Wrapper>
      <TimepointPanel />
      <ViewerPanel />
      <TaskActionPanel />
    </Wrapper>
  );
}
