import { TaskType } from "nota-predict-web/src/Annotate/components/Manage/hooks/TaskType";
import { PropsWithChildren, useContext } from "react";
import { Link } from "react-router-dom";

import { ProjectContext } from "../../../Project/contexts/ProjectContext";
import { useGetTaskRoute } from "../../../Project/Layout/AnnotatePage/useGetTaskRoute";

interface TaskLinkProps {
  task: TaskType;
  patientID: number;
  openTaskViewOnly?: boolean;
}

const TaskLink = ({
  task,
  patientID,
  openTaskViewOnly,
  children,
}: PropsWithChildren<TaskLinkProps>) => {
  const getTaskRoute = useGetTaskRoute();
  const taskRoute = getTaskRoute({
    projectId: task.projectId,
    patientId: patientID,
    taskId: task.id,
  });

  const { getRoute } = useContext(ProjectContext);
  const location = getRoute(taskRoute);

  return (
    <Link
      to={{
        pathname: location,
        search: openTaskViewOnly ? "?view_only=true" : undefined,
      }}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </Link>
  );
};

export { TaskLink };
