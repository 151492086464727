import { useEffect, useRef } from "react";

import { ToolControllerType } from "../../toolController/ToolControllerType";
import { useContourChanged } from "./useContourChanged";

export function useContourUpdated(
  toolController: ToolControllerType | null,
  seriesId: number | undefined
): void {
  const { onContourChanged } = useContourChanged(seriesId);

  const toolControllerRef = useRef<ToolControllerType>();

  useEffect(() => {
    toolControllerRef.current = toolController;
    toolController?.setOnContourChangedCallback(onContourChanged);

    return () => {
      const toolController = toolControllerRef.current;
      toolController?.setOnContourChangedCallback(null);
    };
  }, [toolController, onContourChanged]);
}
