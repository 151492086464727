import { gql } from "@apollo/client/core";

export const ROI_RECIST_EVALUATION_FRAGMENT = gql`
  fragment RoiRecistEvaluation on roi_recist_evaluations {
    roiId: roi_id
    longAxisDiameter: long_axis_diameter
    shortAxisDiameter: short_axis_diameter
    volume
    visibility
    instanceId: instance_id
    LADPoints: long_axis_diameter_points
    SADPoints: short_axis_diameter_points
  }
`;
