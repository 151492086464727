import { CircularProgress, Tooltip } from "@mui/material";
import CTAButton from "nota-predict-web/src/common/components/buttons/CTAButton";
import { SvgIcon } from "nota-predict-web/src/common/components/icons/SvgIcon";
import { main } from "nota-predict-web/src/common/theme/main";
import { QcReportExporter } from "nota-predict-web/src/DataManagement/QcReportExport/QcReportExporter";
import React, { ReactElement, useCallback } from "react";
import { AiOutlineAudit as RawQCReportDownloadIcon } from "react-icons/ai";
import { TbTableExport as RawAnnotationsDownloadIcon } from "react-icons/tb";
import styled from "styled-components";

import { ReactComponent as FolderZip } from "../../../assets/svgs/FolderZip.svg";
import { AnnotationsExporter } from "../../../DataManagement/AnnotationsExport/ExportAnnotationsButton";
import {
  getPatientTaskPairs,
  PatientTaskPair,
} from "../../../DataManagement/AnnotationsExport/PatientTaskPair";
import { IconButton } from "../Annotate/page/AnnotationPanel/IconButton";
import { ActionButton } from "./ActionButton";
import { PatientMutableTaskType } from "./hooks/TaskType";
import { PatientTaskTableRowType } from "./PatientTable/types/PatientTaskTableRowType";

const AnnotationsDownloadIcon = styled(RawAnnotationsDownloadIcon)`
  & path {
    fill: none;
  }
`;

const QCReportDownloadIcon = styled(RawQCReportDownloadIcon)``;

const LoadingIcon = styled(CircularProgress)`
  &.MuiCircularProgress-colorPrimary {
    color: ${main.colors.neutral.neutral4};
  }
`;

interface BaseExportButtonProps {
  disabled?: boolean;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  label?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
  tooltip: string;
  // Main app toolbar (i.e. context bar), annotation panel toolbar, or just an icon
  variant: "toolbar" | "panel" | "icon";
}

const BaseExportButton = ({
  disabled,
  icon,
  label,
  onClick,
  tooltip,
  variant = "toolbar",
}: BaseExportButtonProps): ReactElement => {
  switch (variant) {
    case "toolbar":
      if (!label) {
        throw new Error("Label is required for toolbar variant");
      }
      return (
        <CTAButton
          icon={<SvgIcon icon={icon} size={22} />}
          text={label}
          tooltip={tooltip}
          onClick={onClick}
        />
      );
    case "panel":
      if (!label) {
        throw new Error("Label is required for panel variant");
      }
      return (
        <ActionButton
          label={label}
          icon={icon}
          onClick={onClick}
          tooltip={tooltip}
          disabled={disabled}
        />
      );
    case "icon":
      return (
        <Tooltip title={tooltip}>
          <IconButton onClick={onClick} disabled={disabled}>
            <SvgIcon icon={icon} size={22} color={main.colors.neutral.black} />
          </IconButton>
        </Tooltip>
      );
  }
};

interface ExportButtonProps
  extends Omit<BaseExportButtonProps, "icon" | "inProgressIcon" | "downloadIcon"> {
  selectedPatientRows?: PatientTaskTableRowType[] | PatientMutableTaskType[];
  patientTaskPairs?: PatientTaskPair[];
  inProgressIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  downloadLabel?: string;
  downloadIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  downloadTooltip?: string;
}

export const AnnotationsExportButton = ({
  selectedPatientRows,
  label,
  downloadLabel,
  downloadTooltip,
  tooltip,
  variant = "toolbar",
  ...rest
}: ExportButtonProps): ReactElement => {
  const patientTaskPairs = rest.patientTaskPairs ?? getPatientTaskPairs(selectedPatientRows ?? []);

  const disabled = patientTaskPairs.length === 0;
  const iconSize = variant === "panel" ? 16 : 22;

  return (
    <AnnotationsExporter
      patientTaskPairs={patientTaskPairs}
      ExportButton={({ onClick }) => (
        <BaseExportButton
          label={label}
          icon={() => <AnnotationsDownloadIcon size={iconSize} />}
          onClick={onClick}
          tooltip={tooltip}
          disabled={disabled}
          variant={variant}
        />
      )}
      PendingButton={() => (
        <BaseExportButton
          label={"Preparing Archive"}
          icon={() => <LoadingIcon size={iconSize} />}
          tooltip={"Preparing for export..."}
          disabled={true}
          variant={variant}
        />
      )}
      DownloadButton={({ onClick }) => (
        <BaseExportButton
          label={downloadLabel || "Download Archive"}
          icon={FolderZip}
          onClick={onClick}
          tooltip={downloadTooltip || "Download archive"}
          disabled={disabled}
          variant={variant}
        />
      )}
    />
  );
};

export const QCReportExportButton = ({
  selectedPatientRows,
  label,
  downloadLabel,
  downloadTooltip,
  tooltip,
  variant = "toolbar",
  ...rest
}: ExportButtonProps): ReactElement => {
  const patientTaskPairs = rest.patientTaskPairs ?? getPatientTaskPairs(selectedPatientRows ?? []);

  const disabled = patientTaskPairs.length === 0;
  const iconSize = variant === "panel" ? 16 : 22;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const downloadIcon = useCallback(() => <QCReportDownloadIcon size={iconSize} />, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const inProgressIcon = useCallback(() => <LoadingIcon size={iconSize} />, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const exportIcon = useCallback(() => <QCReportDownloadIcon size={iconSize} />, []);

  return (
    <QcReportExporter
      patientTaskPairs={patientTaskPairs}
      ExportButton={({ onClick }) => (
        <BaseExportButton
          label={label}
          icon={downloadIcon}
          onClick={onClick}
          tooltip={tooltip}
          disabled={disabled}
          variant={variant}
        />
      )}
      PendingButton={() => (
        <BaseExportButton
          label={"Preparing Report"}
          icon={inProgressIcon}
          tooltip={"Preparing for export..."}
          disabled={true}
          variant={variant}
        />
      )}
      DownloadButton={({ onClick }) => (
        <BaseExportButton
          label={downloadLabel || "Download QC Report"}
          icon={exportIcon}
          onClick={onClick}
          tooltip={downloadTooltip || "Download quality control report"}
          disabled={disabled}
          variant={variant}
        />
      )}
    />
  );
};
