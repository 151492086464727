export const QC_COMPUTED_VALUES_MAP = {
  patient_position: {
    HFP: "Head first-prone",
    HFS: "Head first-supine",
    HFDR: "Head first-decubitus right",
    HFDL: "Head first-decubitus left",
    FFDR: "Feet first-decubitus right",
    FFDL: "Feet first-decubitus left",
    FFP: "Feet first-prone",
    FFS: "Feet first-supine",
    LFP: "Left first-prone",
    LFS: "Left first-supine",
    RFP: "Right first-prone",
    RFS: "Right first-supine",
    AFDR: "Anterior first-decubitus right",
    AFDL: "Anterior first-decubitus left",
    PFDR: "Posterior first-decubitus right",
    PFDL: "Posterior first-decubitus left",
  },
};

export type QcReportMappableComputedField = keyof typeof QC_COMPUTED_VALUES_MAP;
