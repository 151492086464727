import { useMutation } from "@apollo/client";
import { ApolloCache, gql } from "@apollo/client/core";
import { MutationTuple } from "@apollo/client/react/types/types";

const MUTATION = gql`
  mutation DeletePatients($patientIds: [Int!]!) {
    delete_patient_biopsy_details(where: { patient: { patient_id: { _in: $patientIds } } }) {
      affected_rows
    }
    deleteLesion: delete_lesion(where: { patient: { patient_id: { _in: $patientIds } } }) {
      returning {
        patientId: patient_id
        lesionId: lesion_id
      }
    }
    delete_labelmap(
      where: { instance: { series: { study: { patient: { patient_id: { _in: $patientIds } } } } } }
    ) {
      affected_rows
    }
    deleteInstance: delete_instance(
      where: { series: { study: { patient: { patient_id: { _in: $patientIds } } } } }
    ) {
      returning {
        seriesId: series_id
        instanceId: instance_id
      }
    }
    delete_view_only_series(
      where: { series: { study: { patient: { patient_id: { _in: $patientIds } } } } }
    ) {
      affected_rows
    }

    deleteSeries: delete_series(
      where: { study: { patient: { patient_id: { _in: $patientIds } } } }
    ) {
      returning {
        seriesId: series_id
        studyId: study_id
      }
    }
    delete_study_follow_up_map(
      where: { study: { patient: { patient_id: { _in: $patientIds } } } }
    ) {
      affected_rows
    }
    deleteFollowUp: delete_follow_up(where: { patient_id: { _in: $patientIds } }) {
      returning {
        followUpId: id
        patientId: patient_id
      }
    }
    delete_study_celery_job_map(where: { study: { patient_id: { _in: $patientIds } } }) {
      affected_rows
    }
    deleteStudy: delete_study(where: { patient_id: { _in: $patientIds } }) {
      returning {
        patientId: patient_id
        studyId: study_id
      }
    }
    delete_patient_project_arm(where: { patient: { patient_id: { _in: $patientIds } } }) {
      affected_rows
    }
    delete_temp_demo_patients(where: { patient_id: { _in: $patientIds } }) {
      affected_rows
    }
    delete_task_assignment(where: { patient_id: { _in: $patientIds } }) {
      affected_rows
    }
    deletePatient: delete_patient(where: { patient_id: { _in: $patientIds } }) {
      returning {
        patientId: patient_id
      }
    }
  }
`;

export type Variables = {
  patientIds: number[];
};

export type Data = {
  deletePatient: { returning: { patientId: number }[] };
  deleteStudy: { returning: { patientId: number; studyId: number }[] };
  deleteSeries: { returning: { studyId: number; seriesId: number }[] };
  deleteInstance: { returning: { seriesId: number; instanceId: number }[] };
  deleteLesion: { returning: { lesionId: number; patientId: number }[] };
  deleteFollowUp: { returning: { followUpId: number; patientId: number }[] };
};

export function useDeletePatients(): MutationTuple<Data, Variables> {
  return useMutation<Data, Variables>(MUTATION, {
    update: (cache, result) => {
      const { data } = result;
      if (!data) {
        throw new Error("Something went wrong updating the cache after deleting patients");
      }

      const {
        deletePatient: { returning: patients },
        deleteStudy: { returning: studies },
        deleteSeries: { returning: series },
        deleteInstance: { returning: instances },
        deleteLesion: { returning: lesions },
        deleteFollowUp: { returning: followUps },
      } = data;

      const lesionIds = lesions.map(({ lesionId }) => lesionId);

      deleteLesionsFromCache(cache, lesionIds);

      for (const { patientId } of patients) {
        evictFromCache(cache, patientId, "patient");
      }

      for (const { instanceId } of instances) {
        evictFromCache(cache, instanceId, "instance");
      }

      for (const { seriesId } of series) {
        deleteSeriesFromCache(cache, seriesId);
        evictFromCache(cache, seriesId, "series");
      }

      for (const { studyId } of studies) {
        evictFromCache(cache, studyId, "study");
      }

      for (const { followUpId } of followUps) {
        evictFromCache(cache, followUpId, "follow_up");
      }
    },
  });
}

function deleteLesionsFromCache<T>(cache: ApolloCache<T>, lesionIds: number[]) {
  for (const lesionId of lesionIds) {
    deleteLesionFromCache(cache, lesionId);
    evictFromCache(cache, lesionId, "lesion");
  }
}

function deleteRoiFromCache<T>(cache: ApolloCache<T>, roiId: number) {
  const roiCacheId = cache.identify({
    id: roiId,
    __typename: "roi",
  });
  cache.modify({
    id: roiCacheId,
    fields: {
      contours(existingContourRefs = [], { readField }) {
        for (const existingContourRef of existingContourRefs) {
          const contourId = readField("id", existingContourRef) as number;
          evictFromCache(cache, contourId, "contour");
        }
        return [];
      },
      roi_classifications(existingRoiClassificationRefs = [], { readField }) {
        for (const existingRoiClassificationRef of existingRoiClassificationRefs) {
          const roiClassificationId = readField("id", existingRoiClassificationRef) as number;
          evictFromCache(cache, roiClassificationId, "roi_classification");
        }
        return [];
      },
      ellipse(existingEllipseRef, { readField }) {
        if (existingEllipseRef) {
          const ellipseId = readField("id", existingEllipseRef) as number;
          evictFromCache(cache, ellipseId, "ellipse");
        }
        return {};
      },
    },
  });
}

function deleteLesionFromCache<T>(cache: ApolloCache<T>, lesionId: number) {
  const lesionCacheId = cache.identify({
    id: lesionId,
    __typename: "lesion",
  });
  cache.modify({
    id: lesionCacheId,
    fields: {
      rois(existingRoiRefs = [], { readField }) {
        for (const existingRoiRef of existingRoiRefs) {
          const roiId = readField("id", existingRoiRef) as number;
          deleteRoiFromCache(cache, roiId);
          evictFromCache(cache, roiId, "roi");
        }
        return [];
      },
      lesion_classifications(existingLesionClassificationRefs = [], { readField }) {
        for (const existingLesionClassificationRef of existingLesionClassificationRefs) {
          const lesionClassificationId = readField("id", existingLesionClassificationRef) as number;
          evictFromCache(cache, lesionClassificationId, "lesion_classification");
        }
        return [];
      },
    },
  });
}

function deleteSeriesFromCache<T>(cache: ApolloCache<T>, seriesId: number) {
  const seriesCacheId = cache.identify({
    id: seriesId,
    __typename: "series",
  });
  cache.modify({
    id: seriesCacheId,
    fields: {
      series_classifications(existingSeriesClassificationRefs = [], { readField }) {
        for (const existingSeriesClassificationRef of existingSeriesClassificationRefs) {
          const seriesClassificationId = readField("id", existingSeriesClassificationRef) as number;
          evictFromCache(cache, seriesClassificationId, "series_classification");
        }
        return [];
      },
    },
  });
}

function evictFromCache<T>(cache: ApolloCache<T>, id: number, typename: string): void {
  const cacheId = cache.identify({
    id,
    __typename: typename,
  });
  cache.evict({ id: cacheId });
}
