export type TumourAndDiagnosisVariables = {
  tumour: {
    data: {
      measurable: boolean;
      tumour_burdens?: {
        data: {
          burden: number;
        };
      };
      diagnosis: {
        data: {
          type: string;
          code: string;
          primary_location: string;
          diagnosis_date: Date;
          follow_up_id: number;
        };
      };
    };
  };
};

export function getTumourAndDiagnosisVariables(
  burden?: number,
  followUpId?: number
): TumourAndDiagnosisVariables {
  if (followUpId === undefined) {
    throw new Error("followUpId is undefined when inserting a RECIST lesion/roi");
  }

  //TODO replace hardcoded values
  const TEMP_TUMOUR_VALUES = {
    measurable: false,
  };
  const TEMP_DIAGNOSIS_VALUES = {
    type: "Lung Carcinoma",
    code: "448993007",
    primary_location: "Lung",
    diagnosis_date: new Date(),
  };

  return {
    tumour: {
      data: {
        ...TEMP_TUMOUR_VALUES,
        ...(burden !== undefined && { tumour_burdens: { data: { burden } } }),
        diagnosis: {
          data: {
            ...TEMP_DIAGNOSIS_VALUES,
            follow_up_id: followUpId,
          },
        },
      },
    },
  };
}
