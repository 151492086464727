import { createContext } from "react";

import { TaskType } from "./components/Manage/hooks/TaskType";
import { TaskDescriptionType as TaskDescriptionDataType } from "./types/TaskDescriptionType";

export type TaskContextType = {
  selectedTaskAssignmentId: number | undefined;
  viewOnly: boolean;
  isQc: boolean;
  task: TaskType;
};

const DEFAULT_CONTEXT: TaskContextType = {
  task: {
    id: 0,
    name: "",
    taskDescription: {
      id: 0,
      data: {} as TaskDescriptionDataType,
    },
    projectId: 0,
    enabled: true,
    qcSchemaId: 0,
    taskAssignments: [],
    lesions: [], // TODO: collapse lesions into generic sub-criteria?
    seriesClassifications: [],
    restrictedUsers: [],
    excludedPatients: [],
    restrictedCohorts: [],
  },
  selectedTaskAssignmentId: undefined,
  viewOnly: true,
  isQc: false, // TODO: Temporary, should move toward a "taskType" prop that can be "annotation", "segmentation", or "qc"
};

export const TaskContext = createContext<TaskContextType>(DEFAULT_CONTEXT);
