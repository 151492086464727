import { ApolloCache } from "@apollo/client";

export function getPatientCacheId<T>(patientID: number, cache: ApolloCache<T>): string {
  const id = cache.identify({
    id: patientID,
    __typename: "patient",
  });

  if (!id) {
    throw new Error("Unable to construct subject cache id");
  }

  return id;
}
