import Tooltip from "@mui/material/Tooltip";
import React from "react";
import styled from "styled-components";

import { SeriesListItemFragmentType } from "../../../../../Annotate/components/Annotate/page/AnnotationPanel/hooks/useStudiesList";
import { LabelIndicator } from "../../../../../Annotate/components/Annotate/page/common/LabelIndicator";
import { useGetViewerForSeries } from "../../../../../Annotate/components/Annotate/page/ViewerPanel/Viewer/hooks/useGetViewerForSeries";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding: 3px 6px;
  gap: 6px;
  flex: 1;
`;

const Text = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

interface SeriesListItemProps {
  series: SeriesListItemFragmentType;
  onClick: (series: SeriesListItemFragmentType) => void;
}

export function SeriesListItem({ series, onClick }: SeriesListItemProps): JSX.Element {
  const { seriesDescription, id } = series;

  const text = seriesDescription ? seriesDescription : "N/A";

  const getViewerForSeries = useGetViewerForSeries();

  const { color } = getViewerForSeries(id) || {};

  return (
    <Tooltip title={text}>
      <Wrapper onClick={() => onClick(series)}>
        <LabelIndicator color={color ?? "transparent"} />
        <Text>{text}</Text>
      </Wrapper>
    </Tooltip>
  );
}
