import Tooltip from "@mui/material/Tooltip";
import classNames from "classnames";
import React from "react";
import { useDrag } from "react-dnd";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { ReactComponent as ErrorIcon } from "../../../../../assets/svgs/Error.svg";
import { SvgIcon } from "../../../../../common/components/icons/SvgIcon";
import { imagesLoadingFailedSeriesIdsSelector } from "../../../../../common/store/annotatePage/cornerstone/selector";
import { main } from "../../../../../common/theme/main";
import { useProjectId } from "../../../../../Project/hooks/useProjectId";
import { dragAndDropItemTypes } from "../../../../types/DragAndDropItemTypes";
import { FilterableSeriesListItemFragmentType } from "../AnnotationPanel/hooks/useStudiesList";
import { isSeriesViewOnly } from "../AnnotationPanel/utils/isSeriesViewOnly";
import { LabelIndicator } from "../common/LabelIndicator";
import { useGetViewerForSeries } from "../ViewerPanel/Viewer/hooks/useGetViewerForSeries";
import { SeriesFlagButton } from "./SeriesFlagButton";
import { SeriesInfoButton } from "./SeriesInfoButton";
import { SeriesNotesButton } from "./SeriesNotesButton";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 3px 6px;
  align-items: center;
  flex-wrap: wrap;

  &.filtered {
    opacity: 0.4;

    & .series-list-item-icons.filtered {
      opacity: 0.8;
    }
  }
`;

const IconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: flex-end;
  margin-left: 6px;
`;

const LabelWrapper = styled.div`
  min-width: 0;
  flex: 1;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  gap: 6px;
  align-items: center;
  min-width: 80px;
`;

const Text = styled.div<{ disabled: boolean }>`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme, disabled }) => (disabled ? theme.colors.actionPrimary.disabled : "unset")};
`;

interface SeriesListItemProps {
  series: FilterableSeriesListItemFragmentType;
  onClick: (series: FilterableSeriesListItemFragmentType) => void;
  showViewerColorBar: boolean;
  enabledDnd?: boolean;
  extraLabels?: JSX.Element;
}

export function SeriesListItem({
  series,
  onClick,
  showViewerColorBar,
  extraLabels,
}: SeriesListItemProps): JSX.Element {
  const failedSeriesIds = useSelector(imagesLoadingFailedSeriesIdsSelector);

  const { seriesDescription, id: seriesId } = series;

  const projectId = useProjectId();

  const handleClicked = () => {
    onClick(series);
  };

  const viewOnly = isSeriesViewOnly(series, projectId);

  const getViewerForSeries = useGetViewerForSeries();
  const { color } = getViewerForSeries(seriesId) || {};

  const [, drag] = useDrag(
    () => ({
      type: dragAndDropItemTypes.SERIES,
      item: { seriesId },
    }),
    [seriesId]
  );

  const failedToLoad = failedSeriesIds.includes(series.id);

  const text = seriesDescription ? seriesDescription : "N/A";

  const tooltipText = `${text}${
    failedToLoad
      ? " - One or more instance in this series failed to load. If this issue persists, contact Altis Labs"
      : ""
  }`;

  return (
    <Wrapper className={classNames("series-list-item", series.filtered ? "filtered" : "")}>
      <Tooltip title={tooltipText}>
        <LabelWrapper ref={drag} onClick={handleClicked} className="series-list-item-label">
          {showViewerColorBar && <LabelIndicator color={color ?? "transparent"} />}
          {extraLabels}
          {failedToLoad && <FailedToLoadIcon />}
          <Text disabled={viewOnly || !!series.filtered}>{text}</Text>
        </LabelWrapper>
      </Tooltip>
      <IconsWrapper
        className={classNames(["series-list-item-icons", series.filtered ? "filtered" : ""])}
      >
        <SeriesInfoButton series={series} />
        <SeriesNotesButton series={series} />
        <SeriesFlagButton series={series} />
      </IconsWrapper>
    </Wrapper>
  );
}

function FailedToLoadIcon(): JSX.Element {
  const {
    colors: {
      states: { warning },
    },
  } = main;
  return (
    //   wrapped in div to prevent shrinking
    <div>
      <SvgIcon icon={ErrorIcon} color={warning} size={18} />
    </div>
  );
}
