import { gql } from "@apollo/client";

import { PROJECT_DETAILS_FRAGMENT } from "../../Project/contexts/Project/ProjectDetailsFragment";

export const GET_PROJECT_DETAILS_BY_IDS = gql`
  query getRecentProject($projectIds: [Int!]!) {
    project(where: { id: { _in: $projectIds } }) {
      ...ProjectDetails
    }
  }
  ${PROJECT_DETAILS_FRAGMENT}
`;
