import React, { useState } from "react";
import { FilterProps } from "react-table";
import styled from "styled-components";

import SearchTextField from "../input/SearchTextField";
import { OutsideAlerter } from "../OutsideAlerter";
import { DefaultIconFilterHeader } from "./DefaultIconFilterHeader";
import { FilterButton } from "./FilterButton";
import { TableDataType } from "./TableDataType";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export function DefaultColumnFilter<T extends TableDataType>({
  column,
}: FilterProps<T>): JSX.Element {
  const { filterValue, setFilter } = column;

  const [showFilter, setShowFilter] = useState(false);

  const handleFilterChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFilter(value);
  };

  const handleToggleOpen = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    setShowFilter((open) => !open);
  };

  const handleClose = () => {
    setShowFilter(false);
  };

  const isFiltered = !!filterValue;

  return (
    <OutsideAlerter callback={handleClose}>
      <Wrapper>
        <DefaultIconFilterHeader column={column}>
          <FilterButton isFiltered={isFiltered} onClick={handleToggleOpen} />
        </DefaultIconFilterHeader>

        {showFilter && (
          <SearchTextField
            autoFocus
            onChange={handleFilterChanged}
            value={filterValue ?? ""}
            style={{ padding: "3px 12px 3px 40px" }}
          />
        )}
      </Wrapper>
    </OutsideAlerter>
  );
}
