import { ORGAN } from "../../../../../types/LesionTypeType";
import { AnatomicalStructuresFragmentType } from "../../../../AnatomicalStructuresFragment";
import { useAnatomicalStructuresByStructure } from "./useAnatomicalStructuresByStructure";
import { useLesionsList } from "./useLesionsList";
import { lesionListItemTypeFilters } from "./useTaskLesionListOptions";

type ReturnType = {
  missingOrgans: AnatomicalStructuresFragmentType[] | undefined;
  loading: boolean;
};

export function useMissingOrgans(structures: string[]): ReturnType {
  const { data: anatomicalStructuresData, loading: anatomicalStructuresLoading } =
    useAnatomicalStructuresByStructure(structures);

  const { data: lesionsData, loading: lesionsLoading } = useLesionsList(
    lesionListItemTypeFilters[ORGAN]
  );

  if (anatomicalStructuresLoading || lesionsLoading) {
    return {
      missingOrgans: undefined,
      loading: true,
    };
  }

  if (!anatomicalStructuresData || !lesionsData) {
    throw new Error(
      "Either anatomical structures or lesion data is undefined and not loading in useMissingOrgans"
    );
  }

  const { anatomicalStructures } = anatomicalStructuresData;
  const { lesions } = lesionsData;

  const missingOrgans = anatomicalStructures.filter(
    ({ id }) => !lesions.map(({ location }) => location).includes(id)
  );

  return { missingOrgans, loading: false };
}
