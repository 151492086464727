import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";

import { useDeleteEllipse } from "./tools/ellipse/hooks/useDeleteEllipse";
import { EllipseClickDataType } from "./tools/ellipse/types/EllipseClickDataType";
import { ViewerContextMenu } from "./ViewerContextMenu";
import { ViewerContextMenuItemType } from "./ViewerContextMenuItemType";

interface EllipseContextMenuProps {
  event?: EllipseClickDataType;
  onClose: () => Promise<void>;
}

export function EllipseContextMenu({ event, onClose }: EllipseContextMenuProps): JSX.Element {
  const [deleteEllipse] = useDeleteEllipse();

  const handleClose = async () => {
    await onClose();
  };

  const handleDelete = async () => {
    if (!event) {
      throw new Error("No callback data when handling delete ellipse");
    }

    const {
      toolData: { id: ellipseId },
    } = event;

    if (ellipseId === undefined) {
      throw new Error("Ellipse has no id when handling delete");
    }

    await deleteEllipse({ variables: { ellipseId } });
    await handleClose();
  };

  const menuItems: ViewerContextMenuItemType[] = [
    {
      label: "Delete",
      icon: <DeleteIcon />,
      callback: handleDelete,
    },
  ];

  return <ViewerContextMenu event={event} onClose={handleClose} menuItems={menuItems} />;
}
