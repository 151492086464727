import { NON_MEASURABLE, NOT_PRESENT } from "../../../../../../enums/TaskDescriptionEnums";
import { ClassificationValuesType } from "../../../../../../types/TaskDescriptionType";
import { InputRoiType } from "./types/InputLesionType";
import { hasClassification } from "./utils/hasClassification";

export function isRoiMeasurable(roi: InputRoiType): boolean {
  const nonMeasurableClassifications: ClassificationValuesType[] = [NOT_PRESENT, NON_MEASURABLE];
  return !nonMeasurableClassifications.some((classification) =>
    hasClassification(roi, classification)
  );
}
