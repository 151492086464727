import React, { ReactNode, useMemo } from "react";
import { useDeepCompareMemo } from "use-deep-compare";

import handleApolloError from "../../../../../common/utils/handleApolloError";
import { getTimepoints } from "../getTimepoints";
import { usePatientData } from "./hooks/usePatientData";
import { PatientContext } from "./PatientContext";

interface PatientContextProviderProps {
  children: ReactNode;
  patientId: number;
}

export function PatientContextProvider({
  patientId,
  children,
}: PatientContextProviderProps): JSX.Element {
  const { data, loading, error } = usePatientData(patientId);

  const {
    patient: { id, patientDicomId, studies, tempDemoPatients },
  } = data ?? {
    patient: { id: 0, patientDicomId: "", studies: [], tempDemoPatients: [] },
  };

  const isSynthetic = tempDemoPatients.length > 0;

  const timepoints = getTimepoints(studies);

  const memoTimepoints = useDeepCompareMemo(() => timepoints, [timepoints]);

  const context = useMemo(
    () => ({
      id: id,
      patientDicomId: patientDicomId,
      isSynthetic: isSynthetic,
      timepoints: timepoints,
      loading: loading,
    }),
    [id, patientDicomId, isSynthetic, memoTimepoints, loading]
  );

  if (error) handleApolloError(error);

  return <PatientContext.Provider value={context}>{children}</PatientContext.Provider>;
}
