import { useDialogReturnType } from "../../../common/components/Dialog/useDialog";
import { useFormDialog } from "../../../common/components/Dialog/useFormDialog";
import { UploadProjectFileForm } from "./UploadProjectFileForm";

export function useUploadProjectFileForm(): useDialogReturnType {
  return useFormDialog({
    children: UploadProjectFileForm,
    label: "File Upload",
    showCloseButton: true,
    reactModalProps: {
      shouldCloseOnOverlayClick: true,
      shouldCloseOnEsc: true,
    },
    props: undefined,
  });
}
