import { RoutePathSingleOrArray } from "../Layout";
import { RoutePathType } from "../RoutePathType";

export function getRoute(...routePaths: (RoutePathSingleOrArray | undefined)[]): RoutePathType[] {
  return routePaths.flatMap((routePath) => {
    if (!routePath) {
      return [];
    }
    if (!Array.isArray(routePath)) {
      return [routePath];
    }

    return routePath;
  });
}
