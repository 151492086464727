import { useContext } from "react";

import { ProjectContext } from "../../../Project/contexts/ProjectContext";
import { SubjectType } from "../types/SubjectType";

export function useSingleSubjectQuery(subjectId: number): SubjectType | undefined {
  const { patients } = useContext(ProjectContext);

  return patients.find((subject) => subject.id === subjectId);
}
