import React from "react";
import { Column } from "react-table";

import { getPatientProgressTaskAssignments } from "../../../common/components/TaskStatus/utils/getPatientProgressTaskAssignments";
import { sortTaskByStatus } from "../../../common/components/StudyTable/utils/sortTaskByStatus";
import { TextCell } from "../../../common/components/Table/TextCell";
import { PatientMutableTaskType } from "../Manage/hooks/TaskType";
import { CompleteTaskProgressType, TASK_PENDING } from "./TaskProgressType";
import { WorklistTaskLink } from "./WorklistTaskLink";
import {
  TaskStatus,
  TaskStatusProps,
} from "nota-predict-web/src/common/components/TaskStatus/TaskStatus";

export const PATIENT_DICOM_ID_COLUMN_ID = "patientDicomId";
export const STATUS_COLUMN_ID = "status";
export const TASK_COLUMN_ID = "task";
export const ASSIGNED_COLUMN_ID = "assigned";

export type TaskWorklistTableColumnIdType =
  | typeof PATIENT_DICOM_ID_COLUMN_ID
  | typeof STATUS_COLUMN_ID
  | typeof TASK_COLUMN_ID
  | typeof ASSIGNED_COLUMN_ID;

export function useTaskWorklistTableColumns(
  globalContext: boolean,
  openTaskViewOnly: boolean
): Column<PatientMutableTaskType>[] {
  return [
    {
      id: PATIENT_DICOM_ID_COLUMN_ID,
      Header: "Subject ID",
      accessor: ({ patientDicomId }) => patientDicomId,
      showFilter: true,
      Cell: ({ value }: { value: string }) => <TextCell value={value} />,
    },
    {
      id: TASK_COLUMN_ID,
      Header: "Task",
      accessor: (row) => row,
      // eslint-disable-next-line react/display-name,@typescript-eslint/explicit-module-boundary-types
      Cell: ({ value: row }: { value: PatientMutableTaskType }) => (
        <WorklistTaskLink row={row} openTaskViewOnly={openTaskViewOnly} />
      ),
      sortType: (
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        {
          values: {
            task: {
              task: { name: nameA },
            },
          },
        },
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        {
          values: {
            task: {
              task: { name: nameB },
            },
          },
        }
      ): number => {
        return nameA.localeCompare(nameB);
      },
    },
    {
      id: STATUS_COLUMN_ID,
      Header: "Status",
      accessor: (row) => {
        if (!row.task) {
          return { progress: TASK_PENDING, taskAssignments: [] };
        }
        return getPatientProgressTaskAssignments(row.id, [row]);
      },
      style: {
        width: "150px",
      },
      // eslint-disable-next-line react/display-name
      Cell: ({ value: props }: { value: TaskStatusProps }) => {
        if (props.taskAssignments.filter(({ task }) => !!task).length !== 0) {
          return (
            <TaskStatus
              showAllAssignees={true}
              enablePopOver={false}
              globalContext={globalContext}
              {...props}
            />
          );
        }
        return null;
      },
      sortType: (
        {
          values: taskValueA,
        }: {
          values: { [key: string]: CompleteTaskProgressType };
        },
        {
          values: taskValueB,
        }: {
          values: { [key: string]: CompleteTaskProgressType };
        }
      ) => {
        return sortTaskByStatus(taskValueA[STATUS_COLUMN_ID], taskValueB[STATUS_COLUMN_ID]);
      },
    },
  ];
}
