import { gql, useQuery } from "@apollo/client";
import { POLLING_INTERVAL } from "nota-predict-web/src/common/utils/configConstants";
import { useDeepCompareMemo } from "use-deep-compare";

import { ANATOMICAL_STRUCTURES_FRAGMENT } from "../../../Annotate/components/AnatomicalStructuresFragment";
import {
  isAnnotatePageAndSkipped,
  useIsAnnotatePageAndSkippedCallback,
} from "../../../Annotate/components/Annotate/page/AnnotationPanel/hooks/debugUtils";
import { RawSubjectType } from "../rawTypes/RawSubjectType";
import { parseSubjectFromQueryResult } from "../utils/rawDataParsers/parseSubjectFromQueryResult";

/*
todo
- flip the query so that all studies are queried with followup as children instead of the other way around
- make followup order optional and handle where used - this is effectively an "empty" followup temporarily
- eventually, make usage of followup use a function getFollowUps() from subject so that the "empty" followups are removed
 */

const SUBJECT_FRAGMENT = gql`
  fragment Subject on patient {
    patient_id
    patient_dicom_id
    enrolments {
      randomization_date
    }
    patient_demographic {
      bmi
      dob
      ecog_performance_status
      ethnicity
      sex
    }
    patient_history {
      smoking_status
      death_date
    }
    patient_project_arms {
      project_arm {
        name
        arm_num
      }
    }
    follow_ups(order_by: { follow_up_order: desc }) {
      diagnoses {
        diagnosis_date
        stages {
          ajcc_stage
          m_stage
          n_stage
          t_stage
        }
        diagnosis_histology_maps {
          histology {
            result
          }
        }
        treatments {
          start_date
        }
        tumours {
          id
          tumour_mutations {
            mutation
          }
        }
      }
      follow_up_date
      follow_up_order
      id
      study_follow_up_maps(
        where: {
          study: {
            series: { _not: { project_series_exclusions: { project_id: { _eq: $projectId } } } }
          }
        }
      ) {
        study {
          study_id
          study_dicom_id
          study_instance_uid
          study_date
          upload_jobs: study_celery_job_maps(
            order_by: { celery_job: { last_modified: desc } }
            where: { celery_job: { name: { _eq: DICOM_UPLOAD } } }
            limit: 1
          ) {
            celery_job {
              id
              status
              last_modified
              created_at
            }
          }
          series(
            where: { _not: { project_series_exclusions: { project_id: { _eq: $projectId } } } }
          ) {
            series_instance_uid
            series_id
            survival_predictions {
              survival
            }
            mortality_risks {
              prediction
            }
            series_tumour_burden_maps {
              tumour_burden {
                diametric
                volumetric
                tumour_id
                id
                predicted
              }
            }
            series_tumour_response_maps {
              tumour_response {
                classification
                id
              }
            }
          }
        }
      }
    }
    temp_demo_patients {
      patient_id
    }
    studies(
      where: {
        series: { _not: { project_series_exclusions: { project_id: { _eq: $projectId } } } }
      }
    ) {
      last_modified
    }
  }
  ${ANATOMICAL_STRUCTURES_FRAGMENT}
`;

export const GET_SUBJECTS_QUERY = gql`
  query getSubjectData($projectId: Int!) {
    patient(
      where: {
        _or: [
          { patient_project_arms: { project_arm: { project_id: { _eq: $projectId } } } }
          { label_patients: { label: { label_projects: { project_id: { _eq: $projectId } } } } }
        ]
      }
      distinct_on: [patient_id]
    ) {
      ...Subject
    }
  }
  ${SUBJECT_FRAGMENT}
`;

export type RawSubjectsQueryType = {
  patient: RawSubjectType[];
};

type useSubjectsQueryVariables = { projectId: number };

export function useSubjectQuery(projectId: number) {
  const { data, loading, error, startPolling, stopPolling } = useQuery<
    RawSubjectsQueryType,
    useSubjectsQueryVariables
  >(GET_SUBJECTS_QUERY, {
    variables: { projectId },
    skip: isAnnotatePageAndSkipped(window.location.pathname),
  });

  useIsAnnotatePageAndSkippedCallback((isSkippedOnAnnotatePage) => {
    if (isSkippedOnAnnotatePage) {
      stopPolling();
    } else {
      startPolling(POLLING_INTERVAL);
    }
  });

  const subjects = useDeepCompareMemo(() => {
    const { patient: rawSubjects } = data || {
      patient: [],
    };

    const orderedRawSubjects = [...rawSubjects].sort(orderByLastModified);

    return orderedRawSubjects.map((subject) => parseSubjectFromQueryResult(subject));
  }, [data]);

  return {
    subjects,
    loading,
    error,
    startPolling,
    stopPolling,
  };
}

// we were previously ordering like this in the query, however in order to use the `distinct_on`, we can't anymore, so order them in code here
// order patients by maximum study.last_modified and put nulls last
const orderByLastModified = (a: RawSubjectType, b: RawSubjectType) => {
  const aLastModified = a.studies.reduce(
    (max, study) =>
      new Date(study.last_modified).getTime() > max ? new Date(study.last_modified).getTime() : max,
    0
  );

  const bLastModified = b.studies.reduce(
    (max, study) =>
      new Date(study.last_modified).getTime() > max ? new Date(study.last_modified).getTime() : max,
    0
  );

  if (aLastModified === bLastModified) {
    return 0;
  }
  if (aLastModified === null) {
    return 1;
  }
  if (bLastModified === null) {
    return -1;
  }
  return aLastModified > bLastModified ? -1 : 1;
};
