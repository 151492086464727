import { useContext } from "react";
import { useSelector } from "react-redux";

import { getProjectViewFilters } from "../../../common/store/projectViewFiltersSlice";
import { ProjectContext } from "../../../Project/contexts/ProjectContext";
import { SubjectType } from "../types/SubjectType";
import { applySubjectLevelFilters } from "../utils/filters/applySubjectLevelFilters";

export function useFilteredSubjectsQuery(filter = true): SubjectType[] {
  const { patients } = useContext(ProjectContext);

  const filters = useSelector(getProjectViewFilters);

  if (!filter) {
    return patients;
  }

  return patients.filter(applySubjectLevelFilters(filters));
}
