import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";

import { ActionButtonsWrapper } from "../../../../common/components/Dialog/Form/ActionButtonsWrapper";
import { FormProps } from "../../../../common/components/Dialog/FormProps";
import { ErrorLabel } from "../../../../common/components/input/ErrorLabel";
import { Input } from "../../../../common/components/input/Input";
import { InputButton } from "../../../../common/components/input/InputButton";
import { Label } from "../../../../common/components/input/Label";
import { main } from "../../../../common/theme/main";
import { CohortType } from "../../../../common/types/CohortType";
import { ColorPicker } from "../Labels/ColorPicker";
import { ColorWrapper } from "../Labels/CreateAnnotationForm";
import { useGlobalCohorts } from "../Project/useGlobalCohorts";
import { useCreateCohort } from "./useCreateCohort";
import { useProjectCohorts } from "./useProjectCohorts";
import { useUpdateCohort } from "./useUpdateCohort";

export const DEFAULT_COHORT_COLOR = "#8800ff";

const EMPTY_COHORT = { name: "", metadata: { color: DEFAULT_COHORT_COLOR } };

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 24px 15px;
  gap: 24px;
  width: 419px;
`;

interface CreateCohortFormProps {
  cohort: CohortType | undefined;
  projectId?: number;
}

export const CreateCohortForm: FC<FormProps<CreateCohortFormProps>> = ({
  onSubmit,
  onCancel,
  props: { cohort, projectId },
}: FormProps<CreateCohortFormProps>) => {
  const [createCohort] = useCreateCohort();
  const [updateCohort] = useUpdateCohort();

  const handleCreateCohort = async ({ name, metadata }: CohortType) => {
    const variables = {
      label: {
        name,
        project_id: projectId ?? null,
        type_name: "COHORT",
        metadata: { color: metadata.color },
      },
    };
    await createCohort({ variables });
  };

  const handleUpdateCohort = async ({ id, name, metadata }: CohortType) => {
    const variables = { id, name, metadata: { color: metadata.color } };
    await updateCohort({ variables });
  };

  return (
    <InternalCreateCohortForm
      onSubmit={onSubmit}
      onCancel={onCancel}
      props={{
        cohort,
        projectId,
        onCreateCohort: handleCreateCohort,
        onUpdateCohort: handleUpdateCohort,
      }}
    />
  );
};

interface InternalCreateCohortFormProps {
  cohort: CohortType | undefined;
  projectId?: number;
  onCreateCohort: (cohort: CohortType) => Promise<void>;
  onUpdateCohort: (cohort: CohortType) => Promise<void>;
}

const InternalCreateCohortForm: FC<FormProps<InternalCreateCohortFormProps>> = ({
  onSubmit,
  onCancel,
  props: { cohort, projectId, onCreateCohort, onUpdateCohort },
}: FormProps<InternalCreateCohortFormProps>) => {
  const isNewCohort = !cohort;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data } = projectId ? useProjectCohorts(projectId || 0) : useGlobalCohorts();

  const otherCohortNames: string[] =
    data?.cohorts?.map(({ name }) => name).filter((name) => !cohort || cohort.name !== name) ?? [];

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<CohortType>({ defaultValues: cohort ?? EMPTY_COHORT });

  const handleCancel = () => {
    onCancel();
  };

  const handleSubmitForm = async (cohort: CohortType) => {
    if (isNewCohort) {
      await onCreateCohort(cohort);
    } else {
      await onUpdateCohort(cohort);
    }

    onSubmit();
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Wrapper>
          <div>
            <Label htmlFor={"cohort-name"} required error={errors.name}>
              Cohort Name
            </Label>
            <Input
              error={errors.name}
              type="text"
              id={"cohort-name"}
              autoFocus
              autoComplete={"off"}
              {...register("name", {
                required: true,
                validate: (value) => !otherCohortNames.includes(value),
              })}
            />
            {errors.name && <ErrorLabel>A unique cohort name must be provided.</ErrorLabel>}
          </div>
          <ColorWrapper>
            Color:
            <Controller
              name="metadata.color"
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <ColorPicker color={value ?? DEFAULT_COHORT_COLOR} onColorChanged={onChange} />
              )}
            />
          </ColorWrapper>
        </Wrapper>

        <ActionButtonsWrapper>
          <InputButton type="submit" name="submit-button" value={"Confirm"} />
          <InputButton
            type="button"
            name="cancel-button"
            value={"Cancel"}
            background={main.colors.neutral.white}
            color={main.colors.neutral.black}
            onClick={handleCancel}
          />
        </ActionButtonsWrapper>
      </form>
    </>
  );
};
