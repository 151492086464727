import { showFollowUpLabelsSelector } from "nota-predict-web/src/common/store/annotatePage/taskSelector";
import { useMemo } from "react";
import { useSelector } from "react-redux";

import { useProjectId } from "../../../../../../../Project/hooks/useProjectId";
import {
  formatTimepointLabel,
  timepointIncludeDay,
} from "../../../StudyPanel/utils/formatTimepointLabel";
import { isSeriesViewOnly } from "../../utils/isSeriesViewOnly";
import { TimepointType } from "./TimepointType";
import { useTimepoints } from "./useTimepoints";

export function useTimepointsNonViewOnlySeries(): TimepointType[] | undefined {
  const { timepoints } = useTimepoints();
  const projectId = useProjectId();
  const _showFollowUpLabel = useSelector(showFollowUpLabelsSelector);
  const showFollowUpLabel = useMemo(() => _showFollowUpLabel, [_showFollowUpLabel]);

  if (!timepoints) {
    return undefined;
  }

  return timepoints
    .map((timepoint, i) => {
      const { series, ...other } = timepoint;
      const includeDay = timepointIncludeDay(timepoints, i);
      const label = formatTimepointLabel(timepoint, showFollowUpLabel, includeDay);
      const nonViewOnlySeries = series.filter((series) => !isSeriesViewOnly(series, projectId));
      return {
        ...other,
        series: nonViewOnlySeries,
        includeDay,
        label,
      };
    })
    .filter(({ series }) => series.length > 0);
}
