import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { MutationTuple } from "@apollo/client/react/types/types";

type InsertData = {
  insertLabelSeries: { returning: { id: number; label_id: number; series_id: number }[] };
  insertLabelStudies: { returning: { id: number; label_id: number; study_id: number }[] };
  insertLabelPatients: { returning: { id: number; label_id: number; patient_id: number }[] };
};

type InsertVariables = {
  labelSeriesPairs: { label_id: number; series_id: number }[];
  labelStudyPairs: { label_id: number; study_id: number }[];
  labelPatientPairs: { label_id: number; patient_id: number }[];
};

const INSERT_MUTATION = gql`
  mutation InsertCVSValues(
    $labelSeriesPairs: [label_series_insert_input!]!
    $labelStudyPairs: [label_study_insert_input!]!
    $labelPatientPairs: [label_patient_insert_input!]!
  ) {
    insertLabelSeries: insert_label_series(
      objects: $labelSeriesPairs
      on_conflict: { constraint: label_series_series_id_label_id_key, update_columns: [] }
    ) {
      returning {
        id
        label_id
        series_id
      }
    }
    insertLabelStudies: insert_label_study(
      objects: $labelStudyPairs
      on_conflict: { constraint: label_study_study_id_label_id_key, update_columns: [] }
    ) {
      returning {
        id
        label_id
        study_id
      }
    }
    insertLabelPatients: insert_label_patient(
      objects: $labelPatientPairs
      on_conflict: { constraint: label_patient_patient_id_label_id_key, update_columns: [] }
    ) {
      returning {
        id
        label_id
        patient_id
      }
    }
  }
`;

export function useInsertCSVValues(): MutationTuple<InsertData, InsertVariables> {
  return useMutation<InsertData, InsertVariables>(INSERT_MUTATION);
}

type DeleteData = {
  deleteLabelSeries: { returning: { id: number }[] };
  deleteLabelStudies: { returning: { id: number }[] };
  deleteLabelPatients: { returning: { id: number }[] };
};

type DeleteVariables = {
  labelId: number;
  seriesIds: number[];
  studyIds: number[];
  patientIds: number[];
};

const DELETE_MUTATION = gql`
  mutation DeleteLabelSeries(
    $labelId: Int!
    $seriesIds: [Int!]!
    $studyIds: [Int!]!
    $patientIds: [Int!]!
  ) {
    deleteLabelSeries: delete_label_series(
      where: { _and: [{ label_id: { _eq: $labelId } }, { series_id: { _in: $seriesIds } }] }
    ) {
      returning {
        id
      }
    }
    deleteLabelStudies: delete_label_study(
      where: { _and: [{ label_id: { _eq: $labelId } }, { study_id: { _in: $studyIds } }] }
    ) {
      returning {
        id
      }
    }
    deleteLabelPatients: delete_label_patient(
      where: { _and: [{ label_id: { _eq: $labelId } }, { patient_id: { _in: $patientIds } }] }
    ) {
      returning {
        id
      }
    }
  }
`;

export function useDeleteCSVValues(): MutationTuple<DeleteData, DeleteVariables> {
  return useMutation<DeleteData, DeleteVariables>(DELETE_MUTATION);
}
