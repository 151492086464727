import React, { useEffect, useState } from "react";
import styled from "styled-components";

import FilterCheckbox from "../../Analysis/ToolPanel/components/FilterCheckbox";
import { InputButton } from "../../common/components/input/InputButton";
import { main } from "../../common/theme/main";
import { Options } from "./Options";
import { loadOptions, saveOptions } from "./utils";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  min-width: 200px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
  margin-top: 12px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 12px;
`;

const optionKeys: Array<keyof Options> = [
  "includeManualMasks",
  "includePredictedMasks",
  "includeSeriesVolumes",
];

const optionDisplayNames: Record<keyof Options, { label: string; enabled: boolean }> = {
  includeManualMasks: { label: "Manually segmented volumes", enabled: true },
  includePredictedMasks: { label: "Predicted segmentation volumes", enabled: true },
  includeSeriesVolumes: { label: "Series volumes", enabled: true },
  includePatientJson: { label: "Subject JSONs", enabled: false },
  includePredictedSummaries: { label: "Predicted summaries", enabled: false },
  includeSummaryCsv: { label: "Summary CSV", enabled: false },
};

interface ExportOptionsSelectionProps {
  onConfirm: (options: Options) => void;
  onCancel: () => void;
}

export function ExportOptionsSelection({
  onConfirm,
  onCancel,
}: ExportOptionsSelectionProps): JSX.Element {
  const [options, setOptions] = useState<Options>({
    includeManualMasks: true,
    includePredictedMasks: true,
    includeSeriesVolumes: true,
    includePatientJson: true,
    includePredictedSummaries: false,
    includeSummaryCsv: true,
  });

  useEffect(() => {
    const options = loadOptions();
    if (!options) {
      return;
    }

    setOptions(options);
  }, []);

  const handleConfirm = () => {
    saveOptions(options);

    onConfirm(options);
  };

  const handleOptionChanged = (option: keyof Options) => (checked: boolean) => {
    setOptions((options) => {
      const updatedOptions = { ...options, [option]: checked };
      saveOptions(updatedOptions);
      return updatedOptions;
    });
  };

  return (
    <Wrapper>
      <Title>Export Annotations:</Title>
      {optionKeys.flatMap((key) => {
        const { label, enabled } = optionDisplayNames[key];
        if (!enabled) {
          return [];
        }

        return [
          <FilterCheckbox
            checked={options[key]}
            key={key}
            label={label}
            onChange={handleOptionChanged(key)}
            name={key}
          />,
        ];
      })}
      <Buttons>
        <InputButton autoFocus type="button" value={"Export"} onClick={handleConfirm} />
        <InputButton
          type="button"
          value={"Cancel"}
          background={main.colors.neutral.white}
          color={main.colors.neutral.black}
          onClick={onCancel}
        />
      </Buttons>
    </Wrapper>
  );
}
