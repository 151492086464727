import { useEffect, useRef } from "react";

import { ToolControllerType } from "../../toolController/ToolControllerType";
import { EllipseClickDataType } from "../types/EllipseClickDataType";

export function useEllipseRightClicked(
  toolController: ToolControllerType | null,
  onEllipseRightClicked: (data: EllipseClickDataType) => void
): void {
  const toolControllerRef = useRef<ToolControllerType>();

  useEffect(() => {
    toolController?.setOnEllipseRightClickedCallback(onEllipseRightClicked);

    return () => {
      const toolController = toolControllerRef.current;
      toolController?.setOnEllipseRightClickedCallback(null);
    };
  }, [toolController]);
}
