import { useContext } from "react";
import styled from "styled-components";

import { TaskContext } from "../../../../TaskContext";
import { AnnotationsPanel } from "./AnnotationsPanel";
import { QualityControlPanel } from "./QualityControlPanel/QualityControlPanel";
import { QualityControlProvider } from "./QualityControlPanel/QualityControlProvider";
import { useValidatedQualityControlSchema } from "./QualityControlPanel/useValidatedQualityControlSchema";
import { RequiredLesionsList } from "./RequiredLesionsList";
import { LesionToScrollToProvider } from "./toolbar/SaveChecks/LesionToScrollTo/LesionToScrollToProvider";
import { TaskToolbar } from "./toolbar/TaskToolbar";

const Wrapper = styled.div`
  flex: 8;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 3px;
  gap: 6px;
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export function QcTaskActionPanel({ qcSchemaId }: { qcSchemaId: number | null }): JSX.Element {
  if (qcSchemaId === null) {
    throw new Error("QC task has no QC schema id");
  }

  const schemaValidator = useValidatedQualityControlSchema(qcSchemaId);

  return (
    <QualityControlProvider
      schema={schemaValidator?.schemaRow?.schema}
      error={schemaValidator.error}
    >
      <LesionToScrollToProvider>
        <Wrapper>
          <TopWrapper>
            <TaskToolbar />
          </TopWrapper>
          <QualityControlPanel />
        </Wrapper>
      </LesionToScrollToProvider>
    </QualityControlProvider>
  );
}

export function AnnotationTaskActionPanel(): JSX.Element {
  return (
    <LesionToScrollToProvider>
      <Wrapper>
        <TopWrapper>
          <TaskToolbar />
          <RequiredLesionsList />
        </TopWrapper>
        <AnnotationsPanel />
      </Wrapper>
    </LesionToScrollToProvider>
  );
}

function EmptyPanel(): JSX.Element {
  return (
    <LesionToScrollToProvider>
      <Wrapper>
        <TopWrapper>
          <TaskToolbar />
        </TopWrapper>
      </Wrapper>
    </LesionToScrollToProvider>
  );
}

export function TaskActionPanel(): JSX.Element {
  const {
    isQc,
    selectedTaskAssignmentId,
    task: { qcSchemaId },
  } = useContext(TaskContext);

  if (!selectedTaskAssignmentId) {
    return <EmptyPanel />;
  }

  return isQc && qcSchemaId ? (
    <QcTaskActionPanel qcSchemaId={qcSchemaId} />
  ) : (
    <AnnotationTaskActionPanel />
  );
}
