import cornerstoneTools from "@altis-labs/cornerstone-tools";
import _ from "lodash/core";

import { ELLIPTICAL_ROI } from "../../../../../../../cornerstone/ToolType";
import { NEW_FINDING_ROI_ID } from "../toolController/NewFindingRoiId";

export default class EllipseToolWrapper {
  constructor(ellipseTool) {
    this.currentRoi = null;
    this.enabled = false;
    this.toolType = ELLIPTICAL_ROI;
    this.onEllipseCreatedCallback = null;
    this.onEllipseChangedCallback = null;
    this.onEllipseDoubleClickedCallback = null;
    this.onEllipseRightClickedCallback = null;
    this.tool = ellipseTool;
    this.tool.setOnEllipseCreatedCallback(this.onEllipseCreated);
    this.tool.setOnEllipseChangedCallback(this.onEllipseChanged);
    this.tool.setOnEllipseDoubleClickedCallback(this.onEllipseDoubleClicked);
    this.tool.setOnEllipseRightClickedCallback(this.onEllipseRightClicked);
  }

  turnOn() {
    this.enabled = true;
  }

  turnOff() {
    this.enabled = false;
  }

  setCurrentRoi(roi) {
    this.currentRoi = roi;

    this.tool.setCurrentRoi(roi);

    if (!roi) {
      this.tool.setPassive();
    } else {
      this.updateState();
    }
  }

  setPassiveRoi(roi) {
    this.tool.setPassiveRoi(roi);
  }

  setOnEllipseCreatedCallback(callback) {
    this.onEllipseCreatedCallback = callback;
  }

  setOnEllipseChangedCallback(callback) {
    this.onEllipseChangedCallback = callback;
  }

  setOnEllipseDoubleClickedCallback(onEllipseDoubleClickedCallback) {
    this.onEllipseDoubleClickedCallback = onEllipseDoubleClickedCallback;
  }

  setOnEllipseRightClickedCallback(callback) {
    this.onEllipseRightClickedCallback = callback;
  }

  setOnExceptionThrown(onExceptionThrown) {
    this.tool.setOnExceptionThrown(onExceptionThrown);
  }

  onEllipseDoubleClicked = (toolData) => {
    if (this.onEllipseDoubleClickedCallback) {
      this.onEllipseDoubleClickedCallback(toolData);
    }
  };

  onEllipseRightClicked = (data) => {
    this.onEllipseRightClickedCallback?.(data);
  };

  onEllipseCreated = async (data) => {
    if (!this.enabled) {
      return;
    }

    this.updateState();

    if (this.onEllipseCreatedCallback) {
      let imageId = data.imageId;
      //on initial startup, there are no images loaded but the ellipse is invalidated, so don't send an update
      if (!imageId) {
        return;
      }

      let toolSliceData = { imageId: imageId, toolData: [data.data] };
      await this.onEllipseCreatedCallback(toolSliceData);
    }
  };

  onEllipseChanged = async (data) => {
    if (!this.enabled) {
      return;
    }

    this.updateState();

    if (this.onEllipseChangedCallback) {
      let imageId = data.imageId;
      //on initial startup, there are no images loaded but the ellipse is invalidated, so don't send an update
      if (!imageId) {
        return;
      }

      let toolSliceData = { imageId: imageId, toolData: [data.data] };
      await this.onEllipseChangedCallback(toolSliceData);
    }
  };

  updateState = () => {
    if (!this.ellipseExistsForCurrentRoi()) {
      this.tool.setActive();
    } else {
      this.tool.setPassive();
    }
  };

  ellipseExistsForCurrentRoi = () => {
    const currentRoi = this.currentRoi;
    const toolType = this.toolType;

    if (!currentRoi) {
      return false;
    }

    const globalToolStates = cornerstoneTools.globalImageIdSpecificToolStateManager.toolState;

    const toolsStateData = [];

    Object.keys(globalToolStates).forEach((imageId) => {
      const sliceToolState = globalToolStates[imageId];

      if (_.has(sliceToolState, toolType)) {
        const toolStateData = sliceToolState[toolType].data;

        toolStateData.forEach((data) => {
          if (
            data.roi &&
            [currentRoi.id, NEW_FINDING_ROI_ID].includes(data.roi.id) &&
            currentRoi.lesionId === data.roi.lesionId
          ) {
            toolsStateData.push(data);
          }
        });
      }
    });

    return toolsStateData.length > 0;
  };

  setToolRoiStates = (roiStates) => {
    this.tool.setToolRoiStates(roiStates);
  };
}
