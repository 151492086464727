import { useContext } from "react";
import { useDispatch } from "react-redux";

import { setSelectedLesionId } from "../../../../../common/store/annotatePage/selectionSlice";
import { ParsedLesionType } from "../../../../parsers/parseLesion";
import { TaskContext } from "../../../../TaskContext";
import {
  SELECTED_CONTOUR_COLOR,
  setToolStateColorForLesion,
} from "../ViewerPanel/tools/legacy/ToolUtils";

type ReturnType = {
  selectFirstLesion: (lesions: ParsedLesionType[]) => void;
};

export function useSelectFirstLesion(): ReturnType {
  const dispatch = useDispatch();
  const {
    task: { id: taskId },
  } = useContext(TaskContext);

  const selectFirstLesion = (lesions: ParsedLesionType[]) => {
    const firstLesion =
      lesions.find(({ taskId: lesionTaskId }) => lesionTaskId === taskId) ?? lesions[0];

    const { id } = firstLesion ?? { id: null };
    setToolStateColorForLesion([{ lesionId: id, color: SELECTED_CONTOUR_COLOR }]);
    dispatch(setSelectedLesionId(id));
  };

  return { selectFirstLesion };
}
