import { useCallback } from "react";

import { useGetSeriesImageIds } from "../../../ViewerPanel/Viewer/hooks/useGetSeriesImageIds";

export function useGetSeriesFirstImageId() {
  const getSeriesImageIds = useGetSeriesImageIds();

  return useCallback(
    (seriesId: number) => {
      const imageIds = getSeriesImageIds(seriesId);
      if (imageIds.length === 0) {
        throw new Error(`No image ids found for series ${seriesId}`);
      }

      return imageIds[0];
    },
    [getSeriesImageIds]
  );
}
