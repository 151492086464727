import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { MutationTuple } from "@apollo/client/react/types/types";

import { ClassificationValuesType } from "../../../../../types/TaskDescriptionType";
import { getRoiClassificationCacheId } from "./getRoiClassificationCacheId";

const MUTATION = gql`
  mutation DeleteRoiClassifications($roiIds: [Int!]!, $classifications: [String!]!) {
    deleted: delete_roi_classification(
      where: { _and: [{ roi_id: { _in: $roiIds } }, { classification: { _in: $classifications } }] }
    ) {
      roiClassifications: returning {
        id
        roiId: roi_id
        classification
      }
    }
  }
`;

type Variables = {
  roiIds: number[];
  classifications: ClassificationValuesType[];
};

type Data = {
  deleted: {
    roiClassifications: {
      id: number;
      roiId: number;
      classification: ClassificationValuesType;
    }[];
  };
};

export function useDeleteRoiClassifications(): MutationTuple<Data, Variables> {
  return useMutation<Data, Variables>(MUTATION, {
    update: (cache, { data }) => {
      if (!data) {
        return;
      }

      const {
        deleted: { roiClassifications },
      } = data;

      roiClassifications.forEach(({ id }) => {
        const roiClassificationCacheId = getRoiClassificationCacheId(id, cache);
        cache.evict({ id: roiClassificationCacheId });
      });

      cache.gc();
    },
  });
}
