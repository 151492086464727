import {
  ANATOMY,
  CONSTRAINT_ALL,
  CONSTRAINT_ONLY_ONE,
} from "../../../../Annotate/enums/TaskDescriptionEnums";
import {
  ClassificationObjectsType,
  ClassificationsType,
  ClassificationsTypeType,
  ClassificationValuesType,
} from "../../../../Annotate/types/TaskDescriptionType";
import {
  ClassificationModeDefinitionsType,
  ClassificationsMapType,
  ClassificationsMapValueType,
} from "../ClassificationsMapType";

export function parseClassifications(
  classifications: ClassificationsType[] | undefined
): ClassificationModeDefinitionsType {
  const classificationModes = {} as ClassificationModeDefinitionsType;

  if (!classifications || classifications.length === 0) {
    return classificationModes;
  }

  classifications.forEach(({ values: classificationGroupings, constraint, mode }) => {
    if (constraint !== CONSTRAINT_ALL) {
      throw new Error("Constraint should be set to ALL");
    }

    if (mode === undefined) {
      throw new Error("Mode should not be undefined here!");
    }

    const classificationsMap = {} as ClassificationsMapType;

    (classificationGroupings as ClassificationsType[]).forEach(({ values, constraint, type }) => {
      if (constraint !== CONSTRAINT_ONLY_ONE) {
        throw new Error("Constraint should be set to ONLY_ONE");
      }
      if (!type) {
        throw new Error("Classifications should have a type");
      }
      const valuesSelectedMap = {} as Record<ClassificationValuesType, boolean>;
      (values as ClassificationObjectsType[]).forEach(({ value }) => {
        valuesSelectedMap[value] = false;
      });
      classificationsMap[type] = {
        constraint,
        values: valuesSelectedMap,
      };
    });

    const filteredClassificationsMap = filterObject(
      classificationsMap,
      (map) => !isAnatomyClassification(map)
    );
    const useAnatomySelector = Object.keys(classificationsMap).some((key) =>
      isAnatomyClassification(classificationsMap[key as ClassificationsTypeType])
    );

    classificationModes[mode] = {
      map: filteredClassificationsMap,
      useAnatomySelector,
    };
  });

  return classificationModes;
}

export function filterObject<T>(
  obj: Record<ClassificationsTypeType, T>,
  predicate: (value: T) => boolean
): Record<ClassificationsTypeType, T> {
  return Object.keys(obj)
    .filter((key) => predicate(obj[key as ClassificationsTypeType]))
    .reduce<Record<string, T>>((res, key) => {
      res[key] = obj[key as ClassificationsTypeType];
      return res;
    }, {});
}

export function isAnatomyClassification({ values }: ClassificationsMapValueType): boolean {
  const keys = Object.keys(values);
  return keys.length === 1 && keys[0] === ANATOMY;
}
