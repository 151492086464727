import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { MutationTuple } from "@apollo/client/react/types/types";

import { ROI_LIST_ITEM_FRAGMENT } from "../fragments/RoiListItemFragment";
import {
  Data as DeleteData,
  INTERNAL_MUTATION as DELETE_INTERNAL_MUTATION,
  updateCache as updateDeleteCache,
  Variables as DeleteVariables,
} from "./useDeleteRois";
import {
  Data as InsertData,
  INTERNAL_MUTATION as INSERT_INTERNAL_MUTATION,
  updateCache as updateInsertCache,
  Variables as InsertVariables,
} from "./useInsertRois";
import { useLesionListQueryInput } from "./useLesionListQueryInput";

const MUTATION = gql`
    mutation InsertDeleteRois($rois: [roi_insert_input!]!, $skipRecist: Boolean!, $diagnosisIds: [Int!]!, $roiIds: [Int!]!) {
        ${INSERT_INTERNAL_MUTATION}
        ${DELETE_INTERNAL_MUTATION}
    }

    ${ROI_LIST_ITEM_FRAGMENT}
`;

export type Data = DeleteData & InsertData;
export type Variables = DeleteVariables & InsertVariables;

export function useInsertDeleteRois(): MutationTuple<Data, Variables> {
  const lesionListQueryInput = useLesionListQueryInput();

  return useMutation<Data, Variables>(MUTATION, {
    update: (cache, result) => {
      updateDeleteCache(cache, result, lesionListQueryInput);
      updateInsertCache(cache, result);
    },
  });
}
