import React from "react";

import { NODE, TUMOUR_TIMEPOINT_BURDEN } from "../../../../../enums/TaskDescriptionEnums";
import { getRoiBurden } from "../../StudyPanel/utils/TumourResponse/getRoiBurden";
import { hasClassification } from "../../StudyPanel/utils/TumourResponse/utils/hasClassification";
import { TumourBurdenTimepointStat } from "./TumourBurdenTimepointStat";
import { RoiStatComponentDefinition, RoiStatProps } from "./useGetRoiStatComponents";

function RoiTumourBurdenStat(props: RoiStatProps): JSX.Element {
  const { roi, lesion } = props;
  const isNode = hasClassification(lesion, NODE);
  const { diametric } = getRoiBurden(roi, isNode);

  if (!diametric || !hasValue(props)) {
    throw new Error("Should not render");
  }

  return <TumourBurdenTimepointStat value={diametric} />;
}

function hasValue({ roi, lesion }: RoiStatProps): boolean {
  const isNode = hasClassification(lesion, NODE);
  const { diametric } = getRoiBurden(roi, isNode);
  return !!diametric;
}

export const roiTumourBurdenStatDefinition: RoiStatComponentDefinition = {
  type: TUMOUR_TIMEPOINT_BURDEN,
  hasValue,
  component: RoiTumourBurdenStat,
};
