import { Box, Fade, List, ListItem, styled as muiStyled } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useOkForm } from "nota-predict-web/src/common/components/Dialog/useOkForm";
import { InputBox } from "nota-predict-web/src/common/components/input/InputBox";
import {
  PatientContextType,
  usePatientContext,
} from "nota-predict-web/src/Dashboard/components/Settings/Patients/Viewer/PatientContext";
import React, { useState } from "react";
import { BiCopy as CopyIcon } from "react-icons/bi";
import { BsCheck as CheckIcon } from "react-icons/bs";
import { FiInfo as InfoIcon } from "react-icons/fi";
import styled from "styled-components";

import ToggleSwitch from "../../../../../common/components/ToggleSwitch";
import { main } from "../../../../../common/theme/main";
import { useProjectId } from "../../../../../Project/hooks/useProjectId";
import {
  SeriesListItemFragmentType,
  StudyListItemFragmentType,
} from "../AnnotationPanel/hooks/useStudiesList";
import { useStudyFromCache } from "../AnnotationPanel/hooks/useStudyFromCache";
import { IconButton } from "../AnnotationPanel/IconButton";
import { useDeleteViewOnlySeries } from "./hooks/useDeleteViewOnlySeries";
import { useInsertViewOnlySeries } from "./hooks/useInsertViewOnlySeries";

interface SeriesInfoButtonProps {
  series: SeriesListItemFragmentType;
  study?: StudyListItemFragmentType;
  patient?: PatientContextType;
  size?: number;
  color?: string;
}

const CheckboxLabelWrapper = styled.div`
  color: ${(props) => props.theme.colors.neutral.black};
  font-size: 13px;
  width: 190px;
`;

const GreenCheckIcon = muiStyled(CheckIcon)(() => ({
  color: main.colors.states.success,
  position: "absolute",
}));

const StyledListItem = muiStyled((props) => <ListItem {...props} />)(() => ({
  display: "flex",
  flexDirection: "row",
  gap: 12,
}));

const StyleIconButton = muiStyled(IconButton)(() => ({
  marginLeft: -6,
  position: "relative",
}));

const CopyButton = ({ text }: { text?: string | null }) => {
  const [success, setSuccess] = useState(false);

  const handleCopy = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    navigator.clipboard.writeText(text || "");
    setSuccess(true);

    // Reset success after 5 seconds
    setTimeout(() => {
      setSuccess(false);
    }, 2000);
  };

  if (!text) {
    return null;
  }

  return (
    <Tooltip title={"Copy"}>
      <StyleIconButton onClick={handleCopy}>
        <Fade in={success}>
          <Box>
            <GreenCheckIcon size={18} />
          </Box>
        </Fade>
        <Fade in={!success}>
          <Box>
            <CopyIcon size={18} color={main.colors.neutral.black} />
          </Box>
        </Fade>
      </StyleIconButton>
    </Tooltip>
  );
};

export function SeriesInfoButton({
  series,
  size = 18,
  color = main.colors.neutral.black,
  ...props
}: SeriesInfoButtonProps): JSX.Element {
  const contextPatient = usePatientContext();
  const cachedStudy = useStudyFromCache({ studyId: series.studyId });
  const currentProjectId = useProjectId();

  const [insertViewOnlySeries, { loading: insertLoading }] = useInsertViewOnlySeries();
  const [updateViewOnlySeries, { loading: deleteLoading }] = useDeleteViewOnlySeries();

  const patient = props?.patient ?? contextPatient;
  const study = props?.study ?? cachedStudy;
  const viewOnlySeries = series.viewOnly.find(({ projectId }) => projectId === currentProjectId);
  const viewOnly = viewOnlySeries?.enabled;

  const [showSeriesInfoDialog, { dialog }] = useOkForm({
    title: `Series Info - ${series.seriesDescription}`,
    shouldCloseOnEsc: true,
    shouldCloseOnOverlayClick: true,
    message: (
      <List>
        <StyledListItem>
          <InputBox name="Subject DICOM ID">
            <>{patient?.patientDicomId ?? "(Not available)"}</>
          </InputBox>
          {!!patient?.patientDicomId && <CopyButton text={patient?.patientDicomId} />}
        </StyledListItem>
        <StyledListItem>
          <InputBox name="Series description">
            <>{series.seriesDescription}</>
          </InputBox>
          <CopyButton text={series.seriesDescription} />
        </StyledListItem>
        <StyledListItem>
          <InputBox name="Series instance UID">
            <>{series.seriesInstanceUid}</>
          </InputBox>
          <CopyButton text={series.seriesInstanceUid} />
        </StyledListItem>
        <StyledListItem>
          <InputBox name="Study instance UID">
            <>{study?.studyInstanceUid ?? "(Not available)"}</>
          </InputBox>
          {!!study?.studyInstanceUid && <CopyButton text={study.studyInstanceUid} />}
        </StyledListItem>
        <StyledListItem>
          <InputBox name="Internal series ID">
            <>{series.id}</>
          </InputBox>
          <CopyButton text={`${series.id}`} />
          <InputBox name="Internal study ID">
            <>{study?.id}</>
          </InputBox>
          <CopyButton text={`${study?.id}`} />
          <CheckboxLabelWrapper>View Only:</CheckboxLabelWrapper>
          <ToggleSwitch
            title={"View Only"}
            checked={viewOnly ?? false}
            disabled={insertLoading || deleteLoading}
            onChange={() => {
              if (viewOnly === undefined) {
                insertViewOnlySeries({
                  variables: {
                    projectId: currentProjectId,
                    seriesId: series.id,
                  },
                });
              } else {
                updateViewOnlySeries({
                  variables: {
                    projectId: currentProjectId,
                    seriesId: series.id,
                  },
                });
              }
            }}
          />
        </StyledListItem>
      </List>
    ),
    maxWidth: "80vw",
    width: "fit-content",
  });

  const handleClick = () => {
    showSeriesInfoDialog(true);
  };

  return (
    <>
      {dialog}
      <Tooltip title={"Series Info"}>
        <IconButton onClick={handleClick}>
          <InfoIcon size={size} color={color} />
        </IconButton>
      </Tooltip>
    </>
  );
}
