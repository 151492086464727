import { OptionType } from "../../DataManagement/Upload/components/GenericMultiSelect";

export type CohortType = {
  id: number;
  project_id: number | null;
  name: string;
  metadata: {
    color: string;
  };
};

export function getCohortSelectOption(cohort: CohortType): OptionType<CohortType> {
  const { name } = cohort;
  return {
    value: cohort,
    label: name,
  };
}
