import { ProjectArmFilters } from "../../types/ProjectViewFilterTypes";
import { RootState } from "../store";
import { ProjectFilterSelectors } from "./ProjectFilterSelectors";

export const defaultProjectArmsState = {
  0: true,
  1: true,
};

export const clearProjectArmsState = {
  0: false,
  1: false,
};

export const projectArmFilters = new ProjectFilterSelectors<ProjectArmFilters>(
  defaultProjectArmsState,
  clearProjectArmsState,
  (state: RootState) => state.projectViewFilters.projectArm
);
