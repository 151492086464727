import { ContourDiametersType } from "../../../../../../types/ContourDiametersType";
import { ContourPointToolDataType } from "../../../../../../types/ContourPointToolDataType";

type Point = { x: number; y: number };

const intersects = (line1: Point[], line2: Point[]): boolean => {
  const [{ x: a, y: b }, { x: c, y: d }] = line1;
  const [{ x: p, y: q }, { x: r, y: s }] = line2;

  const det = (c - a) * (s - q) - (d - b) * (r - p);

  if (det === 0) return false;
  else {
    const lambda = ((p - a) * (s - q) - (q - b) * (r - p)) / det;
    const gamma = ((p - a) * (d - b) - (q - b) * (c - a)) / det;
    return 0 < lambda && lambda < 1 && 0 < gamma && gamma < 1;
  }
};

const getIntersection = (line1: Point[], line2: Point[]): Point | undefined => {
  const [{ x: a, y: b }, { x: c, y: d }] = line1;
  const [{ x: p, y: q }, { x: r, y: s }] = line2;

  const det = (c - a) * (s - q) - (d - b) * (r - p);

  if (det === 0) return undefined;
  else {
    const lambda = ((p - a) * (s - q) - (q - b) * (r - p)) / det;
    return { x: a + lambda * (c - a), y: b + lambda * (d - b) };
  }
};

const findPerpendicular = (
  edges: Point[][],
  longest: { currLength: Point[] },
  rowPixelSpacing: number | undefined,
  columnPixelSpacing: number | undefined
) => {
  if (!rowPixelSpacing) {
    rowPixelSpacing = 1;
  }

  if (!columnPixelSpacing) {
    columnPixelSpacing = 1;
  }

  const originalSlope =
    (longest.currLength[1].y - longest.currLength[0].y) /
    (longest.currLength[1].x - longest.currLength[0].x);
  const targetSlope = -1 / originalSlope;

  const candidateLengths = [];
  const xLength = Math.abs(longest.currLength[1].x - longest.currLength[0].x);
  const yLength = Math.abs(longest.currLength[1].y - longest.currLength[0].y);
  let point1: { x: number; y: number };
  let iterator: number;
  let target: number;
  let iteratorTarget: string;

  if (xLength > yLength) {
    point1 =
      longest.currLength[0].x < longest.currLength[1].x
        ? longest.currLength[0]
        : longest.currLength[1];
    iterator = point1.x;
    target = point1.x + xLength;
    iteratorTarget = "x";
  } else {
    point1 =
      longest.currLength[0].y < longest.currLength[1].y
        ? longest.currLength[0]
        : longest.currLength[1];
    iterator = point1.y;
    target = point1.y + yLength;
    iteratorTarget = "y";
  }

  // Continuously slice our contours with perpendicular lines, checking that we have only two intersections
  // Take the one that is the longest
  while (iterator <= target) {
    const currPoint: Point =
      iteratorTarget === "x"
        ? { x: iterator, y: point1.y + originalSlope * (iterator - point1.x) }
        : {
            x: point1.x + (iterator - point1.y) / originalSlope,
            y: iterator,
          };
    const currLength: Point[] =
      targetSlope < 0
        ? [
            { x: 0, y: targetSlope * (0 - currPoint.x) + currPoint.y },
            { x: (0 - currPoint.y) / targetSlope + currPoint.x, y: 0 },
          ]
        : [
            { x: 0, y: targetSlope * (0 - currPoint.x) + currPoint.y },
            { x: (512 - currPoint.y) / targetSlope + currPoint.x, y: 512 },
          ];

    const intersectingLengths: Point[][] = [];
    for (const edge of edges) {
      if (intersects(edge, currLength)) {
        intersectingLengths.push(edge);
        if (intersectingLengths.length > 2) break;
      }
    }
    if (intersectingLengths.length === 2) {
      // We now have two edges that are at the ends of our perpendicular line
      const pointA = getIntersection(currLength, intersectingLengths[0]);
      const pointB = getIntersection(currLength, intersectingLengths[1]);
      if (pointA === undefined || pointB === undefined) continue;
      const length = [pointA, pointB];
      candidateLengths.push({
        length,
        distance: Math.sqrt(
          Math.pow((pointB.x - pointA.x) * rowPixelSpacing, 2) +
            Math.pow((pointB.y - pointA.y) * columnPixelSpacing, 2)
        ),
      });
    }
    iterator += 0.05;
  }
  return candidateLengths.reduce((currMax, length) => {
    return length.distance > currMax.distance ? length : currMax;
  });
};

const findContourDiametersInternal = (
  points: Pick<ContourPointToolDataType, "x" | "y" | "lines">[],
  rowPixelSpacing: number | undefined,
  columnPixelSpacing: number | undefined
): ContourDiametersType | undefined => {
  if (!rowPixelSpacing) {
    rowPixelSpacing = 1;
  }

  if (!columnPixelSpacing) {
    columnPixelSpacing = 1;
  }

  const edges: Point[][] = [];
  const lengths = [];
  points.forEach((point) => {
    edges.push([
      { x: point.x, y: point.y },
      { x: point.lines[0].x, y: point.lines[0].y },
    ]);
  });

  // Double for loop for all other points
  // Check that it is not a neighbouring vertex
  for (let i = 0; i < points.length; ++i) {
    for (let j = i + 2; j < points.length; ++j) {
      if (i === 0 && j === points.length - 1) continue;
      const currLength = [
        { x: points[i].x, y: points[i].y },
        { x: points[j].x, y: points[j].y },
      ];
      // Otherwise, now we check that this line does not intersect with any previous edge
      if (
        edges.every((edge) => {
          return !intersects(edge, currLength);
        })
      ) {
        // If no intersections, good to push this back
        // Exception is if this specific length is completely outside of the polygon
        // Check the slopes to make sure is actually going into the polygon
        lengths.push({
          currLength,
          distance: Math.sqrt(
            Math.pow((currLength[1].x - currLength[0].x) * rowPixelSpacing, 2) +
              Math.pow((currLength[1].y - currLength[0].y) * columnPixelSpacing, 2)
          ),
        });
      }
    }
  }

  if (lengths.length === 0) {
    return undefined;
  }
  const LAD = lengths.reduce((currMax, length) => {
    return length.distance > currMax.distance ? length : currMax;
  });

  const [LADStart, LADEnd] = LAD.currLength;

  const SAD = findPerpendicular(edges, LAD, rowPixelSpacing, columnPixelSpacing);

  const [SADStart, SADEnd] = SAD.length;

  return {
    LAD: LAD.distance,
    LADPoints: { start: LADStart, end: LADEnd },
    SAD: SAD.distance,
    SADPoints: { start: SADStart, end: SADEnd },
  };
};

export const findContourDiameters = (
  points: Pick<ContourPointToolDataType, "x" | "y" | "lines">[],
  rowPixelSpacing: number | undefined,
  columnPixelSpacing: number | undefined
): ContourDiametersType | undefined => {
  let result: ContourDiametersType | undefined = undefined;
  try {
    result = findContourDiametersInternal(points, rowPixelSpacing, columnPixelSpacing);
  } catch (e) {
    console.error(e);
  }
  return result;
};
