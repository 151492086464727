import React, { FC } from "react";

import { Card } from "../../../common/components/cards/Card";
import {
  LinkToRouteProps,
  useProjectPageLinkHandler,
} from "../../../Project/Layout/hooks/useProjectPageLinkHandler";
import { KaplanChartContainer } from "./KaplanChartContainer";

type KaplanMeierCurveCardProps = LinkToRouteProps;

export const KaplanMeierCurveCard: FC<KaplanMeierCurveCardProps> = ({
  linkToRoute,
}: KaplanMeierCurveCardProps) => {
  const handleGoToProjectPageLink = useProjectPageLinkHandler(linkToRoute);
  return (
    <Card
      title={"Kaplan Meier"}
      content={<KaplanChartContainer />}
      onClickHeader={handleGoToProjectPageLink}
    />
  );
};
