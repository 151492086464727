import React, { forwardRef, useEffect } from "react";
import { TableToggleAllRowsSelectedProps } from "react-table";
import styled from "styled-components";

const Checkbox = styled.input`
  accent-color: ${({ theme }) => theme.colors.neutral.black};
`;

export const IndeterminateCheckbox = forwardRef<HTMLInputElement, TableToggleAllRowsSelectedProps>(
  ({ indeterminate, ...rest }: TableToggleAllRowsSelectedProps, ref) => {
    const defaultRef = React.useRef<HTMLInputElement>(null);
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      // @ts-ignore
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <Checkbox type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

IndeterminateCheckbox.displayName = "IndeterminateCheckbox";
