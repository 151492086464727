/**
 * Checks if the given value is a valid primary key. Valid primary keys are
 * non-negative integers greater than 0.
 * @param value The value to check.
 * @returns True if the value is a valid primary key, false otherwise.
 */
export const isValidPrimaryKey = (value: unknown): boolean => {
  return typeof value === "number" && value > 0;
};

/**
 * Checks if the given values are all valid primary keys. If one or more values
 * are not valid primary keys, this function will return false. Otherwise, it
 * will return true.
 * @param values
 * @returns True if all values are valid primary keys, false otherwise.
 */
export const areAllValidPrimaryKeys = (values: unknown[]): boolean => {
  return values.every(isValidPrimaryKey);
};
