import React from "react";

import { main } from "../../../../../../common/theme/main";
import { SeriesListItemFragmentType } from "../hooks/useStudiesList";
import { TimelineItem } from "./TimelineItem";
import { TimelineProgress, TimelineProgressProps } from "./TimelineProgress";

interface ViewerTimelineItemProps extends TimelineProgressProps {
  background?: string;
  seriesList: SeriesListItemFragmentType[];
  children: JSX.Element;
}

export function ViewerTimelineItem({
  background,
  children,
  ...timelineItemProps
}: ViewerTimelineItemProps): JSX.Element {
  const actualBackground = background ?? main.colors.background.secondary;
  return (
    <TimelineItem
      {...timelineItemProps}
      background={actualBackground}
      progressBar={<TimelineProgress {...timelineItemProps} />}
    >
      {children}
    </TimelineItem>
  );
}
