import {
  ERROR,
  SUCCESS,
  TaskStatusType,
  WARNING,
} from "../../../../../../../common/types/StatusTypes";
import { AnyTaskResult } from "./types/SaveCheckResultType";

// FIXME: nearly a identical functionality as 'getAnnotationTaskStatus' but considerably different
// implementation that depends on status being set in order to determine partial success, and does
// not check for 'required'.
export function getOverallTaskStatus(results: AnyTaskResult<unknown>[]): TaskStatusType {
  const allStatus = [...new Set(results.map(({ status }) => status))];
  if (allStatus.includes(ERROR)) {
    return ERROR;
  }
  if (allStatus.includes(WARNING)) {
    return WARNING;
  }
  return SUCCESS;
}
