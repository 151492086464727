import React, { useState } from "react";
import styled from "styled-components";

import { OK_CANCEL } from "../../../../common/components/Dialog/DialogActionButtonsType";
import { useDialog } from "../../../../common/components/Dialog/useDialog";
import { SelectLabel } from "../../../../common/components/input/Label";
import { GenericSingleSelect } from "../../../../DataManagement/Upload/components/GenericSingleSelect";
import {
  taskProgressDisplayNames,
  TaskProgressType,
  TaskProgressTypeArray,
} from "../../TaskWorklist/TaskProgressType";
import { useUpdateTaskAssignmentsStatus } from "../hooks/useUpdateTaskAssignmentsStatus";
import { PatientTaskTableRowType } from "../PatientTable/types/PatientTaskTableRowType";

const StatusModalWrapper = styled.div`
  width: 500px;
`;

const UnassignedPatientErrorWrapper = styled.div`
  color: ${({ theme }) => theme.colors.states.error};
  font-size: 12px;
`;

export type StatusOptionType = {
  label: string;
  value: string;
};

export function useUpdateTaskAssignmentsStatusDialog(
  selectedPatientRows: PatientTaskTableRowType[]
) {
  const [updateTaskAssignmentsStatus] = useUpdateTaskAssignmentsStatus();

  const statuses: StatusOptionType[] = TaskProgressTypeArray.map((value): StatusOptionType => {
    return { label: taskProgressDisplayNames[value], value: value };
  });
  const [selectedValue, setSelectedValue] = useState<StatusOptionType>(statuses[0]);

  const unassignedUsers = selectedPatientRows.filter(
    (row) => row.task?.taskAssignments.length === 0
  ).length;
  const taskAssignmentIds = selectedPatientRows.flatMap((row) =>
    row.task ? row.task.taskAssignments.map(({ id }) => id) : []
  );
  return useDialog({
    label: `Change Status of Task Assignment${selectedPatientRows.length > 1 ? "s" : ""}`,
    content: (
      <StatusModalWrapper>
        <SelectLabel>Select a Status</SelectLabel>
        <GenericSingleSelect
          values={statuses}
          defaultValue={{
            label: selectedValue.label,
            value: selectedValue,
          }}
          selected={selectedValue}
          onSelectedChanged={(e) => {
            setSelectedValue(e ?? statuses[0]);
          }}
          getOption={(status: StatusOptionType) => ({
            label: status.label,
            value: status,
          })}
          menuPortalTarget={document.body}
        />
        {unassignedUsers > 0 && (
          <UnassignedPatientErrorWrapper>
            {unassignedUsers} selected subject(s) have not yet been assigned to a user, and will
            therefore not have their status changed. In order to change their status, please assign
            them to a user first.
          </UnassignedPatientErrorWrapper>
        )}
      </StatusModalWrapper>
    ),
    options: {
      actionButtonOptions: {
        type: OK_CANCEL,
        okText: "Change",
        onOkOverride: () =>
          updateTaskAssignmentsStatus({
            variables: { taskAssignmentIds, status: selectedValue.value as TaskProgressType },
          }),
      },
    },
  });
}
