import React from "react";
import styled from "styled-components";

import { FlexLoading } from "../../../common/components/Loading";

const LoadingOverlay = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
`;

interface ChartLoadingWrapperProps {
  loading: boolean;
  data: unknown[];
  children: JSX.Element;
}

export function ChartLoadingWrapper({
  loading,
  data,
  children,
}: ChartLoadingWrapperProps): JSX.Element {
  return loading || data.length === 0 ? (
    <LoadingOverlay>{loading ? <FlexLoading /> : <>No data to display</>}</LoadingOverlay>
  ) : (
    children
  );
}
