import { useFindPageByRoute } from "../hooks/useFindPageByRoute";
import { useGoToRoute } from "../hooks/useGoToRoute";
import { useLayout } from "../hooks/useLayout";
import { ANNOTATE_WORKLIST } from "../RoutePathType";
import { getPageRoute } from "../utils/getPageRoute";

type ReturnType = () => void;

export function useGoToWorklist(): ReturnType {
  const layout = useLayout();

  const goToProjectPath = useGoToRoute();
  const findPageByRoute = useFindPageByRoute();

  return () => {
    const { project } = layout;

    const workListPage = findPageByRoute(project, ANNOTATE_WORKLIST);

    if (!workListPage) {
      throw new Error("Unable to find worklist page in useGoToWorklist");
    }

    const route = getPageRoute(workListPage);
    goToProjectPath(route);
  };
}
