import styled from "styled-components";

import { getInitials, UserAvatarType } from "../../../types/UserType";
import { getPixelsFromSize } from "../../icons/utils/getPixelsFromSize";
import { stringToColor } from "./stringToColor";

const Text = styled.div<{
  fontSize: string;
  color?: string;
  textBrightness?: number;
}>`
  font-style: normal;
  font-size: ${(props) => props.fontSize};
  line-height: 20px;
  color: ${(props) => props?.color};
  filter: brightness(${(props) => props.textBrightness ?? 100}%);
`;

export interface StringAvatarProps {
  user: UserAvatarType;
  size: number;
  textColor?: string;
  background?: string;
  fontSizeMultiplier?: number;
  textBrightness?: number;
}

export function stringAvatar({
  user,
  size,
  textColor,
  background,
  fontSizeMultiplier,
  textBrightness,
}: StringAvatarProps): {
  style: { backgroundColor: string; width: string; height: string };
  children: JSX.Element;
} {
  const initials = getInitials(user);
  const fontSize = getPixelsFromSize(Math.round(size * (fontSizeMultiplier ?? 0.4)));
  const sizePixels = getPixelsFromSize(size);
  const backgroundColor = background ?? stringToColor(initials);

  return {
    style: {
      backgroundColor,
      width: sizePixels,
      height: sizePixels,
    },
    children: (
      <Text fontSize={fontSize} color={textColor} textBrightness={textBrightness}>
        {initials}
      </Text>
    ),
  };
}
