import { useTimepoints } from "./Timepoint/useTimepoints";

type ReturnType = (seriesId: number) => boolean;

export function useIsSeriesIdBaseline(): ReturnType {
  const { timepoints } = useTimepoints();

  return (seriesId) => {
    if (!timepoints) {
      throw new Error("Timepoints not set in useIsSeriesIdBaseline");
    }

    const timepoint = timepoints.find(({ series }) => series.some(({ id }) => id === seriesId));
    if (!timepoint) {
      throw new Error(`Unable to find timepoint containing series with id ${seriesId}`);
    }
    const { index } = timepoint;
    return index === 0;
  };
}
