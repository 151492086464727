import { gql } from "@apollo/client";

export const SERIES_CLASSIFICATION_FRAGMENT = gql`
  fragment SeriesClassification on series_classification {
    id
    seriesId: series_id
    taskId: task_id
    userId: user_id
    classification
  }
`;
