import { ASSIGN, BiopsyAssignmentActionType, CHANGE, UNASSIGN } from "./BiopsyAssignmentActionType";
import { BiopsyAssignmentFormData } from "./BiopsyAssignmentFormData";

export const formContents: Record<BiopsyAssignmentActionType, BiopsyAssignmentFormData> = {
  [UNASSIGN]: {
    title: "Unassign Biopsy Results from Lesion",
    message: "Please confirm that you would like to unassign the biopsy results from this lesion.",
    showMaybe: false,
  },
  [ASSIGN]: {
    title: "Link to Histology",
    message: "Is this the primary tumor that was biopsied?",
    showMaybe: false,
  },
  [CHANGE]: {
    title: "Re-assign Biopsy Results to Lesion",
    message:
      "Please confirm that you would like to re-assign the biopsy. Is this the primary tumor that was biopsied?",
    showMaybe: false,
  },
};
