import { SubjectType } from "../../Analysis/common/types/SubjectType";
import { FOLLOW_UP_TEXT } from "../../Analysis/common/utils/FollowUpText";
import { getFollowUpDiametricTumourBurden } from "../../Analysis/common/utils/getFollowUpDiametricTumourBurden";
import { getFollowUpMortalityRisk } from "../../Analysis/common/utils/getFollowUpMortalityRisk";
import { getFollowUpSurvivalPrediction } from "../../Analysis/common/utils/getFollowUpSurvivalPrediction";
import { getFollowUpTumourResponse } from "../../Analysis/common/utils/getFollowUpTumourResponse";
import { sortFollowUps } from "../../Analysis/common/utils/sortFollowUps";
import { getFormattedMMMDDYYYY } from "./dateFormatUtils/getFormattedDate";

export default function getCsvData(subjects: SubjectType[]): string[][] {
  const naString = "N/A";
  const csvData: string[][] = [];
  csvData.push([
    "Subject ID",
    "Date of Birth",
    "Date of Death",
    "Randomization Date",
    "Sex",
    "Smoking Status",
    "Project Arm",
    "Mutation",
    "Histology",
    "AJCC Stage",
    "ECOG",
    `${FOLLOW_UP_TEXT} #`,
    `${FOLLOW_UP_TEXT} Date`,
    "Predicted Survival (Months)",
    "Mortality Risk (%)",
    "Tumor Burden (mm)",
    "Tumor Response Classification",
  ]);
  for (const subject of subjects) {
    const {
      subjectId,
      dateOfBirth,
      deathDate,
      randomizationDate,
      followUps,
      sex,
      smokingStatus,
      projectArm: { name: projectArmName },
      diagnosis: { ajccStage, histology, mutation },
      ecogPerformanceStatus,
    } = subject;

    const formattedDateOfBirth = dateOfBirth ? getFormattedMMMDDYYYY(dateOfBirth, false) : naString;
    const formattedDeathDate = deathDate ? getFormattedMMMDDYYYY(deathDate, false) : naString;
    const formattedRandomizationDate = randomizationDate
      ? getFormattedMMMDDYYYY(randomizationDate, false)
      : naString;

    const formattedSex = sex ?? naString;
    const formattedSmokingStatus = smokingStatus ?? naString;

    const formattedAjccStage = ajccStage ?? naString;
    const formattedHistology = histology ?? naString;
    const formattedMutation = mutation ?? naString;
    const formattedEcog = ecogPerformanceStatus ? ecogPerformanceStatus.toString() : naString;

    const sortedFollowUps = sortFollowUps(followUps, true);
    for (const followUp of sortedFollowUps) {
      const { order, date } = followUp;
      const survivalPrediction = getFollowUpSurvivalPrediction(followUp);
      const formattedPrediction = survivalPrediction ? survivalPrediction.toString() : naString;
      const mortalityRisk = getFollowUpMortalityRisk(followUp);
      const formattedMortality = mortalityRisk ? mortalityRisk.toFixed(1).toString() : naString;
      const burden = getFollowUpDiametricTumourBurden(followUp);
      const formattedBurden = burden ? burden.toString() : naString;
      const response = getFollowUpTumourResponse(followUp);
      const formattedResponse = response ?? naString;
      const formattedDate = date ? getFormattedMMMDDYYYY(date, false) : naString;

      csvData.push([
        subjectId,
        formattedDateOfBirth,
        formattedDeathDate,
        formattedRandomizationDate,
        formattedSex,
        formattedSmokingStatus,
        projectArmName,
        formattedMutation,
        formattedHistology,
        formattedAjccStage,
        formattedEcog,
        order.toString(),
        formattedDate,
        formattedPrediction,
        formattedMortality,
        formattedBurden,
        formattedResponse,
      ]);
    }
  }
  return csvData;
}
