import React, { useCallback } from "react";

import { useJumpToViewerSeries } from "./useJumpToViewerSeries";
import { SeriesListItemFragmentType } from "./useStudiesList";

export type OnSeriesClickedReturnType = (
  e?: React.MouseEvent<Element, MouseEvent>,
  series?: SeriesListItemFragmentType | undefined
) => void;

export function useOnSeriesClicked(): OnSeriesClickedReturnType {
  const jumpToViewerSeries = useJumpToViewerSeries();

  return useCallback(
    (
      e?: React.MouseEvent<Element, MouseEvent>,
      series?: SeriesListItemFragmentType | undefined
    ) => {
      e?.stopPropagation();

      if (!series) {
        return;
      }

      jumpToViewerSeries(series);
    },
    [
      // TODO: This exhaustive list of deps may contribute to unnecessary re-renders, but we don't want to break functionality. See #184924509
      // We should revisit in the next stage of the performance work and see if we can remove some of these deps.
      jumpToViewerSeries,
    ]
  );
}
