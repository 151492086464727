import { ApolloCache, gql } from "@apollo/client";

import { getSeriesCacheId } from "./getSeriesCacheId";
import { getStudyCacheId } from "./getStudyCacheId";

export function getSeriesFollowUpIdFromCache<T>(
  seriesId: number,
  cache: ApolloCache<T>
): number | undefined {
  const seriesFragment = cache.readFragment<{ studyId: number }>({
    id: getSeriesCacheId(seriesId, cache),
    fragment: gql`
      fragment SeriesFragment on series {
        studyId: study_id
      }
    `,
  });

  if (!seriesFragment) {
    return undefined;
  }

  const { studyId } = seriesFragment;
  const studyFragment = cache.readFragment<{
    followUps: { followUp: { id: number; order: number } }[];
  }>({
    id: getStudyCacheId(studyId, cache),
    fragment: gql`
      fragment StudyFragment on study {
        followUps: study_follow_up {
          followUp: follow_up {
            id
            order: follow_up_order
          }
        }
      }
    `,
  });

  if (!studyFragment) {
    return undefined;
  }

  const { followUps } = studyFragment;
  if (followUps.length !== 1) {
    throw new Error(`One or more than one study follow ups was found for study with id ${studyId}`);
  }

  const {
    followUp: { id },
  } = followUps[0];
  return id;
}
