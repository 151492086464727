import labelmapProvider from "./initLabelmapProvider";

export default function getIsolatedSegmentToolData(seriesId, labelmapId, toolData) {
  let modifiedtoolData = [];
  let labelmapSegment = labelmapProvider.getSegmentIdFromLabelmapId(seriesId, labelmapId);
  for (let j = 0; j < toolData.length; j++) {
    let isolatedSegmentToolData = toolData[j] & labelmapSegment;
    if (isolatedSegmentToolData === labelmapSegment) {
      modifiedtoolData[j] = 1;
    } else {
      modifiedtoolData[j] = 0;
    }
  }
  return modifiedtoolData;
}
