import { BaseWadoUrlFragmentType } from "../fragments/BaseWadoUrlFragmentType";

export function parseBaseWadoUrl(wadoUrls: BaseWadoUrlFragmentType): string {
  if (wadoUrls.length === 0) {
    throw new Error("No wadoUrls are defined. Cannot create wado url for sop instance");
  }

  const { wadoUrl: baseWadoUrl } = wadoUrls[0];
  return baseWadoUrl;
}
