import { Reference, useMutation, useQuery } from "@apollo/client";
import { TaskContext } from "nota-predict-web/src/Annotate/TaskContext";
import { areAllValidPrimaryKeys } from "nota-predict-web/src/common/utils/isValidPrimaryKey";
import { usePrevious } from "nota-predict-web/src/common/utils/usePrevious";
import { useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from "react";
import isEqual from "react-fast-compare";
import { useForm } from "react-hook-form";
import { useDebouncedCallback } from "use-debounce";
import { useDeepCompareEffect, useDeepCompareMemo } from "use-deep-compare";

import { useSelectedTaskAssignment } from "../../../../../useSelectedTaskAssignment";
import { TASK_COMPLETED } from "../../../../TaskWorklist/TaskProgressType";
import { OptionsQcRule } from "../../../utils/qc-validation/schema/option";
import { QcSchema } from "../../../utils/qc-validation/schema/ruleSet";
import { useGetActiveSeries } from "../hooks/useGetActiveSeries";
import {
  compareQcFormValues,
  extractDefaultValues,
  flatQcFormResults,
  getIsIgnoredSeries,
  makeQcReportKey,
  mergeQcFormValues,
  parseQcReportKey,
  qc_debugLog,
  rulesSetForScope,
  scopeIdKey,
} from "./QcForm.utils";
import { useQualityControlContext } from "./QualityControlProvider";
import {
  CREATE_QC_REPORT,
  CREATE_QC_REPORT_UNIQUE_CONSTRAINTS,
  GET_STORED_QC_REPORTS_FOR_PATIENT,
  GetStoredQcReportsForPatientVariables,
  UPDATE_QC_REPORT,
  UPDATE_QC_REPORT_STATUS,
} from "./QualityControlProvider.queries";
import type {
  CreateQcReportVariables,
  QcFormData,
  QcFormResults,
  QcFormScope,
  QcFormStateUpdatePayload,
  QcFormValue,
  QcReportKey,
  QcReportRow,
  UpdateQcReportValidityVariables,
  UpdateQcReportVariables,
} from "./QualityControlProvider.types";

const GET_STORED_REPORT_POLL_INTERVAL = 10000;
const DEBOUNCE_SAVE_TIMEOUT = 1000;

/**
 * This hook is used to interface QC form with db data via the QualityControlProvider context, it is not meant to be used directly.
 * Instead, use the `useQualityControlContext` hook to access the state.
 * @internal see useQualityControlContext
 */
const useGetQcReportsForActivePatient = ({
  activeReportKey,
  onReportsChanged,
}: {
  activeReportKey: string | null;
  onReportsChanged?: (reports: QcReportRow[]) => void;
}) => {
  const { task: currentTask, isQc } = useContext(TaskContext);
  const activeSeries = useGetActiveSeries();
  const activePatientId = activeSeries?.study?.patientId ?? -1;
  const currentSchemaId = currentTask?.qcSchemaId ?? -1;
  const pollInverval = useRef<number>(1000);

  const variables: GetStoredQcReportsForPatientVariables = {
    qcSchemaId: currentSchemaId,
    patientId: activePatientId,
  };

  const shouldSkip =
    !isQc ||
    !areAllValidPrimaryKeys([currentTask?.id, currentSchemaId, activePatientId]) ||
    !activeReportKey;

  const { data, error, refetch } = useQuery<{
    qcReports: QcReportRow[];
  }>(GET_STORED_QC_REPORTS_FOR_PATIENT, {
    variables,
    pollInterval: shouldSkip ? undefined : pollInverval.current,
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    skip: shouldSkip,
    onError(error) {
      throw error;
    },
    onCompleted(data) {
      if (data?.qcReports?.length) {
        qc_debugLog("Found stored reports for current patient:", data?.qcReports);
        pollInverval.current = GET_STORED_REPORT_POLL_INTERVAL;
      }
    },
  });

  const isReady = !!data;
  const findReportForKey = useCallback(
    (reportKey: string | null) => {
      if (!reportKey) {
        return;
      }
      const [scope, foreignKeyId] = parseQcReportKey(reportKey);
      return scope
        ? data?.qcReports?.find((report) => report[scopeIdKey(scope)] === foreignKeyId)
        : undefined;
    },
    [data?.qcReports]
  );

  const storedQcReport = findReportForKey(activeReportKey);

  useEffect(() => {
    // If the active report changes, reset the poll interval to 250ms so we can get the latest data
    pollInverval.current = 250;
  }, [activeReportKey]);

  useDeepCompareEffect(() => {
    onReportsChanged?.(data?.qcReports ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.qcReports]);

  useEffect(() => {
    qc_debugLog("Active subject changed, refetching stored reports", {
      activePatientId,
      variables,
    });
    // TODO: not awaiting this intentionally (fire + forget)
    // Will add an eslint disable for this line when NOTA-695 is merged
    refetch(variables);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePatientId]);

  return {
    storedQcReport,
    isReady,
    error,
    findReportForKey,
    allReports: data?.qcReports ?? [],
    refetch,
  };
};

const useCreateQcReport = ({
  scope,
  foreignKeyId,
  onCreated,
}: {
  scope: QcFormScope;
  foreignKeyId: number | null;
  onCreated?: (newReport: QcReportRow) => void;
}) => {
  const {
    task: { qcSchemaId },
    viewOnly,
  } = useContext(TaskContext);
  const { progress } = useSelectedTaskAssignment();

  const { storedQcReport, schema } = useQualityControlContext();
  const rules = rulesSetForScope<OptionsQcRule[]>(scope, schema);

  const defaultFormValues: QcFormResults = extractDefaultValues(rules);

  const newReport: CreateQcReportVariables = useDeepCompareMemo(
    () => ({
      qcSchemaId: qcSchemaId ?? -1,
      scope,
      defaultValues: defaultFormValues,
      [`${scopeIdKey(scope)}`]: foreignKeyId,
      uniqueConstraint: CREATE_QC_REPORT_UNIQUE_CONSTRAINTS[scope],
    }),
    [qcSchemaId, scope, defaultFormValues, foreignKeyId]
  );

  const optimisticResponse = {
    qcReport: {
      qcSchemaId: qcSchemaId,
      scope,
      result: {},
      isValid: false,
      [`${scopeIdKey(scope)}`]: foreignKeyId,
    } as QcReportRow,
  };

  const [createQcReport] = useMutation<
    {
      qcReport: QcReportRow;
    },
    CreateQcReportVariables
  >(CREATE_QC_REPORT, {
    optimisticResponse,
    onCompleted(data) {
      if (data?.qcReport?.id) {
        qc_debugLog("QC report was successfully created", data?.qcReport);
        onCreated?.(data?.qcReport);
      }
    },
    onError(error) {
      throw error;
    },
  });

  const debouncedCreateQcReport = useDebouncedCallback(createQcReport, DEBOUNCE_SAVE_TIMEOUT, {
    leading: true,
  });

  return useCallback(async () => {
    if (!qcSchemaId || !scope || !foreignKeyId || schema) {
      // can't create a task without a schema ID, task ID, scope, and report key
      // qc_debugLog("Not creating a QC report because we don't have all the required data yet:", {
      //   qcSchemaId,
      //   scope,
      //   foreignKeyId,
      //   schemaRow,
      // });
      return;
    }

    if (viewOnly) {
      qc_debugLog("Not creating a new report because the task is view-only");
      return;
    }

    if (storedQcReport?.id) {
      // Last stored state exists, not creating a new report
      qc_debugLog("Not creating a QC report because one already exists:", storedQcReport);
      return;
    }

    if (progress === TASK_COMPLETED) {
      // It has been decided that we can create reports for read only QC tasks, as long as the task is not complete
      return;
    }

    // qc_debugLog("Need to create a new report because none exists yet", {
    //   foreignKeyId,
    //   storedQcReport,
    //   allReports: allStoredReports,
    // });

    await debouncedCreateQcReport({
      variables: newReport,
    });
  }, [
    qcSchemaId,
    scope,
    foreignKeyId,
    schema,
    viewOnly,
    storedQcReport,
    progress,
    debouncedCreateQcReport,
    newReport,
  ]);
};

const useUpdateQcReport = ({
  onUpdated,
}: { onUpdated?: (updatedReport: QcReportRow | null) => void } = {}) => {
  const { viewOnly } = useContext(TaskContext);
  const lastPayloadSent = useRef<UpdateQcReportVariables | null>(null);
  const [updateQcReport] = useMutation<
    {
      qcReport: QcReportRow;
    },
    UpdateQcReportVariables
  >(UPDATE_QC_REPORT, {
    onCompleted(data) {
      qc_debugLog("QC report finished saving", data?.qcReport);
      if (data?.qcReport) {
        onUpdated?.(data?.qcReport);
      }
    },
    onError(error) {
      throw error;
    },
  });

  return useCallback(
    async ({
      qcReportId: id,
      reportKey,
      updatedFormValues,
      ignoreViewOnly = false,
    }: {
      qcReportId: number;
      reportKey: string;
      updatedFormValues: QcFormResults;
      ignoreViewOnly?: boolean;
    }) => {
      if (id && updatedFormValues) {
        if (isEqual(lastPayloadSent.current, { id, changes: updatedFormValues })) {
          qc_debugLog("Payload is the same as the last one sent (no-op)");
        }

        if (viewOnly) {
          // It has been decided that QC reports can not be updated when the task is read-only
          // unless the `ignoreViewOnly` flag is set to true
          if (!ignoreViewOnly) {
            qc_debugLog(
              "Not sending update because the task is view-only (to force an update, set 'ignoreViewOnly'=true)"
            );
            // Always call onUpdated when the task is view-only, with a null payload even if we don't send an update
            onUpdated?.(null);
            return;
          }
          qc_debugLog("Task is view-only, but `ignoreViewOnly` is true, forcing update");
        }

        // strip computed_values from the payload, they are write-only
        const changes = Object.fromEntries(
          Object.entries(updatedFormValues).filter(([key]) => !key.startsWith("computed_"))
        );

        const computedValues = Object.fromEntries(
          Object.entries(updatedFormValues).filter(([key]) => key.startsWith("computed_"))
        );

        if (Object.keys(computedValues).length) {
          qc_debugLog(
            "Computed values were found in pending update, removing because computed_values are read-only",
            {
              reportKey,
              qcReportId: id,
              computedValues,
            }
          );
        }

        lastPayloadSent.current = { id, changes };
        qc_debugLog("Saving form with new values", { reportKey, qcReportId: id, changes });
        await updateQcReport({
          variables: {
            id,
            changes,
          },
        });
      }
    },
    [onUpdated, updateQcReport, viewOnly]
  );
};

export const useSaveQcReportStatus = () => {
  const [updateStatus] = useMutation<
    {
      qcReport: Omit<QcReportRow, "result">;
    },
    UpdateQcReportValidityVariables
  >(UPDATE_QC_REPORT_STATUS, {
    update(cache, { data }) {
      if (data?.qcReport) {
        qc_debugLog("Updated QC report status, updating cache", data?.qcReport);
        const { qcReport } = data;
        const id = cache.identify({
          id: qcReport.id,
          __typename: "qc_report",
        });

        cache.modify({
          fields: {
            // update qcReports to set the isValid property on the report
            qcReports(existingReports, { toReference }) {
              const newReportRef = toReference(qcReport);
              return existingReports.map((reportRef: Reference) => {
                if (reportRef.__ref === id) {
                  return newReportRef;
                }
                return reportRef;
              });
            },
          },
        });
      }
    },

    onError(error) {
      throw error;
    },
  });

  return useCallback(
    async ({ qcReportId: id, isFormValid }: { qcReportId?: number; isFormValid: boolean }) => {
      if (id) {
        await updateStatus({
          variables: {
            id,
            isFormValid,
          },
        });
      }
    },
    [updateStatus]
  );
};

/**
 * This hook is used to observe the state of the QC form's isValid state. It can be used for either the active form or a stored report.
 * @param isValid The isValid state to observe
 * @param qc_report The report in question to observe
 * @param onChange A callback to be called when the isValid state changes.
 */
export const useFormStateIsValidChanged = (
  isValid: boolean | undefined,
  qc_report: QcReportRow | undefined,
  onChange: (...args: unknown[]) => void
) => {
  useEffect(() => {
    onChange();
    // PRAGMA: - Only call onChange when isValid changes, not when the callback changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid, qc_report?.id]);
};

/**
 * This hook is used to observe the state of the QC form changing. It can be used for either the active form or a stored report.
 * @param reportKey The report key to observe
 * @param reportId The report ID to observe
 * @param onChange A callback to be called when the stored report ID changes.
 */
export const useActiveQcReportChanged = (
  reportKey: string | null | undefined,
  reportId: number | null | undefined,
  onChange?: (...args: unknown[]) => void
): void => {
  useEffect(() => {
    onChange?.();
    // PRAGMA: - Only call onChange when key or id change, not when the callback changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportKey, reportId]);
};

/**
 * This hook is used to observe when the active series changes.
 * @param seriesId The series ID to observe
 * @param onChange A callback to be called when the series ID changes.
 */
export const useActiveSeriesChanged = (
  seriesId: number | null | undefined,
  onChange?: (...args: unknown[]) => void
): void => {
  useEffect(() => {
    onChange?.();
    // PRAGMA: - Only call onChange when seriesId changes, not when the callback changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seriesId]);
};

/**
 * This hook is used to track whether a specific QC form element has been updated from the stored report. It uses a ref to track the value,
 * so that it can be updated immediately and without triggering a re-render.
 * @returns A tuple containing the current value and a setter function
 */
export const useDidUpdateQcValueFromServer = (): [boolean, (value: boolean) => void] => {
  const _didUpdateFromStoredReport = useRef<boolean>(false);
  const didUpdateFromStoredReport = _didUpdateFromStoredReport.current;
  const setDidUpdateFromStoredReport = useCallback(
    (value: boolean) => {
      _didUpdateFromStoredReport.current = value;
    },
    [_didUpdateFromStoredReport]
  );
  return [didUpdateFromStoredReport, setDidUpdateFromStoredReport];
};

/**
 * This hook is used to manage the state of the QC form via the QualityControlProvider context, it is not meant to be used directly.
 * Instead, use the `useQualityControlContext` hook to access the state.
 * @internal see useQualityControlContext
 */
export const useQcFormState = ({ schema }: { schema?: QcSchema }) => {
  const { task: currentTask, viewOnly } = useContext(TaskContext);

  const isSavingState = useState(false);
  const [isSaving, setIsSaving] = isSavingState;

  const activeScopeState = useState<QcFormScope>("SERIES");
  const [activeScope] = activeScopeState;

  const activeSeries = useGetActiveSeries();
  const activePatientId = activeSeries?.study?.patientId ?? null;

  const activeForeignKeyId = useMemo(() => {
    switch (activeScope) {
      case "SERIES":
        return activeSeries?.id ?? null;
      case "STUDY":
        return activeSeries?.studyId ?? null;
      case "PATIENT":
        return activeSeries?.study?.patientId ?? null;
      default:
        return null;
    }
  }, [activeScope, activeSeries?.id, activeSeries?.studyId, activeSeries?.study?.patientId]);

  const activeReportKey = makeQcReportKey(activeScope, activeForeignKeyId);
  const prevReportKey = usePrevious(activeReportKey);

  const rules = rulesSetForScope(activeScope, schema);
  const defaultFormValues = extractDefaultValues(rules);

  const {
    storedQcReport,
    isReady: didLoadStoredReports,
    error: storedReportsError,
    findReportForKey,
    allReports: allStoredReports,
    refetch: refetchStoredReports,
  } = useGetQcReportsForActivePatient({
    activeReportKey,
    onReportsChanged: (reports) => {
      if (reports.length === 0) return;
      qc_debugLog("Stored reports changed", {
        activeReportKey,
        reports,
      });

      const newReports: QcFormData = reports.reduce((acc, report) => {
        const reportKey = makeQcReportKey(report.scope, report[scopeIdKey(report.scope)]);
        if (!reportKey) {
          console.warn(
            `Attempted to update form state without a report key – was the report created?`
          );
          qc_debugLog(
            "Attempted to update form state without a report key – was the report created?",
            {
              report,
              reportKey,
              activeReportKey,
              _storedReports: allStoredReports,
            }
          );
          return acc;
        }

        return !Object.keys(qcFormState).includes(reportKey)
          ? { ...acc, [reportKey]: report.result }
          : acc;
      }, {} as QcFormData);

      if (Object.keys(newReports).length) {
        qc_debugLog("New report(s) found that were not previously in state, adding", {
          activeReportKey,
          newReports,
        });

        updateQcFormState(newReports);
      }
    },
  });

  const prevQcReportId = usePrevious(storedQcReport?.id);
  const prevActiveSeriesId = usePrevious(activeSeries?.id);

  const formManager = useForm<Record<string, QcFormValue>>({
    defaultValues: Object.fromEntries(
      rules.map(({ name, default_value }) => [name, default_value])
    ),
    shouldFocusError: true,
    mode: "onChange",
  });

  const [qcFormState, updateQcFormState] = useReducer(
    (state: QcFormData, payload: QcFormStateUpdatePayload) => {
      if (!currentTask?.qcSchemaId) {
        throw new Error(
          "Cannot update QC results without a QC schema ID, maybe this is not a QC task?"
        );
      }

      const reportKeys = Object.keys(payload ?? {}) as QcReportKey[];
      const [activeScope] = parseQcReportKey(activeReportKey);
      return {
        ...state,
        ...reportKeys.reduce((acc, reportKey) => {
          const storedReport = findReportForKey(reportKey);
          const storedFormData = storedReport?.result;
          const updatedFormData = payload[reportKey];
          const stateFormData = state?.[reportKey];

          const storedReportKey = makeQcReportKey(activeScope, storedReport);
          if (!storedReportKey) {
            console.warn(
              `Attempted to update form state without a report key – was the report created?`
            );
            qc_debugLog(
              "Attempted to update form state without a report key – was the report created?",
              {
                reportKey,
                storedReport,
                storedReportKey,
                activeScope,
                activeReportKey,
                payload,
                _qcFormState: state,
                _storedReports: allStoredReports,
              }
            );
            return acc;
          }

          if (!storedFormData && !stateFormData) {
            qc_debugLog(
              `No stored report or state found for ${reportKey}, applying defaults to updated form data`,
              {
                reportKey,
                stateFormData,
                storedFormData,
                updatedFormData,
                defaultFormValues,
                _qcFormState: state,
                _storedReports: allStoredReports,
              }
            );

            return {
              ...acc,
              [reportKey]: mergeQcFormValues(defaultFormValues, updatedFormData),
            };
          }

          if (!stateFormData && storedFormData) {
            qc_debugLog(
              `No state found for ${reportKey}, applying defaults to stored report and merging with updated form data`,
              {
                reportKey,
                stateFormData,
                storedFormData,
                updatedFormData,
                _qcFormState: state,
                _storedReports: allStoredReports,
              }
            );

            const storedWithDefaults = mergeQcFormValues(defaultFormValues, storedFormData);

            return {
              ...acc,
              [reportKey]: mergeQcFormValues(storedWithDefaults, updatedFormData),
            };
          }

          const [formsAreEqual, delta] = compareQcFormValues(stateFormData, updatedFormData, {
            ignoreExtraKeys: "a",
            aKeyName: "state",
            bKeyName: "updatePayload",
          });

          if (formsAreEqual) {
            // qc_debugLog("Not updating current form's state because the new values are the same", {
            //   reportKey,
            //   stateFormData,
            //   storedFormData,
            //   newFormData: action.formData,
            //   delta: delta?.delta,
            //   _qcFormState: state,
            //   _storedReports: allStoredReports,
            // });
            return acc;
          }

          qc_debugLog(`Updating ${reportKey}'s state`, {
            reportKey,
            stateFormData,
            storedFormData,
            updatedFormData,
            delta: delta?.delta,
            _qcFormState: state,
            _storedReports: allStoredReports,
          });

          return {
            ...state,
            [reportKey]: mergeQcFormValues(stateFormData, updatedFormData),
          };
        }, {} as QcFormData),
      };
    },
    {
      ...allStoredReports.reduce((acc, report) => {
        const reportKey = makeQcReportKey(report.scope, report[scopeIdKey(report.scope)]);
        return reportKey ? { ...acc, [reportKey]: report.result } : acc;
      }, {}),
    } as QcFormData
  );

  const activeQcFormData = useMemo<QcFormResults | undefined>(() => {
    if (!activeReportKey) return undefined;
    return qcFormState?.[activeReportKey];
  }, [activeReportKey, qcFormState]);

  const createReport = useCreateQcReport({
    scope: activeScope,
    foreignKeyId: parseQcReportKey(activeReportKey)[1],
    onCreated: async (newReport) => {
      qc_debugLog("Created a new report, going to refetch stored reports", { activeReportKey });
      await refetchStoredReports();
      if (!activeReportKey) {
        qc_debugLog(
          "Created a new report, but not updating form state because there is no active report key"
        );
        return;
      }
      updateQcFormState({
        [activeReportKey]: newReport.result || {},
      });
    },
  });

  const isUpToDate = useDeepCompareMemo(() => {
    // Check that all the keys in activeQcFormValues are in storedQcReport?.result
    // and that the values are the same
    const [formsAreEqual] = compareQcFormValues(activeQcFormData, storedQcReport?.result);
    if (formsAreEqual) {
      setIsSaving(false);
    }
    return formsAreEqual;
  }, [activeQcFormData, storedQcReport?.result, setIsSaving]);

  const isFormValid = useMemo(() => {
    return formManager.formState.isValid;
  }, [formManager.formState.isValid]);

  const getQcField = useCallback(
    (key: string) => {
      return {
        ...storedQcReport?.result?.[key],
        ...activeQcFormData?.[key],
        _stored: storedQcReport?.result?.[key],
        _state: activeQcFormData?.[key],
      };
    },
    [activeQcFormData, storedQcReport?.result]
  );

  const saveQcReportStatus = useSaveQcReportStatus();

  const _saveReport = useUpdateQcReport({
    onUpdated(updatedReport) {
      setIsSaving(false);
      const msg = updatedReport ? "Finished saving report" : "Report was not saved";
      qc_debugLog(msg, {
        updatedReport,
        isSaving,
      });
    },
  });
  const saveReport = useDebouncedCallback(_saveReport, DEBOUNCE_SAVE_TIMEOUT, {
    leading: true,
    maxWait: DEBOUNCE_SAVE_TIMEOUT * 5,
  });

  const isAttentionMap = getIsIgnoredSeries(activeScope, activeSeries);

  const needToCreateReport =
    !storedQcReport && didLoadStoredReports && !storedReportsError && !isAttentionMap;

  useEffect(() => {
    if (needToCreateReport) {
      qc_debugLog("Need to create a new report because none exists yet", {
        activeReportKey,
        storedQcReport,
        activeScope,
      });
      createReport();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedQcReport?.id, needToCreateReport]);

  useEffect(() => {
    qc_debugLog(`isSaving changed to`, isSaving, {
      activeReportKey,
      isSaving,
      storedQcReport,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaving]);

  const resetForm = useCallback(() => {
    qc_debugLog(`Resetting form (runs async)`, {
      activeReportKey,
      stateIsValid: isFormValid,
      storedIsValid: storedQcReport?.isValid,
      stored: storedQcReport?.result,
      state: activeQcFormData,
      _qcFormState: qcFormState,
    });

    const merged = mergeQcFormValues(storedQcReport?.result, activeQcFormData);
    const resetData = flatQcFormResults(merged, defaultFormValues);

    formManager.reset(resetData, {
      keepValues: false,
      keepDirty: false,
      keepDirtyValues: false,
      keepIsValid: false,
      keepDefaultValues: true,
    });

    qc_debugLog("Reset complete (runs async)", {
      storedReportId: storedQcReport?.id,
      activeReportKey,
      stateIsValid: isFormValid,
      storedIsValid: storedQcReport?.isValid,
      resetData,
      _qcFormState: qcFormState,
    });
    // PRAGMA: - Only refresh callback when the following change:
    // - storedQcReport?.result
    // - activeQcFormData
    // - defaultFormValues
    // - formManager
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedQcReport?.result, activeQcFormData, defaultFormValues, formManager]);

  const attemptSave = async () => {
    if (!activeReportKey) {
      qc_debugLog("Not saving report because there is no active report key");
      return;
    }

    if (!storedQcReport?.id) {
      qc_debugLog("Not saving report because there is no stored report ID");
      return;
    }

    if (findReportForKey(activeReportKey)?.id !== storedQcReport?.id) {
      console.warn(
        `Attempted to save report with ID ${
          storedQcReport?.id
        } but the stored report's report key (${
          storedQcReport?.[scopeIdKey(activeScope)]
        }) does not match the active report key ${activeReportKey}.`
      );
      qc_debugLog(
        "Not saving report because the stored report ID does not match the active report key",
        {
          storedReportId: storedQcReport?.id,
          activeReportKey,
          storedReport: storedQcReport,
          activeReport: findReportForKey(activeReportKey),
        }
      );
      return;
    }

    // TODO: Expect this is spurious, needs further testing, but disabling until we try again with SUBJECT rules
    // // See https://linear.app/altislabs/issue/NOTA-757/re-verify-that-subject-rules-and-tab-switching-still-work-for-qc-form

    // if (activeScope !== storedQcReport?.scope) {
    //   console.warn(
    //     `Attempted to save report with ID ${storedQcReport?.id} but the stored report's scope (${storedQcReport?.scope}) does not match the active scope (${activeScope}).`
    //   );
    //   qc_debugLog(
    //     "Not saving report because the stored report scope does not match the active scope",
    //     {
    //       storedReportId: storedQcReport?.id,
    //       activeScope,
    //       storedReport: storedQcReport,
    //       activeReport: findReportForKey(activeReportKey),
    //     }
    //   );
    //   return;
    // }

    if (!activeQcFormData || !Object.keys(activeQcFormData).length) {
      qc_debugLog(
        "Not saving report because there is no active QC form data, or it is empty – this is probably because the form is being reset"
      );
      return;
    }

    if (viewOnly) {
      qc_debugLog("Report is in view-only mode, not saving");
      return;
    }

    const [formsAreEqual, delta] = compareQcFormValues(storedQcReport?.result, activeQcFormData);
    if (formsAreEqual) {
      // qc_debugLog("Not saving report because the values are the same", {
      //   storedReportId: storedQcReport?.id,
      //   activeReportKey,
      //   activeQcFormData,
      //   storedQcFormData: storedQcReport?.result,
      //   isFormValid: isFormValid,
      // });
      return;
    }

    setIsSaving(true);

    qc_debugLog("About to save report", {
      reportId: storedQcReport?.id,
      activeReportKey,
      activeQcFormData,
      isFormValid,
      delta: delta?.delta,
    });

    await saveReport({
      qcReportId: storedQcReport?.id,
      reportKey: activeReportKey,
      updatedFormValues: activeQcFormData ?? {},
    });
  };

  const updateQcReportWithDefaults = useCallback(
    async (reportKey: QcReportKey, reportId?: number, data?: QcFormResults) => {
      if (!reportKey || !data || !reportId) return;
      qc_debugLog("Updating report with defaults", {
        reportKey,
        reportId,
        data,
      });
      setIsSaving(true);
      await saveReport({
        qcReportId: reportId,
        reportKey: reportKey,
        updatedFormValues: data ?? {},
        ignoreViewOnly: true,
      });
    },
    [saveReport, setIsSaving]
  );

  useEffect(() => {
    attemptSave();
    // PRAGMA: - Only save when activeQcFormData changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeQcFormData]);

  useEffect(() => {
    if (!activeReportKey) return;
    if (isUpToDate) {
      qc_debugLog(
        "QC form state synced/is up to date with stored, active report key:",
        activeReportKey,
        "/",
        storedQcReport?.id,
        {
          storedIsValid: storedQcReport?.isValid,
          stateIsValid: isFormValid,
          stored: storedQcReport?.result,
          state: activeQcFormData,
        }
      );
    } else {
      const ifViewOnly = viewOnly ? " (but report is view-only)" : "";
      qc_debugLog(
        `QC form state needs updating/does not match stored${ifViewOnly}, active report key:`,
        activeReportKey,
        "/",
        storedQcReport?.id,
        {
          storedIsValid: storedQcReport?.isValid,
          stateIsValid: isFormValid,
          stored: storedQcReport?.result,
          state: activeQcFormData,
          delta: compareQcFormValues(activeQcFormData, storedQcReport?.result, {
            aKeyName: "state",
            bKeyName: "stored",
          })?.[1]?.delta,
        }
      );
    }
    // PRAGMA: - Only log when isUpToDate changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpToDate]);

  useActiveSeriesChanged(activeSeries?.id, async () => {
    qc_debugLog(
      `useQcFormState detected active series changed, ${prevActiveSeriesId} → ${activeSeries?.id}`,
      {
        activeReportKey,
        storedReportId: storedQcReport?.id,
        patientId: activePatientId,
        seriesId: activeSeries?.id,
        formIsValid: storedQcReport?.isValid,
        isUpToDate: isUpToDate,
        stored: {
          data: storedQcReport?.result,
          isValid: storedQcReport?.isValid,
          all: allStoredReports,
        },
        state: {
          data: activeQcFormData,
          isValid: formManager.formState.isValid,
          all: qcFormState,
        },
      }
    );

    if (prevReportKey && prevActiveSeriesId && !viewOnly) {
      const prevState = qcFormState?.[prevReportKey];
      const storedState = findReportForKey(prevReportKey)?.result;

      if (!prevState) return;

      const [formsAreEqual, delta] = compareQcFormValues(storedState, prevState, {
        aKeyName: "stored",
        bKeyName: "state",
      });

      const msg = !formsAreEqual
        ? "Found changes in previous report, saving it before resetting form"
        : "No changes found in previous report, but saving it anyway before resetting form";
      qc_debugLog(msg, {
        activeReportKey,
        storedReportId: storedQcReport?.id,
        prevQcReportId,
        prevActiveSeriesId,
        prevReportKey,
        patientId: activePatientId,
        state: prevState,
        stored: storedState,
        delta: delta?.delta,
      });

      if (prevReportKey && prevReportKey !== activeReportKey && prevQcReportId) {
        setIsSaving(true);
        await saveReport({
          qcReportId: prevQcReportId,
          reportKey: prevReportKey,
          updatedFormValues: qcFormState?.[prevReportKey] ?? {},
        });
      }
    }
  });

  useActiveQcReportChanged(activeReportKey, storedQcReport?.id, () => {
    const reportIdChanged = prevQcReportId !== storedQcReport?.id;
    const reportKeyChanged = prevReportKey !== activeReportKey;

    const reportIdBeforeAfter = `${prevQcReportId} → ${storedQcReport?.id}`;
    const reportKeyBeforeAfter = `${prevReportKey} → ${activeReportKey}`;

    const msg = `useQcFormState detected ${
      reportIdChanged ? "stored report ID" : reportKeyChanged ? "report key" : "unknown"
    } changed, ${reportIdChanged ? reportIdBeforeAfter : reportKeyBeforeAfter}`;

    qc_debugLog(msg, {
      activeReportKey,
      storedReportId: storedQcReport?.id,
      patientId: activePatientId,
      seriesId: activeSeries?.id,
      formIsValid: storedQcReport?.isValid,
      isUpToDate: isUpToDate,
      stored: {
        data: storedQcReport?.result,
        isValid: storedQcReport?.isValid,
        all: allStoredReports,
      },
      state: {
        data: activeQcFormData,
        isValid: formManager.formState.isValid,
        all: qcFormState,
      },
    });
    resetForm();
  });

  useFormStateIsValidChanged(formManager.formState.isValid, storedQcReport, () => {
    if (
      viewOnly ||
      !activeReportKey ||
      !didLoadStoredReports ||
      !storedQcReport?.id ||
      formManager.formState.isValidating
    ) {
      return;
    }
    qc_debugLog(`Form isValid is now ${formManager.formState.isValid}, going to update report`, {
      activeReportKey,
      formStateIsValid: formManager.formState.isValid,
      stateIsValid: isFormValid,
      storedIsValid: storedQcReport?.isValid,
      storedQcReport,
    });
    saveQcReportStatus({
      qcReportId: storedQcReport.id,
      isFormValid: formManager.formState.isValid,
    });
  });

  return {
    formManager,
    qcFormState,
    activeQcFormData,
    activeScopeState,
    updateQcFormState,
    updateQcReportWithDefaults,
    saveQcReportStatus,
    storedQcReport,
    allStoredReports,
    activeSeries,
    activePatientId,
    activeReportKey,
    getQcField,
    isUpToDate,
    refetchStoredReports,
    schema,
    isSavingState,
  };
};
