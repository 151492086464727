import { ApolloCache, useMutation } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { FetchResult } from "@apollo/client/link/core";
import { MutationTuple } from "@apollo/client/react/types/types";

import { LesionsListQueryInput } from "../fragments/LesionListItemFragment";
import { RoiListItemFragmentType } from "../fragments/RoiListItemFragment";
import { deleteRoiFromCache } from "./deleteRoiFromCache";
import { getDiagnosisCacheId } from "./getDiagnosisCacheId";
import { useLesionListQueryInput } from "./useLesionListQueryInput";

export const INTERNAL_MUTATION = `
    delete_diagnosis(where: { id: { _in: $diagnosisIds } }) {
      returning {
        id
      }
    }
    delete_roi(where: { roi_id: { _in: $roiIds } }) {
      returning {
        id: roi_id
      }
    }
`;

const MUTATION = gql`
  mutation DeleteRois($diagnosisIds: [Int!]!, $roiIds: [Int!]!) {
    ${INTERNAL_MUTATION}
  }
`;

export type Variables = {
  roiIds: number[];
  diagnosisIds: number[];
};

export type Data = {
  delete_roi: { returning: { id: number }[] };
  delete_diagnosis: { returning: { id: number }[] };
};

export type DeleteRoisReturnType = MutationTuple<Data, Variables>;

export function useDeleteRois(): DeleteRoisReturnType {
  const lesionListQueryInput = useLesionListQueryInput();

  return useMutation<Data, Variables>(MUTATION, {
    update: (cache, result) => updateCache(cache, result, lesionListQueryInput),
  });
}

export function updateCache(
  cache: ApolloCache<Data>,
  { data }: FetchResult<Data>,
  lesionListQueryInput: LesionsListQueryInput
): void {
  if (!data) {
    throw new Error("Something went wrong updating the cache after deleting a roi");
  }

  const {
    delete_roi: { returning: rois },
    delete_diagnosis: { returning: diagnoses },
  } = data;

  const roiIds = rois.map(({ id }) => id);
  for (const roiId of roiIds) {
    deleteRoiFromCache(lesionListQueryInput, roiId, cache);
  }

  const diagnosisIds = diagnoses.map(({ id }) => id);

  for (const diagnosisId of diagnosisIds) {
    const diagnosisCacheId = getDiagnosisCacheId(diagnosisId, cache);
    cache.evict({ id: diagnosisCacheId });
  }

  cache.gc();
}

export function getVariables(rois: RoiListItemFragmentType[]): Variables {
  const roiIds = rois.map(({ id }) => id);

  const diagnosisIds = rois.flatMap(({ tumours }) =>
    tumours ? tumours.map(({ tumour }) => tumour.diagnosis.id) : []
  );

  return { roiIds, diagnosisIds };
}
