import styled from "styled-components";

export const InputButton = styled.input<{
  background?: string;
  color?: string;
  borderColor?: string;
  width?: number;
  disabled?: boolean;
  minWidth?: number;
  height?: number;
}>`
  width: ${(props) => (props.width ? `${props.width}px` : null)};
  height: ${(props) => (props.height ? `${props.height}px` : null)};
  min-width: ${(props) => props.minWidth ?? 64}px;
  background: ${(props) => props.background ?? props.theme.colors.neutral.black};
  color: ${(props) => props.color ?? props.theme.colors.neutral.white};
  border: 1px solid ${(props) => props.borderColor ?? props.theme.colors.neutral.black};
  border-radius: 6px;
  cursor: ${({ disabled }) => (!disabled ? "pointer" : "default")};
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  font-style: normal;
  line-height: 20px;
`;

export const InputDivBase = styled.button<{
  background?: string;
  color?: string;
  borderColor?: string;
  disabled?: boolean;
}>`
  background: ${(props) => props.background ?? props.theme.colors.neutral.black};
  color: ${(props) => props.color ?? props.theme.colors.neutral.white};
  border: 1px solid ${(props) => props.borderColor ?? props.theme.colors.neutral.black};
  border-radius: 6px;
  cursor: ${({ disabled }) => (!disabled ? "pointer" : "default")};
  padding: 6px;

  font-size: 13px;
  font-weight: 600;
  font-family: Inter;
  font-style: normal;
  line-height: 20px;
`;

export const InputDiv = styled(InputDivBase)<{
  background?: string;
  color?: string;
  borderColor?: string;
  width?: number;
  disabled?: boolean;
}>`
  width: ${(props) => props.width ?? 64}px;
`;

export const InputDivFlex = styled(InputDivBase)<{
  background?: string;
  color?: string;
  borderColor?: string;
  width?: number;
  disabled?: boolean;
}>`
  width: 100%;
`;

export const InnerButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;
