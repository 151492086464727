import React from "react";
import styled from "styled-components";

const OuterWrapper = styled.div`
  display: inline-block;
`;
const Wrapper = styled.div<{ background: string }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  background: ${(props) => props.background};
  border-radius: 4px;
`;
const Text = styled.div<{ color: string }>`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: ${(props) => props.color};
`;

interface LabelProps {
  label: string;
  textColor: string;
  backgroundColor: string;
}

export function Label({ label, textColor, backgroundColor }: LabelProps): JSX.Element {
  return (
    <OuterWrapper>
      <Wrapper background={backgroundColor}>
        <Text color={textColor}>{label}</Text>
      </Wrapper>
    </OuterWrapper>
  );
}
