import { useDialogReturnType } from "../../../../../common/components/Dialog/useDialog";
import { useFormDialog } from "../../../../../common/components/Dialog/useFormDialog";
import { TaskDoesNotExistDialog } from "./TaskDoesNotExistDialog";

export function useTaskDoesNotExistDialog(): useDialogReturnType {
  return useFormDialog({
    children: TaskDoesNotExistDialog,
    label: "Task Does Not Exist",
    showCloseButton: false,
    props: {},
  });
}
