import { useDialogReturnType } from "../../../../common/components/Dialog/useDialog";
import { useFormDialog } from "../../../../common/components/Dialog/useFormDialog";
import { ConfirmDeletePatientsDialog } from "./ConfirmDeletePatientsDialog";

export function useConfirmDeletePatientsDialog(onConfirm: () => void): useDialogReturnType {
  return useFormDialog({
    children: ConfirmDeletePatientsDialog,
    label: "Delete Selected Subjects",
    showCloseButton: true,
    props: { onConfirm },
  });
}
