import React, { useContext } from "react";
import styled from "styled-components";

import { FlexLoading } from "../../../../../common/components/Loading";
import { PatientContext } from "../../../../../Dashboard/components/Settings/Patients/Viewer/PatientContext";
import { TimepointListItem } from "../../../../../Dashboard/components/Settings/Patients/Viewer/TimepointListItem";
import { TimepointType } from "../AnnotationPanel/hooks/Timepoint/TimepointType";
import { timepointIncludeDay } from "./utils/formatTimepointLabel";

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 0 6px 12px;
  overflow-y: scroll;
`;

const TitleWrapper = styled.div`
  font-size: 18px;
  font-weight: bolder;
  margin: 12px 0 12px 0;
  padding: 0px 6px;
`;

const ListItemWrapper = styled.div`
  background: ${(props) => props.theme.colors.background.secondary};
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  overflow: hidden;
  padding: 3px 6px;
`;

interface TimepointPanelProps {
  timepoints: TimepointType[];
  selected: TimepointType | undefined;
  onSelected: (seriesId: number, timepoint: TimepointType) => void;
}

export function TasklessTimepointPanel({
  timepoints,
  selected,
  onSelected,
}: TimepointPanelProps): JSX.Element {
  const { loading } = useContext(PatientContext);
  return (
    <>
      <TitleWrapper>Timepoints</TitleWrapper>
      <ListWrapper>
        {!loading ? (
          timepoints.map((timepoint, key) => {
            const includeDay = timepointIncludeDay(timepoints, key);
            return (
              <ListItemWrapper key={key}>
                <TimepointListItem
                  timepoint={timepoint}
                  onSelect={onSelected}
                  selected={timepoint?.index === selected?.index}
                  includeDay={includeDay}
                />
              </ListItemWrapper>
            );
          })
        ) : (
          <FlexLoading />
        )}
      </ListWrapper>
    </>
  );
}
