import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React, { forwardRef } from "react";

import { ViewerContextMenuItemType } from "./ViewerContextMenuItemType";

const useStyles = makeStyles(() => ({
  icon: {
    minWidth: "30px",
  },
  text: {
    textAlign: "center",
    margin: "auto",
  },
}));

export const ViewerContextMenuItem = forwardRef<HTMLLIElement, ViewerContextMenuItemType>(
  ({ label, icon, callback }: ViewerContextMenuItemType, ref) => {
    const classes = useStyles();
    return (
      <MenuItem onClick={callback} ref={ref}>
        <ListItemIcon classes={{ root: classes.icon }}>{icon}</ListItemIcon>
        <ListItemText classes={{ root: classes.text }}>{label}</ListItemText>
      </MenuItem>
    );
  }
);
