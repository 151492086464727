import { ApolloCache } from "@apollo/client";

export const TYPENAME = "ellipse";

export function getEllipseCacheId<T>(id: number, cache: ApolloCache<T>): string {
  const cacheId = cache.identify({
    id,
    __typename: TYPENAME,
  });

  if (!cacheId) {
    throw new Error(`Unable to construct ${TYPENAME} cache id`);
  }

  return cacheId;
}
