import { matchSorter } from "match-sorter";

import { UserAvatarType } from "../../../common/types/UserType";
import { PatientMutableTaskType } from "../Manage/hooks/TaskType";
import {
  TASK_COMPLETED,
  TaskProgressType,
} from "./TaskProgressType";

export function isAssigned({ task }: PatientMutableTaskType, userId: number): boolean {
  if (!task) {
    return false;
  }

  const { taskAssignments } = task;
  return (
    taskAssignments.length > 0 &&
    taskAssignments.some(
      ({ user: { id: taskAssignmentUserId } }) => taskAssignmentUserId === userId
    )
  );
}

export function isUnassigned({ task }: PatientMutableTaskType): boolean {
  if (!task) {
    return false;
  }

  const { taskAssignments } = task;
  return taskAssignments.length === 0;
}

export function isInProgress(patientTask: PatientMutableTaskType, userId: number): boolean {
  return getProgress(patientTask, userId) !== TASK_COMPLETED;
}

export function isCompleted(patientTask: PatientMutableTaskType, userId: number): boolean {
  return getProgress(patientTask, userId) === TASK_COMPLETED;
}

export function getProgress(
  { task }: PatientMutableTaskType,
  userId: number
): TaskProgressType | undefined {
  if (!task) {
    return undefined;
  }
  const { taskAssignments } = task;
  return taskAssignments.find(({ user: { id } }) => userId === id)?.progress;
}

export function orderByAssigned(
  { task: taskA }: PatientMutableTaskType,
  { task: taskB }: PatientMutableTaskType
): number {
  const numberOfAssignmentsA = taskA?.taskAssignments.length ?? 0;
  const numberOfAssignmentsB = taskB?.taskAssignments.length ?? 0;
  return numberOfAssignmentsB - numberOfAssignmentsA;
}

export function filterTaskWorklistRow(row: PatientMutableTaskType, query: string): boolean {
  const { task, patientDicomId } = row;
  const taskName = task?.name;
  const progresses = [...new Set(task?.taskAssignments.map(({ progress }) => progress) ?? [])];

  return [taskName, patientDicomId, ...progresses]
    .filter((field) => field)
    .some((field) => matchSorter([field], query).length > 0);
}

export function doesTaskAssignmentUserMatch(user: UserAvatarType, userId: number) {
  return user.id === userId;
}
