import { useFlags } from "launchdarkly-react-client-sdk";
import { FC } from "react";
import { useHistory, useLocation } from "react-router";

import { Card } from "../../../common/components/cards/Card";
import {
  LinkToPatientProps,
  usePatientLinkHandler,
} from "../../../Project/Layout/hooks/usePatientLinkHandler";
import {
  LinkToRouteProps,
  useProjectPageLinkHandler,
} from "../../../Project/Layout/hooks/useProjectPageLinkHandler";
import { ChartDataTypeProps } from "../../MedianSurvival/components/MedianTumorBurdenSurvivalPredictionCard";
import MortalityChangefromBaselineContainer from "./MortalityChangefromBaselineContainer";

type MortalityChangefromBaselineCardProps = LinkToRouteProps &
  LinkToPatientProps &
  ChartDataTypeProps;

export const MortalityChangeFromBaselineCard: FC<MortalityChangefromBaselineCardProps> = ({
  linkToRoute,
  patientRoute,
  allowedDataTypes,
}: MortalityChangefromBaselineCardProps) => {
  const handleGoToProjectPageLink = useProjectPageLinkHandler(linkToRoute);

  const flags = useFlags();
  const showReorgPages: boolean = flags["notaShowReorganisationPages"];

  const { search } = useLocation();
  const { replace } = useHistory();

  const searchParams = new URLSearchParams(search);

  const handleOpenPatient = (patientId: number) => {
    searchParams.set("subject_id", patientId.toString());
    replace({ search: searchParams.toString() });
  };

  const handleGoToPatient = usePatientLinkHandler(patientRoute);

  if (!allowedDataTypes) {
    throw new Error("MortalityChangeFromBaselineCard: allowedDataTypes is null!");
  }

  return (
    <Card
      title={"Change from Baseline"}
      content={
        <MortalityChangefromBaselineContainer
          allowedDataTypes={allowedDataTypes}
          onClickPatient={showReorgPages ? handleOpenPatient : handleGoToPatient}
        />
      }
      onClickHeader={handleGoToProjectPageLink}
    />
  );
};
