import React, { FC } from "react";

import FilterCategory from "../FilterCategory";
import { AgeFilters } from "./AgeFilters";
import { EthnicityFilters } from "./EthnicityFilters";
import { SexFilters } from "./SexFilters";

export const DemographicsFilters: FC = () => {
  const options = (
    <>
      <AgeFilters />
      <EthnicityFilters />
      <SexFilters />
    </>
  );

  return <FilterCategory name="Demographics" options={options} />;
};
