import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Row } from "react-table";
import styled from "styled-components";

import { ActionButton } from "../../../../Annotate/components/Manage/ActionButton";
import { ReactComponent as CancelIcon } from "../../../../assets/svgs/CancelOutline.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/svgs/Delete.svg";
import { ReactComponent as AddIcon } from "../../../../assets/svgs/PlusOutline.svg";
import { ReactComponent as RemoveIcon } from "../../../../assets/svgs/RemoveOutline.svg";
import { Card } from "../../../../common/components/cards/Card";
import { BigFlexLoading } from "../../../../common/components/Loading";
import { OutsideAlerter } from "../../../../common/components/OutsideAlerter";
import { INCLUDED_HEADER_ID } from "../../../../common/components/SeriesTable/getColumns";
import {
  OPEN_PATIENT_PAGE_HEADER_ID,
  SUBJECT_ID_HEADER_ID,
  TASK_STATUS_HEADER_ID,
  UPLOAD_STATUS_HEADER_ID,
} from "../../../../common/components/StudyTable/StudyTableColumns";
import { SubStudyTable } from "../../../../common/components/StudyTable/SubStudyTable";
import { usePatientStudies } from "../../../../common/components/StudyTable/usePatientStudies";
import { Table, TableProps } from "../../../../common/components/Table/Table";
import { TableControlsType } from "../../../../common/components/Table/TableControlsType";
import ToggleSwitch from "../../../../common/components/ToggleSwitch";
import { CurrentUserCan } from "../../../../common/contexts/UserContext/CurrentUserCan";
import { ADD, REMOVE, UpdateModeType } from "../../../../common/types/UpdateModeType";
import { ADMIN } from "../../../../common/types/UserRoleType";
import { POLLING_INTERVAL } from "../../../../common/utils/configConstants";
import { useGlobalCohorts } from "../Project/useGlobalCohorts";
import { CohortAssignmentSelector } from "./CohortAssignmentSelector";
import { DeletePatientWrapper } from "./DeletePatientWrapper";
import { PatientTableRowType } from "./PatientTableRowType";
import { usePatientTableColumns } from "./usePatientTableColumns";
import { PatientViewerWrapper } from "./Viewer/PatientViewerWrapper";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const LeftPanelWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const ActionPanelWrapper = styled.div`
  flex: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TableWrapper = styled.div`
  flex: 1;
`;

const ButtonsWrapper = styled.div`
  flex: 0;
  display: flex;
  padding: 4px;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

const CohortButtonsInternalWrapper = styled(ButtonsWrapper)`
  padding: unset;
  border-radius: unset;
`;

const FloatingWrapper = styled.div`
  position: absolute;
  transform: translate(0, 4px);
  z-index: 999;
  border-radius: 8px;
  padding: 8px;
  background: ${({ theme }) => theme.colors.neutral.white};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
`;

const ToggleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  font-size: 14px;
  align-items: center;
`;

export function GlobalPatientsManagement(): JSX.Element {
  const tableKeyPrefix = "GlobalPatientsManagement";

  const [showSynthPatients, setShowSynthPatients] = useState(false);

  const { data: cohortsData, loading: cohortsLoading } = useGlobalCohorts();
  const cohorts = cohortsData?.cohorts ?? [];
  const {
    data: patientsData,
    loading: patientsLoading,
    startPolling,
    stopPolling,
  } = usePatientStudies(undefined, !showSynthPatients);

  useEffect(() => {
    startPolling(POLLING_INTERVAL);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  const [showCohortAssignment, setShowCohortAssignment] = useState<UpdateModeType | undefined>(
    undefined
  );

  const [selectedPatientIds, setSelectedPatientIds] = useState<number[]>([]);

  const [patientToView, setPatientToView] = useState<PatientTableRowType | undefined>();

  const tableRef = useRef<TableControlsType<PatientTableRowType>>(null);

  const patients = patientsData?.patients ?? [];

  const handleSelectPatient = (patientId: number, selected: boolean) => {
    if (selected) {
      setSelectedPatientIds((prev) => [...prev, patientId]);
    } else {
      setSelectedPatientIds((prev) => prev.filter((id) => id !== patientId));
    }
  };

  const columns = usePatientTableColumns(
    setPatientToView,
    patientToView,
    cohorts,
    cohortsLoading,
    handleSelectPatient
  );

  const rows: PatientTableRowType[] = useMemo(() => {
    return patients.map((row) => ({
      ...row,
      isSelected: selectedPatientIds.some((id) => id === row.id),
    }));
  }, [patients, selectedPatientIds]);

  const handleClearSelectedPatientRows = () => {
    const { current: tableControls } = tableRef;
    setSelectedPatientIds([]);
    tableControls?.clearSelectedRows();
    tableControls?.clearAllFilters();
    tableControls?.toggleAllRowsExpanded(false);
  };

  const handleCohortAssignClicked = () => {
    setShowCohortAssignment(ADD);
  };

  const handleCohortUnassignClicked = () => {
    setShowCohortAssignment(REMOVE);
  };

  const handleCloseCohortAssign = () => {
    setShowCohortAssignment(undefined);
  };

  const handleCloseStudies = (row: PatientTableRowType) => {
    const rowId = rows.indexOf(row);
    const { current: tableControls } = tableRef;
    tableControls?.setRowExpanded([rowId.toString()], false);
  };

  const handleShowSynthPatientsChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setShowSynthPatients(isChecked);
  };

  useEffect(() => {
    if (!patientsData) {
      return;
    }

    if (patientToView === undefined) {
      return;
    }

    const { patients } = patientsData;

    const { id: patientToViewId } = patientToView;

    const patientIds = patients.map(({ id }) => id);

    if (!patientIds.includes(patientToViewId)) {
      setPatientToView(undefined);
    }
  }, [patientsData]);

  const patientsSelected = selectedPatientIds.length > 0;

  if (patientsLoading) {
    return <BigFlexLoading />;
  }

  return (
    <Card
      noHead
      expand={true}
      content={
        <DndProvider backend={HTML5Backend}>
          <Wrapper>
            <LeftPanelWrapper>
              <ActionPanelWrapper>
                <ButtonsWrapper>
                  <ActionButton
                    label={"Clear"}
                    icon={CancelIcon}
                    onClick={handleClearSelectedPatientRows}
                    tooltip={"Clear all selections"}
                  />
                  <CurrentUserCan
                    allowedRoles={[ADMIN]}
                    yes={
                      <DeletePatientWrapper
                        patientIds={selectedPatientIds}
                        onDeleted={handleClearSelectedPatientRows}
                      >
                        {({ onClick }) => (
                          <ActionButton
                            label={"Delete"}
                            onClick={onClick}
                            icon={DeleteIcon}
                            tooltip={"Delete selected subject(s)"}
                            disabled={!patientsSelected}
                          />
                        )}
                      </DeletePatientWrapper>
                    }
                  />

                  <OutsideAlerter callback={handleCloseCohortAssign}>
                    <CohortButtonsInternalWrapper>
                      <div>
                        <ActionButton
                          label={"Assign Cohorts"}
                          icon={AddIcon}
                          onClick={handleCohortAssignClicked}
                          tooltip={
                            !showCohortAssignment ? "Assign selected subjects to cohorts" : ""
                          }
                          disabled={!patientsSelected}
                        />
                        {showCohortAssignment === ADD && (
                          <FloatingWrapper>
                            <CohortAssignmentSelector
                              updateMode={ADD}
                              patientIds={selectedPatientIds}
                              onClose={handleCloseCohortAssign}
                              cohorts={cohorts}
                              cohortsLoading={cohortsLoading}
                            />
                          </FloatingWrapper>
                        )}
                      </div>
                      <div>
                        <ActionButton
                          label={"Unassign Cohorts"}
                          icon={RemoveIcon}
                          onClick={handleCohortUnassignClicked}
                          tooltip={
                            !showCohortAssignment ? "Unassign selected subjects from cohorts" : ""
                          }
                          disabled={!patientsSelected}
                        />
                        {showCohortAssignment === REMOVE && (
                          <FloatingWrapper>
                            <CohortAssignmentSelector
                              updateMode={REMOVE}
                              patientIds={selectedPatientIds}
                              onClose={handleCloseCohortAssign}
                              cohorts={cohorts}
                              cohortsLoading={cohortsLoading}
                            />
                          </FloatingWrapper>
                        )}
                      </div>
                    </CohortButtonsInternalWrapper>
                  </OutsideAlerter>
                </ButtonsWrapper>
                <CurrentUserCan
                  allowedRoles={[ADMIN]}
                  yes={
                    <ToggleWrapper>
                      Show synthetic subjects
                      <ToggleSwitch
                        checked={showSynthPatients}
                        onChange={handleShowSynthPatientsChanged}
                      />
                    </ToggleWrapper>
                  }
                />
              </ActionPanelWrapper>
              <TableWrapper>
                <TableWithRef
                  ref={tableRef}
                  columns={columns}
                  data={rows}
                  enableSelect={false}
                  enableExpand={true}
                  renderSubComponent={(props: { row: Row<PatientTableRowType> }) => (
                    <SubStudyTable
                      {...props}
                      tableKeyPrefix={tableKeyPrefix}
                      hiddenColumns={[
                        UPLOAD_STATUS_HEADER_ID,
                        OPEN_PATIENT_PAGE_HEADER_ID,
                        INCLUDED_HEADER_ID,
                        SUBJECT_ID_HEADER_ID,
                        TASK_STATUS_HEADER_ID,
                      ]}
                      hiddenSeriesColumns={[INCLUDED_HEADER_ID]}
                      onClose={handleCloseStudies}
                    />
                  )}
                />
              </TableWrapper>
            </LeftPanelWrapper>
            {patientToView && (
              <PatientViewerWrapper patient={patientToView} setPatient={setPatientToView} />
            )}
          </Wrapper>
        </DndProvider>
      }
    />
  );
}

const TableWithRef = forwardRef<
  TableControlsType<PatientTableRowType>,
  TableProps<PatientTableRowType>
>((props, ref) => {
  return <Table {...props} forwardedRef={ref} />;
});
TableWithRef.displayName = "TableWithRef";
