import { MutationTuple, useMutation } from "@apollo/client";
import { gql } from "@apollo/client/core";

import { TASK_PENDING, TaskProgressType } from "../../TaskWorklist/TaskProgressType";
import { getCacheId } from "../cache/getCacheId";
import { ConfirmTaskAssignmentsRowType } from "../PatientTable/ConfirmTaskAssignments/ConfirmTaskAssignmentsRowType";
import { TaskAssignmentType } from "./TaskType";
import { TASK_ASSIGNMENT_FRAGMENT } from "./useRawPatientTasksQuery";

type InsertTaskAssignmentType = {
  user_id: number;
  patient_id: number;
  task_id: number;
  progress: TaskProgressType;
  show_badge: boolean;
};

type Variables = {
  objects: InsertTaskAssignmentType[];
  taskAssignmentIdsToDelete: number[];
};

type Data = {
  insert_task_assignment: {
    returning: (TaskAssignmentType & { taskId: number })[];
  };
  delete_task_assignment: {
    returning: { id: number }[];
  };
};

const MUTATION = gql`
  mutation InsertDeleteTaskAssignments(
    $objects: [task_assignment_insert_input!]!
    $taskAssignmentIdsToDelete: [Int!]!
  ) {
    insert_task_assignment(objects: $objects) {
      returning {
        taskId: task_id
        ...TaskAssignment
      }
    }
    delete_task_assignment(where: { id: { _in: $taskAssignmentIdsToDelete } }) {
      returning {
        id
      }
    }
  }
  ${TASK_ASSIGNMENT_FRAGMENT}
`;

export function useInsertDeleteTaskAssignments(): MutationTuple<Data, Variables> {
  return useMutation<Data, Variables>(MUTATION, {
    update(cache, { data }) {
      if (!data) {
        throw new Error(
          "Something went wrong updating the cache after inserting a case assignment"
        );
      }
      const {
        insert_task_assignment: { returning: insertedTaskAssignments },
        delete_task_assignment: { returning: deletedTaskAssignments },
      } = data;

      for (const taskAssignment of insertedTaskAssignments) {
        const { taskId } = taskAssignment;

        const taskCacheId = getCacheId(taskId, "task", cache);

        cache.modify({
          id: taskCacheId,
          fields: {
            task_assignments(currentTaskAssignmentRefs = []) {
              const newTaskAssignmentRef = cache.writeFragment({
                data: taskAssignment,
                fragment: gql`
                  fragment TaskAssignmentReference on task_assignment {
                    id
                    type
                  }
                `,
              });
              return [...currentTaskAssignmentRefs, newTaskAssignmentRef];
            },
          },
        });
      }

      for (const { id } of deletedTaskAssignments) {
        const taskAssignmentCacheId = getCacheId(id, "task_assignment", cache);
        cache.evict({ id: taskAssignmentCacheId });
      }

      cache.gc();
    },
  });
}

export function getAddTaskAssignmentsVariables(rows: ConfirmTaskAssignmentsRowType[]): Variables {
  const objects: InsertTaskAssignmentType[] = rows.flatMap(({ id: patient_id, task, users }) => {
    if (!task) {
      return [];
    }

    const { id: task_id, taskAssignments } = task;

    return users.flatMap(({ id: user_id }) => {
      const assignedUsersIds = taskAssignments.map(({ user: { id } }) => id);
      if (assignedUsersIds.includes(user_id)) {
        return [];
      }
      return [{ task_id, progress: TASK_PENDING, user_id, patient_id, show_badge: false }];
    });
  });
  return { objects, taskAssignmentIdsToDelete: [] };
}

export function getRemoveTaskAssignmentsVariables(
  rows: ConfirmTaskAssignmentsRowType[]
): Variables {
  const taskAssignmentIdsToDelete: number[] = rows.flatMap(({ task, users }) => {
    if (!task) {
      return [];
    }

    const { taskAssignments } = task;
    const userIdsToRemove = users.map(({ id }) => id);
    return taskAssignments
      .filter(({ user: { id } }) => userIdsToRemove.includes(id))
      .map(({ id }) => id);
  });

  return { objects: [], taskAssignmentIdsToDelete };
}
