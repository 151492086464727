import { ApolloCache } from "@apollo/client/core";

export function getCacheId<T>(id: number, typename: string, cache: ApolloCache<T>): string {
  const cacheId = cache.identify({
    id,
    __typename: typename,
  });

  if (!cacheId) {
    throw new Error(`Unable to construct cache id (${id}) for type ${typename}`);
  }

  return cacheId;
}
