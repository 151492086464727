import { ApolloCache } from "@apollo/client";

export function getLesionCacheId<T>(lesionId: number, cache: ApolloCache<T>): string {
  const id = cache.identify({
    id: lesionId,
    __typename: "lesion",
  });

  if (!id) {
    throw new Error("Unable to construct lesion cache id");
  }

  return id;
}
