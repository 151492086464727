import { useSelector } from "react-redux";

import { getProjectViewFilters } from "../../../common/store/projectViewFiltersSlice";
import { ProjectArmFilters } from "../../../common/types/ProjectViewFilterTypes";

export function useFilteredProjectArmIds(): number[] {
  const filters = useSelector(getProjectViewFilters);

  const projectArmsIds: number[] = [];

  let count = 0;
  const projectArmFilter = filters.projectArm;
  for (const projectArmFilterKey in projectArmFilter) {
    if (!Object.prototype.hasOwnProperty.call(projectArmFilter, projectArmFilterKey)) {
      continue;
    }

    if (projectArmFilter[projectArmFilterKey as unknown as keyof ProjectArmFilters]) {
      projectArmsIds.push(count);
    }
    count++;
  }

  return projectArmsIds;
}
