import {
  ADMIN,
  GLOBAL_ROLE_SOFTWARE_MANAGER,
  GlobalRoleType,
  PROJECT_ROLE_ANNOTATOR,
  PROJECT_ROLE_COORDINATOR,
  PROJECT_ROLE_QC_ANNOTATOR,
  PROJECT_ROLE_VIEWER,
  UserRoleType,
} from "../../../types/UserRoleType";

export const projectRoleLabels: Record<UserRoleType, string> = {
  [PROJECT_ROLE_COORDINATOR]: "Coordinator",
  [PROJECT_ROLE_QC_ANNOTATOR]: "QC Annotator",
  [PROJECT_ROLE_VIEWER]: "Viewer",
  [PROJECT_ROLE_ANNOTATOR]: "Annotator",
};

export const globalRoleLabels: Record<GlobalRoleType, string> = {
  [GLOBAL_ROLE_SOFTWARE_MANAGER]: "Software Manager",
  [ADMIN]: "Admin",
};

export const roleLabels: Record<UserRoleType | GlobalRoleType, string> = {
  ...projectRoleLabels,
  ...globalRoleLabels,
};
