import { useMutation } from "@apollo/client";
import { gql, Reference, StoreObject } from "@apollo/client/core";
import { MutationTuple } from "@apollo/client/react/types/types";

import { getPatientCacheId } from "../../../../Annotate/components/Annotate/page/AnnotationPanel/hooks/getPatientCacheId";
import { CohortType } from "../../../../common/types/CohortType";
import { COHORT_FRAGMENT } from "../../../../Project/contexts/Project/CohortFragment";

const MUTATION = gql`
  mutation InsertRemoveCohortPatient(
    $deleted: label_patient_bool_exp!
    $added: [label_patient_insert_input!]!
  ) {
    deleted: delete_label_patient(where: $deleted) {
      cohortPatients: returning {
        patientId: patient_id
        cohortId: label_id
        cohort: label {
          ...Cohort
        }
      }
    }
    added: insert_label_patient(
      objects: $added
      on_conflict: { constraint: label_patient_patient_id_label_id_key, update_columns: [] }
    ) {
      cohortPatients: returning {
        patientId: patient_id
        cohortId: label_id
        cohort: label {
          ...Cohort
        }
      }
    }
  }
  ${COHORT_FRAGMENT}
`;

type Data = {
  deleted: { cohortPatients: { patientId: number; cohort: CohortType }[] };
  added: { cohortPatients: { patientId: number; cohort: CohortType }[] };
};

export type Variables = {
  deleted: {
    _or: {
      _and: { patient_id: { _eq: number }; label_id: { _eq: number } }[];
    }[];
  };
  added: {
    patient_id: number;
    label_id: number;
  }[];
};

export function useInsertDeleteCohortPatient(): MutationTuple<Data, Variables> {
  return useMutation<Data, Variables>(MUTATION, {
    update(cache, { data }) {
      if (!data) {
        throw new Error("Something went wrong updating the cache after updating a cohort patient");
      }

      const {
        added: { cohortPatients: addedCohortPatients },
        deleted: { cohortPatients: deletedCohortPatients },
      } = data;

      const addedCohortPatientIds = addedCohortPatients.map(({ patientId }) => patientId);
      const deletedCohortPatientIds = deletedCohortPatients.map(({ patientId }) => patientId);

      const affectedPatientIds = [
        ...new Set([...addedCohortPatientIds, ...deletedCohortPatientIds]),
      ];

      for (const patientId of affectedPatientIds) {
        const patientCacheId = getPatientCacheId(patientId, cache);

        const addedCohorts = addedCohortPatients
          .filter(({ patientId: addedPatientId }) => addedPatientId === patientId)
          .flatMap(({ cohort }) => cohort);

        const deletedCohortIds = deletedCohortPatients
          .filter(({ patientId: deletedPatientId }) => deletedPatientId === patientId)
          .map(({ cohort: { id } }) => id);

        if (addedCohorts.length === 0 && deletedCohortIds.length === 0) {
          continue;
        }

        for (const addedCohort of addedCohorts) {
          const { id: cohortId } = addedCohort;

          cache.modify({
            id: patientCacheId,
            fields: {
              label_patients(existingCohortPatients = [], { readField, toReference }) {
                if (
                    existingCohortPatients.some(
                    (ref: Reference | StoreObject | undefined) =>
                      readField("label_id", ref) === cohortId
                  )
                ) {
                  return existingCohortPatients;
                }
                return [
                  ...existingCohortPatients,
                  {
                    label_id: cohortId,
                    patient_id: patientId,
                    label: toReference({
                      __typename: "label",
                      id: cohortId,
                    }),
                    __typename: "label_patient",
                  },
                ];
              },
            },
          });
        }

        cache.modify({
          id: patientCacheId,
          fields: {
            label_patients(existingCohortPatients = [], { readField }) {
              return existingCohortPatients.filter(
                (ref: Reference | StoreObject | undefined) =>
                  !deletedCohortIds.includes(readField("label_id", ref) as number)
              );
            },
          },
        });
      }
    },
  });
}
