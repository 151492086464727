import React from "react";
import styled from "styled-components";

import { Card } from "../../../../../common/components/cards/Card";
import { FilterableTimepointType } from "../AnnotationPanel/hooks/Timepoint/TimepointType";
import { useGetAndSaveTumourResponses } from "./hooks/useGetAndSaveTumourResponses";
import { ExclusionWrapper, TimepointListItem } from "./TimepointListItem";
import { timepointIncludeDay } from "./utils/formatTimepointLabel";

const Wrapper = styled.div`
  padding: 0 12px 2px 12px;
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

const StudyExclusionWrapper = styled.div`
  background: ${(props) => props.theme.colors.background.main};
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  padding-top: 6px;
`;

interface TimepointListProps {
  timepoints: FilterableTimepointType[];
  excludedStudyCount: number;
}

export function TimepointList({ timepoints, excludedStudyCount }: TimepointListProps): JSX.Element {
  const seriesTumourResponses = useGetAndSaveTumourResponses();

  return (
    <Wrapper>
      {timepoints.map((timepoint, index) => {
        const includeDay = timepointIncludeDay(timepoints, index);
        return (
          <TimepointListItem
            timepoint={timepoint}
            key={index}
            seriesTumourResponses={seriesTumourResponses}
            includeDay={includeDay}
          />
        );
      })}
      {!!excludedStudyCount && (
        <StudyExclusionWrapper>
          <ExclusionWrapper>
            +{excludedStudyCount} {excludedStudyCount === 1 ? "study" : "studies"} not shown
          </ExclusionWrapper>
        </StudyExclusionWrapper>
      )}
    </Wrapper>
  );
}
