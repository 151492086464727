import { isLesionLesion } from "../../../Annotate/components/Annotate/page/AnnotationPanel/utils/isLesionLesion";
import {
  BENIGN,
  INDETERMINATE,
  MALIGNANT,
  MEASURABLE,
  METASTASIS,
  NON_MEASURABLE,
  PRIMARY,
} from "../../../Annotate/enums/TaskDescriptionEnums";
import { DayjsDateRange } from "../../../common/utils/dateFormatUtils/DayjsDateRange";
import { Data } from "../hooks/useProjectLabels";
import { getIncludedPatientLesions } from "./getIncludedPatientLesions";
import { getIncludedRoiCount } from "./getIncludedRoiCount";
import { getLabelDisplayName } from "./getLabelDisplayName";

export type LesionData = {
  organ: string;
  total: number;
  primary: number;
  metastatic: number;
  malignant: number;
  benign: number;
  indeterminate: number;
  measurable: number;
  nonMeasurable: number;
  contoured: number;
};

export function buildLesionData(
  { patients }: Data,
  range: DayjsDateRange,
  includedClassifications: string[] | undefined = undefined
): LesionData[] {
  const lesions = getIncludedPatientLesions(patients, range, false)
    .flatMap(({ lesions }) => lesions)
    .filter(isLesionLesion);
  const results: LesionData[] = [];

  for (const lesion of lesions) {
    const { classifications, rois } = lesion;

    const lesionClassifications = classifications.map(({ classification }) => classification);

    const include = includedClassifications
      ? includedClassifications.every((classifications) =>
          lesionClassifications.includes(classifications)
        )
      : true;

    if (!include) {
      continue;
    }

    const name = getLabelDisplayName(lesion, "ORGAN");

    let existingResult = results.find(({ organ }) => organ === name);
    if (!existingResult) {
      existingResult = {
        organ: name,
        total: 0,
        primary: 0,
        metastatic: 0,
        malignant: 0,
        benign: 0,
        indeterminate: 0,
        measurable: 0,
        nonMeasurable: 0,
        contoured: 0,
      };
      results.push(existingResult);
    }

    const totalCount = rois.length;
    const contouredCount = getIncludedRoiCount(lesion, "CONTOURED_LESION");

    existingResult.total += totalCount;

    if (lesionClassifications.includes(PRIMARY)) {
      existingResult.primary += totalCount;
    }

    if (lesionClassifications.includes(METASTASIS)) {
      existingResult.metastatic += totalCount;
    }

    if (lesionClassifications.includes(BENIGN)) {
      existingResult.benign += totalCount;
    }

    if (lesionClassifications.includes(INDETERMINATE)) {
      existingResult.indeterminate += totalCount;
    }

    if (lesionClassifications.includes(MALIGNANT)) {
      existingResult.malignant += totalCount;
    }

    if (lesionClassifications.includes(MEASURABLE)) {
      existingResult.measurable += totalCount;
    }

    if (lesionClassifications.includes(NON_MEASURABLE)) {
      existingResult.nonMeasurable += totalCount;
    }

    existingResult.contoured += contouredCount;
  }

  return results;
}
