import React, { ReactElement } from "react";
import styled from "styled-components";

interface ItemSubtext2Props {
  text: string;
}

const StyledSubtext = styled.div`
  color: ${(props) => props.theme.colors.neutral.neutral5};
  font-size: 11px;
`;

const StyledP = styled.p`
  margin: 0;
`;

export default function ItemSubtext2({ text }: ItemSubtext2Props): ReactElement {
  return (
    <StyledSubtext>
      <StyledP>{text}</StyledP>
    </StyledSubtext>
  );
}
