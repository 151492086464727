import React from "react";
import styled from "styled-components";

import { HeaderText, LabelValueWrapper, ValueText } from "./SimpleBodyTimepointStat";
import { HUStat } from "./utils/getRoiHUStats";

const DEFAULT_SEPARATOR = ":";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3px;
  flex-wrap: wrap;
`;

interface HUBodyTimepointStatProps {
  title: string;
  stats: HUStat;
  separator?: string;
}

export function HUBodyTimepointStat({
  title,
  stats,
  separator = DEFAULT_SEPARATOR,
}: HUBodyTimepointStatProps): JSX.Element {
  const formatValue = (value: number) =>
    Math.round(value).toLocaleString("en-US", { maximumFractionDigits: 0 });
  if (stats) {
    const { average, min, max } = stats;
    return (
      <Wrapper>
        <HeaderText>{title}</HeaderText>
        <LabelValueWrapper>
          <HeaderText>{`Avg${separator}`}</HeaderText>
          <ValueText>{formatValue(average)}</ValueText>
        </LabelValueWrapper>
        <LabelValueWrapper>
          <HeaderText>{`Min${separator}`}</HeaderText>
          <ValueText>{formatValue(min)}</ValueText>
        </LabelValueWrapper>
        <LabelValueWrapper>
          <HeaderText>{`Max${separator}`}</HeaderText>
          <ValueText>{formatValue(max)}</ValueText>
        </LabelValueWrapper>
      </Wrapper>
    );
  }

  return (
    <LabelValueWrapper>
      <HeaderText>
        {title}
        {separator}
      </HeaderText>
      <ValueText>N/A</ValueText>
    </LabelValueWrapper>
  );
}
