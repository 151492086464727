import {
  MenuItemType,
  ProjectLayoutType,
  RoutePathSingleOrArray,
  SubMenuItemType,
} from "../Layout";
import { useFindPageByRoute } from "./useFindPageByRoute";

export function useCurrentUserAllowedMenu(projectLayout: ProjectLayoutType): MenuItemType[] {
  const filteredMenu: MenuItemType[] = [];

  const findPageByRoute = useFindPageByRoute();

  const { menu } = projectLayout;
  for (const item of menu) {
    const { route: parentRoute, subItems } = item;

    const filteredSubItems: SubMenuItemType[] = [];
    for (const subItem of subItems ?? []) {
      const { route } = subItem;
      if (!findPageByRoute(projectLayout, [parentRoute, route] as RoutePathSingleOrArray)) {
        continue;
      }
      filteredSubItems.push(subItem);
    }

    const page = findPageByRoute(projectLayout, parentRoute);
    if (!page && filteredSubItems.length === 0) {
      continue;
    }

    filteredMenu.push({ ...item, subItems: filteredSubItems });
  }

  return filteredMenu;
}
