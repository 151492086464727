import { CircularProgress } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { TaskAssignmentType } from "nota-predict-web/src/Annotate/components/Manage/hooks/TaskType";
import { useOkCancelForm } from "nota-predict-web/src/common/components/Dialog/useOkCancelForm";
import { useContext, useMemo } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import styled from "styled-components";

import { ReactComponent as Save } from "../../../../../../../assets/svgs/Save.svg";
import { SvgIcon } from "../../../../../../../common/components/icons/SvgIcon";
import { main } from "../../../../../../../common/theme/main";
import { getStatusColorType } from "../../../../../../../common/types/StatusColorTypes";
import {
  ERROR,
  SUCCESS,
  TaskStatusType,
  WARNING,
} from "../../../../../../../common/types/StatusTypes";
import { ANNOTATION_SCOPE } from "../../../../../../../Project/Layout/Layout";
import { TaskContext } from "../../../../../../TaskContext";
import { useSelectedTaskAssignment } from "../../../../../../useSelectedTaskAssignment";
import { TASK_COMPLETED } from "../../../../../TaskWorklist/TaskProgressType";
import { useTaskAssignmentReOpener } from "../../../../routing/useTaskAssignmentReOpener";
import { useUpdateTaskAssignmentProgress } from "../../hooks/useUpdateTaskAssignmentProgress";
import { IconButton } from "../../IconButton";
import { useQualityControlContext } from "../../QualityControlPanel/QualityControlProvider";
import { useCantSaveTaskDialog } from "./Dialog/CantSaveTaskDialog";
import { useSuccessfulSaveTaskDialog } from "./Dialog/SuccessfulSaveTaskDialog";
import { flattenTaskResults, TaskCheckResults, useTaskChecks } from "./useTaskSaveChecks";
import { canAutoSaveTask } from "./utils/canAutoSaveTask";

const SaveWrapper = styled.div<{ background: string }>`
  background-color: ${({ background }) => background};
  width: 30px;
  height: 30px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${main.colors.neutral.white};
`;
const LoadingIcon = () => (
  <LoadingIconWrapper>
    <CircularProgress size={16} thickness={5} color="inherit" />
  </LoadingIconWrapper>
);

function getButtonColor(status: TaskStatusType, isViewOnly: boolean): string {
  if (isViewOnly) {
    return main.colors.neutral.neutral5;
  }

  return getStatusColorType(status);
}

function getTaskStatus(results: TaskCheckResults): TaskStatusType {
  const allResults = flattenTaskResults(results);
  if (allResults.every(({ status }) => status === SUCCESS)) {
    return SUCCESS;
  }
  if (allResults.filter(({ required }) => required).every(({ status }) => status === SUCCESS)) {
    return WARNING;
  }
  return ERROR;
}

const updateTasks = async (
  taskId: null | number = null,
  taskAssignments: TaskAssignmentType[],
  updateTaskAssignmentProgress: ReturnType<typeof useUpdateTaskAssignmentProgress>[0]
) => {
  if (taskId) {
    return updateTaskAssignmentProgress({
      variables: {
        id: taskId,
        progress: TASK_COMPLETED,
      },
    });
  }
  return Promise.all(
    taskAssignments.map((taskAssignment) =>
      updateTaskAssignmentProgress({
        variables: {
          id: taskAssignment.id,
          progress: TASK_COMPLETED,
        },
      })
    )
  );
};

interface SaveButtonProps {
  isSaving?: boolean;
  buttonBackground?: string;
  handleButtonClicked: () => void | Promise<void>;
}

const SaveButton = ({
  isSaving = false,
  buttonBackground = main.colors.neutral.neutral5,
  handleButtonClicked,
}: SaveButtonProps) => {
  useHotkeys(
    "shift+s",
    () => {
      handleButtonClicked();
    },
    { scopes: [ANNOTATION_SCOPE] }
  );

  return (
    <Tooltip title={"Save Task (Shift + S)"}>
      <IconButton onClick={handleButtonClicked} data-rule-name="save-task-button">
        <SaveWrapper background={buttonBackground}>
          {isSaving ? (
            <LoadingIcon />
          ) : (
            <SvgIcon icon={Save} size={15} color={main.colors.background.secondary} />
          )}
        </SaveWrapper>
      </IconButton>
    </Tooltip>
  );
};

interface TaskSpecificCompleteButtonProps {
  onViewOnly: () => void;
  onCanAutoSave: () => Promise<void>;
  onCannotSave: () => void;
  buttonBackground: string;
}

export const QcTaskCompleteButton = ({
  onViewOnly,
  onCanAutoSave,
  onCannotSave,
  buttonBackground,
}: TaskSpecificCompleteButtonProps) => {
  const { viewOnly } = useContext(TaskContext);
  const {
    submitActiveFormState: [submitActiveForm],
    isSavingState: [isQcSaving],
  } = useQualityControlContext();

  const results = useTaskChecks();

  const handleButtonClicked = async () => {
    if (isQcSaving) {
      // TODO: this can probably removed, as it wasn't here prior to performance work in https://github.com/altis-labs/nota/pull/3460
      //  it also isn't connected to anything upstream
      // onIsSaving?.();
      return;
    }

    if (viewOnly) {
      onViewOnly();
      return;
    }

    // TODO: this can probably removed, as it wasn't here prior to performance work in https://github.com/altis-labs/nota/pull/3460
    //  it also isn't connected to anything upstream
    // onShouldSave?.();
    submitActiveForm?.();

    if (canAutoSaveTask(results)) {
      await onCanAutoSave();
      return;
    }

    if (!viewOnly) {
      onCannotSave();
    }
  };

  return (
    <SaveButton
      isSaving={isQcSaving}
      buttonBackground={buttonBackground}
      handleButtonClicked={handleButtonClicked}
    />
  );
};

export const AnnotationTaskCompleteButton = ({
  onViewOnly,
  onCanAutoSave,
  onCannotSave,
  buttonBackground,
}: TaskSpecificCompleteButtonProps) => {
  const { viewOnly } = useContext(TaskContext);

  const results = useTaskChecks();

  const handleButtonClicked = async () => {
    if (viewOnly) {
      onViewOnly();
      return;
    }

    if (canAutoSaveTask(results)) {
      await onCanAutoSave();
      return;
    }

    if (!viewOnly) {
      onCannotSave();
    }
  };

  return (
    <SaveButton
      isSaving={false}
      buttonBackground={buttonBackground}
      handleButtonClicked={handleButtonClicked}
    />
  );
};

export const CompleteTaskButton = () => {
  const {
    isQc,
    viewOnly,
    task: { taskAssignments },
  } = useContext(TaskContext);

  const selectedTaskAssignment = useSelectedTaskAssignment();
  const [updateTaskAssignmentProgress] = useUpdateTaskAssignmentProgress();
  const { id: selectedTaskAssignmentId } = selectedTaskAssignment;
  const hasMultipleAssignments = taskAssignments.length > 1;

  const results = useTaskChecks();
  const taskStatus = getTaskStatus(results);

  const buttonBackground = useMemo(
    () => getButtonColor(taskStatus, viewOnly),
    [taskStatus, viewOnly]
  );

  const handleConfirmSave = async (showMultipleTaskDialog = true) => {
    if (hasMultipleAssignments && showMultipleTaskDialog) {
      setShowCantSaveDialog(false);
      setShowMultipleTaskCompletionDialog(true);
      return;
    }
    await updateTasks(selectedTaskAssignmentId, taskAssignments, updateTaskAssignmentProgress);
    setShowCantSaveDialog(false);
    setShowSuccessfulSaveTaskDialog(true);
  };

  const handleConfirmSaveAllTasks = async () => {
    await updateTasks(null, taskAssignments, updateTaskAssignmentProgress);
    setShowMultipleTaskCompletionDialog(false);
    setShowSuccessfulSaveTaskDialog(true);
  };

  const [setShowCantSaveDialog, { dialog: cantSaveTaskDialog }] = useCantSaveTaskDialog({
    results,
    onConfirmSave: handleConfirmSave,
    entity: isQc ? "report" : undefined, // TODO: ugh, bad.
    multiple: isQc ? true : undefined, // ...and here
  });

  const [setShowSuccessfulSaveTaskDialog, { dialog: successfulSaveTaskDialog }] =
    useSuccessfulSaveTaskDialog();

  const [setShowMultipleTaskCompletionDialog, { dialog: multipleTaskCompletionDialog }] =
    useOkCancelForm({
      message:
        "There are multiple users assigned to this task, do you wish to mark all of their assignments as completed?",
      title: "Complete multiple tasks",
      width: 500,
      onOkCallback: handleConfirmSaveAllTasks,
      cancelLabel: "No, just this one",
      okLabel: "Yes",
      showCloseButton: true,
      onCancelCallback: () => handleConfirmSave(false),
    });

  const [showReOpenTaskForm, { dialog: reOpenTaskDialog }] = useTaskAssignmentReOpener({
    taskAssignment: selectedTaskAssignment,
  });

  const dialogs = (
    <>
      {successfulSaveTaskDialog}
      {cantSaveTaskDialog}
      {reOpenTaskDialog}
      {multipleTaskCompletionDialog}
    </>
  );

  const button = isQc ? (
    <QcTaskCompleteButton
      onViewOnly={() => showReOpenTaskForm(true)}
      onCanAutoSave={handleConfirmSave}
      onCannotSave={() => setShowCantSaveDialog(true)}
      buttonBackground={buttonBackground}
    />
  ) : (
    <AnnotationTaskCompleteButton
      onViewOnly={() => showReOpenTaskForm(true)}
      onCanAutoSave={handleConfirmSave}
      onCannotSave={() => setShowCantSaveDialog(true)}
      buttonBackground={buttonBackground}
    />
  );

  return (
    <>
      {dialogs}
      {button}
    </>
  );
};
