import React, { FC, useEffect, useState } from "react";

import { useAllProjects } from "../../../../Dashboard/components/Settings/Project/useAllProjects";
import { useCurrentUser } from "../../../contexts/UserContext/useCurrentUser";
import { SiteType } from "../../../types/SiteType";
import { isExistingUser, isNewUser, UserType } from "../../../types/UserType";
import { getNextId } from "../../../utils/getNextId";
import handleApolloError from "../../../utils/handleApolloError";
import { replaceAt } from "../../../utils/replaceAt";
import { FormProps } from "../../Dialog/FormProps";
import { useDialogReturnType } from "../../Dialog/useDialog";
import { useFormDialog } from "../../Dialog/useFormDialog";
import { FlexLoading } from "../../Loading";
import { useCreateSiteDialog } from "../../SiteManagement/Dialogs/useCreateSiteDialog";
import { useEditProjectSitesDialog } from "../../SiteManagement/Dialogs/useEditProjectSitesDialog";
import {
  AnonymizationConfigurationContextProvider,
  AnonymizationConfigurationType,
  anonymizationMethods,
  defaultAnonymizationConfig,
} from "../../UserManagement/Dialogs/Contexts/AnonymizationConfigurationContext";
import { useAllAnonymizationMethods } from "../../UserManagement/Dialogs/hooks/useAllAnonymizationMethods";
import {
  createProjectUserContext,
  UserManagementContextProvider,
} from "../../UserManagement/UserManagementContext";
import { getAllProjectRoles } from "../../UserManagement/utils/getAllProjectRoles";
import { CreateProjectForm } from "../CreateProjectForm";
import { NewProjectType } from "../NewProjectType";
import {
  getAnonymizationConfigParams,
  getInsertProjectVariables,
  useInsertProject,
} from "../useInsertProject";

export function useCreateProjectDialog(): useDialogReturnType {
  return useFormDialog({
    children: FormWrapper,
    label: "Create Project",
    showCloseButton: true,
    props: {},
  });
}

const FormWrapper: FC<FormProps<unknown>> = ({ onSubmit, onCancel }: FormProps<unknown>) => {
  const [config, setConfig] = useState<AnonymizationConfigurationType>(defaultAnonymizationConfig);
  const [insertProject, { loading: insertProjectLoading, error }] = useInsertProject();

  const { data: projectsData, loading: projectsLoading } = useAllProjects();

  const projects = projectsData?.project ?? [];
  const maxLegacyTrialId = projects.reduce((max, { legacyTrialId }) => {
    if (legacyTrialId !== undefined && legacyTrialId > max) {
      return legacyTrialId;
    }
    return max;
  }, 0);

  const user = useCurrentUser();

  const [sites, setSites] = useState<SiteType[]>([]);
  const [users, setUsers] = useState<UserType[]>([]);

  //populate default user and roles
  useEffect(() => {
    setUsers([{ ...user, projectRoles: getAllProjectRoles() }]);
  }, []);

  const handleSitesCreated = (newSites: SiteType[]) => {
    const newSitesWithId = newSites.map((site) => {
      const { name, metadata, type_name } = site;
      const id = getNextId(sites.map((site) => site.id ?? 0));
      return { id, name, metadata, type_name };
    });

    setSites([...sites, ...newSitesWithId]);
  };

  const handleSiteRemoved = (site: SiteType) => {
    setSites(sites.filter((existingSite) => existingSite.id !== site.id));
  };

  const handleSiteEdited = (site: SiteType) => {
    const existingIndex = sites.findIndex((existingSite) => existingSite.id === site.id);
    setSites(replaceAt(sites, existingIndex, site));
  };

  const handleUsersCreated = async (users: UserType[]) => {
    const usersToBeCreated = users.filter(isNewUser);
    if (usersToBeCreated.length > 0) {
      throw new Error("Should not be able to create new users from UserManagementCard");
    }

    const addUsers = users.filter(isExistingUser);

    setUsers((users) => [...users, ...addUsers]);
  };

  const handleUserRemoved = (user: UserType) => {
    setUsers(users.filter((existingUser) => existingUser.id !== user.id));
  };

  const handleUserEdited = (user: UserType) => {
    const existingIndex = users.findIndex((existingUser) => existingUser.id === user.id);
    setUsers(replaceAt(users, existingIndex, user));
  };

  const [setShowCreateSiteDialog, { dialog: createSiteDialog }] =
    useCreateSiteDialog(handleSitesCreated);
  const [setShowEditSitesDialog, { dialog: editSitesDialog }] = useEditProjectSitesDialog({
    sites,
    actionCallbacks: {
      onCreated: handleSitesCreated,
      onRemoved: handleSiteRemoved,
      onEdited: handleSiteEdited,
    },
    options: {
      allowCreateSite: true,
      allowRemoveSite: true,
      allowEditSite: true,
    },
  });

  const handleShowSites = () => {
    setShowEditSitesDialog(true);
  };

  const handleAddSite = () => {
    setShowCreateSiteDialog(true);
  };

  const { data: availableAnonymizationMethodsData } = useAllAnonymizationMethods([
    ...anonymizationMethods,
  ]);

  const handleCreateProject = async (project: NewProjectType) => {
    if (!availableAnonymizationMethodsData) {
      throw new Error("Anonymization methods are undefined in handleCreateProject");
    }

    const { anonymizationMethods } = availableAnonymizationMethodsData;
    const anonymizationParams = getAnonymizationConfigParams(config, anonymizationMethods);

    const variables = getInsertProjectVariables(
      project,
      sites,
      users,
      anonymizationParams,
      maxLegacyTrialId
    );
    await insertProject({ variables });
  };

  if (error) handleApolloError(error);
  if (insertProjectLoading || projectsLoading) return <FlexLoading />;

  return (
    <UserManagementContextProvider
      rules={createProjectUserContext}
      users={users}
      setUsers={setUsers}
      onCreated={handleUsersCreated}
      onRemoved={handleUserRemoved}
      onEdited={handleUserEdited}
    >
      <>
        {createSiteDialog}
        {editSitesDialog}
        <AnonymizationConfigurationContextProvider config={config} setConfig={setConfig}>
          <CreateProjectForm
            props={{
              sites,
              onAddSite: handleAddSite,
              onShowSites: handleShowSites,
              users,
              onSaveProject: handleCreateProject,
            }}
            onSubmit={onSubmit}
            onCancel={onCancel}
          />
        </AnonymizationConfigurationContextProvider>
      </>
    </UserManagementContextProvider>
  );
};
