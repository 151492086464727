import { useEffect } from "react";

interface UseBeforeUnloadProps {
  preventUnload?: boolean;
  action?: (...args: []) => void;
}

// write jsdoc to explain useBeforeUnload

/**
 * A hook that allows you to prevent the user from leaving the page, 
 * and optionally call a function before user leaves the page.
 * @param {boolean} preventUnload - prevent user from leaving the page
 * @param {function} [action] - function to be called before user leaves the page
 * @returns 
 */
export const useBeforeUnload = ({ preventUnload, action }: UseBeforeUnloadProps) =>
  useEffect(() => {
    const unloadCallback = (event: BeforeUnloadEvent) => {
      action?.();
      if (!preventUnload) {
        return;
      }
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, [preventUnload, action]);
