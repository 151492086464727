import { LesionTaskCheckResults } from "../useTaskSaveChecks";
import { getLesionCheckResultsToShowOnSwitch } from "./getLesionCheckResultsToShowOnSwitch";
import { getUnsuccessfulLesionCheckResults } from "./getUnsuccessfulLesionCheckResults";

export function getLesionChecksMessage(
  results: LesionTaskCheckResults[],
  isLesionSwitchDialog: boolean
): string {
  const filterFunction = isLesionSwitchDialog
    ? getLesionCheckResultsToShowOnSwitch
    : getUnsuccessfulLesionCheckResults;

  const isSingleLesion = results.length === 1;
  const totalIssues = results.reduce(
    (total, { results }) => total + filterFunction(results).length,
    0
  );

  if (totalIssues === 0) {
    return "";
  }

  const isSingleIssue = totalIssues === 1;

  return `${isSingleLesion ? "This" : "These"} label${isSingleLesion ? "" : "s"} ${
    isSingleLesion ? "is" : "are"
  } incomplete. Please address the following issue${isSingleIssue ? "" : "s"}.`;
}
