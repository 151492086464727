import { ApolloError, gql, useQuery } from "@apollo/client";

import { parseProjectDetails } from "../../Dashboard/utils/parseProjectDetails";
import { PROJECT_DETAILS_FRAGMENT } from "../../Project/contexts/Project/ProjectDetailsFragment";
import { ProjectType } from "../types/ProjectType";
import { RawProjectDetailsType } from "../types/RawProjectDetailsType";

type useAllUsersProjectsResultType = {
  projects?: ProjectType[];
  error?: ApolloError;
  loading: boolean;
};

type Variables = { userId: number };

export const GET_USER_PROJECTS_QUERY = gql`
  query GetProject($userId: Int!) {
    project(where: { project_users: { user_id: { _eq: $userId } }, disabled: { _eq: false } }) {
      ...ProjectDetails
    }
  }
  ${PROJECT_DETAILS_FRAGMENT}
`;

export function useAllUsersProjects(userId: number): useAllUsersProjectsResultType {
  const { data, loading, error } = useQuery<RawProjectDetailsType, Variables>(
    GET_USER_PROJECTS_QUERY,
    {
      variables: { userId },
      fetchPolicy: "network-only",
    }
  );

  let projects;

  if (!loading && !error && data) {
    projects = parseProjectDetails(data);
  }

  return {
    projects,
    loading,
    error,
  };
}
