import { ApolloCache, useMutation } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { MutationTuple } from "@apollo/client/react/types/types";

import { LesionTypeType } from "../../../../../types/LesionTypeType";
import { getCacheId } from "../../../../Manage/cache/getCacheId";
import {
  GetLesionsListDataType,
  GetLesionsListVariablesType,
  LESION_LIST_ITEM_FRAGMENT,
  LesionListItemFragmentType,
  RoiQueryConditionType,
} from "../fragments/LesionListItemFragment";
import { getLesionsListFromCache } from "./getLesionsListFromCache";
import { getSeriesFollowUpIdFromCache } from "./getSeriesFollowUpIdFromCache";
import {
  getTumourAndDiagnosisVariables,
  TumourAndDiagnosisVariables,
} from "./getTumourAndDiagnosisVariables";
import { useLesionListQueryInput } from "./useLesionListQueryInput";
import { GET_LESIONS_LIST, getVariables as getLesionsListVariables } from "./useLesionsList";

const MUTATION = gql`
  mutation InsertLesion(
    $lesion: lesion_insert_input!
    $roiCondition: roi_bool_exp!
    $skipRecist: Boolean!
  ) {
    insert_lesion_one(object: $lesion) {
      ...LesionListItem
    }
  }
  ${LESION_LIST_ITEM_FRAGMENT}
`;

type Variables = {
  roiCondition: RoiQueryConditionType;
  skipRecist: boolean;
  lesion: {
    task_id: number;
    patient_id: number;
    name: string;
    created_by: number;
    type: LesionTypeType;
    location: number | null;
    rois: {
      data: {
        series_id: number;
        created_by: number;
        tumour_roi_maps?: {
          data: TumourAndDiagnosisVariables;
        };
      }[];
    };
  };
};

type Input<T> = {
  taskId: number;
  roiCondition: RoiQueryConditionType;
  patientId: number;
  name: string;
  createdBy: number;
  type: LesionTypeType;
  location: number;
  seriesIds: number[];
  isRecist: boolean;
  burden?: number;
  cache: ApolloCache<T>;
};

type Data = { insert_lesion_one: LesionListItemFragmentType };

export function useInsertLesion(): MutationTuple<Data, Variables> {
  const lesionListQueryInput = useLesionListQueryInput();

  return useMutation<Data, Variables>(MUTATION, {
    update(cache, { data }) {
      if (!data) {
        throw new Error("Something went wrong updating the cache after inserting a lesion");
      }

      const { insert_lesion_one: lesionFragment } = data;

      const { id: lesionId, taskId } = lesionFragment;

      if (taskId === null) {
        throw new Error("Inserting lesions with no task id is not currently supported");
      }

      const existingLesions = getLesionsListFromCache(lesionListQueryInput, cache);

      const variables = getLesionsListVariables(lesionListQueryInput);
      cache.writeQuery<GetLesionsListDataType, GetLesionsListVariablesType>({
        query: GET_LESIONS_LIST,
        variables,
        data: { lesions: [...existingLesions, lesionFragment] },
      });

      const taskCacheId = getCacheId(taskId, "task", cache);

      cache.modify({
        id: taskCacheId,
        fields: {
          lesions(existingLesions = []) {
            return [
              {
                __typename: "lesion",
                lesion_id: lesionId,
              },
              ...existingLesions,
            ];
          },
        },
      });
    },
  });
}

export function getVariables<T>({
  taskId,
  roiCondition,
  patientId,
  name,
  createdBy: created_by,
  type,
  location,
  seriesIds,
  isRecist,
  burden,
  cache,
}: Input<T>): Variables {
  const rois = seriesIds.map((series_id) => {
    const followUpId = isRecist ? getSeriesFollowUpIdFromCache(series_id, cache) : undefined;
    return {
      series_id,
      created_by,
      ...(isRecist && {
        tumour_roi_maps: {
          data: getTumourAndDiagnosisVariables(burden, followUpId),
        },
      }),
    };
  });
  return {
    skipRecist: !isRecist,
    roiCondition,
    lesion: {
      task_id: taskId,
      patient_id: patientId,
      name,
      created_by,
      type,
      location,
      rois: { data: rois },
    },
  };
}
