import React from "react";
import styled from "styled-components";

import { FollowUpType } from "../../common/types/FollowUpType";
import { formatMonthsRounded } from "../../common/utils/formatMonthsRounded";
import { getFollowUpInMonths } from "../../common/utils/getFollowUpInMonths";
import { TEXT_OPACITY } from "../../common/utils/textOpacity";
import { SubjectImagePanel } from "./SubjectImagePanel";

const StyledLabelContentsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  opacity: ${() => TEXT_OPACITY};
`;

const StyledLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

interface SubjectAxisTickProps {
  x: number;
  y: number;
  followUp?: FollowUpType;
  patientId: number;
}

export const SubjectAxisTick = ({
  x,
  y,
  followUp,
  patientId,
}: SubjectAxisTickProps): React.ReactElement => {
  const width = 100;
  const height = 150;

  if (!followUp) {
    return <></>;
  }

  const tickLabel = formatMonthsRounded(getFollowUpInMonths(followUp.order));

  return (
    <svg x={x - width / 2} y={y} width={width} height={height}>
      <foreignObject width={width} height={height}>
        <StyledLabelWrapper // @ts-ignore
          xmlns="http://www.w3.org/1999/xhtml"
        >
          <StyledLabelContentsWrapper>{tickLabel}</StyledLabelContentsWrapper>
          <SubjectImagePanel
            maxImageHeight={130}
            maxImageWidth={100}
            followUp={followUp}
            patientId={patientId}
          />
        </StyledLabelWrapper>
      </foreignObject>
    </svg>
  );
};
