import { debounce } from "lodash";
import React, { useState } from "react";

import { ProjectType } from "../common/types/ProjectType";
import { useCurrentUserAllowedItems } from "../Project/Layout/hooks/useCurrentUserAllowedItems";
import { useGoToProject } from "../Project/Layout/hooks/useGoToProject";
import { useLayout } from "../Project/Layout/hooks/useLayout";
import { ActionButtonType } from "../Project/Layout/Layout";
import { ActionButton } from "../Project/Layout/TopBar/ActionButton";
import { AllProjectsContainer } from "./components/Project/AllProjectsContainer";
import { RecentProjectsContainer } from "./components/Project/RecentProjectsContainer";
import {
  ActionButtonWrapper,
  ActionPanelWrapper,
  CardWrapper,
  SearchWrapper,
  StyledSearchField,
} from "./Dashboard";

export function ProjectSelection(): JSX.Element {
  const [filter, setFilter] = useState("");
  const goToProject = useGoToProject();
  const { dashboard } = useLayout();
  const { buttons, showKaplanMeierPreview } = dashboard ?? {
    buttons: [],
    showKaplanMeierPreview: false,
  };

  const handleClickProject = ({ legacyTrialId }: ProjectType) => {
    if (legacyTrialId === undefined) {
      throw new Error("Legacy trial id should not be undefined here");
    }
    goToProject(legacyTrialId);
  };

  const handleSearch = debounce((event) => {
    setFilter(event.target.value);
  }, 250);

  const filteredButtons = useCurrentUserAllowedItems<ActionButtonType>()(buttons);

  return (
    <>
      <ActionPanelWrapper>
        <SearchWrapper>
          <StyledSearchField size={40} onChange={handleSearch} />
        </SearchWrapper>
        {filteredButtons.map(({ action }, index) => (
          <ActionButtonWrapper key={index}>
            <ActionButton action={action} />
          </ActionButtonWrapper>
        ))}
      </ActionPanelWrapper>
      <CardWrapper>
        <RecentProjectsContainer
          filter={filter}
          onClickProject={handleClickProject}
          showKaplanMeierPreview={showKaplanMeierPreview}
        />
        <AllProjectsContainer filter={filter} onClickProject={handleClickProject} />
      </CardWrapper>
    </>
  );
}
