import { ApolloCache } from "@apollo/client";

import { DiameterPointsType } from "../../../../AnnotationPanel/fragments/RoiRecistEvaluationFragment";
import { ContourDiametersDataType } from "../../../../AnnotationPanel/Stats/utils/getRoiContourDiameters";
import { getRoiVolumeMm3 } from "../../../../AnnotationPanel/Stats/utils/getRoiVolumeMm3";
import { getTotalHUStats } from "../../../../AnnotationPanel/Stats/utils/getTotalHUStats";
import { getInstanceIdFromImageIdFromCache } from "../../../selection/getInstanceIdFromImageIdFromCache";

export type RoiRecistEvaluationVariablesType = {
  roi_id: number;
  long_axis_diameter: number | undefined;
  short_axis_diameter: number | undefined;
  long_axis_diameter_points: DiameterPointsType | undefined;
  short_axis_diameter_points: DiameterPointsType | undefined;
  instance_id: number | undefined;
  volume: number | undefined;
  hu_min: number | undefined;
  hu_max: number | undefined;
  hu_mean: number | undefined;
};

export function getRoiRecistEvaluationVariables<T>(
  roiId: number,
  seriesId: number,
  contourDiametersData: ContourDiametersDataType | undefined,
  disableMeasurements: boolean,
  cache: ApolloCache<T>
): RoiRecistEvaluationVariablesType {
  const { imageId: diametersImageId } = contourDiametersData || {};

  const diametersInstanceId = diametersImageId
    ? getInstanceIdFromImageIdFromCache(seriesId, diametersImageId, cache)
    : undefined;

  if (diametersInstanceId === null) {
    throw new Error(`Unable to find instanceId for imageId: ${diametersImageId}`);
  }

  const volume = getRoiVolumeMm3(roiId, seriesId, cache);

  const huStats = getTotalHUStats(roiId);

  const {
    LAD = undefined,
    SAD = undefined,
    SADPoints = undefined,
    LADPoints = undefined,
  } = !disableMeasurements ? contourDiametersData ?? {} : {};

  return {
    roi_id: roiId,
    long_axis_diameter: LAD,
    short_axis_diameter: SAD,
    long_axis_diameter_points: LADPoints,
    short_axis_diameter_points: SADPoints,
    instance_id: diametersInstanceId,
    volume,
    hu_min: huStats?.min,
    hu_max: huStats?.max,
    hu_mean: huStats?.average,
  };
}
