import React, { useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import styled from "styled-components";

import { ToolbarRadioButton } from "../../../../../Annotate/components/Annotate/page/ViewerPanel/toolbar/ToolbarRadioButton";
import { WindowLevelButton } from "../../../../../Annotate/components/Annotate/page/ViewerPanel/toolbar/WindowLevelButton";
import { windowLevelPresets } from "../../../../../Annotate/components/Annotate/page/ViewerPanel/toolbar/windowLevelPresets";
import { useActiveTool } from "../../../../../Annotate/components/Annotate/page/ViewerPanel/Viewer/hooks/useActiveTool";
import { useSetActiveTool } from "../../../../../Annotate/components/Annotate/page/ViewerPanel/Viewer/hooks/useSetActiveTool";
import { useSetAllViewersToolQueue } from "../../../../../Annotate/components/Annotate/page/ViewerPanel/Viewer/hooks/useSetAllViewersToolQueue";
import { ReactComponent as Pan } from "../../../../../assets/svgs/Pan.svg";
import { ReactComponent as Search } from "../../../../../assets/svgs/Search.svg";
import { WINDOW_LEVEL_PRESET_QUEUE } from "../../../../../common/store/annotatePage/ViewerConfigType";
import { WindowLevelPresetType } from "../../../../../common/store/annotatePage/WindowLevelPresetType";
import { PAN, ToolType, WINDOWLEVEL, ZOOM } from "../../../../../cornerstone/ToolType";
import { ANNOTATION_SCOPE } from "../../../../../Project/Layout/Layout";

const Wrapper = styled.div`
  flex: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 8px 20px 8px 20px;
  gap: 20px;
  border-radius: 6px;
  pointer-events: auto;
`;

export function Toolbar(): JSX.Element {
  const { activeTool } = useActiveTool();
  const setActiveTool = useSetActiveTool();
  const setAllViewersToolQueue = useSetAllViewersToolQueue();
  const [preset, setPreset] = useState<WindowLevelPresetType | undefined>(undefined);

  const handleToolChanged = (tool: ToolType) => {
    setActiveTool(tool);
  };

  const handleWindowLevelPresetSelected = (preset: WindowLevelPresetType) => {
    setAllViewersToolQueue({
      queue: WINDOW_LEVEL_PRESET_QUEUE,
      value: preset,
    });
  };

  useHotkeys(
    "h",
    () => {
      handleToolChanged(PAN);
    },
    { scopes: [ANNOTATION_SCOPE] }
  );

  useHotkeys(
    "z",
    () => {
      handleToolChanged(ZOOM);
    },
    { scopes: [ANNOTATION_SCOPE] }
  );

  useHotkeys(
    "w",
    () => {
      if (activeTool !== WINDOWLEVEL) {
        handleToolChanged(WINDOWLEVEL);
      } else {
        if (!preset) {
          handleWindowLevelPresetSelected(windowLevelPresets[0]);
          setPreset(windowLevelPresets[0]);
        } else {
          const newPresetIndex =
            windowLevelPresets.findIndex(({ name }) => name === preset.name) + 1;
          handleWindowLevelPresetSelected(
            windowLevelPresets[newPresetIndex !== windowLevelPresets.length ? newPresetIndex : 0]
          );
          setPreset(
            windowLevelPresets[newPresetIndex !== windowLevelPresets.length ? newPresetIndex : 0]
          );
        }
      }
    },
    { scopes: [ANNOTATION_SCOPE] }
  );

  return (
    <Wrapper>
      <WindowLevelButton
        value={WINDOWLEVEL}
        currentValue={activeTool}
        onChange={handleToolChanged}
        presets={windowLevelPresets}
        onPresetSelected={handleWindowLevelPresetSelected}
        key={WINDOWLEVEL}
        tooltip={"Window Level (W)"}
      />
      <ToolbarRadioButton
        icon={Search}
        value={ZOOM}
        currentValue={activeTool}
        onChange={handleToolChanged}
        key={ZOOM}
        tooltip={"Zoom (Z)"}
      />
      <ToolbarRadioButton
        icon={Pan}
        value={PAN}
        currentValue={activeTool}
        onChange={handleToolChanged}
        key={PAN}
        tooltip={"Pan (H)"}
      />
    </Wrapper>
  );
}
