import React from "react";
import styled from "styled-components";

import { AnnotateViewer } from "./AnnotateViewer";
import { PrefetchProgressIndicator } from "./PrefetchProgressIndicator";
import { ViewerToolbar } from "./toolbar/ViewerToolbar";
import { ViewersGrid } from "./ViewersGrid";

const Wrapper = styled.div`
  flex: 27;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 3px;
`;

const ToolbarWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  z-index: 1000;
  pointer-events: none;
`;

const PrefetchProgressWrapper = styled.div`
  padding: 3px 0 0 0;
`;

export function ViewerPanel(): JSX.Element {
  return (
    <Wrapper>
      <ToolbarWrapper>
        <ViewerToolbar />
      </ToolbarWrapper>
      <ViewersGrid Viewer={AnnotateViewer} />
      <PrefetchProgressWrapper>
        <PrefetchProgressIndicator />
      </PrefetchProgressWrapper>
    </Wrapper>
  );
}
