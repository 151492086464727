import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { useCurrentUser } from "../../../../../../../common/contexts/UserContext/useCurrentUser";
import {
  hiddenLesionIdsSelector,
  selectedLesionIdSelector,
} from "../../../../../../../common/store/annotatePage/selectionSelector";
import {
  drawMissingRoisOptionSelector,
  includeCalculateContourMeasurementsSelector,
} from "../../../../../../../common/store/annotatePage/taskSelector";
import {
  CANCEL_QUEUE,
  DRAW_QUEUE,
  MEASURE_QUEUE,
  ViewerConfigType,
  WINDOW_LEVEL_PRESET_QUEUE,
} from "../../../../../../../common/store/annotatePage/ViewerConfigType";
import { ImageViewerSynchronizer } from "../../../../../../../cornerstone/ImageViewerSynchronizer";
import { MEASURE } from "../../../../../../../cornerstone/ToolType";
import { TaskContext } from "../../../../../../TaskContext";
import { useGetToolControllerRoi } from "../../../AnnotationPanel/hooks/useGetToolControllerRoi";
import { useLesionsList } from "../../../AnnotationPanel/hooks/useLesionsList";
import { getSelectedLesion } from "../../selection/getSelectedLesion";
import { getVisibleRoiIds } from "../../selection/getVisibleRoiIds";
import { useViewerViewOnly } from "../../useViewerViewOnly";
import { getRoi } from "../../utils/getRoi";
import { useActiveTool } from "../../Viewer/hooks/useActiveTool";
import { useSetViewerToolQueue } from "../../Viewer/hooks/useSetViewerToolQueue";
import { useContourDoubleClicked } from "../contour/hooks/useContourDoubleClicked";
import { useContourRightClicked } from "../contour/hooks/useContourRightClicked";
import { useContourUpdated } from "../contour/hooks/useContourUpdated";
import { ContourContextMenuData } from "../contour/types/ContourContextMenuDataType";
import { useEllipseDoubleClicked } from "../ellipse/hooks/useEllipseDoubleClicked";
import { useEllipseRightClicked } from "../ellipse/hooks/useEllipseRightClicked";
import { useEllipseUpdated } from "../ellipse/hooks/useEllipseUpdated";
import { EllipseClickDataType } from "../ellipse/types/EllipseClickDataType";
import ToolController from "../legacy/ToolController";
import { NEW_FINDING_ROI_ID } from "./NewFindingRoiId";
import { ToolControllerType } from "./ToolControllerType";

export type ToolControllerCallbacks = {
  onContourRightClicked: (data: ContourContextMenuData) => void;
  onEllipseRightClicked: (toolData: EllipseClickDataType) => void;
};

// FIXME: There are 2 ToolControllers, 2 useToolControllers, and an AnnotateViewerToolController. See #185044274
// --B

export function useToolController(
  viewerConfig: ViewerConfigType,
  element: HTMLDivElement | null,
  { onContourRightClicked, onEllipseRightClicked }: ToolControllerCallbacks
): void {
  const {
    task: { id: currentTaskId },
  } = useContext(TaskContext);

  const [toolError, setToolError] = useState<Error | null>(null);
  const [toolController, setToolController] = useState<ToolControllerType | null>(null);

  const { id: userId } = useCurrentUser();

  const elementRef = useRef<HTMLDivElement>();

  const { activeTool, lastActiveTool } = useActiveTool();
  const selectedLesionId = useSelector(selectedLesionIdSelector);
  const hiddenLesionIds = useSelector(hiddenLesionIdsSelector);
  const calculateMeasurements = useSelector(includeCalculateContourMeasurementsSelector);
  const drawMissingRoisOption = useSelector(drawMissingRoisOptionSelector);

  const { data: lesionsData } = useLesionsList();

  const viewOnly = useViewerViewOnly(viewerConfig);

  const setViewerToolQueue = useSetViewerToolQueue();

  const { toolQueues, seriesId } = viewerConfig;

  const onToolExceptionThrown = useCallback((e: Error) => {
    setToolError(e);
  }, []);

  useContourUpdated(toolController, seriesId);
  useEllipseUpdated(toolController, seriesId);
  useContourRightClicked(toolController, onContourRightClicked);
  useEllipseRightClicked(toolController, onEllipseRightClicked);
  useEllipseDoubleClicked(toolController);
  useContourDoubleClicked(toolController);

  const getToolControllerRoi = useGetToolControllerRoi();

  /*
  errors from the tool controller occur on event listeners and therefore do not propagate to the main thread. this mechanism is used to ensure that the errors are propagated so that users see the error screen when they occur to prevent further usage of problematic tool output
   */
  useEffect(() => {
    if (!toolError) {
      return;
    }

    throw toolError;
  }, [toolError]);

  useEffect(() => {
    elementRef.current = element ?? undefined;
    if (!element) {
      return;
    }

    const imageViewerSynchronizer = ImageViewerSynchronizer.getInstance();
    imageViewerSynchronizer.add(element);

    let toolController: ToolController;
    try {
      toolController = new ToolController(element, calculateMeasurements, onToolExceptionThrown);
    } catch (e) {
      console.error("ToolController failed to load", e);
      return;
    }

    setToolController(toolController);
  }, [element, calculateMeasurements]);

  useEffect(() => {
    return () => {
      const element = elementRef.current;
      if (!element) {
        return;
      }

      const imageViewerSynchronizer = ImageViewerSynchronizer.getInstance();
      imageViewerSynchronizer.remove(element);
    };
  }, []);

  useEffect(() => {
    if (activeTool === lastActiveTool) {
      return;
    }
    toolController?.handleActiveToolChanged(activeTool, lastActiveTool);
  }, [activeTool, lastActiveTool]);

  useEffect(() => {
    if (!toolController || !toolQueues) {
      return;
    }

    const { id: viewerConfigId } = viewerConfig;
    const cancel = toolQueues?.[CANCEL_QUEUE];
    if (cancel) {
      toolController.cancelActiveTool();
      setViewerToolQueue({
        viewerConfigId,
        queue: CANCEL_QUEUE,
        value: false,
      });
    }

    const draw = toolQueues?.[DRAW_QUEUE];
    if (draw) {
      toolController.forceRedraw();
      setViewerToolQueue({ viewerConfigId, queue: DRAW_QUEUE, value: false });
    }

    const windowLevelPreset = toolQueues?.[WINDOW_LEVEL_PRESET_QUEUE];
    if (windowLevelPreset) {
      const { window, level } = windowLevelPreset;
      toolController.setWindowLevel(window, level);
      setViewerToolQueue({
        viewerConfigId,
        queue: WINDOW_LEVEL_PRESET_QUEUE,
        value: null,
      });
    }

    const measure = toolQueues?.[MEASURE_QUEUE];
    //It appears that the speed at which updates to activeTool and MEASURE_QUEUE are changed can result in
    // the output of this nested conditional block, notably if toolQueues?.[MEASURE_QUEUE] is true before
    // activeTool is MEASURE then this will set toolQueues?.[MEASURE_QUEUE] back to false before setActiveTool
    // has a chance to fire (timing currently resolved by changing activeTool before MEASURE_QUEUE)
    // -Samantha Persico
    if (!measure) {
      toolController.setPassiveTool(MEASURE);
    } else {
      if (activeTool === MEASURE) {
        toolController.setActiveTool(activeTool);
      } else {
        toolController.setPassiveTool(MEASURE);
        setViewerToolQueue({
          viewerConfigId,
          queue: MEASURE_QUEUE,
          value: false,
        });
      }
    }
  }, [toolController, toolQueues, activeTool]);

  useEffect(() => {
    if (!lesionsData) {
      return;
    }

    const { lesions } = lesionsData;

    const selectedLesion = getSelectedLesion(lesions, selectedLesionId);

    const roi = getRoi(selectedLesion, viewerConfig);
    const visibleRoiIds = getVisibleRoiIds(lesions, hiddenLesionIds);

    const canDrawMissingRois = !!drawMissingRoisOption;

    // insert a "magic" roi if the roi does not exist so it can be handled upstream
    const toolControllerRoi =
      canDrawMissingRois && selectedLesion
        ? getToolControllerRoi(
            roi?.id ?? NEW_FINDING_ROI_ID,
            roi?.series.id ?? NEW_FINDING_ROI_ID,
            selectedLesion,
            userId
          )
        : undefined;

    toolController?.selectRoi(toolControllerRoi, activeTool, visibleRoiIds, viewOnly);
  }, [
    toolController,
    viewerConfig.seriesId,
    hiddenLesionIds,
    selectedLesionId,
    activeTool,
    lesionsData,
    currentTaskId,
    viewOnly,
  ]);

  useEffect(() => {
    if (!lesionsData) {
      return;
    }

    const { lesions } = lesionsData;
    const visibleToolRoiIds = getVisibleRoiIds(lesions, hiddenLesionIds);
    const toolRoiSavedStatuses: number[] = [];
    const selectedContourToolRoiId: number | undefined = undefined;

    toolController?.setToolRoiInfo({
      toolRoiSavedStatuses,
      selectedContourToolRoiId,
      visibleToolRoiIds,
    });
  }, [toolController, viewerConfig, selectedLesionId, hiddenLesionIds, lesionsData]);
}
