import Tooltip from "@mui/material/Tooltip";
import React from "react";

import {
  FLAGGED,
  NOT_FLAGGED,
  SeriesFlag,
  SeriesFlagProps,
} from "../../../../../common/components/SeriesFlags/SeriesFlag";
import { SeriesListItemFragmentType } from "../AnnotationPanel/hooks/useStudiesList";
import { IconButton } from "../AnnotationPanel/IconButton";
import { useCreateSeriesFlagForm } from "./hooks/useCreateSeriesFlagForm";
import { useTaskSeriesFlags } from "./hooks/useTaskSeriesFlags";

interface SeriesFlagButtonProps extends Omit<SeriesFlagProps, "flagType"> {
  series: SeriesListItemFragmentType;
}

export function SeriesFlagButton({ series, ...flagProps }: SeriesFlagButtonProps): JSX.Element {
  const filteredSeriesFlags = useTaskSeriesFlags(series);

  const handleFlagClicked = () => {
    showCreateSeriesFlagForm(true);
  };

  const [showCreateSeriesFlagForm, { dialog: createSeriesFlagForm }] =
    useCreateSeriesFlagForm(series);

  return <>
    {createSeriesFlagForm}
    <Tooltip title={"Flag series"}>
      <IconButton onClick={handleFlagClicked}>
        {
          <SeriesFlag
            flagType={filteredSeriesFlags.length > 0 ? FLAGGED : NOT_FLAGGED}
            {...flagProps}
          />
        }
      </IconButton>
    </Tooltip>
  </>;
}
