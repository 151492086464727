import { useQuery } from "@apollo/client";
import { ApolloQueryResult } from "@apollo/client/core/types";
import { useMemo } from "react";

import { ParsedQueryArgs } from "./ParsedQueryArgs";
import { useParsedQueryReturnType } from "./useParsedQueryReturnType";

export function useParsedQuery<TInput, TOutput, TData, TVariables>({
  query,
  input,
  options,
  parseVariables,
  parseData,
}: ParsedQueryArgs<TInput, TOutput, TData, TVariables>): useParsedQueryReturnType<
  TInput,
  TOutput,
  TVariables
> {
  const variables = parseVariables ? parseVariables(input) : undefined;

  const { data, refetch, loading, error, ...otherResults } = useQuery<TData, TVariables>(query, {
    ...options,
    variables,
  });

  const output: TOutput | undefined = useMemo(() => {
    if (!loading && !error) {
      if (!data) {
        throw new Error("data null but not loading/error in useParsedQuery");
      }

      return parseData(data, input);
    }
  }, [data, loading, error, parseData, input]);

  const refetchCallback: (newInput?: TInput) => Promise<ApolloQueryResult<TOutput>> = async (
    newInput
  ) => {
    const variables = newInput && parseVariables ? parseVariables(newInput) : undefined;
    const { data, ...otherResults } = await refetch(variables);
    const output = parseData(data, input);
    return { ...otherResults, data: output };
  };

  return { data: output, loading, error, refetch: refetchCallback, ...otherResults };
}
