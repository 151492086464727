import { createContext, PropsWithChildren, useCallback, useContext } from "react";
import { Link } from "react-router-dom";

import { ProjectContext } from "../../../Project/contexts/ProjectContext";
import { RoutePathSingleOrArray } from "../../../Project/Layout/Layout";
import { PATIENT_SLUG } from "../../../Project/Layout/RoutePathType";

type PatientRouteGenerator = (patientID: number) => string;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const defaultGenerator = (patientID: number) => {
  throw new Error(
    "PatientRouteContext is not initialized, did you forget to wrap your app in a <PatientRouteProvider />?"
  );
};

const PatientRouteContext = createContext<PatientRouteGenerator>(defaultGenerator);

interface PatientRouteProviderProps {
  patientRoute?: RoutePathSingleOrArray;
}

export const PatientRouteProvider = ({
  patientRoute,
  children,
}: PropsWithChildren<PatientRouteProviderProps>) => {
  const { getRoute } = useContext(ProjectContext);

  const makePatientRoute = useCallback(
    (patientID: number) => {
      if (patientRoute === undefined || !Array.isArray(patientRoute)) {
        throw new Error("patientRoute is not defined or is not an array");
      }

      return getRoute(patientRoute.map((route) => (route === PATIENT_SLUG ? patientID : route)));
    },
    [getRoute, patientRoute]
  );

  return (
    <PatientRouteContext.Provider value={makePatientRoute}>{children}</PatientRouteContext.Provider>
  );
};

interface PatientLinkProps {
  patientID: number;
}

export const PatientLink = ({ patientID, children }: PropsWithChildren<PatientLinkProps>) => {
  const getPatientRoute = useContext(PatientRouteContext);
  const location = getPatientRoute(patientID);

  return (
    <Link to={location} target="_blank" rel="noopener noreferrer">
      {children}
    </Link>
  );
};
