import React, { FC } from "react";
import { FaChevronRight } from "react-icons/fa";
import styled from "styled-components";

import { SvgIcon } from "../../../../../common/components/icons/SvgIcon";
import { main } from "../../../../../common/theme/main";
import { useGoToTask } from "../../../../../Project/Layout/AnnotatePage/useGoToTask";
import { useSubjectIdParam } from "../../../../../Project/utils/useSubjectIdParam";
import { PatientMutableTaskType } from "../../../Manage/hooks/TaskType";
import { useProjectPatientTaskRows } from "../../../Manage/hooks/useProjectPatientTaskRows";
import { TaskProgressWithUser } from "../../../../../common/components/TaskStatus/TaskProgressLabel";
import { TASK_IN_PROGRESS } from "../../../TaskWorklist/TaskProgressType";
import { orderByAssigned } from "../../../TaskWorklist/TaskWorklistTableRowType";

const Wrapper = styled.div`
  padding: 0 12px 2px 12px;
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

const TitleWrapper = styled.div`
  font-size: 18px;
  font-weight: bolder;
  margin: 12px 0 12px 0;
  padding: 0px 6px;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding: 12px;
  position: relative;

  box-sizing: border-box;

  height: unset;
  min-height: 40px;
  min-width: 200px;

  background: ${(props) => props.theme.colors.background.secondary};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 12px;

  cursor: pointer;
`;

const HeaderWrapper = styled.div`
  font-style: normal;
  font-weight: bolder;
  cursor: pointer;
  margin-bottom: 6px;
`;

const ArrowWrapper = styled.div`
  position: absolute;
  top: 40%;
  left: 90%;
`;

export const PatientTaskList: FC = () => {
  const goToTask = useGoToTask(true);

  const subjectId = useSubjectIdParam();

  const { patientsTasks } = useProjectPatientTaskRows(false);
  const patientTasks = patientsTasks?.filter(({ id }) => id === subjectId) ?? [];

  const sortedRows = [...patientTasks].sort(orderByAssigned);

  const handleTaskSelected = (patientTask: PatientMutableTaskType) => {
    const { id: patientId, task } = patientTask;
    if (!task) {
      throw new Error("No task in selected row");
    }

    const { id: taskId, projectId } = task;

    goToTask({ taskId, projectId, patientId });
  };

  return (
    <Wrapper>
      <TitleWrapper>Tasks</TitleWrapper>
      {sortedRows.map((row, index) => {
        if (row.task === undefined) {
          throw new Error("Task should not be undefined here");
        }
        return (
          <CardWrapper key={index} onClick={() => handleTaskSelected(row)}>
            <div>
              <HeaderWrapper>{row.task.name}</HeaderWrapper>
              {row.task.taskAssignments.length > 0 && (
                <TaskProgressWithUser
                  progress={{
                    taskAssignments: row.task.taskAssignments,
                    displayProgress: TASK_IN_PROGRESS,
                  }}
                />
              )}
            </div>
            <ArrowWrapper>
              <SvgIcon size={16} icon={FaChevronRight} color={main.colors.neutral.neutral3} />
            </ArrowWrapper>
          </CardWrapper>
        );
      })}
    </Wrapper>
  );
};
