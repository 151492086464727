import { useEffect, useRef } from "react";

import { ROI } from "../../../../../../../../cornerstone/ToolType";
import { ToolRoiType } from "../../../../../../../types/ToolRoiType";
import { useTrySelectLesionId } from "../../../../AnnotationPanel/hooks/useTrySelectLesionId";
import { useSetActiveTool } from "../../../Viewer/hooks/useSetActiveTool";
import { ToolControllerType } from "../../toolController/ToolControllerType";

export function useContourDoubleClicked(toolController: ToolControllerType | null): void {
  const toolControllerRef = useRef<ToolControllerType>();

  const setActiveTool = useSetActiveTool();

  const trySelectLesionId = useTrySelectLesionId();

  const handleContourDoubleClicked = (data: { toolData: { roi: ToolRoiType } }) => {
    const {
      toolData: {
        roi: { lesionId },
      },
    } = data;

    const onLesionChanged = () => {
      setActiveTool(ROI);
    };

    if (!trySelectLesionId(lesionId, onLesionChanged)) {
      return;
    }
  };

  useEffect(() => {
    toolControllerRef.current = toolController;
    toolController?.subscribeToContourDoubleClicked(handleContourDoubleClicked);

    return () => {
      const toolController = toolControllerRef.current;
      toolController?.unsubscribeFromContourDoubleClicked(handleContourDoubleClicked);
    };
  }, [toolController, handleContourDoubleClicked]);
}
