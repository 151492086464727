import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { RadioProps } from "@mui/material/Radio/Radio";
import Tooltip from "@mui/material/Tooltip";
import withStyles from "@mui/styles/withStyles";
import { capitalizeFirstLetter } from "nota-predict-web/src/Analysis/common/utils/captatlizeFirstLetter";
import { StyledInputNumber } from "nota-predict-web/src/Analysis/ToolPanel/components/FilterNumberInput";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import isEqual from "react-fast-compare";
import { Controller, useController, useFormContext } from "react-hook-form";
import { FaCalculator as ComputedValueIcon } from "react-icons/fa";
import Select, { OptionsType, ValueType } from "react-select";
import styled from "styled-components";
import { useDebounce } from "use-debounce";
import { useDeepCompareMemo } from "use-deep-compare";

import {
  BlackCheckbox,
  CheckboxWrapper,
} from "../../../../../../Analysis/ToolPanel/components/FilterCheckbox";
import { ReactComponent as RemoveIcon } from "../../../../../../assets/svgs/RemoveOutline.svg";
import { ErrorLabel } from "../../../../../../common/components/input/ErrorLabel";
import { getSelectStyle } from "../../../../../../common/components/input/getSelectStyle";
import { main } from "../../../../../../common/theme/main";
import { ActionButton } from "../../../../Manage/ActionButton";
import { RowWrapper } from "../../../../Manage/CreateTaskWizard/CreateTaskWizard";
import { isFragmentQcRule } from "../../../utils/qc-validation/schema/baseRule";
import { hasDependentQcRules } from "../../../utils/qc-validation/schema/dependentRules";
import { AnyQcRule, isOptionsQcRule } from "../../../utils/qc-validation/schema/option";
import {
  coerceToOptionType,
  coerceToPlainValue,
  coerceToSafeNumber,
  determineIfShouldShowOther,
  determineInputControlType,
  ensureArray,
  extractDefaultValue,
  friendlyComputedValue,
  getNumberArrayError,
  getSelectOptions,
  isCheckboxChecked,
  isQcFormValueEmpty,
  isRadioChecked,
  isSafeSameValue,
  qc_debugLog,
  qcOtherValueKey,
  setOptionChecked,
} from "./QcForm.utils";
import { useQualityControlContext } from "./QualityControlProvider";
import { useActiveQcReportChanged } from "./QualityControlProvider.hooks";
import {
  OptionType,
  QcFormScope,
  QcFormStateUpdatePayload,
  QcFormValue,
  TypeableQcInputEvent,
} from "./QualityControlProvider.types";

const FieldWrapper = styled.div`
  flex: 1;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  margin-right: 8px;
`;

const TitleWrapper = styled.p`
  margin-top: 10px;
  margin-bottom: -5px;
  font-size: 13px;
`;

const OptionalFieldIndicator = styled.span`
  color: ${(props) => props.theme.colors.neutral.neutral5};
`;

interface ComputedFieldIndicatorProps {
  computedValue?: QcFormValue;
  value?: QcFormValue;
}

const ComputedFieldIndicator = styled("span")<ComputedFieldIndicatorProps>((props) => {
  const { computedValue, value, theme } = props;
  const valuesAreEqual = isSafeSameValue(computedValue, value);
  return {
    color: valuesAreEqual ? theme.colors.neutral.neutral6 : theme.colors.states.info,
    fontSize: "12px",
    lineHeight: "12px",
    verticalAlign: "text-top",
    marginLeft: "4px",
    cursor: "help",
  };
});

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin-right: 0px;
    margin-left: 0px;
    gap: 6px;
  }

  & .MuiFormControlLabel-label {
    font-size: 13px;
  }
`;

const RadioWrapper = styled.div`
  display: flex;
  flex-direction: row;
  cursor: default;
`;

// Copy of "nota-predict-web/src/Analysis/ToolPanel/components/FilterInputWrapper"
// TODO: We should not have so many different styles –B
const NumberInputWrapper = styled.div`
  display: flex;
  min-width: 100px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const DependentRulesWrapper = styled.div`
  margin-top: -10px;
  margin-left: 20px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: -12px;
    left: -14px;
    height: calc(100% + 6px);
    border-left: 1px solid ${(props) => props.theme.colors.neutral.neutral6};
    z-index: -1;
  }
`;

const ButtonWrapper = styled.div`
  width: 100px;
`;

const StyledTextInput = styled(TextField)({
  "& .MuiInputBase-input": {
    fontSize: "13px",
    padding: "12px",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    "& fieldset": {
      borderColor: main.colors.neutral.neutral6,
    },
    "&:hover fieldset, &:focus-visible input, &.Mui-focused fieldset": {
      borderColor: main.colors.neutral.black,
      outline: "none",
    },
  },
});

const BlackRadio = withStyles({
  root: {
    color: main.colors.neutral.black,
    boxSizing: "border-box",
  },
})((props: RadioProps) => <Radio color="default" {...props} />);

export type QcFormElementOnChangeEvent = ({
  name,
  value,
  isValid,
  isTouched,
  isDirty,
}: {
  name: string;
  value: OptionType | OptionType[] | null;
  isValid?: boolean;
  isTouched?: boolean;
  isDirty?: boolean;
}) => void;

// const isBooleanField = (rule: ReadableQcRule): boolean => {
//   // TODO: not yet implemented
//   if (rule.input_type === "select-many") return false;
//   if (rule.input?.input_options.length !== 2) return false;
//   const validBoolishValues = ["true", "false", "yes", "no"];
//   return rule.input?.input_options.every((value) => validBoolishValues.includes(value.toLowerCase()));
// };

interface QcFormElementProps {
  rule: AnyQcRule;
  scope: QcFormScope;
  isActive: boolean;
  disabled?: boolean;
  className?: string;
  "data-rule-name"?: string;
}

const ON_CHANGE_OPTIONS = {
  shouldValidate: true,
  shouldDirty: true,
};

const DEBOUNCE_TYPING_TIMOUT = 2500;

const useValueChanged = (value: QcFormValue, onChange?: () => void) => {
  useEffect(() => {
    onChange?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
};

const useValueOrComputedChanged = (
  value: QcFormValue,
  computedValue: QcFormValue,
  onChange?: () => void
) => {
  useEffect(() => {
    onChange?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, computedValue]);
};

const useUserIsTypingChanged = (userIsTyping: boolean, onChange?: () => void) => {
  useEffect(() => {
    onChange?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIsTyping]);
};

const useIsActiveFormChanged = (isActive: boolean, onChange?: () => void) => {
  useEffect(() => {
    onChange?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);
};

export function QcFormElement({
  rule,
  scope,
  isActive,
  disabled: _disabled = false,
  ...domProps
}: QcFormElementProps): JSX.Element {
  const { updateQcFormState, storedQcReport, activeReportKey, getQcField, schema } =
    useQualityControlContext();

  // TODO: we aren't using rule.description, this should be in the Mui component's help prop
  // but we're not using MUI 5 components across the board yet
  // rule.description

  const disabled = _disabled || rule.view_only;

  const { setValue, setError, control } = useFormContext();

  const [userIsTyping, setUserIsTyping] = useState(false);

  const { field, fieldState } = useController({
    name: rule.name,
    rules: { required: rule.required },
  });

  const { field: otherField } = useController({
    name: qcOtherValueKey(rule.name),
  });

  const [debouncedOtherValue, otherValueDebouncer] = useDebounce(
    otherField.value,
    userIsTyping ? DEBOUNCE_TYPING_TIMOUT : 0
  );

  const optionRule = isOptionsQcRule(rule) ? rule : undefined;
  const [debouncedValue, valueDebouncer] = useDebounce(
    field.value,
    optionRule || !userIsTyping ? 0 : DEBOUNCE_TYPING_TIMOUT
  );

  useUserIsTypingChanged(userIsTyping, () => {
    if (!userIsTyping) {
      flushDebouncers();
    }
    const action = userIsTyping ? "is typing" : "stopped typing";
    qc_debugLog(`'${field.name}' user ${action}`, {
      value: field.value,
      debouncedValue,
      otherValue: otherField.value,
      debouncedOtherValue,
    });
  });

  const flushDebouncers = useCallback(() => {
    valueDebouncer.flush();
    otherValueDebouncer.flush();
    // qc_debugLog(`Flushing debouncers for '${field.name}'`, {
    //   value: field.value,
    //   debouncedValue,
    //   otherValue: otherField.value,
    //   debouncedOtherValue,
    // });
  }, [valueDebouncer, otherValueDebouncer]);

  const storedState = getQcField(rule.name);

  const prefilledValue = useDeepCompareMemo(
    () =>
      storedState?.computed_value
        ? friendlyComputedValue(rule.name, storedState?.computed_value)
        : extractDefaultValue(rule),
    [rule, storedState?.computed_value]
  );

  const hasComputedValue = !isQcFormValueEmpty(storedState?.computed_value);
  const hasOtherField = optionRule?.allow_other && otherField;

  const manualErrorMessage = useMemo(
    () =>
      [null, undefined, ""].includes(fieldState?.error?.message)
        ? null
        : fieldState?.error?.message,
    [fieldState?.error?.message]
  );

  const errorMessage =
    manualErrorMessage ?? rule?.error_message ?? schema?.defaults?.error_message ?? "Invalid value";

  const options = useDeepCompareMemo(
    () => getSelectOptions(optionRule?.input_options ?? [], optionRule?.allow_other ?? false),
    [optionRule?.input_options, optionRule?.allow_other]
  );

  const { inputControlType, isMulti } = useMemo(
    () => determineInputControlType(rule, options),
    [rule, options]
  );
  const shouldShowOther = useMemo(
    () => determineIfShouldShowOther(rule, field.value),
    [field.value, rule]
  );

  // PRAGMA — getSelectStyle is theoretically where we should add additional control types, but we're looking MUI v5 soon
  // and updating them seems like not a great use of time. —B
  const selectStyle = getSelectStyle<OptionType, false>();

  const handleSelectChange = (option: ValueType<OptionType, boolean>) => {
    const newValue = coerceToPlainValue(option);
    if (isEqual(newValue, field.value)) {
      return;
    }
    setValue(field.name, newValue, ON_CHANGE_OPTIONS);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const newValue = setOptionChecked(event.target.name, checked, field.value);
    if (isEqual(newValue, field.value)) {
      return;
    }
    setValue(field.name, newValue, ON_CHANGE_OPTIONS);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === field.value) {
      return;
    }
    setValue(field.name, event.target.value, ON_CHANGE_OPTIONS);
  };

  const handleRadioReset = () => {
    if (!field.value) {
      return;
    }
    setValue(field.name, null, ON_CHANGE_OPTIONS);
  };

  useActiveQcReportChanged(activeReportKey, storedQcReport?.id, () => {
    if (!activeReportKey) return;
    flushDebouncers();
    qc_debugLog(`'${field.name}' flushed debouncers`, {
      storedReportId: storedQcReport?.id,
      activeReportKey,
      name: field.name,
      value: field.value,
      debouncedValue,
      ...(hasOtherField ? { otherValue: otherField.value, debouncedOtherValue } : {}),
    });
  });

  useValueOrComputedChanged(field.value, storedState?.computed_value, () => {
    // This is specifically for number inputs to handle messy computed values
    if (inputControlType !== "number") {
      return;
    }

    const err = getNumberArrayError({
      value: field.value,
      computedValue: storedState?.computed_value,
    });
    if (err) {
      qc_debugLog(`Setting '${field.name}' error`, {
        value: field.value,
        debouncedValue,
        computedValue: storedState?.computed_value,
        err,
      });
      setError(field.name, err);
    }
  });

  const handleTextOrNumberChange = (event: TypeableQcInputEvent) => {
    setValue(field.name, event?.target.value, ON_CHANGE_OPTIONS);
    if (event?.type === "blur") {
      setUserIsTyping(false);
    }
  };

  const handleOtherTextChange = (event: TypeableQcInputEvent) => {
    setValue(otherField.name, event?.target.value, ON_CHANGE_OPTIONS);
    if (event?.type === "blur") {
      setUserIsTyping(false);
    }
  };

  useIsActiveFormChanged(isActive, () => {
    if (isActive && activeReportKey) {
      qc_debugLog(
        `'${field.name}'`,
        "isActive is now",
        isActive,
        "for active report key",
        activeReportKey
      );
    }
  });

  const buildUpdatePayload = (
    prop: "value" | "other_value",
    newValue: typeof field.value | string
  ): QcFormStateUpdatePayload | undefined => {
    // This captures changes and updates the shared form state (which is used to update the QC report)
    if (!activeReportKey) {
      // No report key, can't update QC form values.
      // qc_debugLog("No active report key, not updating", prop, {
      //   name: field.name,
      //   value: field.value,
      //   debouncedValue,
      //   otherValue,
      //   debouncedOtherValue,
      //   newValue,
      // });
      return;
    }

    if (!storedQcReport?.id) {
      // No active QC report, can't update QC form values.
      return;
    }

    if (!isActive) {
      // Not the active form, don't update.
      qc_debugLog(`'${field.name}' ${prop} changed, but is not active, skipping`, {
        activeReportKey,
        qcReportId: storedQcReport?.id,
        ...(prop === "value"
          ? { value: field.value, name: field.name }
          : { otherValue: otherField.value, name: otherField.name }),
        debouncedValue,
        debouncedOtherValue,
        newValue,
      });
      return;
    }

    if (disabled) {
      // Disabled, don't update.
      qc_debugLog(`'${field.name}' ${prop} changed, but is disabled, skipping`, {
        activeReportKey,
      });
      return;
    }

    if (
      (prop === "value" && isEqual(newValue, storedState.value)) ||
      (prop === "other_value" && isEqual(newValue, storedState.other_value))
    ) {
      // No change, don't update.
      qc_debugLog(`'${field.name}' ${prop} changed, but is the same, skipping`, {
        activeReportKey,
        qcReportId: storedQcReport?.id,
        ...(prop === "value"
          ? { value: field.value, name: field.name }
          : { otherValue: otherField.value, name: otherField.name }),
        debouncedValue,
        debouncedOtherValue,
        newValue,
        lastStoredValue: storedState._stored?.value,
        lastStoredOtherValue: storedState._stored?.other_value,
        lastStateValue: storedState?._state?.value,
        lastStateOtherValue: storedState._state?.other_value,
      });
      return;
    }

    if (newValue === undefined) {
      // Possibly in the middle of a change, don't update.
      qc_debugLog(`'${field.name}' ${prop} changed, but is undefined, skipping`, {
        activeReportKey,
        qcReportId: storedQcReport?.id,
        ...(prop === "value"
          ? { value: field.value, name: field.name }
          : { otherValue: otherField.value, name: otherField.name }),
        debouncedValue,
        debouncedOtherValue,
        newValue,
      });
      return;
    }

    const otherValuePayload =
      prop === "other_value" && optionRule?.allow_other
        ? { other_value: shouldShowOther ? newValue : null }
        : {};

    const payload: QcFormStateUpdatePayload = {
      [activeReportKey]: {
        [rule.name]: {
          value: prop === "value" ? newValue : field.value,
          ...otherValuePayload,
        },
      },
    };

    return payload;
  };

  useValueChanged(debouncedValue, () => {
    // TODO: this and the following can be simplified into a single function,
    // they are currently split this way for logging purposes. —B
    // This captures changes and updates the shared form state (which is used to update the stored QC report)
    if (!activeReportKey) return;
    const payload = buildUpdatePayload("value", debouncedValue);
    qc_debugLog(`'${field.name}' debouncedValue changed, should update state`, {
      activeReportKey,
      qcReportId: storedQcReport?.id,
      payload,
      debounceStatus: valueDebouncer.isPending() ? "pending" : "flushed",
    });
    if (payload) {
      updateQcFormState(payload);
    }
  });

  useValueChanged(debouncedOtherValue, () => {
    // This captures changes and updates the shared form state (which is used to update the stored QC report)
    if (!activeReportKey) return;
    if (!hasOtherField) return;
    const payload = buildUpdatePayload("other_value", debouncedOtherValue);
    qc_debugLog(`'${field.name}' debouncedOtherValue changed, should update state`, {
      activeReportKey,
      qcReportId: storedQcReport?.id,
      payload,
      debounceStatus: otherValueDebouncer.isPending() ? "pending" : "flushed",
    });
    if (payload) {
      updateQcFormState(payload);
    }
  });

  const currentSelectValue = useMemo(
    () => coerceToOptionType(field.value ?? prefilledValue, isMulti),
    [prefilledValue, field.value, isMulti]
  );

  const select = !["select-one", "select-many"].includes(inputControlType) ? null : (
    <Select
      ref={field.ref}
      isDisabled={disabled}
      value={currentSelectValue}
      isClearable={(!rule.required && !rule.view_only) || isMulti}
      isSearchable={true}
      isMulti={isMulti}
      options={options}
      onChange={handleSelectChange}
      menuPortalTarget={document.body}
      styles={selectStyle}
      menuPlacement={"auto"}
    />
  );

  const checkboxGroup = (options: OptionsType<OptionType>) =>
    !["checkbox"].includes(inputControlType) ? null : (
      <FormControl variant="standard" component="fieldset" ref={field.ref}>
        <FormGroup>
          {options.map((option) => {
            const optDefaultValue = Array.isArray(prefilledValue)
              ? prefilledValue
              : !isQcFormValueEmpty(prefilledValue)
              ? [prefilledValue]
              : [];
            const checked =
              isCheckboxChecked(option.value, (field.value ?? optDefaultValue) as string[]) ??
              false;
            return (
              <CheckboxWrapper key={option.value}>
                <StyledFormControlLabel
                  control={
                    <BlackCheckbox
                      name={option.value}
                      // TODO: we only support one default value string on select-many,
                      // should probably support an array of defaults  —B
                      checked={checked}
                      value={option.value}
                      onChange={handleCheckboxChange}
                      disableRipple
                      size={"small"}
                      style={{ margin: "2px 0px", padding: "0px" }}
                      disabled={disabled}
                    />
                  }
                  label={option.label}
                />
              </CheckboxWrapper>
            );
          })}
        </FormGroup>
      </FormControl>
    );

  const radioGroup = (options: OptionsType<OptionType>) =>
    inputControlType !== "radio" ? null : (
      <>
        <RadioGroup
          defaultValue={(prefilledValue as string) ?? undefined}
          onChange={handleRadioChange}
          name={field.name}
        >
          {options.map((option) => (
            <RadioWrapper key={option.value}>
              <StyledFormControlLabel
                control={
                  <BlackRadio
                    name={option.value}
                    value={option.value}
                    checked={isRadioChecked(option.value, field.value as string)}
                    disableRipple
                    size={"small"}
                    style={{ margin: "2px 0px", padding: "0px" }}
                    disabled={disabled}
                  />
                }
                label={option.label}
              />
            </RadioWrapper>
          ))}
        </RadioGroup>
        {!rule.required && !rule.view_only && (
          <ButtonWrapper>
            <ActionButton
              label={"Clear Field"}
              icon={RemoveIcon}
              onClick={handleRadioReset}
              tooltip={"Clear this optional field"}
            />
          </ButtonWrapper>
        )}
      </>
    );

  const textInput =
    inputControlType !== "text" ? null : (
      <StyledTextInput
        variant="outlined"
        type="text"
        onChange={handleTextOrNumberChange}
        onBlur={handleTextOrNumberChange}
        onFocus={() => setUserIsTyping(true)}
        size={"small"}
        value={field.value ?? ""}
        disabled={disabled}
      />
    );

  const numberValue = coerceToSafeNumber(field.value);
  const numberValueAsString = coerceToSafeNumber(field.value, true);
  const numberInput =
    inputControlType !== "number" ? null : (
      <NumberInputWrapper>
        <StyledInputNumber
          type={numberValue !== null ? "number" : "text"}
          value={numberValue ?? numberValueAsString ?? ""}
          onChange={handleTextOrNumberChange}
          onBlur={handleTextOrNumberChange}
          onFocus={() => setUserIsTyping(true)}
          name={field.name}
          disabled={disabled}
        />
      </NumberInputWrapper>
    );

  const otherTextInput = !shouldShowOther ? null : (
    <Controller
      control={control}
      name={otherField.name}
      render={() => (
        <StyledTextInput
          data-rule-name={`${otherField.name}`}
          variant="outlined"
          type="text"
          onChange={handleOtherTextChange}
          onBlur={handleOtherTextChange}
          onFocus={() => setUserIsTyping(true)}
          size={"small"}
          value={otherField.value ?? ""}
          disabled={disabled}
        />
      )}
    />
  );

  const prettyDisplayName = capitalizeFirstLetter(rule?.display_name ?? rule.name);

  return (
    <FieldWrapper className="qc-form-rule" data-rule-name={`${rule.name}`} {...domProps}>
      <Controller
        control={control}
        name={rule.name}
        defaultValue={
          ((isMulti ? ensureArray(prefilledValue) : prefilledValue) as string | string[]) ?? null
        }
        render={() => (
          <>
            <TitleWrapper>
              {prettyDisplayName}
              <OptionalFieldIndicator>
                {!rule.required && !rule.view_only && " (Optional)"}
              </OptionalFieldIndicator>
              {hasComputedValue && (
                <ComputedFieldIndicator value={field.value} computedValue={prefilledValue}>
                  <Tooltip
                    title={`Computed value: ${
                      Array.isArray(prefilledValue) ? prefilledValue.join(", ") : prefilledValue
                    }`}
                    placement={"top-start"}
                  >
                    <span>
                      <ComputedValueIcon size={14} />
                    </span>
                  </Tooltip>
                </ComputedFieldIndicator>
              )}
            </TitleWrapper>
            {select}
            {checkboxGroup(options)}
            {radioGroup(options)}
            {numberInput}
            {textInput}
          </>
        )}
      />
      {otherTextInput}
      <RowWrapper className="qc-form-validation-error">
        {fieldState.error && !disabled && <ErrorLabel>{errorMessage}</ErrorLabel>}
      </RowWrapper>

      {!hasDependentQcRules(rule) ? null : (
        <DependentRulesWrapper className="qc-form-dependent-rules">
          {rule?.dependent_rules?.map(
            (depRule) =>
              isFragmentQcRule(depRule) && (
                <QcFormElement
                  rule={depRule}
                  scope={scope}
                  key={depRule.name}
                  isActive={isActive}
                  data-rule-name={`${depRule.name}`}
                  disabled={disabled}
                />
              )
          )}
        </DependentRulesWrapper>
      )}
    </FieldWrapper>
  );
}
