import { useMutation } from "@apollo/client";
import { gql, Reference, StoreObject } from "@apollo/client/core";
import { MutationTuple } from "@apollo/client/react/types/types";

import { SERIES_CLASSIFICATION_FRAGMENT } from "../../../../fragments/SeriesClassificationFragment";
import { SeriesClassificationType } from "../../../../types/SeriesClassificationType";
import { getCacheId } from "../../../Manage/cache/getCacheId";

const MUTATION = gql`
  mutation DeleteSeriesClassifications(
    $seriesIds: [Int!]!
    $classifications: [String!]!
    $taskIds: [Int!]!
  ) {
    deleted: delete_series_classification(
      where: {
        _and: [
          { series_id: { _in: $seriesIds } }
          { classification: { _in: $classifications } }
          { task_id: { _in: $taskIds } }
        ]
      }
    ) {
      seriesClassifications: returning {
        ...SeriesClassification
      }
    }
  }
  ${SERIES_CLASSIFICATION_FRAGMENT}
`;

type Variables = {
  seriesIds: number[];
  classifications: string[];
  taskIds: number[];
};

type Data = {
  deleted: {
    seriesClassifications: SeriesClassificationType[];
  };
};

export function useDeleteSeriesClassifications(): MutationTuple<Data, Variables> {
  return useMutation<Data, Variables>(MUTATION, {
    update: (cache, { data }) => {
      if (!data) {
        return;
      }

      const {
        deleted: { seriesClassifications },
      } = data;

      seriesClassifications.forEach(({ id, seriesId, taskId }) => {
        const seriesClassificationCacheId = getCacheId(id, "series_classification", cache);
        const seriesCacheId = getCacheId(seriesId, "series", cache);
        cache.modify({
          id: seriesCacheId,
          fields: {
            series_classifications(existingClassificationRefs = [], { readField }) {
              return existingClassificationRefs.filter(
                (existingClassificationRef: Reference | StoreObject | undefined) =>
                  id !== readField("id", existingClassificationRef)
              );
            },
          },
        });

        const taskCacheId = getCacheId(taskId, "task", cache);
        cache.modify({
          id: taskCacheId,
          fields: {
            series_classifications(existingClassificationRefs = [], { readField }) {
              return existingClassificationRefs.filter(
                (existingClassificationRef: Reference | StoreObject | undefined) =>
                  id !== readField("id", existingClassificationRef)
              );
            },
          },
        });
        cache.evict({ id: seriesClassificationCacheId });
      });

      cache.gc();
    },
  });
}
