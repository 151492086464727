import Detail from "nota-predict-web/src/common/components/Detail";
import React, { FC } from "react";
import styled from "styled-components";

import { FOLLOW_UP_TEXT } from "../../../Analysis/common/utils/FollowUpText";

const PanelWrapper = styled.div`
  display: flex;
`;
interface RadiologyPanelProps {
  criteria: string;
  followUp: string;
  children: React.ReactChild | React.ReactChild[];
}

const RadiologyInfoElement = styled.div`
  flex: 1;
`;

const RadiologyPanel: FC<RadiologyPanelProps> = ({
  criteria,
  followUp,
  children,
}: RadiologyPanelProps) => {
  return (
    <PanelWrapper>
      <RadiologyInfoElement>
        <Detail title="Criteria" value={criteria} />
        <Detail title={FOLLOW_UP_TEXT} value={followUp} />
      </RadiologyInfoElement>
      <>{children}</>
    </PanelWrapper>
  );
};

export default RadiologyPanel;
