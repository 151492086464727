import { ViewerConfigType } from "../../../../../../common/store/annotatePage/ViewerConfigType";
import { LesionListItemFragmentType } from "../../AnnotationPanel/fragments/LesionListItemFragment";
import { RoiListItemFragmentType } from "../../AnnotationPanel/fragments/RoiListItemFragment";

export function getRoi(
  selectedLesion: LesionListItemFragmentType | null,
  viewerConfig: ViewerConfigType
): RoiListItemFragmentType | undefined {
  if (selectedLesion === null) {
    return undefined;
  }

  const { seriesId } = viewerConfig;

  const { rois } = selectedLesion;

  return rois.find(({ series: { id } }) => id === seriesId);
}
