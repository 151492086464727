import React from "react";

import { ReactComponent as CloseIcon } from "../../../../../assets/svgs/Close.svg";
import { SvgIcon } from "../../../../../common/components/icons/SvgIcon";
import { main } from "../../../../../common/theme/main";
import { IconButton } from "../../../../../Dashboard/components/Settings/Cohort/DeletableCohortLabel";
import { ClassificationObjectsType } from "../../../../types/TaskDescriptionType";
import { ClassificationLabel } from "./ClassificationLabel";

interface DeleteClassificationLabelProps {
  classification: string;
  availableClassifications: ClassificationObjectsType[];
  onDeleteClick: (classification: string) => void;
  readOnly: boolean;
}

export function DeleteClassificationLabel({
  classification,
  availableClassifications,
  onDeleteClick,
  readOnly,
}: DeleteClassificationLabelProps): JSX.Element {
  const classificationDefinition = availableClassifications.find(
    ({ value }) => value === classification
  );

  if (!classificationDefinition) {
    throw new Error("Classification Definition should exist for this classification!");
  }

  const {
    readOnly: classificationReadOnly,
    displayName,
    value: rawClassification,
  } = classificationDefinition;

  const canDelete = !readOnly && !classificationReadOnly;

  const {
    colors: {
      neutral: { white },
    },
  } = main;

  return (
    <ClassificationLabel text={displayName ? displayName : rawClassification}>
      {canDelete && (
        <IconButton onClick={() => onDeleteClick(classification)}>
          <SvgIcon icon={CloseIcon} size={12} color={white} />
        </IconButton>
      )}
    </ClassificationLabel>
  );
}
