import { ApolloCache, ApolloClient, useApolloClient } from "@apollo/client";
import { useContext } from "react";

import { PatientContext } from "../../../../../../Dashboard/components/Settings/Patients/Viewer/PatientContext";
import { useProjectId } from "../../../../../../Project/hooks/useProjectId";
import { TaskContext } from "../../../../../TaskContext";
import { TaskType } from "../../../../Manage/hooks/TaskType";
import { getSeriesFromCache } from "./getSeriesFromCache";
import { getStudyCacheId } from "./getStudyCacheId";
import {
  getVariables,
  STUDY_LIST_ITEM_FRAGMENT,
  StudyListItemFragmentType,
} from "./useStudiesList";

interface GetStudyFromCacheProps {
  task: TaskType;
  patientId: number;
  seriesId?: number;
  studyId?: number;
  projectId: number;
  cache: ApolloCache<StudyListItemFragmentType>;
}

const getStudyFromCache = ({
  task,
  patientId,
  seriesId,
  studyId,
  projectId,
  cache,
}: GetStudyFromCacheProps): StudyListItemFragmentType | null => {
  if (!seriesId && !studyId) {
    throw new Error("Either seriesId or studyId must be provided for getStudyFromCache");
  }

  const series = seriesId ? getSeriesFromCache(seriesId, projectId, cache) : null;
  const id = studyId || (series && series.studyId);

  if (!id) {
    throw new Error("Could not find studyId for getStudyFromCache");
  }

  return cache.readFragment<StudyListItemFragmentType>({
    fragment: STUDY_LIST_ITEM_FRAGMENT,
    id: getStudyCacheId(id, cache),
    fragmentName: "StudyListItem",
    variables: getVariables({ id: patientId, task: task }),
  });
};

export const useStudyFromCache = (props: Partial<GetStudyFromCacheProps>) => {
  const contextProjectId = useProjectId();
  const { task } = useContext(TaskContext);
  const { id: patientId } = useContext(PatientContext);
  const { cache } = useApolloClient() as ApolloClient<StudyListItemFragmentType>;

  return getStudyFromCache({
    task: task,
    patientId: patientId,
    seriesId: props.seriesId,
    studyId: props?.studyId,
    projectId: props?.projectId || contextProjectId,
    cache: props?.cache || cache,
  });
};
