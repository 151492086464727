import { useCallback, useContext } from "react";

import { DRAW_QUEUE } from "../../../../../../../common/store/annotatePage/ViewerConfigType";
import { ViewerContext } from "../ViewerContext";
import { useSetViewerToolQueue } from "./useSetViewerToolQueue";

export function useRedrawAllViewers(): () => void {
  const { viewerConfigs } = useContext(ViewerContext);
  const setViewerToolQueue = useSetViewerToolQueue();

  return useCallback(() => {
    for (const { id: viewerConfigId } of viewerConfigs) {
      setViewerToolQueue({ viewerConfigId, queue: DRAW_QUEUE, value: true });
    }
  }, [viewerConfigs, setViewerToolQueue]);
}
