import { useDialogReturnType } from "../../../../common/components/Dialog/useDialog";
import { useOkCancelForm } from "../../../../common/components/Dialog/useOkCancelForm";
import { useOkForm } from "../../../../common/components/Dialog/useOkForm";
import { useCurrentUser } from "../../../../common/contexts/UserContext/useCurrentUser";
import { TaskAssignmentType } from "../../Manage/hooks/TaskType";
import { TASK_IN_PROGRESS } from "../../TaskWorklist/TaskProgressType";
import { useUpdateTaskAssignmentProgress } from "../page/AnnotationPanel/hooks/useUpdateTaskAssignmentProgress";

interface TaskAssignmentReOpenerProps {
  taskAssignment: TaskAssignmentType;
}

export function useTaskAssignmentReOpener({
  taskAssignment,
}: TaskAssignmentReOpenerProps): useDialogReturnType {
  const { id: userId } = useCurrentUser();

  const [updateTaskAssignmentProgress] = useUpdateTaskAssignmentProgress();

  const handleConfirmReOpenTask = async () => {
    const { id } = taskAssignment;
    await updateTaskAssignmentProgress({
      variables: {
        id,
        progress: TASK_IN_PROGRESS,
      },
    });
  };

  const reopenDialog = useOkCancelForm({
    title: "Task is Completed",
    message: "This task was previously marked as completed. Would you like to re-open this task?",
    okLabel: "Re-Open",
    cancelLabel: "View",
    onOkCallback: handleConfirmReOpenTask,
  });

  const taskNotAssignedToUserDialog = useOkForm({
    title: "Task Assigned to Another User",
    message:
      "This task is assigned to another user. Only a manager can re-assign tasks. You can still view this task.",
  });

  const {
    user: { id: taskAssignmentUserId },
  } = taskAssignment;
  if (taskAssignmentUserId !== userId) {
    return taskNotAssignedToUserDialog;
  }

  return reopenDialog;
}
