import { SeriesFragmentType, StudyFragmentType } from "../StudyTable/usePatientStudies";
import { TableDataType } from "../Table/TableDataType";

export type SeriesTableRowType = TableDataType & {
  studyId: StudyFragmentType["id"];
  isTempDemoPatient: boolean;
} & SeriesFragmentType;

export function getSeriesTableRow(
  studyId: number,
  isTempDemoPatient: boolean,
  series: SeriesFragmentType
): SeriesTableRowType {
  const { seriesDate } = series;
  return {
    studyId,
    isTempDemoPatient,
    ...series,
    seriesDate: seriesDate !== "1900-01-01" ? seriesDate : null,
  };
}

export function getSeriesTableRowId(id: number): string {
  return id.toString();
}
