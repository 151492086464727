import { useApolloClient } from "@apollo/client";
import axios from "axios";
import dayjs from "dayjs";
import { matchSorter } from "match-sorter";
import React, { useState } from "react";
import { Column, Row } from "react-table";
import styled from "styled-components";

import { ReactComponent as CancelIcon } from "../../assets/svgs/CancelOutline.svg";
import { useGetAuthToken } from "../../auth0/useGetAuthToken";
import { Avatar } from "../../common/components/Avatar/Avatar";
import { ActionButton } from "../../common/components/buttons/ActionButton";
import { OK_CANCEL } from "../../common/components/Dialog/DialogActionButtonsType";
import { RED_BUTTON_OVERRIDES } from "../../common/components/Dialog/RedButtonOverrides";
import { useDialog } from "../../common/components/Dialog/useDialog";
import { SvgIcon } from "../../common/components/icons/SvgIcon";
import { formatUserFullName } from "../../common/components/UserManagement/utils/formatUserFullName";
import { useCurrentUserCan } from "../../common/contexts/UserContext/useCurrentUserCan";
import { main } from "../../common/theme/main";
import { ADMIN, PROJECT_ROLE_COORDINATOR } from "../../common/types/UserRoleType";
import { UserType } from "../../common/types/UserType";
import { DocumentTableRowType } from "./ProjectDocumentType";
import { formatFileSize } from "./utils/formatFileSize";

const DeleteButtonWrapper = styled.div`
  cursor: pointer;
`;

const UserWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 6px;
  gap: 3px;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.neutral.black};
`;

export function useDocumentTableColumns(): Column<DocumentTableRowType>[] {
  const getToken = useGetAuthToken();
  const deleteAllowed = useCurrentUserCan([ADMIN, PROJECT_ROLE_COORDINATOR]);
  const { cache } = useApolloClient();

  const [documentId, setDocumentId] = useState<number | undefined>(undefined);
  const [setConfirmDeleteDialog, { dialog: confirmDeleteDialog }] = useDialog({
    label: `Delete Document`,
    content: (
      <>
        Deleting a document is permanent and can't be undone.
        <br />
        Are you sure you would like to continue?
      </>
    ),
    options: {
      showCloseButton: false,
      actionButtonOptions: {
        type: OK_CANCEL,
        okText: "Delete",
        onOkOverride: async () => {
          const token = await getToken();
          axios
            .delete(window._env_.REACT_APP_NOTA_API_URL + "/userProjectFile/", {
              params: { id: documentId },
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((response) => {
              if (response.status === 200) {
                const cacheId = cache.identify({
                  id: documentId,
                  __typename: "user_project_file",
                });
                cache.evict({ id: cacheId });
              }
            });
          setDocumentId(undefined);
        },
        okButtonOverrides: RED_BUTTON_OVERRIDES,
      },
    },
  });

  function showConfirmDeleteDialog(id: number) {
    setConfirmDeleteDialog(true);
    setDocumentId(id);
  }

  async function handleDownload(id: number, filename: string, type: string) {
    const token = await getToken();
    axios
      .get(window._env_.REACT_APP_NOTA_API_URL + "/userProjectFile/", {
        params: { id: id },
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      })
      .then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", filename + "." + type);
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  }

  const columns: Column<DocumentTableRowType>[] = [
    {
      id: "description",
      Header: "Description",
      disableFilters: false,
      accessor: ({ description }) => description,
      // eslint-disable-next-line react/display-name
      Cell: ({ value: description }: { value: string }) => {
        return <>{description}</>;
      },
    },
    {
      id: "fileName",
      Header: "Filename",
      disableFilters: false,
      accessor: ({ filename }) => filename,
      // eslint-disable-next-line react/display-name
      Cell: ({ value: filename }: { value: string }) => {
        return <>{filename}</>;
      },
    },
    {
      id: "type",
      Header: "Type",
      disableFilters: false,
      accessor: ({ type }) => type,
      // eslint-disable-next-line react/display-name
      Cell: ({ value: type }: { value: string }) => {
        return <>{type}</>;
      },
    },
    {
      id: "size",
      Header: "Size",
      disableFilters: false,
      accessor: ({ size }) => size,
      // eslint-disable-next-line react/display-name
      Cell: ({ value: size }: { value: number }) => {
        const fileSize = formatFileSize(size);
        return <>{fileSize}</>;
      },
    },
    {
      id: "uploadDate",
      Header: "Upload Date",
      disableFilters: false,
      accessor: ({ uploadDate }) => uploadDate,
      // eslint-disable-next-line react/display-name
      Cell: ({ value: uploadDate }: { value: string }) => {
        return <>{dayjs(uploadDate).format("MMM DD, YYYY")}</>;
      },
    },
    {
      id: "uploadedBy",
      Header: "Uploaded By",
      disableFilters: false,
      filter: (rows: Row<DocumentTableRowType>[], columnIds: string[], filteredValue: string) =>
        rows.filter(
          ({
            original: {
              uploadedBy: { email, firstName, lastName },
            },
          }) =>
            [email, firstName, lastName]
              .filter((field) => field)
              .some((field) => matchSorter([field], filteredValue).length > 0)
        ),
      accessor: ({ uploadedBy }) => uploadedBy,
      // eslint-disable-next-line react/display-name
      Cell: ({ value: uploadedBy }: { value: UserType }) => {
        const displayName = formatUserFullName(uploadedBy) ?? uploadedBy.email;
        return (
          <UserWrapper>
            <Avatar user={uploadedBy} />
            <>{displayName}</>
          </UserWrapper>
        );
      },
    },
    {
      id: "download",
      Header: "Download",
      disableFilters: true,
      accessor: (file) => file,
      // eslint-disable-next-line react/display-name
      Cell: ({ value: file }: { value: DocumentTableRowType }) => {
        return (
          <ActionButton
            onClick={() => handleDownload(file.id, file.filename, file.type)}
            width={100}
          >
            Download
          </ActionButton>
        );
      },
      style: {
        width: "120px",
      },
    },
  ];

  if (deleteAllowed) {
    columns.push({
      id: "delete",
      Header: "Delete",
      disableFilters: true,
      accessor: ({ id }) => id,
      // eslint-disable-next-line react/display-name
      Cell: ({ value: id }: { value: number }) => {
        return (
          <>
            {confirmDeleteDialog}
            <DeleteButtonWrapper onClick={() => showConfirmDeleteDialog(id)}>
              <SvgIcon color={main.colors.states.error} icon={CancelIcon} size={16} />
            </DeleteButtonWrapper>
          </>
        );
      },
      style: {
        width: "50px",
      },
    });
  }

  return columns;
}
