import cornerstoneTools from "@altis-labs/cornerstone-tools";

import { MEASURE } from "../../../../../../../cornerstone/ToolType";

export default class LengthTool {
  constructor(cornerstoneElement) {
    this.onDataUpdate = null;
    this.toolName = MEASURE;

    const tool = cornerstoneTools.getToolForElement(cornerstoneElement, this.toolName);

    if (!tool) {
      throw new Error("Cornerstone length tool is null");
    }

    cornerstoneElement.addEventListener(
      cornerstoneTools.EVENTS.MEASUREMENT_COMPLETED,
      this.onLengthChanged,
      false
    );

    this.tool = tool;
    this.cornerstoneElement = cornerstoneElement;
  }

  onLengthChanged = async (event) => {
    if (!this.onDataUpdate) {
      return;
    }

    await this.onDataUpdate(event);
  };

  setActive() {
    cornerstoneTools.setToolActiveForElement(this.cornerstoneElement, this.toolName, {
      mouseButtonMask: 1,
    });
  }

  setPassive = () => {
    cornerstoneTools.setToolPassiveForElement(this.cornerstoneElement, this.toolName, {
      mouseButtonMask: 1,
    });
  };

  setOnLengthChangedCallback(onDataUpdateCallback) {
    this.onDataUpdate = onDataUpdateCallback;
  }
}
