import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { StudyTable } from "../../../common/components/StudyTable/StudyTable";
import { INCLUDED_HEADER_ID } from "../../../common/components/StudyTable/StudyTableColumns";
import { useStudyRowsFromSubjects } from "../../../common/components/StudyTable/useStudyRowsFromSubjects";
import { seriesSelectionActions } from "../../../common/store/seriesSelectionSlice";
import { useAppDispatch } from "../../../common/store/store";
import { IPRO, MORTALITY } from "../../ChangeFromBaseline/components/Filter";
import { useFilteredSubjectsWithFollowUp } from "../../common/hooks/useFilteredSubjectsWithFollowUp";
import { SubjectType } from "../../common/types/SubjectType";
import {
  BOTH,
  SurvivalDistributionChartDialogType,
} from "../../MedianSurvival/components/useSurvivalDistributionChartDialog";
import { getSubjectMortalityHistogram } from "../utils/getSubjectMortalityHistogram";
import { getSubjectSurvivalHistogram } from "../utils/getSubjectSurvivalHistogram";
import { SurvivalBinType } from "../utils/SurvivalBinType";
import { SurvivalDistributionChart } from "./SurvivalDistributionChart";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ItemWrapper = styled.div`
  flex: 1;
`;

const StudyTableWrapper = styled(ItemWrapper)`
  min-height: 35vh;
`;

const GraphTitle = styled.div`
  flex: 1;
  text-align: center;
  font-weight: bold;
`;

export interface SurvivalDistributionChartContainerProps {
  followUp: number;
  containerType: SurvivalDistributionChartDialogType;
}

const BIN_SIZE_MONTHS = 3;

export default function SurvivalDistributionChartContainer({
  followUp,
  containerType,
}: SurvivalDistributionChartContainerProps): React.ReactElement {
  const tableKey = "SurvivalDistributionChartContainer";

  const showBoth = containerType === BOTH;
  const showSurvival = containerType === IPRO || showBoth;
  const showMortality = containerType === MORTALITY || showBoth;

  const dispatch = useAppDispatch();

  const [selectedSubjects, setSelectedSubjects] = useState<SubjectType[] | null>(null);

  const [selectedSurvivalBarIndex, setSelectedSurvivalBarIndex] = useState<number | null>(null);
  const [selectedMortalityBarIndex, setSelectedMortalityBarIndex] = useState<number | null>(null);

  const subjects = useFilteredSubjectsWithFollowUp(followUp);

  if (!selectedSubjects) {
    setSelectedSubjects(subjects);
  }

  const { studyRows, loading } = useStudyRowsFromSubjects(selectedSubjects ?? subjects);

  useEffect(() => {
    dispatch(seriesSelectionActions.setStudyRows({ tableKey, studyRows, loading }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studyRows, loading]);

  useEffect(() => {
    return () => {
      dispatch(seriesSelectionActions.clearStudyRows({ tableKey }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const survivalHistogram = getSubjectSurvivalHistogram(subjects, followUp, BIN_SIZE_MONTHS);

  const mortalityHistogram = getSubjectMortalityHistogram(subjects, followUp);

  const handleSurvivalBinSelected = (
    bin: SurvivalBinType,
    selectedBinIndex: number | null,
    isSelected: boolean
  ) => {
    setSelectedSurvivalBarIndex(selectedBinIndex);
    setSelectedMortalityBarIndex(null);

    handleBinSelected(bin, isSelected);
  };

  const handleMortalityBinSelected = (
    bin: SurvivalBinType,
    selectedBinIndex: number | null,
    isSelected: boolean
  ) => {
    setSelectedSurvivalBarIndex(null);
    setSelectedMortalityBarIndex(selectedBinIndex);

    handleBinSelected(bin, isSelected);
  };

  const handleBinSelected = (survivalBin: SurvivalBinType, isSelected: boolean) => {
    const { subjectsByArm } = survivalBin;
    const survivalBinSubjects = [];
    for (const key in subjectsByArm) {
      if (!Object.prototype.hasOwnProperty.call(subjectsByArm, key)) {
        continue;
      }

      const arm = parseInt(key);
      const armSubjects = subjectsByArm[arm];
      survivalBinSubjects.push(...armSubjects);
    }
    setSelectedSubjects(isSelected ? survivalBinSubjects : null);
  };

  return (
    <Wrapper>
      {showBoth && <GraphTitle>Survival Distribution</GraphTitle>}
      <ItemWrapper>
        {showSurvival && (
          <SurvivalDistributionChart
            histogram={survivalHistogram}
            chartType={IPRO}
            selectedBinIndex={selectedSurvivalBarIndex}
            onSurvivalBinSelected={handleSurvivalBinSelected}
          />
        )}
        {showBoth && <GraphTitle>Mortality Risk</GraphTitle>}
        {showMortality && (
          <SurvivalDistributionChart
            histogram={mortalityHistogram}
            chartType={MORTALITY}
            selectedBinIndex={selectedMortalityBarIndex}
            onSurvivalBinSelected={handleMortalityBinSelected}
          />
        )}
      </ItemWrapper>
      <StudyTableWrapper>
        <StudyTable tableKey={tableKey} hiddenColumns={[INCLUDED_HEADER_ID]} />
      </StudyTableWrapper>
    </Wrapper>
  );
}
