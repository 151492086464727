import "./ConerstoneViewportOverlay.css";

import CornerstoneViewport from "@altis-labs/react-cornerstone-viewport";
import { debounce } from "lodash";
import React, { ComponentType, KeyboardEvent, useEffect, useMemo, useState } from "react";
import { useDrop } from "react-dnd";
import AutoSizer from "react-virtualized-auto-sizer";
import styled from "styled-components";

import {
  CANCEL_QUEUE,
  ViewerConfigType,
} from "../../../../../common/store/annotatePage/ViewerConfigType";
import { main } from "../../../../../common/theme/main";
import { useViewerLabelOverrides } from "../../../../../Dashboard/components/Settings/Patients/Viewer/hooks/useViewerLabelOverrides";
import { ToolControllerProps } from "../../../../../Dashboard/components/Settings/Patients/Viewer/ToolController";
import { dragAndDropItemTypes } from "../../../../types/DragAndDropItemTypes";
import { DISABLE_VIEWER_IMAGE_PREFETCHING } from "../AnnotationPanel/hooks/debugUtils";
import { AccentBottom } from "../common/AccentBottom";
import { AccentTop } from "../common/AccentTop";
import { defaultTools } from "./tools/toolController/defaultTools";
import { useActivateViewer } from "./Viewer/hooks/useActivateViewer";
import { useChangeViewerSeries } from "./Viewer/hooks/useChangeViewerSeries";
import { useClearViewerImageId } from "./Viewer/hooks/useClearViewerImageId";
import { useGetSeriesImageIds } from "./Viewer/hooks/useGetSeriesImageIds";
import { useGetViewerImageId } from "./Viewer/hooks/useGetViewerImageId";
import { useSetViewerImageId } from "./Viewer/hooks/useSetViewerImageId";
import { useSetViewerToolQueue } from "./Viewer/hooks/useSetViewerToolQueue";

const ViewerWrapper = styled.div`
  flex: 1;
  border-radius: 12px;
  overflow: hidden;
`;

type SeriesDragAndDropItemType = {
  seriesId: number;
};

export type ViewerOptions = {
  resetImageIdIndexOnSeriesChange: boolean;
};

export interface ViewerProps {
  viewerConfig: ViewerConfigType;
  options?: ViewerOptions;
  toolController: ComponentType<ToolControllerProps>;
}

export function Viewer({
  viewerConfig,
  options,
  toolController: ToolController,
}: ViewerProps): JSX.Element {
  const [viewerImageInitialized, setViewerImageInitialized] = useState<boolean>(false);
  const [element, setElement] = useState<HTMLDivElement | null>(null);
  const [imageIds, setImageIds] = useState<string[]>([]);
  const [imageIdIndex, setImageIdIndex] = useState<number>(0);

  const { id: viewerConfigId, seriesId, isActive } = viewerConfig;
  const { resetImageIdIndexOnSeriesChange = false } = options ?? {};

  const getViewerImageIds = useGetSeriesImageIds();
  const activateViewer = useActivateViewer();
  const setViewerToolQueue = useSetViewerToolQueue();
  const setViewerImageId = useSetViewerImageId();
  const clearViewerImageId = useClearViewerImageId();

  const labelOverrides = useViewerLabelOverrides(seriesId);

  useEffect(() => {
    setViewerImageInitialized(false);
    return () => {
      setViewerImageIdDebounced.cancel();
    };
  }, []);

  const imageId = useGetViewerImageId(viewerConfigId);

  useEffect(() => {
    /*
        when the series changes, we get the set the new imageIds from that series
        in some cases, we preset an image id when changing series, so we may skip the clearing
        of the current image id to ensure it doesn't get wiped out when switching series
         */
    const imageIds = getViewerImageIds(seriesId);

    if (imageId && !imageIds.includes(imageId)) {
      clearViewerImageId(viewerConfigId);
    }

    setImageIds(imageIds);

    if (resetImageIdIndexOnSeriesChange) {
      setImageIdIndex(0);
    }
  }, [seriesId]);

  const changeViewerSeries = useChangeViewerSeries();

  const [, drop] = useDrop(() => ({
    accept: dragAndDropItemTypes.SERIES,
    drop: ({ seriesId }: SeriesDragAndDropItemType) => changeViewerSeries(viewerConfigId, seriesId),
  }));

  const handleKeyDown = ({ key }: KeyboardEvent<HTMLDivElement>) => {
    const cancelKeys = ["Delete", "Escape"];
    if (cancelKeys.includes(key)) {
      setViewerToolQueue({
        viewerConfigId,
        queue: CANCEL_QUEUE,
        value: true,
      });
    }
  };

  const setViewerImageIdDebounced = useMemo(
    () =>
      debounce((viewerConfigId: number, imageId: string | null) => {
        setViewerImageId(viewerConfigId, imageId);
      }, 100),
    [viewerConfigId]
  );

  const handleImageIdIndexChanged = (index: number) => {
    if (!viewerImageInitialized) {
      setViewerImageInitialized(true);
      return;
    }

    // it's possible that the cornerstone callback sends back -1
    // this occurs when the imageIds does not include imageId
    const imageId = imageIds[index] ?? null;

    setViewerImageIdDebounced(viewerConfigId, imageId);
  };

  const handleViewerActivated = () => {
    if (isActive) {
      return;
    }

    activateViewer(viewerConfigId);
  };

  const { color } = viewerConfig;

  useEffect(() => {
    if (!imageId) {
      setViewerImageIdDebounced(viewerConfigId, null);
      return;
    }

    const index = imageIds.indexOf(imageId);
    if (index === imageIdIndex || index === -1) {
      setViewerImageIdDebounced.cancel();
      return;
    }

    setViewerImageIdDebounced.cancel();
    setImageIdIndex(index);
  }, [imageIds, imageId, imageIdIndex]);

  const activeBarColor = isActive ? main.colors.neutral.neutral6 : main.colors.actionPrimary.active;

  return (
    <>
      <ToolController viewerConfig={viewerConfig} element={element} />
      <ViewerWrapper onKeyDown={handleKeyDown} ref={drop}>
        <AutoSizer>
          {({ height, width }) => {
            return (
              <CornerstoneViewport
                tools={defaultTools}
                imageIds={imageIds}
                imageIdIndex={imageIdIndex}
                imageIdIndexChange={handleImageIdIndexChanged}
                isStackPrefetchEnabled={!DISABLE_VIEWER_IMAGE_PREFETCHING}
                initialLoad={setElement}
                style={{ height, width }}
                setViewportActive={handleViewerActivated}
                labelOverrides={labelOverrides}
              />
            );
          }}
        </AutoSizer>

        <AccentTop selected={isActive} accentColor={color} unselectedSize={3} selectedSize={7} />
        <AccentBottom
          selected={isActive}
          accentColor={activeBarColor}
          unselectedSize={3}
          selectedSize={7}
        />
      </ViewerWrapper>
    </>
  );
}
