import { useState } from "react";
import { useDrag } from "react-dnd";
import { useSelector } from "react-redux";
import styled from "styled-components";

import {
  showFollowUpLabelsSelector,
  showTumourResponseLabelsSelector,
} from "../../../../../common/store/annotatePage/taskSelector";
import { useProjectId } from "../../../../../Project/hooks/useProjectId";
import { dragAndDropItemTypes } from "../../../../types/DragAndDropItemTypes";
import { getFirstSeriesNotViewOnly } from "../AnnotationPanel/hooks/Timepoint/getFirstSeriesNotViewOnly";
import { FilterableTimepointType } from "../AnnotationPanel/hooks/Timepoint/TimepointType";
import { SeriesListItemFragmentType } from "../AnnotationPanel/hooks/useStudiesList";
import { formatMM } from "../AnnotationPanel/Stats/utils/formatMM";
import { ExpandCollapseButton } from "../common/ExpandCollapseButton";
import { useActiveViewerConfig } from "../ViewerPanel/Viewer/hooks/useActiveViewerConfig";
import { useChangeViewerSeries } from "../ViewerPanel/Viewer/hooks/useChangeViewerSeries";
import { SeriesList } from "./SeriesList";
import { TimepointCard } from "./TimepointCard";
import { TumourResponseLabel } from "./TumourResponseLabel";
import { formatTimepointLabel } from "./utils/formatTimepointLabel";
import { SeriesTumourResponseType } from "./utils/TumourResponse/types/SeriesTumourResponseType";

const Wrapper = styled.div<{ filterWholeCard?: boolean }>`
  background: ${(props) => props.theme.colors.background.main};
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  overflow: hidden;
  ${(props) =>
    props.filterWholeCard &&
    `{
    opacity: 0.4;
    & *.filtered {
      opacity: 1 !important;
    }
    & .series-list-item-icons.filtered {
      opacity: 0.8;
    }
  }`}
  transition: opacity 0.05s ease-out;
  & * {
    transition: opacity 0.05s ease-out;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
  cursor: pointer;
`;

const Text = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;

export const ExclusionWrapper = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: grey;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  text-overflow: ellipsis;
  padding-bottom: 6px;
`;

interface TimepointListItemProps {
  timepoint: FilterableTimepointType;
  seriesTumourResponses: SeriesTumourResponseType[];
  includeDay: boolean;
}

export function TimepointListItem({
  timepoint,
  seriesTumourResponses,
  includeDay,
}: TimepointListItemProps): JSX.Element {
  const [collapsed, setCollapsed] = useState<boolean>(true);

  const projectId = useProjectId();

  const { series: seriesList, excludedSeriesCount } = timepoint;

  const changeViewerSeries = useChangeViewerSeries();

  const activeViewerConfig = useActiveViewerConfig();
  const showTumourResponseLabels = useSelector(showTumourResponseLabelsSelector);

  const showFollowUpLabel = useSelector(showFollowUpLabelsSelector);

  const [, drag] = useDrag(() => {
    const firstViewableSeries = getFirstSeriesNotViewOnly(timepoint, projectId);
    const defaultSeries = seriesList[0];
    const seriesId = (firstViewableSeries ?? defaultSeries)?.id;

    return {
      type: dragAndDropItemTypes.SERIES,
      item: {
        seriesId,
      },
    };
  }, [timepoint, projectId, seriesList]);

  const { id: activeViewerId, seriesId: activeViewerSeriesId } = activeViewerConfig || {};

  const viewerSeries =
    seriesList.find(({ id }) => activeViewerSeriesId === id) ??
    (seriesList.length > 0 ? seriesList[0] : undefined);

  const handleSeriesClicked = ({ id }: SeriesListItemFragmentType) => {
    if (activeViewerId === undefined) {
      return;
    }
    changeViewerSeries(activeViewerId, id);
  };

  const handleHeaderClicked = () => {
    setCollapsed(!collapsed);
  };

  if (!viewerSeries) {
    return <></>;
  }

  const studyLabel = formatTimepointLabel(timepoint, showFollowUpLabel, includeDay);

  const seriesTumourResponse = seriesTumourResponses.find(({ seriesId: responseSeriesId }) =>
    seriesList.some(({ id }) => responseSeriesId === id)
  );

  const { isBaseline, tumourResponse, totalBurden } = seriesTumourResponse ?? {
    isBaseline: false,
    tumourResponse: undefined,
    totalBurden: 0,
  };

  const extraInfo = seriesTumourResponse ? formatMM(Math.round(totalBurden), 0) : "N/A";

  const showViewerColorBar = seriesList.length === 1;

  const shouldFilterWholeCard = seriesList.every(({ filtered }) => filtered);

  return (
    <Wrapper ref={drag} filterWholeCard={shouldFilterWholeCard}>
      <TimepointCard
        series={viewerSeries}
        showViewerColorBar={showViewerColorBar}
        label={
          showTumourResponseLabels && (
            <TumourResponseLabel
              isBaseline={isBaseline}
              response={tumourResponse}
              extraInfo={extraInfo}
            />
          )
        }
      />
      <HeaderWrapper onClick={handleHeaderClicked} className="timepoint-header">
        <Text>{studyLabel}</Text>
        <ExpandCollapseButton collapsed={collapsed} />
      </HeaderWrapper>
      {!collapsed && (
        <>
          <SeriesList series={seriesList} onClick={handleSeriesClicked} />
          {!!excludedSeriesCount && (
            <ExclusionWrapper>+{excludedSeriesCount} series not shown</ExclusionWrapper>
          )}
        </>
      )}
    </Wrapper>
  );
}
