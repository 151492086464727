import { TimepointOptionsType } from "../../../../../../types/TaskDescriptionType";
import { LesionListItemFragmentType } from "../../fragments/LesionListItemFragment";
import { RoiListItemFragmentType } from "../../fragments/RoiListItemFragment";
import { useGetTimepointOptionsFromRoiClassifications } from "./useGetTimepointOptionsFromRoiClassifications";

export type RoiViewOnlyOptionsReturnType = (
  lesion: LesionListItemFragmentType,
  { classifications }: RoiListItemFragmentType
) => TimepointOptionsType[];

export function useGetRoiViewOnlyOptions(): RoiViewOnlyOptionsReturnType {
  const getTimepointOptionsFromRoiClassifications = useGetTimepointOptionsFromRoiClassifications();

  return (lesion, roi) => {
    const filter = ({ isViewOnly }: TimepointOptionsType) => isViewOnly;
    return getTimepointOptionsFromRoiClassifications(lesion, roi, filter);
  };
}
