import React from "react";

import { useGoToRoute } from "../hooks/useGoToRoute";
import { Icons } from "../Icons";
import { RoutePathSingleOrArray, TopBarType } from "../Layout";
import { BackButton } from "./BackButton";
import { Label } from "./Label";

interface LabelContentProps {
  topBar: TopBarType;
  parentRoute?: RoutePathSingleOrArray;
}

export function LabelContent({ topBar, parentRoute }: LabelContentProps): JSX.Element {
  const { showBackButton, label, icon: iconEnum } = topBar;

  const goToPath = useGoToRoute();

  const onClickBack = () => {
    if (!parentRoute) {
      throw new Error(
        "No parent route specified in TopBarTitleContent when showBackButton enabled"
      );
    }
    goToPath(parentRoute);
  };

  if (showBackButton) {
    return <BackButton onClickBack={onClickBack} />;
  }

  if (label && iconEnum) {
    return <Label icon={Icons[iconEnum]} title={label} />;
  }

  return <></>;
}
