import {
  TASK_COMPLETED,
  TASK_IN_PROGRESS,
  TASK_PENDING,
  TaskProgressType,
} from "../../../Annotate/components/TaskWorklist/TaskProgressType";
import { DayjsDateRange } from "../../../common/utils/dateFormatUtils/DayjsDateRange";
import { DataGroupByType } from "../components/TasksPerAnnotatorCard";
import { Data } from "../hooks/useProjectLabels";
import { getUserTaskAssignments } from "./getUserTaskAssignments";

export type TasksPerAnnotatorData = {
  task: string;
  [TASK_PENDING]: number;
  [TASK_IN_PROGRESS]: number;
  [TASK_COMPLETED]: number;
};

export function buildTasksPerAnnotatorData(
  { patients, projectTasks }: Data,
  selectedAnnotatorUserIds: number[],
  range: DayjsDateRange,
  groupByMethod: DataGroupByType
): TasksPerAnnotatorData[] {
  const annotatorTasks: TasksPerAnnotatorData[] = [];

  const userTaskAssignments = getUserTaskAssignments(
    patients,
    selectedAnnotatorUserIds,
    range,
    groupByMethod
  );
  for (const { taskAssignments, count } of userTaskAssignments) {
    for (const { taskId, progress } of taskAssignments) {
      const task = projectTasks.find(({ id }) => id === taskId);
      if (!task) {
        throw new Error(`No task found for task id ${taskId}`);
      }

      const { name } = task;

      let existingAnnotatorTask = annotatorTasks.find(
        ({ task: existingTask }) => existingTask === name
      );

      if (!existingAnnotatorTask) {
        existingAnnotatorTask = {
          task: name,
          [TASK_COMPLETED]: 0,
          [TASK_IN_PROGRESS]: 0,
          [TASK_PENDING]: 0,
        };
        annotatorTasks.push(existingAnnotatorTask);
      }

      existingAnnotatorTask[progress as TaskProgressType] += count;
    }
  }

  return annotatorTasks;
}
