import { ApolloCache } from "@apollo/client/core";

import {
  GetLesionsListDataType,
  GetLesionsListVariablesType,
  LesionListItemFragmentType,
  LesionsListQueryInput,
} from "../fragments/LesionListItemFragment";
import { GET_LESIONS_LIST, getVariables } from "./useLesionsList";

export function getLesionsListFromCache<T>(
  lesionListQueryInput: LesionsListQueryInput,
  cache: ApolloCache<T>
): LesionListItemFragmentType[] {
  const variables = getVariables(lesionListQueryInput);

  const { lesions } = cache.readQuery<GetLesionsListDataType, GetLesionsListVariablesType>({
    query: GET_LESIONS_LIST,
    variables,
  }) ?? {
    lesions: [],
  };
  return lesions;
}
