import { gql } from "@apollo/client";
import { ApolloCache } from "@apollo/client/core";

export function getImageIdFromInstanceIdFromCache<T>(
  cache: ApolloCache<T>,
  instanceId: number
): string | null {
  const { wadoUrl } = cache.readFragment<{
    wadoUrl: string;
  }>({
    id: `instance:${instanceId}`,
    fragment: gql`
      fragment InstanceWadoUrl on instance {
        wadoUrl
      }
    `,
  }) ?? { wadoUrl: null };

  return wadoUrl;
}
