import { ROI, ToolType } from "../../../../../../../../cornerstone/ToolType";
import { ContourClipboardEntryType } from "../../../../../../../ContourContextProvider";
import { ContourToolDataType } from "../../../../../../../types/ContourToolDataType";
import { getToolSliceData } from "../../legacy/ToolUtils";

export function canPasteContour(
  clipboardEntry: ContourClipboardEntryType,
  lesionId: number,
  roiId: number,
  imageId: string,
  tool: ToolType
): boolean {
  if (!clipboardEntry) {
    return false;
  }

  const {
    lesionId: clipboardLesionId,
    roiId: clipboardRoiId,
    imageId: clipboardImageId,
    toolData,
  } = clipboardEntry;

  if (tool !== ROI) {
    return false;
  }

  if (toolData.length === 0) {
    return false;
  }

  return (
    lesionId === clipboardLesionId &&
    (roiId !== clipboardRoiId || imageId !== clipboardImageId) &&
    !isContourDataInSlice(roiId, imageId, toolData)
  );
}

function isContourDataInSlice(roiId: number, imageId: string, toolData: ContourToolDataType[]) {
  const toolSliceData = getToolSliceData(roiId, imageId, ROI) as ContourToolDataType[];

  for (const existingToolData of toolSliceData) {
    for (const toolDatum of toolData) {
      if (isContourToolDataEqual(existingToolData, toolDatum)) {
        return true;
      }
    }
  }

  return false;
}

function isContourToolDataEqual(
  { handles: { points: pointsA } }: ContourToolDataType,
  { handles: { points: pointsB } }: ContourToolDataType
): boolean {
  if (pointsA.length !== pointsB.length) {
    return false;
  }

  for (let i = 0; i < pointsA.length; i++) {
    const { x: xA, y: yA } = pointsA[i];
    const { x: xB, y: yB } = pointsB[i];

    if (xA !== xB || yA !== yB) {
      return false;
    }
  }

  return true;
}
