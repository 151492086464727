import makeStyles from '@mui/styles/makeStyles';
import LinearProgress from "@mui/material/LinearProgress";
import React from "react";
import { useSelector } from "react-redux";

import { imagesLoadingProgressSelector } from "../../../../../common/store/annotatePage/cornerstone/selector";
import { main } from "../../../../../common/theme/main";

const useStyles = makeStyles({
  root: {
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: main.colors.secondary.secondary,
    },
    height: 6,
    width: "100%",
    borderRadius: "2px",
  },
});

export function PrefetchProgressIndicator(): JSX.Element | null {
  const classes = useStyles();

  const progress = useSelector(imagesLoadingProgressSelector);

  if (progress === 1) {
    return null;
  }
  return (
    <>
      <LinearProgress className={classes.root} variant="determinate" value={100 * progress} />
    </>
  );
}
