import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { QueryResult } from "@apollo/client/react/types/types";

import { RawProjectDetailsType } from "../../../../common/types/RawProjectDetailsType";
import { PROJECT_DETAILS_FRAGMENT } from "../../../../Project/contexts/Project/ProjectDetailsFragment";

type Variables = undefined;

export const GET_ALL_PROJECTS = gql`
  query GetProjects {
    project {
      ...ProjectDetails
    }
  }
  ${PROJECT_DETAILS_FRAGMENT}
`;

export function useAllProjects(): QueryResult<RawProjectDetailsType, Variables> {
  return useQuery<RawProjectDetailsType, Variables>(GET_ALL_PROJECTS);
}
