import React from "react";
import styled from "styled-components";

import { SiteType } from "../../../types/SiteType";
import { OK_ONLY } from "../../Dialog/DialogActionButtonsType";
import { useDialog, useDialogReturnType } from "../../Dialog/useDialog";
import { EditProjectSites,EditSitesOptions } from "../EditProjectSites";
import { SiteActionCallbacksType } from "../SiteActionCallbacksType";

const Wrapper = styled.div`
  flex: 1;
  width: 57vw;
  min-width: 800px;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

interface useEditProjectSitesDialogProps {
  sites: SiteType[];
  actionCallbacks: SiteActionCallbacksType;
  options: EditSitesOptions;
}

export function useEditProjectSitesDialog({
  sites,
  actionCallbacks,
  options,
}: useEditProjectSitesDialogProps): useDialogReturnType {
  const label = "Edit Sites";

  const content = (
    <Wrapper>
      <EditProjectSites sites={sites} actionCallbacks={actionCallbacks} options={options} />
    </Wrapper>
  );

  return useDialog({
    label,
    content,
    options: {
      showCloseButton: true,
      actionButtonOptions: {
        type: OK_ONLY,
      },
    },
  });
}
