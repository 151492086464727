import { useApolloClient } from "@apollo/client";
import React, { ReactNode, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { BigFlexLoading } from "../../../../common/components/Loading";
import { setTaskDescription } from "../../../../common/store/annotatePage/taskSlice";
import { PatientContext } from "../../../../Dashboard/components/Settings/Patients/Viewer/PatientContext";
import { TaskContext } from "../../../TaskContext";
import { InstanceType } from "../../../types/InstanceType";
import { useTaskLoadingFailedDialog } from "../routing/Forms/useTaskLoadingFailedDialog";
import { DISABLE_PAGE_IMAGE_PREFETCHING } from "./AnnotationPanel/hooks/debugUtils";
import { loadTask, unloadTask } from "./AnnotationPanel/hooks/loadTask";
import { useSelectFirstLesion } from "./hooks/useSelectFirstLesion";
import { ImageLoader } from "./ImageLoader";

interface TaskLoaderProps {
  children: ReactNode;
}

export function TaskLoader({ children }: TaskLoaderProps): JSX.Element {
  const { task } = useContext(TaskContext);
  const { id } = useContext(PatientContext);
  const dispatch = useDispatch();
  const client = useApolloClient();

  const { id: taskId } = task;

  const { selectFirstLesion } = useSelectFirstLesion();
  const [showDialog, { dialog }] = useTaskLoadingFailedDialog();

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingMessage, setLoadingMessage] = useState<string | undefined>(undefined);
  const [instances, setInstances] = useState<InstanceType[]>([]);

  useEffect(() => {
    setLoading(true);
    loadTask(client, { id, task }, setLoadingMessage)
      .then(({ lesions, instances, taskDescription }) => {
        dispatch(setTaskDescription(taskDescription));
        selectFirstLesion(lesions);
        setInstances(instances);

        setLoading(false);
      })
      .catch(() => {
        showDialog(true);
      });

    return unloadTask;
  }, [taskId, id]);

  if (loading) {
    return (
      <>
        {dialog}
        <BigFlexLoading>{loadingMessage}</BigFlexLoading>
      </>
    );
  }

  return (
    <>
      {!DISABLE_PAGE_IMAGE_PREFETCHING && <ImageLoader instances={instances} />}
      {children}
    </>
  );
}
