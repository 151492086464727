import React from "react";
import styled from "styled-components";

import { UsersList } from "../../../../common/components/UserManagement/UsersList";
import { main } from "../../../../common/theme/main";
import { UserAvatarType } from "../../../../common/types/UserType";
import {
  TASK_COMPLETED,
  TASK_IN_PROGRESS,
  TASK_PENDING,
  taskProgressColors,
  TaskProgressType,
} from "../../TaskWorklist/TaskProgressType";
import { TaskType } from "../hooks/TaskType";

const OuterWrapper = styled.div`
  display: inline-block;
`;

export const ModeTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 4px 0;
  min-height: 18px;
`;

const Wrapper = styled.div<{ background: string }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 8px;
  background: ${(props) => props.background};
  border-radius: 4px;
  gap: 8px;
`;

const Text = styled.div<{ color: string }>`
  font-style: normal;
  font-size: 12px;
  line-height: 12px;
  color: ${(props) => props.color};
`;

interface TaskLabelProps {
  task: TaskType;
  users: UserAvatarType[];
  formatName?: (name: string) => JSX.Element;
}

export function TaskLabel({
  task,
  users,
  formatName = (name: string) => <>{name}</>,
}: TaskLabelProps): JSX.Element {
  const { name, taskAssignments } = task;

  const progress = getMinimumTaskAssignmentProgress(taskAssignments);

  const color =
    progress && users.length > 0 ? taskProgressColors[progress] : main.colors.neutral.neutral4;

  return (
    <OuterWrapper>
      <Wrapper background={color}>
        <ModeTextWrapper>
          <Text color={"white"}>{formatName(name)}</Text>
        </ModeTextWrapper>
        {users && users.length > 0 && <UsersList users={users} />}
      </Wrapper>
    </OuterWrapper>
  );
}

export function getMinimumTaskAssignmentProgress(
  taskAssignments: { progress: TaskProgressType }[]
): TaskProgressType | undefined {
  const progresses = taskAssignments.map(({ progress }) => progress);

  const allProgress = [TASK_PENDING, TASK_IN_PROGRESS, TASK_COMPLETED] as TaskProgressType[];

  return allProgress.find((progress) => progresses.includes(progress));
}
