import { TaskType } from "../../../../Annotate/components/Manage/hooks/TaskType";

export function getPatientTaskShowBadge(tasks: TaskType[]): boolean {
  if (
    tasks.every(({ taskAssignments }) => {
      return taskAssignments.length === 0;
    })
  ) {
    return false;
  }
  return tasks.some(({ taskAssignments }) => {
    return (
      taskAssignments.length > 0 &&
      taskAssignments.some(({ showBadge }) => {
        return showBadge;
      })
    );
  });
}
