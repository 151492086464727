import { useContext } from "react";

import { ViewerContext } from "../ViewerContext";
import { ToolQueueActionType } from "./useSetAllViewersToolQueue";

type ViewerToolQueueActionType = ToolQueueActionType & {
  viewerConfigId: number;
};

export function useSetViewerToolQueue(): (action: ViewerToolQueueActionType) => void {
  const { setViewerConfigs } = useContext(ViewerContext);

  return (action) => {
    const { viewerConfigId, queue, value } = action;
    setViewerConfigs((viewerConfigs) =>
      viewerConfigs.map((viewerConfig) => {
        if (viewerConfig.id !== viewerConfigId) {
          return viewerConfig;
        }

        return {
          ...viewerConfig,
          toolQueues: { ...viewerConfig.toolQueues, [queue]: value },
        };
      })
    );
  };
}
