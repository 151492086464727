import React from "react";
import styled from "styled-components";

import { SeriesListItemFragmentType } from "../AnnotationPanel/hooks/useStudiesList";
import { SeriesListItem } from "./SeriesListItem";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

interface SeriesListProps {
  onClick: (series: SeriesListItemFragmentType) => void;
  series: SeriesListItemFragmentType[];
}

export function SeriesList({ series, onClick }: SeriesListProps): JSX.Element {
  const showViewerColorBar = series.length > 1;
  return (
    <Wrapper className="series-list">
      {series.map((seriesItem, index) => {
        const handleSeriesClicked = () => {
          onClick(seriesItem);
        };
        return (
          <SeriesListItem
            series={seriesItem}
            key={index}
            onClick={handleSeriesClicked}
            showViewerColorBar={showViewerColorBar}
          />
        );
      })}
    </Wrapper>
  );
}
