import { useMutation } from "@apollo/client";
import { gql, Reference, StoreObject } from "@apollo/client/core";
import { FetchResult } from "@apollo/client/link/core";
import {
  MutationFunctionOptions,
  MutationResult,
  MutationTuple,
} from "@apollo/client/react/types/types";

import {
  ClassificationValuesType,
  TimepointOptionsType,
} from "../../../../../types/TaskDescriptionType";
import {
  Data as DeleteRoiRecistEvaluationsData,
  INTERNAL_MUTATION as DELETE_ROI_RECIST_EVALUATIONS_MUTATION,
  useUpdateCache as useUpdateRoiRecistEvaluationsCache,
  Variables as DeleteRoiRecistEvaluationsVariables,
} from "../../ViewerPanel/tools/contour/hooks/useDeleteRoiRecistEvaluations";
import {
  Data as UpsertRoiRecistEvaluationData,
  INTERNAL_MUTATION as UPSERT_ROI_RECIST_EVALUATION_MUTATION,
  Variables as UpsertRoiRecistEvaluationVariables,
} from "../../ViewerPanel/tools/contour/hooks/useUpsertRoiRecistEvaluation";
import { RoiRecistEvaluationVariablesType } from "../../ViewerPanel/tools/contour/utils/getRoiRecistEvaluationVariables";
import { ROI_CLASSIFICATION_FRAGMENT } from "../fragments/RoiClassificationFragment";
import { RoiListItemFragmentType } from "../fragments/RoiListItemFragment";
import { ROI_RECIST_EVALUATION_FRAGMENT } from "../fragments/RoiRecistEvaluationFragment";
import { ClassificationType } from "../types/ClassificationType";
import { getRoiCacheId } from "./getRoiCacheId";
import { getRoiClassificationCacheId } from "./getRoiClassificationCacheId";
import { updateRoiRecistEvaluationsInCache } from "./updateRoiRecistEvaluationsInCache";
import { useRecalculateRoiMeasurements } from "./useRecalculateRoiMeasurements";

const MUTATION = gql`
  mutation InsertRoiClassifications(
    $objects: [roi_classification_insert_input!]!
    $roiIds: [Int!]!
    $roiRecistEvaluations: [roi_recist_evaluations_insert_input!]!
    $roiRecistEvaluationIdsToDelete: [Int!]!
    $deleteClassifications: [String!]!
  ) {
    insertRoiClassification: insert_roi_classification(
      on_conflict: {
        constraint: roi_classification_roi_id_classification_key
        update_columns: classification
      }
      objects: $objects
    ) {
      inserted: returning {
        ...InsertRoiClassificationFragment
      }
    }
    deleteRoiClassifications: delete_roi_classification(
      where: {
        _and: [{ roi_id: { _in: $roiIds } }]
        classification: { _in: $deleteClassifications }
      }
    ) {
      deleted: returning {
        ...InsertRoiClassificationFragment
      }
    }
    ${UPSERT_ROI_RECIST_EVALUATION_MUTATION}
    ${DELETE_ROI_RECIST_EVALUATIONS_MUTATION}
  }

  fragment InsertRoiClassificationFragment on roi_classification {
    id
    roiId: roi_id
    classification
  }

  ${ROI_RECIST_EVALUATION_FRAGMENT}
`;

type Variables = {
  objects: {
    classification: ClassificationValuesType;
    roi_id: number;
  }[];
  roiIds: number[];
  deleteClassifications: ClassificationValuesType[];
} & UpsertRoiRecistEvaluationVariables &
  DeleteRoiRecistEvaluationsVariables;

type InsertRoiClassificationFragmentType = {
  id: number;
  roiId: number;
  classification: ClassificationValuesType;
};

type Data = {
  insertRoiClassification: {
    inserted: InsertRoiClassificationFragmentType[];
  };
  deleteRoiClassifications: {
    deleted: InsertRoiClassificationFragmentType[];
  };
} & UpsertRoiRecistEvaluationData &
  DeleteRoiRecistEvaluationsData;

function useInsertRoiClassificationInternal(): MutationTuple<Data, Variables> {
  const updateRoiRecistEvaluationCache = useUpdateRoiRecistEvaluationsCache();
  return useMutation<Data, Variables>(MUTATION, {
    update: (cache, { data }) => {
      if (!data) {
        return;
      }

      const {
        insertRoiClassification: { inserted },
        deleteRoiClassifications: { deleted },
        insert_roi_recist_evaluations: { roiRecistEvaluations },
      } = data;

      inserted.forEach(({ id, roiId, classification }) => {
        const roiCacheId = getRoiCacheId(roiId, cache);
        cache.modify({
          id: roiCacheId,
          fields: {
            roi_classifications(existingClassificationRefs = [], { readField }) {
              const newClassificationRef = cache.writeFragment<
                ClassificationType & { __typename: string }
              >({
                fragment: ROI_CLASSIFICATION_FRAGMENT,
                data: {
                  __typename: "roi_classification",
                  id,
                  classification,
                },
              });

              return [
                ...existingClassificationRefs.filter(
                  (classificationRef: Reference | StoreObject | undefined) => {
                    const existingClassification = readField("classification", classificationRef);
                    const existingId = readField("id", classificationRef);
                    const isUnique = existingClassification !== classification && existingId !== id;
                    const isDeleted = deleted.some(
                      ({
                        id: deletedId,
                        roiId: deletedRoiId,
                        classification: deletedClassification,
                      }) =>
                        deletedId === existingId &&
                        deletedRoiId === roiId &&
                        deletedClassification === existingClassification
                    );
                    return isUnique && !isDeleted;
                  }
                ),
                newClassificationRef,
              ];
            },
          },
        });
      });

      deleted.forEach(({ id }) => {
        const roiClassificationCacheId = getRoiClassificationCacheId(id, cache);
        cache.evict({ id: roiClassificationCacheId });
      });

      updateRoiRecistEvaluationCache(cache, { data });

      updateRoiRecistEvaluationsInCache(roiRecistEvaluations, cache);

      cache.gc();
    },
  });
}

function getVariables(
  roiId: number,
  classifications: ClassificationValuesType[],
  deleteClassifications: ClassificationValuesType[],
  roiRecistEvaluations: RoiRecistEvaluationVariablesType[],
  roiRecistEvaluationIdsToDelete: number[]
): Variables {
  return {
    objects: classifications.map((classification) => ({
      classification,
      roi_id: roiId,
    })),
    roiIds: [roiId],
    roiRecistEvaluations,
    roiRecistEvaluationIdsToDelete,
    deleteClassifications,
  };
}

export type InsertRoiClassificationsReturnType = [
  (
    roi: RoiListItemFragmentType,
    option: TimepointOptionsType,
    options?: Omit<MutationFunctionOptions<Data, Variables>, "variables">
  ) => Promise<FetchResult<Data>>,
  MutationResult<Data>
];

export function useInsertRoiClassifications(): InsertRoiClassificationsReturnType {
  const [insertRoiClassifications, ...other] = useInsertRoiClassificationInternal();
  const recalculateRoiMeasurements = useRecalculateRoiMeasurements();

  return [
    async (roi, option, options) => {
      const { rule: classification, disableMeasurements } = option;
      const { id: roiId, classifications } = roi;
      const existingClassifications = classifications.map(({ classification }) => classification);

      const roiRecistEvaluationIdsToDelete = disableMeasurements ? [roiId] : [];
      const roiRecistEvaluationsToInsert = disableMeasurements
        ? []
        : [await recalculateRoiMeasurements(roi)];

      const variables = getVariables(
        roiId,
        [classification],
        existingClassifications,
        roiRecistEvaluationsToInsert,
        roiRecistEvaluationIdsToDelete
      );

      return insertRoiClassifications({ variables, ...options });
    },
    ...other,
  ];
}
