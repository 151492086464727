import React, { ReactElement } from "react";
import {
  Legend as ReChartsLegend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Payload } from "recharts/types/component/DefaultTooltipContent";
import styled from "styled-components";

import { ImageCaptureContextMenuContainer } from "../../../common/components/ImageCaptureContextMenuContainer";
import { GenericTooltip } from "../../../common/utils/barChartUtils/GenericTooltip";
import {
  Filter,
  FilterType,
  GraphView,
  IPRO,
  MORTALITY,
  RECIST,
} from "../../ChangeFromBaseline/components/Filter";
import { Legend } from "../../common/components/Legend";
import { useGraphParams } from "../../common/hooks/useGraphParams";
import { SubjectType } from "../../common/types/SubjectType";
import { armFilterColors } from "../../common/utils/armColors";
import { formatDataType, formatDataTypeAxisLabel } from "../../common/utils/formatDataType";
import { formatFollowUp } from "../../common/utils/formatFollowUp";
import { getFollowUpDiametricTumourBurden } from "../../common/utils/getFollowUpDiametricTumourBurden";
import { getFollowUpVolumetricTumourBurden } from "../../common/utils/getFollowUpVolumetricTumourBurden";
import { getShouldShowGraphData } from "../../common/utils/getShouldShowGraphData";
import { getXAxisProps } from "../../common/utils/getXAxisProps";
import { getYAxisLabel, getYAxisLabelRight } from "../../common/utils/getYAxisLabel";
import { getYAxisProps } from "../../common/utils/getYAxisProps";
import { sortFollowUps } from "../../common/utils/sortFollowUps";
import { formatTooltipValue } from "../../MedianSurvival/utils/formatTooltipValue";
import { tooltipItemSorter } from "../../MedianSurvival/utils/tooltipItemSorter";
import { getDomains } from "../utils/getDomains";
import { getMortalityRisk } from "../utils/getMortalityRisk";
import { getPredictedSurvival } from "../utils/getPredictedSurvival";
import { SubjectAxisTick } from "./SubjectAxisTick";

type SubjectAxisTickType = {
  x: number;
  y: number;
  payload?: Payload<number, string>;
};

const Wrapper = styled.div`
  flex: 1;
  position: relative;
`;

interface PatientJourneyTumourDetailsChartProps {
  subject: SubjectType;
  allowedDataTypes: FilterType[];
  graphViews?: GraphView[];
}

export function PatientJourneyTumourDetailsChart({
  subject,
  allowedDataTypes,
  graphViews = ["DIAMETRIC", "VOLUMETRIC"],
}: PatientJourneyTumourDetailsChartProps): ReactElement {
  const {
    followUps,
    projectArm: { number: armNumber },
    id: patientId,
  } = subject;

  const sortedFollowUps = sortFollowUps(followUps, true);

  const { graphParams, setGraphParams } = useGraphParams();

  const hasSurvival = allowedDataTypes.includes(IPRO);
  const hasMortality = allowedDataTypes.includes(MORTALITY);

  const { shouldShowBurden, shouldShowSurvival, shouldShowMortality } = getShouldShowGraphData(
    graphParams.filter
  );

  const renderXAxisTick = ({ x, y, payload }: SubjectAxisTickType) => {
    if (!payload) {
      throw new Error("Null payload in PatientJourneyTumourDetails");
    }

    const { value } = payload;
    if (value === undefined) {
      return <></>;
    }

    const followUp = sortedFollowUps[value];

    return <SubjectAxisTick x={x} y={y} followUp={followUp} patientId={patientId} />;
  };

  const getXAxisValue = (data: string) => {
    return data;
  };

  const domains = getDomains(sortedFollowUps);
  const defaultDomain: [number, string] = [0, "auto"];

  const xAxisProps = getXAxisProps();
  const yAxisProps = getYAxisProps(true);

  const burdenYAxisLabel = getYAxisLabel(formatDataTypeAxisLabel(RECIST, graphParams.view));
  const survivalYAxisLabel = getYAxisLabelRight(formatDataTypeAxisLabel(IPRO, graphParams.view));
  const mortalityYAxisLabel = getYAxisLabelRight(
    formatDataTypeAxisLabel(MORTALITY, graphParams.view)
  );

  const isDiametric = graphParams.view === "DIAMETRIC";

  return (
    <Wrapper>
      <ImageCaptureContextMenuContainer>
        {({ reference }) => (
          <ResponsiveContainer ref={reference} height={545}>
            <LineChart margin={{ top: 0, right: 20, left: 20, bottom: 75 }} data={sortedFollowUps}>
              <XAxis
                {...xAxisProps}
                allowDuplicatedCategory={false}
                padding={{ left: 40, right: 40 }}
                tick={renderXAxisTick}
                tickFormatter={getXAxisValue}
              />
              <YAxis
                yAxisId={RECIST}
                orientation={"left"}
                {...yAxisProps}
                domain={shouldShowBurden ? domains[RECIST][graphParams.view] : defaultDomain}
              >
                {shouldShowBurden && burdenYAxisLabel}
              </YAxis>
              {(hasSurvival || hasMortality) && (
                <YAxis
                  {...yAxisProps}
                  yAxisId={shouldShowSurvival ? IPRO : MORTALITY}
                  orientation={"right"}
                  domain={
                    shouldShowSurvival || shouldShowMortality
                      ? shouldShowSurvival
                        ? domains[IPRO]
                        : domains[MORTALITY]
                      : defaultDomain
                  }
                >
                  {shouldShowSurvival && survivalYAxisLabel}
                  {shouldShowMortality && mortalityYAxisLabel}
                </YAxis>
              )}
              <Tooltip
                content={<GenericTooltip />}
                isAnimationActive={false}
                formatter={(value: number, name: string) =>
                  formatTooltipValue(value, name, graphParams.view)
                }
                labelFormatter={formatFollowUp}
                itemSorter={(item) => tooltipItemSorter(item, domains, graphParams.view)}
              />
              <ReChartsLegend align="left" verticalAlign="top" height={48} content={<Legend />} />
              {shouldShowBurden && isDiametric && (
                <Line
                  isAnimationActive={false}
                  type="linear"
                  dataKey={getFollowUpDiametricTumourBurden}
                  stroke={armFilterColors[armNumber][RECIST].color}
                  name={formatDataType(RECIST, graphParams.view)}
                  legendType={"plainline"}
                  yAxisId={RECIST}
                />
              )}
              {shouldShowBurden && !isDiametric && (
                <Line
                  isAnimationActive={false}
                  type="linear"
                  dataKey={getFollowUpVolumetricTumourBurden}
                  stroke={armFilterColors[armNumber][RECIST].color}
                  name={formatDataType(RECIST, graphParams.view)}
                  legendType={"plainline"}
                  yAxisId={RECIST}
                />
              )}
              {shouldShowSurvival && (
                <Line
                  isAnimationActive={false}
                  type="linear"
                  dataKey={getPredictedSurvival}
                  stroke={armFilterColors[armNumber][IPRO].color}
                  name={formatDataType(IPRO, graphParams.view)}
                  legendType={"plainline"}
                  yAxisId={IPRO}
                />
              )}
              {shouldShowMortality && (
                <Line
                  isAnimationActive={false}
                  type="linear"
                  dataKey={getMortalityRisk}
                  stroke={armFilterColors[armNumber][MORTALITY].color}
                  name={formatDataType(MORTALITY, graphParams.view)}
                  legendType={"plainline"}
                  yAxisId={MORTALITY}
                />
              )}
            </LineChart>
          </ResponsiveContainer>
        )}
      </ImageCaptureContextMenuContainer>
      <Filter
        allowedDataTypes={allowedDataTypes}
        graphViews={graphViews}
        onGraphParamsChanged={setGraphParams}
        activeFilter={graphParams.filter}
        activeView={graphParams.view}
      />
    </Wrapper>
  );
}
