import React, { ReactElement } from "react";
import { TooltipProps as ReChartsTooltipType } from "recharts";
import styled from "styled-components";

import { TooltipItem } from "./TooltipItem";

export const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: ${(props) => props.theme.colors.actionPrimary.default};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  padding: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.neutral.white};
`;

const TooltipDivider = styled.div`
  height: 0;
  margin: 8px;
  border: 0.5px solid ${(props) => props.theme.colors.neutral.white40};
`;

const TooltipHeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  font-style: normal;
  font-size: 16px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.neutral.white80};
`;

interface TooltipProps extends ReChartsTooltipType<number, string> {
  formatLabel: (label: number | string) => string;
  formatValue: (value: string | number) => string;
}

export function Tooltip({
  active,
  payload,
  label,
  formatLabel,
  formatValue,
}: TooltipProps): ReactElement | null {
  if (!active || !payload || payload.length < 1) {
    return null;
  }
  const itemsToRender = payload.filter((item) => item.type !== "none");

  return (
    <TooltipWrapper>
      <TooltipHeaderWrapper>{formatLabel(label)}</TooltipHeaderWrapper>
      <TooltipDivider />
      {itemsToRender.map((item, index) => (
        <TooltipItem key={index} data={item} formatValue={formatValue} />
      ))}
    </TooltipWrapper>
  );
}
