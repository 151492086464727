const N = 3;

const RECENT_TRIAL_IDS_KEY = "RECENT_TRIAL_IDS_KEY";
const RECENT_PROJECT_IDS_KEY = "RECENT_PROJECT_IDS_KEY";

type KeyType = typeof RECENT_TRIAL_IDS_KEY | typeof RECENT_PROJECT_IDS_KEY;

function loadIds(key: KeyType): number[] {
  const idsData = localStorage.getItem(key);
  if (!idsData) {
    return [];
  }
  return JSON.parse(idsData) as number[];
}

function addId(key: KeyType, id: number): void {
  const ids = loadIds(key);

  if (ids.length === 0) {
    ids.push(id);
  } else if (ids) {
    if (ids[ids.length - 1] !== id) {
      if (ids.length === N) {
        ids.shift();
        ids.push(id);
      } else {
        ids.push(id);
      }
      ids.forEach((id, i) => {
        if (i !== ids.length - 1) {
          if (id === ids[ids.length - 1]) {
            ids.splice(i, 1);
          }
        }
      });
    }
  }
  localStorage.setItem(key, JSON.stringify(ids));
}

export function getRecentTrialsIds(): number[] {
  return loadIds(RECENT_TRIAL_IDS_KEY).reverse();
}

export function addRecentTrialId(trialId: number): void {
  addId(RECENT_TRIAL_IDS_KEY, trialId);
}

export function getRecentProjectIds(): number[] {
  return loadIds(RECENT_PROJECT_IDS_KEY).reverse();
}

export function addRecentProjectId(trialId: number): void {
  addId(RECENT_PROJECT_IDS_KEY, trialId);
}
