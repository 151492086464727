import { normalizeImageId } from "./normalizeImageId";

export function getWadoUrl(
  baseWadoUrl: string,
  studyInstanceUid: string,
  seriesInstanceUid: string,
  sopInstanceUid: string | null
): string {
  if (!sopInstanceUid) {
    throw new Error("Cannot getWadoUrl for null sopInstanceUid");
  }

  const uriBasePath = "wadouri:" + baseWadoUrl;
  const studyUID = "?studyUID=" + stripSyntheticDataString(studyInstanceUid);
  const seriesUID = "&seriesUID=" + stripSyntheticDataString(seriesInstanceUid);
  const objectUID = "&objectUID=" + stripSyntheticDataString(sopInstanceUid);
  const contentType = "&contentType=application/dicom";

  const wadoUrl = uriBasePath + studyUID + seriesUID + objectUID + contentType;
  return normalizeImageId(wadoUrl, baseWadoUrl);
}

export function stripSyntheticDataString(uid: string, delimiter = "_SD_"): string {
  const regex = new RegExp(`(.*)(${delimiter}[A-Z]*$)`);
  return uid.replace(regex, "$1");
}
