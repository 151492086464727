import { useAuth0 } from "@auth0/auth0-react";
import { GetTokenSilentlyOptions } from "@auth0/auth0-spa-js/src/global";

type ReturnType = (config: GetTokenSilentlyOptions) => Promise<string>;

export function useGetTokenSilently(): ReturnType {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();

  return async (config) => {
    let token;
    try {
      token = await getAccessTokenSilently(config);
    } catch (e) {
      console.warn("Failed to get token silently, trying with popup", e);
      token = await getAccessTokenWithPopup(config);
    }
    return token;
  };
}
