import React from "react";
import { components } from "react-select";
import { OptionProps } from "react-select/src/components/Option";
import styled from "styled-components";

import FilterCheckbox from "../../../../../Analysis/ToolPanel/components/FilterCheckbox";
import { Label } from "../../../../../common/components/Label";
import { main } from "../../../../../common/theme/main";
import {
  GenericMultiSelect,
  OptionType,
} from "../../../../../DataManagement/Upload/components/GenericMultiSelect";
import {
  taskProgressColors,
  taskProgressDisplayNames,
  TaskProgressType,
} from "../../../TaskWorklist/TaskProgressType";
import { getFilterPopoutSelectStyle } from "./getFilterPopoutSelectStyle";

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
`;

export const Option = (props: OptionProps<OptionType<TaskProgressType | undefined>, true>) => {
  const {
    colors: {
      neutral: { white, neutral4 },
    },
  } = main;

  //@ts-ignore
  const { value: progress } = props as OptionType<TaskProgressType | undefined>;

  const background = progress ? taskProgressColors[progress] : neutral4;
  const text = progress ? taskProgressDisplayNames[progress] : "Unassigned";

  return (
    <div>
      <components.Option {...props}>
        <OptionWrapper>
          <FilterCheckbox
            label={
              <Label background={background} color={white}>
                {text}
              </Label>
            }
            name={props.label}
            checked={props.isSelected}
            onChange={() => undefined}
          />
        </OptionWrapper>
      </components.Option>
    </div>
  );
};

interface TaskProgressColumnFilterProps {
  progresses: (TaskProgressType | undefined)[];
  selectedProgresses: (TaskProgressType | undefined)[];
  onSelectedProgressesChanged: (progresses: (TaskProgressType | undefined)[]) => void;
}

export function TaskProgressColumnFilter({
  progresses,
  selectedProgresses,
  onSelectedProgressesChanged,
}: TaskProgressColumnFilterProps): JSX.Element {
  const handleFilterChanged = (progresses: (TaskProgressType | undefined)[]) => {
    onSelectedProgressesChanged(progresses);
  };

  const styles = getFilterPopoutSelectStyle<OptionType<TaskProgressType | undefined>, true>();

  return (
    <GenericMultiSelect
      autoFocus={false}
      values={progresses}
      selected={selectedProgresses}
      placeholder={"Filter Progress..."}
      getOption={(value) => ({
        value,
        label: value ? taskProgressDisplayNames[value] : "Unassigned",
      })}
      onSelectedChanged={handleFilterChanged}
      menuPortalTarget={null}
      isClearable={false}
      menuIsOpen
      backspaceRemovesValue={false}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      controlShouldRenderValue={false}
      tabSelectsValue={false}
      styles={styles}
      preventBaseStyles={true}
      components={{
        Option,
        DropdownIndicator: null,
        IndicatorSeparator: null,
      }}
    />
  );
}
