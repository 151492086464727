import { ROI } from "../../../../../../../../cornerstone/ToolType";
import { getToolSliceData, setToolSliceData } from "../../legacy/ToolUtils";
import { ContourCallbackDataType } from "../types/ContourCallbackDataType";
import { useContourChanged } from "./useContourChanged";

type ReturnType = {
  deleteContour: (data: ContourCallbackDataType) => Promise<void>;
};

export function useDeleteSelectedContour(seriesId: number | undefined): ReturnType {
  const { onContourChanged } = useContourChanged(seriesId);

  const deleteContour = async (data: ContourCallbackDataType) => {
    const { imageId, roiId, toolData } = data;

    const existingToolData = getToolSliceData(roiId, imageId, ROI);

    const newToolData = existingToolData.filter((item) => !toolData.includes(item));

    setToolSliceData(ROI, roiId, imageId, newToolData);

    await onContourChanged({
      ...data,
      toolData: newToolData,
    });
  };

  return { deleteContour };
}
