import Switch from "@mui/material/Switch";
import styled from "styled-components";

const ToggleSwitch = styled(Switch)`
  && .Mui-checked {
    color: ${(props) => props.theme.colors.states.success};
  }
  && .Mui-checked + .MuiSwitch-track {
    background-color: ${(props) => props.theme.colors.states.success};
  }
`;

export default ToggleSwitch;
