import {
  alpha,
  Box,
  Checkbox,
  CheckboxProps as MuiCheckboxProps,
  FormControl,
  FormControlLabel,
  FormLabel,
} from "@mui/material";
import React, { CSSProperties, useCallback, useMemo } from "react";
import styled from "styled-components";

interface CheckboxItem<T extends string> {
  label: string;
  value: T;
  selected: boolean;
  color?: string;
}

interface CheckboxProps<T extends string> {
  title?: string;
  items: CheckboxItem<T>[];
  disabled?: boolean;
  requireOne?: boolean;
  size?: "small" | "medium";
  direction?: "vertical" | "horizontal";
  showReset?: boolean;
  defaultValue?: T[];
  onChange?: (selectedItems: CheckboxItem<T>[]) => void;
  style?: CSSProperties;
}

const ResetButton = styled.button<{
  visible?: boolean;
}>`
  border: none;
  box-sizing: border-box;
  border-radius: 6px;
  color: rgba(27, 31, 32, 0.6);
  padding: 2px 8px;
  background-color: transparent;
  cursor: pointer;
  font-size: 11px;
  font-weight: 600;
  font-family: Inter, verdana, sans-serif;
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  & .MuiFormControlLabel-label {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    cursor: pointer;
    font-family: Inter, verdana, sans-serif;
  }
`;

const StyledContainer = styled(Box)<{ direction: "vertical" | "horizontal" }>`
  display: flex;
  flex-direction: ${({ direction }) => (direction === "vertical" ? "column" : "row")};
  align-items: ${({ direction }) => (direction === "vertical" ? "flex-start" : "center")};
  justify-content: flex-start;
  gap: 16px;
`;
// TODO: Gap should use theme.spacing but it's not working probably because we're importing from styled-components

const StyledFormGroup = styled(Box)<{ direction: "vertical" | "horizontal" }>`
  display: flex;
  flex-direction: ${({ direction }) => (direction === "vertical" ? "column" : "row")};
  align-items: ${({ direction }) => (direction === "vertical" ? "flex-start" : "center")};
  justify-content: flex-start;
  max-height: 20px;
  & > * {
    margin-right: ${({ direction }) => (direction === "vertical" ? 0 : 16)}px;
    margin-bottom: ${({ direction }) => (direction === "vertical" ? 16 : 0)}px;
  }
`;

const StyledCheckbox = styled(Checkbox)<{ customColor?: string } & MuiCheckboxProps>`
  &.MuiCheckbox-colorSecondary.Mui-checked {
    color: ${(props) => props.customColor || props.theme.colors.actionPrimary.default};
  }
  &.MuiCheckbox-colorSecondary.Mui-checked:hover {
    background-color: ${(props) =>
      alpha(props.customColor || props.theme.colors.actionPrimary.default, 0.08)};
  }
  &.MuiIconButton-colorSecondary:hover {
    background-color: ${(props) =>
      alpha(props.customColor || props.theme.colors.actionPrimary.default, 0.04)};
  }
`;

export function CheckboxGroup<T extends string>({
  title,
  items,
  disabled,
  requireOne,
  size = "medium",
  direction = "vertical",
  showReset = true,
  defaultValue,
  onChange,
  style,
}: CheckboxProps<T>) {
  const selectedItems = useMemo(() => {
    return items.filter((item) => item.selected);
  }, [items]);

  const isSelected = useCallback(
    (item: CheckboxItem<T>) => selectedItems.includes(item),
    [selectedItems]
  );

  const shouldPreventDeselect = useCallback(
    (item: CheckboxItem<T>) =>
      requireOne && isSelected(item) && selectedItems.length === 1 ? true : false,
    [isSelected, requireOne, selectedItems]
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(
        items.map((item) => {
          if (item.value !== event.target.name || shouldPreventDeselect(item)) {
            return item;
          }
          return {
            ...item,
            selected: !item.selected,
          };
        })
      );
    },
    [shouldPreventDeselect, onChange, items]
  );

  const shouldShowResetButton = useMemo(() => {
    return (
      showReset &&
      selectedItems
        .map((item) => item.value)
        .sort()
        .join() !== [...(defaultValue || [])]?.sort().join()
    );
  }, [showReset, selectedItems, defaultValue]);

  const reset = useCallback(() => {
    onChange?.(
      items.map((item) => ({
        ...item,
        selected: defaultValue?.includes(item.value) ?? true,
      }))
    );
  }, [onChange, items, defaultValue]);

  return (
    <StyledContainer direction={direction} style={style}>
      <ResetButton onClick={reset} visible={shouldShowResetButton}>
        Reset
      </ResetButton>
      <FormControl component="fieldset" variant="standard" disabled={disabled}>
        {title && <FormLabel component="legend">{title}</FormLabel>}
        <StyledFormGroup direction={direction}>
          {items.map((item) => (
            <StyledFormControlLabel
              key={item.value}
              control={
                <StyledCheckbox
                  customColor={item.color}
                  size={size}
                  style={{
                    opacity: shouldPreventDeselect(item) ? 0.6 : 1,
                  }}
                  color="secondary"
                  checked={
                    selectedItems.find((selectedItem) => selectedItem.value === item.value)
                      ?.selected ?? false
                  }
                  onChange={handleChange}
                  name={item.value}
                />
              }
              label={item.label}
            />
          ))}
        </StyledFormGroup>
      </FormControl>
    </StyledContainer>
  );
}
