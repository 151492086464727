import { Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";

import {
  DEFAULT_TAB,
  QC_SCOPE_FOR_TAB,
  QcForm,
  qcSchemaTabTypes,
  SERIES_TAB,
  SUBJECT_TAB,
  Wrapper,
} from "./QcForm";
import { useQualityControlContext } from "./QualityControlProvider";
import { QcFormScope } from "./QualityControlProvider.types";

export function QcTabsContainer(): JSX.Element {
  const [tabValue, setTabValue] = useState<qcSchemaTabTypes>(DEFAULT_TAB);

  const {
    activeScopeState: [, setActiveScope],
    schema,
  } = useQualityControlContext();

  useEffect(() => {
    // always default to series tab
    setTabValue(SERIES_TAB);
    setActiveScope("SERIES");
  }, [schema?.series_rule_set.length, schema?.subject_rule_set.length, setActiveScope]);

  useEffect(() => {
    setActiveScope(QC_SCOPE_FOR_TAB[tabValue] as QcFormScope);
  }, [setActiveScope, tabValue]);

  const handleChange = (value: qcSchemaTabTypes): void => {
    setTabValue(value);
  };

  const shouldShowSubjectTab = schema && schema.subject_rule_set.length > 0;

  return (
    <Wrapper className="qc-tabs-wrapper">
      <Tabs value={tabValue} onChange={(e, val) => handleChange(val)} className="qc-tabs-tab-bar">
        <Tab label="Series Rules" value={SERIES_TAB} />
        {shouldShowSubjectTab && <Tab label="Subject Rules" value={SUBJECT_TAB} />}
      </Tabs>
      <QcForm scope="SERIES" visible={tabValue === SERIES_TAB} />
      {shouldShowSubjectTab && <QcForm scope="PATIENT" visible={tabValue === SUBJECT_TAB} />}
    </Wrapper>
  );
}
