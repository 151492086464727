import React, { FC } from "react";

import { main } from "../../theme/main";
import { InputButton } from "../input/InputButton";
import { ActionButtonsWrapper } from "./Form/ActionButtonsWrapper";
import { Wrapper } from "./Form/Wrapper";
import { FormProps } from "./FormProps";
import { RED_BUTTON_OVERRIDES } from "./RedButtonOverrides";

export interface OkCancelFormProps {
  title: string;
  message: React.ReactNode;
  okLabel?: string;
  cancelLabel?: string;
  onOkCallback?: () => void;
  onCancelCallback?: () => void;
  width?: number;
  destructive?: boolean;
  showCloseButton?: boolean;
}

export const OkCancelForm: FC<FormProps<OkCancelFormProps>> = ({
  props: {
    message,
    okLabel = "Ok",
    cancelLabel = "Cancel",
    onOkCallback,
    onCancelCallback,
    width,
    destructive = false,
  },
  onSubmit,
  onCancel,
}: FormProps<OkCancelFormProps>) => {
  const handleSubmitForm = () => {
    onOkCallback?.();
    onSubmit();
  };

  const handleCancel = () => {
    onCancelCallback?.();
    onCancel();
  };

  const okOverrides = destructive ? { ...RED_BUTTON_OVERRIDES } : {};

  return (
    <form onSubmit={handleSubmitForm}>
      <Wrapper width={width}>
        {message}
        <ActionButtonsWrapper>
          <InputButton
            autoFocus
            type="button"
            name="submit-button"
            value={okLabel}
            {...okOverrides}
            onClick={handleSubmitForm}
          />
          <InputButton
            type="button"
            name="cancel-button"
            value={cancelLabel}
            background={main.colors.neutral.white}
            color={main.colors.neutral.black}
            onClick={handleCancel}
          />
        </ActionButtonsWrapper>
      </Wrapper>
    </form>
  );
};
