import { ELLIPTICAL_ROI, ROI } from "../../../../../../cornerstone/ToolType";
import { ToolRoiType } from "../../../../../types/ToolRoiType";
import { SELECTED_CONTOUR_COLOR } from "../../ViewerPanel/tools/legacy/ToolUtils";
import { LesionListItemFragmentType } from "../fragments/LesionListItemFragment";
import { useLesionColorDeferred } from "../utils/useLesionColorDeferred";

type ToolControllerRoi = {
  id: number;
  seriesId: number;
  contoursToolRoi: ToolRoiType;
  ellipseToolRoi: ToolRoiType;
  labelMapId: number;
};

export function useGetToolControllerRoi(): (
  roiId: number,
  seriesId: number,
  lesion: LesionListItemFragmentType,
  userId: number
) => ToolControllerRoi | null {
  const getLesionColor = useLesionColorDeferred();

  return (roiId, seriesId, lesion, userId) => {
    const { id: lesionId, location } = lesion;
    const color = getLesionColor(location);

    return {
      id: roiId,
      seriesId,
      contoursToolRoi: {
        id: roiId,
        type: ROI,
        userId,
        lesionId,
        color: SELECTED_CONTOUR_COLOR,
        fillColor: SELECTED_CONTOUR_COLOR,
      },
      ellipseToolRoi: {
        id: roiId,
        type: ELLIPTICAL_ROI,
        userId,
        lesionId,
        color,
        fillColor: color,
      },
      labelMapId: 0, //todo implement
    };
  };
}
