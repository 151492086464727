import { Drawer as DrawerMui } from "@mui/material";

import { DrawerPage } from "../../Project/Layout/DrawerPage";
import { DrawerPageType } from "../../Project/Layout/Layout";

interface DrawerProps {
  open: boolean;
  page: DrawerPageType;
  width?: number;
  zIndex?: number;
  onClose?: () => void;
}

export function Drawer({ open, page, width, zIndex, onClose }: DrawerProps): JSX.Element {
  return (
    <DrawerMui
      anchor="right"
      disableEnforceFocus
      onClose={onClose}
      open={open}
      ModalProps={{
        sx: {
          zIndex: zIndex ? zIndex : 1200,
        },
      }}
    >
      <DrawerPage page={page} width={width} />
    </DrawerMui>
  );
}
