import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardHeaderText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 24px;
`;

interface ItemsCardProps {
  label: string;
  children: JSX.Element;
}

export function ItemsCard({ label, children }: ItemsCardProps): JSX.Element {
  return (
    <Wrapper>
      <CardHeaderText>{label}</CardHeaderText>
      {children}
    </Wrapper>
  );
}
