import React, { useContext, useEffect } from "react";

import { SimpleViewer } from "../../../../../Annotate/components/Annotate/page/ViewerPanel/SimpleViewer";
import { getViewerConfigFromSeriesId } from "../../../../../Annotate/components/Annotate/page/ViewerPanel/utils/getViewerConfigFromSeriesId";
import { useChangeViewerSeries } from "../../../../../Annotate/components/Annotate/page/ViewerPanel/Viewer/hooks/useChangeViewerSeries";
import { useClearViewers } from "../../../../../Annotate/components/Annotate/page/ViewerPanel/Viewer/hooks/useClearViewers";
import { useSetViewers } from "../../../../../Annotate/components/Annotate/page/ViewerPanel/Viewer/hooks/useSetViewers";
import { ViewerContext } from "../../../../../Annotate/components/Annotate/page/ViewerPanel/Viewer/ViewerContext";
import { ViewersGrid } from "../../../../../Annotate/components/Annotate/page/ViewerPanel/ViewersGrid";
import { Toolbar } from "./Toolbar";

interface ViewerPanelProps {
  seriesId: number | undefined;
  onLoaded: (seriesId: number | undefined) => void;
}

export function ViewerPanel({ seriesId, onLoaded }: ViewerPanelProps): JSX.Element {
  const { viewerConfigs } = useContext(ViewerContext);
  const changeViewerSeries = useChangeViewerSeries();

  const clearViewers = useClearViewers();
  const setViewers = useSetViewers();

  useEffect(() => {
    return () => {
      clearViewers();
    };
  }, []);

  useEffect(() => {
    if (seriesId === undefined) {
      return;
    }

    if (viewerConfigs.length === 0) {
      const newConfig = getViewerConfigFromSeriesId(seriesId, 0);
      setViewers([newConfig]);
      onLoaded(seriesId);
      return;
    }

    const { id: firstViewerConfigId } = viewerConfigs[0];

    changeViewerSeries(firstViewerConfigId, seriesId);
  }, [seriesId]);

  return (
    <>
      <Toolbar />
      <ViewersGrid Viewer={SimpleViewer} />
    </>
  );
}
