import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../store";

const classifications = (state: RootState) => state.annotatePage.classifications;

export const lesionsClassificationsDefinitionsSelector = createSelector(
  [classifications],
  ({ lesionsClassificationsDefinitions }) => lesionsClassificationsDefinitions
);
