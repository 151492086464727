import React, { useContext, useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { ReactComponent as Brush } from "../../../../../../assets/svgs/Brush.svg";
import { ReactComponent as HockeyPuck } from "../../../../../../assets/svgs/HockeyPuck.svg";
import { ReactComponent as Lens } from "../../../../../../assets/svgs/Lens.svg";
import { ReactComponent as Link } from "../../../../../../assets/svgs/Link.svg";
import { ReactComponent as Pan } from "../../../../../../assets/svgs/Pan.svg";
import { ReactComponent as Polyline } from "../../../../../../assets/svgs/Polyline.svg";
import { ReactComponent as ScissorTool } from "../../../../../../assets/svgs/ScissorTool.svg";
import { ReactComponent as Search } from "../../../../../../assets/svgs/Search.svg";
import { ReactComponent as Visibility } from "../../../../../../assets/svgs/Visibility.svg";
import { taskToolsSelector } from "../../../../../../common/store/annotatePage/taskSelector";
import {
  MEASURE_QUEUE,
  WINDOW_LEVEL_PRESET_QUEUE,
} from "../../../../../../common/store/annotatePage/ViewerConfigType";
import { WindowLevelPresetType } from "../../../../../../common/store/annotatePage/WindowLevelPresetType";
import { main } from "../../../../../../common/theme/main";
import { ImageViewerSynchronizer } from "../../../../../../cornerstone/ImageViewerSynchronizer";
import {
  BRUSH,
  ELLIPTICAL_ROI,
  FREEHAND_SCISSORS,
  MEASURE,
  PAN,
  ROI,
  ROI_SCULPTOR,
  ToolType,
  WINDOWLEVEL,
  ZOOM,
} from "../../../../../../cornerstone/ToolType";
import { ANNOTATION_SCOPE } from "../../../../../../Project/Layout/Layout";
import { OBJECT_CONTOUR, OBJECT_DETECTION } from "../../../../../enums/TaskDescriptionEnums";
import { TaskContext } from "../../../../../TaskContext";
import { clearNonReadOnlyToolSliceData } from "../tools/legacy/ToolUtils";
import { useActiveTool } from "../Viewer/hooks/useActiveTool";
import { useSetActiveTool } from "../Viewer/hooks/useSetActiveTool";
import { useSetAllViewersToolQueue } from "../Viewer/hooks/useSetAllViewersToolQueue";
import { MeasureCancelButtons } from "./MeasureCancelButtons";
import { ToolbarCheckboxButton } from "./ToolbarCheckboxButton";
import { ToolbarRadioButton } from "./ToolbarRadioButton";
import { useLesionsVisibility } from "./useLesionsVisibility";
import { ViewerControlsPopover } from "./ViewerControlsPopover";
import { WindowLevelButton } from "./WindowLevelButton";
import { windowLevelPresets } from "./windowLevelPresets";

const Wrapper = styled.div`
  flex: 0;
  background: ${main.colors.background.main};
  display: flex;
  flex-direction: row;
  padding: 8px 20px 8px 20px;
  gap: 20px;
  border-radius: 6px;
  pointer-events: auto;
`;

const DividerWrapper = styled.div`
  width: 2px;
  height: 20px;
  background-color: ${main.colors.neutral.neutral7};
`;

export function ViewerToolbar(): JSX.Element {
  const { activeTool } = useActiveTool();
  const { viewOnly, isQc } = useContext(TaskContext);
  const availableTools = useSelector(taskToolsSelector);
  const setAllViewersToolQueue = useSetAllViewersToolQueue();
  const setActiveTool = useSetActiveTool();
  const [preset, setPreset] = useState<WindowLevelPresetType | undefined>(undefined);

  const showContouringTools = availableTools.includes(OBJECT_CONTOUR);
  const showDetectTools = availableTools.includes(OBJECT_DETECTION);

  const [isLinked, setLink] = useState(false);

  useEffect(() => {
    const imageViewerSynchronizer = ImageViewerSynchronizer.getInstance();
    const linked = imageViewerSynchronizer.getEnabled();

    setLink(linked);

    const initialTool = viewOnly
      ? PAN
      : showDetectTools
      ? ELLIPTICAL_ROI
      : showContouringTools
      ? ROI
      : PAN;

    handleToolChanged(initialTool);
  }, []);

  const { visibility, setVisibility } = useLesionsVisibility();

  useHotkeys(
    "d",
    () => {
      if (!viewOnly && showDetectTools) {
        handleToolChanged(ELLIPTICAL_ROI);
      }
    },
    { scopes: [ANNOTATION_SCOPE] }
  );

  useHotkeys(
    "c",
    () => {
      if (!viewOnly && showContouringTools) {
        handleToolChanged(ROI);
      }
    },
    { scopes: [ANNOTATION_SCOPE] }
  );

  useHotkeys(
    "r",
    () => {
      handleStartMeasurements(MEASURE);
    },
    { scopes: [ANNOTATION_SCOPE] }
  );

  useHotkeys(
    "v",
    () => {
      if (!viewOnly && showContouringTools) {
        handleToolChanged(ROI_SCULPTOR);
      }
    },
    { scopes: [ANNOTATION_SCOPE] }
  );

  useHotkeys(
    "h",
    () => {
      handleToolChanged(PAN);
    },
    { scopes: [ANNOTATION_SCOPE] }
  );

  useHotkeys(
    "e",
    () => {
      handleVisibility();
    },
    { scopes: [ANNOTATION_SCOPE] }
  );

  useHotkeys(
    "z",
    () => {
      handleToolChanged(ZOOM);
    },
    { scopes: [ANNOTATION_SCOPE] }
  );

  useHotkeys(
    "w",
    () => {
      if (activeTool !== WINDOWLEVEL) {
        handleToolChanged(WINDOWLEVEL);
      } else {
        if (!preset) {
          handleWindowLevelPresetSelected(windowLevelPresets[0]);
          setPreset(windowLevelPresets[0]);
        } else {
          const newPresetIndex =
            windowLevelPresets.findIndex(({ name }) => name === preset.name) + 1;
          handleWindowLevelPresetSelected(
            windowLevelPresets[newPresetIndex !== windowLevelPresets.length ? newPresetIndex : 0]
          );
          setPreset(
            windowLevelPresets[newPresetIndex !== windowLevelPresets.length ? newPresetIndex : 0]
          );
        }
      }
    },
    { scopes: [ANNOTATION_SCOPE] }
  );

  const handleToolChanged = (tool: ToolType) => {
    setActiveTool(tool);
  };

  const handleVisibility = () => {
    setVisibility(!visibility);
  };

  const handleLink = () => {
    const linked = !isLinked;
    setLink(linked);

    const imageViewerSynchronizer = ImageViewerSynchronizer.getInstance();
    imageViewerSynchronizer.setEnabled(linked);
  };

  const handleStartMeasurements = (tool: ToolType) => {
    //The order of these calls has been swapped, see the comment in ../tools/toolController/useToolController.ts
    handleToolChanged(tool);
    setAllViewersToolQueue({ queue: MEASURE_QUEUE, value: true });
  };

  const handleCancelMeasurements = () => {
    clearNonReadOnlyToolSliceData(MEASURE);
    setAllViewersToolQueue({ queue: MEASURE_QUEUE, value: false });
  };

  const handleWindowLevelPresetSelected = (preset: WindowLevelPresetType) => {
    setAllViewersToolQueue({
      queue: WINDOW_LEVEL_PRESET_QUEUE,
      value: preset,
    });
  };

  const viewTools = [
    <DividerWrapper key={"DIVIDE0"} />,
    <ViewerControlsPopover key={0} disabled={isQc} />,
    <WindowLevelButton
      value={WINDOWLEVEL}
      currentValue={activeTool}
      onChange={handleToolChanged}
      presets={windowLevelPresets}
      onPresetSelected={handleWindowLevelPresetSelected}
      key={WINDOWLEVEL}
      tooltip={"Window Level (W)"}
    />,
    <ToolbarRadioButton
      icon={Search}
      value={ZOOM}
      currentValue={activeTool}
      onChange={handleToolChanged}
      key={ZOOM}
      tooltip={"Zoom (Z)"}
    />,
    <ToolbarRadioButton
      icon={Pan}
      value={PAN}
      currentValue={activeTool}
      onChange={handleToolChanged}
      key={PAN}
      tooltip={"Pan (H)"}
    />,
  ];

  const detectTools = [
    <ToolbarRadioButton
      icon={Lens}
      value={ELLIPTICAL_ROI}
      currentValue={activeTool}
      onChange={handleToolChanged}
      key={ELLIPTICAL_ROI}
      tooltip={"Draw Ellipse (D)"}
    />,
  ];

  const contourTools = [
    <ToolbarRadioButton
      icon={Polyline}
      value={ROI}
      currentValue={activeTool}
      onChange={handleToolChanged}
      key={ROI}
      tooltip={"Draw Roi (C)"}
    />,
    <ToolbarRadioButton
      icon={HockeyPuck}
      value={ROI_SCULPTOR}
      currentValue={activeTool}
      onChange={handleToolChanged}
      key={ROI_SCULPTOR}
      tooltip={"Sculpt Roi (V)"}
    />,
  ];

  //TEMP disable brush tool buttons since not implemented
  const showBrushTools = false;

  const brushTools = [
    <ToolbarRadioButton
      icon={Brush}
      value={BRUSH}
      currentValue={activeTool}
      onChange={handleToolChanged}
      key={BRUSH}
    />,
    <ToolbarRadioButton
      icon={ScissorTool}
      value={FREEHAND_SCISSORS}
      currentValue={activeTool}
      onChange={handleToolChanged}
      key={FREEHAND_SCISSORS}
    />,
  ];

  const measureTools = [
    <DividerWrapper key={"DIVIDE2"} />,
    <ToolbarCheckboxButton
      icon={Visibility}
      value={visibility}
      onChange={handleVisibility}
      key={"VISIBILITY"}
      tooltip={"Toggle Visibility (E)"}
    />,
    <ToolbarCheckboxButton icon={Link} value={isLinked} onChange={handleLink} key={"LINK"} />,
  ];

  const lesionTools = [
    ...(!viewOnly && showDetectTools ? detectTools : []),
    ...(!viewOnly && showContouringTools ? contourTools : []),
    <MeasureCancelButtons
      onCancel={handleCancelMeasurements}
      value={MEASURE}
      currentValue={activeTool}
      onChange={handleStartMeasurements}
      key={MEASURE}
      tooltip={"Ruler (R)"}
    />,
    ...viewTools,
    ...(!viewOnly && showBrushTools ? brushTools : []),
    ...measureTools,
  ];

  return <Wrapper>{lesionTools}</Wrapper>;
}
