import { GraphView } from "../../ChangeFromBaseline/components/Filter";
import { getDataTypeUnit } from "../../common/utils/formatDataType";
import { getDataTypeFromName } from "./getDataTypeFromName";

export const formatTooltipValue = (
  value: number,
  name: string,
  graphView: GraphView,
  unitOverride?: string
) => {
  const dataType = getDataTypeFromName(name, graphView);

  if (!dataType) {
    throw new Error(`Could not find data type for ${name}`);
  }

  const unit = unitOverride ?? getDataTypeUnit(dataType, graphView);
  const formattedValue = `${value.toFixed(1)} ${unit}`;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // we need to ignore this because recharts' type doesn't permit us to change the type of value from number to string
  return [formattedValue, name] as [number, string];
};
