import React, { ReactNode } from "react";
import styled from "styled-components";

import { main } from "../../theme/main";
import { InputDiv } from "../input/InputButton";
import { FlexLoading } from "../Loading";

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;

interface ActionButtonProps {
  onClick?: () => void;
  children?: ReactNode;
  disabled?: boolean;
  loading?: boolean;
  width?: number;
}

export function ActionButton({
  onClick,
  disabled = false,
  loading = false,
  width,
  children,
}: ActionButtonProps): JSX.Element {
  const {
    colors: {
      neutral: { black, white },
      actionPrimary: { disabled: disabledColor },
    },
  } = main;

  const color = !disabled ? black : disabledColor;

  return (
    <InputDiv
      disabled={disabled || loading}
      background={color}
      borderColor={color}
      width={width}
      type="button"
      onClick={!disabled && !loading ? onClick : undefined}
    >
      <ContentWrapper>
        {loading && (
          <div>
            <FlexLoading color={white} />
          </div>
        )}
        {children}
      </ContentWrapper>
    </InputDiv>
  );
}
