import { createSelector } from "@reduxjs/toolkit";
import React, { FC, ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getProjectViewFilters,
  projectViewFiltersActions,
} from "../../../common/store/projectViewFiltersSlice";
import { capitalize } from "../../../common/utils/capitalize";
import { AjccStageType } from "../../common/types/AjccStageType";
import FilterButton from "./FilterButton";
import FilterCategory from "./FilterCategory";
import { ButtonWrapper } from "./FilterInputWrapper";

const getStageFilters = createSelector(
  [getProjectViewFilters],
  (projectViewFilters) => projectViewFilters.stage
);

export const StageFilters: FC = () => {
  const dispatch = useDispatch();
  const { ajccStage } = useSelector(getStageFilters);

  const components: ReactElement[] = [];

  for (const key in ajccStage) {
    if (!Object.prototype.hasOwnProperty.call(ajccStage, key)) {
      continue;
    }

    const stage = key as AjccStageType;
    const isChecked = ajccStage[stage] ?? false;

    const onFilterChanged = (checked: boolean) => {
      dispatch(
        projectViewFiltersActions.setAjccStage({
          ...ajccStage,
          [stage]: checked,
        })
      );
    };

    components.push(
      <FilterButton
        key={stage}
        text={capitalize(stage)}
        onClick={onFilterChanged}
        active={isChecked}
        width={40}
      />
    );
  }

  const options = <ButtonWrapper>{components}</ButtonWrapper>;

  return <FilterCategory name={"Stage"} options={options} />;
};
