import { NewProjectType } from "./NewProjectType";

export const EMPTY_PROJECT: NewProjectType = {
  name: "",
  status: "ACTIVE",
  startDate: new Date(),
  arms: 2,
  targetEndDate: new Date("2023-01-01"),
  estimatedCompletion: new Date("2023-01-01"),
  cohorts: [],
  disabled: false,
};
