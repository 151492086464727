import { main } from "../../../common/theme/main";
import {
  COMPLETE_RESPONSE,
  MISSING,
  NOT_EVALUABLE,
  PARTIAL_RESPONSE,
  PROGRESSIVE_DISEASE,
  STABLE_DISEASE,
  TumourResponseType,
} from "../types/TumourResponseType";

export const tumourResponseColors: Record<TumourResponseType, string> = {
  [PROGRESSIVE_DISEASE]: main.colors.states.error,
  [STABLE_DISEASE]: main.colors.states.warning,
  [PARTIAL_RESPONSE]: main.colors.states.info,
  [COMPLETE_RESPONSE]: main.colors.states.success,
  [NOT_EVALUABLE]: main.colors.states.error,
  [MISSING]: main.colors.states.error,
};
