import FlagIcon from "@mui/icons-material/Flag";
import React, { ReactElement } from "react";

import { OptionType } from "../../../DataManagement/Upload/components/GenericMultiSelect";
import { main } from "../../theme/main";
import { MuiIcon } from "../icons/MuiIcon";

export const FLAGGED = "Flagged";
export const PARTIALLY_FLAGGED = "Partially Flagged";
export const NOT_FLAGGED = "Not Flagged";

export type FlagTypeOptions = typeof FLAGGED | typeof PARTIALLY_FLAGGED | typeof NOT_FLAGGED;

export interface SeriesFlagProps {
  flagType?: FlagTypeOptions;
  flagSize?: number;
  flaggedColor?: string;
  unflaggedColor?: string;
  partialFlaggedColor?: string;
}

export function SeriesFlag({
  flagType = FLAGGED,
  flagSize = 15,
  flaggedColor = main.colors.states.error,
  unflaggedColor = main.colors.neutral.black,
  partialFlaggedColor = main.colors.states.warning,
}: SeriesFlagProps): JSX.Element {
  let color = unflaggedColor;

  if (flagType === FLAGGED) {
    color = flaggedColor;
  } else if (flagType === PARTIALLY_FLAGGED) {
    color = partialFlaggedColor;
  }

  return <MuiIcon icon={FlagIcon} color={color} size={flagSize} />;
}

export function getFlagOption(
  flag: FlagTypeOptions | undefined
): OptionType<FlagTypeOptions | undefined> {
  if (!flag) {
    return {
      value: undefined,
      label: "Unassigned",
    };
  }
  return { value: flag, label: flag };
}

export function areFlagsEqual(a: FlagTypeOptions, b: FlagTypeOptions): boolean {
  return a === b;
}

export const seriesFlagFilterProps = {
  getOption: getFlagOption,
  includeUnassigned: false,
  placeholder: "Filter Flags...",
  rightAlign: true,
  // eslint-disable-next-line react/display-name,react/prop-types
  RowLabelComponent: ({ value }: { value: FlagTypeOptions }): ReactElement | null =>
    value !== NOT_FLAGGED ? <SeriesFlag flagType={value} /> : null,
  // eslint-disable-next-line react/display-name,react/prop-types
  SelectLabelComponent: ({ value }: { value: FlagTypeOptions }): ReactElement | null => {
    return (
      <>
        {value !== NOT_FLAGGED && <SeriesFlag flagType={value} />}
        {value}
      </>
    );
  },
};
