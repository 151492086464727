import React, { ReactElement } from "react";
import styled from "styled-components";

const StyledSideNavButton = styled.button<{ active: boolean }>`
  width: 240px;
  height: 56px;
  @media only screen and (max-height: 800px) {
    height: 42px;
  }
  outline: none;
  padding-inline-start: 64px;
  display: flex;
  align-items: center;
  border: none;
  transition: 0.2s all;
  color: ${(props) =>
    props.disabled
      ? props.theme.colors.neutral.white20
      : props.active
      ? props.theme.colors.secondary.secondary
      : props.theme.colors.neutral.white};

  background: ${(props) =>
    props.active ? props.theme.colors.actionPrimary.active : props.theme.colors.neutral.black};

  &:hover {
    background: ${(props) =>
      props.disabled ? props.theme.colors.neutral.black : props.theme.colors.neutral.white10};
    cursor: ${(props) => (props.disabled ? "initial" : "pointer")};
  }
`;

const NavText = styled.p`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
`;
interface SideNavSubButtonProps {
  text: string;
  active: boolean;
  onClick?: React.MouseEventHandler;
}

const SideNavSubButton = ({ text, onClick, active }: SideNavSubButtonProps): ReactElement => {
  return (
    <StyledSideNavButton onClick={onClick} active={active}>
      <NavText>{text}</NavText>
    </StyledSideNavButton>
  );
};

export default SideNavSubButton;
