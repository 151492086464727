import { RoutePathSingleOrArray } from "../Layout";
import { PATIENT_SLUG } from "../RoutePathType";
import { useGoToRoute } from "./useGoToRoute";

export interface LinkToPatientProps {
  patientRoute?: RoutePathSingleOrArray;
}

/**
 * @deprecated prefer <PatientLink />
 */
export function usePatientLinkHandler(
  patientRoute: RoutePathSingleOrArray | undefined
): ((patientId: number) => void) | undefined {
  const goToProjectPath = useGoToRoute();

  if (!patientRoute) {
    return undefined;
  }

  return (patientId: number) => {
    if (!Array.isArray(patientRoute)) {
      throw new Error("patientRoute should be an array");
    }
    const subbedPath = patientRoute.map((route) => {
      switch (route) {
        case PATIENT_SLUG:
          return patientId;
        default:
          return route;
      }
    });
    goToProjectPath(subbedPath);
  };
}
