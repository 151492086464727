import React, { ComponentType } from "react";

import { AnatomicalStructuresFragmentType } from "../../../../Annotate/components/AnatomicalStructuresFragment";
import { ClassificationAttributeType } from "../../../../Annotate/components/Manage/hooks/useClassificationAttributes";
import { AnnotationTableRowType } from "./AnnotationTableRowType";

interface LabelColumnRendererProps {
  row: AnnotationTableRowType;
  AnatomicalStructureComponent: ComponentType<{
    label: AnatomicalStructuresFragmentType;
  }>;
  ClassificationComponent: ComponentType<{
    classification: ClassificationAttributeType;
  }>;
}

export function LabelColumnRenderer({
  row,
  AnatomicalStructureComponent,
  ClassificationComponent,
}: LabelColumnRendererProps): JSX.Element {
  const { classification, anatomicalStructure } = row;
  if (classification && anatomicalStructure) {
    throw new Error("Row contains a classification and an anatomical structure!");
  }

  if (anatomicalStructure) {
    return <AnatomicalStructureComponent label={anatomicalStructure} />;
  }

  if (classification) {
    return <ClassificationComponent classification={classification} />;
  }

  throw new Error("Row does not contain classification or anatomical structure!");
}
