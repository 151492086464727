import { ApolloCache, useMutation } from "@apollo/client";
import { gql, Reference, StoreObject } from "@apollo/client/core";
import { MutationTuple } from "@apollo/client/react/types/types";

import { getSeriesCacheId } from "../../../Annotate/components/Annotate/page/AnnotationPanel/hooks/getSeriesCacheId";
import { getStudyCacheId } from "../../../Annotate/components/Annotate/page/AnnotationPanel/hooks/getStudyCacheId";
import {
  SERIES_LIST_ITEM_FRAGMENT,
  SeriesListItemFragmentType,
  STUDY_LIST_ITEM_FRAGMENT,
} from "../../../Annotate/components/Annotate/page/AnnotationPanel/hooks/useStudiesList";

const MUTATION = gql`
  mutation InsertDeleteSeriesProjectExclusions(
    $projectIds: [Int!]!
    $deleteSeriesIds: [Int!]!
    $seriesExclusions: [project_series_exclusion_insert_input!]!
  ) {
    delete_project_series_exclusion(
      where: {
        _and: [{ project_id: { _in: $projectIds } }, { series_id: { _in: $deleteSeriesIds } }]
      }
    ) {
      deleted: returning {
        projectId: project_id
        series {
          ...SeriesListItem
        }
      }
    }
    insert_project_series_exclusion(objects: $seriesExclusions) {
      inserted: returning {
        projectId: project_id
        series {
          seriesId: series_id
          studyId: study_id
        }
      }
    }
  }
  ${SERIES_LIST_ITEM_FRAGMENT}
`;

type Data = {
  delete_project_series_exclusion: {
    deleted: {
      projectId: number;
      series: SeriesListItemFragmentType;
    }[];
  };
  insert_project_series_exclusion: {
    inserted: {
      projectId: number;
      series: {
        seriesId: number;
        studyId: number;
      };
    }[];
  };
};

type Variables = {
  projectIds: number[];
  deleteSeriesIds: number[];
  seriesExclusions: { project_id: number; series_id: number }[];
};

type StudyWithProjectSeriesType = {
  id: number;
  series: { id: number }[];
};

export function useInsertDeleteSeriesProjectExclusions(): MutationTuple<Data, Variables> {
  return useMutation<Data, Variables>(MUTATION, {
    update(cache, { data }) {
      if (!data) {
        throw new Error("Something went wrong updating the cache after inserting a task");
      }

      const {
        delete_project_series_exclusion: { deleted },
        insert_project_series_exclusion: { inserted },
      } = data;

      for (const {
        series: { seriesId, studyId },
        projectId,
      } of inserted) {
        const seriesCacheId = getSeriesCacheId(seriesId, cache);
        const studyCacheId = getStudyCacheId(studyId, cache);
        cache.modify({
          id: seriesCacheId,
          fields: {
            project_series_exclusions(existingProjectSeriesExclusionRefs = [], { readField }) {
              if (
                existingProjectSeriesExclusionRefs.some(
                  (ref: Reference | StoreObject | undefined) =>
                    readField("project_id", ref) === projectId
                )
              ) {
                return existingProjectSeriesExclusionRefs;
              }
              return [
                ...existingProjectSeriesExclusionRefs,
                {
                  project_id: projectId,
                  __typename: "project_series_exclusion",
                },
              ];
            },
          },
        });

        const study = getStudyFromCache(studyId, projectId, cache);
        if (study) {
          cache.writeFragment<StudyWithProjectSeriesType>({
            id: studyCacheId,
            fragment: STUDY_LIST_ITEM_FRAGMENT,
            fragmentName: "StudyListItem",
            data: {
              ...study,
              series: study.series.filter(({ id }) => id !== seriesId),
            },
            variables: {
              projectIds: [projectId],
            },
          });
        }
      }

      for (const { series, projectId } of deleted) {
        const { id: seriesId, studyId } = series;
        const seriesCacheId = getSeriesCacheId(seriesId, cache);
        const studyCacheId = getStudyCacheId(studyId, cache);
        cache.modify({
          id: seriesCacheId,
          fields: {
            project_series_exclusions(existingProjectSeriesExclusions = [], { readField }) {
              return existingProjectSeriesExclusions.filter(
                (ref: Reference | StoreObject | undefined) =>
                  readField("project_id", ref) !== projectId
              );
            },
          },
        });

        const study = getStudyFromCache(studyId, projectId, cache);
        if (study) {
          cache.writeFragment<StudyWithProjectSeriesType>({
            id: studyCacheId,
            fragment: STUDY_LIST_ITEM_FRAGMENT,
            fragmentName: "StudyListItem",
            data: {
              ...study,
              series: [...study.series, series],
            },
            variables: {
              projectIds: [projectId],
            },
          });
        }
      }
    },
  });
}

function getStudyFromCache<T>(
  studyId: number,
  projectId: number,
  cache: ApolloCache<T>
): StudyWithProjectSeriesType | null {
  return cache.readFragment<StudyWithProjectSeriesType>({
    id: getStudyCacheId(studyId, cache),
    fragment: gql`
      fragment StudyItem on study {
        id: study_id
        series(
          where: { _not: { project_series_exclusions: { project_id: { _in: $projectIds } } } }
          order_by: [{ series_date: asc }]
        ) {
          id: series_id
        }
      }
    `,
    variables: {
      projectIds: [projectId],
    },
  });
}
