export function findIndexGaps(list: number[]): number[] {
  const gaps = [];

  const sorted = list.concat().sort((a, b) => a - b);

  if (sorted[0] < 0) {
    throw new Error("Invalid args: values in list must be positive");
  }

  for (let i = 1; i < sorted.length; i++) {
    const a = sorted[i - 1];
    const b = sorted[i];

    if (b - a > 1) {
      const gapIndex = a + 1;
      gaps.push(gapIndex);
    }
  }

  return gaps;
}
