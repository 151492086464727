import React from "react";
import styled from "styled-components";

import { main } from "../../theme/main";
import { Label } from "../Label";

const Wrapper = styled.div`
  display: inline-block;
  margin: 1px;
`;

interface SeriesFlagLabelProps {
  reason: string;
}

export function SeriesFlagLabel({ reason }: SeriesFlagLabelProps): JSX.Element {
  const {
    colors: {
      neutral: { white, neutral4 },
    },
  } = main;

  return (
    <Wrapper>
      <Label background={neutral4} color={white}>
        {reason}
      </Label>
    </Wrapper>
  );
}
