import React from "react";
import {
  Bar,
  BarChart,
  Label,
  LabelList,
  Legend as ReChartsLegend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Payload } from "recharts/types/component/DefaultLegendContent";

import { Legend } from "../../../Analysis/common/components/Legend";
import { getXAxisProps } from "../../../Analysis/common/utils/getXAxisProps";
import { axisLabelProps } from "../../../Analysis/common/utils/getYAxisLabel";
import { getYAxisProps } from "../../../Analysis/common/utils/getYAxisProps";
import { TEXT_OPACITY } from "../../../Analysis/common/utils/textOpacity";
import {
  TASK_COMPLETED,
  TASK_IN_PROGRESS,
  TASK_PENDING,
  taskProgressColors,
  taskProgressDisplayNames,
  TaskProgressType,
} from "../../../Annotate/components/TaskWorklist/TaskProgressType";
import { ImageCaptureContextMenuContainer } from "../../../common/components/ImageCaptureContextMenuContainer";
import { formatTotal, GenericTooltip } from "../../../common/utils/barChartUtils/GenericTooltip";
import { getBarShape } from "../../../common/utils/barChartUtils/getBarShape";
import { sortData } from "../../../common/utils/barChartUtils/sortData";
import { TasksPerAnnotatorData } from "../utils/buildTasksPerAnnotatorData";

const bars: (keyof Pick<
  TasksPerAnnotatorData,
  typeof TASK_PENDING | typeof TASK_IN_PROGRESS | typeof TASK_COMPLETED
>)[] = [TASK_PENDING, TASK_IN_PROGRESS, TASK_COMPLETED];

const legendPayload: Payload[] = bars.map((bar) => ({
  type: "square",
  color: taskProgressColors[bar],
  value: taskProgressDisplayNames[bar],
}));

interface TasksPerAnnotatorChartProps {
  data: TasksPerAnnotatorData[];
}

export function TasksPerAnnotatorChart({ data }: TasksPerAnnotatorChartProps): JSX.Element {
  const xAxisProps = getXAxisProps();
  const yAxisProps = getYAxisProps(true);

  const longestTaskNameLength = Math.max(...data.map(({ task }) => task.length));

  const getDataCount = (item: TasksPerAnnotatorData) =>
    bars.map((key) => item[key]).reduce((partialSum, a) => partialSum + a, 0);

  const sortedData = sortData(data, getDataCount);

  const formatter = (value: number, name: string) => {
    let formattedName = "N/A";

    if (name && name in taskProgressDisplayNames) {
      formattedName = taskProgressDisplayNames[name as TaskProgressType];
    }

    return [value, formattedName] as [number, string];
  };

  const dense = data.length > 8;

  return (
    <ImageCaptureContextMenuContainer>
      {({ reference }) => (
        <ResponsiveContainer ref={reference}>
          <BarChart
            layout={"horizontal"}
            data={sortedData}
            maxBarSize={100}
            margin={{ top: 0, right: 0, left: 30, bottom: 15 }}
          >
            <ReChartsLegend
              align="center"
              verticalAlign="bottom"
              content={<Legend />}
              payload={legendPayload}
              wrapperStyle={{ bottom: 0 }}
            />
            <XAxis
              {...xAxisProps}
              type={"category"}
              dataKey={"task"}
              interval={0}
              angle={dense ? -90 : 0}
              textAnchor={dense ? "end" : "middle"}
              height={dense ? 20 + 6 * longestTaskNameLength : undefined}
            >
              <Label
                value="Task"
                offset={-5}
                position="insideBottom"
                fontFamily={"Inter"}
                fontStyle={"normal"}
                fontWeight={600}
                fontSize={"11px"}
                opacity={TEXT_OPACITY}
              />
            </XAxis>
            <YAxis
              type={"number"}
              {...yAxisProps}
              allowDecimals={false}
              domain={[0, (dataMax: number) => Math.ceil(dataMax * 1.05)]}
            >
              <Label {...axisLabelProps} position="left" value={"# of Tasks"} offset={15} />
            </YAxis>
            <Tooltip
              content={<GenericTooltip summaryFormatter={formatTotal} />}
              isAnimationActive={false}
              formatter={formatter}
              cursor={false}
            />
            {bars.map((bar, index) => (
              <Bar
                isAnimationActive={false}
                dataKey={bar}
                stackId={"y"}
                key={bar}
                legendType={"square"}
                fill={legendPayload[index].color}
                shape={(props) => getBarShape(props, bars, bar)}
              >
                {index === bars.length - 1 && (
                  <LabelList
                    position="top"
                    valueAccessor={({ value }: { value: number[] }) => value[1]}
                  />
                )}
              </Bar>
            ))}
          </BarChart>
        </ResponsiveContainer>
      )}
    </ImageCaptureContextMenuContainer>
  );
}
