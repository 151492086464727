import { OptionType } from "../../../../DataManagement/Upload/components/GenericMultiSelect";
import { TaskType } from "../hooks/TaskType";

export type TaskOptionType = OptionType<TaskType>;

export function getTaskOption(task: TaskType): TaskOptionType {
  const { name } = task;
  return {
    label: name,
    value: task,
  };
}
