import ClearIcon from "@mui/icons-material/Clear";
import React from "react";
import { Column } from "react-table";
import styled from "styled-components";

import { ReactComponent as EditIcon } from "../../../assets/svgs/EditOutline.svg";
import { SiteType } from "../../types/SiteType";
import { MuiIcon } from "../icons/MuiIcon";
import { SvgIcon } from "../icons/SvgIcon";
import { CellProps } from "../Table/CellProps";
import { useEditSiteDialog } from "./Dialogs/useEditSiteDialog";
import { useRemoveSiteFromProjectDialog } from "./Dialogs/useRemoveSiteFromProjectDialog";
import { SiteActionCallbacksType } from "./SiteActionCallbacksType";
import { SiteLabel } from "./SiteLabel";
import { SiteTableRowType } from "./SiteTableRowType";

export const NAME_COLUMN_ID = "name";
export const EDIT_USER_COLUMN_ID = "editUser";
export const REMOVE_USER_COLUMN_ID = "removeUser";

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 8px;
  cursor: pointer;
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: fit-content;
  min-width: 100px;
`;

export function useProjectSitesTableColumns(
  actionCallbacks: SiteActionCallbacksType,
  allowRemoveSite: boolean,
  allowEditSite: boolean
): Column<SiteType>[] {
  const { onRemoved } = actionCallbacks ?? {
    onRemoved: undefined,
  };
  const columns: Column<SiteType>[] = [
    {
      id: NAME_COLUMN_ID,
      Header: "Name",
      accessor: ({ name }) => name,
      Cell: ({ value: name }: { value: string }) => (
        <LabelWrapper>
          <SiteLabel name={name} />
        </LabelWrapper>
      ),
      showFilter: true,
    },
  ];
  if (allowEditSite) {
    columns.push({
      id: EDIT_USER_COLUMN_ID,
      Header: "",
      style: {
        width: "42px",
      },
      // eslint-disable-next-line react/prop-types,react/display-name
      Cell: ({ row: { original: row } }: CellProps<SiteTableRowType>) => {
        const [setShowEditSiteDialog, { dialog: editSiteDialog }] = useEditSiteDialog(
          row,
          actionCallbacks
        );
        const handleOnClick = () => {
          setShowEditSiteDialog(true);
        };

        return (
          <>
            {editSiteDialog}
            <ButtonWrapper onClick={handleOnClick}>
              <SvgIcon icon={EditIcon} size={16} />
            </ButtonWrapper>
          </>
        );
      },
    });
  }
  if (allowRemoveSite) {
    columns.push({
      id: REMOVE_USER_COLUMN_ID,
      Header: "",
      style: {
        width: "42px",
      },
      // eslint-disable-next-line react/prop-types,react/display-name
      Cell: ({ row: { original: row } }: CellProps<SiteTableRowType>) => {
        const handleConfirmRemoveSite = (site: SiteType) => {
          if (!allowRemoveSite) {
            throw new Error("handleConfirmRemoveSite called but allowRemoveSite is true");
          }

          onRemoved?.(site);
        };

        const [setShowRemoveSiteDialog, { dialog: removeSiteDialog }] =
          useRemoveSiteFromProjectDialog(row, handleConfirmRemoveSite);

        const handleOnClick = () => {
          setShowRemoveSiteDialog(true);
        };

        return (
          <>
            {removeSiteDialog}
            <ButtonWrapper onClick={handleOnClick}>
              <MuiIcon icon={ClearIcon} size={16} />
            </ButtonWrapper>
          </>
        );
      },
    });
  }

  return columns;
}
