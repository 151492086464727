import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import React from "react";
import { FaUser as UserIcon } from "react-icons/fa";

import { ReactComponent as DownloadIcon } from "../../assets/svgs/Download.svg";
import { ReactComponent as HomeIcon } from "../../assets/svgs/Home.svg";
import { ReactComponent as RadiologyIcon } from "../../assets/svgs/Lungs.svg";
import { ReactComponent as SchemaIcon } from "../../assets/svgs/Schema.svg";
import { ReactComponent as SettingsIcon } from "../../assets/svgs/Settings.svg";
import { ReactComponent as TasksIcon } from "../../assets/svgs/Tasks.svg";
import { ReactComponent as UsersIcon } from "../../assets/svgs/Users.svg";
import { AnalysisIcon } from "../../common/components/icons/AnalysisIcon";
import { DataManagementIcon } from "../../common/components/icons/DataManagementIcon";
import { DEFAULT_ICON_SIZE } from "../../common/components/icons/IconDefaults";
import { InvertedFolderIcon } from "../../common/components/icons/InvertedFolderIcon";
import { OverviewIcon } from "../../common/components/icons/OverviewIcon";
import { SvgIcon } from "../../common/components/icons/SvgIcon";
import { main } from "../../common/theme/main";
import {
  ANALYSIS_ICON,
  DATA_MANAGEMENT_ICON,
  DOCUMENTS_ICON,
  DOWNLOAD_ICON,
  HOME_ICON,
  IconType,
  OVERVIEW_ICON,
  RADIOLOGY_ICON,
  SCHEMA_ICON,
  SETTINGS_ICON,
  UPLOAD_ICON,
  USER_ICON,
  USERS_ICON,
  WORKLIST_ICON,
} from "./Layout";

export const Icons: Record<IconType, JSX.Element> = {
  [OVERVIEW_ICON]: <OverviewIcon />,
  [DATA_MANAGEMENT_ICON]: <DataManagementIcon />,
  [ANALYSIS_ICON]: <AnalysisIcon />,
  [DOCUMENTS_ICON]: <InvertedFolderIcon />,
  [RADIOLOGY_ICON]: <SvgIcon icon={RadiologyIcon} color={main.colors.neutral.black} />,
  [DOWNLOAD_ICON]: <SvgIcon icon={DownloadIcon} color={main.colors.neutral.black} />,
  [UPLOAD_ICON]: <CloudUploadOutlinedIcon />,
  [SETTINGS_ICON]: <SvgIcon icon={SettingsIcon} color={main.colors.neutral.black} />,
  [SCHEMA_ICON]: <SvgIcon icon={SchemaIcon} color={main.colors.neutral.black} />,
  [USERS_ICON]: <SvgIcon icon={UsersIcon} color={main.colors.neutral.black} />,
  [USER_ICON]: <UserIcon size={DEFAULT_ICON_SIZE} color={main.colors.neutral.black} />,
  [WORKLIST_ICON]: <SvgIcon icon={TasksIcon} color={main.colors.neutral.black} />,
  [HOME_ICON]: <SvgIcon icon={HomeIcon} color={main.colors.neutral.black} />,
};
