import React from "react";

import { ReactComponent as MicroscopeIcon } from "../../../../../../assets/svgs/Microscope.svg";
import { SvgIcon } from "../../../../../../common/components/icons/SvgIcon";
import { main } from "../../../../../../common/theme/main";
import { IconButton } from "../IconButton";

interface BiopsyIconProps {
  isLinked: boolean;
  isUnsure: boolean;
  onClick: (e: React.MouseEvent<Element, MouseEvent>) => void;
  readOnly?: boolean;
}

export function BiopsyIcon({
  isLinked,
  isUnsure,
  onClick,
  readOnly = false,
}: BiopsyIconProps): JSX.Element {
  const {
    colors: {
      secondary: { secondary },
      neutral: { black },
      states: { warning },
    },
  } = main;

  const color = isLinked ? (isUnsure ? warning : secondary) : black;

  return (
    <IconButton onClick={onClick} readOnly={readOnly}>
      <SvgIcon icon={MicroscopeIcon} color={color} size={18} />
    </IconButton>
  );
}
