import axios, { AxiosResponse } from "axios";
import { qc_debugLog } from "nota-predict-web/src/Annotate/components/Annotate/page/AnnotationPanel/QualityControlPanel/QcForm.utils";
import { useProjectTasks } from "nota-predict-web/src/Annotate/components/Manage/hooks/useProjectTasks";
import { ComponentType, ReactElement, useEffect, useState } from "react";
import { useDeepCompareMemo } from "use-deep-compare";

import { useGetAuthToken } from "../../auth0/useGetAuthToken";
import { useOkForm } from "../../common/components/Dialog/useOkForm";
import { FAILED, SUCCESS } from "../../common/types/JobStatusType";
import { useProjectId } from "../../Project/hooks/useProjectId";
import { PatientTaskPair } from "../AnnotationsExport/PatientTaskPair";
import { useJobStatus } from "../AnnotationsExport/useJobStatus";

const exportState = ["NONE", "PREPARING", "READY"] as const;
type ExportState = (typeof exportState)[number];

const downloadResult = (files: Blob) => {
  const href = URL.createObjectURL(files);
  const link = document.createElement("a");
  // set link display to none to prevent it from being rendered
  // in the DOM and taking up space
  link.style.display = "none";
  link.href = href;
  link.download = "QC Export.zip";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

interface QcReportExporterProps {
  ExportButton: ComponentType<{ onClick: () => void }>;
  PendingButton: ComponentType;
  DownloadButton: ComponentType<{ onClick: () => void }>;
  patientTaskPairs: PatientTaskPair[];
}

const useGetAllQcTasks = () =>
  (useProjectTasks()?.projectTasks ?? []).filter((x) => x.qcSchemaId !== null);

export const QcReportExporter = ({
  ExportButton,
  PendingButton,
  DownloadButton,
  patientTaskPairs,
}: QcReportExporterProps): ReactElement => {
  const [state, setState] = useState<ExportState>("NONE");

  const [jobId, setJobId] = useState<number | undefined>(undefined);

  const [getJobStatus, { data: jobStatusData, stopPolling }] = useJobStatus();

  const [showStartExportFailedForm, { dialog: startExportFailedForm }] = useOkForm({
    title: "Export Failed To Start",
    message: "The export task failed to start. If this problem persists, contact Altis Labs.",
  });

  const [showExportFailedForm, { dialog: exportFailedForm }] = useOkForm({
    title: "Export Failed",
    message: "The export task failed. If this problem persists, contact Altis Labs.",
  });

  const [showDownloadFailedForm, { dialog: downloadFailedForm }] = useOkForm({
    title: "Download Failed",
    message: "The export download failed. If this problem persists, contact Altis Labs.",
  });

  const projectId = useProjectId();
  const getToken = useGetAuthToken(); // TODO: make sure user is allowed to download QC report
  const allQcTasks = useGetAllQcTasks();

  useEffect(() => {
    if (state !== "PREPARING" || !jobId) {
      return;
    }

    getJobStatus({ variables: { jobId } });
  }, [state, jobId, getJobStatus]);

  useEffect(() => {
    const jobStatus = jobStatusData?.celeryJobs[0]?.status;
    if (!jobStatus || !stopPolling) {
      return;
    }

    if (![SUCCESS, FAILED].includes(jobStatus)) {
      return;
    }

    stopPolling();
    const success = jobStatus === SUCCESS;

    if (!success) {
      showExportFailedForm(true);
      setJobId(undefined);
    }

    setState(success ? "READY" : "NONE");
  }, [jobStatusData, stopPolling, showExportFailedForm, setJobId]);

  // only include task pairs that have QC schemas
  const selectedTaskPairs = useDeepCompareMemo(
    () => patientTaskPairs.filter((p) => allQcTasks.some((task) => task.id === p.taskId)),

    [patientTaskPairs, allQcTasks]
  );

  const handleDownload = async () => {
    if (!jobId) {
      throw new Error("No jobId");
    }

    const token = await getToken();

    const success = await tryDownloadExport(jobId, token);

    if (!success) {
      showDownloadFailedForm(true);
    }

    setJobId(undefined);
    setState("NONE");
  };

  const tryDownloadExport = async (jobId: number, token: string) => {
    if (state !== "READY") {
      return;
    }

    const celeryUrl = window._env_.REACT_APP_CELERY_API_URL;

    let response: AxiosResponse | undefined;
    try {
      response = await axios.get(celeryUrl + "/qc/download/", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          job_id: jobId,
        },
        responseType: "blob",
      });
    } catch (e) {
      console.error("Failed to download export", e);
      return false;
    }

    if (!response) {
      console.error("Failed to download export");
      return false;
    }
    await downloadResult(response.data);
    return true;
  };

  const prepareDownload = async () => {
    qc_debugLog("Preparing download", { state });
    if (state !== "NONE") {
      return;
    }
    setState("PREPARING");

    const celeryUrl = window._env_.REACT_APP_CELERY_API_URL;
    const token = await getToken();
    let response: AxiosResponse<ResponseType> | undefined;
    let jobId: number | undefined = undefined;

    try {
      response = await axios.post(celeryUrl + "/qc/export/", selectedTaskPairs, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          project: projectId,
        },
      });
    } catch (e) {
      showStartExportFailedForm(true);
      console.error("Failed to request export", e);
    }

    if (!response) {
      showStartExportFailedForm(true);
      console.error("Failed to request export");
    } else {
      try {
        jobId = parseInt(response.data);
      } catch (e) {
        console.error("Failed to parse job ID", e);
      }
    }

    setJobId(jobId);
    if (jobId === undefined) {
      setState("NONE");
    }
  };

  useEffect(() => {
    qc_debugLog("state changed to", state);
    if (state === "READY") {
      // automatically download when ready
      handleDownload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <>
      {startExportFailedForm}
      {exportFailedForm}
      {downloadFailedForm}
      {state === "NONE" && <ExportButton onClick={prepareDownload} />}
      {state === "PREPARING" && <PendingButton />}
      {state === "READY" && <DownloadButton onClick={handleDownload} />}
    </>
  );
};
