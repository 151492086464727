import { useContext } from "react";
import { FilterProps } from "react-table";
import styled from "styled-components";

import { InputButton } from "../../../../../common/components/input/InputButton";
import { DefaultIconFilterHeader } from "../../../../../common/components/Table/DefaultIconFilterHeader";
import { main } from "../../../../../common/theme/main";
import { UserType } from "../../../../../common/types/UserType";
import { ProjectContext } from "../../../../../Project/contexts/ProjectContext";
import { allProgresses, TaskProgressType } from "../../../TaskWorklist/TaskProgressType";
import {
  CombinedUserProgressFilterType,
  getNonNullCombinedUserProgressFilter,
} from "./CombinedUserProgressFilterType";
import { FilterPopout } from "./FilterPopout";
import { TaskProgressColumnFilter } from "./TaskProgressColumnFilter";
import { UserColumnFilter } from "./UserColumnFilter";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
`;

const FilterWrapper = styled.div`
  flex: 1;
  min-width: 200px;
`;

// eslint-disable-next-line @typescript-eslint/ban-types
export function CombinedUserProgressColumnFilter<T extends object = {}>({
  column,
}: FilterProps<T>): JSX.Element {
  const { filterValue, setFilter } = column;
  const { progresses: filteredProgresses, users: filteredUsers } =
    getNonNullCombinedUserProgressFilter(filterValue);

  const progresses = [...allProgresses, undefined];
  const { users: definedUsers = [] } = useContext(ProjectContext);
  const users = [...definedUsers, undefined];

  const handleProgressesSelected = (progresses: (TaskProgressType | undefined)[]) => {
    setFilter((filterValue: CombinedUserProgressFilterType) => {
      const filter = getNonNullCombinedUserProgressFilter(filterValue);
      return { ...filter, progresses };
    });
  };

  const handleUsersSelected = (users: (UserType | undefined)[]) => {
    setFilter((filterValue: CombinedUserProgressFilterType) => {
      const filter = getNonNullCombinedUserProgressFilter(filterValue);
      return { ...filter, users };
    });
  };

  const handleClearFilter = () => {
    setFilter({ progresses: [], users: [] });
  };

  const handleSelectAll = () => {
    setFilter({ progresses, users });
  };

  const isFiltered = filteredProgresses.length > 0 || filteredUsers.length > 0;

  return (
    <DefaultIconFilterHeader column={column}>
      <FilterPopout
        isFiltered={isFiltered}
        onClearFilter={handleClearFilter}
        extraActionButtons={
          <InputButton
            type="button"
            value={"All"}
            background={main.colors.neutral.white}
            color={main.colors.neutral.black}
            onClick={handleSelectAll}
          />
        }
      >
        <Wrapper>
          <FilterWrapper>
            <TaskProgressColumnFilter
              progresses={progresses}
              selectedProgresses={filteredProgresses}
              onSelectedProgressesChanged={handleProgressesSelected}
            />
          </FilterWrapper>
          <FilterWrapper>
            <UserColumnFilter
              users={users}
              selectedUsers={filteredUsers}
              onSelectedUsersChanged={handleUsersSelected}
            />
          </FilterWrapper>
        </Wrapper>
      </FilterPopout>
    </DefaultIconFilterHeader>
  );
}
