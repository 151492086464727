import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { StudyTableRowType } from "../components/StudyTable/StudyTableRowType";
import { RootState } from "./store";

export type SeriesSelectionSliceType = Record<
  string,
  {
    loading: boolean;
    studyRows: StudyTableRowType[];
  }
>;

const initialState: SeriesSelectionSliceType = {};

export const seriesSelectionSlice = createSlice({
  name: "seriesSelection",
  initialState,
  reducers: {
    clearStudyRows(state, { payload: { tableKey } }: PayloadAction<{ tableKey: string }>) {
      delete state[tableKey];
    },
    setStudyRows(
      state,
      {
        payload: { tableKey, studyRows, loading },
      }: PayloadAction<{
        tableKey: string;
        studyRows: StudyTableRowType[];
        loading: boolean;
      }>
    ) {
      const updatedStudyRows = updateStudyRows(
        studyRows,
        state[tableKey]?.studyRows ?? [],
        state[tableKey]?.loading ?? true
      );

      state[tableKey] = { loading, studyRows: updatedStudyRows };
    },
    setAllStudiesSelected(
      state,
      {
        payload: { tableKey, isSelected },
      }: PayloadAction<{ tableKey: string; isSelected: boolean }>
    ) {
      state[tableKey].studyRows = state[tableKey].studyRows.map((studyRow) => ({
        ...studyRow,
        isSelected,
        seriesRows: studyRow.seriesRows.map((seriesRow) => ({
          ...seriesRow,
          isSelected,
        })),
      }));
    },
    setStudySelected(
      state,
      {
        payload: { tableKey, studyId, selected },
      }: PayloadAction<{
        tableKey: string;
        studyId: number;
        selected: boolean;
      }>
    ) {
      state[tableKey].studyRows = state[tableKey].studyRows.map((studyRow) => {
        const { id } = studyRow;
        if (id !== studyId) {
          return studyRow;
        }

        return {
          ...studyRow,
          isSelected: selected,
          seriesRows: studyRow.seriesRows.map((seriesRow) => ({
            ...seriesRow,
            isSelected: selected,
          })),
        };
      });
    },
    setSeriesSelected(
      state,
      {
        payload: { tableKey, seriesId, selected },
      }: PayloadAction<{
        tableKey: string;
        seriesId: number;
        selected: boolean;
      }>
    ) {
      state[tableKey].studyRows = state[tableKey].studyRows.map((studyRow) => {
        const { seriesRows } = studyRow;

        return {
          ...studyRow,
          seriesRows: seriesRows.map((seriesRow) => {
            const { id } = seriesRow;
            if (id !== seriesId) {
              return seriesRow;
            }

            return {
              ...seriesRow,
              isSelected: selected,
            };
          }),
        };
      });
    },
  },
});

export const seriesSelectionActions = seriesSelectionSlice.actions;

const state = (state: RootState) => state.seriesSelection;

export const makeLoadingSelector = () => {
  return createSelector(
    [state, (state: RootState, page: string) => page],
    (state, page) => state[page]?.loading ?? false
  );
};

export const makeStudyRowsSelector = () => {
  return createSelector(
    [state, (state: RootState, page: string) => page],
    (state, page) => state[page]?.studyRows ?? []
  );
};

export const makeSelectedSeriesSelector = () => {
  return createSelector(
    [state, (state: RootState, page: string) => page],
    (state, page) =>
      state[page]?.studyRows.flatMap(({ seriesRows }) =>
        seriesRows.filter(({ isSelected }) => isSelected)
      ) ?? []
  );
};

function updateStudyRows(
  newRows: StudyTableRowType[],
  oldRows: StudyTableRowType[],
  loading: boolean
): StudyTableRowType[] {
  if (newRows.length === 0 && oldRows.length > 0 && loading) {
    return oldRows;
  }

  const oldRowIds = oldRows.map(({ id }) => id);

  const addedRows = newRows.filter(({ id }) => !oldRowIds.includes(id));
  const existingRows = newRows
    .filter(({ id }) => oldRowIds.includes(id))
    .map((row) => {
      const { id: newRowId, seriesRows: newSeriesRows } = row;
      const oldRow = oldRows.find(({ id }) => id === newRowId);
      if (!oldRow) {
        throw new Error(`Unable to find existing study row to update with id ${newRowId}`);
      }

      const { isSelected, seriesRows: oldSeriesRows } = oldRow;

      const oldSeriesRowIds = oldSeriesRows.map(({ id }) => id);
      const addedSeriesRows = newSeriesRows.filter(({ id }) => !oldSeriesRowIds.includes(id));
      const existingSeriesRows = newSeriesRows
        .filter(({ id }) => oldSeriesRowIds.includes(id))
        .map((row) => {
          const { id: newSeriesRowId } = row;
          const oldSeriesRow = oldSeriesRows.find(({ id }) => id === newSeriesRowId);
          if (!oldSeriesRow) {
            throw new Error(
              `Unable to find existing series row to update with id ${newSeriesRowId}`
            );
          }

          const { isSelected: isSeriesSelected } = oldSeriesRow;
          return {
            isSelected: isSeriesSelected,
            ...row,
          };
        });

      return {
        ...row,
        isSelected,
        seriesRows: [...addedSeriesRows, ...existingSeriesRows],
      };
    });

  return [...addedRows, ...existingRows];
}
