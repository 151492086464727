import { useSelector } from "react-redux";

import { FilterTypes, getProjectViewFilters } from "../../../common/store/projectViewFiltersSlice";
import { FollowUpType } from "../types/FollowUpType";
import { SubjectType } from "../types/SubjectType";
import { STABLE_DISEASE, TumourResponseType } from "../types/TumourResponseType";
import { getFollowUpTumourResponse } from "../utils/getFollowUpTumourResponse";
import { getMostRecentFollowUp } from "../utils/getMostRecentFollowUp";
import { useFilteredSubjectsQuery } from "./useFilteredSubjectsQuery";

function isMostRecentFollowUpStableDisease(followUps: FollowUpType[]): boolean {
  const mostRecentFollowUp = getMostRecentFollowUp(followUps);
  if (!mostRecentFollowUp) {
    return false;
  }

  const mostRecentTumourResponse = getFollowUpTumourResponse(mostRecentFollowUp);

  return mostRecentTumourResponse === STABLE_DISEASE;
}

function filter(subjects: SubjectType[], filters: FilterTypes): SubjectType[] {
  //if SD = false, it should ONLY exclude patient that has SD has most recent
  const recistFilters = filters.recist1_1;
  const enabledFilters = Object.keys(recistFilters)
    .map((key) => key as TumourResponseType)
    .filter((response) => recistFilters[response] === true);

  return subjects.filter((subject) => {
    const { followUps } = subject;
    const allResponse = [...new Set(followUps.flatMap((followUp) => followUp.tumourResponses))];

    if (recistFilters[STABLE_DISEASE] === false) {
      const mostRecentIsStableDisease = isMostRecentFollowUpStableDisease(followUps);
      if (mostRecentIsStableDisease) {
        return false;
      }
      enabledFilters.push(STABLE_DISEASE);
    }

    return allResponse.every((response) => enabledFilters.includes(response));
  });
}

// NOTE: The subjects returned by this hook are also filtered by subject filters
export function useTumorClassificationFilteredSubjectsQuery(): SubjectType[] {
  const subjects = useFilteredSubjectsQuery();

  const filters = useSelector(getProjectViewFilters);
  return filter(subjects, filters);
}
