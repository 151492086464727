import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { MutationTuple } from "@apollo/client/react/types/types";

import { GET_ALL_PROJECTS } from "../../../Dashboard/components/Settings/Project/useAllProjects";
import { PROJECT_DETAILS_FRAGMENT } from "../../../Project/contexts/Project/ProjectDetailsFragment";
import { useCurrentUser } from "../../contexts/UserContext/useCurrentUser";
import { GET_USER_PROJECTS_QUERY } from "../../queries/useAllUsersProjects";
import { ProjectDetailsType } from "../../types/RawProjectDetailsType";
import { UserRoleType } from "../../types/UserRoleType";
import { UserType } from "../../types/UserType";
import { NewProjectType } from "./NewProjectType";

const MUTATION = gql`
  mutation UpdateProject(
    $id: Int!
    $name: String!
    $status: enum_project_status_enum!
    $estimatedCompletion: timestamp!
    $disabled: Boolean!
    $details: jsonb!
    $addUsers: [project_user_insert_input!]!
    $deleteUserIds: [Int!]!
    $addUserRoles: [project_user_role_insert_input!]!
    $addCohorts: [label_project_insert_input!]!
    $deleteCohortIds: [Int!]!
  ) {
    deletedUsers: delete_project_user(
      where: { _and: [{ project_id: { _eq: $id } }, { user_id: { _in: $deleteUserIds } }] }
    ) {
      returning {
        projectId: project_id
        userId: user_id
      }
    }
    deletedUserRoles: delete_project_user_role(
      where: { _and: [{ project_id: { _eq: $id } }, { user_id: { _in: $deleteUserIds } }] }
    ) {
      returning {
        projectId: project_id
        userId: user_id
        role
      }
    }
    delete_label_project(
      where: { _and: [{ label_id: { _in: $deleteCohortIds } }, { project_id: { _eq: $id } }] }
    ) {
      returning {
        cohortId: label_id
      }
    }
    insertedUsers: insert_project_user(objects: $addUsers) {
      returning {
        projectId: project_id
        userId: user_id
      }
    }
    insertedUserRoles: insert_project_user_role(objects: $addUserRoles) {
      returning {
        projectId: project_id
        userId: user_id
        role
      }
    }
    insert_label_project(objects: $addCohorts) {
      returning {
        projectId: project_id
        cohortId: label_id
      }
    }
    project: update_project_by_pk(
      pk_columns: { id: $id }
      _append: { details: $details }
      _set: {
        name: $name
        status: $status
        estimated_completion: $estimatedCompletion
        disabled: $disabled
      }
    ) {
      ...ProjectDetails
    }
  }

  ${PROJECT_DETAILS_FRAGMENT}
`;

type AddUserRoleType = { project_id: number; user_id: number; role: UserRoleType };

type Variables = {
  id: number;
  name: string;
  status: string;
  estimatedCompletion?: string;
  projectId: number;
  disabled: boolean;
  details: {
    study_no?: number;
    ip?: string;
    disease?: string;
    phase?: string;
    design?: string;
    primary_endpoints?: string;
    secondary_endpoints?: string;
  };
  addUsers: { project_id: number; user_id: number }[];
  deleteUserIds: number[];
  addUserRoles: AddUserRoleType[];
  addCohorts: { project_id: number; label_id: number }[];
  deleteCohortIds: number[];
};

export function useUpdateProject(): MutationTuple<unknown, Variables> {
  const { id: userId } = useCurrentUser();

  return useMutation<unknown, Variables>(MUTATION, {
    refetchQueries: [
      { query: GET_ALL_PROJECTS },
      { query: GET_USER_PROJECTS_QUERY, variables: { userId } },
    ],
  });
}

export function getUpdateProjectVariables(
  id: number,
  newProject: NewProjectType,
  oldProject: ProjectDetailsType,
  users: UserType[]
): Variables {
  const {
    name,
    status,
    estimatedCompletion,
    studyNumber: study_no,
    investigationProduct: ip,
    indication: disease,
    phase,
    design,
    primaryEndpoints: primary_endpoints,
    secondaryEndpoints: secondary_endpoints,
    cohorts,
    disabled,
  } = newProject;

  const addCohorts =
    cohorts
      .filter(
        ({ cohort: newCohort }) =>
          !oldProject.cohorts.some(({ cohort: oldCohort }) => newCohort.id === oldCohort.id)
      )
      .map(({ cohort: { id } }) => {
        return {
          label_id: id,
          project_id: oldProject.id,
        };
      }) ?? [];

  const deleteCohortIds = oldProject.cohorts
    .filter(
      ({ cohort: oldCohort }) =>
        !cohorts.some(({ cohort: newCohort }) => oldCohort.id === newCohort.id)
    )
    .map(({ cohort }) => cohort.id);

  const originalUserIds = oldProject.users.map(({ user: { id } }) => id);
  const newUserIds = users.map(({ id }) => id);

  const deleteUserIds = originalUserIds.filter(
    (originalUserId) => !newUserIds.includes(originalUserId)
  );

  const addUserIds = newUserIds.filter((newUserId) => !originalUserIds.includes(newUserId));

  const newProjectUserRoles: AddUserRoleType[] = users.flatMap(({ id, projectRoles }) =>
    (projectRoles ?? []).map((role) => ({
      user_id: id,
      project_id: oldProject.id,
      role: role,
    }))
  );

  const originalProjectUserRoles: AddUserRoleType[] = oldProject.projectUserRoles.map(
    ({ userId, role }) => ({
      user_id: userId,
      project_id: oldProject.id,
      role,
    })
  );

  const addUserRoles = newProjectUserRoles.filter(
    (newProjectUserRole) =>
      !originalProjectUserRoles.some((originalProjectUserRole) =>
        isSameRole(originalProjectUserRole, newProjectUserRole)
      )
  );

  const removeUserRoles = originalProjectUserRoles.filter(
    (originalProjectUserRole) =>
      !newProjectUserRoles.some((newProjectUserRole) =>
        isSameRole(originalProjectUserRole, newProjectUserRole)
      )
  );

  //if we need to remove any user roles, we need to re-add all the roles for that user
  const removeUserRoleUniqueUserIds = [...new Set(removeUserRoles.map(({ user_id }) => user_id))];

  for (const userId of removeUserRoleUniqueUserIds) {
    const roles = newProjectUserRoles.filter(
      ({ user_id: projectUserRoleId }) => projectUserRoleId === userId
    );

    if (!deleteUserIds.includes(userId)) {
      deleteUserIds.push(userId);

      for (const { user_id, role, project_id } of roles) {
        if (
          !addUserRoles.some(
            ({ user_id: addUserRoleUserId, role: addUserRoleRole }) =>
              addUserRoleUserId === userId && addUserRoleRole === role
          )
        ) {
          addUserRoles.push({ user_id, role, project_id });
        }
      }

      if (!addUserIds.includes(userId)) {
        addUserIds.push(userId);
      }
    }
  }

  for (const { user_id } of addUserRoles) {
    if (!addUserIds.includes(user_id) && deleteUserIds.includes(user_id)) {
      addUserIds.push(user_id);
    }
  }

  return {
    id,
    name,
    status,
    estimatedCompletion: estimatedCompletion.toUTCString(),
    projectId: oldProject.id,
    disabled: disabled,
    details: {
      study_no,
      ip,
      disease,
      phase,
      design,
      primary_endpoints,
      secondary_endpoints,
    },
    addUsers: addUserIds.map((user_id) => ({
      project_id: oldProject.id,
      user_id,
    })),
    deleteUserIds,
    addUserRoles,
    addCohorts,
    deleteCohortIds,
  };
}

function isSameRole(
  { role: roleA, user_id: userIdA, project_id: projectIdA }: AddUserRoleType,
  { role: roleB, user_id: userIdB, project_id: projectIdB }: AddUserRoleType
): boolean {
  return roleA === roleB && userIdA === userIdB && projectIdA === projectIdB;
}
