import React, { FC, useEffect, useState } from "react";

import { ProjectDetailsType } from "../../../types/RawProjectDetailsType";
import { isExistingUser, isNewUser, UserType } from "../../../types/UserType";
import handleApolloError from "../../../utils/handleApolloError";
import { replaceAt } from "../../../utils/replaceAt";
import { FormProps } from "../../Dialog/FormProps";
import { useDialogReturnType } from "../../Dialog/useDialog";
import { useFormDialog } from "../../Dialog/useFormDialog";
import { FlexLoading } from "../../Loading";
import {
  AnonymizationConfigurationContextProvider,
  AnonymizationConfigurationType,
  defaultAnonymizationConfig,
} from "../../UserManagement/Dialogs/Contexts/AnonymizationConfigurationContext";
import {
  createProjectUserContext,
  UserManagementContextProvider,
} from "../../UserManagement/UserManagementContext";
import { CreateProjectForm } from "../CreateProjectForm";
import { NewProjectType } from "../NewProjectType";
import { getUpdateProjectVariables, useUpdateProject } from "../useUpdateProject";

export function useEditProjectDialog(project: ProjectDetailsType): useDialogReturnType {
  return useFormDialog({
    children: FormWrapper,
    label: "Edit Project",
    showCloseButton: true,
    props: { project },
  });
}

const FormWrapper: FC<FormProps<{ project: ProjectDetailsType }>> = ({
  onSubmit,
  onCancel,
  props,
}: FormProps<{ project: ProjectDetailsType }>) => {
  const { project: existingProject } = props;
  const [updateProject, { loading, error }] = useUpdateProject();

  const [config, setConfig] = useState<AnonymizationConfigurationType>(defaultAnonymizationConfig);

  const [users, setUsers] = useState<UserType[]>(
    existingProject.users.map(
      ({ user }): UserType => ({
        ...user,
        globalRoles: user.globalRoles.map((globalRole) => globalRole.role),
        projectRoles: existingProject.projectUserRoles
          .filter(({ userId }) => user.id === userId)
          .map(({ role }) => role),
      })
    )
  );

  useEffect(() => {
    setUsers(
      existingProject.users.map(
        ({ user }): UserType => ({
          ...user,
          globalRoles: user.globalRoles.map((globalRole) => globalRole.role),
          projectRoles: existingProject.projectUserRoles
            .filter(({ userId }) => user.id === userId)
            .map(({ role }) => role),
        })
      )
    );
  }, [existingProject.users, existingProject.projectUserRoles]);

  const handleUsersCreated = (users: UserType[]) => {
    const usersToBeCreated = users.filter(isNewUser);
    if (usersToBeCreated.length > 0) {
      throw new Error("Should not be able to create new users from UserManagementCard");
    }

    const addUsers = users.filter(isExistingUser);

    setUsers((users) => [...users, ...addUsers]);
  };

  const handleUserRemoved = (user: UserType) => {
    setUsers(users.filter((existingUser) => existingUser.id !== user.id));
  };

  const handleUserEdited = (user: UserType) => {
    const existingIndex = users.findIndex((existingUser) => existingUser.id === user.id);
    setUsers(replaceAt(users, existingIndex, user));
  };

  const handleShowSites = () => {
    throw new Error("Showing sites should not be reachable when editing a project");
  };

  const handleAddSite = () => {
    throw new Error("Adding a site should not be reachable when editing a project");
  };

  const handleUpdateProject = async (project: NewProjectType) => {
    const variables = getUpdateProjectVariables(existingProject.id, project, props.project, users);

    await updateProject({ variables });
  };

  if (error) handleApolloError(error);
  if (loading) return <FlexLoading />;

  return (
    <UserManagementContextProvider
      rules={createProjectUserContext}
      users={users}
      setUsers={setUsers}
      onCreated={handleUsersCreated}
      onRemoved={handleUserRemoved}
      onEdited={handleUserEdited}
    >
      <>
        <AnonymizationConfigurationContextProvider config={config} setConfig={setConfig}>
          <CreateProjectForm
            props={{
              sites: [],
              onAddSite: handleAddSite,
              onShowSites: handleShowSites,
              users,
              onSaveProject: handleUpdateProject,
              project: existingProject,
            }}
            onSubmit={onSubmit}
            onCancel={onCancel}
          />
        </AnonymizationConfigurationContextProvider>
      </>
    </UserManagementContextProvider>
  );
};
