import React, { FC } from "react";

import RadiologyCaseStatusTable from "../../../common/components/StatusTable";

export const processedData = [
  {
    type: "New",
    weekTotal: 27,
    allTimeTotal: 273,
    allCasesTotal: 1240,
    allCasesThisWeek: 117,
  },
  {
    type: "Reader 2",
    weekTotal: 19,
    allTimeTotal: 232,
    allCasesTotal: 1240,
    allCasesThisWeek: 117,
  },
  {
    type: "Reader 1",
    weekTotal: 20,
    allTimeTotal: 197,
    allCasesTotal: 1240,
    allCasesThisWeek: 117,
  },
  {
    type: "Adjudication",
    weekTotal: 6,
    allTimeTotal: 78,
    allCasesTotal: 1240,
    allCasesThisWeek: 117,
  },
  {
    type: "Complete",
    weekTotal: 48,
    allTimeTotal: 456,
    allCasesTotal: 1240,
    allCasesThisWeek: 117,
  },
];

const RadiologyCaseStatusTableContainer: FC = () => {
  return <RadiologyCaseStatusTable data={processedData} />;
};

export default RadiologyCaseStatusTableContainer;
