import { ApolloCache } from "@apollo/client";

export const TYPENAME = "diagnosis";

export function getDiagnosisCacheId<T>(diagnosisId: number, cache: ApolloCache<T>): string {
  const id = cache.identify({
    id: diagnosisId,
    __typename: TYPENAME,
  });

  if (!id) {
    throw new Error(`Unable to construct ${TYPENAME} cache id`);
  }

  return id;
}
