import { AnatomicalStructuresFragmentType } from "../../../../AnatomicalStructuresFragment";
import { DEFAULT_ANNOTATION_COLOR } from "../utils/useLesionColorDeferred";

export function getNewAnatomicalStructure(
  structure: string,
  name: string
): AnatomicalStructuresFragmentType {
  return {
    id: 0,
    structure,
    name,
    readonly: false,
    color: DEFAULT_ANNOTATION_COLOR,
  };
}
