import { UpdateModeType } from "../../common/types/UpdateModeType";
import { AnatomicalStructuresFragmentType } from "./AnatomicalStructuresFragment";

export type AnatomicalStructureOptionType = {
  label: string;
  value: AnatomicalStructuresFragmentType;
  state?: UpdateModeType;
};

export function getAnatomicalStructureOption(
  anatomicalStructure: AnatomicalStructuresFragmentType,
  state?: UpdateModeType
): AnatomicalStructureOptionType {
  const { name } = anatomicalStructure;
  return {
    label: name,
    value: anatomicalStructure,
    state,
  };
}

export function getAnatomicalStructureOptions(
  anatomicalStructures: AnatomicalStructuresFragmentType[],
  allowUserDefined: boolean,
  state?: UpdateModeType
): AnatomicalStructureOptionType[] {
  const options: AnatomicalStructureOptionType[] = [];

  for (const anatomicalStructure of anatomicalStructures) {
    const { readonly } = anatomicalStructure;

    if (!allowUserDefined && !readonly) {
      continue;
    }

    const option = getAnatomicalStructureOption(anatomicalStructure, state);
    options.push(option);
  }
  return options;
}
