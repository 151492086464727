import React from "react";

import { NODE } from "../../../../../enums/TaskDescriptionEnums";
import { getRoiBurden } from "../../StudyPanel/utils/TumourResponse/getRoiBurden";
import { hasClassification } from "../../StudyPanel/utils/TumourResponse/utils/hasClassification";
import { LesionListItemFragmentType } from "../fragments/LesionListItemFragment";
import { TimepointType } from "../hooks/Timepoint/TimepointType";
import { useContourListener } from "../hooks/useContourListener";
import { TumourBurdenTimepointStat } from "./TumourBurdenTimepointStat";

interface LesionTotalBurdenStatProps {
  lesion: LesionListItemFragmentType;
  timepoints?: TimepointType[];
}

export function LesionTotalBurdenStat({
  lesion,
  timepoints,
}: LesionTotalBurdenStatProps): JSX.Element {
  const isNode = hasClassification(lesion, NODE);
  const { rois } = lesion;
  useContourListener(rois.map(({ id }) => id));

  if (!timepoints) {
    return <></>;
  }

  const timepointsBurdens: number[] = [];

  for (const { series } of timepoints) {
    const timepointBurdens: number[] = [];
    for (const { id: seriesId } of series) {
      const roi = rois.find(({ series: { id } }) => id === seriesId);
      if (!roi) {
        continue;
      }

      const { diametric } = getRoiBurden(roi, isNode);
      if (!diametric) {
        continue;
      }

      timepointBurdens.push(diametric);
    }
    const timepointBurdenSum = timepointBurdens.reduce(
      (partialSum, burden) => partialSum + burden,
      0
    );
    timepointsBurdens.push(timepointBurdenSum);
  }

  const mostRecentBurden = timepointsBurdens[timepointsBurdens.length - 1];

  if (mostRecentBurden === undefined) {
    return <></>;
  }

  return <TumourBurdenTimepointStat value={mostRecentBurden} />;
}
