import React from "react";
import styled from "styled-components";

import { ReactComponent as ForwardIcon } from "../../../../../assets/svgs/Forward.svg";
import { SvgIcon } from "../../../../../common/components/icons/SvgIcon";
import { Loading } from "../../../../../common/components/Loading";
import { main } from "../../../../../common/theme/main";
import { useGoToTask } from "../../../../../Project/Layout/AnnotatePage/useGoToTask";
import { useNextTask } from "./hooks/useNextTask";

const Wrapper = styled.div<{ enabled: boolean }>`
  cursor: ${(props) => (props.enabled ? "pointer" : "default")};
`;

export function TaskForwardButton(): JSX.Element {
  const goToTask = useGoToTask();
  const {
    colors: {
      actionPrimary: { default: black, hover, disabled },
    },
  } = main;

  const { loading, nextTask, refetch } = useNextTask();

  if (loading && nextTask) {
    throw new Error("Next task returned but still loading!");
  }

  if (loading) return <Loading size={8} thickness={6} color={hover} />;

  const handleClickForward = async () => {
    const { nextTask } = await refetch();

    if (!nextTask) {
      return;
    }

    goToTask(nextTask);
  };

  return (
    <Wrapper onClick={nextTask ? handleClickForward : undefined} enabled={!!nextTask}>
      <SvgIcon icon={ForwardIcon} size={16} color={nextTask ? black : disabled} />
    </Wrapper>
  );
}
