import React, { useState } from "react";
import Select, { SelectOptionActionMeta, ValueType } from "react-select";
import { FormatOptionLabelMeta, NamedProps } from "react-select/src/Select";
import { ActionMeta } from "react-select/src/types";

import { useOkCancelForm } from "../../../../../common/components/Dialog/useOkCancelForm";
import { useOkForm } from "../../../../../common/components/Dialog/useOkForm";
import { getSelectStyle } from "../../../../../common/components/input/getSelectStyle";
import { useCreateTaskForm } from "../../CreateTaskWizard/useCreateTaskForm";
import { useEditTaskForm } from "../../CreateTaskWizard/useEditTaskForm";
import { TaskType } from "../../hooks/TaskType";
import { useUpdateTaskEnabled } from "../../hooks/useUpdateTaskEnabled";
import { GroupedTaskOptionType } from "../GroupedTaskOptionType";
import { isTaskOption } from "../isTaskOption";
import {
  TaskDescriptionOptionType,
  TaskDescriptionTemplateType,
} from "../TaskDescriptionOptionType";
import { TaskOptionLabel } from "../TaskOptionLabel";
import { getTaskOption } from "../TaskOptionType";
import { TaskSelectOptionType } from "../TaskSelectOptionType";
import { useNewTaskOptions } from "./useNewTaskOptions";
import { useProjectTaskOptions } from "./useProjectTaskOptions";
import { useTaskTemplateOptions } from "./useTaskTemplateOptions";

interface TasksSelectProps extends NamedProps<TaskSelectOptionType, true, GroupedTaskOptionType> {
  selectedTasks: TaskType[];
  setSelectedTasks: (tasks: TaskType[]) => void;
  enableTasksManagement: boolean;
}

export function TasksSelect({
  selectedTasks,
  setSelectedTasks,
  enableTasksManagement,
  ...selectProps
}: TasksSelectProps): JSX.Element {
  const [selectedTaskDescriptionTemplate, setSelectedTaskDescriptionTemplate] = useState<
    TaskDescriptionTemplateType | undefined
  >(undefined);

  const [pendingEditTask, setPendingEditTask] = useState<TaskType | undefined>(undefined);
  const [pendingChangeEnabledTask, setPendingChangeEnabledTask] = useState<TaskType | undefined>(
    undefined
  );

  const [updateTaskEnabled] = useUpdateTaskEnabled();

  const handleTasksCreated = (tasks: TaskType[]) => {
    setSelectedTasks([...selectedTasks, ...tasks]);
  };

  const handleConfirmChangeEnabled = async () => {
    if (!pendingChangeEnabledTask) {
      throw new Error("No pending change enabled task in handleConfirmChangeEnabled");
    }
    const { id, enabled } = pendingChangeEnabledTask;
    await updateTaskEnabled({
      variables: {
        id,
        enabled: !enabled,
      },
    });

    setPendingChangeEnabledTask(undefined);
  };

  const handleClickDeleteTask = () => {
    showDeleteTaskDialog(true);
  };

  const handleClickEditTask = (task: TaskType) => {
    setPendingEditTask(task);
    showEditTaskForm(true);
  };

  const handleClickChangeEnabled = async (task: TaskType) => {
    setPendingChangeEnabledTask(task);

    if (task.enabled) {
      showConfirmDisableDialog(true);
    } else {
      showConfirmEnableDialog(true);
    }
  };

  const [setShowCreateTaskForm, { dialog: createTaskDialog }] = useCreateTaskForm({
    taskDescriptionTemplate: selectedTaskDescriptionTemplate,
    onTasksCreated: handleTasksCreated,
  });

  const [showEditTaskForm, { dialog: editTaskDialog }] = useEditTaskForm({
    task: pendingEditTask,
  });

  const [showDeleteTaskDialog, { dialog: deleteTaskDialog }] = useOkForm({
    title: "Contact Support",
    message:
      "Tasks may contain large sums of work, and as such require the support team to remove. Please contact support if you would like to delete this task.",
  });

  const [showConfirmEnableDialog, { dialog: confirmEnableDialog }] = useOkCancelForm({
    title: "Confirm Enable Task",
    message: "Would you like to enable this task?",
    okLabel: "Confirm",
    onOkCallback: handleConfirmChangeEnabled,
  });
  const [showConfirmDisableDialog, { dialog: confirmDisableDialog }] = useOkCancelForm({
    title: "Confirm Disable Task",
    message:
      "Would you like to disable this task? All existing labels will still be kept, however tasks will no longer appear in the worklist.",
    okLabel: "Confirm",
    onOkCallback: handleConfirmChangeEnabled,
  });

  const { options: taskTemplateOptions, loading: taskTemplateOptionsLoading } =
    useTaskTemplateOptions();

  const { options: newTaskOptions, loading: newTaskOptionsLoading } = useNewTaskOptions();

  const { options: projectTaskOptions, loading: projectTaskOptionsLoading } =
    useProjectTaskOptions();

  const handleTaskChanged = (
    values: ValueType<TaskSelectOptionType, true>,
    actionMeta: ActionMeta<TaskSelectOptionType>
  ) => {
    const { action } = actionMeta;
    if (action === "select-option") {
      const selectActionMeta = actionMeta as SelectOptionActionMeta<TaskSelectOptionType>;
      const { option } = selectActionMeta;
      if (option && !isTaskOption(option)) {
        const { value } = option as TaskDescriptionOptionType;
        setSelectedTaskDescriptionTemplate(value);
        setShowCreateTaskForm(true);
        return;
      }
    }

    const tasks = values.filter(isTaskOption).map(({ value }) => value as TaskType);

    setSelectedTasks(tasks);
  };

  const formatOptionLabel = (
    option: TaskSelectOptionType,
    labelMetadata: FormatOptionLabelMeta<TaskSelectOptionType, true>
  ) => {
    const { context } = labelMetadata;
    const isValue = context === "value";

    if (!enableTasksManagement) {
      const { label } = option;
      return <>{label}</>;
    }

    return (
      <TaskOptionLabel
        option={option}
        isValue={isValue}
        onDeleteTask={handleClickDeleteTask}
        onEditTask={handleClickEditTask}
        onChangeEnabled={handleClickChangeEnabled}
      />
    );
  };

  const selectStyle = getSelectStyle<TaskSelectOptionType, true, GroupedTaskOptionType>();

  // when creating new tasks, there is a duplicate entry in both selected and options, so manually filter it out here
  const filteredProjectTaskOptions = projectTaskOptions.filter(
    ({ value: { id } }) => !selectedTasks.some(({ id: selectedTaskId }) => selectedTaskId === id)
  );

  const options: GroupedTaskOptionType[] = [
    { label: "Project Tasks", options: filteredProjectTaskOptions },
  ];

  if (enableTasksManagement) {
    options.push({ label: "New Basic Task", options: newTaskOptions });
    options.push({
      label: "New Task From Template",
      options: taskTemplateOptions,
    });
  }

  const value = selectedTasks.map(getTaskOption);

  const isLoading =
    projectTaskOptionsLoading || taskTemplateOptionsLoading || newTaskOptionsLoading;

  return (
    <>
      {createTaskDialog}
      {editTaskDialog}
      {deleteTaskDialog}
      {confirmEnableDialog}
      {confirmDisableDialog}
      <Select<TaskSelectOptionType, true, GroupedTaskOptionType>
        autoFocus
        styles={selectStyle}
        isClearable={true}
        isSearchable={true}
        isLoading={isLoading}
        placeholder={"Select tasks..."}
        formatOptionLabel={formatOptionLabel}
        {...selectProps}
        isMulti={true}
        options={options}
        onChange={handleTaskChanged}
        value={value}
        menuPortalTarget={document.body}
      />
    </>
  );
}
