import { Popover } from "@mui/material";
import axios, { AxiosResponse } from "axios";
import React, { ComponentType, ReactElement, useEffect, useState } from "react";

import { useGetAuthToken } from "../../auth0/useGetAuthToken";
import { useOkForm } from "../../common/components/Dialog/useOkForm";
import { FAILED, SUCCESS } from "../../common/types/JobStatusType";
import { useProjectId } from "../../Project/hooks/useProjectId";
import { useMixpanel } from "../../telemetry";
import { useReactGA } from "../../telemetry/google-analytics";
import { ExportOptionsSelection } from "./ExportOptionsSelection";
import { Options } from "./Options";
import { PatientTaskPair } from "./PatientTaskPair";
import { useJobStatus } from "./useJobStatus";

const exportState = ["NONE", "PREPARING", "READY"] as const;
type ExportState = (typeof exportState)[number];

interface ExportAnnotationsButtonProps {
  ExportButton: ComponentType<{ onClick: (event: React.MouseEvent<HTMLElement>) => void }>;
  PendingButton: ComponentType;
  DownloadButton: ComponentType<{ onClick: () => void }>;
  patientTaskPairs: PatientTaskPair[];
}

export const AnnotationsExporter = ({
  ExportButton,
  PendingButton,
  DownloadButton,
  patientTaskPairs,
}: ExportAnnotationsButtonProps): ReactElement => {
  const [state, setState] = useState<ExportState>("NONE");

  const [jobId, setJobId] = useState<number | null>(null);

  const [getJobStatus, { data: jobStatusData, stopPolling }] = useJobStatus();
  const projectId = useProjectId();
  const getToken = useGetAuthToken();

  const mixpanel = useMixpanel();
  const reactGA = useReactGA();

  const [showStartExportFailedForm, { dialog: startExportFailedForm }] = useOkForm({
    title: "Export Failed To Start",
    message: "The export task failed to start. If this problem persists, contact Altis Labs.",
  });

  const [showExportFailedForm, { dialog: exportFailedForm }] = useOkForm({
    title: "Export Failed",
    message: "The export task failed. If this problem persists, contact Altis Labs.",
  });

  const [showDownloadFailedForm, { dialog: downloadFailedForm }] = useOkForm({
    title: "Download Failed",
    message: "The export download failed. If this problem persists, contact Altis Labs.",
  });

  const [optionsAnchorElement, setOptionsAnchorElement] = useState<HTMLElement | null>(null);
  const isOptionsOpen = Boolean(optionsAnchorElement);
  const optionsId = isOptionsOpen ? "filter-popover" : undefined;

  useEffect(() => {
    if (state !== "PREPARING" || !jobId) {
      return;
    }

    getJobStatus({ variables: { jobId } });
  }, [state, jobId, getJobStatus]);

  useEffect(() => {
    const jobStatus = jobStatusData?.celeryJobs[0]?.status;
    if (!jobStatus || !stopPolling) {
      return;
    }

    if (![SUCCESS, FAILED].includes(jobStatus)) {
      return;
    }
    stopPolling();
    const success = jobStatus === SUCCESS;
    if (!success) {
      showExportFailedForm(true);
      mixpanel.track("Annotations export failed");
    } else {
      mixpanel.track("Annotations export completed");
    }

    setState(success ? "READY" : "NONE");
  }, [jobStatusData, stopPolling, showExportFailedForm, mixpanel]);

  const handleOpenExportOptions = (event: React.MouseEvent<HTMLElement>) => {
    setOptionsAnchorElement(event.currentTarget);
  };

  const handleCloseExportOptions = () => {
    setOptionsAnchorElement(null);
  };

  const handleExport = async (options: Options) => {
    if (patientTaskPairs.length === 0) {
      throw new Error("No patientsTasks");
    }

    handleCloseExportOptions();

    reactGA.event("annotation_export_triggered");

    const token = await getToken();

    const jobId = await tryRequestExport(patientTaskPairs, projectId, token, options);

    if (jobId === undefined) {
      showStartExportFailedForm(true);
      mixpanel.track("Annotations export failed to start", {
        patientTasks: patientTaskPairs,
      });
      return;
    }

    mixpanel.track("Annotations export started", {
      patientTasks: patientTaskPairs,
    });
    setJobId(jobId);
    setState("PREPARING");
  };

  const handleDownload = async () => {
    if (!jobId) {
      throw new Error("No jobId");
    }
    reactGA.event("annotation_download_triggered");

    const token = await getToken();

    const success = await tryDownloadExport(jobId, token);

    if (!success) {
      showDownloadFailedForm(true);
      mixpanel.track("Annotations download failed", { jobId: jobId });
    } else {
      mixpanel.track("Annotations download succeeded", { jobId: jobId });
    }

    setState("NONE");
  };

  return (
    <>
      {startExportFailedForm}
      {exportFailedForm}
      {downloadFailedForm}
      <Popover
        id={optionsId}
        open={isOptionsOpen}
        anchorEl={optionsAnchorElement}
        onClose={handleCloseExportOptions}
        anchorOrigin={{
          vertical: 40,
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <ExportOptionsSelection onCancel={handleCloseExportOptions} onConfirm={handleExport} />
      </Popover>
      {state === "NONE" && <ExportButton onClick={handleOpenExportOptions} />}
      {state === "PREPARING" && <PendingButton />}
      {state === "READY" && <DownloadButton onClick={handleDownload} />}
    </>
  );
};

async function tryRequestExport(
  patientTaskPairs: PatientTaskPair[],
  projectId: number,
  token: string,
  options: Options
) {
  const celeryUrl = window._env_.REACT_APP_CELERY_API_URL;

  const body = {
    patient_task_pairs: patientTaskPairs.map(({ patientId: patient_id, taskId: task_id }) => ({
      patient_id,
      task_id,
    })),
    options: {
      include_manual_masks: options.includeManualMasks,
      include_predicted_masks: options.includePredictedMasks,
      include_series_volumes: options.includeSeriesVolumes,
    },
  };

  let response: AxiosResponse<ResponseType> | undefined;
  try {
    response = await axios.post(celeryUrl + "/annotation/export/", body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        project: projectId,
      },
    });
  } catch (e) {
    console.error("Failed to request export", e);
    return undefined;
  }

  if (!response) {
    console.error("Failed to request export");
    return undefined;
  }

  let jobId: number | undefined = undefined;
  try {
    jobId = parseInt(response.data);
  } catch (e) {
    console.error("Failed to parse job ID", e);
    return undefined;
  }

  return jobId;
}

async function tryDownloadExport(jobId: number, token: string) {
  const celeryUrl = window._env_.REACT_APP_CELERY_API_URL;

  let response: AxiosResponse | undefined;
  try {
    response = await axios.get(celeryUrl + "/annotation/download/", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        job_id: jobId,
      },
      responseType: "blob",
    });
  } catch (e) {
    console.error("Failed to download export", e);
    return false;
  }

  if (!response) {
    console.error("Failed to download export");
    return false;
  }

  const href = URL.createObjectURL(response.data);

  // create "a" HTML element with href to file & click
  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", "annotations.zip"); //or any other extension
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);

  return true;
}
