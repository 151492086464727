import { cloneDeep } from "lodash";

import { ANNOTATE_LIMIT_TO_BASELINE } from "../../../../enums/TaskDescriptionEnums";
import {
  AnnotateRulesType,
  TaskDescriptionType as TaskDescriptionDataType,
} from "../../../../types/TaskDescriptionType";

export function createLesionClassificationDescription(
  data: TaskDescriptionDataType,
  newTaskName: string,
  firstTimepointOnly: boolean
): TaskDescriptionDataType {
  const taskDescriptionCopy = cloneDeep(data);

  taskDescriptionCopy.name = newTaskName;

  const { options } = taskDescriptionCopy;

  const timepointOption = {
    rule: ANNOTATE_LIMIT_TO_BASELINE as AnnotateRulesType,
    enabled: firstTimepointOnly,
  };

  if (!options) {
    taskDescriptionCopy.options = [timepointOption];
    return taskDescriptionCopy;
  }

  const filteredOptions = options.filter(({ rule }) => rule !== ANNOTATE_LIMIT_TO_BASELINE);
  filteredOptions.push(timepointOption);
  taskDescriptionCopy.options = filteredOptions;

  return taskDescriptionCopy;
}
