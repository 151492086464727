export const GLOBAL_ROLE_SOFTWARE_MANAGER = "SOFTWARE_MANAGER";
export const ADMIN = "ADMIN";

export const PROJECT_ROLE_VIEWER = "VIEWER";
export const PROJECT_ROLE_ANNOTATOR = "ANNOTATOR";
export const PROJECT_ROLE_COORDINATOR = "COORDINATOR";
export const PROJECT_ROLE_QC_ANNOTATOR = "QC_ANNOTATOR";

export type UserRoleType =
  | typeof PROJECT_ROLE_VIEWER
  | typeof PROJECT_ROLE_ANNOTATOR
  | typeof PROJECT_ROLE_COORDINATOR
  | typeof PROJECT_ROLE_QC_ANNOTATOR;

export type GlobalRoleType = typeof GLOBAL_ROLE_SOFTWARE_MANAGER | typeof ADMIN;

export type ProjectUserRoleType = { projectId: number; role: UserRoleType };
