import React from "react";
import styled from "styled-components";

import { LabelIndicator } from "../common/LabelIndicator";
import { SELECTED_CONTOUR_COLOR } from "../ViewerPanel/tools/legacy/ToolUtils";
import { BiopsyAssignmentButton } from "./BiopsyLinking/BiopsyAssignmentButton";
import { LesionListItemFragmentType } from "./fragments/LesionListItemFragment";
import { isLesionLesion } from "./utils/isLesionLesion";
import { isLesionPredicted } from "./utils/isLesionPredicted";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const TextButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

const LabelComponentWrapper = styled.div`
  margin: 0 6px 0 0;
  line-height: 50%;
`;

const Text = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  flex-shrink: 0;
`;

interface LesionsListItemHeaderLabelProps {
  lesion: LesionListItemFragmentType;
  viewOnly: boolean;
  color: string;
  selectedLesionId: number | null;
}

export function LesionsListItemHeaderLabel({
  lesion,
  viewOnly,
  color,
  selectedLesionId,
}: LesionsListItemHeaderLabelProps): JSX.Element {
  const { id: lesionId } = lesion;

  const labelText = formatLesionName(lesion);

  const showBiopsyAssignment = isLesionLesion(lesion);
  const isPredicted = isLesionPredicted(lesion);

  const labelColor = isPredicted
    ? color
    : selectedLesionId === lesionId
    ? SELECTED_CONTOUR_COLOR
    : color;

  return (
    <Wrapper>
      <LabelComponentWrapper>
        <LabelIndicator color={labelColor} />
      </LabelComponentWrapper>
      <TextButtonsWrapper>
        <Text>{labelText}</Text>
        {showBiopsyAssignment && <BiopsyAssignmentButton lesionId={lesionId} readOnly={viewOnly} />}
      </TextButtonsWrapper>
    </Wrapper>
  );
}

export function formatLesionName({ name }: LesionListItemFragmentType): string {
  return name.replace(
    /(\w)(\w*)/g,
    (string, first, rest) => first.toUpperCase() + rest.toLowerCase()
  );
}
