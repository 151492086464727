import { useMemo } from "react";

import { useCurrentUser } from "../../../../common/contexts/UserContext/useCurrentUser";
import { PatientTasksType } from "./TaskType";
import { Data, useRawPatientTasksQuery } from "./useRawPatientTasksQuery";

type ReturnType = {
  patientsTasks: PatientTasksType[] | undefined;
  refetch: () => Promise<PatientTasksType[]>;
  startPolling: (pollInterval: number) => void;
  stopPolling: () => void;
  loading: boolean;
};

export function useProjectPatientsTasks(ignoreUserRestrictions: boolean): ReturnType {
  const { data, loading, refetch, ...other } = useRawPatientTasksQuery();
  const { id: userId } = useCurrentUser();

  const patientsTasks = useMemo(
    () => (data ? parseProjectPatientTasks(data, userId, ignoreUserRestrictions) : undefined),
    [data]
  );

  const refetchCallback = async () => {
    const data = await refetch();
    return parseProjectPatientTasks(data, userId, ignoreUserRestrictions);
  };

  return {
    patientsTasks,
    refetch: refetchCallback,
    ...other,
    loading,
  };
}

function parseProjectPatientTasks(
  data: Data,
  userId: number,
  ignoreUserRestrictions: boolean
): PatientTasksType[] {
  const { tasks, patients, cohorts } = data;
  const enabledTasks = tasks.filter(({ enabled }) => enabled);

  const results: PatientTasksType[] = [];

  for (const { id: patientId, patientCohorts, studies, ...otherPatientFields } of patients) {
    const studyCohorts = studies.flatMap(({ studyCohorts }) => studyCohorts);
    const seriesCohorts = studies.flatMap(({ series }) =>
      series.flatMap(({ seriesCohorts }) => seriesCohorts)
    );
    const allCohorts = [...patientCohorts, ...studyCohorts, ...seriesCohorts];
    const cohortIds = [...new Set(allCohorts.map(({ id }) => id))];

    const filteredTasks = enabledTasks
      .filter(({ excludedPatients, restrictedUsers, restrictedCohorts }) => {
        const excludedPatientIds = excludedPatients.map(({ id }) => id);
        const restrictedUserIds = restrictedUsers.map(({ id }) => id);
        const restrictedCohortIds = restrictedCohorts.map(({ id }) => id);

        const userCanViewTask =
          ignoreUserRestrictions ||
          restrictedUserIds.length === 0 ||
          restrictedUserIds.includes(userId);

        return (
          !excludedPatientIds.includes(patientId) &&
          (restrictedCohortIds.length === 0 ||
            restrictedCohortIds.some((id) => cohortIds.includes(id))) &&
          userCanViewTask
        );
      })
      .map((task) => {
        const { taskAssignments } = task;
        const patientAssignments = taskAssignments.filter(
          ({ patientId: assignmentPatientId }) => assignmentPatientId === patientId
        );
        return { ...task, taskAssignments: patientAssignments };
      });

    const filteredCohorts = cohortIds.flatMap((id) => {
      const cohort = cohorts.find(({ id: cohortId }) => id === cohortId);
      return cohort ? [cohort] : [];
    });

    results.push({
      id: patientId,
      studies,
      ...otherPatientFields,
      tasks: filteredTasks,
      cohorts: filteredCohorts,
    });
  }

  return results;
}
