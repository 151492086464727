import { ELLIPTICAL_ROI, MEASURE, ROI } from "../../../../../../../cornerstone/ToolType";
import { loadMeasurements as loadLesionsMeasurements } from "../../../AnnotationPanel/utils/loadMeasurements";
import { updateEllipseToolDataId } from "../ellipse/utils/updateEllipseToolDataId";
import { addEllipseToCache, clearEllipseCache } from "./EllipseToolChangeDetector";
import { appendToolSliceData, clearRoiData, clearToolSliceData } from "./ToolUtils";

export function loadMeasurements(measurements, instancesById) {
  clearToolSliceData(MEASURE);
  measurements.forEach((measurement) => {
    const { instanceId, toolData } = measurement;
    const { LADLength, SADLength } = toolData;

    let { wadoUrl } = instancesById[instanceId];
    appendToolSliceData(MEASURE, wadoUrl, [LADLength]);
    appendToolSliceData(MEASURE, wadoUrl, [SADLength]);
  });
}

export function loadTools(lesions, instances) {
  loadLesionsMeasurements(lesions, instances);
  clearEllipseCache();

  //inject too data into cornerstone
  clearToolSliceData(ROI);
  clearToolSliceData(ELLIPTICAL_ROI);

  lesions.forEach((lesion) => {
    lesion.rois.forEach((roi) => {
      let ellipse = roi.ellipse;
      if (ellipse && ellipse.slice) {
        let toolData = ellipse.slice.toolData;
        let imageId = ellipse.slice.imageId;

        addEllipseToCache(toolData);

        appendToolSliceData(ELLIPTICAL_ROI, imageId, toolData);

        updateEllipseToolDataId(ellipse.id, ellipse);
      }

      let contours = roi.contours;
      if (contours) {
        contours.forEach((contour) => {
          let { toolData, instanceId } = contour;

          const instance = instances.find(({ id }) => id === instanceId);
          if (!instance) {
            throw new Error(
              `Unable to find instance with id: ${instanceId} when loading tool data`
            );
          }
          let { wadoUrl } = instance;
          appendToolSliceData(ROI, wadoUrl, toolData);
        });
      }
    });
  });
}

export function removeRoiToolData(roi) {
  let ellipse = roi.ellipse;
  if (ellipse) {
    let roi = ellipse.roi;
    clearRoiData(roi.id, ELLIPTICAL_ROI);
  }
  const { contoursToolRoi } = roi;
  if (contoursToolRoi) {
    const { id } = contoursToolRoi;
    clearRoiData(id, ROI);
  }
}

export function removeToolData(lesion) {
  lesion.rois.forEach((roi) => {
    removeRoiToolData(roi);
  });
}
