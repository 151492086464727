import { cloneDeep } from "lodash";

import {
  ANNOTATE_ALLOW_LESIONS,
  ANNOTATE_ALLOW_ORGANS,
  ANNOTATE_CALCULATE_CONTOUR_MEASUREMENTS,
  ANNOTATE_LIMIT_TO_BASELINE,
  CHECK_MISSING_CONTOURS,
  CHECK_MISSING_ORGANS,
  OBJECT_CONTOUR,
  OBJECT_DETECTION,
  OBJECT_LABELLING,
} from "../../../../enums/TaskDescriptionEnums";
import {
  AnnotateRulesType,
  TaskDescriptionType as TaskDescriptionDataType,
} from "../../../../types/TaskDescriptionType";
import { AnatomicalStructuresFragmentType } from "../../../AnatomicalStructuresFragment";
import { LabellingOptions } from "../LabellingOptions";

export function createObjectLabellingTaskDescription(
  data: TaskDescriptionDataType,
  anatomicalStructures: AnatomicalStructuresFragmentType[],
  newTaskName: string,
  labellingOptions: LabellingOptions,
  firstTimepointOnly: boolean
): TaskDescriptionDataType {
  const taskDescriptionCopy = cloneDeep(data);

  taskDescriptionCopy.name = newTaskName;

  let { options, checks, lesion } = taskDescriptionCopy;

  if (!options) {
    throw new Error(`${OBJECT_LABELLING} task description should have options`);
  }

  if (!checks) {
    throw new Error(`${OBJECT_LABELLING} task description should have checks`);
  }

  if (!lesion) {
    throw new Error(`${OBJECT_LABELLING} task description should have lesions`);
  }

  const { task, lesion: lesionChecks } = checks;

  if (!anatomicalStructures.find(({ structure }) => structure === "lesion")) {
    options = options.filter(({ rule }) => rule !== ANNOTATE_ALLOW_LESIONS);
  }

  const anatomicalStructuresWithoutLesion = anatomicalStructures.filter(
    ({ structure }) => structure !== "lesion"
  );

  if (anatomicalStructuresWithoutLesion.length === 0) {
    checks = {
      ...checks,
      task: task.filter((check) => check !== CHECK_MISSING_ORGANS),
    };
  }

  options = options.filter(({ rule }) => rule !== ANNOTATE_ALLOW_ORGANS);

  if (anatomicalStructuresWithoutLesion.length > 0) {
    const allowOrgansOption = {
      rule: ANNOTATE_ALLOW_ORGANS as AnnotateRulesType,
      enabled: true,
      args: {
        organs: anatomicalStructuresWithoutLesion.map(({ structure }) => structure),
      },
    };

    options = [allowOrgansOption, ...options];
  }

  const timepointOption = {
    rule: ANNOTATE_LIMIT_TO_BASELINE as AnnotateRulesType,
    enabled: firstTimepointOnly,
  };

  const { timepoint } = lesion;

  let { objects } = timepoint;

  const { enableSegmentation, enableDetection } = labellingOptions;
  if (!enableSegmentation) {
    objects = objects.filter(({ type }) => type !== OBJECT_CONTOUR);
    options = options.filter(({ rule }) => rule !== ANNOTATE_CALCULATE_CONTOUR_MEASUREMENTS);
    checks = {
      ...checks,
      lesion: lesionChecks.filter((check) => check !== CHECK_MISSING_CONTOURS),
    };
  }

  options = options.filter(({ rule }) => rule !== ANNOTATE_LIMIT_TO_BASELINE);

  options.push(timepointOption);

  if (!enableDetection) {
    objects = objects.filter(({ type }) => type !== OBJECT_DETECTION);
  }

  lesion = {
    ...lesion,
    timepoint: {
      ...timepoint,
      objects,
    },
  };

  taskDescriptionCopy.options = options;

  taskDescriptionCopy.checks = checks;

  taskDescriptionCopy.lesion = lesion;

  return taskDescriptionCopy;
}
