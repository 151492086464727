import { createSelector } from "@reduxjs/toolkit";
import React, { FC, ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getProjectViewFilters,
  projectViewFiltersActions,
} from "../../../common/store/projectViewFiltersSlice";
import { formatFollowUp } from "../../common/utils/formatFollowUp";
import FilterCategory from "./FilterCategory";
import FilterCheckbox from "./FilterCheckbox";

export const selectTimepointsFilters = createSelector(
  [getProjectViewFilters],
  (projectViewFilters) => projectViewFilters.timepoints
);

export const TimepointsFilters: FC = () => {
  const dispatch = useDispatch();
  const timepointsFilters = useSelector(selectTimepointsFilters);

  const components: ReactElement[] = [];
  for (const key in timepointsFilters) {
    if (!Object.prototype.hasOwnProperty.call(timepointsFilters, key)) {
      continue;
    }

    const isChecked = timepointsFilters[key];
    const timepoint = parseInt(key);

    const onFilterChanged = (checked: boolean) => {
      dispatch(
        projectViewFiltersActions.setTimepoints({
          ...timepointsFilters,
          [timepoint]: checked,
        })
      );
    };

    components.push(
      <FilterCheckbox
        key={timepoint}
        label={formatFollowUp(timepoint)}
        checked={isChecked}
        onChange={onFilterChanged}
        name={timepoint.toString()}
      />
    );
  }

  return <FilterCategory name={"Timepoints"} options={components} />;
};
