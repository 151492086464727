import React from "react";
import { Column } from "react-table";
import styled from "styled-components";

import { ReactComponent as AddIcon } from "../../../../../assets/svgs/PlusOutline.svg";
import { ReactComponent as RemoveIcon } from "../../../../../assets/svgs/RemoveOutline.svg";
import { SvgIcon } from "../../../../../common/components/icons/SvgIcon";
import { UsersList } from "../../../../../common/components/UserManagement/UsersList";
import { main } from "../../../../../common/theme/main";
import { ADD, REMOVE, UpdateModeType } from "../../../../../common/types/UpdateModeType";
import { TaskLabel } from "../TaskLabel";
import { ConfirmTaskAssignmentsRowType } from "./ConfirmTaskAssignmentsRowType";

const ListWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 3px;
`;

export function getColumns(mode: UpdateModeType): Column<ConfirmTaskAssignmentsRowType>[] {
  const columns: Column<ConfirmTaskAssignmentsRowType>[] = [
    {
      id: "patientDicomId",
      Header: "Subject ID",
      flex: 0.5,
      accessor: ({ patientDicomId }) => patientDicomId ?? "N/A",
      showFilter: true,
    },
    {
      id: "task",
      Header: "Task",
      flex: 1,
      // eslint-disable-next-line react/prop-types,react/display-name
      accessor: ({ task }) => {
        if (!task) {
          return null;
        }

        const { taskAssignments } = task;
        const users = taskAssignments.map(({ user }) => user);
        return <TaskLabel task={task} users={users} />;
      },
    },
  ];

  if (mode === ADD) {
    columns.push({
      id: "addedUsers",
      Header: "Added User(s)",
      flex: 1,
      // eslint-disable-next-line react/prop-types,react/display-name
      accessor: ({ users, task }) => {
        if (!task) {
          return null;
        }

        const { taskAssignments } = task;
        const assignedUserIds = taskAssignments.map(({ user: { id } }) => id);

        const filteredUsers = users.filter(({ id }) => !assignedUserIds.includes(id));

        if (filteredUsers.length === 0) {
          return <>No change</>;
        }

        return (
          <ListWrapper>
            <SvgIcon icon={AddIcon} size={16} color={main.colors.states.success} />
            <UsersList users={filteredUsers} />
          </ListWrapper>
        );
      },
    });
  } else if (mode === REMOVE) {
    columns.push({
      id: "removedUsers",
      Header: "Removed User(s)",
      flex: 1,
      // eslint-disable-next-line react/prop-types,react/display-name
      accessor: ({ users, task }) => {
        if (!task) {
          return null;
        }

        const { taskAssignments } = task;
        const assignedUserIds = taskAssignments.map(({ user: { id } }) => id);

        const filteredUsers = users.filter(({ id }) => assignedUserIds.includes(id));

        if (filteredUsers.length === 0) {
          return <>No change</>;
        }

        return (
          <ListWrapper>
            <SvgIcon icon={RemoveIcon} size={16} color={main.colors.states.error} />
            <UsersList users={filteredUsers} />
          </ListWrapper>
        );
      },
    });
  } else throw new Error(`Update mode ${mode} not supported in ConfirmTaskAssignmentsForm table`);
  return columns;
}
