import React from "react";

import { GlobalRoleType, UserRoleType } from "../../types/UserRoleType";
import { Can, CanProps } from "./Can";
import { useCurrentUserCan } from "./useCurrentUserCan";

interface CurrentUserCanProps extends Omit<CanProps, "can"> {
  allowedRoles: (UserRoleType | GlobalRoleType)[];
}

export function CurrentUserCan({ yes, no, allowedRoles }: CurrentUserCanProps): JSX.Element | null {
  const can = useCurrentUserCan(allowedRoles);
  return <Can can={can} yes={yes} no={no} />;
}
