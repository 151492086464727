import { useSelector } from "react-redux";

import { taskCheckSelector } from "../../../../../../../common/store/annotatePage/taskSelector";
import {
  CHECK_MISSING_ORGANS,
  CHECK_MISSING_REQUIRED_QC_FIELDS,
} from "../../../../../../enums/TaskDescriptionEnums";
import { TaskCheckType } from "../../../../../../types/TaskDescriptionType";
import { useQualityControlTaskChecks } from "../../QualityControlPanel/QcTask.hooks";
import { useMissingOrgansCheck } from "./Checks/MissingOrgans/useMissingOrgansCheck";
import { UseTaskCheckReturnType } from "./types/UseTaskCheckReturnType";

export function useEnabledTaskChecks() {
  const enabledChecks = useSelector(taskCheckSelector);

  if (!enabledChecks) {
    return [];
  }

  const allChecks: Record<TaskCheckType, () => UseTaskCheckReturnType<unknown>> = {
    [CHECK_MISSING_ORGANS]: useMissingOrgansCheck,
    [CHECK_MISSING_REQUIRED_QC_FIELDS]: useQualityControlTaskChecks,
  };

  return enabledChecks.map((type) => allChecks[type]());
}
