import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { QueryResult } from "@apollo/client/react/types/types";

import { USER_FRAGMENT } from "../../../Settings/UserFragment";
import { ProjectDocumentType } from "../ProjectDocumentType";

export type Data = { userProjectFiles: ProjectDocumentType[] };

export type Variables = { projectId: number };

export const QUERY = gql`
  query GetProjectDocuments($projectId: Int!) {
    userProjectFiles: user_project_file(where: { project_id: { _eq: $projectId } }) {
      id
      description
      filename
      size
      type
      uploadDate: created_at
      uploadedBy: user {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

export function useAllProjectDocuments(projectId: number): QueryResult<Data, Variables> {
  return useQuery<Data, Variables>(QUERY, { variables: { projectId } });
}
