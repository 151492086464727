import {
  ADMIN,
  GLOBAL_ROLE_SOFTWARE_MANAGER,
  GlobalRoleType,
  PROJECT_ROLE_ANNOTATOR,
  PROJECT_ROLE_COORDINATOR,
  PROJECT_ROLE_QC_ANNOTATOR,
  PROJECT_ROLE_VIEWER,
  UserRoleType,
} from "../../../types/UserRoleType";
import { getAllGlobalRoles } from "./getAllGlobalRoles";
import { getAllProjectRoles } from "./getAllProjectRoles";

const roleHierarchy: Record<UserRoleType | GlobalRoleType, (UserRoleType | GlobalRoleType)[]> = {
  [PROJECT_ROLE_VIEWER]: [],
  [PROJECT_ROLE_ANNOTATOR]: [PROJECT_ROLE_VIEWER],
  [PROJECT_ROLE_QC_ANNOTATOR]: [PROJECT_ROLE_ANNOTATOR],
  [PROJECT_ROLE_COORDINATOR]: [PROJECT_ROLE_ANNOTATOR],
  [GLOBAL_ROLE_SOFTWARE_MANAGER]: [],
  [ADMIN]: [...getAllGlobalRoles(), ...getAllProjectRoles()],
};

export function getInheritedRoles(
  roles: (UserRoleType | GlobalRoleType)[]
): (UserRoleType | GlobalRoleType)[] {
  const inheritedRoles = new Set<UserRoleType | GlobalRoleType>();

  for (const role of roles) {
    inheritedRoles.add(role);

    const childrenRoles = roleHierarchy[role].filter((hierarchyRole) => hierarchyRole !== role);

    const inheritedChildRoles = getInheritedRoles(childrenRoles);
    for (const inheritedChildRole of inheritedChildRoles) {
      inheritedRoles.add(inheritedChildRole);
    }
  }

  return [...inheritedRoles];
}
