import React, { FC } from "react";
import styled from "styled-components";

import { Content } from "./Content";
import { DrawerPageType } from "./Layout";
import { TopBar } from "./TopBar/TopBar";

const Wrapper = styled.div<{ width?: number }>`
  width: ${(props) => (props.width ? `${props.width}px` : "1000px")};
  height: 100%;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.background.main};
`;

const TopBarWrapper = styled.div`
  display: flex;
  height: 64px;
  background: ${(props) => props.theme.colors.actionPrimary.inverted};
  box-shadow: inset 0px -1px 0px ${(props) => props.theme.colors.neutral.neutral8};
`;

const ContentWrapper = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;
  align-content: stretch;
`;

const MainContentWrapper = styled.div`
  overflow-y: auto;
  flex: 1;
  overflow-x: auto;
  width: calc(100vw - 480px - 48px - 48px);
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
`;

interface DrawerPageProps {
  page: DrawerPageType;
  width?: number;
}

export const DrawerPage: FC<DrawerPageProps> = ({ page, width }: DrawerPageProps) => {
  const { topBar, content } = page;
  return (
    <Wrapper width={width}>
      {topBar && (
        <TopBarWrapper>
          <TopBar topBar={topBar} />
        </TopBarWrapper>
      )}
      <ContentWrapper>
        <MainContentWrapper>
          <Content content={content} />
        </MainContentWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};
