import { useSelector } from "react-redux";

import { taskTimepointEnabledOptionsSelector } from "../../../../../../../common/store/annotatePage/taskSelector";
import { TimepointOptionsType } from "../../../../../../types/TaskDescriptionType";
import { useGetIsLesionFromCurrentTask } from "../../../ViewerPanel/useGetIsLesionFromCurrentTask";
import { LesionListItemFragmentType } from "../../fragments/LesionListItemFragment";
import { ClassificationType } from "../../types/ClassificationType";

type ReturnType = (
  lesion: LesionListItemFragmentType,
  roi?: { classifications: ClassificationType[] }
) => boolean;

export function useGetMeasurementsDisabledForRoi(): ReturnType {
  const options = useSelector(taskTimepointEnabledOptionsSelector);
  const getIsLesionFromCurrentTask = useGetIsLesionFromCurrentTask();

  return (lesion, roi) => {
    if (!options) {
      return false;
    }

    if (!getIsLesionFromCurrentTask(lesion)) {
      return true;
    }

    const { classifications: lesionClassifications } = lesion;
    const { classifications: roiClassifications } = roi ?? {
      classifications: [] as ClassificationType[],
    };

    const filter = ({ disableMeasurements, rule }: TimepointOptionsType) =>
      disableMeasurements &&
      (lesionClassifications.map(({ classification }) => classification).includes(rule) ||
        roiClassifications.map(({ classification }) => classification).includes(rule));

    return options.some(filter);
  };
}
