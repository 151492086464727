import { ApolloError, useQuery } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { QueryResult } from "@apollo/client/react/types/types";
import handleApolloError from "nota-predict-web/src/common/utils/handleApolloError";
import { useRef } from "react";

import { QcSchema, validateQcSchema } from "../../../utils/qc-validation/schema/ruleSet";

export class QcValidationError extends Error {
  code: "INVALID_SCHEMA" | "SERVER_ERROR";
  shouldShowDialog: boolean;
  constructor(message: string, code: "INVALID_SCHEMA" | "SERVER_ERROR", shouldShowDialog = true) {
    super(message);
    this.name = "QcValidationError";
    this.code = code;
    this.shouldShowDialog = shouldShowDialog;
  }
}

type QcSchemaRow = {
  id: number;
  name: string;
  version: string;
  originalFilename: string;
  projectId: number;
  schema: QcSchema;
};

type ValidatedSchemaType = {
  schemaRow?: QcSchemaRow;
  loading: boolean;
  error: ApolloError | QcValidationError | undefined;
};

const GET_QUALITY_CONTROL_SCHEMA_QUERY = gql`
  query GetQualityControlSchemaById($qcSchemaId: Int!) {
    qcSchemas: qc_schema(where: { id: { _eq: $qcSchemaId } }) {
      id
      name
      version
      originalFilename: original_filename
      projectId: project_id
      schema
    }
  }
`;

type DataType = {
  qcSchemas: QcSchemaRow[];
};

type Variables = {
  qcSchemaId: number | null;
};

function useGetQualityControlSchema(qcSchemaId: number | null): QueryResult<DataType> {
  return useQuery<DataType, Variables>(GET_QUALITY_CONTROL_SCHEMA_QUERY, {
    variables: { qcSchemaId },
    skip: !qcSchemaId,
  });
}

export function useValidatedQualityControlSchema(qcSchemaId: number | null): ValidatedSchemaType {
  const { data, loading, error } = useGetQualityControlSchema(qcSchemaId);
  const validationError = useRef<QcValidationError | undefined>();

  if (!qcSchemaId) {
    return { schemaRow: undefined, loading, error };
  }

  if (error) {
    handleApolloError(error);
  }

  const schemaRow = data?.qcSchemas?.[0] || undefined;

  if (!qcSchemaId) {
    return { schemaRow: undefined, loading: false, error: undefined };
  }

  if ((data?.qcSchemas || []).length > 1) {
    throw new Error("Too many QC schema returned");
  }

  if (!loading && !schemaRow) {
    throw new Error(`No QC schema found for id ${qcSchemaId}`);
  }
  if (schemaRow?.schema) {
    try {
      const schema = validateQcSchema(schemaRow?.schema);
      return {
        schemaRow: { ...schemaRow, schema } as QcSchemaRow,
        loading,
        error: validationError.current || error,
      };
    } catch (e) {
      console.error("Error validating QC schema", e);
      return {
        schemaRow: undefined,
        loading: false,
        error: new QcValidationError(
          `The QC schema for this task "${schemaRow?.name}" cannot be used because it does not match the expected format. Please check the schema or contact support for help.`,
          "INVALID_SCHEMA"
        ),
      };
    }
  }

  return {
    schemaRow: undefined,
    loading,
    error: validationError.current || error,
  };
}
