import { gql } from "@apollo/client/core";

import { useParsedQuery } from "../apollo/utils/useParsedQuery";
import { useParsedQueryReturnType } from "../apollo/utils/useParsedQueryReturnType";
import { UserType } from "../common/types/UserType";
import { parseUserFragment, USER_FRAGMENT, UserFragmentType } from "./UserFragment";

export const QUERY = gql`
  query GetAllUsers {
    users {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export type Data = {
  users: UserFragmentType[];
};

export function useUsers(): useParsedQueryReturnType<undefined, UserType[], undefined> {
  return useParsedQuery<undefined, UserType[], Data, undefined>({
    query: QUERY,
    input: undefined,
    parseVariables: undefined,
    parseData,
  });
}

function parseData(data: Data): UserType[] {
  return data.users.map(parseUserFragment);
}
