import React from "react";
import styled from "styled-components";

import { ReactComponent as TreeIcon } from "../../../assets/svgs/Tree.svg";
import { SvgIcon } from "../../../common/components/icons/SvgIcon";
import { getFormattedLong } from "../../../common/utils/dateFormatUtils/getFormattedDateLong";

const PaddingBottom = styled.div`
  padding-bottom: 8px;
`;

const BoldedItemSubtext2 = styled.div`
  font-weight: 600;
  color: ${(props) => props.theme.colors.neutral.neutral5};
  font-size: 11px;
`;

const PaddingBottomForLargeText = styled.div`
  padding-bottom: 3px;
`;

const StyledItemText = styled.div`
  color: ${(props) => props.theme.colors.neutral.black};
  font-size: 16px;
  font-weight: 600;
`;

const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const VerticalBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  margin-right: 8px;
  :first-child {
    margin-left: 16px;
  }
  :last-child {
    margin-right: 16px;
  }
`;

const IconWrapper = styled.div`
  background: ${(props) => props.theme.colors.neutral.neutral8};
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
`;

const StyledItemSubtext = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.colors.neutral.black};
  font-weight: 500;
`;

interface AlgorithmSummaryProps {
  studyName: string;
  studyNumber: string;
  algorithmName: string;
  createdOn: string | null;
  lastRun: string | null;
}

const MiddleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
`;

function AlgorithmSummary({
  studyName,
  studyNumber,
  algorithmName,
  createdOn,
  lastRun,
}: AlgorithmSummaryProps): React.ReactElement {
  const lastRunString = lastRun ? getFormattedLong(lastRun) : "-";
  const createdOnString = createdOn ? getFormattedLong(createdOn) : "-";
  return (
    <RowWrapper>
      <IconWrapper>
        <SvgIcon icon={TreeIcon} size={16} />
      </IconWrapper>
      <MiddleWrapper>
        <VerticalBlock>
          <PaddingBottomForLargeText>
            <BoldedItemSubtext2>{"Study Name"}</BoldedItemSubtext2>
          </PaddingBottomForLargeText>
          <StyledItemText>{studyName}</StyledItemText>
        </VerticalBlock>
        <VerticalBlock>
          <PaddingBottomForLargeText>
            <BoldedItemSubtext2>{"Study Number"}</BoldedItemSubtext2>
          </PaddingBottomForLargeText>
          <StyledItemText>{studyNumber}</StyledItemText>
        </VerticalBlock>
        <VerticalBlock>
          <PaddingBottomForLargeText>
            <BoldedItemSubtext2>{"Algorithm"}</BoldedItemSubtext2>
          </PaddingBottomForLargeText>
          <StyledItemText>{algorithmName}</StyledItemText>
        </VerticalBlock>
        <VerticalBlock>
          <PaddingBottom>
            <BoldedItemSubtext2>{"Last Run"}</BoldedItemSubtext2>
          </PaddingBottom>
          <StyledItemSubtext>{lastRunString}</StyledItemSubtext>
        </VerticalBlock>
        <VerticalBlock>
          <PaddingBottom>
            <BoldedItemSubtext2>{"Created"}</BoldedItemSubtext2>
          </PaddingBottom>
          <StyledItemSubtext>{createdOnString}</StyledItemSubtext>
        </VerticalBlock>
      </MiddleWrapper>
    </RowWrapper>
  );
}

export default AlgorithmSummary;
