import { matchSorter } from "match-sorter";
import React from "react";
import { Column, Row } from "react-table";
import styled from "styled-components";

import { ReactComponent as EditIcon } from "../../../../assets/svgs/EditOutline.svg";
import { Avatar } from "../../../../common/components/Avatar/Avatar";
import { SvgIcon } from "../../../../common/components/icons/SvgIcon";
import { useEditUserDialog } from "../../../../common/components/UserManagement/Dialogs/useEditUserDialog";
import { RoleLabel } from "../../../../common/components/UserManagement/RoleLabel";
import { formatUserFullName } from "../../../../common/components/UserManagement/utils/formatUserFullName";
import { getAllGlobalRoles } from "../../../../common/components/UserManagement/utils/getAllGlobalRoles";
import { useFilterNonAdminRoles } from "../../../../common/components/UserManagement/utils/useAllRoleOptions";
import { orderRolesByGroup } from "../../../../common/types/RoleGroupType";
import { GlobalRoleType } from "../../../../common/types/UserRoleType";
import { UserType } from "../../../../common/types/UserType";
import { ConditionalGreyedWrapper } from "../../../../common/utils/ConditionalGreyedWrapper";
import { OptionType } from "../../../../DataManagement/Upload/components/GenericMultiSelect";
import { getFilterColumnProps } from "./GenericColumnFilter";
import { UserTableRowType } from "./UserTableRowType";

const UserWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 6px;
  gap: 3px;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.neutral.black};
`;

const EditButtonWrapper = styled.div`
  cursor: pointer;
`;

export function useUserTableColumns(): Column<UserTableRowType>[] {
  const globalRoles = useFilterNonAdminRoles(getAllGlobalRoles());

  return [
    {
      id: "email",
      Header: "User",
      disableFilters: false,
      filter: (rows: Row<UserTableRowType>[], columnIds: string[], filteredValue: string) =>
        rows.filter(({ original: { email, firstName, lastName } }) =>
          [email, firstName, lastName]
            .filter((field) => field)
            .some((field) => matchSorter([field], filteredValue).length > 0)
        ),
      accessor: (user) => user,
      // eslint-disable-next-line react/display-name
      Cell: ({ value: user }: { value: UserType }) => {
        const displayName = formatUserFullName(user) ?? user.email;
        return (
          <ConditionalGreyedWrapper greyed={user.suspended}>
            <UserWrapper>
              <Avatar user={user} />
              <>{displayName}</>
            </UserWrapper>
          </ConditionalGreyedWrapper>
        );
      },
    },
    {
      id: "title",
      Header: "Title",
      disableFilters: false,
      accessor: (user) => user,
      Cell: ({ value: user }: { value: UserType }) => {
        return (
          <ConditionalGreyedWrapper greyed={user.suspended}>
            {user.title ?? "N/A"}
          </ConditionalGreyedWrapper>
        );
      },
    },

    {
      id: "organization",
      Header: "Organization",
      disableFilters: false,
      accessor: (user) => user,
      Cell: ({ value: user }: { value: UserType }) => {
        return (
          <ConditionalGreyedWrapper greyed={user.suspended}>
            {user.organization ?? "N/A"}
          </ConditionalGreyedWrapper>
        );
      },
    },
    {
      id: "roles",
      Header: "Roles",
      style: {
        width: "33%",
      },
      disableSortBy: true,
      ...getFilterColumnProps(
        getGlobalRoleValues,
        areGlobalRolesEqual,
        {
          values: globalRoles,
          getOption: getGlobalRoleOption,
          includeUnassigned: true,
          placeholder: "Filter Roles...",
          // eslint-disable-next-line react/display-name,react/prop-types
          RowLabelComponent: ({ value }) => <RoleLabel role={value} />,
          // eslint-disable-next-line react/display-name,react/prop-types
          SelectLabelComponent: ({ value }) => <RoleLabel role={value} />,
        },
        "suspended"
      ),
    },
    {
      id: "edit",
      Header: "Edit",
      style: {
        width: "42px",
      },
      disableSortBy: true,
      accessor: (user) => user,
      // eslint-disable-next-line react/display-name
      Cell: ({ value: user }: { value: UserType }) => {
        const [setShowEditUserDialog, { dialog: editUserDialog }] = useEditUserDialog(user);

        const handleEditClick = () => {
          setShowEditUserDialog(true);
        };
        return (
          <>
            {editUserDialog}
            <EditButtonWrapper onClick={handleEditClick}>
              <SvgIcon icon={EditIcon} size={16} />
            </EditButtonWrapper>
          </>
        );
      },
    },
  ];
}

function getGlobalRoleOption(
  role: GlobalRoleType | undefined
): OptionType<GlobalRoleType | undefined> {
  if (!role) {
    return {
      value: undefined,
      label: "Unassigned",
    };
  }

  return { value: role, label: role };
}

function getGlobalRoleValues({ globalRoles }: UserTableRowType): GlobalRoleType[] {
  return orderRolesByGroup(globalRoles);
}

function areGlobalRolesEqual(roleA: GlobalRoleType, roleB: GlobalRoleType): boolean {
  return roleA === roleB;
}
