import {
  METASTASIS,
  NEW_FINDING,
  NODE,
  NON_TARGET,
  OTHER,
  PRIMARY,
  TARGET,
} from "../../../../../../enums/TaskDescriptionEnums";
import { getRoiBurden } from "./getRoiBurden";
import { isRoiMeasurable } from "./isRoiMeasurable";
import { InputLesionType } from "./types/InputLesionType";
import { hasClassification } from "./utils/hasClassification";

export type IntermediateType = {
  seriesId: number;
  sumOfDiameters: number;
  nodeDiameters: number[];
  containsMissingMeasurements: boolean;
  numberOfLesions: number;
  numberOfTargetLesions: number;
};

export function getIntermediateData(
  lesions: InputLesionType[],
  seriesIds: number[]
): IntermediateType[] {
  const timepoints: IntermediateType[] = [];

  const contributingLesions = lesions.filter(doesLesionContribute);
  if (contributingLesions.length === 0) {
    return timepoints;
  }

  for (const seriesId of seriesIds) {
    let sumOfDiameters = 0;
    let numberOfTargetLesions = 0;
    const nodeDiameters: number[] = [];
    let containsMissingMeasurements = false;
    let numberOfLesions = 0;

    for (const lesion of contributingLesions) {
      const contribution = getLesionContribution(lesion, seriesId);
      if (!contribution) {
        containsMissingMeasurements = true;
        continue;
      }

      const { isTarget, diameter, nodeDiameter, isMeasurable } = contribution;

      const isMeasurableTarget = isTarget && isMeasurable;
      const isNonMeasurableNonTarget = isMeasurable && !isTarget;

      if (isMeasurableTarget || isNonMeasurableNonTarget) {
        numberOfLesions++;
        if (isTarget) {
          numberOfTargetLesions++;
        }
      }

      sumOfDiameters += diameter;
      if (nodeDiameter !== undefined) {
        nodeDiameters.push(nodeDiameter);
      }
    }

    timepoints.push({
      sumOfDiameters,
      numberOfLesions,
      numberOfTargetLesions,
      nodeDiameters,
      seriesId,
      containsMissingMeasurements,
    });
  }

  return timepoints;
}

type LesionContributionType = {
  isTarget: boolean;
  diameter: number;
  nodeDiameter: number | undefined;
  isMeasurable: boolean;
};

function doesLesionContribute(lesion: InputLesionType): boolean {
  const isTarget = hasClassification(lesion, TARGET);
  const isNonTarget = hasClassification(lesion, NON_TARGET);

  const isPrimary = hasClassification(lesion, PRIMARY);
  const isMetastasis = hasClassification(lesion, METASTASIS);
  const isNode = hasClassification(lesion, NODE);
  const isOther = hasClassification(lesion, OTHER);
  const isNewFinding = hasClassification(lesion, NEW_FINDING);

  //classifications incomplete
  if (!isTarget && !isNonTarget && !(isPrimary || isMetastasis || isNode || isOther)) {
    return false;
  }

  if ([isPrimary, isMetastasis, isNode, isOther].filter((value) => value).length > 1) {
    throw new Error(
      "Lesion contain contain multiple classifications in tumour response calculation"
    );
  }

  return isTarget || isNewFinding;
}

function getLesionContribution(
  lesion: InputLesionType,
  seriesId: number
): LesionContributionType | undefined {
  const isTarget = hasClassification(lesion, TARGET);
  const isNode = hasClassification(lesion, NODE);

  const { rois } = lesion;
  const seriesRois = rois.filter(({ series: { id } }) => id === seriesId);
  if (seriesRois.length > 1) {
    throw new Error("More than one roi was found in tumour response calculation");
  }

  const roi = seriesRois[0];
  if (!roi) {
    return undefined;
  }

  const isMeasurable = isRoiMeasurable(roi);
  const { diametric } = isMeasurable && isTarget ? getRoiBurden(roi, isNode) : { diametric: 0 };
  if (diametric === undefined) {
    return undefined;
  }

  return {
    diameter: diametric,
    nodeDiameter: isNode ? diametric ?? undefined : undefined,
    isTarget,
    isMeasurable,
  };
}
