import cornerstoneTools from "@altis-labs/cornerstone-tools";
import cornerstone from "cornerstone-core";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useSelector } from "react-redux";
import { useDeepCompareEffect, useDeepCompareMemo } from "use-deep-compare";

import { selectedLesionIdSelector } from "../../../../../common/store/annotatePage/selectionSelector";
import { ViewerConfigType } from "../../../../../common/store/annotatePage/ViewerConfigType";
import { ROI } from "../../../../../cornerstone/ToolType";
import { ANNOTATION_SCOPE } from "../../../../../Project/Layout/Layout";
import { TaskContext } from "../../../../TaskContext";
import { ToolDataType } from "../../../../types/ToolDataType";
import { ContourContextMenu } from "./ContourContextMenu";
import { CornerstoneClickEvent } from "./CornerstoneClickEvent";
import { EllipseContextMenu } from "./EllipseContextMenu";
import { useSelectedViewerRoiId } from "./selection/useSelectedViewerRoiId";
import { ToolController } from "./ToolController";
import { useCopyContour } from "./tools/contour/hooks/useCopyContour";
import { usePasteContour } from "./tools/contour/hooks/usePasteContour";
import { useViewerCanPasteContour } from "./tools/contour/hooks/useViewerCanPasteContour";
import { ContourCallbackDataType } from "./tools/contour/types/ContourCallbackDataType";
import { ContourContextMenuData } from "./tools/contour/types/ContourContextMenuDataType";
import { EllipseClickDataType } from "./tools/ellipse/types/EllipseClickDataType";
import { getToolSliceData } from "./tools/legacy/ToolUtils";
import { ToolControllerCallbacks } from "./tools/toolController/useToolController";
import { useViewerViewOnly } from "./useViewerViewOnly";
import { useActiveTool } from "./Viewer/hooks/useActiveTool";

interface AnnotateViewerToolControllerProps {
  viewerConfig: ViewerConfigType;
  element: HTMLDivElement | null;
}

export function AnnotateViewerToolController({
  viewerConfig,
  element,
}: AnnotateViewerToolControllerProps): JSX.Element {
  const [contourContextMenuData, setContourContextMenuData] = useState<
    ContourContextMenuData | undefined
  >(undefined);
  const [ellipseContextMenuData, setEllipseContextMenuData] = useState<
    EllipseClickDataType | undefined
  >(undefined);

  const memoViewerConfig = useDeepCompareMemo(() => viewerConfig, [viewerConfig]);

  const { selectedTaskAssignmentId } = useContext(TaskContext);

  const viewOnly = useViewerViewOnly(viewerConfig);

  const { activeTool } = useActiveTool();
  const selectedRoiId = useSelectedViewerRoiId(viewerConfig);
  const { copyContour } = useCopyContour();
  const lesionId = useSelector(selectedLesionIdSelector);

  const { canPaste } = useViewerCanPasteContour(viewerConfig);
  const { pasteContour } = usePasteContour(viewerConfig);
  const canPasteContour = canPaste();

  useHotkeys(
    "mod+c",
    () => {
      if (activeTool === ROI && !!selectedRoiId) {
        retrieveAndCopyContourData(selectedRoiId);
      }
    },
    { scopes: [ANNOTATION_SCOPE] }
  );

  useHotkeys(
    "mod+v",
    () => {
      if (canPasteContour) {
        pasteContour();
      }
    },
    { scopes: [ANNOTATION_SCOPE] }
  );

  const retrieveAndCopyContourData = (roiId: number) => {
    // get the current wado image id from cornerstone through the html element
    // stored in the FreehandRoi cornerstone tool
    const imageId = cornerstone.getImage(
      cornerstoneTools.store.state.tools.find((toolData: ToolDataType) => toolData.name === ROI)
        .element
    ).imageId;

    if (!imageId) {
      throw new Error("Tried to copy annotations while cornerstone has no listed Image Id.");
    }

    const data: ContourCallbackDataType = {
      imageId,
      roiId,
      toolData: getToolSliceData(selectedRoiId, imageId, ROI),
    };

    copyContour(data, lesionId);
  };

  const handleShowContourContextMenu = useCallback((data: ContourContextMenuData) => {
    setContourContextMenuData(data);
  }, []);

  const handleShowEllipseContextMenu = useCallback((data: EllipseClickDataType) => {
    setEllipseContextMenuData(data);
  }, []);

  const handleClick = useCallback((event: CornerstoneClickEvent) => {
    const { detail } = event;
    const { buttons } = detail;

    switch (buttons) {
      case 2: //right mouse
        {
          const {
            client: { x: clientX, y: clientY },
          } = event.detail.currentPoints;

          setContourContextMenuData((data) => {
            if (data) {
              return data;
            }

            return {
              event: { clientX, clientY },
              clickedContour: false,
            };
          });
        }
        break;
    }
  }, []);

  useDeepCompareEffect(() => {
    if (!element) {
      return;
    }

    element.addEventListener(cornerstoneTools.EVENTS.MOUSE_DOWN, handleClick);

    return () => {
      element.removeEventListener(cornerstoneTools.EVENTS.MOUSE_DOWN, handleClick);
    };
  }, [element]);

  const toolControllerCallbacks: ToolControllerCallbacks = useMemo(
    () => ({
      onContourRightClicked: handleShowContourContextMenu,
      onEllipseRightClicked: handleShowEllipseContextMenu,
    }),
    [handleShowContourContextMenu, handleShowEllipseContextMenu]
  );

  return (
    <>
      {selectedTaskAssignmentId && (
        <ToolController
          element={element}
          viewerConfig={memoViewerConfig}
          callbacks={toolControllerCallbacks}
        />
      )}
      {!viewOnly && (
        <ContourContextMenu
          viewerConfig={memoViewerConfig}
          event={contourContextMenuData}
          onClose={async () => setContourContextMenuData(undefined)}
        />
      )}
      {!viewOnly && (
        <EllipseContextMenu
          event={ellipseContextMenuData}
          onClose={async () => setEllipseContextMenuData(undefined)}
        />
      )}
    </>
  );
}
