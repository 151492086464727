import { combineReducers } from "@reduxjs/toolkit";

import { reducer as annotatePageReducer } from "./annotatePage/annotatePageSlice";
import { patientTableHeightsSlice } from "./patientTableHeightsSlice";
import { projectViewFiltersSlice } from "./projectViewFiltersSlice";
import { seriesSelectionSlice } from "./seriesSelectionSlice";

const rootReducer = combineReducers({
  projectViewFilters: projectViewFiltersSlice.reducer,
  annotatePage: annotatePageReducer,
  seriesSelection: seriesSelectionSlice.reducer,
  patientTableHeights: patientTableHeightsSlice.reducer,
});

export default rootReducer;
