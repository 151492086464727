import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { MutationTuple } from "@apollo/client/react/types/types";

import { CohortType } from "../../../../common/types/CohortType";
import { COHORT_FRAGMENT } from "../../../../Project/contexts/Project/CohortFragment";
import { updateCohortsInCache } from "./updateCohortsInCache";

const MUTATION = gql`
  mutation CreateCohort($label: label_insert_input!) {
    cohort: insert_label_one(object: $label) {
      ...Cohort
    }
  }
  ${COHORT_FRAGMENT}
`;

type Data = { cohort: CohortType };

type Variables = { label: { name: string; project_id: number | null; type_name: string; metadata: { color: string } } };

export function useCreateCohort(): MutationTuple<Data, Variables> {
  return useMutation<Data, Variables>(MUTATION, {
    update(cache, { data }) {
      if (!data) {
        throw new Error("Something went wrong updating the cache after inserting a cohort");
      }

      const { cohort } = data;

      updateCohortsInCache(cohort, cache);
    },
    refetchQueries: ["GetAllCohorts"],
  });
}
