import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { MutationTuple } from "@apollo/client/react/types/types";

import { TumourBurdenType } from "../../../../../../Analysis/common/types/TumourBurdenType";
import { NODE, TARGET } from "../../../../../enums/TaskDescriptionEnums";
import { getRoiBurden } from "../../StudyPanel/utils/TumourResponse/getRoiBurden";
import { isRoiMeasurable } from "../../StudyPanel/utils/TumourResponse/isRoiMeasurable";
import { hasClassification } from "../../StudyPanel/utils/TumourResponse/utils/hasClassification";
import { LesionListItemFragmentType } from "../fragments/LesionListItemFragment";
import { RoiListItemFragmentType } from "../fragments/RoiListItemFragment";
import { getTumourBurdenCacheId } from "./getTumourBurdenCacheId";
import { getTumourCacheId } from "./getTumourCacheId";

const MUTATION = gql`
  mutation UpdateTumourBurdens(
    $insertObjects: [tumour_burden_insert_input!]!
    $tumourBurdenIdsToDelete: [Int!]!
  ) {
    update: insert_tumour_burden(
      objects: $insertObjects
      on_conflict: {
        constraint: tumour_burden_id_tumour_id_key
        update_columns: [diametric, volumetric]
      }
    ) {
      tumourBurdens: returning {
        tumourId: tumour_id
        id
        diametric
        volumetric
      }
    }
    delete: delete_tumour_burden(where: { id: { _in: $tumourBurdenIdsToDelete } }) {
      tumourBurdens: returning {
        tumourId: tumour_id
        id
      }
    }
  }
`;

type UpdateVariables = {
  tumour_id: number;
  id: number;
  diametric: number | undefined;
  volumetric: number | undefined;
};

type InsertVariables = {
  tumour_id: number;
  diametric: number | undefined;
  volumetric: number | undefined;
  series_tumour_burden_maps: {
    data: { series_id: number };
    on_conflict: {
      constraint: string;
      update_columns: string[];
    };
  };
};

type BurdenVariables = UpdateVariables | InsertVariables;

type Variables = {
  insertObjects: BurdenVariables[];
  tumourBurdenIdsToDelete: number[];
};

type Data = {
  update: {
    tumourBurdens: ({ tumourId: number; id: number } & TumourBurdenType)[];
  };
  delete: { tumourBurdens: { tumourId: number; id: number }[] };
};

export function useUpdateTumourBurdens(): MutationTuple<Data, Variables> {
  return useMutation<Data, Variables>(MUTATION, {
    update(cache, { data }) {
      if (!data) {
        throw new Error("Something went wrong updating the cache after updating tumour diagnosis");
      }

      const {
        update: { tumourBurdens: updated },
        delete: { tumourBurdens: deleted },
      } = data;

      for (const updatedTumourBurden of updated) {
        const { tumourId, id } = updatedTumourBurden;
        cache.modify({
          id: getTumourCacheId(tumourId, cache),
          fields: {
            tumour_burdens(tumourBurdenRefs = [], { readField }) {
              const newTumourBurdenRef = cache.writeFragment({
                data: updatedTumourBurden,
                fragment: gql`
                  fragment TumourBurden on tumour_burden {
                    id
                    tumour_id
                    diametric
                    volumetric
                  }
                `,
              });

              if (tumourBurdenRefs.some((ref: never) => readField("id", ref) === id)) {
                return tumourBurdenRefs;
              }

              return [...tumourBurdenRefs, newTumourBurdenRef];
            },
          },
        });
      }

      const deletedTumourBurdenIds = deleted.map(({ id }) => id);
      for (const id of deletedTumourBurdenIds) {
        const tumourBurdenCacheId = getTumourBurdenCacheId(id, cache);
        cache.evict({ id: tumourBurdenCacheId });
      }

      cache.gc();
    },
  });
}

type Input = {
  roiId: number;
  seriesId: number;
  diametric: number | undefined;
  volumetric: number | undefined;
  data: {
    tumourId: number;
    tumourBurdenIds: number[];
  }[];
};

export function getVariables(lesion: LesionListItemFragmentType, seriesIds: number[]): Variables {
  const insertObjects: BurdenVariables[] = [];
  const tumourBurdenIdsToDelete: number[] = [];

  const isTarget = hasClassification(lesion, TARGET);
  const isNode = hasClassification(lesion, NODE);
  const rois = lesion.rois;

  const inputs = rois
    .filter(({ series: { id: seriesId } }) => seriesIds.includes(seriesId))
    .map((roi) => getInput(roi, isNode, seriesIds));

  for (const input of inputs) {
    const mode = getMode(input, isTarget);

    const { diametric, volumetric, data, seriesId } = input;

    switch (mode) {
      case DELETE: {
        const tumourBurdenIds = data.flatMap(({ tumourBurdenIds }) => tumourBurdenIds);
        tumourBurdenIdsToDelete.push(...tumourBurdenIds);
        break;
      }
      case INSERT: {
        insertObjects.push(
          ...data.map(({ tumourId }) => ({
            tumour_id: tumourId,
            diametric,
            volumetric,
            series_tumour_burden_maps: {
              data: { series_id: seriesId },
              on_conflict: {
                constraint: "series_tumour_burden_map_pkey",
                update_columns: [],
              },
            },
          }))
        );
        break;
      }
      case UPDATE:
        insertObjects.push(
          ...data.flatMap(({ tumourId, tumourBurdenIds }) =>
            tumourBurdenIds.map((id) => ({
              tumour_id: tumourId,
              id,
              diametric,
              volumetric,
            }))
          )
        );
        break;
      default:
        throw new Error(`Mode ${mode} not defined`);
    }
  }

  return {
    insertObjects,
    tumourBurdenIdsToDelete,
  };
}

function getInput(roi: RoiListItemFragmentType, isNode: boolean, seriesIds: number[]): Input {
  if (seriesIds.length === 0) {
    throw new Error("Cannot get tumour burden variables when there are no series");
  }

  const {
    id: roiId,
    tumours,
    series: { id: seriesId },
  } = roi;

  const isBaseline = seriesId === seriesIds[0];

  const isMeasurable = isRoiMeasurable(roi);

  const { diametric, volumetric } =
    getRoiBurden(roi, isNode) ??
    (!isBaseline && !isMeasurable
      ? { diametric: 0, volumetric: 0 }
      : { diametric: undefined, volumetric: undefined });

  if (!tumours || tumours.length === 0) {
    throw new Error(`roi with id ${roiId} does not have tumours in useUpdateTumourBurdens`);
  }

  const data = tumours.map(({ tumour: { id: tumourId, burdens } }) => {
    const tumourBurdenIds = burdens.map(({ id }) => id);
    return { tumourId, tumourBurdenIds };
  });

  return {
    roiId,
    seriesId,
    diametric,
    volumetric,
    data,
  };
}

const INSERT = "INSERT";
const UPDATE = "UPDATE";
const DELETE = "DELETE";
type Mode = typeof INSERT | typeof UPDATE | typeof DELETE;

function getMode({ diametric, volumetric, data }: Input, isTarget: boolean): Mode {
  if (diametric === undefined || !isTarget) {
    return DELETE;
  }

  const hasBurdens = data.some(({ tumourBurdenIds }) => tumourBurdenIds.length > 0);
  if (!hasBurdens) {
    return INSERT;
  }

  return UPDATE;
}
