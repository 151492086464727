import { parse } from "flatted";

import { CONTOUR_VERSION } from "../enums/contourVersion";
import { ContourFragmentType } from "../fragments/ContourFragmentType";
import { ContourToolDataType } from "../types/ContourToolDataType";

export function parseContour(contourFragment: ContourFragmentType): ContourToolDataType[] {
  const { version, toolData } = contourFragment;

  let parsedToolData;
  if (version) {
    if (version !== CONTOUR_VERSION) {
      throw new Error(`Contours data version: ${version} not supported!`);
    }
    parsedToolData = parse(JSON.stringify(toolData));
  }
  return parsedToolData;
}
