import { useEffect, useState } from "react";
import { useLocation } from "react-router";

import { useCurrentUser } from "../../../../../common/contexts/UserContext/useCurrentUser";
import { useCurrentUserCan } from "../../../../../common/contexts/UserContext/useCurrentUserCan";
import { PROJECT_ROLE_ANNOTATOR } from "../../../../../common/types/UserRoleType";
import { TaskContextType } from "../../../../TaskContext";
import { TaskType } from "../../../Manage/hooks/TaskType";
import { useInsertDeleteTaskAssignments } from "../../../Manage/hooks/useInsertDeleteTaskAssignments";
import { useTask } from "../../../Manage/hooks/useTask";
import { TASK_COMPLETED, TASK_PENDING } from "../../../TaskWorklist/TaskProgressType";
import { useUpdateTaskAssignmentLastAccessed } from "../../../TaskWorklist/useUpdateTaskAssignmentLastAccessed";
import { getTaskAssignment } from "./utils/getTaskAssignment";

export type ReturnType = {
  context: TaskContextType | undefined;
  loading: boolean;
};

export function useGetTaskContext(
  projectId: number,
  patientId: number,
  taskId: number
): ReturnType {
  const [result, setResult] = useState<ReturnType>({
    context: undefined,
    loading: true,
  });
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const viewOnlyQueryParam = searchParams.get("view_only") === "true";

  const [insertDeleteTaskAssignments] = useInsertDeleteTaskAssignments();

  const [updateTaskAssignmentLastAccessed] = useUpdateTaskAssignmentLastAccessed();

  const { id: userId } = useCurrentUser();

  // use the network only policy here since it will ensure the data is up to date to avoid conflicts when multiple users try to open a task
  const {
    task,
    patientCohortIds,
    loading: taskLoading,
  } = useTask(patientId, taskId, { fetchPolicy: "network-only", nextFetchPolicy: "cache-only" });

  const canEditTask = useCurrentUserCan([PROJECT_ROLE_ANNOTATOR]);

  useEffect(() => {
    if (task) {
      const { taskAssignments } = task;

      const taskAssignment = getTaskAssignment(taskAssignments, userId);

      if (taskAssignment) {
        updateTaskAssignmentLastAccessed({
          variables: { taskAssignmentId: taskAssignment.id },
        }).then(({ data }) => {
          if (!data || data.update_task_assignment.returning.length !== 1) {
            console.warn(
              "Zero or more than one task assignments had their last accessed field updated but only one was expected"
            );
          }
        });
      }
    }
    // PRAGMA: One of these task ids could be removed, if it was determined to be desired
    // https://github.com/altis-labs/nota/pull/3307#pullrequestreview-1675158488
  }, [task?.id, taskId, updateTaskAssignmentLastAccessed, userId]);

  useEffect(() => {
    if (taskLoading) {
      setResult({ context: undefined, loading: true });
      return;
    }

    if (!task) {
      setResult({ context: undefined, loading: false });
      return;
    }

    const isQc = task.qcSchemaId !== null;

    const canOpenTask = canTaskBeOpened(task, userId, patientId, patientCohortIds);
    if (!canOpenTask) {
      setResult({ context: undefined, loading: false });
      return;
    }

    const { taskAssignments } = task;

    const taskAssignment = getTaskAssignment(taskAssignments, userId);

    if (taskAssignment) {
      const {
        id: selectedTaskAssignmentId,
        user: { id: selectedTaskAssignmentUserId },
        progress,
      } = taskAssignment;

      setResult({
        context: {
          task,
          viewOnly:
            !canEditTask ||
            progress === TASK_COMPLETED ||
            selectedTaskAssignmentUserId !== userId ||
            viewOnlyQueryParam,
          selectedTaskAssignmentId,
          isQc,
        },
        loading: false,
      });
      return;
    }

    if (viewOnlyQueryParam || !canEditTask) {
      setResult({
        context: {
          task,
          viewOnly: true,
          selectedTaskAssignmentId: undefined,
          isQc,
        },
        loading: false,
      });
      return;
    }

    if (!taskAssignment) {
      insertDeleteTaskAssignments({
        variables: {
          objects: [
            {
              task_id: taskId,
              progress: TASK_PENDING,
              user_id: userId,
              patient_id: patientId,
              show_badge: false,
            },
          ],
          taskAssignmentIdsToDelete: [],
        },
      }).then(({ data }) => {
        const {
          insert_task_assignment: { returning: insertedTaskAssignments },
        } = data || { insert_task_assignment: { returning: [] } };

        if (insertedTaskAssignments.length !== 1) {
          console.warn("Zero or more than one tasks was inserted but only one was expected");
        }

        const insertedTaskAssignment = insertedTaskAssignments[0];
        const {
          id: selectedTaskAssignmentId,
          user: { id: selectedTaskAssignmentUserId },
        } = insertedTaskAssignment;

        setResult({
          context: {
            task: {
              ...task,
              taskAssignments: [...task.taskAssignments, insertedTaskAssignment],
            },
            viewOnly: selectedTaskAssignmentUserId !== userId,
            isQc,
            selectedTaskAssignmentId,
          },
          loading: false,
        });
      });
      return;
    }
  }, [
    task,
    taskLoading,
    userId,
    projectId,
    patientId,
    taskId,
    canEditTask,
    viewOnlyQueryParam,
    insertDeleteTaskAssignments,
  ]);

  return result;
}

function canTaskBeOpened(
  { enabled, restrictedUsers, excludedPatients, restrictedCohorts }: TaskType,
  userId: number,
  patientId: number,
  cohortIds: number[]
): boolean {
  const isUserRestricted =
    restrictedUsers.length !== 0 &&
    !restrictedUsers.some(({ id: restrictedUserId }) => restrictedUserId === userId);
  const isPatientExcluded = excludedPatients.some(({ id }) => id === patientId);
  const isCohortExcluded =
    restrictedCohorts.length !== 0 && !restrictedCohorts.some(({ id }) => cohortIds.includes(id));

  return enabled && !isUserRestricted && !isPatientExcluded && !isCohortExcluded;
}
