import { Popover } from "@mui/material";
import React, { useContext, useState } from "react";
import styled from "styled-components";

import { ReactComponent as PlusIcon } from "../../../../../../assets/svgs/PlusOutlineFilled.svg";
import { ReactComponent as MinusIcon } from "../../../../../../assets/svgs/RemoveCircle.svg";
import { ReactComponent as Viewers } from "../../../../../../assets/svgs/Viewers.svg";
import { SvgIcon } from "../../../../../../common/components/icons/SvgIcon";
import { main } from "../../../../../../common/theme/main";
import { useAddViewer } from "../Viewer/hooks/useAddViewer";
import { useJumpToViewer } from "../Viewer/hooks/useJumpToViewer";
import { useRemoveLastViewer } from "../Viewer/hooks/useRemoveLastViewer";
import { ViewerContext } from "../Viewer/ViewerContext";

const PopOut = styled.div`
  background-color: ${main.colors.background.secondary};
  gap: 10px;
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 600;
  align-items: center;
  overflow: hidden;
  padding: 10px;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: -2px;
  cursor: pointer;
`;

const ViewerWrapper = styled.div`
  width: 100%;
  justify-items: left;
`;

const ButtonWrapper = styled.div<{
  color: string;
  pointer: boolean;
}>`
  cursor: ${({ pointer }) => (pointer ? "pointer" : "default")};
  display: flex;
  flex-direction: row;
  gap: 8px;
  color: ${({ color }) => color}}
`;

interface ViewerControlsPopoverProps {
  disabled: boolean;
}

export function ViewerControlsPopover({ disabled }: ViewerControlsPopoverProps): JSX.Element {
  const addViewer = useAddViewer();
  const removeLastViewer = useRemoveLastViewer();
  const jumpToViewer = useJumpToViewer();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { viewerConfigs } = useContext(ViewerContext);

  const canAddViewers = viewerConfigs.length < 6 && !disabled;
  const canRemoveViewers = viewerConfigs.length > 1 && !disabled;

  const handleAddViewer = () => {
    if (!canAddViewers) {
      return;
    }
    const viewerConfig = addViewer();
    if (!viewerConfig) {
      return;
    }

    jumpToViewer(viewerConfig);
    handleClose();
  };

  const handleRemoveViewer = () => {
    if (!canRemoveViewers) {
      return;
    }
    removeLastViewer();
    handleClose();
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const addButtonColor = canAddViewers ? "" : main.colors.neutral.neutral6;
  const removeButtonColor = canRemoveViewers ? "" : main.colors.neutral.neutral6;

  const open = Boolean(anchorEl);
  const id = open ? "viewer-popover" : undefined;

  return (
    <>
      <IconWrapper onClick={handleClick}>
        <SvgIcon icon={Viewers} size={20} color={main.colors.actionPrimary.default} />
      </IconWrapper>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 40,
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <PopOut>
          Viewers
          <ViewerWrapper>
            <ButtonWrapper onClick={handleAddViewer} color={addButtonColor} pointer={canAddViewers}>
              <SvgIcon icon={PlusIcon} size={21} color={addButtonColor} />
              Add
            </ButtonWrapper>
          </ViewerWrapper>
          <ViewerWrapper>
            <ButtonWrapper
              onClick={handleRemoveViewer}
              color={removeButtonColor}
              pointer={canRemoveViewers}
            >
              <SvgIcon icon={MinusIcon} size={21} color={removeButtonColor} />
              Remove
            </ButtonWrapper>
          </ViewerWrapper>
        </PopOut>
      </Popover>
    </>
  );
}
