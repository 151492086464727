import { TaskLink } from "nota-predict-web/src/common/components/Link";
import React, { useContext, useMemo } from "react";
import { Column, Row, UseFiltersColumnProps } from "react-table";

import { ReactComponent as LaunchIcon } from "../../../../assets/svgs/Launch.svg";
import { SvgIcon } from "../../../../common/components/icons/SvgIcon";
import { getFlagValueFromSeries } from "../../../../common/components/SeriesFlags/getFlagValueFromSeries";
import {
  areFlagsEqual,
  FLAGGED,
  FlagTypeOptions,
  NOT_FLAGGED,
  PARTIALLY_FLAGGED,
  SeriesFlag,
  seriesFlagFilterProps,
} from "../../../../common/components/SeriesFlags/SeriesFlag";
import { SeriesFlagLabel } from "../../../../common/components/SeriesFlags/SeriesFlagLabel";
import { CellProps } from "../../../../common/components/Table/CellProps";
import { TextCell } from "../../../../common/components/Table/TextCell";
import { main } from "../../../../common/theme/main";
import { CohortLabel } from "../../../../Dashboard/components/Settings/Cohort/CohortLabel";
import { getFilterColumnProps } from "../../../../Dashboard/components/Settings/Users/GenericColumnFilter";
import { ProjectContext } from "../../../../Project/contexts/ProjectContext";
import { REASON_OPTIONS } from "../../Annotate/page/StudyPanel/CreateSeriesFlagForm";
import { TaskLabel } from "../../TaskWorklist/TaskLabel";
import { TaskProgressWithUser } from "../../../../common/components/TaskStatus/TaskProgressLabel";
import { TASK_PENDING } from "../../TaskWorklist/TaskProgressType";
import { areCohortsEqual } from "./filters/areCohortsEqual";
import { CombinedUserProgressColumnFilter } from "./filters/CombinedUserProgressColumnFilter";
import { getNonNullCombinedUserProgressFilter } from "./filters/CombinedUserProgressFilterType";
import { getCohortOption } from "./filters/getCohortOption";
import { TaskColumnFilter } from "./filters/TaskColumnFilter";
import { getNonNullTaskFilter } from "./filters/TaskFilterType";
import { PatientTaskTableRowType } from "./types/PatientTaskTableRowType";
import { getReasonsFromStudies } from "./utils/getReasonsFromStudies";
import { includeTaskByName, includeTaskByUserAndProgress } from "./utils/includeTask";

export function usePatientTableColumns(): Column<PatientTaskTableRowType>[] {
  const flagValues: FlagTypeOptions[] = [FLAGGED, PARTIALLY_FLAGGED, NOT_FLAGGED];
  const { cohorts = [] } = useContext(ProjectContext);

  const columns: Column<PatientTaskTableRowType>[] = [
    {
      id: "seriesFlags",
      Header: <SeriesFlag flagSize={17} />,
      ...getFilterColumnProps(getFlagValues, areFlagsEqual, {
        values: flagValues,
        ...seriesFlagFilterProps,
      }),
      style: {
        width: "50px",
      },
    },
    {
      id: "patientDicomId",
      Header: <>Subject ID</>,
      disableFilters: false,
      style: {
        width: "25%",
      },
      accessor: ({ patientDicomId }) => patientDicomId,
      Cell: ({ value }: { value: string }) => <TextCell value={value} />,
    },
    {
      id: "cohorts",
      Header: "Cohort(s)",
      style: {
        width: "25%",
      },
      disableSortBy: true,
      ...getFilterColumnProps(({ cohorts }) => cohorts, areCohortsEqual, {
        values: cohorts,
        getOption: getCohortOption,
        includeUnassigned: false,
        placeholder: "Filter Cohorts...",
        // eslint-disable-next-line react/display-name,react/prop-types
        RowLabelComponent: ({ value }) => <CohortLabel cohort={value} />,
        // eslint-disable-next-line react/display-name,react/prop-types
        SelectLabelComponent: ({ value }) => <CohortLabel cohort={value} />,
      }),
    },
    {
      id: "tasks",
      Header: "Task",
      style: {
        width: "25%",
      },
      disableFilters: false,
      disableSortBy: true,
      Filter: TaskColumnFilter,
      filter: (rows: Row<PatientTaskTableRowType>[], columnIds: string[], filterValue: unknown) => {
        const { tasks } = getNonNullTaskFilter(filterValue);
        return rows.filter(({ original: { task } }) => includeTaskByName(task, tasks));
      },
      // eslint-disable-next-line react/prop-types,react/display-name
      Cell: ({
        row: {
          original: { task },
        },
      }: {
        column: UseFiltersColumnProps<PatientTaskTableRowType>;
        row: Row<PatientTaskTableRowType>;
      }) => {
        if (!task) {
          return null;
        }

        return <TaskLabel task={task} />;
      },
    },
    {
      id: "status",
      Header: "Status",
      style: {
        width: "25%",
      },
      disableFilters: false,
      disableSortBy: true,
      Filter: CombinedUserProgressColumnFilter,
      filter: (rows: Row<PatientTaskTableRowType>[], columnIds: string[], filterValue: unknown) => {
        const { progresses, users } = getNonNullCombinedUserProgressFilter(filterValue);
        return rows.filter(({ original: { task } }) =>
          includeTaskByUserAndProgress(task, progresses, users)
        );
      },
      // eslint-disable-next-line react/prop-types,react/display-name
      Cell: ({
        row: {
          original: { task },
        },
      }: {
        column: UseFiltersColumnProps<PatientTaskTableRowType>;
        row: Row<PatientTaskTableRowType>;
      }) => {
        if (!task) {
          return null;
        }

        const { taskAssignments } = task;

        return (
          <TaskProgressWithUser
            progress={{ taskAssignments, displayProgress: TASK_PENDING }}
            showBadge={false}
          />
        );
      },
    },
    {
      id: "flagReasons",
      Header: "Flag Reasons",
      style: {
        width: "25%",
      },
      disableSortBy: true,
      ...getFilterColumnProps(
        ({ studies }) => getReasonsFromStudies(studies),
        (a, b) => a === b,
        {
          values: REASON_OPTIONS,
          getOption: (value) => ({
            value: value,
            label: value ?? "",
          }),
          includeUnassigned: true,
          placeholder: "Filter Flag Reasons...",
          // eslint-disable-next-line react/display-name,react/prop-types
          RowLabelComponent: ({ value }) => <SeriesFlagLabel reason={value} />,
          // eslint-disable-next-line react/display-name,react/prop-types
          SelectLabelComponent: ({ value }) => <SeriesFlagLabel reason={value} />,
        }
      ),
      accessor: ({ studies }) => getReasonsFromStudies(studies),
      Cell: ({ value: reasons }: { value: string[] }) => (
        <>
          {reasons.map((reason) => (
            <SeriesFlagLabel key={reason} reason={reason} />
          ))}
        </>
      ),
    },
    {
      id: "goToTaskPage",
      Header: "Go To",
      style: {
        width: "70px",
      },
      // eslint-disable-next-line react/prop-types,react/display-name
      Cell: ({ row: { original: row } }: CellProps<PatientTaskTableRowType>) => {
        if (row.task === undefined) {
          return null;
        }

        return (
          <TaskLink patientID={row.id} task={row.task}>
            <SvgIcon icon={LaunchIcon} size={14} color={main.colors.neutral.neutral3} />
          </TaskLink>
        );
      },
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => columns, []);
}

function getFlagValues({ studies }: PatientTaskTableRowType): FlagTypeOptions[] {
  const allSeries = studies.flatMap(({ series }) => series);
  return getFlagValueFromSeries(allSeries);
}
