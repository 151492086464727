import React from "react";

import { ReactComponent as AddIcon } from "../../assets/svgs/PlusOutlineFilled.svg";
import { ReactComponent as RemoveIcon } from "../../assets/svgs/RemoveCircle.svg";
import { SvgIcon } from "../components/icons/SvgIcon";
import { ADD, REMOVE, UpdateModeType } from "./UpdateModeType";

interface UpdateModeIconProp {
  mode: UpdateModeType;
  color: string;
  size: number;
}

export function UpdateModeIcon({ mode, color, size }: UpdateModeIconProp): JSX.Element {
  const icons: Record<UpdateModeType, JSX.Element> = {
    [ADD]: <SvgIcon icon={AddIcon} size={size} color={color} />,
    [REMOVE]: <SvgIcon icon={RemoveIcon} size={size} color={color} />,
  };

  return icons[mode];
}
