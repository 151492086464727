import ClearIcon from "@mui/icons-material/Clear";
import styled from "styled-components";

import IconButton from "../../../common/components/buttons/IconButton";
import { MuiIcon } from "../../../common/components/icons/MuiIcon";
import StateProgressBar from "../../../common/components/StateProgressBar";
import { main } from "../../../common/theme/main";
import { TaskStatusType } from "../../../common/types/StatusTypes";

interface UploadInProgressLabelProps {
  progress: number;
  state: TaskStatusType;
  onCancel: () => void;
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

export function UploadInProgressLabel({
  progress,
  state,
  onCancel,
}: UploadInProgressLabelProps): JSX.Element {
  return (
    <Wrapper>
      <StateProgressBar max={100} value={progress * 100} state={state} width={500} height={12} />
      <IconButton
        icon={<MuiIcon icon={ClearIcon} size={20} color={main.colors.neutral.black} />}
        onClick={onCancel}
      />
    </Wrapper>
  );
}
