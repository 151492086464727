import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { QueryResult } from "@apollo/client/react/types/types";

import { LesionTypeType } from "../../../Annotate/types/LesionTypeType";

export type Variables = {
  projectId: number;
};

export type Data = {
  patients: {
    studies: {
      series_aggregate: {
        aggregate: {
          count: number;
        };
      };
    }[];
    cohorts: {
      id: number;
    }[];
    id: number;
    taskAssignments: {
      id: number;
      taskId: number;
      userId: number;
      progress: string;
      lastModified: string;
      showBadge: boolean;
    }[];
    lesions: {
      id: number;
      created: string;
      createdBy: number;
      taskId: number;
      type: LesionTypeType;
      anatomicalStructure: {
        id: number;
        displayName: string;
        color: string;
      };
      classifications: {
        id: number;
        classification: string;
      }[];
      rois: {
        id: number;
        ellipses: {
          aggregate: {
            count: number;
          };
        };
        contours: {
          aggregate: {
            count: number;
          };
        };
      }[];
    }[];
  }[];
  projectTasks: {
    id: number;
    name: string;
  }[];
};

export type PatientType = Data["patients"][number];
export type TaskType = Data["projectTasks"][number];
export type TaskAssignmentType = PatientType["taskAssignments"][number];
export type LesionType = PatientType["lesions"][number];
export type RoiType = LesionType["rois"][number];
export type AnatomicalStructureTye = LesionType["anatomicalStructure"];
export type LesionClassification = LesionType["classifications"][number];

const QUERY = gql`
  query getProjectLesions($projectId: Int!) {
    patients: patient(
      where: {
        label_patients: {
          label: { _and: [
            { label_projects: { project_id: { _eq: $projectId } } }
            { type_name: { _eq: "COHORT" } }
          ] }
        }    
      }
    ) {
      studies {
        series_aggregate {
          aggregate {
            count
          }
        }
      }
      cohorts: label_patients( where: { label: { type_name: { _eq: "COHORT" } } }) {
        id: label_id
      }
      id: patient_id
      taskAssignments: task_assignments(where: { task: { project_id: { _eq: $projectId } } }) {
        id
        taskId: task_id
        progress
        userId: user_id
        lastModified: last_modified
        showBadge: show_badge
      }
      lesions(where: { task: { project_id: { _eq: $projectId } } }) {
        id: lesion_id
        created
        createdBy: created_by
        taskId: task_id
        type
        anatomicalStructure: anatomical_structure {
          id: anatomical_structures_id
          displayName: display_name
          color
        }
        classifications: lesion_classifications {
          id
          classification
        }
        rois {
          id: roi_id
          ellipses: ellipses_aggregate {
            aggregate {
              count
            }
          }
          contours: contours_aggregate {
            aggregate {
              count
            }
          }
        }
      }
    }
    projectTasks: task(where: { project_id: { _eq: $projectId } }) {
      id
      name
    }
  }
`;

export function useProjectLabels(projectId: number): QueryResult<Data, Variables> {
  return useQuery<Data, Variables>(QUERY, { variables: { projectId } });
}
