import { TimepointType } from "../../../../Annotate/components/Annotate/page/AnnotationPanel/hooks/Timepoint/TimepointType";
import { StudyFragmentType } from "../../../../Annotate/fragments/StudyFragmentType";
import { getDate } from "../../../../common/utils/dateFormatUtils/getDate";
import dayjs from "dayjs";

export function getTimepoints(studies: StudyFragmentType[]): TimepointType[] {
  const nonIndexedTimepoints: TimepointType[] = [];

  for (const { studyDate, series: seriesList } of studies) {
    for (const series of seriesList) {
      const { seriesDate } = series;

      const date =
        getDate(dayjs(seriesDate).format()) ?? getDate(dayjs(studyDate).format()) ?? undefined;

      let existingTimepoint: TimepointType | undefined = nonIndexedTimepoints.find(
        ({ date: timepointDate }) => timepointDate?.getTime() === date?.getTime()
      );
      if (!existingTimepoint) {
        existingTimepoint = { index: -1, date, series: [] };
        nonIndexedTimepoints.push(existingTimepoint);
      }

      existingTimepoint.series.push(series);
    }
  }

  const sorted = nonIndexedTimepoints.sort(({ date: dateA }, { date: dateB }) => {
    if (!dateA) {
      return -1;
    }
    if (!dateB) {
      return -1;
    }
    return dateA.getTime() - dateB.getTime();
  });

  return sorted.map((timepoint, index) => {
    const { date } = timepoint;
    return {
      ...timepoint,
      index: date ? index : undefined,
    };
  });
}
