import { useEffect } from "react";

import { SubjectType } from "../../../Analysis/common/types/SubjectType";
import { POLLING_INTERVAL } from "../../utils/configConstants";
import { getStudyTableRow, StudyTableRowType } from "./StudyTableRowType";
import { usePatientStudies } from "./usePatientStudies";

export function useStudyRowsFromSubjects(subjects: SubjectType[]): {
  loading: boolean;
  studyRows: StudyTableRowType[];
} {
  const patientIds = subjects.map(({ id }) => id);

  const {
    data: patientsData,
    loading,
    startPolling,
    stopPolling,
  } = usePatientStudies(patientIds, false);

  useEffect(() => {
    startPolling(POLLING_INTERVAL);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  const studyRows =
    patientsData?.patients.flatMap((patient) => {
      const { studies } = patient;
      return studies.map((study) => getStudyTableRow(patient, study));
    }) ?? [];

  return { loading, studyRows };
}
