import React, { Dispatch, ReactElement, SetStateAction } from "react";

import { UserType } from "../../types/UserType";
import { UserActionCallbacksType } from "./UserActionCallbacksType";

type UserManagementRulesType = {
  allowEditOtherUser: boolean;
  allowEditRole: boolean;
  allowEditMetadata: boolean;
  allowResetPassword: boolean;
  allowRemoveUser: boolean;
  allowCreateUser: boolean;
  allowAddUser: boolean;
  allowEditUserSuspension: boolean;
  showGlobalRoles: boolean;
  showProjectRoles: boolean;
  isWithinProject: boolean;
};

export const createProjectUserContext: UserManagementRulesType = {
  allowEditOtherUser: true,
  allowEditRole: true,
  allowEditMetadata: false,
  allowResetPassword: false,
  allowRemoveUser: true,
  allowCreateUser: false,
  allowAddUser: true,
  allowEditUserSuspension: false,
  showGlobalRoles: false,
  showProjectRoles: true,
  isWithinProject: false,
};

export const manageProjectUserContext: UserManagementRulesType = {
  allowEditOtherUser: true,
  allowEditRole: true,
  allowEditMetadata: false,
  allowResetPassword: true,
  allowRemoveUser: true,
  allowCreateUser: false,
  allowAddUser: true,
  allowEditUserSuspension: false,
  showGlobalRoles: false,
  showProjectRoles: true,
  isWithinProject: true,
};

export const manageCurrentUserContext: UserManagementRulesType = {
  allowEditOtherUser: false,
  allowEditRole: false,
  allowEditMetadata: true,
  allowResetPassword: true,
  allowRemoveUser: false,
  allowCreateUser: false,
  allowAddUser: false,
  allowEditUserSuspension: false,
  showGlobalRoles: true,
  showProjectRoles: false,
  isWithinProject: false,
};

export const manageGlobalUserContext: UserManagementRulesType = {
  allowEditOtherUser: true,
  allowEditRole: true,
  allowEditMetadata: true,
  allowResetPassword: true,
  allowRemoveUser: false,
  allowCreateUser: true,
  allowAddUser: true,
  allowEditUserSuspension: true,
  showGlobalRoles: true,
  showProjectRoles: false,
  isWithinProject: false,
};

type UserManagementContextType = UserActionCallbacksType & {
  users: UserType[];
  setUsers: Dispatch<SetStateAction<UserType[]>>;
  rules: UserManagementRulesType;
};

export const UserManagementContext = React.createContext<UserManagementContextType>({
  users: [],
  setUsers: () => undefined,
  rules: manageProjectUserContext,
});

interface UserContextProps extends UserManagementContextType {
  children: JSX.Element;
}

export const UserManagementContextProvider = ({
  rules,
  children,
  users,
  setUsers,
  onEdited,
  onRemoved,
  onPasswordReset,
  onCreated,
}: UserContextProps): ReactElement => {
  return (
    <UserManagementContext.Provider
      value={{
        users,
        setUsers,
        rules,
        onEdited,
        onRemoved,
        onPasswordReset,
        onCreated,
      }}
    >
      {children}
    </UserManagementContext.Provider>
  );
};
