import styled from "styled-components";

export const LabelIndicator = styled.div<{ color: string; height?: string }>`
  width: 4px;
  height: ${(props) => (props.height ? props.height : "18px")};
  background: ${(props) => props.color};
  border-radius: 12px;
  flex: none;
  order: 0;
  flex-grow: 0;
`;
