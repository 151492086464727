import { useContext, useEffect, useMemo, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Select, { ValueType } from "react-select";
import CreatableSelect from "react-select/creatable";
import styled from "styled-components";

import FilterCheckbox from "../../../../Analysis/ToolPanel/components/FilterCheckbox";
import { ErrorLabel } from "../../../../common/components/input/ErrorLabel";
import { getSelectStyle } from "../../../../common/components/input/getSelectStyle";
import { Input } from "../../../../common/components/input/Input";
import {
  InnerButtonWrapper,
  InputButton,
  InputDiv,
} from "../../../../common/components/input/InputButton";
import { Label } from "../../../../common/components/input/Label";
import { FlexLoading } from "../../../../common/components/Loading";
import ToggleSwitch from "../../../../common/components/ToggleSwitch";
import { UserSelect } from "../../../../common/components/UserManagement/UserSelect";
import { main } from "../../../../common/theme/main";
import { CohortType } from "../../../../common/types/CohortType";
import { ADD } from "../../../../common/types/UpdateModeType";
import { UserType } from "../../../../common/types/UserType";
import { CohortSelect } from "../../../../DataManagement/Upload/components/CohortSelect";
import { ProjectContext } from "../../../../Project/contexts/ProjectContext";
import { TEMPLATE_TASK_DESCRIPTION_NAMES } from "../../../enums/TaskDescriptionEnums";
import { AnatomicalStructureAnnotation } from "../../AnatomicalStructureAnnotation";
import {
  AnatomicalStructureOptionType,
  getAnatomicalStructureOption,
  getAnatomicalStructureOptions,
} from "../../AnatomicalStructureOptionType";
import { AnatomicalStructuresFragmentType } from "../../AnatomicalStructuresFragment";
import { getNewAnatomicalStructure } from "../../Annotate/page/AnnotationPanel/AnatomicalStructureSelector/getNewAnatomicalStructure";
import { validateAnatomicalStructure } from "../../Annotate/page/AnnotationPanel/AnatomicalStructureSelector/validateAnatomicalStructure";
import { useAnatomicalStructures } from "../../useAnatomicalStructures";
import { TaskType } from "../hooks/TaskType";
import {
  ClassificationAttributeType,
  useClassificationAttributes,
} from "../hooks/useClassificationAttributes";
import { useExistingTaskAnnotations } from "../hooks/useExistingTaskAnnotations";
import { useQualityControlRuleSets } from "../hooks/useQualityControlRuleSets";
import { TaskDescriptionTemplateType } from "../PatientTable/TaskDescriptionOptionType";
import {
  ClassificationAttributeOptionType,
  getClassificationAttributeOption,
} from "../PatientTable/types/ClassificationAttributeOptionType";
import { CreateTaskArgumentsType } from "./CreateTaskArgumentsType";
import { SelectLabel } from "./SelectLabel";
import { useCreateTasks } from "./useCreateTasks";
import {
  getQualityControlRuleSetOption,
  QualityControlRuleSetOptionType,
} from "./utils/getQualityControlRuleSetOption";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 400px;
`;

export const RowWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 5;
`;

const LabellingOptionsWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

export const FieldWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
`;

interface CreateTaskWizardProps {
  taskDescriptionTemplate: TaskDescriptionTemplateType;
  onTasksCreated: (task: TaskType[]) => void;
  onCancel: () => void;
}

export function CreateTaskWizard({
  taskDescriptionTemplate,
  onTasksCreated,
  onCancel,
}: CreateTaskWizardProps): JSX.Element {
  const [selectedUsers, setSelectedUsers] = useState<UserType[]>([]);

  const [selectedCohorts, setSelectedCohorts] = useState<CohortType[]>([]);

  const [limitToBaseline, setLimitToBaseline] = useState<boolean>(false);

  const [qualityControlRuleSet, setQualityControlRuleSet] = useState<number | null>(null);

  const {
    template: { wizardSettings },
  } = taskDescriptionTemplate;

  const {
    showInteractionOptions,
    showAnatomySelection,
    enableSegmentation,
    enableDetection,
    allowUserDefinedLabels,
    showClassificationSelection,
    allowUserDefinedClassifications,
    showSeriesClassificationOptions,
    showLimitToBaselineOption,
    showQualityControlRuleSetOption,
  } = wizardSettings;

  const { cohorts, projectId, users } = useContext(ProjectContext);

  const {
    data: anatomicalStructures = [] as AnatomicalStructuresFragmentType[],
    loading: anatomicalStructuresLoading,
  } = useAnatomicalStructures();

  const { data: classificationAttributesData, loading: classificationAttributesLoading } =
    useClassificationAttributes();

  const { classificationAttributes = [] } = classificationAttributesData || {};

  const { data: qualityControlRuleSetsData, loading: qualityControlRuleSetsLoading } =
    useQualityControlRuleSets();

  const qualityControlRuleSets = useMemo(
    () => qualityControlRuleSetsData?.qcSchemas ?? [],
    [qualityControlRuleSetsData]
  );

  const createTasks = useCreateTasks();

  const [loading, setLoading] = useState<boolean>(false);

  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    setValue,
    // FIXME: FormFieldsType specified here is not convertible to CreateTaskArgumentsType
  } = useForm<FormFieldsType>({
    defaultValues: {
      taskName: "",
      anatomicalStructures: [],
      seriesClassifications: [],
      restrictedUsers: [],
      restrictedCohorts: [],
      labellingOptions: {
        enableDetection,
        enableSegmentation,
      },
      seriesClassificationOptions: {
        toggleOnlyOne: false,
        toggleAny: false,
      },
      limitToBaseline: false,
      qcSchemaId: showQualityControlRuleSetOption ? qualityControlRuleSet : null,
    },
  });

  useEffect(() => {
    if (
      showQualityControlRuleSetOption &&
      qualityControlRuleSets.length > 0 &&
      qualityControlRuleSet === null
    ) {
      const mostRecent = qualityControlRuleSets
        .slice()
        .sort((a, b) => new Date(b.lastModified).getTime() - new Date(a.lastModified).getTime())[0];
      setQualityControlRuleSet(mostRecent.id);
      setValue("qcSchemaId", mostRecent.id);
    }
  }, [qualityControlRuleSet, qualityControlRuleSets, showQualityControlRuleSetOption]);

  const handleCancel = () => {
    onCancel();
  };

  const handleAnatomyChanged = (values: ValueType<AnatomicalStructureOptionType, true>) => {
    return values.map(({ value }) => value).filter((a) => a) as AnatomicalStructuresFragmentType[];
  };

  const handleClassificationAttributesChanged = (
    values: ValueType<ClassificationAttributeOptionType, true>
  ) => {
    return values.map(({ value }) => value).filter((a) => a) as ClassificationAttributeType[];
  };

  const handleRestrictedUsersChanged = (values: UserType[]) => {
    setSelectedUsers(values);
    return values;
  };

  const handleRestrictedCohortsChanged = (values: CohortType[]) => {
    setSelectedCohorts(values);
    return values;
  };

  const handleLimitToBaselineChanged = (value: boolean) => {
    setLimitToBaseline(value);
    return value;
  };

  const handleQualityControlRuleSetChanged = (ruleSet: QualityControlRuleSetOptionType | null) => {
    setQualityControlRuleSet(ruleSet?.value ?? null);
    return ruleSet?.value ?? null;
  };

  const handleAnatomyCreated = (
    anatomy: string,
    selectedAnatomicalStructures: AnatomicalStructuresFragmentType[]
  ) => {
    if (!validateAnatomicalStructure(anatomy, anatomicalStructures)) {
      return;
    }

    const structure = anatomy.toLowerCase().replace(" ", "_");
    const newAnatomicalStructure = getNewAnatomicalStructure(structure, anatomy);

    return [...selectedAnatomicalStructures, newAnatomicalStructure];
  };

  const handleClassificationCreated = (
    classification: string,
    selectedClassificationAttributes: ClassificationAttributeType[]
  ) => {
    if (
      classificationAttributes.some(({ text }) => text === classification) ||
      classification === ""
    ) {
      return selectedClassificationAttributes;
    }

    const newClassificationAttribute = {
      text: classification,
      readonly: false,
    };

    return [...selectedClassificationAttributes, newClassificationAttribute];
  };

  const formatOptionLabel = (option: AnatomicalStructureOptionType) => {
    const { value: anatomicalStructure } = option;

    // @ts-ignore
    if (option.__isNew__) {
      // @ts-ignore
      return formatCreateLabel(anatomicalStructure);
    }

    const { id } = anatomicalStructure;

    const state = id === 0 ? ADD : undefined;

    return (
      <AnatomicalStructureAnnotation anatomicalStructure={anatomicalStructure} state={state} />
    );
  };

  const formatCreateLabel = (anatomy: string) => {
    const newAnatomicalStructure = getNewAnatomicalStructure(anatomy, anatomy);

    return (
      <AnatomicalStructureAnnotation anatomicalStructure={newAnatomicalStructure} state={ADD} />
    );
  };

  const formatCreateClassificationLabel = (classification: string) => {
    return <SelectLabel name={classification} state={ADD} />;
  };

  const formatUploadRuleSetLabel = () => {
    return <SelectLabel name={"Upload new QC rule set..."} state={ADD} />;
  };

  const formatClassificationAttributeOptionLabel = (option: ClassificationAttributeOptionType) => {
    // @ts-ignore
    if (option.__isNew__) {
      const { value: newClassificationName } = option;
      // @ts-ignore
      return formatCreateClassificationLabel(newClassificationName);
    }

    const { value: classificationAttribute } = option;

    const { text: newClassificationName } = classificationAttribute;

    const state = !classificationAttributes.some(({ text }) => text === newClassificationName)
      ? ADD
      : undefined;

    return <SelectLabel name={newClassificationName} state={state} />;
  };

  // FIXME: Was previously CreateTaskArgumentsType, but that is not convertible to FormFieldsType
  // forced to change with react-hook-forms upgrade —B
  const handleComplete: SubmitHandler<FormFieldsType> = async (args) => {
    setLoading(true);

    const { taskDescription } = taskDescriptionTemplate;

    const tasks = await createTasks({
      ...args,
      taskDescription,
      projectIds: projectId ? [projectId] : [],
    });

    setLoading(false);
    onTasksCreated(tasks);
  };

  const selectStyle = getSelectStyle<AnatomicalStructureOptionType, true>();

  const classificationAttributesSelectStyle = getSelectStyle<
    ClassificationAttributeOptionType,
    true
  >();

  const qualityControlRuleSetSelectStyle = getSelectStyle<QualityControlRuleSetOptionType, false>();

  const anatomicalStructureOptions = getAnatomicalStructureOptions(
    anatomicalStructures,
    allowUserDefinedLabels
  );

  const usedAnatomicalStructuresOptions = useExistingTaskAnnotations();

  const filteredAnatomicalStructuresOptions = anatomicalStructureOptions.filter(
    (item) => !usedAnatomicalStructuresOptions.includes(item.value.structure)
  );

  const classificationOptions = classificationAttributes.map((classificationAttribute) =>
    getClassificationAttributeOption(classificationAttribute)
  );

  const qualityControlRuleSetOptions = qualityControlRuleSets.map((qualityControlRuleSet) =>
    getQualityControlRuleSetOption(qualityControlRuleSet)
  );

  return (
    <form onSubmit={handleSubmit(handleComplete)}>
      <Wrapper>
        <FieldWrapper>
          <RowWrapper>
            <Label htmlFor={"taskName"} required>
              Task Name
            </Label>
            <Input
              type="text"
              id={"taskName"}
              autoFocus
              autoComplete={"off"}
              disabled={loading}
              {...register("taskName", {
                required: "A task name must be provided!",
                validate: validateTaskName,
              })}
              error={errors.taskName}
            />
          </RowWrapper>
          <RowWrapper>
            {errors.taskName && errors.taskName.message && (
              <ErrorLabel>{errors.taskName.message}</ErrorLabel>
            )}
          </RowWrapper>
        </FieldWrapper>
        {showAnatomySelection && (
          <FieldWrapper>
            <Controller
              control={control}
              name="anatomicalStructures"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <>
                  Annotations
                  <CreatableSelect
                    value={value.map((anatomicalStructure) =>
                      getAnatomicalStructureOption(anatomicalStructure)
                    )}
                    styles={selectStyle}
                    isClearable={false}
                    isSearchable={true}
                    placeholder={"Select annotations..."}
                    isMulti={true}
                    options={filteredAnatomicalStructuresOptions}
                    onChange={(e) => onChange(handleAnatomyChanged(e))}
                    onCreateOption={(anatomy) => onChange(handleAnatomyCreated(anatomy, value))}
                    menuPortalTarget={document.body}
                    isValidNewOption={(userInput) =>
                      allowUserDefinedLabels &&
                      validateAnatomicalStructure(userInput, anatomicalStructures)
                    }
                    formatCreateLabel={(anatomy) => formatCreateLabel(anatomy)}
                    formatOptionLabel={(option) => formatOptionLabel(option)}
                    isLoading={anatomicalStructuresLoading}
                    isDisabled={loading}
                  />
                </>
              )}
            />
            <RowWrapper>
              {errors.anatomicalStructures && (
                <ErrorLabel>At least one annotation must be selected!</ErrorLabel>
              )}
            </RowWrapper>
          </FieldWrapper>
        )}
        {showClassificationSelection && (
          <FieldWrapper>
            <Controller
              control={control}
              name="seriesClassifications"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <>
                  Classifications
                  <CreatableSelect
                    value={value.map((classificationAttribute) =>
                      getClassificationAttributeOption(classificationAttribute)
                    )}
                    isClearable={false}
                    isSearchable={true}
                    placeholder={"Select classifications..."}
                    isMulti={true}
                    options={classificationOptions}
                    onChange={(e) => onChange(handleClassificationAttributesChanged(e))}
                    onCreateOption={(classification) =>
                      onChange(handleClassificationCreated(classification, value))
                    }
                    isValidNewOption={(userInput) => {
                      return (
                        userInput !== "" &&
                        allowUserDefinedClassifications &&
                        !classificationAttributes.some(({ text }) => text === userInput)
                      );
                    }}
                    menuPortalTarget={document.body}
                    isLoading={classificationAttributesLoading}
                    isDisabled={loading}
                    formatCreateLabel={formatCreateClassificationLabel}
                    formatOptionLabel={(option) => formatClassificationAttributeOptionLabel(option)}
                    styles={classificationAttributesSelectStyle}
                  />
                </>
              )}
            />
            <RowWrapper>
              {errors.seriesClassifications && (
                <ErrorLabel>At least one classification must be selected!</ErrorLabel>
              )}
            </RowWrapper>
          </FieldWrapper>
        )}
        {showInteractionOptions && (
          <FieldWrapper>
            Options
            <RowWrapper>
              <Controller
                name="labellingOptions"
                control={control}
                rules={{
                  required: true,
                  validate: ({ enableSegmentation, enableDetection }) =>
                    enableSegmentation || enableDetection,
                }}
                render={({ field: { value: labellingOptions, onChange } }) => (
                  <LabellingOptionsWrapper>
                    <FilterCheckbox
                      onChange={(checked) =>
                        onChange({
                          ...labellingOptions,
                          enableSegmentation: checked,
                        })
                      }
                      name={"SEGMENT"}
                      checked={labellingOptions.enableSegmentation}
                      label="Enable Segmentations"
                      disabled={loading}
                    />
                    <FilterCheckbox
                      onChange={(checked) =>
                        onChange({
                          ...labellingOptions,
                          enableDetection: checked,
                        })
                      }
                      checked={labellingOptions.enableDetection}
                      name={"DETECT"}
                      label="Enable Detections"
                      disabled={loading}
                    />
                  </LabellingOptionsWrapper>
                )}
              />
            </RowWrapper>
            <RowWrapper>
              {errors.labellingOptions && (
                <ErrorLabel>At least one option must be enabled!</ErrorLabel>
              )}
            </RowWrapper>
          </FieldWrapper>
        )}
        {showSeriesClassificationOptions && (
          <FieldWrapper>
            Options
            <RowWrapper>
              <Controller
                name="seriesClassificationOptions"
                control={control}
                rules={{
                  validate: {
                    atLeastOne: ({ toggleOnlyOne, toggleAny }) =>
                      toggleOnlyOne || toggleAny || "You must select an option!",
                    onlyOne: ({ toggleOnlyOne, toggleAny }) =>
                      (toggleOnlyOne && !toggleAny) ||
                      (toggleAny && !toggleOnlyOne) ||
                      "Only one option can be selected!",
                  },
                }}
                render={({ field: { value: seriesClassificationOptions, onChange } }) => (
                  <LabellingOptionsWrapper>
                    <FilterCheckbox
                      onChange={(checked) =>
                        onChange({
                          toggleOnlyOne: checked,
                          toggleAny: false,
                        })
                      }
                      name={"ONLY ONE"}
                      checked={seriesClassificationOptions.toggleOnlyOne}
                      label="Allow Only One Classification"
                      disabled={loading}
                    />
                    <FilterCheckbox
                      onChange={(checked) =>
                        onChange({
                          toggleOnlyOne: false,
                          toggleAny: checked,
                        })
                      }
                      checked={seriesClassificationOptions.toggleAny}
                      name={"ANY"}
                      label="Allow Any Classification"
                      disabled={loading}
                    />
                  </LabellingOptionsWrapper>
                )}
              />
            </RowWrapper>
            <RowWrapper>
              {errors.seriesClassificationOptions && (
                <ErrorLabel>
                  {
                    //@ts-ignore
                    errors.seriesClassificationOptions.message as string
                  }
                </ErrorLabel>
              )}
            </RowWrapper>
          </FieldWrapper>
        )}
        {showQualityControlRuleSetOption && (
          <FieldWrapper>
            <Controller
              control={control}
              name="qcSchemaId"
              rules={{ required: true }}
              render={({ field: { onChange } }) => (
                <>
                  Quality Control Rule Set
                  <Select
                    value={qualityControlRuleSetOptions.find(
                      ({ value }) => value === qualityControlRuleSet
                    )}
                    isClearable={false}
                    isSearchable={true}
                    placeholder={"Select Quality Control Rule Set..."}
                    isMulti={false}
                    options={qualityControlRuleSetOptions}
                    onChange={(e) => onChange(handleQualityControlRuleSetChanged(e))}
                    menuPortalTarget={document.body}
                    isLoading={qualityControlRuleSetsLoading}
                    isDisabled={loading}
                    formatCreateLabel={formatUploadRuleSetLabel}
                    formatOptionLabel={(option: QualityControlRuleSetOptionType) => option.label}
                    styles={qualityControlRuleSetSelectStyle}
                  />
                </>
              )}
            />
            <RowWrapper>
              {errors.qcSchemaId && <ErrorLabel>A rule set must be selected!</ErrorLabel>}
            </RowWrapper>
          </FieldWrapper>
        )}
        {
          <FieldWrapper>
            <Controller
              control={control}
              name="restrictedUsers"
              render={({ field: { onChange } }) => (
                <>
                  Restrict To Users
                  <UserSelect
                    users={users}
                    selectedUsers={selectedUsers}
                    setSelectedUsers={(e) => onChange(handleRestrictedUsersChanged(e))}
                    isCreatable={false}
                    placeholder={"Select team members..."}
                    isLoading={loading}
                  />
                  {selectedUsers.length === 0 &&
                    "If the task is not restricted to any users, it will be available to all users within the project."}
                </>
              )}
            />
          </FieldWrapper>
        }
        {
          <FieldWrapper>
            <Controller
              control={control}
              name="restrictedCohorts"
              render={({ field: { onChange } }) => (
                <>
                  Restrict To Cohorts
                  <CohortSelect
                    cohorts={cohorts}
                    selected={selectedCohorts}
                    onSelectedChanged={(e) => onChange(handleRestrictedCohortsChanged(e))}
                  />
                  {selectedCohorts.length === 0 &&
                    "If the task is not restricted to any cohorts, it will be available to all cohorts within the project."}
                </>
              )}
            />
          </FieldWrapper>
        }
        {showLimitToBaselineOption && (
          <FieldWrapper>
            <Controller
              control={control}
              name="limitToBaseline"
              render={({ field: { onChange } }) => (
                <RowWrapper>
                  Apply task to subject's earliest study only.
                  <ToggleSwitch
                    size="small"
                    checked={limitToBaseline}
                    onChange={() => onChange(handleLimitToBaselineChanged(!limitToBaseline))}
                  />
                </RowWrapper>
              )}
            />
          </FieldWrapper>
        )}
        <RowWrapper>
          <InputButton
            type="button"
            name="cancel-button"
            value={"Cancel"}
            background={main.colors.neutral.white}
            color={main.colors.neutral.black}
            onClick={handleCancel}
            disabled={loading}
          />
          <InputDiv
            type="submit"
            color={main.colors.neutral.white}
            background={main.colors.neutral.black}
            width={84}
            disabled={loading}
          >
            <InnerButtonWrapper>
              {loading && <FlexLoading color={main.colors.neutral.white} />}
              Create
            </InnerButtonWrapper>
          </InputDiv>
        </RowWrapper>
      </Wrapper>
    </form>
  );
}

export type WizardSettingsType = {
  showAnatomySelection: boolean;
  showInteractionOptions: boolean;
  enableSegmentation: boolean;
  enableDetection: boolean;
  allowUserDefinedLabels: boolean;
  showClassificationSelection: boolean;
  allowUserDefinedClassifications: boolean;
  showSeriesClassificationOptions: boolean;
  showLimitToBaselineOption: boolean;
  showQualityControlRuleSetOption: boolean;
};

export function validateTaskName(name: string): string | boolean {
  if (TEMPLATE_TASK_DESCRIPTION_NAMES.includes(name)) {
    return "You cannot give tasks the same name as template tasks!";
  }
  return true;
}

export type FormFieldsType = Omit<
  CreateTaskArgumentsType,
  "taskDescription" | "projectIds | isTemplate"
>;
