import { ApolloCache } from "@apollo/client/core";

import { ELLIPTICAL_ROI } from "../../../../../../cornerstone/ToolType";
import { deleteToolDataForRoi } from "../../ViewerPanel/tools/legacy/ToolUtils";
import { getEllipseCacheId } from "./getEllipseCacheId";
import { getRoiCacheId } from "./getRoiCacheId";

export function deleteEllipseFromCache<T>(
  roiId: number,
  ellipseId: number,
  cache: ApolloCache<T>
): void {
  cache.modify({
    id: getRoiCacheId(roiId, cache),
    fields: {
      ellipse() {
        return null;
      },
    },
  });

  const ellipseCacheId = getEllipseCacheId(ellipseId, cache);
  cache.evict({ id: ellipseCacheId });

  deleteToolDataForRoi(ELLIPTICAL_ROI, roiId);
}
