import React, {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useOkForm } from "../../../../../common/components/Dialog/useOkForm";
import { MaskListItemFragmentType } from "./fragments/RoiListItemFragment";
import { useDownloadAndLoadMask } from "./Masks/hooks/useDownloadAndLoadMask";
import { useSetMaskVisible } from "./Masks/hooks/useSetMaskVisible";
import { useUnloadMask } from "./Masks/hooks/useUnloadMask";
import { defaultDownloadState, DownloadState } from "./Masks/types/downloadState";
import { clearAllMasks } from "./Masks/utils/clearAllMasks";

export type Mask = MaskListItemFragmentType & {
  seriesId: number;
  anatomicalStructureId: number;
};

type MaskContextType = {
  onToggleMaskVisibility: (mask: Mask) => Promise<boolean>;
  getMaskDownloadState: (maskId: number) => DownloadState | undefined;
};

const errStr = (name?: string) => {
  return `MaskContext.${name} not initialized, did you forget to wrap your app in a <MaskContextProvider />?`;
};

const MaskContext = createContext<MaskContextType>({
  get onToggleMaskVisibility(): never {
    throw new Error(errStr("onToggleMaskVisibility"));
  },
  get getMaskDownloadState(): never {
    throw new Error(errStr("getMaskDownloadState"));
  },
});

interface MaskContextProviderProps {
  children: React.ReactNode;
}

export function MaskContextProvider({ children }: MaskContextProviderProps): ReactElement {
  const [downloadState, setDownloadState] = useState<Record<number, DownloadState>>({});

  // TODO: we need to limit the number of loaded masks to avoid memory issues when loading too many
  //   see NOTA-364
  const maxActiveMasks = 8;

  const downloadMask = useDownloadAndLoadMask();
  const setMaskVisible = useSetMaskVisible();
  const unloadMask = useUnloadMask();

  useEffect(() => {
    return () => {
      //   clear download state and masks on unmount
      //   TODO we probably want to only clear the masks we loaded, but we dont keep track of masks we loaded beyond the id
      setDownloadState({});
      clearAllMasks();
    };
  }, []);

  const [showTooManyMasksDialog, { dialog }] = useOkForm({
    title: "Maximum Predicted ROIs",
    message: `Currently, only ${maxActiveMasks} predicted ROIs can be displayed at once. Please hide some predicted ROIs before displaying more.`,
  });

  const numberOfActiveMasks = useMemo(
    () => Object.values(downloadState).filter((v) => v.downloaded || v.downloading).length,
    [downloadState]
  );

  const handleSetMaskVisibility = useCallback(
    (mask: Mask, visible: boolean) => {
      const { id } = mask;

      setMaskVisible(mask, visible);

      setDownloadState((downloadState) => ({
        ...downloadState,
        [id]: {
          ...downloadState[id],
          visible,
        },
      }));
    },
    [setMaskVisible]
  );

  const handleDownloadMask = useCallback(
    async (mask: Mask) => {
      const { id } = mask;

      setDownloadState((downloadState) => ({
        ...downloadState,
        [id]: {
          downloaded: false,
          downloading: true,
          error: false,
          visible: false,
        },
      }));

      const success = await downloadMask(mask);

      if (success) {
        setMaskVisible(mask, true);
      }

      setDownloadState((downloadState) => ({
        ...downloadState,
        [id]: {
          downloaded: success,
          downloading: false,
          error: !success,
          visible: success,
        },
      }));

      return success;
    },
    [downloadMask, setMaskVisible]
  );

  const handleToggleMaskVisibility = useCallback(
    async (mask: Mask) => {
      const { id } = mask;

      let maskDownloadState = downloadState[id];
      if (!maskDownloadState) {
        maskDownloadState = defaultDownloadState;
        setDownloadState((prev) => ({
          ...prev,
          [id]: maskDownloadState,
        }));
      }

      const { downloading, downloaded } = maskDownloadState;

      if (!downloaded && !downloading) {
        if (numberOfActiveMasks >= maxActiveMasks) {
          showTooManyMasksDialog(true);
          return false;
        }

        return await handleDownloadMask(mask);
      }

      if (downloaded) {
        handleSetMaskVisibility(mask, false);

        // TODO: we are unloading the masks to avoid memory issues when loading too many
        //   see NOTA-364
        unloadMask(mask);
        setDownloadState((prev) => ({
          ...prev,
          [id]: {
            ...prev[id],
            downloaded: false,
          },
        }));
      }

      return false;
    },
    [
      downloadState,
      numberOfActiveMasks,
      handleDownloadMask,
      showTooManyMasksDialog,
      handleSetMaskVisibility,
      unloadMask,
    ]
  );

  const getMaskDownloadState = useCallback(
    (maskId: number) => {
      return downloadState[maskId];
    },
    [downloadState]
  );

  return (
    <MaskContext.Provider
      value={{
        onToggleMaskVisibility: handleToggleMaskVisibility,
        getMaskDownloadState,
      }}
    >
      {dialog}
      {children}
    </MaskContext.Provider>
  );
}

export function useMaskProvider() {
  return useContext(MaskContext);
}
