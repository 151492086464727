import { useFlags } from "launchdarkly-react-client-sdk";
import { AnnotationsExportButton } from "nota-predict-web/src/Annotate/components/Manage/ExportButton";
import { useContext } from "react";
import styled from "styled-components";

import { CurrentUserAvatar } from "../../../../../../common/components/Avatar/CurrentUserAvatar";
import { main } from "../../../../../../common/theme/main";
import { PatientContext } from "../../../../../../Dashboard/components/Settings/Patients/Viewer/PatientContext";
import { PatientTaskPair } from "../../../../../../DataManagement/AnnotationsExport/PatientTaskPair";
import { TaskContext } from "../../../../../TaskContext";
import { FilterPopover } from "./FilterPopover";
import { CompleteTaskButton } from "./SaveChecks/SaveTaskButton";
import { SortPopover } from "./SortPopover";
import { TaskSelector } from "./TaskSelector";

const Wrapper = styled.div`
  background: ${main.colors.background.secondary};
  padding: 8px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`;

export function TaskToolbar(): JSX.Element {
  const { task, selectedTaskAssignmentId } = useContext(TaskContext);
  const { id: patientId } = useContext(PatientContext);

  const flags = useFlags();
  const showAnnotationExport = flags["showNotaExportAnnotations"];

  const showUnimplementedButtons = false;

  const patientsTaskPairs: PatientTaskPair[] = [
    {
      patientId,
      taskId: task.id,
    },
  ];

  return (
    <>
      <Wrapper>
        <TaskSelector task={task} patientId={patientId} />
        {selectedTaskAssignmentId && <CompleteTaskButton />}
        {showUnimplementedButtons && <FilterPopover />}
        {showUnimplementedButtons && <SortPopover />}
        {showAnnotationExport && (
          <AnnotationsExportButton
            patientTaskPairs={patientsTaskPairs}
            variant="icon"
            tooltip={"Export annotations from this task"}
          />
        )}
        <CurrentUserAvatar showProfileOnClick={true} />
      </Wrapper>
    </>
  );
}
