import { useContext } from "react";

import { TaskContext } from "../../../../../TaskContext";
import { SeriesFlagType } from "../../../../../types/SeriesFlagType";
import { SeriesListItemFragmentType } from "../../AnnotationPanel/hooks/useStudiesList";

export function useTaskSeriesFlags({ seriesFlags }: SeriesListItemFragmentType): SeriesFlagType[] {
  const {
    task: { id: taskId },
  } = useContext(TaskContext);

  return seriesFlags.filter((seriesFlag) => seriesFlag.taskId === taskId);
}
