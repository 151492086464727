import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { QueryResult } from "@apollo/client/react/types/types";

export type ClassificationAttributeType = {
  text: string;
  readonly: boolean;
};

export const GET_CLASSIFICATION_ATTRIBUTES_QUERY = gql`
  query GetClassificationAttributes {
    classificationAttributes: classification_attribute(where: { readonly: { _eq: false } }) {
      text
      readonly
    }
  }
`;

export type DataType = {
  classificationAttributes: ClassificationAttributeType[];
};

export function useClassificationAttributes(): QueryResult<DataType> {
  return useQuery<DataType>(GET_CLASSIFICATION_ATTRIBUTES_QUERY);
}
