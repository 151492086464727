import React, { ReactElement } from "react";
import styled from "styled-components";

import { ReactComponent as AltisLogoIcon } from "../../assets/svgs/AltisLogo.svg";
import { main } from "../theme/main";
import { SvgIcon } from "./icons/SvgIcon";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 79px;
  height: 16px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.secondary.secondary};
`;

const LogoWrapper = styled.div`
  margin-right: 2px;
`;

export const NotaLogo = (): ReactElement => {
  return (
    <Wrapper>
      <LogoWrapper>
        <SvgIcon icon={AltisLogoIcon} color={main.colors.secondary.secondary} />
      </LogoWrapper>
      Nota
    </Wrapper>
  );
};
