import { ProjectLayoutType } from "../Layout";
import { getPageRouteStr } from "../utils/getPageRouteStr";
import { useFindPage } from "./useFindPage";

export function useLayoutDefaultRoute(layout: ProjectLayoutType, url: string): string | undefined {
  const defaultPage = useFindPage()(layout, ({ defaultPage }) => !!defaultPage);
  if (!defaultPage) {
    return undefined;
  }

  return getPageRouteStr(defaultPage, url);
}
