import cornerstoneTools from "@altis-labs/cornerstone-tools";

import { ROI } from "../../../../../../../cornerstone/ToolType";
import { ContourDiametersType } from "../../../../../../types/ContourDiametersType";

export type ContourDiametersDataType = {
  imageId: string;
} & ContourDiametersType;

export function getRoiContourDiameters(roiId: number): ContourDiametersDataType[] {
  const toolStates = cornerstoneTools.globalImageIdSpecificToolStateManager.toolState;

  const allDiameters = [];
  for (const imageId of Object.keys(toolStates)) {
    if (!toolStates[imageId][ROI]) {
      continue;
    }

    const { data } = toolStates[imageId][ROI];
    for (const toolData of data) {
      const {
        invalidated,
        roi: { id },
        diameters,
      } = toolData;
      if (roiId !== id || invalidated || diameters === undefined) {
        continue;
      }

      allDiameters.push({ imageId, ...diameters });
    }
  }

  return allDiameters;
}
