import { useAuth0 } from "@auth0/auth0-react";
import _mixpanel, { OverridedMixpanel } from "mixpanel-browser";
import { useCallback, useMemo } from "react";

import { BASE_URL } from "../auth0/AuthProvider";
import { silentTry } from "./index";

interface MixpanelInstance extends OverridedMixpanel {
  __loaded: boolean;
  cookie?: {
    campaign_params_saved?: boolean;
    cookie_domain?: string;
    cross_site?: boolean;
    cross_subdomain?: boolean;
    default_expiry?: number;
    disabled?: boolean;
    expire_days?: number;
    name?: string;
    props?: {
      distinct_id?: string;
      $device_id?: string;
      $initial_referrer?: string;
      $initial_referring_domain?: string;
      $user_id?: string;
      $os?: string;
      $browser?: string;
      $referrer?: string;
    } & {
      [key: string]: string;
    };
    secure?: boolean;
  };
}

const mixpanel = _mixpanel as MixpanelInstance;

export const initMixpanel = () => {
  if (!window._env_.REACT_APP_MIXPANEL_TOKEN) {
    return;
  }
  mixpanel.init(window._env_.REACT_APP_MIXPANEL_TOKEN, {
    // @ts-ignore
    track_pageview: true,
  });
};

export const useMixpanel = () => {
  const { user } = useAuth0();
  const uniqueId = `${user?.email || user?.sub || "anonymous"} (${BASE_URL})`;

  const isReady = mixpanel?.__loaded && mixpanel?.cookie?.disabled !== true;

  const userPayload = useMemo(
    () => ({
      $name: user?.name,
      $email: user?.email,
      $auth0_ID: user?.sub,
      $avatar: user?.picture,
    }),
    [user]
  );

  const createOrUpdateUser = useCallback(() => {
    if (!user || !isReady) {
      return;
    }

    silentTry(() => mixpanel.people.set_once(userPayload));
    silentTry(() =>
      mixpanel.people.set_once({
        "First login date": new Date().toISOString(),
      })
    );
    silentTry(() =>
      mixpanel.people.set_once({
        "Base URL": BASE_URL,
      })
    );
  }, [user, isReady, userPayload]);

  const identify = useCallback(() => {
    if (!user || !isReady) {
      return;
    }

    silentTry(() => mixpanel.identify(uniqueId));
    createOrUpdateUser();
  }, [user, isReady, uniqueId, createOrUpdateUser]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const track = useCallback(
    !user || !isReady
      ? // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      : (eventName: string, properties?: Record<string, any>) =>
          mixpanel.track(eventName, properties),
    []
  );

  const trackPageview = useCallback(() => {
    // @ts-ignore
    silentTry(() => mixpanel.track_pageview());
  }, []);

  return { identify, track, trackPageview };
};
