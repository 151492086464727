import React, { FC } from "react";
import styled from "styled-components";

import { useProjectPatientTaskRows } from "../../../Annotate/components/Manage/hooks/useProjectPatientTaskRows";
import { TaskWorklistTable } from "../../../Annotate/components/TaskWorklist/TaskWorklistTable";
import { orderByAssigned } from "../../../Annotate/components/TaskWorklist/TaskWorklistTableRowType";
import { Card } from "../../../common/components/cards/Card";
import { useSubjectIdParam } from "../../../Project/utils/useSubjectIdParam";
import { GraphCardProps } from "../../common/utils/GraphCardProps";

const TableWrapper = styled.div`
  min-height: 175px;
`;

export const PatientTasksCard: FC<GraphCardProps> = ({ onClickHeader }: GraphCardProps) => {
  const subjectId = useSubjectIdParam();

  const { patientsTasks } = useProjectPatientTaskRows(false);
  const patientTasks = patientsTasks?.filter(({ id }) => id === subjectId) ?? [];

  const sortedRows = [...patientTasks].sort(orderByAssigned);

  return (
    <Card
      title={"Tasks"}
      content={
        <TableWrapper>
          <TaskWorklistTable rows={sortedRows} globalProgress openTaskViewOnly />
        </TableWrapper>
      }
      onClickHeader={onClickHeader}
    />
  );
};
