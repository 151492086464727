import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { MutationTuple } from "@apollo/client/react/types/types";

import { FlattedContourToolDataType } from "../../../../../../../types/FlattedContourToolDataType";
import { ROI_RECIST_EVALUATION_FRAGMENT } from "../../../../AnnotationPanel/fragments/RoiRecistEvaluationFragment";
import { updateRoiRecistEvaluationsInCache } from "../../../../AnnotationPanel/hooks/updateRoiRecistEvaluationsInCache";
import {
  Data as UpsertRoiRecistEvaluationData,
  INTERNAL_MUTATION as UPSERT_ROI_RECIST_EVALUATION_MUTATION,
  Variables as UpsertRoiRecistEvaluationVariables,
} from "./useUpsertRoiRecistEvaluation";

type ContourMutationFragmentType = {
  id: number;
  roiId: number;
};

const CONTOUR_MUTATION_FRAGMENT = gql`
  fragment ContourUpdateMutation on contour {
    id: contour_id
    roiId: roi_id
  }
`;

const MUTATION = gql`
  mutation UpdateContour(
    $toolData: jsonb!
    $version: String!
    $roiId: Int!
    $instanceId: Int!
    $roiRecistEvaluations: [roi_recist_evaluations_insert_input!]!
  ) {
    update_contour(
      _set: { tool_data: $toolData, version: $version }
      where: {
        _and: [
          { roi_id: { _eq: $roiId } }
          { instance_id: { _eq: $instanceId } }
        ]
      }
    ) {
      returning {
        ...ContourUpdateMutation
      }
    }
    ${UPSERT_ROI_RECIST_EVALUATION_MUTATION}
  }
  ${CONTOUR_MUTATION_FRAGMENT}
  ${ROI_RECIST_EVALUATION_FRAGMENT}
`;

type Variables = {
  toolData: FlattedContourToolDataType[];
  version: string;
  roiId: number;
  instanceId: number;
} & UpsertRoiRecistEvaluationVariables;

type Data = {
  update_contour: { returning: ContourMutationFragmentType[] };
} & UpsertRoiRecistEvaluationData;

export function useUpdateContour(): MutationTuple<Data, Variables> {
  return useMutation<Data, Variables>(MUTATION, {
    update(cache, { data }) {
      if (!data) {
        throw new Error("Something went wrong updating the cache after updating a contour");
      }
      const {
        insert_roi_recist_evaluations: { roiRecistEvaluations },
      } = data;
      updateRoiRecistEvaluationsInCache(roiRecistEvaluations, cache);
    },
  });
}
