import { useParams } from "react-router";

interface TaskRouteProps {
  projectId: string;
  patientId: string;
  taskId: string;
}

interface TaskPageParams {
  projectId: number;
  patientId: number;
  taskId: number;
}

export function useTaskPageParams(): TaskPageParams {
  const {
    projectId: rawProjectId,
    patientId: rawPatientId,
    taskId: rawTaskId,
  } = useParams<TaskRouteProps>();

  const projectId = parseInt(rawProjectId);
  const patientId = parseInt(rawPatientId);
  const taskId = parseInt(rawTaskId);

  return {
    projectId,
    taskId,
    patientId,
  };
}
