import { useContext } from "react";

import { ProjectContext } from "../../../Project/contexts/ProjectContext";
import { getInheritedRoles } from "../../components/UserManagement/utils/roleHierarchy";
import { ADMIN, GlobalRoleType, UserRoleType } from "../../types/UserRoleType";
import { useCurrentUserRoles } from "./useCurrentUserRoles";

export function useCurrentUserCanDeferred(): (
  allowedRoles: (GlobalRoleType | UserRoleType)[]
) => boolean {
  const { projectId } = useContext(ProjectContext);

  const roles = useCurrentUserRoles(projectId);

  const inheritedRoles = getInheritedRoles(roles);

  return (allowedRoles) => {
    const adminBypassedAllowedRoles = allowedRoles.length === 0 ? [ADMIN] : allowedRoles;

    return inheritedRoles.some((role) => adminBypassedAllowedRoles.includes(role));
  };
}
