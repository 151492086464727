import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client/core";

import handleApolloError from "../../../../common/utils/handleApolloError";
import { TEMPLATE_TASK_DESCRIPTION_NAMES } from "../../../enums/TaskDescriptionEnums";
import { TaskDescriptionType as TaskDescriptionDataType } from "../../../types/TaskDescriptionType";

export const GET_TASK_DESCRIPTIONS_QUERY = gql`
  query GetTaskDescriptions($where: task_description_bool_exp!) {
    task_description(where: $where) {
      data
      id
      tasks {
        taskId: id
        projectId: project_id
      }
      isTemplate: is_template
    }
  }
`;

export type TaskDescriptionType = {
  id: number;
  data: TaskDescriptionDataType;
  tasks: {
    taskId: number;
    projectId: number;
  }[];
  isTemplate: boolean;
};

type DataType = {
  task_description: TaskDescriptionType[];
};

type VariablesType = {
  where: {
    _or: {
      data: {
        _contains: {
          name: string;
        };
      };
    }[];
  };
};

export type ReturnType = {
  taskDescriptions: TaskDescriptionType[] | undefined;
  loading: boolean;
};

export function useTaskDescriptions(): ReturnType {
  const { data, loading, error } = useQuery<DataType, VariablesType>(GET_TASK_DESCRIPTIONS_QUERY, {
    variables: getVariables(TEMPLATE_TASK_DESCRIPTION_NAMES),
  });

  if (error) handleApolloError(error);

  const { task_description = [] } = data || {};

  return {
    taskDescriptions: task_description,
    loading,
  };
}

function getVariables(taskDescriptionNames: string[]): VariablesType {
  return {
    where: {
      _or: taskDescriptionNames.map((name) => ({
        data: {
          _contains: {
            name,
          },
        },
      })),
    },
  };
}
