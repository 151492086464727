import dayjs, { Dayjs } from "dayjs";

export function enumerateMonths(start: Dayjs, end: Dayjs): Dayjs[] {
  const startMonth = start.startOf("month");

  const endMonth = end.startOf("month");

  const numberOfMonths = endMonth.diff(startMonth, "month");

  const months: dayjs.Dayjs[] = [];

  for (let i = 0; i <= numberOfMonths; i++) {
    months.push(startMonth.add(i, "month"));
  }

  return months;
}
