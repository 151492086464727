import { RoiListItemFragmentType } from "../fragments/RoiListItemFragment";
import { useIsSeriesIdBaseline } from "./useIsSeriesIdBaseline";

type ReturnType = (roi: RoiListItemFragmentType) => boolean;

export function useIsBaselineRoi(): ReturnType {
  const isSeriesIdBaseline = useIsSeriesIdBaseline();

  return ({ series: { id } }) => {
    return isSeriesIdBaseline(id);
  };
}
