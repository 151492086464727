export const AJCC_I = "I";
export const AJCC_IA = "IA";
export const AJCC_IB = "IB";
export const AJCC_II = "II";
export const AJCC_IIA = "IIA";
export const AJCC_IIB = "IIB";
export const AJCC_III = "III";
export const AJCC_IIIA = "IIIA";
export const AJCC_IIIB = "IIIB";
export const AJCC_IIIC = "IIIC";
export const AJCC_IV = "IV";
export const AJCC_IVA = "IVA";
export const AJCC_IVB = "IVB";

export type AjccStageType =
  | typeof AJCC_I
  | typeof AJCC_IA
  | typeof AJCC_IB
  | typeof AJCC_II
  | typeof AJCC_IIA
  | typeof AJCC_IIB
  | typeof AJCC_III
  | typeof AJCC_IIIA
  | typeof AJCC_IIIB
  | typeof AJCC_IIIC
  | typeof AJCC_IV
  | typeof AJCC_IVA
  | typeof AJCC_IVB;

const groups: AjccStageType[][] = [
  [AJCC_I, AJCC_IA, AJCC_IB],
  [AJCC_II, AJCC_IIA, AJCC_IIB],
  [AJCC_III, AJCC_IIIA, AJCC_IIIB, AJCC_IIIC],
  [AJCC_IV, AJCC_IVA, AJCC_IVB],
];

export function getSimilarAjccStages(ajccStage: AjccStageType): AjccStageType[] {
  const group = groups.find((group) => group.includes(ajccStage));
  if (group === undefined) {
    return [];
  }
  return group;
}
