import React, { FC } from "react";

import { FlexLoading } from "../../../common/components/Loading";
import { main } from "../../../common/theme/main";
import handleApolloError from "../../../common/utils/handleApolloError";
import { useSubjectQuery } from "../../common/hooks/useSubjectQuery";
import { generateKaplanMeierData } from "../utils/generateKaplanMeierData";
import { kaplanMeierAttributes } from "./kaplanMeierAttributes";
import { TinyKaplanChart } from "./TinyKaplanChart";

interface TinyKaplanChartContainerProps {
  projectId: number;
}

export const TinyKaplanChartContainer: FC<TinyKaplanChartContainerProps> = ({
  projectId,
}: TinyKaplanChartContainerProps) => {
  const { subjects, loading, error } = useSubjectQuery(projectId);

  if (loading)
    return <FlexLoading size={36} thickness={4} color={main.colors.actionPrimary.hover} />;
  if (error) handleApolloError(error);
  if (!subjects) throw new Error("Subject null with no apollo error");

  const kaplanMeierData = generateKaplanMeierData(subjects);

  return <TinyKaplanChart data={kaplanMeierData} attributes={kaplanMeierAttributes} />;
};
