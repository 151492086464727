import React from "react";
import styled from "styled-components";

import { GenericSingleSelect } from "../../../DataManagement/Upload/components/GenericSingleSelect";
import { ManagerOptionType } from "./types/ManagerOptionType";

const ProjectLabelWrapper = styled.div`
  display: flex;
  gap: 3px;
`;

const ProjectLabelText = styled.span`
  font-weight: bold;
`;

interface ProjectReassignmentProps {
  name: string;
  managerOptions: ManagerOptionType[];
  selectedValue: ManagerOptionType;
  onChangeManager: (projectId: number, manager: ManagerOptionType | null) => void;
  projectId: number;
}

export function ProjectReassignment({
  name,
  managerOptions,
  selectedValue,
  onChangeManager,
  projectId,
}: ProjectReassignmentProps): JSX.Element {
  return (
    <>
      <ProjectLabelWrapper>
        Project:<ProjectLabelText>{name}</ProjectLabelText>
      </ProjectLabelWrapper>
      <GenericSingleSelect
        values={managerOptions}
        selected={selectedValue}
        onSelectedChanged={(e) => {
          selectedValue = e || managerOptions[0];
          onChangeManager(projectId, e);
        }}
        getOption={(manager: ManagerOptionType) => ({
          label: manager.label,
          value: manager,
        })}
        menuPortalTarget={document.body}
      />
    </>
  );
}
