import { useContext } from "react";

import { ViewerConfigType } from "../../../../../common/store/annotatePage/ViewerConfigType";
import { useIsLesionViewOnly } from "../AnnotationPanel/hooks/useIsLesionViewOnly";
import { useSelectedLesion } from "./selection/useSelectedLesion";
import { useSelectedRoiViewOnly } from "./useSelectedRoiViewOnly";
import { useViewerSeriesViewOnly } from "./useViewerSeriesViewOnly";
import { ViewerContext } from "./Viewer/ViewerContext";

export function useViewerViewOnly(viewerConfig: ViewerConfigType): boolean | undefined {
  const { viewOnly } = useContext(ViewerContext);

  const selectedLesion = useSelectedLesion();

  const { canAnnotate } = useIsLesionViewOnly(selectedLesion);

  const isSelectedRoiViewOnly = useSelectedRoiViewOnly(viewerConfig);

  const isSeriesViewOnly = useViewerSeriesViewOnly(viewerConfig);

  return !canAnnotate || isSelectedRoiViewOnly || isSeriesViewOnly || viewOnly;
}
