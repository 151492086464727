import React, { FC } from "react";

import { Card } from "../../../common/components/cards/Card";
import RTOGDetailPanelContainer from "../../Longitudinal/components/RTOGDetailPanelContainer";
import { GraphCardProps } from "../utils/GraphCardProps";

export const SubjectDetailsCard: FC<GraphCardProps> = ({
  onClickHeader,
  actionButtons,
}: GraphCardProps) => {
  return (
    <Card
      title={"Subject Details"}
      content={<RTOGDetailPanelContainer />}
      onClickHeader={onClickHeader}
      actionButtons={actionButtons}
    />
  );
};
