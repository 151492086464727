import { OkCancelForm, OkCancelFormProps } from "./OkCancelForm";
import { useDialogReturnType } from "./useDialog";
import { useFormDialog } from "./useFormDialog";

export function useOkCancelForm(props: OkCancelFormProps): useDialogReturnType {
  const { title, showCloseButton } = props;
  return useFormDialog({
    children: OkCancelForm,
    label: title,
    showCloseButton: showCloseButton ?? false,
    reactModalProps: {
      shouldCloseOnOverlayClick: false,
      shouldCloseOnEsc: false,
    },
    props,
  });
}
