import cornerstone from "cornerstone-core";

import { CornerstoneImageType } from "./CornerstoneImageType";

export async function getImageFromCornerstoneCache(imageId: string): Promise<CornerstoneImageType> {
  let image = cornerstone.imageCache.imageCache[imageId]?.image;

  // its possible the image is not in the cache
  // if we preload lots of images, some will get purged depending on what loaded first
  // here we cache the image if it's not found
  if (!image) {
    image = await cornerstone.loadAndCacheImage(imageId, {});
  }

  if (!image) {
    throw new Error(
      `Unable to retrieve image with imageId ${imageId} from cornerstone image cache`
    );
  }

  return image;
}
