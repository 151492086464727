import {
  ANATOMY,
  ANNOTATE_ALLOW_LESIONS,
  ANNOTATE_ALLOW_ORGANS,
  ANNOTATE_CALCULATE_CONTOUR_MEASUREMENTS,
  ANNOTATE_CAN_DRAW_MISSING_ROIS,
  ANNOTATE_INCLUDE_OTHER_LESIONS,
  ANNOTATE_LIMIT_TO_BASELINE,
  ANNOTATE_SERIES_SHOW_FOLLOW_UP_LABELS,
  ANNOTATE_SERIES_SHOW_TUMOUR_RESPONSE,
  ANNOTATE_SHOW_PREDICTED_ROIS,
  BENIGN,
  CHECK_MINIMUM_CONTOUR,
  CHECK_MISSING_CLASSIFICATIONS,
  CHECK_MISSING_CONTOURS,
  CHECK_MISSING_ORGANS,
  CHECK_MISSING_REQUIRED_QC_FIELDS,
  CHECK_MISSING_ROIS,
  CONSTRAINT_ALL,
  CONSTRAINT_ANY,
  CONSTRAINT_ONLY_ONE,
  DEFAULT,
  MALIGNANT,
  MEASURABLE,
  METASTASIS,
  NEW_FINDING,
  NODE,
  NON_MEASURABLE,
  NON_TARGET,
  NOT_PRESENT,
  OBJECT_CONTOUR,
  OBJECT_DETECTION,
  OTHER,
  PRIMARY,
  TARGET,
  TOO_SMALL_TO_MEASURE,
  TUMOUR_CLASSIFICATION,
  TUMOUR_HU,
  TUMOUR_LAD,
  TUMOUR_SAD,
  TUMOUR_TIMEPOINT_BURDEN,
  TUMOUR_TOTAL_BURDEN,
  TUMOUR_VOLUME,
  TYPE,
  TYPE_DEFAULT,
  TYPE_RECIST_1_1,
} from "../enums/TaskDescriptionEnums";

export type TaskDescriptionTypeType = typeof TYPE_RECIST_1_1 | typeof TYPE_DEFAULT;

export type AnnotateRulesType =
  | typeof ANNOTATE_ALLOW_LESIONS
  | typeof ANNOTATE_ALLOW_ORGANS
  | typeof ANNOTATE_INCLUDE_OTHER_LESIONS
  | typeof ANNOTATE_CALCULATE_CONTOUR_MEASUREMENTS
  | typeof ANNOTATE_CAN_DRAW_MISSING_ROIS
  | typeof ANNOTATE_LIMIT_TO_BASELINE
  | typeof ANNOTATE_SHOW_PREDICTED_ROIS;

export type StatsType =
  | typeof TUMOUR_TOTAL_BURDEN
  | typeof TUMOUR_TIMEPOINT_BURDEN
  | typeof TUMOUR_VOLUME
  | typeof TUMOUR_HU
  | typeof TUMOUR_LAD
  | typeof TUMOUR_SAD;

export const statsDisplayName: Record<StatsType, string> = {
  [TUMOUR_TOTAL_BURDEN]: "",
  [TUMOUR_TIMEPOINT_BURDEN]: "",
  [TUMOUR_VOLUME]: "VOL",
  [TUMOUR_HU]: "HU",
  [TUMOUR_LAD]: "LAD",
  [TUMOUR_SAD]: "SAD",
};

type AnnotateSeriesRulesType =
  | typeof ANNOTATE_SERIES_SHOW_FOLLOW_UP_LABELS
  | typeof ANNOTATE_SERIES_SHOW_TUMOUR_RESPONSE;

export type ClassificationModeType = typeof DEFAULT | ClassificationValuesType;

export type ClassificationsTypeType =
  | typeof ANATOMY
  | typeof TARGET
  | typeof TYPE
  | typeof TUMOUR_CLASSIFICATION;

export type ConstraintType =
  | typeof CONSTRAINT_ALL
  | typeof CONSTRAINT_ANY
  | typeof CONSTRAINT_ONLY_ONE;

export type ClassificationValuesType =
  | typeof TARGET
  | typeof NON_TARGET
  | typeof PRIMARY
  | typeof METASTASIS
  | typeof NODE
  | typeof OTHER
  | typeof BENIGN
  | typeof MALIGNANT
  | typeof ANATOMY
  | typeof NON_MEASURABLE
  | typeof MEASURABLE
  | typeof NOT_PRESENT
  | typeof TOO_SMALL_TO_MEASURE
  | typeof NEW_FINDING
  | string;

export type ClassificationObjectsType = {
  value: ClassificationValuesType;
  readOnly: boolean;
  displayName?: string;
};

export type GroupingType = {
  value: ClassificationValuesType;
  displayName: string;
  hideIfEmpty: boolean;
  showCount: boolean;
};

type TimepointObjectsTypeType = typeof OBJECT_CONTOUR | typeof OBJECT_DETECTION;

// TODO: Collapse this into TaskCheckType
export type LesionTaskCheckType =
  | typeof CHECK_MISSING_CONTOURS
  | typeof CHECK_MISSING_CLASSIFICATIONS
  | typeof CHECK_MINIMUM_CONTOUR
  | typeof CHECK_MISSING_ROIS;

export type TaskCheckType = typeof CHECK_MISSING_ORGANS | typeof CHECK_MISSING_REQUIRED_QC_FIELDS;

export type ChecksType = {
  task: TaskCheckType[];
  lesion: LesionTaskCheckType[];
};

export type TaskDescriptionType = {
  name: string;
  type: TaskDescriptionTypeType;
  options?: TaskOptionsType[];
  checks?: ChecksType;
  lesion?: LesionType;
  patient?: PatientType;
};

export type TaskOptionsType<TArgs = unknown> = {
  rule: AnnotateRulesType;
  enabled: boolean;
  args?: TArgs;
};

export type CanDrawMissingRoisArgsType = {
  classifications: ClassificationValuesType[];
};

type PatientType = {
  study: StudyType;
};

type StudyType = {
  series: SeriesType;
};

type SeriesType = {
  stats?: StatsType[];
  options?: SeriesOptionsType[];
  classifications?: ClassificationsType[];
};

type SeriesOptionsType = {
  rule: AnnotateSeriesRulesType;
  enabled: boolean;
};

type LesionType = {
  stats?: StatsType[];
  grouping?: GroupingType[];
  classifications?: ClassificationsType[];
  timepoint: TimepointType;
};

export type ClassificationsType = {
  constraint: ConstraintType;
  mode?: ClassificationModeType;
  type?: ClassificationsTypeType;
  values: ClassificationsType[] | ClassificationObjectsType[];
};

type TimepointType = {
  stats?: StatsType[];
  objects: TimepointObjectsType[];
  options?: TimepointOptionsType[];
};

type TimepointObjectsType = {
  type: TimepointObjectsTypeType;
  constraints?: ConstraintType[];
};

export type TimepointOptionsModeType = ClassificationModeType | ClassificationValuesType;

export type TimepointOptionsType = {
  rule: ClassificationValuesType;
  enabled: boolean;
  displayName: string;
  modes: TimepointOptionsModeType[];
  showWhenExists: boolean;
  showWhenDoesNotExist: boolean;
  showAtBaseline: boolean;
  showAtFollowUp: boolean;
  showWhenClassified: boolean;
  hideIfMeasurementsExist: boolean;
  isViewOnly: boolean;
  disableMeasurements: boolean;
  triggersLesionClassification: ClassificationValuesType[];
  preventsLesionClassification: ClassificationValuesType[];
};
