import React, { FC, forwardRef, useRef } from "react";
import styled from "styled-components";

import handleApolloError from "../../../utils/handleApolloError";
import { LoadingCard } from "../../Loading";
import { Table, TableProps } from "../../Table/Table";
import { TableControlsType } from "../../Table/TableControlsType";
import { TableDataType } from "../../Table/TableDataType";
import { anonymizationMethods } from "./Contexts/AnonymizationConfigurationContext";
import {
  AnonymizationTableRowType,
  useAllAnonymizationMethods,
} from "./hooks/useAllAnonymizationMethods";
import { useAnonymizationTableColumns } from "./useAnonymizationTableColumns";

const TableWrapper = styled.div`
  height: 250px;
`;

type AnonymizationTableType = TableDataType & AnonymizationTableRowType;

export const AnonymizationMethodsCard: FC = () => {
  const { data, loading, error } = useAllAnonymizationMethods([...anonymizationMethods]);

  const tableRef = useRef<TableControlsType<AnonymizationTableType>>(null);
  const columns = useAnonymizationTableColumns();
  const memoizedRows = React.useMemo<AnonymizationTableRowType[]>(
    () => data?.anonymizationMethods ?? [],
    [data]
  );

  if (loading) {
    return <LoadingCard />;
  }

  if (error) handleApolloError(error);

  if (!data) {
    throw new Error("Anonymization data is undefined!");
  }

  return (
    <TableWrapper>
      <TableWithRef columns={columns} data={memoizedRows} ref={tableRef} enableSelect={false} />
    </TableWrapper>
  );
};
const TableWithRef = forwardRef<
  TableControlsType<AnonymizationTableType>,
  TableProps<AnonymizationTableType>
>((props, ref) => {
  return <Table {...props} forwardedRef={ref} />;
});
TableWithRef.displayName = "TableWithRef";
