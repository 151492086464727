import axios, { CancelTokenSource } from "axios";

import { UploadMetadataType } from "./UploadMetadataType";

export async function uploadFiles(
  files: FileList,
  metadata: UploadMetadataType,
  onProgress: (e: ProgressEvent) => void,
  onCancelled: (message: string) => void,
  onError: (e: Error) => void,
  cancelToken: CancelTokenSource,
  getAuthToken: () => Promise<string>
): Promise<void> {
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    formData.append("files", file, file.name);
  }

  formData.append("metadata", JSON.stringify(metadata));

  const apiUrl = window._env_.REACT_APP_CELERY_API_URL;
  const uploadUrl = apiUrl + "/upload/";

  const token = await getAuthToken();

  try {
    const result = await axios.post(uploadUrl, formData, {
      onUploadProgress: onProgress,
      cancelToken: cancelToken.token,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.debug(result.data);
  } catch (e) {
    if (!(e instanceof Error)) {
      throw new Error("Unknown error type received in upload files post operation ");
    }

    if (axios.isCancel(e)) {
      onCancelled(e.message);
    } else {
      onError(e);
    }
  }
}
