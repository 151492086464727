import { FollowUpType } from "../types/FollowUpType";

export function getFollowUpVolumetricTumourBurden(followUp: FollowUpType): number | null {
  const { tumourBurdens } = followUp;
  if (tumourBurdens.length < 1) {
    return null;
  }

  const validVolumetricBurdens = tumourBurdens.flatMap(({ volumetric }) =>
    volumetric !== null ? [volumetric] : []
  );
  if (validVolumetricBurdens.length < 1) {
    return null;
  }

  // we divide by 1000 to convert from mm3 to cm3
  return validVolumetricBurdens.reduce((total, burden) => total + burden, 0) / 1000;
}
