import React, { forwardRef, useRef } from "react";
import { Column } from "react-table";
import styled from "styled-components";

import { ActionButton } from "../../../Annotate/components/Manage/ActionButton";
import { ReactComponent as AddIcon } from "../../../assets/svgs/PlusOutlineFilled.svg";
import { SiteType } from "../../types/SiteType";
import { Table, TableProps } from "../Table/Table";
import { TableControlsType } from "../Table/TableControlsType";
import { useCreateSiteDialog } from "./Dialogs/useCreateSiteDialog";
import { SiteActionCallbacksType } from "./SiteActionCallbacksType";
import { SiteTableRowType } from "./SiteTableRowType";
import { useProjectSitesTableColumns } from "./useProjectSitesTableColumns";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ActionPanelWrapper = styled.div`
  flex: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TableWrapper = styled.div`
  flex: 1;
`;

const ButtonsWrapper = styled.div`
  flex: 0;
  display: flex;
  padding: 4px;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

export type EditSitesOptions = {
  allowCreateSite: boolean;
  allowRemoveSite: boolean;
  allowEditSite: boolean;
};

export interface EditProjectSitesProps {
  sites: SiteType[];
  actionCallbacks: SiteActionCallbacksType;
  options: EditSitesOptions;
}

export function EditProjectSites({
  sites,
  actionCallbacks,
  options: { allowCreateSite, allowRemoveSite, allowEditSite },
}: EditProjectSitesProps): JSX.Element {
  const ref = useRef<TableControlsType<SiteTableRowType>>(null);
  const { onCreated } = actionCallbacks ?? {
    onCreated: undefined,
  };

  if (!onCreated) {
    throw new Error("onCreated not defined in EditProjectSites");
  }

  const handleConfirmCreateSites = (sites: SiteType[]) => {
    onCreated(sites);
  };

  const [setCreateSiteDialogOpen, { dialog }] = useCreateSiteDialog(handleConfirmCreateSites);

  const allColumns = useProjectSitesTableColumns(actionCallbacks, allowRemoveSite, allowEditSite);

  const memoizedColumns = React.useMemo<Column<SiteType>[]>(() => allColumns, [allColumns]);
  const memoizedRows = React.useMemo<SiteType[]>(() => sites, [sites]);

  const handleClickCreateSite = () => {
    if (!allowCreateSite) {
      throw new Error("handleClickCreateSite called by allowCreateSite is false");
    }

    setCreateSiteDialogOpen(true);
  };

  return (
    <>
      {dialog}
      <Wrapper>
        {allowCreateSite && (
          <ActionPanelWrapper>
            <ButtonsWrapper>
              <ActionButton
                icon={AddIcon}
                label={"Create Site"}
                onClick={handleClickCreateSite}
                tooltip={"Add a new site to this project"}
              />
            </ButtonsWrapper>
          </ActionPanelWrapper>
        )}
        <TableWrapper>
          <TableWithRef ref={ref} columns={memoizedColumns} data={memoizedRows} />
        </TableWrapper>
      </Wrapper>
    </>
  );
}

const TableWithRef = forwardRef<TableControlsType<SiteTableRowType>, TableProps<SiteTableRowType>>(
  (props, ref) => {
    return <Table {...props} forwardedRef={ref} />;
  }
);
TableWithRef.displayName = "TableWithRef";
