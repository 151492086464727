import { useContext } from "react";

import { ViewerConfigType } from "../../../../../../../common/store/annotatePage/ViewerConfigType";
import { useProjectId } from "../../../../../../../Project/hooks/useProjectId";
import { useTimepointsNonViewOnlySeries } from "../../../AnnotationPanel/hooks/Timepoint/useTimepointsNonViewOnlySeries";
import { getNextViewerConfig } from "../../utils/getNextViewerConfig";
import { getNextViewerConfigId } from "../../utils/getNextViewerConfigId";
import {
  getViewerConfigFromSeriesId,
  MAX_NUMBER_OF_VIEWERS,
} from "../../utils/getViewerConfigFromSeriesId";
import { ViewerContext } from "../ViewerContext";
import { useAddViewerInternal } from "./useAddViewerInternal";

export function useAddViewer(): (seriesId?: number) => ViewerConfigType | undefined {
  const timepoints = useTimepointsNonViewOnlySeries();
  const { viewerConfigs } = useContext(ViewerContext);
  const addViewerInternal = useAddViewerInternal();
  const projectId = useProjectId();

  return (seriesId) => {
    if (!timepoints) {
      return;
    }

    const numberOfViewers = viewerConfigs.length;
    if (numberOfViewers >= MAX_NUMBER_OF_VIEWERS) {
      return;
    }

    const nextViewerConfigId = getNextViewerConfigId(viewerConfigs);
    const viewerConfig =
      seriesId === undefined
        ? getNextViewerConfig(timepoints, viewerConfigs, projectId)
        : getViewerConfigFromSeriesId(seriesId, nextViewerConfigId);

    if (!viewerConfig) {
      return;
    }

    addViewerInternal(viewerConfig, true);

    return viewerConfig;
  };
}
