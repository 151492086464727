import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { QueryResult } from "@apollo/client/react/types/types";

const GET_PROJECT_SITES_QUERY = gql`
  query GetProjectSites($projectId: Int!) {
    labels: label(where: { project_id: { _eq: $projectId } }) {
      id
      name
    }
  }
`;

type DataType = {
  labels: { id: number; name: string }[];
};

type Variables = {
  projectId: number | undefined;
};

export function useGetSites(projectId: number | undefined): QueryResult<DataType> {
  return useQuery<DataType, Variables>(GET_PROJECT_SITES_QUERY, {
    variables: { projectId },
    skip: !projectId,
    fetchPolicy: "cache-and-network",
  });
}
