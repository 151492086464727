import React, { ReactChild, ReactElement } from "react";
import styled from "styled-components";

interface MenuTitleProps {
  iconTitle: ReactChild;
  buttons: ReactChild;
}

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 0;
`;

const TitleWrapper = styled.div`
  padding: 16px;
  width: 100%;
  background: ${(props) => props.theme.colors.background.secondary};
  box-shadow: inset 0px -1px 0px ${(props) => props.theme.colors.neutral.neutral7};
`;

const MenuTitle = ({ iconTitle, buttons }: MenuTitleProps): ReactElement => {
  return (
    <TitleWrapper>
      <FlexWrapper>
        <div>{iconTitle}</div>
        <div>{buttons}</div>
      </FlexWrapper>
    </TitleWrapper>
  );
};

export default MenuTitle;
