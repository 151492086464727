import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../store";

const selection = (state: RootState) => state.annotatePage.selection;

const selectionSelector = createSelector([selection], (selection) => selection);

export const selectedLesionIdSelector = createSelector(
  [selectionSelector],
  ({ selectedLesionId }) => selectedLesionId
);

export const hiddenLesionIdsSelector = createSelector(
  [selectionSelector],
  ({ hiddenLesionIds }) => hiddenLesionIds
);
