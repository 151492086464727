import { z } from "zod";

import { isFragmentQcRule } from "./baseRule";
import {
  numberQcRule,
  numberUploadableQcRule,
  optionsQcRule,
  optionsUploadableQcRule,
  textQcRule,
  textUploadableQcRule,
} from "./option";

const baseQcSchema = z.strictObject({
  name: z.string(),
  info: z.strictObject({
    display_name: z.string(),
  }),
  defaults: z.strictObject({
    error_message: z.string(),
  }),
});

const refineUnknownItem = (item: unknown, rule: z.AnyZodObject, ctx: z.RefinementCtx) => {
  const res = rule.safeParse(item);
  const name = isFragmentQcRule(item) ? item.name : "unknown";
  if (!res.success) {
    res.error.errors.forEach((err) => {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: err.message,
        path: [name],
      });
    });
  }
  return res.success;
};

const buildRulesSet = ({
  optionsRule,
  textRule,
  numberRule,
}: {
  optionsRule: z.AnyZodObject;
  textRule: z.AnyZodObject;
  numberRule: z.AnyZodObject;
}) => {
  return z.array(
    z
      .custom(() => true)
      .superRefine((item, ctx) => {
        const inputType = isFragmentQcRule(item) ? item.input_type : "unknown";
        switch (inputType) {
          case "select-one":
          case "select-many":
            return refineUnknownItem(item, optionsRule, ctx);
          case "text":
            return refineUnknownItem(item, textRule, ctx);
          case "number":
            return refineUnknownItem(item, numberRule, ctx);
          default:
            return ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: `Unknown input type: ${inputType}`,
              path: ["unknown"],
            });
        }
      }),
  );
};

const rulesSet = buildRulesSet({
  optionsRule: optionsQcRule,
  textRule: textQcRule,
  numberRule: numberQcRule,
});

const uploadableRulesSet = buildRulesSet({
  optionsRule: optionsUploadableQcRule,
  textRule: textUploadableQcRule,
  numberRule: numberUploadableQcRule,
});

export const qcSchema = baseQcSchema.extend({
  series_rule_set: rulesSet,
  subject_rule_set: rulesSet.optional().transform((val) => val ?? []),
});

export const uploadableQcSchema = baseQcSchema.extend({
  series_rule_set: uploadableRulesSet,
  subject_rule_set: uploadableRulesSet.optional().transform((val) => val ?? []),
});

export type UploadableQcSchema = z.infer<typeof uploadableQcSchema>;
export type QcSchema = z.infer<typeof qcSchema>;

export const validateUploadableQcSchema = (data: unknown): UploadableQcSchema | never =>
  uploadableQcSchema.parse(data);
export const validateQcSchema = (data: unknown): QcSchema | never => qcSchema.parse(data);
