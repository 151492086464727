import React, { useContext, useEffect } from "react";
import { generatePath, Redirect, useLocation, useParams, useRouteMatch } from "react-router";

import { ProjectContext } from "../contexts/ProjectContext";
import { Content } from "./Content";
import { PageType } from "./Layout";
import { getPageDisplayName } from "./utils/getPageDisplayName";
import { setWindowTitle } from "./utils/setWindowTitle";

interface StandalonePageProps {
  page: PageType;
}

export function StandalonePage({ page }: StandalonePageProps): JSX.Element {
  const { content } = page;
  const { search } = useLocation();
  const { patients } = useContext(ProjectContext);
  const { path, params } = useRouteMatch();
  const { patientId: rawPatientId } = useParams<{
    patientId: string;
  }>();

  const searchParams = new URLSearchParams(search);
  const queryPatientDicomId = searchParams.get("subject_dicom_id");

  useEffect(() => {
    const windowTitle = getPageDisplayName(page);
    setWindowTitle(windowTitle);
  }, [page]);

  if (rawPatientId === "-") {
    if (!queryPatientDicomId) {
      throw new Error("No subject found in route or query params.");
    }

    const patient = patients.find(({ subjectId }) => subjectId === queryPatientDicomId);
    if (!patient) {
      throw new Error(`No subject found in project for subject DICOM id ${queryPatientDicomId}.`);
    }

    const newPath = generatePath(path, { ...params, patientId: patient.id });
    return <Redirect to={newPath} />;
  }

  return <Content content={content} />;
}
