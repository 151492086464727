import dayjs, { Dayjs } from "dayjs";

import { TASK_COMPLETED } from "../../../Annotate/components/TaskWorklist/TaskProgressType";
import { PatientType } from "../hooks/useProjectLabels";
import { getEarliestDate } from "./getEarliestDate";

export function getEarliestTaskDate(
  patients: PatientType[],
  includeIncompleteTasks: boolean
): Dayjs | undefined {
  const taskAssignments = patients
    .flatMap(({ taskAssignments }) => taskAssignments)
    .filter(({ progress }) => includeIncompleteTasks || progress === TASK_COMPLETED);

  const dates = taskAssignments.map(({ lastModified }) => dayjs(lastModified));

  return getEarliestDate(dates);
}
