import { useContext, useMemo } from "react";
import { useSelector } from "react-redux";

import {
  includeOtherLesionsSelector,
  includePredictedLesionsSelector,
  recistTaskSelector,
} from "../../../../../../common/store/annotatePage/taskSelector";
import { PatientContext } from "../../../../../../Dashboard/components/Settings/Patients/Viewer/PatientContext";
import { TaskContext } from "../../../../../TaskContext";
import { LesionsListQueryInput } from "../fragments/LesionListItemFragment";

export function useLesionListQueryInput(): LesionsListQueryInput {
  const { task } = useContext(TaskContext);
  const { id } = useContext(PatientContext);
  const isRecist = useSelector(recistTaskSelector);
  const includeOtherLesions = useSelector(includeOtherLesionsSelector);
  const includePredictedLesions = useSelector(includePredictedLesionsSelector);

  return useMemo(
    () => ({
      taskContext: { id, task },
      skipRecist: !isRecist,
      includeOtherLesions,
      includePredictedLesions,
    }),
    [task, id, isRecist, includeOtherLesions, includePredictedLesions]
  );
}
