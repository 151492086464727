import cornerstoneTools from "@altis-labs/cornerstone-tools";
import { Mutex } from "async-mutex";

import { ELLIPTICAL_ROI } from "../../../../../../../cornerstone/ToolType";
import EllipseToolChangeDetector from "./EllipseToolChangeDetector";
import ellipseStyles from "./EllipseToolStyle";

export default class EllipseTool {
  constructor(cornerstoneElement) {
    this.onEllipseChangedCallback = null;
    this.onEllipseCreatedCallback = null;
    this.onEllipseRightClickedCallback = null;
    this.onEllipseDoubleClickedCallback = null;
    this.onExceptionThrown = null;
    this.mutex = new Mutex();

    const tool = cornerstoneTools.getToolForElement(cornerstoneElement, ELLIPTICAL_ROI);

    if (!tool) {
      throw new Error("Cornerstone ellipse tool is null");
    }

    tool.setStyle(ellipseStyles);
    tool.setDataInvalidatedCallback(this.onToolDataInvalidated);

    this.tool = tool;
    this.cornerstoneElement = cornerstoneElement;
    this.ellipseToolChangeDetector = new EllipseToolChangeDetector(
      this.onEllipseCreated,
      this.onEllipseChanged
    );
    tool.setOnEllipseClicked(this.onEllipseClicked);
  }

  onToolDataInvalidated = async (data) => {
    await this.ellipseToolChangeDetector.testEllipseData(data);
  };

  setCurrentRoi(roi) {
    this.tool.setCurrentRoi(roi);
  }

  setPassiveRoi(roi) {
    this.tool.setPassiveRoi(roi);
  }

  setOnEllipseDoubleClickedCallback(onEllipseDoubleClickedCallback) {
    this.onEllipseDoubleClickedCallback = onEllipseDoubleClickedCallback;
  }

  setOnEllipseRightClickedCallback(callback) {
    this.onEllipseRightClickedCallback = callback;
  }

  setOnExceptionThrown(onExceptionThrown) {
    this.onExceptionThrown = onExceptionThrown;
  }

  setActive() {
    cornerstoneTools.setToolActiveForElement(this.cornerstoneElement, ELLIPTICAL_ROI, {
      mouseButtonMask: 1,
    });
  }

  setPassive = () => {
    cornerstoneTools.setToolPassiveForElement(this.cornerstoneElement, ELLIPTICAL_ROI, {
      mouseButtonMask: 1,
    });
  };

  setEnabled() {
    cornerstoneTools.setToolEnabledForElement(this.cornerstoneElement, ELLIPTICAL_ROI, {
      mouseButtonMask: 1,
    });
  }

  setOnEllipseCreatedCallback(callback) {
    this.onEllipseCreatedCallback = callback;
  }

  setOnEllipseChangedCallback(callback) {
    this.onEllipseChangedCallback = callback;
  }

  onEllipseCreated = async (toolData) => {
    if (!this.onEllipseCreatedCallback) {
      return;
    }

    this.mutex.acquire().then(async (release) => {
      try {
        await this.onEllipseCreatedCallback(toolData);
      } catch (e) {
        console.error("Error in EllipseTool onEllipseCreated", e);
        this.onExceptionThrown(e);
      } finally {
        release();
      }
    });
  };

  onEllipseChanged = async (toolData) => {
    if (!this.onEllipseChangedCallback) {
      return;
    }
    this.mutex.acquire().then(async (release) => {
      try {
        await this.onEllipseChangedCallback(toolData);
      } catch (e) {
        console.error("Error in EllipseTool onEllipseChanged", e);
        this.onExceptionThrown(e);
      } finally {
        release();
      }
    });
  };

  onEllipseClicked = async (toolData, detail) => {
    const {
      event: { detail: mouseClickDetail },
      buttons,
    } = detail;

    if (mouseClickDetail === 2) {
      this.onEllipseDoubleClickedCallback?.(toolData);
      return;
    }

    const isLeftMousePress = buttons === 2;
    if (isLeftMousePress) {
      this.onEllipseRightClickedCallback?.({ ...detail, toolData });
    }
  };

  setToolRoiStates = (roiStates) => {
    this.tool.setToolRoiStates(roiStates);
  };
}
