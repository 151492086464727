import { gql, useQuery } from "@apollo/client";
import { TaskContext } from "nota-predict-web/src/Annotate/TaskContext";
import { useContext, useEffect } from "react";

import { useActiveViewerConfig } from "../../ViewerPanel/Viewer/hooks/useActiveViewerConfig";

interface GetActiveSeriesIdOptions {
  failAction?: "return" | "warn" | "throw";
}

interface GetActiveSeriesOptions extends GetActiveSeriesIdOptions {
  pollInterval?: number;
}

const GET_ACTIVE_SERIES_DEFAULT_POLL_INTERVAL = 10000;

export const useGetActiveSeriesId = ({ failAction = "return" }: GetActiveSeriesIdOptions = {}) => {
  const activeViewer = useActiveViewerConfig();

  const err = "No active viewer found for useGetCurrentSeries()";

  if (!activeViewer) {
    switch (failAction) {
      case "return":
        return;
      case "warn":
        console.warn(err);
        return;
      case "throw":
        throw new Error(err);
      default:
        return;
    }
  }
  return activeViewer.seriesId;
};

export type ActiveSeries = ReturnType<typeof useGetActiveSeries>;

export const useGetActiveSeries = ({
  failAction = "return",
  pollInterval,
}: GetActiveSeriesOptions = {}) => {
  const seriesId = useGetActiveSeriesId({ failAction });

  const { data, error } = useGetSeriesQuery(seriesId, pollInterval);
  // opted not to use const series = seriesId !== undefined ? getSeriesFromCache(seriesId, projectId, cache) : undefined;
  // because it does not have all the fields we need

  useEffect(() => {
    if (error) {
      console.error("Error fetching series\n", error);
    }
  }, [error]);

  return data?.series;
};

type GetSeriesQueryResponse = {
  series: SeriesQueryType;
};

export type SeriesQueryType = {
  id: number;
  seriesInstanceUid: string;
  seriesDescription: string;
  seriesNumber: number;
  seriesDate: string;
  computedSliceThickness: number;
  computedAnatomicalPlane: string;
  seriesNotes: {
    id: number;
    notes: string;
  }[];
  studyId: number;
  study: {
    id: number;
    studyInstanceUid: string;
    studyDate: string;
    patientName?: string; // FIXME: WHY IS THIS ON THE STUDY?!
    patientId: number;
    patient: {
      id: number;
      patientDicomId: string;
    };
  };
};

const useGetSeriesQuery = (
  seriesId: number | undefined,
  pollInterval = GET_ACTIVE_SERIES_DEFAULT_POLL_INTERVAL
) => {
  const { isQc } = useContext(TaskContext);

  return useQuery<GetSeriesQueryResponse>(
    gql`
      query GetSeries($seriesId: Int!) {
        series: series_by_pk(series_id: $seriesId) {
          id: series_id
          seriesInstanceUid: series_instance_uid
          seriesDescription: series_description
          seriesNumber: series_number
          seriesDate: series_date
          computedSliceThickness: computed_slice_thickness
          computedAnatomicalPlane: computed_anatomical_plane
          seriesNotes: series_notes {
            id: id
            notes: notes
          }
          studyId: study_id
          study {
            id: study_id
            studyInstanceUid: study_instance_uid
            studyDate: study_date
            patientName: patient_name
            patientId: patient_id
            patient {
              id: patient_id
              patientDicomId: patient_dicom_id
            }
          }
        }
      }
    `,
    {
      variables: {
        seriesId,
      },
      skip: !isQc || !seriesId,
      pollInterval: seriesId && isQc ? pollInterval : undefined,
    }
  );
};
