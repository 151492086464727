import React from "react";
import { Redirect, Route, RouteComponentProps, Switch, useRouteMatch } from "react-router";

import { Project } from "../Project";
import { useCurrentUserAllowedItems } from "./hooks/useCurrentUserAllowedItems";
import { useLayoutDefaultRoute } from "./hooks/useLayoutDefaultRoute";
import { usePageGroups } from "./hooks/usePageGroups";
import { PageType, ProjectLayoutType, ProjectPageType } from "./Layout";
import { ProjectPage } from "./ProjectPage";
import { DASHBOARD, PATIENT, SUBJECT } from "./RoutePathType";
import { StandalonePage } from "./StandalonePage";
import { getPageRouteStr } from "./utils/getPageRouteStr";

interface ProjectRoutesProps {
  layout: ProjectLayoutType;
}

export function ProjectRoutes({ layout }: ProjectRoutesProps): JSX.Element {
  const { url, path: basePath } = useRouteMatch();

  const { pages: projectPages, standalonePages } = layout;

  const allowedProjectPages = useCurrentUserAllowedItems<ProjectPageType>()(projectPages);
  const allowedStandalonePages = useCurrentUserAllowedItems<PageType>()(standalonePages);

  const groupedPages = usePageGroups(layout);

  const defaultRoute = useLayoutDefaultRoute(layout, url) ?? DASHBOARD;

  return (
    <Switch>
      {Object.entries(groupedPages).map(([parentRoute, pages], parentIndex) => {
        const parentUrl = `${basePath}/${parentRoute}`;
        const defaultPage = pages.find(({ defaultPage }) => defaultPage) ?? pages[0];
        if (!defaultPage) {
          throw new Error(`Page group ${parentRoute} does not have a default page!`);
        }
        const path = getPageRouteStr(defaultPage, url);

        return (
          <Route key={parentIndex} exact path={parentUrl}>
            <Redirect to={path} />
          </Route>
        );
      })}
      {allowedStandalonePages.map((page, pageIndex) => {
        const path = getPageRouteStr(page, basePath);
        
        return (
          <Route key={pageIndex} path={path}>
            <StandalonePage page={page} />
          </Route>
        );
      })}
      {allowedProjectPages.map((page, pageIndex) => {
        const path = getPageRouteStr(page, basePath);

        return (
          <Route key={pageIndex} path={path}>
            <Project layout={layout}>
              <ProjectPage page={page} />
            </Project>
          </Route>
        );
      })}
      {/* When renaming patient to subject in the front end, we wanted to keep backwards compatibility for the old annotate page links with patient */}
      <Route path={"/:trialId(\\d+)/annotate/project/:projectId(\\d+)/patient/:patientId(\\d+|-)/task/:taskId(\\d+)"} component={PatientRedirectHelper}></Route>
      <Redirect to={defaultRoute} />
    </Switch>
  );
}

const PatientRedirectHelper = (props: RouteComponentProps): JSX.Element => {
  const { location } = props;
  const oldPath = location.pathname;
  const splitPath = oldPath.split("/");
  const projectIndex = splitPath.findIndex((value) => value === PATIENT);

  if (projectIndex !== -1) {
    splitPath[projectIndex] = SUBJECT;
  }

  const path = splitPath.join("/");
  return <Redirect to={path} />;
};
