import { useContext } from "react";

import { ToolQueueType } from "../../../../../../../common/store/annotatePage/ViewerConfigType";
import { ViewerContext } from "../ViewerContext";

export type ToolQueueActionType = {
  queue: ToolQueueType;
  value: unknown;
};

export function useSetAllViewersToolQueue(): (action: ToolQueueActionType) => void {
  const { setViewerConfigs } = useContext(ViewerContext);

  return (action) => {
    const { queue, value } = action;
    setViewerConfigs((viewerConfigs) =>
      viewerConfigs.map((viewerConfig) => {
        return {
          ...viewerConfig,
          toolQueues: { ...viewerConfig.toolQueues, [queue]: value },
        };
      })
    );
  };
}
