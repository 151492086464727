import cornerstoneTools from "@altis-labs/cornerstone-tools";

import { ToolType } from "../../../../../../../cornerstone/ToolType";
import { ToolCallbackDataType } from "../types/ToolCallbackDataType";

export function updateToolDataRoiId(
  imageId: string,
  toolType: ToolType,
  oldRoiId: number,
  newRoiId: number
): ToolCallbackDataType<unknown>["toolData"] {
  const newToolData: any[] = [];

  const toolStates: any = cornerstoneTools.globalImageIdSpecificToolStateManager.toolState;

  const toolData = toolStates[imageId]?.[toolType].data;
  if (!toolData) {
    throw new Error(`Unable to update contour tool data for imageId: ${imageId}`);
  }

  for (let i = 0; i < toolData.length; i++) {
    const { roi, ...other } = toolData[i];
    if (!roi) {
      continue;
    }

    const { id } = roi;
    if (id !== oldRoiId) {
      continue;
    }

    toolData[i] = { ...other, roi: { ...roi, id: newRoiId } };

    newToolData.push(toolData[i]);
  }

  return newToolData;
}
