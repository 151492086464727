import { OkForm, OkFormProps } from "./OkForm";
import { useDialogReturnType } from "./useDialog";
import { useFormDialog } from "./useFormDialog";

export function useOkForm(props: OkFormProps): useDialogReturnType {
  const { title, shouldCloseOnEsc = false, shouldCloseOnOverlayClick = false } = props;
  return useFormDialog({
    children: OkForm,
    label: title,
    showCloseButton: false,
    reactModalProps: {
      shouldCloseOnOverlayClick,
      shouldCloseOnEsc,
    },
    props,
  });
}
