import { LesionTaskCheckResultComponentProps } from "nota-predict-web/src/Annotate/components/Annotate/page/AnnotationPanel/toolbar/SaveChecks/types/SaveCheckResultComponentProps";
import { useGoToJumpToIds } from "nota-predict-web/src/Annotate/components/Annotate/page/AnnotationPanel/toolbar/SaveChecks/utils/useGoToJumpToIds";
import { InputButton } from "nota-predict-web/src/common/components/input/InputButton";

import { MinimumContourCheckResult } from "./interface";

export const MinimumContourCheckAction = ({
  onRequestClose,
  result,
}: LesionTaskCheckResultComponentProps<MinimumContourCheckResult>): JSX.Element => {
  const jumpToIDs = useGoToJumpToIds();

  if (result === null) {
    return <></>;
  }

  return (
    <InputButton
      type="button"
      onClick={() => {
        jumpToIDs(result);
        onRequestClose();
      }}
      value="Jump to slice"
      width={100}
    />
  );
};
