import { getRoiHUStats, HUStat } from "./getRoiHUStats";

export function getTotalHUStats(roiId: number): HUStat | undefined {
  const allHUStats = getRoiHUStats(roiId);

  if (allHUStats.length === 0) {
    return undefined;
  }

  const min = Math.min(...allHUStats.map(({ min }) => min));
  const max = Math.max(...allHUStats.map(({ max }) => max));
  const average =
    allHUStats.map(({ average }) => average).reduce((p, c) => p + c, 0) / allHUStats.length;

  return {
    average,
    min,
    max,
  };
}
