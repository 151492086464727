// Limits the annotate page study queries to some number of studies. Null means no limit. Changing this should not be committed.
import { useEffect } from "react";

export const DEBUG_STUDIES_LIST_LIMIT = null;

// Limits the annotate page lesion queries to some number of lesions. Null means no limit. Changing this should not be committed.
export const DEBUG_LESION_LIMIT = null;

// Disables image prefetching in the viewer
export const DISABLE_VIEWER_IMAGE_PREFETCHING = false;

// Disables image prefetching in the annotation page. Not the same as viewer prefetching.
export const DISABLE_PAGE_IMAGE_PREFETCHING = true;

// Disables the annotate page's use of queries that are probably not required. This is set to true with the intention of making it permanent once battle-tested.
const DEBUG_SKIP_FOR_ANNOTATE_PAGE = true;

export const isAnnotatePageAndSkipped = (pathname: string) => {
  if (!DEBUG_SKIP_FOR_ANNOTATE_PAGE) {
    return false;
  }

  return !!pathname.match(/annotate\/project\/(\d+)\/subject\/(\d+)/);
};

export const useIsAnnotatePageAndSkippedCallback = (
  onPathChanged?: (isAnnotatePage: boolean) => void
) => {
  useEffect(() => {
    if (isAnnotatePageAndSkipped(window.location.pathname)) {
      onPathChanged?.(true);
    } else {
      onPathChanged?.(false);
    }
  }, [window.location.pathname]);
};
