import styled from "styled-components";

import { main } from "../../../../../common/theme/main";
import { SeriesFlagButton } from "../StudyPanel/SeriesFlagButton";
import { SeriesInfoButton } from "../StudyPanel/SeriesInfoButton";
import { SeriesNotesButton } from "../StudyPanel/SeriesNotesButton";
import { SeriesClassificationsPanelWrapper } from "./SeriesClassificationsPanelWrapper";
import { Viewer, ViewerProps } from "./Viewer";
import { useTaskSeries } from "../AnnotationPanel/hooks/useTaskSeries";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 3px;
  position: relative;
`;

const LeftTopBar = styled.div`
  position: absolute;
  left: 18px;
  top: 64px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export function AnnotateViewer(props: ViewerProps): JSX.Element {
  const { viewerConfig } = props;
  const { seriesId } = viewerConfig;
  const taskSeries = useTaskSeries();
  const series = taskSeries?.find(({ id }) => id === seriesId);

  const {
    colors: {
      neutral: { neutral5 },
    },
  } = main;

  return (
    <Wrapper>
      <LeftTopBar>
        {series && <SeriesInfoButton series={series} color={neutral5} />}
        {series && <SeriesNotesButton series={series} noNotesColor={neutral5} />}
        {series && <SeriesFlagButton series={series} flagSize={20} unflaggedColor={neutral5} />}
      </LeftTopBar>
      <Viewer {...props} />
      <SeriesClassificationsPanelWrapper viewerConfig={viewerConfig} />
    </Wrapper>
  );
}
