import { Menu } from "@mui/material";
import React from "react";

import { ToolClickEventType } from "./tools/types/ToolClickEventType";
import { getMouseState } from "./utils/getMouseState";
import { ViewerContextMenuItem } from "./ViewerContextMenuItem";
import { ViewerContextMenuItemType } from "./ViewerContextMenuItemType";

interface ViewerContextMenuProps {
  event?: ToolClickEventType;
  menuItems: ViewerContextMenuItemType[];
  onClose: () => Promise<void>;
}

export function ViewerContextMenu({
  event,
  onClose,
  menuItems,
}: ViewerContextMenuProps): JSX.Element {
  const mouseState = event ? getMouseState(event) : undefined;
  const anchorPosition = mouseState ? { top: mouseState.y, left: mouseState.x } : undefined;

  return (
    <Menu
      onContextMenu={(e) => e.preventDefault()}
      keepMounted
      open={!!mouseState && menuItems.length > 0}
      onClose={onClose}
      anchorReference="anchorPosition"
      anchorPosition={anchorPosition}
    >
      {menuItems.map((menuItem, index) => (
        <ViewerContextMenuItem {...menuItem} key={index} />
      ))}
    </Menu>
  );
}
