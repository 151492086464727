import { useApolloClient } from "@apollo/client";

import { useGetIsLesionFromCurrentTask } from "../../ViewerPanel/useGetIsLesionFromCurrentTask";
import { getLesionFromCache } from "./getLesionFromCache";
import { useTimepointsNonViewOnlySeriesIds } from "./Timepoint/useTimepointsNonViewOnlySeriesIds";
import { useLesionListQueryInput } from "./useLesionListQueryInput";
import { getVariables, useUpdateTumourBurdens } from "./useUpdateTumourBurdens";

type ReturnType = {
  onTumourBurdenChanged: (lesionId: number) => Promise<void>;
};

export function useTumourBurdenChanged(): ReturnType {
  const lesionListQueryInput = useLesionListQueryInput();
  const seriesIds = useTimepointsNonViewOnlySeriesIds();
  const getIsLesionFromCurrentTask = useGetIsLesionFromCurrentTask();

  const { cache } = useApolloClient();

  const [updateTumourBurden] = useUpdateTumourBurdens();

  const onTumourBurdenChanged = async (lesionId: number) => {
    if (!seriesIds) {
      throw new Error("No series ids when updating tumour burden");
    }

    const { skipRecist } = lesionListQueryInput;
    if (skipRecist) {
      return;
    }

    const lesion = getLesionFromCache(lesionListQueryInput, lesionId, cache);
    if (!lesion) {
      throw new Error(`Unable to get lesion with id '${lesionId}' from cache`);
    }

    if (!getIsLesionFromCurrentTask(lesion)) {
      return;
    }

    const variables = getVariables(lesion, seriesIds);

    await updateTumourBurden({ variables });
  };

  return { onTumourBurdenChanged };
}
