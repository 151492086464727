import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { QueryResult } from "@apollo/client/react/types/types";

const GET_PROJECT_QUERY = gql`
  query GetProjectFromTrialId($trialId: Int!) {
    project(where: { legacy_trial_id: { _eq: $trialId } }) {
      id
    }
  }
`;

type DataType = {
  project: { id: number }[];
};

type Variables = {
  trialId: number;
};

export function useGetProjectFromTrial(trialId: number): QueryResult<DataType> {
  return useQuery<DataType, Variables>(GET_PROJECT_QUERY, {
    variables: { trialId },
  });
}
