import { createSelector } from "@reduxjs/toolkit";
import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getDemographicsFilters,
  projectViewFiltersActions,
} from "../../../../common/store/projectViewFiltersSlice";
import { capitalize } from "../../../../common/utils/capitalize";
import { EthnicityType } from "../../../common/types/EthnicityType";
import FilterButton from "../FilterButton";
import { ButtonWrapper } from "../FilterInputWrapper";
import FilterSubtext from "../FilterSubtext";

const getEthnicityFilters = createSelector(
  [getDemographicsFilters],
  (demographicsFilters) => demographicsFilters.ethnicity
);

export const EthnicityFilters: FC = () => {
  const dispatch = useDispatch();
  const ethnicityFilters = useSelector(getEthnicityFilters);

  const ethnicityOptions = Object.keys(ethnicityFilters).map((filter, index) => {
    const ethnicity = filter as EthnicityType;

    const updateEthnicityFilters = () => {
      dispatch(projectViewFiltersActions.toggleEthnicity(ethnicity));
    };

    const isChecked = ethnicityFilters[ethnicity];

    return (
      <FilterButton
        key={index}
        text={capitalize(filter)}
        onClick={updateEthnicityFilters}
        active={isChecked}
        width={90}
      />
    );
  });

  const options = (
    <>
      <ButtonWrapper>{ethnicityOptions}</ButtonWrapper>
    </>
  );

  return (
    <>
      <FilterSubtext text="Ethnicity" />
      {options}
    </>
  );
};
