import React from "react";

import { InputButton } from "../../../../../../../../../common/components/input/InputButton";
import { useTrySelectLesionId } from "../../../../hooks/useTrySelectLesionId";
import { useSetLesionToScrollTo } from "../../LesionToScrollTo/useSetLesionToScrollTo";
import { LesionTaskCheckResultComponentProps } from "../../types/SaveCheckResultComponentProps";
import { MissingClassificationsCheckResultType } from "./useMissingClassificationsCheck";

export function MissingClassificationsCheckActions({
  result,
  onRequestClose,
}: LesionTaskCheckResultComponentProps<MissingClassificationsCheckResultType>): JSX.Element {
  const trySelectLesionId = useTrySelectLesionId();
  const setLesionToScrollTo = useSetLesionToScrollTo();

  const handleGoToObject = () => {
    if (!result) {
      throw new Error("Missing classification result is empty when go to object was clicked");
    }

    const { lesionId } = result;
    trySelectLesionId(lesionId);
    setLesionToScrollTo(lesionId);
    //  todo add concept of selected roi and set it here

    onRequestClose();
  };

  if (!result) {
    return <></>;
  }

  return (
    <InputButton type={"button"} onClick={handleGoToObject} value={"Go to label"} width={100} />
  );
}
