import html2canvas, { Options } from "html2canvas";
import React, { useCallback, useRef, useState } from "react";

/**
 * Code is duplicated from recharts-to-png here: https://github.com/brammitch/recharts-to-png
 * with minor changes to file types and names of various elements.
 */

type UseGetComponentAsImage = [
  () => Promise<HTMLCanvasElement> | undefined,
  {
    isLoading: boolean;
    ref: React.MutableRefObject<{ current: HTMLElement } | undefined>;
  }
];

/**
 * @param options - optional html2canvas Options object
 */
export function useGetComponentAsImage(options?: Partial<Options>): UseGetComponentAsImage {
  const ref = useRef<{ current: HTMLElement } | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const getImage = useCallback(() => {
    if (ref !== null && ref?.current?.current) {
      setIsLoading(true);

      return html2canvas(ref.current.current, {
        logging: false,
        ...options,
      }).then((canvas) => {
        setIsLoading(false);
        return canvas;
      });
    }
  }, [options]);

  return [
    getImage,
    {
      ref,
      isLoading,
    },
  ];
}
