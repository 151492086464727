import React from "react";
import Select, { components, IndicatorProps, ValueType } from "react-select";

import { ReactComponent as DropDownArrow } from "../../../../../../assets/svgs/DropDownArrow.svg";
import { ReactComponent as DropUpArrow } from "../../../../../../assets/svgs/DropUpArrow.svg";
import { getClassificationsSelectStyle } from "../getClassificationsSelectStyle";
import { AnatomicalStructureOptionType } from "./AnatomicalStructureOptionType";

const DropdownIndicator = (props: IndicatorProps<AnatomicalStructureOptionType, false>) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        {props.selectProps.menuIsOpen ? <DropUpArrow /> : <DropDownArrow />}
      </components.DropdownIndicator>
    )
  );
};

interface AnatomyClassificationSelectorProps {
  options: AnatomicalStructureOptionType[] | undefined;
  selected: AnatomicalStructureOptionType | null;
  onSelected: (value: AnatomicalStructureOptionType | null) => void;
  disabled: boolean;
}

export function AnatomyClassificationSelector({
  options,
  selected,
  onSelected,
  disabled,
}: AnatomyClassificationSelectorProps): JSX.Element {
  const handleSelected = (value: ValueType<AnatomicalStructureOptionType, false>) => {
    onSelected(value);
  };

  const selectStyle = getClassificationsSelectStyle<AnatomicalStructureOptionType, false>();

  return (
    <Select
      isSearchable={true}
      isLoading={!options}
      components={{ DropdownIndicator }}
      placeholder={!disabled ? "Location" : "N/A"}
      styles={selectStyle}
      options={options}
      onChange={handleSelected}
      value={selected}
      menuPortalTarget={document.body}
      menuPosition={"fixed"}
      isDisabled={disabled}
    />
  );
}
