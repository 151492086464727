import { useEffect } from "react";

import { useCurrentUser } from "../../../../common/contexts/UserContext/useCurrentUser";
import { useSelectedTaskAssignment } from "../../../useSelectedTaskAssignment";
import { TASK_COMPLETED } from "../../TaskWorklist/TaskProgressType";
import { useTaskAssignmentReOpener } from "./useTaskAssignmentReOpener";

export function AutoTaskAssignmentReOpener(): JSX.Element {
  const taskAssignment = useSelectedTaskAssignment();
  const { id: userId } = useCurrentUser();
  const {
    id,
    progress,
    user: { id: taskAssignmentUserId },
  } = taskAssignment;

  const [showReOpenTaskForm, { dialog }] = useTaskAssignmentReOpener({
    taskAssignment,
  });

  useEffect(() => {
    if (progress !== TASK_COMPLETED && taskAssignmentUserId === userId) {
      return;
    }

    showReOpenTaskForm(true);
  }, [id, taskAssignmentUserId]); //we aren't monitoring progress to avoid showing this dialog after saving a task

  return dialog;
}
