import { useDeepCompareCallback } from "use-deep-compare";

import { ViewerConfigType } from "../../../../../../../common/store/annotatePage/ViewerConfigType";
import { useAddViewerInternal } from "./useAddViewerInternal";
import { useClearViewers } from "./useClearViewers";

export function useSetViewers(): (viewerConfigs: ViewerConfigType[]) => void {
  const clearViewers = useClearViewers();
  const addViewerInternal = useAddViewerInternal();

  return useDeepCompareCallback(
    (viewerConfigs) => {
      clearViewers();

      viewerConfigs.forEach((viewerConfig) => addViewerInternal(viewerConfig, false));
    },
    [addViewerInternal, clearViewers]
  );
}
