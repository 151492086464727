import { useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { ActionButton } from "../../../Annotate/components/Manage/ActionButton";
import { ReactComponent as CancelIcon } from "../../../assets/svgs/CancelOutline.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/svgs/Delete.svg";
import { ReactComponent as PlusIcon } from "../../../assets/svgs/PlusOutline.svg";
import { useProjectId } from "../../../Project/hooks/useProjectId";
import {
  makeSelectedSeriesSelector,
  seriesSelectionActions,
} from "../../store/seriesSelectionSlice";
import { useAppSelector } from "../../store/store";
import { TableControlsType } from "../Table/TableControlsType";
import { SeriesSelectionLabel } from "./SeriesSelectionLabel";
import { StudyTable } from "./StudyTable";
import { StudyTableColumnType } from "./StudyTableColumns";
import { StudyTableRowType } from "./StudyTableRowType";
import { StudyTableStats } from "./StudyTableStats";
import { useInsertDeleteSeriesProjectExclusions } from "./useInsertDeleteSeriesProjectExclusions";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-height: 550px;
`;

const ActionPanelWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ButtonsWrapper = styled.div`
  flex: 0;
  display: flex;
  padding: 4px;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

interface StudyTableManagerProps {
  tableKey: string;
  hiddenColumns: StudyTableColumnType[];
  enableSelect?: boolean;
  enableExpand?: boolean;
}

export function StudyTableManager({
  tableKey,
  hiddenColumns,
  enableSelect = false,
  enableExpand = false,
}: StudyTableManagerProps): JSX.Element {
  const projectId = useProjectId();

  const dispatch = useDispatch();

  const tableRef = useRef<TableControlsType<StudyTableRowType>>(null);

  const selectedSeriesSelector = useMemo(makeSelectedSeriesSelector, []);
  const selectedSeries = useAppSelector((state) => selectedSeriesSelector(state, tableKey));

  const [updateExclusions] = useInsertDeleteSeriesProjectExclusions();

  const handleSelectAll = (isSelected: boolean) => {
    dispatch(seriesSelectionActions.setAllStudiesSelected({ tableKey, isSelected }));
  };

  const handleClearSelectedRows = () => {
    handleSelectAll(false);
    const { current: tableControls } = tableRef;
    tableControls?.clearAllFilters();
    tableControls?.toggleAllRowsExpanded(false);
  };

  const handleExclude = async () => {
    const seriesExclusions = selectedSeries
      .filter(
        ({ projectExclusions }) =>
          !projectExclusions.map(({ projectId }) => projectId).includes(projectId)
      )
      .map(({ id: series_id }) => ({
        series_id,
        project_id: projectId,
      }));

    if (seriesExclusions.length === 0) {
      return;
    }

    await updateExclusions({
      variables: {
        projectIds: [projectId],
        seriesExclusions,
        deleteSeriesIds: [],
      },
    });
  };

  const handleInclude = async () => {
    const deleteSeriesIds = selectedSeries
      .filter(({ projectExclusions }) =>
        projectExclusions.map(({ projectId }) => projectId).includes(projectId)
      )
      .map(({ id }) => id);

    if (deleteSeriesIds.length === 0) {
      return;
    }

    await updateExclusions({
      variables: {
        projectIds: [projectId],
        seriesExclusions: [],
        deleteSeriesIds,
      },
    });
  };

  const seriesSelected = selectedSeries.length > 0;

  return (
    <Wrapper>
      <ActionPanelWrapper>
        <ButtonsWrapper>
          <ActionButton
            label={"Clear"}
            icon={CancelIcon}
            onClick={handleClearSelectedRows}
            tooltip={"Clear all selections"}
          />
          <ActionButton
            label={"Include"}
            icon={PlusIcon}
            onClick={handleInclude}
            tooltip={"Include selected series/studies from project"}
            disabled={!seriesSelected}
          />
          <ActionButton
            label={"Exclude"}
            icon={DeleteIcon}
            onClick={handleExclude}
            tooltip={"Exclude selected series/studies from project"}
            disabled={!seriesSelected}
          />
        </ButtonsWrapper>
        {enableSelect && <SelectionInfo tableKey={tableKey} />}
      </ActionPanelWrapper>

      <StudyTable
        tableKey={tableKey}
        tableRef={tableRef}
        hiddenColumns={hiddenColumns}
        enableSelect={enableSelect}
        enableExpand={enableExpand}
      />

      <StudyTableStats tableKey={tableKey} />
    </Wrapper>
  );
}

interface SelectionInfoProps {
  tableKey: string;
}

function SelectionInfo({ tableKey }: SelectionInfoProps): JSX.Element {
  const selectedSeriesSelector = useMemo(makeSelectedSeriesSelector, []);

  const selectedSeries = useAppSelector((state) => selectedSeriesSelector(state, tableKey));

  const totalStudies = [...new Set(selectedSeries.map(({ studyId }) => studyId))].length;

  const totalSeries = selectedSeries.length;

  return <SeriesSelectionLabel totalSeries={totalSeries} totalStudies={totalStudies} />;
}
