import NoteAddIcon from "@mui/icons-material/NoteAdd";
import Tooltip from "@mui/material/Tooltip";
import React from "react";

import { ViewerConfigType } from "../../../../../../../common/store/annotatePage/ViewerConfigType";
import { usePasteContour } from "../../tools/contour/hooks/usePasteContour";
import { useViewerCanPasteContour } from "../../tools/contour/hooks/useViewerCanPasteContour";
import { ContourContextMenuData } from "../../tools/contour/types/ContourContextMenuDataType";
import { ViewerContextMenu } from "../../ViewerContextMenu";
import { verifyContourContextMenuData } from "./utils";

interface PasteContourContextMenuProps {
  viewerConfig: ViewerConfigType;
  event?: ContourContextMenuData;
  onClose: () => Promise<void>;
}

export function PasteContourContextMenu({
  viewerConfig,
  event,
  onClose,
}: PasteContourContextMenuProps): JSX.Element {
  const { canPaste } = useViewerCanPasteContour(viewerConfig);
  const { pasteContour } = usePasteContour(viewerConfig);

  const handleClose = async () => {
    await onClose();
  };

  const handlePaste = async () => {
    verifyContourContextMenuData(event);

    await pasteContour();

    await handleClose();
  };

  const canPasteContour = canPaste();

  const menuItems = canPasteContour
    ? [
        {
          label: "Paste",
          icon: (
            <Tooltip title={"Paste Contour(s) (ctrl + C)"}>
              <NoteAddIcon />
            </Tooltip>
          ),
          callback: handlePaste,
        },
      ]
    : [];

  return <ViewerContextMenu event={event} onClose={handleClose} menuItems={menuItems} />;
}
