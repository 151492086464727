import { useContext, useRef } from "react";
import { CSVLink } from "react-csv";

import { ProjectContext } from "../../Project/contexts/ProjectContext";
import getCsvData from "./getCsvData";

interface getCsvLinkProp {
  onClose?: () => void;
}

type CsvLinkType = {
  csvData: string[][];
  csvLinkRef: React.RefObject<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>;
  handleDownload: () => void;
};

type ReturnType = {
  csvLink?: CsvLinkType;
  loading: boolean;
};

export default function useCsvLink({ onClose }: getCsvLinkProp): ReturnType {
  const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

  const { patients } = useContext(ProjectContext);

  const csvData = getCsvData(patients);

  const handleDownload = () => {
    if (csvLinkRef.current) {
      csvLinkRef.current.link.click();
    }
    if (onClose) onClose();
  };

  return {
    loading: false,
    csvLink: {
      csvData,
      csvLinkRef,
      handleDownload,
    },
  };
}
