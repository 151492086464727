import { main } from "../../common/theme/main";
import { ERROR, INFO, SUCCESS, TaskStatusType, WARNING } from "./StatusTypes";

const success = main.colors.states.success;
const info = main.colors.states.info;
const warning = main.colors.states.warning;
const error = main.colors.states.error;

const STATUS_COLOR_TYPE = [success, info, warning, error] as const;

export type StatusColorType = typeof STATUS_COLOR_TYPE[number];

export function getStatusColorType(status: TaskStatusType): StatusColorType {
  const stateNames: Record<TaskStatusType, StatusColorType> = {
    [SUCCESS]: success,
    [INFO]: info,
    [WARNING]: warning,
    [ERROR]: error,
  };

  if (!stateNames[status]) {
    throw new Error(`Status: ${status} Color not found`);
  }

  return stateNames[status];
}
