import React, { createContext } from "react";

type BasePathContextProviderType = {
  basePath: string;
};

export const BasePathContext = createContext<BasePathContextProviderType>({
  basePath: "/",
});

interface BasePathContextProviderProps {
  basePath: string;
  children: JSX.Element;
}

export function BasePathContextProvider({
  basePath,
  children,
}: BasePathContextProviderProps): JSX.Element {
  return <BasePathContext.Provider value={{ basePath }}>{children}</BasePathContext.Provider>;
}
