import React, { ReactElement } from "react";
import styled from "styled-components";

import Heading4 from "./Heading4";

interface ItemSubtextProps {
  text: string;
}

const StyledSubtext = styled.div`
  color: ${(props) => props.theme.colors.neutral.black};
  font-weight: normal;
  font-size: 13px;
`;

export default function ItemSubtext({ text }: ItemSubtextProps): ReactElement {
  return (
    <StyledSubtext>
      <Heading4 title={text} />
    </StyledSubtext>
  );
}
