import { useFlags } from "launchdarkly-react-client-sdk";
import { main } from "nota-predict-web/src/common/theme/main";
import React, { FC, RefObject } from "react";
import { FaUpload } from "react-icons/fa6";
import styled from "styled-components";

import MediumFilledButton from "../../../common/components/buttons/MediumFilledButton";
import { UPLOAD_DICOM } from "../../../Project/Layout/Layout";

export const ButtonRowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 50px;
  padding: 26px;
`;

export function UploadIcon(): JSX.Element {
  return <FaUpload style={{ color: main.colors.primary.primary }} size={22} />;
}

interface UploadButtonsProps {
  onFilesSelected: (event: React.FormEvent<HTMLInputElement>) => void;
  onCloudUploadClicked: () => void;
  onFileUploadClicked: () => void;
  disabled: boolean;
  inputFileRef: RefObject<HTMLInputElement>;
  inputFolderRef: RefObject<HTMLInputElement>;
  type?: typeof UPLOAD_DICOM;
}

const UploadButtons: FC<UploadButtonsProps> = ({
  disabled,
  onFilesSelected,
  onCloudUploadClicked,
  onFileUploadClicked,
  inputFileRef,
  inputFolderRef,
  type,
}: UploadButtonsProps) => {
  const flags = useFlags();
  const showS3Upload = flags["notaShowS3Upload"];

  const handleClickUploadFolder = () => {
    inputFolderRef.current?.click();
  };

  const handleClickUploadFiles = () => {
    inputFileRef.current?.click();
  };

  // TODO: simplify once notaShowReorganisationPages flag is deprecated
  const showFolder = !type || type === UPLOAD_DICOM;
  const showDicom = !type || type === UPLOAD_DICOM;
  const showDocuments = !type;
  const showCloud = !type && showS3Upload;

  return (
    <ButtonRowWrapper>
      <input
        type="file"
        id="file"
        multiple
        ref={inputFileRef}
        style={{ display: "none" }}
        onChange={onFilesSelected}
      />
      <input
        type="file"
        id="folder"
        // @ts-ignore
        //eslint-disable-next-line react/no-unknown-property
        directory=""
        webkitdirectory=""
        multiple
        ref={inputFolderRef}
        style={{ display: "none" }}
        onChange={onFilesSelected}
      />
      {showFolder && (
        <MediumFilledButton
          text={"Upload Folder"}
          icon={UploadIcon()}
          onClick={handleClickUploadFolder}
          disabled={disabled}
        />
      )}
      {showDicom && (
        <MediumFilledButton
          text={"Upload DICOMs"}
          icon={UploadIcon()}
          onClick={handleClickUploadFiles}
          disabled={disabled}
        />
      )}
      {showCloud && (
        <MediumFilledButton
          text={"Configure Cloud Upload"}
          icon={UploadIcon()}
          onClick={onCloudUploadClicked}
          disabled={disabled}
        />
      )}
      {showDocuments && (
        <MediumFilledButton
          text={"Upload Document"}
          icon={UploadIcon()}
          onClick={onFileUploadClicked}
          disabled={disabled}
        />
      )}
    </ButtonRowWrapper>
  );
};

export default UploadButtons;
