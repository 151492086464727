import React, { ReactElement } from "react";
import styled from "styled-components";

import Text from "./Text";

interface ItemTextProps {
  text: string;
}

const StyledText = styled.div`
  color: ${(props) => props.theme.colors.neutral.black};
  font-size: 16px;
  font-weight: 600 !important;
`;

export default function ItemText({ text }: ItemTextProps): ReactElement {
  return (
    <StyledText>
      <Text text={text} />
    </StyledText>
  );
}
