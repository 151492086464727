import cornerstoneTools from "@altis-labs/cornerstone-tools";
import _ from "lodash/core";

import { ELLIPTICAL_ROI, ROI } from "../../../../../../../cornerstone/ToolType";

const SELECTED_CONTOUR_COLOR = "#ff00ff";
function clearRoiData(roiId, toolType) {
  const globalToolStates = cornerstoneTools.globalImageIdSpecificToolStateManager.toolState;

  Object.keys(globalToolStates).forEach((key) => {
    const sliceToolState = globalToolStates[key];

    if (_.has(sliceToolState, toolType)) {
      const roiToolData = sliceToolState[toolType].data;

      sliceToolState[toolType].data = roiToolData.filter((data) => data.roi.id !== roiId);
    }
  });
}

function getRoiToolStateDataForRoi(roi) {
  const roiToolStateData = [];

  const toolStates = cornerstoneTools.globalImageIdSpecificToolStateManager.toolState;

  Object.keys(toolStates).forEach(function (key) {
    const sliceToolState = toolStates[key];

    if (sliceToolState[roi.type]) {
      const roiToolData = sliceToolState[roi.type].data;

      roiToolData.forEach((data) => {
        data.imageId = key;
        if (data.roi && roi.id === data.roi.id) {
          roiToolStateData.push(data);
        }
      });
    }
  });

  return roiToolStateData;
}

function setToolStateColorForLesion(lesionColorPairs) {
  const toolStates = cornerstoneTools.globalImageIdSpecificToolStateManager.toolState;

  Object.keys(toolStates).forEach(function (key) {
    const sliceToolState = toolStates[key];

    if (sliceToolState[ROI]) {
      const roiToolData = sliceToolState[ROI].data;

      roiToolData.forEach((data) => {
        for (const lesionColor of lesionColorPairs) {
          if (data.roi && lesionColor.lesionId === data.roi.lesionId) {
            data.roi.color = lesionColor.color;
            data.roi.fillColor = lesionColor.color;
          }
        }
      });
    }
  });
}

function deleteToolDataForRoi(toolType, roiId) {
  const toolStates = cornerstoneTools.globalImageIdSpecificToolStateManager.toolState;

  Object.keys(toolStates).forEach(function (key) {
    let keepData = [];
    const sliceToolState = toolStates[key];

    if (sliceToolState[toolType]) {
      const roiToolData = sliceToolState[toolType].data;

      roiToolData.forEach((data) => {
        if (!data.roi || roiId !== data.roi.id) {
          keepData.push(data);
        }
      });
      sliceToolState[toolType].data = keepData;
    }
  });
}

function clearToolSliceData(toolType, filter) {
  const toolStates = cornerstoneTools.globalImageIdSpecificToolStateManager.toolState;

  Object.keys(toolStates).forEach(function (key) {
    const sliceToolState = toolStates[key];

    if (sliceToolState[toolType]) {
      if (!filter) {
        sliceToolState[toolType].data = [];
        return;
      }

      const { data: toolDataList } = sliceToolState[toolType];

      sliceToolState[toolType].data = toolDataList.filter((toolData) => filter(toolData));
    }
  });
}

function clearNonReadOnlyToolSliceData(toolType) {
  const readOnlyFilter = ({ readOnly }) => readOnly;
  clearToolSliceData(toolType, readOnlyFilter);
}

function getToolStateIndices(toolType, imageId, roiId) {
  const states = cornerstoneTools.globalImageIdSpecificToolStateManager.toolState;

  let imageToolStates = states[imageId];
  if (!imageToolStates) {
    return [];
  }

  let { data: imageToolState } = imageToolStates[toolType] ?? { data: null };
  if (!imageToolState) {
    return [];
  }

  let roiToolStates = [];

  imageToolState.forEach((state, index) => {
    let { roi } = state;
    if (roi.id === roiId) {
      roiToolStates.push(index);
    }
  });

  return roiToolStates;
}

function getToolSliceData(roiId, imageId, toolType) {
  const toolStates = cornerstoneTools.globalImageIdSpecificToolStateManager.toolState;

  let toolSliceData = [];

  Object.keys(toolStates).forEach(function (key) {
    let sliceToolState = toolStates[key];

    if (sliceToolState[toolType]) {
      const roiToolData = sliceToolState[toolType].data;

      roiToolData.forEach((data) => {
        if (data.roi && roiId === data.roi.id && imageId === key) {
          toolSliceData.push(data);
        }
      });
    }
  });

  return toolSliceData;
}

function appendToolSliceData(toolType, imageId, toolData) {
  const toolStates = cornerstoneTools.globalImageIdSpecificToolStateManager.toolState;

  let toolSliceData = [];

  let sliceToolStates = toolStates[imageId];
  if (!sliceToolStates) {
    toolStates[imageId] = {};
    sliceToolStates = toolStates[imageId];
  }

  let toolTypeSliceToolStates = sliceToolStates[toolType];
  if (!toolTypeSliceToolStates) {
    sliceToolStates[toolType] = { data: [] };
    toolTypeSliceToolStates = sliceToolStates[toolType];
  }

  toolData.forEach((data) => toolTypeSliceToolStates.data.push(data));

  return toolSliceData;
}

function setToolSliceData(toolType, roiId, imageId, toolData) {
  const toolStates = cornerstoneTools.globalImageIdSpecificToolStateManager.toolState;

  let toolSliceData = [];

  let sliceToolStates = toolStates[imageId];
  if (!sliceToolStates) {
    toolStates[imageId] = {};
    sliceToolStates = toolStates[imageId];
  }

  let toolTypeSliceToolStates = sliceToolStates[toolType];
  if (!toolTypeSliceToolStates) {
    sliceToolStates[toolType] = { data: [] };
    toolTypeSliceToolStates = sliceToolStates[toolType];
  }

  const existingToolDataForOtherRois = toolTypeSliceToolStates.data.filter(
    (data) => data?.roi.id !== roiId
  );

  toolTypeSliceToolStates.data = [...existingToolDataForOtherRois, ...toolData];

  return toolSliceData;
}

function setEllipseId(ellipseToolData, id) {
  const {
    handles: {
      start: { x: newStartX, y: newStartY },
      end: { x: newEndX, y: newEndY },
    },
  } = ellipseToolData;

  const toolStates = cornerstoneTools.globalImageIdSpecificToolStateManager.toolState;

  Object.keys(toolStates).forEach(function (key) {
    const sliceToolState = toolStates[key];

    const toolTypeSliceToolStates = sliceToolState[ELLIPTICAL_ROI];
    if (!toolTypeSliceToolStates) {
      return;
    }

    const { data } = toolTypeSliceToolStates;
    for (let i = 0; i < data.length; i++) {
      const {
        handles: {
          start: { x: existingStartX, y: existingStartY },
          end: { x: existingEndX, y: existingEndY },
        },
      } = data[i];

      if (
        existingStartX === newStartX &&
        existingStartY === newStartY &&
        existingEndX === newEndX &&
        existingEndY === newEndY
      ) {
        data[i] = { id, ...data[i] };
      }
    }
  });
}

function doesImageContainRoiToolData(imageId, toolType, roi) {
  const toolStates = cornerstoneTools.globalImageIdSpecificToolStateManager.toolState;

  let currentRoiToolState = [];

  if (_.has(toolStates, imageId)) {
    const imageToolState = toolStates[imageId];

    if (_.has(imageToolState, toolType)) {
      let roiToolState = imageToolState[toolType].data;

      currentRoiToolState = roiToolState.filter(
        (stateData) => stateData.roi && stateData.roi.id === roi.id
      );
    }
  }

  return currentRoiToolState.length >= 1;
}

export {
  appendToolSliceData,
  clearNonReadOnlyToolSliceData,
  clearRoiData,
  clearToolSliceData,
  deleteToolDataForRoi,
  doesImageContainRoiToolData,
  getRoiToolStateDataForRoi,
  getToolSliceData,
  getToolStateIndices,
  SELECTED_CONTOUR_COLOR,
  setEllipseId,
  setToolSliceData,
  setToolStateColorForLesion,
};
