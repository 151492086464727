import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { QueryResult } from "@apollo/client/react/types/types";

import type { CohortType } from "../../../../common/types/CohortType";
import { COHORT_FRAGMENT } from "../../../../Project/contexts/Project/CohortFragment";

export type Data = {
  cohorts: CohortType[];
};

export type Variables = { projectId: number };

export const QUERY = gql`
  query GetAllCohorts($projectId: Int!) {
    cohorts: label(
      where: {
        _and: [
          {
            _or: [
              { label_projects: { project_id: { _eq: $projectId } } }
              { project_id: { _eq: $projectId } }
            ]
          }
          { type_name: { _eq: "COHORT" } }
        ]
      }
    ) {
      ...Cohort
    }
  }
  ${COHORT_FRAGMENT}
`;

export function useAllCohorts(projectId: number): QueryResult<Data, Variables> {
  return useQuery<Data, Variables>(QUERY, {
    variables: { projectId },
    fetchPolicy: "no-cache",
  });
}
