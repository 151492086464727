import Tooltip from "@mui/material/Tooltip";
import withStyles from "@mui/styles/withStyles";
import React from "react";
import styled from "styled-components";

import { CountIndicator } from "../../common/components/ProjectManagement/Dialogs/CountIndicator";
import { main } from "../../common/theme/main";
import { ADD, REMOVE, updateModeColors, UpdateModeType } from "../../common/types/UpdateModeType";
import { AnatomicalStructureAnnotation } from "./AnatomicalStructureAnnotation";
import { AnatomicalStructureOptionType } from "./AnatomicalStructureOptionType";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;
  gap: 6px;
  padding: 12px;
  font-size: 14px;
  border: 1 px ${(props) => props.theme.colors.neutral.white};
  color: ${(props) => props.theme.colors.neutral.black};
  font-weight: 600;
  background: ${(props) => props.theme.colors.neutral.white};
`;

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: main.colors.neutral.white,
    color: main.colors.primary.primary90,
    borderColor: main.colors.neutral.neutral7,
    border: "1px solid",
  },
}))(Tooltip);

interface AnatomicalStructuresListProps {
  anatomicalStructures: AnatomicalStructureOptionType[];
}

export function AnatomicalStructuresList({
  anatomicalStructures,
}: AnatomicalStructuresListProps): JSX.Element {
  if (anatomicalStructures.length === 0) {
    return <>N/A</>;
  }

  const sorted = [...anatomicalStructures].sort(
    ({ value: { name: nameA } }, { value: { name: nameB } }) => nameA.localeCompare(nameB)
  );

  const existing = sorted.filter(({ state }) => !state);
  const added = sorted.filter(({ state }) => state === ADD);
  const removed = sorted.filter(({ state }) => state === REMOVE);

  return (
    <Wrapper>
      <AnatomicalStructuresSubList options={existing} maxItems={1} />
      <AnatomicalStructuresSubList options={added} maxItems={0} state={ADD} />
      <AnatomicalStructuresSubList options={removed} maxItems={0} state={REMOVE} />
    </Wrapper>
  );
}

interface AnatomicalStructuresSubListProps {
  options: AnatomicalStructureOptionType[];
  maxItems: number;
  state?: UpdateModeType;
}

function AnatomicalStructuresSubList({
  options,
  maxItems,
  state,
}: AnatomicalStructuresSubListProps): JSX.Element {
  const color = state ? updateModeColors[state] : main.colors.neutral.neutral3;

  const overflowCount = options.length - maxItems;

  function formatName(name: string) {
    return (
      <HtmlTooltip
        disableTouchListener
        disableFocusListener
        title={
          <ListWrapper>
            Annotations
            {options.map(({ value, state }, index) => (
              <AnatomicalStructureAnnotation
                key={index}
                anatomicalStructure={value}
                state={state}
              />
            ))}
          </ListWrapper>
        }
      >
        <Wrapper>
          {name}
          {overflowCount > 0 && (
            <CountIndicator
              size={10}
              count={overflowCount}
              background={color}
              formatCount={formatCount}
            />
          )}
        </Wrapper>
      </HtmlTooltip>
    );
  }

  function formatCount(count: number) {
    const operator = state ? (state === REMOVE ? "-" : "+") : "+";
    return `${operator}${count}`;
  }

  const labels = options
    .slice(0, maxItems)
    .map(({ value: anatomicalStructure, state }, index) => (
      <AnatomicalStructureAnnotation
        key={index}
        anatomicalStructure={anatomicalStructure}
        state={state}
        formatName={formatName}
      />
    ));

  return (
    <>
      {labels}
      {overflowCount > 0 && maxItems === 0 && (
        <CountIndicator count={overflowCount} background={color} formatCount={formatCount} />
      )}
    </>
  );
}
