import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Legend as ReChartsLegend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { CategoricalChartState } from "recharts/types/chart/generateCategoricalChart";
import styled from "styled-components";

import { ImageCaptureContextMenuContainer } from "../../../common/components/ImageCaptureContextMenuContainer";
import { GenericTooltip } from "../../../common/utils/barChartUtils/GenericTooltip";
import {
  Filter,
  FilterType,
  GraphView,
  IPRO,
  MORTALITY,
  RECIST,
  RECIST_AND_IPRO,
  RECIST_AND_MORTALITY,
} from "../../ChangeFromBaseline/components/Filter";
import { FollowUpAxisTick } from "../../common/components/FollowUpAxisTick";
import { Legend } from "../../common/components/Legend";
import { useFilteredProjectArmIds } from "../../common/hooks/useFilteredProjectArmIds";
import { useGetProjectArmName } from "../../common/hooks/useGetProjectArmName";
import { useGraphParams } from "../../common/hooks/useGraphParams";
import { armFilterColors } from "../../common/utils/armColors";
import { formatArmTitle } from "../../common/utils/formatArmTtitle";
import { formatDataType, formatDataTypeAxisLabel } from "../../common/utils/formatDataType";
import { formatFollowUp } from "../../common/utils/formatFollowUp";
import { getShouldShowGraphData } from "../../common/utils/getShouldShowGraphData";
import { getXAxisProps } from "../../common/utils/getXAxisProps";
import { getYAxisLabel, getYAxisLabelRight } from "../../common/utils/getYAxisLabel";
import { getYAxisProps } from "../../common/utils/getYAxisProps";
import { selectTimepointsFilters } from "../../ToolPanel/components/TimepointsFilters";
import { formatTooltipValue } from "../utils/formatTooltipValue";
import { AnalysisGraphKeys, FollowUpMedianGraphData, getDomains } from "../utils/getMedianSurvival";
import { tooltipItemSorter } from "../utils/tooltipItemSorter";
import { useSurvivalDistributionChartDialog } from "./useSurvivalDistributionChartDialog";

const Wrapper = styled.div`
  flex: 1;
  position: relative;
`;

export function getDeathTypes(filter: FilterType) {
  switch (filter) {
    case RECIST:
      return [RECIST];
    case MORTALITY:
      return [MORTALITY];
    case IPRO:
      return [IPRO];
    case RECIST_AND_IPRO:
      return [RECIST, IPRO];
    case RECIST_AND_MORTALITY:
      return [RECIST, MORTALITY];
  }
}

interface TumorBurdenSurvivalPredictionChartProps {
  medianSurvivalSeries: FollowUpMedianGraphData[];
  allowedDataTypes: FilterType[];
  graphViews?: GraphView[];
}

export default function TumorBurdenSurvivalPredictionChart({
  medianSurvivalSeries,
  allowedDataTypes,
  graphViews = ["DIAMETRIC", "VOLUMETRIC"],
}: TumorBurdenSurvivalPredictionChartProps): React.ReactElement {
  const projectArmIds = useFilteredProjectArmIds();
  const getProjectArmName = useGetProjectArmName();

  const { graphParams, setGraphParams } = useGraphParams();

  const [selectedFollowUp, setSelectedFollowUp] = useState<number | undefined>(undefined);
  const timepointsFilters = useSelector(selectTimepointsFilters);

  const deathTypes = getDeathTypes(graphParams.filter);

  const hasSurvival = allowedDataTypes.includes(IPRO);
  const hasMortality = allowedDataTypes.includes(MORTALITY);

  const { shouldShowBurden, shouldShowSurvival, shouldShowMortality } = getShouldShowGraphData(
    graphParams.filter
  );

  if (shouldShowSurvival && shouldShowMortality) {
    throw new Error(
      "TumorBurdenSurvivalPredictionChart: Survival and Mortality cannot be shown simultaneously"
    );
  }

  const [setDialogOpen, { dialog }] = useSurvivalDistributionChartDialog(
    {
      followUp: selectedFollowUp,
    },
    allowedDataTypes
  );

  const handleClickLine = (data: CategoricalChartState) => {
    if (!data) {
      return;
    }

    const { activePayload } = data;
    if (!activePayload) {
      return;
    }

    const firstPayload = activePayload.length > 0 ? activePayload[0] : null;
    if (!firstPayload) {
      return;
    }

    const {
      payload: { followUpOrder },
    } = firstPayload;

    handleFollowUpClicked(followUpOrder);
  };

  const handleFollowUpClicked = (followUpOrder: number) => {
    if (!timepointsFilters[followUpOrder]) {
      return;
    }

    setSelectedFollowUp(followUpOrder);
    openModal();
  };

  const openModal = () => {
    setDialogOpen(true);
  };

  const getData = (data: FollowUpMedianGraphData, arm: number, medianType: AnalysisGraphKeys) => {
    const medianData = data.byArm[arm];
    if (!medianData) {
      return null;
    }

    if (medianType === RECIST) {
      return medianData[RECIST][graphParams.view];
    } else {
      return medianData[medianType];
    }
  };

  const domains = getDomains(medianSurvivalSeries);
  const defaultDomain: [number, string] = [0, "auto"];

  const xAxisProps = getXAxisProps();
  const yAxisProps = getYAxisProps(true);

  const burdenYAxisLabel = getYAxisLabel(formatDataTypeAxisLabel(RECIST, graphParams.view));
  const survivalYAxisLabel = getYAxisLabelRight(formatDataTypeAxisLabel(IPRO, graphParams.view));
  const mortalityYAxisLabel = getYAxisLabelRight(
    formatDataTypeAxisLabel(MORTALITY, graphParams.view)
  );

  return (
    <>
      {dialog}
      <Wrapper>
        <ImageCaptureContextMenuContainer>
          {({ reference }) => (
            <ResponsiveContainer ref={reference} height={540}>
              <LineChart
                onClick={handleClickLine}
                margin={{ top: 32, right: 20, left: 20, bottom: 40 }}
                data={medianSurvivalSeries}
              >
                <XAxis
                  {...xAxisProps}
                  allowDuplicatedCategory={false}
                  dataKey="followUpOrder"
                  padding={{ left: 10 }}
                  tick={
                    <FollowUpAxisTick
                      onClickFollowUp={handleFollowUpClicked}
                      useDisabledFollowUps={true}
                    />
                  }
                />
                <YAxis
                  {...yAxisProps}
                  yAxisId={RECIST}
                  orientation={"left"}
                  domain={shouldShowBurden ? domains[RECIST][graphParams.view] : defaultDomain}
                >
                  {shouldShowBurden && burdenYAxisLabel}
                </YAxis>
                {(hasSurvival || hasMortality) && (
                  <YAxis
                    {...yAxisProps}
                    yAxisId={shouldShowSurvival ? IPRO : MORTALITY}
                    orientation={"right"}
                    domain={
                      shouldShowSurvival || shouldShowMortality
                        ? shouldShowSurvival
                          ? domains[IPRO]
                          : domains[MORTALITY]
                        : defaultDomain
                    }
                  >
                    {shouldShowSurvival && survivalYAxisLabel}
                    {shouldShowMortality && mortalityYAxisLabel}
                  </YAxis>
                )}
                <Tooltip
                  content={<GenericTooltip />}
                  isAnimationActive={false}
                  formatter={(value: number, name: string) =>
                    formatTooltipValue(value, name, graphParams.view)
                  }
                  labelFormatter={formatFollowUp}
                  itemSorter={(item) => tooltipItemSorter(item, domains, graphParams.view)}
                />
                <ReChartsLegend align="left" verticalAlign="top" height={48} content={<Legend />} />
                {deathTypes.flatMap((medianType) =>
                  projectArmIds.map((arm) => {
                    const dataType = medianType as AnalysisGraphKeys;
                    const { color, width, dashArray } = armFilterColors[arm][dataType];
                    const projectArmName = getProjectArmName(arm);
                    const name = formatArmTitle(
                      projectArmName,
                      formatDataType(dataType, graphParams.view)
                    );

                    return (
                      <Line
                        isAnimationActive={false}
                        key={`${medianType}_${arm}`}
                        type="linear"
                        yAxisId={medianType}
                        dataKey={(data) => getData(data, arm, dataType)}
                        stroke={color}
                        strokeWidth={width}
                        strokeDasharray={dashArray}
                        name={name}
                      />
                    );
                  })
                )}
              </LineChart>
            </ResponsiveContainer>
          )}
        </ImageCaptureContextMenuContainer>
        <Filter
          allowedDataTypes={allowedDataTypes}
          graphViews={graphViews}
          onGraphParamsChanged={setGraphParams}
          activeFilter={graphParams.filter}
          activeView={graphParams.view}
        />
      </Wrapper>
    </>
  );
}
