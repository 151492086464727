import { useContext } from "react";

import { ToolType } from "../../../../../../../cornerstone/ToolType";
import { ViewerContext } from "../ViewerContext";

export function useSetActiveTool(): (tool: ToolType) => void {
  const { setActiveTool } = useContext(ViewerContext);

  return (tool) => {
    setActiveTool(tool);
  };
}
