import {
  CANCEL_QUEUE,
  DRAW_QUEUE,
  MEASURE_QUEUE,
  ViewerConfigType,
  WINDOW_LEVEL_PRESET_QUEUE,
} from "../../../../../../common/store/annotatePage/ViewerConfigType";
import { main } from "../../../../../../common/theme/main";

const viewerColors: string[] = [
  main.colors.states.error,
  main.colors.states.info,
  main.colors.states.warning,
  main.colors.accent.accent1,
  main.colors.accent.accent2Light,
  main.colors.states.success,
];

export const MAX_NUMBER_OF_VIEWERS = viewerColors.length;

export function getViewerConfigFromSeriesId(seriesId: number, id: number): ViewerConfigType {
  return {
    id,
    color: viewerColors[id],
    isActive: id === 0,
    seriesId,
    toolQueues: {
      [DRAW_QUEUE]: false,
      [MEASURE_QUEUE]: false,
      [CANCEL_QUEUE]: false,
      [WINDOW_LEVEL_PRESET_QUEUE]: null,
    },
  };
}
