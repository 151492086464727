import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { QueryResult } from "@apollo/client/react/types/types";

import { CohortType } from "../../../../common/types/CohortType";
import { COHORT_FRAGMENT } from "../../../../Project/contexts/Project/CohortFragment";

export type Data = {
  cohorts: CohortType[];
};

export type Variables = { projectId: number };

export const QUERY = gql`
  query GetProjectCohorts($projectId: Int!) {
    cohorts: label(where: { type_name: { _eq: "COHORT" }, project_id: { _eq: $projectId } }) {
      ...Cohort
    }
  }
  ${COHORT_FRAGMENT}
`;

export function useProjectCohorts(projectId: number): QueryResult<Data, Variables> {
  return useQuery<Data, Variables>(QUERY, {
    variables: { projectId },
  });
}
