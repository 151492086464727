import { LesionListItemFragmentType } from "../../fragments/LesionListItemFragment";
import { RoiClassificationButtonProps } from "../../RoiCard/RoiClassificationButtons";
import { getRoiClassificationButton } from "../../utils/getRoiClassificationButton";
import { useGetTimepointOptionsForLesion } from "./useGetTimepointOptionsForLesion";

export type MissingRoiClassificationButtonsReturnType = (
  lesion: LesionListItemFragmentType
) => RoiClassificationButtonProps[];

export function useGetMissingRoiClassificationButtons(): MissingRoiClassificationButtonsReturnType {
  const getTimepointOptionsForLesion = useGetTimepointOptionsForLesion();

  return (lesion) => {
    const buttons: RoiClassificationButtonProps[] = [];

    const options = getTimepointOptionsForLesion(lesion);

    for (const option of options) {
      const { showWhenDoesNotExist } = option;
      if (!showWhenDoesNotExist) {
        continue;
      }

      const button = getRoiClassificationButton(option, false);
      buttons.push(button);
    }

    return buttons;
  };
}
