import React from "react";
import styled from "styled-components";

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 20px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  gap: 24px;
`;

const ColumnWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const TableCard = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.actionPrimary.inverted};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  padding: 24px;
  gap: 12px;

  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
`;

const IncompleteAssignmentsWrapper = styled(ColumnWrapper)`
  flex: 2;
`;

const CompleteAssignmentsWrapper = styled(ColumnWrapper)`
  flex: 1;
`;

interface WorklistLayoutProps {
  pendingComponent: JSX.Element;
  inProgressComponent: JSX.Element;
  completedComponent: JSX.Element;
  filterComponent: JSX.Element;
}

export function WorklistLayout({
  pendingComponent,
  inProgressComponent,
  completedComponent,
  filterComponent,
}: WorklistLayoutProps): JSX.Element {
  return (
    <FilterWrapper>
      {filterComponent}
      <Wrapper>
        <ColumnWrapper>
          <TableCard>
            Pending
            {pendingComponent}
          </TableCard>
        </ColumnWrapper>
        <ColumnWrapper>
          <IncompleteAssignmentsWrapper>
            <TableCard>
              In Progress
              {inProgressComponent}
            </TableCard>
          </IncompleteAssignmentsWrapper>
          <CompleteAssignmentsWrapper>
            <TableCard>
              Completed
              {completedComponent}
            </TableCard>
          </CompleteAssignmentsWrapper>
        </ColumnWrapper>
      </Wrapper>
    </FilterWrapper>
  );
}
