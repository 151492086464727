import { useCallback } from "react";

import { useGetAuthToken } from "../../../../../../../auth0/useGetAuthToken";
import { useGetSeriesImageIds } from "../../../ViewerPanel/Viewer/hooks/useGetSeriesImageIds";
import { Mask } from "../../MaskContextProvider";
import { useLesionColorDeferred } from "../../utils/useLesionColorDeferred";
import { downloadMask } from "../utils/downloadMask";
import { loadMask } from "../utils/loadMask";

export function useDownloadAndLoadMask() {
  const getSeriesImageIds = useGetSeriesImageIds();
  const getLesionColor = useLesionColorDeferred();

  const getToken = useGetAuthToken();

  return useCallback(
    async (mask: Mask) => {
      const { id, seriesId, anatomicalStructureId } = mask;

      const imageIds = getSeriesImageIds(seriesId);
      if (imageIds.length === 0) {
        throw new Error(`No image ids found for series ${seriesId}`);
      }

      const firstImageId = imageIds[0];

      const color = getLesionColor(anatomicalStructureId);

      const token = await getToken();

      let success = true;
      try {
        const buffer = await downloadMask(id, token);
        loadMask(mask, color, buffer, firstImageId, imageIds.length);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        success = false;
        console.error(`Failed to download mask ${id}: ${e?.message ?? "N/A"}`);
      }

      return success;
    },
    [getLesionColor, getSeriesImageIds]
  );
}
