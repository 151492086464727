import { useSelector } from "react-redux";

import { FilterTypes, getProjectViewFilters } from "../../../common/store/projectViewFiltersSlice";
import { SubjectType } from "../types/SubjectType";
import { applyTimepointLevelFilters } from "../utils/filters/applyTimepointLevelFilters";
import { useFilteredSubjectsQuery } from "./useFilteredSubjectsQuery";

function filter(subjects: SubjectType[], filters: FilterTypes): SubjectType[] {
  // Filter the follow-ups of each subject
  const filteredSubjects = subjects.map((subject) => {
    const followUps = subject.followUps.filter(applyTimepointLevelFilters(filters));
    return { ...subject, followUps };
  });

  // Remove subjects with no follow-ups
  return filteredSubjects.filter((subject) => subject.followUps.length > 0);
}

// NOTE: The subjects returned by this hook are also filtered by subject filters
export function useTimepointFilteredSubjectsQuery(): SubjectType[] {
  const subjects = useFilteredSubjectsQuery();

  const filters = useSelector(getProjectViewFilters);

  // NOTE: Assumes follow_ups are ordered by descending fu_order
  return filter(subjects, filters);
}
