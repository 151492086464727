import { useContext, useReducer } from "react";
import { useDeepCompareEffect } from "use-deep-compare";

import { ContourContext } from "../../../../../ContourContext";

export function useContourListener(roiIds: number[]): void {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const { pushCallback, removeCallback } = useContext(ContourContext);
  useDeepCompareEffect(() => {
    const callbackLocation = pushCallback(() => forceUpdate(), roiIds);

    return () => {
      removeCallback(callbackLocation);
    };
  }, [roiIds]);
}
