import { gql } from "@apollo/client/core";

export const ROI_RECIST_EVALUATION_FRAGMENT = gql`
  fragment InsertRoiRecistEvaluationFragment on roi_recist_evaluations {
    roiId: roi_id
    SAD: short_axis_diameter
    LAD: long_axis_diameter
    volume
    SADPoints: short_axis_diameter_points
    LADPoints: long_axis_diameter_points
    instanceId: instance_id
    huMin: hu_min
    huMax: hu_max
    huMean: hu_mean
  }
`;

export type RoiRecistEvaluationFragmentType = {
  roiId: number;
  LAD?: number;
  SAD?: number;
  volume?: number;
  SADPoints?: DiameterPointsType;
  LADPoints?: DiameterPointsType;
  instanceId?: number;
  huMin: number | null;
  huMax: number | null;
  huMean: number | null;
};

export type DiameterPointsType = {
  start: Point;
  end: Point;
};

export type Point = { x: number; y: number };
