import { ProjectStatusType } from "../../Dashboard/utils/ProjectStatusType";
import { parseUserFragment, UserFragmentType } from "../../Settings/UserFragment";
import { CohortType } from "./CohortType";
import { ProjectArmType } from "./ProjectArmType";
import { SiteType } from "./SiteType";
import { UserRoleType } from "./UserRoleType";
import { UserType } from "./UserType";

export type ProjectType = {
  id: number;
  name: string;
  disabled: boolean;
  estimatedCompletion: Date;
  startDate: Date;
  status: ProjectStatusType;
  indicationName: string;
  investigationName: string;
  arms: ProjectArmType[];
  sites: SiteType[];
  cohorts: { cohort: CohortType }[];
  users: { user: UserFragmentType }[];
  projectUserRoles: { userId: number; role: UserRoleType }[];
  legacyTrialId?: number;
  anonymizationMethods: {
    id: number;
    params: any;
  }[];
};

export function getProjectUsers({ users, projectUserRoles }: ProjectType): UserType[] {
  return users.map(({ user }) => {
    const { id: userId } = user;

    const projectRoles = projectUserRoles
      .filter(({ userId: roleUserId }) => roleUserId === userId)
      .map(({ role }) => role);

    const parsedUser = parseUserFragment(user);
    return { ...parsedUser, projectRoles };
  });
}

export function unpackProjectUsers(
  users: UserType[],
  projectId: number
): Pick<ProjectType, "users" | "projectUserRoles"> {
  const unpackedUsers = users.map((user) => {
    const { globalRoles } = user;
    const unpackedGlobalRoles = globalRoles.map((role) => ({ role }));
    return {
      user: {
        ...user,
        projectRoles: undefined,
        globalRoles: unpackedGlobalRoles,
      },
    };
  });
  const projectUserRoles = users.flatMap(({ id: userId, projectRoles = [] }) =>
    projectRoles.map((role) => ({ project_id: projectId, userId, role }))
  );

  return { users: unpackedUsers, projectUserRoles };
}
