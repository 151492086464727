import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { MutationTuple } from "@apollo/client/react/types/types";

import { SERIES_FLAG_FRAGMENT } from "../../../../../fragments/SeriesFlagFragment";
import { SeriesFlagType } from "../../../../../types/SeriesFlagType";
import { getCacheId } from "../../../../Manage/cache/getCacheId";

type Data = {
  seriesFlag: SeriesFlagType;
  insertSeriesFlagReason: { inserted: { id: number; seriesFlagId: number }[] };
  deleteSeriesFlagReason: { deleted: { id: number; seriesFlagId: number }[] };
};

type Variables = {
  id: number;
  notes: string | null;
  created_by: number;
  insertReasons: { reason: string; series_flag_id: number }[];
  deleteReasonIds: number[];
};

const MUTATION = gql`
  mutation UpdateSeriesFlag(
    $id: Int!
    $notes: String
    $created_by: Int!
    $insertReasons: [series_flag_reason_insert_input!]!
    $deleteReasonIds: [Int!]!
  ) {
    seriesFlag: update_series_flag_by_pk(
      pk_columns: { id: $id }
      _set: { notes: $notes, created_by: $created_by }
    ) {
      ...SeriesFlag
    }
    insertSeriesFlagReason: insert_series_flag_reason(objects: $insertReasons) {
      inserted: returning {
        id
        seriesFlagId: series_flag_id
      }
    }
    deleteSeriesFlagReason: delete_series_flag_reason(where: { id: { _in: $deleteReasonIds } }) {
      deleted: returning {
        id
        seriesFlagId: series_flag_id
      }
    }
  }
  ${SERIES_FLAG_FRAGMENT}
`;

export function useUpdateSeriesFlag(): MutationTuple<Data, Variables> {
  return useMutation<Data, Variables>(MUTATION, {
    update(cache, { data }) {
      if (!data) {
        throw new Error("Something went wrong updating the cache after inserting a series flag");
      }

      const {
        deleteSeriesFlagReason: { deleted },
        insertSeriesFlagReason: { inserted },
      } = data;

      for (const { id, seriesFlagId } of inserted) {
        const seriesFlagCacheId = getCacheId(seriesFlagId, "series_flag", cache);

        cache.modify({
          id: seriesFlagCacheId,
          fields: {
            series_flag_reasons(currentReasonRefs = [], { readField }) {
              if (currentReasonRefs.some((ref: never) => readField("id", ref) === id)) {
                return currentReasonRefs;
              }

              const newReasonRef = {
                __typename: "series_flag_reason",
                id,
              };
              return [...currentReasonRefs, newReasonRef];
            },
          },
        });
      }

      for (const { id, seriesFlagId } of deleted) {
        const seriesFlagCacheId = getCacheId(seriesFlagId, "series_flag", cache);
        cache.modify({
          id: seriesFlagCacheId,
          fields: {
            series_flag_reasons(currentReasonRefs = [], { readField }) {
              return currentReasonRefs.filter((ref: never) => id !== readField("id", ref));
            },
          },
        });

        const seriesFlagReasonId = getCacheId(id, "series_flag_reason", cache);
        cache.evict({
          id: seriesFlagReasonId,
        });
      }
    },
  });
}
