import { useQuery } from "@apollo/client";
import React, { FC } from "react";

import { useProjectId } from "../../../Project/hooks/useProjectId";
import { GET_PROJECT_DETAILS_BY_IDS } from "../../queries/GetProjectDetailsByIds";
import { RawProjectDetailsType } from "../../types/RawProjectDetailsType";
import handleApolloError from "../../utils/handleApolloError";
import { FlexLoading } from "../Loading";
import parseProjectDetailsData from "./parseProjectDetailsData";
import ProjectDetailsPanel from "./ProjectDetailsPanel";

export const ProjectDetailsPanelContainer: FC = () => {
  const projectId = useProjectId();
  const { data, loading, error } = useQuery<RawProjectDetailsType>(GET_PROJECT_DETAILS_BY_IDS, {
    variables: { projectIds: [projectId] },
  });

  if (loading || !data) return <FlexLoading />;
  if (error) handleApolloError(error);
  const projectDetails = parseProjectDetailsData(data);
  return <ProjectDetailsPanel details={projectDetails} />;
};
