import { RoiId } from "../../ViewerPanel/selection/RoiId";
import { useSetViewerImageId } from "../../ViewerPanel/Viewer/hooks/useSetViewerImageId";
import { useGetRoiKeyImageId } from "./useGetRoiKeyImageId";

export function useJumpToViewerRoiKeyImage() {
  const setViewerImageId = useSetViewerImageId();
  const getRoiKeyImageId = useGetRoiKeyImageId();

  return (roi: RoiId, viewerConfigId: number) => {
    const imageId = getRoiKeyImageId(roi);
    if (!imageId) {
      return;
    }

    setViewerImageId(viewerConfigId, imageId);
  };
}
