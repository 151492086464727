import React from "react";
import styled from "styled-components";

import { TimepointOptionsType } from "../../../../../types/TaskDescriptionType";
import { ClassificationButton } from "./ClassificationButton";

type JustifyContentType = "center" | "flex-start" | "flex-end";

const Wrapper = styled.div<{
  justifyContent: JustifyContentType;
}>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: stretch;
  flex-wrap: wrap;
  flex: 1;
  gap: 3px;
`;

export type RoiClassificationButtonProps = TimepointOptionsType & {
  isSet: boolean;
};

interface RoiClassificationButtonsProps {
  buttons: RoiClassificationButtonProps[];
  onClickClassification: (options: TimepointOptionsType) => void;
  viewOnly: boolean;
  loading?: boolean;
  justifyContent?: JustifyContentType;
}

export function RoiClassificationButtons({
  viewOnly,
  buttons,
  onClickClassification,
  loading,
  justifyContent = "center",
}: RoiClassificationButtonsProps): JSX.Element {
  const handleButtonClicked = (option: TimepointOptionsType) => {
    if (viewOnly) {
      return;
    }

    onClickClassification(option);
  };

  return (
    <Wrapper justifyContent={justifyContent}>
      {buttons.map((button, index) => {
        return (
          <ClassificationButton
            option={button}
            isSet={false}
            onClick={handleButtonClicked}
            loading={loading}
            viewOnly={viewOnly}
            key={index}
          />
        );
      })}
    </Wrapper>
  );
}
