import React from "react";
import {
  Bar,
  BarChart,
  Cell,
  Legend as ReChartsLegend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  YAxis,
} from "recharts";
import { CategoricalChartState } from "recharts/types/chart/generateCategoricalChart";
import { Payload as LegendPayload } from "recharts/types/component/DefaultLegendContent";
import { Payload } from "recharts/types/component/DefaultTooltipContent";
import styled from "styled-components";

import { ImageCaptureContextMenuContainer } from "../../../common/components/ImageCaptureContextMenuContainer";
import { GenericTooltip } from "../../../common/utils/barChartUtils/GenericTooltip";
import { Legend } from "../../common/components/Legend";
import { useFilteredProjectArmIds } from "../../common/hooks/useFilteredProjectArmIds";
import { useGetProjectArmName } from "../../common/hooks/useGetProjectArmName";
import { useGraphParams } from "../../common/hooks/useGraphParams";
import { armFilterColors } from "../../common/utils/armColors";
import { formatDataType, formatDataTypeAxisLabel } from "../../common/utils/formatDataType";
import { formatTicksEveryOther } from "../../common/utils/formatTicksEveryOther";
import { getTicksByNumber } from "../../common/utils/getChartTicks";
import { getShouldShowGraphData } from "../../common/utils/getShouldShowGraphData";
import { getYAxisLabel, getYAxisLabelRight } from "../../common/utils/getYAxisLabel";
import { getYAxisProps } from "../../common/utils/getYAxisProps";
import {
  REFERENCE_LINE_STOKE,
  REFERENCE_LINE_STROKE_DASH_ARRAY,
} from "../../common/utils/strokeSettings";
import { formatTooltipValue } from "../../MedianSurvival/utils/formatTooltipValue";
import { getDataTypeFromName } from "../../MedianSurvival/utils/getDataTypeFromName";
import { AnalysisGraphKeys } from "../../MedianSurvival/utils/getMedianSurvival";
import { SubjectMortalityVsBaselineType } from "../utils/parseMortalityBaselineChartData";
import {
  Filter,
  FilterType,
  GraphView,
  IPRO,
  MORTALITY,
  RECIST,
  RECIST_AND_IPRO,
  RECIST_AND_MORTALITY,
} from "./Filter";

const NUMBER_OF_TICKS = 10;
const MIN_TICK_SCALE = 5;

const Wrapper = styled.div`
  flex: 1;
  position: relative;
`;

const Text = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
`;

interface MortalityBaselineChartProps {
  subjectsData: SubjectMortalityVsBaselineType[];
  onClickPatient?: (patientId: number) => void;
  allowedDataTypes: FilterType[];
  graphViews?: GraphView[];
}

function MortalityChangefromBaselineChart({
  subjectsData,
  onClickPatient,
  allowedDataTypes,
  graphViews = ["DIAMETRIC", "VOLUMETRIC"],
}: MortalityBaselineChartProps): React.ReactElement {
  const projectArmIds = useFilteredProjectArmIds();
  const getProjectArmName = useGetProjectArmName();
  const { graphParams, setGraphParams } = useGraphParams();

  let sorted = [...subjectsData];
  const sortBy =
    graphParams.filter === RECIST_AND_IPRO || graphParams.filter === RECIST_AND_MORTALITY
      ? RECIST
      : graphParams.filter;
  sorted = sorted.sort((a, b) => {
    if (sortBy === RECIST) {
      return a.data[RECIST][graphParams.view] < b.data[RECIST][graphParams.view] ? 1 : -1;
    } else {
      return a.data[sortBy] < b.data[sortBy] ? 1 : -1;
    }
  });

  const hasSurvival = allowedDataTypes.includes(IPRO);
  const hasMortality = allowedDataTypes.includes(MORTALITY);

  const allBurdens = sorted.map((s) => s.data[RECIST][graphParams.view]);
  const allSurvivals = hasSurvival ? sorted.map((s) => s.data[IPRO]) : [];
  const allMortalities = hasMortality ? sorted.map((s) => s.data[MORTALITY]) : [];

  const allData = [...allBurdens, ...allSurvivals, ...allMortalities];
  const allMax = allData.length > 0 ? Math.ceil(Math.max(...allData)) : 0;
  const allMin = allData.length > 0 ? Math.floor(Math.min(...allData)) : 0;

  const legendPayload: LegendPayload[] = projectArmIds.map((projectArm) => {
    const { color } = armFilterColors[projectArm][RECIST];
    const name = getProjectArmName(projectArm);
    return {
      id: name,
      type: "square",
      value: name,
      color: color,
    };
  });

  const handleClickPatient = (data: CategoricalChartState) => {
    if (!data || !onClickPatient) {
      return;
    }

    const { activePayload } = data;
    if (!activePayload) {
      return;
    }

    const firstPayload = activePayload.length > 0 ? activePayload[0] : null;
    if (!firstPayload) {
      return;
    }

    const {
      payload: { id },
    } = firstPayload;

    onClickPatient(id);
  };

  const yAxisProps = getYAxisProps(true);

  const customCell = (filter: AnalysisGraphKeys) => {
    return sorted.map((entry, index) => {
      const {
        projectArm: { number },
      } = entry;
      const { color } = armFilterColors[number][filter];
      return <Cell key={index} fill={color} />;
    });
  };

  const { shouldShowBurden, shouldShowSurvival, shouldShowMortality } = getShouldShowGraphData(
    graphParams.filter
  );

  if (shouldShowSurvival && shouldShowMortality) {
    throw new Error(
      "TumorBurdenSurvivalPredictionChart: Survival and Mortality cannot be shown simultaneously"
    );
  }

  const burdenYAxisLabel = getYAxisLabel(formatDataTypeAxisLabel(RECIST, graphParams.view, "%"));
  const survivalYAxisLabel = getYAxisLabelRight(
    formatDataTypeAxisLabel(IPRO, graphParams.view, "%")
  );
  const mortalityYAxisLabel = getYAxisLabelRight(
    formatDataTypeAxisLabel(MORTALITY, graphParams.view, "%")
  );

  const ticks = getTicksByNumber(allMin, allMax, NUMBER_OF_TICKS, MIN_TICK_SCALE);
  const domain = [allMin, allMax];

  return (
    <Wrapper>
      <ImageCaptureContextMenuContainer>
        {({ reference }) => (
          <ResponsiveContainer ref={reference} height={423}>
            <BarChart
              onClick={handleClickPatient}
              data={sorted}
              margin={{
                top: 32,
                right: 20,
                left: 20,
                bottom: 20,
              }}
              barGap={0}
              barCategoryGap={0}
            >
              <Tooltip
                content={
                  <GenericTooltip
                    summaryFormatter={formatTooltipSummary}
                    getColor={(payload) => getTooltipColor(payload, graphParams.view)}
                  />
                }
                isAnimationActive={false}
                formatter={(value: number, name: string) =>
                  formatTooltipValue(value, name, graphParams.view, "%")
                }
                labelFormatter={formatTooltipLabel}
              />
              <ReChartsLegend
                payload={legendPayload}
                align="left"
                verticalAlign="top"
                height={48}
                content={<Legend />}
              />
              <YAxis
                {...yAxisProps}
                yAxisId={RECIST}
                orientation={"left"}
                tickFormatter={formatTicksEveryOther}
                ticks={shouldShowBurden ? ticks : []}
                type={"number"}
                domain={shouldShowBurden ? domain : [0, "auto"]}
              >
                {shouldShowBurden && burdenYAxisLabel}
              </YAxis>
              {(hasSurvival || hasMortality) && (
                <YAxis
                  {...yAxisProps}
                  yAxisId={shouldShowSurvival ? IPRO : MORTALITY}
                  orientation={"right"}
                  tickFormatter={formatTicksEveryOther}
                  ticks={shouldShowSurvival || shouldShowMortality ? ticks : []}
                  domain={shouldShowSurvival || shouldShowMortality ? domain : [0, "auto"]}
                >
                  {shouldShowSurvival && survivalYAxisLabel}
                  {shouldShowMortality && mortalityYAxisLabel}
                </YAxis>
              )}
              {shouldShowBurden && (
                <ReferenceLine
                  y={20}
                  stroke={REFERENCE_LINE_STOKE}
                  strokeDasharray={REFERENCE_LINE_STROKE_DASH_ARRAY}
                  yAxisId={RECIST}
                />
              )}
              {shouldShowBurden && (
                <ReferenceLine
                  y={-30}
                  stroke={REFERENCE_LINE_STOKE}
                  strokeDasharray={REFERENCE_LINE_STROKE_DASH_ARRAY}
                  yAxisId={RECIST}
                />
              )}
              {shouldShowBurden && (
                <Bar
                  dataKey={`data[${RECIST}][${graphParams.view}]`}
                  yAxisId={RECIST}
                  name={formatDataType(RECIST, graphParams.view)}
                  isAnimationActive={false}
                >
                  {customCell(RECIST)}
                </Bar>
              )}
              {shouldShowSurvival && (
                <Bar
                  dataKey={`data[${IPRO}]`}
                  yAxisId={IPRO}
                  name={formatDataType(IPRO, graphParams.view)}
                  isAnimationActive={false}
                >
                  {customCell(MORTALITY)}
                </Bar>
              )}
              {shouldShowMortality && (
                <Bar
                  dataKey={`data[${MORTALITY}]`}
                  yAxisId={MORTALITY}
                  name={formatDataType(MORTALITY, graphParams.view)}
                  isAnimationActive={false}
                >
                  {customCell(MORTALITY)}
                </Bar>
              )}
            </BarChart>
          </ResponsiveContainer>
        )}
      </ImageCaptureContextMenuContainer>
      <Filter
        allowedDataTypes={allowedDataTypes}
        graphViews={graphViews}
        onGraphParamsChanged={setGraphParams}
        activeFilter={graphParams.filter}
        activeView={graphParams.view}
      />
    </Wrapper>
  );
}

export default MortalityChangefromBaselineChart;

const formatTooltipLabel = () => {
  return "Subject ID";
};

const formatTooltipSummary = (payload: Array<Payload<number, string>>) => {
  if (payload.length === 0) {
    return "";
  }

  const {
    payload: { subjectId },
  } = payload[0];
  return <Text>{subjectId}</Text>;
};

// this is a roundabout way to get the color of the tooltip item working correctly. the problem is that recharts uses fixed colors for Bars, but the colors for each bar needs to be determined dynamically (via using a custom Cell). When doing this, the color isn't available in the tooltip's payload, so we have to have a way of looking up the color based on the payload, which is implemented here.
const getTooltipColor = (
  { name, payload: originalPayload }: Payload<number, string>,
  graphView: GraphView
) => {
  if (!name || !originalPayload) {
    return undefined;
  }

  const dataType = getDataTypeFromName(name, graphView);
  if (!dataType) {
    throw new Error(`Unknown data type: ${name}`);
  }

  const payload = originalPayload as SubjectMortalityVsBaselineType;
  const {
    projectArm: { number },
  } = payload;

  const { color } = armFilterColors[number][dataType];
  return color;
};
