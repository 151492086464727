import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { QueryResult } from "@apollo/client/react/types/types";

const GET_TRIAL_QUERY = gql`
  query GetTrialFromProjectId($projectId: Int!) {
    trial(where: { trial_projects: { project_id: { _eq: $projectId } } }) {
      id
    }
  }
`;

type DataType = {
  trial: { id: number }[];
};

type Variables = {
  projectId: number;
};

export function useGetTrialFromProject(projectId: number): QueryResult<DataType> {
  return useQuery<DataType, Variables>(GET_TRIAL_QUERY, {
    variables: { projectId },
  });
}
