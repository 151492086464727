import { gql } from "@apollo/client/core";

import { ELLIPSE_FRAGMENT } from "../../../../../fragments/EllipseFragment";
import { EllipseFragmentType } from "../../../../../fragments/EllipseFragmentType";
import { ClassificationType } from "../types/ClassificationType";
import { ROI_CLASSIFICATION_FRAGMENT } from "./RoiClassificationFragment";
import {
  ROI_LIST_ITEM_TUMOUR_FRAGMENT,
  RoiListItemTumourFragmentType,
} from "./RoiListItemTumourFragment";
import {
  ROI_RECIST_EVALUATION_FRAGMENT,
  RoiRecistEvaluationFragmentType,
} from "./RoiRecistEvaluationFragment";

export const ROI_LIST_ITEM_FRAGMENT = gql`
  fragment RoiListItem on roi {
    id: roi_id
    series {
      id: series_id
    }
    ellipse {
      ...Ellipse
    }
    contours {
      id: contour_id
      instanceId: instance_id
    }
    masks: ml_masks(where: { disabled: { _eq: false } }) {
      id
      model: ml_model {
        name
        version
        displayName: display_name
      }
    }
    classifications: roi_classifications {
      ...RoiClassification
    }
    roiRecistEvaluation: roi_recist_evaluation {
      ...InsertRoiRecistEvaluationFragment
    }
    tumours: tumour_roi_maps @skip(if: $skipRecist) {
      tumour {
        id
        ...RoiTumour
      }
    }
  }
  ${ROI_CLASSIFICATION_FRAGMENT}
  ${ROI_RECIST_EVALUATION_FRAGMENT}
  ${ROI_LIST_ITEM_TUMOUR_FRAGMENT}
  ${ELLIPSE_FRAGMENT}
`;

export type RoiListItemFragmentType = {
  id: number;
  series: { id: number };
  ellipse: EllipseFragmentType | null;
  contours: ContourListItemFragmentType[];
  masks: MaskListItemFragmentType[];
  classifications: ClassificationType[];
  tumours?: { tumour: RoiListItemTumourFragmentType }[];
  roiRecistEvaluation: RoiRecistEvaluationFragmentType | null;
};

export type ContourListItemFragmentType = {
  id: number;
  instanceId: number;
};

export type MaskListItemFragmentType = {
  id: number;
  model: {
    name: string;
    version: string;
    displayName: string;
  };
};
