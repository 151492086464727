import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";

export function initCornerstoneWadoImageLoaderAuth(token: string): void {
  cornerstoneWADOImageLoader.configure({
    beforeSend: (xhr: { setRequestHeader: (arg0: string, arg1: string) => void }) => {
      if (!token) {
        throw new Error("Token is undefined in cornerstoneWADOImageLoader beforeSend");
      }
      xhr.setRequestHeader("Authorization", "Bearer " + token);
    },
  });
}
