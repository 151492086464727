import { FaCircleChevronRight, FaXRay } from "react-icons/fa6";
import { Column } from "react-table";
import styled from "styled-components";

import { PatientMutableTaskType } from "../../../Annotate/components/Manage/hooks/TaskType";
import {
  CompleteTaskProgressType,
  TASK_PENDING,
} from "../../../Annotate/components/TaskWorklist/TaskProgressType";
import { ReactComponent as CheckIcon } from "../../../assets/svgs/CheckFilled.svg";
import { ReactComponent as LaunchIcon } from "../../../assets/svgs/Launch.svg";
import { ReactComponent as RemoveCircleIcon } from "../../../assets/svgs/RemoveCircle.svg";
import { getFilterColumnProps } from "../../../Dashboard/components/Settings/Users/GenericColumnFilter";
import { main } from "../../theme/main";
import { StatusIndicationType } from "../../types/StatusIndicationType";
import { SvgIcon } from "../icons/SvgIcon";
import { PatientLink } from "../Link";
import { getFlagValueFromSeries } from "../SeriesFlags/getFlagValueFromSeries";
import {
  areFlagsEqual,
  FLAGGED,
  FlagTypeOptions,
  NOT_FLAGGED,
  PARTIALLY_FLAGGED,
  SeriesFlag,
  seriesFlagFilterProps,
} from "../SeriesFlags/SeriesFlag";
import StateStatusIndicator from "../StateStatusIndicator";
import { CellProps } from "../Table/CellProps";
import { CheckboxProps, getCheckboxColumn, SELECT_HEADER_ID } from "../Table/getCheckboxColumn";
import { TextCell } from "../Table/TextCell";
import { StudyTableRowType } from "./StudyTableRowType";
import { TaskStatus, TaskStatusProps } from "../TaskStatus/TaskStatus";
import { getPatientProgressTaskAssignments } from "../TaskStatus/utils/getPatientProgressTaskAssignments";
import { sortTaskByStatus } from "./utils/sortTaskByStatus";
import { getUploadStatus } from "./utils/utils";

export type StudyTableColumnType =
  | typeof SELECT_HEADER_ID
  | typeof STUDY_FLAG_HEADER_ID
  | typeof SUBJECT_ID_HEADER_ID
  | typeof STUDY_ID_HEADER_ID
  | typeof STUDY_DATE_HEADER_ID
  | typeof NUMBER_OF_SERIES_HEADER_ID
  | typeof UPLOAD_STATUS_HEADER_ID
  | typeof TASK_STATUS_HEADER_ID
  | typeof OPEN_PATIENT_PAGE_HEADER_ID
  | typeof INCLUDED_HEADER_ID;

export const STUDY_FLAG_HEADER_ID = "studyFlag";
export const SUBJECT_ID_HEADER_ID = "subjectId";
export const STUDY_ID_HEADER_ID = "studyId";
export const STUDY_DATE_HEADER_ID = "studyDate";
export const NUMBER_OF_SERIES_HEADER_ID = "numberOfSeries";
export const UPLOAD_STATUS_HEADER_ID = "uploadStatus";
export const TASK_STATUS_HEADER_ID = "taskStatus";
export const OPEN_PATIENT_PAGE_HEADER_ID = "openPatientPage";
export const INCLUDED_HEADER_ID = "included";

const Button = styled.div`
  cursor: pointer;
  padding: 0 8px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
`;

interface Props {
  hiddenColumns: StudyTableColumnType[];
  onSelectAll: (selected: boolean) => void;
  onSelectStudy: (studyId: number, selected: boolean) => void;
  onOpenPatient: (patientId: number) => void;
  onOpenTaskless: (patientId: number) => void;
  patientsTasks: PatientMutableTaskType[] | undefined;
  showReorgPages: boolean;
}

function getStudyTableCheckboxColumn(
  onSelectStudy: (id: number, selected: boolean) => void,
  onSelectAll: (selected: boolean) => void
): Column<StudyTableRowType> {
  function getHeaderProps(data: StudyTableRowType[]): CheckboxProps {
    const checked =
      data.length > 0 &&
      data.every(({ seriesRows }) => seriesRows.every(({ isSelected }) => isSelected));
    const indeterminate =
      !checked && data.some(({ seriesRows }) => seriesRows.some(({ isSelected }) => isSelected));

    return {
      checked,
      indeterminate,
      onChange: (event) => {
        const selected = (event.target as HTMLInputElement).checked;
        onSelectAll(selected);
      },
    };
  }

  function getRowProps({ id, seriesRows }: StudyTableRowType): CheckboxProps {
    const checked = seriesRows.length > 0 && seriesRows.every(({ isSelected }) => isSelected);
    const indeterminate = !checked && seriesRows.some(({ isSelected }) => isSelected);
    return {
      checked,
      indeterminate,
      onChange: (event) => {
        const selected = (event.target as HTMLInputElement).checked;
        onSelectStudy(id, selected);
      },
    };
  }

  return getCheckboxColumn({
    getHeaderProps,
    getRowProps,
  });
}

export function getColumns({
  hiddenColumns,
  onSelectAll,
  onSelectStudy,
  onOpenPatient,
  onOpenTaskless,
  patientsTasks,
  showReorgPages,
}: Props): Column<StudyTableRowType>[] {
  const flagValues: FlagTypeOptions[] = [FLAGGED, PARTIALLY_FLAGGED, NOT_FLAGGED];

  const columnDefinitions: Record<StudyTableColumnType, Column<StudyTableRowType>> = {
    [SELECT_HEADER_ID]: getStudyTableCheckboxColumn(onSelectStudy, onSelectAll),
    [STUDY_FLAG_HEADER_ID]: {
      id: STUDY_FLAG_HEADER_ID,
      Header: <SeriesFlag flagSize={17} />,
      ...getFilterColumnProps((row) => getFlagValueFromSeries(row.series), areFlagsEqual, {
        values: flagValues,
        ...seriesFlagFilterProps,
      }),
      style: {
        width: "50px",
      },
    },
    [SUBJECT_ID_HEADER_ID]: {
      id: SUBJECT_ID_HEADER_ID,
      Header: "Subject ID",
      disableFilters: false,
      accessor: ({ patientDicomId }) => patientDicomId,
      Cell: ({ value }: { value: string }) => <TextCell value={value} />,
    },
    [STUDY_ID_HEADER_ID]: {
      id: STUDY_ID_HEADER_ID,
      Header: "Study ID",
      disableFilters: false,
      accessor: ({ studyDicomId }) => {
        return studyDicomId?.trim();
      },
      Cell: ({ value }: { value: string }) => <TextCell value={value} />,
    },
    [STUDY_DATE_HEADER_ID]: {
      id: STUDY_DATE_HEADER_ID,
      Header: "Study Date",
      accessor: ({ studyDate }) => studyDate,
      Cell: ({ value }: { value: string }) => <TextCell value={value} />,
    },
    [NUMBER_OF_SERIES_HEADER_ID]: {
      id: NUMBER_OF_SERIES_HEADER_ID,
      Header: "# of Series",
      accessor: ({ series }) => series.length,
      style: {
        width: "84px",
      },
    },
    [UPLOAD_STATUS_HEADER_ID]: {
      id: UPLOAD_STATUS_HEADER_ID,
      Header: "Upload Status",
      accessor: (row) => getUploadStatus(row),
      // eslint-disable-next-line react/prop-types,react/display-name
      Cell: ({ value: status }: { value: StatusIndicationType }) => (
        <StateStatusIndicator {...status} />
      ),
      sortType: (
        {
          values: {
            [UPLOAD_STATUS_HEADER_ID]: { status: statusA },
          },
        },
        {
          values: {
            [UPLOAD_STATUS_HEADER_ID]: { status: statusB },
          },
        }
      ) => {
        return statusA.localeCompare(statusB);
      },
    },
    [TASK_STATUS_HEADER_ID]: {
      id: TASK_STATUS_HEADER_ID,
      Header: "Task Status",
      accessor: ({ patientId }) => {
        if (!patientsTasks) {
          return { progress: TASK_PENDING, taskAssignments: [] };
        }
        return getPatientProgressTaskAssignments(patientId, patientsTasks);
      },
      style: {
        width: "150px",
      },
      // eslint-disable-next-line react/display-name
      Cell: ({ value: props }: { value: TaskStatusProps }) => {
        if (props.taskAssignments.filter(({ task }) => !!task).length !== 0) {
          return <TaskStatus {...props} />;
        }
        return null;
      },
      sortType: (
        {
          values: taskValueA,
        }: {
          values: { [key: string]: CompleteTaskProgressType };
        },
        {
          values: taskValueB,
        }: {
          values: { [key: string]: CompleteTaskProgressType };
        }
      ) => {
        return sortTaskByStatus(
          taskValueA[TASK_STATUS_HEADER_ID],
          taskValueB[TASK_STATUS_HEADER_ID]
        );
      },
    },
    [INCLUDED_HEADER_ID]: {
      id: INCLUDED_HEADER_ID,
      Header: "Included",
      disableFilters: true,
      style: {
        width: "70px",
      },
      // eslint-disable-next-line react/prop-types,react/display-name
      Cell: ({
        row: {
          original: { seriesRows },
        },
      }: CellProps<StudyTableRowType>) => {
        const allIncluded = seriesRows.every(
          ({ projectExclusions }) => projectExclusions.length === 0
        );
        const allExcluded = seriesRows.every(
          ({ projectExclusions }) => projectExclusions.length > 0
        );

        const {
          colors: {
            states: { success, error, warning },
          },
        } = main;
        const handleOnClick = () => {
          //
        };

        return (
          <Button onClick={handleOnClick}>
            <SvgIcon
              icon={allIncluded ? CheckIcon : RemoveCircleIcon}
              size={allIncluded ? 14 : 13}
              color={allIncluded ? success : allExcluded ? error : warning}
            />
          </Button>
        );
      },
    },
    [OPEN_PATIENT_PAGE_HEADER_ID]: {
      id: OPEN_PATIENT_PAGE_HEADER_ID,
      Header: "Open",
      style: {
        width: "70px",
      },
      Cell: ({
        row: {
          original: { patientId },
        },
      }: CellProps<StudyTableRowType>) => {
        return (
          <>
            {showReorgPages ? (
              <ButtonWrapper>
                <Button onClick={() => onOpenPatient(patientId)}>
                  <FaCircleChevronRight size={13} color={main.colors.neutral.neutral6} />
                </Button>
                <Button onClick={() => onOpenTaskless(patientId)}>
                  <FaXRay size={13} color={main.colors.neutral.neutral6} />
                </Button>
              </ButtonWrapper>
            ) : (
              <PatientLink patientID={patientId}>
                <SvgIcon icon={LaunchIcon} size={14} color={main.colors.neutral.neutral3} />
              </PatientLink>
            )}
          </>
        );
      },
    },
  };

  const allColumnIds: StudyTableColumnType[] = [
    SELECT_HEADER_ID,
    STUDY_FLAG_HEADER_ID,
    SUBJECT_ID_HEADER_ID,
    STUDY_ID_HEADER_ID,
    STUDY_DATE_HEADER_ID,
    NUMBER_OF_SERIES_HEADER_ID,
    UPLOAD_STATUS_HEADER_ID,
    TASK_STATUS_HEADER_ID,
    INCLUDED_HEADER_ID,
    OPEN_PATIENT_PAGE_HEADER_ID,
  ];

  const columnIds = allColumnIds.filter((id) => !hiddenColumns.includes(id));
  return columnIds.map((id) => columnDefinitions[id]);
}
