import { ClassificationsMapType } from "../../../../../../common/store/annotatePage/ClassificationsMapType";
import {
  ClassificationsTypeType,
  ClassificationValuesType,
} from "../../../../../types/TaskDescriptionType";

export function areClassificationsEqual(
  classificationsMapProxy: ClassificationsMapType,
  classificationsMap: ClassificationsMapType,
  useAnatomySelector: boolean,
  initialLesionLocationId?: number | null | undefined,
  selectedLesionLocationId?: number | null | undefined
): boolean {
  return (
    areClassificationMapsEqual(classificationsMapProxy, classificationsMap) &&
    isAnatomyEqual(useAnatomySelector, initialLesionLocationId, selectedLesionLocationId)
  );
}

export function areClassificationMapsEqual(
  classificationsMapProxy: ClassificationsMapType,
  classificationsMap: ClassificationsMapType
): boolean {
  return (
    compareMapKeyValues(classificationsMapProxy, classificationsMap) &&
    compareMapKeyValues(classificationsMap, classificationsMapProxy)
  );
}

export function isAnatomyEqual(
  useAnatomySelector: boolean,
  initialLesionLocationId?: number | null | undefined,
  selectedLesionLocationId?: number | null | undefined
): boolean {
  return initialLesionLocationId === selectedLesionLocationId || !useAnatomySelector;
}

function compareMapKeyValues(
  classificationsMap: ClassificationsMapType,
  classificationsMapToCompare: ClassificationsMapType
): boolean {
  return Object.entries(classificationsMapToCompare).every(([key, { values }]) => {
    const type = key as ClassificationsTypeType;
    return Object.keys(values).every((classification) => {
      if (!classificationsMap[type] || !classificationsMap[type].values) {
        return false;
      }
      return (
        classificationsMapToCompare[type].values[classification as ClassificationValuesType] ===
        classificationsMap[type].values[classification as ClassificationValuesType]
      );
    });
  });
}
