import { useContext } from "react";
import { useSelector } from "react-redux";

import { useCurrentUserCan } from "../../../../../../common/contexts/UserContext/useCurrentUserCan";
import { selectedLesionIdSelector } from "../../../../../../common/store/annotatePage/selectionSelector";
import { ADMIN, PROJECT_ROLE_QC_ANNOTATOR } from "../../../../../../common/types/UserRoleType";
import { PatientContext } from "../../../../../../Dashboard/components/Settings/Patients/Viewer/PatientContext";
import { TaskContext } from "../../../../../TaskContext";
import { PatientMutableTaskType } from "../../../../Manage/hooks/TaskType";
import { useProjectPatientTaskRows } from "../../../../Manage/hooks/useProjectPatientTaskRows";
import { TASK_COMPLETED } from "../../../../TaskWorklist/TaskProgressType";
import { useGetIsLesionFromCurrentTask } from "../../ViewerPanel/useGetIsLesionFromCurrentTask";
import { LesionListItemFragmentType } from "../fragments/LesionListItemFragment";
import { isLesionOrgan } from "../utils/isLesionOrgan";
import { isLesionPredicted } from "../utils/isLesionPredicted";

export type LesionViewOnlyProps = {
  canAdd: boolean;
  canDelete: boolean;
  canClassify: boolean;
  canAnnotate: boolean;
  isFromCurrentTask: boolean;
};

export function getIsTaskCompleted(
  patientsTasks: PatientMutableTaskType[],
  patientId: number,
  taskId: number
): boolean {
  const patientsTask = patientsTasks.filter(({ id, task }) => {
    return id === patientId && task?.id === taskId;
  });

  // its possible due to some task exclusion that there are no tasks present for a lesion
  // therefore treat it as complete
  if (patientsTask.length === 0) {
    return true;
  }

  if (patientsTask.length > 1) {
    throw new Error("ID match filter should have returned 1 and only 1 Task.");
  }

  const { task } = patientsTask[0];

  if (!task) {
    return false;
  }

  const { taskAssignments } = task;
  return taskAssignments.some(({ progress }) => progress === TASK_COMPLETED);
}

export function useIsLesionViewOnly(
  lesion: LesionListItemFragmentType | null
): LesionViewOnlyProps {
  const { viewOnly: isTaskViewOnly } = useContext(TaskContext);
  const { id: patientId } = useContext(PatientContext);

  const selectedLesionId = useSelector(selectedLesionIdSelector);
  const getIsLesionFromCurrentTask = useGetIsLesionFromCurrentTask();
  const isUserQc = useCurrentUserCan([PROJECT_ROLE_QC_ANNOTATOR]);
  const isUserAdmin = useCurrentUserCan([ADMIN]);
  const { patientsTasks } = useProjectPatientTaskRows(false);
  if (!lesion) {
    return {
      canAdd: false,
      canDelete: false,
      canAnnotate: false,
      canClassify: false,
      isFromCurrentTask: false,
    };
  }
  if (!patientsTasks) {
    return {
      canAdd: false,
      canDelete: false,
      canAnnotate: false,
      canClassify: false,
      isFromCurrentTask: false,
    };
  }

  const { id: lesionId, taskId } = lesion;

  // predicted or lesions not belonging to tasks cant be annotated currently
  if (isLesionPredicted(lesion) || taskId === null) {
    return {
      canAdd: false,
      canDelete: false,
      canAnnotate: false,
      canClassify: false,
      isFromCurrentTask: false,
    };
  }

  const isTaskCompleted = getIsTaskCompleted(patientsTasks, patientId, taskId);

  const isFromCurrentTask = getIsLesionFromCurrentTask(lesion);

  const isOrgan = isLesionOrgan(lesion);

  const viewOnly = !isFromCurrentTask || isTaskViewOnly || selectedLesionId !== lesionId;

  const override =
    (isUserQc && isOrgan && !isTaskViewOnly && !isTaskCompleted) ||
    (isUserAdmin && isOrgan && !isTaskViewOnly);

  return {
    canAdd: override || !viewOnly,
    canClassify: !viewOnly, //classification in other tasks is currently not supported so don't override
    canDelete: override || !viewOnly,
    canAnnotate: override || !viewOnly,
    isFromCurrentTask,
  };
}
