import React from "react";
import styled from "styled-components";

import { useCurrentUserAllowedItems } from "../hooks/useCurrentUserAllowedItems";
import { ButtonType, RoutePathSingleOrArray, TopBarType } from "../Layout";
import { Button } from "./Button";
import { LabelContent } from "./LabelContent";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  margin: 0 6px;
`;

interface TopBarProps {
  topBar: TopBarType;
  parentRoute?: RoutePathSingleOrArray;
}

export function TopBar({ topBar, parentRoute }: TopBarProps): JSX.Element {
  const { buttons } = topBar;

  const allowedButtons = useCurrentUserAllowedItems<ButtonType>()(buttons ?? []);

  return (
    <Wrapper>
      <LabelWrapper>
        <LabelContent topBar={topBar} parentRoute={parentRoute} />
      </LabelWrapper>
      <ButtonsWrapper>
        {allowedButtons?.map((button, index) => (
          <ButtonWrapper key={index}>
            <Button button={button} />
          </ButtonWrapper>
        ))}
      </ButtonsWrapper>
    </Wrapper>
  );
}
