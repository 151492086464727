import { gql, useQuery } from "@apollo/client";
import { QueryResult } from "@apollo/client/react/types/types";
import { useContext } from "react";
import { useDeepCompareMemo } from "use-deep-compare";

import { PatientContext } from "../../../../../../Dashboard/components/Settings/Patients/Viewer/PatientContext";
import { useProjectId } from "../../../../../../Project/hooks/useProjectId";
import { DEBUG_STUDIES_LIST_LIMIT } from "./debugUtils";

export type SeriesListIdsFragmentType = {
  id: number;
};

const SERIES_LIST_IDS_FRAGMENT = gql`
  fragment SeriesListIds on series {
    id: series_id
  }
`;

export type StudyListIdsFragmentType = {
  id: number;
  series: SeriesListIdsFragmentType[];
};

const STUDY_LIST_IDS_FRAGMENT = gql`
  fragment StudyListIds on study {
    id: study_id
    series(
      order_by: [{ series_date: asc }]
      where: {
        patients_with_children_and_cohorts: {
          _and: [
            { patient_id: { _eq: $patientId } }
            { global_cohort_project_id: { _eq: $projectId } }
          ]
        }
      }
    ) {
      ...SeriesListIds
    }
  }
  ${SERIES_LIST_IDS_FRAGMENT}
`;

const QUERY = gql`
  query GetStudiesForPatient($projectId: Int!, $patientId: Int!) {
    studies: study(
      limit: ${DEBUG_STUDIES_LIST_LIMIT}
      where: { 
        patients_with_children_and_cohorts: {
          _and: [
            { patient_id: { _eq: $patientId } }
            { global_cohort_project_id: { _eq: $projectId } }
          ]
        }
      }
    ) {
      ...StudyListIds
    }
  }
  ${STUDY_LIST_IDS_FRAGMENT}
`;

type Data = {
  studies: StudyListIdsFragmentType[];
};

type Variables = {
  projectId: number;
  patientId: number;
};

export function useAllStudiesList(): QueryResult<Data, Variables> {
  const projectId = useProjectId();
  const { id } = useContext(PatientContext);
  const query = useQuery<Data, Variables>(QUERY, {
    variables: { projectId: projectId, patientId: id },
  });
  return useDeepCompareMemo(() => query, [query]);
}
