import styled from "styled-components";

import { Label } from "../../../../common/components/Label";
import { main } from "../../../../common/theme/main";
import { CohortType } from "../../../../common/types/CohortType";

const ContentsWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
`;

interface CohortLabelProps {
  cohort: CohortType;
  children?: JSX.Element;
}

export function CohortLabel({ cohort, children }: CohortLabelProps): JSX.Element {
  const { name } = cohort;

  const {
    colors: {
      neutral: { white },
    },
  } = main;
  
  return (
    <Label background={cohort.metadata.color} color={white}>
      <ContentsWrapper>
        {name}
        {children}
      </ContentsWrapper>
    </Label>
  );
}
