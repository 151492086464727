import React, { FC, forwardRef, useRef } from "react";
import styled from "styled-components";

import { Card } from "../../common/components/cards/Card";
import { LoadingCard } from "../../common/components/Loading";
import { Table, TableProps } from "../../common/components/Table/Table";
import { TableControlsType } from "../../common/components/Table/TableControlsType";
import handleApolloError from "../../common/utils/handleApolloError";
import { useProjectId } from "../../Project/hooks/useProjectId";
import { useAllProjectDocuments } from "./hooks/useAllProjectDocuments";
import { DocumentTableRowType, ProjectDocumentType } from "./ProjectDocumentType";
import { useDocumentTableColumns } from "./useDocumentTableColumns";

const TableWrapper = styled.div`
  height: 250px;
`;

export const ProjectDocumentsCard: FC = () => {
  const projectId = useProjectId();
  const { data, loading, error } = useAllProjectDocuments(projectId);

  const tableRef = useRef<TableControlsType<DocumentTableRowType>>(null);
  const columns = useDocumentTableColumns();
  const memoizedRows = React.useMemo<ProjectDocumentType[]>(
    () => data?.userProjectFiles ?? [],
    [data]
  );

  if (loading) {
    return <LoadingCard />;
  }

  if (error) handleApolloError(error);

  if (!data) {
    throw new Error("Project Document data is undefined!");
  }

  return (
    <Card
      title={"Project Documents"}
      content={
        <TableWrapper>
          <TableWithRef columns={columns} data={memoizedRows} ref={tableRef} enableSelect={false} />
        </TableWrapper>
      }
    />
  );
};
const TableWithRef = forwardRef<
  TableControlsType<DocumentTableRowType>,
  TableProps<DocumentTableRowType>
>((props, ref) => {
  return <Table {...props} forwardedRef={ref} />;
});
TableWithRef.displayName = "TableWithRef";
