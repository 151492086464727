import { useContext } from "react";

import { ProjectContext } from "../../contexts/ProjectContext";
import { useFindPageByRoute } from "../hooks/useFindPageByRoute";
import { useLayout } from "../hooks/useLayout";
import { RoutePathSingleOrArray } from "../Layout";
import { PATIENT_SLUG, PROJECT_SLUG } from "../RoutePathType";
import { getPageRoute } from "../utils/getPageRoute";
import { tasklessPageRoute } from "./route";

type ReturnType = (patientId: number) => RoutePathSingleOrArray | undefined;

export function useGetTasklessRoute(): ReturnType {
  const layout = useLayout();
  const findPageByRoute = useFindPageByRoute();
  const { projectId } = useContext(ProjectContext);

  return (patientId: number) => {
    if (!projectId) {
      return undefined;
    }
    const { project } = layout;

    const tasklessPage = findPageByRoute(project, tasklessPageRoute);

    if (!tasklessPage) {
      throw new Error("Unable to find annotate page in useGetTaskRoute");
    }

    const route = getPageRoute(tasklessPage);

    return getTasklessRoute(route, projectId, patientId);
  };
}

function getTasklessRoute(
  route: RoutePathSingleOrArray,
  projectId: number,
  patientId: number
): RoutePathSingleOrArray {
  if (!Array.isArray(route)) {
    throw new Error("annotateRoute should be an array");
  }

  return route.map((route) => {
    switch (route) {
      case PATIENT_SLUG:
        return patientId;
      case PROJECT_SLUG:
        return projectId;
      default:
        return route;
    }
  });
}
