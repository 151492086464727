import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { MutationTuple } from "@apollo/client/react/types/types";

import { TaskProgressType } from "../../../Annotate/components/TaskWorklist/TaskProgressType";

type Data = {
  insert_task_assignment: {
    returning: {
      id: number;
      patient_id: number;
      task_id: number;
      user_id: number;
      show_badge: boolean;
      progress: TaskProgressType;
    }[];
  };
};

export type Variables = {
  objects: {
    patient_id: number;
    task_id: number;
    user_id: number;
    show_badge: boolean;
    progress: TaskProgressType;
  }[];
};

export const MUTATION = gql`
  mutation InsertReassignedTasks($objects: [task_assignment_insert_input!]!) {
    insert_task_assignment(
      objects: $objects
      on_conflict: {
        constraint: task_assignment_task_id_patient_id_user_id_key
        update_columns: []
      }
    ) {
      returning {
        id
        patient_id
        task_id
        user_id
        patient_id
        show_badge
        progress
      }
    }
  }
`;

export function useReassignUsersTasks(): MutationTuple<Data, Variables> {
  return useMutation<Data, Variables>(MUTATION);
}
