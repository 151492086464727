import classNames from "classnames";
import { ReactNode } from "react";
import styled from "styled-components";

import { FilterableSeriesListItemFragmentType } from "../AnnotationPanel/hooks/useStudiesList";
import { AccentTop } from "../common/AccentTop";
import { useGetViewerForSeries } from "../ViewerPanel/Viewer/hooks/useGetViewerForSeries";
import { useKeyImageIdForSeries } from "./hooks/useKeyImageIdForSeries";
import { TimepointViewport } from "./TimepointViewport";

const Wrapper = styled.div<{ filtered?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  opacity: ${(props) => (props.filtered ? 0.4 : 1)};
  transition: opacity 0.05s ease-out;
`;

const LabelWrapper = styled.div`
  position: absolute;
  top: 7px;
  left: 7px;
  display: flex;
  align-items: center;
  z-index: 3;
`;

interface TimepointCardProps {
  series: FilterableSeriesListItemFragmentType;
  label?: ReactNode;
  showViewerColorBar: boolean;
}

export function TimepointCard({
  series,
  label,
  showViewerColorBar,
}: TimepointCardProps): JSX.Element {
  const { id: seriesId } = series;
  const getViewerForSeries = useGetViewerForSeries();
  const viewer = getViewerForSeries(seriesId);
  const { color } = viewer || {};
  const selected = viewer?.isActive ?? false;

  const imageId = useKeyImageIdForSeries(series);
  const viewportOptions = {
    scale: 2.5,
  };

  return (
    <Wrapper
      filtered={series.filtered}
      className={classNames(["timepoint-thumbnail", series.filtered ? "filtered" : ""])}
    >
      {label && <LabelWrapper>{label}</LabelWrapper>}
      {showViewerColorBar && <AccentTop selected={selected} accentColor={color} />}
      <TimepointViewport imageId={imageId} viewportOptions={viewportOptions} />
    </Wrapper>
  );
}
