import React from "react";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import styled from "styled-components";

import { BAR_BACKGROUND_FILL, BAR_RADIUS } from "../../../Analysis/common/utils/strokeSettings";
import { ImageCaptureContextMenuContainer } from "../../../common/components/ImageCaptureContextMenuContainer";
import Text from "../../../common/components/text/Text";
import { main } from "../../../common/theme/main";
import { AxisTickLabel } from "./AxisTickLabel";

const Wrapper = styled.div`
  flex: 1;
`;

export interface EnrolmentDataProps {
  name: string;
  success: number;
  total: number;
  percentage: number;
}
interface EnrolmentChartProps {
  enrolmentData: EnrolmentDataProps[];
}

interface FormatAxisProps {
  x: number;
  y: number;
  width: number;
  visibleTicksCount: number;
  index: number;
}

function EnrolmentChart({ enrolmentData }: EnrolmentChartProps): React.ReactElement {
  if (enrolmentData.length === 0) {
    return <Text text={"Not available"} />;
  }
  const formatXAxis = ({
    x,
    y,
    width: totalWidth,
    visibleTicksCount,
    index,
  }: FormatAxisProps): React.ReactElement => {
    const { name, success, total, percentage } = enrolmentData[index];

    const width = totalWidth / visibleTicksCount;
    const height = 36; // this is based on the margins of the table

    return (
      <svg x={x - width / 2} y={y} width={width} height={height}>
        <foreignObject width={width} height={height}>
          <AxisTickLabel name={name} success={success} total={total} percent={percentage} />
        </foreignObject>
      </svg>
    );
  };
  return (
    <Wrapper>
      <ImageCaptureContextMenuContainer>
        {({ reference }) => (
          <ResponsiveContainer ref={reference} height={350}>
            <BarChart data={enrolmentData} margin={{ top: 0, right: 0, left: 0, bottom: 20 }}>
              <XAxis
                dataKey="name"
                axisLine={false}
                tickLine={false}
                tick={formatXAxis}
                interval={0}
              />
              <YAxis dataKey="percentage" domain={[0, 100]} hide={true} />
              <Bar
                dataKey="percentage"
                fill={main.colors.accent.accent1}
                background={{ fill: BAR_BACKGROUND_FILL, radius: BAR_RADIUS }}
                radius={BAR_RADIUS}
                isAnimationActive={false}
              />
            </BarChart>
          </ResponsiveContainer>
        )}
      </ImageCaptureContextMenuContainer>
    </Wrapper>
  );
}

export default EnrolmentChart;
