//not a cornerstone tool but for legacy tool behavior
export const ANNOTATE = "ANNOTATE";

export const WINDOWLEVEL = "Wwwc";
export const ZOOM = "Zoom";
export const PAN = "Pan";
export const SYNCED_SCROLL = "SYNC_SCROLL";
export const SCROLL_MOUSE_WHEEL = "StackScrollMouseWheel";
export const PAN_MULTI_TOUCH = "PanMultiTouch";
export const ZOOM_TOUCH_PINCH = "ZoomTouchPinch";
export const SCROLL_MULTI_TOUCH = "StackScrollMultiTouch";
export const MEASURE = "Length";
export const ELLIPTICAL_PROBE = "EllipticalProbe";
export const ROI = "FreehandRoi";
export const ROI_SCULPTOR = "FreehandRoiSculptor";
export const BRUSH = "Brush";
export const FREEHAND_SCISSORS = "FreehandScissors";
export const ELLIPTICAL_ROI = "EllipticalRoi";

export type ToolType =
  | typeof WINDOWLEVEL
  | typeof ZOOM
  | typeof PAN
  | typeof SYNCED_SCROLL
  | typeof SCROLL_MOUSE_WHEEL
  | typeof PAN_MULTI_TOUCH
  | typeof ZOOM_TOUCH_PINCH
  | typeof SCROLL_MULTI_TOUCH
  | typeof MEASURE
  | typeof ELLIPTICAL_PROBE
  | typeof ROI
  | typeof ROI_SCULPTOR
  | typeof BRUSH
  | typeof FREEHAND_SCISSORS
  | typeof ELLIPTICAL_ROI;
