import { ClassificationsMapType } from "../../../../../../common/store/annotatePage/ClassificationsMapType";
import { ClassificationValuesType } from "../../../../../types/TaskDescriptionType";

type ReturnType = {
  insertClassifications: ClassificationValuesType[];
  deleteClassifications: ClassificationValuesType[];
};

export function getInsertAndDeleteClassificationLists(
  classificationsMap: ClassificationsMapType
): ReturnType {
  const insertClassifications: ClassificationValuesType[] = [];
  const deleteClassifications: ClassificationValuesType[] = [];
  Object.values(classificationsMap).forEach(({ values }) => {
    Object.entries(values).forEach(([classification, isSelected]) => {
      if (!isSelected) {
        deleteClassifications.push(classification as ClassificationValuesType);
        return;
      }
      insertClassifications.push(classification as ClassificationValuesType);
    });
  });
  return {
    insertClassifications,
    deleteClassifications,
  };
}
