import { ApolloClient } from "@apollo/client";
import { gql } from "@apollo/client/core";

import { parsedMutation } from "../../../apollo/utils/parsedMutation";
import { USER_FRAGMENT, UserFragmentType } from "../../../Settings/UserFragment";
import { Data as UsersQueryData, QUERY } from "../../../Settings/useUsers";
import { UserType } from "../../types/UserType";
import {
  parseVariables as parseGlobalRolesVariables,
  useUpdateGlobalUserRoles,
} from "./useUpdateGlobalUserRoles";

const MUTATION = gql`
  mutation CreateUsers($user_fields_list: [CreateUserFields!]!) {
    create_users(user_fields_list: $user_fields_list) {
      internal_id
      auth0_user_id
      email
      user {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

type Input = { users: UserType[] };
type Data = {
  create_users: {
    internal_id: number;
    auth0_user_id: string;
    email: string;
    user: UserFragmentType;
  }[];
};
type Variables = {
  user_fields_list: {
    email: string;
    roles: string[];
  }[];
};

export async function createUsers(
  users: UserType[],
  client: ApolloClient<unknown>
): Promise<UserType[]> {
  if (users.length === 0) {
    return [];
  }

  return await parsedMutation<Input, UserType[], Data, Variables>({
    mutation: MUTATION,
    client,
    options: {
      update(cache, { data }) {
        if (!data) {
          throw new Error("Something went wrong updating the cache after inserting users");
        }

        const { users: existingUsers } = cache.readQuery<UsersQueryData, undefined>({
          query: QUERY,
        }) ?? { users: [] };

        const { create_users } = data;
        const users = create_users.map(({ user }) => user);

        cache.writeQuery<UsersQueryData, undefined>({
          query: QUERY,
          data: {
            users: [...existingUsers, ...users],
          },
        });
      },
    },
    input: { users },
    parseVariables,
    parseData,
  });
}

function parseVariables({ users }: Input): Variables {
  const user_fields_list = users.map(({ email, globalRoles }) => {
    return {
      email,
      roles: globalRoles,
    };
  });
  return { user_fields_list };
}

function parseData(data: Data, input: Input): UserType[] {
  const { users } = input;
  const { create_users: rawUsers } = data;

  const usersWithIds: UserType[] = [];

  for (const rawUser of rawUsers) {
    const match = users.find(({ email }) => email === rawUser.email);
    if (!match) {
      throw new Error("Unable to match created user with email");
    }

    const { internal_id: newId, auth0_user_id: newAuth0Id } = rawUser;
    usersWithIds.push({
      ...match,
      id: newId,
      auth0Id: newAuth0Id,
    });
  }

  return usersWithIds;
}

export function useUpdateExistingUserGlobalRoles(): (
  users: UserType[],
  allUsers: UserType[]
) => Promise<void> {
  const [updateGlobalUserRoles] = useUpdateGlobalUserRoles();

  return async (users, allUsers) => {
    if (users.length === 0) {
      return;
    }

    for (const user of users) {
      const existingUser = allUsers.find(({ id }) => id === user.id);
      if (!existingUser) {
        throw new Error("Unable to find existing user in useUpdateExistingUserGlobalRoles");
      }

      const variables = parseGlobalRolesVariables(existingUser, user);
      await updateGlobalUserRoles({ variables });
    }
  };
}
