import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  AgeFilters,
  DemographicsFilters,
  EthnicityFilters,
  SexFilters,
} from "../types/DemographicsFilterTypes";
import { BmiFilters, LifestyleFilters, SmokingStatusFilters } from "../types/LifestyleFilterTypes";
import {
  EcogFilters,
  HistologyFilters,
  ProjectArmFilters,
  Recist1_1Filters,
  StageFilters,
  TimepointsFilters,
  TumourMutationsFilters,
} from "../types/ProjectViewFilterTypes";
import { clearStageState, defaultStageState } from "./selectors/ajccStageSelectors";
import {
  clearDemographicsState,
  defaultDemographicsState,
} from "./selectors/demographicsSelectors";
import { clearEcogState, defaultEcogState } from "./selectors/egocSelectors";
import { clearHistologyState, defaultHistologyState } from "./selectors/histologySelectors";
import { clearLifestyleState, defaultLifestyleState } from "./selectors/lifestyleSelectors";
import { clearMutationsState, defaultMutationsState } from "./selectors/mutationSelectors";
import { clearProjectArmsState, defaultProjectArmsState } from "./selectors/projectArmSelectors";
import { clearTimepointsState, defaultTimepointsState } from "./selectors/timePointSelectors";
import { clearResponseState, defaultResponseState } from "./selectors/tumourBurdenSelectors";
import { RootState } from "./store";

export interface FilterTypes {
  timepoints: TimepointsFilters;
  projectArm: ProjectArmFilters;
  demographics: DemographicsFilters;
  histology: HistologyFilters;
  tumourMutations: TumourMutationsFilters;
  lifestyle: LifestyleFilters;
  ecog: EcogFilters;
  recist1_1: Recist1_1Filters;
  stage: StageFilters;
  currentProject: number | null;
}

const initialState: FilterTypes = {
  timepoints: defaultTimepointsState,
  projectArm: defaultProjectArmsState,
  demographics: defaultDemographicsState,
  histology: defaultHistologyState,
  tumourMutations: defaultMutationsState,
  lifestyle: defaultLifestyleState,
  ecog: defaultEcogState,
  recist1_1: defaultResponseState,
  stage: defaultStageState,
  currentProject: null,
};

const clearState: FilterTypes = {
  timepoints: clearTimepointsState,
  projectArm: clearProjectArmsState,
  demographics: clearDemographicsState,
  histology: clearHistologyState,
  tumourMutations: clearMutationsState,
  lifestyle: clearLifestyleState,
  ecog: clearEcogState,
  recist1_1: clearResponseState,
  stage: clearStageState,
  currentProject: null,
};

export const projectViewFiltersSlice = createSlice({
  name: "projectViewFilters",
  initialState,
  reducers: {
    setTimepoints(state, action: PayloadAction<TimepointsFilters>) {
      state.timepoints = action.payload;
    },
    resetTimepoints(state) {
      state.timepoints = defaultTimepointsState;
    },
    clearTimepoints(state) {
      state.timepoints = clearTimepointsState;
    },
    setProjectArm(state, action: PayloadAction<ProjectArmFilters>) {
      state.projectArm = action.payload;
    },
    resetProjectArms(state) {
      state.projectArm = defaultProjectArmsState;
    },
    clearProjectArms(state) {
      state.projectArm = clearProjectArmsState;
    },
    setSex(state, action: PayloadAction<SexFilters>) {
      state.demographics.sex = action.payload;
    },
    toggleSex(state, action: PayloadAction<keyof SexFilters>) {
      state.demographics.sex[action.payload] = !state.demographics.sex[action.payload];
    },
    setAge(state, action: PayloadAction<AgeFilters>) {
      state.demographics.age = action.payload;
    },
    setHistology(state, action: PayloadAction<HistologyFilters>) {
      state.histology = action.payload;
    },
    resetHistology(state) {
      state.histology = defaultHistologyState;
    },
    clearHistology(state) {
      state.histology = clearHistologyState;
    },
    setTumourMutations(state, action: PayloadAction<TumourMutationsFilters>) {
      state.tumourMutations = action.payload;
    },
    resetTumourMutations(state) {
      state.tumourMutations = defaultMutationsState;
    },
    clearTumourMutations(state) {
      state.tumourMutations = clearMutationsState;
    },
    toggleTumourMutation(state, action: PayloadAction<keyof TumourMutationsFilters>) {
      state.tumourMutations[action.payload] = !state.tumourMutations[action.payload];
    },
    setSmokingStatus(state, action: PayloadAction<SmokingStatusFilters>) {
      state.lifestyle.smokingStatus = action.payload;
    },
    toggleSmokingStatus(state, action: PayloadAction<keyof SmokingStatusFilters>) {
      state.lifestyle.smokingStatus[action.payload] =
        !state.lifestyle.smokingStatus[action.payload];
    },
    setEthnicity(state, action: PayloadAction<EthnicityFilters>) {
      state.demographics.ethnicity = action.payload;
    },
    toggleEthnicity(state, action: PayloadAction<keyof EthnicityFilters>) {
      state.demographics.ethnicity[action.payload] = !state.demographics.ethnicity[action.payload];
    },
    setEcog(state, action: PayloadAction<EcogFilters>) {
      state.ecog = action.payload;
    },
    toggleEcog(state, action: PayloadAction<number>) {
      state.ecog[action.payload] = !state.ecog[action.payload];
    },
    setBmi(state, action: PayloadAction<BmiFilters>) {
      state.lifestyle.bmi = action.payload;
    },
    setRecist1_1(state, action: PayloadAction<Recist1_1Filters>) {
      state.recist1_1 = action.payload;
    },
    resetRecist1_1(state) {
      state.recist1_1 = defaultResponseState;
    },
    clearRecist1_1(state) {
      state.recist1_1 = clearResponseState;
    },
    setStage(state, action: PayloadAction<StageFilters>) {
      state.stage = action.payload;
    },
    resetStage(state) {
      state.stage = defaultStageState;
    },
    clearStage(state) {
      state.stage = clearStageState;
    },
    setAjccStage(state, action: PayloadAction<StageFilters["ajccStage"]>) {
      state.stage.ajccStage = action.payload;
    },
    toggleTStage(state, action: PayloadAction<keyof StageFilters["tStages"]>) {
      state.stage.tStages[action.payload] = !state.stage.tStages[action.payload];
    },
    setCurrentProject(state, { payload: projectId }: PayloadAction<number>) {
      state.currentProject = projectId;
    },
    setAllFilters(state, action: PayloadAction<FilterTypes>) {
      return action.payload;
    },
    resetDemographics(state) {
      state.demographics = defaultDemographicsState;
    },
    clearDemographics(state) {
      state.demographics = clearDemographicsState;
    },
    resetLifestyle(state) {
      state.lifestyle = defaultLifestyleState;
    },
    clearLifestyle(state) {
      state.lifestyle = clearLifestyleState;
    },
    resetEcog(state) {
      state.ecog = defaultEcogState;
    },
    clearEcog(state) {
      state.ecog = clearEcogState;
    },
    selectAll() {
      return initialState;
    },
    clearAll() {
      return clearState;
    },
  },
});

export const projectViewFiltersActions = projectViewFiltersSlice.actions;

// Graphs using filters may want to create their own selectors, rather than using the filters object directly;
// i.e., using reselect's createSelector (also included in Redux). This will allow the component to re-render only for relevant filter changes.
export const getProjectViewFilters = (state: RootState): FilterTypes => state.projectViewFilters;

export const getDemographicsFilters = createSelector(
  [getProjectViewFilters],
  (projectViewFilters) => projectViewFilters.demographics
);

export const getLifestyleFilters = createSelector(
  [getProjectViewFilters],
  (projectViewFilters) => projectViewFilters.lifestyle
);

const allSelected = (state: RootState): boolean =>
  JSON.stringify(state.projectViewFilters) === JSON.stringify(initialState);

const noneSelected = (state: RootState): boolean =>
  JSON.stringify(state.projectViewFilters) === JSON.stringify(clearState);

export const allFiltersSelected = createSelector([allSelected], (allSelected) => allSelected);

export const noFiltersSelected = createSelector([noneSelected], (noSelected) => noSelected);
