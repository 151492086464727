import { useCallback } from "react";

import { useRedrawAllViewers } from "../../../ViewerPanel/Viewer/hooks/useRedrawAllViewers";
import { Mask } from "../../MaskContextProvider";
import { setMaskVisibility } from "../utils/setMaskVisibility";
import { useGetSeriesFirstImageId } from "./useGetSeriesFirstImageId";

export function useSetMaskVisible() {
  const getSeriesFirstImageId = useGetSeriesFirstImageId();
  const redrawAllViewers = useRedrawAllViewers();

  return useCallback(
    (mask: Mask, visible: boolean, deferRedraw = false) => {
      const { seriesId } = mask;

      const firstImageId = getSeriesFirstImageId(seriesId);

      setMaskVisibility(mask, visible, firstImageId);

      if (deferRedraw) {
        return;
      }

      redrawAllViewers();
    },
    [getSeriesFirstImageId, redrawAllViewers]
  );
}
