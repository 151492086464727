import React, { FC } from "react";

import { StyledTable, TableWrapper } from "../../../common/components/StatusTable";
import TableHeaderText from "../../../common/components/text/TableHeaderText";

export interface IngestionProgressDataProps {
  type: string;
  weekTotal: number;
  allTimeTotal: number;
}
interface DataIngestionProgressProps {
  dataInfo: IngestionProgressDataProps[];
}

function DataIngestionProgress({ dataInfo }: DataIngestionProgressProps): React.ReactElement {
  const data = React.useMemo(() => dataInfo, []);
  return <Table data={data} />;
}

export default DataIngestionProgress;

interface RadiologyCaseStatusTableProps {
  data: {
    type: string;
    weekTotal: number;
    allTimeTotal: number;
  }[];
}

const Table: FC<RadiologyCaseStatusTableProps> = ({ data }: RadiologyCaseStatusTableProps) => {
  return (
    <TableWrapper>
      <StyledTable>
        <tbody>
          <tr>
            <TableHeaderText text={"Data Status (active) "} />
            <TableHeaderText text={"This Week"} />
            <TableHeaderText text={"Overall"} />
          </tr>
          {data.map((dataPerType, index) => {
            return (
              <tr key={index}>
                <td>{dataPerType.type}</td>
                <td>{dataPerType.weekTotal}</td>
                <td>{dataPerType.allTimeTotal}</td>
              </tr>
            );
          })}
        </tbody>
      </StyledTable>
    </TableWrapper>
  );
};
