import { createSelector } from "@reduxjs/toolkit";
import React, { FC, ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getProjectViewFilters,
  projectViewFiltersActions,
} from "../../../common/store/projectViewFiltersSlice";
import { TUMOR_SPELLING } from "../../../common/utils/spellingConstants";
import { DISPLAY_NAMES, TumourResponseType } from "../../common/types/TumourResponseType";
import FilterCategory from "./FilterCategory";
import FilterCheckbox from "./FilterCheckbox";

const getRecist1_1Filters = createSelector(
  [getProjectViewFilters],
  (projectViewFilters) => projectViewFilters.recist1_1
);

export const Recist11Filters: FC = () => {
  const dispatch = useDispatch();
  const responseFilters = useSelector(getRecist1_1Filters);

  const components: ReactElement[] = [];
  for (const key in responseFilters) {
    if (!Object.prototype.hasOwnProperty.call(responseFilters, key)) {
      continue;
    }

    const response = key as TumourResponseType;
    const isChecked = responseFilters[response] ?? false;

    const onFilterChanged = (checked: boolean) => {
      dispatch(
        projectViewFiltersActions.setRecist1_1({
          ...responseFilters,
          [response]: checked,
        })
      );
    };

    components.push(
      <FilterCheckbox
        key={response}
        label={DISPLAY_NAMES[response]}
        checked={isChecked}
        onChange={onFilterChanged}
        name={response.toString()}
      />
    );
  }

  return <FilterCategory name={`${TUMOR_SPELLING} Burden`} options={components} />;
};
