import { TaskStatusType } from "../../../../../../../../common/types/StatusTypes";
import { LesionTaskCheckType, TaskCheckType } from "../../../../../../../types/TaskDescriptionType";

type BaseTaskCheckResult<TCheck = unknown, TResult = unknown> = {
  type: TCheck;
  required: boolean;
  status: TaskStatusType;
  message: string | JSX.Element;
  result: TResult;
};

export type TaskCheckResult<TResult> = BaseTaskCheckResult<TaskCheckType, TResult>;

export type LesionTaskCheckResult<TResult> = BaseTaskCheckResult<LesionTaskCheckType, TResult> & {
  level: LesionCheckResultLevelType;
  showOnSwitch: boolean;
};

export type AnyTaskResult<TResult> = TaskCheckResult<TResult> | LesionTaskCheckResult<TResult>;

export const CHECK_RESULT_SLICE_LEVEL = "CHECK_RESULT_SLICE_LEVEL";
export const CHECK_RESULT_TIMEPOINT_LEVEL = "CHECK_RESULT_TIMEPOINT_LEVEL";
export const CHECK_RESULT_LESION_LEVEL = "CHECK_RESULT_LESION_LEVEL";

export type LesionCheckResultLevelType =
  | typeof CHECK_RESULT_SLICE_LEVEL
  | typeof CHECK_RESULT_TIMEPOINT_LEVEL
  | typeof CHECK_RESULT_LESION_LEVEL;
