import React, { ReactNode } from "react";
import styled from "styled-components";

import { getAllFiles } from "./fileUtils";

const Wrapper = styled.div<{ isDragged: boolean }>`
  display: flex;
  flex-direction: column;
  height: 200px;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  background: ${(props) =>
    `${
      props.isDragged ? props.theme.colors.actionPrimary.hover10 : ""
    } url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23333' stroke-width='3' stroke-dasharray='12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`};
`;

interface DragAndDropUploaderPanelProps {
  dragged: boolean;
  setDragged: (dragged: boolean) => void;
  onFilesDropped: (files: FileList) => void;
  children: ReactNode;
}

export function DragAndDropUploaderPanel({
  dragged,
  setDragged,
  onFilesDropped,
  children,
}: DragAndDropUploaderPanelProps): JSX.Element {
  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragged(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragged(false);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragged(true);
  };

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragged(false);

    const items = event.dataTransfer.items;

    if (items.length === 0) {
      console.log("No files dropped!");
      return;
    }

    const files = await getAllFiles(items);

    onFilesDropped(files as unknown as FileList);
  };

  return (
    <Wrapper
      isDragged={dragged}
      onDrop={(e) => handleDrop(e)}
      onDragOver={(e) => handleDragOver(e)}
      onDragEnter={(e) => handleDragEnter(e)}
      onDragLeave={(e) => handleDragLeave(e)}
    >
      {children}
    </Wrapper>
  );
}
