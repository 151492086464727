import { LesionListItemFragmentType } from "../../fragments/LesionListItemFragment";
import { RoiListItemFragmentType } from "../../fragments/RoiListItemFragment";
import { RoiClassificationButtonProps } from "../../RoiCard/RoiClassificationButtons";
import { getRoiClassificationButton } from "../../utils/getRoiClassificationButton";
import { useGetExistingAvailableRoiClassifications } from "./useGetExistingAvailableRoiClassifications";

export type ExistingRoiClassificationButtonsReturnType = (
  lesion: LesionListItemFragmentType,
  roi: RoiListItemFragmentType
) => RoiClassificationButtonProps[];

export function useGetExistingRoiClassificationButtons(): ExistingRoiClassificationButtonsReturnType {
  const getExistingAvailableRoiClassifications = useGetExistingAvailableRoiClassifications();

  return (lesion, roi) => {
    const { setOptions, unsetOptions } = getExistingAvailableRoiClassifications(lesion, roi);

    const unsetButtons = unsetOptions.map((option) => getRoiClassificationButton(option, false));
    const setButtons = setOptions.map((option) => getRoiClassificationButton(option, true));
    return [...unsetButtons, ...setButtons];
  };
}
