import { gql } from "@apollo/client/core";

export const ANATOMICAL_STRUCTURES_FRAGMENT = gql`
  fragment AnatomicalStructure on anatomical_structures {
    id: anatomical_structures_id
    name: display_name
    structure
    readonly
    color
  }
`;

export type AnatomicalStructuresFragmentType = {
  id: number;
  name: string;
  structure: string;
  readonly: boolean;
  color: string;
};
