import React from "react";
import styled from "styled-components";

import { AnatomicalStructuresFragmentType } from "../../../../Annotate/components/AnatomicalStructuresFragment";
import { ReactComponent as EditIcon } from "../../../../assets/svgs/EditOutline.svg";
import { SvgIcon } from "../../../../common/components/icons/SvgIcon";

const Button = styled.div`
  cursor: pointer;
  padding: 0 8px;
`;

interface LabelEditColumnProps {
  label: AnatomicalStructuresFragmentType;
  onLabelEditClicked: (label: AnatomicalStructuresFragmentType) => void;
}

export function LabelEditColumn({ label, onLabelEditClicked }: LabelEditColumnProps): JSX.Element {
  const handleEditClick = () => {
    onLabelEditClicked(label);
  };
  return (
    <Button onClick={handleEditClick}>
      <SvgIcon icon={EditIcon} size={16} />
    </Button>
  );
}
