import { LesionType } from "../hooks/useProjectLabels";
import { LabelType, labelTypeDisplayNames } from "../types/LabelType";

export function getLabelDisplayName(
  { type, anatomicalStructure: { displayName } }: LesionType,
  labelType: LabelType
): string {
  if (labelType === "ORGAN") {
    return displayName;
  }
  return labelTypeDisplayNames[labelType];
}
