import { useContext } from "react";
import { useDeepCompareCallback } from "use-deep-compare";

import { ViewerContext } from "../ViewerContext";

export function useClearViewers(): () => void {
  const { setViewerConfigs } = useContext(ViewerContext);
  return useDeepCompareCallback(() => {
    setViewerConfigs([]);
  }, [setViewerConfigs]);
}
