import dayjs from "dayjs";
import { useContext, useMemo, useState } from "react";
import styled from "styled-components";

import { IconButton } from "../../../Annotate/components/Annotate/page/AnnotationPanel/IconButton";
import { ReactComponent as ResetIcon } from "../../../assets/svgs/Reset.svg";
import { Card } from "../../../common/components/cards/Card";
import { SvgIcon } from "../../../common/components/icons/SvgIcon";
import { DateFilterType, DEFAULT_FILTER } from "../../../common/utils/dateFormatUtils/dateFilters";
import { DayjsDateRange } from "../../../common/utils/dateFormatUtils/DayjsDateRange";
import { formatRange } from "../../../common/utils/dateFormatUtils/formatRange";
import handleApolloError from "../../../common/utils/handleApolloError";
import { GenericSingleSelect } from "../../../DataManagement/Upload/components/GenericSingleSelect";
import { ProjectContext } from "../../../Project/contexts/ProjectContext";
import { useProjectId } from "../../../Project/hooks/useProjectId";
import { useProjectLabels } from "../hooks/useProjectLabels";
import { studyFilterDisplayName, studyFilters, StudyFilterType } from "../types/StudyFilterOptions";
import {
  buildAnnotationsPerCohortData,
  LabelsPerCohortData,
} from "../utils/buildAnnotationsPerCohortData";
import { getEarliestLabelDate } from "../utils/getEarliestLabelDate";
import { AnnotationsPerCohortChart } from "./AnnotationsPerCohortChart";
import { ChartLoadingWrapper } from "./ChartLoadingWrapper";
import { DownloadChartDataButton } from "./DownloadChartDataButton";
import { Filter } from "./Filter";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-height: 700px;
`;

const FilterBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const SelectWrapper = styled.div`
  width: 250px;
`;

const FilterWrapper = styled.div`
  flex: 2;
  display: flex;
  flex-direction: row;
  justify-content: right;
`;

export function AnnotationsPerCohortCard(): JSX.Element {
  const projectId = useProjectId();
  const { cohorts } = useContext(ProjectContext);

  const { data, loading, error, refetch } = useProjectLabels(projectId);

  const [studyFilter, setStudyFilter] = useState<StudyFilterType | null>("COMPLETED_STUDIES");

  const [filterMode, setFilterMode] = useState<DateFilterType>(DEFAULT_FILTER);

  const [selectedDateRange, setSelectedDateRange] = useState<DayjsDateRange>({
    from: dayjs(),
    to: dayjs(),
  });

  const earliestTaskDate = useMemo(
    () => getEarliestLabelDate(data?.patients ?? [], studyFilter === "ALL_STUDIES") ?? dayjs(),
    [data, studyFilter]
  );

  const handleRefreshClicked = async () => {
    await refetch();
  };

  if (error) handleApolloError(error);

  if (!loading && !data) {
    throw new Error("Data is null with no Apollo error");
  }

  const LabelsPerCohortData =
    !loading && data
      ? buildAnnotationsPerCohortData(
          data,
          cohorts,
          selectedDateRange,
          studyFilter ?? "COMPLETED_STUDIES"
        )
      : [];

  return (
    <Card
      title={"Annotations Per Cohort"}
      content={
        <Wrapper>
          <FilterBarWrapper>
            <SelectWrapper>
              <GenericSingleSelect
                values={[...studyFilters]}
                selected={studyFilter}
                onSelectedChanged={setStudyFilter}
                getOption={(filter) => ({
                  value: filter,
                  label: studyFilterDisplayName[filter],
                })}
                isLoading={loading}
                isDisabled={loading}
              />
            </SelectWrapper>

            <FilterWrapper>
              <Filter
                filterMode={filterMode}
                onSelectFilterMode={setFilterMode}
                selectedRange={selectedDateRange}
                onSetSelectedRange={setSelectedDateRange}
                earliestDate={earliestTaskDate}
                disabled={loading}
              />
            </FilterWrapper>
          </FilterBarWrapper>
          <ChartLoadingWrapper loading={loading} data={LabelsPerCohortData}>
            <AnnotationsPerCohortChart data={LabelsPerCohortData} />
          </ChartLoadingWrapper>
        </Wrapper>
      }
      actionButtons={
        <>
          <DownloadChartDataButton
            entries={[
              {
                filename: `labels-per-cohort-${formatRange(selectedDateRange)}.csv`,
                name: "All Data",
                data: dataToCSV(LabelsPerCohortData),
              },
            ]}
          />
          <IconButton onClick={handleRefreshClicked}>
            <SvgIcon icon={ResetIcon} size={20} />
          </IconButton>
        </>
      }
    />
  );
}

function dataToCSV(data: LabelsPerCohortData[]): string[][] {
  const csvData = [["Label", "Cohort Name", "Study Count"]];
  for (const entry of data) {
    for (const cohort of entry.cohorts) {
      csvData.push([entry.label, cohort.name, cohort.studies.toString()]);
    }
  }
  return csvData;
}
