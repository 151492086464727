import { useContext } from "react";

import { TaskAssignmentType } from "./components/Manage/hooks/TaskType";
import { TaskContext } from "./TaskContext";

export function useSelectedTaskAssignment(): TaskAssignmentType {
  const {
    task: { taskAssignments },
    selectedTaskAssignmentId,
  } = useContext(TaskContext);

  const selectedTaskAssignment = taskAssignments.find(({ id }) => id === selectedTaskAssignmentId);

  if (!selectedTaskAssignment) {
    throw new Error(`Unable to get selected task assignment with id ${selectedTaskAssignmentId}`);
  }

  return selectedTaskAssignment;
}
