import { FC, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";

import { ReactComponent as CloseIcon } from "../../../assets/svgs/Close.svg";
import { ReactComponent as LaunchIcon } from "../../../assets/svgs/Launch.svg";
import { Drawer } from "../../../common/components/Drawer";
import { SvgIcon } from "../../../common/components/icons/SvgIcon";
import { PatientLink, PatientRouteProvider } from "../../../common/components/Link";
import { main } from "../../../common/theme/main";
import { PROJECT_ROLE_VIEWER } from "../../../common/types/UserRoleType";
import { IconButton } from "../../../Dashboard/components/Settings/Cohort/DeletableCohortLabel";
import { ProjectContext } from "../../../Project/contexts/ProjectContext";
import { LinkToPatientProps } from "../../../Project/Layout/hooks/usePatientLinkHandler";
import {
  DrawerPageType,
  EMPTY_CONTENT,
  PATIENT_DETAILS_CONTENT,
  PATIENT_LONGITUDINAL_CONTENT,
  PATIENT_TASKS_CONTENT,
  USER_ICON,
} from "../../../Project/Layout/Layout";
import {
  MORTALITY,
  RECIST,
  RECIST_AND_MORTALITY,
} from "../../ChangeFromBaseline/components/Filter";

type PatientJourneyDrawerProps = LinkToPatientProps;

export const PatientJourneyDrawer: FC<PatientJourneyDrawerProps> = ({
  patientRoute,
}: LinkToPatientProps) => {
  const { search } = useLocation();
  const { replace } = useHistory();
  const { patients } = useContext(ProjectContext);

  const [open, setOpen] = useState<boolean>(false);
  const [drawerPatientDicomId, setDrawerPatientDicomId] = useState<string>("");

  const searchParams = new URLSearchParams(search);
  const urlPatientId = searchParams.get("subject_id");

  useEffect(() => {
    if (urlPatientId !== null) {
      setOpen(true);
      const patient = patients.find(({ id }) => id === parseInt(urlPatientId));
      if (patient) {
        setDrawerPatientDicomId(patient.subjectId);
      }
    }
  }, [urlPatientId, patients]);

  const handleCloseDrawer = () => {
    setOpen(false);
    searchParams.delete("subject_id");
    replace({ search: searchParams.toString() });
  };

  const patientJourneyDrawer: DrawerPageType = {
    topBar: {
      icon: USER_ICON,
      label: drawerPatientDicomId,
      buttons: [
        {
          label: "",
          component: (
            <IconButton onClick={handleCloseDrawer}>
              <SvgIcon icon={CloseIcon} size={16} />
            </IconButton>
          ),
        },
      ],
    },
    content: [
      {
        component: PATIENT_DETAILS_CONTENT,
        props: {
          actionButtons: (
            <PatientLink patientID={parseInt(urlPatientId ?? "")}>
              <SvgIcon icon={LaunchIcon} size={14} color={main.colors.neutral.neutral3} />
            </PatientLink>
          ),
        },
      },
      {
        component: PATIENT_LONGITUDINAL_CONTENT,
        props: {
          allowedDataTypes: [RECIST, MORTALITY, RECIST_AND_MORTALITY],
        },
      },
      {
        component: PATIENT_TASKS_CONTENT,
        allowedRoles: [PROJECT_ROLE_VIEWER],
      },
    ],
  };

  const emptyPage: DrawerPageType = {
    content: [{ component: EMPTY_CONTENT }],
  };

  return (
    <PatientRouteProvider patientRoute={patientRoute}>
      <Drawer
        open={open}
        page={open ? patientJourneyDrawer : emptyPage}
        onClose={handleCloseDrawer}
      />
    </PatientRouteProvider>
  );
};
