import { main } from "../../common/theme/main";
import {
  ACTIVE,
  COMPLETED,
  DONE,
  DRAFT,
  ENROLLING,
  NEW,
  PLANNING,
  ProjectStatusType,
  SUSPENDED,
  TERMINATED,
  UNKNOWN,
} from "./ProjectStatusType";

export const projectStatusColors: Record<
  ProjectStatusType,
  {
    fill: string;
    text: string;
    border: string;
  }
> = {
  [NEW]: {
    fill: main.colors.secondary.secondary20,
    text: main.colors.secondary.secondary,
    border: "none",
  },
  [ACTIVE]: {
    fill: main.colors.actionSecondary.default,
    text: main.colors.actionPrimary.inverted,
    border: "none",
  },
  [PLANNING]: {
    fill: main.colors.states.successBg,
    text: main.colors.actionSecondary.default,
    border: main.colors.actionSecondary.default,
  },
  [SUSPENDED]: {
    fill: main.colors.accent.altAccent3,
    text: main.colors.accent.accent3,
    border: "none",
  },
  [ENROLLING]: {
    fill: main.colors.accent.altAccent4,
    text: main.colors.accent.accent4,
    border: "none",
  },
  [COMPLETED]: {
    fill: main.colors.neutral.neutral7,
    text: main.colors.neutral.neutral3,
    border: "none",
  },
  [DONE]: {
    fill: main.colors.neutral.neutral7,
    text: main.colors.neutral.neutral3,
    border: "none",
  },
  [TERMINATED]: {
    fill: main.colors.states.errorBg,
    text: main.colors.states.error,
    border: "none",
  },
  [DRAFT]: {
    fill: main.colors.neutral.neutral7,
    text: main.colors.neutral.neutral3,
    border: "none",
  },
  [UNKNOWN]: {
    fill: main.colors.neutral.neutral7,
    text: main.colors.neutral.neutral3,
    border: "none",
  },
};
