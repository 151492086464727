import { ApolloCache } from "@apollo/client/core";

import { LesionsListQueryInput } from "../fragments/LesionListItemFragment";
import { getRoiFromCache } from "./getRoiFromCache";

export function getEllipseIdForRoi<T>(
  roiId: number,
  lesionListQueryInput: LesionsListQueryInput,
  cache: ApolloCache<T>
): number {
  const roi = getRoiFromCache(lesionListQueryInput, roiId, cache);
  if (!roi) {
    throw new Error(`Unable to get roi with id ${roiId} from cache on ellipse update`);
  }

  const { ellipse } = roi;
  if (!ellipse) {
    throw new Error(`No ellipse for roi with id ${roiId} exists on ellipse update`);
  }

  const { id } = ellipse;
  return id;
}
