import { ApolloCache } from "@apollo/client";

export function getLesionClassificationCacheId<T>(
  lesionClassificationId: number,
  cache: ApolloCache<T>
): string {
  const id = cache.identify({
    id: lesionClassificationId,
    __typename: "lesion_classification",
  });

  if (!id) {
    throw new Error("Unable to construct lesionClassification cache id");
  }

  return id;
}
