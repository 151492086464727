import { ApolloError } from "@apollo/client";
import { TaskContext } from "nota-predict-web/src/Annotate/TaskContext";
import { createContext, useContext, useState } from "react";

import { QcSchema } from "../../../utils/qc-validation/schema/ruleSet";
import { useQcMissingDefaultsDialog } from "./QcMissingDefaultsDialog";
import { useQcFormState } from "./QualityControlProvider.hooks";
import type {
  QualityControlContextProps,
  SubmitQcFormHandler,
} from "./QualityControlProvider.types";
import { QcValidationError } from "./useValidatedQualityControlSchema";

const QualityControlContext = createContext<QualityControlContextProps>(
  {} as QualityControlContextProps
);

export const QualityControlProvider = ({
  children,
  schema,
  error,
}: React.PropsWithChildren<{
  schema?: QcSchema;
  error: ApolloError | QcValidationError | undefined;
}>): JSX.Element => {
  const { task: currentTask } = useContext(TaskContext);

  const submitActiveFormState = useState<SubmitQcFormHandler | null>(null);

  const qcFormState = useQcFormState({
    schema,
  });

  const missingDefaultsDialog = useQcMissingDefaultsDialog({
    qcFormState,
    schema,
  });

  return (
    <QualityControlContext.Provider
      value={{
        currentTask,
        submitActiveFormState,
        error,
        ...qcFormState,
      }}
    >
      {children}
      {missingDefaultsDialog}
    </QualityControlContext.Provider>
  );
};

/**
 * Use this hook to access the QualityControlContext, including:
 * - Getting the current task, active scope, current form state, and stored QC reports
 * - Updating the QC report form values and status
 * - Determining if the QC report form is up to date and valid
 *
 * Requires that the component is wrapped in a QualityControlProvider.
 */
export const useQualityControlContext = () => {
  const qualityControlContext = useContext(QualityControlContext);
  if (!qualityControlContext) {
    throw new Error(
      "`qualityControlContext` is undefined, did you forget to wrap your component in a QualityControlProvider?"
    );
  }
  return qualityControlContext;
};
