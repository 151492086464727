import cornerstoneTools from "@altis-labs/cornerstone-tools";

import { MEASURE } from "../../../../../../../cornerstone/ToolType";
import { DiameterPointsType } from "../../../AnnotationPanel/fragments/RoiRecistEvaluationFragment";
import { areDiameterPointsEqual } from "../contour/hooks/useContourMeasurements";
import { CornerstonePointsDataType } from "../contour/utils/getCornerstonePointsData";

function getPointFromCornerstoneMeasurementData(
  cornerstonePointsData: CornerstonePointsDataType
): DiameterPointsType {
  const { handles } = cornerstonePointsData;
  const { start, end } = handles;
  return {
    start,
    end,
  };
}

export function toggleCornerstonePointVisibility(
  LADPoints: DiameterPointsType,
  SADPoints: DiameterPointsType,
  visible: boolean
): void {
  const toolStates = cornerstoneTools.globalImageIdSpecificToolStateManager.toolState;

  for (const imageId in toolStates) {
    const sliceToolState = toolStates[imageId];
    if (!sliceToolState[MEASURE]) {
      continue;
    }

    const measureToolSliceState = sliceToolState[MEASURE];

    const { data } = measureToolSliceState || {};

    measureToolSliceState.data = data.map((cornerstonePointsData: CornerstonePointsDataType) => {
      const diameterPoints = getPointFromCornerstoneMeasurementData(cornerstonePointsData);

      if (areDiameterPointsEqual(LADPoints, diameterPoints)) {
        return {
          ...cornerstonePointsData,
          visible,
        };
      }

      if (areDiameterPointsEqual(SADPoints, diameterPoints)) {
        return {
          ...cornerstonePointsData,
          visible,
        };
      }

      return cornerstonePointsData;
    });
  }
}
