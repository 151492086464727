import cornerstoneTools from "@altis-labs/cornerstone-tools";

import { ROI } from "../../../../../../../cornerstone/ToolType";

export type HUStat = {
  average: number;
  min: number;
  max: number;
};

export function getRoiHUStats(roiId: number): HUStat[] {
  const toolStates = cornerstoneTools.globalImageIdSpecificToolStateManager.toolState;

  const allHUStats: HUStat[] = [];
  for (const imageId of Object.keys(toolStates)) {
    if (!toolStates[imageId][ROI]) {
      continue;
    }

    const { data } = toolStates[imageId][ROI];
    for (const toolData of data) {
      const {
        invalidated,
        roi: { id },
        meanStdDev,
      } = toolData;
      if (roiId !== id || invalidated) {
        continue;
      }

      if (meanStdDev === undefined) {
        continue;
      }

      const { mean: average, max, min } = meanStdDev;
      allHUStats.push({
        average,
        max,
        min,
      });
    }
  }

  return allHUStats;
}
