import React, { useContext } from "react";
import styled from "styled-components";

import { ReactComponent as HelpIcon } from "../../../../../../assets/svgs/Help.svg";
import { useOkForm } from "../../../../../../common/components/Dialog/useOkForm";
import { SvgIcon } from "../../../../../../common/components/icons/SvgIcon";
import { roleLabels } from "../../../../../../common/components/UserManagement/utils/roleLabels";
import { useCurrentUser } from "../../../../../../common/contexts/UserContext/useCurrentUser";
import { main } from "../../../../../../common/theme/main";
import { PROJECT_ROLE_QC_ANNOTATOR } from "../../../../../../common/types/UserRoleType";
import { ProjectContext } from "../../../../../../Project/contexts/ProjectContext";
import { TaskType } from "../../../../Manage/hooks/TaskType";
import { useInsertDeleteTaskAssignments } from "../../../../Manage/hooks/useInsertDeleteTaskAssignments";
import { TASK_PENDING } from "../../../../TaskWorklist/TaskProgressType";
import { useQualityControlContext } from "./QualityControlProvider";

const StyledButton = styled.button`
  flex: none;
  border-radius: 8px;
  background: ${main.colors.primary.primary};
  border: 1px solid;
  color: ${(props) => props.color};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  padding: 6px;
`;

const TextWrapper = styled.div`
  flex: 1;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  white-space: nowrap;
  text-align: center;
`;

interface QcHelpButtonProps {
  task: TaskType;
}

export function QcHelpButton({ task }: QcHelpButtonProps): JSX.Element {
  const [insertTaskAssignment] = useInsertDeleteTaskAssignments();
  const { activePatientId } = useQualityControlContext();
  const { users } = useContext(ProjectContext);
  const { id: currentUserId } = useCurrentUser();

  const [showNoLeadAnnotatorFoundDialog, { dialog: noLeadAnnotatorFoundDialog }] = useOkForm({
    title: `No ${roleLabels[PROJECT_ROLE_QC_ANNOTATOR]} assigned to this Project`,
    message: `A task assignment could not be created, as there is no ${roleLabels[PROJECT_ROLE_QC_ANNOTATOR]} present in this project.`,
  });
  const [
    showIncorrectTaskAssignmentCreationDialog,
    { dialog: incorrectTaskAssignmentCreationDialog },
  ] = useOkForm({
    title: "An issue occurred while trying to request help.",
    message:
      "An issue occurred when creating the task assignment, either 0 or more than 1 task assignments were created.",
  });
  const [showHelpRequestedSuccessfullyDialog, { dialog: helpRequestedSuccessfullyDialog }] =
    useOkForm({
      title: "Help Requested",
      message: `Help has been requested for this task assignment, a new task assignment was created for a ${roleLabels[PROJECT_ROLE_QC_ANNOTATOR]} to provide assistance.`,
    });

  const { id: taskId, taskAssignments } = task;
  const annotatorId = users.find(
    ({ id, projectRoles }) =>
      projectRoles?.find((role) => role === PROJECT_ROLE_QC_ANNOTATOR) && id !== currentUserId
  )?.id;

  const handleHelpRequest = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();

    if (!activePatientId) {
      throw new Error("No subject found in the Quality Control Task Annotator view");
    }

    if (!annotatorId) {
      showNoLeadAnnotatorFoundDialog(true);
      return;
    }

    insertTaskAssignment({
      variables: {
        objects: [
          {
            task_id: taskId,
            progress: TASK_PENDING,
            user_id: annotatorId,
            patient_id: activePatientId,
            show_badge: true,
          },
        ],
        taskAssignmentIdsToDelete: [],
      },
    }).then(({ data, errors }) => {
      if (errors && errors.length > 0) {
        throw new Error(errors[0].message);
      }

      const {
        insert_task_assignment: { returning: insertedTaskAssignments },
      } = data || { insert_task_assignment: { returning: [] } };

      if (insertedTaskAssignments.length !== 1) {
        showIncorrectTaskAssignmentCreationDialog(true);
      } else {
        showHelpRequestedSuccessfullyDialog(true);
      }
    });
  };

  const helpAlreadyRequested = !!taskAssignments.find(
    ({ patientId, showBadge }) => showBadge && patientId === activePatientId
  );

  const buttonHoverMessage = helpAlreadyRequested
    ? "Help already requested for this task assignment."
    : "Request help with this task assignment, will create another task assigned to a QC annotator.";

  const buttonColour = helpAlreadyRequested
    ? main.colors.actionPrimary.disabled
    : main.colors.actionPrimary.default;

  return (
    <>
      {noLeadAnnotatorFoundDialog}
      {incorrectTaskAssignmentCreationDialog}
      {helpRequestedSuccessfullyDialog}
      <StyledButton
        disabled={helpAlreadyRequested}
        title={buttonHoverMessage}
        onClick={handleHelpRequest}
        color={buttonColour}
      >
        <SvgIcon icon={HelpIcon} color={buttonColour} />
        <TextWrapper>Request Help</TextWrapper>
      </StyledButton>
    </>
  );
}
