import { RawSeriesType } from "../../rawTypes/RawSeriesType";
import { SeriesType } from "../../types/SeriesType";
import { parseMortalityRisksFromQueryResult } from "./parseMortalityRisksFromQueryResult";
import { parseTumourBurdenFromQueryResult } from "./parseTumourBurdenFromQueryResult";
import { parseTumourResponsesFromQueryResult } from "./parseTumourResponsesFromQueryResult";

export function parseSeriesFromQueryResult(rawSeries: RawSeriesType): SeriesType {
  const {
    series_id: id,
    survival_predictions: rawSurvivalPrediction,
    mortality_risks: rawMortalityRisk,
    series_tumour_burden_maps: rawSeriesTumourBurden,
    series_tumour_response_maps: rawSeriesTumourResponse,
  } = rawSeries;

  const survivalPredictions = rawSurvivalPrediction
    .map((s) => s.survival)
    .filter((s) => s !== null);

  const tumourBurdens = rawSeriesTumourBurden.map(parseTumourBurdenFromQueryResult);
  const tumourResponses = rawSeriesTumourResponse.map(parseTumourResponsesFromQueryResult);

  const mortalityRisks = rawMortalityRisk.map(parseMortalityRisksFromQueryResult);

  return {
    id,
    tumourBurdens,
    tumourResponses,
    survivalPredictions,
    mortalityRisks,
  };
}
