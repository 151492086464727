import { useParsedQuery } from "../../../apollo/utils/useParsedQuery";
import { useParsedQueryReturnType } from "../../../apollo/utils/useParsedQueryReturnType";
import { GET_ALL_PROJECT_DETAILS } from "../../../common/queries/GetAllProjectsDetails";
import { RawProjectDetailsType } from "../../../common/types/RawProjectDetailsType";

type Data = RawProjectDetailsType;
type Variables = undefined;

export function useUnparsedProject(
  projectId: number
): useParsedQueryReturnType<
  number,
  RawProjectDetailsType["project"][number] | undefined,
  Variables
> {
  return useParsedQuery<
    number,
    RawProjectDetailsType["project"][number] | undefined,
    Data,
    Variables
  >({
    query: GET_ALL_PROJECT_DETAILS,
    input: projectId,
    parseVariables: () => undefined,
    parseData: (data, projectId) => {
      const project = data.project.find(({ id }) => id === projectId);
      if (!project) {
        throw new Error(`No matching project for project id ${projectId}`);
      }
      return project;
    },
  });
}
