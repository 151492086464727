import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  background: ${(props) => props.theme.colors.accent.accent3};
  border-radius: 4px;
  min-width: 80px;
`;

const Text = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: ${(props) => props.theme.colors.neutral.white};
`;

interface SiteLabelProps {
  name: string;
}

export function SiteLabel({ name }: SiteLabelProps): JSX.Element {
  return (
    <Wrapper>
      <Text>{name}</Text>
    </Wrapper>
  );
}
