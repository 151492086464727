import { createContext } from "react";

export type LesionToScrollToContextType = {
  lesionToScrollTo: number | null;
  setLesionToScrollTo: (lesion: number | null) => void;
};

const DEFAULT_CONTEXT: LesionToScrollToContextType = {
  lesionToScrollTo: null,
  setLesionToScrollTo: () => undefined,
};
export const LesionToScrollToContext = createContext<LesionToScrollToContextType>(DEFAULT_CONTEXT);
