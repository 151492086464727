import { SubjectType } from "../types/SubjectType";
import { getFollowUpAtOrder } from "../utils/getFollowUpAtOrder";
import { useFilteredSubjectsQuery } from "./useFilteredSubjectsQuery";

export function useFilteredSubjectsWithFollowUp(followUpOrder: number): SubjectType[] {
  const subjects = useFilteredSubjectsQuery();

  return subjects
    .map((subject) => {
      const { followUps } = subject;
      const followUp = getFollowUpAtOrder(followUps, followUpOrder);
      return { ...subject, followUps: followUp ? [followUp] : [] };
    })
    .filter((subject) => subject.followUps.length > 0);
}
