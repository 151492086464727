import { ViewerConfigType } from "../../../../../../common/store/annotatePage/ViewerConfigType";
import { getFirstSeriesNotViewOnly } from "../../AnnotationPanel/hooks/Timepoint/getFirstSeriesNotViewOnly";
import { TimepointType } from "../../AnnotationPanel/hooks/Timepoint/TimepointType";

export function getNextSeries(
  timepoints: TimepointType[],
  viewerConfigs: ViewerConfigType[],
  projectId: number
): number | null {
  if (timepoints.length === 0) {
    return null;
  }

  const defaultSeriesId = viewerConfigs[0]?.seriesId ?? timepoints[0].series[0]?.id ?? null;

  const notShownTimepoints = timepoints.filter(
    ({ series }) => !series.some(({ id }) => viewerConfigs.some(({ seriesId }) => seriesId === id))
  );

  if (notShownTimepoints.length === 0) {
    return defaultSeriesId;
  }

  const firstTimepointWithSeries = notShownTimepoints.find(({ series }) => series.length > 0);
  if (!firstTimepointWithSeries) {
    return defaultSeriesId;
  }

  const firstSeries =
    getFirstSeriesNotViewOnly(firstTimepointWithSeries, projectId) ??
    firstTimepointWithSeries.series[0];

  if (!firstSeries) {
    return defaultSeriesId;
  }

  return firstSeries.id;
}
