import { useContext } from "react";

import { TaskContext } from "../../../../TaskContext";
import { LesionListItemFragmentType } from "../AnnotationPanel/fragments/LesionListItemFragment";

export function useGetIsLesionFromCurrentTask(): (
  lesion: Pick<LesionListItemFragmentType, "taskId">
) => boolean {
  const {
    task: { id: currentTaskId },
  } = useContext(TaskContext);
  return ({ taskId }) => currentTaskId === taskId;
}
