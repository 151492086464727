import { useApolloClient } from "@apollo/client";
import { matchSorter } from "match-sorter";
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";
import styled from "styled-components";

import { ReactComponent as Visibility } from "../../../../../assets/svgs/Visibility.svg";
import { ReactComponent as VisibilityOff } from "../../../../../assets/svgs/VisibilityOff.svg";
import { SvgIcon } from "../../../../../common/components/icons/SvgIcon";
import SearchTextField from "../../../../../common/components/input/SearchTextField";
import { ExpandCollapseButton } from "../common/ExpandCollapseButton";
import { ListLabel } from "../common/ListLabel";
import { useUpdateTaskInProgress } from "../hooks/useUpdateTaskInProgress";
import { useLesionsVisibility } from "../ViewerPanel/toolbar/useLesionsVisibility";
import { LesionListItemFragmentType } from "./fragments/LesionListItemFragment";
import { useDeleteLesion } from "./hooks/useDeleteLesion";
import { useDeleteLesionClassificationsNotInMode } from "./hooks/useDeleteLesionClassificationsNotInMode";
import { useDeleteRois } from "./hooks/useDeleteRois";
import { useInsertLesionClassifications } from "./hooks/useInsertLesionClassification";
import { useInsertRoiClassifications } from "./hooks/useInsertRoiClassifications";
import { useInsertRois } from "./hooks/useInsertRois";
import { useLesionListVisibilityRefresher } from "./hooks/useLesionListVisibilityRefresher";
import { useOnLesionClicked } from "./hooks/useOnLesionClicked";
import { useOnSeriesClicked } from "./hooks/useOnSeriesClicked";
import { LesionListItemPredicateType } from "./hooks/useTaskLesionListOptions";
import { useTumourBurdenChanged } from "./hooks/useTumourBurdenChanged";
import { useUpdateLesionClassificationsOnDeleteRoi } from "./hooks/useUpdateLesionClassificationsOnDeleteRoi";
import { IconButton } from "./IconButton";
import { LesionsListContext } from "./LesionsListContext";
import { LesionsListItem } from "./LesionsListItem";
import { LesionToScrollToContext } from "./toolbar/SaveChecks/LesionToScrollTo/contexts/LesionToScrollToContext";
import { useChangeLesionColors } from "./utils/useChangeLesionColors";

const Wrapper = styled.div<{ collapsed: boolean }>`
  display: flex;
  flex-direction: column;
  flex: ${({ collapsed }) => (collapsed ? 0 : "1 1 auto")};
`;

const ChildWrapper = styled(
  ({ collapsed = true, ...rest }: PropsWithChildren<{ collapsed: boolean }>) => <div {...rest} />
)`
  flex: ${({ collapsed }) => (collapsed ? 0 : "1 1 0")};
  display: flex;
  flex-direction: column;
  border-radius: 0 0 12px 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  min-height: ${(props) => (props.collapsed ? "0px" : "100px")};
  ${(props) => (props.collapsed ? "height: 0px;" : "")}
`;

const HeaderWrapper = styled.div<{ collapsed: boolean; disabled: boolean }>`
  background: ${(props) => props.theme.colors.background.main};
  border-radius: 12px 12px ${({ collapsed }) => (collapsed ? "12px 12px" : "0 0")};

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px 3px 6px 6px;
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
`;

const ActionButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const SearchWrapper = styled.div`
  padding: 6px;
`;

const Search = styled(SearchTextField)`
  padding: 4px 12px 4px 40px;
`;

export type LesionsListOptions = {
  filter?: LesionListItemPredicateType;
  headerLabel: string;
  noun: string;
  hideIfEmpty: boolean;
  showCount: boolean;
};

interface LesionsListProps {
  lesions: LesionListItemFragmentType[];
  options: LesionsListOptions;
}

export function LesionsList({ lesions, options }: LesionsListProps): JSX.Element {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const previousLesionsCountRef = useRef<number>(0);

  const [searchFilter, setSearchFilter] = useState("");

  // Start of LesionList tree hook refactor
  const {
    getLesionMode,
    getReadonlyClassificationsFromLesion,
    getNextLesionId,
    classificationModeDefinitions,
    selectedLesionId,
    statTypes,
    timepoints,
    lesionClassificationsDefinitions,
    anatomicalStructures,
    anatomicalStructuresLoading,
    getViewerForSeries,
    getRoiViewOnlyOptions,
    getIsLesionFromCurrentTask,
    onToggleMaskVisibility,
    getMaskDownloadState,
    jumpToViewerRoi,
    getTimepointOptionsFromRoiClassifications,
    headerStats,
    bodyStats,
    getExistingRoiClassificationButtons,
    getMissingRoiTimepointOptions,
    userId,
    isRecist,
  } = useContext(LesionsListContext);
  const dispatch = useDispatch();
  const { cache } = useApolloClient();

  // Mutation Hooks
  const deleteLesionClassificationsNotInMode = useDeleteLesionClassificationsNotInMode();
  const changeLesionColors = useChangeLesionColors();
  const deleteLesion = useDeleteLesion();
  const handleSeriesSelected = useOnSeriesClicked();
  const deleteRois = useDeleteRois();

  const useInsertRoisObj = useInsertRois();
  const updateTaskInProgress = useUpdateTaskInProgress();
  const { onTumourBurdenChanged } = useTumourBurdenChanged();
  const insertLesionClassificationsObj = useInsertLesionClassifications();
  const insertRoiClassificationsObj = useInsertRoiClassifications();
  const updateLesionClassificationsForMode = useUpdateLesionClassificationsOnDeleteRoi();
  // End of LesionList tree hook refactor

  // useCollapseMaps(lesions);
  useLesionListVisibilityRefresher(lesions);

  // TODO: Temporarily disabled memoization so we can ship this incrementally. Part of #184924509.
  // const selectedLesionId = useMemoSelector(selectedLesionIdSelector, []);
  const handleLesionClicked = useOnLesionClicked();
  // TODO: Temporarily disabled memoization so we can ship this incrementally. Part of #184924509.
  // const memoHandleLesionClicked = useDeepCompareCallback(handleLesionClicked, [
  //   handleLesionClicked,
  // ]);

  const { lesionToScrollTo, setLesionToScrollTo } = useContext(LesionToScrollToContext);
  const virtuoso = useRef<VirtuosoHandle | null>(null);

  useEffect(() => {
    if (lesionToScrollTo !== null) {
      const scrollIndex = filteredLesions.findIndex((filteredLesion) => {
        return filteredLesion.id === lesionToScrollTo;
      });

      if (scrollIndex !== -1) {
        if (!visibility) {
          setVisibility(true);
        }
        if (collapsed) {
          setCollapsed(false);
        }

        if (virtuoso?.current !== null) {
          virtuoso.current.scrollToIndex(scrollIndex);
          setLesionToScrollTo(null);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lesionToScrollTo]);

  useEffect(() => {
    if (lesions.length > previousLesionsCountRef.current && collapsed) {
      setCollapsed(false);
    }
    previousLesionsCountRef.current = lesions.length;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lesions.length]);

  const { filter } = options;
  // TODO: Known cause of some re-rendering. Should memoize this and handleVisibleClicked See #184924509
  const { visibility, setVisibility } = useLesionsVisibility(filter);

  const handleHeaderClicked = useCallback(() => setCollapsed(!collapsed), [collapsed]);

  const handleVisibleClicked = useCallback(
    (e: React.MouseEvent<Element, MouseEvent>) => {
      e.stopPropagation();
      setVisibility(!visibility);
    },
    [setVisibility, visibility]
  );

  const isListEmpty = lesions.length === 0;

  const label = getLabel(options, lesions);

  const noClick = () => void 0;

  const filteredLesions = useMemo(
    () =>
      searchFilter === ""
        ? lesions
        : matchSorter(lesions, searchFilter, {
            keys: [
              "name",
              ({ classifications }) => classifications.map(({ classification }) => classification),
              ({ location }) => anatomicalStructures.find(({ id }) => id === location)?.name ?? "",
            ],
          }),
    [searchFilter, lesions]
  );

  return (
    <Wrapper collapsed={collapsed || lesions.length === 0}>
      <HeaderWrapper
        onClick={!isListEmpty ? handleHeaderClicked : noClick}
        collapsed={isListEmpty || collapsed}
        disabled={isListEmpty}
      >
        <ListLabel text={label} />
        <ActionButtonsWrapper>
          <IconButton onClick={(e) => handleVisibleClicked(e)} key={1}>
            <SvgIcon icon={visibility ? Visibility : VisibilityOff} size={17} />
          </IconButton>
          <ExpandCollapseButton
            collapsed={isListEmpty || collapsed}
            key={2}
            disabled={isListEmpty}
          />
        </ActionButtonsWrapper>
      </HeaderWrapper>
      {!collapsed && lesions.length > 0 && (
        <SearchWrapper>
          <Search value={searchFilter} onChange={(e) => setSearchFilter(e.target.value)} />
        </SearchWrapper>
      )}
      {lesions.length > 0 && (
        <ChildWrapper collapsed={collapsed}>
          <Virtuoso
            data={filteredLesions}
            increaseViewportBy={100}
            style={{
              flex: collapsed || lesions.length === 0 ? 0 : "1 0 auto",
              height: collapsed || lesions.length === 0 ? 0 : "100%",
            }}
            itemContent={(lesionIndex, lesion) => {
              const { id: lesionId } = lesion;
              return (
                <LesionsListItem
                  lesion={lesion}
                  selected={selectedLesionId === lesionId}
                  key={lesionIndex}
                  onClicked={handleLesionClicked}
                  // onClicked={memoHandleLesionClicked}
                  options={options}
                  getLesionMode={getLesionMode}
                  getReadonlyClassificationsFromLesion={getReadonlyClassificationsFromLesion}
                  dispatch={dispatch}
                  getNextLesionId={getNextLesionId}
                  classificationModeDefinitions={classificationModeDefinitions}
                  statTypes={statTypes}
                  timepoints={timepoints}
                  selectedLesionId={selectedLesionId}
                  lesionClassificationsDefinitions={lesionClassificationsDefinitions}
                  anatomicalStructures={anatomicalStructures}
                  anatomicalStructuresLoading={anatomicalStructuresLoading}
                  getViewerForSeries={getViewerForSeries}
                  getRoiViewOnlyOptions={getRoiViewOnlyOptions}
                  getIsLesionFromCurrentTask={getIsLesionFromCurrentTask}
                  onToggleMaskVisibility={onToggleMaskVisibility}
                  getMaskDownloadState={getMaskDownloadState}
                  jumpToViewerRoi={jumpToViewerRoi}
                  getTimepointOptionsFromRoiClassifications={
                    getTimepointOptionsFromRoiClassifications
                  }
                  headerStats={headerStats}
                  bodyStats={bodyStats}
                  getExistingRoiClassificationButtons={getExistingRoiClassificationButtons}
                  getMissingRoiTimepointOptions={getMissingRoiTimepointOptions}
                  deleteLesionClassificationsNotInMode={deleteLesionClassificationsNotInMode}
                  changeLesionColors={changeLesionColors}
                  deleteLesion={deleteLesion}
                  handleSeriesSelected={handleSeriesSelected}
                  deleteRois={deleteRois}
                  useInsertRoisObj={useInsertRoisObj}
                  updateTaskInProgress={updateTaskInProgress}
                  onTumourBurdenChanged={onTumourBurdenChanged}
                  insertLesionClassificationsObj={insertLesionClassificationsObj}
                  insertRoiClassificationsObj={insertRoiClassificationsObj}
                  updateLesionClassificationsForMode={updateLesionClassificationsForMode}
                  userId={userId}
                  cache={cache}
                  isRecist={isRecist}
                />
              );
            }}
            ref={virtuoso}
          />
        </ChildWrapper>
      )}
    </Wrapper>
  );
}

function getLabel(
  { showCount, headerLabel }: LesionsListOptions,
  lesions: LesionListItemFragmentType[]
): string {
  if (!showCount) {
    return headerLabel;
  } else return `${headerLabel} (${lesions.length})`;
}
LesionsList.whyDidYouRender = true;
