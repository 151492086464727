import { gql } from "@apollo/client";
import { ApolloCache } from "@apollo/client/core";

import { getRoiCacheId } from "../../AnnotationPanel/hooks/getRoiCacheId";

export function getRoiContoursFromCache<T>(roiId: number, cache: ApolloCache<T>): number[] {
  const result = cache.readFragment<{
    contours: { instanceId: number }[];
  }>({
    id: getRoiCacheId(roiId, cache),
    fragment: gql`
      fragment RoiContours on roi {
        contours {
          instanceId: instance_id
        }
      }
    `,
  });
  const { contours } = result ?? { contours: [] };

  return contours.map(({ instanceId }) => instanceId);
}
