import { Popover } from "@mui/material";
import React from "react";
import styled from "styled-components";

import { lesionColors } from "../../../../Annotate/components/Annotate/page/AnnotationPanel/utils/useLesionColorDeferred";

const Circle = styled.div<{ color: string; size: number }>`
  background: ${({ color }) => color};
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 50%;
  cursor: pointer;
  cursor: pointer;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
`;

const ColorsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
`;

const ColorWrapper = styled.div`
  padding: 8px;
`;

interface ColorPickerProps {
  color: string;
  onColorChanged: (color: string) => void;
}

export function ColorPicker({ color, onColorChanged }: ColorPickerProps): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClicked = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColorPicked = (color: string) => {
    handleClose();
    onColorChanged(color);
  };

  const open = Boolean(anchorEl);
  const id = open ? "viewer-popover" : undefined;

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 40,
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <ContentWrapper>
          <ColorsWrapper>
            {lesionColors.map((color, index) => (
              <ColorWrapper key={index} onClick={() => handleColorPicked(color)}>
                <Circle size={22} color={color} />
              </ColorWrapper>
            ))}
          </ColorsWrapper>
        </ContentWrapper>
      </Popover>
      <Circle color={color} size={16} onClick={handleClicked} />
    </>
  );
}
