import React from "react";

import { useCreateProjectDialog } from "../../../common/components/ProjectManagement/Dialogs/useCreateProjectDialog";
import { CreateButton } from "./CreateButton";

export function CreateProjectButton(): JSX.Element {
  const [setShowCreateProjectDialog, { dialog }] = useCreateProjectDialog();

  const handleCreateProject = () => {
    setShowCreateProjectDialog(true);
  };

  return (
    <>
      {dialog}
      <CreateButton label={"Create Project"} onClick={handleCreateProject} />
    </>
  );
}
