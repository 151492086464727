import { MutationTuple, useMutation } from "@apollo/client";
import { gql } from "@apollo/client/core";

import {
  ANATOMICAL_STRUCTURES_FRAGMENT,
  AnatomicalStructuresFragmentType,
} from "../../../Annotate/components/AnatomicalStructuresFragment";

const MUTATION = gql`
  mutation UpdateAnatomicalStructure($id: Int!, $name: String!, $color: String!) {
    update_anatomical_structures_by_pk(
      _set: { display_name: $name, color: $color }
      pk_columns: { anatomical_structures_id: $id }
    ) {
      ...AnatomicalStructure
    }
  }
  ${ANATOMICAL_STRUCTURES_FRAGMENT}
`;

type VariablesType = {
  id: number;
  name: string;
  color: string;
};

type DataType = {
  update_anatomical_structures_by_pk: AnatomicalStructuresFragmentType;
};

export function useUpdateAnatomicalStructure(): MutationTuple<DataType, VariablesType> {
  return useMutation<DataType, VariablesType>(MUTATION);
}
