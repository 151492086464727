import React from "react";
import styled from "styled-components";

const Text = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  text-align: right;
  color: ${(props) => props.theme.colors.neutral.neutral3};
`;

interface ClassificationLabelProps {
  option: { displayName: string };
}

export function ClassificationLabel({ option }: ClassificationLabelProps): JSX.Element {
  const { displayName } = option;
  return <Text>{displayName}</Text>;
}
