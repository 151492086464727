import { useContext } from "react";
import { useDeepCompareCallback } from "use-deep-compare";

import { ViewerContext } from "../ViewerContext";

export function useActivateViewer(): (id: number) => void {
  const { setViewerConfigs } = useContext(ViewerContext);

  return useDeepCompareCallback(
    (id) => {
      setViewerConfigs((viewerConfigs) =>
        viewerConfigs.map((viewer) => ({
          ...viewer,
          isActive: viewer.id === id,
        }))
      );
    },
    [setViewerConfigs]
  );
}
