import { gql } from "@apollo/client/core";

import { LesionSourceType } from "../../../../../types/LesionSourceType";
import { LesionTypeType } from "../../../../../types/LesionTypeType";
import { ClassificationType } from "../types/ClassificationType";
import { LESION_CLASSIFICATION_FRAGMENT } from "./LesionClassificationFragment";
import { ROI_LIST_ITEM_FRAGMENT, RoiListItemFragmentType } from "./RoiListItemFragment";

export const LESION_LIST_ITEM_FRAGMENT = gql`
  fragment LesionListItem on lesion {
    id: lesion_id
    taskId: task_id
    task {
      id
      enabled
    }
    created
    patientId: patient_id
    name
    type
    origin
    location
    classifications: lesion_classifications {
      ...LesionClassification
    }
    rois(
      where: $roiCondition
      order_by: [
        { series: { study: { follow_up_map: { follow_up: { follow_up_order: asc } } } } }
        { series: { series_date: asc } }
        { series: { study: { study_date: asc } } }
      ]
    ) {
      ...RoiListItem
    }
  }
  ${ROI_LIST_ITEM_FRAGMENT}
  ${LESION_CLASSIFICATION_FRAGMENT}
`;

export type LesionListItemFragmentType = {
  id: number;
  taskId: number | null;
  patientId: number;
  name: string;
  type: LesionTypeType;
  origin: LesionSourceType;
  location: number;
  task: {
    id: number;
    enabled: boolean;
  } | null;
  rois: RoiListItemFragmentType[];
  classifications: ClassificationType[];
  created: string;
};

export type GetLesionsListDataType = { lesions: LesionListItemFragmentType[] };

export type GetLesionsListVariablesType = GetLesionsListAdditionalOptionsType & {
  lesionCondition: LesionQueryConditionType;
  roiCondition: RoiQueryConditionType;
};

type GetLesionsListAdditionalOptionsType = {
  skipRecist: boolean;
  includeOtherLesions: boolean;
  includePredictedLesions: boolean;
};

export type LesionsListQueryInput = GetLesionsListAdditionalOptionsType & {
  taskContext: Input;
};

type PredictedLesionConditionType = {
  _and: [
    { origin: { _eq: "PREDICTION" } },
    { task_id: { _is_null: true } },
    {
      rois: {
        _and: [
          { ml_masks: Record<string, never> },
          {
            _not: {
              series: {
                view_only: {
                  _and: [{ project_id: { _eq: number } }, { enabled: { _eq: true } }];
                };
              };
            };
          }
        ];
      };
    }
  ];
};

type ProjectTaskConditionType = {
  task: { project_id: { _eq: number } };
};

type CurrentTaskLesionConditionType = {
  task_id: { _eq: number };
};

type TaskConditionType = CurrentTaskLesionConditionType | ProjectTaskConditionType;

export type LesionQueryConditionType = {
  _and: [
    { patient_id: { _eq: number } },
    {
      _or: [TaskConditionType] | [TaskConditionType, PredictedLesionConditionType];
    }
  ];
};

export type RoiQueryConditionType =
  | { _not: { series: { project_series_exclusions: { project_id: { _eq: number } } } } }
  | {
      _and: [
        { _not: { series: { project_series_exclusions: { project_id: { _eq: number } } } } },
        { series: { patients_with_children_and_cohorts: { cohort_id: { _in: number[] } } } }
      ];
    };

type Input = {
  id: number;
  task: { id: number; projectId: number; restrictedCohorts: { id: number }[] };
};

export function getLesionQueryCondition(
  taskContext: Input,
  includeOtherLesions: boolean,
  includePredictedLesions: boolean
): LesionQueryConditionType {
  const {
    id: patientId,
    task: { id: taskId, projectId },
  } = taskContext;

  const condition: LesionQueryConditionType = {
    _and: [
      { patient_id: { _eq: patientId } },
      {
        _or: [
          includeOtherLesions
            ? { task: { project_id: { _eq: projectId } } }
            : { task_id: { _eq: taskId } },
        ],
      },
    ],
  };

  if (includePredictedLesions) {
    // lesion is a prediction, has a null task id, has ml_masks, and is not view only
    condition._and[1]._or[1] = {
      _and: [
        { origin: { _eq: "PREDICTION" } },
        { task_id: { _is_null: true } },
        {
          rois: {
            _and: [
              { ml_masks: {} },
              {
                _not: {
                  series: {
                    view_only: {
                      _and: [{ project_id: { _eq: projectId } }, { enabled: { _eq: true } }],
                    },
                  },
                },
              },
            ],
          },
        },
      ],
    };
  }

  return condition;
}

export function getRoiQueryCondition(projectId: number, cohorts: number[]): RoiQueryConditionType {
  const condition: RoiQueryConditionType =
    cohorts.length > 0
      ? {
          _and: [
            { _not: { series: { project_series_exclusions: { project_id: { _eq: projectId } } } } },
            { series: { patients_with_children_and_cohorts: { cohort_id: { _in: cohorts } } } },
          ],
        }
      : { _not: { series: { project_series_exclusions: { project_id: { _eq: projectId } } } } };
  return condition;
}
