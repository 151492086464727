import { FC } from "react";

import { Card } from "../../../common/components/cards/Card";
import { PatientRouteProvider } from "../../../common/components/Link";
import { LinkToPatientProps } from "../../../Project/Layout/hooks/usePatientLinkHandler";
import {
  LinkToRouteProps,
  useProjectPageLinkHandler,
} from "../../../Project/Layout/hooks/useProjectPageLinkHandler";
import { FilterType } from "../../ChangeFromBaseline/components/Filter";
import { TumorBurdenSurvivalPredictionContainer } from "./TumorBurdenSurvivalPredictionContainer";

export interface ChartDataTypeProps {
  allowedDataTypes?: FilterType[];
}

type TumorBurdenSurvivalPredictionCardProps = LinkToRouteProps &
  LinkToPatientProps &
  ChartDataTypeProps;

export const MedianTumorBurdenSurvivalPredictionCard: FC<
  TumorBurdenSurvivalPredictionCardProps
> = ({ linkToRoute, patientRoute, allowedDataTypes }: TumorBurdenSurvivalPredictionCardProps) => {
  const handleGoToProjectPageLink = useProjectPageLinkHandler(linkToRoute);

  if (!allowedDataTypes) {
    throw new Error("MedianTumorBurdenSurvivalPredictionCard: allowedDataTypes should not be null");
  }
  return (
    <PatientRouteProvider patientRoute={patientRoute}>
      <Card
        title={"Median"}
        content={<TumorBurdenSurvivalPredictionContainer allowedDataTypes={allowedDataTypes} />}
        onClickHeader={handleGoToProjectPageLink}
      />
    </PatientRouteProvider>
  );
};
