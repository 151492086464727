import React from "react";

import { ToolController } from "../../../../../Dashboard/components/Settings/Patients/Viewer/ToolController";
import { Viewer, ViewerProps } from "./Viewer";

export function SimpleViewer({ viewerConfig }: Pick<ViewerProps, "viewerConfig">): JSX.Element {
  return (
    <Viewer
      viewerConfig={viewerConfig}
      options={{ resetImageIdIndexOnSeriesChange: true }}
      toolController={ToolController}
    />
  );
}
