import "react-day-picker/dist/style.css";
import "./day-picker.css";

import dayjs from "dayjs";
import React, { ChangeEventHandler, useEffect, useMemo, useState } from "react";
import { DateRange, DayPicker, SelectRangeEventHandler } from "react-day-picker";
import styled from "styled-components";

import { Input } from "../../../common/components/input/Input";
import { Label } from "../../../common/components/input/Label";
import { DayjsDateRange } from "../../../common/utils/dateFormatUtils/DayjsDateRange";
import { isInRange } from "../../../common/utils/dateFormatUtils/isInRange";

const dateFormat = "MM-DD-YYYY";

const TextFieldsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 12px;
  gap: 6px;
`;

interface DateRangePickerProps {
  defaultMonth?: dayjs.Dayjs;
  fromMonth?: dayjs.Dayjs;
  toMonth?: dayjs.Dayjs;
  selected: DayjsDateRange;
  onSelected: (range: DayjsDateRange) => void;
}

export function DateRangePicker({
  defaultMonth = dayjs(),
  fromMonth,
  toMonth = dayjs(),
  selected,
  onSelected,
}: DateRangePickerProps): JSX.Element {
  const [fromValue, setFromValue] = useState<string>("");
  const [toValue, setToValue] = useState<string>("");

  const [month, setMonth] = useState<Date>(new Date());

  const handleOnSelected: SelectRangeEventHandler = (dateRange: DateRange | undefined) => {
    if (!dateRange) {
      return;
    }

    const range = getDayjsDateRange(dateRange);

    setFromValue(range.from?.format(dateFormat) ?? "");
    setToValue(range.to?.format(dateFormat) ?? "");

    onSelected(range);
  };

  const handleFromChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setFromValue(e.target.value);

    const date = dayjs(e.target.value ?? dayjs(), dateFormat);

    if (!date.isValid()) {
      return;
    }

    if (selected?.to && date.isAfter(selected.to)) {
      onSelected({ from: selected.to, to: date });
    } else {
      onSelected({ from: date, to: selected?.to });
    }
  };

  const handleToChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setToValue(e.target.value);

    const date = dayjs(e.target.value ?? dayjs(), dateFormat);

    if (!date.isValid()) {
      return;
    }

    if (selected?.from && date.isBefore(selected.from)) {
      onSelected({ from: date, to: selected.from });
    } else {
      onSelected({ from: selected?.from, to: date });
    }
  };

  useEffect(() => {
    setFromValue(selected.from?.format(dateFormat) ?? "");
    setToValue(selected.to?.format(dateFormat) ?? "");

    setMonth((month) => {
      const isMonthInRange = isInRange(dayjs(month), selected);
      if (isMonthInRange) {
        return month;
      }

      return selected.from?.toDate() ?? new Date();
    });
  }, [selected]);

  const range = useMemo(() => getDateRange(selected), [selected]);

  return (
    <DayPicker
      mode="range"
      defaultMonth={defaultMonth.toDate()}
      fromMonth={fromMonth?.toDate()}
      toMonth={toMonth?.toDate()}
      selected={range}
      onSelect={handleOnSelected}
      month={month}
      onMonthChange={setMonth}
      footer={
        <TextFieldsWrapper>
          <div>
            <Label htmlFor={"from-date"}>Start</Label>
            <Input
              type="text"
              id={"from-date"}
              autoComplete={"off"}
              value={fromValue}
              onChange={handleFromChange}
            />
          </div>
          {" – "}
          <div>
            <Label htmlFor={"to-date"}>End</Label>
            <Input
              type="text"
              id={"to-date"}
              autoComplete={"off"}
              value={toValue}
              onChange={handleToChange}
            />
          </div>
        </TextFieldsWrapper>
      }
    />
  );
}

function getDateRange({ from, to }: DayjsDateRange): DateRange {
  return { from: from?.toDate(), to: to?.toDate() };
}

function getDayjsDateRange({ from, to }: DateRange): DayjsDateRange {
  return {
    from: dayjs(from ?? undefined).startOf("day"),
    to: dayjs(to ?? from ?? undefined).endOf("day"),
  };
}
