import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "./store";

export type PatientTableHeightsSliceType = {
  studies: Record<number, number>;
  series: Record<number, number>;
};

const initialState: PatientTableHeightsSliceType = {
  studies: {},
  series: {},
};

export const patientTableHeightsSlice = createSlice({
  name: "patientTableHeights",
  initialState,
  reducers: {
    setSubStudiesTableHeight(
      state,
      { payload: { patientId, height } }: PayloadAction<{ patientId: number; height: number }>
    ) {
      state.studies[patientId] = height;
    },
    setSubSeriesTableHeight(
      state,
      { payload: { studyId, height } }: PayloadAction<{ studyId: number; height: number }>
    ) {
      state.series[studyId] = height;
    },
  },
});

export const patientTableHeightActions = patientTableHeightsSlice.actions;

const state = (state: RootState) => state.patientTableHeights;

export const studiesSubTableHeights = createSelector([state], ({ studies }) => {
  return studies;
});

export const seriesSubTableHeights = createSelector([state], ({ series }) => series);
