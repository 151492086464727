import { useSelector } from "react-redux";

import { lesionCheckSelector } from "../../../../../../../common/store/annotatePage/taskSelector";
import {
  CHECK_MINIMUM_CONTOUR,
  CHECK_MISSING_CLASSIFICATIONS,
  CHECK_MISSING_CONTOURS,
  CHECK_MISSING_ROIS,
} from "../../../../../../enums/TaskDescriptionEnums";
import { LesionTaskCheckType } from "../../../../../../types/TaskDescriptionType";
import { useMinimumContourCheck } from "./Checks/MinimumContour";
import { useMissingClassificationsCheck } from "./Checks/MissingClassificationsCheck/useMissingClassificationsCheck";
import { useMissingContoursCheck } from "./Checks/MissingContours/useMissingContoursCheck";
import { useMissingRoisCheck } from "./Checks/MissingRois/useMissingRoisCheck";
import { UseLesionCheckReturnType } from "./types/UseTaskCheckReturnType";

export function useEnabledLesionChecks(): UseLesionCheckReturnType<unknown>[] {
  const enabledChecks = useSelector(lesionCheckSelector);

  if (!enabledChecks) {
    return [];
  }

  const allChecks: Record<LesionTaskCheckType, () => UseLesionCheckReturnType<unknown>> = {
    [CHECK_MISSING_CONTOURS]: useMissingContoursCheck,
    [CHECK_MISSING_CLASSIFICATIONS]: useMissingClassificationsCheck,
    [CHECK_MINIMUM_CONTOUR]: useMinimumContourCheck,
    [CHECK_MISSING_ROIS]: useMissingRoisCheck,
  };

  return enabledChecks.map((type) => allChecks[type]());
}
