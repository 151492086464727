import { gql } from "@apollo/client/core";

import { useParsedMutation } from "../../../apollo/utils/useParsedMutation";
import { useParsedMutationReturnType } from "../../../apollo/utils/useParsedMutationReturnType";
import { UserRoleType } from "../../types/UserRoleType";
import { UserType } from "../../types/UserType";

const MUTATION = gql`
  mutation UpdateProjectUserRoles(
    $userId: Int!
    $projectId: Int!
    $deletedProjectUserRoles: [enum_project_role_enum!]!
    $addedProjectUserRoles: [project_user_role_insert_input!]!
  ) {
    delete_project_user_role(
      where: {
        _and: [
          { user_id: { _eq: $userId } }
          { project_id: { _eq: $projectId } }
          { role: { _nin: $deletedProjectUserRoles } }
        ]
      }
    ) {
      affected_rows
    }
    insert_project_user_role(
      objects: $addedProjectUserRoles
      on_conflict: { constraint: role_pkey, update_columns: [] }
    ) {
      returning {
        projectId: project_id
        userId: user_id
        role
      }
    }
  }
`;

type Variables = {
  userId: number;
  projectId: number;
  deletedProjectUserRoles: string[];
  addedProjectUserRoles: {
    user_id: number;
    project_id: number;
    role: string;
  }[];
};

type Data = {
  delete_project_user_role: { affected_rows: number };
  insert_project_user_role: {
    returning: { projectId: number; userId: number; role: UserRoleType }[];
  };
};

type Input = {
  user: UserType;
  projectId: number;
};

export function useUpdateProjectUserRoles(): useParsedMutationReturnType<Input, undefined> {
  return useParsedMutation<Input, undefined, Data, Variables>({
    mutation: MUTATION,
    parseVariables,
    parseData: () => undefined,
  });
}

function parseVariables({ user, projectId }: Input): Variables {
  const { id: userId, projectRoles = [] } = user;
  const addedProjectUserRoles = projectRoles.map((role) => ({
    user_id: userId,
    project_id: projectId,
    role,
  }));

  return {
    userId,
    projectId,
    deletedProjectUserRoles: projectRoles,
    addedProjectUserRoles,
  };
}
