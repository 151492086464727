import styled from "styled-components";

export const Label = styled.label<{ required?: boolean; error?: unknown }>`
  z-index: 1000;
  position: absolute;
  margin: 0 0 0 20px;
  padding: 0 4px;
  background: ${(props) => props.theme.colors.actionPrimary.inverted};
  font-size: 12px;
  color: ${(props) =>
    props.error ? props.theme.colors.states.error : props.theme.colors.neutral.black};
  ${({ required, theme }) =>
    required &&
    `
&:after {
  content: "*";
  color: ${theme.colors.states.error};
}`};
`;

export const SelectLabel = styled.label<{
  required?: boolean;
  error?: unknown;
}>`
  z-index: 1000;
  position: absolute;
  left: 45px;
  margin: -6px 0 0 0;
  padding: 0 4px;
  background: ${(props) => props.theme.colors.actionPrimary.inverted};
  font-size: 12px;
  color: ${(props) =>
    props.error ? props.theme.colors.states.error : props.theme.colors.neutral.black};
  ${({ required, theme }) =>
    required &&
    `
&:after {
  content: "*";
  color: ${theme.colors.states.error};
}`};
`;
