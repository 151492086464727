import React, { FC, useContext, useEffect, useState } from "react";
import styled from "styled-components";

import { DialogContentInnerWrapper } from "../../../common/components/Dialog/DialogContentInnerWrapper";
import { ActionButtonsWrapper } from "../../../common/components/Dialog/Form/ActionButtonsWrapper";
import { FormProps } from "../../../common/components/Dialog/FormProps";
import { ErrorLabel } from "../../../common/components/input/ErrorLabel";
import { InputButton } from "../../../common/components/input/InputButton";
import { useCurrentProject } from "../../../common/queries/useCurrentProject";
import { main } from "../../../common/theme/main";
import { CohortType, getCohortSelectOption } from "../../../common/types/CohortType";
import { getProjectArmSelectOption, ProjectArmType } from "../../../common/types/ProjectArmType";
import { ProjectType } from "../../../common/types/ProjectType";
import { ProjectContext } from "../../../Project/contexts/ProjectContext";
import { GenericMultiSelect } from "./GenericMultiSelect";
import { GenericSingleSelect } from "./GenericSingleSelect";
import { UploadMetadataType } from "./UploadMetadataType";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 500px;
`;

const ColumnWrapper = styled(DialogContentInnerWrapper)`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 15px;
`;

const MessageText = styled.div`
  font-size: 14px;
  font-weight: 400;
`;

const BoldSpanText = styled.span`
  font-size: 14px;
  font-weight: 600;
`;

const LabelText = styled.div`
  font-size: 12px;
  font-weight: 600;
`;

const ItalicSpanText = styled.span`
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
`;

const RequiredSpanText = styled.span`
  font-size: 12px;
  font-weight: 600;
`;

export interface UploadMetadataFormProps {
  files: FileList | null;
  onConfirm: (metadata: UploadMetadataType) => Promise<void>;
  onCancelled: () => void;
}

export const UploadMetadataForm: FC<FormProps<UploadMetadataFormProps>> = ({
  onCancel,
  props: { files, onConfirm },
}: FormProps<UploadMetadataFormProps>) => {
  const { cohorts } = useContext(ProjectContext);

  let project: ProjectType | undefined = undefined;
  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { data } = useCurrentProject();
    project = data;
  } catch (e) {
    //
  }

  const [selectedCohorts, setSelectedCohorts] = useState<CohortType[]>([]);
  const [selectedProjectArm, setSelectedProjectArm] = useState<ProjectArmType | null>(null);

  const [cohortsNotSelectedError, setCohortsNotSelectedError] = useState<boolean>(false);

  const handleConfirm = async () => {
    const { id: projectArmId } = selectedProjectArm ?? { id: null };

    if (selectedCohorts.length === 0) {
      setCohortsNotSelectedError(true);
      return;
    }

    setCohortsNotSelectedError(false);

    const cohort_ids = selectedCohorts.map(({ id }) => id);

    const uploadMetadata: UploadMetadataType = {
      project_arm_id: projectArmId,
      cohort_ids: cohort_ids,
      source: "nota", //hardcode for nota-pacs source name
    };

    await onConfirm(uploadMetadata);
  };

  const handleCancel = () => {
    onCancel();
  };

  const { arms } = project ?? { arms: [] };

  useEffect(() => {
    setSelectedProjectArm(arms[0] ?? null);
  }, [arms]);

  useEffect(() => {
    if (selectedCohorts.length > 0 && cohortsNotSelectedError) {
      setCohortsNotSelectedError(false);
    }
  }, [selectedCohorts]);

  return (
    <form>
      <Wrapper>
        <ColumnWrapper>
          <MessageText>
            Selected <BoldSpanText>{files ? files.length : 0} file(s)</BoldSpanText> to upload
          </MessageText>
          {arms.length > 0 && (
            <>
              <LabelText>
                Project Arm<ItalicSpanText> (Optional)</ItalicSpanText>
              </LabelText>
              <GenericSingleSelect
                values={arms}
                selected={selectedProjectArm}
                onSelectedChanged={setSelectedProjectArm}
                getOption={getProjectArmSelectOption}
                menuPortalTarget={document.body}
                isClearable={true}
              />
            </>
          )}
          <LabelText>
            Cohorts
            <RequiredSpanText> (Required)</RequiredSpanText>
          </LabelText>
          <GenericMultiSelect
            values={cohorts}
            selected={selectedCohorts}
            onSelectedChanged={setSelectedCohorts}
            getOption={getCohortSelectOption}
            menuPortalTarget={document.body}
          />
          {cohortsNotSelectedError && (
            <ErrorLabel>At least one cohort must be selected!</ErrorLabel>
          )}
        </ColumnWrapper>
      </Wrapper>
      <ActionButtonsWrapper>
        <InputButton type="button" value={"Upload"} onClick={handleConfirm} />
        <InputButton
          type="button"
          value={"Cancel"}
          background={main.colors.neutral.white}
          color={main.colors.neutral.black}
          onClick={handleCancel}
        />
      </ActionButtonsWrapper>
    </form>
  );
};
