import dayjs from "dayjs";

import { FOLLOW_UP_TEXT } from "../../../../../../Analysis/common/utils/FollowUpText";
import { formatFollowUp } from "../../../../../../Analysis/common/utils/formatFollowUp";
import { DATE_UNKNOWN } from "../../../utils/formatDate";
import { TimepointType } from "../../AnnotationPanel/hooks/Timepoint/TimepointType";

const UNKNOWN_FOLLOWUP = `Unknown ${FOLLOW_UP_TEXT}`;

export function formatTimepointLabel(
  { date, index }: TimepointType,
  showFollowUpLabel: boolean,
  includeDay: boolean
): string {
  if (showFollowUpLabel) {
    if (index === undefined) {
      return UNKNOWN_FOLLOWUP;
    }

    return formatFollowUp(index);
  }

  if (!date) {
    return DATE_UNKNOWN;
  }

  return dayjs(date).format(includeDay ? "MMM D YYYY" : "MMM YYYY");
}

export function timepointIncludeDay(timepoints: TimepointType[], index: number): boolean {
  const timepoint = timepoints[index];
  if (!timepoint.date) {
    return false;
  }

  const otherTimepoints = [...timepoints];
  otherTimepoints.splice(index, 1);

  const timePointYear = timepoint.date.getFullYear();
  const timePointMonth = timepoint.date.getMonth();

  return otherTimepoints.some(
    (e) => e.date?.getMonth() === timePointMonth && e.date?.getFullYear() === timePointYear
  );
}
