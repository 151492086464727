import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { MutationTuple } from "@apollo/client/react/types/types";

const MUTATION = gql`
  mutation UpdateLesionLocation($id: Int!, $location: Int!) {
    updateLesion: update_lesion_by_pk(
      pk_columns: { lesion_id: $id }
      _set: { location: $location }
    ) {
      ...UpdateLesionLocationFragment
    }
  }

  fragment UpdateLesionLocationFragment on lesion {
    id: lesion_id
    location
  }
`;

type Variables = {
  id: number;
  location: number;
};

type Data = {
  updateLesion: Variables;
};

export function useUpdateLesionLocation(): MutationTuple<Data, Variables> {
  return useMutation<Data, Variables>(MUTATION);
}
