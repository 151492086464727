import React from "react";

import { GlobalRoleType, UserRoleType } from "../../types/UserRoleType";
import { Label } from "./Label";
import { getRoleColor } from "./utils/roleColors";
import { roleLabels } from "./utils/roleLabels";

interface RoleLabelProps {
  role: UserRoleType | GlobalRoleType;
}

export function RoleLabel({ role }: RoleLabelProps): JSX.Element {
  const text = roleLabels[role];
  const { color, background } = getRoleColor(role);

  return <Label label={text} backgroundColor={background} textColor={color} />;
}
