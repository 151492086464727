// Note: trying to consolidate common string utils into a single file.

export const sentenceToSnakeCase = (
  str: string,
  { trim = true }: { trim?: boolean } = {}
): string => {
  const result = str
    .replace(/([A-Z])/g, " $1")
    .trim()
    .toLowerCase()
    .split(" ")
    .join("_");

  return trim ? result : result + "_";
};

const SPECIAL_CASE_WORDS = ["DICOM", "CT", "MRI", "PET-CT"];
export const specialCaseWords = (str: string): string => {
  return str.replace(
    new RegExp("\\b(" + [...SPECIAL_CASE_WORDS].join("|") + ")\\b", "gi"),
    (match) => match.toUpperCase()
  );
};
