import { useDialogReturnType } from "../../../../common/components/Dialog/useDialog";
import { useFormDialog } from "../../../../common/components/Dialog/useFormDialog";
import { CreateAnnotationForm, CreateAnnotationFormProps } from "./CreateAnnotationForm";

export function useCreateAnnotationForm(props: CreateAnnotationFormProps): useDialogReturnType {
  return useFormDialog({
    children: CreateAnnotationForm,
    label: "Create Annotation",
    showCloseButton: true,
    props,
  });
}
