import React from "react";

import { RoiListItemFragmentType } from "../fragments/RoiListItemFragment";
import { useJumpToViewerRoi } from "./useJumpToViewerRoi";
import { useTrySelectLesionId } from "./useTrySelectLesionId";

export type OnRoiClickedReturnType = (
  e?: React.MouseEvent<Element, MouseEvent>,
  roi?: RoiListItemFragmentType | undefined
) => void;

export function useOnRoiClicked(lesionId: number): OnRoiClickedReturnType {
  const trySelectLesionId = useTrySelectLesionId();
  const jumpToViewerRoi = useJumpToViewerRoi();

  return (e?: React.MouseEvent<Element, MouseEvent>, roi?: RoiListItemFragmentType | undefined) => {
    e?.stopPropagation();

    const onLesionChanged = () => {
      if (!roi) {
        return;
      }

      jumpToViewerRoi(roi);
    };

    if (!trySelectLesionId(lesionId, onLesionChanged)) {
      return;
    }
  };
}
