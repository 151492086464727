import React, { ReactNode } from "react";
import styled from "styled-components";

import { ReactComponent as AddIcon } from "../../../assets/svgs/PlusOutlineFilled.svg";
import { SvgIcon } from "../../../common/components/icons/SvgIcon";
import { main } from "../../../common/theme/main";

const StyledButton = styled.div`
  flex: 1;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.secondary.secondary};
  border: none;
  color: ${(props) => props.theme.colors.neutral.neutral9};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  padding: 6px;
`;

const TextWrapper = styled.div`
  flex: 1;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
`;

interface CreateButtonProps {
  label: ReactNode;
  onClick: () => void;
}

export function CreateButton({ label, onClick }: CreateButtonProps): JSX.Element {
  return (
    <StyledButton onClick={onClick}>
      <SvgIcon icon={AddIcon} color={main.colors.neutral.white} />
      <TextWrapper>{label}</TextWrapper>
    </StyledButton>
  );
}
