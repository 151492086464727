import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { allowedOrgansSelector } from "../../../../../../../../../common/store/annotatePage/taskSelector";
import { ERROR, SUCCESS } from "../../../../../../../../../common/types/StatusTypes";
import { CHECK_MISSING_ORGANS } from "../../../../../../../../enums/TaskDescriptionEnums";
import { useMissingOrgans } from "../../../../hooks/useMissingOrgans";
import { UseTaskCheckReturnType } from "../../types/UseTaskCheckReturnType";

const Bold = styled.span`
  font-weight: bold;
`;

export type MissingOrgansCheckResultType = {
  anatomicalStructureId: number;
}[];

export function useMissingOrgansCheck(): UseTaskCheckReturnType<MissingOrgansCheckResultType> {
  const requiredOrgans = useSelector(allowedOrgansSelector);

  const { missingOrgans } = useMissingOrgans(requiredOrgans);

  return () => {
    const missingOrganNames = missingOrgans?.map(({ name }) => name) ?? [];
    const status = missingOrganNames.length === 0 ? SUCCESS : ERROR;

    const successMessage = "All organs have been defined.";
    const errorMessage = (
      <>
        Missing the following organs: <Bold>{missingOrganNames.join(", ")}</Bold>
      </>
    );

    const message = status === SUCCESS ? successMessage : errorMessage;

    const result =
      missingOrgans?.map(({ id }) => ({
        anatomicalStructureId: id,
      })) ?? [];

    return {
      type: CHECK_MISSING_ORGANS,
      message,
      status,
      required: true,
      result,
    };
  };
}
