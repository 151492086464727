import { ApolloCache } from "@apollo/client";
import { ClassificationModeDefinitionsType } from "nota-predict-web/src/common/store/annotatePage/ClassificationsMapType";
import React, { Dispatch, useCallback, useEffect, useState } from "react";
import isEqual from "react-fast-compare";
import styled from "styled-components";
import { useDeepCompareMemo } from "use-deep-compare";

import { ReactComponent as CloseIcon } from "../../../../../assets/svgs/Close.svg";
import { useOkCancelForm } from "../../../../../common/components/Dialog/useOkCancelForm";
import { SvgIcon } from "../../../../../common/components/icons/SvgIcon";
import {
  ClassificationsDefinitionsType,
  clearLesionClassificationsDefinitions,
  setLesionClassificationsDefinitions,
} from "../../../../../common/store/annotatePage/classificationsSlice";
import { setSelectedLesionId } from "../../../../../common/store/annotatePage/selectionSlice";
import { ViewerConfigType } from "../../../../../common/store/annotatePage/ViewerConfigType";
import { StatsType } from "../../../../types/TaskDescriptionType";
import { AnatomicalStructuresFragmentType } from "../../../AnatomicalStructuresFragment";
import { LesionListItemFragmentType } from "./fragments/LesionListItemFragment";
import { RoiListItemFragmentType } from "./fragments/RoiListItemFragment";
import { RoiListItemTumourFragmentType } from "./fragments/RoiListItemTumourFragment";
import { TimepointType } from "./hooks/Timepoint/TimepointType";
import { ExistingRoiClassificationButtonsReturnType } from "./hooks/timepointOptions/useGetExistingRoiClassificationButtons";
import { MissingRoiClassificationButtonsReturnType } from "./hooks/timepointOptions/useGetMissingRoiClassificationButtons";
import { RoiViewOnlyOptionsReturnType } from "./hooks/timepointOptions/useGetRoiViewOnlyOptions";
import { TimepointOptionsFromRoiClassificationsReturnType } from "./hooks/timepointOptions/useGetTimepointOptionsFromRoiClassifications";
import { DeleteLesionFunctionType } from "./hooks/useDeleteLesion";
import { DeleteLesionClassificationsNotInModeReturnType } from "./hooks/useDeleteLesionClassificationsNotInMode";
import { DeleteRoisReturnType } from "./hooks/useDeleteRois";
import { LesionModeReturnType } from "./hooks/useGetLesionMode";
import {
  ClassificationDisplayNameType,
  ReadonlyClassificationsReturnType,
} from "./hooks/useGetReadonlyClassificationsFromLesion";
import { InsertLesionClassificationsInternalReturnType } from "./hooks/useInsertLesionClassification";
import { InsertRoiClassificationsReturnType } from "./hooks/useInsertRoiClassifications";
import { InsertRoisReturnType } from "./hooks/useInsertRois";
import { useIsLesionViewOnly } from "./hooks/useIsLesionViewOnly";
import { NextLesionIdReturnType } from "./hooks/useNextLesionId";
import { OnRoiClickedReturnType, useOnRoiClicked } from "./hooks/useOnRoiClicked";
import { OnSeriesClickedReturnType } from "./hooks/useOnSeriesClicked";
import { IconButton } from "./IconButton";
import { LesionClassificationsSelector } from "./LesionClassificationsSelector";
import { LesionsListOptions } from "./LesionsList";
import { LesionsListItemHeaderLabel } from "./LesionsListItemHeaderLabel";
import { Mask } from "./MaskContextProvider";
import { DownloadState } from "./Masks/types/downloadState";
import { ClassificationLabel } from "./RoiCard/ClassificationLabel";
import { SeriesRoiCardWrapper } from "./RoiCard/SeriesRoiCardWrapper";
import { LesionTotalBurdenStat } from "./Stats/LesionTotalBurdenStat";
import { TasklessLesionClassificationsSelector } from "./TasklessLesionClassificationsSelector";
import { useLesionColorDeferred } from "./utils/useLesionColorDeferred";

const Wrapper = styled.div<{
  selected: boolean;
}>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: ${(props) =>
    props.selected ? props.theme.colors.neutral.neutral7 : props.theme.colors.background.secondary};
  cursor: pointer;
`;

const ChildWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 6px 6px 6px 12px;
`;

const ActionButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const StatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
`;

const StatAndActionButtonsWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`;

interface LesionsListItemProps {
  lesion: LesionListItemFragmentType;
  selected: boolean;
  onClicked: (lesion: LesionListItemFragmentType) => void;
  options: LesionsListOptions;
  getLesionMode: LesionModeReturnType;
  getReadonlyClassificationsFromLesion: ReadonlyClassificationsReturnType;
  dispatch: Dispatch<any>;
  getNextLesionId: NextLesionIdReturnType;
  classificationModeDefinitions: ClassificationModeDefinitionsType;
  selectedLesionId: number | null;
  lesionClassificationsDefinitions: Record<number, ClassificationsDefinitionsType>;
  anatomicalStructures: AnatomicalStructuresFragmentType[];
  anatomicalStructuresLoading: boolean;
  getViewerForSeries: (seriesId: number) => ViewerConfigType | undefined;
  getRoiViewOnlyOptions: RoiViewOnlyOptionsReturnType;
  getIsLesionFromCurrentTask: (lesion: Pick<LesionListItemFragmentType, "taskId">) => boolean;
  onToggleMaskVisibility: (mask: Mask) => Promise<boolean>;
  getMaskDownloadState: (maskId: number) => DownloadState | undefined;
  jumpToViewerRoi: (roi: RoiListItemFragmentType) => void;
  getTimepointOptionsFromRoiClassifications: TimepointOptionsFromRoiClassificationsReturnType;
  headerStats: StatsType[];
  bodyStats: StatsType[];
  getExistingRoiClassificationButtons: ExistingRoiClassificationButtonsReturnType;
  getMissingRoiTimepointOptions: MissingRoiClassificationButtonsReturnType;
  deleteLesionClassificationsNotInMode: DeleteLesionClassificationsNotInModeReturnType;
  changeLesionColors: (lesionId: number | null) => void;
  deleteLesion: DeleteLesionFunctionType;
  handleSeriesSelected: OnSeriesClickedReturnType;
  deleteRois: DeleteRoisReturnType;
  useInsertRoisObj: InsertRoisReturnType;
  updateTaskInProgress: () => Promise<void>;
  onTumourBurdenChanged: (lesionId: number) => Promise<void>;
  insertLesionClassificationsObj: InsertLesionClassificationsInternalReturnType;
  insertRoiClassificationsObj: InsertRoiClassificationsReturnType;
  updateLesionClassificationsForMode: (
    isBaseline: boolean,
    lesion: LesionListItemFragmentType
  ) => Promise<void>;
  userId: number;
  cache: ApolloCache<object>;
  isRecist: boolean;
  statTypes?: StatsType[];
  timepoints?: TimepointType[];
}

interface TimepointCardProps {
  lesion: LesionListItemFragmentType;
  timepoint: TimepointType;
  timepointIndex: number;
  count: number;
  isFromCurrentTask: boolean;
  getViewerForSeries: (seriesId: number) => ViewerConfigType | undefined;
  getRoiViewOnlyOptions: RoiViewOnlyOptionsReturnType;
  getIsLesionFromCurrentTask: (lesion: Pick<LesionListItemFragmentType, "taskId">) => boolean;
  canClassify: boolean;
  canDelete: boolean;
  onToggleMaskVisibility: (mask: Mask) => Promise<boolean>;
  getMaskDownloadState: (maskId: number) => DownloadState | undefined;
  jumpToViewerRoi: (roi: RoiListItemFragmentType) => void;
  getTimepointOptionsFromRoiClassifications: TimepointOptionsFromRoiClassificationsReturnType;
  headerStats: StatsType[];
  bodyStats: StatsType[];
  getExistingRoiClassificationButtons: ExistingRoiClassificationButtonsReturnType;
  canAdd: boolean;
  getMissingRoiTimepointOptions: MissingRoiClassificationButtonsReturnType;
  handleRoiSelected: OnRoiClickedReturnType;
  handleSeriesSelected: OnSeriesClickedReturnType;
  deleteRois: DeleteRoisReturnType;
  useInsertRoisObj: InsertRoisReturnType;
  updateTaskInProgress: () => Promise<void>;
  onTumourBurdenChanged: (lesionId: number) => Promise<void>;
  insertLesionClassificationsObj: InsertLesionClassificationsInternalReturnType;
  insertRoiClassificationsObj: InsertRoiClassificationsReturnType;
  updateLesionClassificationsForMode: (
    isBaseline: boolean,
    lesion: LesionListItemFragmentType
  ) => Promise<void>;
  userId: number;
  cache: ApolloCache<object>;
  isRecist: boolean;
}

const TimepointCard = ({
  lesion,
  timepoint,
  timepointIndex,
  count,
  isFromCurrentTask,
  getViewerForSeries,
  getRoiViewOnlyOptions,
  getIsLesionFromCurrentTask,
  canClassify,
  canDelete,
  onToggleMaskVisibility,
  getMaskDownloadState,
  jumpToViewerRoi,
  getTimepointOptionsFromRoiClassifications,
  getExistingRoiClassificationButtons,
  headerStats,
  bodyStats,
  canAdd,
  getMissingRoiTimepointOptions,
  handleRoiSelected,
  handleSeriesSelected,
  deleteRois,
  useInsertRoisObj,
  updateTaskInProgress,
  onTumourBurdenChanged,
  insertLesionClassificationsObj,
  insertRoiClassificationsObj,
  updateLesionClassificationsForMode,
  userId,
  cache,
  isRecist,
}: TimepointCardProps) => {
  const { series, label = "" } = timepoint;
  const { rois } = lesion;
  const seriesIds = series.map(({ id }) => id);

  const isFirst = timepointIndex === 0;
  const isLast = timepointIndex === count - 1;

  const roiList = rois.filter(({ series: { id: roiSeriesId } }) => seriesIds.includes(roiSeriesId));
  const memoRoiList = useDeepCompareMemo(() => roiList, [roiList]);

  return (
    <SeriesRoiCardWrapper
      isFirst={isFirst}
      isLast={isLast}
      lesion={lesion}
      seriesList={series}
      label={label}
      rois={memoRoiList}
      isFromCurrentTask={isFromCurrentTask}
      getViewerForSeries={getViewerForSeries}
      getRoiViewOnlyOptions={getRoiViewOnlyOptions}
      getIsLesionFromCurrentTask={getIsLesionFromCurrentTask}
      canClassify={canClassify}
      canDelete={canDelete}
      onToggleMaskVisibility={onToggleMaskVisibility}
      getMaskDownloadState={getMaskDownloadState}
      jumpToViewerRoi={jumpToViewerRoi}
      getTimepointOptionsFromRoiClassifications={getTimepointOptionsFromRoiClassifications}
      headerStats={headerStats}
      bodyStats={bodyStats}
      getExistingRoiClassificationButtons={getExistingRoiClassificationButtons}
      canAdd={canAdd}
      getMissingRoiTimepointOptions={getMissingRoiTimepointOptions}
      handleRoiSelected={handleRoiSelected}
      handleSeriesSelected={handleSeriesSelected}
      deleteRois={deleteRois}
      useInsertRoisObj={useInsertRoisObj}
      updateTaskInProgress={updateTaskInProgress}
      onTumourBurdenChanged={onTumourBurdenChanged}
      insertLesionClassificationsObj={insertLesionClassificationsObj}
      insertRoiClassificationsObj={insertRoiClassificationsObj}
      updateLesionClassificationsForMode={updateLesionClassificationsForMode}
      userId={userId}
      cache={cache}
      isRecist={isRecist}
    />
  );
};

export function LesionsListItem({
  lesion,
  onClicked,
  selected,
  options,
  getLesionMode,
  getReadonlyClassificationsFromLesion,
  dispatch,
  getNextLesionId,
  classificationModeDefinitions,
  selectedLesionId,
  lesionClassificationsDefinitions,
  anatomicalStructures,
  anatomicalStructuresLoading,
  getViewerForSeries,
  getRoiViewOnlyOptions,
  getIsLesionFromCurrentTask,
  onToggleMaskVisibility,
  getMaskDownloadState,
  jumpToViewerRoi,
  getTimepointOptionsFromRoiClassifications,
  headerStats,
  bodyStats,
  getExistingRoiClassificationButtons,
  getMissingRoiTimepointOptions,
  deleteLesionClassificationsNotInMode,
  changeLesionColors,
  deleteLesion,
  handleSeriesSelected,
  deleteRois,
  useInsertRoisObj,
  updateTaskInProgress,
  onTumourBurdenChanged,
  insertLesionClassificationsObj,
  insertRoiClassificationsObj,
  updateLesionClassificationsForMode,
  userId,
  cache,
  isRecist,
  statTypes,
  timepoints,
}: LesionsListItemProps): JSX.Element {
  const [readonlyClassifications, setReadonlyClassifications] = useState<
    ClassificationDisplayNameType[]
  >([]);

  const { rois, id: lesionId, location } = lesion;

  // TODO these should be memoized at the source --B
  const { canAdd, canClassify, canDelete, isFromCurrentTask } = useIsLesionViewOnly(lesion);

  const headerStatType = statTypes?.find((statType) => statType === "TOTAL_BURDEN");

  const [destructuredDeleteLesion] = deleteLesion;

  // LesionList Hook tree reactor
  const headerLabelColor = useLesionColorDeferred()(location);
  const handleRoiSelected = useOnRoiClicked(lesionId);
  // End of hook refactor

  const handleConfirmDeleteLesion = async () => {
    const nextLesionId = getNextLesionId(lesionId);

    changeLesionColors(nextLesionId);
    dispatch(setSelectedLesionId(nextLesionId));

    const tumours = rois
      .flatMap(({ tumours }) => tumours?.map(({ tumour }) => tumour))
      .filter((tumour) => tumour) as RoiListItemTumourFragmentType[];
    const diagnosisIds = tumours.map(({ diagnosis: { id } }) => id);
    await destructuredDeleteLesion({
      variables: {
        lesionId,
        diagnosisIds,
      },
    });
  };

  const handleClicked = useCallback(() => {
    onClicked(lesion);
  }, [onClicked, lesion]);

  useEffect(() => {
    if (anatomicalStructuresLoading) {
      return;
    }
    const lesionMode = getLesionMode(lesion);
    const newReadonlyClassifications = isFromCurrentTask
      ? getReadonlyClassificationsFromLesion(lesion)
      : [];
    if (!isEqual(newReadonlyClassifications, readonlyClassifications)) {
      setReadonlyClassifications(newReadonlyClassifications);
    }

    if (!classificationModeDefinitions[lesionMode]) {
      dispatch(clearLesionClassificationsDefinitions({ lesionId }));
      return;
    }
    const { map: classificationsMapTemplate, useAnatomySelector } =
      classificationModeDefinitions[lesionMode];

    if (isFromCurrentTask) {
      deleteLesionClassificationsNotInMode(lesionMode, lesion);
    }

    dispatch(
      setLesionClassificationsDefinitions({
        lesion,
        classificationsMapTemplate,
        // TODO: Passing this object in as a prop to redux state is going to cause all sorts of fun things
        // This hook should consume a context instead --B
        anatomicalStructures: anatomicalStructures,
        useAnatomySelector,
      })
    );
  }, [
    // TODO: This exhaustive list of deps may contribute to unnecessary re-renders, but we don't want to break functionality. Part of #184924509.
    // We should revisit in the next stage of the performance work and see if we can remove some of these deps.
    // Known culprits: getReadonlyClassificationsFromLesion, deleteLesionClassificationsNotInMode, un-memo anatomicalStructures
    lesion,
    anatomicalStructures,
    classificationModeDefinitions,
    anatomicalStructuresLoading,
    getLesionMode,
    lesionId,
    isFromCurrentTask,
    // getReadonlyClassificationsFromLesion,
    // deleteLesionClassificationsNotInMode,
    // dispatch,
  ]);

  const { noun } = options;
  const typeNameLower = noun.toLowerCase();
  const [showConfirmDeleteDialog, { dialog: confirmDeleteDialog }] = useOkCancelForm({
    title: `Delete ${noun}`,
    message: `Deleting a ${typeNameLower} will also remove all related annotations. Are you sure you want to delete this ${typeNameLower}?`,
    okLabel: "Confirm",
    onOkCallback: handleConfirmDeleteLesion,
  });

  const handleDeleteClicked = useCallback(() => showConfirmDeleteDialog(true), []);

  return (
    <>
      {confirmDeleteDialog}
      <Wrapper selected={selected} onClick={handleClicked}>
        <HeaderWrapper>
          <LesionsListItemHeaderLabel
            lesion={lesion}
            viewOnly={!canClassify}
            selectedLesionId={selectedLesionId}
            color={headerLabelColor}
          />
          <StatAndActionButtonsWrapper>
            {isFromCurrentTask && (
              <StatWrapper>
                {readonlyClassifications.length > 0 && (
                  <ClassificationLabel option={readonlyClassifications[0]} />
                )}
                {headerStatType && (
                  <LesionTotalBurdenStat lesion={lesion} timepoints={timepoints} />
                )}
              </StatWrapper>
            )}
            <ActionButtonsWrapper>
              {canDelete && (
                <IconButton onClick={handleDeleteClicked} key={1}>
                  <SvgIcon icon={CloseIcon} size={16} />
                </IconButton>
              )}
            </ActionButtonsWrapper>
          </StatAndActionButtonsWrapper>
        </HeaderWrapper>
        {isFromCurrentTask && (
          <LesionClassificationsSelector
            lesion={lesion}
            viewOnly={!canClassify}
            lesionClassificationsDefinitions={lesionClassificationsDefinitions}
            getLesionMode={getLesionMode}
            dispatch={dispatch}
          />
        )}
        {!isFromCurrentTask && (
          <TasklessLesionClassificationsSelector
            lesion={lesion}
            anatomicalStructures={anatomicalStructures}
          />
        )}
        <ChildWrapper>
          {timepoints?.map((timepoint, timepointIndex) => (
            <TimepointCard
              lesion={lesion}
              timepoint={timepoint}
              timepointIndex={timepointIndex}
              count={timepoints?.length ?? 0}
              key={`${timepoint?.series?.[0]?.studyId}-${timepointIndex}`}
              isFromCurrentTask={isFromCurrentTask}
              getViewerForSeries={getViewerForSeries}
              getRoiViewOnlyOptions={getRoiViewOnlyOptions}
              getIsLesionFromCurrentTask={getIsLesionFromCurrentTask}
              canClassify={canClassify}
              canDelete={canDelete}
              onToggleMaskVisibility={onToggleMaskVisibility}
              getMaskDownloadState={getMaskDownloadState}
              jumpToViewerRoi={jumpToViewerRoi}
              getTimepointOptionsFromRoiClassifications={getTimepointOptionsFromRoiClassifications}
              headerStats={headerStats}
              bodyStats={bodyStats}
              getExistingRoiClassificationButtons={getExistingRoiClassificationButtons}
              canAdd={canAdd}
              getMissingRoiTimepointOptions={getMissingRoiTimepointOptions}
              handleRoiSelected={handleRoiSelected}
              handleSeriesSelected={handleSeriesSelected}
              deleteRois={deleteRois}
              useInsertRoisObj={useInsertRoisObj}
              updateTaskInProgress={updateTaskInProgress}
              onTumourBurdenChanged={onTumourBurdenChanged}
              insertLesionClassificationsObj={insertLesionClassificationsObj}
              insertRoiClassificationsObj={insertRoiClassificationsObj}
              updateLesionClassificationsForMode={updateLesionClassificationsForMode}
              userId={userId}
              cache={cache}
              isRecist={isRecist}
            />
          ))}
        </ChildWrapper>
      </Wrapper>
    </>
  );
}
LesionsListItem.whyDidYouRender = true;
TimepointCard.whyDidYouRender = true;
