import React, { forwardRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { ActionButton } from "../../../../Annotate/components/Manage/ActionButton";
import { ReactComponent as CancelIcon } from "../../../../assets/svgs/CancelOutline.svg";
import { ReactComponent as AddIcon } from "../../../../assets/svgs/PlusOutline.svg";
import { Card } from "../../../../common/components/cards/Card";
import { Table, TableProps } from "../../../../common/components/Table/Table";
import { TableControlsType } from "../../../../common/components/Table/TableControlsType";
import { useCreateUserDialog } from "../../../../common/components/UserManagement/Dialogs/useCreateUserDialog";
import { UserType } from "../../../../common/types/UserType";
import { useUsers } from "../../../../Settings/useUsers";
import { GlobalUserManagementContextProvider } from "./GlobalUserManagementContextProvider";
import { UserTableRowType } from "./UserTableRowType";
import { useUserTableColumns } from "./useUserTableColumns";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ButtonsWrapper = styled.div`
  flex: 0;
  display: flex;
  padding: 4px;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

export function GlobalUserManagement(): JSX.Element {
  const [users, setUsers] = useState<UserType[]>([]);

  const { data: allUsers } = useUsers();

  useEffect(() => {
    if (!allUsers) {
      setUsers([]);
      return;
    }

    setUsers(allUsers);
  }, [allUsers]);

  return (
    <GlobalUserManagementContextProvider users={users} setUsers={setUsers}>
      <Page users={users} />
    </GlobalUserManagementContextProvider>
  );
}

interface PageProps {
  users: UserType[];
}

function Page({ users }: PageProps): JSX.Element {
  const tableRef = useRef<TableControlsType<UserTableRowType>>(null);

  const [setCreateUserDialogOpen, { dialog: createUserDialog }] = useCreateUserDialog(users);

  const handleCreateUserClicked = () => {
    setCreateUserDialogOpen(true);
  };

  const handleClearClicked = () => {
    const { current: tableControls } = tableRef;
    tableControls?.clearAllFilters();
  };

  const columns = useUserTableColumns();

  return (
    <Card
      noHead
      expand={true}
      content={
        <Wrapper>
          {createUserDialog}
          <ButtonsWrapper>
            {" "}
            <ActionButton
              label={"Clear"}
              icon={CancelIcon}
              onClick={handleClearClicked}
              tooltip={"Clear all selections/filters"}
            />
            <ActionButton
              label={"Invite Team Member"}
              icon={AddIcon}
              onClick={handleCreateUserClicked}
              tooltip={"Invite a new team member"}
            />
          </ButtonsWrapper>
          <TableWithRef
            ref={tableRef}
            columns={columns}
            data={users}
            enableSelect={false}
            minimumWidth={600}
          />
        </Wrapper>
      }
    />
  );
}

const TableWithRef = forwardRef<TableControlsType<UserTableRowType>, TableProps<UserTableRowType>>(
  (props, ref) => {
    return <Table {...props} forwardedRef={ref} />;
  }
);
TableWithRef.displayName = "TableWithRef";
