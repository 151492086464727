import { useContext } from "react";

import { useChangeViewerSeries } from "../../../../ViewerPanel/Viewer/hooks/useChangeViewerSeries";
import { useGetViewerForSeries } from "../../../../ViewerPanel/Viewer/hooks/useGetViewerForSeries";
import { ViewerContext } from "../../../../ViewerPanel/Viewer/ViewerContext";

type ReturnType = (seriesId: number) => number | undefined;

export function useJumpToSeries(): ReturnType {
  const changeViewerSeries = useChangeViewerSeries();
  const getViewerForSeries = useGetViewerForSeries();
  const { viewerConfigs } = useContext(ViewerContext);

  return (seriesId) => {
    if (viewerConfigs.length === 0) {
      console.warn("Using a jump to hook but there are no viewers");
      return undefined;
    }

    const { id: viewerConfigId } = getViewerForSeries(seriesId) ?? viewerConfigs[0];

    const roiSeriesShown = viewerConfigs.some(
      ({ seriesId: viewerConfigSeriesId }) => viewerConfigSeriesId === seriesId
    );
    if (!roiSeriesShown) {
      changeViewerSeries(viewerConfigId, seriesId);
    }

    return viewerConfigId;
  };
}
