import React from "react";

import CTAButton from "../../../common/components/buttons/CTAButton";
import { useGoToRoute } from "../hooks/useGoToRoute";
import { Icons } from "../Icons";
import { ButtonType } from "../Layout";
import { ActionButton } from "./ActionButton";

interface ButtonProps {
  button: ButtonType;
}

export function Button({ button }: ButtonProps): JSX.Element {
  const { label, icon: iconEnum, route, action, component } = button;

  const goToPath = useGoToRoute();

  const handleClicked = () => {
    if (!route) {
      throw new Error("Route not defined in TopBarButton click handler");
    }

    goToPath(route);
  };

  if (component) {
    return component;
  }

  if (route) {
    const icon = iconEnum ? Icons[iconEnum] : undefined;
    return <CTAButton text={label} icon={icon} onClick={handleClicked} />;
  }

  if (action) {
    return <ActionButton action={action} />;
  }

  throw new Error("Invalid top bar button configuration");
}
