import { useSelector } from "react-redux";

import { ClassificationsMapType } from "../../../../../../common/store/annotatePage/ClassificationsMapType";
import { lesionClassificationModeDefinitionsSelector } from "../../../../../../common/store/annotatePage/taskSelector";
import {
  ClassificationModeType,
  ClassificationValuesType,
} from "../../../../../types/TaskDescriptionType";
import { LesionListItemFragmentType } from "../fragments/LesionListItemFragment";
import { useInsertLesionClassifications } from "./useInsertLesionClassification";

export type DeleteLesionClassificationsNotInModeReturnType = (
  mode: ClassificationModeType,
  lesion: LesionListItemFragmentType
) => Promise<void>;

export function useDeleteLesionClassificationsNotInMode(): DeleteLesionClassificationsNotInModeReturnType {
  const classificationModeDefinitions = useSelector(lesionClassificationModeDefinitionsSelector);

  const [insertLesionClassifications] = useInsertLesionClassifications();

  return async (mode: ClassificationModeType, lesion: LesionListItemFragmentType) => {
    const classificationDefinitionForMode = classificationModeDefinitions[mode];

    if (!classificationDefinitionForMode) {
      return;
    }

    const { classifications: lesionClassifications, id: lesionId } = lesion;

    const { map: classificationsMapTemplate } = classificationDefinitionForMode;

    const modeClassifications = getClassificationsInClassificationsMap(classificationsMapTemplate);

    const lesionClassificationsToDelete = lesionClassifications
      .map(({ classification }) => classification)
      .filter(
        (classification) => classification !== mode && !modeClassifications.includes(classification)
      );

    if (lesionClassificationsToDelete.length === 0) {
      return;
    }

    await insertLesionClassifications(lesionId, [], lesionClassificationsToDelete);
  };
}

function getClassificationsInClassificationsMap(
  classificationsMap: ClassificationsMapType
): ClassificationValuesType[] {
  const classifications: ClassificationValuesType[] = [];
  const classificationGroups = Object.values(classificationsMap);

  for (const { values: classificationsSelectedMap } of classificationGroups) {
    const classificationValues = Object.keys(
      classificationsSelectedMap
    ) as ClassificationValuesType[];
    classifications.push(...classificationValues);
  }

  return classifications;
}
