import Tooltip from "@mui/material/Tooltip";
import React, { ReactElement, useMemo } from "react";

import { ReactComponent as ErrorIcon } from "../../../../../../assets/svgs/Error.svg";
import { ReactComponent as Visibility } from "../../../../../../assets/svgs/Visibility.svg";
import { ReactComponent as VisibilityOff } from "../../../../../../assets/svgs/VisibilityOff.svg";
import { SvgIcon } from "../../../../../../common/components/icons/SvgIcon";
import { Loading } from "../../../../../../common/components/Loading";
import { main } from "../../../../../../common/theme/main";
import { IconButton } from "../IconButton";
import { defaultDownloadState, DownloadState } from "./types/downloadState";

interface DownloadButtonProps {
  state: DownloadState | undefined;
  onClick: (e: React.MouseEvent<Element, MouseEvent>) => void;
  plural: boolean;
}

export function DownloadButton({
  state = defaultDownloadState,
  onClick,
  plural,
}: DownloadButtonProps): ReactElement {
  return useMemo(() => {
    const { downloaded, downloading, visible, error } = state;

    const tooltip = getToolTip(state, plural);
    if (error) {
      return (
        <Tooltip title={tooltip}>
          <IconButton onClick={onClick}>
            <SvgIcon icon={ErrorIcon} size={17} color={main.colors.states.error} />
          </IconButton>
        </Tooltip>
      );
    }

    if (downloading) {
      return (
        <IconButton readOnly={true}>
          <Loading />
        </IconButton>
      );
    }

    const showVisibility = downloaded && visible;
    return (
      <Tooltip title={tooltip}>
        <IconButton onClick={onClick}>
          <SvgIcon icon={showVisibility ? Visibility : VisibilityOff} size={17} />
        </IconButton>
      </Tooltip>
    );
  }, [state, onClick, plural]);
}

function getToolTip(state: DownloadState, plural: boolean) {
  const { downloaded, downloading, visible, error } = state;
  if (error) {
    return `There was an error retrieving ${plural ? "these annotations" : "this annotation"}`;
  }
  if (downloading) {
    return `Loading label${plural ? "s" : ""}...`;
  }
  const showHide = downloaded && visible ? "Hide" : "Show";
  return `${showHide} ${plural ? "these" : "this"} ${plural ? "annotations" : "annotation"}`;
}
