import { useProjectTasks } from "../../hooks/useProjectTasks";
import { getTaskOption, TaskOptionType } from "../TaskOptionType";

export function useProjectTaskOptions(): {
  options: TaskOptionType[];
  loading: boolean;
} {
  const { projectTasks = [], loading } = useProjectTasks();

  const options = projectTasks.map(getTaskOption);

  return { options, loading };
}
