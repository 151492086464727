import { ApolloClient } from "@apollo/client";
import { gql } from "@apollo/client/core";

import { CONTOUR_FRAGMENT } from "../../../../../fragments/ContourFragment";
import { ContourFragmentType } from "../../../../../fragments/ContourFragmentType";
import { ELLIPSE_FRAGMENT } from "../../../../../fragments/EllipseFragment";
import { EllipseFragmentType } from "../../../../../fragments/EllipseFragmentType";
import { ROI_RECIST_EVALUATION_FRAGMENT } from "../../../../../fragments/RoiRecistEvaluationFragment";
import { RoiRecistEvaluationFragmentType } from "../../../../../fragments/RoiRecistEvaluationFragmentType";
import { LesionSourceType } from "../../../../../types/LesionSourceType";
import {
  getLesionQueryCondition,
  getRoiQueryCondition,
  LesionQueryConditionType,
  RoiQueryConditionType,
} from "../fragments/LesionListItemFragment";
import { DEBUG_LESION_LIMIT } from "./debugUtils";

const LESION_FRAGMENT = gql`
  fragment ToolDataLesion on lesion {
    id: lesion_id
    taskId: task_id
    anatomicalStructure: anatomical_structure {
      color
    }
    origin
    rois(where: $roiCondition) {
      id: roi_id
      ellipse {
        ...Ellipse
      }
      contours {
        ...Contour
      }
      roiRecistEvaluation: roi_recist_evaluation {
        ...RoiRecistEvaluation
      }
    }
  }
  ${ELLIPSE_FRAGMENT}
  ${CONTOUR_FRAGMENT}
  ${ROI_RECIST_EVALUATION_FRAGMENT}
`;

export type ToolDataLesionFragmentType = {
  id: number;
  taskId: number | null;
  anatomicalStructure: {
    color: string;
  } | null;
  origin: LesionSourceType;
  rois: ToolDataRoiFragmentType[];
};

export type ToolDataRoiFragmentType = {
  id: number;
  contours: ContourFragmentType[];
  ellipse: EllipseFragmentType | null;
  roiRecistEvaluation: RoiRecistEvaluationFragmentType | null;
};

const QUERY = gql`
    query GetTaskToolData($lesionCondition: lesion_bool_exp!, $roiCondition: roi_bool_exp!) {
        lesions: lesion(limit: ${DEBUG_LESION_LIMIT}, where: $lesionCondition, order_by: { last_modified: desc }) {
            ...ToolDataLesion
        }
    }
    ${LESION_FRAGMENT}
`;

export type Data = {
  lesions: ToolDataLesionFragmentType[];
};

export type Variables = {
  lesionCondition: LesionQueryConditionType;
  roiCondition: RoiQueryConditionType;
};

type Input = {
  id: number;
  task: { id: number; projectId: number; restrictedCohorts: { id: number }[] };
};

export function getVariables(
  taskContext: Input,
  includeOtherLesions: boolean,
  includePredictedLesions: boolean
): Variables {
  const {
    task: { projectId, restrictedCohorts },
  } = taskContext;

  const lesionCondition = getLesionQueryCondition(
    taskContext,
    includeOtherLesions,
    includePredictedLesions
  );

  const roiCondition = getRoiQueryCondition(
    projectId,
    restrictedCohorts.map(({ id }) => id)
  );

  return { lesionCondition, roiCondition };
}

export async function getToolData(
  client: ApolloClient<unknown>,
  taskContext: Input,
  includeOtherLesions: boolean,
  includePredictedLesions: boolean
): Promise<ToolDataLesionFragmentType[]> {
  const variables = getVariables(taskContext, includeOtherLesions, includePredictedLesions);
  const {
    data: { lesions },
  } = await client.query<Data, Variables>({
    query: QUERY,
    variables,
    fetchPolicy: "no-cache",
  });
  return lesions;
}
