import React, { useState } from "react";
import styled from "styled-components";

import { UserType } from "../../../types/UserType";
import handleApolloError from "../../../utils/handleApolloError";
import { DialogContentInnerWrapper } from "../../Dialog/DialogContentInnerWrapper";
import { useDialog, useDialogReturnType } from "../../Dialog/useDialog";
import { FlexLoading } from "../../Loading";
import { manageCurrentUserContext, UserManagementContextProvider } from "../UserManagementContext";
import { UserProfile } from "../UserProfile";
import { useSendResetPasswordEmail } from "../useSendResetPasswordEmail";
import { useUpdateUserMetadata } from "../useUpdateUserMetadata";

const Wrapper = styled(DialogContentInnerWrapper)`
  width: 634px;
`;

export function useUserProfileDialog(user: UserType): useDialogReturnType {
  const [users, setUsers] = useState<UserType[]>([]);

  const [
    updateUserMetadata,
    { loading: updateUserMetadataLoading, error: updateUserMetadataError },
  ] = useUpdateUserMetadata();

  const [resetUserPassword, { error: resetUserPasswordError }] = useSendResetPasswordEmail();

  const handleUserEdited = async (user: UserType) => {
    await updateUserMetadata({ user });
  };

  const handleResetUserPassword = async (user: UserType) => {
    await resetUserPassword(user);
  };

  const label = "Profile";

  let content;
  if (updateUserMetadataError) {
    handleApolloError(updateUserMetadataError);
  } else if (resetUserPasswordError) {
    handleApolloError(resetUserPasswordError);
  } else if (updateUserMetadataLoading) {
    content = <FlexLoading />;
  } else {
    content = (
      <UserManagementContextProvider
        rules={manageCurrentUserContext}
        users={users}
        setUsers={setUsers}
        onEdited={handleUserEdited}
        onPasswordReset={handleResetUserPassword}
      >
        <Wrapper>
          <UserProfile user={user} />
        </Wrapper>
      </UserManagementContextProvider>
    );
  }

  return useDialog({
    label,
    content,
    options: {
      showCloseButton: true,
    },
  });
}
