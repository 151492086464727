import { gql } from "@apollo/client/core";

import { useParsedQuery } from "../../../apollo/utils/useParsedQuery";
import { useParsedQueryReturnType } from "../../../apollo/utils/useParsedQueryReturnType";
import { S3ConfigType } from "./S3ConfigType";

export const QUERY = gql`
  query GetProjectS3Config($projectId: Int!) {
    s3BucketConfigs: s3_bucket_config(where: { project_id: { _eq: $projectId } }) {
      id
      projectId: project_id
      bucketName: bucket_name
      bucketPrefix: bucket_prefix
      cohortId: cohort_label_id
      permissionVerified: permission_verified
    }
  }
`;

export type Variables = {
  projectId: number;
};

export type Data = {
  s3BucketConfigs: S3ConfigType[];
};

type ReturnType = S3ConfigType | undefined;

export function useProjectS3Config(
  projectId: number
): useParsedQueryReturnType<Variables, ReturnType, Variables> {
  return useParsedQuery<Variables, ReturnType, Data, Variables>({
    query: QUERY,
    input: { projectId },
    parseVariables: (input) => input,
    parseData,
  });
}

function parseData({ s3BucketConfigs }: Data): ReturnType {
  if (s3BucketConfigs.length === 0) {
    return undefined;
  } else if (s3BucketConfigs.length > 1) {
    throw new Error("More than one S3 Bucket Config returned for this project");
  }
  return s3BucketConfigs[0];
}
