import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import { getTimepointFromSeriesId } from "../../../../../Annotate/components/Annotate/page/AnnotationPanel/hooks/Timepoint/getTimepointFromSeriesId";
import { TimepointType } from "../../../../../Annotate/components/Annotate/page/AnnotationPanel/hooks/Timepoint/TimepointType";
import { IconButton } from "../../../../../Annotate/components/Annotate/page/AnnotationPanel/IconButton";
import { ViewerContextProvider } from "../../../../../Annotate/components/Annotate/page/ViewerPanel/Viewer/ViewerContextProvider";
import { ReactComponent as CloseIcon } from "../../../../../assets/svgs/Close.svg";
import { SvgIcon } from "../../../../../common/components/icons/SvgIcon";
import { BigFlexLoading } from "../../../../../common/components/Loading";
import { PatientContext } from "./PatientContext";
import { TimepointPanel } from "./TimepointPanel";
import { ViewerPanel } from "./ViewerPanel";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 20px;
  overflow: hidden;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const TimepointColumnWrapper = styled(ColumnWrapper)`
  flex: 1;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const ViewerTimepointWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 12px;
`;

const ViewerColumnWrapper = styled(ColumnWrapper)`
  flex: 7;
`;

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  font-size: 13px;
`;

interface SeriesViewerProps {
  onClose: () => void;
}

export function PatientViewer({ onClose }: SeriesViewerProps): JSX.Element {
  const [selectedTimepoint, setSelectedTimepoint] = useState<TimepointType | undefined>(undefined);

  const [selectedSeriesId, setSelectedSeriesId] = useState<number | undefined>(undefined);

  const { timepoints, loading } = useContext(PatientContext);

  const handleTimepointSelected = (
    seriesId: number | undefined,
    timepoint: TimepointType | undefined
  ) => {
    setSelectedSeriesId(seriesId);
    setSelectedTimepoint(timepoint);
  };

  useEffect(() => {
    if (loading) {
      return;
    }

    if (!timepoints) {
      throw new Error("Timepoints are undefined in PatientViewer");
    }

    if (timepoints.length === 0) {
      return;
    }

    const updatedTimepoint = timepoints[0];

    const { series: seriesList } = updatedTimepoint;

    const { id: seriesId } = seriesList[0];

    handleTimepointSelected(seriesId, updatedTimepoint);
  }, [timepoints, loading]);

  const handleViewerLoaded = (seriesId: number | undefined) => {
    const timepoint =
      seriesId !== undefined && timepoints
        ? getTimepointFromSeriesId(seriesId, timepoints)
        : undefined;

    handleTimepointSelected(seriesId, timepoint);
  };

  if (loading) {
    return (
      <Wrapper>
        <BigFlexLoading />
      </Wrapper>
    );
  }

  if (!timepoints || timepoints.length === 0) {
    return <ErrorWrapper>The selected subject does not contain any series.</ErrorWrapper>;
  }

  return (
    <ViewerContextProvider options={{ viewOnly: true }}>
      <Wrapper>
        <ButtonWrapper>
          <IconButton onClick={onClose}>
            <SvgIcon icon={CloseIcon} size={16} />
          </IconButton>
        </ButtonWrapper>
        <ViewerTimepointWrapper>
          <TimepointColumnWrapper>
            <TimepointPanel
              timepoints={timepoints}
              selected={selectedTimepoint}
              onSelected={handleTimepointSelected}
            />
          </TimepointColumnWrapper>
          <ViewerColumnWrapper>
            <ViewerPanel seriesId={selectedSeriesId} onLoaded={handleViewerLoaded} />
          </ViewerColumnWrapper>
        </ViewerTimepointWrapper>
      </Wrapper>
    </ViewerContextProvider>
  );
}
