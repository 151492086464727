import React, { useMemo, useState } from "react";

import { LesionToScrollToContext } from "./contexts/LesionToScrollToContext";

interface GoToLesionProps {
  children?: React.ReactNode;
}

export const LesionToScrollToProvider = ({ children }: GoToLesionProps): JSX.Element => {
  const [lesionToScrollTo, setLesionToScrollTo] = useState<number | null>(null);
  const context = useMemo(
    () => ({
      lesionToScrollTo,
      setLesionToScrollTo,
    }),
    [lesionToScrollTo, setLesionToScrollTo]
  );
  return (
    <LesionToScrollToContext.Provider value={context}>{children}</LesionToScrollToContext.Provider>
  );
};
