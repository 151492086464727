import { ApolloCache } from "@apollo/client/core";

import { ClassificationType } from "../types/ClassificationType";
import { getRoiClassificationCacheId } from "./getRoiClassificationCacheId";

export function deleteRoiClassificationsFromCache<T>(
  roiId: number,
  classifications: ClassificationType[],
  cache: ApolloCache<T>
): void {
  const classificationIds = classifications.map(({ id }) => id);
  for (const id of classificationIds) {
    const classificationCacheId = getRoiClassificationCacheId(id, cache);
    cache.evict({ id: classificationCacheId });
  }
}
