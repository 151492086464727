import { useParams } from "react-router";

interface TasklessRouteProps {
  projectId: string;
  patientId: string;
}

interface TasklessPageParams {
  projectId: number;
  patientId: number;
}

export function useTasklessPageParams(): TasklessPageParams {
  const { projectId: rawProjectId, patientId: rawPatientId } = useParams<TasklessRouteProps>();

  const projectId = parseInt(rawProjectId);
  const patientId = parseInt(rawPatientId);

  return {
    projectId,
    patientId,
  };
}
