import { gql } from "@apollo/client/core";

import { GlobalRoleType } from "../common/types/UserRoleType";
import { UserType } from "../common/types/UserType";

export const USER_FRAGMENT = gql`
  fragment UserFragment on users {
    auth0Id: auth0_user_id
    id: user_id
    firstName: first_name
    lastName: last_name
    title
    organization
    email
    suspended
    avatarImageSource: picture_url
    globalRoles: global_user_roles {
      role
    }
  }
`;

export type UserFragmentType = Omit<UserType, "globalRoles" | "projectRoles"> & {
  globalRoles: { role: GlobalRoleType }[];
};

export function parseUserFragment(userFragment: UserFragmentType): UserType {
  const { globalRoles: rawGlobalRoles, ...other } = userFragment;
  const globalRoles = rawGlobalRoles.map(({ role }) => role);
  return {
    ...other,
    globalRoles,
    projectRoles: undefined,
  };
}
