import { useSelector } from "react-redux";

import { useCurrentUser } from "../../../../../../common/contexts/UserContext/useCurrentUser";
import { lesionsClassificationsDefinitionsSelector } from "../../../../../../common/store/annotatePage/classificationsSelector";
import { selectedLesionIdSelector } from "../../../../../../common/store/annotatePage/selectionSelector";
import {
  lesionClassificationModeDefinitionsSelector,
  lesionStatsSelector,
  recistTaskSelector,
  roiStatsSelector,
} from "../../../../../../common/store/annotatePage/taskSelector";
import { useAnatomicalStructures } from "../../../../useAnatomicalStructures";
import { useGetIsLesionFromCurrentTask } from "../../ViewerPanel/useGetIsLesionFromCurrentTask";
import { useGetViewerForSeries } from "../../ViewerPanel/Viewer/hooks/useGetViewerForSeries";
import { LesionsListContextType } from "../LesionsListContext";
import { useMaskProvider } from "../MaskContextProvider";
import { useTimepointsNonViewOnlySeries } from "./Timepoint/useTimepointsNonViewOnlySeries";
import { useGetExistingRoiClassificationButtons } from "./timepointOptions/useGetExistingRoiClassificationButtons";
import { useGetMissingRoiClassificationButtons } from "./timepointOptions/useGetMissingRoiClassificationButtons";
import { useGetRoiViewOnlyOptions } from "./timepointOptions/useGetRoiViewOnlyOptions";
import { useGetTimepointOptionsFromRoiClassifications } from "./timepointOptions/useGetTimepointOptionsFromRoiClassifications";
import { useGetLesionMode } from "./useGetLesionMode";
import { useGetReadonlyClassificationsFromLesion } from "./useGetReadonlyClassificationsFromLesion";
import { useJumpToViewerRoi } from "./useJumpToViewerRoi";
import { useNextLesionId } from "./useNextLesionId";

interface LesionsListContextReturnType {
  context: LesionsListContextType;
  loading: boolean;
}

export function useGetLesionsListContext(): LesionsListContextReturnType {
  const getLesionMode = useGetLesionMode();
  const getReadonlyClassificationsFromLesion = useGetReadonlyClassificationsFromLesion();
  const getNextLesionId = useNextLesionId();
  const classificationModeDefinitions = useSelector(lesionClassificationModeDefinitionsSelector);
  const selectedLesionId = useSelector(selectedLesionIdSelector);
  const lesionClassificationsDefinitions = useSelector(lesionsClassificationsDefinitionsSelector);
  const { data: anatomicalStructuresData, loading: anatomicalStructuresLoading } =
    useAnatomicalStructures();
  const anatomicalStructures = anatomicalStructuresData ?? [];

  const getViewerForSeries = useGetViewerForSeries();
  const getRoiViewOnlyOptions = useGetRoiViewOnlyOptions();
  const getIsLesionFromCurrentTask = useGetIsLesionFromCurrentTask();
  const { onToggleMaskVisibility, getMaskDownloadState } = useMaskProvider();
  const jumpToViewerRoi = useJumpToViewerRoi();
  const getTimepointOptionsFromRoiClassifications = useGetTimepointOptionsFromRoiClassifications();
  const { headerStats, bodyStats } = useSelector(roiStatsSelector);
  const getExistingRoiClassificationButtons = useGetExistingRoiClassificationButtons();
  const getMissingRoiTimepointOptions = useGetMissingRoiClassificationButtons();
  const { id: userId } = useCurrentUser();
  const isRecist = useSelector(recistTaskSelector);
  const statTypes = useSelector(lesionStatsSelector);
  const timepoints = useTimepointsNonViewOnlySeries();
  return {
    context: {
      getLesionMode,
      getReadonlyClassificationsFromLesion,
      getNextLesionId,
      classificationModeDefinitions,
      selectedLesionId,
      lesionClassificationsDefinitions,
      anatomicalStructures,
      anatomicalStructuresLoading,
      getViewerForSeries,
      getRoiViewOnlyOptions,
      getIsLesionFromCurrentTask,
      onToggleMaskVisibility,
      getMaskDownloadState,
      jumpToViewerRoi,
      getTimepointOptionsFromRoiClassifications,
      headerStats,
      bodyStats,
      getExistingRoiClassificationButtons,
      getMissingRoiTimepointOptions,
      userId,
      isRecist,
      statTypes,
      timepoints,
    },
    loading: anatomicalStructuresLoading,
  };
}
