import { useApolloClient } from "@apollo/client";
import { useEffect, useRef } from "react";

import { useCurrentUser } from "../../../../../../../../common/contexts/UserContext/useCurrentUser";
import { ELLIPTICAL_ROI } from "../../../../../../../../cornerstone/ToolType";
import { EllipseToolDataType } from "../../../../../../../types/EllipseToolDataType";
import { getEllipseIdForRoi } from "../../../../AnnotationPanel/hooks/getEllipseIdForRoi";
import { useLesionListQueryInput } from "../../../../AnnotationPanel/hooks/useLesionListQueryInput";
import { useUpdateTaskInProgress } from "../../../../hooks/useUpdateTaskInProgress";
import { useSelectedLesion } from "../../../selection/useSelectedLesion";
import { useHandleNewRoiDrawn } from "../../contour/hooks/useHandleNewRoiDrawn";
import { replaceEllipseRoiIdInCache } from "../../legacy/EllipseToolChangeDetector";
import { setEllipseId } from "../../legacy/ToolUtils";
import { NEW_FINDING_ROI_ID } from "../../toolController/NewFindingRoiId";
import { ToolControllerType } from "../../toolController/ToolControllerType";
import { ToolCallbackDataType } from "../../types/ToolCallbackDataType";
import { useInsertEllipse } from "./useInsertEllipse";
import { useUpdateEllipse } from "./useUpdateEllipse";

export type EllipseUpdatedCallbackDataType = ToolCallbackDataType<EllipseToolDataType>;

export function useEllipseUpdated(
  toolController: ToolControllerType | null,
  seriesId: number | undefined
): void {
  const selectedLesion = useSelectedLesion();
  const [insertEllipse] = useInsertEllipse();
  const [updateEllipse] = useUpdateEllipse();
  const handleNewRoiDrawn = useHandleNewRoiDrawn(ELLIPTICAL_ROI);
  const lesionListQueryInput = useLesionListQueryInput();
  const { cache } = useApolloClient();
  const { id: userId } = useCurrentUser();
  const updateTaskInProgress = useUpdateTaskInProgress();

  const handleEllipseUpdated = async (data: EllipseUpdatedCallbackDataType) => {
    const { roiId, toolData, imageId } = data;

    if (toolData.length !== 1) {
      throw new Error(
        `Invalid ellipse tool data in useEllipseUpdated.handleEllipseUpdated: 'toolData' for roi '${roiId}' has length of ${toolData.length} but should be exactly 1`
      );
    }
    const { id: callbackEllipseId, roi } = toolData[0];

    if (roiId === NEW_FINDING_ROI_ID) {
      throw new Error("Ellipse roi id is new finding roi id but shouldn't be in update call");
    }

    /*
    it's possible that if the user updates the ellipse immediately after creating it the callback will not have the ellipse id set.
    in this case, we can grab it from the cache, however we will also want to make sure we update the cornerstone data since it will also be stale
    */

    let ellipseId = callbackEllipseId;
    if (!ellipseId) {
      ellipseId = getEllipseIdForRoi(roiId, lesionListQueryInput, cache);

      for (const data of toolData) {
        setEllipseId(data, ellipseId);
      }
    }

    if (!ellipseId) {
      throw new Error("No ellipse in update ellipse callback");
    }

    const newData = {
      roi: { ...roi, id: roiId },
      slice: {
        name: "Detection",
        imageId,
        toolData: toolData.map((data) => ({ ...data, id: ellipseId })),
      },
    };

    if (!ellipseId) {
      throw new Error("No ellipse in update ellipse callback");
    }

    await updateEllipse({
      variables: {
        ellipseId,
        data: { id: ellipseId, ...newData },
      },
    });
  };

  const handleEllipseCreated = async (data: EllipseUpdatedCallbackDataType) => {
    if (seriesId === undefined || !selectedLesion) {
      return;
    }

    const { roiId: callbackRoiId, toolData: callbackToolData, imageId } = data;

    let roiId = callbackRoiId;
    let toolData = callbackToolData;

    const { id, roi } = toolData[0];

    if (callbackRoiId === NEW_FINDING_ROI_ID) {
      const { roiId: newRoiId, toolData: updatedToolData } = await handleNewRoiDrawn(
        data,
        selectedLesion,
        seriesId
      );

      roiId = newRoiId;
      toolData = updatedToolData as EllipseToolDataType[];

      replaceEllipseRoiIdInCache(NEW_FINDING_ROI_ID, roiId);
    }

    if (toolData.length !== 1) {
      throw new Error(
        `Invalid ellipse tool data in useEllipseUpdated.handleEllipseUpdated: 'toolData' for roi '${roiId}' has length of ${toolData.length} but should be exactly 1`
      );
    }

    const newData = {
      roi: { ...roi, id: roiId },
      slice: {
        name: "Detection",
        imageId,
        toolData,
      },
    };

    if (id !== undefined) {
      throw new Error("Ellipse toolData id is NOT undefined in insert");
    }

    await insertEllipse({
      variables: {
        roiId,
        data: newData,
        createdBy: userId,
      },
    });
    await updateTaskInProgress();
  };

  const toolControllerRef = useRef<ToolControllerType>();

  useEffect(() => {
    toolControllerRef.current = toolController;
    toolController?.setOnEllipseCreatedCallback(handleEllipseCreated);
    toolController?.setOnEllipseChangedCallback(handleEllipseUpdated);

    return () => {
      const toolController = toolControllerRef.current;
      toolController?.setOnEllipseCreatedCallback(null);
      toolController?.setOnEllipseChangedCallback(null);
    };
  }, [
    toolController,
    selectedLesion,
    insertEllipse,
    updateEllipse,
    handleNewRoiDrawn,
    userId,
    seriesId,
  ]);
}
