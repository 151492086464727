import { useContext } from "react";

import { ViewerContext } from "../ViewerContext";
import { useActivateViewer } from "./useActivateViewer";

export function useChangeViewerSeries(): (viewerConfigId: number, seriesId: number) => void {
  const { setViewerConfigs } = useContext(ViewerContext);

  const activateViewer = useActivateViewer();

  return (viewerConfigId, seriesId) => {
    setViewerConfigs((viewerConfigs) =>
      viewerConfigs.map((viewerConfig) => {
        if (viewerConfig.id !== viewerConfigId) {
          return viewerConfig;
        }

        return { ...viewerConfig, seriesId };
      })
    );
    activateViewer(viewerConfigId);
  };
}
