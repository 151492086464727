import { Payload } from "recharts/types/component/DefaultLegendContent";

import { main } from "../../../common/theme/main";
import {
  IMAGING_ACQUISITION,
  IMAGING_QUALITY,
  SITE_CERTIFICATION,
  STUDY_INTERVAL,
} from "./imagingDeviationNames";

export const colorConfig = {
  [IMAGING_QUALITY]: main.colors.accent.accent1,
  [IMAGING_ACQUISITION]: main.colors.accent.accent3,
  [STUDY_INTERVAL]: main.colors.accent.accent4,
  [SITE_CERTIFICATION]: main.colors.accent.accent4Light,
};

export type RawImagingDeviationAndViolationsType = {
  sites: {
    id: number;
    name: string;
    labelStudies: {
      study: {
        series: {
          imaging_violations: { violation_type: string }[];
        }[];
      };
    }[];
  }[];
};

export interface ImagingDeviationDataProps {
  siteId: number;
  siteName: string;
  a: { violationType: string; count: number };
  b: { violationType: string; count: number };
  c: { violationType: string; count: number };
  d: { violationType: string; count: number };
  totalCount: number;
}

// TODO: Change violation_type names to be from db
const addToAcurateViolationType = (
  name: string,
  obj: ImagingDeviationDataProps,
  totalSiteImages: number
): void => {
  const value = 100 / totalSiteImages;
  obj.totalCount += value;
  switch (name) {
    case IMAGING_QUALITY:
      obj.a.count += value;
      break;
    case IMAGING_ACQUISITION:
      obj.b.count += value;
      break;
    case STUDY_INTERVAL:
      obj.c.count += value;
      break;
    case SITE_CERTIFICATION:
      obj.d.count += value;
      break;
  }
};

export function parseImagingDeviationData(
  data: RawImagingDeviationAndViolationsType
): ImagingDeviationDataProps[] {
  const parsedData: ImagingDeviationDataProps[] = [];

  const { sites } = data;
  for (const site of sites) {
    const { id, name, labelStudies } = site;

    const series = labelStudies.flatMap((labelStudy) => labelStudy.study.series);
    const imagingViolations = series.flatMap((s) => s.imaging_violations);

    const parsedViolations: ImagingDeviationDataProps = {
      siteId: id,
      siteName: name,
      a: { violationType: IMAGING_QUALITY, count: 0 },
      b: { violationType: IMAGING_ACQUISITION, count: 0 },
      c: { violationType: STUDY_INTERVAL, count: 0 },
      d: { violationType: SITE_CERTIFICATION, count: 0 },
      totalCount: 0,
    };

    for (const imagingViolation of imagingViolations) {
      const { violation_type } = imagingViolation;

      addToAcurateViolationType(violation_type, parsedViolations, series.length);
    }

    parsedData.push(parsedViolations);
  }

  return parsedData;
}
export function sortImagingDeviationData(
  data: ImagingDeviationDataProps[]
): ImagingDeviationDataProps[] {
  return data.sort(sortByTotalCount);
}

function sortByTotalCount(a: ImagingDeviationDataProps, b: ImagingDeviationDataProps): number {
  if (a.totalCount > b.totalCount) return -1;
  else return 1;
}

export function legendPayloadGenerator(): Payload[] {
  const deviations = [IMAGING_ACQUISITION, IMAGING_QUALITY, SITE_CERTIFICATION, STUDY_INTERVAL];
  const payload: Payload[] = [];
  deviations.forEach((deviation) => {
    payload.push({
      id: deviation,
      type: "circle",
      value: deviation,
      color: colorConfig[deviation as keyof typeof colorConfig],
    });
  });
  return payload;
}

export function getColorFromKey(key: string): string {
  switch (key) {
    case "a":
      return colorConfig[IMAGING_QUALITY];
    case "b":
      return colorConfig[IMAGING_ACQUISITION];
    case "c":
      return colorConfig[STUDY_INTERVAL];
    case "d":
      return colorConfig[SITE_CERTIFICATION];
    case "totalCount":
      return "transparent";
    default:
      return "black";
  }
}
