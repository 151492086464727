import axios from "axios";
import nifti from "nifti-reader-js";

export async function downloadMask(maskId: number, token: string): Promise<ArrayBuffer> {
  const endpoint = window._env_.REACT_APP_NOTA_PACS_URL + "/get-mask/";

  let { data } = await axios.get(endpoint, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: "arraybuffer",
    params: {
      mask_id: maskId,
    },
  });

  if (nifti.isCompressed(data)) {
    data = nifti.decompress(data);
  }

  if (!nifti.isNIFTI(data)) {
    throw new Error("NOT NIFTI");
  }

  const niftiHeader = nifti.readHeader(data);
  return nifti.readImage(niftiHeader, data);
}
