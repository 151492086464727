import React, { MouseEvent, useState } from "react";
import { CSVLink } from "react-csv";
import styled from "styled-components";

import { IconButton } from "../../../Annotate/components/Annotate/page/AnnotationPanel/IconButton";
import { ReactComponent as DownloadIcon } from "../../../assets/svgs/Download.svg";
import Menu, { StyledLink, StyledMenuItem } from "../../../common/components/buttons/Menu";
import { SvgIcon } from "../../../common/components/icons/SvgIcon";

const StyledCSVLink = styled(CSVLink)`
  text-decoration: none;
  color: ${(props) => props.theme.colors.actionPrimary.active};
`;

type DownloadChartEntry = { name: string; filename: string; data: string[][] };

interface DownloadChartDataButtonProps {
  entries: DownloadChartEntry[];
}

export function DownloadChartDataButton({ entries }: DownloadChartDataButtonProps): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (entries.length === 0) {
    return <></>;
  }

  if (entries.length === 1) {
    const { filename, data } = entries[0];
    return (
      <CSVLink filename={filename} data={data}>
        <IconButton>
          <SvgIcon icon={DownloadIcon} size={20} />
        </IconButton>
      </CSVLink>
    );
  }
  return (
    <Menu
      id={"download-menu"}
      anchorEl={anchorEl}
      handleClick={handleClick}
      handleClose={handleClose}
      icon={<SvgIcon icon={DownloadIcon} size={20} />}
    >
      {entries.map(({ filename, data, name }, index) => {
        return (
          <StyledCSVLink key={index} filename={filename} data={data}>
            <StyledMenuItem>
              <SvgIcon icon={DownloadIcon} size={20} />
              <StyledLink>{name}</StyledLink>
            </StyledMenuItem>
          </StyledCSVLink>
        );
      })}
    </Menu>
  );
}
