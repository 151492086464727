import { useDialogReturnType } from "../../../../../../common/components/Dialog/useDialog";
import { useFormDialog } from "../../../../../../common/components/Dialog/useFormDialog";
import { SeriesListItemFragmentType } from "../../AnnotationPanel/hooks/useStudiesList";
import { CreateSeriesFlagForm } from "../CreateSeriesFlagForm";

export function useCreateSeriesFlagForm(series: SeriesListItemFragmentType): useDialogReturnType {
  return useFormDialog({
    children: CreateSeriesFlagForm,
    label: "Flag Series",
    showCloseButton: true,
    props: { series },
  });
}
