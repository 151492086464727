import { useApolloClient } from "@apollo/client";
import React, { Dispatch, SetStateAction } from "react";

import { useOkForm } from "../../../../common/components/Dialog/useOkForm";
import {
  createUsers,
  useUpdateExistingUserGlobalRoles,
} from "../../../../common/components/UserManagement/createUsers";
import {
  manageGlobalUserContext,
  UserManagementContextProvider,
} from "../../../../common/components/UserManagement/UserManagementContext";
import { useSendResetPasswordEmail } from "../../../../common/components/UserManagement/useSendResetPasswordEmail";
import {
  parseVariables,
  useUpdateGlobalUserRoles,
} from "../../../../common/components/UserManagement/useUpdateGlobalUserRoles";
import { useUpdateUserMetadata } from "../../../../common/components/UserManagement/useUpdateUserMetadata";
import { isExistingUser, isNewUser, UserType } from "../../../../common/types/UserType";

interface GlobalUserManagementContextProviderProps {
  users: UserType[];
  setUsers: Dispatch<SetStateAction<UserType[]>>;
  children: JSX.Element;
}

export function GlobalUserManagementContextProvider({
  users,
  setUsers,
  children,
}: GlobalUserManagementContextProviderProps): JSX.Element {
  const hasuraClient = useApolloClient();

  const [updateUserMetadata] = useUpdateUserMetadata();
  const [resetUserPassword] = useSendResetPasswordEmail();
  const [updateGlobalUserRoles] = useUpdateGlobalUserRoles();
  const updateExistingUserGlobalRoles = useUpdateExistingUserGlobalRoles();

  const [showCreateUserFailedDialog, { dialog: createUserFailedDialog }] = useOkForm({
    title: "Error Creating User",
    message: "There was an error inviting this user. Consult with Altis Labs for more information.",
  });

  const handleUserPasswordReset = async (user: UserType) => {
    await resetUserPassword(user);
  };

  const handleUserEdited = async (user: UserType) => {
    const existingUser = users.find(({ id }) => id === user.id);
    if (!existingUser) {
      throw new Error("Unable to find existing user in handleUserEdited");
    }

    const variables = parseVariables(existingUser, user);

    await updateUserMetadata({ user }, {});
    await updateGlobalUserRoles({ variables });

    setUsers((users) =>
      users.map((existingUser) => (existingUser.id === user.id ? user : existingUser))
    );
  };

  const handleUsersCreated = async (createdUsers: UserType[]) => {
    const usersToBeCreated = createdUsers.filter(isNewUser);
    const existingUsers = createdUsers.filter(isExistingUser);

    try {
      await createUsers(usersToBeCreated, hasuraClient);
    } catch (e) {
      showCreateUserFailedDialog(true);
    }

    await updateExistingUserGlobalRoles(existingUsers, users);
  };

  return (
    <UserManagementContextProvider
      rules={manageGlobalUserContext}
      users={users}
      setUsers={setUsers}
      onCreated={handleUsersCreated}
      onPasswordReset={handleUserPasswordReset}
      onEdited={handleUserEdited}
    >
      <>
        {createUserFailedDialog}
        {children}
      </>
    </UserManagementContextProvider>
  );
}
