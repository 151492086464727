import { useEffect } from "react";

import { useSubjectQuery } from "../../../Analysis/common/hooks/useSubjectQuery";
import { useDeleteProjectUser } from "../../../common/components/UserManagement/useDeleteProjectUser";
import { getProjectBaseRoute } from "../../../common/routes/getBasePath";
import { CohortType } from "../../../common/types/CohortType";
import { getProjectUsers } from "../../../common/types/ProjectType";
import { UserType } from "../../../common/types/UserType";
import { POLLING_INTERVAL } from "../../../common/utils/configConstants";
import { useAllCohorts } from "../../../Dashboard/components/Settings/Project/useAllCohorts";
import { parseProjectDetails } from "../../../Dashboard/utils/parseProjectDetails";
import { RoutePathSingleOrArray } from "../../Layout/Layout";
import { getProjectRoute } from "../../Layout/utils/getProjectRoute";
import { GetProjectContextProviderType } from "../GetProjectContextProviderType";
import { useAddUsersToProject } from "./useAddUsersToProject";
import { useUnparsedProject } from "./useUnparsedProject";

export function useGetProjectContext(projectId: number): GetProjectContextProviderType {
  const loadingResult: GetProjectContextProviderType = {
    context: undefined,
    loading: true,
  };

  const {
    subjects: patients,
    loading: patientsLoading,
    error: patientsError,
    startPolling: startPollingPatients,
    stopPolling: stopPollingPatients,
  } = useSubjectQuery(projectId);

  if (patientsError) {
    throw new Error(`Error loading patients: ${patientsError.message}`);
  }

  const [deleteProjectUser] = useDeleteProjectUser();

  const {
    data: rawProject,
    loading: projectLoading,
    error: projectError,
    refetch,
  } = useUnparsedProject(projectId);

  if (projectError) {
    throw new Error(`Error loading project: ${projectError.message}`);
  }

  useEffect(() => {
    startPollingPatients(POLLING_INTERVAL);
    return () => stopPollingPatients();
  }, [startPollingPatients, stopPollingPatients]);

  const [addUsersToProject] = useAddUsersToProject();

  const {
    data: cohortsData,
    loading: cohortsLoading,
    error: cohortsError,
  } = useAllCohorts(projectId);

  if (cohortsError) {
    throw new Error(`Error loading cohorts: ${cohortsError.message}`);
  }

  if (patientsLoading || projectLoading || cohortsLoading) {
    return loadingResult;
  }

  if (!rawProject) {
    throw new Error("Something went wrong in ProjectContextProvider: No errors and not loading");
  }

  const project = parseProjectDetails({ project: [rawProject] })[0];
  const legacyTrialId = project.legacyTrialId;

  if (legacyTrialId === undefined) {
    throw new Error("Legacy trial id should not be undefined here");
  }

  const { name: projectName, disabled } = project;
  const cohorts: CohortType[] = cohortsData?.cohorts ?? [];

  const users = getProjectUsers(project);

  if (!patients) {
    throw new Error("Something went wrong in ProjectContextProvider: No errors and not loading");
  }

  const addUsers = async (users: UserType[]) => {
    const variables = {
      projectUsers: users.map(({ id: user_id }) => ({
        project_id: projectId,
        user_id,
      })),
    };
    await addUsersToProject({ variables });
  };

  const removeUser = async (user: UserType) => {
    await deleteProjectUser({ user, project });
  };

  const refetchUsers = async () => {
    await refetch();
  };

  const getRoute = (route: RoutePathSingleOrArray) => {
    const baseRoute = getProjectBaseRoute(legacyTrialId);
    return getProjectRoute(baseRoute, route);
  };

  return {
    context: {
      patients,
      project,
      cohorts,
      users,
      disabled,
      addUsers,
      removeUser,
      getRoute,
      projectId,
      projectName,
      refetchUsers,
      startPollingPatients,
      stopPollingPatients,
    },
    loading: false,
  };
}
