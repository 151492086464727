import React from "react";

import { ViewerConfigType } from "../../../../../common/store/annotatePage/ViewerConfigType";
import { useToolController } from "./hooks/useToolController";

export interface ToolControllerProps {
  viewerConfig: ViewerConfigType;
  element: HTMLDivElement | null;
}

// FIXME: There are 2 ToolControllers, 2 useToolControllers, and an AnnotateViewerToolController. See #185044274
// --B

export function ToolController({ viewerConfig, element }: ToolControllerProps): JSX.Element {
  useToolController(viewerConfig, element);

  return <></>;
}
