import React, { useEffect } from "react";
import styled from "styled-components";

import { useCurrentUser } from "../../../contexts/UserContext/useCurrentUser";
import { UserType } from "../../../types/UserType";
import handleApolloError from "../../../utils/handleApolloError";
import { OK_CANCEL } from "../../Dialog/DialogActionButtonsType";
import { useDialog, useDialogReturnType } from "../../Dialog/useDialog";
import { FlexLoading } from "../../Loading";
import { ProjectReassignment } from "../ProjectReassignment";
import { ManagerOptionType } from "../types/ManagerOptionType";
import { TaskReassignmentType } from "../types/TaskReassignmentType";
import {
  buildGetUsersTaskAssignmentsFilter,
  useGetUsersTaskAssignments,
} from "../useGetUsersTaskAssignments";
import { Data, useProjectsWithManagersForUser } from "../useProjectsWithManagersForUser";

export function useSuspendUserDialog(
  user: UserType,
  taskReassignments: TaskReassignmentType[],
  setTaskReassignments: (reassignments: TaskReassignmentType[]) => void,
  onConfirmSuspendUser: () => void
): useDialogReturnType {
  const { id: userId } = user;
  const { id: currentUserId } = useCurrentUser();

  const { data, loading, error } = useProjectsWithManagersForUser(userId, {
    fetchPolicy: "cache-and-network",
  });

  if (error) handleApolloError(error);

  if (!loading && !data) {
    throw new Error("Data is null with no Apollo error");
  }

  const filter = buildGetUsersTaskAssignmentsFilter([userId]);

  const {
    data: taskAssignmentsData,
    loading: taskAssignmentsLoading,
    error: taskAssignmentsError,
  } = useGetUsersTaskAssignments(filter);

  if (taskAssignmentsError) handleApolloError(taskAssignmentsError);

  if (!taskAssignmentsLoading && !taskAssignmentsData) {
    throw new Error("Data is null with no Apollo error");
  }

  useEffect(() => {
    setTaskReassignments(
      data && taskAssignmentsData
        ? data.projects.map((project): TaskReassignmentType => {
            return {
              projectId: project.id,
              managerId:
                project.projectUserRoles.length > 0
                  ? project.projectUserRoles[0].user.user_id
                  : currentUserId,
              taskAssignments: taskAssignmentsData.taskAssignments.filter(
                ({ task }) => task.projectId === project.id
              ),
            };
          })
        : []
    );
  }, [currentUserId, data, taskAssignmentsData, setTaskReassignments]);

  function handleManagerChange(projectId: number, manager: ManagerOptionType | null) {
    if (!manager) {
      return;
    }
    const newTaskReassignments: TaskReassignmentType[] = Object.assign([], taskReassignments);
    const index = newTaskReassignments.findIndex(
      (reassignment) => reassignment.projectId === projectId
    );
    if (index < 0) {
      return;
    }
    newTaskReassignments[index].managerId = manager.value;
    setTaskReassignments(newTaskReassignments);
  }

  const handleConfirmSuspendUser = () => {
    onConfirmSuspendUser();
  };

  const label = "Suspend Team Member";

  const content = loading ? (
    <FlexLoading />
  ) : (
    <TaskReassignment
      user={user}
      data={data}
      taskReassignments={taskReassignments}
      onChangeManager={handleManagerChange}
    />
  );

  return useDialog({
    label,
    content,
    options: {
      showCloseButton: false,
      actionButtonOptions: {
        type: OK_CANCEL,
        okText: "Suspend",
        onOkOverride: handleConfirmSuspendUser,
      },
    },
  });
}

export const ContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  gap: 32px;
  min-width: 300px;
  max-width: 500px;
  padding: 18px;
`;

export const ProjectsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  gap: 18px;
  padding-right: 18px;
  max-height: 300px;
  overflow: scroll;
`;

export const ProjectLabelWrapper = styled.div`
  display: flex;
  gap: 3px;
`;

interface TaskReassignmentProps {
  user: UserType;
  data: Data | undefined;
  taskReassignments: TaskReassignmentType[];
  onChangeManager: (projectId: number, manager: ManagerOptionType | null) => void;
}

function TaskReassignment({
  user,
  data,
  taskReassignments,
  onChangeManager,
}: TaskReassignmentProps): JSX.Element {
  const { id: userId } = user;
  const { email: currentUserEmail } = useCurrentUser();

  const userOverride: ManagerOptionType = {
    label: currentUserEmail,
    value: userId,
  };

  if (!data) {
    return <></>;
  }

  return (
    <ContentsWrapper>
      <ProjectLabelWrapper>
        Removing a user will require that their tasks be assigned to a project manager. For each
        project below, select a manager to re-assign the removed user's tasks to
      </ProjectLabelWrapper>
      <ProjectsWrapper>
        {data.projects.map(({ id, name, projectUserRoles }, index) => {
          const managerOptions =
            projectUserRoles.length > 0
              ? projectUserRoles
                  .filter(({ user: { user_id } }) => user_id !== userId)
                  .map(({ user: { email, user_id } }) => ({
                    label: email,
                    value: user_id,
                  }))
              : [userOverride];

          const selectedValue =
            managerOptions.find(
              (option) =>
                taskReassignments[index] && taskReassignments[index].managerId === option.value
            ) || managerOptions[0];

          return (
            <ProjectReassignment
              key={id}
              name={name}
              managerOptions={managerOptions}
              selectedValue={selectedValue}
              onChangeManager={onChangeManager}
              projectId={id}
            />
          );
        })}
      </ProjectsWrapper>
    </ContentsWrapper>
  );
}
