import { StylesConfig } from "react-select";
import { GroupTypeBase, OptionTypeBase } from "react-select/src/types";

import { main } from "../../theme/main";

const {
  colors: {
    actionPrimary: { active: black },
    neutral: { neutral5 },
    primary: { primary10: hover, primary20: active },
    states: { error },
  },
} = main;

export function getSelectStyle<
  OptionType extends OptionTypeBase,
  IsMulti extends boolean,
  GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>
>(
  overrides?: StylesConfig<OptionType, IsMulti, GroupType>
): StylesConfig<OptionType, IsMulti, GroupType> {
  return {
    ...overrides,
    menuPortal: (provided, props) => ({
      ...provided,
      zIndex: 9999,
      ...overrides?.menuPortal?.(provided, props),
    }),
    control: (styles, props) => ({
      ...styles,
      borderWidth: "0",
      boxShadow: "none",
      fontSize: "12px",
      ...overrides?.control?.(styles, props),
    }),
    container: (styles, props) => ({
      ...styles,
      boxSizing: "border-box",
      padding: "1px",
      border: `1px solid ${props.selectProps.error ? error : neutral5}`,
      borderRadius: "8px",
      "&:hover": {
        borderColor: props.selectProps.error ? error : black,
      },
      minWidth: 181,
      ...overrides?.container?.(styles, props),
    }),
    menu: (styles, props) => ({
      ...styles,
      fontSize: "12px",
      color: black,
      ...overrides?.menu?.(styles, props),
    }),
    input: (styles, props) => ({
      ...styles,
      fontSize: "12px",
      color: black,
      ...overrides?.input?.(styles, props),
    }),
    singleValue: (styles, props) => ({
      ...styles,
      fontSize: "12px",
      color: black,
      ...overrides?.singleValue?.(styles, props),
    }),
    option: (styles, props) => {
      const { isDisabled, isFocused, isSelected } = props;
      return {
        ...styles,
        backgroundColor: isDisabled ? undefined : isFocused ? hover : undefined,
        color: black,
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled ? (isSelected ? active : active) : undefined,
        },
        ...overrides?.option?.(styles, props),
      };
    },
  };
}
