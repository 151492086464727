import { CohortType } from "../../../common/types/CohortType";
import { DayjsDateRange } from "../../../common/utils/dateFormatUtils/DayjsDateRange";
import { Data } from "../hooks/useProjectLabels";
import { LabelType } from "../types/LabelType";
import { StudyFilterType } from "../types/StudyFilterOptions";
import { getCohortLesions } from "./getCohortLesions";
import { getIncludedPatientLesions } from "./getIncludedPatientLesions";
import { getIncludedRoiCount } from "./getIncludedRoiCount";
import { getLabelDisplayName } from "./getLabelDisplayName";

export type LabelsPerCohortData = {
  label: string;
  cohorts: {
    name: string;
    studies: number;
    color: string;
  }[];
};

export function buildAnnotationsPerCohortData(
  { patients }: Data,
  cohorts: CohortType[],
  range: DayjsDateRange,
  studyFilter: StudyFilterType
): LabelsPerCohortData[] {
  const includeIncompleteTasks = studyFilter === "ALL_STUDIES";

  const patientLesions = getIncludedPatientLesions(patients, range, includeIncompleteTasks);

  const allLabels: LabelsPerCohortData[] = [];

  const labelTypes: LabelType[] = ["DETECTED_LESION", "CONTOURED_MEASURABLE_LESION", "ORGAN"];

  for (const { id, name, metadata } of cohorts) {
    const color = metadata?.color ?? "#A06DFA";
    const cohortLesions = getCohortLesions(patientLesions, id);

    for (const labelType of labelTypes) {
      for (const lesion of cohortLesions) {
        const labelName = getLabelDisplayName(lesion, labelType);

        const count = getIncludedRoiCount(lesion, labelType);
        if (count === 0) {
          continue;
        }

        let existingLabel = allLabels.find(
          ({ label: existingLabelName }) => existingLabelName === labelName
        );

        if (!existingLabel) {
          existingLabel = {
            label: labelName,
            cohorts: [],
          };
          allLabels.push(existingLabel);
        }

        let existingCohort = existingLabel.cohorts.find(
          ({ name: existingCohortName }) => existingCohortName === name
        );

        if (!existingCohort) {
          existingCohort = {
            name,
            color,
            studies: 0,
          };
          existingLabel.cohorts.push(existingCohort);
        }

        existingCohort.studies += count;
      }
    }
  }

  return allLabels;
}
