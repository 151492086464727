import { MutationTuple, useMutation } from "@apollo/client";
import { gql } from "@apollo/client/core";

import { CohortType } from "../../../../common/types/CohortType";
import { UserType } from "../../../../common/types/UserType";
import { useProjectId } from "../../../../Project/hooks/useProjectId";
import { addTaskToCache, TASK_FRAGMENT, TaskFragmentType } from "./useRawPatientTasksQuery";

const MUTATION = gql`
  mutation InsertTasks($objects: [task_insert_input!]!) {
    insert_task(objects: $objects) {
      returning {
        ...Task
      }
    }
  }
  ${TASK_FRAGMENT}
`;

export type VariablesType = {
  objects: {
    project_id: number;
    task_description_id: number;
    name: string;
    task_users_maps: { data: { user_id: number }[] };
    restricted_task_cohorts: { data: { cohort_id: number }[] };
    qc_schema_id: number | null;
  }[];
};

export type DataType = {
  insert_task: {
    returning: TaskFragmentType[];
  };
};

export function useInsertTasks(): MutationTuple<DataType, VariablesType> {
  const projectId = useProjectId();

  return useMutation<DataType, VariablesType>(MUTATION, {
    update(cache, { data }) {
      if (!data) {
        throw new Error(
          "Something went wrong updating the cache after inserting a case assignment"
        );
      }
      const {
        insert_task: { returning: insertedTasks },
      } = data;

      for (const task of insertedTasks) {
        addTaskToCache(task, projectId, cache);
      }
    },
  });
}

export function getInsertTasksVariables(
  projectIds: number[],
  taskDescriptionId: number,
  name: string,
  restrictedUsers: UserType[],
  restrictedCohorts: CohortType[],
  qcSchemaId: number | null
): VariablesType {
  return {
    objects: projectIds.map((projectId) => ({
      project_id: projectId,
      task_description_id: taskDescriptionId,
      name,
      task_users_maps: {
        data: restrictedUsers.map(({ id }) => ({ user_id: id })),
      },
      restricted_task_cohorts: {
        data: restrictedCohorts.map(({ id }) => ({ cohort_id: id })),
      },
      qc_schema_id: qcSchemaId,
    })),
  };
}
