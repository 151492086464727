import { z, ZodError } from "zod";

import { baseQcRule, baseUploadableQcRule, isFragmentQcRule } from "./baseRule";
import { hasDependentQcRules } from "./dependentRules";
import { getInputType } from "./enum";

// Rules for input type of select (multiple options)
const optionsRule = z.strictObject({
  default_value: z.union([z.string().nullish(), z.array(z.string()).nullish()]),
  input_type: z.enum(["select-one", "select-many"] as const),
  input_options: z.array(z.string()).nonempty({
    message: "'select' type rule must have at least one value",
  }),
  allow_other: z.boolean().nullish(),
});

export const optionsQcRule = baseQcRule.merge(optionsRule);
export type OptionsQcRule = z.infer<typeof optionsQcRule>;
export const isOptionsQcRule = (data: unknown): data is OptionsQcRule =>
  optionsQcRule.safeParse(data).success;

export const optionsUploadableQcRule = baseUploadableQcRule.merge(optionsRule);
export type OptionsUploadableQcRule = z.infer<typeof optionsUploadableQcRule>;
export const isOptionsUploadableQcRule = (data: unknown): data is OptionsUploadableQcRule =>
  optionsUploadableQcRule.safeParse(data).success;

export type AnyQcRule<TUploadable extends boolean = false> = z.infer<
  z.ZodUnion<
    [
      TUploadable extends true ? typeof baseUploadableQcRule : typeof baseQcRule,
      TUploadable extends true ? typeof optionsUploadableQcRule : typeof optionsQcRule,
      TUploadable extends true ? typeof textUploadableQcRule : typeof textQcRule,
      TUploadable extends true ? typeof numberUploadableQcRule : typeof numberQcRule,
    ]
  >
>;

// Rules for input type of text, extend as needed
const textRule = z.strictObject({
  input_type: z.literal("text"),
  default_value: z.string().nullish(),
});

export const textQcRule = baseQcRule.merge(textRule);
export type TextQcRule = z.infer<typeof textQcRule>;
export const isTextQcRule = (data: unknown): data is TextQcRule =>
  textQcRule.safeParse(data).success;

export const textUploadableQcRule = baseUploadableQcRule.merge(textRule);
export type TextUploadableQcRule = z.infer<typeof textUploadableQcRule>;
export const isTextUploadableQcRule = (data: unknown): data is TextUploadableQcRule =>
  textUploadableQcRule.safeParse(data).success;

// Rules for input type of numbers, extend as needed
const numberRule = z.strictObject({
  input_type: z.literal("number"),
  default_value: z.number().nullish(),
});

export const numberQcRule = baseQcRule.merge(numberRule);
export type NumberQcRule = z.infer<typeof numberQcRule>;
export const isNumberQcRule = (data: unknown): data is NumberQcRule =>
  numberQcRule.safeParse(data).success;

export const numberUploadableQcRule = baseUploadableQcRule.merge(numberRule);
export type NumberUploadableQcRule = z.infer<typeof numberUploadableQcRule>;
export const isNumberUploadableQcRule = (data: unknown): data is NumberUploadableQcRule =>
  numberUploadableQcRule.safeParse(data).success;

const refineDependentRules = (isUploadable: boolean, data: unknown, ctx: z.RefinementCtx) => {
  if (!hasDependentQcRules(data) || !isFragmentQcRule(data)) {
    return;
  }
  data.dependent_rules.forEach((d) => {
    if (!isFragmentQcRule(d)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Dependent rule must be a valid QC rule",
        path: [...ctx.path, "unknown", "dependent_rules"],
      });
      return;
    }
    try {
      validateQcRule(d, { isUploadable });
    } catch (err) {
      if (err instanceof z.ZodError) {
        err.errors.forEach((error) => {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: error.message,
            path: [...ctx.path, d.name, "dependent_rules"],
          });
        });
      }
    }
  });
};

interface ValidateQcRuleOptions {
  isUploadable?: boolean;
}
export const validateQcRule = (data: unknown, { isUploadable = false }: ValidateQcRuleOptions = {}) => {
  let rule: z.ZodTypeAny;
  switch (getInputType(data)) {
    case "select-one":
    case "select-many":
      rule = isUploadable ? optionsUploadableQcRule : optionsQcRule;
      break;
    case "text":
      rule = isUploadable ? textUploadableQcRule : textQcRule;
      break;
    case "number":
      rule = isUploadable ? numberUploadableQcRule : numberQcRule;
      break;
    default:
      throw new ZodError([
        {
          code: z.ZodIssueCode.custom,
          message: "Invalid input type",
          path: ["input_type"],
        },
      ]);
  }
  return rule.superRefine((...args) => refineDependentRules(isUploadable, ...args)).parse(data);
};
