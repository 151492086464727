export const RECIST_1_1 = "Recist 1.1";
export const ORGAN_SEGMENTATION = "Organ Segmentation";
export const LESION_CLASSIFICATION = "Lesion Classification";
export const OBJECT_LABELLING = "Object Labelling";
export const SERIES_CLASSIFICATION = "Series Classification";

export const QUALITY_CONTROL_TASK = "QC";

export const TYPE_DEFAULT = "DEFAULT";
export const TYPE_RECIST_1_1 = "RECIST_1_1";

export const ANNOTATE_ALLOW_ORGANS = "ALLOW_ORGANS";
export const ANNOTATE_ALLOW_LESIONS = "ALLOW_LESIONS";
export const ANNOTATE_INCLUDE_OTHER_LESIONS = "INCLUDE_OTHER_LESIONS";
export const ANNOTATE_CALCULATE_CONTOUR_MEASUREMENTS = "CALCULATE_CONTOUR_MEASUREMENTS";
export const ANNOTATE_CAN_DRAW_MISSING_ROIS = "CAN_DRAW_MISSING_ROIS";
export const ANNOTATE_LIMIT_TO_BASELINE = "LIMIT_TO_BASELINE";
export const ANNOTATE_SHOW_PREDICTED_ROIS = "ANNOTATE_SHOW_PREDICTED_ROIS";

export const TUMOUR_TOTAL_BURDEN = "TOTAL_BURDEN";
export const TUMOUR_TIMEPOINT_BURDEN = "TIMEPOINT_BURDEN";
export const TUMOUR_VOLUME = "VOLUME";
export const TUMOUR_LAD = "LAD";
export const TUMOUR_SAD = "SAD";
export const TUMOUR_HU = "HU";

export const OBJECT_CONTOUR = "CONTOUR";
export const OBJECT_DETECTION = "DETECTION";

export const CHECK_MINIMUM_CONTOUR = "CHECK_MINIMUM_CONTOUR";
export const CHECK_MISSING_CONTOURS = "CHECK_MISSING_CONTOURS";

export const CHECK_MISSING_ROIS = "CHECK_MISSING_ROIS";
export const CHECK_MISSING_CLASSIFICATIONS = "CHECK_MISSING_CLASSIFICATIONS";
export const CHECK_MISSING_ORGANS = "CHECK_MISSING_ORGANS";
export const CHECK_MISSING_REQUIRED_QC_FIELDS = "CHECK_MISSING_REQUIRED_QC_FIELDS";

export const ANNOTATE_SERIES_SHOW_FOLLOW_UP_LABELS = "SHOW_FOLLOW_UP_LABELS";
export const ANNOTATE_SERIES_SHOW_TUMOUR_RESPONSE = "SHOW_TUMOUR_RESPONSE";

export const TYPE = "TYPE";

export const PRIMARY = "PRIMARY";
export const METASTASIS = "METASTASIS";
export const NODE = "NODE";
export const OTHER = "OTHER";

export const MALIGNANT = "MALIGNANT";
export const BENIGN = "BENIGN";
export const INDETERMINATE = "INDETERMINATE";

export const TARGET = "TARGET";
export const NON_TARGET = "NON_TARGET";

export const NOT_PRESENT = "NOT_PRESENT";
export const TOO_SMALL_TO_MEASURE = "TOO_SMALL_TO_MEASURE";

export const MEASURABLE = "MEASURABLE";

export const NON_MEASURABLE = "NON_MEASURABLE";

export const ANATOMY = "ANATOMY";

export const CONSTRAINT_ALL = "ALL";
export const CONSTRAINT_ONLY_ONE = "ONLY_ONE";
export const CONSTRAINT_ANY = "ANY";

export const DEFAULT = "DEFAULT";
export const NEW_FINDING = "NEW_FINDING";

export const TUMOUR_CLASSIFICATION = "TUMOUR_CLASSIFICATION";

export const TEMPLATE_TASK_DESCRIPTION_NAMES = [
  RECIST_1_1,
  LESION_CLASSIFICATION,
  ORGAN_SEGMENTATION,
  OBJECT_LABELLING,
  SERIES_CLASSIFICATION,
  QUALITY_CONTROL_TASK,
];
