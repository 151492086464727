import { TextareaHTMLAttributes } from "react";
import styled from "styled-components";

const StyledTextArea = styled.textarea<{ error?: unknown }>`
  box-sizing: border-box;
  width: 100%;
  padding: 12px;
  font-size: 12px;
  margin: 8px 0 0 0;
  border-radius: 8px;
  border: 1px solid
    ${({ error, theme }) => (error ? theme.colors.states.error : theme.colors.neutral.neutral5)};
  outline: none;
  &:focus-within {
    border: 1px solid
      ${({ error, theme }) => (error ? theme.colors.states.error : theme.colors.neutral.black)};
  }
  resize: vertical;
  font-family: inherit;
`;

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  text: string | null;
  setText: (text: string | null) => void;
  error?: unknown;
}

export function TextArea({ text, setText, error, ...other }: TextAreaProps): JSX.Element {
  return (
    <StyledTextArea
      {...other}
      error={error}
      value={text ?? undefined}
      onChange={(e) => setText(e.target.value)}
    />
  );
}
