import { main } from "../../../theme/main";
import {
  ADMIN_ROLE_GROUP,
  EDIT_ROLE_GROUP,
  MANAGE_ROLE_GROUP,
  roleGroup,
  RoleGroupType,
  VIEW_ROLE_GROUP,
} from "../../../types/RoleGroupType";
import { GlobalRoleType, UserRoleType } from "../../../types/UserRoleType";

type ColorType = { color: string; background: string };

const {
  colors: {
    accent: { accent1, altAccent1, accent2, accent3, accent5 },
    neutral: { white },
    states: { error, success },
  },
} = main;

const defaultColor: ColorType = {
  color: accent5,
  background: accent1,
};

const roleGroupColors: Record<RoleGroupType, ColorType> = {
  [VIEW_ROLE_GROUP]: {
    color: accent3,
    background: altAccent1,
  },
  [EDIT_ROLE_GROUP]: {
    color: white,
    background: accent1,
  },
  [MANAGE_ROLE_GROUP]: {
    color: white,
    background: accent2,
  },
  [ADMIN_ROLE_GROUP]: {
    color: white,
    background: error,
  },
};

export function getRoleColor(role: UserRoleType | GlobalRoleType): ColorType {
  const group = Object.keys(roleGroup).find((group) =>
    roleGroup[group as RoleGroupType].includes(role)
  );

  if (!group) {
    return defaultColor;
  }

  return roleGroupColors[group as RoleGroupType];
}
