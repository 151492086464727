import { getSeriesTableRow, SeriesTableRowType } from "../SeriesTable/SeriesTableRowType";
import { TableDataType } from "../Table/TableDataType";
import { PatientFragmentType, StudyFragmentType } from "./usePatientStudies";

export type StudyTableRowType = TableDataType & {
  patientId: PatientFragmentType["id"];
  isTempDemoPatient: boolean;
  seriesRows: SeriesTableRowType[];
} & Pick<PatientFragmentType, "patientDicomId" | "taskAssignments"> &
  StudyFragmentType;

export function getStudyTableRow(
  { id: patientId, patientDicomId, taskAssignments, tempDemoPatients }: PatientFragmentType,
  study: StudyFragmentType
): StudyTableRowType {
  const { id: studyId, series, studyDate } = study;
  const isTempDemoPatient = tempDemoPatients.length > 0;
  const seriesRows = series.map((seriesItem) =>
    getSeriesTableRow(studyId, isTempDemoPatient, seriesItem)
  );

  return {
    patientId,
    patientDicomId,
    taskAssignments,
    isTempDemoPatient,
    seriesRows,
    ...study,
    studyDate: studyDate !== "1900-01-01" ? studyDate : null,
  };
}

export function getStudyTableRowId(id: number): string {
  return id.toString();
}

export function getStudyTableRowSeriesId(id: string): number {
  return parseInt(id);
}
