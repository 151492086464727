import { gql } from "@apollo/client/core";

import { useParsedQuery } from "../../apollo/utils/useParsedQuery";
import { useParsedQueryReturnType } from "../../apollo/utils/useParsedQueryReturnType";
import {
  ANATOMICAL_STRUCTURES_FRAGMENT,
  AnatomicalStructuresFragmentType,
} from "./AnatomicalStructuresFragment";

export const QUERY = gql`
  query GetAnatomicalStructures {
    anatomicalStructures: anatomical_structures {
      ...AnatomicalStructure
    }
  }
  ${ANATOMICAL_STRUCTURES_FRAGMENT}
`;

export type Variables = undefined;

export type Data = {
  anatomicalStructures: AnatomicalStructuresFragmentType[];
};

export function useAnatomicalStructures(): useParsedQueryReturnType<
  undefined,
  AnatomicalStructuresFragmentType[],
  Variables
> {
  return useParsedQuery<undefined, AnatomicalStructuresFragmentType[], Data, Variables>({
    query: QUERY,
    input: undefined,
    parseVariables,
    parseData,
  });
}

function parseVariables(): Variables {
  return undefined;
}

function parseData({ anatomicalStructures }: Data): AnatomicalStructuresFragmentType[] {
  return sortAnatomicalStructuresByName(anatomicalStructures);
}

function sortAnatomicalStructuresByName(
  input: AnatomicalStructuresFragmentType[]
): AnatomicalStructuresFragmentType[] {
  return [...input].sort((a, b) => a.name.localeCompare(b.name));
}
