import { Options, safeParse } from "./Options";

const LOCAL_STORAGE_KEY = "exportOptions";

export function loadOptions() {
  const options = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (!options) {
    return undefined;
  }

  const validOptions = safeParse(JSON.parse(options));
  const { success } = validOptions;
  if (!success) {
    console.warn("Failed to parse export options from local storage", validOptions.error);
    return undefined;
  }

  return validOptions.data;
}

export function saveOptions(options: Options) {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(options));
}
