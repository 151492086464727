export const DATE_UNKNOWN = "Date Unknown";

export function formatDate(date: string): string {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dateComponents: string[] = date.split("-");

  if (!dateComponents[0] || !dateComponents[1]) {
    return "Unknown Date Format";
  }

  const monthNumber: number = parseInt(dateComponents[1]);

  if (isNaN(monthNumber)) {
    return "Unknown Date Format";
  }

  const year: string = dateComponents[0];

  return `${months[monthNumber - 1]} ${year}`;
}
