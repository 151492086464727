import { useQuery } from "@apollo/client";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { FC, useState } from "react";

import { ReactComponent as CloseIcon } from "../../../assets/svgs/Close.svg";
import { Card } from "../../../common/components/cards/Card";
import { Drawer } from "../../../common/components/Drawer";
import { SvgIcon } from "../../../common/components/icons/SvgIcon";
import { LoadingCard } from "../../../common/components/Loading";
import { GET_PROJECT_DETAILS_BY_IDS } from "../../../common/queries/GetProjectDetailsByIds";
import { RawProjectDetailsType } from "../../../common/types/RawProjectDetailsType";
import handleApolloError from "../../../common/utils/handleApolloError";
import { IconButton } from "../../../Dashboard/components/Settings/Cohort/DeletableCohortLabel";
import { useProjectId } from "../../../Project/hooks/useProjectId";
import {
  LinkToRouteProps,
  useProjectPageLinkHandler,
} from "../../../Project/Layout/hooks/useProjectPageLinkHandler";
import {
  ALGORITHM_INFO_CONTENT,
  ANALYSIS_ICON,
  DrawerPageType,
} from "../../../Project/Layout/Layout";
import { ModelSummary, useAlgorithms } from "../hooks/useAlgorithms";
import AlgorithmSummary from "./AlgorithmSummary";

interface FilledAlgorithmSummaryProps {
  model: ModelSummary | undefined;
  studyName: string;
  studyNumber: string;
  onClickAlgorithms?: () => void;
}

function FilledAlgorithmSummary({
  model,
  studyName,
  studyNumber,
}: FilledAlgorithmSummaryProps): React.ReactElement {
  const { displayName, createdAt, lastRun } = model ?? {
    displayName: "No Algorithms Run",
    createdAt: "",
    lastRun: "",
  };

  return (
    <AlgorithmSummary
      studyName={studyName}
      studyNumber={studyNumber}
      algorithmName={displayName}
      createdOn={createdAt}
      lastRun={lastRun}
    />
  );
}

type AlgorithmSummaryCardProps = LinkToRouteProps;

export const AlgorithmSummaryCard: FC<AlgorithmSummaryCardProps> = ({
  linkToRoute,
}: AlgorithmSummaryCardProps) => {
  const handleGoToProjectPageLink = useProjectPageLinkHandler(linkToRoute);

  const projectId = useProjectId();
  const { models, loading: modelsLoading, error: modelsError } = useAlgorithms(projectId);
  const flags = useFlags();
  const showReorgPages: boolean = flags["notaShowReorganisationPages"];

  const [open, setOpen] = useState<boolean>(false);

  const handleOpenDrawer = () => setOpen(true);
  const handleCloseDrawer = () => setOpen(false);

  const algorithmInfoDrawer: DrawerPageType = {
    topBar: {
      icon: ANALYSIS_ICON,
      label: "Algorithm Info",
      buttons: [
        {
          label: "",
          component: (
            <IconButton onClick={handleCloseDrawer}>
              <SvgIcon icon={CloseIcon} size={16} />
            </IconButton>
          ),
        },
      ],
    },
    content: [{ component: ALGORITHM_INFO_CONTENT }],
  };

  const {
    data: details,
    loading: detailsLoading,
    error: detailsError,
  } = useQuery<RawProjectDetailsType>(GET_PROJECT_DETAILS_BY_IDS, {
    variables: { projectIds: [projectId] },
  });

  if (modelsLoading) return <LoadingCard />;
  if (modelsError) handleApolloError(modelsError);
  if (detailsLoading || !details) return <LoadingCard />;
  if (detailsError) handleApolloError(detailsError);

  return (
    <>
      <Drawer open={open} page={algorithmInfoDrawer} onClose={handleCloseDrawer} />
      <Card
        noHead={true}
        onClickCard={showReorgPages ? handleOpenDrawer : handleGoToProjectPageLink}
        content={
          <FilledAlgorithmSummary
            model={models[0]}
            studyName={details.project[0].name}
            studyNumber={details.project[0].details.study_no}
          />
        }
      />
    </>
  );
};
