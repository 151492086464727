import { ApolloCache } from "@apollo/client";
import { gql } from "@apollo/client/core";

import { useParsedQuery } from "../../../apollo/utils/useParsedQuery";
import { useParsedQueryReturnType } from "../../../apollo/utils/useParsedQueryReturnType";
import { parseUserFragment, USER_FRAGMENT, UserFragmentType } from "../../../Settings/UserFragment";
import { ProjectType } from "../../types/ProjectType";
import { ProjectUserRoleType } from "../../types/UserRoleType";
import { UserType } from "../../types/UserType";
import { useCurrentUserAuth0Id } from "./useCurrentUserAuth0Id";

const QUERY = gql`
  query GetUser($auth0_user_id: String!) {
    users(where: { auth0_user_id: { _eq: $auth0_user_id } }) {
      projectUserRoles: project_user_roles {
        projectId: project_id
        role
      }
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

type UserTypeWithProjectRoles = UserFragmentType & {
  projectUserRoles: ProjectUserRoleType[];
};

type Data = {
  users: UserTypeWithProjectRoles[];
};

type Variables = { auth0_user_id: string };

type Output = {
  user: UserType;
  allProjectUserRoles: ProjectUserRoleType[];
};

export function useCurrentUserQuery(): useParsedQueryReturnType<string, Output, Variables> {
  const auth0UserId = useCurrentUserAuth0Id();

  return useParsedQuery<string, Output, Data, Variables>({
    query: QUERY,
    input: auth0UserId,
    parseVariables,
    parseData,
  });
}

function parseVariables(auth0UserId: string): Variables {
  return { auth0_user_id: auth0UserId };
}

function parseData({ users }: Data): Output {
  if (users.length !== 1) {
    throw new Error("No or more than one user returned with the same auth0 id");
  }
  const { projectUserRoles: allProjectUserRoles, ...other } = users[0];
  const user = parseUserFragment(other);
  return { user, allProjectUserRoles };
}

export function updateCurrentUserProjectRoles(
  auth0UserId: string | undefined,
  { id: projectId, projectUserRoles }: ProjectType,
  cache: ApolloCache<unknown>
) {
  if (!auth0UserId) {
    throw new Error("auth0UserId is undefined in updateCurrentUserProjectRoles");
  }

  const result = cache.readQuery<Data, Variables>({
    query: QUERY,
    variables: { auth0_user_id: auth0UserId },
  });

  if (!result) {
    return;
  }

  const { users } = result;

  if (users.length !== 1) {
    throw new Error("No or more than one user returned with the same auth0 id");
  }

  const currentUser = users[0];
  const { projectUserRoles: existingProjectUserRoles } = currentUser;

  const otherProjectUserRoles = existingProjectUserRoles.filter(
    ({ projectId: id }) => projectId !== id
  );

  const updatedUser: UserTypeWithProjectRoles = {
    ...currentUser,
    projectUserRoles: [
      ...otherProjectUserRoles,
      ...projectUserRoles.map(({ role }) => ({ projectId, role })),
    ],
  };

  cache.writeQuery<Data, Variables>({
    query: QUERY,
    variables: { auth0_user_id: auth0UserId },
    data: { users: [updatedUser] },
  });
}
