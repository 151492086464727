import { RoiId } from "../../ViewerPanel/selection/RoiId";
import { useJumpToRoiKeyImage } from "./useJumpToRoiKeyImage";

export type JumpToKeyImageArgsType = {
  id: number;
  rois: RoiId[];
};

export function useJumpToLesionKeyImage() {
  const jumpToRoiKeyImage = useJumpToRoiKeyImage();

  return ({ rois }: JumpToKeyImageArgsType) => {
    for (const roi of rois) {
      jumpToRoiKeyImage(roi);
    }
  };
}
