import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { QueryHookOptions, QueryResult } from "@apollo/client/react/types/types";

export type Variables = {
  userId: number;
};

export type Data = {
  projects: {
    id: number;
    name: string;
    projectUserRoles: {
      user: {
        user_id: number;
        username: string;
        first_name: string;
        last_name: string;
        email: string;
      };
    }[];
  }[];
};

export const QUERY = gql`
  query GetProjectsAndManagersForUser($userId: Int!) {
    projects: project(
      where: {
        _and: [
          { project_users: { user_id: { _eq: $userId } } }
          {
            tasks: {
              task_assignments: {
                _and: [{ user_id: { _eq: $userId } }, { _not: { progress: { _eq: COMPLETED } } }]
              }
            }
          }
        ]
      }
    ) {
      id
      name
      projectUserRoles: project_user_roles(where: { role: { _eq: COORDINATOR } }) {
        user {
          user_id
          username
          first_name
          last_name
          email
        }
      }
    }
  }
`;

export function useProjectsWithManagersForUser(
  userId: number,
  options?: Omit<QueryHookOptions<Data, Variables>, "variables">
): QueryResult<Data, Variables> {
  return useQuery<Data, Variables>(QUERY, {
    ...options,
    variables: { userId },
  });
}
