import {
  ADMIN,
  GLOBAL_ROLE_SOFTWARE_MANAGER,
  GlobalRoleType,
  PROJECT_ROLE_ANNOTATOR,
  PROJECT_ROLE_COORDINATOR,
  PROJECT_ROLE_QC_ANNOTATOR,
  PROJECT_ROLE_VIEWER,
  UserRoleType,
} from "./UserRoleType";

export const VIEW_ROLE_GROUP = "VIEW_ROLE_GROUP";
export const EDIT_ROLE_GROUP = "EDIT_ROLE_GROUP";
export const MANAGE_ROLE_GROUP = "MANAGE_ROLE_GROUP";
export const ADMIN_ROLE_GROUP = "ADMIN_ROLE_GROUP";

export type RoleGroupType =
  | typeof VIEW_ROLE_GROUP
  | typeof EDIT_ROLE_GROUP
  | typeof MANAGE_ROLE_GROUP
  | typeof ADMIN_ROLE_GROUP;

export const roleGroup: Record<RoleGroupType, (UserRoleType | GlobalRoleType)[]> = {
  [VIEW_ROLE_GROUP]: [PROJECT_ROLE_VIEWER],
  [EDIT_ROLE_GROUP]: [PROJECT_ROLE_QC_ANNOTATOR, PROJECT_ROLE_ANNOTATOR],
  [MANAGE_ROLE_GROUP]: [PROJECT_ROLE_COORDINATOR, GLOBAL_ROLE_SOFTWARE_MANAGER],
  [ADMIN_ROLE_GROUP]: [ADMIN],
};

export function orderRolesByGroup<T extends UserRoleType | GlobalRoleType>(roles: T[]): T[] {
  return [...roles].sort((roleA, roleB) => {
    const roleGroups = Object.keys(roleGroup);

    const groupA = roleGroups.find((group) => roleGroup[group as RoleGroupType].includes(roleA));
    const groupB = roleGroups.find((group) => roleGroup[group as RoleGroupType].includes(roleB));

    if (groupA === groupB) {
      return 0;
    }

    if (!groupA) {
      return 1;
    }

    if (!groupB) {
      return -1;
    }

    const groupAIndex = roleGroups.indexOf(groupA);
    const groupBIndex = roleGroups.indexOf(groupB);

    return groupAIndex < groupBIndex ? -1 : 1;
  });
}
