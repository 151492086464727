import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { BigFlexLoading } from "../../common/components/Loading";
import { pushPreviousTask } from "../../common/store/annotatePage/taskSlice";
import { PatientContextProvider } from "../../Dashboard/components/Settings/Patients/Viewer/PatientContextProvider";
import { AnnotatePage } from "../components/Annotate/page/AnnotatePage";
import { AutoTaskAssignmentReOpener } from "../components/Annotate/routing/AutoTaskAssignmentReOpener";
import { useTaskDoesNotExistDialog } from "../components/Annotate/routing/Forms/useTaskDoesNotExistDialog";
import { useGetTaskContext } from "../components/Annotate/routing/hooks/useGetTaskContext";
import { ContourContextProvider } from "../ContourContextProvider";
import { TaskContext, TaskContextType } from "../TaskContext";
import { useTaskPageParams } from "./useTaskPageParams";

export function TaskRouteContainer(): JSX.Element {
  const { taskId, patientId, projectId } = useTaskPageParams();

  const { loading, context } = useGetTaskContext(projectId, patientId, taskId);

  const [setShowTaskDoesNotExistDialog, { dialog: taskDoesNotExistDialog }] =
    useTaskDoesNotExistDialog();

  const contextLoaded = !!context;

  useEffect(() => {
    if (loading) {
      return;
    }

    if (!contextLoaded) {
      setShowTaskDoesNotExistDialog(true);
      return;
    }
  }, [loading, contextLoaded, setShowTaskDoesNotExistDialog]);

  if (loading) return <BigFlexLoading>Loading task details...</BigFlexLoading>;

  return (
    <>
      {taskDoesNotExistDialog}
      {context && <Page context={context} patientId={patientId} />}
    </>
  );
}

interface PageProps {
  patientId: number;
  context: TaskContextType;
}

function Page({ patientId, context }: PageProps): JSX.Element {
  const dispatch = useDispatch();

  const {
    task: { id: taskId, projectId },
    selectedTaskAssignmentId,
  } = context;

  useEffect(() => {
    dispatch(pushPreviousTask({ projectId, patientId, taskId }));
  }, [projectId, patientId, taskId, dispatch]);

  return (
    <PatientContextProvider patientId={patientId}>
      <TaskContext.Provider value={context}>
        {selectedTaskAssignmentId && <AutoTaskAssignmentReOpener />}
        <ContourContextProvider>
          <AnnotatePage />
        </ContourContextProvider>
      </TaskContext.Provider>
    </PatientContextProvider>
  );
}
