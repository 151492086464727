import { Popover } from "@mui/material";
import React from "react";
import { Column } from "react-table";
import styled from "styled-components";

import {
  PatientMutableTaskType,
  TaskAssignmentType,
} from "../../../Annotate/components/Manage/hooks/TaskType";
import {
  TaskProgressLabel,
  TaskProgressWithUser,
} from "./TaskProgressLabel";
import {
  TASK_COMPLETED,
  TASK_PENDING,
  TaskProgressType,
} from "../../../Annotate/components/TaskWorklist/TaskProgressType";
import { TaskWorklistTable } from "../../../Annotate/components/TaskWorklist/TaskWorklistTable";
import {
  STATUS_COLUMN_ID,
  TASK_COLUMN_ID,
  TaskWorklistTableColumnIdType,
} from "../../../Annotate/components/TaskWorklist/TaskWorklistTableColumns";
import { main } from "../../theme/main";
import { getCalculatedTaskProgress } from "./utils/getPatientProgressTaskAssignments";
import { useCurrentUser } from "../../contexts/UserContext/useCurrentUser";

const PopOut = styled.div`
  display: flex;
  gap: 6px;
  flex-direction: column;
  background-color: ${main.colors.background.secondary};
  padding: 12px;
  height: 275px;
  width: 400px;
`;

export interface TaskStatusProps {
  taskAssignments: PatientMutableTaskType[];
  showBadge: boolean;
  showAllAssignees?: boolean;
  enablePopOver?: boolean;
  globalContext?: boolean;
}

export function TaskStatus({
  taskAssignments,
  showBadge,
  showAllAssignees = false,
  enablePopOver = true,
  globalContext = true,
}: TaskStatusProps): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "filter-popover" : undefined;

  const { id: userId } = useCurrentUser();

  const columnsFilter = ({ id }: Column<PatientMutableTaskType>) =>
    [STATUS_COLUMN_ID, TASK_COLUMN_ID].includes(id as TaskWorklistTableColumnIdType);

  const tasksWithAssignees = taskAssignments.filter(
    ({ task: taskAssignmentList }) =>
      taskAssignmentList !== undefined && taskAssignmentList.taskAssignments.length >= 1
  );

  const allTaskAssignments = taskAssignments.flatMap(
    ({ task: taskAssignmentList }) => taskAssignmentList?.taskAssignments.map((task) => task) ?? []
  );

  const allTasksAssigned = tasksWithAssignees.length === taskAssignments.length;

  const calculatedTaskProgress = getCalculatedTaskProgress(allTaskAssignments, allTasksAssigned);

  let sortedTaskAssignments: TaskAssignmentType[];
  let overallProgress: TaskProgressType;

  if (globalContext) {
    sortedTaskAssignments = allTaskAssignments.sort((a, b) => {
      if (a.progress === TASK_COMPLETED || b.progress === TASK_PENDING) return -1;
      if (a.progress === TASK_PENDING || b.progress === TASK_COMPLETED) return 1;
      return 0;
    });

    overallProgress = calculatedTaskProgress.displayProgress;
  } else {
    // Worklist table (not popup), should prioritize showing tasks assigned to the current user
    sortedTaskAssignments = [...allTaskAssignments].sort((a, b) =>
      a.user.id === userId ? -1 : b.user.id === userId ? 1 : 0
    );

    overallProgress = sortedTaskAssignments?.[0]?.progress ?? TASK_PENDING;
  }

  const mostCompletedUser = sortedTaskAssignments?.[0]?.user;

  const extraItemCount = allTaskAssignments.length - 1;

  return (
    <>
      {showAllAssignees ? (
        <TaskProgressWithUser
          progress={{
            taskAssignments: allTaskAssignments,
            displayProgress: overallProgress,
          }}
          showBadge={showBadge}
        />
      ) : (
        <TaskProgressLabel
          onClick={handleClick}
          showBadge={showBadge}
          user={mostCompletedUser}
          progress={overallProgress}
          extraItemCount={extraItemCount}
        />
      )}

      {enablePopOver && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 40,
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <PopOut>
            <TaskWorklistTable
              rows={taskAssignments}
              columnsFilter={columnsFilter}
              onTaskSelected={handleClose}
              globalProgress={true}
            />
          </PopOut>
        </Popover>
      )}
    </>
  );
}
