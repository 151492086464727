import { useContext } from "react";

import { ViewerContext } from "../ViewerContext";

const MIN_VIEWERS = 1;

export function useRemoveLastViewer(): () => void {
  const { setViewerConfigs, viewerConfigs } = useContext(ViewerContext);

  return () => {
    const numberOfViewers = viewerConfigs.length;
    if (numberOfViewers <= MIN_VIEWERS) {
      return;
    }

    setViewerConfigs((viewerConfigs) => viewerConfigs.slice(0, -1));
  };
}
