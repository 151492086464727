import { useCallback } from "react";

import { useActivateViewer } from "../../ViewerPanel/Viewer/hooks/useActivateViewer";
import { useAddViewer } from "../../ViewerPanel/Viewer/hooks/useAddViewer";
import { useGetViewerForSeries } from "../../ViewerPanel/Viewer/hooks/useGetViewerForSeries";
import { RoiListItemFragmentType } from "../fragments/RoiListItemFragment";
import { useJumpToViewerRoiKeyImage } from "./useJumpToViewerRoiKeyImage";

export function useJumpToViewerRoi() {
  const addViewer = useAddViewer();
  const activateViewer = useActivateViewer();
  const getViewerForSeries = useGetViewerForSeries();
  const jumpToViewerRoiKeyImage = useJumpToViewerRoiKeyImage();

  return useCallback(
    (roi: RoiListItemFragmentType) => {
      const {
        series: { id: seriesId },
      } = roi;
      // TODO: disabled collapsing for now since doesn't play well with virtualization
      // dispatch(setSelectedRoiCollapsedState({ roiId }));

      let viewer = getViewerForSeries(seriesId);
      if (viewer) {
        const { id } = viewer;
        activateViewer(id);
      } else {
        viewer = addViewer(seriesId);
      }

      if (!viewer) {
        return;
      }

      const { id: viewerConfigId } = viewer;
      jumpToViewerRoiKeyImage(roi, viewerConfigId);
    },
    [activateViewer, addViewer, getViewerForSeries, jumpToViewerRoiKeyImage]
  );
}
