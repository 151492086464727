import { useMemo } from "react";

import { PatientTaskTableRowType } from "../PatientTable/types/PatientTaskTableRowType";
import { PatientMutableTaskType, PatientTasksType } from "./TaskType";
import { useProjectPatientsTasks } from "./useProjectPatientsTasks";

type ReturnType = {
  patientsTasks: PatientMutableTaskType[] | undefined;
  refetch: () => Promise<PatientMutableTaskType[]>;
  startPolling: (pollInterval: number) => void;
  stopPolling: () => void;
  loading: boolean;
};

export function useProjectPatientTaskRows(
  includeEmptyTasks: boolean,
  ignoreUserRestrictions = false
): ReturnType {
  const { patientsTasks, loading, refetch, ...other } =
    useProjectPatientsTasks(ignoreUserRestrictions);

  const parsedPatientsTasks = useMemo(
    () => (patientsTasks ? parse(patientsTasks, includeEmptyTasks) : undefined),
    [patientsTasks, includeEmptyTasks, loading]
  );

  const refetchCallback = async () => {
    const patientsTasks = await refetch();
    return parse(patientsTasks, includeEmptyTasks);
  };

  return {
    patientsTasks: parsedPatientsTasks,
    refetch: refetchCallback,
    ...other,
    loading,
  };
}

function parse(patients: PatientTasksType[], includeEmptyTasks: boolean): PatientMutableTaskType[] {
  return patients
    .flatMap((patient) => {
      const { id, studies, patientDicomId, tasks, cohorts, lastModified } = patient;

      if (tasks.length === 0 && includeEmptyTasks) {
        return [
          {
            id,
            patientDicomId,
            studies,
            cohorts,
            lastModified,
            task: undefined,
          } as PatientTaskTableRowType,
        ];
      }

      return tasks.map((task) => {
        const flagFilteredStudies = studies.map((study) => {
          const flagFilteredSeries = study.series.map((seriesItem) => {
            return {
              ...seriesItem,
              seriesFlags: seriesItem.seriesFlags.filter(
                (seriesFlag) => seriesFlag.taskId === task.id
              ),
            };
          });
          return {
            ...study,
            series: flagFilteredSeries,
          };
        });

        return {
          id,
          patientDicomId,
          studies: flagFilteredStudies,
          cohorts,
          lastModified,
          task,
        };
      });
    })
    .filter(({ studies }) => studies.flatMap(({ series }) => series).length > 0);
}
