import { AnatomicalStructuresFragmentType } from "../../../../AnatomicalStructuresFragment";

export function validateAnatomicalStructure(
  name: string,
  anatomicalStructures: AnatomicalStructuresFragmentType[]
): boolean {
  const re = /^[A-Za-z0-9_\s-]+$/;

  if (name.length === 0 || !re.test(name)) {
    return false;
  }

  const existingStructures = anatomicalStructures.map(({ structure }) => structure.toLowerCase());

  const existingNames = anatomicalStructures.map(({ name }) => name.toLowerCase());

  const formattedName = name.toLowerCase();
  const formattedAnatomicalStructure = formattedName.replace(/[-\s]+/g, "_");

  const matchesExistingStructure = existingStructures.some((existingStructure) => {
    const exactMatch = existingStructure === formattedAnatomicalStructure;
    const reconstructedMatch =
      existingStructure.split(".").join("_") === formattedAnatomicalStructure;
    return exactMatch || reconstructedMatch;
  });

  const matchesExistingName = existingNames.some((existingName) => existingName === formattedName);

  return !matchesExistingStructure && !matchesExistingName;
}
