import { useContext } from "react";
import { useHistory } from "react-router-dom";

import { ProjectContext } from "../../contexts/ProjectContext";
import { RoutePathSingleOrArray } from "../Layout";

type ReturnType = (
  route: RoutePathSingleOrArray,
  seriesId?: number,
  openTaskViewOnly?: boolean
) => void;

export function useGoToRoute(): ReturnType {
  const { getRoute } = useContext(ProjectContext);
  const { push } = useHistory();

  return (route, seriesId, openTaskViewOnly) => {
    const routeStr = getRoute(route);
    push({
      pathname: routeStr,
      state: {
        seriesId: seriesId,
      },
      search: openTaskViewOnly ? "?view_only=true" : undefined,
    });
  };
}
