import { PageType, ProjectLayoutType } from "../Layout";
import { useAllowedLayoutPages } from "./useAllowedLayoutPages";

export function useFindPage(): (
  layout: ProjectLayoutType,
  predicate: (page: PageType) => boolean
) => PageType | undefined {
  const getPages = useAllowedLayoutPages();
  return (layout, predicate) => {
    const pages = getPages(layout);
    return pages.find(predicate);
  };
}
