import {
  getInsertAnatomicalStructuresVariables,
  useInsertAnatomicalStructures,
} from "../../../../common/components/StudyTable/useInsertAnatomicalStructures";
import { TaskType } from "../hooks/TaskType";
import { useClassificationAttributes } from "../hooks/useClassificationAttributes";
import {
  getInsertClassificationAttributesVariables,
  useInsertClassificationAttributes,
} from "../hooks/useInsertClassificationAttributes";
import {
  getTaskDescriptionToInsert,
  useInsertTaskDescription,
} from "../hooks/useInsertTaskDescription";
import { getInsertTasksVariables, useInsertTasks } from "../hooks/useInsertTasks";
import { CreateTaskArgumentsType } from "./CreateTaskArgumentsType";

export function useCreateTasks(): (args: CreateTaskArgumentsType) => Promise<TaskType[]> {
  const [insertAnatomicalStructures] = useInsertAnatomicalStructures();
  const [insertClassificationAttributes] = useInsertClassificationAttributes();
  const [insertTaskDescription] = useInsertTaskDescription();
  const [insertTasks] = useInsertTasks();

  const { data: classificationAttributesData } = useClassificationAttributes();

  return async ({
    taskName,
    taskDescription,
    projectIds,
    anatomicalStructures,
    labellingOptions,
    seriesClassifications,
    seriesClassificationOptions,
    restrictedUsers,
    restrictedCohorts,
    limitToBaseline,
    qcSchemaId,
  }) => {
    if (!taskDescription) {
      throw new Error("No task description specified in useCreateTask");
    }
    if (projectIds.length === 0) {
      throw new Error("No projects selected in useCreateTask");
    }

    if (!classificationAttributesData) {
      throw new Error("Unable to fetch all classification attributes!");
    }

    const { classificationAttributes: allClassificationAttributes } = classificationAttributesData;

    const anatomicalStructuresToInsert = anatomicalStructures.filter(({ id }) => id === 0);

    const classificationAttributesToCreate = seriesClassifications.filter(
      ({ text }) =>
        !allClassificationAttributes.some(({ text: currentText }) => currentText === text)
    );

    if (anatomicalStructuresToInsert.length > 0) {
      const result = await insertAnatomicalStructures({
        variables: getInsertAnatomicalStructuresVariables(anatomicalStructuresToInsert),
      });

      const { data } = result;

      if (!data) {
        throw new Error("There was a problem inserting the anatomical structures!");
      }
    }

    if (classificationAttributesToCreate.length > 0) {
      const result = await insertClassificationAttributes({
        variables: getInsertClassificationAttributesVariables(classificationAttributesToCreate),
      });

      const { data } = result;

      if (!data) {
        throw new Error("There was a problem inserting the classifications!");
      }
    }

    const taskDescriptionToInsert = getTaskDescriptionToInsert(
      taskDescription,
      anatomicalStructures,
      taskName,
      labellingOptions,
      seriesClassifications,
      seriesClassificationOptions,
      limitToBaseline
    );

    let { id: taskDescriptionId } = taskDescription;

    if (taskDescriptionToInsert !== null) {
      const result = await insertTaskDescription({
        variables: { data: taskDescriptionToInsert },
      });
      const { data } = result;
      if (!data) {
        throw new Error("There was a problem inserting the task description!");
      }
      const {
        insert_task_description_one: { id: newTaskDescriptionId },
      } = data;
      taskDescriptionId = newTaskDescriptionId;
    }

    const variables = getInsertTasksVariables(
      projectIds,
      taskDescriptionId,
      taskName,
      restrictedUsers,
      restrictedCohorts,
      qcSchemaId
    );

    const { data: taskData } = await insertTasks({
      variables,
    });

    if (!taskData) {
      throw new Error("Failed to create new task");
    }

    const {
      insert_task: { returning: tasks },
    } = taskData;

    return tasks;
  };
}
