import { MutationTuple, useMutation } from "@apollo/client";
import { gql } from "@apollo/client/core";

import {
  ANATOMICAL_STRUCTURES_FRAGMENT,
  AnatomicalStructuresFragmentType,
} from "../../../Annotate/components/AnatomicalStructuresFragment";
import { Data, QUERY, Variables } from "../../../Annotate/components/useAnatomicalStructures";

const MUTATION = gql`
  mutation InsertAnatomicalStructure($objects: [anatomical_structures_insert_input!]!) {
    insert_anatomical_structures(
      objects: $objects
      on_conflict: {
        constraint: anatomical_structures_structure_key
        update_columns: [display_name]
      }
    ) {
      returning {
        ...AnatomicalStructure
      }
    }
  }
  ${ANATOMICAL_STRUCTURES_FRAGMENT}
`;

type VariablesType = {
  objects: {
    display_name: string;
    structure: string;
    color: string | null;
    readonly: boolean;
  }[];
};

type DataType = {
  insert_anatomical_structures: {
    returning: AnatomicalStructuresFragmentType[];
  };
};

export function useInsertAnatomicalStructures(): MutationTuple<DataType, VariablesType> {
  return useMutation<DataType, VariablesType>(MUTATION, {
    update(cache, { data }) {
      if (!data) {
        throw new Error(
          "Something went wrong updating the cache after inserting anatomical structures"
        );
      }

      const {
        insert_anatomical_structures: { returning: insertAnatomicalStructures },
      } = data;

      const { anatomicalStructures: existingAnatomicalStructures } = cache.readQuery<
        Data,
        Variables
      >({
        query: QUERY,
      }) ?? {
        anatomicalStructures: [],
      };

      cache.writeQuery<Data, Variables>({
        query: QUERY,
        data: {
          anatomicalStructures: [...insertAnatomicalStructures, ...existingAnatomicalStructures],
        },
      });
    },
  });
}

export function getInsertAnatomicalStructuresVariables(
  anatomicalStructures: AnatomicalStructuresFragmentType[]
): VariablesType {
  return {
    objects: anatomicalStructures.map(({ name: display_name, structure, color }) => ({
      display_name,
      structure: structure,
      color,
      readonly: false,
    })),
  };
}
