import { InputButton } from "../../../../../../../../../common/components/input/InputButton";
import { TaskCheckResultComponentProps } from "../../types/SaveCheckResultComponentProps";
import { MissingOrgansCheckResultType } from "./useMissingOrgansCheck";

export function MissingOrgansCheckActions({
  result,
  onRequestClose,
}: TaskCheckResultComponentProps<MissingOrgansCheckResultType>): JSX.Element {
  const handleGoToObject = () => {
    if (!result) {
      throw new Error("Missing organs result is empty when go to object was clicked");
    }
    onRequestClose();
  };

  if (!result) {
    return <></>;
  }

  return <InputButton type={"button"} onClick={handleGoToObject} value={"Go back"} width={100} />;
}
