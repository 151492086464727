import React, { ReactElement } from "react";

import { TinyKaplanChartContainer } from "../../../Analysis/KaplanMeier/components/TinyKaplanChartContainer";

interface ProjectPreviewProps {
  projectId: number;
}

const ProjectPreview = ({ projectId }: ProjectPreviewProps): ReactElement => {
  return <TinyKaplanChartContainer projectId={projectId} />;
};

export default ProjectPreview;
