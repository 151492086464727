import { createContext } from "react";

export const anonymizationMethods = ["SUBJECT_ID_ENCRYPTION"] as const;

export type AnonymizationMethodType = typeof anonymizationMethods[number];

export type AnonymizationConfigurationType = Record<AnonymizationMethodType, any>;

type AnonymizationConfigurationContextType = {
  config: AnonymizationConfigurationType;

  setConfig: (
    config:
      | AnonymizationConfigurationType
      | ((prevConfig: AnonymizationConfigurationType) => AnonymizationConfigurationType)
  ) => void;
};

export const defaultAnonymizationConfig: AnonymizationConfigurationType = {
  SUBJECT_ID_ENCRYPTION: undefined,
};

const DEFAULT_CONTEXT: AnonymizationConfigurationContextType = {
  config: defaultAnonymizationConfig,
  setConfig: () => undefined,
};
export const AnonymizationConfigurationContext = createContext(DEFAULT_CONTEXT);

interface AnonymizationConfigurationContextProviderProps
  extends AnonymizationConfigurationContextType {
  children: JSX.Element;
}

export function AnonymizationConfigurationContextProvider({
  children,
  config,
  setConfig,
}: AnonymizationConfigurationContextProviderProps): JSX.Element {
  return (
    <AnonymizationConfigurationContext.Provider value={{ config, setConfig }}>
      {children}
    </AnonymizationConfigurationContext.Provider>
  );
}
