import { useDialogReturnType } from "../../../../../../common/components/Dialog/useDialog";
import { useFormDialog } from "../../../../../../common/components/Dialog/useFormDialog";
import { BiopsyAssignmentActionType } from "./BiopsyAssignmentActionType";
import {
  BiopsyAssignmentCallbacksType,
  BiopsyAssignmentForm,
  BiopsyAssignmentFormProps,
} from "./BiopsyAssignmentForm";
import { formContents } from "./formContents";

interface useBiopsyAssignmentFormProps extends BiopsyAssignmentCallbacksType {
  action: BiopsyAssignmentActionType;
}

export function useBiopsyAssignmentForm({
  action,
  ...callbacks
}: useBiopsyAssignmentFormProps): useDialogReturnType {
  const props = { ...formContents[action], ...callbacks };
  const { title } = props;
  return useFormDialog<BiopsyAssignmentFormProps>({
    children: BiopsyAssignmentForm,
    label: title,
    showCloseButton: false,
    props,
  });
}
