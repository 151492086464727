import { useMemo } from "react";
import { useSelector } from "react-redux";

import {
  allowedOrgansSelector,
  allowLesionsSelector,
  taskLesionGroupingSelector,
} from "../../../../../../common/store/annotatePage/taskSelector";
import { LESION, LesionTypeType, ORGAN } from "../../../../../types/LesionTypeType";
import { GroupingType } from "../../../../../types/TaskDescriptionType";
import { LesionListItemFragmentType } from "../fragments/LesionListItemFragment";
import { LesionsListOptions } from "../LesionsList";
import { isLesionLesion } from "../utils/isLesionLesion";
import { isLesionOrgan } from "../utils/isLesionOrgan";
import { isLesionPredicted } from "../utils/isLesionPredicted";

const DEFAULT_NOUN_LESION = "Lesion";
const DEFAULT_NOUN_LABEL = "Label";
const DEFAULT_HEADER_LABEL_UNCLASSIFIED = "Pending";
const DEFAULT_HEADER_LABEL_LESION = "Lesions";
const DEFAULT_HEADER_LABEL_LABEL = "Labels";
const DEFAULT_HEADER_LABEL_OTHER_LESION = "Other";
const DEFAULT_HEADER_LABEL_PREDICTIONS = "Predicted";

const DEFAULT_SHOW_LESIONS_COUNT = true;
const DEFAULT_HIDE_IF_EMPTY = true;
const DEFAULT_SHOW_LESIONS_COUNT_UNGROUPED = true;
const DEFAULT_HIDE_IF_EMPTY_UNGROUPED = true;
const DEFAULT_SHOW_LESIONS_COUNT_OTHER = true;
const DEFAULT_HIDE_IF_EMPTY_OTHER = true;

export function useTaskLesionListOptions(): LesionsListOptions[] {
  const lesionGroupings = useSelector(taskLesionGroupingSelector);
  const allowLesions = useSelector(allowLesionsSelector);
  const allowedOrgans = useSelector(allowedOrgansSelector);

  return useMemo(() => {
    return lesionGroupings
      ? getGroupedLesionListOptions(lesionGroupings, DEFAULT_NOUN_LESION)
      : getDefaultLesionListOptions(allowLesions, allowedOrgans.length > 0);
  }, [lesionGroupings, allowLesions, allowedOrgans]);
}

export function getDefaultLesionListOptions(
  showLesions: boolean,
  showOrgan: boolean
): LesionsListOptions[] {
  const defaultLesionListOptions: LesionsListOptions[] = [];
  if (showLesions) {
    defaultLesionListOptions.push({
      filter: lesionListItemTypeFilters[LESION],
      headerLabel: DEFAULT_HEADER_LABEL_LESION,
      noun: DEFAULT_NOUN_LESION,
      hideIfEmpty: DEFAULT_HIDE_IF_EMPTY,
      showCount: DEFAULT_SHOW_LESIONS_COUNT,
    });
  }

  if (showOrgan) {
    defaultLesionListOptions.push({
      filter: lesionListItemTypeFilters[ORGAN],
      headerLabel: DEFAULT_HEADER_LABEL_LABEL,
      noun: DEFAULT_NOUN_LABEL,
      hideIfEmpty: DEFAULT_HIDE_IF_EMPTY,
      showCount: DEFAULT_SHOW_LESIONS_COUNT,
    });
  }
  return defaultLesionListOptions;
}

export function getGroupedLesionListOptions(
  groups: GroupingType[],
  noun: string
): LesionsListOptions[] {
  const options: LesionsListOptions[] = groups.map((group) => {
    const { displayName, showCount, hideIfEmpty } = group;

    return {
      filter: getLesionClassificationsFilter(group, groups, LESION),
      headerLabel: displayName,
      noun,
      hideIfEmpty,
      showCount,
    };
  });

  options.push({
    filter: getLesionClassificationsFilter(undefined, groups, LESION),
    headerLabel: DEFAULT_HEADER_LABEL_UNCLASSIFIED,
    noun,
    hideIfEmpty: DEFAULT_HIDE_IF_EMPTY_UNGROUPED,
    showCount: DEFAULT_SHOW_LESIONS_COUNT_UNGROUPED,
  });

  return options;
}

export const lesionListItemTypeFilters: Record<LesionTypeType, LesionListItemPredicateType> = {
  [LESION]: (lesion: LesionListItemFragmentType, currentTaskId) =>
    isLesionLesion(lesion) && currentTaskId === lesion.taskId,
  [ORGAN]: (lesion: LesionListItemFragmentType, currentTaskId) =>
    isLesionOrgan(lesion) && currentTaskId === lesion.taskId,
};

const otherLesionsFilter = (
  { taskId: lesionTaskId, origin }: LesionListItemFragmentType,
  currentTaskId: number
): boolean => currentTaskId !== lesionTaskId && origin === "USER";

const predictedLesionsFilter = (lesion: LesionListItemFragmentType): boolean => {
  const isPredicted = isLesionPredicted(lesion);
  const containsMasks = lesion.rois.some(({ masks }) => masks.length > 0);
  return isPredicted && containsMasks;
};

export const lesionsForCurrentTaskFilter = (
  { taskId: lesionTaskId, origin }: LesionListItemFragmentType,
  currentTaskId: number
): boolean => currentTaskId === lesionTaskId && origin === "USER";

export type LesionListItemPredicateType = (
  lesion: LesionListItemFragmentType,
  taskId: number
) => boolean;

function getLesionClassificationsFilter(
  group: GroupingType | undefined,
  groups: GroupingType[],
  type?: LesionTypeType
): LesionListItemPredicateType {
  return ({ type: lesionType, classifications, taskId: lesionTaskId }, currentTaskId) => {
    const lesionClassifications = classifications.map(({ classification }) => classification);

    const reversedGroups = [...groups].reverse();

    const previousGroups = group
      ? reversedGroups.slice(0, reversedGroups.indexOf(group))
      : reversedGroups;

    const inPreviousGroups = previousGroups.some(({ value }) =>
      lesionClassifications.includes(value)
    );

    const inCurrentGroup = group ? lesionClassifications.includes(group.value) : true;

    const isType = !type || lesionType === type;

    return isType && lesionTaskId === currentTaskId && inCurrentGroup && !inPreviousGroups;
  };
}

export const otherLesionsOptions: LesionsListOptions = {
  filter: otherLesionsFilter,
  headerLabel: DEFAULT_HEADER_LABEL_OTHER_LESION,
  noun: DEFAULT_NOUN_LABEL,
  hideIfEmpty: DEFAULT_HIDE_IF_EMPTY_OTHER,
  showCount: DEFAULT_SHOW_LESIONS_COUNT_OTHER,
};

export const predictedLesionsOptions: LesionsListOptions = {
  filter: predictedLesionsFilter,
  headerLabel: DEFAULT_HEADER_LABEL_PREDICTIONS,
  noun: DEFAULT_NOUN_LABEL,
  hideIfEmpty: DEFAULT_HIDE_IF_EMPTY_OTHER,
  showCount: DEFAULT_SHOW_LESIONS_COUNT_OTHER,
};
