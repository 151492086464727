import {
  FilterType,
  IPRO,
  MORTALITY,
  RECIST,
  RECIST_AND_IPRO,
  RECIST_AND_MORTALITY,
} from "../../ChangeFromBaseline/components/Filter";

export function getShouldShowGraphData(filter: FilterType) {
  const shouldShowBurden =
    filter === RECIST || filter === RECIST_AND_IPRO || filter === RECIST_AND_MORTALITY;

  const shouldShowSurvival = filter === IPRO || filter === RECIST_AND_IPRO;

  const shouldShowMortality = filter === MORTALITY || filter === RECIST_AND_MORTALITY;

  return {
    shouldShowBurden,
    shouldShowSurvival,
    shouldShowMortality,
  };
}
