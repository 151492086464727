import cornerstone from "cornerstone-core";

import { CornerstoneImageType } from "../../../../../../../cornerstone/CornerstoneImageType";

type ReturnType = {
  rowPixelSpacing: number | undefined;
  columnPixelSpacing: number | undefined;
};

export function getImagePixelSpacing(image: CornerstoneImageType): ReturnType {
  const { imageId } = image;

  const imagePlane = cornerstone.metaData.get("imagePlaneModule", imageId);

  if (!imagePlane) {
    const { rowPixelSpacing, columnPixelSpacing } = image;
    return {
      rowPixelSpacing,
      columnPixelSpacing,
    };
  }

  const { rowPixelSpacing, columnPixelSpacing, rowImagePixelSpacing, colImagePixelSpacing } =
    imagePlane;

  return {
    rowPixelSpacing: rowPixelSpacing ? rowPixelSpacing : rowImagePixelSpacing,
    columnPixelSpacing: columnPixelSpacing ? columnPixelSpacing : colImagePixelSpacing,
  };
}
