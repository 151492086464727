import React, { forwardRef } from "react";

import { Table, TableProps } from "../../../../common/components/Table/Table";
import { TableControlsType } from "../../../../common/components/Table/TableControlsType";
import { TableDataType } from "../../../../common/components/Table/TableDataType";

const GenericTableWithRef = forwardRef<TableControlsType<TableDataType>, TableProps<TableDataType>>(
  (props, ref) => {
    return <Table {...props} forwardedRef={ref} />;
  }
);

export const MemoTableWithRef = React.memo(GenericTableWithRef);
