import { AnyObject } from "nota-predict-web/src/common/types/EmptyObjectType";
import { z } from "zod";

import { getInputType, inputType, refineInputType } from "./enum";

export const fragmentQcRule = z.strictObject({
  name: z.string().min(1),
  input_type: inputType.superRefine(refineInputType),
});

export type FragmentQcRule = z.infer<typeof fragmentQcRule>;

export const isFragmentQcRule = (data: unknown): data is BaseQcRule => {
  return getInputType(data) !== undefined && (data as AnyObject)?.name !== undefined;
};

export const baseQcRule = z.strictObject({
  id: z.string(),
  name: z.string().min(1),
  input_type: inputType.superRefine(refineInputType),
  display_name: z.string().nullish(),
  description: z.string().nullish(),
  dependent_rules: z.array(z.custom(() => true)).optional(),
  required: z
    .boolean()
    .default(false)
    .optional()
    .transform((val) => val ?? false) as z.ZodTransformer<z.ZodTypeAny, boolean | undefined>,
  error_message: z.string().nullish(),
  default_value: z.string().nullish(),
  view_only: z.boolean().default(false).optional(),
});
export type BaseQcRule = z.infer<typeof baseQcRule>;

export const baseUploadableQcRule = baseQcRule.omit({ id: true });
export type BaseUploadableQcRule = z.infer<typeof baseUploadableQcRule>;
