import React, { useState } from "react";
import styled from "styled-components";

import { ReactComponent as CloseIcon } from "../../../../../assets/svgs/Close.svg";
import { ActionButtonsWrapper } from "../../../../../common/components/Dialog/Form/ActionButtonsWrapper";
import { SvgIcon } from "../../../../../common/components/icons/SvgIcon";
import { InputButton } from "../../../../../common/components/input/InputButton";
import { OutsideAlerter } from "../../../../../common/components/OutsideAlerter";
import { FilterButton } from "../../../../../common/components/Table/FilterButton";
import { main } from "../../../../../common/theme/main";

const Wrapper = styled.div<{ rightAlign: boolean }>`
  position: absolute;
  transform: translate(calc(-100% + ${({ rightAlign }) => (rightAlign ? "222px" : "26px")}), 6px);
  z-index: 999;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 8px;
  background: ${({ theme }) => theme.colors.neutral.white};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
`;

const HeaderWrapper = styled.div<{ rightAlign: boolean }>`
  margin-left: ${({ rightAlign }) => (rightAlign ? "0" : "auto")};
  margin-right: ${({ rightAlign }) => (rightAlign ? "auto" : "0")};
  padding: 0 0 8px 0;
`;

const ButtonWrapper = styled.div`
  cursor: pointer;
`;

interface FilterPopoutProps {
  isFiltered: boolean;
  onClearFilter: () => void;
  children: JSX.Element;
  rightAlign?: boolean;
  extraActionButtons?: JSX.Element;
}

export function FilterPopout({
  children,
  onClearFilter,
  isFiltered,
  rightAlign = false,
  extraActionButtons,
}: FilterPopoutProps): JSX.Element {
  const [open, setOpen] = useState<boolean>(false);

  const handleToggleOpen = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    setOpen((open) => !open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClearFilters = () => {
    onClearFilter();
  };

  return (
    <OutsideAlerter callback={handleClose}>
      <>
        <FilterButton isFiltered={isFiltered} onClick={handleToggleOpen} />
        {open && (
          <Wrapper rightAlign={rightAlign}>
            <HeaderWrapper rightAlign={rightAlign}>
              <ButtonWrapper onClick={handleClose}>
                <SvgIcon icon={CloseIcon} size={20} />
              </ButtonWrapper>
            </HeaderWrapper>
            {children}
            <ActionButtonsWrapper>
              <InputButton
                type="button"
                value={"Clear"}
                background={main.colors.neutral.white}
                color={main.colors.neutral.black}
                onClick={handleClearFilters}
              />
              {extraActionButtons}
              <InputButton type="button" value={"Ok"} onClick={handleClose} />
            </ActionButtonsWrapper>
          </Wrapper>
        )}
      </>
    </OutsideAlerter>
  );
}
