import { InputButton } from "nota-predict-web/src/common/components/input/InputButton";
import { DefaultIconFilterHeader } from "nota-predict-web/src/common/components/Table/DefaultIconFilterHeader";
import { main } from "nota-predict-web/src/common/theme/main";
import { components } from "react-select";
import { OptionProps } from "react-select/src/components/Option";
import { FilterProps } from "react-table";
import styled from "styled-components";

import FilterCheckbox from "../../../../../Analysis/ToolPanel/components/FilterCheckbox";
import {
  GenericMultiSelect,
  OptionType,
} from "../../../../../DataManagement/Upload/components/GenericMultiSelect";
import { TaskType } from "../../hooks/TaskType";
import { useProjectTasks } from "../../hooks/useProjectTasks";
import { TaskLabel } from "../TaskLabel";
import { FilterPopout } from "./FilterPopout";
import { getFilterPopoutSelectStyle } from "./getFilterPopoutSelectStyle";
import { getNonNullTaskFilter, TaskFilterType } from "./TaskFilterType";

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
`;

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
`;

const FilterWrapper = styled.div`
  flex: 1;
  min-width: 200px;
`;

export const Option = (props: OptionProps<OptionType<TaskType>, true>) => {
  //@ts-ignore
  const { value: task } = props as OptionType<TaskType>;

  return (
    <div>
      <components.Option {...props}>
        <OptionWrapper>
          <FilterCheckbox
            label={<TaskLabel task={task} users={[]} />}
            name={props.label}
            checked={props.isSelected}
            onChange={() => undefined}
          />
        </OptionWrapper>
      </components.Option>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-types
export function TaskColumnFilter<T extends object = {}>({ column }: FilterProps<T>): JSX.Element {
  const { filterValue, setFilter } = column;
  const { tasks: filteredTasks } = getNonNullTaskFilter(filterValue);
  const styles = getFilterPopoutSelectStyle<OptionType<TaskType>, true>();

  const { projectTasks: tasks = [], loading } = useProjectTasks();

  const handleTasksSelected = (tasks: TaskType[]) => {
    setFilter((filterValue: TaskFilterType) => {
      const filter = getNonNullTaskFilter(filterValue);
      return { ...filter, tasks };
    });
  };

  const handleClearFilter = () => {
    setFilter({ tasks: [] });
  };

  const handleSelectAll = () => {
    setFilter({ tasks });
  };

  const isFiltered = filteredTasks.length > 0;

  return (
    <DefaultIconFilterHeader column={column}>
      <FilterPopout
        isFiltered={isFiltered}
        onClearFilter={handleClearFilter}
        extraActionButtons={
          <InputButton
            type="button"
            value={"All"}
            background={main.colors.neutral.white}
            color={main.colors.neutral.black}
            onClick={handleSelectAll}
          />
        }
      >
        <Wrapper>
          <FilterWrapper>
            <GenericMultiSelect
              autoFocus={false}
              values={tasks}
              selected={filteredTasks}
              isLoading={loading}
              placeholder={"Filter Tasks..."}
              getOption={(value) => ({ value, label: value.name })}
              onSelectedChanged={handleTasksSelected}
              isClearable={false}
              menuIsOpen
              backspaceRemovesValue={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              controlShouldRenderValue={false}
              tabSelectsValue={false}
              styles={styles}
              preventBaseStyles={true}
              components={{
                Option,
                DropdownIndicator: null,
                IndicatorSeparator: null,
              }}
            />
          </FilterWrapper>
        </Wrapper>
      </FilterPopout>
    </DefaultIconFilterHeader>
  );
}
