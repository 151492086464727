import React, { ReactElement } from "react";
import styled from "styled-components";

const StyledText = styled.p<{ heavy: boolean }>`
  font-size: 16px;
  font-style: normal;
  font-weight: ${(props) => (props.heavy ? 600 : 400)};
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  margin-bottom: 24px;
  min-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface ItemTextProps {
  text: string;
  heavy?: boolean;
}

export default function ItemText({ text, heavy }: ItemTextProps): ReactElement {
  return <StyledText heavy={heavy || false}>{text}</StyledText>;
}
