import { ApolloClient, ApolloProvider } from "@apollo/client";
import { createTheme, StyledEngineProvider, Theme, ThemeProvider } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { SnackbarProvider } from "material-ui-snackbar-provider";
import React, { ReactElement, useEffect, useState } from "react";
import { HotkeysProvider } from "react-hotkeys-hook";
import Modal from "react-modal";
import { Provider } from "react-redux";
import { Router } from "react-router";
import { ThemeProvider as SCThemeProvider } from "styled-components";

import { createHasuraClient } from "./apollo/createHasuraClient";
import { AuthProvider } from "./auth0/AuthProvider";
import { useGetAuthToken } from "./auth0/useGetAuthToken";
import { useHandleLogin } from "./auth0/useHandleLogin";
import { BigFlexLoading } from "./common/components/Loading";
import { UserContextProvider } from "./common/contexts/UserContext/UserContextProvider";
import { Routes } from "./common/routes/Routes";
import { history } from "./common/store/history";
import store from "./common/store/store";
import { main } from "./common/theme/main";
import { initCornerstoneWadoImageLoaderAuth } from "./cornerstone/initCornerstoneWadoImageLoaderAuth";
import { ErrorBoundary } from "./error/ErrorBoundary";
import { LDProvider } from "./LaunchDarkly/LDProvider";
import { useMixpanel } from "./telemetry";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

//register react-modal to app
Modal.setAppElement("#root");

const theme = createTheme();

const useStyles = makeStyles((theme) => ({
  root: {
    // Some CSS that accesses the theme.
  },
}));

const App = (): ReactElement => {
  return (
    <Router history={history}>
      <AuthProvider>
        <LoginLayer>
          <InitializeClientTokensLayer>
            <LDProvider>
              <HotkeysProvider initiallyActiveScopes={[]}>
                <ErrorBoundary>
                  <Routes />
                </ErrorBoundary>
              </HotkeysProvider>
            </LDProvider>
          </InitializeClientTokensLayer>
        </LoginLayer>
      </AuthProvider>
    </Router>
  );
};

export default App;

interface LoginLayerProps {
  children: JSX.Element;
}

function LoginLayer({ children }: LoginLayerProps): JSX.Element {
  const isLoggedIn = useHandleLogin();
  const mixpanel = useMixpanel();

  if (!isLoggedIn) {
    return <BigFlexLoading>Authenticating user...</BigFlexLoading>;
  }

  mixpanel.identify();

  return children;
}

interface InitializeClientTokensLayerProps {
  children: JSX.Element;
}

function InitializeClientTokensLayer({ children }: InitializeClientTokensLayerProps): JSX.Element {
  const [hasuraClient, setHasuraClient] = useState<ApolloClient<unknown> | null>(null);
  const [pacsAuthInitialized, setPacsAuthInitialized] = useState(false);

  const getAuthToken = useGetAuthToken();

  /*
  - When requesting tokens, it's possible that multiple requests are made before one is resolved.
  - This occurs in graphql clients where there may be many queries executed in succession
  - Previously, we were getting the tokens each time a request was made, which caused invalid state
  auth0 errors in the first couple requests (on slow connections)
  - Instead, we prefetch the tokens here before proceeding to limit the likelihood of this occurring
   */
  useEffect(() => {
    getAuthToken().then((token) => {
      const hasuraClient = createHasuraClient(token);
      setHasuraClient(hasuraClient);

      initCornerstoneWadoImageLoaderAuth(token);
      setPacsAuthInitialized(true);
    });
  }, []);

  if (!hasuraClient || !pacsAuthInitialized) {
    return <BigFlexLoading>Establishing connections...</BigFlexLoading>;
  }

  return (
    <Provider store={store}>
      <ApolloProvider client={hasuraClient}>
        <StyledEngineProvider injectFirst>
          <SnackbarProvider SnackbarProps={{ autoHideDuration: 5000 }}>
            <ThemeProvider theme={theme}>
              <SCThemeProvider theme={main}>
                <UserContextProvider>{children}</UserContextProvider>
              </SCThemeProvider>
            </ThemeProvider>
          </SnackbarProvider>
        </StyledEngineProvider>
      </ApolloProvider>
    </Provider>
  );
}
